import {collection, getDocs, limit, orderBy, query, where} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {authFetch} from "../../../../api/network";

function xMonthsAgo(months=1) {
    // Get a date object for the current time
    let d = new Date();

// Set it to one month ago
    d.setMonth(d.getMonth() - months);

// Zero the time component
    d.setHours(0, 0, 0, 0);
    return d.getTime();
}

export async function readAllEvents(community) {
    // read-all-events
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        const payload = {
            member_id: community.member_id,
            user_uid: community.member.user_uid,
            community_uid: community.uid,
        };

        authFetch("/read-all-events", res, res, "POST", {payload})
    })
}

export async function getEventsFromFeed(community,timeframe="New",lim=250) {

    const ref = collection(db, 'community_members', community.id, 'member_events_feeds',community.member_id,'events');

    let q;

    if (timeframe === "New") {
        q = query(ref, where("read","==",false), orderBy("ts", "desc"), limit(lim));
    } else if (timeframe === "Last month") {
        q = query(ref, where("ts",">", xMonthsAgo(1)), orderBy("ts", "desc"), limit(lim*0.5));
    } else {
        q = query(ref, where("ts",">", xMonthsAgo(12)), orderBy("ts", "desc"), limit(lim*2));
    }

    const snap = await getDocs(q);

    if(snap.empty) {
        return [];
    }

    return snap.docs.map(d=>{
        return {
            id: d.id,
            ...d.data()
        }
    })
}