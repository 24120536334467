import React from "react";
import M3_A_Text from "./text";
import {all_field_types} from "../../../common/data/field-types";

/*
* This is the FormattedValue atom
 */

const _props = {
    children: {
        type: "node",
        required: false
    },
    value: {
        type: "string",
        required: false
    },
    type: {
        type: "string",
        required: false,
        options: [
            "currency",
            "date",
            "time",
            "datetime",
            "relative-time"
        ]
    }
};

// todo make part of spec
function format(v, type, data, options) {

    const field_type_data = all_field_types[type];

    if (field_type_data&&field_type_data.formatValue) {
        return field_type_data.formatValue(v, data, options, field_type_data);
    } else {
        return "Format not found for type: " + type;
    }
}

export default function M3_A_FormattedValue({
                                                value,
                                                type,
                                                data,
                                                options = {},
                                                text_props = {}
                                            }) {
    const val = format(value, type, data, options);
    return (
        <M3_A_Text {...text_props}>
            {val}
        </M3_A_Text>
    );
}