import React, {useState} from 'react';
import {useCommunity} from "../../../../config/community";
import {authFetch} from "../../../../../api/network";
import {ExperienceHistory} from "../experience-history";
import {TextAction} from "../../../auth/sign-in";
import {ProfileSection} from "../../../../m3/_legacy_components/profile-field-renderer";

export function validateEducation(obj) {
    let to_return = Object.assign({}, obj);

    if (obj.org_data.title === '') {
        to_return.org_data.title = '-';
    }

    return to_return;
}

export function MemberEducation({can_add, education, my_profile = false, visibility = 'all-members', cb, user_uid}) {
    const community = useCommunity();
    const [modal, setModal] = useState(null);
    const [loading, setLoading] = useState(null);

    function editEducation(ed, id) {
        setLoading('edit');
        const payload = {
            education: validateEducation(ed),
            community_uid: community.uid,
            user_uid: user_uid,
            education_id: id
        };

        const res = async () => {
            cb();
            setTimeout(() => {
                global.addToast({text: 'Education Updated', intent: 'success'});
                setLoading(null);
                setModal(null);
            }, 1500);
        };

        authFetch('/members/education-edit', res, res, "POST", {payload});
    }

    function deleteEducation(id) {
        setLoading('add');
        const payload = {
            community_uid: community.uid,
            user_uid: user_uid,
            education_id: id
        };

        const res = async () => {
            cb();
            setTimeout(() => {
                global.addToast({text: 'Education Deleted', intent: 'success'});
                setLoading(null);
                setModal(null);
            }, 1500);
        };

        authFetch('/members/education-delete', res, res, "POST", {payload});
    }

    let actions = can_add ? [<TextAction text="Add" onClick={() => {
        community.openEditOverlay({
            type: 'add-education',
            size: 'medium',
            closeCb: () => {
                console.log('closeCb')
            }
        })
    }}/>] : [];

    /*

        {modal === 'add-education' &&
            <AddEducationModal loading={loading === 'add'} onAdd={addEducation.bind(this)}
                               onClose={() => setModal(null)}/>}
     */
    // block={true} add={actions} layout={is_mobile?"":"desktop"} section={{autocomplete:[]}} visibility={visibility} title="Education"
    return <>
        <ProfileSection visibility={visibility} title="Education" actions={actions}>
            <ExperienceHistory type='education' can_add={can_add} loading={loading} editEducation={editEducation}
                               deleteEducation={deleteEducation} modal={modal} updateModal={(a) => {
                setModal(a)
            }} data={education}/>

        </ProfileSection>

    </>
}