import React, {useEffect, useRef, useState} from 'react';
import {gapiGetUserCalendars, initializeGapi, isGapiInitialized} from "./gapi";
import {gapi} from "gapi-script";
import {BasicSelect} from "../../../m3/_legacy_components/form/basic-select";
import {InlineLoader} from "../../../m3/_legacy_components/admin-activity";
import {useCommunity} from "../../../config/community";
import {getItemFromLocalStorage} from "../../../m3/utilities/localstorage";
import {loadGoogleIdentityScript} from "./connect-google-account";
import config from "../../../config/firebase-config";
import Button from "../../../m3/_legacy_components/button";
import {TextAction} from "../../auth/sign-in";

function isAuthed() {
    return gapi && gapi.auth2 && gapi.auth2.getAuthInstance() && gapi.auth2.getAuthInstance().isSignedIn.get();
}

/*
 if(window.google) {
                    console.log("GOOGLE INITIATED")
                } else {
                    console.log("GOOGLE NOT INITIATED")
                    loadGoogleIdentityScript()
                        .then(() => {
                          console.log("LOADED")
                            if (window.google) {
                                console.log("GAPI CLIENT")

                                let token_client = new window.google.oauth.initTokenClient({
                                    client_id: config.calendarClientID,
                                    scope: 'https://www.googleapis.com/auth/calendar.readonly',
                                    prompt: '',
                                    callback: (tokenResponse) => {
                                        console.log("------")
                                        console.log(tokenResponse)
                                        console.log("--------")
                                      //  access_token = tokenResponse.access_token;
                                    },
                                })

 */

export function GoogleCalendarSelector({value, onChange}) {
    const community = useCommunity();
    const [options, setOptions] = useState([]);
    const [state, setState] = useState("loading");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    let token_client = useRef(null);

    console.log("GOOGLE CALENDAR SELECTOR", value,)
    useEffect(function () {
        const is_initiated = isGapiInitialized();
        if (!is_initiated) {
            console.log("NOT INITIATED")
            setError('not_initiated')
        }
        console.log("USE EFFECT", isAuthed());
        if (gapi && gapi.auth2) {
            console.log("HAS AUTH2")
            getCalendars();
        } else {
            const local_data = getItemFromLocalStorage(`${community.id}_${community.member_id}_gapi-auth`, null);

            if (local_data) {
                initializeGapi()
                    .then(()=>{
                        console.log("GAPI INITIALIZED")
                        loadGoogleIdentityScript()
                            .then(() => {
                                console.log("script loaded")
                                token_client.current = window.google.accounts.oauth2.initTokenClient({
                                    client_id: config.calendarClientID,
                                    scope: "https://www.googleapis.com/auth/calendar.readonly",
                                    callback: async (resp) => {
                                        if (resp.error !== undefined) {
                                            console.log("ERROR", resp)
                                            throw (resp);
                                        }
                                        console.log("CALLBACK", resp)
                                        getCalendars();
                                    }
                                });

                                gapi.client.setToken({access_token: local_data.access_token});

                                const token_check = gapi.client.getToken();

                                // need user to be do their thing
                                setState("show-button")

                            });
                    })


                /*


                 */
           //   getCalendars(local_data.access_token)
                /*
                initializeGapi()
                    .then((res) => {
                        // now to sign in
                        const auth2 = gapi.auth2.getAuthInstance();

                        if (auth2.isSignedIn.get()) {
                            console.log("ALREADY SIGNED IN")
                        } else {
                            console.log("SET TOKEN", local_data.access_token)

                            const is_signed_in = auth2.isSignedIn.get();

                            console.log("IS SIGNED IN", is_signed_in)
                        }

                    })

                 */
            } else {
                setError('not_authed')
            }
        }
    }, [])

    function getCalendars() {
        gapi.client.load('calendar', 'v3', () => {
            gapiGetUserCalendars()
                .then((res) => {
                    if (res && res.result && res.result.items) {
                        setOptions(res.result.items);
                        setState("");
                        setError(null);
                    } else {
                        setOptions([]);
                        setError('unknown')
                        setState("");
                    }
                    setLoading(false)
                    setState("");
                })
        })
    }

    if(state==="loading") {
     return <div>
         <InlineLoader/>
     </div>
    }

    if(state==="show-button") {

        if(value) {
            return <div>
               Calendar already selected, <TextAction onClick={()=>onChange("",{})}>change it</TextAction>.
            </div>
        }

        return <div>
            <Button text="Select Calendar" onClick={()=>{
                if (gapi.client.getToken() === null && token_client.current !== null) {
                    // Prompt the user to select a Google Account and ask for consent to share their data
                    // when establishing a new session.
                    token_client.current.requestAccessToken({prompt: 'consent'});
                } else {
                    // Skip display of account chooser and consent dialog for an existing session.
                    token_client.current.requestAccessToken({prompt: ''});
                }
            }} />
        </div>
    }

    if (error) {
        return <div>
            Error: {error}
        </div>
    }

    if (loading) {
        return <div>
            <InlineLoader/>
        </div>
    }



    const select_options = options.map((option) => {
        return {
            value: option.id,
            label: option.summary,
            metadata: {
                calendar_name: option.summary,
                calendar_description: option.description,
                calendar_timezone: option.timeZone
            }
        }
    })

    return <BasicSelect value={value} placeholder="Select a calendar"
                        onChange={(v) => {
                            onChange(v.value, v.metadata)
                        }} options={select_options}/>
}