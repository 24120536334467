import dayjs from "dayjs";
import {authFetch} from "../../../../../api/network";
import {logEngagementEvent} from "../../../../config/firebase-setup";
import {utils_strings_isEmail} from "../../../../../common/utilities/strings";
import {data_countries_map} from "../../../../../common/data/data/countries";

export function setupHeaders(row) {
    let headers = {};
    for (let i = 0; i < row.length; i++) {
        headers[i] = {
            label: row[i]
        }
    }
    this.setState({
        file_headers: headers
    })
}

function buildRecordItem(item) {
    let obj = {};
    for (let i = 0; i < item.length; i++) {
        obj[i] = item[i];
    }
    return obj;
}

function buildRecords(data) {
    let records = {};
    for (let i = 1; i < data.length; i++) {
        let item = data[i];
        records[(i - 1)] = buildRecordItem(item)
    }
    return records;
}

export function setupPreview(data) {
    const new_records = buildRecords(data);
    console.log("SETUP PREVIEW", new_records)
    this.setState({
        records: new_records,
        record_count: Object.keys(new_records).length
    })
}

export function importMatchCountry(str) {
    let test = str;

    if (test.length === 2) {
        if (data_countries_map[test]) {
            return data_countries_map[test];
        }
    }

    return '';
}

export function lie_validateImportField(value, {
    type,
    config
}, member_types, preview = false, default_member_type = '') {
    let v = '';
    switch (type) {
        case 'birthday' : {
            const mom = dayjs(value, ['DD-MM-YYYY', 'MM-DD-YYYY', 'DD.MM.YYYY', 'DD-M-YYYY']);
            if (mom.isValid()) {
                return preview ? mom.format('MMM Do, YYYY') : {
                    date: mom.date(),
                    month: mom.month(),
                    year: mom.year()
                }
            }
            return preview ? '' : {
                date: null,
                month: null,
                year: null
            }
        }
        case 'email' : {
            return utils_strings_isEmail(value) ? value : '';
        }
        case 'country' : {
            v = importMatchCountry(value.toString());
            return !preview && v !== '' ? value.toString() : v;
        }
        case 'date' : {
            const mom = dayjs(value);
            if (mom.isValid()) {
                return mom.toDate();
            }
            return null;
        }
        case 'group': {
            return '';
        }
        case 'select': {
            return '';
        }
        case 'checkbox': {
            return false;
        }
        case 'multi-select-taxonomy': {
            return [];
        }
        case 'select-taxonomy' : {
            return null;
        }
        case 'multi-select' : {
            return {};
        }
        case 'member' : {
            return '';
        }
        case 'multi-member' : {
            return {};
        }
        case 'mentor' : {
            return '';
        }
        case 'mentees' : {
            return {};
        }
        case 'member-type' : {
            // loop through match names to see if there is a match
            const config_keys = Object.keys(config);
            for (let i = 0; i < config_keys.length; i++) {
                const ck = config_keys[i];
                if (config[ck].match_name === value) {
                    return preview ? member_types[ck].singular : ck;
                }
            }
            return preview ? member_types[default_member_type].singular : default_member_type;
        }
        case 'phone' : {
            v = value.toString();
            return v === '' ? '' : v;
        }
        case 'string' : {
            v = value.toString();
            return v === '' ? '' : v;
        }
        case 'text' || 'textarea' : {
            v = value.toString();
            return v === '' ? '' : v;
        }
        case 'number' : {
            return parseInt(value) ? parseInt(value) : null;
        }
        default : {
            v = value.toString();
            return v === '' ? '' : v;
        }
    }
}

function buildMember(r, m, member_types) {
    let m_keys = Object.keys(m);
    let obj = {};
    for (let i = 0; i < m_keys.length; i++) {
        const m_key = m_keys[i];
        const m_item = m[m_key];

        const record_data_cell = r[m_item.column] ? r[m_item.column] : '';

        obj[m_key] = lie_validateImportField(record_data_cell, m_item, member_types, false, '');
    }
    return obj;
}

function isMemberValid(nmi) {
    return nmi.account_email !== '' && nmi.first_name !== '' && nmi.last_name !== '';
}

export function validateRecords() {
    // against the
    let valid_records = {};
    let invalid_records = {};
    const {member_types} = this.props;

    const records = Object.values(this.state.records);

    const match = this.state.match;

    // loop through each data row of records
    // create final objects for creation of members
    for (let i = 0; i < records.length; i++) {
        const record = records[i];

        let new_member = buildMember(record, match, member_types);

        if (isMemberValid(new_member)) {
            valid_records[new_member.account_email] = new_member;
        } else {
            invalid_records[(i + 1)] = new_member;
        }
    }

    this.setState({
        valid_records,
        invalid_records
    })
}

export function extendMatchForCustomFields() {
    let match = this.state.match;
    const {member_types, custom_fields} = this.context;

    const cps = Object.values(custom_fields);
    for (let i = 0; i < cps.length; i++) {
        const section = cps[i];
        for (let i = 0; i < section.fields.length; i++) {
            const key = section.fields[i];
            const data = section.field_data[key];
            match[key] = {
                column: '',
                name: data.name,
                type: data.type
            };
        }
    }
    let mt_keys = Object.keys(member_types);
    match['member_type'].config = {};
    for (let i = 0; i < mt_keys.length; i++) {
        match['member_type'].config[mt_keys[i]] = {
            name: member_types[mt_keys[i]].plural,
            match_name: ''
        }
    }

    this.setState({
        match
    })
}

export function buildHeaderOptions(columns) {
    let keys = Object.keys(columns);
    let to_return = [];

    for (let i = 0; i < keys.length; i++) {
        to_return.push({
            value: i.toString(),
            label: columns[keys[i]].label
        })
    }

    return to_return;
}

/*
5QN1ksBsN: "-"
Amawovr8J: "-"
account_email: "zoellner@isaria.de"
address_city: "Muenchen"
address_country: "-"
address_line_one: "-"
address_line_two: "-"
address_state: "-"
address_zip_code: "81675"
bio: "-"
birthday: {date: null, month: null, year: null}
email: "zoellner@isaria.de"
facebook: "-"
first_name: "Andreas O."
last_name: "Zoellner"
linkedin: "-"
location: "-"
member_type: "YLUlemRIAwwxaxU1NfFF"
middle_name: "-"
phone: "-"
secondary_phone: "-"
title: "-"
twitter: "-"
website: "-"
 */

// todo single file for bday utils
function UTIL_buildShortBday(m, d) {
    const date = d < 10 ? `0${d}` : d;
    return `${m}${date}`;
}

export function lie_prepMemberForImport(record) {
    let obj = {
        account_email: record.account_email,
        member_id: record.member_id,
        first_name: record.first_name,
        last_name: record.last_name,
        full_name: record.full_name,

        profile: {
            address: {},
            secondary_emails: '', // str split by ,
            member_type: '',
            birthday: {},
            custom_fields: {},
            contact: {},
            social: {},
            about: {}
        }
    };
    const keys = Object.keys(record);

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const value = record[key];
        switch (key) {
            case 'social.website':
            case 'social.facebook':
            case 'social.eth_address':
            case 'social.instagram':
            case 'social.reddit':
            case 'social.github':
            case 'social.skype':
            case 'social.twitter':
            case 'social.linkedin': {
                obj.profile.social[key.split('.')[1]] = value;
                break;
            }
            case 'secondary_emails':
                obj.profile.secondary_emails = value;
                break;
            case 'member_type' : {
                obj.profile[key] = value;
                break;
            }
            case 'birthday' : {
                if (value.month && !isNaN(value.month) && !isNaN(value.date) && value.date) {
                    // both the month and date are ok
                    const m = parseInt(value.month) + 1;
                    const d = value.date;
                    obj.profile.short_birthday = UTIL_buildShortBday(m, d);
                } else {
                    obj.profile.short_birthday = "";
                }
                obj.profile[key] = value;
                break;
            }
            case 'about.middle_name':
            case 'about.title':
            case 'about.bio': {
                obj.profile.about[key.split('.')[1]] = value;
                break;
            }
            case 'contact.email':
            case 'contact.phone':
            case 'contact.secondary_phone': {
                obj.profile.contact[key.split('.')[1]] = value;
                break;
            }
            case 'account_email': {
                break;
            }
            case 'full_name': {
                break;
            }
            case 'about.first_name':
            case 'about.last_name' : {
                obj.profile.about[key.split('.')[1]] = value;
                break;
            }
            case 'address.city':
            case 'address.zip_code':
            case 'address.line_one':
            case 'address.line_two':
            case 'address.state':
            case 'address.country' : {
                obj.profile.address[key.split('.')[1]] = value;
                break;
            }
            case 'docid':
            case 'id':
            case 'member_id':
            case 'last_name':
            case 'first_name':
                break;
            default : {
                // custom field
                obj.profile.custom_fields[key.replace('custom_fields.', '')] = value;
                break;
            }
        }
    }
    return obj;
}

export function startImport() {
    const valid_records = this.state.valid_records;

    // need arr
    const members = Object.values(valid_records);

    let nmd = [];

    for (let i = 0; i < members.length; i++) {
        const m = members[i];
        nmd.push(lie_prepMemberForImport(m));
    }

    this.callImportAPI(nmd);
}

const JOB_SIZE = 50;

export function callImportAPI(nmd,community) {
    const jobs = Math.ceil(nmd.length / JOB_SIZE);
    let imports = {};
    let promises = [];
    const community_uid = community.uid;
    const member_id = community.member_id;
    for (let i = 0; i < jobs; i++) {
        const job_num = i;
        const batch = nmd.slice((i * JOB_SIZE), ((i + 1) * JOB_SIZE));
        imports[i] = true;
        const res = () => {
            const njd = this.state.jobs_done + 1;
            if (njd === this.state.jobs_total) {
                /*
                analytics().logEvent('import_completed', {
                    event_id: 'import_completed',
                    content_type: 'community',
                    community_uid
                });

                 */

                this.setState({
                    jobs_done: njd,
                    import_done: true
                })
            } else {
                this.setState({
                    jobs_done: njd
                })
            }
        };
        const payload = {
            members: batch,
            member_id,
            community_uid,
            is_batch: true
        };
        if (batch.length > 0) {
            promises.push(authFetch("/members/add-many", res.bind(this), res.bind(this), "POST", {payload}));
        }
    }

    this.setState({
        jobs_total: jobs,
        is_importing: true
    });

    const p = {
        member_id,
        community_uid
    };


    Promise.all(promises).then(() => {
        console.log('post hook')
        logEngagementEvent("import_members", {}, community.uid);
        authFetch("/members/add-many-post-import-hook", () => {
        }, () => {
        }, "POST", {
            payload: p
        })
    });
}

export function importComplete() {

    this.setState({
        import_done: true
    });
}