import React from "react";

export default function PinboardModerators({title = 'Moderators', data}) {

    return <div className="flex" style={{maxWidth:'inherit'}}>
        <div className="flex-none max-w-[8rem] pr-4 min-w-[8rem]">
            <span className="text-gray-500 text-sm">{title}</span>
        </div>
        <div className="flex-grow pt-px -mb-0.5 overflow-hidden">

        </div>
    </div>
};