import React from "react";
import SimpleTooltip from "../../../tooltip";
import {CheckIcon, QuestionMarkCircleIcon} from "@heroicons/react/24/outline";
import Button from "../../../button";

export function LIEFooter({onStartImport=()=>{},all_invalid=[],can_import = false,note="",valid_records=0,data_field_key='',missing_key=0}) {
    return <div className="grid px-4 py-3 border-t border-gray-200"
                style={{gridTemplateColumns: '1fr 10rem', gridGap: '2rem'}}>
        <div className="flex flex-col space-y-0.5 h-6">
            {valid_records>0&&<div className="text-gray-600 text-xs">
                <strong>{valid_records} valid</strong> records found
            </div>}
            {missing_key>0&&<div className="text-gray-600 text-xs leading-4 h-6 flex items-center">
                <strong>{missing_key} invalid</strong>&nbsp;records found (missing key values)
                <div className="inline-flex h-6 w-6 items-center pl-2">
                    <SimpleTooltip text={`Invalid rows: ${all_invalid.join(", ")}`}>
                <div className="w-4 h-4 hover:opacity-80 cursor-pointer text-gray-400">
                    <QuestionMarkCircleIcon />
                </div>
                    </SimpleTooltip>
            </div>
            </div>}
            <div className="text-gray-600 text-xs">
                Note: {note}
            </div>
        </div>
        <div className="inline-flex justify-end">
            <div>
            <Button onClick={onStartImport} disabled={!can_import} text="Finish Matching" left_icon={{icon:<CheckIcon />}}
                    intent="success"/>
            </div>
        </div>
    </div>
}