import React, {useEffect, useState} from "react";
import {collection, getDocs, limit, query} from "firebase/firestore";
import {db} from "../../../../config/setup-firestore";
import Button from "../../../../m3/_legacy_components/button";
import {InlineLoader} from "../../../../m3/_legacy_components/admin-activity";
import {SharedDirectoryHeader} from "../shared-directory-header";
import {updateDirectoryViewStack} from "../../../../../api/directory-views";

export async function getAllGroupsData(cid) {
    const col = collection(db, 'community_entities', cid, 'groups');
    let q = query(col, limit(500));
    const snap = await getDocs(q);
    return snap.docs.map(doc => {
        return {
            ...doc.data(),
            id: doc.id
        }
    });
}

export function SmartDirectory({
                                   action,
    resetAction,
                                   renderer,
                                   hide_toolbar,
                                   getStacks = async () => undefined,
                                   createDefaultViewStack = async () => undefined,
                                   community_uid,
                                   is_loading,
                                   demo,
                                   demo_stacks,
                                   onUpdateParent,
                                   onChangeViewStacks,
                                   block_editor,
                                   filterItems = (a) => a,
                                   filterSections = (a) => a,
                                   buildSections = (a) => a,
                                   buildSpec = (a) => undefined,
                                   onChangeCollection = (a) => undefined,
                                   disable_local_prefs = false,
                                   enable_renderer = true,
                                   member_id,
                                   getData = async () => undefined,
                                   page_key = "leaders"
                               }) {
    const [data, setData] = useState(null);
    const [query, setQuery] = useState("");
    const [view_stacks, setViewStacks] = useState(null);
    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [active_collection, setActiveCollection] = useState(null);
    const [active_view, setActiveView] = useState(null);
    const [active_filters, setActiveFilters] = useState([]);
    const [active_group_by, setActiveGroupBy] = useState("");
    const [sort, setSort] = useState(null);

    const [all_groups, setAllGroups] = useState(null);

    const [changes_made, setChangesMade] = useState(false);

    useEffect(() => {
        if(action){
            console.log("handle action",action)
            if(action?.type === "refresh") {
                handleFetchData();
            }

            resetAction();
        }
    }, [action]);

    useEffect(() => {
        if (onChangeViewStacks) {
            onChangeViewStacks(view_stacks);
        }
    }, [view_stacks]);

    useEffect(function () {
        if (is_loading) {
            console.log("Parent is loading, wait..")
            return;
        }
        if (community_uid) {
            getStacks()
                .then(stacks => {
                    if (stacks === undefined) {
                        console.error("Failed to get stacks")
                        setError("Failed to get stacks");
                        return;
                    }
                    if (!stacks || stacks.length === 0) {
                        if (demo && demo_stacks) {
                            console.log("stack", demo_stacks)
                            handleUpdateViewStacks(demo_stacks);
                            handleFetchData();
                        } else if (createDefaultViewStack) {
                            createDefaultViewStack()
                                .then(() => {
                                    getStacks()
                                        .then(stacks => {
                                            if (stacks.length > 0) {
                                                handleUpdateViewStacks(stacks);
                                                handleFetchData();
                                            } else {
                                                console.error("Failed to create default stack")
                                            }
                                        })
                                })
                        } else {
                            setError("No stacks found, cannot create")
                        }
                    } else {
                        console.log("SET STACKS", stacks)
                        handleUpdateViewStacks(stacks);
                        handleFetchData();
                    }
                });
            fetchFilterData();
        }
    }, [community_uid, is_loading])

    function handleFetchData() {
        getData()
            .then(data => {
                if (data === undefined) {
                    console.error("Failed to get data");
                    setError("Failed to get data");
                    return;
                }
                setData(data);
                setReady(true);
                setLoading(false);
            })

    }

    useEffect(function () {
        //console.log("VIEW STACKS", view_stacks, active_view)
        if (view_stacks && view_stacks.length > 0 && !active_view) {
            const local_prefs = localStorage.getItem(`${community_uid}-${page_key}_directory`);
            if (local_prefs && !disable_local_prefs) {
                const prefs = JSON.parse(local_prefs);
                if (prefs && prefs.active_view && prefs.active_collection) {

                    const collection_found = view_stacks.filter(v => v.id === prefs.active_collection.id)[0];
                    if (collection_found) {

                        const view_found = collection_found.stack.filter(v => v.id === prefs.active_view.id)[0];
                        if (view_found) {
                            console.log("VIEW FOUND", view_found)
                            console.log("prefs", prefs)
                            setActiveCollection(collection_found);
                            setActiveView(view_found);
                            setActiveFilters(prefs.active_filters || []);
                            setActiveGroupBy(prefs.active_group_by || "");
                            setSort(prefs.sort || null);
                            if (onChangeCollection) {
                                onChangeCollection(collection_found);
                            }
                            return;
                        }
                    }
                }
            }

            const active_stack = view_stacks[0];
            const active_view = active_stack.stack[0];
            console.log("active stack", active_stack, active_view)
            if (active_stack && active_view) {
                setActiveCollection(active_stack);
                setActiveView(active_view);
                setActiveFilters(active_view.filters || []);
                setActiveGroupBy(active_view.group_by || "");
                setSort(active_view.sort || null);
                if (onChangeCollection) {
                    onChangeCollection(active_stack);
                }
            } else {
                console.error("no active view")
            }
        }

        return () => {

            savePreferencesToLocalStorage();
        }
    }, [view_stacks, active_view, changes_made])

    function handleSelectCollection(collection_id) {
        console.log("CURRENT ID", active_collection.id)
        console.log("NEW collection_id", collection_id)
        const new_collection = view_stacks.filter(vs => vs.id === collection_id)[0];
        const new_active_view = view_stacks.filter(vs => vs.id === collection_id)[0].stack[0];
        console.log("NEW VIEW", new_active_view)
        // set loading
        setLoading(true);
        setActiveCollection(new_collection);
        setActiveView(new_active_view);
        setActiveFilters(new_active_view.filters || []);
        setActiveGroupBy(new_active_view.group_by || "");
        setSort(new_active_view.sort || null);
        if (onChangeCollection) {
            onChangeCollection(new_collection);
        }
        handleFetchData();
    }

    // TODO - set defaults when data is loaded
    function handleSaveChanges() {
        let update = {
            ...active_collection,
            stack: active_collection.stack.map(v => {
                if (v.id === active_view.id) {
                    return {
                        ...v,
                        label: active_view.label,
                        filters: active_filters,
                        group_by: active_group_by,
                        sort: sort
                    }
                } else {
                    return v;
                }
            })
        }

        handleUpdateParent();

        setChangesMade(false);
        setLoading(true);
        updateDirectoryViewStack(community_uid, member_id, active_collection.id, update)
            .then(() => {
                setLoading(false);
            })
    }

    function handleUpdateParent(field, value) {
        if (onUpdateParent) {
            let obj = {
                sort: sort,
                filters: active_filters,
                group_by: active_group_by,
                query: query
            }
            if (field && value !== undefined) {
                obj[field] = value;

                onUpdateParent({
                    [field]: value
                })
                return;
            }
            onUpdateParent(obj);
        }
    }

    function handleChangeFilter(id, value, remove) {
        const index = active_filters.findIndex(f => f.id === id);
        console.log("CHANGE FILTER", id, value, remove, index)
        if (id === "clear" && remove) {
            const nf2 = [];
            setActiveFilters(nf2);
            handleUpdateViewField(active_collection.id, active_view.id, 'filters', nf2);
            setChangesMade(true);

            handleUpdateParent('filters', nf2);
            return;
        }

        if (index > -1) {

            if (remove) {
                const new_filters = [...active_filters];
                new_filters.splice(index, 1);
                setActiveFilters(new_filters);
                handleUpdateViewField(active_collection.id, active_view.id, 'filters', new_filters);
                setChangesMade(true);

                handleUpdateParent('filters', new_filters);
                return;
            } else {
                let a = [...active_filters];
                if (value) {
                    a[index].value = value;
                } else {
                    // todo get default value of this field
                    a[index].value = null;
                }
                // console.log("SET ACTIVE FILTERS", a)
                setActiveFilters(a);
                handleUpdateViewField(active_collection.id, active_view.id, 'filters', a);
            }
        } else {
            // is not yet an active filter
            //  console.log("ADD FILTER")
            handleAddFilter(id, value, true)
        }
        setChangesMade(true);
    }

    function handleAddFilter(id, default_value = null, update_view) {
        const index = active_filters.findIndex(f => f.id === id);
        if (index === -1) {
            const new_filters = [...active_filters, {
                id: id,
                value: default_value
            }];
            setActiveFilters(new_filters);
            if (update_view) {
                handleUpdateViewField(active_collection.id, active_view.id, 'filters', new_filters);
            }
            setChangesMade(true);
        }
    }

    function handleUpdateViewField(cid, vid, field, value) {
        const updated_vs = [...view_stacks.map(vs => {
            if (vs.id === cid) {
                return {
                    ...vs,
                    stack: vs.stack.map(v => {
                        if (v.id === vid) {
                            return {
                                ...v,
                                [field]: value
                            }
                        } else {
                            return v;
                        }
                    })
                }
            } else {
                return vs;
            }
        })]
        setViewStacks(updated_vs);
    }

    function handleAddView(name, existing_view) {
        if (!name) {
            console.error("no name")
            return;
        }
        let new_view = {
            id: "id" + Math.random().toString(16).slice(2),
            label: name
        }

        let update = {
            ...active_collection,
            stack: [
                ...active_collection.stack,
                new_view
            ]
        }

        const new_view_stacks = view_stacks.map(vs => {
            if (vs.id === active_collection.id) {
                return update;
            } else {
                return vs;
            }
        });

        setActiveCollection(update);
        setActiveView(new_view);
        handleUpdateViewStacks(new_view_stacks);
        setChangesMade(true);
    }

    function handleDeleteView() {
        let update = {
            ...active_collection,
            stack: active_collection.stack.filter(v => v.id !== active_view.id)
        }

        const new_view_stacks = view_stacks.map(vs => {
            if (vs.id === active_collection.id) {
                return update;
            } else {
                return vs;
            }
        });

        setActiveCollection(update);
        setActiveView(update.stack[0]);
        handleUpdateViewStacks(new_view_stacks);

        setChangesMade(true);
    }

    function handleReorderViews(new_order) {
        let update = {
            ...active_collection,
            stack: new_order
        }

        const new_view_stacks = view_stacks.map(vs => {
            if (vs.id === active_collection.id) {
                return update;
            } else {
                return vs;
            }
        });

        setActiveCollection(update);
        handleUpdateViewStacks(new_view_stacks);
        setChangesMade(true);
    }

    function handleSetSort({field, direction}) {
        setSort({
            field: field,
            direction: direction
        });
        setChangesMade(true);
    }

    function handleSetView(view_id) {
        const view_stack_found = view_stacks.filter(vs => vs.id === active_collection.id)[0];
        if (!view_stack_found) {
            console.error("no view stack found")
            return;
        }
        const view_data = view_stack_found.stack.filter(v => v.id === view_id)[0];

        if (!view_data) {
            console.error("no view data")
            return;
        }
        if (view_data.group_by) {
            setActiveGroupBy(view_data.group_by);
        } else {
            setActiveGroupBy("");
        }
        if (view_data.filters) {
            setActiveFilters([...view_data.filters]);
        } else {
            setActiveFilters([]);
        }
        if (view_data.sort) {
            setSort(view_data.sort);
        } else {
            setSort(null);
        }

        setActiveView(view_data);
    }

    function handleUpdateViewStacks(vs) {
        setViewStacks([...vs])
    }

    function fetchFilterData() {
        getAllGroupsData(community_uid)
            .then((gd) => {
                setAllGroups([...gd])
            })
    }

    function savePreferencesToLocalStorage() {

        if (active_collection && active_view && !changes_made) {
            const prefs = {
                active_collection,
                active_view,
                active_filters,
                active_group_by,
                sort
            }
            // console.log("LOCAL STORAGE SET ITEM", community_uid, page_key, prefs)
            localStorage.setItem(`${community_uid}-${page_key}_directory`, JSON.stringify(prefs));
        }

    }

    const is_ready = ready && active_collection && active_view;

    const save_changes = is_ready && changes_made ?
        <Button onClick={handleSaveChanges} text="Save Changes" size="small" intent="minimal-active"/> : null;

    let cm_options = [];

    if (active_view && active_view.id !== "default") {
        cm_options.push({
            label: `Delete View "${active_view.label}"`,
            onClick: () => {
                let c = window.confirm("Are you sure you want to delete this view?");
                if (c === true) {
                    handleDeleteView();
                }
            }
        });
    }

    const props = {
        active_filters,
        active_view,
        active_collection,
        active_group_by,
        sort,
        query,
        filter_data: {
            all_groups
        }
    };

    let spec = buildSpec(view_stacks, props);

    spec.customize_props = {
        active_collection,
        active_view,
        cm_options,
        active_group_by,
        block_editor,
        features: spec.features,
        setActiveGroupBy,
        handleReorderViews,
        handleUpdateViewField,
        setActiveView,
        setChangesMade
    };


    if (error) {
        return <div>
            <p>There was an error loading this page: {error}</p>
        </div>
    }

    if (spec === undefined) {
        return <div className="flex justify-center">
            <p>There was an error loading this page: no spec</p>
        </div>
    }

    const ready_to_render = is_ready && data && spec;

    const filtered_items = filterItems(data || [], props, spec);

    const sections = buildSections(filtered_items, props, spec);

    const filtered_sections = filterSections(sections, props, spec);

    //  console.log("ACTIVE", {sort, active_view, active_filters});

    return <>
        {!hide_toolbar &&
            <SharedDirectoryHeader handleAddFilter={handleAddFilter} handleChangeFilter={handleChangeFilter}
                                   handleSetView={handleSetView} handleAddView={handleAddView}
                                   handleSetSort={handleSetSort} active_sort={sort}
                                   handleSelectCollection={handleSelectCollection}
                                   save_changes={save_changes} active_view={active_view} ready={is_ready}
                                   active_query={query}
                                   active_collection={active_collection} loading={loading}
                                   active_filters={active_filters} {...spec}
                                   handleApplyChanges={() => {
                                       handleUpdateParent();
                                   }}
                                   default_title={"Leaders"}
                                   handleQueryChange={nq => {
                                       setQuery(nq);
                                       console.log("UPDATE PARENT QUERY", nq)
                                       handleUpdateParent('query', nq);
                                   }}/>}
        {ready_to_render && enable_renderer && renderer(filtered_sections, props)}
        {enable_renderer && !ready_to_render && <div className="flex justify-center">
            <InlineLoader padding={"p-12"}/>
        </div>}
    </>
}