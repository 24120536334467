import {formatAddress} from "../utilities/address";
import {formatBirthday} from "../utilities/birthday";
import {data_autocomplete_map} from "./autocomplete-map";
import {standard_formatPhoneNumberValue} from "../utilities/phone";
import {buildImageUrl} from "../utilities/images";

function parseDomainName(url) {
    let hostname;

    if (!url) {
        return "";
    }
    //find & remove protocol (http, ftp, etc.) and get hostname
    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
    } else {
        hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];

    //find & remove "?"
    hostname = hostname.split('?')[0];

    // also remove any www. prefix
    hostname = hostname.replace("www.", "");

    return hostname;
}

function getRelativeTime(timestamp) {
    // rules:
    // 0-1 minute: "Xs ago"
    // 1-60 minutes: "Xm ago"
    // 1-13 hours: "Xh ago"

    // 13-24 hours: "HH:MM AM/PM"
    // 1-6 days: "MMM DD",

    // 7+ days: "MMM DD, YYYY"

    switch (true) {
        case timestamp < 60:
            return `${timestamp}s ago`;
        case timestamp < 3600:
            return `${Math.round(timestamp / 60)}m ago`;
        case timestamp < 46800:
            return `${Math.round(timestamp / 3600)}h ago`;
        case timestamp < 86400:
            return new Intl.DateTimeFormat('en-US', {timeStyle: 'short'}).format(new Date(timestamp));
        case timestamp < 518400:
            return new Intl.DateTimeFormat('en-US', {dateStyle: 'short'}).format(new Date(timestamp));
        default:
            return new Intl.DateTimeFormat('en-US', {dateStyle: 'medium'}).format(new Date(timestamp));

    }
}

export const all_field_types = {
    'text': {
        label: "Text",
        format: "string",
        isValid: (v, meta) => {
            let a = typeof v === "string";

            if (meta?.type) {
                if (meta?.type === "given-name") {
                    return a && v.length > 0;
                } else if (meta?.type === "family-name") {
                    return a && v.length > 0;
                }
            }

            return a;
        },
        getProcessedValue: (v, meta) => {
            return v?.trim();
        },
        formatValue: (v) => {
            if (!v) {
                return "";
            }
            return v.replace(/\\n/g, "\n");
        },
        getEmptyValue: () => {
            return "";
        }
    },
    'long-text': {
        label: "Long Text",
        format: "string",
        isValid: (v) => {
            return !!v && typeof v === "string";
        },
        getProcessedValue: (v, meta) => {
            return v?.trim();
        },
        formatValue: (v) => {
            if (!v || typeof v !== "string") {
                return "";
            }
            return v.replace(/\\n/g, "\n");
        },
        getEmptyValue: () => {
            return "";
        }
    },
    'currency': {
        label: "Currency",
        format: "number",
        isValid: (v) => {
            return !!v && typeof v === "number";
        },
        getProcessedValue: (v, meta) => {
            return v;
        },
        formatValue: (v, data, options) => {
            if (!v) {
                return "";
            }
            return new Intl.NumberFormat(options?.locale || 'en-US', {
                style: 'currency',
                currency: options?.country || 'USD'
            }).format(v);
        },
        getEmptyValue: () => {
            return "";
        }
    },
    'email': {
        label: "Email",
        format: "string",
        isValid: (v) => {
            return !!v && typeof v === "string";
        },
        getProcessedValue: (v, meta) => {
            return v?.trim();
        },
        formatValue: (v) => {
            return v;
        },
        getEmptyValue: () => {
            return "";
        }
    },
    'phone': {
        label: "Phone",
        format: "string",
        isValid: (v) => {
            return !!v && typeof v === "string";
        },
        getProcessedValue: (v, meta) => {
            return v?.trim();
        },
        formatValue: (v) => {
            if (!v) {
                return "";
            }
            return standard_formatPhoneNumberValue(v, "", false);
        },
        getEmptyValue: () => {
            return "";
        }
    },
    'number': {
        label: "Number",
        format: "number",
        isValid: (v) => {
            return !!v && typeof v === "number";
        },
        getProcessedValue: (v, meta) => {
            return v;
        },
        formatValue: (v) => {
            return v;
        },
        getEmptyValue: () => {
            return "";
        }
    },
    'toggle': {
        label: "Boolean",
        format: "boolean",
        formatValue: (v) => {
            return v;
        },
        getProcessedValue: (v, meta) => {
            return Boolean(v);
        },
        getEmptyValue: () => {
            return "";
        }
    },
    'date': {
        label: "Date",
        format: "date",
        formatValue: (v) => {
            if (!v) {
                return "";
            }

            if (isNaN(new Date(v).getTime())) {
                return "";
            }

            return new Intl.DateTimeFormat('en-US', {dateStyle: 'short'}).format(new Date(v));
        },
        getProcessedValue: (v, meta) => {
            return v;
        },
        getEmptyValue: () => {
            return "";
        }
    },
    'datetime': {
        label: "Date Time",
        format: "datetime",
        formatValue: (v, opts) => {
            if (!v) {
                return "";
            }

            if (isNaN(new Date(v).getTime())) {
                return "";
            }

            if (opts?.format === "relative") {
                return getRelativeTime(new Date(v).getTime())
            }

            return new Intl.DateTimeFormat('en-US', {dateStyle: 'short', timeStyle: 'short'}).format(new Date(v));
        },

        getProcessedValue: (v, meta) => {
            return v;
        },
        getEmptyValue: () => {
            return "";
        }
    },
    'time': {
        label: "Time",
        format: "time",
        formatValue: (v) => {
            if (!v) {
                return "";
            }

            if (isNaN(new Date(v).getTime())) {
                return "";
            }

            return new Intl.DateTimeFormat('en-US', {timeStyle: 'short'}).format(new Date(v));
        },

        getProcessedValue: (v, meta) => {
            return v;
        },
        getEmptyValue: () => {
            return "";
        }
    },
    'date-object': {
        label: "Date Object",
        format: "date-object",
        isValid: (v) => {
            if (!v || typeof v !== "object") {
                return false;
            }
            // if key exists
            return !!formatBirthday(v, !v.hide_year, "");
        },
        formatValue: (v) => {
            if (!v) {
                return "";
            }
            // if it's not a valid date, return empty string
            return formatBirthday(v, !v.hide_year, "");
        },

        getProcessedValue: (v, meta) => {
            return v;
        },
        getEmptyValue: () => {
            return {
                date: null,
                month: null,
                year: null
            };
        }
    },
    'address': {
        label: "Address",
        format: "address",
        isValid: (v) => {
            if (!v || typeof v !== "object") {
                return false;
            }
            return !!formatAddress(v);
        },
        formatValue: (v) => {
            if (!v) {
                return "";
            }
            return formatAddress(v);
        },

        getProcessedValue: (v, meta) => {
            return v;
        },
        getEmptyValue: () => {
            return {
                line_one: "",
                line_two: "",
                city: "",
                state: "",
                zip_code: "",
                country: ""
            };
        }
    },
    'select': {
        label: "Select",
        format: "string",
        isValid: (v, data, options) => {
            // when is a select valid? country should only be one of the array. so we have to see if valid
            if (!v) {
                return false;
            }
            if (!!v && options?.autocomplete) {
                let it;
                if (options?.autocomplete === "value_options") {
                    it = options?.value_options?.find(item => item.value === v);
                    if(!it) {
                        // if it's not valid, check if label matches exactly
                        it = options?.value_options?.find(item => item.label === v);
                    }
                } else {
                    it = data_autocomplete_map?.[options?.autocomplete]?.data?.find(item => item.value === v);
                    if(!it) {
                        // if it's not valid, check if label matches exactly
                        it = data_autocomplete_map?.[options?.autocomplete]?.data?.find(item => item.label === v);
                    }
                }

                return !!it;
            }
            return false;
        },
        getProcessedValue: (v, a, options) => {
            if (!v) {
                return "";
            }
            if (!!v && options?.autocomplete) {
                let it;
                if (options?.autocomplete === "value_options") {
                    it = options?.value_options?.find(item => item.value === v);
                    if(!it) {
                        // if it's not valid, check if label matches exactly
                        it = options?.value_options?.find(item => item.label === v);
                    }
                } else {
                    it = data_autocomplete_map?.[options?.autocomplete]?.data?.find(item => item.value === v);
                    if(!it) {
                        // if it's not valid, check if label matches exactly
                        it = data_autocomplete_map?.[options?.autocomplete]?.data?.find(item => item.label === v);
                    }
                }

                return it?.value || "";
            }
            return "";
        },
        formatValue: (v, data, options) => {
            if (!v) {
                return "";
            }
            if (!!v && options?.autocomplete) {
                let it;
                if (options?.autocomplete === "value_options") {
                    it = options?.value_options?.find(item => item.value === v);
                } else {
                    it = data_autocomplete_map?.[options?.autocomplete]?.data?.find(item => item.value === v);
                }
                return it.label || "";
            }
            return v;
        },
        getEmptyValue: () => {
            return "";
        }
    },
    'url': {
        label: "URL",
        format: "string",
        isValid: (v) => {
            return v && v.startsWith("http");
        },
        formatValue: (v) => {
            return parseDomainName(v);
        },
        getProcessedValue: (v, meta) => {
            return v;
        },
        getEmptyValue: () => {
            return "";
        }
    },
    'image-url': {
        label: "Image URL",
        format: "string",
        formatValue: (v, opts) => {
            return buildImageUrl(v, opts?.default_size || "medium");
        },
        getProcessedValue: (v, meta) => {
            return v;
        },
        getEmptyValue: () => {
            return "";
        }
    },
    'date-frame-object': {
        label: "Date Frame Object",
        format: "date-frame-object",
        formatValue: (v) => {
            if (!v) {
                return "";
            }

            return v;
        },
        getProcessedValue: (v, meta) => {
            return v;
        },
        getEmptyValue: () => {
            // todo
            return null;
        }
    },
    'multi-select': {
        label: "Multi Select",
        format: "array",
        formatValue: (v) => {
            return v;
        },
        getProcessedValue: (v, meta) => {
            return v;
        },
        getEmptyValue: () => {
            return [];
        }
    },
    'map-id': {
        label: "Map ID",
        format: "map-id",
        formatValue: (v) => {
            return v;
        },
        getProcessedValue: (v, meta) => {
            return v;
        },
        getEmptyValue: () => {
            return "";
        }
    },
    'interests': {
        label: "Interests",
        format: "interests",
        formatValue: (v) => {
            return "TODO";
        },
        getProcessedValue: (v, meta) => {
            return v;
        },
        getEmptyValue: () => {
            return {};
        }
    },
    'roles': {
        label: "Positions",
        format: "map-id",
        formatValue: (v) => {
            return "TODO";
        },
        getProcessedValue: (v, meta) => {
            return v;
        },
        getEmptyValue: () => {
            return {};
        }
    },
    'groups': {
        label: "Spaces",
        format: "map-id",
        formatValue: (v) => {
            return "TODO";
        },
        getProcessedValue: (v, meta) => {
            return v;
        },
        getEmptyValue: () => {
            return {};
        }
    }
};