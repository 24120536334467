import React, {useState,useContext} from 'react';
import Modal from "../../../../m3/_legacy_components/modal";
import Button from "../../../../m3/_legacy_components/button";
import {CUSTOM_NANOID} from "../education/add";
import {AddCareerStep} from "./add-career-step";
import {ModalBody, ModalFooter, ModalHeader} from "../../../../m3/_legacy_components/preview-overlay";
import {authFetch} from "../../../../../api/network";
import {useCommunity} from "../../../../config/community";
import {validateCareer} from "./member-career";

const mock = {
    org_data: {
        community_uid: '',
        title: '',
        image: '',
        handle: ''
    },

    title: '', // Bachelor of Science (B.Sc.)

    current: true, // BWL
    location: '',

    from_year: null,
    from_month: null,
    to_month: null,
    to_year: null,

    grade: '', // 1.0

    description: ''
};

function shouldDisable(d) {
    if(d.title.length<2) {
        return true;
    }
    return false;
}

export function AddCareerModal({onClose,data}) {
    const community = useCommunity();
    const [position,setPosition] = useState(mock);
    const [loading,setLoading] = useState(null);

    function addCareer(ed, id) {
        setLoading('add');
        const payload = {
            position: validateCareer(ed),
            community_uid: community.uid,
            user_uid: community.member.user_uid,
            position_id: id
        };

        const res = async (r) => {
            setTimeout(() => {
                setLoading(null);
                onClose();
            }, 1500);
        };

        authFetch('/members/career-add', res, res, "POST", {payload});
    }

    function handleUpdatePosition(f,v) {
        let ed = Object.assign({},position);
        if(f==='range') {
            const final = {...ed,...v};
            setPosition({...final})
        } else if(f==='org_data') {
            ed[f]['title'] = v;
            setPosition({...ed});
        } else {
            ed[f] = v;
            setPosition(ed);
        }
    }

    const disabled = shouldDisable(position);

    return <div className="" style={{minHeight: '16rem'}}>
        <ModalHeader title={"Add Experience"} onClose={onClose}/>
        <ModalBody>
            <AddCareerStep position={position} updatePosition={handleUpdatePosition.bind(this)} />
        </ModalBody>
        <ModalFooter right={
            <Button intent='secondary' loading={loading} disabled={disabled} onClick={() => {
                const id = CUSTOM_NANOID();
                addCareer(position,id);
            }} text='Add'/>
        } left={null}/>
    </div>
}