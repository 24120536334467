import React, {useEffect, useRef} from "react"
import './styles.css';
import {IconAction, TextAction} from "../../../routes/auth/sign-in";
import {CheckCircleIcon, CheckIcon, ExclamationCircleIcon, XMarkIcon} from "@heroicons/react/20/solid";
import {FieldInput} from "../form/field";
import SimpleTooltip from "../tooltip";

// props and context mapping.. :D
const actions = [
    {
        label: "Add to folder",
        value: "add-to-folder",
        value_type: "folder"
    }
];

const operators = [
    {
        label: "Contains",
        value: "contains"
    },
    {
        label: "Regex",
        value: "regex"
    }
];

const sample_set = [
    {
        id: 1,
        name: "Add attachments starting with 'The' to specific folder",
        context: 'email-attachment',
        apply_to: 'file',

        field: 'name',
        operator: 'regex',
        value: "^The",


        action: "add-to-folder",
        action_value: "",

        valid: false,
        error: ""
    }
];

const contextual_validations = {
    'email-attachment': {
        fn: (rule) => {
            let error = "";

            if (!rule.action_value) {
                error = "Please select a folder";
            }

            if(!rule.value) {
                error = "Please enter a value";
            }

            if(!rule.name) {
                error = "Please enter a name";
            }

            return {
                valid: !error,
                error
            }
        }
    }
}

const gridCols = `28% 35% 25% 1fr`;

const fields = [
    {
        label: "Name",
        value: "name"
    }
];

function RuleFieldOperator({field, operator, onChange}) {
    return <div className="flex gap-1">
        <select value={field} onChange={(e) => {
            onChange('field', e.target.value);
        }} className="input-focus h-8 rounded-md pl-2 pr-1">
            {fields.map(opt => {
                return <option key={opt.value} value={opt.value}>{opt.label}</option>
            })}
        </select>
        <select value={operator} onChange={(e) => {
            onChange('operator', e.target.value);
        }} className="input-focus h-8 rounded-md pl-2 pr-1">
            {operators.map(opt => {
                return <option key={opt.value} value={opt.value}>{opt.label}</option>
            })}
        </select>
    </div>
}

export default function SmartRulesActionsManager({context = {}, onChange, rules_context='email-attachment', init_rules = [], max_rules = 6}) {
    const [rules, setRules] = React.useState([...init_rules]);
    const isFirstRun = useRef(true);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        onChange(getValidatedRules(rules));
    }, [rules]);

    function getValidatedRules(rr=[]) {
        return rr.map(rule => {
            return {
                ...rule,
                ...contextual_validations[rules_context].fn(rule)
            }
        });
    }

    function renderActionValue(rule) {
        const {action} = rule;
        const action_data = actions.find(a => a.value === action);

        if (!action_data) {
            console.error("Invalid action value", action);
            return null;
        }

        if (action_data.value_type === 'folder') {
            if (!context.drive_id) {
                return <div>
                    no drive selected
                </div>
            }
            return <div>
                <FieldInput onChange={(id, v) => {
                    rule.action_value = v;
                    setRules([...rules]);
                }} value={rule.action_value} input_props={{
                    drive_id: context.drive_id
                }} type={"drive-folder"}/>
            </div>
        } else {
            return <div>
                unsupported
            </div>
        }
    }

    function newRule() {
        let rule = {
            id: Math.random(),
            name: "",
            context: rules_context,
            apply_to: 'file',

            field: 'name',
            operator: 'regex',
            value: "",


            action: "add-to-folder",
            action_value: ""
        };

        let validation = contextual_validations[rules_context].fn(rule);

        setRules([...rules, {
            ...rule,
            ...validation
        }]);
    }

    return <div>

        <div>
            <div className="grid gap-2 text-xs font-medium text-gray-600 pb-1 border-b border-gray-200"
                 style={{gridTemplateColumns: gridCols}}>
                <div>Name</div>
                <div>Rule</div>
                <div>Action</div>
                <div></div>
            </div>
            {rules.map((rule,index, i) => {
              const validation = contextual_validations[rules_context].fn(rule);
                return <div className="grid text-sm gap-2 py-2 border-b border-gray-200" key={rule.id}
                            style={{gridTemplateColumns: gridCols}}>
                    <div className="flex gap-1">
                        <div className="pt-1.5">
                            <SimpleTooltip placement="top" text={`Priority ${index+1} Rule`}>
                            <div className="bg-gray-100 w-5 flex items-center justify-center text-sm rounded-lg text-gray-600">
                                {index+1}
                            </div>
                            </SimpleTooltip>
                        </div>
                        <input placeholder="Rule Name" onChange={e => {
                            rule.name = e.target.value;
                            setRules([...rules]);
                        }} value={rule.name} className={"input-focus h-8 px-2 w-full rounded-md"}/>
                    </div>
                    <div className="flex flex-col gap-1">
                        <RuleFieldOperator onChange={(id, va) => {
                            rule[id] = va;
                            setRules([...rules]);
                        }} field={rule.field} operator={rule.operator}/>
                        <div>
                        <input placeholder={`"^The" for starts with The`} onChange={e => {
                            rule.value = e.target.value;
                            setRules([...rules]);
                        }} value={rule.value} className={"input-focus h-8 px-2 w-full rounded-md"}/>
                        </div>
                    </div>
                    <div className="flex flex-col gap-1">
                        <div>
                        <select value={rule.action} onChange={(e) => {
                            rule.action = e.target.value;
                            setRules([...rules]);
                        }} className="input-focus h-8 rounded-md pl-2 pr-1">
                            {actions.map(opt => {
                                return <option key={opt.value} value={opt.value}>{opt.label}</option>
                            })}
                        </select>
                        </div>
                        <div>
                        {renderActionValue(rule)}
                        </div>
                    </div>
                    <div className="gap-2 flex">
                        <IconAction onClick={() => {
                            window.confirm("Are you sure you want to delete this rule?") && setRules(rules.filter((r) => r.id !== rule.id));
                        }}>
                            <XMarkIcon/>
                        </IconAction>
                        {!validation.valid && <SimpleTooltip placement="top" text={validation.error||"Not sure.."} usePortal><IconAction>
                            <span className="text-red-600">
                                 <ExclamationCircleIcon/>
                            </span>
                        </IconAction></SimpleTooltip>}
                        {validation.valid && <IconAction>
                            <span className="text-green-500">
                                 <CheckCircleIcon/>
                            </span>
                        </IconAction>}

                    </div>
                </div>
            })}
        </div>

        <div className="pt-2">
            {(rules.length < max_rules) && <TextAction text="Add Rule" onClick={() => {
                newRule();
            }}/>}
        </div>

    </div>
};