import React, {useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {PlusIcon} from "@heroicons/react/24/solid";
import Field from "../../../m3/_legacy_components/form/field";
import SimpleTooltip from "../../../m3/_legacy_components/tooltip";
import {validateEmail} from "../../../../common/utilities/lang-region";
import {db} from "../../../config/setup-firestore";
import {useCommunity} from "../../../config/community";
import Button from "../../../m3/_legacy_components/button";
import {collection, where, limit, orderBy, query, getDocs} from "firebase/firestore";
import {CheckCircleIcon,ExclamationCircleIcon} from "@heroicons/react/20/solid";
import {leaders_placeholders, leaders_placeholders_len} from "../../../../common/data/leaders-placeholders";
import {utils_strings_isEmail} from "../../../../common/utilities/strings";

const RowGrid = styled.div`

  > div {
    margin-top: 0 !important;
  }
`;

function getPlaceholder(num) {
    const index = num % leaders_placeholders_len;
    return leaders_placeholders[index];
}

function getWarnings({first_name, last_name, account_email}, all_checks) {

    if (account_email && all_checks[account_email] && all_checks[account_email].status === 'potential-duplicate') {
        return {
            type: 'warning',
            message: "Member already exists with this email",
        }
    }

    if (first_name && last_name && all_checks[`${first_name} ${last_name}`] && all_checks[`${first_name} ${last_name}`].status === 'potential-duplicate') {
        return {
            type: 'warning',
            message: "There's an existing member with this name",
        }
    }

    return null;
}

const MemberRow = ({
                       first_name,
                       id,
                       all_checks,
                       show_member_type = true,
                       last_name,
                       member_type,
                       valid,
                       is_mobile,
                       num,
                       first = false,
                       onBlur,
                       account_email,
                       onChange
                   }) => {
    const community = useCommunity();
    const pd = getPlaceholder(num);
    const grid_cols = show_member_type ? `grid-cols-2 md:grid-cols-10` : `grid-cols-2 md:grid-cols-8`;
    const warning = getWarnings({first_name, last_name, account_email}, all_checks);

    return <RowGrid className={`mb-2 gap-3 grid ${grid_cols}`} show_member_type={show_member_type} first={first}>
        {show_member_type && !is_mobile && <div className="col-span-2">
            <Field input_props={{
                options: community.member_types.map(mt => {
                    return {
                        text: mt.singular,
                        value: mt.id
                    }
                }),
                classic: true
            }} type={"select"} placeholder={'Membership Level'} onBlur={() => {
                onBlur('member_type');
            }} label={first && 'Type'} onChange={(c,v) => {
                onChange(id, 'member_type', v, () => onBlur('member_type'))
            }} value={member_type}/>
        </div>}
        <div className="col-span-2 md:col-span-3">
            <Field success={valid} type={"email"} placeholder={pd.email} onBlur={() => {
                onBlur('email');
            }} autoFocus={first} label={first && 'Email'} onChange={(c,v) => {
                onChange(id, 'account_email', v)
            }} value={account_email}/>
        </div>
        <div className="md:col-span-2">
            <Field success={valid} type={"text"} placeholder={pd.first_name} onBlur={() => {
                onBlur('first_name');
            }} label={first && 'First Name'} onChange={(c,v) => {
                onChange(id, 'first_name', v)
            }} value={first_name}/>
        </div>
        <div className="md:col-span-2">
            <Field success={valid} type={"text"} placeholder={pd.last_name} onBlur={() => {
                onBlur('last_name');
            }} label={first && 'Last Name'} onChange={(c,v) => {
                onChange(id, 'last_name', v)
            }} value={last_name}/>
        </div>
        <div className="flex items-end pb-2.5">
            {warning &&
            <SimpleTooltip content_props={{style: {minWidth: '12rem', textAlign: 'center'}}} usePortal={false}
                           text={warning.message}>
                <div className="w-5 h-5 h-svg-5 text-yellow-600">
                    <ExclamationCircleIcon/>
                </div>
            </SimpleTooltip>}
            {!warning && valid && <div className="h-5 w-5">
                <div className="h-svg-5 text-green-700">
                    <CheckCircleIcon/>
                </div>
            </div>}
        </div>
    </RowGrid>
};

function getFinalMembers(members) {
    let arr = [];

    for (let i = 0; i < members.length; i++) {
        const member = members[i];
        if (utils_strings_isEmail(member.account_email) || member.first_name.length > 2) {

            arr.push({
                first_name: member.first_name,
                last_name: member.last_name,
                member_type: member.member_type,
                account_email: member.account_email,
                profile: {
                    member_type: member.member_type,
                    about: {
                        title: '',
                        location: '',
                        bio: '',
                        gender: '',
                        middle_name: '',
                        first_name: member.first_name,
                        last_name: member.last_name
                    }
                }
            })
        }
    }

    return arr;
}

function memberIsValid({first_name, account_email}) {
    return first_name.length > 2 || utils_strings_isEmail(account_email);
}

function normalizeName(input) {
    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
}

function getBlankMember(dmt) {
    return {
        first_name_touched: false,
        last_name_touched: false,
        email_touched: false,
        member_type_touched: false,
        first_name: '',
        last_name: '',
        member_type: dmt,
        account_email: '',
        email_status: "unknown",
        valid: false
    }
}

function generateInits(rows, dmt, init_members_list) {
    let init_members = [], init_data = {};

    if (init_members_list && init_members_list.length) {
        init_members_list.forEach(mi => {
            const id = "fjksndfkjndsf";
            init_members.push(id);
            init_data[id] = {
                first_name_touched: false,
                last_name_touched: false,
                email_touched: false,
                member_type_touched: false,
                first_name: mi.first_name ? mi.first_name : "",
                last_name: mi.last_name ? mi.last_name : "",
                member_type: mi.member_type ? mi.member_type : dmt,
                account_email: mi.account_email ? mi.account_email : "",
                email_status: "unknown",
                valid: false
            };
        })
    } else {
        for (let i = 0; i < rows; i++) {
            const id = "fnaosdinfoisndf";
            init_members.push(id);
            init_data[id] = getBlankMember(dmt);
        }
    }

    return {init_members, init_data};
}

async function getMemberByFullName(qid, cid, first_name = "", last_name = "") {
    const ref = collection(db, 'community_members', cid, 'members');
    const q = query(ref, where('about.first_name', '==', first_name), where('about.last_name', '==', last_name), limit(1));
    const snap = await getDocs(q);

    const d = snap.empty ? null : snap.docs[0].data();

    return {
        [qid]: d
    };
}

async function getMemberByAccountEmail(qid, cid, account_email = "") {
    const ref = collection(db, 'community_members', cid, 'members');
    const q = query(ref, where('account_email', '==', account_email), limit(1));
    const snap = await getDocs(q);

    const d = snap.empty ? null : snap.docs[0].data();

    return {
        [qid]: d
    };
}

async function getMembersForChecks(entries, cid) {

    let promises = [];

    entries.forEach(([id, data]) => {
        if (data.type === 'full_name') {
            promises.push(getMemberByFullName(id, cid, data.first_name.trim(), data.last_name.trim()))
        } else {
            promises.push(getMemberByAccountEmail(id, cid, data.account_email.trim().toLowerCase()))
        }
    })

    return await Promise.all(promises).then(resp => {
        // need to return merged array
        return resp.reduce((result, current) => {
            return Object.assign(result, current);
        }, {})
    });
}

function buildMemberChecks(data) {
    let items_to_check = {};

    const members = Object.entries(data)

    members.forEach(([id, row]) => {
        if (row.first_name && row.last_name) {
            const full_name = `${row.first_name} ${row.last_name}`;
            items_to_check[full_name] = {
                type: 'full_name',
                first_name: row.first_name,
                last_name: row.last_name,
                status: 'unknown'
            };
        }

        if (row.account_email && utils_strings_isEmail(row.account_email)) {
            items_to_check[row.account_email] = {
                type: 'account_email',
                account_email: row.account_email,
                status: 'unknown'
            };
        }
    })

    return items_to_check;
}

export function CreateMembersForm({
                                      max_rows = -1,
                                      default_member_type = '',
                                      show_member_type = false,
                                      start_rows = 1,
                                      init_members_list,
                                      setNewMembers = () => {
                                      },
                                      show_add_member = false
                                  }) {
    const community = useCommunity();
    const {init_members, init_data} = generateInits(start_rows, default_member_type, init_members_list);
    const [members, setMembers] = useState(init_members);
    const [all_checks, updateAllChecks] = useState({});
    const all_checks_ref = useRef({});
    const [data, setData] = useState(init_data);

    const member_checks = buildMemberChecks(data);

    useEffect(function () {
        handleBlur('email')
    }, [init_members_list])

    useEffect(function () {
        all_checks_ref.current = all_checks;
        const not_yet_started = Object.entries(all_checks).filter(a => {
            return a[1].status === 'unknown';
        })
        if (not_yet_started.length > 0) {
            startChecks(not_yet_started)
        }
    }, [all_checks])

    function startChecks(not_yet_started) {
        let new_all_checks = {...all_checks_ref.current};

        not_yet_started.filter(([id, dt]) => {
            new_all_checks[id].status = 'checking';
        })

        updateAllChecks(new_all_checks);

        startCheckProcess(not_yet_started);
    }

    function startCheckProcess(nys) {
        getMembersForChecks(nys, community.uid)
            .then(result => {
                let nac = {...all_checks_ref.current};

                const results = Object.entries(result);

                results.forEach(([qid, dt]) => {
                    nac[qid] = {
                        ...nac[qid],
                        status: dt ? "potential-duplicate" : "ok",
                        dt: dt
                    }
                });

                updateAllChecks({
                    ...nac
                })
            })
    }

    function updateChecks() {
        updateAllChecks({
            ...member_checks,
            ...all_checks
        })
    }

    function handleBlur(field_id) {
        let nd = {...data};
        const entries = Object.entries(data);
        for (let i = 0; i < entries.length; i++) {
            const [id, member] = entries[i];

            // handle set the members name from the email
            if (field_id === 'email' && !member.first_name_touched && !member.last_name_touched && member.account_email.length > 4 && member.account_email.includes('@')) {
                const split = member.account_email.split('@')[0];
                if (split.includes('.')) {
                    const split1 = split.split('.');

                    const fname = normalizeName(split1[0]);
                    const lname = normalizeName(split1[1]);

                    nd[id].first_name = fname;
                    nd[id].last_name = lname;
                }
            }

            nd[id].valid = memberIsValid(member);
        }
        const fnm = getFinalMembers(Object.values(nd));
        setNewMembers(fnm);
        setData(nd);

        setTimeout(() => {
            updateChecks();
        }, 50)
    }

    function handleAddMember() {
        const new_id = Math.random().toString(36).substr(2, 9);
        setMembers([...members, new_id]);
        let nd = {...data};
        nd[new_id] = getBlankMember(default_member_type);
        setData(nd);
    }

    function handleDeleteMember(id) {
        let nm = [...members];
        const index = members.findIndex(a => a === id);
        nm.splice(index, 1);
        setMembers(nm);
        let nd = {...data};
        delete nd[id];
        setData(nd);
    }

    function handleChange(id, field, value, cb = () => {
    }) {
        let nd = {...data};
        nd[id][field] = value;
        nd[id][`${field}_touched`] = true;
        setData(nd);

        cb();
    }

    const valid_count = Object.values(data).filter(a => a.valid).length;

    const reached_max_rows = max_rows === -1 ? false : members.length > (max_rows - 1);

    return <div className="w-full">
        <div className="">
            {members.map((id, i) => {
                const member = data[id];
                return <MemberRow all_checks={all_checks} id={id} show_member_type={show_member_type} key={id}
                                  valid={member.valid} is_mobile={false}
                                  onBlur={handleBlur} num={i} first={i === 0} account_email={member.account_email}
                                  member_type={member.member_type} first_name={member.first_name}
                                  last_name={member.last_name} onChange={handleChange}/>
            })}
        </div>
        {show_add_member && !reached_max_rows && <div className="pt-1">
            <Button minimal text="Additional Member" left_icon={{icon: <PlusIcon/>}} onClick={() => handleAddMember()}/>
        </div>}
    </div>
}