import React from 'react';

/*
* This is the Emoji atom
 */

const _props = {

};

export default function M3_A_Emoji({
    className,
    emoji,
    sx
}) {

    let classes = "", style = {
        lineHeight: "100%",
        fontSize: ""
    };

    if (className) {
        classes += ` ${className}`;
    }

    if (sx) {
        style = {...sx};
    }

    return (
        <span className={classes} style={style}>
            {emoji}
        </span>
    );
}