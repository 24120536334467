import React, {useEffect, useRef, useState} from 'react';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import {DEFAULT_USER_PHOTO, useCommunity} from "../../../../config/community";
import {getImagesFromContentState} from "../../write";
import {DraftComposer} from "./draft-composer";
import {useToasts} from "../../../../config/toasts";
import {ArrowUpCircleIcon} from "@heroicons/react/24/solid";
import {Avatar} from "../../../../m3/_legacy_components/avatar";
import {InlineLoader} from "../../../../m3/_legacy_components/admin-activity";
import {XMarkIcon} from "@heroicons/react/20/solid";
import {useIsMobile} from "../../../../m3/hooks/is-mobile";
import {composer_handleSendReply} from "../../../../../api/messages/handle-send-reply";
import {buildImageUrl} from "../../../../../common/utilities/images";

export function WriteSendIconButton({disabled, loading, onClick}) {

    return <div onClick={onClick}
                className={`${disabled ? "text-gray-400" : loading ? "p-0.5 rounded-full" : "text-blue-600"} cursor-pointer h-7 w-7`}>
        {!loading ? <ArrowUpCircleIcon/> : <InlineLoader mini/>}
    </div>
}

function Wrapper({children, onClose, left, right, hide_avatar, right_classes = "items-center", onClick, community}) {
    const show_bar = left || right;
    return <div onClick={() => {
        if (onClick) {
            onClick();
        }
    }}
                className="cursor-pointer relative gap-2 flex flex-col rounded-lg px-4 py-3 rounded-none border-b border-gray-200">
        <div className="flex gap-2">
            {!hide_avatar && <div className="flex-none">
                <Avatar size="h-8 w-8"
                        url={buildImageUrl(community.member.profile_picture, '_medium', DEFAULT_USER_PHOTO)}/>
            </div>}
            <div className="flex-grow pr-8">
                {children}
            </div>
        </div>
        {show_bar && <div className="flex">
            <div className="flex-grow flex gap-2"></div>
            <div className={`flex flex-none gap-2 ${right_classes}`}>
                {right}
            </div>
        </div>}
        {!!onClose && <div onClick={onClose} className="absolute z-10 top-3 right-4">
            <div className="h-5 w-5 text-gray-500">
                <XMarkIcon/>
            </div>
        </div>}
    </div>
}

export function NewReply({
                             reply_to_name = "",
                             thread_id = "",
                             reply_to_id = "",
                             focus,
                             onClear = () => {
                             },
                             onSendReply = () => {
                             },
                             post_id = ""
                         }) {
    const is_mobile = useIsMobile();
    const [state, setState] = useState("prompt");
    const [loading, setLoading] = useState(null);
    const [attachments, setAttachments] = useState([]);
    const [images, setImages] = useState([]);
    const [editorState, setEditorState] = useState(null);
    const community = useCommunity();
    const toasts = useToasts();
    const focusCatch = useRef(false);

    const cs = editorState ? editorState.getCurrentContent() : {getPlainText: () => ""};

    const can_send = cs.getPlainText();

    useEffect(function () {
        if (focus && focusCatch.current === true) {
            focusCatch.current = false;
            return;
        }
        if (focus && state === 'prompt' && focusCatch.current === false) {
            setState('compose');
            focusCatch.current = true;
        }
    }, [focus, state])

    function handleSend() {

        const contentState = editorState.getCurrentContent();

        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            reply_id: reply_to_id,
            thread_id,
            reply_to_id,
            post_id,

            images: getImagesFromContentState(contentState),
            attachments: Object.entries(attachments).filter(a => a[1].status === 'done').map(ent => {
                return {
                    id: ent[1].id,
                    folder: ent[1].folder,
                    url: ent[1].url,
                    name: ent[1].name,
                    type: ent[1].type,
                    size: ent[1].size
                }
            }),
            raw: convertToRaw(contentState),
            plain_text: contentState.getPlainText()
        };

        toasts.addToast({text: "Sending reply...", intent: "info"});

        setLoading('sending');

        const res = (result) => {
            toasts.addToast({text: "Reply sent", intent: "success"});
            setState('prompt');
            setLoading('');
            clearEditorState();
            onSendReply(payload);
        };

        composer_handleSendReply(payload, {community}, res);
    }

    function handleCommandEnter() {
        if(!can_send) {
            toasts.addToast({
                text: "Please enter a reply",
                intent: "danger"
            })
        } else {
            handleSend();
        }
    }

    function clearEditorState() {
        // clean editorState
        setEditorState(EditorState.push(editorState, ContentState.createFromText('')));
    }

    const btn = <WriteSendIconButton loading={loading === 'sending'} onClick={handleSend} disabled={!can_send}/>;

    if (state === "prompt") {
        return <Wrapper community={community} onClick={() => {
            setState("compose")
        }}>
            <div className="bg-gray-100 h-8 flex px-2 items-center rounded-lg text-gray-500">
                Add a reply...
            </div>
        </Wrapper>
    }

    const closeFn = is_mobile ? () => {
        setState('prompt');
        clearEditorState();
        onClear();
    } : null;

    return <Wrapper onClose={closeFn} hide_avatar right_classes="items-end" community={community} right={btn}>
        {reply_to_name && <div className="text-sm pb-2 text-gray-700">
            <span>
                Replying to <span className="font-semibold">{reply_to_name}</span>
            </span>
        </div>}
        <div className="" style={{wordBreak: 'break-word'}}>
            <DraftComposer handleCommandEnter={handleCommandEnter} auto_focus show_toolbar={false} type="reply"
                           update={(es, att) => {
                               setEditorState(es);
                               setAttachments(att);
                           }}/>
        </div>
    </Wrapper>
}