import React, {useState, useCallback, useRef, useEffect, useContext, useMemo, Component} from 'react';
import {
    EditorState,
    Entity,
    SelectionState,
    AtomicBlockUtils,
    convertToRaw,
    DefaultDraftBlockRenderMap,
    RichUtils,
    convertFromRaw,
    convertFromHTML,
    ContentState,
    Modifier
} from "draft-js";
import {getSelectedBlocksList, getSelectionEntity, getSelectionText} from "draftjs-utils";
import Editor from "@draft-js-plugins/editor";
import "draft-js/dist/Draft.css"
import createMentionPlugin, {
    defaultSuggestionsFilter,
} from '@draft-js-plugins/mention';
import "./style-overrides.css";

import '@draft-js-plugins/mention/lib/plugin.css';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import '@draft-js-plugins/linkify/lib/plugin.css';

import styled from "styled-components";
import createInlineToolbarPlugin from "@draft-js-plugins/inline-toolbar";
import createDividerPlugin from '@draft-js-plugins/divider';
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {ChevronDownIcon, LinkIcon} from "@heroicons/react/20/solid";
import {DEFAULT_UNASSIGNED_ROLE, useCommunity} from "../../../config/community";
import {useToasts} from "../../../config/toasts";
import {sc_handleConvertToEm} from "../helpers/handle-convert-to-em";
import {sc_handleConvertToHeader} from "../helpers/handle-convert-to-header";
import {scEditorHandleAddLink} from "./handle-add-link";
import {AddLinkModal} from "./add-link-modal";
import {MentionEntry} from "./plugins/mention-entry";
import {es_handleReturn} from "../helpers/handleReturn";
import {scEditorKeyBindingFn} from "./key-bindings";
import {scEditorBlockRenderer} from "./block-renderer";
import {ScEditorContent} from "./editor";
import {sc_editor_style_map} from "./style-map";
import {sc_block_render_map} from "./block-render-map";
import {es_insertCustomBlock, es_insertMultipleBlocks} from "../helpers/insert-custom-block";
import {scPluginLinkify} from "./plugins/linkify";
import {scPluginLink} from "./plugins/anchor";
import {useIsMobile} from "../../../m3/hooks/is-mobile";
import {generateRandomKey} from "../helpers/add-single-block";
import SimpleTooltip from "../../../m3/_legacy_components/tooltip";
import {PopupContentWrapper} from "../../../m3/_legacy_components/entity-list";
import {ContentPopupMenu} from "../../../m3/_legacy_components/content-popup/components";
import {imageResizeRequest} from "../../../../api/image-resizer";

const dividerPlugin = createDividerPlugin();

const B = () => <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M15.6 10.79c.97-.67 1.65-1.77 1.65-2.79 0-2.26-1.75-4-4-4H7v14h7.04c2.09 0 3.71-1.7 3.71-3.79 0-1.52-.86-2.82-2.15-3.42zM10 6.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-3v-3zm3.5 9H10v-3h3.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z"/>
    <path d="M0 0h24v24H0z" fill="none"/>
</svg>;

const UL = () => <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M23.062,4.287 C23.58,4.287 24,4.707 24,5.225 C24,5.744 23.58,6.164 23.062,6.164 L9.536,6.164 C9.018,6.164 8.598,5.744 8.598,5.225 C8.598,4.707 9.018,4.287 9.536,4.287 L23.062,4.287 z M23.062,11.011 L9.536,11.011 C9.018,11.011 8.598,11.431 8.598,11.949 C8.598,12.467 9.018,12.888 9.536,12.888 L23.062,12.888 C23.58,12.888 24,12.467 24,11.949 C24,11.431 23.58,11.011 23.062,11.011 z M23.062,17.735 L9.536,17.735 C9.018,17.735 8.598,18.155 8.598,18.673 C8.598,19.191 9.018,19.611 9.536,19.611 L23.062,19.611 C23.58,19.611 24,19.191 24,18.673 C24,18.155 23.58,17.735 23.062,17.735 z M1.798,3.529 C0.805,3.529 0,4.334 0,5.327 C0,6.32 0.805,7.125 1.798,7.125 C2.791,7.125 3.596,6.32 3.596,5.327 C3.596,4.334 2.791,3.529 1.798,3.529 z M1.798,10.073 C0.805,10.073 0,10.878 0,11.871 C0,12.864 0.805,13.669 1.798,13.669 C2.791,13.669 3.596,12.864 3.596,11.871 C3.596,10.878 2.791,10.073 1.798,10.073 z M1.798,16.875 C0.805,16.875 0,17.68 0,18.673 C0,19.666 0.805,20.471 1.798,20.471 C2.791,20.471 3.596,19.666 3.596,18.673 C3.596,17.68 2.791,16.875 1.798,16.875 z"/>
</svg>;

const OL = () => <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M2.464,13.74 C3.899,13.74 4.866,14.553 4.866,15.757 C4.866,16.517 4.467,17.167 3.292,18.293 L1.935,19.588 L1.935,19.674 L4.972,19.674 L4.972,20.825 L0.077,20.825 L0.077,19.867 L2.339,17.629 C3.287,16.681 3.533,16.315 3.533,15.848 C3.533,15.246 3.095,14.852 2.421,14.852 C1.733,14.852 1.271,15.304 1.271,15.968 L1.271,16.002 L0,16.002 L0,15.973 C0,14.645 1.006,13.74 2.464,13.74 z M23.076,17.821 L9.753,17.821 C9.239,17.873 8.85,18.205 8.829,18.745 C8.881,19.26 9.213,19.649 9.753,19.67 L23.076,19.67 C23.586,19.67 24,19.256 24,18.745 C24,18.235 23.586,17.821 23.076,17.821 z M3.412,3.175 L2.074,3.175 L0.197,4.431 L0.197,5.721 L1.988,4.533 L2.074,4.533 L2.074,8.98 L0.202,8.98 L0.202,10.12 L5.188,10.12 L5.188,8.98 L3.412,8.98 L3.412,3.175 z M9.753,6.424 L23.076,6.424 C23.586,6.424 24,6.01 24,5.5 C24,4.99 23.586,4.576 23.076,4.576 L9.753,4.576 C9.239,4.628 8.85,4.96 8.829,5.5 C8.881,6.015 9.213,6.403 9.753,6.424 z M9.753,12.924 L23.076,12.924 C23.586,12.924 24,12.51 24,12 C24,11.49 23.586,11.076 23.076,11.076 L9.753,11.076 C9.239,11.128 8.85,11.46 8.829,12 C8.881,12.515 9.213,12.904 9.753,12.924 z"/>
</svg>;

const BQ = () => <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">

    <path
        d="M20.239,3.907 C20.789,3.907 21.235,4.352 21.235,4.902 C21.235,5.452 20.789,5.898 20.239,5.898 L0.996,5.898 C0.446,5.898 0,5.452 -0,4.902 C0,4.352 0.446,3.907 0.996,3.907 L20.239,3.907 z M23.004,10.959 L7.633,10.959 C7.083,10.959 6.637,11.404 6.637,11.954 C6.637,12.504 7.083,12.95 7.633,12.95 L23.004,12.95 C23.554,12.95 24,12.504 24,11.954 C24,11.404 23.554,10.959 23.004,10.959 z M18.801,18.093 L7.633,18.093 C7.083,18.093 6.637,18.539 6.637,19.089 C6.637,19.639 7.083,20.085 7.633,20.085 L18.801,20.085 C19.351,20.085 19.797,19.639 19.797,19.089 C19.797,18.539 19.351,18.093 18.801,18.093 z M0.996,10.046 C0.446,10.046 0,10.492 0,11.042 L0,20.129 C0,20.679 0.446,21.125 0.996,21.125 C1.545,21.125 1.991,20.679 1.991,20.129 L1.991,11.042 C1.991,10.492 1.545,10.046 0.996,10.046 z"/>
</svg>;

const I = () => <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M10 4v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V4z"/>
</svg>;

const U = () => <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path
        d="M12 17c3.31 0 6-2.69 6-6V3h-2.5v8c0 1.93-1.57 3.5-3.5 3.5S8.5 12.93 8.5 11V3H6v8c0 3.31 2.69 6 6 6zm-7 2v2h14v-2H5z"/>
</svg>;

const Btn = styled.button`
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    fill: ${props => props.fill};
    height: ${props => props.icon_size === "sm" ? "16px" : "18px"};
    width: ${props => props.icon_size === "sm" ? "16px" : "18px"};
  }
`;

function CustomButton({onClick, tooltip, light, icon_size = "md", fill = "auto", active, icon, theme}) {

    let classes;

    if (light) {
        classes = active ? "bg-gray-300 text-gray-700" : "text-gray-500 hover:text-gray-800 hover:bg-gray-100";
    } else {
        classes = active ? "bg-gray-700 text-gray-200" : "text-gray-400 hover:text-gray-200 hover:bg-gray-700";
    }

    const btn = <Btn icon_size={icon_size} fill={fill} active={active} onMouseDown={(e) => e.preventDefault()}
                     onClick={onClick}
                     className={`${classes} rounded-md sc-toolbar-button`}>
        {icon}
    </Btn>

    if (tooltip) {
        return <SimpleTooltip usePortal text={tooltip.title} subtext={tooltip.subtitle}>
            {btn}
        </SimpleTooltip>
    }

    return btn;
}

const Separator = styled.div`
  width: 1px;
  background: ${props => props.light ? "#D7D7D7" : "#3e3e3c"};
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
`;

const ToolbarStyles = styled.div`
  > div {
    border-radius: 6px;
    background: #151513;
    padding: 2px 3px !important;
    box-shadow: 0px 1px 2px 0px rgba(44, 44, 44, 0.5) !important;
    border: 1px solid #2d2d2a;

    > svg {
      fill: #6B6B6B;
    }
  }

  > div::before {
    border: none;
  }

  > div::after {
    border-top-color: #2d2d2a;
  }
`;

// example with audio, image, video
// https://github.com/facebook/draft-js/blob/master/examples/draft-0-10-0/media/media.html

export const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(sc_block_render_map);

// todo markdown plugin fix
// https://ask.csdn.net/questions/6877426

//const {EmojiSuggestions} = scPluginEmoji;

export function ensureExtension(str) {
    return str;
}

function cleanDownloadURL(str) {
    return str.split('&token')[0];
}

const button_theme = {
    active: 'sc-toolbar-button-active',
    button: 'sc-toolbar-button',
    buttonWrapper: 'sc-toolbar-button-wrapper'
};

function FSItem(props) {
    const color = props.active ? `text-gray-200 bg-gray-700` : `text-gray-400 hover:text-gray-200 hover:bg-gray-700`;
    return <div onClick={() => {
        if (props.onClick) {
            props.onClick();
        }
    }} style={{height: '26px', borderRadius: "0.25rem"}}
                className={`px-2 flex items-center text-sm cursor-pointer font-medium ${color}`}>
        {props.children}
    </div>
}

class FontSizePicker extends Component {
    componentDidMount() {
        setTimeout(() => {
            document.body.addEventListener('click', this.onWindowClick);
        });
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.onWindowClick);
    }

    onWindowClick = () => {
        // Call `onOverrideContent` again with `undefined`
        // so the toolbar can show its regular content again.
        this.props.handleFullClose();
    }

    render() {
        const {active = '', _actions} = this.props;
        return (
            <div onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }} className="flex space-x-1" style={{height: '1.65rem'}}>
                <FSItem onClick={() => {
                    _actions._onTextClick();
                    this.props.onOverrideContent(undefined);
                }} active={active === 'unstyled'}>Text</FSItem>
                <FSItem onClick={() => {
                    _actions._onHeader1Click()
                    this.props.onOverrideContent(undefined);
                }} active={active === 'header-one'}>H1</FSItem>
                <FSItem onClick={() => {
                    _actions._onHeader2Click()
                    this.props.onOverrideContent(undefined);
                }} active={active === 'header-two'}>H2</FSItem>
                <FSItem onClick={() => {
                    _actions._onHeader3Click()
                    this.props.onOverrideContent(undefined);
                }} active={active === 'header-three'}>H3</FSItem>
            </div>
        );
    }
}

function renderSize(active) {
    if (active) {
        if (active === 'header-one') {
            return "H1"
        } else if (active === 'header-two') {
            return "H2"
        } else if (active === 'header-three') {
            return "H3"
        }
    }
    return "Text"
}

class FontSizeButton extends Component {
    // When using a click event inside overridden content, mouse down
    // events needs to be prevented so the focus stays in the editor
    // and the toolbar remains visible  onMouseDown = (event) => event.preventDefault()
    onMouseDown = (event) => {
        event.preventDefault()
    };

    handleFullClose = () => {
        this.props.onOverrideContent(undefined);
    };

    onClick = () => {
        // A button can call `onOverrideContent` to replace the content
        // of the toolbar. This can be useful for displaying sub
        // menus or requesting additional information from the user.
        if (this.props.light) {
            return;
        }

        this.props.onOverrideContent((props) => <FontSizePicker
            handleFullClose={this.handleFullClose}
            active={this.props.active} {...props} {...this.props} />);
    }

    render() {
        const {active, _actions, light} = this.props;

        const el = <div
            style={{height: '26px'}}
            onMouseDown={this.onMouseDown}
            className={"flex items-center "}
        >
            <div style={{height: '26px', borderRadius: "0.25rem"}} onClick={() => this.onClick()}
                 className={`${light ? "hover:bg-gray-200" : "hover:bg-gray-600"} rounded-md text-sm py-0.5 flex items-center font-medium pl-1.5 pr-1 -mr-0.5  cursor-pointer`}>
                <span className={light ? "text-gray-600" : `text-gray-200`}>{renderSize(active)}</span>
                <span
                    className={`${light ? "text-gray-500" : "text-gray-400"} ml-0.5 w-4 h-4 h-svg-3`}><ChevronDownIcon/></span>
            </div>
        </div>

        if (light) {

            const is_heading = active && active.startsWith('header-');

            const pp = <PopupContentWrapper>
                <ContentPopupMenu can_check items={[
                    {
                        onClick: () => {
                            _actions._onTextClick();
                        },
                        checked: !is_heading,
                        label: "Text"
                    },
                    {
                        onClick: () => {
                            _actions._onHeader1Click();
                        },
                        checked: active === 'header-one',
                        label: "Header One"
                    },
                    {
                        onClick: () => {
                            _actions._onHeader2Click();
                        },
                        checked: active === 'header-two',
                        label: "Header Two"
                    },
                    {
                        onClick: () => {
                            _actions._onHeader3Click();
                        },
                        checked: active === 'header-three',
                        label: "Header Three"
                    }
                ]}/>
            </PopupContentWrapper>;


            return <>
                <SimpleTooltip hideOnClick simple trigger='click' interactive text={pp} placement='bottom-end'>
                    {el}
                </SimpleTooltip>
            </>
        }

        return (
            el
        );
    }
}


const StyledMention = styled.span`
  background: #FEE3C8;
  color: #555560;
  border-radius: 6px !important;
  padding: 0 4px;
`;

const StyledMergeTag = styled.span`
    background: #fff;
    color: #000000;
    border-radius: 6px !important;
    border: 1px solid rgb(195 195 195);
    padding: 3px 2px;
    letter-spacing: -0.5px;
    font-family: monospace;
    font-size: medium;
    font-weight: 700;
`;

/*
TODO

 */

const mentionPlugin = createMentionPlugin({
    mentionComponent: InlineEntry,
    supportWhitespace: true,
    entityMutability: 'IMMUTABLE',
    theme: {mentionSuggestionsPopup: "mentions-popup-styles"}
});

const mergeTagPlugin = createMentionPlugin({
    mentionComponent: MergeTag,
    supportWhitespace: false,
    mentionTrigger: "{{",
    entityMutability: 'IMMUTABLE',
    theme: {mentionSuggestionsPopup: "mentions-popup-styles"}
});

function MergeTag({entityKey, mention, className, decoratedText}) {
    return <StyledMergeTag key={entityKey} className={`${className}`}>
<span className="text-orange-600 pr-1.5">
    {`{·}`}
</span>
        {decoratedText}
    </StyledMergeTag>
}

function InlineEntry({entityKey, mention, className, decoratedText}) {
    return <StyledMention key={entityKey} className={`${className}`}>

        {decoratedText}
    </StyledMention>
}

function reduceGroups(merge_tags, raw) {
    let a = [];
    merge_tags.forEach(group => {
        group.tags.forEach(tag => {
            a.push({
                example: tag.fn(raw.member, raw.community),
                name: tag.subtitle,
                id: tag.subtitle
            });
        })
    })
    return a;
}

// todo max image resizing / optimizing

function addSummaryBlock() {

}

function addMemberListBlock() {

}

export function image_uploadAndResize(file, path, payload_extras = {}, type, custom_meta = {}, cb) {

    try {
        const res = (resp) => {
            console.log("Image Resize RESP", resp)
            if (resp && resp.data) {
                cb(resp.data.url, {type}, custom_meta, resp.data.color, resp.data.images);
            } else {
                cb(null);
            }
        };
        const err = () => {
            // todo
        };
        const payload = {
            ...payload_extras,
            path
        };
        imageResizeRequest(file, res, "POST", payload);
    } catch (e) {
        console.error(e);
        return cb(null);
    }
}

function StaticToolbarComponent({children, p}) {

    return React.Children.map(children, child => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
            return React.cloneElement(child, p);
        }
    });
}

export function RichTextEditor({
                                   features = {},
                                   hover_formatting,
                                   handleCommandEnter,
                                   es_action,
                                   show_formatting_bar,
                                   editor_action = {},
                                   refresh,
                                   style = {},
                                   merge_tags = [],
                                   initial_action = '',
                                   onChange = () => {
                                   },
                                   handleClose = () => {
                                   },
                                   debug = false,
                                   mode = "",
                                   actions,
                                   right_actions,
                                   fc,
                                   action_bar,
                                   placeholder = "",
                                   template,
                                   content_state,
                                   viewer = false,
                                   auto_focus = false
                               }) {
    const [editorState, setEditorState] = React.useState(
        content_state ? () => EditorState.createWithContent(content_state) : () => EditorState.createEmpty()
    );
    const es_ref = useRef(null);
    const toasts = useToasts();
    const is_mobile = useIsMobile();
    const community = useCommunity();
    const [open, setOpen] = useState('');
    // todo set editor to readonly while using custom block
    const [read_only, setReadOnly] = useState(viewer);
    const [meta, setMeta] = useState(null);
    const [extras, setExtras] = useState({});
    const [es_update, setUpdate] = useState(null);

    const editor = useRef(null);
    const es_updates = useRef({});

    const [merge_tag_suggestions, setMergeTagSuggestions] = useState([]);
    const [suggestions, setSuggestions] = useState([]);

    const editor_actions = useRef({});

    const last_key = useRef(null);

    const all_features = {
        hover_toolbar: !is_mobile && hover_formatting,
        block_arrange: !is_mobile,
        ...features
    };

    useEffect(function () {
        es_ref.current = editorState;
    }, [editorState])

    useEffect(function () {
        if (es_action) {
            if (es_updates.current[es_action.id]) {
                // already updated
                return;
            }

            if (es_action.type === 'add-block') {
                if (!es_action.block_type) {
                    return;
                }
                const {block_key, editor_state} = handleAddBlock(es_ref.current, es_action.block_type, es_action.data);
                es_updates.current[es_action.id] = {
                    status: "done",
                    block_key
                };
                setEditorState(editor_state);
            } else if(es_action.type==="remove-block") {
                if (!es_action.last_es_action_id) {
                    return;
                }
                const ref_block_key = es_updates.current[es_action.last_es_action_id].block_key;

                if (!ref_block_key) {
                    return;
                }
console.log("REMOVE BLOCK", ref_block_key,es_action)
                setUpdate({
                    type: "remove-block",
                    block_key: ref_block_key,
                    data: es_action?.data||{},
                    id: es_action.id
                })

            } else if (es_action.type === "update-block-data") {
                if (!es_action.last_es_action_id) {
                    return;
                }
                const ref_block_key = es_updates.current[es_action.last_es_action_id].block_key;

                if (!ref_block_key) {
                    return;
                }

                setUpdate({
                    type: "update-block-data",
                    block_key: ref_block_key,
                    data: es_action.data,
                    id: es_action.id
                })
            }
        }
    }, [es_action])

    useEffect(function () {
        if (editor_action && editor_action.type) {
            const action_ref = `${editor_action.type}-${editor_action.id}`;
            if (editor_actions.current[`${action_ref}`]) {
                return;
            }
            editor_actions.current[`${action_ref}`] = true;
            if (editor_action.type === 'add-summary') {
                addSummaryBlock();
            } else if (editor_action.type === 'add-member-list') {
                addMemberListBlock();
            } else if (editor_action.type === 'insert-poll') {
                addPollBlock(editor_action.id);
            } else if (editor_action.type === 'insert-signature') {
                addSignature(editor_action.raw);
            } else if (editor_action.type === 'insert-widget') {
                console.log("INSERT WIDFGET", editor_action)
                addWidgetBlock(editor_action.id, editor_action?.data || {});
            }
        }
    }, [editor_action])

    function addWidgetBlock(widget_id, widget_data) {
        const {block_key, editor_state} = handleAddBlock(editorState,
            "custom-widget",
            {
                id: widget_id,
                data: widget_data || {}
            },
            "",
            'before'
        );

        setEditorState(editor_state);
    }

    useEffect(() => {
        if (refresh) {
            setEditorState(EditorState.push(editorState, ContentState.createFromText('')));
        }
    }, [refresh])

    useEffect(function () {
        setTimeout(function () {
            if (initial_action === 'upload-image') {
                //  openImageSelector();
            } else if (initial_action === 'upload-attachment') {
                //  openFileSelector();
            }
        }, 50)
    }, [initial_action])

    const [{plugins, InlineToolbar, MentionSuggestions, MergeTagSuggestions}] = useState(() => {
        const toolbarPlugin = createInlineToolbarPlugin();
        const {InlineToolbar} = toolbarPlugin;
        const plugins = [toolbarPlugin, mentionPlugin, mergeTagPlugin, scPluginLinkify(read_only), scPluginLink, dividerPlugin];
        return {
            plugins,
            InlineToolbar,
            MentionSuggestions: mentionPlugin.MentionSuggestions,
            MergeTagSuggestions: mergeTagPlugin.MentionSuggestions
        };
    });

    const onOpenChange = useCallback((_open) => {
        setOpen(_open ? 'mention' : '');
    }, []);
    const onOpenMergeTagChange = useCallback((_open) => {
        setOpen(_open ? 'merge-tag' : '');
    }, []);
    const onOpenChangeEmoji = useCallback(() => {
        setOpen('emoji');

    }, []);
    const onCloseChangeEmoji = useCallback(() => {
        setOpen('');
    }, []);

    const onSearchMergeTagChange = useCallback(({value}) => {
        const arr = reduceGroups(merge_tags, {community: community.data, member: community.member});
        setMergeTagSuggestions(defaultSuggestionsFilter(value, arr));
    }, []);

    const onSearchChange = useCallback(({value}) => {
        // todo
        //const cq = cleanQuery(value);
        const searchable = {members: true, roles: true};
        const context = {
            community_uid: community.uid
        };
        /*
        const queries = buildSearchQueries(searchable);
        queryMembersAndRoles(cq, queries, context, searchable)
            .then((result) => {
                const arr = convertSearchObjToArr(result);
                setSuggestions(defaultSuggestionsFilter(cq, arr.map(a => {
                    return {
                        handle: a.handle,
                        type: a._type,
                        id: a.value,
                        name: a.label,
                        image: a.image
                    }
                })));
            })

         */
    }, []);

    function addPollBlock(poll_id) {
        const {block_key, editor_state} = handleAddBlock(editorState,
            "widget-poll",
            {
                id: poll_id
            },
            "",
            'before'
        );
        setEditorState(editor_state);
    }

    useEffect(function () {
        if (auto_focus) {
            focus();
        }
    }, []);

    useEffect(function () {
        if (fc > 0) {
            focus();
        }

    }, [fc])

    useEffect(function () {
        if (template) {
            if (template.raw) {
                const new_content_state = convertFromRaw(template.raw);
                const new_editor_state = EditorState.push(editorState, new_content_state, "change-block-type");
                setEditorState(new_editor_state);
            }
        }
    }, [template]);

    useEffect(function () {
        if (es_update) {

            if (es_update.type === 'update-block-data' && es_update.block_key && es_update.data.url) {
                const new_update = es_update;
                const cs = es_ref.current.getCurrentContent();

                const block_data = cs.getBlockForKey(es_update.block_key);

                if (!block_data) {
                    // deleted..
                    return;
                }

                const current_selection = es_ref.current.getSelection();

                const contentBlockSelectionState = SelectionState.createEmpty(es_update.block_key);
                const new_content_state = Modifier.mergeBlockData(cs, contentBlockSelectionState, new_update.data);

                const newEditorState = EditorState.push(es_ref.current, new_content_state, 'change-block-data');

                setUpdate(null);
                setEditorState(EditorState.forceSelection(newEditorState, current_selection));
            } else if(es_update.type==="remove-block"&& es_update.block_key) {
                const new_update = es_update;
                const cs = es_ref.current.getCurrentContent();

                const block_data = cs.getBlockForKey(es_update.block_key);
                console.log("ES UPDATE REMOVE BLOCK",es_update,block_data);
                if (!block_data) {
                    // deleted..
                    return;
                }

                const next = cs.getBlockAfter(es_update.block_key);

                const prev = cs.getBlockBefore(es_update.block_key);

               const focus_key = next ? next.getKey() : es_update.block_key;

                console.log("FOCUS KEY",focus_key);

                /*
                const removeSelection = new SelectionState({
                    anchorKey: es_update.block_key,
                    anchorOffset: block_data.getText().length,
                    focusKey: next.getKey(),
                    focusOffset: 0
                });

                 */

                const contentBlockSelectionState = SelectionState.createEmpty(es_update.block_key);
                const updatedSelection = contentBlockSelectionState.merge({
                    anchorKey: prev.getKey(),
                    anchorOffset: prev.getText().length,
                    focusKey: next.getKey(),
                    focusOffset: 0
                });

                console.log("UPDATED SELECTION",JSON.stringify(updatedSelection.toJS()));

                let new_content_state = Modifier.removeRange(
                    cs,
                    updatedSelection,
                    "forward"
                );
                const change_type = "remove-range";
                const newEditorState = EditorState.push(es_ref.current, new_content_state, change_type);
console.log("change_type",change_type);
                setUpdate(null);
                setEditorState(newEditorState);
            }
        }
    }, [es_update])

    useEffect(function () {
        onChange(editorState)
    }, [editorState])


    function addBlankSpace() {
        let _sel = editorState.getSelection();

        let _cs = Modifier.insertText(
            editorState.getCurrentContent(),
            _sel,
            ' '
        );
        setEditorState(EditorState.push(
            editorState,
            _cs,
            'insert-text'
        ));
    }

    function insertTag(tag) {
        const raw = {community: community.data, member: community.member};
        const all_tags = reduceGroups(merge_tags, raw);
        const item = all_tags.filter(at => at.id === tag)[0];
        const stateWithEntity = editorState.getCurrentContent().createEntity(
            '{{mention',
            'IMMUTABLE',
            {
                mention: {id: tag, name: tag, example: item.example},
            },
        );
        let _sel = editorState.getSelection();
        const entityKey = stateWithEntity.getLastCreatedEntityKey()
        const stateWithText = Modifier.insertText(stateWithEntity, editorState.getSelection(), tag, null, entityKey);
        const new_content = stateWithText.merge({
            selectionBefore: _sel,
            selectionAfter: stateWithText.getSelectionAfter().set('hasFocus', true)
        });
        const new_editor_state = EditorState.push(editorState, new_content);
        const final_editor_state = EditorState.moveSelectionToEnd(new_editor_state);
        setEditorState(EditorState.forceSelection(final_editor_state, final_editor_state.getSelection()));
    }

    function handleMetaChange(c) {
        setMeta({
            ...meta,
            ...c
        });
    }

    function scEditorHandleKeyCommand(command) {
        if (command === 'add-link') {
            // here we should open the modal to add a link
            /*
            setMeta({
                type: 'add-link',
                selection_text: getSelectionText(editorState),
                selection_link: ''
            })

             */
            setOpen('add-link');
            return 'handled';
        } else if (command === "em") {
            setEditorState(
                sc_handleConvertToEm(editorState)
            )
            return "handled";
        } else if (command === "convertToImage") {
            setEditorState(
                RichUtils.toggleBlockType(editorState, "image-with-caption")
            );
            return "handled";
        } else if (command === 'convert-to-h2') {
            setEditorState(
                sc_handleConvertToHeader(editorState, 'header-two')
            );
            return 'handled';
        } else if (command === 'convert-to-h1') {
            setEditorState(
                sc_handleConvertToHeader(editorState, 'header-one')
            );
            return 'handled';
        } else if (command === 'convert-to-blockquote') {
            setEditorState(
                sc_handleConvertToHeader(editorState, 'blockquote')
            )
        } else if (command === 'convert-to-unordered-list-item') {
            setEditorState(
                sc_handleConvertToHeader(editorState, 'unordered-list-item')
            )
        } else if (command === 'convert-to-ordered-list-item') {
            setEditorState(
                sc_handleConvertToHeader(editorState, 'ordered-list-item')
            )
        } else if (command === 'bold') {
            setEditorState(
                RichUtils.toggleInlineStyle(editorState, 'BOLD')
            );
            return 'handled';
        } else if (command === 'underline') {
            setEditorState(
                RichUtils.toggleInlineStyle(editorState, 'UNDERLINE')
            );
            return 'handled';
        } else if (command === 'strikethrough') {
            setEditorState(
                RichUtils.toggleInlineStyle(editorState, 'STRIKETHROUGH')
            );
            return 'handled';
        } else if (command === 'italic') {
            setEditorState(
                RichUtils.toggleInlineStyle(editorState, 'ITALIC')
            );
            return 'handled';
        } else if (command === 'escape') {
            // blur and then set selection to the end
            blur();

            // now set the selection to the end
            setEditorState(
                EditorState.moveSelectionToEnd(editorState)
            )

            return 'handled';
        } else if (command === 'submit') {
            if (handleCommandEnter) {
                handleCommandEnter();
            }
            return 'handled';
        }
        return 'not-handled';
    }

    const focus = (blur_focus) => {
        if (editor && editor.current) {
            editor.current.focus()
        }
    }

    const blur = () => {
        if (editor && editor.current) {
            editor.current.blur()
        }
    }

    function handleAddBlock(es, block_type, data = {}, character = '', direction = 'after') {

        const {editor_state, block_key} = es_insertCustomBlock(
            es,
            block_type,
            character,
            data,
            direction
        )

        return {
            editor_state, block_key
        }
    }

    function testLoadPost() {
        /*
        api_getPost(community.uid,"6ZJlkWifqzeVD8vkPEm2")
            .then(p=>{
                console.log("PP",p)
                const new_content_state = convertFromRaw(p.raw);
                const new_editor_state = EditorState.push(editorState, new_content_state, "change-block-type");
                setEditorState(new_editor_state);
            })

         */
    }

    function addSignature(raw_content) {
        if (!raw_content) {
            return;
        }

        const parsed = JSON.parse(raw_content);

        const editor_state_prep = es_insertMultipleBlocks(
        editorState,
            [
                {
                    type: "signature-break",
                    text: "",
                    data: {},
                    key: generateRandomKey()
                }
            ]
            )

        const converted = convertFromRaw(parsed);

        const new_state = ContentState.createFromBlockArray(
            converted.getBlocksAsArray(),
            converted.getEntityMap()
        );
        const new_content_state = Modifier.replaceWithFragment(editor_state_prep.getCurrentContent(), editor_state_prep.getSelection(), new_state.getBlockMap())
        const final_editor_state = EditorState.push(editor_state_prep, new_content_state, 'insert-fragment');
        setEditorState(final_editor_state)
        setExtras({...(extras||{}), added_signature: true});

        setTimeout(()=>{
            blur();
        }, 25);
    }

    function _changeInlineStyle(style = "BOLD") {
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    }

    function handlePastedText(text, html) {
        if (html) {
            const blocksFromHTML = convertFromHTML(html);
            const new_state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
            const new_content_state = Modifier.replaceWithFragment(editorState.getCurrentContent(), editorState.getSelection(), new_state.getBlockMap())
            setEditorState(EditorState.moveSelectionToEnd(EditorState.push(editorState, new_content_state, 'insert-fragment')))
            return true;
        } else if (text) {
            const new_state = ContentState.createFromText(text);
            const new_content_state = Modifier.replaceWithFragment(editorState.getCurrentContent(), editorState.getSelection(), new_state.getBlockMap())
            setEditorState(EditorState.moveSelectionToEnd(EditorState.push(editorState, new_content_state, 'insert-fragment')))
            return true;
        } else {
            return false;
        }
    }

    function _toggleBlockType(type) {
        // blockquote, unordered-list-item, ordered-list-item, header-one, header-two, header-three
        setEditorState(RichUtils.toggleBlockType(editorState, type));
    }

    function handleDragEnd({source, destination}) {
        if (!destination) return;
        if (source.index === destination.index) {
            return setEditorState(EditorState.forceSelection(editorState, editorState.getCurrentContent().getSelectionAfter()));
        }

        const current_content_state = editorState.getCurrentContent();
        const omap = current_content_state.getBlockMap();

        // need to get block id
        const source_block = omap.toIndexedSeq().get(source.index);
        const dest_block = omap.toIndexedSeq().get(destination.index);
        const target_range = SelectionState.createEmpty(dest_block.getKey());

        const direction = destination.index > source.index ? 'after' : 'before';

        let newEditorState = AtomicBlockUtils.moveAtomicBlock(editorState, source_block, target_range, direction);

        setEditorState(EditorState.forceSelection(newEditorState, newEditorState.getCurrentContent().getSelectionAfter()));
    }

    const toolbar_actions = {
        onAddCustomBlock: () => {
            const {block_key, editor_state} = handleAddBlock(editorState,
                "members-list",
                {
                    members: [
                        {
                            name: "Sean McCall",
                            id: "412f3fw",
                            image: `${DEFAULT_UNASSIGNED_ROLE}_small?alt=media`,
                            email: 'seanmccall14@gmail.com',
                            link: `${window.location.host}/${community.data.handle}/member/handle`
                        }
                    ]
                },
                "",
                'before'
            );
            setEditorState(editor_state);
        },
        _onOrderedListClick: () => _toggleBlockType('ordered-list-item'),
        _onUnorderedListClick: () => _toggleBlockType('unordered-list-item'),
        _onBlockquoteClick: () => _toggleBlockType('blockquote'),
        _onTextClick: () => _toggleBlockType('unstyled'),
        _onHeader1Click: () => _toggleBlockType('header-one'),
        _onHeader2Click: () => _toggleBlockType('header-two'),
        _onHeader3Click: () => _toggleBlockType('header-three'),
        onAddDivider: () => {
            let contentState = editorState.getCurrentContent();
            let contentStateWithEntity = contentState.createEntity("divider", 'IMMUTABLE', {});
            let entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            let newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
            setEditorState(EditorState.forceSelection(newEditorState, newEditorState.getCurrentContent().getSelectionAfter()));
        },
        _onBoldClick: () => _changeInlineStyle("BOLD"),
        _onItalicClick: () => _changeInlineStyle("ITALIC"),
        _onUnderlineClick: () => _changeInlineStyle("UNDERLINE"),
        onAddSignature: () => {

        },
        onAddLink: () => {
            const _ss = editorState.getSelection();
            const blocks = getSelectedBlocksList(editorState);
            const selection_text = getSelectionText(editorState);

            if (blocks.size === 1 && selection_text.length > 0) {
                setMeta({
                    selection_text,
                    selection_start: _ss.getStartKey(),
                    selection_link: ""
                });
                setOpen('add-link');
            }
        },
        onRemoveLink: () => {
            const ccs = editorState.getCurrentContent();

            const ncs = Modifier.applyEntity(ccs, editorState.getSelection(), null);

            setEditorState(EditorState.push(editorState, ncs, 'apply-entity'));
        },
        onAddAttachment: () => {
            // openFileSelector();
        },
        onAddImage: () => {
            //  openImageSelector();
        }
    };
    /*
       <DeleteModal
            deleting={false}
            can_delete={true}
            usePortal={false}
            content={<>
                <Field label="Where do you want to upload these files?">

                </Field>
            </>}
            title={`Choose Folder`}
            intent='secondary'
            text='Select'
            onDelete={() => {}}
            onClose={() => {

            }}/>
     */

    const debug_mode = debug;

    const current_content = editorState.getCurrentContent();

    const inline_style = editorState.getCurrentInlineStyle();

    const current_block = current_content.getBlockForKey(editorState.getSelection().getStartKey());
    const current_block_type = current_block.getType();

    const is_bold = inline_style.has("BOLD");
    const is_italic = inline_style.has("ITALIC");
    const is_underline = inline_style.has("UNDERLINE");
    const is_numbered_list = current_block_type === 'ordered-list-item';
    const is_bulleted_list = current_block_type === 'unordered-list-item';
    const is_blockquote = current_block_type === 'blockquote';
    const selection_entity = getSelectionEntity(editorState);
    const selected_entity_data = selection_entity ? current_content.getEntity(selection_entity) : null;
    const is_link = selected_entity_data ? selected_entity_data.getType() === 'LINK' : false;

    //    <EmojiSuggestions onOpen={onOpenChangeEmoji} onClose={onCloseChangeEmoji} open={true}/>
    //    <div>{toolbar_item}</div>
    // {{mention or mention is TYPE
    /*
     <div className="z-10 bg-white flex items-center h-7 py-3 hidden">
            <div className="flex-none pr-2 text-xs text-gray-500">Insert:</div>
        </div>
     */

    const renderToolbar = (externalProps) => {
        return (
            <div className={`flex ${externalProps.light ? "py-0.5 px-0.5" : ""}`}
                 style={{height: externalProps.light ? "auto" : '1.65rem'}}>
                <FontSizeButton _actions={toolbar_actions}
                                active={current_block_type} {...externalProps} />
                <Separator light={externalProps.light}/>
                <div className="flex space-x-px">
                    <CustomButton {...externalProps} tooltip={{title: "Bold", subtitle: "Cmd + B"}}
                                  fill="currentColor" active={is_bold}
                                  icon={<B/>} theme={button_theme}
                                  onClick={() => toolbar_actions._onBoldClick()}/>
                    <CustomButton {...externalProps}
                                  tooltip={{title: "Italics", subtitle: "Cmd + I"}}
                                  fill="currentColor" active={is_italic}
                                  icon={<I/>} theme={button_theme}
                                  onClick={() => toolbar_actions._onItalicClick()}/>
                    <CustomButton {...externalProps}
                                  tooltip={{title: "Underline", subtitle: "Cmd + U"}}
                                  fill="currentColor" active={is_underline}
                                  icon={<U/>} theme={button_theme}
                                  onClick={() => toolbar_actions._onUnderlineClick()}/>
                    <CustomButton {...externalProps}
                                  tooltip={{title: "Add Link", subtitle: "Cmd + K"}} icon_size="sm"
                                  active={is_link}
                                  icon={<LinkIcon/>} theme={button_theme} onClick={(e) => {
                        if (is_link) {
                            toolbar_actions.onRemoveLink();
                        } else {
                            toolbar_actions.onAddLink();
                        }
                    }}/>
                </div>
                <Separator light={externalProps.light}/>
                <div className="flex space-x-1 ">
                    <CustomButton {...externalProps} tooltip={{title: "Numbered List"}}
                                  fill="currentColor" active={is_numbered_list}
                                  icon={<OL/>} theme={button_theme}
                                  onClick={() => toolbar_actions._onOrderedListClick()}/>
                    <CustomButton {...externalProps} tooltip={{title: "Bullet List"}}
                                  fill="currentColor" active={is_bulleted_list}
                                  icon={<UL/>} theme={button_theme}
                                  onClick={() => toolbar_actions._onUnorderedListClick()}/>
                    <CustomButton {...externalProps} tooltip={{title: "Blockquote"}}
                                  fill="currentColor" active={is_blockquote}
                                  icon={<BQ/>} theme={button_theme}
                                  onClick={() => toolbar_actions._onBlockquoteClick()}/>
                </div>
            </div>
        )
    };

    const toolbar_item = !read_only ?
        <StaticToolbarComponent>
            <div>
                {renderToolbar({light: true})}
            </div>
        </StaticToolbarComponent> : null;

    const inline_toolbar = <InlineToolbar>
        {renderToolbar}
    </InlineToolbar>;

    return <div className="relative ">
        {show_formatting_bar && <div className="bg-gray-100 px-0.5 border-b border-[#D7D7D7]">
            {toolbar_item}
        </div>}
        <ScEditorContent
            className={`${mode === 'compose' ? "-mt-5 px-3.5 pb-6" : ""} pt-1.5 prose prose-base prose-overrides text-gray-800`}>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId={`dnd-draft-edit`}>
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            <Editor
                                customStyleMap={sc_editor_style_map}
                                readOnly={read_only}
                                plugins={plugins}
                                ref={editor}
                                onBlur={() => {
                                    last_key.current = null;
                                    const current_key = editorState.getSelection().getStartKey();
                                    const selection_state = SelectionState.createEmpty(current_key);
                                    const new_editor_state = EditorState.forceSelection(editorState, selection_state);
                                    setEditorState(new_editor_state);
                                }}
                                handleReturn={es_handleReturn(setEditorState, open)}
                                blockRenderMap={extendedBlockRenderMap}
                                // handlePastedText={handlePastedText}
                                handleKeyCommand={scEditorHandleKeyCommand}
                                keyBindingFn={(open) ? undefined : scEditorKeyBindingFn.bind(this, editorState, (sel_text, selection_start) => {
                                    const m2 = {
                                        selection_text: sel_text,
                                        selection_start
                                    };
                                    setMeta(m2);
                                }, last_key.current, (kc) => {
                                    last_key.current = kc;
                                })}
                                placeholder={placeholder}
                                blockRendererFn={scEditorBlockRenderer(setEditorState, read_only, current_content, all_features.block_arrange)}
                                editorState={editorState}
                                onChange={(nes) => {
                                    setEditorState(nes);
                                }}
                            />
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {all_features.hover_toolbar && !show_formatting_bar && <ToolbarStyles>
                {inline_toolbar}
            </ToolbarStyles>}

            <MentionSuggestions
                open={open === 'mention'}
                onOpenChange={onOpenChange}
                suggestions={suggestions}
                entryComponent={MentionEntry}
                onSearchChange={onSearchChange}
                renderEmptyPopup={true}
                onAddMention={(new_mention) => {
                    // get the mention object selected
                    console.log("ADD MENTION", new_mention)
                    // update editorstate
                    // addBlankSpace();
                }}
            />
            <MergeTagSuggestions
                open={open === 'merge-tag'}
                onOpenChange={onOpenMergeTagChange}
                suggestions={merge_tag_suggestions}
                entryComponent={MentionEntry}
                onSearchChange={onSearchMergeTagChange}
                renderEmptyPopup={true}
                onAddMention={(new_mention) => {
                    // get the mention object selected
                    console.log("ADD MENTION", new_mention)
                    // addBlankSpace();
                }}
            />


        </ScEditorContent>


        {open === 'add-link' && meta && <AddLinkModal onClose={() => {
            setOpen(null);
            setMeta(null);
        }} meta={meta} handleMetaChange={handleMetaChange} onAdd={() => {
            scEditorHandleAddLink(meta, editorState, (twe) => {
                const current_selection = editorState.getSelection();

                const newContent = twe.merge({
                    selectionBefore: current_selection,
                    selectionAfter: twe.getSelectionAfter().set('hasFocus', true)
                });
                setEditorState(EditorState.push(editorState, newContent, 'insert-characters'));

                setOpen(null);
                setMeta(null);
            })
        }}/>}

        {debug_mode && <pre className="text-xs" style={{maxHeight: '400px', overflowY: 'auto'}}>
                {JSON.stringify(convertToRaw(editorState.getCurrentContent()), undefined, 2)}
            </pre>}
    </div>
}