import {getItemFromLocalStorage, saveItemToLocalStorage} from "../../m3/utilities/localstorage";

export async function incrementCounts(slug='locations-countries',inc=1) {
    const item = getItemFromLocalStorage(`counts` , {
        'locations-countries': 0,
        'locations-continents': 0,
        'locations-cities': 0,
        'locations-states': 0,
        'locations-tags': 0,
        'locations-counties': 0,

        'communities-profiles': 0,

        'associations-categories': 0,
        'associations-types': 0,
        'associations-networks': 0,
        'associations-tags': 0,
    });

    if(!item[slug]) {
        item[slug] = 0;
    }

    item[slug] += inc;

    return saveItemToLocalStorage('counts',item);
}

export async function verifySlugIsUnique(slug='san-diego',scope='country') {
    const item = getItemFromLocalStorage(`${scope}-${slug}` , null);
    return !item;
}