import React from "react";

/*
* This is the Icon atom
 */

const _props = {
    size: {
        type: "string",
        required: false,
        default: "xs",
        options: ["xs", "sm", "base", "lg", "xl"]
    },
    children: {
        type: "node",
        required: true
    }
};

const size_map = {
    "2xs": "w-3 h-3",
    "xs": "w-4 h-4",
    "sm": "w-5 h-5",
    "base": "w-6 h-6",
    "lg": "w-8 h-8",
    "xl": "w-10 h-10"
};


export default function M3_A_Icon({
                                      className,
                                      size = "xs",
                                      children,
                                      icon,
                                      color,
                                      sx
                                  }) {
    let classes = "", style = {};

    if (size_map[size]) {
        classes += ` ${size_map[size]}`;
    } else if (size.includes("h-")) {
        classes += ` ${size}`;
    }

    if (className) {
        classes += ` ${className}`;
    }

    if (sx) {
        style = {...sx};
    }

    if (color) {
        classes += ` ${color}`;
    }

    return (
        <div className={classes} style={style}>
            {children}
            {icon}
        </div>
    );
}