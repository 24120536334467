import React, {useState, useContext} from 'react';
import Modal from "../../../../m3/_legacy_components/modal";
import {AddEducationStep} from "./add-education-step";
import Button from "../../../../m3/_legacy_components/button";
import {authFetch} from "../../../../../api/network";
import {validateEducation} from "./member-education";
import {useCommunity} from "../../../../config/community";
import {ModalBody, ModalFooter, ModalHeader} from "../../../../m3/_legacy_components/preview-overlay";
import {PrettyCheckbox} from "../../../../m3/_legacy_components/toggle";

export function CUSTOM_NANOID() {
    return "id" + Math.random().toString(16).slice(2);
}

const mock = {
    org_data: {
        community_uid: '',
        title: '',
        image: '',
        handle: ''
    },

    degree: '', // Bachelor of Science (B.Sc.)

    field_of_study: '', // BWL

    from_year: null,
    to_year: null,

    grade: '', // 1.0

    description: ''
};

function shouldDisable(d) {
    if (d.degree.length < 2) {
        return true;
    }
    if (d.field_of_study.length < 2) {
        return true;
    }
    return false;
}

export function AddEducationModal({onClose, data}) {
    const community = useCommunity();
    const [education, setEducation] = useState(mock);
    const [loading, setLoading] = useState(null);

    function addEducation(ed, id) {
        setLoading('add');
        const payload = {
            education: validateEducation(ed),
            community_uid: community.uid,
            user_uid: community.member.user_uid,
            education_id: id
        };

        const res = async () => {
            setTimeout(() => {
                setLoading(null);
                onClose();
            }, 500);
        };

        authFetch('/members/education-add', res, res, "POST", {payload});
    }

    function handleUpdateEducation(f, v) {
        let ed = Object.assign({}, education);
        if (f === 'range') {
            const final = {...ed, ...v};
            setEducation({...final})
        } else if (f === 'org_data') {
            ed[f]['title'] = v;
            setEducation({...ed});
        } else {
            ed[f] = v;
            setEducation(ed);
        }
    }

    const disabled = shouldDisable(education);

    return <div className="" style={{minHeight: '16rem'}}>
        <ModalHeader title={"Edit Education"} onClose={onClose}/>
        <ModalBody>
            <AddEducationStep education={education}
                              updateEducation={handleUpdateEducation.bind(this)}/>
        </ModalBody>
        <ModalFooter right={
            <Button intent='secondary' loading={loading} disabled={disabled} onClick={() => {
                const id = CUSTOM_NANOID();
                addEducation(education, id);
            }} text='Add'/>
        } left={null}/>
    </div>
}