import {Avatar} from "../../../m3/_legacy_components/avatar";

import {DEFAULT_USER_PHOTO, useCommunity} from "../../../config/community";
import DataWrapper from "../../../m3/_legacy_components/data-wrapper";
import React, {useEffect, useState} from "react";
import {getItemFromLocalStorage, saveItemToLocalStorage} from "../../../m3/utilities/localstorage";
import {TextAction} from "../../auth/sign-in";
import {getPinboardData} from "../../../m3/_legacy_components/pinboard/editor";
import {group_demo_data} from "./mock_items";
import {LayoutContentBlock} from "../../../m3/_legacy_components/app-frame/layouts";
import {SectionHeader} from "../../../m3/_legacy_components/entity-grid";
import Pinboard from "../../../m3/_legacy_components/pinboard";
import {getGroupMembersFollowers, isInLastXMinutes, searchForCity, shouldGetSuggestionForLocation} from "./utilities";
import {utils_dates_format} from "../../../../common/utilities/dates";
import {buildImageUrl} from "../../../../common/utilities/images";


const one_month = 60 * 24 * 30;
const ten_days = 60 * 24 * 10;

export function MiniMemberCard({data = {}, onOpen}) {
    return <div onClick={() => {
        if (onOpen) {
            onOpen();
        }
    }} className="inline-flex h-4 space-x-1 hover:underline cursor-pointer text-gray-600">
        <div className="h-4">
            <Avatar size={"h-3.5 w-3.5"} url={buildImageUrl(data.profile_picture, "_medium", DEFAULT_USER_PHOTO)}/>
        </div>
        <div className="font-medium">
            {data.name}
        </div>
    </div>
}

function MemberItem({mid, cid, handleOpen}) {
    return <DataWrapper id={mid} type={`community_members.${cid}.members`}><MiniMemberCard
        onOpen={() => handleOpen(mid)}/></DataWrapper>
}

export function GroupMods({mod_len, handleOpen, community, mods}) {
    if (mod_len === 1) {
        return <div className="flex flex-wrap gap-1 items-center text-xs text-gray-500">
            <span>Organized by</span><MemberItem handleOpen={handleOpen} cid={community.id} mid={mods[0]}/>
        </div>
    } else if (mod_len === 2) {
        return <div className="flex flex-wrap gap-1 items-center text-xs text-gray-500">
            <span>Organized by</span><MemberItem handleOpen={handleOpen} cid={community.id}
                                                 mid={mods[0]}/><span>and</span><MemberItem handleOpen={handleOpen}
                                                                                            cid={community.id}
                                                                                            mid={mods[1]}/>
        </div>
    } else {
        return <div className="flex flex-wrap gap-1 items-center text-xs text-gray-500">
            <span>Organized by</span><MemberItem handleOpen={handleOpen} cid={community.id}
                                                 mid={mods[0]}/><span>and {mod_len - 1} others</span>
        </div>
    }
}

export function GroupCommonMembers({ids, handleOpen, community}) {
    const mem_len = ids.length;
    if (mem_len === 0) {
        return <div className="flex flex-wrap gap-1 items-center text-xs text-gray-500">
            No one you follow is a member of this Group.
        </div>;
    }
    if (mem_len === 1) {
        return <div className="flex flex-wrap gap-1 items-center text-xs text-gray-500">
            <MemberItem handleOpen={handleOpen} cid={community.id} mid={ids[0]}/> is a member.
        </div>
    } else if (mem_len === 2) {
        return <div className="flex flex-wrap gap-1 items-center text-xs text-gray-500">
            <MemberItem handleOpen={handleOpen} cid={community.id} mid={ids[0]}/><span>and</span><MemberItem
            handleOpen={handleOpen} cid={community.id} mid={ids[1]}/><span>are members.</span>
        </div>
    } else {
        return <div className="flex flex-wrap gap-1 items-center text-xs text-gray-500">
            <MemberItem handleOpen={handleOpen} cid={community.id} mid={ids[0]}/><span>and {mem_len - 1} other people you follow are members.</span>
        </div>
    }
}

export function GroupInsight({group, flags}) {
    const community = useCommunity();
    if (!group || !group.moderators) {
        return null;
    }

    function handleOpen(id) {
        community.openPreviewOverlay({
            type: 'member',
            id
        })
    }

    const mods = Object.keys(group.moderators.user_uids);
    const mod_len = mods.length;
    const group_members_you_follow = getGroupMembersFollowers(group, community);

    if (flags.show_organizers && mod_len > 0) {
        return <GroupMods mods={mods} community={community} handleOpen={handleOpen} mod_len={mod_len}/>
    } else if (flags.show_organizers && mod_len === 0 && group_members_you_follow > 0) {
        return <GroupCommonMembers ids={group_members_you_follow} handleOpen={handleOpen} community={community}/>
    } else if (isInLastXMinutes(group.created_at, ten_days)) {
        return <div className="flex flex-wrap gap-1 items-center text-xs text-gray-500">
            <span>Created by</span><MemberItem handleOpen={handleOpen} cid={community.id}
                                               mid={group.created_by}/><span>on {utils_dates_format(group.created_at)?.short_date}</span>
        </div>
    } else if (isInLastXMinutes(group.last_activity, one_month)) {
        return <div className="flex flex-wrap gap-1 items-center text-xs text-gray-500">
            <span>Last activity</span><span>{utils_dates_format(group.last_activity)?.relative}</span>
        </div>
    } else if (mod_len > 0) {
        return <GroupMods mods={mods} community={community} handleOpen={handleOpen} mod_len={mod_len}/>
    } else {
        return <div className="flex space-x-1 items-center text-xs text-gray-500">
            <span>Last activity</span><span>{utils_dates_format(group.last_activity)?.relative}</span>
        </div>
    }
}

export function SuggestAddLocation({
                                       data,
    simple,
                                       all_names = [],
                                       onSetLocation = () => {}
                                   }) {
    const [suggestion, setSuggestion] = useState(null);
    const [set_location, setSetLocation] = useState(false);

    useEffect(function () {
        const should = shouldGetSuggestionForLocation(data, all_names);

        if (should) {
            const check_if_already_suggested = getItemFromLocalStorage("suggested_location_" + data.id + data.name);
            if (check_if_already_suggested) {
                return;
            }
            getSuggestionData(should);
        } else if(all_names.length===0&&data?.name?.length>4) {
            const check_arr = [
                "Hub",
                "Group",
                "Chapter",
                "City"
            ];
            // check all words in name
            let found = "";
            const words = data.name.split(" ");
            for (let i = 0; i < words.length; i++) {
                const word = words[i];
                if (check_arr.includes(word)) {
                    // replace the term found and trim string
                    found = data.name.replace(word, "").trim();
                    break;
                }
            }
            if(found) {
                const check_if_already_suggested = getItemFromLocalStorage("suggested_location_" + found);
                if (check_if_already_suggested) {
                    return;
                }
                getSuggestionData(found);
            }
        }
    }, [data.name, data.location, data.location_place_id])

    function getSuggestionData(str) {
        searchForCity(str)
            .then((results) => {
                if (results.length > 0) {
                    saveItemToLocalStorage("suggested_location_" + data.id + data.name, results[0]);
                    setSuggestion(results[0]);
                }
            })
    }

    if (!suggestion) {
        return null;
    }

    if (set_location) {
        return null;
    }

    const text = simple ? "Add: " : "Add location: ";

    return <div>
        <TextAction onClick={() => {
            onSetLocation(suggestion.label, suggestion.value)
            setSetLocation(true);
        }} text={
            `${text}${suggestion.label} (${suggestion.note})`
        }/>
    </div>;
}

export function EntityPinboard({community_uid, demo, onOpenSettings, type = "group", id = ""}) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    useEffect(function () {
        setData(null);
    }, [id, community_uid, type])

    useEffect(() => {
        if (!id) {
            return;
        }
        if (loading) {
            return;
        }
        if (!data) {
            setLoading(true);
            getPinboardData(community_uid, type, id)
                .then((data) => {
                    setData(data);
                    setLoading(false);
                });
        }
    }, [id, data, community_uid, type, loading]);

    const pinboard_sections = group_demo_data.pinboard;

    if (!id) {
        return null;
    }

    if (loading) {
        return null;
    }

    const fdata = demo ? pinboard_sections : data;

    if (!fdata) {
        return null;
    }

    if (!fdata.length) {
        return null;
    }

    console.log("fdata",fdata)

    return <div>
        <div className="pb-1">
            <SectionHeader title="Pinboard" actions={<>
                <TextAction onClick={onOpenSettings} text="Edit"/>
            </>}/>
        </div>
        <Pinboard sections={fdata}/>
    </div>
}