import {data_data_integrity_map} from "./data-integrity";

export const data_invite_status_map = {
    'missing-email': {
        label: "Missing Email",
        color: "yellow"
    },
    'not-invited': {
        label: "Not Invited",
        color: "gray"
    },
    'logged-in': {
        label: "Logged In",
        color: "green"
    },
    'invite-sent': {
        label: "Invite Sent",
        color: "blue"
    }
}

export const data_invite_status_arr = Object.entries(data_invite_status_map).map(([k, v]) => {
    return {
        value: k,
        label: v.label
    }
});