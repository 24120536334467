import React, {useEffect, useState} from "react";

/*
* This is the ProgressBar atom
* supports a fakeload which accepts a ms count to show progress linearly for the X ms
 */

const _props = {
    progress: {
        type: "number",
        required: true,
        default: 0
    },
    inline: {
        type: "boolean",
        required: false,
        default: false
    }
};

export default function M3_A_ProgressBar({
                                             progress = 0,
                                             fakeload,
                                             inline
                                         }) {
    const [p, setP] = useState(progress);

    useEffect(function () {
        setP(progress);
    }, [progress]);

    useEffect(function () {
        if (fakeload) {
            let i = 0;
            const interval = setInterval(function () {
                i += 0.01;
                setP(i);
            }, fakeload / 100);
            return () => clearInterval(interval);
        }
    }, [fakeload])

    const h = inline ? "h-0.5" : "h-1";
    const bg = inline ? "bg-white" : "bg-gray-200";

    return (<div className={`absolute z-50 top-0 left-0 right-0 ${h} ${bg}`}>
            <div className={`${h} rounded-md`} style={{
                width: `${Math.floor(p * 100)}%`,
                backgroundColor: '#12A6FF',
                transition: 'width',
                transitionTimingFunction: "cubicBezier(0.4, 0, 0.2, 1)",
                transitionDuration: "0.4s"
            }}/>
        </div>
    );
}