import React from "react"
import './styles.css';
import {renderField} from "../profile-field-renderer";
import {useToasts} from "../../../config/toasts";
import {copyTextToClipboard} from "../../../m3/utilities/copy-to-clipboard";
import {InformationCircleIcon} from "@heroicons/react/20/solid";

export function CopyToClipboardComponent(props) {
    const toasts = useToasts();

    function handler() {
        copyTextToClipboard(props.text, () => {
            toasts.addToast({text: "Copied to Clipboard"})
        });
    }

    return <span className="-mx-1 -my-0.5 px-1 py-0.5 hover:bg-gray-200 rounded-md cursor-pointer" onClick={() => {
        handler()
    }}>
        {props.children}
    </span>
}

function RenderFieldValue({children, tag}) {
    if (tag === "copy") {
        return <CopyToClipboardComponent text={children}>
            {children}
        </CopyToClipboardComponent>
    }
    return <span className="inline-block -mt-1 text-sm font-medium rounded-md text-gray-600 ">
        {children}
    </span>
}

function Item(props) {
    const {icon, label, value, can_add} = props;
    return <div style={{gridTemplateColumns:icon?`1.5rem 1fr auto`: '1fr auto'}} className="grid text-sm items-baseline gap-2 py-1">
        {icon&&<div className="text-gray-500 w-6 flex-none" style={{alignSelf: 'flex-start',paddingTop:'1px'}}>
            <div className="w-4.5 h-4.5">
                {!icon ? <InformationCircleIcon/> : icon}
            </div>
        </div>}
        <div>
        {props.component ? props.component : <RenderFieldValue tag={props.tag}>{renderField({
            ...props,
            label: null,
            tag: true,
            size: "text-sm"
        })}</RenderFieldValue>}
        </div>
        {!value && can_add && <div className="text-gray-500 flex-grow">
            <span>Add</span>
        </div>}
    </div>
}

export default function DetailList({items = []}) {

    return <div className="flex flex-col gap-1">
        {items.map((it, k) => {
            return <Item {...it} key={k}/>
        })}
    </div>
};