import React, {useEffect, useRef, useState} from "react";
import './styles.css';
import {
    CommunityIndexPage, PageLayoutSection
} from "../../../m3/_legacy_components/app-frame/layouts";
import {LeadersTeam} from "./team";
import {useCommunity} from "../../../config/community";
import {buildLeadersDirectory} from "./build-leaders-directory";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {setDocumentTitle} from "../../../m3/utilities/set-document-title";
import {SmartDirectory} from "./smart-directory";
import {m3_icon_map} from "../../../m3/icons/icon-map";
import {getDirectoryViewStacks, handleCreateDefaultStack} from "../../../../api/directory-views";

function buildRole(rid, role_data, ch, dpp) {
    return {
        id: rid,
        title: role_data.name,
        purpose: role_data.purpose,
        link: `/c/${ch}/position/${rid}`,
        member_id: role_data.assignee,
        member_link: `/c/${ch}/member/${role_data.assignee_data.handle}`,
        handle: role_data.handle,
        image: role_data.assignee_data.profile_picture ? `${role_data.assignee_data.profile_picture}` : `${dpp}`,
        name: role_data.assignee_data.name ? role_data.assignee_data.name : "Unassigned",
        assignee_handle: role_data.assignee_data.handle,
    };
}

// todo shared FN
export function smoothScrollTo(scroller_id = "main-content", scroll_to_id = "", yOffset = 0) {
    const scroll_el = document.getElementById(scroller_id);
    const element = document.getElementById(scroll_to_id);
    if (!element) {
        console.log('could not get element')
        return;
    }
    const y = element.getBoundingClientRect().top + scroll_el.scrollTop + yOffset;
    scroll_el.scrollTo({top: y, behavior: 'smooth'});
}

const customGroupSorter = (a, b) => a.rank < b.rank ? 1 : -1 || b.name > a.name ? 1 : -1;

function sortArray(arr, field, direction = "asc") {
    return arr.sort((a, b) => {
        if (direction === "asc") {
            return a[field] > b[field] ? 1 : -1;
        } else {
            return a[field] < b[field] ? 1 : -1;
        }
    })
}

function buildLeadersContent(roles, teams, community, active_group_by, active_filters, all_group_by, active_sort) {
    let sections = [];
    // team.rank
    const ch = community.profile.handle;

    if (!active_group_by) {
        return sortArray([{
            title: "",
            roles: roles.map(r => {
                return buildRole(r.id, r, ch, community.default_member_profile_picture)
            })
        }], active_sort?.field || "title", active_sort?.direction || "asc");
    }

    let not_in_teams = {};

    roles.forEach((role) => {
        not_in_teams[role.id] = true;
    })

    const group_filter = active_filters.filter(g => g.id === "group")[0];

    let at = [];

    if (group_filter && group_filter.value) {
        at = teams.filter(t => group_filter.value.includes(t.id));
    } else {
        at = teams;
    }

    // only groups that have display_leaders = true
    at = at.filter(t => t.display_leaders);

    const t = at.sort(customGroupSorter);

    t.forEach((team, k) => {
        if (team) {

            sections.push({
                title: team.name,
                purpose: team.purpose,
                link: `/c/${ch}/space/${team.id}`,
                handle: team.handle,
                id: team.id,
                roles: []
            });

            if (team.roles_order && team.roles_order.length > 0 && active_sort?.field === 'order') {
                let tro = [...team.roles_order];
                if (active_sort?.direction === "desc") {
                    tro = tro.reverse();
                }
                tro.forEach(rid => {
                    const role_data = roles.filter(r => r.id === rid)[0];
                    if (role_data) {
                        if (not_in_teams[rid]) {
                            delete not_in_teams[rid];
                        }
                        sections[k].roles.push(buildRole(rid, role_data, ch, community.default_member_profile_picture));
                    }
                });
            } else {
                let team_roles = [];
                Object.keys(team.entities.roles).forEach(rid => {
                    const role_data = roles.filter(r => r.id === rid)[0];
                    if (role_data) {
                        if (not_in_teams[rid]) {
                            delete not_in_teams[rid];
                        }
                        team_roles.push(buildRole(rid, role_data, ch, community.default_member_profile_picture));
                    }
                });

                sections[sections.length - 1].roles = sortArray(team_roles, active_sort?.field || "title", active_sort?.direction || "asc");
            }
        }
    });


    const unassigned_ids = Object.keys(not_in_teams);

    /*
    if (unassigned_ids.length > 0) {
        sections.push({
            title: "Additional Roles",
            purpose: "Roles not yet assigned to a team.",
            link: `#`,
            handle: "",
            id: "additional",
            roles: []
        });
        let unassigned_roles = [];
        unassigned_ids.forEach(rid => {
            const role_data = roles.filter(r => r.id === rid)[0];
            if (role_data) {
                unassigned_roles.push(buildRole(rid, role_data, ch, community.default_member_profile_picture));
            }
        });
        sections[sections.length - 1].roles = unassigned_roles.sort((a, b) => (a.title > b.title) ? 1 : -1);
    }
*/
    return sections;
}

function filterPositions(all_positions, active_filters, query, all_filters, all_teams) {
    let ap = [...all_positions];

    if (active_filters.length > 0) {
        active_filters.forEach(filter => {
            const filter_data = all_filters.filter(f => f.id === filter.id)[0];
            if (filter_data) {
                if (filter.id === "unassigned" && !!filter.value) {
                    ap = ap.filter(p => p.assignee === "");
                } else if (filter.id === "group" && !!filter.value) {
                    const team_role_ids = all_teams.filter(t => filter.value.includes(t.id)).map(t => Object.keys(t.entities.roles)).flat();
                    ap = ap.filter(p => team_role_ids.includes(p.id));
                }
            } else {
                console.log('filter not found', filter);
            }
        })
    }

    if (query) {
        return ap.filter(r => {
            return r.name.toLowerCase().includes(query.toLowerCase()) || r?.assignee_data?.name.toLowerCase().includes(query.toLowerCase());
        })
    }

    return ap;
}

const LeadersPageRenderer = (sections = []) => {
    return <div className="pt-4 px-4 space-y-8">
        {sections.map((sec, seckey) => {
            return <LeadersTeam {...sec} key={sec.id || seckey}/>
        })}
        {!sections.length &&
            <div className="text-center text-gray-500 p-8">No positions found.</div>}
    </div>
};

export default function LeadersPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const community = useCommunity();

    const [action, setAction] = useState(null);
    let [searchParams, setSearchParams] = useSearchParams();
    const roles_count = useRef(community.all_roles.length);

    setDocumentTitle(`Leaders`, `${community.profile.name}`);

    useEffect(function () {
        const action = searchParams.has('action')

        if (!!action && searchParams.get('action') === 'create') {
            community.openEditOverlay({
                type: 'add-position',
                size: 'medium'
            })
            navigate(location.pathname);
        }
    }, [searchParams, location])

    useEffect(() => {
        community.updatePositions();
    }, []);

    useEffect(() => {
        console.log("ROLES COUNT", roles_count.current, community.all_roles.length)
        if(roles_count.current !== community.all_roles.length) {
            roles_count.current = community.all_roles.length;
            setAction({
                type: "refresh"
            });
            console.log("REFRESHING")
        }
    }, [community.all_roles]);

    const content = <PageLayoutSection padding_x="" padding_y="" divider={false}>
        <SmartDirectory page_key="leaders" action={action} resetAction={() => setAction(null)}
                        buildSpec={(view_stacks, props) => {
                            const a = buildLeadersDirectory(view_stacks, props?.filter_data?.all_groups ? props.filter_data.all_groups : community.all_teams);
                            /*
                            a.primary_actions.push(
                                <Button onClick={() => {
                                    community.openEditOverlay({
                                        type: 'add-position',
                                        size: 'medium'
                                    })
                                }} key="new" text="New" size="small" intent="primary"/>
                            );

                             */
                            return a;
                        }}
                        getStacks={async () => {
                            return await getDirectoryViewStacks(community.uid, "leaders")
                                .then(vs => {
                                    return vs;
                                })
                        }}
                        getData={async () => {
                            return community.all_roles;
                        }}
                        filterItems={(items, props, spec) => {
                            return filterPositions(items, props.active_filters, props.query, spec.filters, props?.filter_data?.all_groups ? props?.filter_data?.all_groups : community.all_teams)
                        }}
                        buildSections={(filtered_items, props, spec) => {
                            return buildLeadersContent(filtered_items, props?.filter_data?.all_groups ? props?.filter_data?.all_groups : community.all_teams, community, props.active_group_by, props.active_filters, spec.group_by, props.sort)
                        }}
                        filterSections={(sections, props, spec) => {
                            return sections.filter(lsec => lsec.roles.length > 0)
                        }}
                        createDefaultViewStack={async () => {
                            return await handleCreateDefaultStack(community.uid, community.member_id, "leaders", "Title", [
                                {
                                    label: "Unassigned",
                                    id: "unassigned",
                                    filters: [
                                        {
                                            id: "unassigned",
                                            value: true
                                        }
                                    ]
                                }
                            ])
                                .then(() => {
                                    return true;
                                })
                        }}
                        community_uid={community.uid} member_id={community.member_id}
                        renderer={LeadersPageRenderer}/>
    </PageLayoutSection>;

    let header_actions = [];

    header_actions.push({
        type: "icon",
        intent: "primary",
        icon: m3_icon_map.outlines.add,
        onClick: () => {
            community.openEditOverlay({
                type: 'add-position',
                size: 'medium',
                onCloseCb: () => {
                    community
                        .updatePositions();
                }
            })
        }
    })

    return <CommunityIndexPage desktop_slim header={{
        title: "Leaders",
        actions: header_actions
    }}>
        {content}
    </CommunityIndexPage>;
};