import {authFetch} from "../network";

export async function api_editPost(community,pl) {
    return await new Promise((resolve, reject) => {
        const res = (r) => {
            resolve(r);
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            ...pl
        };
        authFetch("/posts/edit", res, res, "POST", {payload});
    })
}