import React, {useEffect, useState} from "react";
import {externalAuthFetch} from "../../../../api/network";
import Button from "../../../m3/_legacy_components/button";
import {FieldLabel} from "../../../m3/_legacy_components/form/field";
import {useIsMobile} from "../../../m3/hooks/is-mobile";
import {utils_strings_replaceAt} from "../../../../common/utilities/strings";

const classes = {
    '0': 'rounded-r-none',
    '1': 'rounded-none -mr-px -ml-px',
    '2': 'rounded-l-none',
    '3': 'rounded-r-none',
    '4': 'rounded-none -mr-px -ml-px',
    '5': 'rounded-l-none'
};

export function PinEntry({
                             len = 6, onSubmit = () => {
    }, setCode = () => {
    }
                         }) {
    const [entries, updateEntries] = useState(Array.from(Array(len), (_, x) => {
        return {
            value: '',
            ref: React.createRef()
        }
    }));
    const [pin, setPin] = useState(new Array(len + 1).join("Z"));
    const [error, setError] = useState('');

    useEffect(function () {
        let f_pin = entries.map(e => e.value).join('');
    }, [entries])

    const is_mobile = useIsMobile();

    function handlePaste(e, index) {
        // only allow numbers
        // if index + 1 === len, is last
        let val = e.clipboardData.getData('Text').trim();

        // let's remove all non-numbers
        val = val.replace(/\D/g, '');

        // now let's see if length matches len
        if (val.length !== len) {
            console.log("invalid length", val.length, len);
            return;
        }

        if (val.length === len && index === 0) {
            // possible paste and is number, lets try

            let ne = [...entries], np = "";
            for (let i = 0; i < len; i++) {
                ne[i].value = val.charAt(i);
                np = `${np}${val.charAt(i)}`;
            }

            np = `${val}`;

            setPin(np);

            updateEntries(ne);

            entries[(len-1)].ref.current.focus();

            onSubmit(np);
        }
    }

    function handleChange(val, index) {
        // only allow numbers
        // if index + 1 === len, is last
        if (isNaN(val)) {
            return;
        }

        let ne = entries.slice();
        let np = `${pin}`;

        ne[index].value = val.charAt(0);
        np = utils_strings_replaceAt(np, index, val.charAt(0));

        setPin(np);

        updateEntries(ne);

        if (val === '') {
// what happens here?
        } else if ((index + 1) === len) {
            onSubmit(np);
        } else {
            entries[(index + 1)].ref.current.focus();
        }
    }

    function handleKeyDown(e,k) {
        if (e.key === 'Backspace') {
            handleBackspace(k);
        }
    }

    function handleBackspace(index) {
        // expected behavior is if index === 0, clear the first item
        // else clear the previous item and focus on it
        let ne = entries.slice();
        let np = `${pin}`;

        if(index===0) {
            ne[0].value = '';
            np = utils_strings_replaceAt(np, 0,'Z');
        } else {
            ne[index].value = '';
            np = utils_strings_replaceAt(np, index,'Z');
        }

        setPin(np);

        updateEntries(ne);

        if(index>0) {
            entries[(index-1)].ref.current.focus();
        }
    }

    return <form autoComplete="off" className="flex max-w-xl">
        {entries.map((en, k) => <div className="flex" key={k}>
            <input onKeyDown={e=>handleKeyDown(e,k)} onPaste={(e) => handlePaste(e, k)} placeholder="•"
                   className={`${is_mobile ? "px-2 w-10 py-2 text-xl" : "px-3 w-14 py-4 text-2xl"} focus:z-10 block border inline-flex  text-center  outline-none rounded-lg shadow-sm new-focus-standard sm:flex-1 border-gray-300 placeholder-gray-500 text-gray-800 ${classes[k.toString()]}`}
                   autoFocus={k === 0} ref={en.ref} value={en.value} type="text"
                   onChange={e => handleChange(e.target.value, k)}/>
            {k === 2 && <div
                className={` ${is_mobile ? "px-2.5 text-2xl" : "px-4 text-3xl"} flex items-center justify-center  text-gray-500`}>-</div>}
        </div>)}
    </form>
}

export function VerifyAccountPin({
                                     username = "",
                                     onSuccess = () => {
                                     },
                                     code_sent = false
                                 }) {
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState('');
    const [phase, setPhase] = useState(code_sent ? 'sent-code' : '');

    function sendCode() {
        setLoading('sending-code');

        const res = (resp) => {
            setPhase('sent-code');
            setLoading('');
        };

        const payload = {
            username: username
        };

        externalAuthFetch("/send-verification-code", res, res, "POST", payload);
    }

    function resendCode() {
        setPhase('resent-code')
    }

    function handlePinSubmit(c) {
        setCode(c);
        verifyCode(c);
    }

    function verifyCode(c) {
        if (loading === 'verifying-code') {
            return;
        }

        setLoading('verifying-code');

        const res = (resp) => {
            console.log("RESP", resp);
            if (resp.data.status === 'invalid') {
                setPhase('invalid-code')
            } else {
                onSuccess(resp.data.auth_code);
                setPhase('verified');
            }
            setLoading('');
        };

        const payload = {
            code: `${c}`,
            username: username
        };

        externalAuthFetch("/check-verification-code", res, res, "POST", payload);
    }

    let a = <div>
        <FieldLabel label="Enter the six-digit code"/>
        <PinEntry len={6} onSubmit={handlePinSubmit} setCode={c => setCode(c)}/>
    </div>;

    return <div className="pt-4 space-y-4">
        {!phase && <div className="space-y-4">
            <div className="text-base text-gray-700">
                It looks like this is your first time signing in. To continue we need to verify your email first.
            </div>

            <Button intent='secondary' fill size='large'
                    onClick={sendCode} text={"Send Verification Code"}
                    loading={loading === 'sending-code'}/>
        </div>}

        {phase === 'invalid-code' && <div className="space-y-4">
            <div className="text-base text-gray-700">
                That code didn't check out, would you like to try another code?
            </div>

            {a}

            <div className="mt-2">
                <a onClick={sendCode} className="link">Resend Code</a>
            </div>
        </div>}

        {(phase === 'sent-code' || phase === 'resent-code') && <div className="space-y-4">
            <div className="text-base text-gray-700">
                <div className="text-green-700 pb-2">Email sent!</div>
                {phase === 'sent-code' &&
                    <div>If you haven't received an email after 2 minutes, you can <a onClick={() => resendCode()}
                                                                                      className="color-link">get a new
                        code</a>.</div>}
                {phase === 'resent-code' && <div>If you still don't received an email, check your spam folder.</div>}
            </div>
            {a}
        </div>}

        {phase === 'verified' && <div className="space-y-4">
            <div className="text-base text-gray-700">
                <div className="text-green-700 pb-2">Email verified!</div>
                <div>Thank you for verifying your email address.</div>
            </div>
        </div>}

    </div>
}