import React, {useEffect, useState} from "react";
import {
    CommunityIndexPage,PageLayoutSection
} from "../../../m3/_legacy_components/app-frame/layouts";
import {useCommunity} from "../../../config/community";
import dayjs from "dayjs";
import {setDocumentTitle} from "../../../m3/utilities/set-document-title";
import {collection, getDocs, limit, query, where} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {SmartDirectory} from "../leaders/smart-directory";
import {buildCalendarDirectory} from "./build-calendar-directory";
import {getFeatureFlagValue} from "../preferences/tabs/advanced";
import {
    calendarBuildEventsSections,
    calendarFilterEvents,
    CalendarPageRenderer, calendarSortEvents,
    getBirthdayMap,
    parseBirthdayMapToEvents,
    SubscribeToCalendar
} from "./utilities";
import {useIsMobile} from "../../../m3/hooks/is-mobile";
import {m3_icon_map} from "../../../m3/icons/icon-map";
import {getDirectoryViewStacks, handleCreateDefaultStack} from "../../../../api/directory-views";
import {CalendarSubscribePopover} from "./subscribe-popover";

export function CalendarPage() {
    const community = useCommunity();
    const [birthdays, setBirthdays] = useState([]);
    const [stacks, setStacks] = useState([]);
    const [modal, setModal] = useState(null);
    const [active_stack, setActiveStack] = useState(null);
    setDocumentTitle(`Calendar`, `${community.profile.name}`);

    useEffect(() => {
        const show_bdays = getFeatureFlagValue('birthdays_calendar');
        if (!show_bdays) {
            return;
        }
        getBirthdayMap(community.uid)
            .then(res => {
                if (res) {
                    setBirthdays(parseBirthdayMapToEvents(res));
                }
            })
    }, [community.uid])
    /*
    buttons.push(<Button onClick={() => {
        alert("doing..");
        const res = () => {
            alert("done");
        };
        authFetch("/build-birthday-map",res,res,"POST",{
            payload: {
                community_uid: community.uid
            }
        })
    }} text="Update Birthday Calendar" />);

     */

    const content = <SmartDirectory page_key="calendar"
                                    buildSpec={(view_stacks, props) => {
                                        const a = buildCalendarDirectory(view_stacks, props?.filter_data?.all_groups ? props.filter_data.all_groups : community.all_teams);
                                        /*
                                        a.primary_actions.push(
                                            <Button onClick={() => {
                                                community.openEditOverlay({
                                                    type: 'add-event',
                                                    size: 'plus'
                                                })
                                            }} key="new" text="New" size="small" intent="primary"/>
                                        );

                                         */
                                        return a;
                                    }}
                                    onChangeViewStacks={(view_stacks) => {
                                        if(view_stacks) {

                                            setStacks(view_stacks[0]?.stack);
                                            if(view_stacks[0]?.stack?.length) {
                                                setActiveStack(view_stacks[0]?.stack[0]?.id);
                                            }
                                        }
                                    }}
                                    getStacks={async () => {
                                        return await getDirectoryViewStacks(community.uid, "calendar")
                                            .then(vs => {
                                                return vs;
                                            })
                                    }}
                                    getData={async () => {
                                        const ref = collection(db, 'community_content', community.uid, 'events');
                                        const today_start = dayjs().startOf('day').toISOString();
                                        //console.log("community.member.group_ids.slice(0, 10))", community.member.group_ids);
                                        if (!community?.member?.group_ids?.length) {
                                            return [];
                                        }

                                        const q = query(ref, where('start_datetime', '>', today_start), where("group_id", "in", community.member.group_ids.slice(0, 10)), limit(50))
                                        // console.log("GET DATA", community.member)
                                        return await getDocs(q)
                                            .then(snap => {
                                                const evts = !snap.empty ? snap.docs.map(doc => {
                                                    return {
                                                        id: doc.id,
                                                        ...doc.data()
                                                    }
                                                }).sort(calendarSortEvents) : [];
                                                return evts;
                                            });
                                    }}
                                    filterItems={(items, props, spec) => {
                                        return calendarFilterEvents(items, props, spec, birthdays);
                                    }}
                                    buildSections={(filtered_items, props, spec) => {
                                        return calendarBuildEventsSections(filtered_items, props, spec, community)
                                    }}
                                    filterSections={(sections, props, spec) => {
                                        return sections;
                                        // return sections.filter(lsec => lsec.roles.length > 0)
                                    }}
                                    createDefaultViewStack={async () => {
                                        return await handleCreateDefaultStack(community.uid, community.member_id, "calendar", "Calendar", [], {
                                            id: "all",
                                            label: "Upcoming",
                                        })
                                            .then(() => {
                                                return true;
                                            })
                                    }}
                                    community_uid={community.uid} member_id={community.member_id}
                                    renderer={CalendarPageRenderer}/>

    let header_actions = [];

    header_actions.push({
        type: "icon",
        icon: m3_icon_map.outlines.cloud,
        onClick: () => {
            setModal("subscribe");
        }
    })

    // todo if can add event
    header_actions.push({
        type: "icon",
        intent: "primary",
        icon: m3_icon_map.outlines.add,
        onClick: () => {
            community.openEditOverlay({
                type: 'add-event',
                size: 'plus'
            })
        }
    })

    return <CommunityIndexPage desktop_slim header={{
        title: "Calendar",
        actions: header_actions
    }}>
        <PageLayoutSection padding_x="" padding_y="" divider={false}>
        {content}
        </PageLayoutSection>

        {modal === "subscribe" && <CalendarSubscribePopover handleClose={()=>{
            setModal(null);
        }} />}
    </CommunityIndexPage>;
}