import {doc, getDoc} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {authFetch} from "../../../../api/network";

export async function getGroupData(uid, id) {
    if (!uid || !id) {
        console.error('getGroupData', uid, id)
        return null;
    }
    const ref = doc(db, 'community_entities', uid, 'groups', id);
    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? {...doc.data(), id: doc.id} : null;
        });
}

export async function handleSetSpaceLocation(community,id,label,place_id) {
    const payload = {
        community_uid: community.id,
        id: id,
        member_id: community.member_id,
        location_place_id: place_id,
        location: label
    };

    return await new Promise((resolve, reject) => {
        const res = (r) => {
            resolve(r);
        };
        authFetch("/groups/edit-location", res, res, "POST", {payload});
    });
}

// TODO
export async function reorderRoles(new_order) {
    return true;
    /*
    const payload = {
        id: id,
        community_uid: community.uid,
        member_id: community.member_id,
        roles: new_order
    };

    setData({
        ...data,
        roles_order: new_order
    })

    const res = () => {

    };

    const err = () => {

    };

    authFetch("/groups/arrange-roles", res, err, "POST", {payload});

     */
}