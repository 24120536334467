import {doc, getDoc} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {authFetch} from "../../../../api/network";


function buildDefaultListData(data, list_id) {
    const context_id = data?.context_id || list_id?.split('__')[1] || "";

    return {
        list_id: data?.list_id || list_id || "",
        context: data?.context || "space",
        context_id: context_id || "",
        label: data?.title || "Loading",
        size: data?.count || "Loading", // count
        views: {
            "table": {
                id: "table",
                name: "Table",
                layout: "table",
                config: JSON.stringify({
                    space: context_id,
                    filters: [],
                    sort: [
                        {
                            field: "about.last_name",
                            dir: "asc",
                            label: "Last Name"
                        }
                    ],
                    attributes: [
                        'location',
                        'occupation',
                        'member-type'
                    ]
                })
            },
            "grid": {
                id: "grid",
                name: "Grid",
                layout: "grid",
                is_default: true,
                config: JSON.stringify({
                    space: context_id,
                    filters: [],
                    sort: [
                        {
                            field: "about.last_name",
                            dir: "asc",
                            label: "Last Name"
                        }
                    ],
                    attributes: [
                        'location',
                        'occupation',
                        'member-type'
                    ]
                })
            }
        }
    }
}

export async function api_createDefaultListData(community, data) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp?.data?.list_id)
        };

        const payload = {
            member_id: community.member_id,
            community_uid: community.uid,
            ...data
        };

        authFetch("/lists/create", res, res, "POST", {payload})
    })
}

export async function api_updateAttributeOverride(ctx, data) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        const payload = {
            ...ctx,
            ...data
        };

        authFetch("/lists/views/update-override", res, res, "POST", {payload})
    })
}
export async function api_createListView(ctx, data) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp?.data?.view_id)
        };

        const payload = {
            ...ctx,
            ...data
        };

        authFetch("/lists/views/create", res, res, "POST", {payload})
    })
}

export async function api_updateListView(ctx, data) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp?.data?.view_id)
        };

        const payload = {
            ...ctx,
            ...data
        };

        authFetch("/lists/views/update", res, res, "POST", {payload})
    })
}

export async function api_deleteListView(ctx, data) {
    return new Promise(resolve => {
        const res = (resp) => {
            console.log("RESPONSE", resp)
            resolve({
                view_id: resp?.data?.view_id, default_view_id: resp?.data?.default_view_id
            })
        };

        const payload = {
            ...ctx,
            ...data
        };

        authFetch("/lists/views/delete", res, res, "POST", {payload})
    })
}

function processData(obj, raw_data) {
    // need to see if the title is loading and count

    return {
        ...obj,
        label: obj.label === "Loading" ? raw_data?.title : obj.label,
        size: obj.size === "Loading" ? raw_data?.count : obj.size,
    }
}

export async function contacts_handleLoadListData(community_uid, list_id, raw_data) {
    const ref = doc(db, 'community_content', community_uid, 'list_views', list_id);
    let dt;
    dt = await getDoc(ref)
        .then(doc => {
            return doc.exists() ? processData({...doc.data(), id: doc.id}, raw_data) : null;
        });

    if (!dt) {
        // doesn't exist, need to create
        const payload = buildDefaultListData(raw_data, list_id);

        const result = await api_createDefaultListData({uid: community_uid, member_id: raw_data?.member_id}, payload);

        if (result) {
            dt = await getDoc(ref)
                .then(doc => {
                    return doc.exists() ? processData({...doc.data(), id: doc.id}, raw_data) : null;
                });
        }
    }

    return dt;
}

export async function handleBulkChanges(community_uid, member_id, changes) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        const payload = {
            member_id: member_id,
            community_uid: community_uid,
            changes: changes.map(ch => {
                return {
                    id: ch.id,
                    field: ch.field,
                    value: ch.value
                }
            })
        };

        authFetch("/members/bulk-change", res, res, "POST", {payload})
    })
}