import React, {useState} from "react"
import './styles.css';
import Lightbox from "../lightbox";
export default function SingleImageViewer({image,onClose=()=>{}}) {
    if(!image) {
        return;
    }
    let url;
    let height, width, color;

    if(typeof image === 'string') {
        url = image;
    } else {
        url = image.url;
        color = image?.color||"#000000";
        height = image?.height;
        width = image?.width;
    }

    const image_obj = {
        url,
        color,
        height,
        width
    };

    return <Lightbox simple items={[image_obj]} onClose={() => onClose(false)}/>
}