import React from "react";
import {m3_icon_map} from "../icons/icon-map";
import M3_A_IconButton from "../atoms/icon-button";
import {useToasts} from "../../config/toasts";
import {copyTextToClipboard} from "../utilities/copy-to-clipboard";

/*
* This is the CopyToClipboard atom
 */

const _props = {
    children: {
        type: "string",
        required: true
    }
};

export default function M3_A_CopyToClipboard({value}) {
    const toasts = useToasts();
    const [copied, setCopied] = React.useState(false);

    function handleCopy() {
        copyTextToClipboard(value, () => {
            setCopied(true);
            toasts.addToast({
                intent: "success",
                text: "Copied to clipboard"
            });

            setTimeout(() => {
                setCopied(false);
            }, 1000);
        });
    }

    let intent, icon;

    if(copied) {
        intent = "success";
        icon = m3_icon_map.outlines["check"];
    } else {
        icon = m3_icon_map.outlines["copy"];
    }

    return <M3_A_IconButton onClick={handleCopy} intent={intent} square size="sm" icon={icon}/>
}