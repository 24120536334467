import React from "react";
import {SidebarSectionEditor} from "../../../../m3/layouts/layout/section-builder/sidebar-wrapper";

export function MemberPreferencesSidebarNav({community}) {

    return <div className="grid gap-4" style={{}}>
        {community.is_admin && <div>
            <SidebarSectionEditor/>
        </div>}
    </div>
}