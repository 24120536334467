import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {getMemberProfileById} from "../../../config/community";
import {getEntityFollowData} from "../follow-button/api";
import EntityProfileCard from "../entity-profile-card";
import Button from "../button";
import ProfileFieldRenderer from "../profile-field-renderer";
import {PreviewHeader} from "./index";
import {InlineLoader} from "../admin-activity";
import {buildMemberProfile, buildMemberProfileSections} from "../../../routes/community/member/member-profile-utils";
import {getMemberFlags} from "../../../routes/community/member/utils";
import {CommonMemberProfile} from "../../../routes/community/member/profile";
import {PageLayoutBlock, PageLayoutSection} from "../app-frame/layouts";

function validateData(dt) {
    return dt && !!dt.about && !!dt.handle && !!dt.id;
}

export function MemberPreview({onClose, id, community, unaverse, data}) {
    const navigate = useNavigate();
    const [dt, setDt] = useState(validateData(data) ? data : null);
    const [follow_data, setFollowData] = useState({});

    useEffect(function () {
        if (!validateData(data) && id) {
            getMemberProfileById(community.id, id)
                .then(pr => {
                    setDt({...pr, id: id});
                })
        } else if (data && id) {
            setDt({...data, id: id});
        }
    }, [data, id])

    useEffect(function () {
        if (dt && dt.id) {
            getEntityFollowData(community, dt.id, "member")
                .then((fd) => {
                    setFollowData(fd)
                })
        }
    }, [dt])

    if (!dt) {
        return <div className="">
            <PreviewHeader title="Member" onClose={() => onClose()}/>
            <div className="flex justify-center p-32 items-center">
                <InlineLoader/>
            </div>
        </div>
    }

    const flags = getMemberFlags(dt.id, dt, community);
    const fns = {};

    if (follow_data) {
        flags.follows_you = !!follow_data['members'] && follow_data['members'][community.member_id];
    }
    /*
     <FollowButton updateFollowing={(fl) => {
            }} id={dt.id} type="member"/>
     */
    const m = buildMemberProfile(dt, flags, community, unaverse, fns);

    const profile_actions = {};

    /*
      const s = buildMemberProfileSections(dt, community.custom_fields, community, unaverse, flags, fns)
     <div className='border-t mt-2 border-gray-200'>
            <ProfileFieldRenderer my_profile={dt.id===community.member_id} preview sections={s}/>
        </div>
     */
    return <>
        <PreviewHeader title="Profile" onClose={() => onClose()}/>
        <PageLayoutSection padding_x="" divider={false}>
            <EntityProfileCard onTitleClick={() => {
                navigate(`/c/${community.domain}/member/${dt.handle}`);
            }} preview {...m} />
            <PageLayoutBlock padding_x="px-4 sm:px-6">
                <div className="flex gap-2">
                <Button text="View Profile" onClick={() => {
                    community.closePreviewOverlay();
                    navigate(`/c/${community.domain}/member/${dt.handle}`);
                }}/>

                {community.flags.is_admin && <Button text="Manage" onClick={() => {
                    community.openEditOverlay({
                        type: 'manage-member',
                        data: dt,
                        handle: dt.handle
                    })
                }}/>}
                </div>
            </PageLayoutBlock>
        </PageLayoutSection>
        <PageLayoutSection>
            <PageLayoutBlock>
                <CommonMemberProfile profile_actions={profile_actions} mode="view" member_id={dt?.id} member_data={dt}/>
            </PageLayoutBlock>
        </PageLayoutSection>
    </>
}