import React from "react";
import {AuthorPoll} from "./author";
import SimplePortal from "../simple-portal";
import Modal from "../modal";
import EntityTabs from "../entity-tabs";
import MetaHeader from "../meta-header";
import {ModalBody, ModalContentBlock, ModalHeader} from "../preview-overlay";

export function NewPollModal({onClose, onCreatePoll}) {
    return <SimplePortal center>
        <Modal min_height="40vh" center size="medium">
            <ModalHeader onClose={onClose} title="Add Poll to Post"/>

            <ModalBody>
                <ModalContentBlock>
                    <AuthorPoll onNewPoll={(poll_id) => {
                        onCreatePoll(poll_id);
                    }}/>
                </ModalContentBlock>
            </ModalBody>
        </Modal>
    </SimplePortal>
}