import React, {useEffect, useRef, useState} from 'react';
import Field from "../../../../m3/_legacy_components/form/field";
import {
    buildCustomFields,
    EditMemberCustomFields
} from "../../../../routes/community/manage-member/edit-sections/custom";
import {authFetch} from "../../../../../api/network";
import FormFieldsRenderer, {updateFieldValue} from "../../../../m3/_legacy_components/form-fields-renderer";
import {EditMemberContact} from "../../../../routes/community/manage-member/edit-sections/contact";
import {VerifyAccountPin} from "../../../../routes/auth/verify-pin";
import {SectionHeader} from "../../../../m3/_legacy_components/entity-grid";
import {Avatar} from "../../../../m3/_legacy_components/avatar";
import EditImageModal from "../../../../m3/_legacy_components/edit-image-modal";
import AddSecondaryEmail, {sendEmailVerificationCode} from "../../../../m3/_legacy_components/user-email-manager";
import {AdMeActionChangeAccountEmail} from "../../../../routes/community/member/change-account-email";

import {PrettyCheckbox} from "../../../../m3/_legacy_components/toggle";
import {utils_objects_unique} from "../../../../../common/utilities/objects";
import {buildImageUrl} from "../../../../../common/utilities/images";
import {data__member_profile_sections} from "../../../../../common/data/profile-sections";
import {data__member_profile_fields} from "../../../../../common/data/data-fields";
import {getNestedValue} from "../../../../../common/utilities/general";
import {buildCommonMemberProfileSections} from "../../../../routes/community/member/profile/utilities";
import {buildStandardFields} from "../../../../routes/community/manage-member/edit-sections/standard";

function handleUpdateProfile(update, updated_fields, context) {
    const payload = {
        ...update,
        community_uid: context.uid,
        id: context.member_id,
        member_id: context.member_id,
        user_uid: context.member.user_uid,
        _updated_fields: updated_fields
    };
    console.log("handleUpdateProfile", payload)
    const r = async () => {
        console.log("update saved");
    };

    authFetch('/members/update-own-profile', r, r, "POST", {payload});
}

export function FHB_Contact({state = {}, context = {}, handlers = {}}) {
    const {updateStateValue, handleOnBlur} = handlers;
    const [dt, setDt] = React.useState({
        ...context.member
    })
    const valueRef = React.useRef({});
    const [changes_made, setChangesMade] = React.useState(false);
    const [modal, setModal] = React.useState(null);
    const [phase, setPhase] = React.useState('');
    const [new_account_email, setNewAccountEmail] = React.useState('');
    const [loading, setLoading] = React.useState('');
    const firstRun = useRef(true);

    useEffect(function () {
        setDt({...context.member})
    }, [context.member])

    // todo verify email if not verified
    // todo allow changing account email
    // todo pass account_email_verified to parent state
    // todo enable add email modal
    function handleChange(accessor, new_value, old_value, field) {
        //   console.log("handleChange", accessor, new_value, old_value, field)
        if (JSON.stringify(new_value) === JSON.stringify(old_value)) {
            return;
        }
        setDt({
            ...updateFieldValue(accessor, new_value, dt)
        })
        setChangesMade(true);
    }

    React.useEffect(() => {
        if (dt.account_email && dt.emails_status && dt.emails_status[dt.account_email] && dt.emails_status[dt.account_email].status === 'verified') {
            if (firstRun.current) {
                firstRun.current = false;
                handlers.updateStateValue('account_email_verified', true);
                return;
            }

        }
    }, [])

    React.useEffect(() => {
        valueRef.current = {
            changes_made,
            phone: dt.contact.phone,
            phone_services: dt.phone_services || {}
        }
    }, [changes_made, dt]);

    const fns = {
        addEmail: () => {
            setModal('add-secondary')
        }
    };

    React.useEffect(function () {
        return () => {
            handleSave()
        }
    }, [])

    function isValid() {
        return true;
    }

    function handleSave() {
        if (!isValid() || !valueRef.current.changes_made) {
            return;
        }
        handleUpdateProfile({
            contact: {
                phone: valueRef.current.phone
            },
            phone_services: valueRef.current.phone_services || {}
        }, ['contact.phone', 'phone_services'], context);
    }

    function changeAccountEmail(new_email) {
        console.log("CAN CHANGE")
        setNewAccountEmail(new_email);
        setModal('');
        setPhase('change-account-email')
        handlers.updateStateValue('account_email_verified', false);

        sendEmailVerificationCode(context.user_uid, new_email, true)
            .then((r) => {
                if (!r) {
                    return;
                }
                if (r.data.ok === 'yes') {
                    setPhase('verify-account-email')
                } else {

                }
            })
        // TODO
        /*
        1. send verification code to new email
        2. verify code
        3. change email
        4. refresh state
         */
        /*
        handleChangeMemberAccountEmail(community,dt.user_uid,dt.id,new_email)
            .then((resp) => {
                setLoading('');
                setModal("");
                console.log("RESP", resp);
                if (resp.data.ok === 'yes') {
                    // history.push(`/${community.data.handle}/admin/member/${resp.data.id}`);
                    toasts.addToast({text: `Account Email changed`, intent: 'success'});
                    if (resp.data && resp.data.member) {
                        console.log("ACCOUNT EMAIL CHANGED",resp.data.member)
                        if(cb) {
                            cb();
                        }
                        updateDt({...dt, ...resp.data.member})
                    }
                } else {
                    // no error is shown
                    toasts.addToast({
                        text: resp.data.code && code_messages[resp.data.code] ? code_messages[resp.data.code] : "Something went wrong..",
                        intent: 'danger'
                    });
                }
            })
         */
    }

    function handleChangeAccountEmail(code) {
        console.log("handleChangeAccountEmail", code)
        const payload = {
            auth_code: code,
            member_id: context.member_id,
            community_uid: context.community_uid,
            id: context.member_id,
            new_email: new_account_email,
            member_user_uid: context.user_uid,
            user_uid: context.user_uid,
        };

        return new Promise(resolve => {
            const res = (resp) => {
                console.log("DONE")
                // TODO I would need to sign out and then sign back in with the new account since a new email is used
                if (context.refreshData) {
                    context.refreshData();
                }
                resolve(resp);
            };

            authFetch("/members/change-account-email", res, res, "POST", {payload});
        })
    }

    /*
       return <div>
           <VerifyAccountPin username={dt.account_email} onSuccess={(reset_pw_code) => {
               console.log("reset_pw_code", reset_pw_code);
           }}/>
       </div>
     */
    if (phase === 'change-account-email' || phase === 'verify-account-email') {
        return <div>
            <div>
                CHANGE ACCOUNT EMAIL {new_account_email}
            </div>

            {phase === 'verify-account-email' &&
                <VerifyAccountPin code_sent username={dt.account_email} onSuccess={(reset_pw_code) => {
                    console.log("reset_pw_code", reset_pw_code);
                    handleChangeAccountEmail(reset_pw_code)
                }}/>}
        </div>
    }

    /*
    click <span onClick={()=>{
                    setModal('change-email')
            }} className="underline cursor-pointer">here to change it</span>
     */

    return <div>

        <div className="space-y-4">
            <EditMemberContact fill flow={'flow'} fns={fns} data={dt} onChange={handleChange}/>

            <div className="text-xs text-gray-600">
                Your account email is <span className="font-medium">{dt.account_email}</span>, contact your community
                admins if you would like to have it changed.
            </div>
        </div>

        {modal === "add-secondary" && <AddSecondaryEmail
            use_code
            user_uid={context.user_uid}
            account_email={dt.account_email}
            community_uid={context ? context.community_uid : ""}
            onClose={() => {
                if (context.refreshData) {
                    context.refreshData();
                }
                setModal(null);
            }}/>}
        {modal === 'change-email' && <AdMeActionChangeAccountEmail
            use_code
            user_uid={context.user_uid}
            account_email={dt.account_email}
            community_uid={context ? context.community_uid : ""}
            default_email={""}
            loading={loading === 'changing-account-email'}
            onConfirm={(h) => changeAccountEmail(h)}
            onClose={() => {
                setModal("");
            }} onCancel={() => {
            setModal("");
        }}
        />}
    </div>
}

function PolicyCheckbox({children, onChange, active, label}) {
    return <div className="space-y-2">
        <PrettyCheckbox onClick={onChange} active={active} label={children}/>
    </div>
}

/*
   return <div className="space-y-4">
        <div className="text-sm text-gray-800">
            Before we continue, we need to agree on some terms:
        </div>
        <PolicyCheckbox label="I agree to the Unaty Data Privacy Policy for Users">
            🗃️ Unaty collects usage data, technical data, and more, but not the contents you maintain in your community. Please read the full data <a>privacy policy</a>.
        </PolicyCheckbox>

        <PolicyCheckbox label={"I agree to the Terms of Use and the Code of Conduct for Users"}>
            🤝 You can be invited to communities, which contents are managed by an organization. Please read the full <a>usage terms</a>. Once you’re in, we want to respect the law and human dignity. Please read the full <a>code of conduct</a>.
        </PolicyCheckbox>
    </div>
 */
export function FHB_GlobalPolicies({state = {}, meta_map = {}, context = {}, handlers = {}}) {
    const [privacy_policy, setPrivacyPolicy] = React.useState(false);
    const [code_of_conduct, setCodeOfConduct] = React.useState(false);
    const [terms_of_use, setTermsOfUse] = React.useState(false);
    const isFirstRun = useRef(true);
    const valueRef = React.useRef({
        privacy_policy,
        code_of_conduct,
        terms_of_use
    });

    React.useEffect(function () {
        return () => {
            if (meta_map && meta_map.user_policies_to_accept) {
                if (meta_map.user_policies_to_accept.length > 0) {
                    handleAcceptPolicies();
                }
            }

        }
    }, [])

    function handleAcceptPolicies() {
        const res = (result) => {
        };

        let payload = {
            policies: []
        };

        meta_map.user_policies_to_accept.forEach((policy) => {
            payload.policies.push({
                slug: policy.slug,
                version: policy.version
            });
        })

        authFetch("/users/accept-many-policies", res, res, "POST", {payload});
    }

    useEffect(function () {
        if (meta_map && meta_map.user_policies_to_accept) {
            if (meta_map.user_policies_to_accept.length === 0) {
                // no policies need accepting
                if (isFirstRun.current && meta_map) {
                    isFirstRun.current = false;
                    handlers.updateStateValue(['code_of_conduct', 'privacy_policy', 'terms_of_use'], [true, true, true]);
                }
            }
        }
    }, [meta_map])

    useEffect(function () {
        if (meta_map && meta_map.user_policies_to_accept) {
            if (meta_map.user_policies_to_accept.length > 0) {
                handlers.updateStateValue(['code_of_conduct', 'privacy_policy', 'terms_of_use'], [code_of_conduct, privacy_policy, terms_of_use]);

                valueRef.current = {
                    code_of_conduct,
                    privacy_policy,
                    terms_of_use
                };
            }
        }
    }, [code_of_conduct, privacy_policy, terms_of_use])


    if (meta_map && meta_map.user_policies_to_accept) {
        if (meta_map.user_policies_to_accept.length === 0) {
            // no policies need accepting
            return null;
        }
    }

    const {updateStateValue, handleOnBlur} = handlers;

    return <div className="space-y-4">
        <div>
            <SectionHeader title="Review data policy & terms of use" size="text-lg"/>
            <div className="text-sm text-gray-800 pt-1">
                Unaty collects usage and technical data, not contents inside your community which are managed and owned
                by your organization.
            </div>
        </div>
        <PolicyCheckbox onChange={() => {
            setPrivacyPolicy(!privacy_policy)
        }} active={!!privacy_policy}>
            I agree to the Orange Data <a target="_blank" rel="nofollow"
                                         href="https://theorange.app/policies/unaty-usage-terms-for-users"
                                         className="link">Privacy Policy</a> for Users
        </PolicyCheckbox>
        <PolicyCheckbox onChange={() => {
            setTermsOfUse(!terms_of_use)
            setCodeOfConduct(!code_of_conduct);
        }} active={!!terms_of_use}>
            I agree to the <a target="_blank" rel="nofollow"
                              href="https://theorange.app/policies/unaty-usage-terms-for-users" className="link">Terms
            of Use</a> and the <a rel="nofollow" target="_blank"
                                  href="https://theorange.app/policies/unaty-community-code-of-conduct-for-users"
                                  className="link">Code of Conduct for
            Users</a>
        </PolicyCheckbox>
    </div>
}

export function FHB_FullName({state = {}, context = {}, handlers = {}}) {
    const {updateStateValue, handleOnBlur} = handlers;
    const [profile_picture, setProfilePicture] = React.useState(context.member.profile_picture || "");
    const [profile_picture_color, setProfilePictureColor] = React.useState(context.member.profile_picture_color || "");
    const [first_name, setFirstName] = React.useState(context.member.about.first_name || "");
    const [last_name, setLastName] = React.useState(context.member.about.last_name || "");
    const [changes_made, setChangesMade] = React.useState(false);
    const [modal, setModal] = useState('');
    const valueRef = React.useRef({});

    React.useEffect(() => {
        updateStateValue(['first_name', 'last_name', 'profile_picture', 'profile_picture_color'], [first_name, last_name, profile_picture, profile_picture_color]);
        valueRef.current = {
            changes_made,
            first_name,
            profile_picture,
            profile_picture_color,
            last_name
        }
    }, [first_name, changes_made, last_name, profile_picture, profile_picture_color]);

    React.useEffect(function () {
        return () => {
            handleSave()
        }
    }, [])

    function isValid() {
        return first_name && last_name;
    }

    function handleBlur() {
        updateStateValue(['first_name', 'last_name', 'profile_picture', 'profile_picture_color'], [first_name, last_name, profile_picture, profile_picture_color]);

        handleOnBlur();
    }

    function handleSave() {
        if (!isValid() || !valueRef.current.changes_made) {
            return;
        }
        handleUpdateProfile({
            about: {
                first_name: valueRef.current.first_name,
                last_name: valueRef.current.last_name
            },
            profile_picture: valueRef.current.profile_picture,
            profile_picture_color: valueRef.current.profile_picture_color
        }, ['about.first_name', 'about.last_name', 'profile_picture', 'profile_picture_color'], context);
    }

    const onClick = () => {
        setModal('edit-profile-picture');
    };

    const openEditProfilePicture = () => {
        setModal('edit-profile-picture');
    };

    const handleProfilePictureChange = (url, color) => {
        setProfilePicture(url);
        setProfilePictureColor(color);
        setChangesMade(true);
        setModal('');
    };

    return <div className="space-y-6">
        <div>
            <div className="flex justify-center text-center">
                <div className="text-center">
                    <Avatar edit={openEditProfilePicture} onClick={onClick} large={true}
                            url={buildImageUrl(profile_picture)} border
                            size={"h-32 w-32"}/>
                    <div onClick={openEditProfilePicture}
                         className="font-medium pt-2 text-sm text-gray-500 cursor-pointer">
                        {profile_picture ? "Change Photo" : "Add a Photo"}
                    </div>
                </div>
            </div>
        </div>
        <div className="grid gap-4 grid-cols-2">
            <Field type={"text"} autoFocus={!first_name} placeholder={"Jane"} onBlur={() => {
                handleBlur();
            }} label={'First Name'} onChange={(c, v) => {
                setFirstName(v);
                setChangesMade(true);
            }} value={first_name}/>

            <Field type={"text"} autoFocus={first_name && !last_name} placeholder={"Appleseed"} onBlur={() => {
                handleBlur();
            }} label={'Last Name'} onChange={(c, v) => {
                setLastName(v);
                setChangesMade(true);
            }} value={last_name}/>
        </div>

        {modal === "edit-profile-picture" &&
            <EditImageModal onChange={handleProfilePictureChange} onClose={() => setModal('')}/>}
    </div>
}

function buildUpdateObject(updated_fields, dt) {
    let update_object = {};
    updated_fields.forEach((field) => {
        // fields can be nested
        let field_parts = field.split('.');

        if (field_parts.length === 1) {
            update_object[field] = dt[field];
        } else if (field_parts.length === 2) {
            if (!update_object[field_parts[0]]) {
                update_object[field_parts[0]] = {};
            }
            update_object[field_parts[0]][field_parts[1]] = getNestedValue(field, dt);
        } else if (field_parts.length === 3) {
            if (!update_object[field_parts[0]]) {
                update_object[field_parts[0]] = {};
            }
            if (!update_object[field_parts[0]][field_parts[1]]) {
                update_object[field_parts[0]][field_parts[1]] = {};
            }
            update_object[field_parts[0]][field_parts[1]][field_parts[2]] = getNestedValue(field, dt);
        }
    });
    return update_object;

}

export function FHB_ProfileFieldsSection({state = {}, block = {}, context = {}, handlers = {}}) {
    const {updateStateValue, handleOnBlur} = handlers;
    const [updated_fields, setUpdatedFields] = React.useState([]);
    const [dt, updateDt] = React.useState({...context.member});

    const valueRef = React.useRef({});

    useEffect(() => {
        valueRef.current = {
            updated_fields,
            dt
        }
    }, [updated_fields,dt]);

    React.useEffect(() => {
        return () => {
            handleSave()
        }
    }, [])

    function handleOnChange(accessor,new_value) {

        const old_value = getNestedValue(accessor, dt);

        if (JSON.stringify(new_value) === JSON.stringify(old_value)) {
            return;
        }

        updateDt({
            ...updateFieldValue(accessor, new_value, dt)
        })
        setUpdatedFields(utils_objects_unique([...updated_fields, accessor]))
    }

    function isValid() {
        return true;
    }

    function handleSave() {
        if (!isValid() || valueRef?.current?.updated_fields?.length === 0) {
            return;
        }

        const update_object = buildUpdateObject(valueRef?.current?.updated_fields, valueRef?.current?.dt);

        handleUpdateProfile(update_object, valueRef.current.updated_fields, context);
    }

    const section_metadata = buildCommonMemberProfileSections("edit", dt.id, dt, context?.profile_definitions?.sections, context, block.type)[0];

    if(!section_metadata || !section_metadata?.visible_fields?.length) {
        return <div>
            Invalid section.
        </div>;
    }

    const fields = buildStandardFields(section_metadata.visible_fields, dt, context, {}, handleOnChange, {
        col_span: 2
    });

/*
    const fields = section_metadata.visible_fields.map(field=>{
        return {
            label: field.label,
            accessor: field?.accessor,
            type: field.type,
            col_span: 2,

            disabled: !field?.can_edit,
        }
    });

 */

    return <div>
        <FormFieldsRenderer onChange={handleOnChange} data={dt} fields={fields}/>
    </div>
}

export function FHB_CustomFieldsSection({state = {}, block = {}, context = {}, handlers = {}}) {
    const {updateStateValue, handleOnBlur} = handlers;
    const [updated_fields, setUpdatedFields] = React.useState([]);
    const [dt, updateDt] = React.useState({...context.member});

    const section_index = context.custom_fields.findIndex(c => c.id === block.id);
    const section_data = context.custom_fields[section_index];
    const valueRef = React.useRef({});

    React.useEffect(() => {
        //    updateStateValue(['first_name','last_name'], [first_name,last_name]);
        valueRef.current = {
            updated_fields,
            dt
        }
    }, [updated_fields, dt])

    React.useEffect(() => {
        return () => {
            handleSave()
        }
    }, [])

    function isValid() {
        return true;
    }

    function handleSave() {
        console.log("handle save")
        if (!isValid() || valueRef?.current?.updated_fields.length === 0) {
            return;
        }
        const update = {
            custom_fields: {
                ...valueRef.current.dt.custom_fields
            }
        };
        console.log("update", update)
        handleUpdateProfile(update, valueRef.current.updated_fields, context);
    }

    function handleChange(accessor, new_value, old_value, field) {
        if (JSON.stringify(new_value) === JSON.stringify(old_value)) {
            return;
        }
        updateDt({
            ...updateFieldValue(accessor, new_value, dt)
        })
        setUpdatedFields(utils_objects_unique([...updated_fields, accessor]))
    }

    console.log("REF VALUE", valueRef.current)

    const section_metadata = buildCommonMemberProfileSections("edit", dt.id, dt, context?.profile_definitions?.sections, context, block.id)[0];

    console.log("section data",block,section_metadata)
    if(!section_metadata || !section_metadata?.visible_fields?.length) {
        return <div>
            Invalid section.
        </div>;
    }

    const fields = buildCustomFields(section_metadata.visible_fields, context, dt.id === context.member_id);

    const fns = {};

    return <div>
        <EditMemberCustomFields fill community={context} flow={"flow"} fns={fns} data={dt}
                                onChange={handleChange} fields={fields}/>
    </div>
}