import React, {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {externalAuthFetch} from "../../../../api/network";
import {FullScreenLoader} from "../onboarding";
import M3_A_BlankSlate from "../../../m3/atoms/blank-slate";
import ExternalAuthPage from "../../../m3/_legacy_components/external-auth-page";
import {ExternalPolicyWrapper} from "./policy";
import {ExternalOptInWrapper} from "./opt-in";
import {buildImageUrl} from "../../../../common/utilities/images";

/*
This is the entry point for the external community page.
it will be used as an external view spot for community content and member actions so they don't have to log in
viewing policies, events, opt-ing in will be possible here
this will work based off url parameters like ?member_id=1234&page=policy&content_id=privacy-policy
 */

const pages = {
    'policy': {
        required_props: ['content_id'],
    },
    'event': {},
    'opt-in': {},
    'data-check': {}
};

async function handleValidateToken(token, domain, member_id) {

    return new Promise(resolve => {
        const res = (resp) => {
            if (!resp || !resp.data) {
                return resolve({
                    ok: "no",
                    data: {
                        member: {},
                        community: {}
                    }
                });
            }
            return resolve(resp.data);
        };

        externalAuthFetch("/external-token-page-check", res, res, "POST", {
            payload: {
                token,
                domain,
                member_id
            }
        })
    });
}

export default function CommunityExternalPage() {
    const {domain} = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const token = searchParams.get('token');

    const [page, setPage] = useState(searchParams.get('page') || "");
    const [member_id, setMemberId] = useState(searchParams.get('member_id') || "");
    const [content_id, setContentId] = useState(searchParams.get('content_id') || "");

    const [token_is_valid, setTokenIsValid] = useState(false);
    const [loading, setLoading] = useState(true);

    const [member_data, setMemberData] = useState(null);
    const [community_data, setCommunityData] = useState(null);

    useEffect(function () {
        validateToken();
    }, [page, member_id, content_id])

    function validateToken() {
        handleValidateToken(token, domain, member_id)
            .then((result) => {
                console.log("VALIDATE TOKEN RESULT", result)
                if (result.ok === "yes" && result.data.community && result.data.member) {
                    setMemberData(result.data.member);
                    setCommunityData(result.data.community);
                    setTokenIsValid(true);
                    setLoading(false);
                } else {
                    setTokenIsValid(false);
                    setLoading(false);
                }
            })
    }

    function renderContent() {
        switch (page) {
            case 'policy':
                return <ExternalPolicyWrapper token={token} member_id={member_id} member={member_data}
                                      community={community_data} policy_slug={content_id}/>
            case 'event':
                return <div>
                    Event
                </div>
            case 'opt-in':
                return <ExternalOptInWrapper token={token} member_id={member_id} member={member_data}
                                             community={community_data} />
            case 'data-check':
                return <div>
                    Data Check
                </div>
            default:
                return <div>
                    Not found... blank slate?
                </div>
        }
    }

    const invalid_page_content = <div className="h-screen w-full">
        <M3_A_BlankSlate
            height="h-screen"
            title={"Invalid Link"}
            description={"The link you clicked is invalid or expired."}
            button={{
                children: "Back to Home",
                intent: "primary",
                onClick: () => {
                    navigate("/");
                }
            }}
        />
    </div>;

    if(!loading && !token_is_valid) {
        return invalid_page_content;
    }

    if (loading || !community_data || !member_data) {
        return <FullScreenLoader />
    }

    if (!token || !domain || !page || !pages[page] || !member_id) {
        return invalid_page_content;
    }

    // check if required props are set
    const required_props = pages[page].required_props;

    if (required_props) {
        for (let i = 0; i < required_props.length; i++) {
            if (!searchParams.get(required_props[i])) {
                return invalid_page_content;
            }
        }
    }

    const styles = {
        image: buildImageUrl(community_data?.profile_picture),
        background_image: buildImageUrl(community_data?.branding?.sign_in_cover_photo, ""),
        color: community_data?.branding?.color
    };

    return (
        <ExternalAuthPage bg_image={styles?.background_image || "/images/custom/seven.jpg"}
                          image={styles.image || ""}
                          show_logo={false}>
            {renderContent()}
        </ExternalAuthPage>
    )
}