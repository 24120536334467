export const data_languages_map = {
    'en': "English",
    'de': "German"
}

export const data_languages_arr = Object.entries(data_languages_map).map(([key, value]) => {
    return {
        value: key,
        label: value
    }
})