import React from 'react';

function reorder(obj) {
    const entries = Object.entries(obj).sort((a, b) => (a[1] < b[1]) ? 1 : -1);

    let final = {};

    entries.forEach((ent,index) => {
        final[ent[0]] = index+1;
    })

    return final;
}

export function voting_handleSelection(id, vote, poll, metadata) {
    let nv = {...vote};
    const {voting_method} = poll;
    const len = Object.keys(nv).length;
    if (voting_method === 'rating-number') {
        if (nv.hasOwnProperty(id)) {
            delete nv[id];
        } else {
            return {
                [id]: true
            }
        }
    } else if (voting_method === 'rating-reaction') {
        if (nv.hasOwnProperty(id)) {
            delete nv[id];
        } else {
            return {
                [id]: true
            }
        }
    } else if (voting_method === 'single-choice') {
        if (nv[id]) {
            delete nv[id];
        } else {

            return {
                [id]: true
            }
        }
    } else if (voting_method === 'ranked-choice-all') {
        // todo re-adjust
        if (nv[id]) {
            delete nv[id];
            nv = reorder(nv);
        } else {
            nv[id] = len+1;
        }
    } else if (voting_method === 'ranked-choice-any') {
        if (nv[id]) {
            delete nv[id];
            nv = reorder(nv);
        } else {
            nv[id] = len+1;
        }
    } else if (voting_method === 'event-datetime') {
        console.log("event-datetime",id,nv)
        nv[id] = metadata.value;
    }
    return nv;
}

function numberLargestInArray(it,totals) {
    const arr = Object.entries(totals).filter(a=>a[0]!=='total').map(a=>a[1]);

const max = Math.max(...arr);
    return it===max;
}

export function voting_getIsWinner(poll,id,vote,totals,status) {
    const is_selected = vote[id];
    if(status==='open') {
        return false;
    }

    if (poll.voting_method === 'single-choice') {
        return numberLargestInArray(totals[id],totals);
    } else if(poll.voting_method==='ranked-choice-all') {
        return totals.winner === id;
    }  else if(poll.voting_method==='ranked-choice-any') {
        return numberLargestInArray(totals[id],totals);
    }

    return false;
}

export function voting_shouldDisableButton(poll, vote) {

    const {voting_method} = poll;
    const keys = Object.keys(vote);
    if(poll.status==='draft') {
        return true;
    }

    if (voting_method === 'single-choice') {
        return !keys.length;
    } else if(voting_method==='ranked-choice-all') {
        return keys.length!==poll.choices.length;
    }  else if(voting_method==='ranked-choice-any') {
        return keys.length===0;
    } else if(voting_method === 'rating-number') {
        return !keys.length;
    } else if(voting_method === 'rating-reaction') {
        return !keys.length;
    } else if(voting_method === 'event-datetime') {
        return !keys.length || !Object.values(vote).filter(a=>a!==-1).length;
    }

    return false;
}

const mocks = {
    choices: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
    ],
    choices_data: {
        "a": {
            text: "James"
        },
        "b": {
            text: "Tom"
        },
        "c": {
            text: "Amanda"
        },
        "d": {
            text: "Jane"
        },
        "e": {
            text: "Jack"
        },
        "f": {
            text: "Russell"
        }
    },
    // winner should be
    votes: {
        "a": {
            "m1": 1,
            "m2": 4,
            "m3": 3,
            "m4": 1,
            "m5": 2,
            "m6": 1,
        },
        "b": {
            "m1": 4,
            "m2": 1,
            "m3": 4,
            "m4": 5,
            "m5": 6,
            "m6": 2,
        },
        "c": {
            "m1": 3,
            "m2": 2,
            "m3": 6,
            "m4": 6,
            "m5": 3,
            "m6": 5,
        },
        "d": {
            "m1": 2,
            "m2": 3,
            "m3": 2,
            "m4": 2,
            "m5": 1,
            "m6": 3,
        },
        "e": {
            "m1": 5,
            "m2": 5,
            "m3": 1,
            "m4": 3,
            "m5": 4,
            "m6": 4,
        },
        "f": {
            "m1": 6,
            "m2": 6,
            "m3": 5,
            "m4": 2,
            "m5": 5,
            "m6": 6,
        }
    },
    voted: {
        "m1": true,
        "m2": true,
        "m3": true,
        "m4": true,
        "m5": true,
        "m6": true
    }
}

function getRoundVotes(round,choice_id,votes) {
   // console.log('getRoundVotes',choice_id,votes)
    const entries = Object.entries(votes[choice_id]);
    return entries.filter(b=>b[1]===round).length;
}

function analyzeRound(index,choices,choices_data,votes,voted,elims) {
    const voters = Object.keys(voted);
    const voter_count = voters.length;

    const majority_threshold = voter_count / 2;

    let winner = "";
    let round = {};
    let eliminated = [];

    let round_votes = {};

    let choices_after_elims = choices.filter(a=>!elims.includes(a));

    choices_after_elims.forEach(chid=>{
        const r_votes = getRoundVotes(1,chid,votes);
        round_votes[chid] = r_votes;
        if(r_votes>majority_threshold) {
            winner = chid;
        }
    })

    round.votes = {...round_votes};

    const round_tallies = Object.entries(round_votes).sort((a, b) => (a[1] < b[1]) ? 1 : -1);

    if(!winner) {
        // if no winner eliminate one with least
        eliminated.push(round_tallies[(round_tallies.length-1)][0]);
    }

    round.eliminated = [...eliminated];

    return {
        eliminated,
        winner: winner,
        round: round
    }
}

function buildRoundVotesData(votes_data,eliminated) {
    let vd = {...votes_data};

    const entries = Object.entries(votes_data);

    const len = entries.length;
    eliminated.forEach(el=>{
       // all votes numbers need to be adjusted to reflect new preferences I think
        if(vd[el]) {
            const choice_votes = vd[el];
      //      console.log("el",el,"votes",JSON.stringify(choice_votes))
            Object.entries(choice_votes).forEach((ent,ind)=>{

                if(ent[0]>=len) {
                    // already last choice, drops off
                } else {
                    entries.forEach(item=>{
                        if(item[0]!==el) {
                            // if not the one being eliminated
                            // ent[0] === member id
                            // ent[1] === current rank
                            if(item[1][ent[0]]>ent[1]) {
                             //   console.log("SUBTRACT ONE from ",item[0],ent[0],item[1][ent[0]],'because less than',ent[1])
                                vd[item[0]][ent[0]]--;
                            }
                        }
                    })
                }
            })
            delete vd[el];
        }
    })

    return vd;
}

// should be a vote from m2 for 4 in round 2 (3)

function getRoundsDisplay(obj) {
    return Object.entries(obj).map(([index,data])=>{
        let ind;
        if(index==='count') {
            ind = "Results";
        } else {
            ind = `Round ${parseInt(index)+1}`
        }
        return [ind, data.round?data.round.votes:null]
    });
}

/*
  0 = no, 1 = yes, 2 = maybe, -1 = no response
        choices: { [choice_id]: { 0: 0, 1: 0, 2: 0, -1: 0 }
        winners: [choice_id] // top 1 or 2
 */
export function voting_eventDateTimeResolver(data,mock) {
    let choices = {};

   // console.log("voting_eventDateTimeResolver",data,mock)

    data.choices.forEach(choice_id=>{
        const choice = data.choices_data[choice_id];
        let choice_score = 0;
        choices[choice_id] = {
            0: 0,
            [-1]: 0,
            1: 0,
            2: 0,
            'score': 0
        };

        const choice_votes = data.votes[choice_id];

        if(!choice_votes) {
            // no votes yet
            return;
        }

        const choice_votes_values = Object.values(choice_votes);
        // need to get a count of values

        choices[choice_id][0] = choice_votes_values.filter(a=>a===0).length;
        choices[choice_id][1] = choice_votes_values.filter(a=>a===1).length;
        choices[choice_id][2] = choice_votes_values.filter(a=>a===2).length;
        choices[choice_id][-1] = choice_votes_values.filter(a=>a===-1).length;

        choice_score = choices[choice_id][1] + (choices[choice_id][2]*0.5);

        choices[choice_id].score = choice_score;
    });

    // to get the ranked, we need to sort by the total score for each choice
    // +2 for yes, +1 for maybe, 0 for no

    let ranked = Object.entries(choices).sort((a, b) => (a[1].score < b[1].score) ? 1 : -1).map(a=>a[0]);

    return {
        choices,
        ranked
    }
}

export function voting_rankedChoiceResolver(data,mock=false) {
    let fd;
    if(mock) {
        fd = mocks;
    } else {
        fd = data;
    }
    const {choices,choices_data,votes,voted} = fd;

    let rounds = {
        count: 0
    };

    const voters = Object.keys(voted);
    const voter_count = voters.length;

    let winner = "";

    let eliminated = [];

    let index = 0;

    let _votes_data = JSON.parse(JSON.stringify(votes));

    while(!winner) {
        _votes_data = buildRoundVotesData(_votes_data,eliminated);

        const result = analyzeRound(index,choices,choices_data,_votes_data,voted,eliminated);
        winner = result.winner;
        rounds.count++;
        rounds[index] = result;
     //   console.log("ROUND _votes_data", JSON.stringify(_votes_data), result.eliminated);
        if(result.eliminated&&result.eliminated[0]) {
            eliminated.push(result.eliminated[0]);
        }
        if(!winner&&(index+1)>=voter_count) {
            winner = "none";
        } else {
            index++;
        }
    }

    if(!mock) {
        return {
            rounds_display: getRoundsDisplay(rounds),
            rounds,
            winner
        }
    }
    /*

    return {
        rounds,
        winner
    }
     */
    return <div>
        winner: {winner}
         <pre className="text-xs p-2 bg-gray-100 border-gray-300 rounded-md overflow-y-scroll text-left"
              style={{maxHeight: '400px'}}>{JSON.stringify(rounds, undefined, 2)}</pre>
    </div>
}