import React, {useLayoutEffect} from 'react';
import {EditorBlock} from 'draft-js';
import focusCurrentBlock from "../../helpers/focusCurrentBlock";
import {InlineLoader} from "../../../../m3/_legacy_components/admin-activity";
const max_width = 608;
const max_height = 460;

function getImageDimensions(data,mw) {
    const dh = parseInt(data.height);
    const dw = parseInt(data.width);

    const square = dh === dw;
    const vertical = dh > dw;

    const height_exceeds_max = dh > max_height;
    const width_exceeds_max = dw > mw;

 //   console.log("IMAGE DIMENSIONS",{dh,dw,square,vertical,height_exceeds_max,width_exceeds_max})

    if(square) {
        if(height_exceeds_max) {
            return {
                height: max_height,
                width: max_height
            }
        } else {
            return {
                height: dh,
                width: dw
            }
        }
    } else if(vertical&&height_exceeds_max) {
        return {
         height: max_height,
         width: dw / (dh/max_height)
        }
    } else if(!vertical&&height_exceeds_max&&width_exceeds_max) {
        return {
            maxWidth: '100%',
         width: mw,
         height: dh / (dw/mw)
        }
    } else if(width_exceeds_max) {
        return {
            width: mw,
            height: dh / (dw/mw)
        }
    } else if(vertical && !height_exceeds_max && !width_exceeds_max) {
        return {
            height: dh,
            width: dw,
            maxHeight: '100%',
            maxWidth: '100%'
        }
    } else {
        return {
            width: '100%',
            height: 'auto',
            maxHeight: '480px'
        }
    }
}

function getContainerWidth() {
    const el = document.getElementById("message-content");
const minus = 80;
    if(!el) {
        return 608-minus
    } else {
        return el.children[0].clientWidth-minus;
    }
}


export function EB_Image(props) {
    const setEditorState = props.blockProps.setEditorState;
    const read_only = props.blockProps.read_only;
    const onOpenLighbox = props.blockProps.onOpenLighbox || (()=>{});
    const currentBlock = props.block;
    const data = Object.fromEntries(currentBlock.getData());
    const text = currentBlock.getText();
    const preview_ref = React.useRef(null);
    const has_focus = false;

    useLayoutEffect(function () {
        if(data.loading&&preview_ref.current&&data.preview_file) {
            // show preview
            const reader = new FileReader();
            reader.onload = function (e) {
                preview_ref.current.style.backgroundImage = `url(${ reader.result })`;
            };
            reader.readAsDataURL(data.preview_file);
        }
    }, [data.loading, data.file])

    /*
    console.log("EB IMAGE")
    console.log("data:", data)
    console.log("text:", text)

     */

    if(props&&props.blockProps&&props.blockProps.hide) {
        return null;
    }

    let content;

    const {height,width} = getImageDimensions(data,getContainerWidth());
 //   console.log("HEIGHT", height)
   // console.log("WIDTH", width)
    // 378.6858075040783 608
    content = <div className={`image-block group`}>
        <div contentEditable={false}>
            {data.loading&&<div className="mt-4 mb-2">
                <div className="flex relative"
                     style={{height: `${parseInt(height)}px`, width: `${width}px`, aspectRatio: ""}}>
                    <div ref={preview_ref}
                         className="bg-gray-100 w-full h-full bg-cover bg-center blur-sm rounded-md border border-gray-200 ">
                    </div>
                    <div className="absolute left-0 right-0 bottom-0 top-0 flex items-center justify-center">
                    <InlineLoader padding/>
                    </div>
                </div>

            </div>}
            {data.url && <img
                className={`block border border-gray-200 rounded-md ${read_only?"cursor-pointer":""}`}
                height={height} width={width} style={{aspectRatio: '',height:'auto',width,maxHeight:`100%`,maxWidth:`100%`}}
                src={data.url}
                alt=""
                onClick={()=>{
                    if(read_only) {
                        onOpenLighbox(data.url);
                    } else {
                        focusCurrentBlock({
                            setEditorState,
                            block: currentBlock
                        })
                    }
                }}
            />}

        </div>
        {(!read_only||(read_only&&text))&&<div className="text-xs relative mt-1.5 mb-1.5 mx-0.5 max-w-md text-gray-600 text-left">
        <EditorBlock {...props} />
            {!read_only&&!text&&!has_focus&&<span className="absolute top-0 left-0 pointer-events-none text-gray-400">Add a Caption</span>}
        </div>}
    </div>;

    return content;
}