export const data_continents_map = {
    'na': {
        label: "North America",
        countries: [
            "us", "ca", "mx", "cu", "gt", "bz", "sv", "hn", "ni", "cr", "pa", "jm", "ht", "do", "tt", "ag", "dm", "lc", "vc", "bb", "gd", "kn", "bs", "aw", "ai", "vg", "vi", "ms", "gp", "mq", "tc", "gd", "bl", "mf", "bq", "cw", "sx", "pm", "gl",
        ],
    },
    'sa': {
        label: "South America",
        countries: [
            "br", "co", "ve", "gy", "ec", "pe", "bo", "py", "sr", "uy", "fk", "gf",
        ],
    },
    'eu': {
        label: "Europe",
        countries: [
            "gb", "fr", "de", "it", "es", "ua", "pl", "ro", "nl", "be", "el", "cz", "pt", "hu", "se", "at", "bg", "dk", "fi", "sk", "no", "ie", "hr", "md", "lt", "si", "lv", "ee", "cy", "lu", "mt", "is", "al", "mk", "rs", "me", "gg", "im", "je", "gi"
        ],
    },
    'af': {
        label: "Africa",
        countries: [
            "eg", "ly", "tn", "dz", "ma", "eh", "mr", "sn", "gm", "gw", "gn", "sl", "lr", "ci", "bf", "gh", "tg", "bj",
            "ne", "ng", "td", "cf", "cm",
            "er", "sd", "ss", "et", "so", "dj", "ke", "ug", "tz", "rw", "bi", "mz", "zm", "mg", "zw", "na", "bw", "sz",
            "ls", "za", "re", "mw", "ls", "sc", "mu", "km", "st", "sh", "tg", "sh", "tg", "sh", "tg", "sh", "tg", "sh",
        ],
    },
    "as": {
        label: "Asia",
        countries: [
            "ru", "cn", "in", "id", "pk", "bd", "jp", "ph", "vn", "tr", "ir", "th", "my", "iq", "kz", "sa", "kg", "ae",
            "tm", "uz", "np", "kp", "kr", "lk", "af", "mm", "jo", "sy", "ye", "ps", "kw", "om", "qa", "bh", "la", "il",
            "mn", "bn", "ge", "am", "az", "lb", "cy", "tm", "tj", "kg", "bt", "mn", "bn", "hk", "mo", "sg", "tw", "kp",
        ]
    },
    "oc": {
        label: "Oceania",
        countries: [
            "au", "nz", "fj", "pg", "sb", "vu", "nc", "pf", "ck", "ws", "fm", "mh", "ki", "to", "tv", "nr", "nu", "tk",
            "wf", "as", "gu", "mp", "pw", "tl", "nf", "cx", "cc", "hm", "nf", "cx", "cc", "hm", "nf", "cx", "cc", "hm",
        ]
    },
};

export const data_continents_arr = Object.entries(data_continents_map).map(([k, v]) => {
    return {
        value: k,
        label: v.label,
    }
})