import React from 'react';
import {FieldInput, FieldLabel} from "../../../m3/_legacy_components/form/field";
import {EyeIcon, EyeSlashIcon} from "@heroicons/react/24/outline";

export function checkPasswordStrength(pw) {
    const len = pw.length;
    let strength = 0;
    let checks = {
        special: false,
        caps: false,
        numbers: false,
        small: false
    };

    let key_code_map = {};

    if (len < 7) {
        return 0;
    } else if (len > 16) {
        strength += 3;
    } else if (len > 12) {
        strength += 2;
    } else if (len > 6) {
        strength += 1;
    }

    for (let i = 0; i < len; i++) {
        const char = pw.charCodeAt(i);
        key_code_map[char] = true;
        if (!checks.caps && char >= 65 && char <= 90) {
            checks.caps = true;
        } else if (!checks.numbers && char >= 48 && char <= 57) {
            checks.numbers = true;
        } else if (!checks.small && char >= 97 && char <= 122) {
            checks.small = true;
        } else if (!checks.special && (char >= 33 && char <= 47) || (char >= 58 && char <= 64)) {
            checks.special = true;
        }
    }

    if (checks.small && checks.caps) {
        strength += 1;
    } else if (checks.special) {
        strength += 1;
    } else if (checks.numbers) {
        strength += 1;
    }

    const variety = Object.keys(key_code_map).length;

    if (variety < 3) {
        return 0;
    } else if (variety < 4) {
        return strength > 1 ? 1 : strength;
    } else if (variety < 7) {
        return strength > 2 ? 2 : strength;
    }

    return {score: strength > 5 ? 5 : strength}
}

const strength = {
    '0': {
        text: 'Weak',
        color: 'danger',
        w: '6'
    },
    '1': {
        text: 'Ok',
        color: 'yellow',
        w: '14'
    },
    '2': {
        text: 'Good',
        color: 'blue',
        w: '20'
    },
    '3': {
        text: 'Strong',
        color: 'success',
        w: '24'
    },
    '4': {
        text: 'Strong',
        color: 'success',
        w: '24'
    },
    '5': {
        text: 'Strong',
        color: 'success',
        w: '24'
    }
};

function StrengthMeter({str}) {
    if (!str) {
        return null;
    }
    const {text, color, w} = strength[`${str}`];
    return <div className="flex items-center">
        <div className={`pr-2 text-${color}-800 font-semibold`}>{text}</div>
        <div className="relative h-2 w-24">
            <div className={`absolute top-0 left-0 h-2 w-24 rounded-md bg-gray-200`}></div>
            <div className={`absolute top-0 left-0 transition-all h-2 w-${w} rounded-md bg-${color}-600`}></div>
        </div>
    </div>
}

function RightIcon({icon, onClick}) {
    return <div className={"absolute top-0 right-0 bottom-0 flex items-center pr-3"}>
        <div className="w-5 h-5 cursor-pointer text-gray-400 hover:text-gray-700" onClick={(e) => {
            e.stopPropagation();
            if (onClick) {
                onClick();
            }
        }}>
            {icon}
        </div>
    </div>
}

function PasswordInput({
                           value = "", placeholder = "", score = 0, onChange = () => {
    }, onEnter = () => {
    }, label = "Password", autoFocus = false
                       }) {
    const [show_pw, setShowPw] = React.useState(false);
    const right_icon = {
        icon: show_pw ? <EyeSlashIcon/> : <EyeIcon/>,
        onClick: () => {
            setShowPw(!show_pw)
        }
    };
    const str = score.toString();
    const comp = value && <StrengthMeter str={str}/>;
    return <div>
        <FieldLabel label={label} help_text="Minimum of 6 characters with uppercase, lowercase, and a number."
                    corner_hint={comp}/>
        <div className="relative">
            <FieldInput id="pw" input_props={{name: "password-one"}} placeholder={placeholder} onEnter={onEnter}
                        autoFocus={autoFocus} type={show_pw ? "text" : "password"} onChange={onChange} value={value}/>
            {right_icon && <RightIcon {...right_icon} />}
        </div>
    </div>
}

export const PasswordField = ({
    placeholder = "**********",
                                  onChange, onEnter = () => {
    }, label = "Password", autoFocus = false, score, value
                              }) => {

    return <PasswordInput placeholder={placeholder} onEnter={onEnter} score={score} onChange={onChange} value={value}
                          label={label} autoFocus={autoFocus}/>;
};