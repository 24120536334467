import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import ContentPopup from "../content-popup";
import {ContentMenuSection} from "../content-popup/components";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {IconAction, TextAction} from "../../../routes/auth/sign-in";
import {directory_renderGalleryCard} from "../content-grid";
import {useCommunity} from "../../../config/community";
import {SectionHeader} from "../entity-grid";
import {HandRaisedIcon, XMarkIcon, PencilIcon, ChevronDownIcon, ChevronLeftIcon} from "@heroicons/react/20/solid";
import {createPortal} from "react-dom";
import {PhotoIcon} from "@heroicons/react/24/outline";
import {BlocksPopupContent} from "../../../routes/community/write/widgets/popup";
import SimpleTooltip from "../tooltip";
import {FieldLabel} from "../form/field";
import {BasicSelect} from "../form/basic-select";
import {buildCardMeta} from "../../../m3/utilities/build-card-meta";
import {m3_icon_map} from "../../icons/icon-map";
import {utils_objects_moveArrayItem} from "../../../../common/utilities/objects";

function buildUrl(u) {
    return u ? `${u}?alt=media` : "";
}

function Card({member, properties, blocks}) {
    const format = 'small';

    const item = {
        card: {
            title: member.name,
            subtitle: member.handle,
            image: buildUrl(member.profile_picture),
            meta: buildCardMeta({
                meta: {
                    occupation: member?.about.occupation,
                    birthday: JSON.stringify(member?.birthday),
                    location: member?.about.location,
                    join_date: member?.join_date
                }
            }),
            social: [],
            occupation: member?.about?.occupation || "",
            location: member?.about?.location || "",
            email: member?.contact?.email,
            membership_status: member?.membership_status||"active",
            account_status: member?.account_status,
            data_integrity: member?.data_integrity,
            phone: member?.contact?.phone,
            handle: member.handle || "",
            actions: [],
            custom_fields: member?.custom_fields||{},
        },
        raw: member,
        blocks: blocks
    };
    const ex = {
        properties
    };

    return <div className="h-auto my-3 mx-auto w-48">
        {directory_renderGalleryCard(format, item, ex)}
    </div>
}

function renderOptions(info, props, onChange) {
    const edit_options = [];
    const can_edit = info.options && Object.keys(info.options).length;
    if (!can_edit) {
        return null;
    }
    return <div className="pt-2">
        {Object.entries(info.options).map(([id, dt]) => {
            if(dt.hidden) {
                return null;
            }
            const v = props[id] || "";
            const full_value = !v ? null : dt.filter(a => a.value === v)[0] ? {
                value: v,
                label: dt.filter(a => a.value === v)[0].label
            } : v;
            return <div key={id}>
                <div className="capitalize">
                    <FieldLabel label={id}/>
                </div>
                <BasicSelect value={full_value}
                             onChange={(v) => {
                                 onChange(id, v.value)
                             }} options={dt.map(it => {
                    return {id: it.value, value: it.value, label: it.label}
                })}/>
            </div>
        })}
    </div>
}

function BlockItem(props) {
    const {type, label, accessor, drag, handleRemove} = props;
    const block_info = block_type_info[type];
    const [editing, setEditing] = useState(false);
    if (!block_info) {
        return <div>
            --- {type}
        </div>
    }
    return <div className="py-1">
        <div className="flex">
            <div className="flex-grow flex space-x-2 items-center">
                <div className="w-3 flex justify-center">
                    {drag}
                </div>
                <div className="text-gray-700 text-sm truncate">
                    {block_info.label}{label ? ` (${label})` : null}
                </div>
            </div>
            <div className="flex space-x-2">
                <IconAction onClick={() => setEditing(!editing)}>
                    {editing ? <ChevronDownIcon/> : <ChevronLeftIcon/>}
                </IconAction>
            </div>
        </div>
        {editing && <div className="space-y-1">
            {renderOptions(block_info, props, props.handleChange)}
            <div className="pt-1">
                <TextAction onClick={handleRemove} text="Delete Block"/>
            </div>
        </div>}
    </div>
}

export const block_type_info = {
    'profile-picture': {
        height: 'image',
        label: 'Profile Picture',
        desc: 'Thumbnail / Full',
        defs: {
            format: 'full',
            accessor: 'profile_picture'
        },
        options: {
            format: [{label: 'Full', value: 'full'}, {label: 'Thumbnail', value: 'thumbnail'}]
        }
    },
    'title': {
        height: '1.5rem',
        label: 'Title',
        desc: 'First, last, or full name',
        defs: {
            accessor: 'name'
        },
        options: {
            accessor: [{value: 'name', label: 'Name'}, {
                label: 'First Name',
                value: 'about.first_name'
            }, {label: 'Last Name', value: 'about.last_name'}]
        }
    },
    'member_type': {
        label: 'Membership Level',
        desc: ""
    },
    'meta': {
        label: 'Meta',
        defs: {
            accessors: [
                'occupation',
                'location',
                'birthday',
                'joined_date'
            ]
        },
        options: {},
        desc: 'Occupation, location, birthday & more'
    },
    'social': {
        label: 'Social',
        defs: {
            accessors: [
                'linkedin'
            ],
        },
        desc: 'can change social items shown'
    },
    'custom-field': {
        multiple: true,
        defs: {
            accessor: "",
            label: ""
        },
        options: {

        },
        label: 'Custom Field',
        desc: 'can change field'
    },
    'email': {
        label: 'Email',
        defs: {
            accessor: "account_email"
        },
        desc: 'Member email'
    },
    'handle': {
        label: 'Handle',
        defs: {
            accessor: "handle"
        },
        desc: 'Member handle'
    },
    'data_integrity': {
        label: 'Profile Quality',
        defs: {
            accessor: "data_integrity"
        },
        desc: 'Member data integrity'
    },
    'membership_status': {
        label: 'Membership Status',
        defs: {
            accessor: "membership_status"
        },
        desc: ''
    },
    'account_status': {
        label: 'Invite Status',
        defs: {
            accessor: "account_status"
        },
        desc: 'Member invite status'
    },
    'location': {
        label: 'Location',
        defs: {
            accessor: "about.location"
        },
        desc: 'Member location'
    },
    'occupation': {
        label: 'Occupation',
        defs: {
            accessor: "about.occupation"
        },
        desc: 'Member occupation'
    },
    'phone': {
        // todo can have formatting options
        label: 'Phone',
        defs: {
            accessor: "contact.phone"
        },
        desc: 'Member phone'
    },
    'interests': {
        label: 'Interests',
        desc: 'Member interests',
        defs: {}
    },
    'actions': {
        height: 2,
        label: 'Actions',
        defs: {
            actions: ['profile', 'email']
        },
        options: [
            {
                type: 'profile',
                label: 'Profile'
            },
            {
                type: 'email',
                label: 'Email'
            },
            {
                type: 'call',
                label: 'Call'
            }
        ],
        desc: ''
    }
};

export const useDraggableInPortal = () => {
    const self = useRef({}).current;

    useEffect(() => {
        const div = document.createElement('div');
        div.style.position = 'absolute';
        div.style.pointerEvents = 'none';
        div.style.top = '0';
        div.style.width = '100%';
        div.style.height = '100%';
        self.elt = div;
        document.body.appendChild(div);
        return () => {
            document.body.removeChild(div);
        };
    }, [self]);

    return (render) => (provided, ...args) => {
        const element = render(provided, ...args);
        if (provided.draggableProps.style.position === 'fixed') {
            return createPortal(element, self.elt);
        }
        return element;
    };
};

export const IconGrabHandle = () => {
    return <svg fill="currentColor" x="0" y="0" viewBox="0, 0, 24, 24">
        <g id="Layer_15">
            <path
                d="M8.5,14 C7.395,14 6.5,13.105 6.5,12 C6.5,10.895 7.395,10 8.5,10 C9.605,10 10.5,10.895 10.5,12 C10.5,13.105 9.605,14 8.5,14 z"/>
            <path
                d="M15.5,14 C14.395,14 13.5,13.105 13.5,12 C13.5,10.895 14.395,10 15.5,10 C16.605,10 17.5,10.895 17.5,12 C17.5,13.105 16.605,14 15.5,14 z"/>
            <path
                d="M8.5,20 C7.395,20 6.5,19.105 6.5,18 C6.5,16.895 7.395,16 8.5,16 C9.605,16 10.5,16.895 10.5,18 C10.5,19.105 9.605,20 8.5,20 z"/>
            <path
                d="M15.5,20 C14.395,20 13.5,19.105 13.5,18 C13.5,16.895 14.395,16 15.5,16 C16.605,16 17.5,16.895 17.5,18 C17.5,19.105 16.605,20 15.5,20 z"/>
            <path
                d="M8.5,8 C7.395,8 6.5,7.105 6.5,6 C6.5,4.895 7.395,4 8.5,4 C9.605,4 10.5,4.895 10.5,6 C10.5,7.105 9.605,8 8.5,8 z"/>
            <path
                d="M15.5,8 C14.395,8 13.5,7.105 13.5,6 C13.5,4.895 14.395,4 15.5,4 C16.605,4 17.5,4.895 17.5,6 C17.5,7.105 16.605,8 15.5,8 z"/>
        </g>
    </svg>
};

function AddBlockElement({add_blocks, addBlock}) {
    const [open, setOpen] = useState(false);
    return <SimpleTooltip trigger='click' onOpen={() => setOpen(true)} onClose={() => setOpen(false)} interactive simple
                          text={<BlocksPopupContent onClick={addBlock} blocks={add_blocks.filter(a => a.can_add)}
                                                    title={"Add a Block"}/>} placement='bottom-end'>
        <TextAction text={open ? `Done` : `Add`}/>
    </SimpleTooltip>
}

function buildFullBlockFieldInfo(allowed_block_fields, defs) {
    let full_block_field_info = {
        ...block_type_info
    };

    return full_block_field_info;
}

function BlockListEditor({updateBlocks, allowed_block_fields, defs, blocks = []}) {
    const community = useCommunity();

    const [_blocks, setBlocks] = useState([...blocks]);
    const isFirstRun = useRef(true);

    const full_block_field_info = buildFullBlockFieldInfo(allowed_block_fields, defs);

    useEffect(function () {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        if (_blocks && updateBlocks) {
            updateBlocks(_blocks);
        }
    }, [_blocks])

    function handleAddBlock(bl) {
        const info = Object.entries(block_type_info).filter(a => a[0] === bl.value)[0];
        if (!info) {
            console.error('oh no', bl)
            return;
        }
        const nb = {
            type: bl.value,
            ...info.defs || {},
            accessor: bl?.accessor,
            label: bl?.label,
        };
        setBlocks([
            ..._blocks,
            nb
        ])
    }

    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const {destination, source} = result;
        // need to sort pins and save on done

        let nb = [...utils_objects_moveArrayItem(_blocks, source.index, destination.index)];

        setBlocks(nb);
    }

    function handleRemove(index) {
        let nb = [..._blocks];
        nb.splice(index, 1)
        setBlocks(nb);
    }

    function onChange(index, field, value) {
        let nb = [..._blocks];
        nb[index][field] = value
        setBlocks(nb);
    }

    const renderDraggable = useDraggableInPortal();

    const add_blocks = buildBlocksCanAdd(_blocks, allowed_block_fields, defs);

    console.log("ADD BLOCKS",add_blocks)
    console.log("__BLOCKS",_blocks)

    return <div>
        <Card properties={defs.properties} blocks={_blocks} member={community.member}/>

        <SectionHeader size="text-sm" title="Attributes"
                       actions={<AddBlockElement addBlock={handleAddBlock} add_blocks={add_blocks}/>}/>
        <div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="blocks-layout">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {_blocks.map((bl, key) => {
                                return <Draggable isDragDisabled={false} key={bl.type} draggableId={bl.type}
                                                  style={{zIndex: 999}}
                                                  index={key}>
                                    {renderDraggable((pr2) => <div ref={pr2.innerRef}
                                                                   key={bl.type} {...pr2.draggableProps}>
                                            <BlockItem handleChange={(fi, vl) => onChange(key, fi, vl)} drag={<IconAction>
                                                <div
                                                    className='text-gray-500 hover:text-gray-800' {...pr2.dragHandleProps}>
                                                    {m3_icon_map.custom['grab-handle']}
                                                </div>
                                            </IconAction>} handleRemove={() => handleRemove(key)} {...bl} key={key}/>
                                        </div>
                                    )}
                                </Draggable>
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    </div>
}

function buildBlocksCanAdd(existing, allowed_block_fields, defs) {
    let base = [
        ...Object.entries(block_type_info).map(bl => {
            const already_added = !existing ? false : existing.findIndex(a => a.type === bl[0]) !== -1;
            const {label, desc, multiple} = bl[1];
            return {
                allowed: allowed_block_fields.indexOf(bl[0]) !== -1,
                can_add: !already_added || multiple,
                value: bl[0],
                label: label,
                desc: desc,
            }
        }).filter(a => a.allowed)
    ];

    console.log("buildBlocksCanAdd",JSON.stringify(base))

    for (let i = 0; i < allowed_block_fields.length; i++) {
        const already_added = !existing ? false : existing.findIndex(a => a.type === allowed_block_fields[i]) !== -1;
        if (!already_added) {
            const allowed = allowed_block_fields.indexOf(allowed_block_fields[i]) !== -1;
            const prop = defs.properties[allowed_block_fields[i]];
            if(base.find(a=>a.value===allowed_block_fields[i])) {
                continue;
            }

            if (!prop) {
                continue;
            }

            const label = prop.label;
            const desc = "";
            const value = allowed_block_fields[i];
            base.push({
                allowed: allowed,
                can_add: true,
                value: 'custom-field',
                accessor: value,
                label: label,
                desc: desc,
            })
        }
    }

    return base;
}

export function DirectoryCustomizeLayout({
                                             hide_header,
                                             defs,
                                             blocks = [],
                                             onBack = () => {
                                             },
                                             updateBlocks
                                         }) {
    const [changes_made, setChangesMade] = useState(false);
    const [b, setB] = useState(blocks);
    const n = changes_made ? <TextAction onClick={() => {
        if (b) {
            updateBlocks(b)
            setChangesMade(false);
        }
    }} text="Apply"/> : null;

    const c =  <BlockListEditor defs={defs} allowed_block_fields={defs.allowed_block_fields} updateBlocks={(ba) => {
        if (ba) {
            setB(ba)
            setChangesMade(true)
        }
    }} blocks={blocks}/>;

    if(!onBack) {
        return <div className="">
            <SectionHeader size="text-sm" title="Appearance"
                           actions={n}/>

            {c}
        </div>;
    }

    return <ContentMenuSection onBack={onBack} divider title="Customize Cards"
                               actions={n}>
        {c}
    </ContentMenuSection>
}