import React from "react";
import M3_C_Dialog from "../components/dialog";
import M3_A_Portal from "../atoms/portal";
import M3_A_Prose from "../atoms/prose";
import M3_A_Markdown from "../atoms/markdown";

const _props = {};

function parseConfig(config) {
    return config;
}

export default function M3_E_OptInPrompt({
                                              config,
                                              demo
                                          }) {

    const {dialog_count, content, footer, header} = parseConfig(config, demo);

    return (<M3_A_Portal background>
            <M3_C_Dialog
                dialog_count={dialog_count}
                is_loading={config?.is_loading}
                is_initializing={config?.is_initializing}
                footer={footer}
                header={header}>
                {content?.description && <div>
                    <M3_A_Prose>
                        {content?.description &&
                            <M3_A_Markdown skeleton={config?.is_initializing}>{content?.description}</M3_A_Markdown>}
                    </M3_A_Prose>
                </div>}
            </M3_C_Dialog>
        </M3_A_Portal>
    );
}