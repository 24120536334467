import React, {useState} from "react"
import './styles.css';
import EntityCard from "../entity-card";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/24/outline";

export function SectionHeader({
                                  title,
                                  suffix,
                                  onBack,
                                  subtitle,
                                  children,
                                  weight = 'font-semibold',
                                  size = 'text-base',
                                  actions
                              }) {
    return <div className="flex min-h-[1.75rem] items-center">
        <div className="flex-grow flex gap-1">
            {!!onBack && <div className="flex-none">
                <ArrowAction onClick={() => onBack()}>
                    <ChevronLeftIcon/>
                </ArrowAction>
            </div>}
            <div>
                <div className={`tracking-tight ${size} ${weight} text-gray-800`}>
                    {title}{children}
                    {suffix && <span className="text-gray-500 font-medium pl-2">{suffix}</span>}
                </div>
                {subtitle && <div className="text-sm text-gray-600">{subtitle}</div>}
            </div>
        </div>
        <div className="flex gap-4">
            {actions}
        </div>
    </div>
}

function ArrowAction({children, onClick}) {
    return <div onClick={() => {
        if (onClick) {
            onClick();
        }
    }}
                className="w-6 h-6 rounded-full flex items-center justify-center hover:bg-gray-100 cursor-pointer text-gray-500 hover:text-gray-800">
        <div className="w-4 h-4">
            {children}
        </div>
    </div>
}

function Actions({can_prev, can_next, handlePrev, handleNext}) {
    return <div className="flex space-x-1.5">
        {can_prev && <ArrowAction onClick={() => handlePrev()}>
            <ChevronLeftIcon/>
        </ArrowAction>}
        {can_next && <ArrowAction onClick={() => handleNext()}>
            <ChevronRightIcon/>
        </ArrowAction>}
    </div>
}

export default function EntityGrid({title, items = []}) {
    const per_page = Math.floor((window.innerWidth > 1000 ? 1000 : window.innerWidth) / 300);
    const pages = Math.ceil(items.length / per_page);
    const [page, setPage] = useState(0);
    const can_prev = page !== 0;
    const can_next = (page + 1) < pages;
    const start_index = page * per_page;
    const show_items = items.slice(start_index, start_index + per_page);

    function handleNext() {
        setPage(page + 1);
    }

    function handlePrev() {
        setPage(page - 1);
    }

    return <div>
        <SectionHeader title={title} actions={<Actions can_next={can_next} can_prev={can_prev} handleNext={handleNext}
                                                       handlePrev={handlePrev}/>}/>
        <div className="grid gap-3 mt-2" style={{gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))'}}>
            {show_items.map((it, index) => {
                return <EntityCard key={`${index}-${it.id}`} config={{...it}}/>
            })}
        </div>
    </div>
};