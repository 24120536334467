import React from "react";
import {memberPoliciesCalculator} from "./utils";
import dayjs from "dayjs";

function PolicyItem({
                        label = "Privacy Policy",
                        status = 'up-to-date',
                        data,
                        details
                    }) {

    // {getPre(moment.unix((details.at/1000)))}

    if(status === 'up-to-date') {
        return <p>
            <strong>{label}</strong> (version {data.version}) accepted on {dayjs.unix((details.at/1000)).format('MMMM Do YYYY [at] h:mma')}.
        </p>
    }

    if(status === 'never-accepted') {
        return <p>
            <strong>{label}</strong> (version {data.version}) has never been accepted.
        </p>
    }

    if(status === 'outdated') {
        return <p>
            <strong>{label}</strong> (version {data.version}) was accepted on {dayjs.unix((details.at/1000)).format('MMMM Do YYYY [at] h:mma')} but a newer version has been released.
        </p>
    }

    return null;
}

function renderPoliciesList(pc) {

    // render never accepted first, then outdated, then up-to-date

    return <div>
        <ul className="space-y-2">
            {pc.never_accepted.map(a => {
                const dt = pc.policy_data.find(b => b.id === a.id);
                return <li key={a.id}>
                    <PolicyItem data={dt} details={a} label={dt.name}
                                status={'never-accepted'}/>
                </li>
            })}

            {pc.outdated.map(a => {
                const dt = pc.policy_data.find(b => b.id === a.id);
                return <li key={a.id}>
                    <PolicyItem data={dt} details={a} label={dt.name}
                                status={'outdated'}/>
                </li>
            })}

            {pc.up_to_date.map(a => {
                const dt = pc.policy_data.find(b => b.id === a.id);
                return <li key={a.id}>
                    <PolicyItem data={dt} details={a} label={dt.name}
                                status={'up-to-date'}/>
                </li>
            })}
        </ul>
    </div>
}

export function MemberPoliciesList({data, community}) {

    const pc = memberPoliciesCalculator(data, community);

    if (pc.status === 'na') {
        // no community policies
        return <p>
            No active policies
        </p>
    }

    return <div>
        <p>
            {pc.status==='up-to-date'&&<span>All policies are up to date.</span>}
            {pc.status==='outdated'&&<span>Some policies are outdated.</span>}
            {pc.status==='never-accepted'&&<span>Some policies have never been accepted.</span>}
        </p>
        <br/>
        {renderPoliciesList(pc)}
    </div>
}