import React from "react";
import {ModalHeader} from "../../../../m3/_legacy_components/preview-overlay";

function AppItem({children,onClick,app}) {
return <div onClick={onClick} className="border p-3 flex gap-3 rounded-md hover:border-gray-300 border-gray-200 hover:bg-gray-100 cursor-pointer">
    <div className="flex-none w-16">
        <img className="rounded-md" src={app.thumbnail} alt={app.name} />
    </div>
    <div className="flex-grow">
        <div>
            <div className="text-base font-bold text-gray-900">{app.name}</div>
        </div>
        <div>
            <div className="text-xs text-gray-600">{app.short_description}</div>
        </div>
        <div>{children}</div>
    </div>
</div>
}

function AppsSection({children,title}) {
    return <div>
        <div className="font-semibold text-sm leading-6 text-gray-600 tracking-tight">{title}</div>
        <div className="mt-1 grid grid-cols-1 gap-5 sm:grid-cols-2">
            {children}
        </div>

    </div>
}

export function AppDirectoryPage({all_apps, goToApp,
                                     group, id, setData
                                 }) {

    const added_apps = all_apps.filter(app => app.added);

    const available_apps = all_apps.filter(app => !app.added);

    function handleClick(id) {
        goToApp(id);
    }

    return <div>
        <ModalHeader sizing="h-8 px-0"
                     divider={false}
                     title={"Apps"}/>
        <div className="grid grid-cols-1 gap-4 mt-2">
            {added_apps.length > 0 && <AppsSection title="Added Apps">
                    {added_apps.map(app => {
                        return <AppItem onClick={()=>handleClick(app.id)} key={app.id} app={app}/>
                    })}
            </AppsSection>}

            {available_apps.length > 0 && <AppsSection title="Available Apps">
                    {available_apps.map(app => {
                        return <AppItem onClick={()=>handleClick(app.id)} key={app.id} app={app}/>
                    })}
            </AppsSection>}

        </div>
    </div>
}