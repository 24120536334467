import React, {useState} from "react";
import {ChevronLeftIcon, ChevronRightIcon, PlusIcon, XMarkIcon} from "@heroicons/react/24/outline";

const allowed_is = ['centered', 'cover'];

function ImageRenderer({image, image_style, image_id = ""}) {
    const is = image_style && allowed_is.includes(image_style) ? image_style : 'centered';

    const classes = is === 'centered' ? "bg-contain" : "bg-cover";

    return <div id={image_id} className={`${classes} rounded-xl bg-center bg-no-repeat w-full h-full`}
                style={{backgroundImage: `url(${image})`}}>

    </div>
}

const badge_mock = {
    background_color: "#FCF0DB",
    image: null
};

function getWidth(size) {
    if (size === 'large') {
        return 'w-32'
    }
    return 'w-20'
}

function AddBadgeCover() {
    return <div
        className="absolute transition-colors text-gray-400 hover:text-gray-500 hover:bg-gray-200 flex cursor-pointer items-center justify-center rounded-xl top-0 left-0 right-0 bottom-0 bg-gray-100">
        <div className="w-8 h-8">
            <PlusIcon />
        </div>
    </div>
}

function BadgeHoverManage() {
    return <div
        className="absolute transition-opacity opacity-0 flex cursor-pointer group-hover:opacity-100 items-center justify-center rounded-xl bg-opacity-70 top-0 left-0 right-0 bottom-0 bg-gray-700">
        <div className="bg-blue-600 px-2 hover:opacity-80 rounded-md leading-7 text-white text-sm tracking-tight font-semibold">Edit</div>
    </div>
}

export function Badge({
                          is_add_badge,
                          image,
                          manage,
                          image_style = 'centered',
                          image_id,
                          name,
                          description,
                          show_details = false,
                          size = 'standard',
                          onClick,
                          id = '1234',
                          background_color = "#FCF0DB"
                      }) {

    return <>
        <div style={{}} onClick={() => {
            if (onClick) {
                onClick()
            }
        }} className={`relative group inline-block ${getWidth(size)}`}>
            <div
                className={`${image_style === 'cover' ? "" : "p-1"} rounded-xl relative ${manage ? "" : "hover:opacity-70 cursor-pointer"} transition-opacity`}
                style={{backgroundColor: image_style === 'cover' ? "#ffffff" : background_color, aspectRatio: '4/5'}}>
                <ImageRenderer image_style={image_style} image_id={image_id} image={image}/>
                {is_add_badge&&<AddBadgeCover/>}
            </div>
            {manage && <BadgeHoverManage/>}
        </div>
        {show_details && <div className="max-w-sm">
            <div className="text-gray-800 leading-5 pt-1 font-bold text-base">{name}</div>
            {description && <div className="text-sm pt-0.5 flex space-x-2 text-gray-600">{description}</div>}
        </div>}

    </>
}

function ArrowAction({children, onClick}) {
    return <div onClick={() => {
        if (onClick) {
            onClick();
        }
    }}
                className="w-6 h-6 rounded-full flex items-center justify-center hover:bg-gray-100 cursor-pointer text-gray-500 hover:text-gray-800">
        <div className="w-4 h-4">
            {children}
        </div>
    </div>
}

export default function PinboardBadges({manage,fill, can_add, onAdd, onClick, badges = [badge_mock]}) {
    const [view, setView] = useState(null);

    const count = badges.length;

    function badgesList() {
        return <div className="space-x-2 pr-3 -mb-2 overflow-x-auto whitespace-nowrap" style={{maxWidth: fill?"":'338px'}}>
            {badges.map((it, index) => {
                return <Badge manage={manage} onClick={() => {
                    if (manage && onClick) {
                        onClick();
                    } else {
                        setView(it.id)
                    }
                }} {...it} key={index}/>
            })}
            {can_add&&<Badge image="" is_add_badge background_color={"#ffffff"} />}
        </div>
    }

    function previous(si) {
        const next_index = si - 1;
        if (si === 0) {
            setView(badges[(count - 1)].id);
        } else {
            setView(badges[next_index].id);
        }
    }

    function next(si) {
        const next_index = si + 1;
        if (next_index >= badges.length) {
            setView(badges[0].id);
        } else {
            setView(badges[next_index].id);
        }
    }

    function singleBadge() {
        const selected_index = badges.findIndex(badge => badge.id === view);
        const badge_data = badges[selected_index];
        const can_paginate = count > 1;
        return <div>
            <div className="flex items-center pb-1.5">
                <div className="flex-grow flex space-x-1">
                    {can_paginate && <ArrowAction onClick={() => previous(selected_index)}>
                        <ChevronLeftIcon/>
                    </ArrowAction>}
                    {can_paginate && <ArrowAction onClick={() => next(selected_index)}>
                        <ChevronRightIcon/>
                    </ArrowAction>}
                </div>
                <div className="flex space-x-1">

                    <ArrowAction onClick={() => setView(null)}>
                        <XMarkIcon/>
                    </ArrowAction>
                </div>
            </div>
            <div className="pb-1">
                <Badge show_details size='large' {...badge_data} />
            </div>
        </div>
    }

    if (view) {
        return singleBadge();
    }
    return badgesList();
};