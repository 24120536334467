import {useCommunity} from "../../../../config/community";
import React, {useEffect, useState} from "react";
import {MessageActions, ReactionWrapper} from "../index";
import {messages_reactToPost, messages_reactToReply} from "../../../../../api/messages/reactions";

function checkIfMemberHasReacted(reactions, member_id) {
    if (!reactions) {
        return false;
    }
    const keys = Object.keys(reactions);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (reactions[key].includes(member_id)) {
            return true;
        }
    }
    return false;
}

export function updateReactionData(reaction_id, action, data, community) {
    if (action === 'add') {
        let new_reactions_order = data.reactions_order || [];
        let new_reactions = {...data?.reactions || {}};
        let new_reactions_counts = {...data?.reactions_counts || {}};
        let new_reactions_members = {...data?.reactions_members || {}};

        if (!new_reactions[reaction_id]) {
            new_reactions[reaction_id] = [];
        }
        new_reactions[reaction_id].push(community.member_id);
        new_reactions_counts[reaction_id] = new_reactions_counts[reaction_id] ? new_reactions_counts[reaction_id] + 1 : 1;
        new_reactions_members[community.member_id] = true;

        if (!new_reactions_order.includes(reaction_id)) {
            new_reactions_order.push(reaction_id);
        }
        return {
            new_reactions,
            new_reactions_counts,
            new_reactions_members,
            new_reactions_order
        }
    } else if (action === 'remove') {
        let new_reactions_order = data.reactions_order || [];
        let new_reactions = {...data.reactions};
        let new_reactions_counts = {...data.reactions_counts};
        let new_reactions_members = {...data.reactions_members};

        if (new_reactions[reaction_id] && new_reactions[reaction_id].includes(community.member_id)) {
            new_reactions[reaction_id] = new_reactions[reaction_id].filter(it => it !== community.member_id);
        }

        if (!checkIfMemberHasReacted(new_reactions, community.member_id)) {
            delete new_reactions_members[community.member_id];
        }

        if (new_reactions_counts[reaction_id]) {
            new_reactions_counts[reaction_id] = new_reactions_counts[reaction_id] - 1;
        }

        if (new_reactions_counts[reaction_id] === 0) {
            delete new_reactions_counts[reaction_id];
            new_reactions_order = new_reactions_order.filter(it => it !== reaction_id);
            delete new_reactions[reaction_id];
        }

        return {
            new_reactions,
            new_reactions_counts,
            new_reactions_members,
            new_reactions_order
        }
    }
}

export function MessageReactionsActionsWrapper({
                                                   data,
                                                   show_actions = true,
                                                   pt = 'pt-4',
                                                   show_reply_count,
                                                   text_size = "text-sm",
                                                   onReply,
                                                   getUpdatedData,
                                                   updatePostReactions,
                                                   reply_id,
                                                   post_id,
                                                   post_link
                                               }) {
    const community = useCommunity();
    const [reactions, setReactions] = useState(data?.reactions || {});
    const [reactions_counts, setReactionsCounts] = useState(data?.reactions_counts || {});
    const [reactions_order, setReactionsOrder] = useState(data?.reactions_order || []);
    const [reactions_members, setReactionsMembers] = useState(data?.reactions_members || {});

    useEffect(function () {
        updateLocalData(data)
    }, [data])

    function updateLocalData(dt) {
        setReactions(dt?.reactions || {});
        setReactionsCounts(dt?.reactions_counts || {});
        setReactionsOrder(dt?.reactions_order || []);
        setReactionsMembers(dt?.reactions_members || {});
    }

    function handleReact(rid) {
        if (!rid) {
            return;
        }

        const ndt = getUpdatedData();

        const already_reacted = ndt?.reactions?.[rid] && ndt?.reactions?.[rid].length > 0 && ndt?.reactions?.[rid].includes(community.member_id);

        if (already_reacted) {
            handleRemoveReaction(rid);
        } else {
            handleAddReaction(rid);
        }

        updateLocalData(ndt);
    }

    function handleRemoveReaction(rid) {
        if (!rid) {
            return;
        }

        updatePostReactions({
            reaction_id: rid,
            action: 'remove'
        })

        if (reply_id) {
            messages_reactToReply(community, {
                reply_id: reply_id,
                post_id: post_id,
                member_id: community.member_id,
                reaction_id: rid
            }, true)
                .then(r => {

                })
        } else {
            messages_reactToPost(community, {
                post_id: post_id,
                member_id: community.member_id,
                reaction_id: rid
            }, true)
                .then(r => {

                })
        }
    }

    function handleAddReaction(rid) {
        if (!rid) {
            return;
        }

        updatePostReactions({
            reaction_id: rid,
            action: 'add'
        })

        if (reply_id) {
            messages_reactToReply(community, {
                reply_id: reply_id,
                post_id: post_id,
                member_id: community.member_id,
                reaction_id: rid
            }, false)
                .then(r => {

                })
        } else {
            messages_reactToPost(community, {
                post_id: post_id,
                member_id: community.member_id,
                reaction_id: rid
            }, false)
                .then(r => {

                })
        }

    }

    const has_reactions = reactions && Object.keys(reactions).length > 0;

    const reaction_pl = {
        reactions,
        reactions_counts,
        reactions_order,
        reactions_members
    };

    return <>
        {has_reactions && <div className={`flex ${pt}`}>
            <ReactionWrapper handleAddReaction={handleReact} handleReact={handleReact} {...reaction_pl}
                             post_id={data.id} updatePostReactions={updatePostReactions} data={data}/>
        </div>}
        {show_actions && <div className={`${pt}`}>
            <MessageActions show_reply_count={show_reply_count} text_size={text_size} handleAddReaction={handleReact}
                            onReply={onReply} data={data} post_link={post_link}/>
        </div>}
    </>
}