import React from 'react';
import ContentPopup from "../../../../m3/_legacy_components/content-popup";
import {
    ContentMenuSection,
    ContentPopupDivider,
    ContentPopupMenu
} from "../../../../m3/_legacy_components/content-popup/components";
import {DocumentIcon} from "@heroicons/react/24/outline";
import {formatFileSize, getFileTypeIcon} from "../../../../m3/icons/file-helpers";

export function FileListItem({name, onClick=()=>{}, type, url, size, folder}) {
    return <div onClick={onClick}
        className="px-1 py-0.5 rounded-md cursor-pointer group hover:bg-gray-100 transition-colors flex space-x-1">
        <div className="flex-none w-7 h-10 flex items-center justify-center">
            <div className="h-6 w-6">
                {getFileTypeIcon(type)}
            </div>
        </div>
        <div className="flex-grow">
            <div className="leading-5">
                <span className="text-sm font-semibold text-gray-800">{name}</span>
            </div>
            <div className="text-xs flex space-x-2">
                <span className=" text-gray-600">{formatFileSize(size)}</span>
            </div>
        </div>
    </div>
}

export function WriteAttachPopup() {

    return <ContentPopup>
        <div className="overflow-y-auto" style={{maxHeight: '20rem'}}>
            <ContentPopupMenu/>
            <ContentPopupDivider/>
            <ContentMenuSection title="Recent Files">
                <div className="-mx-1.5">
                    <FileListItem/>
                    <FileListItem/>
                    <FileListItem/>
                    <FileListItem/>
                </div>
            </ContentMenuSection>
            <ContentMenuSection onBack={() => {
            }} title="Browse files">
                <div className="-mx-3 pt-1.5">
                    <div className="border-b border-gray-200"/>
                    <div className="px-1.5">

                        FILE BROWSER
                    </div>
                </div>
            </ContentMenuSection>
        </div>
    </ContentPopup>
}