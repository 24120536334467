import React from "react";
import {PinboardBlockWrapper} from "../index";
import M3_A_Button from "../../../atoms/button";

export default function PinboardText({title = 'Text', can_edit, handleChange, data}) {
    const [edit_mode, setEditMode] = React.useState(false);
    const [text, setText] = React.useState(data?.[0] || "");

    return <PinboardBlockWrapper title={title}>
        {edit_mode && <div className="relative px-1 pt-1 pr-4 pb-2">
                <textarea autoFocus={true} rows={2} placeholder={"Add text"}
                          className="input-focus border rounded-md py-2 text-base px-3 w-full"
                          value={text} onChange={e => {
                    setText(e.target.value);
                }}/>
            <div className="pt-1 gap-3 flex">
                <M3_A_Button onClick={() => {
                    setText(data?.[0] || "");
                    setEditMode(false);
                }} text={"Cancel"} />
                <M3_A_Button onClick={() => {
                    handleChange([text]);
                    setEditMode(false);
                }} text={"Save"} />
            </div>
        </div>}
        {!edit_mode && <div onClick={() => {
            if (can_edit) {
                setEditMode(true);
            }
        }} className={`text-sm text-gray-800 ${can_edit ? "cursor-pointer" : ""}`}>
            {text||(can_edit?"Add text":"")}
        </div>}
    </PinboardBlockWrapper>
};