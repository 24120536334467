import React from "react"
import './styles.css';
import EditModal from "../edit-modal";
import {ModalBody, ModalContentBlock, ModalFooter, ModalHeader} from "../preview-overlay";
import {AdminImportWrapper} from "./import/wrapper";

export default function ImportMembersFromCSV({
                                                 handleClose = () => {}
                                             }) {

    let body = <AdminImportWrapper onClose={handleClose} />;

    return <EditModal open onClose={handleClose} size="large" custom>
        <ModalHeader title={"Import Members"} onClose={handleClose}/>
        <ModalBody>
            <ModalContentBlock>
            {body}
            </ModalContentBlock>
        </ModalBody>
    </EditModal>
};