import {authFetch} from "../../../../api/network";

export async function api_createPoll(community,pl) {
    return await new Promise((resolve, reject) => {
        const res = (r) => {
            resolve(r);
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            ...pl
        };
        authFetch("/polls/create", res, res, "POST", {payload});
    })
}

export function api_deletePoll(community,poll_id) {
    return new Promise((resolve, reject) => {
        const res = (r) => {
            resolve(r);
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            poll_id
        };
        authFetch("/polls/delete", res, res, "POST", {payload});
    })
}

// now do closePoll posts
export function api_closePoll(community,poll_id) {
    return new Promise((resolve, reject) => {
        const res = (r) => {
            resolve(r);
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            poll_id
        };
        authFetch("/polls/close", res, res, "POST", {payload});
    })
}

export async function api_votePoll(community,poll_id,vote) {
    return await new Promise((resolve, reject) => {
        const res = (r) => {
            resolve(r);
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            poll_id,
            vote
        };
        authFetch("/polls/vote", res, res, "POST", {payload});
    })
}

export async function api_changeVotePoll(community,poll_id,votes) {
    return await new Promise((resolve, reject) => {
        const res = (r) => {
            resolve(r);
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            poll_id,
            votes
        };
        authFetch("/polls/change-vote", res, res, "POST", {payload});
    })
}