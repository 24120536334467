import React from 'react';
import {isAnonymousUnatyEmail} from "../../../m3/utilities/email";
import SimpleTooltip from "../../../m3/_legacy_components/tooltip";
import {copyTextToClipboard} from "../../../m3/utilities/copy-to-clipboard";
import {useToasts} from "../../../config/toasts";
import {valuePipeline} from "../../../m3/_legacy_components/profile-field-renderer/value-pipeline";
import {useCommunity} from "../../../config/community";
import {
    MemberGroupsOrganizersList,
    renderField
} from "../../../m3/_legacy_components/profile-field-renderer";
import {getPhoneNumberData} from "../../../m3/utilities/phone";

function DetailListComponent({children, onClick, tooltip = ""}) {
    const ic = onClick ? "hover:bg-gray-200 hover:text-gray-800 cursor-pointer" : "";
    const a = <div onClick={onClick}
                   className={`${ic} inline-block -mx-1.5 -my-0.5 px-1.5 py-0.5  text-sm font-medium rounded-md text-gray-700 `}>
        <div className="truncate overflow-hidden" style={{maxWidth:'14rem'}}>{children}</div>
    </div>;

    if (!tooltip) {
        return a;
    }

    return <SimpleTooltip placement="bottom-start" text={tooltip}>
        {a}
    </SimpleTooltip>
}

export function DLCAssignee({data,openModal}) {
    const community = useCommunity();

    return <DetailListComponent onClick={() => {
        openModal();
    }} tooltip="Change Assignee">
        {!data.assignee ? <AddEl>Assign Member</AddEl> : <div className="-mt-1">
            {renderField({
                label: "",
                value: data.assignee,
                community_uid: community.id,
                type: "assignee",
            })}
        </div>}
    </DetailListComponent>
}

export function DLCAccountEmail({data, openModal}) {
const community = useCommunity();
    const is_anonymous = isAnonymousUnatyEmail(data.account_email);

    const is_admin = community.admins.all_user_uids[data.id];

    let message = is_admin ? "Only Admins can change their emails" : is_anonymous ? "Add Account Email" : "Change Account Email";

    const onClick = is_admin || !openModal ? null : () => {
        if (openModal) {
            openModal();
        }
    };

    return <DetailListComponent onClick={onClick} tooltip={message}>
        {is_anonymous ? <AddEl>Add Account Email</AddEl> : data.account_email}
    </DetailListComponent>
}

export function DLCHandle({data, openModal}) {

    return <DetailListComponent onClick={() => {
        if (openModal) {
            openModal();
        }
    }} tooltip="Change handle">
        @{data.handle}
    </DetailListComponent>
}

export function DLCGeneral({value,fallback,tooltip="",onClick}) {
    return <DetailListComponent onClick={()=>{
        if(onClick){
            onClick();
        }
    }} tooltip={tooltip}>
        {value?value:<AddEl>{fallback}</AddEl>}
    </DetailListComponent>
}

export function DLCBirthday({data,onClick}) {
const bd = valuePipeline(data.birthday || {}, {type: 'birthday'});
    return <DetailListComponent onClick={() => {
        onClick()
    }} tooltip="Edit Birthday">
        {!bd?<AddEl>Add Birthday</AddEl>:bd}
    </DetailListComponent>
}

function AddEl({children}) {
    return <span className="text-gray-400">{children}</span>
}

export function DLCJoined({data,onClick}) {

    const jd = valuePipeline(data.join_date || null, {type: 'join_date'});
    return <DetailListComponent onClick={() => {
        onClick();
    }} tooltip="Edit Join Date">
        {jd==="Unknown"||!jd?<AddEl>Add Join Date</AddEl>:jd}
    </DetailListComponent>
}

export function DLCPhone({data, onClick}) {
const pn = getPhoneNumberData(data.contact.phone);
    return <DetailListComponent onClick={() => {
        if (onClick) {
            onClick();
        }
    }} tooltip="Edit Phone Number">
        {pn.display || <AddEl>Add Phone</AddEl>}
    </DetailListComponent>
}

export function DLCMembershipStatus({data, value, onClick}) {
    return <DetailListComponent onClick={() => {
        onClick();
    }}>
        {value}
    </DetailListComponent>
}

export function DLCMemberType({data, value, onClick}) {

    return <DetailListComponent onClick={() => {
            onClick();
    }}>
        {value}
    </DetailListComponent>;
}

export function DLCGroupOrganizer({data}) {
    const community = useCommunity();
    return <DetailListComponent>
        <MemberGroupsOrganizersList fallback={<AddEl>N/A</AddEl>} data={data} community={community} />
    </DetailListComponent>;
}

export function DLCPositions({data}) {
    const community = useCommunity();
    const v = valuePipeline(data.roles || {}, {type: 'roles',community_uid:community.id});
    return <DetailListComponent>
        {v || <AddEl>N/A</AddEl>}
    </DetailListComponent>;
}

export function DLCLastLogin({data, value}) {
const ll = valuePipeline(data.last_sign_in || null, {type: 'last_sign_in'});
    return <DetailListComponent>
        {ll || <AddEl>Never signed in</AddEl>}
    </DetailListComponent>;
}

export function DLCID({data}) {
const toasts = useToasts();
    return <DetailListComponent onClick={() => {
        copyTextToClipboard(data.id,()=>{
            toasts.addToast({intent: "info", text: "Copied to Clipboard"});
        })
    }} tooltip="Click to copy ID">
        {data.id}
    </DetailListComponent>
}