import {collection, where, getDocs, limit, query} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {createUserWithEmailAndPassword,getIdToken} from "firebase/auth";
import {getCurrentLang, getCurrentTimezone} from "../../../../common/utilities/lang-region";
import {authFetch, externalAuthFetch} from "../../../../api/network";

export async function api_userExistsWithEmail(email) {
    const ref = collection(db, "users");
    const q = query(ref, where('account_email', '==', email.toLowerCase()), limit(1));
    return await getDocs(q)
        .then(snap => {
            return !snap.empty;
        });
}

export async function api_userExistsWithSecondaryEmail(email) {
    const ref = collection(db, "users");
    const q = query(ref, where('secondary_emails', 'array-contains', email.toLowerCase()), limit(1));
    return await getDocs(q)
        .then(snap => {
            return !snap.empty;
        });
}

export async function api_emailIsTaken(email) {
    let p = [];

    p.push(api_userExistsWithEmail(email.toLowerCase()));
    p.push(api_userExistsWithSecondaryEmail(email.toLowerCase()));

    return await Promise.all(p).then((r) => {
        for (let i = 0; i < r.length; i++) {
            if (r[i]) {
                return true;
            }
        }
        return false;
    })
}

export async function signUpUser(auth, email_clean, password, name, handle) {
    console.log("signUpUser",email_clean, password, name, handle)
    return await createUserWithEmailAndPassword(auth,email_clean.toLowerCase(), password).then(
        async result => {
            await updateToken(auth, result.user);
            return await createUserObject(result.user, handle, name)
                .then(()=>{
                    console.log("createUserObject")
                    return true;
                })
        }).catch((error) => {
        // Handle Errors here.
      return false;
    });
}

function updateToken(auth,user) {
    return getIdToken(user,true)
        .then((token) => {
            document.cookie = '__session=' + token + ';max-age=3600';
        });
}

async function createUserObject(user, handle, name) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        const name_split = name.split(' ');

        const data = {
            payload: {
                uid: user.uid,
                email: user.email,
                handle: handle,
                first_name: name_split[0],
                last_name: name_split[1] ? name_split[1] : ''
            },
            options: {
                language: getCurrentLang(),
                timezone: getCurrentTimezone()
            }
        };

        authFetch("/users/create-account", res, res, "POST", data)
    })

}