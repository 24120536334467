import {authFetch} from "./network";
import {chunk, getManyDocuments} from "../app/config/community";
import {doc, documentId, getDoc} from "firebase/firestore";
import {db} from "../app/config/setup-firestore";


export async function api_updateEvent(payload,community) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        authFetch("/events/edit", res, res, "POST", {payload});
    })
}

export async function api_deleteEvent(payload,community) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        authFetch("/events/delete", res, res, "POST", {payload});
    })
}

export async function api_deleteAllSpaceEvents(payload,community) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        authFetch("/events/delete-all-for-space", res, res, "POST", {payload});
    })
}

export async function api_createEvent(payload,community) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        authFetch("/events/create", res, res, "POST", {payload});
    })
}

export async function getEventData(uid, id) {
    if (!uid || !id) {
        console.error('getEventData', uid, id)
        return null;
    }
    const ref = doc(db, 'community_content', uid, 'events', id);
    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? {...doc.data(), id: doc.id} : null;
        });
}

export async function getManyEvents(cid,fids) {
    let p = [];
    const chunks = chunk(fids,10);
    chunks.forEach(a=>{
        p.push(getManyDocuments('community_content', cid, 'events', documentId(), a));
    })
    return await Promise.all(p).then(arrs=>{
        return arrs.flat();
    });
}