import React, {useEffect, useState} from "react"
import './styles.css';
import {RenderName, valuePipeline} from "./value-pipeline";
import {PlusIcon} from "@heroicons/react/20/solid";
import DataWrapper from "../data-wrapper";
import {getMemberGroupsOrganizing} from "../follow-button/api";
import {TextAction} from "../../../routes/auth/sign-in";
import {VisibilityStatusSymbol} from "../../../routes/community/member/utilities/visibility";
import {useCommunity} from "../../../config/community";
import {PageLayoutSection} from "../app-frame/layouts";
import M3_A_Text from "../../atoms/text";
import M3_A_Heading from "../../atoms/heading";
import {communityGetSectionsToDisplay} from "../../../routes/community/member/member-profile-utils";
import {getNestedValue} from "../../../../common/utilities/general";
import M3_A_Icon from "../../atoms/icon";
import M3_A_IconButton from "../../atoms/icon-button";

function PFLabel({children}) {
    return <div className="text-xs font-medium text-gray-600">
        {children}
    </div>
}

function PFValue({children, size = 'text-base'}) {
    return <div className={`${size} pt-0.5 text-gray-800 overflow-x-hidden`} style={{
        wordBreak: 'break-all'
    }}>
        {children}
    </div>
}

function EmptyValue({add_prompt, label, onAddClick}) {
    return <div>
        {add_prompt && <AddFieldPrompt field={label} onClick={onAddClick}/>}
    </div>
}

export function MemberGroupsOrganizersList({data, community, fallback = null}) {
    const [dt, setDt] = useState(null);

    useEffect(function () {
        if (!dt) {
            getMemberGroupsOrganizing(community.id, data.id)
                .then(gr => setDt([...gr]))
        }
    }, [dt, data.id, community.id])

    if (!data || !dt || dt.length === 0) {
        return fallback;
    }

    return <ShowMoreEntitiesWrapper items={dt.sort((x, y) => x.name > y.name ? 1 : -1)} renderItem={(it, i) => {
        return <RenderName can_hide={false} key={it.id} data={it} type="group"/>;
    }}/>;
}

export function ShowMoreEntitiesWrapper({items, renderItem, limit = 12}) {
    const [showMore, setShowMore] = useState(false);
    const show_count = showMore ? items.length : limit;
    return <div className="flex flex-wrap text-sm gap-1 mt-0.5">
        {items.slice(0, show_count).map((it, i) => {
            return renderItem(it, i);
        })}
        {items.length > limit && <div className="text-xs font-medium text-gray-600">
            <TextAction onClick={() => {
                setShowMore(!showMore);
            }} text={showMore ? "Show less" : "Show more"}/>
        </div>}
    </div>
}

export function MemberGroupsList({data, label = "Spaces", minimal, fallback = null}) {
const community = useCommunity();
    function content() {
        if (data.groups) {
            let fv = Object.keys(data.groups);

            return <ShowMoreEntitiesWrapper items={fv} renderItem={(id, i) => <DataWrapper key={id} id={id}
                                                                                           type={`community_entities.${community.uid}.groups`}>
                <RenderName type="group"/>
            </DataWrapper>}/>;
        } else {
            return fallback;
        }
    }

    if (minimal) {
        return content();
    }

    return <div className="-mx-1.5 px-1.5 py-1">
        <PFLabel>{label}</PFLabel>
        <div className="pt-0.5">
            {content()}
        </div>
    </div>
}

export function renderField(props) {
    const {label, value, size, type} = props;
    return <>
        {label && <PFLabel>{label}</PFLabel>}
        <PFValue size={size}>{props.empty ? <EmptyValue {...props} /> : valuePipeline(value, props)}</PFValue>
    </>
}

function Item(props) {
    const {footer, interactive, onClick, icon, value} = props;
    let ic = interactive ? "hover:bg-gray-100 cursor-pointer transition-colors" : "";
    return <div className="">
        <div onClick={() => {
            if (onClick) {
                onClick();
            }
        }} className={`space-x-2 ${icon ? "flex" : ""} -mx-1.5 px-1.5 py-1 rounded-md ${ic}`}>
            {icon && <div className="bg-gray-200 flex flex-none items-center justify-center h-10 w-10 rounded-md">
                <div className="w-5 h-5 text-gray-600">
                    {icon.icon}
                </div>
            </div>}
            <div className={`${icon ? "pt-px" : ""}`}>
                {renderField(props)}
                {footer}
            </div>
        </div>
    </div>
}

function SectionTitle({children, visibility, actions = []}) {

    return <div className="flex items-baseline">
        <div className="flex-grow">
            <M3_A_Heading size="h5">{children}</M3_A_Heading>
        </div>
        <div className="flex space-x-2">
            {actions.map((act, ind) => {
                return <div key={ind}>
                    {act}
                </div>
            })}
            {visibility && <div className="text-xs p-0.5 font-medium text-gray-600">
                <VisibilityStatusSymbol visibility={visibility}/>
            </div>}
        </div>
    </div>
}


export function ProfileSection({title, items, actions, preview, visibility, cols = 2}) {
    return <PageLayoutSection>
        <SectionTitle visibility={visibility} actions={actions}>
            {title}
        </SectionTitle>
        {items && <div className={`grid pt-2 ${cols === 1 ? "gap-1" : "gap-x-4 gap-y-2"}  mobile-1-col`}
                       style={{gridTemplateColumns: `repeat(${cols}, ${100 / cols}%)`}}>
            {items.map((item, k) => {
                /*
                if(item.empty) {
                    return null;
                }
                 */
                if (item.custom) {
                    return <div key={k}>
                        {item.component}
                    </div>
                }
                return <Item {...item} key={k}/>
            })}
        </div>}
    </PageLayoutSection>
}

export function AddFieldPrompt({
                                   field = "", onClick = () => {}
                               }) {
    return <div className="-mx-1">
        <M3_A_IconButton size="sm" icon={<PlusIcon/>} onClick={onClick} variant="minimal" text={`Add ${field}`} />
    </div>
}

export default function ProfileFieldRenderer({preview, my_profile, children, sections = []}) {
    const community = useCommunity();
    const section_visibility = communityGetSectionsToDisplay(community, community.is_admin, my_profile);

    return <>
        {sections.map((sec, k) => {
            const display = getNestedValue(sec.display_key || "", section_visibility);

            const sec_vis = getNestedValue(sec.vis_key, section_visibility) || "all-members";
            if (display === false) {
                return null;
            }
            return <ProfileSection visibility={sec_vis} cols={preview ? 1 : 2} preview={preview} {...sec}
                                   key={`${k}${sec.title}`}/>
        })}
        {children}
    </>
};