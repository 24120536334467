import React from "react";
import {ArrowRightIcon, ChevronDownIcon, ChevronLeftIcon, InformationCircleIcon} from "@heroicons/react/24/outline";
import SimpleTooltip from "../../../tooltip";
import {ExclamationCircleIcon} from "@heroicons/react/24/solid";

export const sanitization_codes = {
    'invalid-email': {
        desc: "This is not a valid email; please check for any spelling for formatting mistakes like a space in the email"
    }
};

export const lie_grid_style = {
    gridTemplateColumns: '2rem 0.8fr 2rem 1.4fr',
    gridGap: '1rem'
};

export function LIEBlank({text="Blank"}) {
    return <span className="text-gray-400 italic">{text}</span>
}

export function LIEDirectionArrow() {
    return <div className="w-4 h-4 text-gray-400">
        <ArrowRightIcon/>
    </div>
}

export function LIEToggle({open,onToggle}) {
    return <div onClick={onToggle} className="w-4 h-4 rounded-full hover:bg-gray-100 transition-colors cursor-pointer text-gray-500">
        {open?<ChevronDownIcon/>:<ChevronLeftIcon/>}
    </div>
}

export function lie_getRowIndicator(error, info, number = 1) {
    let m;
    if (error) {
        m = sanitization_codes[error];
        return <SimpleTooltip text={m?m.desc:error}>
            <div className="w-4 h-4 text-amber-400">
                <ExclamationCircleIcon/>
            </div>
        </SimpleTooltip>
    } else if (info) {
        m = sanitization_codes[info];
        return <SimpleTooltip text={m?m.desc:info}>
            <div className="w-4 h-4 text-secondary">
                <InformationCircleIcon/>
            </div>
        </SimpleTooltip>
    } else {
        return <div className="text-xs text-gray-500 w-8 leading-5 text-right">{number}</div>
    }
}