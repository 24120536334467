import {useCommunity} from "../../../config/community";
import React, {useEffect, useState} from "react";
import {SectionHeader} from "../../../m3/_legacy_components/entity-grid";
import FormFieldsRenderer, {getFieldValue, updateFieldValue} from "../../../m3/_legacy_components/form-fields-renderer";
import Button from "../../../m3/_legacy_components/button";
import {member_directory_definition} from "../../../m3/_legacy_components/directory/data-type-definitions";
import {getCFValue, isCFEmpty} from "../../../m3/_legacy_components/profile-field-renderer/value-pipeline";
import {getItemFromLocalStorage, saveItemToLocalStorage} from "../../../m3/utilities/localstorage";
import {member_editProfile} from "../member/api";
import {useToasts} from "../../../config/toasts";
import {IconAction} from "../../auth/sign-in";
import {EyeSlashIcon} from "@heroicons/react/24/outline";
import SimpleTooltip from "../../../m3/_legacy_components/tooltip";
import {SimpleProgressBar} from "../../../m3/_legacy_components/progress-bar";
import {CheckCircleIcon, CheckIcon, ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/20/solid";
import ContentPopup from "../../../m3/_legacy_components/content-popup";
import {isAnonymousUnatyEmail} from "../../../m3/utilities/email";
import {authFetch} from "../../../../api/network";

function Item({
                  onIgnore,
                  done,
                  onPrevious,
                  onNext,
                  can_next,
                  can_previous,
                  sublabel,
                  id,
                  onChange = () => {
                  }, member, data
              }) {
    const community = useCommunity();
    const toasts = useToasts();
    const [loading, setLoading] = useState(false);
    const [changes_made, setChangesMade] = useState(false);

    function handleOnChange(accessor, new_value, old_value, field) {
        onChange(accessor, new_value, old_value, field)
        setChangesMade(true);
    }

    const fs = [
        {...data}
    ];

    function handleUpdate() {
        setLoading(true);

        member_editProfile(community, id, {profile: member, updated_fields: [fs[0].accessor]})
            .then(() => {
                setLoading(false);
                setChangesMade(false);
                toasts.addToast({text: "Changes Saved", intent: "success"});
                community.updateMemberProfile();
                done()
            })
    }

    /*
        <div className="text-gray-600 text-sm pb-2 -mt-0.5">
                {fs[0].metasubtitle}
            </div>
     */

    return <div className="group relative w-full">
        <div className="border-gray-300 border rounded-lg p-2.5" style={{minHeight: '7rem'}}>
            <div className="font-bold text-gray-800 text-lg pb-2">
                <span>{fs[0].metatitle}</span>
            </div>
            <FormFieldsRenderer onChange={handleOnChange} data={member} fields={fs}/>
        </div>
        <div className="flex pt-2">
            <div className="flex-grow hidden sm:flex space-x-2">
                <Button left_icon={{icon: <ChevronLeftIcon/>}} onClick={() => onPrevious()} disabled={!can_previous}
                        text="Previous"/>
                <Button right_icon={{icon: <ChevronRightIcon/>}} onClick={() => onNext()} text="Next"
                        disabled={!can_next}/>
            </div>
            <div className="flex sm:space-x-2">
                <div className="hidden sm:block">
                    <Button onClick={() => onIgnore()} text="Doesn't apply"/>
                </div>
                <Button loading={loading} onClick={() => handleUpdate()} intent="primary"
                        disabled={!changes_made} text="Save & continue"/>
            </div>
        </div>
    </div>
}

function getCFTitle(cfd) {
    let title = cfd.question || cfd.name;
    if (!cfd.question && cfd.required) {
        title += ' (required)';
    }
    if (cfd.name === "2023 Member Opt-In (deadline January 27, 2023)") {
        return "Do you want to remain a Sandbox member this year?";
    }
    return title;
}

function buildValueMessage(type) {

    if (type === 'occupation') {
        return "Adding an occupation helps others find you in the _legacy_directory."
    } else if (type === 'location') {
        return "Adding a location helps others find you in the _legacy_directory."
    }
    // why is this valuable to fill out?
    return "Completing your profile helps you connect with other members.";
}

function buildTitle(label) {
    return `Add your ${label}`;
}

function buildFields(community) {
    let a = [];

    const fields_to_check = [
        'contact.phone',
        'profile_picture',

        'about.bio',
        'about.occupation',
        'about.location',
        'about.headline',
        //   'interests',
        //    'signature',
        'birthday',
        //     'join_date',
    ];

    // todo custom fields

    // do you want to remain a sandbox member this year?
    fields_to_check.forEach(fid => {
        const fd = member_directory_definition.properties[fid];
        if (fd) {
            const vv = getFieldValue(fd.field, community.member);
            a.push({
                _value: vv,
                title: "",
                label: "",
                raw_label: fd.label,
                metatitle: buildTitle(fd.label),
                metasubtitle: buildValueMessage(fd.type),
                type: fd.type,
                accessor: fd.field,
                empty: isCFEmpty(vv, fd.type),
                col_span: 2
            })
        } else {
            console.error("Field not found", fid);
        }
    })

    Object.entries(community.custom_fields).forEach(([key, cfs], index) => {
        cfs.fields.forEach(f => {
            const cfd = cfs.field_data[f];
            const cfv = getCFValue(f, cfd.type, community.member.custom_fields || {});
            if (community.important_custom_fields.includes(f)) {
                a.push({
                    _value: cfv,
                    label: "",
                    title: "",
                    metatitle: getCFTitle(cfd),
                    raw_label: cfd.name,
                    type: cfd.type,
                    metasubtitle: buildValueMessage(cfd.type),
                    accessor: `custom_fields.${f}`,
                    empty: isCFEmpty(cfv, cfd.type),
                    input_props: {
                        community_uid: community.uid,
                        options: cfd.options || {},
                    },
                    community_uid: community.uid,
                    required: cfd.required,
                    options: cfd?.options || {},
                    col_span: 2
                })
            }
        })
    })

    return a;
}

/*
   {fields.map(it => {
                if (ignore[`${it.accessor}`] || done[`${it.accessor}`]) {
                    return null;
                }
                return <Item key={it.accessor} done={() => {
                    setDone({
                        ...done,
                        [it.accessor]: true
                    })

                }} onIgnore={() => {
                    setIgnore({
                        ...ignore,
                        [it.accessor]: true
                    })
                }} data={it} onChange={handleChange} id={community.member.id} member={dt}/>;
            })}

            const tooltip_content = <ContentPopup>
        <div className="p-2 w-56">
            <div className="text-sm text-gray-800">
                <div className="pb-0.5 font-semibold">Completed Steps</div>
            </div>
            <div>
                {steps_summary.map((it, k) => {
                    return <div className="flex items-center py-0.5" key={k}>
                        <div className="flex-none w-6">
                            {it.completed && <div className="w-4 h-4 text-gray-700 svg-child-inherit">
                                <CheckIcon/>
                            </div>}
                        </div>
                        <div className="flex-grow text-sm font-medium text-gray-800">
                            {it.title}
                        </div>
                    </div>
                })}
            </div>
        </div>
    </ContentPopup>
 */

function ProgressBarWrapper({progress, steps_summary}) {

    return <div className="relative">
        <SimpleProgressBar height={10} intent="dynamic" value={progress}/>
        <div className="absolute bg-white h-2.5 w-1 bottom-0" style={{left: '20%'}}/>
        <div className="absolute bg-white h-2.5 w-1 bottom-0" style={{left: '40%'}}/>
        <div className="absolute bg-white h-2.5 w-1 bottom-0" style={{left: '60%'}}/>
        <div className="absolute bg-white h-2.5 w-1 bottom-0" style={{left: '80%'}}/>
    </div>
}

function buildStepsSummary(all, remaining) {
    let a = [];

    all.forEach(it => {
        a.push({
            title: it.raw_label,
            completed: !remaining.includes(it)
        })
    })

    const sort_by_completed = (a, b) => {
        if (a.completed && !b.completed) {
            return -1;
        }
        if (!a.completed && b.completed) {
            return 1;
        }
        return 0;
    };

    return a.sort(sort_by_completed);
}

export function getProfileQualityLevel(member, community, ignore = {}) {
    if (!member) {
        return "";
    }
    const all_fields = buildFields(community);

    const relevant = all_fields.filter(a => a.type !== 'address');

    const empty = relevant.filter(a => {
        if (ignore[a.accessor]) {
            return false;
        }
        return a.empty;
    });

    const score_per_field = 100 / relevant.length;

    if (!member.account_email || isAnonymousUnatyEmail(member.account_email)) {
        return "Incomplete"
    }

    const current_score = (relevant.length - empty.length) * score_per_field;

    if (current_score < 20) {
        return "Incomplete";
    } else if (current_score < 40) {
        return "Basic";
    } else if (current_score < 60) {
        return "Intermediate";
    } else if (current_score < 80) {
        return "Advanced";
    } else {
        return "Perfect";
    }
}

export function OnlyVisibleToYou() {
    return <SimpleTooltip text="Only visible to you">
        <IconAction>
            <EyeSlashIcon/>
        </IconAction>
    </SimpleTooltip>
}

export async function updateIgnoredFields(community, id, ignore) {

    const uid = community.id;

    if (!community || !uid || !community.member_id) {
        console.error("Community not set")
        return;
    }

    const key = `${community.domain}-${community.member_id}-data-check-ignore`;
    saveItemToLocalStorage(key, ignore);

    const payload = {
        community_uid: uid,
        member_id: community.member_id,
        id,
        fields: Object.keys(ignore)
    }

    const res = (resp) => {
        console.log(resp);
    };

    authFetch('/members/save-ignored-fields', res, res, 'POST', {payload});
}

export function CompleteYourProfile() {
    const community = useCommunity();

    const key = `${community.domain}-${community.member_id}-data-check-ignore`;
    const done_key = `${community.domain}-${community.member_id}-data-check-done`;

    const [fields, setFields] = useState(buildFields(community))
    const [ignore, setIgnore] = useState(getItemFromLocalStorage(key, {}));
    const [done, setDone] = useState({});
    const [selected, setSelected] = useState(null);
    const [hide, setHide] = useState(getItemFromLocalStorage(done_key, false));

    const relevant_steps = fields.filter(a => a.type !== 'address');

    const to_fill_out = fields.filter(a => a.type !== 'address').filter(b => b.empty).filter(a => !ignore[a.accessor]).filter(a => !done[a.accessor]);

    const remaining = to_fill_out.length;

    useEffect(function () {
        setFields(buildFields(community))
    }, [community.member]);

    useEffect(function () {
        return () => {
            handleSaveIgnoreFields()
        }
    }, [])

    function handleSaveIgnoreFields() {
        updateIgnoredFields(community, community.member_id, getItemFromLocalStorage(key, {}))
            .then(() => {
            })
    }

    useEffect(function () {
        if (ignore) {
            saveItemToLocalStorage(key, ignore)
        }
    }, [ignore]);

    useEffect(function () {
        if (fields.length === 0) {
            return;
        }
        if (!remaining && selected) {
            setSelected(null);
            return;
        }
        if (selected === null && to_fill_out.length > 0) {
            setSelected(0);
        } else if (selected !== null && to_fill_out.length > 0) {
            if (selected + 1 > to_fill_out.length) {
                setSelected(to_fill_out.length - 1);
            }
        }
    }, [fields, ignore, done, selected]);

    function next() {
        setSelected(selected + 1)
    }

    function previous() {
        setSelected(selected - 1)
    }

    const [dt, setDt] = useState({...community.member});

    function handleChange(accessor, new_value, old_value, field) {
        setDt({
            ...updateFieldValue(accessor, new_value, dt)
        })
    }

    if (hide) {
        return null;
    }

    if (remaining === 0) {

        const done_count = Object.keys(done).length;
        const ignore_count = Object.keys(ignore).length;

        if (done_count > 0 || ignore_count > 0) {
            return <div className="">
                <div className="border border-gray-300 p-4 flex items-center flex-col text-center rounded-xl">
                    <div className="pb-2">
                        <div className="h-8 w-8 text-blue-600">
                            <CheckCircleIcon/>
                        </div>
                    </div>
                    <h3 className="text-lg font-bold">
                        Nice job, you're profile is complete!
                    </h3>
                    <p className="text-gray-500 text-base">
                        Explore the community and start connecting with other members.
                    </p>
                    <div className="pt-3">
                        <Button onClick={() => {
                            saveItemToLocalStorage(done_key, true);
                            setHide(true);
                        }} intent="primary" text="Got it!"/>
                    </div>
                </div>
            </div>
        }

        return null;
    }

    const steps_left = remaining;

    const progress = (fields.length - remaining) / fields.length;

    const subtitle = <div className="flex space-x-1 items-center">
        {steps_left} more step{steps_left === 1 ? "" : "s"} to connect with other members
    </div>

    function renderField() {
        const can_next = selected < to_fill_out.length - 1;
        const can_previous = selected > 0;
        if (selected !== null) {
            const field_data = to_fill_out[selected];

            if (!field_data) {
                return <div className="group relative w-full">
                    <div className="border-gray-400 border rounded-lg p-2.5" style={{minHeight: '6rem'}}>

                    </div>
                    <div className="flex pt-2 h-10">

                    </div>
                </div>;
            }

            return <Item data={field_data} can_next={can_next} can_previous={can_previous} onNext={next}
                         onPrevious={previous} member={dt}
                         onChange={handleChange} onIgnore={() => {
                setIgnore({...ignore, [field_data.accessor]: true});
            }} done={() => {
                setDone({...done, [field_data.accessor]: true});
            }} id={community.member.id}/>
        }
    }

    const steps_summary = buildStepsSummary(relevant_steps, to_fill_out);

    const profile_quality_level = getProfileQualityLevel(community.member, community, ignore);

    const title = <span>Complete your Profile</span>;

    let actions = <div>
        <OnlyVisibleToYou/>
    </div>

    //subtitle={subtitle}
    return <div>
        <SectionHeader actions={actions} title={title} size="text-lg"/>
        <div className="pt-2">
            <ProgressBarWrapper steps_summary={steps_summary} progress={progress}/>
        </div>
        <div className="flex overflow-x-auto pt-3 gap-3">
            {renderField()}
        </div>
    </div>
}