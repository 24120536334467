import React from 'react';

export function GeneralListItem({title = "Group Name", subtitle = []}) {
    return <div className="flex px-3 py-2">
        <div className="flex-grow">
            <div className="text-base flex font-bold text-gray-800 ">
                {title}
            </div>
            <div className="text-sm space-x-3 flex -mt-0.5 text-gray-500">
                {subtitle.map((st,key)=>{
                    return <div key={key}>
                        {st}
                    </div>
                })}
            </div>
        </div>
        <div></div>
    </div>
}

export function MMGroupsView({groups = [],member_id}) {
    return <div className="divide-y divide-gray-200">
        {groups.length===0&&<div className="p-4 text-gray-500 text-sm">
            No Spaces found
        </div>}
        {groups.map(group=>{
            const is_organizer = !!group.moderators.user_uids[member_id];
            return <GeneralListItem key={group.id}
                                    title={group.name||""}
                                    subtitle={[`${group.closed ? "Closed" : "Open"} Space`,(is_organizer?'Organizer':"")]}
                                    />
        })}
    </div>
}