export const data__profile_field_editability = {
    'standard': {
        label: "Member & Admins",
        description: "Member and any Admins can edit"
    },
    'read-only': {
        label: "Admins only",
        description: "Only Admins can edit"
    },
    'locked': {
        label: "Locked if set",
        description: "Members can edit if empty"
    },
    'derived': {
        label: "Derived",
        description: "Derived from other fields",
        is_disabled: true
    },
    'dynamic': {
        label: "Dynamic",
        description: "Dynamic based on activity",
        is_disabled: true
    }
};

export const data__profile_field_editability_arr = Object.entries(data__profile_field_editability).map(([k, v]) => {
    return {
        value: k,
        label: v.label,
        disabled: v.is_disabled
    }
})