import React from "react";
import {WB_CoreText} from "./core/text";
import {WB_CoreBlockquote} from "./core/blockquote";
import {WB_CoreBulletList} from "./core/bullet-list";
import {WB_CoreHeaderOne} from "./core/header-one";
import {WB_CoreHeaderTwo} from "./core/header-two";
import {WB_CoreHeaderThree} from "./core/header-three";
import {WB_CoreHorizontalDivider} from "./core/horizontal-divider";
import {WB_MediaImage} from "./media/image";
import {WB_MediaVideo} from "./media/video";
import {WB_MessagesPost} from "./messages/post";
import {WB_MessagesReply} from "./messages/reply";
import {WB_CustomPollSingleChoice} from "./custom/poll-single-choice";
import {WB_CustomEventsNextNEvents} from "./custom/events-next-n-events";
import {WB_CustomLeadersGroupModerators} from "./custom/leaders-group-moderators";
import {WB_CustomLeadersTeamLeaders} from "./custom/leaders-team-leaders";
import {WB_CoreNumberedList} from "./core/numbered-list";
import {WB_MediaLink} from "./media/link";

export function ContentBlocksShowcase() {
    return <div className="space-y-3">
        <WB_CoreText />
        <WB_CoreBlockquote />
        <WB_CoreBulletList />
        <WB_CoreNumberedList />
        <WB_CoreHeaderOne />
        <WB_CoreHeaderTwo />
        <WB_CoreHeaderThree />
        <WB_CoreHorizontalDivider />
        <WB_MediaImage />
        <WB_MediaVideo />
        <WB_MessagesPost />
        <WB_MessagesReply />
        <WB_MediaLink />
        <WB_CustomPollSingleChoice />
        <WB_CustomEventsNextNEvents />
        <WB_CustomLeadersGroupModerators />
        <WB_CustomLeadersTeamLeaders />
    </div>
}