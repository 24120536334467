import {collection, getDocs, limit, query, where} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {groupsFollowSchemaWithCities} from "../../../m3/utilities/get-name-initials";
import {authFetch, externalAuthFetch} from "../../../../api/network";

export function isInLastXMinutes(date, min) {
    const minutes = min * 60 * 1000;

    return ((new Date()) - date) < minutes;
}

const one_month = 60 * 24 * 30;
const ten_days = 60 * 24 * 10;

export function isInLast30Days(dt) {
    if (!dt) {
        return false;
    }
    return isInLastXMinutes(dt, one_month);
}

export function getImageType(data, flags = {}) {
    if (!data) {
        return "";
    }
    if (data.profile_picture) {
        return flags.minimal ? "small-image" : "image"
    }
    if (data.emoji) {
        return "emoji"
    }
    return "text"
}

function intersection(o1, o2) {
    return Object.keys(o1).filter({}.hasOwnProperty.bind(o2));
}

export function getGroupMembersFollowers(group, community) {

    const following = community?.member_following?.members || {};

    const group_members = group?.user_uids || {};

    return intersection(following, group_members);
}

export async function markGroupAsUpToDate(cid,gid,member_id) {
    const p2 = {
        community_uid: cid,
        group_id: gid,
        member_id: member_id
    };
    return new Promise(resolve => {
        const r = () => {
            resolve();
        };
        authFetch('/members/group-caught-up', r, r, "POST", {payload: p2});
    })
}


export async function getTopicData(cid, gid) {
    const ref = collection(db, "community_content", cid, "series");
    const q = query(ref, where('group_id', '==', gid), limit(50));
    return await getDocs(q)
        .then((querySnapshot) => {
            let a = [];
            querySnapshot.forEach((doc) => {
                a.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            return a;
        });
}

export function buildPinboardData(data) {
    let a = {
        pins: [],
        pins_data: {},
        pins_sections: []
    };

    if (data?.pins) {
        a.pins = data.pins;
    }

    if (data?.pins_data) {
        a.pins_data = data.pins_data;
    }

    if (data?.pins_sections) {
        a.pins_sections = data.pins_sections;
    } else {
        a.pins_sections = [
            {
                id: "default",
                label: "Default",
                pins: a.pins || []
            }
        ];
    }

    return a;
}

export function shouldGetSuggestionForLocation({name, location, location_place_id}, all_names) {
    if (location || location_place_id) {
        // already has a location
        return false;
    }

    if (name && name.split(' ').length > 0) {
        const groups_follow_city_schema = groupsFollowSchemaWithCities(all_names);
        if (groups_follow_city_schema && name.includes(groups_follow_city_schema)) {
            const suggestion = name.replace(groups_follow_city_schema, '').trim();
            if (suggestion.length > 3) {
                return suggestion;
            }
        }
    }

    return false;

}

export async function searchForCity(query) {
    return await new Promise((resolve, reject) => {
        const handleResult = (result) => {
            if (result && result.ok && result.data.cities) {
                resolve(result.data.cities.map(result => {
                    return {
                        value: result.value,
                        id: result.value,
                        label: result.label,
                        note: result.note || ""
                    }
                }))
            } else {
                resolve([])
            }
        }
        try {
            externalAuthFetch("/cities-autocomplete", handleResult, handleResult, "POST", {query})
        } catch (e) {
            reject(e);
        }
    });
}


export function buildWriteWidgets(has_calendar) {
    let p = [];

    if (has_calendar) {
        p.push({
            id: "google_calendar-upcoming_events",
        })
    }

    return p;
}

export function getGroupAccessForMember(access_item, is_organizer, is_member, is_admin, member, member_types) {
    let flag = false;

    if (is_admin) {
        flag = true;
    }

    if (access_item.moderators && is_organizer) {
        flag = true;
    }

    if (access_item.group_members && is_member) {
        flag = true;
    }

    // todo finish this with membership levels, roles

    return flag;
}
