import React, {useEffect, useState} from 'react';
import {parseStringifiedJSON} from "../../group/manage/tabs/forum";
import {collection, getDocs, limit, query, where} from "firebase/firestore";
import {db} from "../../../../config/setup-firestore";
import {useCommunity} from "../../../../config/community";
import {FieldLabel} from "../../../../m3/_legacy_components/form/field";
import {TextAction} from "../../../auth/sign-in";
import {DraftComposer} from "../../message/replies/draft-composer";
import {SectionHeader} from "../../../../m3/_legacy_components/entity-grid";
import Button from "../../../../m3/_legacy_components/button";
import {convertFromRaw, convertToRaw} from "draft-js";
import {useToasts} from "../../../../config/toasts";
import {authFetch} from "../../../../../api/network";
import {ToggleWithTitle} from "../../../../m3/_legacy_components/toggle-with-title";

/*
Where to store the member signature...
- thinking it should be separate from the member query object directly..
- need to store in a new kind of post block..

let's do a signatures collection
 */

export async function getMemberSignature(community_uid, member_id) {
    const sigs = await getMemberSignatures(community_uid, member_id);

    if(sigs.length===0) {
        return "";
    } else {
        return sigs[0].raw;
    }
}

async function getMemberSignatures(community_uid, member_id) {
    const ref = collection(db, "community_content", community_uid, "signatures");
    const q = query(ref, where('member_id', '==', member_id), limit(50));
    return await getDocs(q)
        .then((querySnapshot) => {
            let a = [];
            querySnapshot.forEach((doc) => {
                a.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            return a;
        });
}

async function savePostsEmailPreferenceChanges(community, signature, preferences = {}) {
    // only save signature if it's filled, how to handle delete

    // for preferences, save only_apply_via_email as a bool and email_signature_id

    const payload = {
        community_uid: community.id,
        member_id: community.member_id,

        signature: !signature ? "" : signature,

        preferences
    };

    return await new Promise((resolve, reject) => {
        const r = (resp) => {
            resolve(resp);
        }
        authFetch("/members/update-my-posts-email-preferences", r, r, "POST", {payload});
    });
}

function convertToContentState(obj) {
    if (!obj || !obj.raw || typeof obj.raw !== 'string') {
        return null;
    }
    try {
        let parsed = JSON.parse(obj.raw);

        if (parsed && typeof parsed === "object") {
            return convertFromRaw(parsed);
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
}

function buildSignature(member, all_roles) {
    let a = `${member.name}`;
    let my_roles = Object.values(all_roles).filter(b => b.assignee === member.id).map(c => c.name);
    if (my_roles.length > 0) {
        a += `\n${my_roles.join(', ')}`;
    }
    if (member.account_email) {
        a += `\n${member.account_email}`
    }
    if (member.contact.phone) {
        a += `\n${member.contact.phone}`
    }
    return a;
}

export function MemberPreferencesPostsEmail() {
    const community = useCommunity();
    const toasts = useToasts();
    const [member_signature, setMemberSignature] = useState(null);
    const [only_apply_via_email, setOnlyApplyViaEmail] = useState(true);
    const [changed, setChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const [signatures, setSignatures] = useState(null);

    useEffect(function () {
        getSigs();
    }, [])

    function prefill() {
        const generated = buildSignature(community.member, community.all_roles);

        console.log("GENERATED", generated)
        alert("TODO")
        // now I need to create
    }

    function saveChanges() {
        setLoading(true);

        toasts.addToast({
            text: "Updating",
            intent: "info",
        });

        const sig = !member_signature?"":JSON.stringify(convertToRaw(member_signature));

        community.setMemberSignature(sig)

        savePostsEmailPreferenceChanges(community, sig, {
            only_apply_via_email,
            signature_id: signatures?.[0]?.id
        })
            .then(() => {
                setLoading(false);
                setChanged(false);
                toasts.addToast({
                    text: "Updated",
                    intent: "success",
                });
            })
    }

    function getSigs() {
        getMemberSignatures(community.id, community.member_id)
            .then(sigs => {
                setMemberSignature(sigs.length > 0 ? convertToContentState(sigs[0]) : null);
                setSignatures(sigs);
            })
    }

    const can_create_signature = signatures && signatures.length < 2;

    const save_btn = <Button loading={loading} intent="success" size="small" onClick={() => {
        saveChanges();
    }} disabled={!changed}>Save</Button>;

    const signature_is_empty = !member_signature || (member_signature && member_signature.getPlainText().length===0);

    return <div className="grid gap-6">
        <SectionHeader actions={save_btn} title="New Posts"/>
        <div>
            <ToggleWithTitle
                title="Show signatures only on email"
                subtitle="Signature will not show for posts within the app."
                checked={only_apply_via_email}
                onToggle={() => {
                    setOnlyApplyViaEmail(!only_apply_via_email);
                    setChanged(true);
                }}
            />
        </div>
        {signatures && <div>
            <FieldLabel corner_hint={signature_is_empty&&<TextAction onClick={()=>{
                prefill()
            }} />} label="Signature"/>
            <div className="input-focus px-3 min-h-[8rem] pb-4 rounded-md">
                <DraftComposer content_state={member_signature} show_toolbar={false} type="signature"
                               update={(es, att) => {
                                   // member signature here is stored as a content state that can be null
                                   if (es) {
                                       const cs = es.getCurrentContent();
                                       const plain_text = cs.getPlainText();

                                       // check if is the same as current
                                       const current_plain_text = member_signature ? member_signature.getPlainText() : "";

                                       if (current_plain_text === plain_text) {
                                           console.log("Is the same..")
                                           return;
                                       }

                                       setMemberSignature(cs);
                                       setChanged(true);
                                   }
                               }}/>
            </div>
        </div>}
    </div>
}