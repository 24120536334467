import React, {useEffect, useState} from 'react';
import {authFetch} from "../../../../api/network";
import Button from "../../../m3/_legacy_components/button";
import {changeMemberHandle} from "../../community/manage-member";
import {useToasts} from "../../../config/toasts";
import {useUnaverse} from "../../../config/unaverse";
import {collection, doc, getDocs, query, where} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {slugify} from "../../../m3/utilities/slugify";

export function HandleFixerFrame({community_uid}) {
    const [handle,setHandle] = useState("");
    const [state,setState] = useState("");

    return <div className="space-y-4">
        <div>
            Handle Fixer
        </div>
        <div className="p-2">
            <input placeholder="handle" value={handle} onChange={e=>setHandle(e.target.value)} />
            <Button disabled={!handle} text="Go" onClick={()=>setState("ready")}/>
            <Button text="Clear" onClick={()=>setState("")}/>
        </div>
        {state==="ready"&&<HandleFixer community_uid={community_uid} handle={handle} />}
    </div>
}


function HandleFixer({community_uid="",handle=""}) {
    const [data,setData] = useState();
    const unaverse = useUnaverse();
    const [community_member,setCommunityMember] = useState(null);
    const [loading,setLoading] = useState(false);

    useEffect(()=>{
        getData()
        getCommunityMember();
    }, [community_uid,handle])

    function getCommunityMember() {
        if (!community_uid || !handle) return;
        const members_ref = collection(db, "community_members", community_uid, "members");

        const search = query(members_ref, where("user_uid", "==", unaverse.user_uid));

        getDocs(search)
            .then((querySnapshot) => {
            const doc = querySnapshot.docs[0];
            if(doc) {
                setCommunityMember(doc.data());
            } else {
                console.error("No community member found")
            }
        })
    }

    function getData() {
        setLoading(true);

        const payload = {
            job: "handle-info",
            handle,
            community_uid
        };

        const handler = (resp) => {
            console.log("RESP",resp.data)
            setData(resp.data);
            setLoading(false);
        }

        authFetch("/superadmin/cleaner", handler, handler, "POST", {payload});
    }

    if(loading||!community_member) {
        return <div>Loading...</div>
    }

    return <div className="grid grid-cols-2">
        <div>
            <div className="font-bold">Handle @{handle}</div>
            <div>{data?.members.length} members</div>
            <div>{data?.groups.length} groups</div>
            <div>{data?.roles.length} roles</div>
            <div>{data?.member_types.length} membership levels</div>
            <div>{data?.handle_data.length} handle entries</div>
        </div>
        <div>
            <div>Members</div>
            <div>
                {data?.members.map((m,i)=>(
                    <MemberEntry community_member={community_member} community_uid={community_uid} {...m} key={i} />
                ))}
            </div>
        </div>
    </div>
}

function MemberEntry({id,community_member,community_uid,handle,name}) {
    const [new_handle,setNewHandle] = useState(slugify(name));
    const toasts = useToasts();

    function handleUpdate() {
        toasts.addToast({text: "Updating handle..."});
        changeMemberHandle({
            member: community_member,
            uid: community_uid,
            member_id: community_member.id,
            user_uid: community_member.user_uid
        }, id, new_handle)
            .then((resp)=>{

                toasts.addToast({text: `Member handle changed`, intent: 'success'});
            })
    }
return <div>
    <div>{id} / @{handle} / {name}</div>
    <div>
        <input value={new_handle} onChange={e=>setNewHandle(e.target.value)} placeholder="new handle" />
        <Button text="Update" onClick={handleUpdate} />
    </div>
</div>
}