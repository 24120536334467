import React from 'react';
import M3_A_Heading from "../atoms/heading";
import M3_A_Link from "../atoms/link";
import M3_A_Text from "../atoms/text";
import M3_A_Icon from "../atoms/icon";

/*
    This is a simple component that renders a block with a title and a content.
 */

const intent_bg_color_map = {
  "edit": "bg-yellow-50",
  "private": "bg-orange-50",
  "admin": "bg-purple-50"
};

export default function M3_C_Block({title, actions, top, intent, children}) {
    let wrapper_classes = "-mx-4 sm:-mx-6 px-4 sm:px-6 py-2 sm:py-4 -my-2 sm:-my-4";

    if(intent) {
        wrapper_classes += " " + intent_bg_color_map[intent];
    }

    return <div className={wrapper_classes}>
        {top && <div className="flex gap-2 -mt-1 h-6 pb-0.5 items-center text-gray-500">
            {top?.icon && <M3_A_Icon size="2xs" icon={top.icon} />}
            {top?.label && <>
                <M3_A_Text truncate size="xs">{top.label}</M3_A_Text>
            </>}
        </div>}
        {title && <div className="flex items-baseline">
            <div className="flex-grow pt-0.5">
                <M3_A_Heading size="h6">{title}</M3_A_Heading>
            </div>
            {actions?.length&&<div className="flex gap-2">
                {actions.map((act,index) => {
                    return <M3_A_Text size="sm" key={index}>
                        <M3_A_Link variant="action"
                                   onClick={() => act?.onClick()}>{act.label}</M3_A_Link>
                    </M3_A_Text>
                })}
            </div>}
        </div>}
        <div className={`pt-2`}>
            {children}
        </div>
    </div>
}