import {getStorage, ref, uploadBytes} from "firebase/storage";
import React, {useRef, useState} from "react";
import {doc, onSnapshot} from "firebase/firestore";
import {db} from "../../app/config/setup-firestore";
import {getAverageRGB, rgbToHex} from "../../app/m3/_legacy_components/badge-builder/utilities";

export function buildImageUrl(u, size = '_medium', fb) {
    if (u && u.indexOf('alt=media') !== -1) {
        return u;
    }
    const und = !size ? "" : size.indexOf("_") === 0 ? size : `_${size}`;

    return u ? `${u}${und}?alt=media` : fb ? `${fb}${und}?alt=media` : "";
}

function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

function generateId() {
    return randomIntFromInterval(0, 2423493);
}

async function handleFileUpload(fileRef, file, metadata) {
    return new Promise((resolve, reject) => {
        uploadBytes(fileRef, file, metadata)
            .then((snapshot) => {
                return resolve(snapshot);
            })
    })
}

async function loadImage(file) {
    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();
            reader.onload = function (e) {
                const img = new Image;
                img.onload = function () {
                    resolve(img);
                };
                img.onerror = function () {
                    reject();
                };
                img.src = reader.result; // is the data URL because called with readAsDataURL
            }
            reader.readAsDataURL(file);
        } catch (e) {
            // console.log("LOAD IMAGE ERROR", e)
            reject(e);
        }
    })
}

const default_allowed_types = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/gif"
];

const default_options = {
    max_size_mb: 10,
    allowed_types: [...default_allowed_types],
    bucket: "unaty-posts",
    base_path: "test-community-id/posts/test-post-id/images"
};

function getHeightAndWidth(height,width) {
    // max is 2048
    const max = 2048;
    const ratio = height/width;
    if(height>width) {
        if(height>max) {
            return [Math.floor(max/ratio), max];
        }
    } else {
        if(width>max) {
            return [max,Math.floor(max/ratio)];
        }
    }

    return [height,width];
}

async function handleFileLoad(file, _options = {}) {
    if (!file) return {
        ok: false,

        error: "invalid-file"
    };
    const options = {
        ...default_options,
        ..._options
    };
    // console.log("file", file)
    // check type
    if (!options.allowed_types.includes(file.type)) {
        return {
            ok: false,
            error: "invalid-file-type"
        };
    }

    // check size
    const max_size_bytes = options.max_size_mb * 1024 * 1024;
    if (file.size > max_size_bytes) {
        return {
            ok: false,
            error: "invalid-file-size"
        };
    }

    try {
        // console.log("try load image", file)
        return await image_utilities
            .loadImage(file)
            .then(function (img) {
                //   console.log("img loaded", img)
                const width = img.width;
                const height = img.height;
                const color_rgb = getAverageRGB(img);
                const color_hex = rgbToHex(color_rgb.r, color_rgb.g, color_rgb.b);

                // ensure width and height are > 0
                if (width <= 0 || height <= 0) {

                    return {
                        ok: false,
                        error: "invalid-dimensions"
                    };
                }

                const id = image_utilities.generateId();

                const storage = getStorage(undefined, options?.bucket);
                const path = ref(storage, `${options?.base_path}/${id}/${id}.png`);

                const [h,w] = getHeightAndWidth(height,width);

                return {
                    ok: true,

                    bucket: options?.bucket,

                    color: color_hex,
                    path,
                    metadata: {
                        contentType: file.type
                    },

                    file,
                    id,
                    height: h,
                    width: w,
                    type: file.type,
                    status: "none"
                };
            })
    } catch (e) {
          console.error("error", e)
        return {
            ok: false,
            error: "unknown-error"
        };
    }
}

async function listenForResizedImages(id, path) {
    return new Promise((resolve, reject) => {
        const ref = doc(db, "events_listener", `image-${id}`);
        const unsubscribe = onSnapshot(ref, (document) => {
            const data = document.data();
            if (data) {
                if (data.ok) {
                    resolve(data);
                    unsubscribe();
                } else {
                    // error
                    reject(data);
                    unsubscribe();
                }
            }
        });
    });
}

async function uploadImageFile(image) {

    // bucket is unaty-posts
    const storage = getStorage(undefined, image.bucket);
    const fileRef = ref(storage, image.path);

    console.log("fileRef START UPLOAD", {id: image.id, image});
    return await image_utilities
        .handleFileUpload(fileRef, image.file, image.metadata)
        .then(async (snapshot) => {
            // listen now for updates
            return await listenForResizedImages(image.id, image.path);
        })
}

export function useImageResizeUploader(_images = [], bucket = "unaty-posts", base_path = "test-community-id/posts/test-post-id/images") {
    const [images, setImages] = useState(_images);
    const images_data_ref = useRef(images);
    const [error, setError] = useState(null);
    const handleFileChange = (e) => {
        const files = e.target.files;
        const file = files[0];
        image_utilities
            .handleFileLoad(file)
            .then((image_metadata) => {

                if (image_metadata?.ok) {
                    setImages([...images, image_metadata]);
                } else {

                    setError(image_metadata?.error)
                }
            });
    }

    React.useEffect(() => {
        images.forEach(image => {
            if (image.status === "none") {
                // upload file to firebase storage
                // set image status to success or error

                // need to update status
                image.status = "loading";

                setImages([...images]);

                uploadImageFile(image, base_path, bucket)
                    .then(async (snapshot) => {
                        // listen for resized images
                        return await listenForResizedImages(image.id, image.path);
                    })
            }
        });

        // update data ref
        images_data_ref.current = images;
    }, [images, base_path, bucket]);

    const loading_statuses = ["loading", "none"]

    const loading_completed = images.filter(image => !loading_statuses.includes(image.status)).length === images.length;

    return [images, handleFileChange, error, {
        is_loading: !loading_completed,
    }];
}

// https://firebasestorage.googleapis.com/v0/b/unaty-posts/o/whCu8ZP1U9wD16xhTQeA%2Fposts%2F_temp%2Fimages%2F129218%2F129218_2048x2048.png?alt=media
function adjustUrlSize(url = "", size = "2048x2048") {
    if (!url) {
        return "";
    } else if (url.indexOf("?alt=media") === -1 || url.indexOf("firebasestorage.googleapis.com") === -1 || url.indexOf("2048x2048.") === -1) {
        return url;
    } else {
        return url.replace("2048x2048.", `${size}.`);
    }
}

export const image_utilities = {
    handleFileLoad,
    loadImage,
    adjustUrlSize,
    generateId,
    handleFileUpload,
    uploadImageFile,
    buildImageUrl
};