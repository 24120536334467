function getLabel(b) {
    if(typeof b === "string") {
        return b;
    }
    return b.label ? b.label : b.text ? b.text : "";
}

function utils_objects_buildOptionsArray(obj) {
    return Object.entries(obj).map(([id,b])=>{

        if(b&&typeof b === "object") {
            return {value:id,...b,label:getLabel(b)};
        } else {
            return {value:id,label:getLabel(b)};
        }
    })
}

function utils_objects_unique(a) {
    for(let i=0; i<a.length; ++i) {
        for(let j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }
    return a;
}

function utils_objects_getObjectOptions(obj) {
    return Object.entries(obj).map(([id, ob]) => {
        return {
            id,
            value:id,
            label: typeof ob === "string" ? ob : (ob.label || "")
        }
    });
}

function utils_objects_moveArrayItem(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
}

function utils_objects_getKeysFromArray(arr, key) {
    let obj = {};
    for (let i = 0; i < arr.length; i++) {
        obj[arr[i][key]] = true
    }
    return obj;
}

export {
    utils_objects_getKeysFromArray,
    utils_objects_moveArrayItem,
    utils_objects_getObjectOptions,
    utils_objects_unique,
    utils_objects_buildOptionsArray
};