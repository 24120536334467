import React, {useState} from "react";
import {useCommunity} from "../../../../config/community";
import FormFieldsRenderer from "../../../../m3/_legacy_components/form-fields-renderer";
import SimplePortal from "../../../../m3/_legacy_components/simple-portal";
import Modal from "../../../../m3/_legacy_components/modal";
import MetaHeader from "../../../../m3/_legacy_components/meta-header";
import Button from "../../../../m3/_legacy_components/button";
import {TextAction} from "../../../auth/sign-in";
import {useToasts} from "../../../../config/toasts";
import {api_updatePosition} from "../../../../../api/positions/update";

export function ChangeAssigneeModal({data, onClose, onChange}) {
    const community = useCommunity();
    const toasts = useToasts();

    const [value, setValue] = React.useState(data.assignee || "");
    const [state, setState] = useState("");

    function handleOnChange(accessor, new_value, old_value, field) {
        setValue(new_value);
    }

    function handleClose() {
        if (onClose) {
            onClose();
        }
    }

    function onSubmit() {
        setState("loading");
        api_updatePosition(community,{
            id: data.id,
            assignee: value
        })
            .then((res) => {
                setState("");
                toasts.addToast({text: "Position updated", intent: "success"});
                if (onChange) {
                    onChange(res.data);
                }
                if(onClose) {
                    onClose();
                }
            })

    }

    const f = [
        {
            label: "Assignee",
            accessor: "assignee",
            type: 'assignee',
            corner_hint: !!value&&<TextAction text="Unassign" onClick={()=>{
                handleOnChange("assignee", "");
            }} />,
            value: value,
            input_props: {
                community_uid: community.id,
            },
            col_span: 2
        }
    ];

    return <SimplePortal>
        <Modal center size="small">
            <div className="py-2 px-4 relative">
                <MetaHeader can_close title="Change Assignee" onClose={handleClose}/>
                <div className="pt-2 space-y-4">
                    <FormFieldsRenderer onChange={handleOnChange} data={{...data,assignee: value}} fields={f}/>
                </div>
                <div className="flex pt-3 pb-1">
                    <div className="flex-grow"></div>
                    <div>
                        <Button disabled={data.assignee===value} intent={`primary`} loading={state === "loading"} onClick={() => {
                            onSubmit();
                        }} text={`Update Assignee`}/>
                    </div>
                </div>
            </div>
        </Modal>
    </SimplePortal>

    return <div>

    </div>
}