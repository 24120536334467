import {authFetch} from "../network";

export async function api_createPosition(payload) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        authFetch("/roles/create", res, res, "POST", {payload});
    })

}