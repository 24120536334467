import React from "react";
import M3_A_Text from "../../../m3/atoms/text";
import {SubscribeToCalendar} from "./utilities";
import {useCommunity} from "../../../config/community";
import EditModal from "../../../m3/_legacy_components/edit-modal";
import {ModalBody, ModalFooter, ModalHeader} from "../../../m3/_legacy_components/preview-overlay";
export function CalendarSubscribePopover({
                                             handleClose
                                         }) {
    const community = useCommunity();

    let footer_right = null;

    return <EditModal open onClose={handleClose} size="medium" custom>
        <ModalHeader title={"Subscribe to Calendar"} onClose={handleClose}/>
        <ModalBody>
            <div className="grid gap-4 pb-4">
            <div>
                <M3_A_Text size="sm">
                    When you subscribe to the calendar, events will automatically appear in your personal calendar. When events are added, updated, or removed, your calendar will automatically update.
                </M3_A_Text>
            </div>
                <div>
            <SubscribeToCalendar community_uid={community.uid} member_id={community.member_id}
                                 user_uid={community.member.user_uid}/>
                </div>
            </div>
        </ModalBody>
        {footer_right && <ModalFooter right={footer_right}/>}
    </EditModal>
}