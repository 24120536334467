import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {CommunitySignIn} from "./routes/unaverse/community/sign-in";
import HomePage from "./routes/community/home";
import {FlowPage} from "./routes/community/flow";
import {OnboardingPage} from "./routes/community/onboarding";
import WritePage from "./routes/community/write";
import {CalendarPage} from "./routes/community/calendar";
import EventPage from "./routes/community/event";
import {DrivePage} from "./routes/community/drive";
import MessagesPage from "./routes/community/messages";
import {MessagePageWrapper} from "./routes/community/message";
import {PolicyPageWrapper} from "./routes/community/policy/viewer";
import MemberPage from "./routes/community/member";
import {MemberIDRedirect, RedirectPage} from "./routes/community/member/id-redirect";
import CommunityContactsPage from "./routes/community/contacts";
import {CommunityTestingPage} from "./m3/_legacy_components/app-frame/testing";
import GroupPage from "./routes/community/group";
import GroupsPage from "./routes/community/groups";
import RolePage from "./routes/community/position";
import LeadersPage from "./routes/community/leaders";
import {CommunityToolsPage} from "./routes/manage/community-tools";
import NotificationsPage from "./routes/community/notifications";
import {CommunityMenuPage} from "./routes/community/more";
import CommunitySearchPage from "./routes/community/search";
import SignOutPage from "./routes/auth/sign-out";
import {CommunityWrapper} from "./m3/_legacy_components/app-frame/community-frame";

export function CommunityRouter() {
    return <CommunityWrapper>
        <Routes>
        <Route path="sign-in" element={<CommunitySignIn/>}/>

        <Route path="home" element={<HomePage/>}/>

        <Route path="flow" element={<FlowPage/>}/>

        <Route path="onboarding" element={<OnboardingPage/>}/>

        <Route path="write" element={<WritePage/>}/>

        <Route path="calendar" element={<CalendarPage/>}/>

        <Route path="event/:id" element={<EventPage/>}/>

        <Route path="drive/*" element={<DrivePage/>}/>

        <Route path="posts" element={<MessagesPage/>}/>
        <Route path="post/:id" element={<MessagePageWrapper/>}/>

        <Route path="policy/:id" element={<PolicyPageWrapper/>}/>

        <Route path="member/:handle" element={<MemberPage/>}/>

        <Route path="member-id/:id" element={<MemberIDRedirect/>}/>

        <Route path="contacts" element={<CommunityContactsPage/>}/>

        <Route path="testing" element={<CommunityTestingPage/>}/>

        <Route path="space/:id/*" element={<GroupPage/>}/>
        <Route path="spaces" element={<GroupsPage/>}/>

        <Route path="position/:id" element={<RolePage/>}/>
        <Route path="leaders" element={<LeadersPage/>}/>

        <Route path="tools" element={<CommunityToolsPage/>}/>

        <Route path="notifications" element={<NotificationsPage/>}/>

        <Route path="you" element={<CommunityMenuPage/>}/>

        <Route path="search" element={<CommunitySearchPage/>}/>

        <Route path="sign-out" element={<SignOutPage/>}/>

        <Route path="my-profile" element={<RedirectPage/>}/>

        <Route
            path="*"
            element={<Navigate to="home" replace/>}
        />
    </Routes>
    </CommunityWrapper>
}