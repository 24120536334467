import {m3_icon_map} from "../../../m3/icons/icon-map";
import M3_A_ActionMenu from "../../../m3/atoms/action-menu";
import M3_A_IconButton from "../../../m3/atoms/icon-button";
import React, {useState} from "react";
import M3_C_SelectPanel from "../../../m3/components/select-panel";
import M3_A_ActionList from "../../../m3/atoms/action-list";
import M3_A_AnchoredOverlay from "../../../m3/atoms/anchored-overlay";
import M3_A_FormControlGroup from "../../../m3/atoms/form-control-group";
import M3_A_FormControl from "../../../m3/atoms/form-control";
import M3_A_Select from "../../../m3/atoms/select";
import M3_A_Toggle from "../../../m3/atoms/toggle";
import M3_A_Button from "../../../m3/atoms/button";
import M3_A_Input from "../../../m3/atoms/input";
import M3_C_PromptDialog from "../../../m3/components/prompt-dialog";
import {api_createListView, api_deleteListView, api_updateAttributeOverride, api_updateListView} from "./api";
import M3_A_SearchInput from "../../../m3/atoms/search-input";
import M3_A_Link from "../../../m3/atoms/link";
import M3_A_Text from "../../../m3/atoms/text";
import M3_A_BlankSlate from "../../../m3/atoms/blank-slate";
import M3_A_Popover from "../../../m3/atoms/popover";

function ViewSort({
                      config
                  }) {
    const active_sort = config.state.active_view_data?.config?.sort?.[0];

    const sort_options = config?.sort?.options || [];

    return <M3_A_AnchoredOverlay size="lg" in_portal hide_on_click={false} placement="bottom-start"
                                 renderAnchor={() => {
                                     return <div>
                                         <M3_A_IconButton
                                             icon={m3_icon_map.outlines[active_sort.dir === "asc" ? "sort_ascending" : "sort_descending"]}
                                             text={<><span
                                                 className="text-gray-500">Sorted by</span>&nbsp;
                                                 <span
                                                     className="text-gray-800">{active_sort.label}</span></>}/>
                                     </div>
                                 }}>
        <div className="px-3 py-2">
            <M3_A_FormControlGroup>
                <M3_A_FormControl>
                    <M3_A_Select onChange={e => {
                        config?.fns?.onUpdateSort({
                            field: e?.target?.value,
                            dir: active_sort.dir,
                            label: e?.target?.selectedOptions[0]?.label
                        });
                    }} value={active_sort.field} options={sort_options}/>
                </M3_A_FormControl>
                <M3_A_FormControl>
                    <M3_A_Select onChange={e => {
                        config?.fns?.onUpdateSort({
                            field: active_sort.field,
                            dir: e?.target?.value,
                            label: active_sort.label
                        });
                    }} value={active_sort.dir} options={[
                        {
                            value: "asc",
                            label: "Ascending"
                        },
                        {
                            value: "desc",
                            label: "Descending"
                        }
                    ]}/>
                </M3_A_FormControl>
            </M3_A_FormControlGroup>
        </div>
    </M3_A_AnchoredOverlay>;
}

export function AdvancedViewBar({
                                    config
                                }) {

    if (!config || !config.state.active_view_data || !config.state.active_view_data?.config?.sort?.[0]) {
        return <div>
            something went wrong
        </div>
    }

    const has_undo = config.fns.hasUndo();
    const has_redo = config.fns.hasRedo();

    return <div className="h-12 bg-white border-b border-gray-300 flex px-3">
        <div className="flex-grow flex items-center gap-2">
            <ViewSort config={config}/>
        </div>
        <div className="flex-none flex items-center gap-2">
            <M3_A_IconButton compact adjust_icon_size={-2} square disabled={!has_undo}
                             onClick={() => config.fns.undo()} icon={m3_icon_map.solid["undo"]}/>
            <M3_A_IconButton compact adjust_icon_size={-2} square disabled={!has_redo}
                             onClick={() => config.fns.redo()} icon={m3_icon_map.solid["redo"]}/>
        </div>
    </div>
}

function getViewsToDisplay(config, mobile) {
    let a = Object.values(config.data.list.views).map((view, index) => {
        return {
            id: view.id,
            label: view.name,
            active: config.state.active_view_id === view.id,
            trailing_text: view.is_default ? "Default" : "",
            leading_indicator: {
                type: "icon",
                icon: m3_icon_map.outlines[view.layout]
            },
            trailing_actions: !config?.features?.can_edit_views || mobile ? null :
                [<M3_A_IconButton intent="secondary" square adjust_icon_size={-1} size={"sm"} sx={{
                    // padding: 0,
                    // border: "none"
                }} onClick={() => {
                    config?.fns?.onEditView(view.id, view)
                }} icon={m3_icon_map.outlines.edit}/>],
            onClick: () => {
                config?.fns?.onChangeView(view.id, view)
            }
        }
    });

    if (config?.features?.can_add_views && !mobile) {
        a.push({
            type: "divider"
        })

        a.push({
            id: "add-view",
            label: "Create new view",
            leading_indicator: {
                type: "icon",
                icon: m3_icon_map.outlines.add
            },
            onClick: config?.fns?.onCreateView
        })
    }

    return a;
}

export const UpdateCircle = ({border_color = "#ffffff", color = "#3b82f6", position = "-top-0.5 -right-0.5"}) => {
    return <div className={`absolute ${position} h-3 w-3 rounded-full border-2`} style={{
        borderColor: border_color,
        backgroundColor: color
    }}/>
}

export function ViewSelectorButton({
                                       mobile,
                                       config
                                   }) {

    const display_views = getViewsToDisplay(config, mobile);
    const overlay = <M3_A_AnchoredOverlay size="lg" in_portal hide_on_click={true} placement="bottom-start"
                                          renderAnchor={() => {
                                              return <div className="relative">
                                                  <M3_A_IconButton dropdown
                                                                   icon={m3_icon_map.outlines[config.state.active_view_data.layout]}
                                                                   text={config.state.active_view_data.name}/>
                                                  {config?.state?.unsaved_changes && <UpdateCircle/>}
                                              </div>
                                          }}>
        <div className="px-1 py-2 flex flex-col gap-2">
            {config?.state?.unsaved_changes && <>
                <div className="flex gap-2 px-2">
                    <M3_A_Button text="Save changes" intent="primary" onClick={() => config?.fns?.saveViewChanges()}/>
                    <M3_A_Button text="Discard changes" intent="secondary"
                                 onClick={() => config?.fns?.discardViewChanges()}/>
                </div>
                {!mobile && <div className="px-2">
                    <M3_A_Text color="text-gray-700" size="sm">
                        Or, <M3_A_Link variant="action" onClick={() => {
                        config?.fns?.saveAsNewView()
                    }}>save as a new view</M3_A_Link>.
                    </M3_A_Text>
                </div>}
            </>}
            <M3_A_ActionList size="sm" items={display_views}/>
        </div>
    </M3_A_AnchoredOverlay>
    return <div>
        {overlay}
    </div>;
}

export function AttributesCustomizeButton({
                                              config,
                                              cols,
                                              handleOnDragEnd,
                                              addColumn,
                                              handleEdit,
                                              removeColumn
                                          }) {
    const [panel, setPanel] = useState('overview');
    const filtered_attributes = config.data.attributes.filter(attribute => !cols.find(col => col.accessorKey === attribute?.col_data?.accessorKey));

    // now render remaining with ability to add to cols
    let add_attributes_items = [];

    filtered_attributes.forEach((attribute, index) => {
        add_attributes_items.push({
            id: attribute.value,
            leading_indicator: attribute?.leading_indicator,
            key: attribute.value,
            label: attribute.label,
            onClick: () => {
                addColumn(attribute.col_data);
            }
        });
    });

    let manage_attributes_items = [];

    cols.forEach((col, index) => {
        // only add if not locked
        if (!col?.meta?.locked) {

            manage_attributes_items.push({
                leading_indicator: {
                    type: "icon",
                    icon: m3_icon_map.outlines[col?.icon]
                },
                can_edit: true,
                can_delete: true,
                key: col.accessorKey,
                label: col.label,
                id: col.accessorKey
            });
        }
    });

    const panels = [
        {
            id: "overview",
            header: {
                title: "View settings"
            },
            content: [
                {
                    id: "toggle",
                    children: <M3_A_Toggle on={config.state.show_advanced} onChange={config?.fns?.toggleAdvanced}
                                           label="Show sort and filter controls"/>
                },
                {
                    id: "attributes",
                    no_padding: true,
                    children: <M3_A_ActionList can_drag={true} handleDelete={removeColumn} size="sm"
                                               handleEdit={handleEdit}
                                               handleOnDragEnd={handleOnDragEnd}
                                               items={manage_attributes_items}/>
                },
                {
                    id: "add-attributes",
                    no_padding: true,
                    children: <M3_A_ActionList size="sm" items={[
                        {
                            id: "add-attribute",
                            label: "Add Attribute",
                            opens_submenu: true,
                            onClick: () => {
                                setPanel('add-attributes');
                            },
                            leading_indicator: {
                                type: "icon",
                                icon: m3_icon_map.outlines.add
                            }
                        }
                    ]}/>
                }
            ]
        },
        {
            id: "add-attributes",
            header: {
                title: "Add Attribute",
                onBack: () => {
                    setPanel('overview');
                }
            },
            content: [
                {
                    id: "add-attributes-overview",
                    no_padding: true,
                    children: <M3_A_ActionList can_drag={false} size="sm" items={add_attributes_items}/>
                }
            ]
        }
    ];

    return <M3_C_SelectPanel onClose={() => {
        setPanel('overview');
    }} active_panel={panel} overlay={{
        size: "lg",
        placement: "bottom-end"
    }} panels={panels} onPanelChange={() => {
    }} renderAnchor={() => {
        return <div>
            <M3_A_IconButton icon={m3_icon_map.outlines.cog} text="Customize"/>
        </div>
    }}/>;
}

export function ContactsImportExportButton({
                                               onImport,
                                               onExport
                                           }) {

    const list = {
        items: [
            {
                id: "import",
                leading_indicator: {
                    type: "icon",
                    icon: m3_icon_map.outlines.import,
                },
                label: "Import from CSV",
                onClick: () => {
                    onImport();
                }
            },
            {
                id: "export",
                leading_indicator: {
                    type: "icon",
                    icon: m3_icon_map.outlines.export,
                },
                label: "Export and download",
                onClick: () => {
                    onExport();
                }
            }
        ]
    };

    return <M3_A_ActionMenu renderAnchor={() => {
        return <div><M3_A_IconButton dropdown icon={m3_icon_map.outlines.import} text="Import / Export"/></div>
    }} overlay={{
        size: "base",
        placement: "bottom-end"
    }} lists={[list]}/>;
}

export const ContactsSelectedActionsBar = ({
                                               selected,
                                               onClearSelected,
                                               actions_messages,
                                               onAddToSpace,
    onBulkEdit
                                           }) => {
    const list_data = {
        items: actions_messages.map((action, index) => {
            return {
                label: action.label,
                onClick: action.onClick
            }
        })
    }

    /*
       <M3_A_IconButton onClick={() => {
                    onBulkEdit();
                }} icon={m3_icon_map.outlines["bulk-edit"]} text="Bulk Edit"/>
     */

    return <div className="absolute bottom-0 left-0 z-50 right-0 pb-4 flex">
        <div className="mx-auto bg-white rounded-lg border border-gray-200 py-1.5 px-3 flex gap-4" style={{
            boxShadow: "0 3px 10px rgba(0,0,0,0.2)"
        }}>
            <div className="flex items-center gap-1.5 text-sm">
                <div
                    className="bg-blue-500 text-white font-medium px-1 min-w-[1.5rem] text-center leading-6 rounded-lg">{selected.length}</div>
                <div className="text-gray-700">Selected</div>
            </div>
            <div className="flex gap-2">
                <M3_A_ActionMenu renderAnchor={() => {
                    return <div><M3_A_IconButton icon={m3_icon_map.outlines.email} text="Send Email" dropdown/></div>
                }} lists={[list_data]}/>

                <M3_A_IconButton onClick={() => {
                    onAddToSpace();
                }} icon={m3_icon_map.outlines["add-to-space"]} text="Add to Space"/>

            </div>
            <div className="flex items-center">
                <M3_A_IconButton compact variant={`minimal`} adjust_icon_size={0} square
                                 onClick={() => onClearSelected()}
                                 icon={m3_icon_map.solid["close"]}/>
            </div>
        </div>
    </div>
};

function SearchBar({config}) {
    const [query, setQuery] = useState(config?.state?.query || "");
    const sx = {
        minWidth: "5rem",
        width: "100%",
        maxWidth: "20rem"
    };
    return <div className="flex-grow max-w-xs">
        <M3_A_SearchInput value={query} sx={sx} onEnter={(e) => {
            if (e?.target?.value) {
                config.fns.setQuery(e?.target?.value)
            }
        }} adjust_icon_size={-2} size="base" placeholder="Search" onChange={(e) => {
            setQuery(e?.target?.value);
        }} onClear={() => {
            setQuery("");
            config.fns.setQuery("");
        }} onBlur={() => {
            if (!query && config.state.query) {
                config.fns.setQuery("");
            }
        }} onFocus={() => {

        }}/>
    </div>
}

export const ContactsPrimaryToolbar = ({
                                           config,
                                           children,
                                           handleEdit,
                                           handleReorderCols,
                                           col_offset = 0,
                                           cols,
                                           removeColumn,
                                           addColumn
                                       }) => {
    return <>
        <div className="h-10 relative gap-4 z-20 bg-white flex px-4 border-b border-gray-300">
            <div className="flex gap-2 flex-grow">
                <ViewSelectorButton config={config}/>
                <SearchBar config={config}/>
            </div>
            <div className="flex gap-2">
                {config?.features?.can_customize &&
                    <AttributesCustomizeButton handleOnDragEnd={(result) => handleReorderCols(result, col_offset)}
                                               config={config} removeColumn={removeColumn}
                                               handleEdit={config?.fns?.handleEditAttribute}
                                               cols={cols} addColumn={addColumn}/>}
                {children}
            </div>
        </div>

        {config?.state?.show_advanced && <AdvancedViewBar config={config}/>}
    </>
}

export function ContactsEditAttributeModal({data, view_data, list_id, ctx, view_id, id, onClose, onSave}) {
    const [label, setLabel] = useState(data?.label || "");
    const [loading, setLoading] = useState(false);

    function handleUpdateAttribute() {
        const new_config = view_data?.config || {};

        if (!new_config?.overrides) {
            new_config.overrides = {};
        }

        if (label) {
            new_config.overrides[id] = {
                label
            };
        } else {
            if (new_config.overrides[id]) {
                delete new_config.overrides[id];
            }
        }

        setLoading(true);

        const new_view_data = {
            ...view_data,
            config: new_config
        };

        api_updateAttributeOverride(ctx, {
            list_id,
            view_id,
            config: JSON.stringify(new_config)
        })
            .then((_resp) => {
                if (_resp) {
                    onSave(view_id, new_view_data, id, new_config);
                } else {
                    onClose();
                }
            })
    }

    function onChange(e) {
        setLabel(e?.target?.value);
    }

    return <M3_C_PromptDialog title={"Edit Attribute"} confirm_loading={loading}
                              onConfirm={() => {
                                  handleUpdateAttribute();
                              }} onClose={() => {
        onClose();
    }}>
        <M3_A_FormControl label="Custom Label">
            <M3_A_Input auto_focus name={"name"} value={label} type="text" onChange={onChange}/>
        </M3_A_FormControl>
    </M3_C_PromptDialog>
}

export const ContactsEmptyState = ({query = ""}) => {
    let title = `No results for "${query}"`;
    let description = `You may want to try different keywords or check for a typo.`;
    return <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-start pt-24">
        <M3_A_BlankSlate icon={m3_icon_map.outlines.search} title={title} description={description}/>
    </div>
};

export function ContactsEditViewModal({
                                          id,
                                          ctx,
                                          mode = "create",
                                          data,
                                          list_id,
                                          can_delete_view,
                                          context_id,
                                          onClose,
                                          onSuccess,
                                          onDelete,
                                      }) {

    const [name, setName] = useState(data?.name || "");
    const [is_default, setIsDefault] = useState(data?.is_default || false);
    const [layout, setLayout] = useState(data?.layout || "grid");
    const [loading, setLoading] = useState(false);

    function onChange(e) {
        setName(e?.target?.value);
    }

    function handleCreateView() {
        if (!name) {
            return;
        }

        setLoading(true);
        const new_view = {
            name,
            layout,
            is_default: false,
            config: JSON.stringify({
                space: context_id,
                filters: data?.filters || [],
                sort: data?.sort || [
                    {
                        field: "about.last_name",
                        dir: "asc",
                        label: "Last Name"
                    }
                ],
                attributes: data?.attributes || [
                    'location',
                    'occupation',
                    'member-type'
                ]
            })
        }

        api_createListView(ctx, {
            list_id,
            ...new_view
        })
            .then((view_id) => {
                if (view_id) {
                    onSuccess(view_id, {
                        ...new_view,
                        config: JSON.parse(new_view.config),
                        id: view_id
                    });
                } else {
                    onClose();
                }
            })
    }

    function handleDeleteView() {
        if (!id) {
            return;
        }
        api_deleteListView(ctx, {
            list_id,
            view_id: id,
            name,
            is_default
        })
            .then((resp) => {
                console.log("view_id delete")
                if (resp?.view_id && resp.default_view_id) {
                    onDelete(resp?.view_id, resp?.default_view_id);
                } else {
                    onClose();
                }
            })
    }

    function handleUpdateView() {
        if (!id) {
            return;
        }

        const new_view = {
            ...data,
            name,
            is_default,
            layout: data?.layout,
            config: JSON.stringify(data?.config || {})
        };

        api_updateListView(ctx, {
            list_id,
            view_id: id,
            name,
            is_default,
            layout: data?.layout,
            config: JSON.stringify(data?.config || {})
        })
            .then((view_id) => {
                if (view_id) {
                    onSuccess(view_id, {
                        ...new_view,
                        config: JSON.parse(new_view.config),
                        id: view_id
                    });
                } else {
                    onClose();
                }
            })
    }

    return <M3_C_PromptDialog title={mode === 'create' ? "Create view" : "Edit View"} confirm_loading={loading}
                              confirm_disabled={!!name} onConfirm={() => {
        if (mode === "create") {
            handleCreateView();
        } else if (mode === "update") {
            handleUpdateView();
        }
    }} onClose={() => {
        onClose();
    }}>
        <M3_A_FormControl label="Name">
            <M3_A_Input auto_focus name={"name"} value={name} type="text" onChange={onChange}/>
        </M3_A_FormControl>
        {!id && <M3_A_FormControl label="Layout">
            <M3_A_Select onChange={e => {
                setLayout(e?.target?.value);
            }} value={layout} options={[
                {
                    value: "grid",
                    label: "Grid"
                },
                {
                    value: "table",
                    label: "Table"
                }
            ]}/>
        </M3_A_FormControl>}
        {id && <M3_A_FormControl>
            <M3_A_Toggle on={is_default} onChange={e => {
                setIsDefault(!is_default);
            }} label="Make default view"/>
        </M3_A_FormControl>}
        {can_delete_view && id && <M3_A_FormControl>
            <M3_A_Button text="Delete view" onClick={() => {
                // do a window confirm
                window.confirm("Are you sure you want to delete this view?") && handleDeleteView();
            }}/>
        </M3_A_FormControl>}
    </M3_C_PromptDialog>
}