import React, {useContext, useEffect, useRef, useState} from 'react';
import {getAuth, signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword} from "firebase/auth";
import {app} from "./firebase-setup";
import {validateEmail} from "../../common/utilities/lang-region";

const auth = getAuth(app);

const AuthContext = React.createContext(null);

function removePreloader() {
    const el = document.getElementById("preloader");
    if(el) {
        el.remove();
    }
}

function setToken() {
    if(auth&&auth.currentUser) {
        auth.currentUser.getIdToken(true).then(function (idToken) {
            document.cookie = '__session=' + idToken + ';max-age=3600';
        });
    }
}

const AuthContextProvider = ({children}) => {
    const [user, setUser] = useState(() => {
        const user = auth.currentUser;

        return user;
    });
    const [ready,setReady] = useState(false);

    useEffect(() => {
        auth.onAuthStateChanged(firebaseUser => {
            removePreloader();
            setToken();
            setUser(firebaseUser);
            setReady(true);
        });
    }, [])

    return <AuthContext.Provider value={{user,__ready: ready, is_logged_in: !!user}}>{children}</AuthContext.Provider>
}

const useAuth = () => {
    const user = useContext(AuthContext);

    return user;
};

const findAccountByUnatyID = async (unatyid) => {

    return true;
};

const emailSignIn = async (email, password) => {
    return await new Promise((resolve, reject) => {
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                resolve(userCredential);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(errorCode,errorMessage)
                reject(error);
            });
    })
}

const popupSignIn = (provider) => {
    if(!!provider) {
        console.log('provider exists')
    } else {
        console.log('provider does not exist')
    }

    return signInWithPopup(auth,provider);
};

const signOut = () => {
    return auth.signOut();
};

const AuthProviders = {
    GoogleAuthProvider
};

export const authApp = auth;

export {useAuth, findAccountByUnatyID, emailSignIn, popupSignIn, signOut, AuthContextProvider, AuthProviders}