import React, {useState} from "react";
import {IconAction} from "../../../auth/sign-in";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import {ModalHeader} from "../../../../m3/_legacy_components/preview-overlay";
import {useUnaverse} from "../../../../config/unaverse";
import {getPhoneNumberData} from "../../../../m3/utilities/phone";
import {UserEmailManager} from "../../../../m3/_legacy_components/user-email-manager";
import Field from "../../../../m3/_legacy_components/form/field";
import {SimpleHandleSelector} from "../../../../m3/_legacy_components/simple-handle-selector";
import Button from "../../../../m3/_legacy_components/button";
import dayjs from "dayjs";
import {authFetch} from "../../../../../api/network";
import {useCommunity} from "../../../../config/community";
import {changeMemberHandle} from "../../manage-member";
import {useToasts} from "../../../../config/toasts";
import {ChangeHandleModal} from "../../manage-member/change-handle";

export function PrefDivider() {
    return <div className="border-b my-2 border-gray-200">

    </div>
}

export function PrefSection({title, onClick, subtitle}) {
    const interactive = !!onClick;
    return <div onClick={() => {
        if (interactive) {
            onClick();
        }
    }} className={`flex px-4 rounded-lg ${interactive ? "hover:bg-gray-100 cursor-pointer" : ""}  py-2`}>
        <div className="flex-grow">
            <div className="text-base text-gray-800 font-medium">{title}</div>
            <div className="text-sm -mt-px text-gray-600">{subtitle}</div>
        </div>
        <div className="flex items-center">
            {interactive && <IconAction onClick={() => {

            }}>
                <ChevronRightIcon/>
            </IconAction>}
        </div>
    </div>
}

function ChangePasswordSection() {
    return <div>
        todo
    </div>
}

function UsernameSection({init}) {
    const [v, setV] = useState(init);
    const unaverse = useUnaverse();
    const community = useCommunity();
    const [state, setState] = useState("");

    function handleSave() {
        setState("saving")

        const payload = {
            community_uid: community.uid,
            id: unaverse.uid,
            new_handle: v
        };

        const res = () => {
            setState("");
            community.updateMemberProfile();
        }

        authFetch('/members/change-handle', res, res, "POST", {payload});
    }

    return <div className="mt-2">
        <SimpleHandleSelector check_id={"community"} community_uid={community.uid} type={'member'}
                              current_handle={community.member.handle}
                              onChangeHandle={(h) => {
                                  console.log("ON CHANGE HANDLE",h)
                                  setV(h);
                                  setState("unsaved");
                              }}/>
        {state && v !== community.member.handle && <div className="mt-4 flex">
            <div className="flex-grow"/>
            <div className="">
                <Button disabled={!v} loading={state === "saving"} text="Save" intent="success"
                        onClick={() => handleSave()}/>
            </div>
        </div>}
    </div>
}

function EmailSection() {
    return <div className="mt-2">
        <UserEmailManager/>
    </div>
}

function PhoneNumberSection({id = "", init = ""}) {
    const [v, setV] = useState(init);
    const unaverse = useUnaverse();
    const [state, setState] = useState("");

    function handleSave() {
        setState("saving")

        const payload = {
            id: unaverse.uid,
            contact: {
                phone: v
            },
            _updated_fields: ["contact.phone"]
        };

        const res = () => {
            setState("");
            unaverse.updateUserProfile();
        }

        authFetch('/users/update-own-profile', res, res, "POST", {payload});
    }

    return <div className="mt-2">
        <Field onChange={(a, b) => {
            setV(b);
            setState("unsaved")
        }} type="phone" label="Phone" value={v} id="phone"/>
        {state && <div className="mt-4 flex">
            <div className="flex-grow"/>
            <div className="">
                <Button loading={state === "saving"} text="Save" intent="success" onClick={() => handleSave()}/>
            </div>
        </div>}
    </div>
}

function buildSections(member, setPage, setModal) {
    const pn = getPhoneNumberData(member.contact.phone)
    return [
        {
            title: "Change Handle",
            subtitle: `@${member.handle}`,
            onClick: () => setModal("change-handle")
        },
        {
            title: "Phone",
            subtitle: String(pn.display),
            onClick: () => setPage("phone")
        },
        {
            title: "Email",
            subtitle: member.account_email,
            onClick: () => setPage("email")
        },
        /*
        {
            title: "Verified",
            subtitle: "No.",
            onClick: () => {}
        },

         */
        {
            divider: true
        },
        {
            title: "Account creation",
            subtitle: dayjs(member.created_at).format('MMM D, YYYY, h:mm:ss A')
        },
        /*
        {
            title: "Change your password",
            onClick: () => setPage("password")
        },

         */
        {
            divider: true
        },
        {
            title: "Deactivate your account",
            subtitle: "",
            onClick: () => setPage("deactivate")
        }
    ]
}

export function MemberPreferencesYourAccount() {
    const [page, setPage] = useState("");
    const [modal, setModal] = useState("");
    const [loading, setLoading] = useState("");
    const toasts = useToasts();
    const community = useCommunity();

    const sections = buildSections(community.member, setPage, setModal)

    function changeHandle(h) {
        setLoading('handle');

        changeMemberHandle(community, community.member_id, h)
            .then((resp)=>{
                setLoading('');
                setModal("");
community.updateMemberProfile();
                toasts.addToast({text: `Member handle changed`, intent: 'success'});
            })
    }

    function renderPage() {
        switch (page) {
            case "password" :
                return <div>
                    <ModalHeader sizing="h-8 px-0" divider={false} goBack={() => setPage("")} title="Password"/>
                    <ChangePasswordSection/>
                </div>
            case "username" :
                return <div>
                    <ModalHeader sizing="h-8 px-0" divider={false} goBack={() => setPage("")} title="Handle"/>
                    <UsernameSection/>
                </div>
            case "phone" :
                return <div>
                    <ModalHeader sizing="h-8 px-0" divider={false} goBack={() => setPage("")} title="Phone"/>
                    <PhoneNumberSection init={community.member.contact.phone}/>
                </div>
            case "email" :
                return <div>
                    <ModalHeader sizing="h-8 px-0" divider={false} goBack={() => setPage("")} title="Email"/>
                    <EmailSection/>
                </div>
            case "deactivate" :
                return <div>
                    <ModalHeader sizing="h-8 px-0" divider={false} goBack={() => setPage("")}
                                 title="Deactivate your account"/>
                    <div className="mt-2">
                        If you would like to deactivate and delete your account, please contact your commnity admins.
                    </div>
                </div>
            default:
                return <div>
                    {sections.map((sec, ind) => {
                        if (sec.divider) {
                            return <PrefDivider key={ind} {...sec} />
                        }
                        return <PrefSection key={ind} {...sec} />
                    })}
                </div>
        }
    }

    return <div className="">
        {renderPage()}

        {modal === 'change-handle' &&
            <ChangeHandleModal loading={loading === 'handle'} community_uid={community.id} data={{handle: community.member.handle}}
                               onConfirm={(h) => {
                                   changeHandle(h)
                               }}
                               onClose={() => setModal("")} onCancel={() => {
                setModal("");
            }} type='member'/>}
    </div>
}