function utils_strings_isEmail(str) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(str).toLowerCase());
}

function utils_strings_replaceAt(str, index, replacement) {
    return str.substr(0, index) + replacement + str.substr(index + replacement.length);
}

const handleRegex = /^[a-zA-Z0-9-_]+$/;

function utils_strings_isHandle(input,len=true) {
    if (len&&(input.length < 3 || input.length > 28)) {
        return false;
    }
    let regex = new RegExp(handleRegex);
    return regex.test(input);
}

export {
    utils_strings_isHandle,
    utils_strings_replaceAt,
    utils_strings_isEmail
}