import React, {useEffect, useState} from "react";
import {AsyncSuggestions} from "../simple-autocomplete/async";
import {taxonomySearch} from "../app-frame/taxonomy-api";
import {externalAuthFetch} from "../../../../api/network";

async function handleSearch(query) {
    return await new Promise((resolve, reject) => {
        taxonomySearch(query, "locations")
            .then((resp) => {

                if (resp.results) {
                    resolve(resp.results.map(result => {
                        return {
                            value: result.title,
                            id: result.id.split('_')[1],
                            label: result.title
                        }
                    }))
                } else {
                    resolve([])
                }
            })
    });
}

function GAPILocationInput({onChange, value}) {
    const [top, setTop] = useState([]);

    const ic = "input-focus border rounded-md leading-10 text-base px-3 w-full";

    async function handleCitySearch(query) {
        return await new Promise((resolve, reject) => {
            const handleResult = (result) => {
                if (result && result.ok && result.data.cities) {
                    resolve(result.data.cities.map(result => {
                        return {
                            value: result.value,
                            id: result.value,
                            label: result.label,
                            note: result.note || ""
                        }
                    }))
                } else {
                    resolve([])
                }
            }
            externalAuthFetch("/places-autocomplete", handleResult, handleResult, "POST", {query})
        });
    }

    function handleBlur() {

    }

    return <AsyncSuggestions onBlur={handleBlur} show_no_results={false} show_menu_focus={!!top.length}
                             default_options={top} onClick={(a, b) => {
        onChange(a.label, a.value);
    }} handleSearch={handleCitySearch} input_classes={ic} value={value}/>
}

export function LocationField({
                                  value, autocomplete = "", onBlur, onChange = () => {
    }
                              }) {
    const ic = "input-focus border rounded-md leading-10 text-base px-3 w-full";
    const [top, setTop] = useState([]);

    useEffect(function () {
        handleSearch("")
            .then(resp => setTop(resp))
    }, []);

    if (autocomplete === "cities") {
        return <GAPILocationInput onChange={onChange} value={value}/>
    }

    return <AsyncSuggestions onBlur={onBlur} show_no_results={false} show_menu_focus={!!top.length}
                             default_options={top} onClick={(a, b) => {
        onChange(a.label, "");
    }} handleSearch={handleSearch} input_classes={ic} value={value}/>
}