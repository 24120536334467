import dayjs from "dayjs";
import {getPhoneNumberData} from "../../../../utilities/phone";
import {utils_strings_isEmail} from "../../../../../../common/utilities/strings";
import {data_countries_arr, data_countries_map} from "../../../../../../common/data/data/countries";
import {data_continents_arr} from "../../../../../../common/data/data/continents";
import {formatBirthday} from "../../../../../../common/utilities/birthday";

const examples = [
    "Sean Elliott McCall",
    "Sean E McCall",
    "Sean E. McCall",
    "John Smith",
    "Paul Steve Panakkal",
    "Blake Andrew Robertson",
    "Simon Baron-Cohen",
    "Dick Van Dyck",
    "Adriaen de Vries",
    "Sam de la Hoya",
    "Jamie del Bosque",
    "Dara Ó Briain",
    "Boyd van der Vuurst de Vries",
    "Alex Draconia van der Vuurst de Vries",
    "Fabio Alexander Di Nagamaka"
];
const last_name_prefixes = [
    "van der",
    "von der",
    "vom",
    "de la",
    "di",
    "de",
    "del",
    "van",
    "ó",
    "Ó",
    "von"
];

function isLastNamePrefix(str) {
    let test = str.toLowerCase();
    if(!last_name_prefixes.includes(test)&&!last_name_prefixes.includes(str)) {
        return '';
    }
    const index = last_name_prefixes.indexOf(test);
    return index;
}

function isUppercase(l) {
    return l === l.toUpperCase()
}

function checkLongLastName(str) {
    const test = str.toLowerCase();
    for(let i=0;i<last_name_prefixes.length;i++) {
        const prefix = last_name_prefixes[i];
        if(test.indexOf(prefix)!==-1) {
            return test.indexOf(prefix);
        }
    }
    return -1;
}

export function isMiddleInitial(str) {
    if(str.length===1&&isUppercase(str.charAt(0))) {
        return true;
    } else if(str.length===1&&isUppercase(str.charAt(0))&&str.charAt(1)==='.') {
        return true;
    }
    return false;
}

export function splitName(full_name) {
    let first_name = "", last_name = "", middle_name = "", t1, t2, t3, t4, t5;

    if(full_name.indexOf("|")!==-1) {
        const splitter = full_name.split("|");
        const splitter_len = splitter.length;
        if(splitter.length===2) {
            first_name = splitter[0];
            last_name = splitter[1];
        } else if(splitter.length===3) {
            first_name = splitter[0];
            middle_name = splitter[1];
            last_name = splitter[2];
        } else {
            first_name = splitter[0];
            middle_name = [...splitter].slice(1,(splitter_len-1)).join(" ");
            last_name = splitter[(splitter_len-1)];
        }

        let d2 = `${first_name.trim()}${middle_name?` | ${middle_name.trim()}`:''}${last_name?` | ${last_name.trim()}`:''}`

        return {
            full_name: full_name,
            divided: d2,
            first_name: first_name.trim(),
            last_name: last_name.trim(),
            middle_name: middle_name.trim()
        }
    }

    const split = full_name.trim().split(" ");
    const name_len = full_name.length;

    const len = split.length;

    if(len===1) {
        first_name = split[0];
    } else if(len===2) {
        first_name = split[0];
        last_name = split[1];
    } else if(len===3) {
        t4 = isLastNamePrefix(split[1]);
        if(t4) {
            first_name = split[0];
            last_name = `${split[1]} ${split[2]}`;
        } else if(isMiddleInitial(split[1])) {
            first_name = split[0];
            middle_name = split[1];
            last_name = split[2];
        } else {
            // default case
            first_name = split[0];
            middle_name = split[1];
            last_name = split[2];
        }
    } else if(len===4) {
        t1 = split[0];
        t2 = split[(len-1)];
        t3 = [...split].slice(1,len-1).join(" ");
        t4 = isLastNamePrefix(t3);

        if(t4) {
            first_name = full_name.slice(0,(t4+1));
            last_name = full_name.slice((t4+1),name_len);
        } else {
            if(split[1].length>4) {
                // likely second first name
                first_name = `${split[0]} ${split[1]}`;
            } else {
                first_name = split[0];
            }

            if(split[2].length>4) {
                last_name = `${split[3]}`;
                middle_name = `${split[2]}`;
            } else {
                last_name = `${split[2]} ${split[3]}`;
            }
        }
    } else {
        // we know there's at least one first and one last name
        t1 = split[0];
        t2 = split[(len-1)];
        // rest
        t3 = [...split].slice(1,len-1).join(" ");
        t4 = checkLongLastName(t3);
        // t4 === index of the splitter
        if(t4>-1) {
            t5 = `${t3.slice(0,t4)}`;
            first_name = `${t1}${t5?` ${t5}`:''}`;
            last_name = `${t3.slice((t4),t3.length)} ${t2}`;
        } else {
            // go to 2 first names, probably not less than 2 chars
            if(split[1].length>4&&len>4) {
                // likely second first name
                first_name = `${split[0]} ${split[1]}`;
                t5 = 2;
            } else {
                first_name = split[0];
                t5 = 1;
            }

            if(split[len-2].length>4&&len>4) {
                // likely second last name
                last_name = `${split[(len-2)]} ${split[(len-1)]}`;
                middle_name = [...split].slice((t5+1),len-2).join(" ");
            } else {
                last_name = split[(len-1)];
                middle_name = [...split].slice((t5+1),len-1).join(" ");
            }
        }
    }

    let divided = `${first_name.trim()}${middle_name?` | ${middle_name.trim()}`:''}${last_name?` | ${last_name.trim()}`:''}`

    return {
        full_name: full_name,
        divided,
        first_name: first_name.trim(),
        last_name: last_name.trim(),
        middle_name: middle_name.trim()
    }
}

export const sanitization_codes = {
    'invalid-email': {
        desc: "This is not a valid email; please check for any spelling for formatting mistakes like a space in the email"
    }
};

function getDeliminator(str) {
    const comma_count = (str.match(/,/g) || []).length;
    if(comma_count>0) {
        return ',';
    }
    const semicolon_count = (str.match(/;/g) || []).length;
    if(semicolon_count>0) {
        return ';';
    }
    return ' ';
}

export function sanitizationPipeline(raw, type, mapping_obj, value_only = false) {
    let temp = raw.trim(), t1, t2, t3, t4;

    if (!raw && type !== 'select') {
        return value_only ? '' : {
            value: '',
            valid: true,
            code: 'empty'
        }
    }

    switch (type) {
        case 'select':
            // t1 = ID of select
            t1 = mapping_obj.mapping[temp];
            if (t1) {
                // ok it's mapped
                t2 = mapping_obj.field_details.options.filter(a => a.id === t1)[0];
                if(!t2) {
                    return value_only ? '' : {value: '', valid: false, code: 'select-not-mapped'};
                }
                return value_only ? t2.id : {value: t2.label, valid: true};
            } else if (mapping_obj.field_details.default_selection) {
                // show default val
                t2 = mapping_obj.field_details.options.filter(a => a.id === mapping_obj.field_details.default_selection)[0];
                if(!t2) {
                    return value_only ? '' : {value: '', valid: false, code: 'select-not-mapped'};
                }
                return value_only ? t2.id : {value: t2.label, valid: true, default_value: true};
            }
            return value_only ? '' : {value: temp, valid: false, code: 'select-not-mapped'};
        case 'boolean':
            t1 = temp;
            t2 = !!t1;
            return value_only ? t2.toString() : {value: t2.toString(), valid: true}
        case 'boolean-label':
            // incoming value is either false or true or On or Off, find by label, match to true/false bool
            t1 = mapping_obj.field_details.options.filter(a => a.label === temp)[0];
            t2 = t1 ? t1.id : false;
            t3 = mapping_obj.field_details.options.filter(a => a.id === t2)[0].label;
            return value_only ? t2 : {value: t3, valid: true}
        case 'multi-email':
            t1 = temp.split(getDeliminator(temp));

            t2 = t1.filter(a => utils_strings_isEmail(a));

            return value_only ? t2.join(",") : {value: t2.join(", "), valid: true};
        case 'full-name':
            t1 = splitName(temp);
            return value_only ? t1 : {value: t1.divided, valid: true};
        case 'email':
            // check if is valid email
            if (utils_strings_isEmail(temp)) {
                return value_only ? temp : {value: temp, valid: true};
            } else {
                return value_only ? '' : {value: '', valid: false, code: 'invalid-email'};
            }
        case 'id':
            if (raw.length === 10 && raw.indexOf(" ") === -1) {
                return value_only ? temp : {value: temp, valid: true};
            } else {
                return value_only ? '' : {
                    value: '',
                    valid: false,
                    code: 'invalid'
                }
            }
        case 'phone':
            // check if is valid phone number
            t1 = getPhoneNumberData(raw);
            if (t1.valid) {
                return value_only ? temp : {value: t1.display, valid: true};
            } else {
                return value_only ? temp : {value: raw, valid: true, code: 'formatting'};
            }
        case 'country':
            temp = temp.toLowerCase();
            if (temp.length === 2) {
                if (data_countries_map[temp]) {
                    return value_only ? temp : {value: data_countries_map[temp], valid: true};
                } else {
                    return value_only ? '' : {value: temp, valid: false, code: 'invalid'};
                }
            }

            t1 = data_continents_arr.map(a => a.toLowerCase());

            if (t1.includes(temp)) {
                t2 = data_countries_arr;
                t3 = t2.findIndex(a => a[1].toLowerCase() === temp);
                t4 = t2[t3][0];
                return value_only ? t4 : {value: data_countries_map[t4], valid: true};
            }

            return value_only ? '' : {value: temp, valid: false, code: 'invalid'};
        case 'number':
            t1 = parseInt(temp);
            if (!isNaN(t1)) {
                return value_only ? t1.toString() : {value: t1.toString(), valid: true};
            }
            return value_only ? '' : {value: temp, valid: false, code: 'invalid'};
        case 'date':
            t1 = dayjs(temp, ['DD-MM-YYYY', 'MM-DD-YYYY', 'DD.MM.YYYY', 'DD-M-YYYY']);
            if (t1.isValid()) {
                return value_only ? t1.format('DD-MM-YYYY') : {value: t1.format('DD-MM-YYYY'), valid: true};
            }
            return value_only ? temp : {value: temp, valid: false, code: 'invalid'};
        case 'birthday':
            t1 = dayjs(temp, ['DD-MM-YYYY', 'MM-DD-YYYY', 'DD.MM.YYYY', 'DD-M-YYYY']);
            if (t1.isValid()) {
                return value_only ? {
                    date: t1.date(),
                    month: t1.month(),
                    year: t1.year()
                } : {
                    valid: true,
                    value: formatBirthday({
                        date: t1.date(),
                        month: t1.month(),
                        year: t1.year()
                    })
                }
            }
            return value_only ? '' : {value: temp, valid: false, code: 'invalid'};
        case 'string':
            return value_only ? temp : {value: temp, valid: true};
        default:
            return value_only ? '' : {value: temp, valid: true};
    }
}