export const data_data_integrity_map = {
    'unknown': {
        label: "Unknown",
        color: "yellow"
    },
    'incomplete': {
        label: "Incomplete",
        color: "yellow"
    },
    'outdated': {
        label: "Outdated",
        color: "gray"
    },
    'up-to-date': {
        label: "Basic",
        color: "blue"
    },
    'good': {
        label: "Intermediate",
        color: "blue"
    },
    'perfect': {
        label: "Perfect",
        color: "green"
    },
};

export const data_data_integrity_arr = Object.entries(data_data_integrity_map).map(([k, v]) => {
    return {
        value: k,
        label: v.label,

        _meta: {
            color: v.color
        }
    }
});