import React from "react"
import './styles.css';
import ContentPopup from "../content-popup";
import {ContentPopupMenu} from "../content-popup/components";
import {ArrowsUpDownIcon, ChevronDownIcon} from "@heroicons/react/20/solid";
import SimpleTooltip from "../tooltip";

export default function SortSelect({
    items = [],
    active,
                                       setSort = () => {}
                                   }) {

    const content = <ContentPopup>
        <ContentPopupMenu can_check items={[
            ...items.map((it, key) => {
                return {
                    label: it.label,
                    checked: active === it.value,
                    onClick: () => {
                        setSort(it.value)
                    }
                }
            })
        ]} />
    </ContentPopup>;

    const selected_label = items.find(it => it.value === active).label;

    return <div className="flex gap-0.5 items-center">
        <div className="text-gray-500 w-3 h-3">
            <ArrowsUpDownIcon />
        </div>
        <SimpleTooltip simple trigger="click" interactive hideOnClick placement="bottom-start" text={content}>
            <div className="text-gray-600 hover:opacity-70 transition-opacity cursor-pointer items-center inline-flex text-xs">
                <span>Sort:&nbsp;</span>
                <span className="font-semibold">{selected_label}</span>
                <span className="w-4 h-4 ml-px text-gray-500">
                <ChevronDownIcon />
            </span>
            </div>
        </SimpleTooltip>

    </div>
};