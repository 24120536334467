import React from "react";
import {member_profile_data_fields} from "../data/data";
import {LinkIcon} from "@heroicons/react/24/outline";
import {custom_field_types} from "../../../../../../common/data/data/custom-field-types";
export function lie_getKeyBlockId(mapping) {
    const entries = Object.entries(mapping);

    const member_id = entries.filter(([id, obj]) => obj.data_field === 'docid')[0];
    if (member_id) {
        return member_id[0];
    }
    const account_email = entries.filter(([id, obj]) => obj.data_field === 'account_email')[0];
    if (account_email) {
        return account_email[0];
    }
    /*
    names are not import keys
    const first_name = entries.filter(([id, obj]) => obj.data_field === 'about.first_name')[0];
    if (first_name) {
        return first_name[0];
    }
    const full_name = entries.filter(([id, obj]) => obj.data_field === 'full_name')[0];
    if (full_name) {
        return full_name[0];
    }

     */
    return null;
}

export function lie_buildDataFieldOptions(full_fields, mapping) {
    const mapped_entries = Object.entries({...mapping});

    const entries = Object.entries(full_fields).map(([id, data]) => {
        const block = mapped_entries.filter(a => a[1].data_field === id)[0];
        let obj = data;
        if (block) {
            obj.meta = <div className="w-4 h-full flex items-center ml-auto">
                <div className="w-4 justify-end flex text-gray-400 items-center">
                    <LinkIcon/>
                </div>
            </div>;
        }
        return [
            id, obj
        ]
    });
    return entries.map(([id, data]) => {
        return {
            id,
            label: data.label,
            meta: data.meta,
            icon: <div style={{width:'1.1rem',height:'1.1rem'}} className="rounded-md flex items-center justify-center text-white bg-purple-700">
                <div className="w-3.5 h-3.5">
                    {custom_field_types[data.type]}
                </div>
            </div>,
            categories: data.categories
        }
    });
}

function buildCfsRef() {
    let allowed_field_types = [];
    let field_types_map = {};

    const all = Object.entries(custom_field_types);

    for (let i = 0; i < all.length; i++) {
        const [id,data] = all[i];
        if(data.can_be_imported) {
            allowed_field_types.push(id);
        }
        field_types_map[id] = data.import_format;
    }
    return {
        allowed_field_types,
        field_types_map
    }
}

export function lie_buildCustomSelectOptions(it_data) {
    return it_data.options.choices.filter(a=>!a.hidden).map(it => {
        return {
            id: it.value,
            label: it.text
        }
    });
}

export function lie_buildFullProfileFieldData(community,ncfs) {
    let base = {...member_profile_data_fields};

    // ok prod
    if (community.uid) {
        base['member_type'].options = Object.entries(community.member_types).map(it => {
            return {
                id: it[0],
                label: it[1].singular
            }
        });
        base['member_type']['default_selection'] = community.profile.preferences.members.default_member_type;

        const cft_ref = buildCfsRef();
        const cfs = Object.entries(ncfs).map(sec => {
            return Object.entries(sec[1].field_data);
        });
        let t1, t2;

        for (let i = 0; i < cfs.length; i++) {
            const sec = cfs[i];
            // sec is arr
            for (let k = 0; k < sec.length; k++) {
                const [it_id,it_data] = sec[k];
                if (cft_ref.allowed_field_types.includes(it_data.type)) {
                    base[`custom_fields.${it_id}`] = {
                        label: it_data.name,
                        type: cft_ref.field_types_map[it_data.type], // convert to string
                        categories: ['custom']
                    };
                    base[`custom_fields.${it_id}`].field_id = it_id;
                    if(it_data.type==='checkbox') {
                        t1 = it_data.options;
                        t2 = !t1 ? {unchecked_label: 'false',checked_label: 'true'} : t1;
                        base[`custom_fields.${it_id}`].options = Object.entries(t2).map(([r1,r2])=>{
                            return {
                                id: r1!=='unchecked_label',
                                label: r2
                            }
                        })
                    } else if(it_data.type==='select') {
                        base[`custom_fields.${it_id}`].options = lie_buildCustomSelectOptions(it_data);
                    } if(it_data.type==='multi-select') {
                        base[`custom_fields.${it_id}`].options = it_data.options.choices.filter(a=>!a.hidden).map(it => {
                            return {
                                id: it.value,
                                label: it.text
                            }
                        });
                    }
                }
            }
        }
    } else {
        // mock data
        base['member_type'].options = [
            {
                id: '1231fsd0sd',
                label: 'Member'
            },
            {
                id: '0124if03dn',
                label: 'Ambassador'
            },
            {
                id: '1j0t3nf023',
                label: 'Guest'
            }
        ];
        base['member_type']['default_selection'] = '1j0t3nf023';
    }

    return base;
}