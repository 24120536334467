import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {getMemberProfileById} from "../../../config/community";
import {PreviewHeader} from "../../../m3/_legacy_components/preview-overlay";
import {InlineLoader} from "../../../m3/_legacy_components/admin-activity";
import MessagePage from "./index";

function validateData(dt) {
    return dt && !!dt.subject && !!dt.id;
}

export function PostPreview({onClose, id, community, unaverse, data}) {
    const navigate = useNavigate();
    /*
    const [dt, setDt] = useState(validateData(data) ? data : null);

    useEffect(function () {
        if (!validateData(data) && id) {

        } else if (data && id) {
            setDt({...data, id: id});
        }
    }, [data, id])

    if (!dt) {
        return <div className="">
            <PreviewHeader title="Post" onClose={() => onClose()}/>
            <div className="flex justify-center p-32 items-center">
                <InlineLoader/>
            </div>
        </div>
    }

     */

    return <>
        <PreviewHeader title="Post" onClose={() => onClose()}/>
        <div className="">
            <MessagePage preview id={id} init_data={data}/>
        </div>
    </>
}