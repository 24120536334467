import React from "react";

/*
* This is the Popover atom
 */

const _props = {};

function PopoverContent({children}) {

}

function M3_A_Popover({children}) {
    return <div>
        {children}
    </div>
}

PopoverContent.displayName = 'Popover.Content';

export default Object.assign(M3_A_Popover, {Content: PopoverContent})