import {externalAuthFetch} from "../../../../api/network";
import {getItemFromLocalStorage} from "../../../m3/utilities/localstorage";

const HELP_CENTER_URL = "https://theorange.app/help/";

export function goToHelpCenter(page = "welcome-to-orange") {
    window.open(`${HELP_CENTER_URL}${page}`, "_blank");
}

export function buildUserCommunities(ids, data) {
    let a = [];

    for (let i = 0; i < ids.length; i++) {
        let id = ids[i];
        let community = data[id];
        if (community) {
            a.push({
                id: id,
                handle: community.handle,
                name: community.name,
                profile_picture: community.profile_picture
            });
        }
    }

    return a;
}

export async function missingProductFeatureTag(feature, addToast = () => {
}) {
    addToast({
        text: `${feature} is not available yet. We're working on it!`
    })
    const res = () => {
    };
    externalAuthFetch("/missing-feature", res, res, "POST", {
        payload: {
            feature
        }
    });
}

export function schemeIsValid(scheme) {
    const scheme_split = scheme.split(',');

    if (scheme_split.length < 8) {
        return false;
    }

    // now check that each item is a hex code
    for (let i = 0; i < scheme_split.length; i++) {
        const item = scheme_split[i];
        if (!item.startsWith('#')) {
            return false;
        }

        if (item.length !== 7) {
            return false;
        }
    }

    return true;
}

export function isValidM3Scheme(scheme) {
    // should be a stringified object
    if (typeof scheme !== 'string') {
        return false;
    }

    try {
        const parsed = JSON.parse(scheme);
        if (parsed.accent_color && parsed.brightness && parsed.mode && parsed.notification_color) {
            return true;
        }
    } catch (e) {
        return false;
    }
}

export function buildCommunityTheme(community, return_theme) {
    // check localstorage

    if (community.theme_preferences) {
        return community.theme_preferences;
    }

    let local_scheme_data_m3 = getItemFromLocalStorage(`community_scheme_m3-${community.id}`);

    let local_scheme_m3 = local_scheme_data_m3?.scheme;

    if (local_scheme_m3) {
        // theme is saved as 8-10 hex strings separated by commas

        if (return_theme) {
            return local_scheme_m3;
        }
        return local_scheme_m3;
    }

    if (community?.profile?.sidebar_scheme) {
        // check if it's a valid scheme because this is where old schemes are stored
        if (isValidM3Scheme(community?.profile?.sidebar_scheme)) {
            if (return_theme) {
                return JSON.parse(community.profile.sidebar_scheme);
            }
            return JSON.parse(community.profile.sidebar_scheme);
        }
    }

    return {
        accent_color: community?.branding?.color || "#FF8400",
        brightness: 2,
        mode: "light",
        notification_color: "red"
    }
}