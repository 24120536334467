import {convertFromRaw} from 'draft-js';
import {collection, getDocs, limit, orderBy, query, startAfter, where} from "firebase/firestore";
import {db} from "../../app/config/setup-firestore";
import {utils_dates_format} from "../../common/utilities/dates";
export async function api_getPostReplies(community_uid,post_id,reply_to_id="",ref,page,per_page=25) {
    const replies_docs = await getPostReplies(community_uid,post_id,reply_to_id,ref,page,per_page,'group');

    const recent_replies = replies_docs.map(d=>{
        const dt = d.data();
        return {
            ...dt,
            id: d.id
        }
    });

    const last_ref = recent_replies.length>0 ? replies_docs[replies_docs.length-1] : null;

    return {replies: recent_replies.map(rp=>{
            return {
                ...rp,
                content_state: convertFromRaw(rp.raw),
                formatted_date: utils_dates_format(rp.created_at),
                data: rp
            }
        }), last_ref}
}

async function getPostReplies(community_uid,post_id,reply_to_id="",ref,page,per_page=25) {
let aref = collection(db, 'community_content', community_uid, 'posts', post_id, 'replies'), q, snap;
    if(ref&&page>0) {
        q = query(aref, where('reply_to_id', '==', reply_to_id), startAfter(ref), orderBy('created_at','desc'), limit(per_page));
        snap = await getDocs(q);

        return snap.docs;
    }
    q = query(aref, where('reply_to_id', '==', reply_to_id), orderBy('created_at','desc'), limit(per_page));
    snap = await getDocs(q);

    return snap.docs;
}