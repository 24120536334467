import React, {useState} from "react"
import './styles.css';
import {Avatar} from "../avatar";
import {BriefcaseIcon, MapPinIcon} from "@heroicons/react/24/outline";
import {DEFAULT_USER_PHOTO} from "../../../config/community";

import {CIModerator} from "../../icons/_legacy/custom/Moderator";
import {buildImageUrl} from "../../../../common/utilities/images";

function Cover({setExpanded, cover_style, loading, expanded}) {
    let style = {};

    if (!loading) {
        style = {background: `#e3e8ed`};
    }

    if (cover_style) {
        style = {...cover_style};
    }

    return <div style={style} onClick={(e) => {
        e.stopPropagation();
        setExpanded(!expanded);
    }} className={`bg-cover bg-center cursor-pointer -mb-4 ${expanded ? "h-32" : "h-16"}`}>

    </div>
}

function Image({loading, image, features = {}, large, xl, onClick}) {
    return <Avatar edit={features.openEditProfilePicture} onClick={onClick} large={large} url={image} border
                   size={xl ? "w-40 h-40" : large ? "h-32 w-32" : `h-16 w-16`}/>
}

function Favicon({loading, image, features = {}, onClick}) {
    return <Avatar  url={image} border
                   size={`h-5 w-5`}/>

}

function SmallImage({loading, minimal, image, dims = "h-12 w-12"}) {
    return <Avatar url={image ? image : buildImageUrl(DEFAULT_USER_PHOTO, '_small')} border={!minimal} size={dims}/>
}

function Emoji({loading, emoji, mini}) {
    const st = mini ? {fontSize: '1.2rem', lineHeight: '1.2rem'} : {fontSize: '3rem', lineHeight: '3.5rem'};
    return <div className="text-center" style={{...st}}>
        {emoji}
    </div>
}

function Date({loading, date, month}) {
    return <div className="h-12 w-12 border-3 shadow-md border-white bg-white text-center rounded-lg ">
        <div style={{fontSize: '0.64rem'}}
             className="text-red-500 tracking-wide pt-0.5 uppercase font-bold">{month}</div>
        <div className="text-gray-800 leading-5 text-xl pt-px font-bold">{date}</div>
    </div>
}

function CustomIcon({loading, icon}) {
    return <div
        className="h-12 flex items-center justify-center w-12 border-3 border-white bg-gray-200 text-gray-600 text-center rounded-xl ">
        <div className="w-6 h-6">
            {icon}
        </div>
    </div>
}

function Text({string, bg_color = 'bg-gray-400', color, mini}) {
    const sizes = mini ? "h-5 w-5 rounded-md border-1 text-[0.7rem]" : "h-14 w-14 rounded-xl border-3 text-base";
    let s = {};
    if(color) {
        s.background = `${color}`;
    }
    return <div style={s}
        className={`${sizes} uppercase  border-white ${bg_color}  tracking-wide flex items-center justify-center font-semibold text-white`}>
        {string}
    </div>
}

function Count({count, bg_color = 'bg-gray-300', color, mini}) {
    const sizes = mini ? "h-5 w-5 rounded-md border-1 text-[0.65rem]" : "h-10 w-10 rounded-xl border-3 text-sm";
    let s = {};
    if(color) {
        s.background = `${color}`;
    }
    return <div style={s}
                className={`${sizes} uppercase  border-white ${bg_color}  tracking-wide flex items-center justify-center font-semibold text-gray-500`}>
        {count}
    </div>
}

export function UniversalIcon(props) {
    if (props.loading && props.type !== 'image') {
        return <div className={`${props.minimal?"":"-m-0.5"} `}>
            <div className={`${props.minimal?"":"border-white border-3"} h-14 w-14 rounded-lg bg-gray-200`}/>
        </div>
    }

    const ex_classes = props.type==="emoji" && props.mini ? "flex items-center justify-center h-full": "";

    return <div className={`${props.mini ? "" : props.minimal ? "" : (props.top_offset || "-m-0.5")} ${ex_classes}`}>
        {props.type === 'favicon' && <Favicon {...props} />}
        {props.type === 'small-image' && <SmallImage {...props} />}
        {props.type === 'image' && <Image {...props} />}
        {props.type === 'custom-icon' && <CustomIcon {...props} />}
        {props.type === 'date' && <Date {...props} />}
        {props.type === 'emoji' && <Emoji {...props} />}
        {props.type === 'text' && <Text {...props} />}
        {props.type === 'count' && <Count {...props} />}
    </div>
}

function Actions({buttons}) {
    return <div className="absolute top-2 right-3">
        {buttons.map((it, index) => {
            return <div className="inline-flex" key={index}>
                {it}
            </div>
        })}
    </div>
}

function Meta({meta = [], footer}) {

    return <div className={`px-4 flex flex-wrap items-center gap-0.5 -mx-1.5 pt-1.5 ${footer ? "pb-1.5" : "pb-3"}`}>
        {meta.map((it, index) => {
            return <div className="inline-flex" key={index}>
                <MetaItem {...it}  />
            </div>
        })}
    </div>
}

function MetaItem({icon, label, editable}) {
    return <div
        className={`inline-flex group pr-2 space-x-2 px-1.5 rounded-lg ${editable ? "hover:bg-gray-200 cursor-pointer" : ""} items-center`}>
        <div className="">
            <div className="h-4 w-4 text-gray-500">{icon}</div>
        </div>
        <div className="flex-grow text-sm text-gray-600 whitespace-nowrap">
            <div>{label}</div>
        </div>
    </div>
}

function DescAction({show_more,onClick}) {
    if(show_more) {
        return <span className="cursor-pointer underline right-0 bg-white" onClick={() => onClick(false)}>Hide</span>
    } else {
        return <span className="cursor-pointer underline right-0 bg-white" onClick={() => onClick(true)}>Read more</span>
    }
}

function Header({title, suffix, loading, onClick, subtitle_color, header_top_padding = 'pt-2', desc, subtitle}) {
    const [show_more, setShowMore] = useState(false);
    const has_overflow = desc && desc.length > 165;
    return <div className={`px-4 ${header_top_padding}`}>
        <div onClick={() => {
            if (onClick) {
                onClick();
            }
        }} className="text-lg flex space-x-1 hover:underline cursor-pointer pt-px leading-5 text-gray-800 font-bold">
            <span>{loading ? <span className="h-3 inline-block w-48 rounded-md bg-gray-400"/> : title}</span>
            {suffix}
        </div>
        {subtitle && <div className={`leading-5 pt-0.5 text-sm ${subtitle_color||"text-gray-500"} font-medium`}>{subtitle}</div>}
        {desc && <div className={`text-gray-600 pt-1 relative text-sm spacing-4 `}>
            <span className={show_more?"":`line-clamp-4`}>{desc}&nbsp;&nbsp;</span>
            {has_overflow ? <DescAction onClick={()=>setShowMore(!show_more)} show_more={show_more} /> : null}
        </div>}
    </div>
}

const member_mock_config = {
    type: "member",
    title: "Jason Smith",
    subtitle: "@jsmith",
    icon: {
        type: "image"
    },
    meta: [
        {
            icon: <MapPinIcon/>,
            label: "Cupertino"
        },
        {
            icon: <BriefcaseIcon/>,
            label: "Product Manager"
        }
    ]
};

export const getEntityTag = (tag,config) => {
  switch (tag) {
      case "new":
          return <div style={{fontSize:'0.7rem'}} className="uppercase h-5 leading-5 font-bold rounded-md text-white bg-blue-400 tracking-wide px-1.5 ">New</div>
      case "organizer-tag":
          return <div style={{fontSize:'0.7rem'}} className="uppercase h-5 leading-5 font-bold rounded-md text-white bg-orange-500 tracking-wide px-1.5 ">New</div>
      case "organizer":
          return <div className={`h-5 svg-child-inherit ${config.invert_colors?"text-white":"text-green-700"} `}><CIModerator /></div>
      default:
          return null;
  }
};

export function EntityTags({config}) {
    return <div className="absolute flex space-x-2 top-1.5 right-2.5">
        {config.tags.map((t,k)=>{
            return <div key={k}>
                {getEntityTag(t.type,config)}
            </div>
        })}
    </div>
}

export default function EntityCard({children, onClick, footer, config = {...member_mock_config}}) {
    const [expanded, setExpanded] = useState(false);

    return <div className="overflow-hidden group relative rounded-xl">
        <Cover cover_style={config.cover_style} expanded={expanded} setExpanded={setExpanded}/>
        <div className={`bg-[#f8f8fa] ${expanded ? "" : "rounded-xl"} `}>
            <div className={`relative ${config?.flags?.tight_title ? "h-6" : "h-8"}`}>
                <div className="absolute -top-4 left-3.5">
                    <UniversalIcon {...config.icon} loading={config.loading} />
                </div>
                <Actions buttons={config?.buttons||[]} />
            </div>
            <Header onClick={onClick} {...config} />
            {children}
            <Meta {...config} />
            {config.footer&&<div className="px-4 pb-3">
                {config.footer}
            </div>}
        </div>
        {config?.tags?.length>0&&<EntityTags config={config} />}
    </div>
};