import React, {useEffect, useRef, useState} from "react"
import './styles.css';
import {ModalBody, ModalContentBlock, ModalFooter, ModalHeader} from "../preview-overlay";
import Button from "../button";
import {RichSelect} from "../rich-select/rich-select";
import {FieldLabel} from "../form/field";
import dayjs from "dayjs";
import EditModal from "../edit-modal";
import {InlineLoader} from "../admin-activity";
import {exportMembers} from "../../../routes/unaverse/preferences/tabs/advanced";
import {useCommunity} from "../../../config/community";
import EmptyState from "../empty-state";
import {DocumentIcon} from "@heroicons/react/24/outline";
import {logEngagementEvent} from "../../../config/firebase-setup";


export const export_separator_ref = {
    comma: {
        separator: ',',
        default_region: 'usa'
    },
    semicolon: {
        separator: ';',
        default_region: 'deu'
    }
};

// https://gist.github.com/mlconnor/1887156
export const regional_date_formats = {
    fra: 'DD/MM/YYYY',
    gbr: 'DD/MM/YYYY',
    deu: 'DD.MM.YYYY',
    can: 'DD/MM/YYYY',
    ita: 'DD/MM/YYYY',
    usa: 'M/D/YYYY',
    swe: 'YYYY-MM-DD',
    esp: 'D/MM/YYYY',
};

const options = [
    {
        value: 'comma',
        label: "Comma-separated",
        desc: "US and International standard",
        emoji: "🌐"
    },
    {
        value: 'semicolon',
        label: "Semicolon-separated",
        desc: "European standard",
        emoji: "🇪🇺"
    }
];

const today = dayjs();

const date_format_options = [
    {
        value: 'usa',
        label: "United States",
        desc: `${regional_date_formats['usa']} (${today.format(regional_date_formats['usa'])})`,
        emoji: "🇺🇸"
    },
    {
        value: 'deu',
        label: "Germany",
        desc: `${regional_date_formats['deu']} (${today.format(regional_date_formats['deu'])})`,
        emoji: "🇩🇪"
    },
    {
        value: 'gbr',
        label: "United Kingdom",
        desc: `${regional_date_formats['gbr']} (${today.format(regional_date_formats['gbr'])})`,
        emoji: "🇬🇧"
    },
    {
        value: 'fra',
        label: "France",
        desc: `${regional_date_formats['fra']} (${today.format(regional_date_formats['fra'])})`,
        emoji: "🇫🇷"
    },
    {
        value: 'ita',
        label: "Italy",
        desc: `${regional_date_formats['ita']} (${today.format(regional_date_formats['ita'])})`,
        emoji: "🇮🇹"
    },
    {
        value: 'esp',
        label: "Spain",
        desc: `${regional_date_formats['esp']} (${today.format(regional_date_formats['esp'])})`,
        emoji: "🇪🇸"
    },
    {
        value: 'can',
        label: "Canada",
        desc: `${regional_date_formats['can']} (${today.format(regional_date_formats['can'])})`,
        emoji: "🇨🇦"
    },

    {
        value: 'swe',
        label: "Sweden",
        desc: `${regional_date_formats['swe']} (${today.format(regional_date_formats['swe'])})`,
        emoji: "🇸🇪"
    }
];

export default function CsvExportModal({
                                           query_payload = {},
                                           export_context = "",
                                           handleClose = () => {
                                           }
                                       }) {
    const [delimiter, setDelimiter] = useState(options[0].value);
    const [date_format, setDateFormat] = useState(date_format_options[0].value);
    const community = useCommunity();
    const [link, setLink] = useState("");
    const [state, setState] = useState("");

    function startExport() {
        setState("loading")
        exportMembers(community, query_payload, delimiter, date_format)
            .then(async resp => {
                if (resp) {
                    logEngagementEvent("export_members", {}, community.uid);
                    console.log("RESP", resp)
                    setLink(resp.data.url);
                    setState("done");
                }
            })
    }

    if (!export_context) {
        return null;
    }

    let body = null;

    let footer_right = null;

    if (!state) {
        body = <div>
            <div className="pb-5">
                <p className="text-sm text-gray-600">
                    Export member data to a csv file you can download.
                </p>
            </div>
            <div className="space-y-4">
                <div>
                    <FieldLabel label="Delimiter"/>
                    <RichSelect onSelect={(v) => {
                        setDelimiter(v);
                        if (date_format === date_format_options[0].value) {
                            if (v === 'comma') {
                                setDateFormat('usa');
                            } else if (v === 'semicolon') {
                                setDateFormat('deu');
                            }
                        }
                    }} options={options} selected={delimiter}/>
                </div>

                <div>
                    <FieldLabel label="Date Format"/>
                    <RichSelect onSelect={(v) => setDateFormat(v)} options={date_format_options}
                                selected={date_format}/>
                </div>
            </div>
        </div>;
        footer_right = <Button text="Export to CSV" intent="primary" onClick={() => startExport()}/>
    } else if (state === "loading") {
        body = <div className="pt-2 pb-6 flex justify-center">
            <InlineLoader padding="p-8"/>
        </div>
    } else if (state === "done") {
        body = <div className="">
            <EmptyState title="File ready for download"
                        icon={<DocumentIcon/>}
                        button={<Button text="Download" intent="primary" onClick={() => {
                            // download the link file
                            window.open(link, '_blank');
                        }}/>}
            />
        </div>
        footer_right = <Button text="Done" onClick={handleClose}/>
    }

    return <EditModal open onClose={handleClose} size="medium" custom>
        <ModalHeader title={"Export Members"} onClose={handleClose}/>
        <ModalBody>
            <ModalContentBlock>
            {body}
            </ModalContentBlock>
        </ModalBody>
        {footer_right && <ModalFooter right={footer_right}/>}
    </EditModal>
};