import styled from "styled-components";
import {components} from "react-select";
import React from "react";
//import {ESMembersList} from "../../reorganize/entity-select/item";
import {Avatar} from "../avatar";
import {ChevronDownIcon, MagnifyingGlassIcon, XMarkIcon} from "@heroicons/react/20/solid";

const IconBoxMini = styled.div`

  > svg {
    height: 20px;
  }
`;


const DropdownBox = styled.span`
  padding-right: 12px;

  > svg {
    height: 18px;
  }
`;

const ClearBox = styled.div`
  padding: 0;

  > svg {
    height: 20px;
  }
`;

const ClearIndicator = props => {
    const {
        getStyles,
        innerProps: {ref, ...restInnerProps},
    } = props;
    return (
        <div
            {...restInnerProps}
            ref={ref}
            style={getStyles('clearIndicator', props)}
        >
            <ClearBox className="text-gray-500 hover:text-gray-800"><XMarkIcon/></ClearBox>
        </div>
    );
};

const DropdownIndicator = ({innerProps}) => {
    return <DropdownBox className='text-gray-500' {...innerProps}>
        <MagnifyingGlassIcon/>
    </DropdownBox>
};

const SimpleDropdownIndicator = ({innerProps}) => {
    return <DropdownBox className='text-gray-500' {...innerProps}>
        <ChevronDownIcon/>
    </DropdownBox>
};

const IndicatorSeparator = ({innerProps}) => {
    return <span className="border-r border-solid border-gray-200 h-6 mr-2 inline-block" {...innerProps} />;
};

const ControlComponent = props => (
    <components.Control {...props} />
);

const SelectContainer = ({children, ...props}) => {
    return (
        <components.SelectContainer {...props}>
            {children}
        </components.SelectContainer>
    );
};

const SingleValue = props => {
    const val = props.getValue()[0];
    return <div className="flex flex-none">
        {val.image && <div className="mr-1.5">
           <Avatar url={val.image} text={val.label} size="h-6 w-6"/>
        </div>}
        <div className="flex-1">
            <components.SingleValue {...props}/>
        </div>
    </div>
};

const Option = props => {
    const data = props.data;
    // props.isFocused
    return <div onClick={() => props.selectOption(data)}
                className={`relative flex px-3 py-1.5 transition-colors ${props.isDisabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"} ${props.isFocused ? "bg-selection" : "bg-white hover:bg-gray-100"}`}>
        {(data.image || data.icon) && <div className="flex-none w-6" style={{height: '24px'}}>
            {!data.icon && !data.simple && data.image &&
            <Avatar url={data.image ? `${data.image}_small?alt=media` : ""} text={data.label} size="xs"/>}

            {!data.image && data.icon && !data.simple && <IconBoxMini
                className={`h-6 w-6 flex items-center justify-center ${props.isFocused ? "text-gray-200" : "text-gray-700"}`}>
                {data.icon}
            </IconBoxMini>}
        </div>}
        <div className="flex-1 items-center flex px-2.5">
            <components.Option {...props} getStyles={() => {
                return {}
            }} children={<span className="">
                               <span
                                   className={`text-sm font-semibold ${props.isFocused ? "text-white" : "text-gray-800"}`}>{data.label}</span>
                {data.desc &&
                <span
                    className={`text-sm pl-3 ${props.isFocused ? "text-gray-200 font-medium" : "text-gray-400"}`}>{data.desc}</span>}</span>}/>
        </div>
        <div className="flex-none">

        </div>
    </div>
};

const NewOption = props => {
    const data = props.data;
    const {innerProps,innerRef} = props;
    return <components.Option {...props}>
        {data.image&&<div className="mr-1.5"><Avatar size="h-5 w-5" url={data.image} /></div>}
        <div className="pointer-events-none">
            {props.children}
        </div>
    </components.Option>
}

const OptionSimple = props => {
    const data = props.data;
    // props.isFocused
    return <div onClick={() => props.selectOption(data)}
                className={`relative flex px-3 py-1.5 transition-colors ${props.isDisabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"} ${props.isFocused ? "bg-selection" : "bg-white hover:bg-gray-100"}`}>
        <div className="flex-1 items-center flex pl-1.5">
            <components.Option {...props} getStyles={() => {
                return {}
            }} children={<span className="">
                               <span
                                   className={`text-sm font-semibold ${props.isFocused ? "text-white" : "text-gray-800"}`}>{data.label}</span>
                {data.desc &&
                <span
                    className={`text-sm pl-3 ${props.isFocused ? "text-gray-200 font-medium" : "text-gray-400"}`}>{data.desc}</span>}</span>}/>
        </div>
        <div className="flex-none">

        </div>
    </div>
};

const ValueContainer = props => {
    return <components.ValueContainer {...props} />
}

const MultiValueContainer = props => {
    const data = props.data;
    const {innerRef,innerProps} = props;
    return (
        <components.MultiValueContainer {...props}>
            {data.image&&<div><Avatar url={data.image} /></div>}
            {props.children}
        </components.MultiValueContainer>
    );
};

const MultiValueLabel = props => {
    const data = props.data;
    return (
        <span onClick={(e)=>e.stopPropagation()} className={props.innerProps.className}>
            <span className="px-1">{props.children}</span>{data.meta&&<span className="pl-1 text-xs text-gray-500">{data.meta}</span>}
            </span>
    );
};

const RemoveIcon = styled.span`
  height: 100%;
  display: flex;
  align-items: center;

  > svg {
    margin-top: 1px;
    height: 12px;
  }
`;

const MultiValueRemove = props => {
    return (
        <components.MultiValueRemove {...props}>
            <RemoveIcon>
                <XMarkIcon/>
            </RemoveIcon>
        </components.MultiValueRemove>
    );
};



const Menu = props => {

    return (
        <div>
            <components.Menu {...props}>{props.children}</components.Menu>
        </div>
    );
};

export const advanced_select_components_simple = {
    ControlComponent,
    MultiValueContainer,
    MultiValueLabel,
    MultiValueRemove,
    ClearIndicator,
    Option: NewOption,
    // IndicatorSeparator,
    DropdownIndicator: SimpleDropdownIndicator,
    SelectContainer,
    SingleValue
};

export const advanced_select_components = {
    ControlComponent,
    MultiValueContainer,
    MultiValueLabel,
    MultiValueRemove,
    ClearIndicator,
    Option: NewOption,
        ValueContainer,
  //  IndicatorSeparator,
    DropdownIndicator,
    SelectContainer,
    SingleValue
};