import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import EntityProfileCard from "../entity-profile-card";
import Button from "../button";
import {PreviewHeader} from "./index";
import {buildRoleProfileCard} from "../../../routes/community/position";
import {InlineLoader} from "../admin-activity";
import {getEventData} from "../../../../api/events";

import {getAdvancedImageBackgroundStyle} from "../../../m3/utilities/image-background";
import dayjs from "dayjs";
import DataWrapper from "../data-wrapper";
import {CalendarIcon, ClockIcon, LinkIcon, MapPinIcon} from "@heroicons/react/24/outline";
import {useCommunity} from "../../../config/community";
import ContentPopup from "../content-popup";
import {ContentPopupDivider, ContentPopupMenu, ContentPopupSubheader} from "../content-popup/components";
import SimpleTooltip from "../tooltip";
import {BellSlashIcon, CheckIcon} from "@heroicons/react/20/solid";
import {event_rsvp} from "../../../../api/events/rsvp";
import {parseConferenceData, parseEventLocation} from "../../../routes/community/calendar/utilities";
import {buildImageUrl} from "../../../../common/utilities/images";

function RSVPButton({event}) {
    const community = useCommunity();
    const [data, setData] = useState(event);
    // responseStatus: declined, accepted, needsAction, tentative

    const member_response_status = data?.attendees?.[community.member_id] || "needsAction";

    function handleRSVP(new_status) {
        let updated_data = {...data};
        updated_data.attendees[community.member_id] = new_status;
        setData(updated_data);
        event_rsvp(event.id,new_status,community)
            .then(() => {
                console.log("RSVP updated")
            })
    }

    // ContentPopupDivider

    const content = <ContentPopup>
        <ContentPopupSubheader top>
            Going?
        </ContentPopupSubheader>
        <ContentPopupMenu can_check items={[
            {
                label: "Yes",
                checked: member_response_status === "accepted",
                onClick: () => handleRSVP("accepted")
            },
            {
                label: "No",
                checked: member_response_status === "declined",
                onClick: () => handleRSVP("declined")
            },
            {
                label: "Maybe",
                checked: member_response_status === "tenative",
                onClick: () => handleRSVP("tenative")
            },
        ]}/>
    </ContentPopup>

    const text = member_response_status === "accepted" ? "Going: Yes" : member_response_status === "declined" ? "Going: No" : member_response_status === "tenative" ? "Going: Maybe" : "Going?";

    const icon = member_response_status === "accepted" ? {icon: <CheckIcon/>} : null;

    return <SimpleTooltip usePortal simple trigger="click" interactive hideOnClick={false} placement="bottom-end"
                          text={content}>
        <Button dropdown left_icon={icon} text={text}/>
    </SimpleTooltip>;
}

export function EventInsight({event, flags}) {
    const community = useCommunity();
    if (!event) {
        return null;
    }

    const host_member_id = getHost(event);

    if (!host_member_id) {
        return null;
    }

    // if you are the host, you can not see the insight
    if (host_member_id === community.member_id) {
        return null;
    }

    return <div className="px-4 flex space-x-1 items-center text-xs pb-2.5 text-gray-500">
        <DataWrapper id={host_member_id} type={`community_members.${community.id}.members`}><Name/></DataWrapper><span>invited you.</span>
    </div>
}

function EventSubtitle({is_public, community_uid, host_member_id, group_id}) {
    return <span className="inline-flex gap-1.5">
        <span>{is_public ? "Public" : "Private"}</span>
        {host_member_id && <span className="opacity-60">•</span>}
        {host_member_id &&
            <span>Hosted by <DataWrapper id={host_member_id} type={`community_members.${community_uid}.members`}><Name/></DataWrapper></span>}
        {group_id && <span className="opacity-60">•</span>}
        {group_id && <span><DataWrapper id={group_id}
                                        type={`community_entities.${community_uid}.groups`}><Name/></DataWrapper></span>}
    </span>
}

function Name({data}) {
    return <span className="">{data?.name}</span>
}

function getHost(event) {
    const obj = event?.hosts?.user_uids || {};
    return obj && Object.keys(obj)[0] || null;
}

export function buildEventProfileCard(data, unaverse, community, flags = {}, fns = {}, loading) {
    let title, message, ex = {}, buttons = [], footer, subtitle, meta = [], stats = [];

    const default_size = "_large"; //_large

    const cover_photo_size = flags.cover_photo_size !== undefined ? flags.cover_photo_size : default_size;

    if (loading) {
        title = "Loading";
    } else if (flags.does_not_exist) {
        title = "This event doesn't exist";
        subtitle = "Try searching for another.";
    } else if (data) {
        title = data.summary;
        subtitle = <EventSubtitle is_public={data?.community_visibility === "public"} community_uid={community.id}
                                  group_id={data?.group_id} host_member_id={getHost(data)}/>;
        const day = dayjs(data.start);

        const full_start_time_from_ms = dayjs(data.start_datetime);

        // example: Thursday, January 4th, at 7:00pm
        const day_full_format = `dddd, MMMM Do[,] [at] h:mma`;

        meta.push({
            label: `${full_start_time_from_ms.format(day_full_format)}`,
            icon: <ClockIcon/>
        })

        if (data?.location) {
            const parsed = parseEventLocation(data.location);
            meta.push({
                label: parsed.label || data.location,
                sublabel: parsed.sublabel || "",
                icon: <MapPinIcon/>,
                action: parsed?.sublabel ? {
                    label: "Show Map",
                    onClick: () => {
                        // open in google maps
                        const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(data?.location)}`;

                        window.open(url, "_blank", "noopener noreferrer");
                    }
                } : null
            })
        }

        if(data?.conference_data) {

            const parse_conf_data = parseConferenceData(data.conference_data);

            if(parse_conf_data?.label) {
                meta.push({
                    label: parse_conf_data.label,
                    icon: <LinkIcon/>,
                    action: {
                        label: "Join",
                        onClick: () => {
                            const url = parse_conf_data.uri;

                            if (url) {
                                window.open(url, "_blank", "noopener noreferrer");
                            }
                        }
                    }
                })
            }
        }

        if(data?.source && data.source === "google") {
            meta.push({
                label: "Synced via Google Calendar",
                icon: <CalendarIcon/>
            })
        }

        buttons.push(<RSVPButton event={data}/>);

        footer = <EventInsight flags={flags} event={data}/>;

        const invited_count = data?.stats?.invited || 0;
        const accepted_count = data?.stats?.accepted || 0;

        stats.push({
            count: `${invited_count}`,
            label: `Invited`,
            onClick: () => {
                //     fns.onOpenMembers();
            }
        });

        stats.push({
            count: `${accepted_count}`,
            label: `Going`,
            onClick: () => {
                //     fns.onOpenMembers();
            }
        });

        ex = {
            desc: data.description,
            image: buildImageUrl(data?.cover_photo, cover_photo_size),
            image_color: data?.cover_photo_color,
            cover_style: getAdvancedImageBackgroundStyle("event", data?.cover_photo || "", data?.cover_photo_color || "", "auto", cover_photo_size),

            features: {
                desc_chars: 2000,
                single_row_meta: true,
                edit_cover: !!fns.onOpenEditCoverPhoto,
                onOpenEditCoverPhoto: () => fns.onOpenEditCoverPhoto(),
            },

            meta,
            stats,

            icon: {
                type: "date",
                date: day.format("DD"),
                month: day.format("MMM")
            }
        };

    } else {
        title = "Not found";
    }

    return {
        title,
        subtitle,
        loading: !!loading,
        message,
        footer,
        // subtitle: getMemberTypeName(data, community),
        buttons,
        ...ex
    }
}

/*
  <FollowButton updateFollowing={(fl) => {
            }} id={dt.id} type="member"/>
 */
export function EventPreview({onClose, id, community, unaverse, data}) {
    const navigate = useNavigate();

    const [dt, setDt] = useState(data);
    const [follow_data, setFollowData] = useState({});

    useEffect(function () {
        if (!data && id) {
            getEventData(community.id, id)
                .then(pr => {
                    setDt({...pr, id: id});
                })
        }
    }, [data, id])

    useEffect(function () {
        if (dt && dt.id) {
            /*
            getEntityFollowData(community, dt.id, "role")
                .then((fd) => {
                    setFollowData(fd)
                })

             */
        }
    }, [dt])

    if (!dt) {
        return <div className="" style={{minHeight: '50vh'}}>
            <PreviewHeader title="Event" onClose={() => onClose()}/>
            <div className="flex justify-center p-32 items-center">
                <InlineLoader/>
            </div>
        </div>
    }

    const flags = {};
    const fns = {};

    const m = buildEventProfileCard(dt, unaverse, community, flags);

    return <div>
        <PreviewHeader title="Event" onClose={() => onClose()}/>
        <EntityProfileCard onTitleClick={() => {
            navigate(`/c/${community.domain}/event/${dt.id}`);
        }} preview {...m} />
        <div className="px-4 flex pb-1 space-x-2">
            <Button text="Go to Event" onClick={() => {
                community.closePreviewOverlay();
                navigate(`/c/${community.domain}/event/${dt.id}`);
            }}/>

        </div>
        <div className='border-t mt-2 border-gray-200'>

        </div>
    </div>
}