import React from "react";
import M3_A_Avatar from "./avatar";
import M3_A_Icon from "./icon";
import M3_A_Color from "./color";
import M3_A_Emoji from "./emoji";

/*
* This is the Token atom
 */

const _props = {
    type: {
        type: "string",
        required: false,
        default: "avatar",
        options: ["avatar", "icon", "color", "emoji"]
    }
};

function renderComponent(type, data) {
    switch (type) {
        case "avatar":
            return <M3_A_Avatar {...data} />;
        case "icon":
            return <M3_A_Icon {...data} />;
        case "color":
            return <M3_A_Color {...data} />;
        case "emoji":
            return <M3_A_Emoji {...data} />;
        case "letter":
            return <M3_A_Avatar {...data} />;
        default:
            return null;
    }
}

export default function M3_A_Token({
    type = "avatar",
    data
                                   }) {

    return renderComponent(type,data);
}