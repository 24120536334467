import React from "react";
import createLinkPlugin from "@draft-js-plugins/anchor";

function ToolbarItem({text,first,last}) {
    const border = `${first?"rounded-l":""} ${last?"rounded-r":""}`;
    return <div className={`${border} text-white text-sm font-medium cursor-pointer px-3 py-1.5 bg-gray-800 hover:bg-gray-600`}>{text}</div>
}

function InlineToolbar({items=[]}) {
    return <div className="bg-gray-800 rounded m-1 overflox-x-hidden flex divide-x divide-gray-500">
        {items.map((it,k)=><ToolbarItem first={k===0} last={(k+1)===items.length} key={k} {...it} />)}
    </div>
}

const LinkComponent = (props) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    const items = [
        {
            text: 'Edit link'
        },
        {
            text: 'Remove link'
        }
    ];

    return <a rel="noreferrer noopener" className="link-2" onClick={()=>{}}>
        {props.children}
    </a>
};

export const scPluginLink = createLinkPlugin({
    Link: LinkComponent
});