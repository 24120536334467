import React, {useRef, useState} from "react";
import {LayoutFull} from "../../../m3/_legacy_components/app-frame/layouts";
import {UnaverseSectionHeader} from "./unaverse";
import Button from "../../../m3/_legacy_components/button";
import {TextAction} from "../../auth/sign-in";
import {copyTextToClipboard} from "../../../m3/utilities/copy-to-clipboard";
import {useToasts} from "../../../config/toasts";
import {authFetch} from "../../../../api/network";
import {image_uploadAndResize} from "../../../features/forum/editor/rich-text-editor";
import {imageResizeRequest, migrateImageRequest} from "../../../../api/image-resizer";
import {utils_strings_isEmail} from "../../../../common/utilities/strings";

function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

const buildImageInfo = (images) => {
    return {
        sizes: images.map(it => {
            return {
                height: it.height,
                width: it.width,
                url: it.url,
                name: it.name
            }
        })
    }
};

export async function api_generateGhostAccessLink(email) {
    return new Promise((resolve, reject) => {
        const res = (resp) => {
            if(resp?.data?.link) {
                resolve(resp.data.link);
            } else {
                reject("Error generating link");
            }
        };
        authFetch("/get-ghost-access-link", res,res,"POST", {
            payload: {
                origin: window.origin,
                email
            }
        })
    })
}

function GhostAccessLink() {
    const [loading, setLoading] = useState(false);
    const toasts = useToasts();
    const [email, setEmail] = useState("");
    function getLink() {
        toasts.addToast({
            text: "Generating link...",
            intent: "info",
        });

        setLoading(true);

        api_generateGhostAccessLink(email)
            .then(link => {
                copyTextToClipboard(link);
                toasts.addToast({
                    text: "Link copied to clipboard",
                    intent: "success",
                });
                setLoading(false);
            })
    }

    return <div className="flex gap-3">
        <div>Generate a ghost sign in link</div>
        <input className="input-focus" placeholder={"enter a user email"} type="text" value={email} onChange={e => setEmail(e.target.value)}/>
        <Button loading={loading} disabled={!utils_strings_isEmail(email)} onClick={getLink}>Get Link</Button>
    </div>
}

function TestPhotoConverter() {
    // input: https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2FQZMOJ37AA9
    // full image is under:https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2FQZMOJ37AA9?alt=media
    // need to generate thumbnail
    const [url, setUrl] = useState("");
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);

    function getFile() {
        setLoading(true);
        const final_url = url.includes("?alt=media") ? url : `${url}?alt=media`;
        console.log("FINAL URL", final_url, final_url === "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2FwFTqLbOMP?alt=media")
        try {
            /*
            fetch(final_url)
                .then(res => {
                    return res.blob();
                })
                .then(blob => {
                    setFile(blob);
                    setLoading(false);
                })

             */
            const res = (resp) => {
                console.log("RESP", resp)
                setLoading(false);
            };
            migrateImageRequest("/migrate", res,res,"POST", {
                payload: {
                    url: final_url
                }
            });
        } catch (e) {
            console.log("ERROR", e)
            setLoading(false);
        }
    }

    function reprocessImage() {
        const uid = randomIntFromInterval(0, 14294323);

        setLoading(true);

        let scope_id = "";

        const metadata = {
            path: `${scope_id ? `${scope_id}/` : ``}profile_pictures/${uid}`,
            uid
        };

        const res = (resp) => {
            console.log("RESP", resp)
            setLoading(false);
            if (resp && resp.data && resp.data.url) {

            } else {

            }
        };

        imageResizeRequest(file, res, "POST", {
            ...{profile_picture: true},
            ...metadata
        });
    }

    return <div>
        <input className="input-focus" type="text" value={url} onChange={e => {
            setUrl(e.target.value)
        }}/>
        <div>
            Has file: {file ? "yes" : "no"}
        </div>
        <Button loading={loading} disabled={!url} onClick={() => {
            getFile()
        }} text="Get file"/>
        {file && <div>
            <Button loading={loading} text="Convert to Image" onClick={() => reprocessImage()}/>
        </div>}
    </div>
}

/**
 * UploadImagesGetUrls
 * This tool is used to upload images to a demo bucket on firebase and get the urls for them.
 * This is useful for testing the image upload functionality.
 * @returns {JSX.Element}
 */
function UploadImagesGetUrls() {
    const toasts = useToasts();
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [urls, setUrls] = useState([]); // [url, url, url
    const urls_store = useRef([]);

    function getUrlsForFiles() {

        console.log("GETTING URLS FOR FILES", images.length)
        // for each file...
        images.forEach(file => {
            const uid = randomIntFromInterval(0, 14294323);
            const cb = (url, meta, extra, color, images) => {
                console.log("url", url, images);
                urls_store.current.push(buildImageInfo(images, url));
                setUrls([...urls_store.current]);
            };

            image_uploadAndResize(file, `demo/images/${uid}`, {}, file.type, {}, cb)

        })

        setImages([]);
    }

    return <div className="grid gap-4">
        <div className="">
            <div className="">
                <div className="text-sm pb-2">
                    Upload Images and get urls for up to 3 images at a time, may take 20 seconds to process
                </div>
            </div>
        </div>

        <div>
            <input type={"file"} multiple={true} onChange={(e) => {
                const files = e.target.files;
                if (files) {
                    const fileArray = Array.from(files);
                    setImages(fileArray);
                }
            }}/>
        </div>
        <div>
            <Button text="Get Urls" intent="primary" onClick={getUrlsForFiles} disabled={images.length === 0}/>
        </div>
        <div>
            {urls.map((url, index) => {
                return <div key={index}>
                    <div>
                        <img height="20px" width="20px" src={url.sizes[0].url} alt={url.sizes[0].name}/>
                    </div>
                    <div>
                        <div>
                            Image {index + 1}
                        </div>
                        <div className="flex space-x-4">
                            {url.sizes.map(size => {
                                return <div key={size.name}>
                                    <TextAction text={size.name} onClick={() => {
                                        copyTextToClipboard(size.url, () => {
                                            toasts.addToast({
                                                text: "Copied to clipboard",
                                                intent: "success"
                                            })
                                        })
                                    }}/>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            })}
        </div>
    </div>
}

export function ManageToolsPage() {

    return <LayoutFull>
        <div className="space-y-4">
            <UnaverseSectionHeader
                title={"Tools"}
            />
            <div>
                <UploadImagesGetUrls/>
            </div>
            <div>
                <TestPhotoConverter/>
            </div>
            <div>
                <GhostAccessLink />
            </div>
        </div>
    </LayoutFull>
}