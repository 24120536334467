import React from 'react';
import {CheckIcon} from "@heroicons/react/20/solid";
import styled from "styled-components";

const IconItem = styled.div`
> svg {
    height: 14px;
}
`;

function getStyles(size) {
    switch (size) {
        case 'xs':
            return {
                button: 'h-4 w-7 border-2',
                circle: 'h-3 w-3',
                circle_unchecked: 'translate-x-0',
                circle_checked: 'translate-x-3 '
            }
        default:
            return {
                button: 'h-6 w-11 border-2',
                circle: 'h-5 w-5 ',
                circle_unchecked: 'translate-x-0',
                circle_checked: 'translate-x-5 '
            }
    }
}

export function PrettyRadio({label,onChange = () => {
},value}) {
    return <div onClick={()=>onChange()} className="flex py-px cursor-pointer items-center space-x-2">
        <input checked={value} onChange={()=>{}} className="pretty-radio cursor-pointer" type="radio" />
        {label&&<span className="text-base text-gray-700">{label}</span>}
    </div>
}

export function PrettySwitch({checked,onToggle}) {
    return <input checked={!!checked} onChange={()=>{
        if(onToggle) {
            onToggle()
        }
    }} className="pretty-switch cursor-pointer" type="checkbox" />
}

export function PrettyCheckbox({onClick=()=>{},ref,label,active=true,children}) {
    const colors = active ? "bg-blue-600 border-blue-600 text-white hover:bg-blue-400" : "bg-white hover:bg-gray-200 border-gray-300";
    return <div ref={ref} className="flex items-center space-x-1.5">
        <div onClick={()=>{
            onClick();
        }} className={` w-4 cursor-pointer h-4 flex ${colors} transition-colors rounded-md border `}>
            {active&&<div className="w-3.5 h-3.5">
                <CheckIcon />
            </div>}
        </div>
        {label&&<span className="text-sm text-gray-700">{label}</span>}
        {children&&<span className="">{children}</span>}
    </div>
}

// ATTENTION leave these, they are needed!
let options = ['bg-amber-500','border-amber-500','border-selection','bg-selection','bg-success','border-success','bg-danger','border-danger'];

export function Toggle({layout="checkmark",simple,color="selection",size="sm",onToggle=()=>{},checked=false,label=""}) {
    const styles = getStyles(size);
    const content = <div className="flex items-center" onClick={(e)=> {
        e.stopPropagation()
        onToggle(!checked)
    }}>
        {layout==='switch'&&<div className="flex-shrink-0 pt-px">
            {!checked&&<button onClick={(e)=> {
                e.stopPropagation()
                onToggle(true)
            }} type="button"
                               className={`bg-gray-200 relative real-outline-none inline-flex flex-shrink-0 ${styles.button} border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200`}
                               aria-pressed="false">
                        <span
                            className={` inline-block ${styles.circle} ${styles.circle_unchecked} rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}/>
            </button>}
            {checked&&<button onClick={(e)=> {
                e.stopPropagation()
                onToggle(false)
            }} type="button"
                              className={`bg-${color} real-outline-none relative inline-flex flex-shrink-0 ${styles.button} border-${color} rounded-full cursor-pointer transition-colors ease-in-out duration-200`}
                              aria-pressed="false">
                        <span
                            className={`outline-none inline-block ${styles.circle} ${styles.circle_checked} rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}/>
            </button>}
        </div>}
        {layout==='checkmark'&&<div className="flex-shrink-0 pl-px flex items-start">
            {!checked&&<div onClick={(e)=> {
                e.stopPropagation()
                onToggle(true)
            }} className="inline-flex justify-center items-center hover:bg-gray-100 flex-shrink-0 h-4 w-4 cursor-pointer text-gray-500 rounded-md bg-white border border-gray-400">

            </div>}
            {checked&&<IconItem onClick={(e)=> {
                e.stopPropagation()
                onToggle(false)
            }} className="inline-flex items-center justify-center flex-shrink-0 h-4 w-4 cursor-pointer text-white rounded-md border border-secondary bg-secondary">
                <CheckIcon/>
            </IconItem>}
        </div>}
        {label&&<div className={`ml-3 text-${size} text-gray-500`}>
            {label}
        </div>}
    </div>;
    if(simple) {
        return content;
    }

    return <div className="mt-1 mb-1">
        <div className="">
            {content}
        </div>
    </div>
}