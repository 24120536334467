import React from "react"
import './styles.css';
import ExternalAuthPage from "../../../m3/_legacy_components/external-auth-page";
import {UnaverseCategoryBackground} from "../create-account";
import {setDocumentTitle} from "../../../m3/utilities/set-document-title";

export default function ResetPasswordPage() {

    setDocumentTitle(`Reset Password`);

    return <ExternalAuthPage show_logo background={<UnaverseCategoryBackground />}>
        <div className="w-full space-y-6 max-w-sm">
           RESET PW
       </div>
    </ExternalAuthPage>
};