import {authFetch} from "../network";

export async function group_changeCoverPhoto(community, id, {url, color}) {
    const p2 = {
        community_uid: community.uid,
        id: id,
        member_id: community.member_id,
        user_uid: community.member.user_uid,
        color: color,
        url: url.split("_large")[0].replace('?alt=media', '')
    };
    return new Promise(resolve => {
        const r = () => {
resolve();
        };
        authFetch('/groups/set-cover-photo', r, r, "POST", {payload: p2});
    })
}