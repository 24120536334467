export const sample_flow_manifest = {
    name: "Member Onboarding",
    type: "onboarding",

    branding: {
        accent_color: "#316941",
    },

    triggers: {
        type: "first-sign-in" // role change
    },

    header: {
        title: "Creative Collective Onboarding",
        logo: {
            type: "image",
            url: `https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2FOUQZXK59QZ_medium?alt=media`
        },
    },

    context: "community",
    context_id: "",

    features: {
      skippable: false
    },

    state: {

    },

    intro: {
        title: "👋 Welcome to Creative Collective!",
        layout: "standard",
        description: "We're excited to have you here. Let's get you set up.",

        blocks: [],

        actions: {
            primary: "Let's Start",
            primary_action: {
                type: "next"
            },
        },

        features: {
            skippable: false,
            can_press_enter: true
        }
    },

    outtro: {
        title: "All Done!",
        description: "You're all set up and ready to go!",

        blocks: [],

        actions: {
            primary: "Sign in",
            primary_action: {
                type: "redirect",
                to: 'home?ref=flow&refid=memberonboarding'
            }
        }
    },

    steps: [
        {
            id: "134",
            name: "Name",
            type: "standard",

            title: "Tell us a bit about yourself:",
            description: "",

            complete_rules: [
                {
                    type: 'state-exists',
                    field: 'first_name'
                },
                {
                    type: 'state-exists',
                    field: 'last_name'
                }
            ],

            actions: {
                primary: "Continue"
            },

            blocks: [
                {
                    type: "full_name"
                }
            ],

            data: {}
        },
        {
            id: "234",
            name: "Contact",
            type: "standard",

            title: "What's the best way to reach you?",
            description: "",

            complete_rules: [
                {
                    type: 'state-exists',
                    field: 'account_email_verified'
                }
            ],

            actions: {
                primary: "Continue"
            },

            blocks: [
                {
                    type: "contact"
                }
            ],

            data: {}
        },
        {
            id: "53453",
            name: "optin",
            type: "standard",

            title: "Favorites",
            description: "",

            complete_rules: [

            ],

            actions: {
                primary: "Continue"
            },

            blocks: [
                {
                    type: "custom_fields_section",
                    id: "a0NdNWGSt1zlRHdAUwhz"
                }
            ],

            features: {
                skippable: true
            },

            data: {}
        },
        {
            id: "23425",
            name: "creative-collective-details",
            type: "standard",

            title: "Complete your Profile",
            description: "",

            complete_rules: [

            ],

            actions: {
                primary: "Continue"
            },

            blocks: [
                {
                    type: "custom_fields_section",
                    id: "pT0e3KCV5BkZXCL3oIv9"
                }
            ],

            data: {}
        }
    ]
}