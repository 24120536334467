import React from "react";
import {m3_adjust_size} from "../utilities";

/*
* This is the Avatar atom
 */

const _props = {
    src: {
        type: "string",
        required: true
    },
    alt: {
        type: "string",
        required: false
    },
    size: {
        type: "string",
        required: false,
        default: "xs",
        options: ["xs", "sm", "base", "lg", "xl"]
    }
};

const size_map = {
    "xs": "w-4 h-4",
    "sm": "w-5 h-5",
    "base": "w-6 h-6",
    "lg": "w-8 h-8",
    "xl": "w-10 h-10",
    "2xl": "w-12 h-12",
    "3xl": "w-16 h-16",
};

const rounded_map = {
    "xs": "rounded-md",
    "sm": "rounded-md",
    "base": "rounded-lg",
    "lg": "rounded-xl",
    "xl": "rounded-2xl",
    "2xl": "rounded-3xl",
    "3xl": "rounded-3xl",
};

export default function M3_A_Avatar({
                                        letter,
                                        avatar,
                                        alt,
                                        size = "xs",
                                        sx
                                    }) {
    let classes = "", style = {};

    if(size.includes("h-")) {
        classes += ` ${size} rounded-lg`;
    } else {
        classes += ` ${size_map[size]}`;
        classes += ` ${rounded_map[size]}`;
    }

    if(sx) {
        style = {...sx};
    }

    if(!avatar&&letter) {
        return <div className={`${classes} text-center text-white inline-flex justify-center items-center bg-gray-200`} style={{
            lineHeight: "normal",
            fontSize: "small",
            fontWeight: "500",
            ...style
        }}>
            <span>{letter}</span>
        </div>
    }

    return (
        <img style={style} src={avatar} alt={alt} className={classes}/>
    );
}