import React from "react";
import M3_A_Text from "./text";
import M3_A_Avatar from "./avatar";
import M3_A_Icon from "./icon";
import {m3_adjust_size} from "../utilities";
import M3_A_Color from "./color";

/*
* This is the InlineEntity atom
 */

const _props = {
    avatar: {
        type: "string",
        required: false
    },
    icon: {
        type: "string",
        required: false
    },
    children: {
        type: "string",
        required: true
    },
    size: {
        type: "string",
        required: false,
        default: "sm",
        options: ["xs", "sm", "base", "lg", "xl"]
    },
    is_interactive: {
        type: "boolean",
        required: false,
        default: false
    }
};

const height_size_map = {
    "xs": "h-5",
    "sm": "h-6",
    "base": "h-7",
    "lg": "h-8",
    "xl": "h-10"
};

const left_width_size_map = {
    "xs": "w-4",
    "sm": "w-5",
    "base": "w-6",
    "lg": "w-8",
    "xl": "w-10"
};

const gap_map = {
    "xs": "gap-1",
    "sm": "gap-1.5",
    "base": "gap-2",
    "lg": "gap-2.5"
};

export default function M3_A_InlineEntity({
                                              compact,
                                              is_interactive,
                                              show_border,
                                              label_sx,
                                              sx,
                                              onClick,
                                              avatar,
                                              truncate,
                                              letter,
                                              color,
    auto_height,
                                              narrow,
                                              icon,
                                              icon_color,
                                              children,
                                              token_sx,
                                              text_color = "",
                                              color_shape,
                                              size = "sm"
                                          }) {
    let classes = `inline-flex ${gap_map[size]} border ${narrow ? "" : compact ? "pr-1.5 pl-0.5" : "pr-2 pl-1"} rounded-lg items-center`,
        style = {}, text_classes = '';

    if(auto_height) {
        classes += " h-auto";
    } else {
        classes += ` ${height_size_map[size]} `;
    }

    if (show_border) {
        classes += " border-gray-200";
    } else {
        classes += " border-transparent";
    }

    if (is_interactive) {
        classes += " transition-colors hover:bg-gray-200 cursor-pointer";
    }

    if (sx) {
        style = sx;
    }

    const sized_down = m3_adjust_size(size, 0);
    const two_sizes_down = m3_adjust_size(size, -2);

    let left_width = "";

    if(!compact) {
        left_width = left_width_size_map[size];
    }

    let span_props = {
        className: classes,
        style: style
    };

    if (onClick) {
        span_props.onClick = onClick;
    }

    return (
        <span {...span_props}>
            {(avatar || letter) &&
                <span className={`flex-none ${left_width}`}><M3_A_Avatar sx={token_sx} letter={letter} size={sized_down}
                                                                         avatar={avatar}/></span>}
            {color && <span className={`flex-none inline-flex justify-center ${left_width} px-0.5`}><M3_A_Color sx={token_sx} shape={color_shape}
                                                                                     size={size}
                                                                                     color={color}/></span>}
            {icon && <span className={`flex-none inline-flex justify-center ${left_width} px-0.5`}>
                <M3_A_Icon sx={token_sx} color={icon_color} size={two_sizes_down}>
                {icon}
            </M3_A_Icon>
            </span>}
            {children && <M3_A_Text className={text_classes} truncate={truncate} sx={label_sx} color={text_color}
                                    leading="leading-normal"
                                    weight="font-medium" size={size}>
                {children}
            </M3_A_Text>}
            </span>
    );
}