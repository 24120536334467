import {authFetch} from "../network";

export async function api_addNote(community, pl) {
    if (!pl.scope || !pl.scope_id) {
        alert("Please select a scope and scope id");
        return;
    }
    if (!pl.raw || !pl.plain_text) {
        alert("Please enter some text");
        return;
    }
    return await new Promise((resolve, reject) => {
        const res = (r) => {
            resolve(r);
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,

            raw: pl.raw,
            plain_text: pl.plain_text,

            scope: pl.scope || "member",
            scope_id: pl.scope_id || ""
        };
        authFetch("/notes/add", res, res, "POST", {payload});
    })
}

export async function api_editNote(community, pl) {
    if (!pl.id) {
        alert("Please select a note");
        return;
    }
    return await new Promise((resolve, reject) => {
        const res = (r) => {
            resolve(r);
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,

            raw: pl.raw,
            plain_text: pl.plain_text,

            id: pl.id
        };
        authFetch("/notes/edit", res, res, "POST", {payload});
    })
}

export async function api_deleteNote(community, pl) {
    if (!pl.id) {
        alert("Please select a note");
        return;
    }
    return await new Promise((resolve, reject) => {
        const res = (r) => {
            resolve(r);
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,

            id: pl.id
        };
        authFetch("/notes/delete", res, res, "POST", {payload});
    })
}