import React from 'react';
import M3_A_Text from "../atoms/text";
import M3_A_Icon from "../atoms/icon";
import {m3_adjust_size} from "../utilities";
import styled from "styled-components";

/*
* This is the FilterBar component
 */

const _props = {
    items: {
        type: "array",
        required: true,
        children: {
            type: "object",
            required: true,
            children: {
                id: {
                    type: "string",
                    required: true
                },
                label: {
                    type: "string",
                    required: true
                },
                icon: {
                    type: "node",
                    required: false
                }
            }
        }
    }

};

const item_classes_base_map = {
'default': "border rounded-lg min-w-[4.5rem] px-2.5",
    'underline': "border-b"
}

// ${variant}_${active}:
const item_classes_status_map = {
    'default_true': "border-blue-800 bg-blue-800 text-white font-semibold",
    'default_false': "border-gray-200",

    'underline_true': "border-b-2 border-blue-800 text-blue-800 font-semibold",
    'underline_false': "border-b-2 border-transparent",
};

const height_map = {
    "xs": "h-7",
    "sm": "h-8",
    "base": "h-9",
    "lg": "h-10",
}

function Item({label, variant, size, icon, onClick, active, icon_size, text_size}) {
    let classes = "flex-none text-center  inline-flex justify-center whitespace-nowrap items-center  cursor-pointer";
    classes += ` ${item_classes_base_map[variant]}`;
    classes += ` ${item_classes_status_map[`${variant}_${active}`]}`;
    classes += ` ${height_map[size]}`;

    return <div onClick={onClick} className={`gap-1 ${classes}`}>
        {icon && <M3_A_Icon size={icon_size}>{icon}</M3_A_Icon>}
        <M3_A_Text bold_offset size={text_size}>{label}</M3_A_Text>
    </div>
}

const wrapper_classes_base_map = {
    'default': 'gap-2',
    'underline': 'gap-3'
};

const Wrapper = ({x_padding,variant,children}) => {
    const classes = wrapper_classes_base_map[variant];
    return <div
        className={`${classes} text-gray-700 font-normal flex flex-nowrap overflow-x-auto hide-scrollbar ${x_padding}`}>
        {children}
    </div>
}

export default function M3_C_FilterBar({
                                           items = [],
                                           x_padding = "px-4",
                                           variant = "default",
                                           onClick,
                                           active_id = "",
                                           size = "sm"
                                       }) {
    const [_active_id, setActiveId] = React.useState(active_id || items[0]?.id || "");
    const text_size = m3_adjust_size(size, 0);
    const icon_size = m3_adjust_size(size, -1);

    React.useEffect(() => {
        if (onClick) {
            onClick(_active_id);
        }
    }, [_active_id])

    if (!items) {
        return null;
    }

    return <Wrapper variant={variant} x_padding={x_padding}>
        {items.map((item, index) => {
            const item_id = `${item.id || index}`;
            return <Item key={item_id} variant={variant} size={size} onClick={e => setActiveId(item_id)} active={_active_id === item_id}
                         text_size={text_size} icon_size={icon_size} {...item} />
        })}
    </Wrapper>;
}