import React, {useState} from "react"
import SemanticWelcome from "../../../m3/_legacy_components/semantic-welcome";
import QuickActions from "../../../m3/_legacy_components/quick-actions";
import {
    CommunityIndexPage, PageLayoutBlock, PageLayoutSection, PageLayoutWrapper
} from "../../../m3/_legacy_components/app-frame/layouts";
import {useCommunity} from "../../../config/community";
import {PlusCircleIcon, UserCircleIcon} from "@heroicons/react/24/outline";
import {useNavigate} from "react-router-dom";
import {GuessMembersGame} from "../../widgets/guess-members";
import {Forum} from "../../../features/forum/forum";
import {CompleteYourProfile} from "./complete-your-profile";
import {MemberBirthdaysWidget} from "../../widgets/birthdays";
import {setDocumentTitle} from "../../../m3/utilities/set-document-title";
import Button from "../../../m3/_legacy_components/button";
import {externalAuthFetch} from "../../../../api/network";
import GettingStarted from "../../../m3/_legacy_components/getting-started";
import {getStartedMemberManifest} from "../../../m3/_legacy_components/getting-started/member-manifest";
import {useIsMobile} from "../../../m3/hooks/is-mobile";
import {m3_icon_map} from "../../../m3/icons/icon-map";
import {buildImageUrl} from "../../../../common/utilities/images";

function generateQuickActions(navigate, community) {
    let a = [

        {
            label: "Edit Profile",
            icon: <UserCircleIcon/>,
            priority: 1,
            onClick: () => {
                navigate(`/c/${community.domain}/member/${community.member.handle}?edit`)
            }
        }
    ];

    if (community.member_access.add_members) {
        a.push({
            label: "New Member",
            icon: <PlusCircleIcon/>,
            priority: 1,
            onClick: () => {
                navigate(`/c/${community.domain}/members?action=create`)
            }
        });
        a.push({
            label: "Create Position",
            icon: <PlusCircleIcon/>,
            priority: 1,
            onClick: () => {
                navigate(`/c/${community.domain}/leaders?action=create`)
            }
        });
        a.push({
            label: "New Space",
            icon: <PlusCircleIcon/>,
            priority: 1,
            onClick: () => {
                navigate(`/c/${community.domain}/spaces?action=create`)
            }
        });
    }


    if (community.flags.is_admin) {
        /*
        a.push({
            label: "Recently Logged In",
            icon: <UserGroupIcon/>,
            priority: 2,
            onClick: () => {
                navigate(`/c/${community.domain}/members?goBack=1&sort=[["last_sign_in","desc"]/]`)
            }
        });

         */
    }

    /*
    community.shortcuts.forEach((sc, index) => {
        if (index < 2) {
            a.push({
                label: `${sc.label} Members`,
                icon: <UserGroupIcon/>,
                priority: 2,
                onClick: () => {
                    navigate(`/c/${community.domain}/members?goBack=1&collection=group_${sc.id.split('__')[1]}`)
                }
            })
        }
    })

    community.member_types.forEach((mt, ind) => {
        a.push({
            label: `All ${mt.plural}`,
            icon: <UserIcon/>,
            priority: 2,
            onClick: () => {
                navigate(`/c/${community.domain}/members?goBack=1,filters=[["member_type","in","${mt.id}"]/]`)
            }
        })
    })

     */

    return a;
}

function TestModule({member_id, community_uid, user_uid}) {
    const [state, setState] = useState('')

    function action() {
        console.log('action')
        setState('loading')

        const body = {
            community_uid,
            member_id,
            user_uid
        };

        console.log('body', body)

        const res = (resp) => {
            console.log('resp', resp)
            setState('done')
            alert('done')
        };

        externalAuthFetch("/generate-calendar-token", res, res, "POST", body)
    }

    return <div>
        <Button loading={state === 'loading'} text="Go" onClick={action}/>
    </div>
}

export default function HomePage() {
    const navigate = useNavigate();
    const community = useCommunity();
    const is_mobile = useIsMobile();
    const [active, setActive] = useState('Latest');
    setDocumentTitle(`Home`, `${community.profile.name}`);
    const items = generateQuickActions(navigate, community);

    /*
        <CompleteYourProfile />
       <WidgetEventsSidebar />
     */

    const body = <>
        <PageLayoutSection divider={false}>
            <PageLayoutBlock>
                <SemanticWelcome/>
            </PageLayoutBlock>
        </PageLayoutSection>
        <PageLayoutSection divider={false}>
            <PageLayoutBlock>
                <CompleteYourProfile/>
            </PageLayoutBlock>
        </PageLayoutSection>
        <PageLayoutSection divider={false} padding_x="">
            <PageLayoutBlock>
                <Forum show_title show_create={true} onChangeTab={nt => setActive(nt)}/>
            </PageLayoutBlock>
        </PageLayoutSection>
    </>;

    let header = null, sidebar = null

    if (is_mobile) {
        header = {
            title: `${community?.profile?.name}`,
            actions: [
                {
                    type: "icon",
                    show_update: community?.unread > 0,
                    update_border_color: community.active_theme?.background,
                    icon: m3_icon_map.outlines.notifications,
                    onClick: () => navigate(`/c/${community.domain}/notifications`)
                }
            ]
        };
        if(community?.profile?.profile_picture) {
            header.icon = {
                size: "h-7 w-7",
                avatar: buildImageUrl(community?.profile?.profile_picture)
            };
        }
    } else {
        sidebar = <PageLayoutWrapper sticky>
            <PageLayoutSection divider={false} gap="gap-6">
                <PageLayoutBlock>
                    <QuickActions show_title={false} minimal items={items}/>
                </PageLayoutBlock>
                <PageLayoutBlock>
                    <GuessMembersGame/>
                </PageLayoutBlock>
                <PageLayoutBlock>
                    <MemberBirthdaysWidget/>
                </PageLayoutBlock>
            </PageLayoutSection>
        </PageLayoutWrapper>;
    }

    return <CommunityIndexPage sidebar={sidebar} header={header}>
        {body}
    </CommunityIndexPage>;
};