import React from "react";
import {activity_types} from "../../../m3/_legacy_components/admin-activity/types";
import {hydrateDynamicText, renderDynamicText} from "../../../m3/_legacy_components/admin-activity/item";
import DataWrapper from "../../../m3/_legacy_components/data-wrapper";

import {DEFAULT_USER_PHOTO} from "../../../config/community";
import {Avatar} from "../../../m3/_legacy_components/avatar";
import {utils_dates_format} from "../../../../common/utilities/dates";

function Contents({data={}, parent_data={}, show_time, ts, msg}) {
    const final = hydrateDynamicText(msg, {...parent_data,member_name:data.name||""});
    const f_arr = final.split(/##/g);
    const text = renderDynamicText(f_arr, {});
    return <div className="flex space-x-2 items-baseline">
        <div className="text-gray-500 flex-none w-14">{utils_dates_format(ts).short_date}</div>
        <div className="text-gray-800 flex-grow">{text}</div>
    </div>
}

function Item({type, ts, connector, __date, show_time, scope_id, cid, data, message_key="message"}) {
    const activity_info = activity_types[type];

    if (!activity_info) {
        console.error('missing info', type)
        return null;
    }

    return <DataWrapper id={scope_id} type={`community_members.${cid}.members`}>
        <Contents show_time={show_time} ts={ts} parent_data={data} msg={activity_info[message_key]}/>
    </DataWrapper>;
}


function Section({children, title = "New Members"}) {
    return <div className="pb-6">
        <div>
            <div className="text-base font-bold text-gray-800">{title}</div>
        </div>
        <div className="space-y-1.5 pt-1 text-sm">
            {children}
        </div>
    </div>
}

const event_metadata = {
    "new-member-added": {
        prio: 0,
        title: "New Member Added"
    },
    "changed-member-type": {
        prio: 1,
        title: "New Membership Level"
    },
    "email-changed": {
        prio: 2,
        title: "Email Changed"
    },
    "phone-changed": {
        prio: 3,
        title: "Phone Number Changed"
    },
    "bio-changed": {
        prio: 3,
        title: "Bio Changed"
    },
    "location-changed": {
        prio: 3,
        title: "Location Changed"
    },
    "occupation-changed": {
        prio: 3,
        title: "Occupation Changed"
    },
    'role-change': {
        prio: 1,
        title: "Role Changed"
    },
    'group-created': {
        prio: 4,
        title: "Group Created"
    },
    'position-created': {
        prio: 4,
        title: "Position Created"
    }
};

function buildSections(data) {
    let a = [];

    data.forEach(event => {
        const type_index = a.findIndex(b => `${event.type}` === b.type);
        const event_info = event_metadata[event.type];
        if (event_info) {
            if (type_index === -1) {
                a.push({
                    title: event_info.title,
                    type: event.type,
                    events: [
                        event
                    ],
                    sort: event_info.prio
                });
            } else {
                a[type_index].events.push(event);
            }
        } else {
            console.error("NOT SUPPORTED", event.type, event_info)
        }
    })

    return a.sort((a, b) => a.sort > b.sort ? 1 : -1);
}

export function UpdatesTableView({data, community}) {

    const s = buildSections(data);

    const total_count = data.length;

    return <div className="px-4 pb-3">
        <div className={total_count<16?"":`catch-up-table-view`}>
            {s.map((sec)=>{
                return <Section key={sec.title} title={sec.title}>
                    {sec.events.map(evt=> {
                        return <Item cid={community.uid} key={evt.id} message_key={'catch_up'} {...evt} />
                    })}
                </Section>
            })}

        </div>
    </div>
}