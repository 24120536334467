import {convertToRaw, EditorState} from "draft-js";
import {utils_dates_format} from "../../../../common/utilities/dates";

function getDateMinusDays(days = 0) {
    let date = new Date();
    date.setDate(date.getDate() - days);
    return date;
}

const buildForum = () => {

    return {
        topics: [
            {
                name: "Announcements",
                id: "1",
            },
            {
                name: "Newsletter",
                id: "2",
            },
            {
                name: "Discussions",
                id: "3",
            }
        ],
        latest: [
            {
                raw_content: convertToRaw(EditorState.createWithText("Hello fellow artists!\nI wanted to remind everyone that our next group exhibition is coming up in just a few weeks, and I encourage all of you to submit your best work to showcase your talents.").getCurrentContent()),
                pinned: true,
                subject: "Summer Activities Reminder",
                layout: 'standard',
                author_name: "Anne Marie",
                author_image: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2FW6XNNEI6DQ",
                topic: "Announcements",
                empty_body: false,
                has_image: true,
                sent_via_email: true,
                date_formatted: utils_dates_format(getDateMinusDays(1)).relative,
                reply_count: 41,
                repliers: [
                    {
                        id: "",
                    },
                    {
                        id: "",
                    },
                    {
                        id: "",
                    },
                    {
                        id: "",
                    },
                    {
                        id: "",
                    },
                    {
                        id: "",
                    }
                ],
                reactions: {
                    'bear': {
                        count: 55,
                        emoji: '🐻'
                    },
                    'grapes': {
                        count: 31,
                        you: true,
                        emoji: '🍇'
                    },
                    'surfer': {
                        count: 2,
                        emoji: '🏄'
                    }
                },
                reactions_counts: {
                    'bear': 55,
                    'grapes': 31,
                    'surfer': 2
                }
            },
            {
                raw_content: convertToRaw(EditorState.createWithText("Working from home can be a challenge, but with a few simple tweaks to your home office setup, you can optimize your space for maximum productivity.").getCurrentContent()),
                pinned: false,
                subject: "Optimizing your Home Office for Remote Work",
                layout: 'standard',
                author_name: "Danny Graham",
                author_image: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2F88A0MW0A3E",
                topic: "Discussions",
                has_image: true,
                date_formatted: utils_dates_format(getDateMinusDays(3)).relative,
                reply_count: 1,
                repliers: [
                    {
                        id: "",
                    }
                ]
            },
            {
                raw_content: convertToRaw(EditorState.createWithText("Hello everyone, my name is Jamie Smith and I'm excited to be a part of Artists Collective! I'm a freelance graphic designer based in Brooklyn, and I'm looking forward to connecting with other creatives and sharing ideas. I'm particularly interested in learning more about UI/UX design and exploring new tools and techniques for creating compelling visuals. In my free time, I love hiking and exploring new neighborhoods around the city. Looking forward to getting to know everyone!").getCurrentContent()),
                pinned: false,
                subject: "New Member Introduction",
                layout: 'standard',
                author_name: "Jamie Smith",
                author_image: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2F9AGAOE8G5P",
                topic: "Vorstellung",
                has_image: true,
                date_formatted: utils_dates_format(getDateMinusDays(10)).relative,
                reply_count: 0,
                images: [
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2F9AGAOE8G5P_medium?alt=media",
                    }
                ],
                repliers: [],
                reactions: {
                    'bear': {
                        count: 55,
                        emoji: '🐻'
                    },
                    'grapes': {
                        count: 31,
                        you: true,
                        emoji: '🍇'
                    },
                    'surfer': {
                        count: 2,
                        emoji: '🏄'
                    }
                },
                reactions_counts: {
                    'bear': 55,
                    'grapes': 31,
                    'surfer': 2
                }
            },
            {
                raw_content: convertToRaw(EditorState.createWithText("").getCurrentContent()),
                pinned: false,
                subject: "Gallery Opening Event",
                layout: 'standard',
                author_name: "Sam Sullivan",
                author_image: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2FXZ2JBKW1M7",
                topic: "Berichte",
                empty_body: true,
                has_image: true,
                date_formatted: utils_dates_format(getDateMinusDays(24)).relative,
                images: [
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/files%2FJ62oUDAvKbZFS91QiaTj%2Fposts%2FH5CVJ8XDPX_large.png?alt=media",
                        width: 308,
                        height: 460
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/files%2FJ62oUDAvKbZFS91QiaTj%2Fposts%2F3660WXIH9O_large.png?alt=media",
                        width: 518,
                        height: 502
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/files%2FJ62oUDAvKbZFS91QiaTj%2Fposts%2FU1OMKBEZP4_large.png?alt=media",
                        width: 500,
                        height: 364
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/files%2FJ62oUDAvKbZFS91QiaTj%2Fposts%2F8P280M653W_large.png?alt=media",
                        width: 518,
                        height: 374
                    }
                ]
            },
            {
                raw_content: convertToRaw(EditorState.createWithText("").getCurrentContent()),
                pinned: false,
                subject: "May Meeting Minutes",
                layout: 'standard',
                author_name: "Donna Bell",
                author_image: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2FJ5LOGR32D6",
                empty_body: true,
                topic: "Protokolle",
                has_image: true,
                date_formatted: utils_dates_format(getDateMinusDays(45)).relative,
                attachments: [
                    {
                        name: "Minutes May 2020.pdf",
                        type: "application/pdf",
                        url: "",
                        size: 4392340
                    }
                ]
            }
        ]
    };
}

const buildEvents = () => {

    const date_one = getDateMinusDays(-14);
    const date_two = getDateMinusDays(-41);
    const date_three = getDateMinusDays(-60);

    const date_one_short = utils_dates_format(date_one).short_date;
    const date_two_short = utils_dates_format(date_two).short_date;
    const date_three_short = utils_dates_format(date_three).short_date;

    return [
        {
            summary: "Evening Soirѐe",
            location: "Pier 59",
            datetime_string: utils_dates_format(date_one).full_date,
            _month_name: date_one_short.split(" ")[0],
            _day: date_one_short.split(" ")[1],
            attendee_count: 23,
            attendees: [
                {},
                {},
                {},
                {},
                {},
            ]
        },
        {
            summary: "Career Discussion Night",
            datetime_string: utils_dates_format(date_two).full_date,
            _month_name: date_two_short.split(" ")[0],
            _day: date_two_short.split(" ")[1],
            location: "Virtual",
            hangoutLink: "",
            attendee_count: 91,
            attendees: [
                {},
                {},
                {},
                {},
                {},
            ]
        },
        {
            summary: "Picnic in the Park",
            location: "Central Park",
            datetime_string: utils_dates_format(date_three).full_date,
            _month_name: date_three_short.split(" ")[0],
            _day: date_three_short.split(" ")[1],
            attendee_count: 0,
            attendees: []
        }
    ];
};

const buildPinboard = () => {

    return [
        {
            label: "Meet our Hub",
            type: "links",
            data: [
                {
                    name: "2023 Leaders",
                    image: "https://www.youtube.com/s/desktop/a2197efa/img/favicon_32x32.png",
                    external: true
                },
                {
                    name: "Member Guidelines",
                    image: "https://ssl.gstatic.com/docs/documents/images/kix-favicon7.ico",
                    external: true
                }
            ]
        },
        {
            label: "Social",
            type: "links",
            data: [
                {
                    name: "@ac-ny",
                    image: "https://instagram.com/static/images/ico/apple-touch-icon-76x76-precomposed.png/666282be8229.png",
                    external: true
                },
                {
                    name: "Our Twitter",
                    image: "https://abs.twimg.com/responsive-web/client-web/icon-ios.b1fc7278.png",
                    external: true
                },
                {
                    name: "Join WhatsApp",
                    image: "https://static.whatsapp.net/rsrc.php/v3/yP/r/rYZqPCBaG70.png",
                    external: true
                }
            ]
        }
    ];
}


const buildMedia = () => {

    return {
        albums: [
            {
                name: "Summer Party",
                description: "17",
                cover_image: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/files%2Fdemo%2Fimages%2F2378502OIR6K4LX2D_medium.png?alt=media"
            },
            {
                name: "Hamptons Getaway",
                description: "12",
                cover_image: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/files%2Fdemo%2Fimages%2F12310851MN0QFWUWQS_medium.png?alt=media"
            },
            {
                name: "New Member Dinner",
                description: "8",
                cover_image: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/files%2Fdemo%2Fimages%2F34556533D5JKDOHNT_medium.png?alt=media"
            },
            {
                name: "Anna's New Gallery",
                description: "5",
                cover_image: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/files%2Fdemo%2Fimages%2F20907430FJ7FDKAOM_medium.png?alt=media"
            }
        ],
        recent: [
            {
                type: "image/jpeg",
                url: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/files%2Fdemo%2Fimages%2F11948094CYBGZ2TJFM_medium.png?alt=media"
            },
            {
                type: "image/jpeg",
                url: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/files%2Fdemo%2Fimages%2F5250510MI7AQ2QD6T_medium.png?alt=media"
            },
            {
                type: "image/jpeg",
                url: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/files%2Fdemo%2Fimages%2F1147883ZWMF1DRNY2_medium.png?alt=media"
            },
            {
                preview_frame_url: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/files%2Fdemo%2Fimages%2F2655116P6K8ZQDBN8_medium.png?alt=media",
                type: "video/mp4",
                url: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/static%2Fsample-10s.mp4?alt=media"
            },
            {
                type: "image/jpeg",
                url: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/files%2Fdemo%2Fimages%2F34556533D5JKDOHNT_medium.png?alt=media"
            },
            {
                type: "image/jpeg",
                url: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/files%2Fdemo%2Fimages%2F2378502OIR6K4LX2D_medium.png?alt=media"
            },
            {
                type: "image/jpeg",
                url: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/files%2Fdemo%2Fimages%2F12310851MN0QFWUWQS_medium.png?alt=media"
            },
        ]
    };
}

const buildDrive = () => {

    return {
        folders: [],
        files: []
    };
}

const buildAbout = () => {

    return {
        name: "New York Hub",
        emoji: "",
        purpose: "This is the description of the group"
    };
}

export const group_demo_data = {
    about: buildAbout(),
    forum: buildForum(),
    events: buildEvents(),
    pinboard: buildPinboard(),
    media: buildMedia(),
    drive: buildDrive()
}