import React, {useState} from "react";
import {ManageUnaversePage} from "./pages/unaverse";
import {ManageAnalyticsPage} from "./pages/analytics";
import {ManageRegistrationsPage} from "./pages/registrations";
import {Outlet, Route, Routes, useNavigate} from "react-router-dom";
import {ManageUsersPage} from "./pages/users";
import {ManageCommunitiesPage} from "./pages/communities";
import {ManageUserProfile} from "./pages/user-profile";
import {ManageCommunityProfile} from "./pages/community-profile";
import {ManageMigrationsPage} from "./pages/migrations";
import {ManageBetaProgram} from "./pages/beta-program";
import {ManageTaxonomiesPage} from "./pages/taxonomies";
import {ManageToolsPage} from "./pages/tools";

function MenuItem({text,active,onClick}) {
    return <div onClick={onClick} className="relative group cursor-pointer" style={{height: '60px'}}>
        <div className={`flex items-center h-full font-medium justify-center ${active?"text-blue-700":"text-gray-500 hover:text-gray-800"}`}>
            {text}
        </div>
    </div>
}

const items = [
    "Content", "Users", "Communities", "Beta", "Migrations", "Analytics", "Taxonomies", "Tools"
];

function CustomMenu({page,selectPage=()=>{}}) {
    return <div className="flex space-x-6">
        {items.map(it => <MenuItem onClick={()=>selectPage(it)} active={page===it} text={it} key={it}/>)}
    </div>
}

const count_object = {
    'users': {
        total: 3912
    },
    'communities': {
        total: 21,
        profiles: 492
    },
    'locations': {
        continents: 42,
        states: 42,
        cities: 42,
        counties: 42,
        countries: 42,
        tags: 23
    },
    'associations': {
        tags: 41,
        categories: 41,
        types: 4,
        networks: 43
    }
};

function Wrapper({navigate,renderPage,page,setPage}) {
    return <div>
        <CustomMenu page={page} selectPage={(pg)=>setPage(pg)} />
        <div>
            {renderPage()}
            <Outlet />
        </div>
    </div>
}

export function ManagePage({}) {
    const navigate = useNavigate();
    const [page,setPage] = useState(items[0]);

    function renderPage() {
        if(page==='Content') {
            return <ManageUnaversePage/>
        } else if(page==='Users') {
            return <ManageUsersPage/>
        } else if(page==='Communities') {
            return <ManageCommunitiesPage/>
        } else if(page==='Registrations') {
            return <ManageRegistrationsPage/>
        } else if(page==='Migrations') {
            return <ManageMigrationsPage/>
        } else if(page==='Analytics') {
            return <ManageAnalyticsPage/>
        } else if(page==='Beta') {
            return <ManageBetaProgram />
        } else if(page==='Taxonomies') {
            return <ManageTaxonomiesPage />
        } else if(page==='Tools') {
            return <ManageToolsPage />
        } else {
            return <div>
                dunno
            </div>
        }

    }

    return <div className="mx-auto max-w-6xl px-8 pt-20" id="main-frame">
        <Routes>
            <Route path="*" element={<Wrapper navigate={navigate} page={page} renderPage={renderPage} setPage={setPage} />}>
                <Route path="user/:id" element={<ManageUserProfile />} />
                <Route path="community/:id" element={<ManageCommunityProfile />} />
            </Route>
        </Routes>
    </div>
}