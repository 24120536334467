import React from "react";
import styled from "styled-components";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import M3_A_Icon from "./icon";
import {M3_A_InputWrapper, m3_input_height_map} from "./input";
import {m3_icon_map} from "../icons/icon-map";

/*
* This is the Select atom
 */

const _props = {};

const StyledSelect = styled.select`
    appearance: none;
    border-radius: inherit;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #d4d4d8;
    color: currentColor;
    font-size: inherit;
    outline: none;
    width: 100%;


    background-color: inherit;
    margin-top: 1px;
    margin-left: 1px;
    margin-bottom: 1px;

    &:disabled {
        background-color: #efefef;
        border-color: #d4d4d8;
    }

    &:hover {
        border-color: #b3b3b3;
    }

    &:focus {
        box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(58, 151, 212, 0.36), 0 0 0 0 transparent, 0 0 0 1px rgba(60, 66, 87, 0.16), 0 0 0 0 transparent, 0 0 0 0 transparent, 0 0 0 0 transparent;
        border-color: #659adf;
    }
`;

const ArrowContainer = styled.div`
    pointer-events: none;
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
`;

const LIContainer = styled.div`
    position: absolute;
    left: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
`;


export default function M3_A_Select({
                                        ref,
                                        onChange,
                                        value,
                                        options,
                                        size = "base",
                                        required,
                                        disabled,
                                        validation_status,
                                        placeholder,
                                        ...rest
                                    }) {
    let classes = `${rest.leading_indicator ? "pl-8" : "pl-2"} pr-8`;

    if (size) {
        classes += ` ${m3_input_height_map[size]}`;
        classes += ` text-${size}`;
    }

    return (
        <M3_A_InputWrapper size="base">
            {rest.leading_indicator && <LIContainer>
                <M3_A_Icon icon={rest.leading_indicator.icon} size="xs" color="text-gray-600"/>
            </LIContainer>}
            <StyledSelect
                className={classes}
                ref={ref}
                required={required}
                disabled={disabled}
                aria-invalid={validation_status === 'error' ? 'true' : 'false'}
                data-hasplaceholder={Boolean(placeholder)}
                defaultValue={placeholder ?? undefined}
                value={value}
                onChange={onChange}
                {...rest}
            >
                {placeholder && (
                    <option value="" disabled={required} hidden={required}>
                        {placeholder}
                    </option>
                )}
                {options.map((option, index) => {
                    return (
                        <option key={index} disabled={option.disabled} value={option.value}>{option.label}</option>
                    );
                })}
            </StyledSelect>
            <ArrowContainer>
                <M3_A_Icon icon={<ChevronDownIcon/>} size="xs" color="text-gray-600"/>
            </ArrowContainer>
        </M3_A_InputWrapper>
    );
}