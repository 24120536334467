import React from "react";
import {
    ArchiveBoxIcon,
    ArrowLeftIcon, ArrowPathIcon, ArrowRightIcon, ArrowRightOnRectangleIcon,
    AtSymbolIcon, Bars3CenterLeftIcon,
    BriefcaseIcon, BuildingOfficeIcon,
    CakeIcon,
    CalendarIcon,
    ChartPieIcon, CheckCircleIcon,
    CheckIcon,
    ChevronDoubleUpIcon, ClipboardDocumentCheckIcon, CloudArrowUpIcon, CogIcon, CursorArrowRippleIcon, EnvelopeIcon,
    GlobeAltIcon, HeartIcon,
    HomeIcon, InboxIcon, InformationCircleIcon,
    KeyIcon,
    LockClosedIcon,
    LockOpenIcon,
    MapIcon, MapPinIcon,
    PaperAirplaneIcon,
    PencilIcon,
    PhoneIcon, PhotoIcon, PlusCircleIcon,
    PlusIcon,
    TagIcon,
    UserIcon,
    UsersIcon, XCircleIcon, XMarkIcon
} from "@heroicons/react/20/solid";
import RoleIcon from "../../icons/_legacy/custom/RoleIcon";

// types / colors
// admin = purple
// email = light blue
// owner = orange
// gray = system + smaller
// teal = role
// pale blue = moderator
// secondary = email, phone updated or set
// important update (address) = light orange f47f5a
// green = engagement

export const activity_types_ref = {
    'system': {
        color: 'bg-gray-600',
        size: '4'
    },
    'email': {
        color: 'bg-secondary-300',
        size: '5'
    },
    'success': {
        color: 'bg-success-600',
        size: '5'
    },
    'danger': {
        color: 'bg-danger-600',
        size: '5'
    },
    'admin': {
        color: 'bg-purple-600',
        size: '5'
    },
    'note': {
        color: 'bg-yellow-400',
        size: '5'
    },
    'moderator': {
        color: 'bg-blue-700',
        size: '5'
    },
    'owner': {
        color: 'bg-orange-800',
        size: '5'
    },
    'role': {
        color: 'bg-teal-600',
        size: '6'
    },
    'unaty': {
        color: 'bg-orange-500',
        size: '5'
    },
    'primary': {
        color: 'bg-blue-500',
        size: '5'
    },
    'secondary': {
        color: 'bg-primary-400',
        size: '5'
    },
    'engagement': {
        color: 'bg-success-400',
        size: '5'
    }
};

export const activity_types = {

    //// VERIFICATION
    'email-verification-success': {
        message: 'Unaty verified the **{email}** is real and deliverable',
        icon: <CheckIcon/>,
        type: 'unaty'
    },
    'email-verification-failed': {
        message: 'Unaty was not able to verify **{email}** is real and deliverable',
        icon: <XMarkIcon/>,
        type: 'danger'
    },
    'phone-verification-success': {
        message: 'Unaty verified **{phone}** is real and working',
        icon: <CheckIcon/>,
        type: 'unaty'
    },
    'phone-verification-failed': {
        message: 'Unaty was not able to verify **{phone}** is real and working',
        icon: <XMarkIcon/>,
        type: 'danger'
    },
    'address-verification-success': {
        message: 'Unaty verified **{address}** is real',
        icon: <CheckIcon/>,
        type: 'unaty'
    },
    'address-verification-failed': {
        message: 'Unaty was not able to verify **{address}** is real',
        icon: <XMarkIcon/>,
        type: 'danger'
    },


    //// SEGMENTS
    'added-to-segment': {
        message: '**{member_name}** was added to [{name}](segment={id})',
        icon: <ChartPieIcon/>,
        type: 'system'
    },
    'removed-from-segment': {
        message: '**{member_name}** was removed from [{name}](segment={id})',
        icon: <ChartPieIcon/>,
        type: 'system'
    },
    'segment-added': {
        added: true,
        message: '**{admin_name}** added {name}',
        icon: <PlusIcon/>,
        type: 'admin'
    },

    //// ERRORS
    'email-delivery-fail': {
        message: 'Email delivery failed to **{email}**',
        icon: <XCircleIcon/>,
        type: 'danger'
    },

    //// FLOWS
    'flow-complete': {
        message: 'Completed flow: **{name}**',
        icon: <CheckCircleIcon/>,
        type: 'engagement'
    },


    //// POLICIES
    'policy-accepted': {
        message: 'Accepted policy **{name}** (version {version})',
        icon: <ClipboardDocumentCheckIcon/>,
        type: 'engagement'
    },

    //// OPT-IN
    'opted-in': {
        message : 'Opted in for **{label}**',
        icon: <CheckCircleIcon/>,
        type: 'engagement'
    },


    //// COMMUNITIES
    'create-community': {
        added: true,
        message: '[{member_name}](member={id}) ({meta}) created **{name}**',
        icon: <PlusIcon/>,
        type: 'owner'
    },
    'renamed-community': {
        message: '{admin_name} renamed **{name}** --{old}--',
        icon: <PencilIcon/>,
        type: 'owner'
    },


    //// ROLES
    'role-added': {
        added: true,
        message: '**{admin_name}** added {name}',
        icon: <PlusIcon/>,
        type: 'admin'
    },
    'role-renamed': {
        added: true,
        message: '**{admin_name}** renamed {name} --{old}--',
        icon: <PlusIcon/>,
        type: 'admin'
    },
    'position-change-1': {
        added: true,
        message: '**{member_name}** is assigned to the position **[{name}](role={id})**',
        catch_up: '**{member_name}** is assigned to the position **[{name}](role={id})**',
        icon: <RoleIcon/>,
        type: 'role'
    },
    'position-change-2': {
        added: true,
        message: '**{old_assignee_name}** is no longer **[{name}](role={id})**',
        catch_up: '**{old_assignee_name}** is no longer **[{name}](role={id})**',
        icon: <RoleIcon/>,
        type: 'role'
    },
    'position-change-3': {
        added: true,
        message: '{new_assignee_name} replaced {old_assignee_name} as the **[{name}](role={id})**',
        catch_up: '**{new_assignee_name}** replaced {old_assignee_name} as [{name}](role={id})',
        icon: <RoleIcon/>,
        type: 'role'
    },

    'position-created': {
        added: true,
        message: '{member_name} created a new position **[{name}](role={id})**',
        catch_up: '{member_name} created a new position **[{name}](role={id})**',
        icon: <RoleIcon/>,
        type: 'role'
    },

    'group-created': {
        added: true,
        message: '{member_name} created a new group **[{name}](group={id})**',
        catch_up: '{member_name} created a new group **[{name}](group={id})**',
        icon: <RoleIcon/>,
        type: 'group'
    },

    'member-assigned-to-role': {
        added: true,
        message: '**{member_name}** was assigned to the position [{name}](role={id})',
        icon: <RoleIcon/>,
        type: 'role'
    },
    'member-unassigned-from-role': {
        added: true,
        message: 'No longer assigned to the position [{name}](role={id})',
        icon: <RoleIcon/>,
        type: 'role'
    },
    'assignee-set': {
        added: true,
        message: '[{member_name}](member={id}) was assigned to this position',
        icon: <RoleIcon/>,
        type: 'role'
    },
    'assignee-changed': {
        added: true,
        message: '[{member_name}](member={id}) replaced {old} as the assignee',
        icon: <RoleIcon/>,
        type: 'role'
    },
    'assignee-removed': {
        added: true,
        message: '[{member_name}](member={id}) is no longer assigned to this position',
        icon: <RoleIcon/>,
        type: 'role'
    },


    //// MEMBER TYPES
    'member_type-added': {
        added: true,
        message: '**{admin_name}** added {name}',
        icon: <PlusIcon/>,
        type: 'admin'
    },

    //// MEMBERSHIP STATUS
    'membership_status-change': {
        added: true,
        icon: <CheckCircleIcon />,
        type: 'admin',
        message: '**{admin_name}** changed membership status to ++{new}++ --{old}--',
    },

    //// ADD MEMBER
    'member-type-added': {
        added: true,
        message: '**{admin_name}** added {name}',
        icon: <UserIcon/>,
        type: 'admin'
    },
    'member-type-rename': {
        added: true,
        message: '{admin_name} renamed ++{name}++ --{old}--',
        icon: <PencilIcon/>,
        type: 'admin'
    },
    'member-added-through-import': {
        added: true,
        message: '{member_name} ({meta}) through an import by **{admin_name}**',
        icon: <CloudArrowUpIcon/>,
        type: 'admin'
    },
    'member-added-through-import-email-only': {
        added: true,
        message: '{meta} through an import by **{admin_name}**',
        icon: <CloudArrowUpIcon/>,
        type: 'admin'
    },
    'member-added-through-import-name-only': {
        added: true,
        message: '{member_name} through an import by **{admin_name}**',
        icon: <CloudArrowUpIcon/>,
        type: 'admin'
    },
    'member-added': {
        added: true,
        // Ramin Rente added member name (or email)
        message: '**{admin_name}** added {member_name} ({meta})',
        icon: <PlusIcon/>,
        type: 'admin'
    },
    'member-added-email-only': {
        added: true,
        message: '**{admin_name}** added {meta}',
        icon: <PlusIcon/>,
        type: 'admin'
    },
    'member-added-name-only': {
        added: true,
        message: '**{admin_name}** added {member_name}',
        icon: <PlusIcon/>,
        type: 'admin'
    },

    //// JOIN COMMUNITY
    'applied-to-join': {
        message: '**{member_name}** applied to join the community',
        icon: <InboxIcon/>,
        type: 'engagement'
    },
    'application-approved': {
        message: '**{admin_name}** approved the membership application',
        icon: <CheckIcon/>,
        type: 'success'
    },
    'application-autoapproved': {
        message: 'Membership application was automatically approved',
        icon: <CogIcon/>,
        type: 'success'
    },
    'application-denied': {
        message: '**{admin_name}** denied the membership application',
        icon: <XMarkIcon/>,
        type: 'danger'
    },

    //// ADDRESS
    'address-autocomplete': {
        added: true,
        message: 'Unaty autocompleted the address',
        icon: <MapPinIcon/>,
        type: 'primary'
    },
    'moved-country': {
        added: true,
        message: 'Country changed to ++{moved_to_city}, {moved_to_country}++ --{moved_from_city}, {moved_from_country}--',
        icon: <BuildingOfficeIcon/>,
        type: 'secondary'
    },
    'moved-city': {
        added: true,
        message: 'City changed to ++{moved_to_city}++ --{moved_from_city}--',
        icon: <BuildingOfficeIcon/>,
        type: 'secondary'
    },
    'set-country': {
        added: true,
        message: 'City changed to {moved_to_city}, {moved_to_country}',
        icon: <BuildingOfficeIcon/>,
        type: 'secondary'
    },
    'set-city': {
        added: true,
        message: 'City changed to {moved_to_city}',
        icon: <BuildingOfficeIcon/>,
        type: 'secondary'
    },
    'address-updated': {
        added: true,
        message: 'Full address updated to **{address}**',
        icon: <HomeIcon/>,
        type: 'primary'
    },


    //// PROFILE PICTURE
    'changed-profile-picture': {
        added: true,
        message: '**{member_name}** set a new profile picture',
        icon: <PhotoIcon/>,
        type: 'secondary'
    },
    'admin-changed-profile-picture': {
        added: true,
        message: '**{admin_name}** set a new profile picture',
        icon: <PhotoIcon/>,
        type: 'admin'
    },


    //// ACCOUNT EMAIL
    'account-email-added': {
        message: '**{email}** was added as the account email',
        icon: <AtSymbolIcon/>,
        type: 'primary'
    },
    'account-email-changed': {
        message: 'Account email changed to **{email}**',
        catch_up: `{member_name}'s email changed to **{email}**`,
        icon: <AtSymbolIcon/>,
        type: 'primary'
    },


    //// EMAILS
    'sent-email': {
        added: true,
        template: "email",
        icon: <EnvelopeIcon/>,
        type: 'email'
    },
    'sent-email_policy-update': {
        added: true,
        template: "email",
        icon: <EnvelopeIcon/>,
        type: 'email'
    },
    'sent-email_opt-in': {
        added: true,
        template: "email",
        icon: <EnvelopeIcon/>,
        type: 'email'
    },

    //// PHONE
    'phone-added': {
        added: true,
        message: '**{phone}** added as the phone number',
        icon: <PhoneIcon/>,
        type: 'primary'
    },
    'phone-removed': {
        added: true,
        message: '**{phone}** removed as the phone number',
        icon: <PhoneIcon/>,
        type: 'primary'
    },
    'phone-changed': {
        added: true,
        message: 'Phone number changed to **{phone}**',
        catch_up: `{member_name}'s phone changed to **{phone}**`,
        icon: <PhoneIcon/>,
        type: 'primary'
    },
    'email-changed': {
        added: true,
        message: 'Email changed to **{email}**',
        catch_up: `{member_name}'s email changed to **{email}**`,
        icon: <EnvelopeIcon/>,
        type: 'primary'
    },
    'bio-changed': {
        added: true,
        message: 'Bio changed to **{bio}**',
        catch_up: `{member_name}'s bio changed to **{bio}**`,
        icon: <Bars3CenterLeftIcon/>,
        type: 'primary'
    },
    'location-changed': {
        added: true,
        message: 'Location changed to **{location}**',
        catch_up: `{member_name}'s location changed to **{location}**`,
        icon: <MapPinIcon/>,
        type: 'primary'
    },
    'occupation-changed': {
        added: true,
        message: 'Occupation changed to **{occupation}**',
        catch_up: `{member_name}'s occupation changed to **{occupation}**`,
        icon: <BriefcaseIcon/>,
        type: 'primary'
    },

    'phone-set-reachability': {
        added: true,
        message: 'Reachability updated for **{phone}**',
        icon: <PhoneIcon/>,
        type: 'primary'
    },


    //// TIMEZONE
    'timezone-changed': {
        added: true,
        message: 'Timezone changed to {timezone}',
        icon: <MapIcon/>,
        type: 'secondary'
    },
    'timezone-set': {
        added: true,
        message: 'Timezone set to {timezone}',
        icon: <MapIcon/>,
        type: 'secondary'
    },


    //// LANGUAGE
    'language-changed': {
        added: true,
        message: 'Language changed to {language}',
        icon: <GlobeAltIcon/>,
        type: 'secondary'
    },
    'language-set': {
        added: true,
        message: 'Language set to {language}',
        icon: <GlobeAltIcon/>,
        type: 'secondary'
    },


    //// ADMINS
    'member-added-as-admin': {
        added: true,
        message: 'Made community admin',
        icon: <ChevronDoubleUpIcon/>,
        type: 'admin'
    },
    'member-removed-as-admin': {
        added: true,
        message: 'Removed as community admin',
        icon: <ChevronDoubleUpIcon/>,
        type: 'admin'
    },


    //// OWNER
    'member-added-as-owner': {
        added: true,
        message: '**{member_name}** became the community owner',
        icon: <KeyIcon/>,
        type: 'owner'
    },
    'member-removed-as-owner': {
        message: '**{member_name}** is no longer the owner',
        icon: <KeyIcon/>,
        type: 'owner'
    },


    //// UPDATE PROFILE
    'changed-member-type': {
        added: true,
        message: '**{admin_name}** changed membership level to ++{new}++ --{old}--',
        catch_up: `{member_name}'s new membership level is ++{new}++ --{old}--`,
        icon: <UserIcon/>,
        type: 'admin'
    },
    'member-updated-profile': {
        added: true,
        message: 'Profile updated',
        icon: <UserIcon/>,
        type: 'engagement'
    },
    'updated-through-import': {
        added: true,
        message: 'Profile updated through import by **{admin_name}**',
        icon: <CloudArrowUpIcon/>,
        type: 'admin'
    },
    'admin-update-custom-field': {
        message: '**{admin_name}** changed {field} to ++{new}++ --{old}--',
        icon: <PencilIcon/>,
        type: 'admin'
    },
    'admin-set-custom-field': {
        message: '**{admin_name}** set {field} to ++{new}++',
        icon: <PencilIcon/>,
        type: 'admin'
    },
    'member-update-custom-field': {
        message: '**{admin_name}** changed {field} to ++{new}++ --{old}--',
        icon: <PencilIcon/>,
        type: 'engagement'
    },
    'member-set-custom-field': {
        message: '**{admin_name}** set {field} to ++{new}++',
        icon: <PencilIcon/>,
        type: 'engagement'
    },
    'member-updated-birthday': {
        added: true,
        message: 'Birthday set to {birthday}',
        icon: <CakeIcon/>,
        type: 'secondary'
    },
    'admin-updated-profile': {
        added: true,
        message: 'Profile updated by **{admin_name}**',
        icon: <UserIcon/>,
        type: 'admin'
    },
    'member-updated-join-date': {
        added: true,
        message: 'Join date set to **{join_date}**',
        icon: <CalendarIcon/>,
        type: 'secondary'
    },
    'member-changed-handle': {
        added: true,
        message: 'Handle changed to **@{handle}** --@{old_handle}--',
        icon: <AtSymbolIcon/>,
        type: 'primary'
    },


    //// ARCHIVED
    'member-archived': {
        added: true,
        message: '**{admin_name}** deactivated {member_name}',
        icon: <ArchiveBoxIcon/>,
        type: 'system'
    },
    'member-unarchived': {
        added: true,
        message: '**{admin_name}** reactivated {member_name}',
        icon: <ArchiveBoxIcon/>,
        type: 'system'
    },


    //// DATA SUGGESTIONS
    'new-data-suggestion': {
        note: 'This is for the member who received the data suggestion',
        added: true,
        message: '**{member_name}** made a data suggestion',
        icon: <InformationCircleIcon/>,
        type: 'engagement'
    },
    'made-data-suggestion': {
        added: true,
        message: 'Suggested a profile update for **{member_name}**',
        icon: <InformationCircleIcon/>,
        type: 'engagement'
    },

    //// SUSPENDED
    'member-suspended': {
        message: '**{admin_name}** suspended {member_name}',
        icon: <XCircleIcon/>,
        type: 'system'
    },
    'member-unsuspended': {
        message: '**{admin_name}** unsuspended {member_name}',
        icon: <XCircleIcon/>,
        type: 'system'
    },


    //// TAGS
    'member-tag-removed': {
        message: '**Tagname** removed',
        icon: <TagIcon/>,
        type: 'system'
    },
    'member-tag-added': {
        message: '**Tagname** added',
        icon: <TagIcon/>,
        type: 'system'
    },


    //// INVITES
    'followed-invite': {
        added: true,
        message: '{name} logged in after being invited by **{admin_name}**',
        icon: <CheckCircleIcon/>,
        type: 'engagement'
    },
    'sent-invite': {
        added: true,
        message: '**{admin_name}** sent an invite to {email}',
        icon: <PaperAirplaneIcon/>,
        type: 'email'
    },
    'opened-invite': {
        message: '{member_name} clicked on the invite link',
        icon: <CursorArrowRippleIcon/>,
        type: 'engagement'
    },


    //// NOTES
    'add-note': {
        added: true,
        message: '**{admin_name}** added a note',
        icon: <PencilIcon/>,
        type: 'note'
    },


    //// QUICK PROFILE UPDATE
    // track status below
    'sent-qpu-email': {
        message: '**{admin_name}** sent a quick profile update email',
        icon: <PaperAirplaneIcon/>,
        type: 'email'
    },
    'sent-data-check': {
        message: '**{admin_name}** sent a data check',
        icon: <CheckCircleIcon/>,
        type: 'email'
    },
    'follow-data-check': {
        message: '{name} completed a data check sent by **{admin_name}**',
        icon: <CheckCircleIcon/>,
        type: 'email'
    },


    //// LOGINS
    'logged-in-first-time': {
        added: true,
        message: '**{member_name}** logged in for the first time',
        icon: <ArrowRightOnRectangleIcon/>,
        type: 'engagement'
    },
    'logged-in-after-a-while': {
        added: true,
        message: '**{member_name}** logged in again after {meta}',
        icon: <ArrowRightOnRectangleIcon/>,
        type: 'engagement'
    },


    //// ONBOARDING
    'completed-onboarding': {
        added: true,
        message: '{member_name} completed the onboarding',
        icon: <PaperAirplaneIcon/>,
        type: 'engagement'
    },
    'skipped-onboarding': {
        added: true,
        message: '{member_name} skipped the onboarding',
        icon: <ArrowPathIcon/>,
        type: 'engagement'
    },


    //// INTERESTS
    'added-interest': {
        message: '**{member_name}** is interested in [{name}](interest={id})',
        icon: <HeartIcon/>,
        type: 'system'
    },
    'removed-interest': {
        message: '**{member_name}** is no longer interested in [{name}](interest={id})',
        icon: <HeartIcon/>,
        type: 'system'
    },
    'updated-interests': {
        message: '{member_name} updated their interests',
        icon: <HeartIcon/>,
        type: 'system'
    },


    //// POLICIES
    'member-accepted-policy': {
        message: '{member_name} accepted version {policy_version} of the {policy_name}',
        icon: <ClipboardDocumentCheckIcon/>,
        type: 'engagement'
    },
    'policy-added': {
        message: '{admin_name} created the new policy {name}',
        icon: <PlusIcon/>,
        type: 'admin'
    },
    'policy-updated': {
        message: '{admin_name} published version {meta}',
        icon: <CheckIcon/>,
        type: 'admin'
    },

    'new-member-added': {
        message: '**{member_name}** joined the community',
        catch_up: '**{member_name}** joined the community',
        icon: <CheckIcon/>,
        type: 'primary'
    },


    //// GROUPS
    'renamed-group': {
        message: 'Group renamed to **{group_name}**',
        icon: <PencilIcon/>,
        type: 'system'
    },
    'member-created-group': {
        message: '**{member_name}** created the group [{name}](group-{id})',
        icon: <PlusCircleIcon fill="#ffffff"/>,
        type: 'engagement'
    },
    'added-to-group': {
        message: 'Joined [{name}](group-{id})',
        icon: <ArrowRightIcon fill="#ffffff"/>,
        type: 'system'
    },
    'removed-from-group': {
        message: 'Left [{name}](group-{id})',
        icon: <ArrowRightIcon fill="#ffffff"/>,
        type: 'system'
    },


    //// GROUP MODERATORS
    'added-as-moderator': {
        message: '**{member_name}** was made moderator of [{name}](group-{id})',
        icon: <CheckCircleIcon fill="#ffffff"/>,
        type: 'moderator'
    },
    'removed-as-moderator': {
        message: '**{member_name}** is no longer a moderator of [{name}](group-{id})',
        icon: <CheckCircleIcon fill="#ffffff"/>,
        type: 'moderator'
    },
    'changed-group-moderators': {
        message: 'dynamic',
        icon: <CheckCircleIcon fill="#ffffff"/>,
        type: 'moderator'
    },


    //// GROUP MEMBERS
    // can have sub events like joined / left
    'changed-members': {
        message: 'dynamic',
        icon: <UsersIcon/>,
        type: 'system'
    },
    'joined-group': {
        message: '{member_name} joined [{name}](group-{id})',
        icon: <ArrowRightIcon/>,
        type: 'system'
    },
    'left-group': {
        message: '{member_name} left [{name}](group-{id})',
        icon: <ArrowLeftIcon/>,
        type: 'system'
    },


    //// GROUP EMAIL ACTIONS
    'added-email': {
        message: '**{group_email}** added as the group email',
        icon: <AtSymbolIcon/>,
        type: 'system'
    },
    'changed-email-who-can-send': {
        message: 'Now **{meta}** can send to the group email',
        icon: <AtSymbolIcon/>,
        type: 'system'
    },
    'changed-email-default-reply': {
        message: 'Group email default reply set to {email}',
        icon: <AtSymbolIcon/>,
        type: 'system'
    },
    'removed-email': {
        message: 'Group email removed',
        icon: <AtSymbolIcon/>,
        type: 'system'
    },
    'changed-email': {
        message: 'Group email changed to **{group_email}**',
        icon: <AtSymbolIcon/>,
        type: 'system'
    },


    //// GROUP PRIVACY
    'made-private': {
        message: 'Group made private',
        icon: <LockClosedIcon/>,
        type: 'system'
    },
    'made-public': {
        message: 'Group made public',
        icon: <LockOpenIcon/>,
        type: 'system'
    },
    'converted-to-team': {
        message: 'Group converted to a team',
        icon: <BriefcaseIcon/>,
        type: 'system'
    },
    'converted-to-group': {
        message: 'Team converted to a group',
        icon: <UsersIcon/>,
        type: 'system'
    },
};