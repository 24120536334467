import React, {useState, useEffect} from "react"
import './styles.css';
import EmptyState from "../empty-state";
import {collection, getDocs, limit, query, where} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {useCommunity} from "../../../config/community";
import {ChevronDownIcon, FolderIcon, ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/20/solid";
import {InlineLoader} from "../admin-activity";

import './build-folder-tree';
import {buildFolderTree} from "./build-folder-tree";
import {SectionHeader} from "../entity-grid";
import {TextAction} from "../../../routes/auth/sign-in";
import DataWrapper from "../data-wrapper";
import Button from "../button";
import SimpleTooltip from "../tooltip";

function Name({data}) {
    return <span>{data.name}</span>
}

function FolderPreview({id}) {
    const community = useCommunity();


    return <DataWrapper id={id} type={`community_entities.${community.uid}.folders`}><Name/></DataWrapper>;
}

export function DriveFolderSelectorField({value, drive_id, onChange}) {

    const content = <div className="content-popup-shadow max-w-md bg-white border border-gray-200 rounded-lg">
        <FolderSelector drive_id={drive_id} onSelect={(nfid) => {
            onChange(nfid);
            // close the tippy instance below...

        }}/>
    </div>

    let c;

    if (!value) {
        c = <Button left_icon={{icon: <FolderIcon/>}} text="Select Folder"/>;
    } else {
        c = <Button left_icon={{icon: <FolderIcon/>}} text={<FolderPreview id={value}/>}/>;
    }

    return <SimpleTooltip listenForClose usePortal simple trigger="click" interactive hideOnClick={false} placement="bottom-start"
                          text={content}>
        {c}
    </SimpleTooltip>;
}

function FolderSelectorWrapper({tree, selected, onExpandNode, init_expanded = [], onSelect}) {
    const [expanded, setExpanded] = useState([...init_expanded]);

    function renderNodes(nodes) {
        return nodes.map((node) => {
            const is_expanded = expanded.includes(node.id);
            const is_selected = selected === node.id;
            const is_loading = node.children_ready === 'loading';
            return <div key={node.id}>
                <div className={` px-1.5 h-7 rounded-md ${is_selected ? "bg-blue-500" : "hover:bg-gray-100 "}`}
                     onClick={() => {
                         if (is_selected) {
                             onSelect(null);
                             return;
                         }
                         onSelect(node.id);
                     }}>
                    <div className="flex gap-1 items-center h-7" style={{
                        paddingLeft: node.depth === 1 ? "" : "calc(0.5rem * " + (node.depth) + ")"
                    }}>
                        <div
                            className={`flex-none w-4 opacity-60 hover:opacity-90 cursor-pointer ${is_selected ? "text-white" : "text-gray-900"}`}>
                            <div onClick={(e) => {
                                e.stopPropagation();
                                if (is_expanded) {
                                    setExpanded(expanded.filter((a) => a !== node.id));
                                } else {
                                    setExpanded([...expanded, node.id]);
                                    onExpandNode(node.id);
                                }
                            }} className="w-4 h-4">
                                {is_expanded ? <ChevronDownIcon/> : <ChevronRightIcon/>}
                            </div>
                        </div>
                        <div className="flex-none w-5">
                            <div className={`w-5 h-5 ${is_selected ? "text-gray-200" : "text-blue-400"}`}>
                                <FolderIcon/>
                            </div>
                        </div>
                        <div className="flex-grow">
                            <div className={`text-sm ${is_selected ? "text-white font-semibold" : "text-gray-900 "}`}>
                                {node.name}
                            </div>
                        </div>
                        <div className="flex-none">
                            <div>

                            </div>
                        </div>
                    </div>
                </div>
                {is_expanded && <div>
                    {is_loading && <div className="px-1.5 h-7 rounded-md">
                        <InlineLoader mini/>
                    </div>}
                    {renderNodes(node.children)}
                </div>}
            </div>
        })
    }

    return <div>
        {renderNodes(tree.children)}
    </div>
}

export default function FolderSelector({
                                           drive_id = "", onSelect = () => {
    }
                                       }) {
    const community = useCommunity();
    const [selected_folder, setSelectedFolder] = React.useState(null);
    const [loading, setLoading] = React.useState(drive_id);
    const [folder_map, setFolderMap] = React.useState({});

    const folder_map_ref = React.useRef({});

    useEffect(function () {
        folder_map_ref.current = folder_map;
    }, [folder_map])

    useEffect(() => {
        getFolders(drive_id)
            .then((folders) => {
                let folder_map_new = {...folder_map_ref.current};

                folders.forEach((folder) => {
                    folder_map_new[folder.id] = {
                        children_ready: 'not_loaded',
                        ...folder,
                    };
                });


                setFolderMap(folder_map_new);
                setLoading("");
            })
    }, [drive_id])

    function handleExpandNode(node_id) {
        const status = folder_map[node_id].children_ready;

        if (status === 'not_loaded') {
            setLoading(node_id);
            setFolderMap({
                ...folder_map_ref.current,
                [node_id]: {
                    children_ready: 'loading',
                    ...folder_map_ref.current[node_id],
                }
            })
            getFolders(node_id)
                .then((folders) => {
                    let folder_map_new = {...folder_map_ref.current};

                    folders.forEach((folder) => {
                        folder_map_new[folder.id] = {
                            children_ready: 'not_loaded',
                            ...folder,
                        };
                    });

                    folder_map_new[node_id].children_ready = 'loaded';


                    setFolderMap(folder_map_new);
                    setLoading("");
                })
        }

    }

    async function getFolders(folder_id) {
        const ref = collection(db, "community_entities", community.uid, "folders");
        const q = query(ref, where('folder', '==', folder_id), limit(50));

        return await getDocs(q)
            .then((querySnapshot) => {
                return querySnapshot.docs.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    }
                });
            });
    }

    if (!drive_id) {
        return <EmptyState title="No drive found"/>
    }

    const tree = buildFolderTree(folder_map, drive_id);

    if (!tree) {
        return <div>
            <InlineLoader/>
        </div>
    }

    if (tree.children.length === 0) {
        return <EmptyState title="No folders found"/>
    }

    // onBack={()=>{}}
    return <div className="max-w-md max-h-[20rem] relative min-w-[24rem] min-h-[12rem] overflow-y-auto hide-scrollbar">
     <div className="sticky top-0 z-10 rounded-t-lg bg-white udk-menublur pt-3 px-3 pb-0.5">
         <SectionHeader title="Select Folder" actions={<span className="close-tooltip">
             <TextAction onClick={() => {
            if (selected_folder) {
                onSelect(selected_folder);
            }
        }} disabled={!selected_folder} text="Done"/>
         </span>}/>
     </div>
        <div className="pt-1 px-3 pb-3">
            <FolderSelectorWrapper selected={selected_folder} onExpandNode={handleExpandNode}
                                   init_expanded={[]} onSelect={(sid) => setSelectedFolder(sid)} tree={tree}/>
        </div>
    </div>
};