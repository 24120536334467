import React from "react";

/*
* This is the Color atom
 */

const color_bg_map = {
    "red": "bg-red-500",
    "green": "bg-green-500",
    "blue": "bg-blue-500",
    "indigo": "bg-indigo-500",
    "purple": "bg-purple-500",
    "pink": "bg-pink-500",
    "yellow": "bg-yellow-500",
    "gray": "bg-gray-500",
    "white": "bg-white",
    "black": "bg-black"
};

const size_dims_map = {
    "2xs": "h-0.5 w-0.5",
    "xs": "h-1 w-1",
    "sm": "h-2 w-2",
    "base": "h-3 w-3",
    "lg": "h-4 w-4",
    "xl": "h-5 w-5"
};

const _props = {
    color: {
        type: "string",
        required: true,
        options: ["red", "green", "blue", "indigo", "purple", "pink", "yellow", "gray", "white", "black"]
    },
    shape: {
        type: "string",
        required: false,
        default: "circle",
        options: ["circle", "square"]
    },
    size: {
        type: "string",
        required: false,
        default: "base",
        options: ["2xs", "xs", "sm", "base", "lg", "xl"]
    }
};

export default function M3_A_Color({
                                       color = "red",
                                       shape = "circle",
                                       size = "base"
                                   }) {
    let classes = "mx-0.5";

    if (shape === "circle") {
        classes += " rounded-full";
    } else {
        classes += " rounded-sm";
    }

    classes += ` ${size_dims_map[size]}`;

    classes += ` ${color_bg_map[color]}`;

    return <div className={classes} />
}