import {authFetch} from "../network";

export async function group_makeMemberOrganizer(community,payload,toasts,itid,type,data,setData,id,f_type,cb) {
    return new Promise(resolve => {

        toasts.addToast({text: 'Adding organizer', intent: 'info'});
        let new_entities = {...data.entities};
        let new_mods = {...data.moderators};
        if (typeof itid === 'string') {
            new_mods[type][id] = true;
        } else {
            itid.forEach(_id => {
                new_mods[f_type][_id] = true;
                new_entities[f_type][_id] = true;
            })
        }
        const new_group = {
            ...data,
            entities: new_entities,
            moderators: new_mods,
        };
        setData(new_group);
        if (cb) {
            cb(new_group);
        }

        const res = (resp) => {
            if (resp && resp.data && resp.data.data) {
                setData({...resp.data.data});
            }
            toasts.addToast({text: 'Organizer added', intent: 'success'});
            resolve();
        };

        authFetch("/groups/add-moderator", res, res, "POST", {payload});
    });
}


export async function group_removeOrganizer(community,payload,data,setData,itid,type,toasts) {
    return new Promise(resolve => {

        const f_type = (type === 'member' || type === 'members') ? 'members' : 'roles';

        toasts.addToast({text: 'Removing organizer', intent: 'info'});

        let new_mods = {...data.moderators};

        if (typeof itid === 'string') {
            delete new_mods[f_type][itid];
        } else {
            itid.forEach(_id => {
                delete new_mods[f_type][_id];
            })
        }

        const new_group = {
            ...data,
            moderators: new_mods
        };
        setData(new_group);

        const res = (resp) => {
            if (resp.data && resp.data.data) {
                setData({...resp.data.data});
            }
            toasts.addToast({text: 'Organizer removed', intent: 'success'});
            resolve();
        };

        authFetch("/groups/remove-moderator", res, res, "POST", {payload});
    })
}