import {data_data_integrity_map} from "./data-integrity";

export const data_social_map = {
    'website': {
        label: "Website"
    },
    'twitter': {
        label: "Twitter"
    },
    'eth_address': {
        label: "Eth Address"
    },
    'linkedin': {
        label: "LinkedIn"
    },
    'facebook': {
        label: "Facebook"
    },
    'reddit': {
        label: "Reddit"
    },
    'discord': {
        label: "Discord"
    },
    'instagram': {
        label: "Instagram"
    },
    'skype': {
        label: "Skype"
    },
    'github': {
        label: "GitHub"
    }
}

export const data_social_arr = Object.entries(data_social_map).map(([k, v]) => {
    return {
        value: k,
        label: v.label
    }
});