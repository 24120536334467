import React from "react";
import {UserCircleIcon, UsersIcon} from "@heroicons/react/20/solid";
import {CheckCircleIcon, ShieldCheckIcon, UserIcon} from "@heroicons/react/24/outline";

export const icon_map = {
    "user": <UserCircleIcon />,
    "membership_status": <CheckCircleIcon />,
    "member_type": <UserIcon />,
    "account_status": <ShieldCheckIcon />,
    "group": <UsersIcon />
};