// TODO check value-pipeline

export const data__member_profile_fields = {
    'bio': {
        label: "Bio",

        type: "long-text",

        validation: {
            type: "bio"
        },

        meta: {
            editable: true
        },
        member: {
            accessor: "about.bio",
        },

        sample_value: "Austin Knight is a product designer at Google, and formerly at HubSpot. He is the author of the book, 'Why They Buy', and the host of the podcast, 'The Design Better Podcast'."
    },
    'sessions': {
        label: "Sessions",

        type: "number",

        validation: {},
        meta: {
            read_only: true,
            editable: false
        },
        member: {
            accessor: "stats.sessions",
        },

        sample_value: 12
    },
    'account-email': {
        label: "Account Email",

        type: "email",
        autocomplete: "email",

        validation: {
            required: true,
            type: "email"
        },
        meta: {
            core: true,
            read_only: true,
            enable_easy_copy: true
        },
        member: {
            accessor: "account_email",
        },

        sample_value: "austin.knight@demo.getunaty.com"
    },
    'first-name': {
        label: "First Name",

        type: "text",
        autocomplete: "given-name",

        validation: {
            type: "given-name",
            required: true,
        },
        meta: {
            editable: true,
            core: true
        },
        member: {
            accessor: "about.first_name",
        },

        sample_value: "Austin"
    },
    'last-name': {
        label: "Last Name",

        type: "text",
        autocomplete: "family-name",

        validation: {
            type: "family-name",
            required: true,
        },
        meta: {
            editable: true,
            core: true,
            can_sort: true,
        },
        member: {
            accessor: "about.last_name",
        },

        sample_value: "Knight"
    },
    'middle-name': {
        label: "Middle Name",

        type: "text",
        autocomplete: "additional-name",

        validation: {},
        meta: {
            editable: true,
        },
        member: {
            accessor: "about.middle_name",
        },

        sample_value: "James"
    },
    'display-name': {
        label: "Display Name",
        icon: 'name', // maps to m3_icon_map

        type: "text",
        autocomplete: "name",

        validation: {
            required: true,
        },
        meta: {
            core: true,
            read_only: true
        },
        member: {
            accessor: "name",
        },

        sample_value: "Austin Knight"
    },
    'full-name': {
        label: "Full Name",
        icon: 'name', // maps to m3_icon_map

        type: "text",
        autocomplete: "name",

        validation: {},
        meta: {
            derived: true,
            core: true,
            read_only: true
        },
        member: {
            accessor: "__full_name",
        },

        sample_value: "Austin Knight"
    },
    'title': {
        label: "Title",
        icon: 'text', // maps to m3_icon_map

        type: "text",
        autocomplete: "personal-title",

        validation: {},
        meta: {
            editable: true,
        },
        member: {
            accessor: "about.title",
        },

        sample_value: "Dr."
    },
    'website': {
        label: "Website",

        type: "url",
        autocomplete: "url",

        display: {
            format: "url"
        },
        validation: {
            type: "url"
        },
        meta: {
            editable: true,
        },
        member: {
            accessor: "social.website",
        },

        sample_value: "https://www.orange_austinknight.com"
    },
    'twitter': {
        label: "Twitter",

        type: "url",
        autocomplete: "url",

        validation: {},
        meta: {
            type: "twitter-url",
            editable: true
        },
        member: {
            accessor: "social.twitter",
        },

        sample_value: "https://twitter.com/orange_austinknight"
    },
    'facebook': {
        label: "Meta",

        type: "url",
        autocomplete: "url",

        validation: {},
        meta: {
            type: "meta-url",
            editable: true
        },
        member: {
            accessor: "social.facebook",
        },

        sample_value: "https://facebook.com/orange_austinknight"
    },
    'linkedin': {
        label: "LinkedIn",

        type: "url",
        autocomplete: "url",

        validation: {},
        meta: {
            type: "linkedin-url",
            editable: true
        },
        member: {
            accessor: "social.linkedin",
        },

        sample_value: "https://linkedin.com/in/orange_austinknight"
    },
    'instagram': {
        label: "Instagram",

        type: "url",
        autocomplete: "url",

        validation: {},
        meta: {
            type: "instagram-url",
            editable: true
        },
        member: {
            accessor: "social.instagram",
        },

        sample_value: "https://instagram.com/orange_austinknight"
    },
    'location': {
        label: "Location",
        icon: 'location', // maps to m3_icon_map

        type: "text",
        autocomplete: "location",

        validation: {},
        meta: {
            can_make_key_field: true,
            editable: true
        },
        member: {
            accessor: "about.location",
        },

        sample_value: "Chicago, IL"
    },
    'occupation': {
        label: "Occupation",
        icon: 'occupation', // maps to m3_icon_map

        type: "text",
        autocomplete: "occupation",

        validation: {},
        meta: {
            can_make_key_field: true,
            editable: true
        },
        member: {
            accessor: "about.occupation",
        },

        sample_value: "Product Designer"
    },
    'address': {
        label: "Address",

        type: "address",
        autocomplete: "street-address",

        display: {
            format: "address"
        },

        validation: {
            type: "address"
        },
        meta: {},
        member: {
            accessor: "address",
        },

        sample_value: {
            "line_one": "123 Main St",
            "line_two": "Apt 2",
            "city": "Chicago",
            "state": "IL",
            "zip_code": "60601",
            "country": "us"
        }
    },
    'address.country': {
        label: "Country",
        icon: 'address',

        type: "select",
        autocomplete: "country",

        validation: {},

        parent: {
            id: "address",
            label: "Address"
        },

        meta: {
            editable: true
        },

        member: {
            accessor: "address.country",
        },

        display: {
            variant: "label"
        },

        sample_value: "us"
    },
    'address.state': {
        label: "State",
        icon: 'address',

        type: "text",
        autocomplete: "state",

        validation: {},

        parent: {
            id: "address",
            label: "Address"
        },

        meta: {
            editable: true
        },

        member: {
            accessor: "address.state",
        },

        display: {},

        sample_value: "California"
    },
    'address.city': {
        label: "City",
        icon: 'address',

        type: "text",
        autocomplete: "city",

        validation: {},

        parent: {
            id: "address",
            label: "Address"
        },

        meta: {
            editable: true
        },

        member: {
            accessor: "address.city",
        },

        display: {},

        sample_value: "San Diego"
    },
    'address.zip_code': {
        label: "ZIP Code",
        icon: 'address',

        type: "text",
        autocomplete: "city",

        validation: {},

        parent: {
            id: "address",
            label: "Address"
        },

        meta: {
            editable: true
        },

        member: {
            accessor: "address.zip_code",
        },

        display: {},

        sample_value: "92130"
    },
    'address.line_one': {
        label: "Line One",
        icon: 'address',

        type: "text",
        autocomplete: "address-line1",

        validation: {},

        parent: {
            id: "address",
            label: "Address"
        },

        meta: {
            editable: true
        },

        member: {
            accessor: "address.line_one",
        },

        display: {},

        sample_value: "123 Main St"
    },
    'address.line_two': {
        label: "Line Two",
        icon: 'address',

        type: "text",
        autocomplete: "address-line2",

        validation: {},

        parent: {
            id: "address",
            label: "Address"
        },

        meta: {
            editable: true
        },

        member: {
            accessor: "address.line_two",
        },

        display: {},

        sample_value: "Apt 2"
    },
    'about.gender': {
        label: "Sex",

        type: "select",
        autocomplete: "sex",

        validation: {},

        meta: {
            editable: true
        },

        member: {
            accessor: "about.gender",
        },

        display: {
            variant: "label"
        },

        sample_value: "female"
    },
    /*
    'age': {
        label: "Age",

        type: "number",
        autocomplete: "age",

        validation: {},
        meta: {

        },
        member: {
            accessor: "age",
        },

        sample_value: 30
    },

     */
    birthday: {
        label: "Birthday",
        icon: 'birthday', // maps to m3_icon_map

        type: "date-object",
        autocomplete: "birthday",

        display: {
            format: "date"
        },
        validation: {},
        meta: {
            editable: true
        },
        member: {
            accessor: "birthday",
        },

        sample_value: {
            "year": 1990,
            "month": 1,
            "day": 1
        }
    },
    email: {
        label: "Email",

        type: "email",
        autocomplete: "email",

        validation: {
            type: "email"
        },
        meta: {
            can_make_key_field: true,
            enable_easy_copy: true
        },
        member: {
            accessor: "contact.email",
        },

        sample_value: "austin.knight@demo.getunaty.com"
    },
    phone: {
        label: "Phone",
        icon: 'phone', // maps to m3_icon_map

        type: "phone",
        autocomplete: "tel",

        validation: {
            type: "phone"
        },
        display: {
            format: "phone"
        },
        meta: {
            editable: true,
            can_make_key_field: true,
            enable_easy_copy: true
        },
        member: {
            accessor: "contact.phone",
        },

        sample_value: "+12628252883"
    },
    continent: {
        label: "Continent",
        icon: 'globe', // maps to m3_icon_map

        type: "select",
        autocomplete: "continent",

        display: {
            variant: "label",
            autocomplete: "continent"
        },
        validation: {},
        meta: {
            editable: true,
            enriched: true
        },
        member: {
            accessor: "continent",
        },

        sample_value: "na"
    },
    'handle': {
        label: "Handle",
        icon: 'handle', // maps to m3_icon_map

        type: "text",
        autocomplete: "handle",

        validation: {},
        meta: {},
        member: {
            accessor: "handle",
        },

        sample_value: "orange_austinknight"
    },
    'id': {
        label: "ID",
        icon: 'id', // maps to m3_icon_map

        type: "text",
        autocomplete: "id",

        validation: {},
        meta: {
            read_only: true,
        },
        member: {
            accessor: "id",
        },

        display: {
            variant: "id"
        },

        sample_value: "QHW7WTZZBK"
    },
    'headline': {
        label: "Headline",
        icon: 'headline', // maps to m3_icon_map

        type: "text",
        autocomplete: "headline",

        validation: {},
        meta: {
            editable: true
        },
        member: {
            accessor: "about.headline",
        },

        sample_value: "Product Designer at Google"
    },
    'interests': {
        label: "Interests",
        icon: 'interests', // maps to m3_icon_map

        type: "interests",
        autocomplete: "interests",

        display: {
            multi_column: true
        },

        validation: {},
        meta: {
            can_make_key_field: true,
            hide_label_in_profile: true,
        },
        member: {
            accessor: "interests",
        },

        sample_value: {"default_coffee": true, "default_yoga": true}
    },
    'industry': {
        label: "Industry",
        icon: 'office', // maps to m3_icon_map

        type: "text",
        autocomplete: "industry",

        validation: {},
        meta: {
            editable: true,
        },
        member: {
            accessor: "about.industry",
        },

        sample_value: "Technology"
    },
    'last-sign-in': {
        label: "Last Sign In",
        icon: 'clock', // maps to m3_icon_map

        type: "datetime",

        display: {
            format: "relative"
        },

        validation: {},
        meta: {
            can_sort: true,
            read_only: true,
            editable: false
        },
        member: {
            accessor: "last_sign_in",
        },

        sample_value: 1704685042207
    },
    'created-at': {
        label: "Created",
        icon: 'clock', // maps to m3_icon_map

        type: "datetime",

        display: {
            format: "relative"
        },

        validation: {},
        meta: {
            can_sort: true,
            read_only: true,
            editable: false
        },
        member: {
            accessor: "created_at",
        },

        sample_value: 1704685042207
    },
    'profile-updated-at': {
        label: "Profile updated",
        icon: 'clock', // maps to m3_icon_map

        type: "datetime",

        display: {
            format: "relative"
        },

        validation: {},
        meta: {
            can_sort: true,
            read_only: true,
            editable: false
        },
        member: {
            accessor: "profile_updated_at",
        },

        sample_value: 1704685042207
    },

    'spaces': {
        label: "Spaces",
        icon: 'spaces',

        type: "groups",

        display: {
            multi_column: true
        },

        validation: {},
        meta: {
            editable: false,
            dynamic: true
        },
        member: {
            accessor: "groups",
        },

        sample_value: ["space1", "space2"]
    },
    'positions': {
        label: "Positions",
        icon: 'positions',

        type: "roles",

        display: {
            multi_column: true
        },

        validation: {},
        meta: {
            editable: false,
            dynamic: true
        },
        member: {
            accessor: "roles",
        },

        sample_value: ["space1", "space2"]
    },
    'member-type': {
        label: "Membership Level",
        icon: 'member-type', // maps to m3_icon_map

        type: "select",
        options_type: "dynamic",

        validation: {},
        meta: {
            editable: true,
            internal: true
        },
        member: {
            accessor: "member_type",
        },
        display: {
            variant: "label",
        },

        sample_value: "F409ASDFA439"
    },
    'data-integrity': {
        label: "Data Integrity",
        icon: 'data-integrity', // maps to m3_icon_map

        type: "select",
        autocomplete: "data-integrity",
        // how to handle options?

        validation: {},
        meta: {
            read_only: true,
            derived: true
        },

        display: {
            variant: "square"
        },

        member: {
            accessor: "data_integrity",
        },

        sample_value: "outdated"
    },
    'membership-status': {
        label: "Membership Status",
        icon: 'membership-status', // maps to m3_icon_map

        type: "select",
        autocomplete: "membership-status",

        validation: {},

        meta: {
            editable: true,
            internal: true
        },

        member: {
            accessor: "membership_status",
        },

        display: {
            variant: "circle"
        },

        sample_value: "active"
    },
    'neighborhood': {
        label: "Neighborhood",
        icon: 'address', // maps to m3_icon_map

        type: "text",
        autocomplete: "neighborhood",

        validation: {},
        meta: {
            editable: true,
            enriched: true
        },
        member: {
            accessor: "neighborhood",
        },

        sample_value: "River North"
    },
    /*
    'join-date': {
        label: "Join Date",
        icon: 'calendar', // maps to m3_icon_map

        type: "date-frame-object",

        display: {
          format: "date"
        },
        validation: {},
        meta: {},
        member: {
            accessor: "join_date",
        },

        sample_value: {
            type: "month_year",
            value: {
                year: 2021,
                month: 0,
                season: null,
                date: null
            }
        }
    },

     */
    'profile-picture': {
        label: "Profile Picture",
        icon: 'avatar', // maps to m3_icon_map

        type: "image-url",

        display: {
            format: "image",
            default_size: "medium"
        },
        validation: {},
        meta: {
            block_from_contacts: true
        },
        member: {
            accessor: "profile_picture",
        },

        sample_value: "https://images.unsplash.com/photo-1612832943266-2f0d5a3d0f8a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8&ixlib=rb-1.2.1&w=1000&q=80"
    },
    'cover-photo': {
        label: "Cover Photo",
        icon: 'cover-photo', // maps to m3_icon_map

        type: "image-url",

        validation: {},
        meta: {
            block_from_contacts: true
        },
        member: {
            accessor: "cover_photo",
        },

        sample_value: "https://images.unsplash.com/photo-1612832943266-2f0d5a3d0f8a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8&ixlib=rb-1.2.1&w=1000&q=80"
    }
};

export const findDataField = (accessor_key, attributes) => {
    return !attributes ? null : attributes.find(att => att?.col_data?.accessorKey === accessor_key);
}