import React from "react";
import M3_A_Portal from "../atoms/portal";
import M3_C_Dialog from "./dialog";
import {useProvidedStateOrCreate} from "../hooks";

const _props = {};

export default function M3_C_PromptDialog({
                                              initial_state,
                                              confirm_disabled,
                                              confirm_loading,
                                              confirm_label = "Save",
                                              onClose = () => {
                                              },
                                              title = "Edit view",
                                              children,
                                              onConfirm = () => {
                                              }
                                          }) {

    const header = {
        title: title,
        size: "sm"
    };

    const footer = {
        actions: {
            cancel: {
                label: "Cancel",
                onClick: () => {
                    onClose()
                }
            },
            confirm: {
                label: confirm_label,
                intent: "primary",
                disabled: confirm_disabled,
                loading: confirm_loading,
                onClick: () => {
                    onConfirm();
                }
            }
        },
    };

    return (<M3_A_Portal onClose={onClose} background>
        <M3_C_Dialog max_width="max-w-md" footer={footer} header={header}>
            {children}
        </M3_C_Dialog>
    </M3_A_Portal>);
}