import React, {useEffect, useState} from 'react';
import FormFieldsRenderer, {updateFieldValue} from "../../../m3/_legacy_components/form-fields-renderer";
import {useToasts} from "../../../config/toasts";
import Button from "../../../m3/_legacy_components/button";
import {MiniHeader} from "./edit-member";
import Accordion from "../../../m3/_legacy_components/accordion";
import {EditMemberGeneral} from "./edit-sections/general";
import {useCommunity} from "../../../config/community";
import {member_editOtherProfile} from "../member/api";

export function FieldsGroupWrapper({data, flow, onClose = ()=>{}, cb, onSave, fns, hide_header, sections, padding_x = "px-4"}) {
    const toasts = useToasts();
    const [state, setState] = useState("");
    const [loading, setLoading] = useState("");
    const [dt, updateDt] = useState({...data});
    const [unsaved_changes, setUnsavedChanges] = useState({});

    useEffect(function () {
        if (data._highlighted_field) {
            const el = document.getElementById(data._highlighted_field);
            if (el) {
                el.scrollIntoView()
            }
        }
    }, [data])

    function handleChange(accessor, new_value, old_value, field) {
        updateDt({
            ...updateFieldValue(accessor, new_value, dt)
        })
        setUnsavedChanges({...unsaved_changes, [accessor]: true})
    }

    function handleSave() {
        setState("saving")
        onSave(dt, Object.keys(unsaved_changes))
            .then(() => {
                setUnsavedChanges({});
                setState("");
                if (cb) {
                    cb();
                }
                onClose();
            })
    }

    const unsaved_changes_count = Object.keys(unsaved_changes).length;

    const actions = <>
        <Button size="small" loading={state === "saving"} onClick={handleSave} disabled={!unsaved_changes_count}
                text="Save" intent="success"/>
    </>;

    const ep = {
        fns,
        flow,
        data: dt,
        onChange: handleChange
    };

    return <>
        <div className="space-y-2 divide-y divide-gray-200 pb-4" id="edit-wrapper">
            {sections.map((sec, k) => {
                return <div id={`_edit-${sec.title}`} key={k} className={"pt-2"}>
                    <Accordion action={unsaved_changes_count ? actions : null} open={true} title={sec.title}
                               subtitle={sec.subtitle} padding_x={padding_x}>
                        <div className="pt-1">
                            {sec.component && <sec.component {...ep}/>}
                        </div>
                    </Accordion>
                </div>
            })}
        </div>
    </>
}

function EditMemberPreferences({data, onChange}) {

    function handleOnChange(accessor, new_value, old_value, field) {
        onChange(accessor, new_value, old_value, field)
    }

    const sec_1 = [
        {
            label: "Timezone",
            accessor: "preferences.timezone",
            type: "timezone"
        },
        {
            label: "Language",
            accessor: "preferences.language",
            type: "language"
        },
        /*
        {
            label: "Secondary Emails",
            accessor: "secondary_emails",
            type: "secondary_emails",
            col_span: 2,
            input_props: {
                member_id: data.id,
                user_uid: data.user_uid
            }
        }

         */
    ];

    return <div className="space-y-3 pb-4" id="edit-prefs">
        <div className="grid ">
            <div>
                <FormFieldsRenderer onChange={handleOnChange} data={data} fields={sec_1}/>
            </div>
        </div>
    </div>
}

function buildSections() {
    let b = [];

    b.push({
        title: "General",
        component: EditMemberPreferences
    })

    return b;
}

export function ManageMemberPreferences({data, updateMember}) {
    const community = useCommunity();
    const flow = "manage-member";
    const fns = {};
    const sections = buildSections(flow, fns);

    async function onSave(dt, updated_fields) {
        return await member_editOtherProfile(community, data.id, {profile: dt, updated_fields});
    }

    return (
        <FieldsGroupWrapper onSave={onSave} fns={fns} flow={flow} sections={sections} data={data}/>
    );
}