import React from "react";
import M3_C_Dialog from "../components/dialog";
import M3_A_SectionHead from "../atoms/sectionhead";
import M3_A_Portal from "../atoms/portal";
import M3_A_Prose from "../atoms/prose";
import M3_A_Markdown from "../atoms/markdown";

/*
* This is the PolicyPrompt
 */

const _demo = {
    config: {
        header: {
            context_bar: {
                type: "avatar",
                data: {
                    size: "sm",
                    avatar: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/community_logos%2FW8SLI1DA5N_small?alt=media"
                }
            },
            title: "Review Privacy Policy",
            subtitle: "Updated March 25th, 2023"
        },
        footer: {
            actions: {
                confirm: {
                    label: "Accept",
                    intent: "primary",
                    handler: () => {
                        console.log("CONFIRM");
                    }
                }
            },
            caption: "By clicking \"Accept\", you confirm you agree to the above policy.",
        },
        content: {
            key_updates: "We've updated our privacy policy to include new information about how we use your data.",
            policy_markdown: `
### Privacy Policy
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget ultricies ultrices, nunc nunc ultrices nunc, quis ultricies nisl nunc eget nunc. Donec euismod, nisl eget ultricies ultrices, nunc nunc ultrices nunc, quis ultricies nisl nunc eget nunc. Donec euismod, nisl eget ultricies ultrices, nunc nunc ultrices nunc, quis ultricies nisl nunc eget nunc. Donec euismod, nisl eget ultricies ultrices, nunc nunc ultrices nunc, quis ultricies nisl nunc eget nunc. Donec euismod, nisl eget ultricies ultrices, nunc nunc ultrices nunc, quis ultricies nisl nunc eget nunc. Donec euismod, nisl eget ultricies ultrices, nunc nunc ultrices nunc, quis ultricies nisl nunc eget nunc.

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget ultricies ultrices, nunc nunc ultrices nunc, quis ultricies nisl nunc eget nunc. Donec euismod, nisl eget ultricies ultrices, nunc nunc ultrices nunc, quis ultricies nisl nunc eget nunc. Donec euismod, nisl eget ultricies ultrices, nunc nunc ultrices nunc, quis ultricies nisl nunc eget nunc. Donec euismod, nisl eget ultricies ultrices, nunc nunc ultrices nunc, quis ultricies nisl nunc eget nunc. Donec euismod, nisl eget ultricies ultrices, nunc nunc ultrices nunc, quis ultricies nisl nunc eget nunc. Donec euismod, nisl eget ultricies ultrices, nunc nunc ultrices nunc, quis ultricies nisl nunc eget nunc.

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget ultricies ultrices, nunc nunc ultrices nunc, quis ultricies nisl nunc eget nunc. Donec euismod, nisl eget ultricies ultrices, nunc nunc ultrices nunc, quis ultricies nisl nunc eget nunc. Donec euismod, nisl eget ultricies ultrices, nunc nunc ultrices nunc, quis ultricies nisl nunc eget nunc. Donec euismod, nisl eget ultricies ultrices, nunc nunc ultrices nunc, quis ultricies nisl nunc eget nunc. Donec euismod, nisl eget ultricies ultrices, nunc nunc ultrices nunc, quis ultricies nisl nunc eget nunc. Donec euismod, nisl eget ultricies ultrices, nunc nunc ultrices nunc, quis ultricies nisl nunc eget nunc.

`
        },
        dialog_count: 1
    }
};

const _props = {};

function parseConfig(config, demo) {
    if (demo) {
        return _demo.config;
    }
    return config;
}

export default function M3_E_PolicyPrompt({
                                              config,
                                              demo
                                          }) {

    const {dialog_count, content, footer, header} = parseConfig(config, demo);

    return (<M3_A_Portal background>
            <M3_C_Dialog
                dialog_count={dialog_count}
                is_loading={config?.is_loading}
                is_initializing={config?.is_initializing}
                footer={footer}
                header={header}>
                {content?.key_updates && <div>
                    <M3_A_SectionHead skeleton={config?.is_initializing}>Key Updates</M3_A_SectionHead>
                    <M3_A_Prose>
                        {content?.key_updates &&
                            <M3_A_Markdown skeleton={config?.is_initializing}>{content?.key_updates}</M3_A_Markdown>}
                    </M3_A_Prose>
                </div>}
                <div>
                    <M3_A_SectionHead skeleton={config?.is_initializing}>Read the full policy</M3_A_SectionHead>

                    <M3_A_Prose>
                        <M3_A_Markdown skeleton_lines={20}
                                       skeleton={config?.is_initializing}>{content?.policy_markdown}</M3_A_Markdown>
                    </M3_A_Prose>
                </div>
            </M3_C_Dialog>
        </M3_A_Portal>
    );
}