import React, {useEffect, useRef} from 'react';
import M3_C_SelectPanel from "../components/select-panel";
import M3_A_ActionList from "../atoms/action-list";
import {m3_icon_map} from "../icons/icon-map";
import M3_A_NodeSelector from "../atoms/node-selector";
import M3_A_Text from "../atoms/text";
import M3_A_IconButton from "../atoms/icon-button";
import M3_A_Button from "../atoms/button";
import {data_months_map} from "../../../common/data/data/months";

function generateCalendar(month, year, date) {

    const days_in_month = new Date(year, month + 1, 0).getDate();

    let cal = [];

    let day = 1;

    let start = new Date(year, month, 1).getDay();

    for (let i = 0; i <= 5; i++) {
        cal.push([]);
        for (let j = 0; j < 7; j++) {
            if (day > days_in_month) {
                cal[i].push('');
            } else {
                if (i === 0 && j < start) {
                    cal[i].push('');
                } else {
                    cal[i].push(day++);
                }
            }
        }
    }

    let year_str = year.toString();

    let month_str = data_months_map[month].label;

    return {
        today: `${year}-${month}-${date.getDate()}`,
        year_str,
        month_str,
        cal
    }
}

function DatePanelWrapper({
                              onSelectDate = () => {
                              },
                              starting_date,
                          }) {
    const current_date = new Date();
    const initial_date = starting_date || new Date();
    const current_year = current_date.getFullYear();
    const [show, setShow] = React.useState("date"); // month, year
    const [month, setMonth] = React.useState(initial_date.getMonth());
    const [year, setYear] = React.useState(initial_date.getFullYear());
    const [year_index, setYearIndex] = React.useState(0);

    function nextMonth() {
        if (month === 11) {
            setMonth(0);
            setYear(year + 1);
        } else {
            setMonth(month + 1);
        }
    }

    function prevMonth() {
        if (month === 0) {
            setMonth(11);
            setYear(year - 1);
        } else {
            setMonth(month - 1);
        }
    }

    const {cal, today, month_str, year_str} = generateCalendar(month, year, initial_date);

    if (show === "month") {
        return <div className="px-2">
            <div className="text-center pb-2">
                <M3_A_Text className="text-gray-600" size="sm" weight="font-medium">Select a Month</M3_A_Text>
            </div>
            <div className="grid gap-2 grid-cols-3">
                {data_months_map.map((month, index) => {
                    return <div className="flex justify-center" key={index}>
                        <M3_A_Button text={month.label} variant={'minimal'} size="base" onClick={() => {
                            setMonth(index);
                            setShow("date");
                        }}/>
                    </div>
                })}
            </div>
        </div>
    } else if (show === "year") {
        // 100 years before and 50 after
        // show them in segments of 20 so 5 rows of 4 based off of the year_index
        let years = [];

        for (let i = year_index; i < year_index + 20; i++) {
            years.push(current_year + i - 10);
        }

        const current_year_span_str = `${years[0]} - ${years[years.length - 1]}`;

        return <div className="px-2">
            <div className="flex gap-4 items-center pb-2">
                <div className="text-lg flex gap-3 flex-grow text-gray-800 font-bold">
                    <M3_A_Text>{current_year_span_str}</M3_A_Text>
                </div>
                <div className="flex gap-2">
                    <M3_A_IconButton compact variant={'minimal'} size="sm" square active
                                     icon={m3_icon_map.outlines.chevron_left}
                                     onClick={() => {
                                         setYearIndex(year_index - 20);

                                     }}/>
                    <M3_A_IconButton compact variant={'minimal'} size="sm" square
                                     icon={m3_icon_map.outlines.chevron_right}
                                     onClick={() => {
                                         setYearIndex(year_index + 20);
                                     }}/>
                </div>
            </div>
            <div>
                <div className="grid gap-2 grid-cols-4">
                    {years.map((year, index) => {
                        return <div className="flex justify-center" key={index}>
                            <M3_A_Button text={year} variant={year === current_year ? 'default' : 'minimal'} size="base"
                                         onClick={() => {
                                             setYear(year);
                                             setShow("date");
                                         }}/>
                        </div>
                    })}
                </div>
            </div>
        </div>
    }

    return <div className="px-2">
        <div className="flex gap-4 items-center pb-2">
            <div className="text-base flex gap-3 flex-grow text-gray-800 font-bold">
                <M3_A_NodeSelector dropdown={false} onClick={() => {
                    setShow("month");
                }}>
                    <M3_A_Text>{month_str}</M3_A_Text>
                </M3_A_NodeSelector>
                <M3_A_NodeSelector dropdown={false} onClick={() => {
                    setShow("year");
                }}>
                    <M3_A_Text>{year_str}</M3_A_Text>
                </M3_A_NodeSelector>
            </div>
            <div className="flex gap-2">
                <M3_A_IconButton compact variant={'minimal'} size="sm" square active
                                 icon={m3_icon_map.outlines.chevron_left}
                                 onClick={() => prevMonth()}/>
                <M3_A_IconButton compact variant={'minimal'} size="sm" square icon={m3_icon_map.outlines.chevron_right}
                                 onClick={() => nextMonth()}/>
            </div>
        </div>
        <div className="">
            <div>
                <div className="text-gray-500 text-sm grid grid-cols-7 gap-1 pb-1">
                    <div className="font-medium">Su</div>
                    <div className="font-medium">Mo</div>
                    <div className="font-medium">Tu</div>
                    <div className="font-medium">We</div>
                    <div className="font-medium">Th</div>
                    <div className="font-medium">Fr</div>
                    <div className="font-medium">Sa</div>
                </div>
            </div>
            <div className="grid gap-1">
                {cal.map((row, index) => {
                    return <div key={index} className="grid grid-cols-7 gap-1">
                        {row.map((day, index) => {
                            const is_today = `${year}-${month}-${day}` === today;
                            return <div key={index}>
                                {day && <M3_A_Button text={day} compact variant={is_today ? 'default' : 'minimal'}
                                                     size="base" square
                                                     onClick={() => {
                                                         onSelectDate(new Date(year, month, day), year, month, day);
                                                     }}/>}
                            </div>
                        })}
                    </div>
                })}
            </div>
        </div>
    </div>
}

function getPanels(type, value, onChange, initial_query, options, selected, setSelected) {
    if (type === "date") {
        return [
            {
                id: "date",
                content: [
                    {
                        id: "date",
                        no_padding: true,
                        children: <DatePanelWrapper starting_date={value} onSelectDate={(date, year, month, day) => {
                            onChange({date,year,month,day});
                        }}/>
                    },
                    {
                        id: "list",
                        no_padding: true,
                        children: <M3_A_ActionList size="sm" items={[{
                            label: "No Date",
                            onClick: () => {
                                onChange({date:null,year:null,month:null,day:null})
                            },
                            value: null,
                            id: "no_date",
                            leading_indicator: {
                                type: "icon",
                                icon: m3_icon_map.outlines.xcircle
                            }
                        }]}/>
                    }
                ]
            }
        ]
    }

    return [
        {
            id: "editor",
            content: [
                {
                    id: "list",
                    no_padding: true,
                    children: <M3_A_ActionList initial_query={initial_query} can_select onSelect={a => {
                        if (a === selected || !a) return;
                        setSelected(a);
                    }} selected={selected} enable_search_filter size="sm" items={options.map(a => {
                        return {
                            label: a.label,
                            value: a.value,
                            id: a.value,
                        }
                    })}/>
                }
            ]
        }
    ]
}

export function M3_E_SelectPanelFieldEditor({
                                                type = "select",
                                                no_anchor,
                                                initial_query,
                                                onChange,
                                                value,
                                                options = []
                                            }) {
    const [selected, setSelected] = React.useState(value);
    const isFirstRun = useRef(true);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        if (onChange) {
            onChange(selected);
        }
    }, [selected]);

    const panels = getPanels(type, value, onChange, initial_query, options, selected, setSelected);

    if (no_anchor) {
        return <M3_C_SelectPanel panels={panels}/>
    }

    const renderAnchor = () => {
        return <div>
            <button className="btn btn-primary">Show Panel</button>
        </div>
    }

    return (
        <div>
            <M3_C_SelectPanel renderAnchor={renderAnchor} panels={panels}/>
        </div>
    );
}