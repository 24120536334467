import React from "react";
function Image() {
    return <div style={{backgroundImage: `url()`}} className="h-72 rounded-t-lg border-b border-gray-200 bg-cover bg-center">

    </div>
}

function Title() {
    return <div  className="text-base leading-5 text-gray-800 font-medium">
        Who Loses When the Algorithms Win?
         </div>
}

function Description() {
    return <div style={{lineHeight:'1.2rem'}} className="text-sm text-gray-600">
        While recommendation media promises users a better consumption experience in a post-social world, results may vary for some.
    </div>
}

function Source() {
    return <div className=" text-sm text-gray-500">
        bloomberg.com
    </div>
}

export function WB_MediaLink({}) {
    return <div className="w-full group cursor-pointer rounded-lg border border-gray-200 overflow-hidden">
        <Image />
        <div className="bg-white group-hover:bg-gray-100 space-y-0.5 px-4 pt-3 pb-3">
         <Source />
            <Title />
            <Description />
        </div>
    </div>
}