import React from "react"
import './styles.css';
import {Avatar} from "../avatar";
import {LogoMark} from "../app-frame/icons";
import {useNavigate} from "react-router-dom";

export default function ExternalAuthPage({children, show_logo, bg_image, image, background, loading = false}) {
    const navigate = useNavigate();
    let style = {};
    if (!loading && bg_image) {
        style = {backgroundImage: `url(${bg_image})`};
    }

    return <div className={`external-auth-page`}>
        <div className="px-6 relative w-full py-4 flex flex-col">
            <div className="flex-grow w-full justify-center flex items-center">
                <div className="pt-20 pb-40 w-full flex justify-center">
                    {children}
                    <div className="absolute top-3 left-3">
                        {show_logo &&
                            <div className="cursor-pointer" onClick={() => navigate('/')}><LogoMark width={'36'}
                                                                                                     height={'36'}/>
                            </div>}
                        {image && <Avatar url={image} size={"h-12 w-12"}/>}
                    </div>

                </div>
            </div>
        </div>
        <div className={`p-3 relative hidden sm:block`}>
            <div className={`bg-gray-100 bg-cover bg-center w-full h-full rounded-xl ${loading ? "bg-gray-200 animate-pulse" : ""}`} style={style}>
                {!bg_image ? background : null}
            </div>
        </div>
    </div>
};