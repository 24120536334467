import {getPhoneNumberSuggestion} from "./phone-number-cleaner";
import {
    parsePhoneNumberFromString,
    getCountryCallingCode,
    isPossiblePhoneNumber,
    formatIncompletePhoneNumber
} from "libphonenumber-js/min";

function processEditStr(str,cc="",country) {
    if(!str) {
        return ``;
    } else if(country) {
        if(!cc) {
            const cc2 = getCountryCallingCode(country.toUpperCase());
            return str.replace(`+${cc2}`,'')
        } else {
            return str.replace(`+${cc}`,'')
        }

    } else if(str.length>5) {
        return str.replace(`+${cc}`,'')
    } else {
        return str;
    }
}

function formatNumber(n) {
    if(!n) {
        return ``;
    }
    if(n.length>7&&n.indexOf('+')===-1) {
        return `+${n.replace(/ /g,'').trim()}`
    }
    return n.replace(/ /g,'').trim()
}

export function getPhoneNumberData(str='',country="") {
    let dv = str;
    let number = str;
    let cc = '';
    if(country) {
        cc = getCountryCallingCode(country.toUpperCase());
        number = `+${cc}${number}`;
    }

    if(!str) {
        return {
            valid: false,
            method: 'empty',
            tel: '',
            number: formatNumber(number),
            country: "",
            calling_code: "",
            edit: processEditStr(dv,cc),
            display: dv
        }
    }

    const guess = getPhoneNumberSuggestion(str);

    if(!guess) {

        return {
            valid: false,
            tel: '',
            number: formatNumber(number),
            method: 'no-guess',
            country: country,
            calling_code: cc,
            edit: processEditStr(dv,cc,country),
            display: dv
        }
    }

    const pn = parsePhoneNumberFromString(guess, country?country.toUpperCase():"");

    if(!pn) {
        if(country) {
            const cc = getCountryCallingCode(country.toUpperCase());
            dv = formatIncompletePhoneNumber(dv,country.toUpperCase()).replace(`+${cc}`,'').trim();
            number = formatIncompletePhoneNumber(dv,country.toUpperCase());
        }
        return {
            valid: false,
            method: 'no-number',
            country: country,
            number: formatNumber(number),
            tel: '',
            calling_code: cc,
            edit: processEditStr(dv,cc),
            display: dv
        }
    }

    if(!pn.isValid()) {
        if(country) {
            const cc = getCountryCallingCode(country.toUpperCase());
            dv = formatIncompletePhoneNumber(dv,country.toUpperCase()).replace(`+${cc}`,'').trim();
        }
        return {
            valid: false,
            method: 'not-valid',
            country: country,
            number: formatNumber(number),
            tel: '',
            calling_code: cc,
            edit: processEditStr(dv,cc),
            display: dv
        }
    }

    dv = pn.formatInternational().trim();

    number = pn.formatInternational();

    return {
        valid: true,
        method: 'full-valid',
        tel: pn.getURI(),
        number: formatNumber(number),
        country: country ? country : (pn.country ? pn.country : ""),
        calling_code: pn.countryCallingCode,
        international: pn.formatInternational(),
        national: pn.formatNational(),
        edit: processEditStr(dv,cc,pn.country),
        display: dv
    }
}