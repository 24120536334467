import React, {useEffect, useRef, useState} from 'react';
import {sanitizeHandle} from "../../../../m3/_legacy_components/createable-async-select/entities";
import {handleIsUnique} from "../../../../m3/_legacy_components/simple-handle-selector/utilities";
import {FieldLabel} from "../../../../m3/_legacy_components/form/field";
import M3_A_Input from "../../../../m3/atoms/input";
import M3_A_Text from "../../../../m3/atoms/text";
import {utils_strings_isHandle} from "../../../../../common/utilities/strings";


function getMeta(scope) {
    if (scope === 'community') {
        return '2-80 characters. Letters, numbers, dashes, and underscores only.';
    } else {
        return '5-80 characters. Letters, numbers, dashes, and underscores only.';
    }
}

function getLabel(type, change_mode) {
    if (type === 'community') {
        return 'Domain'
    }
    return change_mode ? "New Handle" : "Handle"
}

export default function HandleSelector({
                                           // check against
                                           current_handle = '',
                                           type = '',
                                           check_id = '',
                                           invalid,
                                           default_text = '',
                                           autoFocus = false,
                                           show_label = true,
                                           placeholder = "my-handle",
                                           change_mode = false,
                                           scope,
                                           scope_id,
                                           setHandle,
                                           checkingHandle
                                       }) {
    const [handleVal, setHandleVal] = useState(default_text);
    const [valid, setValid] = useState(false);
    const [checking, setChecking] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [checked, setChecked] = useState(false);
    const [meta_handle, setMetaHandle] = useState("");
    const [taken_handle, setTakenHandle] = useState("");
    const [taken, setTaken] = useState(false);

    const isFirstRun = useRef(true);

    useEffect(function () {
        // as long as it's invalid, keep trying to update

        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        if (default_text.length > 1 && !valid && !checking && !checked) {
            const handle_new = sanitizeHandle(default_text);

            if (utils_strings_isHandle(handle_new, scope)) {
                checkIfHandleExists(handle_new).then(() => {

                });
            }
        }
    }, [default_text, valid, checking]);

    function handleChange(v2, blur = false) {

        let v = v2.replace(/ /g, '');
        setHandleVal(v.toLowerCase().trim());
        setChecked(false);
        setTaken(false);
        setTakenHandle("");
        if (blur) {
            handleBlur();
        }
    }

    function handleBlur() {

        if (handleVal && handleVal === default_text) {
            // is initial
            return;
            setChecking(true);
            checkIfHandleExists(handleVal).then(() => {
            });
        } else if (!utils_strings_isHandle(handleVal, scope)) {
            setHandle('');
            setValid(false);
        } else {
            setValid(true);
            checkIfHandleExists(handleVal).then(() => {
            });
        }
    }

    function checkIfHandleExists(value, skip) {

        if (!skip) {
            setChecking(true);
            if (checkingHandle) {
                checkingHandle();
            }
        }

        if (skip && generating) {
            //
            return;
        } else if (skip && !generating) {
            setGenerating(true);
        }
        //console.log("handle is unique", value, type, scope, scope_id,)
        return handleIsUnique(value, type, scope, scope_id, check_id).then((taken) => {
           // console.log('handleIsUnique', taken, value, type, scope, scope_id)
          //  console.log('current_handle', current_handle, value)
            // handleIsUnique true sean member community J62oUDAvKbZFS91QiaTj
            if (taken && value === default_text) {
                if (value.length > 3) {
                    let rand = Math.floor(Math.random() * (999 - 100 + 1) + 100);
                    checkIfHandleExists(`${value}-${rand}`, true);
                }
                return false;
            } else if (taken) {
                // let parent know
                const val = Math.floor(1000 + Math.random() * 9000);
                setTakenHandle(handleVal);
                let nh = handleVal ? `${handleVal}-${val}` : value ? `${value}-${val}` : `group-${val}`;
                setChecking(false);
                setMetaHandle(handleVal);
                setHandleVal(nh);
                setChecked(true);
                setTaken(true);
                setValid(false);
                setHandle('');
                checkIfHandleExists(`${nh}`, true);
            } else {
                setHandleVal(value);
                setChecking(false);
                setChecked(true);
                setTaken(false);
                setValid(true);
                setHandle(value);
            }
            return taken;
        });
    }

    const success = valid && checked && !taken;
    const error = (taken && checked);
    const hint = success ? <div className="text-xs text-green-700">
        Handle available
    </div> : error ? <div className="text-xs text-red-700">
        Handle not available
    </div> : null;

    /*
         <FieldInput intent={success ? "success" : error ? "danger" : ""}
                    custom={{minLength: 2, maxLength: 80}} type="text" placeholder={placeholder} autoFocus={autoFocus}
                    value={handleVal} onBlur={handleBlur}
                    onChange={(a, b) => handleChange(b)}/>

                     <div className="text-xs pt-1 text-gray-600">
            {getMeta(scope)}
        </div>
     */
    return <div>
        {show_label && <FieldLabel corner_hint={hint} label={getLabel(type, change_mode)}/>}

        <M3_A_Input leading_indicator_leftpad={`pl-[10.7rem]`} leading_indicator={<M3_A_Text color="text-gray-500">app.theorange.app/c/</M3_A_Text>} value={handleVal} onBlur={handleBlur} placeholder={placeholder} auto_focus={autoFocus} onChange={e=>{
            handleChange(e.target.value)
        }} {...{minLength: 2, maxLength: 80}} />



        {taken_handle &&
            <div className="text-xs text-red-600 mt-3"><strong>@{meta_handle}</strong> is already taken</div>}
        {handleVal && checked && !taken && !valid && <div className="text-xs text-red-600 mt-3">Handle is invalid</div>}
    </div>
}