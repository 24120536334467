import React from "react";

export function WB_CoreBulletList({}) {
    return <div className="prose prose-base">
        <ul className="">
            <li>One</li>
            <li>Two</li>
            <li>Three</li>
        </ul>
    </div>
}