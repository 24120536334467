import React, {useEffect, useRef, useState} from 'react';
import Button from "../../../../m3/_legacy_components/button";
import FormFieldsRenderer from "../../../../m3/_legacy_components/form-fields-renderer";
import {isGapiInitialized} from "../../calendar/gapi";
import {authFetch} from "../../../../../api/network";
import {saveItemToLocalStorage} from "../../../../m3/utilities/localstorage";
import {useCommunity} from "../../../../config/community";

function SettingsSection({title, actions, children}) {

    return <div className="flex gap-10 pt-3">
        <div className="flex-grow">
            <div>
                <div className="font-semibold text-base text-gray-800">{title}</div>
            </div>
            <div className="mt-1">
                {children}
            </div>
        </div>
        <div className="flex-none flex gap-2 flex-col pt-2">
            {actions}
        </div>
    </div>
}

function getDefaultConfig(app_id, group_data) {
    const app_data = group_data?.['power_ups']?.[app_id];
    return app_data?.['config'] || {};
}

function shouldHideField(field, fields, data) {
    if (!field.hide) {
        return false;
    }
    for (let i = 0; i < field.hide.length; i++) {
        const hide = field.hide[i];
        if (data[hide.accessor] === hide.value) {
            return true;
        }
    }
    return false;
}

async function signInToGoogle(google_email, community_uid, member_id) {
    console.log("signInToGoogle");
    const is_initiated = isGapiInitialized();
    console.log("is_initiated", is_initiated);
    // get token from the server I guess, then sign in to google with this email and set local storage thing
    // saveItemToLocalStorage(`${community_uid}_${member_id}_gapi-auth`, authResult);
    const payload = {
        google_email,
        community_uid,
        member_id
    }

    const result_token = await new Promise((resolve, reject) => {

        const res = (resp) => {
            if (resp?.data?.token) {
                resolve(resp.data.token);
            } else {
                resolve(null);
            }
        };

        authFetch("/get-gapi-token", res, res, "POST", {payload});
    })

    if (!result_token) {
        console.log("no token");
        return;
    } else {
        console.log("got token", result_token);
    }
    saveItemToLocalStorage(`${community_uid}_${member_id}_gapi-auth`, result_token);
    return;
}

export function AppSettingsPage({
                                    app_data,
                                    app_id,
                                    handleRemoveApp,
                                    updateAppSettings,
                                    group
                                }) {
    const community = useCommunity();
    const [data, setData] = useState(getDefaultConfig(app_id, group));
    const [unsaved_changes, setUnsavedChanges] = useState(false);

    useEffect(function () {
        const searchParams = new URLSearchParams(window.location.search);
        const cbflow = searchParams.has('cbflow');
        const google_email = searchParams.get('google_email');
        const google_auth = searchParams.get('google_auth');
        if (google_auth && google_auth === "success" && google_email && cbflow) {
            console.log("SIGN IN TO GOOGLE, SHOW CALLOUT THAT CONNECT SUCCESSFUL")
            // this will show the calendar selector
            setData({...data, google_user_email: google_email});


            signInToGoogle(google_email,community.uid,community.member_id)
                .then(()=>{
                    console.log("signed in to google")
                    setData({...data, google_user_email: google_email});
                })
        }
    }, [])

    console.log("APP SETTINGS", data)

    function handleOnChange(accessor, new_value, old_value, field, extra) {
        let new_data = {...data, [accessor]: new_value};
        if (field.value_mapping) {
            for (let i = 0; i < field.value_mapping.length; i++) {
                const mapping = field.value_mapping[i];
                if (!!(extra[mapping])) {
                    new_data = {...new_data, [mapping]: extra[mapping]};
                }
            }
        }
        setData(new_data);
        setUnsavedChanges(true);
    }

    const save_btn = unsaved_changes ? <Button text="Save" intent="success" onClick={() => {
        updateAppSettings(data);
        setUnsavedChanges(false);
    }}/> : null;


    return <div className="grid grid-cols-1 gap-4 divide-gray-200 divide-y ">
        {app_data.config_manifest.sections.map((config_sec, k) => {

            const final_fields = config_sec.fields.map((field) => {
                return {
                    ...field,
                    col_span: 2,
                    input_props: {
                        disabled: !!field.disabled,
                        options: field.options
                    }
                }
            }).filter((field) => {
                const hide = shouldHideField(field, config_sec.fields, data);
                return !hide;
            })
            return <SettingsSection actions={<>
                {save_btn}
            </>} key={k} title={config_sec.name}>
                <FormFieldsRenderer onChange={handleOnChange} data={{...data}} fields={final_fields}/>
            </SettingsSection>
        })}
        <SettingsSection actions={<>
            <Button text="Remove App" onClick={() => {
                // prompt the user first if they want to remove the app using window.confirm
                // if they do, then call the remove app endpoint
                // if they don't, then do nothing

                window.confirm("Are you sure you want to remove this app?") && handleRemoveApp()

            }}/>
        </>} title={"Remove App from Group"}>
            <div className="text-gray-700 text-sm">
                <div className="mb-2">
                    Removing this app will remove all data associated with it.
                </div>
            </div>
        </SettingsSection>
    </div>
}