import React from "react";
import {usePagination} from "./use-pagination";
import {ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/20/solid";
import {IconAction} from "../../auth/sign-in";

const DOTS = 10;
export const Pagination = props => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <ul
            className={`grid ${className}`}
            style={{gridTemplateColumns:'4rem 1fr 4rem'}}
        >
            <div className="p-4">
                <li
                    className={`${currentPage === 1 ? "disabled" : ""}`}
                >
                    <IconAction onClick={onPrevious} disabled={currentPage===1}>
                        <ArrowLeftIcon />
                    </IconAction>
                </li>
            </div>

            <div className="p-2 flex items-center text-sm space-x-4 justify-center">
                {paginationRange.map(pageNumber => {
                    if (pageNumber === DOTS) {
                        return <li key="dots" className="pagination-item dots">&#8230;</li>;
                    }

                    return (
                        <li
                            key={pageNumber}
                            className={`pagination-item ${pageNumber === currentPage ? "bg-blue-500 text-white" : "text-gray-600 hover:bg-gray-200"}`}
                            onClick={() => onPageChange(pageNumber)}
                        >
                            {pageNumber}
                        </li>
                    );
                })}
            </div>
            <div className="flex justify-end p-4">
                <li
                    className={`${currentPage === lastPage ? "disabled" : ""}`}
                >
                    <IconAction onClick={onNext} disabled={currentPage === lastPage}>
                        <ArrowRightIcon />
                    </IconAction>
                </li>
            </div>

        </ul>
    );
};