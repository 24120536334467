import React, {useCallback, useEffect, useId as useReactId, useRef, useState} from "react";
import {_m3_c_list_view_utils} from "../components/list-view/utils";
import {useSearchParams} from "react-router-dom";

export function useProvidedRefOrCreate(providedRef) {
    const createdRef = React.useRef(null)
    return providedRef ?? createdRef;
}

export function useDimensions(layout, filtering, inline_sidebar_width, is_mobile, search_open) {
    const [dimensions, setDimensions] = React.useState(() => _m3_c_list_view_utils.getDimensions(filtering ? 3 : 0, inline_sidebar_width, layout, is_mobile));

    useEffect(function () {
        const handleResize = () => {
            const new_dimensions = _m3_c_list_view_utils.getDimensions(filtering ? 3 : 0, inline_sidebar_width, layout, is_mobile);
            setDimensions(new_dimensions);
        };

        window.addEventListener('resize', handleResize);

        setDimensions(_m3_c_list_view_utils.getDimensions(filtering ? 3 : 0, inline_sidebar_width, layout, is_mobile));

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [filtering, inline_sidebar_width, layout, search_open, is_mobile]);

    return dimensions;
}

export function useId(id) {
    const uniqueId = useReactId()
    if (id) {
        return id
    }
    return uniqueId;
}

export function useProvidedStateOrCreate(
    externalState,
    setExternalState,
    defaultState
) {
    const [internalState, setInternalState] = useState(externalState || defaultState);
    const state = externalState ?? internalState
    const setState = useCallback(
        (s) => {
            setInternalState(s)
            if (setExternalState) setExternalState(s)
        },
        [setExternalState],
    )
    return [state, setState]
}

function sidebarIsOpen() {
    const body = document.querySelector("body");
    if (body) {
        return [!body.classList.contains("right-sidebar-collapsed"), body.classList.contains("sidebar-collapsed")];
    } else {
        return [
            false,
            false
        ];
    }
}

export function useCurrentLayout(is_mobile) {
    const [open, setOpen] = useState(sidebarIsOpen());
    const observerRef = useRef(null);

    useEffect(() => {
        if (is_mobile) {
            return;
        }
        const body = document.querySelector("body");
        observerRef.current = new MutationObserver((entry) => {
            setOpen(sidebarIsOpen());
        })
        observerRef.current.observe(body, {
            attributes: true,
            attributeFilter: ["class"],
            childList: false
        });

        return () => {
            observerRef.current.disconnect();
        };
    }, [is_mobile]);

    return open;
}

function removeFromTo(array, from, to) {
    array.splice(
        from,
        !to ||
        1 +
        to -
        from +
        (!((to < 0) ^ (from >= 0)) && (to < 0 || -1) * array.length),
    );
    return array.length;
}

export const useUndoManager = (limit = 10, callback) => {
    const [commands, setCommands] = React.useState([]);
    const [index, setIndex] = React.useState(-1);
    const is_executing = React.useRef(false);

    function execute(command, action) {
        if (!command || typeof command[action] !== 'function') {
            return this;
        }
        is_executing.current = true;

        command[action]();

        is_executing.current = false;
    }

    function addCommand(command) {
        if (is_executing.current) {
            return;
        }

        // if we are here after having called undo,
        // invalidate items higher on the stack
        commands.splice(index + 1, commands.length - index);
        commands.push(command);

        // if limit is set, remove items from the start
        if (limit && commands.length > limit) {
            removeFromTo(commands, 0, -(limit + 1));
        }

        setCommands(commands)
        // set the current index to the end
        setIndex(commands.length - 1);

        if (callback) {
            callback();
        }
    }

    function undo() {
        let command = commands[index];

        if (!command) {
            return;
        }

        execute(command, 'undo');

        setIndex(index - 1);

        if (callback) {
            callback();
        }
    }

    function redo() {
        let command = commands[index + 1];

        if (!command) {
            return this;
        }

        execute(command, 'redo');

        setIndex(index + 1);

        if (callback) {
            callback();
        }
    }

    function clearCommands() {
        let prev_size = commands.length;

        setCommands([]);
        setIndex(-1);

        if (callback && prev_size > 0) {
            callback();
        }
    }

    function hasUndo() {
        return index > -1;
    }

    function hasRedo() {
        return index < commands.length - 1;
    }

    function getIndex() {
        return index;
    }

    function getCommands() {
        return commands;
    }

    return [addCommand, clearCommands, undo, redo, hasUndo, hasRedo, getIndex, getCommands]
}


// this hook listens for a parameter in the URL and if present calls an action
/*
const mock_action = { key: "action", value: "create", trigger = "initialize", handler: () => { console.log("triggered") } }
 */
export const useParamActions = (actions = []) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const completed_actions = useRef({});

    useEffect(function () {

        let to_delete = [];
        for (let action of actions) {
            if (searchParams.has(action.key) && searchParams.get(action.key) === action?.value && action?.ready && !completed_actions.current[action.key]) {
                action?.handler();
                to_delete.push(action.key);
                completed_actions.current[action.key] = true;
            }
        }

        if (to_delete.length > 0) {
            setSearchParams(params => {
                to_delete.forEach(key => {
                    params.delete(key);
                })
                return params;
            })
        }
    }, [searchParams])

    return true;
}