import React from "react";
import {useUnaverse} from "../../../config/unaverse";
import PreferencesModal from "../../../m3/_legacy_components/preferences-modal";
import SimplePortal from "../../../m3/_legacy_components/simple-portal";
import {
    Bars3BottomLeftIcon,
    BeakerIcon, ClipboardDocumentCheckIcon,
    BellIcon, EnvelopeIcon,
    GlobeAmericasIcon, PaintBrushIcon, UserIcon
} from "@heroicons/react/24/outline";
import {useCommunity} from "../../../config/community";
import {MemberPreferencesYourAccount} from "./tabs/your-account";
import {MemberPreferencesNotifications} from "./tabs/notifications";
import {MemberPreferencesLanguageRegion} from "./tabs/language-region";
import {MemberPreferencesAdvanced} from "./tabs/advanced";
import {MemberPreferencesStyle} from "./tabs/sidebar-style";
import {MemberPreferencesPostsEmail} from "./tabs/posts-email";
import {MemberPreferencesSidebarNav} from "./tabs/sidebar-navigation";
import {MemberPreferencesPolicies} from "./tabs/policies";

/*
Your account

Change phone number or email

Change password

Deactivate account

Security & Access
> Apps and sessions
> Security (2FA)
>

Privacy & safety
> mute and block

Notifications
> Preferences
> Email Notifications

> a11y, display

> Language & region

Advanced
 */
export function MemberPreferencesModal({starting_tab = "Your Account"}) {
    const unaverse = useUnaverse();
    const community = useCommunity();

    let opts = [
        {
            label: 'Your Account',
            icon: <UserIcon/>,
            comp: <MemberPreferencesYourAccount member={community.member}/>
        },
        {
            label: 'Notifications',
            icon: <BellIcon/>,
            comp: <MemberPreferencesNotifications member={community.member}/>
        },
        {
            label: 'Region & timezone',
            icon: <GlobeAmericasIcon/>,
            comp: <MemberPreferencesLanguageRegion member={community.member}/>
        },
        {
            label: 'Posts & Email',
            icon: <EnvelopeIcon/>,
            comp: <MemberPreferencesPostsEmail member={community.member}/>
        },
        {
            label: 'Policies',
            icon: <ClipboardDocumentCheckIcon/>,
            comp: <MemberPreferencesPolicies member={community.member}/>
        },
        {
            divider: true
        },
        {
            label: "Sidebar Theme",
            icon: <PaintBrushIcon/>,
            comp: <MemberPreferencesStyle community={community}/>
        },
        {
            label: "Sidebar Navigation",
            icon: <Bars3BottomLeftIcon/>,
            comp: <MemberPreferencesSidebarNav community={community}/>
        },
        {
            divider: true
        },
        {
            label: 'Advanced',
            icon: <BeakerIcon/>,
            comp: <MemberPreferencesAdvanced member={community.member}/>
        },
    ];

    return <SimplePortal center>
        <PreferencesModal size="xl" onClose={() => {
            community.toggleSettings()
        }} starting_tab={starting_tab} options={opts}/>
    </SimplePortal>
}