const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DB_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_ID,
    messagingSenderId: process.env.REACT_APP_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    mapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    calendarApiKey: process.env.REACT_APP_CALENDAR_API_KEY,
    calendarClientID: process.env.REACT_APP_CALENDAR_CLIENT_ID
}
export default config;