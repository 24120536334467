import React, {useEffect, useRef, useState} from "react";
import AsyncCreatableSelect from 'react-select/async-creatable';
import {advanced_select_styles} from "./styles";
import {advanced_select_components} from "./components";
import {collection, limit, endAt, orderBy, query, startAt, getDocs, documentId} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {queryGroupsData, queryMembersData} from "../directory/build-elasticsearch-query";

import {getManyDocuments} from "../../../config/community";
import {buildImageUrl} from "../../../../common/utilities/images";

export const HandleRegex = /^[A-Za-z0-9_-]*$/;
export const HandleRegex1 = /[^A-Za-z0-9_-]/gi;


const umlautMap = {
    '\u00dc': 'ue',
    '\u00c4': 'Ae',
    '\u00d6': 'oe',
    '\u0153': 'oe',
    '\u00fc': 'ue',
    '\u00e4': 'ae',
    '\u00f6': 'oe',
    '\u04e6': 'Oe',
    '\u04e7': 'oe',
    '\u0450': 'e',
    '\u00df': 'ss',
};

export function replaceUmlaute(str) {
    return str.replace(new RegExp('[' + Object.keys(umlautMap).join('|') + ']', "g"),
        (a) => umlautMap[a]
    );
}

export function cleanQuery(str, rep = '-') {
    return replaceUmlaute(str).trim().replace(/ /g, rep).toLowerCase();
}

export function sanitizeHandle(str) {
    // remove spaces
    // underscores and dashes are OK
    let str1 = replaceUmlaute(str).replace(/ /g, "-").replace(HandleRegex1, "");
    str1 = str1.trim();
    str1 = str1.replace(/ /g, '-');
    str1 = str1.toLowerCase();
    // block 4 and under for now special users
    return str1;
}


async function getGroups(ids, community_uid) {
    const docs = await getManyDocuments('community_entities', community_uid, 'groups', documentId(), ids);
    return docs.map(doc => {
        const dt = doc.data();
        return {
            id: doc.id,
            title: dt.name,
            description: dt.purpose||"",
            ...dt
        }
    })
}

async function getMembers(ids, community_uid) {
    const docs = await getManyDocuments('community_members', community_uid, 'members', documentId(), ids);
    return docs.map(doc => {
        const dt = doc.data();
        return {
            id: doc.id,
            first_name: dt.about.first_name,
            last_name: dt.about.last_name,
            ...dt
        }
    })
}

async function queryFB(community_uid) {
    const ref = collection(db, 'community_members', community_uid, 'members');
    const q = query(ref, orderBy("last_sign_in", "desc"), limit(50));
    const snap = await getDocs(q);

    return snap.docs.map(doc => {
        const dt = doc.data();
        return {
            id: doc.id,
            first_name: dt.about.first_name,
            last_name: dt.about.last_name,
            ...dt
        }
    })
}

function getValue(selected, results) {
    if (selected) {
        return selected;
    }
    const a = Object.values(results).filter(a => a.label === selected)[0];
    return a ? a.value : "";
}

function processObjectValue(selected, results) {
    let keys = Object.keys(selected);
    return keys.map(key => {

        if (results[key]) {
            return results[key]
        }

        return {
            label: "..",
            value: key
        }
    })
}

function getShowValue(selected, results = {}) {
    if (selected && typeof selected === "object") {

        return processObjectValue(selected, results);
    }
    const id = getValue(selected, results);

    if (id && results[id]) {
        return results[id];
    }

    return !selected ? "" : {
        label: id ? "..." : selected,
        value: id
    }
}

function getField(obj, arr) {
    if (obj && Object.values(obj).length) {
        return Object.values(obj)[0]._search ? "_search" : "label";
    }
    if (arr && arr[0]) {
        return arr[0]._search ? "_search" : "label";
    }
    return "label";
}

function filterResults(res, existing, q) {

    const f = getField(res, existing);

    const me = existing.map(a => a[f]);

    const final = Object.values(res).filter(a => me.indexOf(a[f]) === -1);
    if (q) {
        return final.filter(a => {
            // !!fuzzyContains(cleanQuery(a[f]),q)
            //   const contains = fuzzyContains(cleanQuery(a[f], ' '),q,1)

            return cleanQuery(a[f]).indexOf(q) !== -1;
        });
        //return final.filter(a=>cleanQuery(a[f]).indexOf(q) !== -1)
    }
    return final;
}

export function buildGroupsQuery(qu, scope_id = "") {
    console.log("buildGroupsQuery", qu, scope_id)
    return {
        current: 0,
        size: 25,
        result_fields: {
            title: {raw: {}},
            image: {raw: {}},
            scope: {raw: {}},
            description: {raw: {}},
            handle: {raw: {}},
        },
        search_fields: {
            title: {},
            handle: {},
            description: {}
        },
        sort: {
            title: "asc"
        },
        query: qu,
        filters: {
            all: [
                {
                    "type": "group"
                },
                {
                    "scope": scope_id
                }
            ],
            any: [],
            none: [],
        },
    };
}

export function buildMembersQuery(qu) {
    return {
        current: 0,
        size: 25,
        result_fields: {
            name: {raw: {}},
            first_name: {raw: {}},
            last_name: {raw: {}},
            account_status: {raw: {}},
            member_type: {raw: {}},
            profile_picture: {raw: {}},
            handle: {raw: {}},
        },
        search_fields: {
            name: {},
            email: {},
            first_name: {},
            last_name: {}
        },
        sort: {
            last_name: "asc"
        },
        query: qu,
        filters: {
            all: [],
            any: [],
            none: [],
        },
    };
}

function parseId(a) {
    const dash_count = (a.id.match(/-/g) || []).length;
    if (dash_count === 1) {
        return a.id.split("-")[1];
    } else if (dash_count === 2) {
        return a.id.split("-")[2];
    } else {
        return a.id;
    }
}

function processResults(arr) {
    return arr.map(a => {
        return {
            ...a,
            id: parseId(a)
        }
    })
}

async function queryMembers(request, community_uid) {
    return await queryMembersData(request, community_uid)
        .then(resp => {
            if (resp.results) {
                return {
                    members: [...processResults(resp.results)],
                    page: resp.page
                }
            } else {
                return null
            }
        });
}

async function queryGroups(request, community_uid) {
    return await queryGroupsData(request, community_uid)
        .then(resp => {
            if (resp.results) {
                return {
                    groups: [...processResults(resp.results)],
                    page: resp.page
                }
            } else {
                return null
            }
        });
}

function arrayToObj(arr, image_fb = "") {
    let a = {};
    // todo optimize for new small
    arr.forEach(it => {
        a[it.id] = {
            value: it.id,
            label: it.name,
            _search: `${it.name} ${it.first_name} ${it.last_name} ${it.handle}`,
            image: buildImageUrl(it.profile_picture, "_medium", image_fb)
        };
    })
    return a;
}

function arrayToObjGroups(arr, image_fb) {
    let a = {};
    arr.forEach(it => {
        a[it.id] = {
            value: it.id,
            label: it.title,
            _search: `${it.title} ${it.description}`,
            image: ""
        };
    })
    return a;
}

/*
TODO
- Debounce DONE
- Images for menu and value DONE
- third character thing // weird loading behavior / not showing results DONE
- preview items DONE
- TODO load member values
 */

export function EntitiesAsyncSelect({
                                        autoFocus = false,
                                        multi = false,
                                        existing = [],
                                        type = "members",
                                        value = null,
                                        community_uid,
                                        image_fallback = "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2F1868I1QUR5",
                                        placeholder = "Start typing..",
                                        onChange = () => {
                                        }
                                    }) {
    const [query, setQuery] = useState("");
    const [selected, setSelected] = useState(value);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState({});
    const [last_q, setLastQ] = useState("");
    const [debounce, setDebounce] = useState({});
    const preloaded = useRef(false);
    const loaded_defaults = useRef(false);

    useEffect(() => {
        const {cb, delay} = debounce;
        if (cb) {
            const timeout = setTimeout(cb, delay);
            return () => clearTimeout(timeout);
        }
    }, [debounce]);

    useEffect(function () {
        setSelected(value);
    }, [value])

    useEffect(function () {
        if (preloaded.current) {
            return;
        }

        if (value) {
            preloaded.current = true;

            let ids = typeof value === "string" ? [value] : Object.keys(value);

            let missing = ids.filter(a => !results[a]);
            console.log("MISSING", missing,type);

            if (missing.length > 0) {
                if (type === "groups") {
                    console.log("MISSING GROUPS", missing);
                    getGroups(missing, community_uid)
                        .then(res => {
                            const res1 = arrayToObjGroups(res || [], image_fallback);
                            const new_results = {...res1, ...results};
                            setResults(new_results);
                        })
                } else {
                    getMembers(missing, community_uid)
                        .then(res => {
                            const res1 = arrayToObj(res || [], image_fallback);
                            const new_results = {...res1, ...results};
                            setResults(new_results);
                        })
                }
            } else {
                if (!loaded_defaults.current) {
                    loaded_defaults.current = true;
                    loadOptions("*", (rr) => {
                    });
                }
            }
        } else {
            if (!Object.keys(results).length) {
                if (!loaded_defaults.current) {
                    loaded_defaults.current = true;
                    loadOptions("*", (rr) => {
                    });
                }
            }
        }
    }, [value, community_uid, results])

    function loadOptions(q, cb) {
        const fq = cleanQuery(q, ' ');
        if (fq.length > 0 && last_q !== fq) {
            if (q === "*") {
                if (type === "members") {
                    queryFB(community_uid)
                        .then(res => {
                            const res1 = arrayToObj(res || [], image_fallback);
                            const new_results = {...results, ...res1};
                            setResults(new_results);
                            cb(filterResults(new_results, existing));
                        })
                } else {
                    // todo preload
                    cb(filterResults({}, existing));
                }

            } else {
                setLoading(true);
                if (type === "members") {
                    const esq = buildMembersQuery(fq);
                    queryMembers(esq, community_uid)
                        .then(res => {
                            const res1 = arrayToObj(res.members || [], image_fallback);
                            const new_results = {...results, ...res1};
                            setResults(new_results);
                            setLastQ(fq);
                            setLoading(false);
                            cb(filterResults(new_results, existing, fq));
                        })
                } else {
                    const esq = buildGroupsQuery(fq, community_uid);
                    queryGroups(esq, community_uid)
                        .then(res => {
                            const res1 = arrayToObjGroups(res.groups || [], image_fallback);
                            const new_results = {...results, ...res1};
                            setResults(new_results);
                            setLastQ(fq);
                            setLoading(false);
                            cb(filterResults(new_results, existing, fq));
                        })
                }
                /*
                setDebounce({
                    cb: async () => {
                        setLoading(true);
                        const esq = getQuery(fq);
                        queryES(esq,community_uid)
                            .then(res=>{
                                const res1 = arrayToObj(res.members||[],image_fallback);
                                const new_results = {...results, ...res1};
                                setResults(new_results);
                                setLastQ(fq);
                                setLoading(false);
                            })
                    },
                    delay: 100 // ms
                });
                cb(filterResults(results, existing, fq));

                 */
            }
        } else {
            cb(filterResults(results, existing, fq));
        }
    }

    function handleInputChange(q) {
        setQuery(q);
    }

    function handleChange(it) {
        console.log("CHANGE", it)
        if (!it) {
            setSelected(null);
            onChange('');
            setQuery("");
        } else {
            if (!multi) {
                setSelected(it.value);
                setQuery("");
            } else {
                let f = {};
                it.forEach(n => {
                    f[n.value] = true;
                })
                setSelected(f);
            }

            onChange(it);
        }
    }

    const v_value = getShowValue(selected, results);

    return (
        <AsyncCreatableSelect
            cacheOptions
            backspaceRemovesValue
            captureMenuScroll
            isSearchable
            placeholder={placeholder}
            defaultOptions={filterResults(results, existing)}
            onInputChange={(v) => handleInputChange(v)}
            autoFocus={autoFocus}
            menuShouldScrollIntoView
            isMulti={multi}
            isLoading={loading}
            classNamePrefix={"dropdown-selected"}
            menuPortalTarget={document.body}
            styles={advanced_select_styles}
            components={advanced_select_components}
            isClearable
            isValidNewOption={() => false}
            onChange={(v) => handleChange(v)}
            value={v_value}
            loadOptions={(q, cb) => loadOptions(q, cb)}
        />
    );
}