import React, {useState} from "react";
import {LayoutComplex, LayoutStandard} from "../../../m3/_legacy_components/app-frame/layouts";
import {authFetch} from "../../../../api/network";
import Button from "../../../m3/_legacy_components/button";
import {logEngagementEvent} from "../../../config/firebase-setup";

export function ManageMigrationsPage() {
    const [value, setValue] = useState("");
    function runMigration(type) {
        const payload = {type};
        alert("Starting...")
        const res = (resp) => {
            console.log("RESP",resp)
            alert("Done.. check console for logs")
        }
        authFetch("/superadmin/migrations", res, res, "POST", {payload});
    }

    function runTest(type) {
        const payload = {type};
        alert("Starting...")
        const res = (resp) => {
            console.log("RESP",resp)
            alert("Done.. check console for logs")
        }
        authFetch("/test", res, res, "POST", {payload});
    }

    function testCalendarSync(type) {
        // test-calendar-updater

        const payload = {};

        const res = (resp) => {
            console.log("RESP",resp)
        };

        authFetch("/test-calendar-updater", res, res, "POST", {payload});
    }

    function testFollow(type) {
        const payload = {type};
        const res = (resp) => {
            console.log("RESP",resp)
        }
        authFetch("/follow/unfollow", res, res, "POST", {payload});
    }
    return <LayoutStandard>
        <div>
        <div className="mb-1">
            Run migration:
            <div className="flex gap-2 flex-wrap">
                <Button text="Re-build Member Token Index" onClick={()=>runMigration('build_member_tokens_index')} />
                <Button text="Unaverse Profiles" onClick={()=>runMigration('unaverse_profiles')} />
                <Button text="Global Search Index" onClick={()=>runMigration('global_search_index')} />
                <Button text="Catch-up docs" onClick={()=>runMigration('catchup_events_feeds')} />
                <Button text="Community Search Index" onClick={()=>runMigration('community_search_index')} />
                <Button text="Membership Status Maps" onClick={()=>runMigration('membership_status_maps')} />
                <Button text="Rebuild Member Index" onClick={()=>runMigration('index-all-members')} />
                <Button text="Member Core Enrichments" onClick={()=>runMigration('members_filters')} />
                <Button text="Member Profile Cleanup" onClick={()=>runMigration('member_profile_cleanup')} />
                <Button text="Test Community Index" onClick={()=>runTest()} />
                <Button text="Test Calendar Sync" onClick={()=>testCalendarSync()} />
                <Button text="Test Follow" onClick={()=>testFollow()} />
            </div>
        </div>
        </div>
    </LayoutStandard>
}