import React, {useEffect, useState} from 'react';
import {getPostDeliveryData, getSendStatus} from "../../../features/forum/forum/api";
import {BellIcon, EnvelopeIcon} from "@heroicons/react/24/outline";

function transformData(data) {
    let arr = [];

    const items = Object.entries(data.recipients);

    items.forEach(item => {
        const [email, {ts, member_id, status}] = item;

        if (member_id) {
            arr.push({
                email,
                member_id,
                ts,
                status
            });
        }
    });

    return arr.sort((a, b) => (a.ts < b.ts) ? 1 : -1);
}

function parseStatus(st) {
    if (st === 'delivered') {
        return 'Delivered'
    } else {
        return 'Could not be delivered'
    }
}

function getStatusOfSending(post, has_results) {
    if (!post.mailgun_sent_to_count) {
        return 'hidden'
    }
    if (!has_results) {
        return 'sending'
    }
    return 'delivered'
}

function Message({total = 0, bell, message, show_error, errors = 0}) {
    let m;
    if(message) {
        m = message;
    } else if (total) {
        m = `Sent via email to ${total} members`;
    }
    if(show_error) {
        m = `${m} (${errors} could not be delivered)`
    }

    return <div className='flex gap-2 items-center text-gray-600'>
        <div className="w-4 self-start pt-0.5 flex-none h-4">
            {bell?<BellIcon />:<EnvelopeIcon/>}
        </div>
        <div className="text-sm">
            {m}
        </div>
    </div>
}

export function PostStatsDelivered({post, is_author_or_admin, community_uid, list_id}) {
    const [data, setData] = useState(null);
    const [status, setStatus] = useState(null);
    const [modal, setModal] = useState(null);
    const [tab, setTab] = useState('');
    const [page, setPage] = useState(0);

    useEffect(function () {
        if (post && post.mailgun_message_id) {
            getPostDeliveryData(community_uid, post.id, post.mailgun_message_id)
                .then(dt => {
                    if (!dt) {
                        return;
                    }
                    setData(dt);
                });

            getSendStatus(community_uid, post.id, post.mailgun_message_id, list_id)
                .then(it => {
                    if (!it) {
                        return;
                    }
                    setStatus(it);
                })
        }
    }, [post]);

    if (!data) {
        return null;
    }

    const t_data = transformData(data);


    if (t_data.length === 0) {
        return null;
    }

    const delivered = t_data.filter(a => a.status === 'delivered');
    const delivered_count = delivered.length;
    const failed = t_data.filter(a => a.status === 'permanent_fail');
    const failed_count = failed.length;

    const total_sent_to = post.mailgun_sent_to_count || 0;

    let sending_status = getStatusOfSending(post, delivered_count > 0 || failed_count > 0);

    if (sending_status === 'hidden') {
        return null;
    } else if (sending_status === 'sending') {
        if (!total_sent_to) {
            return null;
        }
        // Notified ${total_sent_to} people
        return <Message bell message={`Notified ${total_sent_to} people`} />;
    }

    const total_count = total_sent_to;

    /*
    let stats = [
        {
            number: total_count,
            label: "Notified",
            onClick: () => setModal(1)
        },

        {
            number: delivered_count,
            label: "Delivered",
            onClick: () => setModal(2)
        },


        {
            number: failed_count,
            label: "Errors",
            onClick: () => setModal(2)
        }
    ];

     */

    let all_metadata = {};

    t_data.forEach(it => {
        all_metadata[it.member_id] = it;
    })

    /*
    const tabs = [
        {
            label: `Notified ${total_count}`,
            type: "member-list",
            community_uid: community_uid,
            ids: t_data.map(it=>it.member_id),
            renderer: (data,id) => {
                return <MemberItem data={data} metadata={all_metadata[id]} />
            }
        },

        {
            label: `Delivered ${delivered_count}`,
            type: "member-list",
            community_uid: community_uid,
            ids: delivered.map(it=>it.member_id),
            renderer: (data,id) => {
                return <MemberItem data={data} metadata={all_metadata[id]} />
            }
        },


        {
            label: `Errors ${failed_count}`,
            type: "member-list",
            community_uid: community_uid,
            ids: failed.map(it=>it.member_id),
            renderer: (data,id) => {
                return <MemberItem data={data} metadata={all_metadata[id]} />
            }
        }
    ];

     */


    return <Message show_error={is_author_or_admin&&failed_count>0} total={total_count} errors={failed_count}/>;
}