// https://docs.google.com/document/d/1kDADHyzpu1-5_ZZlQ-sR8SVmF0cABVXWafRW7qPcr8k/edit

export const data__member_profile_sections = {
    'core': {
        'label': "Core",
        "description": "Core information required for all members",
        "fields": ['id','handle','account-email','display-name','cover-photo']
    },
    'preferences': {
        'label': "Preferences",
        "description": "Preferences for the member",
        "meta": {
            "onboarding_block": false
        },
        "fields": ['language','timezone']
    },
    'introduction': {
        'label': "Introduction",
        "description": "Introduction of the member",
        "meta": {
            "onboarding_block": true
        },
        "fields": ['headline','bio','profile-picture','first-name','last-name','title','middle-name']
    },
    'basic-information': {
        'label': "Basic Information",
        "description": "Basic information about the member",
        "meta": {
            "onboarding_block": true,
            "onboarding_select_fields": true
        },
        "fields": ["full-name","location","occupation","languages","birthday","hometown"]
    },
    'contact-information': {
        'label': "Contact Information",
        "description": "Contact information of the member",
        "meta": {
            "onboarding_block": true
        },
        "fields": ["email","phone","website","social-links"]
    },
    'membership': {
        'label': "Membership",
        "description": "Membership details of the member",
        "meta": {
            "onboarding_block": false
        },
        "fields": ["member-type","membership-status","join-date","referred-by","spaces","positions"]
    },
    'interests': {
        'label': "Interests",
        "description": "Interests of the member",
        "meta": {
            "onboarding_block": false
        },
        "fields": ["interests"]
    },
    'details': {
        'label': "Details",
        "description": "Details of the member",
        "meta": {
            "onboarding_block": true,
            "onboarding_select_fields": true
        },
        "fields": ["sex","address","industry","country","city","state","neighborhood"]
    },
    'work-and-education': {
        'label': "Work and Education",
        "description": "Work and education details of the member",
        "fields": ["work","education"],
        "is_disabled": true
    },
    'family-and-relationships': {
        'label': "Family and Relationships",
        "description": "Family and relationships details of the member",
        "fields": [],
        "is_disabled": true
    },
    '__custom': {
        'label': "Custom",
        "description": "Custom sections",
        "meta": {
            "onboarding_block": true
        },
        "is_disabled": true
    }
};