import React, {useEffect, useRef} from "react";
import {getManyMembers} from "../../routes/community/members/create";

const sizes = ["2xs", "xs", "sm", "base", "lg", "xl", "2xl"];

// if start === "sm" and change === 1, then the next size is "base"
// if start === "sm" and change === -1, then the next size is "xs"
export function m3_adjust_size(start = "sm", change = 1) {
    const start_index = sizes.indexOf(start);
    const next_index = start_index + change;

    if (next_index < 0) {
        return sizes[0];
    } else if (next_index >= sizes.length) {
        return sizes[sizes.length - 1];
    } else {
        return sizes[next_index];
    }
}

const transformForLocal = (doc) => {
    const record = doc.data();
    const now = new Date();
    return {
        __updated_at: now,

        id: doc.id,
        name: record?.name,
        about: {
            first_name: record?.about?.first_name,
            last_name: record?.about?.last_name,
            location: record?.about?.location,
            occupation: record?.about?.occupation,
        },
        profile_picture: record?.profile_picture,
    }
}

function handleUpdateLocalStorage(context_id, data) {
    const key = `m3_${context_id}_members_cache`;

    // update localstorage
    const existing = localStorage.getItem(key);

    let base = [];

    if (existing) {
        // merge and update
        const records = JSON.parse(existing);

        base = records.map(record => {
            const updated = data.find(d => d.id === record.id);
            if (updated) {
                return updated;
            } else {
                return record;
            }
        });
    }

    // add new records
    data.forEach(record => {
        if (!base.find(r => r.id === record.id)) {
            base.push(record);
        }
    });

    localStorage.setItem(key, JSON.stringify(base));
}

function getInitialData(context_id, ids) {
    // check localstorage for valid records
    const key = `m3_${context_id}_members_cache`
    const local_member_cache = localStorage.getItem(key);

    if (local_member_cache) {
        const records = JSON.parse(local_member_cache);
        return records.filter(record => ids.includes(record.id)).filter(record => {
            // only return records that are not expired
            // records expire after 1 day
            const now = new Date();
            const updated_at = new Date(record.__updated_at);
            const diff = now - updated_at;
            return diff < 86400000;
        });
    } else {
        return [];
    }
}

const getInitialLoadingState = (ids, initial_data) => {
    const loading = {};

    ids.forEach(id => {
        const found = initial_data.find(record => record.id === id);
        if (found) {
            loading[id] = true;
        }
    });
    return loading;
};

// add local caching
export function M3DataWrapper({renderChildren, context_id = "", ids = []}) {
    const initial_data = getInitialData(context_id, ids);
    const [data, setData] = React.useState(initial_data);
    const loading_obj = useRef(getInitialLoadingState(ids, initial_data));
    useEffect(() => {
        if (ids.length === 0) {
            return;
        }
        const to_load = ids.filter(id => !loading_obj.current[id]);

        if (to_load.length === 0) {
            return;
        }
        handleLoad(to_load)
    }, [ids, context_id]);

    useEffect(() => {
        if (data.length > 0) {
            handleUpdateLocalStorage(context_id, data);
        }
    }, [context_id, data]);

    function handleLoad(to_load) {
        to_load.forEach(id => {
            loading_obj.current[id] = true;
        });

        getManyMembers(context_id, to_load)
            .then((member_docs) => {
                const records = member_docs
                    .map(doc => transformForLocal(doc));
                setData([...data, ...records]);
            });
    }
    return renderChildren(data);
}

export function getTimeGreeting() {
    let today = new Date()
    let curHr = today.getHours()

    if (curHr < 12) {
        return "Good morning"
    } else if (curHr < 18) {
        return "Good afternoon"
    } else {
        return "Good evening"
    }
}