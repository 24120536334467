import React from 'react';
import DataWrapper from "../../../m3/_legacy_components/data-wrapper";
import {useCommunity} from "../../../config/community";
import {utils_dates_format} from "../../../../common/utilities/dates";

function RenderName({data}) {
    return data.name
}

export function TextLinksFooter({group,links=[]}) {
    const community = useCommunity();
if(!group||!group.created_by) {
    return null;
}
    return <div className="space-y-0.5 py-1 text-[0.75rem] text-gray-500">
        <div className="space-x-3 flex">
            {links.map((link,k)=>{
                return <div onClick={()=>{
                    if(link.onClick) {
                        link.onClick();
                    }
                }} className="cursor-pointer hover:underline" key={k}>
                    {link.label}
                </div>
            })}
        </div>
        <div>Created by <DataWrapper id={group.created_by} type={`community_members.${community.id}.members`}><RenderName
            onOpen={() => {}}/></DataWrapper> on {utils_dates_format(group.created_at).full_date}</div>
    </div>
}