import React, {useEffect, useState} from "react";
import Accordion from "../../../m3/_legacy_components/accordion";
import {ArrowLeftIcon} from "@heroicons/react/20/solid";
import Button from "../../../m3/_legacy_components/button";
import {EditMemberAbout} from "./edit-sections/intro";
import {updateFieldValue} from "../../../m3/_legacy_components/form-fields-renderer";
import {useCommunity} from "../../../config/community";
import {EditMemberCustomFields} from "./edit-sections/custom";
import {member_editProfile, member_editOtherProfile} from "../member/api";
import AddSecondaryEmail from "../../../m3/_legacy_components/user-email-manager";
import {AdMeActionChangeAccountEmail} from "../member/change-account-email";
import {authFetch} from "../../../../api/network";
import {useToasts} from "../../../config/toasts";
import {isAnonymousUnatyEmail} from "../../../m3/utilities/email";
import {logEngagementEvent} from "../../../config/firebase-setup";
import {buildCommonMemberProfileSections} from "../member/profile/utilities";
import {EditMemberStandardSection} from "./edit-sections/standard";

const code_messages = {
    'known-member': 'Member has already logged in - they should change the email themselves',
    '': 'Not sure!',
    'email-belongs-to-existing-member': 'Email already taken by another member',
};

export function buildMemberEditSections(dt, handleChange, community, fns, flow) {
    const profile_sections = buildCommonMemberProfileSections("edit", dt.id, dt, community?.profile_definitions?.sections, community);

    let b = [
        {
            title: "Intro",
            component: <EditMemberAbout flow={flow} fns={fns} data={dt} onChange={handleChange}/>
        }
    ];

    profile_sections.forEach((section, index) => {
        console.log("FOCUS --- SECTION", section)
        if (section && !section.hide_section && section?.visible_fields?.length > 0) {
            if (section.is_custom_section) {
                b.push({
                    title: section.label,
                    component: <EditMemberCustomFields community={community} flow={flow} fns={fns} data={dt}
                                                       onChange={handleChange} fields={section?.visible_fields}/>
                })
            } else {
                b.push({
                    title: section.label,
                    component: <EditMemberStandardSection fields={section?.visible_fields} data={dt}
                                                          onChange={handleChange} section_id={section.id} fns={fns}/>
                })
            }
        }
    });

    /*

    // todo start with intro section, but this should be same as common member profile
    b = b.concat([
        {
            title: "Intro",
            component: <EditMemberAbout flow={flow} fns={fns} data={dt} onChange={handleChange}/>
        },
        {
            title: "Basic Information",
            component: <EditMemberBasicInformation flow={flow} fns={fns} data={dt} onChange={handleChange}/>
        },
        {
            title: "Contact  Information",
            component: <EditMemberContact flow={flow} fns={fns} data={dt} onChange={handleChange}/>
        },
    ]);

    if (flow === "manage") {
        b.push({
            title: "Membership",
            component: <EditMemberGeneral flow={flow} fns={fns} data={dt} onChange={handleChange}/>
        })
    }

    b = b.concat([
        {
            title: "Details",
            component: <EditMemberDetails flow={flow} fns={fns} data={dt} onChange={handleChange}/>
        }
    ]);

    Object.entries(community.custom_fields).map(([key, cfs], index) => {
        b.push({
            title: cfs.name,
            component: <EditMemberCustomFields community={community} flow={flow} fns={fns} data={dt}
                                               onChange={handleChange} section={{id: key, ...cfs}}/>
        })
    })

     */

    return b;
}

export function ArrowAction({
                                children,
                                disabled,
                                size = 'w-6 h-6',
                                icon_size = 'h-5 w-5',
                                onClick,
                                colors = ' text-gray-500 hover:text-gray-800'
                            }) {
    return <div onClick={() => {
        if (onClick && !disabled) {
            onClick();
        }
    }}
                className={`${size} ${disabled ? "opacity-50" : "hover:bg-gray-100 cursor-pointer"} rounded-full flex items-center justify-center ${colors}`}>
        <div className={`${icon_size}`}>
            {children}
        </div>
    </div>
}

export function MiniHeader({onClose, title, actions}) {
    return <div className="flex">
        <div className="flex-grow space-x-3 flex items-center">
            <div className="-m-0.5">
                <ArrowAction onClick={() => onClose()}>
                    <ArrowLeftIcon/>
                </ArrowAction>
            </div>
            <div className="font-bold text-gray-800 text-lg">
                {title}
            </div>
        </div>
        <div className="flex space-x-2">
            {actions}
        </div>
    </div>
}

export async function handleChangeMemberAccountEmail(community, user_uid, id, new_email) {
    const payload = {
        member_id: community.member_id,
        community_uid: community.uid,
        id: id,
        new_email,
        member_user_uid: user_uid,
        user_uid: community.member.user_uid,
    };


    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp);
        };

        authFetch("/members/change-account-email", res, res, "POST", {payload});
    })
}

export function EditMember({flow = "standard", cb, onClose, padding_x = "px-4", hide_header, data = {}}) {
    const community = useCommunity();
    const toasts = useToasts();
    const [state, setState] = useState("");
    const [loading, setLoading] = useState("");
    const [dt, updateDt] = useState({...data});
    const [unsaved_changes, setUnsavedChanges] = useState({});
    const [modal, setModal] = useState(null);
    const [modal_data, setModalData] = useState(null);

    useEffect(function () {
        if (data._highlighted_field) {
            const el = document.getElementById(data._highlighted_field);
            if (el) {
                el.scrollIntoView()
            }
        }
    }, [data])

    function handleChange(accessor, new_value, old_value, field) {
        if (JSON.stringify(new_value) === JSON.stringify(old_value)) {
            return;
        }
        updateDt({
            ...updateFieldValue(accessor, new_value, dt)
        })
        if (Array.isArray(accessor)) {
            let new_unsaved_changes = {...unsaved_changes};
            accessor.forEach((a, i) => {
                new_unsaved_changes[a] = true;
            })
            setUnsavedChanges(new_unsaved_changes);
        } else {
            setUnsavedChanges({...unsaved_changes, [accessor]: true})
        }
    }

    function handleSave() {
        setState("saving")
        if (flow === "manage") {
            logEngagementEvent("edit_other_profile", {}, community.uid);
            member_editOtherProfile(community, dt.id, {profile: dt, updated_fields: Object.keys(unsaved_changes)})
                .then(() => {
                    setUnsavedChanges({});
                    setState("");
                    if (cb) {
                        cb();
                    }
                    onClose();
                })
        } else {
            logEngagementEvent("edit_my_profile", {}, community.uid);
            member_editProfile(community, dt.id, {profile: dt, updated_fields: Object.keys(unsaved_changes)})
                .then(() => {
                    setUnsavedChanges({});
                    setState("");
                    if (cb) {
                        cb();
                    }
                    onClose();
                })
        }
    }

    function changeAccountEmail(new_email) {
        setLoading('changing-account-email');
        toasts.addToast({text: `Changing account email`, intent: 'info'});

        handleChangeMemberAccountEmail(community, dt.user_uid, dt.id, new_email)
            .then((resp) => {
                setLoading('');
                setModal("");
                console.log("RESP", resp);
                if (resp.data.ok === 'yes') {
                    // history.push(`/${community.data.handle}/admin/member/${resp.data.id}`);
                    toasts.addToast({text: `Account Email changed`, intent: 'success'});
                    if (resp.data && resp.data.member) {
                        console.log("ACCOUNT EMAIL CHANGED", resp.data.member)
                        if (cb) {
                            cb();
                        }
                        updateDt({...dt, ...resp.data.member})
                    }
                } else {
                    // no error is shown
                    toasts.addToast({
                        text: resp.data.code && code_messages[resp.data.code] ? code_messages[resp.data.code] : "Something went wrong..",
                        intent: 'danger'
                    });
                }
            })
    }

    const fns = {
        addEmail: () => {
            if (isAnonymousUnatyEmail(dt.account_email)) {
                setModal("change-email");
            } else {
                setModal("add-secondary");
            }
        }
    };

    const sections = buildMemberEditSections(dt, handleChange, community, fns, flow);

    const unsaved_changes_count = Object.keys(unsaved_changes).length;

    const default_email = dt?.account_email || "";

    const actions = <>
        <Button size="small" loading={state === "saving"} onClick={handleSave} disabled={!unsaved_changes_count}
                text="Save" intent="success"/>
    </>;

    return <>
        <div className="relative pb-24" style={{minHeight: '20rem'}}>
            {!hide_header &&
                <div className="px-4 sticky top-0 rounded-t-lg bg-white z-10 py-2 border-b border-gray-200">
                    <MiniHeader actions={actions} title="Edit Profile" onClose={onClose}/>
                </div>}
            <div className="space-y-2 divide-y divide-gray-200 pb-4" id="edit-wrapper">
                {sections.map((sec, k) => {
                    return <div id={`_edit-${sec.title}`} key={k} className={"pt-2"}>
                        <Accordion action={unsaved_changes_count ? actions : null} open={true} title={sec.title}
                                   subtitle={sec.subtitle} padding_x={padding_x}>
                            <div className="pt-1">
                                {sec.component}
                            </div>
                        </Accordion>
                    </div>
                })}
            </div>
            {modal === "add-secondary" && <AddSecondaryEmail
                user_uid={dt.user_uid}
                community_uid={community ? community.uid : ""}
                onClose={() => {
                    setModal(null);
                    setModalData(null);
                }}/>}
            {modal === 'change-email' && <AdMeActionChangeAccountEmail
                default_email={default_email}
                loading={loading === 'changing-account-email'} community_uid={community.uid}
                onConfirm={(h) => changeAccountEmail(h)}
                onClose={() => {
                    setModal("");
                }} onCancel={() => {
                setModal("");
            }}
            />}
        </div>
    </>
}