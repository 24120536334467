import React, {useState} from "react";
import Button from "../../../m3/_legacy_components/button";
import {chunk, getManyDocuments, useCommunity} from "../../../config/community";
import {useToasts} from "../../../config/toasts";
import {ArrowRightIcon} from "@heroicons/react/24/outline";
import {TextAction} from "../../auth/sign-in";
import {CreateMembersForm} from "./create-form";
import {authFetch} from "../../../../api/network";
import {documentId} from "firebase/firestore";
import MemberImportSummary from "./member-import-summary";
import ProgressBar from "../../../m3/_legacy_components/progress-bar";
import {trace} from "firebase/performance";
import {app_perf, logEngagementEvent} from "../../../config/firebase-setup";
import {ModalBody, ModalContentBlock, ModalFooter, ModalHeader} from "../../../m3/_legacy_components/preview-overlay";
import {api_addPeopleToGroup} from "../../../../api/groups/add-people";
import {membersBuildInviteTo} from "../manage-member";


export async function getManyMembers(cid, fids) {
    if(!cid || !fids || fids.length === 0) {
        console.log("NO CID OR FIDS",{
            cid,
            fids
        });
        return [];
    }
    let p = [];
    const chunks = chunk(fids, 10);
    chunks.forEach(a => {
        p.push(getManyDocuments('community_members', cid, 'members', documentId(), a));
    })
    return await Promise.all(p).then(arrs => {
        return arrs.flat();
    });
}

async function prepSendInvites(community, ids) {
    return await getManyMembers(community.id, ids);
}

function Step({label, num, active, active_num}) {
    const phase = active ? "active" : active_num > num ? "disabled" : "";
    return <div className="flex items-center space-x-1.5">
        <div
            className={`w-5 h-5 rounded-full  text-xs font-medium flex justify-center items-center ${phase === 'active' ? "bg-selection text-white" : phase === 'disabled' ? "bg-gray-200 text-gray-400" : "bg-gray-200 text-gray-600"}`}>{num}</div>
        <div
            className={`${phase === 'active' ? "text-selection font-semibold" : phase === 'disabled' ? "text-gray-500 font-semibold" : "text-gray-600 font-medium"} text-xssm `}>{label}</div>
    </div>
}

function SimpleSteps({steps, active}) {

    return <div className="flex space-x-4">
        {steps.map((st, k) => {
            return <Step key={st.label} {...st} num={k + 1} active={k === active}/>
        })}
    </div>
}

export function CreateMembers({
                                  space_id,
                                  cb = () => {
                                  }, flow = 'generic', initial = [], onConfirmed = () => {
    }, onClose = () => {
    }
                              }) {
    console.log("CREATE MEMBERs", flow)
    const toasts = useToasts();
    const community = useCommunity();
    const [step, setStep] = useState(1);
    const [members, setMembers] = useState([]);
    const [results, setResults] = useState({
        added: [],
        existing: [],
        error: [],
    })
    const [stage, setStage] = useState('');

    function create() {
        const t_add_member = trace(app_perf, "ADD_MEMBER");
        t_add_member.start();
        const b = Date.now();
        if (stage === 'creating') {
            return;
        }

        setStage('creating')
        nextStep('loading');

        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            early_response: true,
            members: members
        };

        const res = (resp) => {
            const c = Date.now();
            t_add_member.stop();
            console.log("TIME:", (c - b), 'ms')
            handleResult(resp);
        };

        authFetch("/members/add-many", res, handleError.bind(this), "POST", {payload});
    }

    function handleResult(result) {
        toasts.addToast({text: `Members were added.`, intent: 'success'});

        if (cb && result && result.data && result.data.added && result.data.added.length) {
            cb(result.data.added.map(it => it.member_id));
        }

        if (result.ok) {
            logEngagementEvent("create_members", {}, community.uid);
            setResults(result.data)
            nextStep('ready');
            setStage('');
        } else {
            nextStep('ready');
            setStage('');
        }
    }

    function handleError() {
        toasts.addToast({text: `Members could not be created.`, intent: 'error'});
        setStage('')
    }

    function sendInvite() {
        console.log("SEND INVITE", results)
        const ids = results.added.map(item => {
            return {
                id: item.member_id,
                name: item.name,
                email: item.email,
                invitee_user_uid: item.uid
            }
        }).filter(a => !!a.email && !!a.name);

        setStage('inviting')

        const prefill_base = {
            config: {},
            content: `${community.member.name} (${community.member.account_email}) invited you to join ${community.profile.name} on Orange.`,
            subject: `${community.member.name} invited you to join ${community.profile.name}`,
            type: 'invite',
            template: 'system-invite',
            onSend: () => {
                community.closeComposer();
                setStage('');
                onConfirmed(results.added.map(it => it.member_id));
            },
            onClose: () => {
                community.closeComposer();
                setStage('');

            }
        };

        prepSendInvites(community, ids.map(it => it.id))
            .then(mems => {
                const to = membersBuildInviteTo(mems.map(b => {
                    return {
                        id: b.id,
                        ...b.data()
                    }
                }));

                const prefill = {
                    to,
                    ...prefill_base
                };

                community.openComposer(prefill)
            })
    }

    function addMemberIdsToSpace(member_ids) {
        let m = {};
        member_ids.forEach(id => {
            m[id] = true;
        })

        api_addPeopleToGroup({
            community_uid: community.id,
            member_id: community.member_id
        }, space_id, {
            roles: {},
            members: m,
            member_types: {},
            groups: {},
            filters: {},
        })
            .then(resp => {
                if (!resp.ok || resp.ok === 'no') {
                    toasts.addToast({intent: "danger", text: "Something went wrong"})
                } else {
                    toasts.addToast({intent: "success", text: "Members added to space"});
                }
                setStep(4);
            })
    }

    function nextStep(stage) {
        if (stage === 'loading') {
            setStep(2);
        } else if (stage === 'ready') {
            if (flow === 'add-to-group' && space_id) {
                setStep(3);
            } else {
                setStep(4);
            }
        }
    }

    let steps = [
        {label: "Create"},
    ];

    if (flow === 'add-to-group' && space_id) {
        steps.push({label: "Add to Space"});
    }

    steps.push({label: "Invite"})

    const dmt = community.profile.preferences.members.default_member_type;
    let body, footerRight, topper, footerLeft, title, subtitle;
    switch (step) {
        // step 1 is always add member data
        case 1 : {
            topper = <div className="pb-1.5">

            </div>;
            title = "Create Members";
            subtitle = "New members won't automatically be sent an invitation, but you can choose to send one after creating them."
            footerRight = <div className=" flex space-x-2">
                <Button text="Cancel" intent='standard' onClick={() => onClose()}/>
                <Button disabled={members.length === 0} text="Create" intent='success'
                        onClick={create.bind(this)}/>
            </div>;
            footerLeft = <div className="hidden items-center h-8 space-x-2">
                <div className="text-sm  text-gray-500">
                    Looking to create many members?
                </div>
                <TextAction onClick={() => {
                    //history.push(`/${community.data.handle}/admin/members/import`);
                }} size="sm" text="Import from file" icon_right icon={<ArrowRightIcon/>} inverse/>
            </div>;
            body = <div>
                <div className="pb-6">
                    <SimpleSteps steps={steps} active={0}/>
                </div>
                <CreateMembersForm init_members_list={initial} start_rows={1} setNewMembers={(nm) => {
                    if (JSON.stringify(nm) !== JSON.stringify(members)) {
                        setMembers([...nm])
                    }
                }} default_member_type={dmt} show_add_member show_member_type/>
            </div>;
            break;
        }
        // step 2 is loading
        case 2: {
            topper = <div className="pb-1.5">
                <SimpleSteps steps={steps} active={0}/>
            </div>;
            title = "Create Members";
            footerRight = <div className=" flex space-x-2">
                <Button text="Create" loading={true} intent='secondary' onClick={() => create()}/>
            </div>;
            footerLeft = <div>

            </div>;
            body = <div>
                <ProgressBar seconds={5}/>
            </div>;
            break;
        }
        // step 4 is invite
        case 4: {
            /*
               <Button loading={stage==='inviting'} intent="success" text="Send invite to new members"
                        onClick={sendInvite.bind(this)}/>
                          {flow==='generic'&&<Button text="Done" intent=''
                                           onClick={() => onConfirmed(results.added.map(it => it.member_id))}/>}
             */
            topper = <div className="pb-1.5">
                <SimpleSteps steps={steps} active={1}/>
            </div>;
            title = "Invite Members";
            footerRight = <div className=" flex space-x-2">
                {results && results.added && results.added.length > 0 &&
                    <Button text="Skip"
                            onClick={() => onConfirmed(results.added.map(it => it.member_id))}/>}

                {results && results.added && results.added.length > 0 &&
                    <Button loading={stage === 'inviting'} intent="primary" text="Compose Invite"
                            onClick={() => sendInvite()}/>}

            </div>;
            footerLeft = <div>

                {flow === 'directory' && <Button text="Return to Directory" intent=''
                                                 onClick={() => onConfirmed(results.added.map(it => it.member_id))}/>}
            </div>;
            body = <div style={{minHeight: '200px'}}>
                <MemberImportSummary handle={community.profile.handle} {...results} />
            </div>
            break;
        }
        // step 3 is add to space, todo needs a callback
        case 3: {
            topper = <div className="pb-1.5">
                <SimpleSteps steps={steps} active={2}/>
            </div>;
            title = "Add to Space";
            footerRight = <div className=" flex space-x-2">
                <Button text="No" onClick={() => {
                    // go to invite
                    nextStep();
                }}/>
                <Button text="Yes, add to Space" intent='success'
                        onClick={() => {
                            addMemberIdsToSpace(results.added.map(it => it.member_id));
                            nextStep()
                        }}/>
            </div>;
            footerLeft = <div>

            </div>;
            body = <div style={{minHeight: '200px'}}>
                <p className="text-base text-gray-700">
                    Would you also like to add these member(s) to the space?
                </p>
            </div>
            break;
        }
    }


    return <div className="">
        <ModalHeader title={title} onClose={onClose}/>
        <ModalBody>
            <ModalContentBlock>
            {body}
            </ModalContentBlock>
        </ModalBody>
        <ModalFooter left={footerLeft} right={footerRight}/>
    </div>
}