import React from 'react';
import M3_A_Label from "./label";

/*
* This is the CounterLabel atom
 */

export default function M3_A_CounterLabel({count,size,sx}) {

    return <M3_A_Label sx={sx} size={size}>
        {count}
    </M3_A_Label>
}