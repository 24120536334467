import React, {useEffect, useRef, useState} from "react";
import {contacts_handleLoadListData} from "./api";
import {contacts_getActiveViewId, contacts_parseListViewData} from "./utilities";
import {saveItemToLocalStorage} from "../../../m3/utilities/localstorage";
import {useSearchParams} from "react-router-dom";

function localCompareObjects(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
}

export function useListData(active_list_id, lists, community_uid) {
    const [search_params, setSearchParams] = useSearchParams();
    const [list_data, setListData] = useState(null);
    const [active_view_id, setActiveViewId] = useState(null);
    const [active_view_data, setActiveViewData] = React.useState(null);
    const is_fetching_list_data = React.useRef(false);

    useEffect(() => {
        if (active_list_id && lists.length > 0) {
            if (is_fetching_list_data.current) {
                //  console.log("is fetching list data already")
                return;
            }
            // console.log("LOADING LIST DATA", props.active, list_data?.id)
            if (active_list_id !== list_data?.id) {
                //  console.log("LOADING LIST DATA", props.active, list_data?.id)
                if (list_data) {
                    setListData(null);
                }
                setActiveViewId(null);
                setActiveViewData(null);
                const raw_list_data = lists?.find(list => list.list_id === active_list_id);
                //    console.log("SET LIST", raw_list_data, props.lists)
                is_fetching_list_data.current = true;
                //  console.log("RAW LIST DATA", raw_list_data, props.active)
                contacts_handleLoadListData(community_uid, active_list_id, raw_list_data)
                    .then(data => {
                        if (!data) {
                            alert("No data");
                            return;
                        }

                        const parsed_data = contacts_parseListViewData(data);
                        const active_view_id = contacts_getActiveViewId(community_uid, parsed_data, search_params.get('view'));
                        //   console.log("SET LIST DATA",parsed_data)
                        setListData(parsed_data);
                        setActiveViewId(active_view_id);
                        is_fetching_list_data.current = false;
                    })
            }
        }
    }, [active_list_id, setActiveViewId, list_data, search_params.get('view'), community_uid, lists])

    useEffect(function () {
        if (active_view_id && list_data && (!active_view_data || active_view_id !== active_view_data.id)) {
            setActiveViewData(list_data.views[active_view_id]);
            // set as last viewed
            saveItemToLocalStorage(`${community_uid}-${list_data.id}-view`, active_view_id);

            setSearchParams(params=>{
                params.set("view", `${active_view_id}`);
                return params;
            })
        }
    }, [active_view_id, active_view_data, search_params, list_data])

    return [list_data, setListData, active_view_id, setActiveViewId, active_view_data, setActiveViewData];
}

export function useInfiniteScrollData(active_view_config, query = "", page_size, community_uid, getData, getPartialsData) {
    const [data, setData] = useState([]);
    const data_ref = useRef([]);
    const [is_fetching, setIsFetching] = useState(true);
    const is_fetching_ref = useRef(false);
    const [partials, setPartials] = useState({});
    const start_after = useRef(null);
    const has_more_ref = useRef(true);
    const partials_data_ref = useRef({});
    const existing_query = useRef("");
    const query_id = useRef("");
    const existing_size = useRef(0);
    const existing_config = useRef(null);

    useEffect(() => {
        // check if there are partials and they are not yet accounted for
        data_ref.current = data;
        if (data && data.length > 0) {

            data.forEach(it => {
                if (it.__partial && !partials_data_ref.current[it.id]) {
                    partials_data_ref.current[it.id] = "load";
                }
            })

            setPartials({...partials_data_ref.current});
        }
    }, [data]);

    useEffect(() => {
        if (partials && Object.keys(partials).length > 0) {

            const to_load = Object.entries(partials_data_ref.current).filter(([a, v]) => {
                return v === "load";
            }).map(a => a[0]);

            if (to_load.length > 0) {
                getPartialsData(to_load)
                    .then(docs => {
                        handlePartialDocs(docs);
                    })

                to_load.forEach(key => {
                    partials_data_ref.current[key] = "loading";
                })

                setPartials({...partials_data_ref.current});
            }
        }
    }, [partials]);

    function handlePartialDocs(full_records) {
        // update data
        // also remove partials

        if (full_records && full_records.length > 0) {
            let new_partials = {...partials_data_ref.current};
            let new_data = [...data_ref.current];
            full_records.forEach(record => {
                if (new_partials[record.id]) {
                    delete new_partials[record.id];
                }
                const data_index = new_data.findIndex(a => a.id === record.id);
                if (data_index > -1) {
                    new_data[data_index] = {
                        ...record,
                        id: record.id
                    };

                    if (new_data[data_index].__partial) {
                        delete new_data[data_index].__partial;
                    }
                }
            })
            partials_data_ref.current = new_partials;
            setPartials(new_partials);
            setData(new_data)
        }
    }

    function refresh() {
//console.log("REFRESH")
        existing_size.current = 0;
        start_after.current = null;
        query_id.current = "";
        has_more_ref.current = true;
        existing_query.current = query;
        setData([]);

        is_fetching_ref.current = true;

        setIsFetching(true);
        initialDataFetch(true);
    }

    useEffect(function () {
        if (!community_uid || !active_view_config || !page_size) {
            console.warn("MISSING DATA", community_uid, active_view_config, page_size)
            return;
        }
        if (data.length === 0 && has_more_ref.current && !is_fetching_ref.current) {
            setIsFetching(true);
            is_fetching_ref.current = true;
            existing_config.current = active_view_config;
            initialDataFetch(false);
        } else if (existing_config.current && !localCompareObjects(existing_config.current.sort, active_view_config.sort)) {
            //  console.log("SORT CHANGED", active_view_config, community_uid, data, has_more_ref, page_size)
            existing_config.current = active_view_config;
            refresh();
        } else if (existing_config.current && !localCompareObjects(existing_query.current, query)) {
            //  console.log("SORT CHANGED", active_view_config, community_uid, data, has_more_ref, page_size)
            existing_config.current = active_view_config;
            existing_query.current = query;
            refresh();
        }

    }, [community_uid, active_view_config, query, data, page_size, setData, setIsFetching]);

    function initialDataFetch(clear) {
        loadMoreData()
            .then(([arr, last_doc]) => {
                if (last_doc) {
                    start_after.current = last_doc;
                }

                handleNewData(arr, clear)
            });
    }

    function handleNewData(records, clear) {
        if ((!records || records.length === 0) && !clear) {
            console.warn("NO records", records)
            return;
        }

        if(!Array.isArray(records)) {
            console.warn("NOT ARRAY", records)
            return;
        }

        const new_data = clear ? [...records] : [...data, ...records]
        setData(new_data)
        is_fetching_ref.current = false;
        has_more_ref.current = records.length === page_size;
        existing_size.current = new_data.length;
        setIsFetching(false);
    }

    async function loadMoreData() {
        const page = Math.floor(existing_size.current / page_size);
        const q_id = `${community_uid}__${JSON.stringify(active_view_config.filters)}__${JSON.stringify(active_view_config.sort)}__${query}__${page}`;
        //console.log("LOAD MORE DATA", q_id)
        if (q_id !== query_id.current) {
            query_id.current = q_id;
            return new Promise((resolve, reject) => {
                getData(active_view_config, query, page_size, start_after.current, existing_size.current)
                    .then(arr => {
                        resolve(arr);
                    })
            });
        }
    }

    function fetchNextPage() {
        if (is_fetching_ref.current) {
            //   console.log("ALREADY FETCHING")
            return;
        }
        setIsFetching(true);
        is_fetching_ref.current = true;
        //console.log("FETCH NEXT PAGE")
        loadMoreData()
            .then((result) => {
                if (result && Array.isArray(result[0])) {
                    const [arr, last_doc] = result;
                    if (last_doc) {
                        start_after.current = last_doc;
                    }
                    // console.log("GOT MORE DATA", arr)
                    handleNewData(arr)
                }
            });
    }

    function updateRecord(id, field, value) {
        //  console.log("UPDATE RECORD", id, field, value)
        // console.log("DATA",data)
        const record_index = data.findIndex(r => r.id === id);

        if (record_index > -1) {
            let new_data = [...data];

            // now update field, this can be a nested field
            const field_parts = field.split(".");

            if(field_parts.length === 1) {
                new_data[record_index][field] = value;
            } else if(field_parts.length === 2) {
                new_data[record_index][field_parts[0]][field_parts[1]] = value;
            }

            setData(new_data);
        }
    }

    return [data, is_fetching, fetchNextPage, has_more_ref, refresh, updateRecord];
}