import React from "react";
import M3_A_Text from "./text";

/*
* This is the Link atom
* This atom is used to create links
 */

const _props = {
    children: {
        type: "string",
        required: true
    }
};

const variant_classes_map = {
    "default": "underline",
    "primary": "text-blue-500 font-semibold hover:text-blue-600",
    "secondary": "text-blue-500 hover:text-blue-600",
    "minimal": "hover:underline",
    "action": "text-blue-500"
};

export default function M3_A_Link({
                                      onClick,
                                      href,
                                      children,
                                      variant = "default"
                                  }) {
    let classes = "cursor-pointer ";

    let ap = {};

    if (onClick) {
        ap.onClick = onClick;
    }

    if (href) {
        ap.href = href;
        ap.rel = "noopener noreferrer";
    }

    if (variant_classes_map[variant]) {
        classes += variant_classes_map[variant];
    }

    return (<>
            <a {...ap} className={classes}>
                <M3_A_Text>
                    {children}
                </M3_A_Text>
            </a>
        </>
    );
}