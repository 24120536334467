import React, {Component} from "react";
import {handleOnFileLoad, handleOnRemoveFile, handleOpenDialog, handleRemoveFile} from "./file-helpers";
import {
    buildHeaderOptions, callImportAPI,
    importComplete,
    setupHeaders,
    setupPreview, startImport, validateRecords
} from "./helpers";
import styled from "styled-components";
import {useCSVReader} from "react-papaparse";
import {ImportSection} from "./shared";
import Button from "../../button";
import {CheckIcon, LinkIcon} from "@heroicons/react/24/outline";
import {TimedProgressBar} from "../timed-progress-bar";
import {CloudArrowUpIcon} from "@heroicons/react/20/solid";
import {FileListItem} from "../../../../routes/community/write/attach/popup";
import {LiveImportEditor} from "../import-editor";
import EmptyState from "../../empty-state";

const FileFrame = styled.div`
  margin-bottom: 4px;

  > div {
    width: 100%;
  }
`;

const ImportFrame = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  padding: 12px;
`;

const ImportText = styled.div`
  padding-top: 16px;
`;

function getSecondsProgress(total) {
    // 33 jobs / 8 jobs
    const count = ((total + 1) / 8) * 6;
    if (count > 20) {
        return 20;
    } else if (count < 4) {
        return 4;
    }
    return count;
}

export const import_initial_state = {
    record_count: 0,
    is_importing: false,
    uploading: false,
    import_done: false,

    file_name: '',
    file: null,

    is_updating_members: false,

    init_mapping: {},
    records: {},
    file_headers: {},
    new_member_data: [],

    jobs: {},
    jobs_done: 0,
    jobs_total: 0,

    valid_records: {},
    invalid_records: {},

    imports: {}
};

function convertHeaderOptionsToColumns(ho) {
    return ho.map(h => {
        const num = parseInt(h.value) + 1;
        // num makes it unique here
        return {
            id: `${num}-${num}${h.label}`,
            label: `${h.label}`
        }
    })
}

function convertImportData(columns, imported) {
    let obj = {};

    let entries = Object.entries(imported);

    for (let i = 0; i < entries.length; i++) {
        // starts at 0
        const [row, data] = entries[i];
        const row_num = (parseInt(row) + 1);
        obj[row_num] = {};
        for (let k = 0; k < columns.length; k++) {
            const col = columns[k];
            const split = col.id.split('-');
            const num = split[0];
            const id = split[1];
            obj[row_num][id] = data[(parseInt(num) - 1)];
        }
    }

    return obj;
}

export const importButtonRef = React.createRef();

const reader_styles = {
    csvReader: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
    },
    browseFile: {

    },
    acceptedFile: {
        border: '1px solid #ccc',
        height: 45,
        lineHeight: 2.5,
        paddingLeft: 10,
        width: '80%',
    },
    remove: {
        borderRadius: 0,
        padding: '0 20px',
    },
    progressBarBackgroundColor: {
        backgroundColor: 'red',
    }
};

function Reader({
                    onFileLoad = () => {
                    }
                }) {
    const {CSVReader} = useCSVReader();

    function handleFileLoad() {
        // handleOnFileLoad
    }

    function handleRemoveFile() {
        // handleOnRemoveFile
    }

    /*
     ref={importButtonRef}
        style={{
            dropFile: {
                display: 'none'
            }
        }}
        onError={() => {
            console.log('error')
        }}
        onDrop={handleFileLoad}
        noProgressBar
        onRemoveFile={handleRemoveFile}
     */
    //{acceptedFile && acceptedFile.name}
    /*
      <button {...getRemoveFileProps()}>
                        Remove
                    </button>
                      <ProgressBar style={reader_styles.progressBarBackgroundColor}/>
     */
    return <CSVReader
        onUploadAccepted={(results) => {
            console.log('---------------------------');
            console.log(results);
            console.log('---------------------------');
            onFileLoad(results);
        }}

    >
        {({
              getRootProps,
              acceptedFile,
              ProgressBar,
              getRemoveFileProps,
          }) => (
            <>
                <div className="p-8 border rounded-xl flex justify-center items-center border-gray-200">
                    <button type='button'
                            className="border-gray-200 hover:bg-gray-100 hover:border-gray-300 cursor-pointer font-medium flex space-x-1.5 border items-center rounded-md leading-7 pt-px h-8 px-2.5 text-sm"
                            {...getRootProps()}
                            style={reader_styles.browseFile}>
                        Select .csv to upload
                    </button>
                </div>
            </>
        )}
    </CSVReader>;
}


export default class AdminImport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...import_initial_state
        };

        this._last_ref = null;

        this.callImportAPI = callImportAPI.bind(this);
        this.importComplete = importComplete.bind(this);
        this.handleOnFileLoad = handleOnFileLoad.bind(this);
        this.validateRecords = validateRecords.bind(this);
        this.handleOpenDialog = handleOpenDialog.bind(this);
        this.handleRemoveFile = handleRemoveFile.bind(this);
        this.handleOnRemoveFile = handleOnRemoveFile.bind(this);

        this.setupPreview = setupPreview.bind(this);
        this.startImport = startImport.bind(this);
        this.setupHeaders = setupHeaders.bind(this);
    }

    getProgress() {
        const imports = this.state.imports;
        const members_total = Object.keys(this.state.valid_records);
        let complete = 0;
        const vals = Object.values(imports);
        for (let i = 0; i < vals.length; i++) {
            if (!vals[i]) {
                complete += 20;
            }
        }
        return `${complete} of ${members_total.length}`;
    }

    render() {
        const {
            records,
            file_name = "",
            uploading,
            file,
            is_importing,
            jobs_done,
            jobs_total,
            import_done,
            record_count,
            file_headers
        } = this.state;

        let header_options = buildHeaderOptions(file_headers);

        let import_going_on = is_importing || (jobs_done < jobs_total);

        const temp_progress = (jobs_done / jobs_total)

        const progress = temp_progress < 0.2 ? 0.2 : temp_progress;

        // file_headers = Obj 0: {label: "ID"}
        // records =
        // header_options = Array {value: "0", label: "ID"}
        const columns = convertHeaderOptionsToColumns(header_options);
        const editing_data = convertImportData(columns, records);
        /*
             <div>
                    <div>Import Members</div>
                    <Button onClick={() => {
                        window.open('https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/static%2Funaty%20example.csv?alt=media', '_blank')
                    }} icon={<CloudArrowDownIcon/>} text='Download Template'/>
                </div>

         */
        return (<div className="">

                <div className={"pt-3 pb-20"}>
                    <div className="space-y-0.5 -mt-3">

                        {import_done && <div className="flex justify-center p-10">
                            <EmptyState icon={<CheckIcon/>} title="Import successful!" intent="success"
                                        subtitle="Your members have been successfully added."
                                        button={<Button onClick={() => {
                                            this.props.onClose();
                                        }} text={"Member Directory"} fill intent="secondary"/>}/>
                        </div>}

                        {import_going_on && !import_done && <ImportFrame className="mx-auto w-96">
                            <div className="p-2 w-full">
                                <TimedProgressBar seconds={getSecondsProgress(jobs_total)}/>
                            </div>
                            <ImportText className='text-gray-800'>
                                Import in progress
                            </ImportText>
                        </ImportFrame>}


                        {!is_importing && !uploading && !file &&
                            <ImportSection title='Upload Source File' icon={<CloudArrowUpIcon/>}>
                                <Reader onFileLoad={(loaded_data) => {
                                    console.log("handleOnFileLoad", loaded_data)
                                    this.handleOnFileLoad(loaded_data);
                                }}/>
                                {record_count > 2 && file && <FileFrame>
                                    <FileListItem color={"#0d9488"} inline_actions can_download={false}
                                                  onDelete={this.handleRemoveFile.bind(this)} name={file.name}
                                                  type='text/csv'
                                                  meta={`${Object.keys(records).length} Records`}/>
                                </FileFrame>}
                            </ImportSection>}

                        {!is_importing && record_count > 2 &&
                            <ImportSection title='Match File Columns' icon={<LinkIcon/>}>
                                <div className="">
                                    <LiveImportEditor onStartImport={(m) => this.callImportAPI(m, this.props.community)}
                                                      file_name={file_name}
                                                      columns={columns} raw_data={editing_data}/>
                                </div>
                            </ImportSection>}

                    </div>
                </div>
            </div>
        );
    }
}