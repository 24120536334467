import React, {useState} from "react";
import {useToasts} from "../../config/toasts";

import M3_E_OptInPrompt from "../../m3/examples/opt-in-prompt";
import {authFetch} from "../../../api/network";
import {buildImageUrl} from "../../../common/utilities/images";

export function OptInPromptWrapper({
                                       current_period_id = "",
                                       handleOptInMembership,
                                       community_data,
                                       preview,
                                       member_id,
                                       community_uid,
                                       gate_data,
                                       period_data = {},
                                       updateParent
                                   }) {
    const [loading, setLoading] = useState("");
    const toasts = useToasts();

    function optInMembership(slug, label) {
        setLoading('opting-in');
        const res = (result) => {
            setLoading('opted-in');
            toasts.addToast({
                text: `You're opted-in updated, thank you!`,
                intent: "success"
            });
            //snapToTop();
            updateParent();
        };

        if (handleOptInMembership) {
            return handleOptInMembership(slug, label)
                .then(res);
        }

        const payload = {
            community_uid: community_uid,
            member_id: member_id,

            slug,
            label
        };

        console.log("OPT IN PAYLOAD", payload)

        authFetch("/members/opt-in", res, res, "POST", {payload});
    }


    const policy_prompt_config = {
        is_initializing: false,
        is_loading: loading === "accepting",

        header: {
            title: `Membership Opt-in`,
            subtitle: `${period_data.label}`,
            context_bar: {
                type: "breadcrumbs",
                data: {
                    crumbs: [
                        {
                            type: "inline-entity",
                            data: {
                                size: "sm",
                                avatar: buildImageUrl(community_data.profile_picture, "_small"),
                                children: community_data.name
                            }
                        },
                        {
                            type: "text",
                            data: {
                                size: "sm",
                                children: "Opt-in"
                            }
                        }
                    ]
                }
            },
        },

        footer: {
            caption: "",
            actions: {
                confirm: {
                    label: "Opt-in",
                    intent: "primary",
                    loading: loading === "opting-in",
                    onClick: () => {
                        if(preview) {
                            alert("Opt-in preview click");
                            return;
                        }
                        optInMembership(current_period_id, period_data.label);
                    }
                }
            },
        },

        content: {
            description: gate_data?.description
        },

        dialog_count: 1
    };

    return <M3_E_OptInPrompt config={policy_prompt_config}/>;
}