import React, {forwardRef, useRef, useState} from "react";
import Tippy from '@tippyjs/react/headless';
import './styles.css';
import {useSpring, motion} from "framer-motion";
import {detectDeviceIOS} from "../../../m3/utilities/device-detection";
import MobileDrawerContainer from "./mobile-drawer-container";

const Children = forwardRef((props, ref) => {
    if (props.inline) {
        return <span ref={ref}>{props.children}</span>
    }
    return <div ref={ref}>{props.children}</div>;
});

export default function SimpleTooltip({
                                          mobileDrawer,
                                          text,
                                          listenForClose,
                                          onClose,
                                          usePortal,
                                          blockRenderBeforeMount,
                                          inline,
                                          onOpen,
                                          appendTo = "parent",
                                          animated,
                                          hideOnClick = true,
                                          interactive,
                                          simple,
                                          trigger = 'mouseenter focus',
                                          placement = 'auto',
                                          hideOnMobile,
                                          children
                                      }) {
    const is_mobile = window.innerWidth < 768 || detectDeviceIOS();
    const springConfig = {damping: 30, stiffness: 300};
    const initialScale = 0.5;
    const opacity = useSpring(0.5, springConfig);
    const scale = useSpring(initialScale, springConfig);
    const ref = useRef();
    const [mounted, setMounted] = useState(false);

    if (is_mobile && hideOnMobile) {
        return <Children inline={inline} children={children}/>
    }

    if (is_mobile && mobileDrawer) {
        return <MobileDrawerContainer trigger={children}>
            {text}
        </MobileDrawerContainer>
    }

    function onMount(instance) {
        if (animated) {
            scale.set(1);
            opacity.set(1);
        }
        ref.current = instance;
    }

    function onHide(p) {
        if (p && p.unmount) {
            const cleanup = scale.onChange(value => {
                if (value <= initialScale) {
                    cleanup();
                    requestAnimationFrame(p.unmount);
                }
            });

            scale.set(initialScale);
            opacity.set(0);
        }

    }

    function handleClick(e) {
        if (interactive && hideOnClick) {
            ref.current.hide();
        }
    }

    const lazyPlugin = {
        fn: () => ({
            onMount: () => setMounted(true),
            onHidden: () => setMounted(false),
        }),
    };

    let final_placement = placement;

    let popper_options = {};

    if (mobileDrawer && is_mobile) {
        final_placement = "bottom";
        appendTo = () => document.body;
        popper_options = {}
    }

    if (usePortal) {
        appendTo = () => document.body;
    }

    const plugins = typeof text === 'string' ? [] : [lazyPlugin];
    return <Tippy
        offset={[0, 8]}
        plugins={plugins}
        animation={animated}
        popperOptions={popper_options}
        onShow={(a) => {
            if (listenForClose) {
                a.popper.querySelector('.close-tooltip').addEventListener('click', () => {
                    a.hide();
                });
            }
            if (onOpen) {
                onOpen();
            }
        }}
        onMount={onMount}
        onHide={(a, b) => {
            if (listenForClose) {
                a.popper.querySelector('.close-tooltip').removeEventListener('click', () => {
                    a.hide();
                });
            }
            if (animated) {
                onHide(a);
            }
            if (onClose) {
                onClose();
            }
        }}
        onClickOutside={(instance, event) => {
            if (!hideOnClick) {
                if (event && event.target && event.target.className && typeof event.target.className === 'string' && event.target.className.includes('option')) {
                    return;
                }
                ref.current.hide();
                if (onClose) {
                    onClose();
                }
            }
        }}
        hideOnClick={hideOnClick}
        inertia
        zIndex={600}
        interactive={interactive}
        placement={final_placement}
        trigger={trigger}
        appendTo={appendTo}
        render={attrs => {
            if (blockRenderBeforeMount && !mounted) {
                return null;
            }
            if (!animated) {
                return <div style={{}} onClick={handleClick}
                            className={`${simple ? "" : "tooltip"}  select-none ${mobileDrawer ? "mobile-drawer" : ""}`}
                            tabIndex="-1" {...attrs}>
                    {text}
                </div>
            }
            return <motion.div onClick={handleClick} style={{scale, opacity}}
                               className={`${simple ? "" : "tooltip"} select-none ${mobileDrawer ? "mobile-drawer" : ""}`}
                               tabIndex="-1" {...attrs}>
                {text}
            </motion.div>
        }}
    >
        <Children inline={inline} children={children}/>
    </Tippy>
};