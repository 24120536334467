import React, {useEffect, useState} from "react";
import {useCommunity} from "../../../config/community";
import {data__member_profile_sections} from "../../../../common/data/profile-sections";
import {data__member_profile_fields} from "../../../../common/data/data-fields";
import M3_A_Input from "../../../m3/atoms/input";
import M3_A_FormControl from "../../../m3/atoms/form-control";
import M3_A_TextArea from "../../../m3/atoms/textarea";
import M3_A_Heading from "../../../m3/atoms/heading";
import {fetchFlow, parseFlow} from "./index";
import {authFetch} from "../../../../api/network";
import M3_A_Button from "../../../m3/atoms/button";
import {generateUniqueID} from "../../../../common/utilities/general";
import M3_A_Text from "../../../m3/atoms/text";
import M3_A_Card from "../../../m3/atoms/card";
import M3_A_Modal from "../../../m3/atoms/modal";
import {
    ModalBody,
    ModalContentBlock,
    ModalHeader
} from "../../../m3/_legacy_components/preview-overlay";
import M3_A_IconButton from "../../../m3/atoms/icon-button";
import {m3_icon_map} from "../../../m3/icons/icon-map";
import {useToasts} from "../../../config/toasts";

// data__member_profile_fields
// data__member_profile_sections

/*
steps are disconnected from profile sections
 */

const CreateBlockButton = ({designer, onAddBlock}) => {
    return <div>
        {designer.data.available_blocks.map((block, index) => {
            return <div key={index}>
                <M3_A_Button onClick={() => onAddBlock(block.type, block.id)} text={block.label}/>
            </div>
        })}
    </div>
}

const getBlockMetadata = (block, all_block_data) => {
    return all_block_data.find(a => {
        const match_id = a.id === block.id;
        const match_type = a.type === block.type;
        const has_id = !!block.id;

        return match_id || (match_type && !has_id);
    });
}

const StepBlocks = ({step, onRemoveBlock, designer, blocks, onAddBlock, handleChange}) => {
    const can_create_block = blocks.length === 0;

    return <div>
        <M3_A_Text weight="font-semibold" size="sm">Blocks</M3_A_Text>

        {blocks?.map((block, index) => {
            const block_metadata = getBlockMetadata(block, designer?.data?.block_data);
            const label = block_metadata?.label || block.type;
            console.log("block metadata", {
                block_metadata
            })
            const can_select_fields = block_metadata?.meta?.onboarding_select_fields;
            return <M3_A_Card elevated={false} key={index}>
                <div className="flex items-center">
                    <div className="flex-grow">
                        <M3_A_Text weight="font-semibold">
                            {label}
                        </M3_A_Text>
                    </div>
                    <div>
                        <M3_A_Button onClick={() => onRemoveBlock(block.id)} text="Remove Block"/>
                    </div>
                </div>
                {can_select_fields && <div>
                    Fields: {block_metadata.fields.map((field, index) => {
                    const field_data = data__member_profile_fields[field];
                    if (!field_data) {
                        return "";
                    }
                    return field_data.label;
                }).join(", ")}
                </div>}
            </M3_A_Card>
        })}

        {can_create_block && <div>
            <CreateBlockButton designer={designer} onAddBlock={onAddBlock}/>
        </div>}
    </div>
}

export const GenericSectionMetadata = ({title,children,buttons}) => {
    return <div className="flex flex-col gap-2">
        <div className="flex items-baseline">
            <div className="flex-grow">
                <M3_A_Heading size="h6">
                    {title}
                </M3_A_Heading>
            </div>
            <div className="flex gap-2">
                {buttons&&buttons.map((a,idx)=>{
                    return <div key={idx}>
                        {a}
                    </div>
                })}
            </div>
        </div>
        <div className="flex flex-col gap-3">
            {children}
        </div>
    </div>
};

const StepMetadata = ({step, designer, step_index, id, title, type}) => {
    const is_intro_or_outtro = type === "intro" || type === "outtro";

    const can_add_blocks = !is_intro_or_outtro;
    const can_remove_step = !is_intro_or_outtro;

    function onChange(event, field) {
        designer.fns.handleChange(id, field, event.target.value)
    }

    function onAddBlock(type, block_id) {
        designer.fns.handleAddBlock(id, type, block_id);
    }

    function onRemoveBlock(block_id) {
        designer.fns.handleRemoveBlock(id, block_id);
    }
// <GenericSectionMetadata/>
    const can_move_up = step_index > 0 && !is_intro_or_outtro && designer.state.total_steps > 1;
    const can_move_down = step_index < designer.state.total_steps - 1 && !is_intro_or_outtro && designer.state.total_steps > 1;
    return <GenericSectionMetadata title={`${title||"Step"}`} buttons={[
        (can_move_up && <M3_A_IconButton onClick={() => designer.fns.moveStep(id, -1)} adjust_icon_size={-1} square compact
                                         icon={m3_icon_map.outlines['chevron_up']} size="sm"/>),
        (can_move_down && <M3_A_IconButton onClick={() => designer.fns.moveStep(id, 1)} adjust_icon_size={-1} square compact
                                           icon={m3_icon_map.outlines['chevron_down']} size="sm"/>),
        (can_remove_step && <M3_A_IconButton onClick={() => window.confirm("Are you sure you want to remove this step?") && designer.fns.handleRemoveStep(id)} adjust_icon_size={-1} square compact
                          icon={m3_icon_map.outlines['delete']} size="sm"/>)
    ]} className="flex flex-col gap-2">
        <M3_A_FormControl label="Title">
            <M3_A_Input fill name={"title"} value={step?.title || ""} type="text" onChange={e => onChange(e, 'title')}/>
        </M3_A_FormControl>
        <M3_A_FormControl label="Description">
            <M3_A_TextArea name={"description"} value={step?.description || ""} type="text"
                           onChange={e => onChange(e, 'description')}/>
        </M3_A_FormControl>

        {can_add_blocks &&
            <StepBlocks onRemoveBlock={onRemoveBlock} onAddBlock={onAddBlock} designer={designer} step={step}
                        blocks={step?.blocks}/>}
    </GenericSectionMetadata>
};

export async function updateCommunityOnboardingFlow(community_uid, id, update) {
    const payload = {
        community_uid: community_uid,

        id,
        update
    };

    console.log("PAYLOAD", payload)

    return new Promise((resolve, reject) => {
        const res = (resp) => {
            resolve(resp);
        };
        authFetch(`/flows/update`, res, res, "POST", {payload});
    })
}

function getBlockData(community) {
    let a = [];

    for (let key in data__member_profile_sections) {
        const section = data__member_profile_sections[key];
        if (section.meta?.onboarding_block) {
            if (key === "__custom") {
                // get all custom field sections from community.custom_fields
                Object.entries(community.custom_fields).forEach(([key, cfs], index) => {

                    a.push({
                        type: "custom_fields_section",
                        label: cfs?.name,
                        meta: section?.meta,

                        visibility: cfs?.visibility,
                        id: cfs?.id,

                        fields: cfs.fields
                    });
                });
            } else {
                const available_fields = section.fields.filter(a => {
                    const field_data = data__member_profile_fields[a];
                    return !!field_data && field_data?.meta?.editable;
                });
                a.push({
                    id: key,
                    type: key,
                    label: section.label,
                    meta: section?.meta,

                    visibility: null,

                    fields: available_fields
                })
            }
        }
    }

    return a;
}

/*
- add up to 6 steps
- each step can have multiple blocks
- each block can have multiple fields based on the type of the block
 */

const allowed_types = ["custom_fields_section", ...Object.keys(data__member_profile_sections)];

function buildNewStep(title = "", description = "") {
    const step_id = generateUniqueID("step")
    return {
        id: `${step_id}`,
        actions: {
            "primary": "Continue"
        },
        complete_rules: [],
        name: `Step ${step_id}`,
        data: {},
        type: "standard",
        title: "",
        description: "",
        blocks: []
    }
}

function transformParsedSteps(steps) {
    return steps.map(step => {
        return {
            id: step?.id,
            title: step?.title,
            description: step?.description,
            blocks: step?.blocks?.map(block => {
                return {
                    type: block.type,
                    id: block?.id || "",
                    data: {}
                }
            })
                .filter(a => {
                    return allowed_types.includes(a.type)
                })
        }
    })
}

function mergeUpdatedSteps(steps, updated) {
    return updated.map(step => {
        const previous = steps.find(a => a.id === step.id);
        if (!previous) {
            return step;
        }
        return {
            ...previous,
            title: step.title,
            description: step.description,
            blocks: step.blocks
        }
    })
}

export function OnboardingDesignerModal({onClose}) {
    return <OnboardingDesigner onClose={onClose}/>
}

function OnboardingDesigner({onClose}) {
    const community = useCommunity();
    const toasts = useToasts();
    const [fetching, setFetching] = useState(false);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [flow, setFlow] = useState(null);
    const [steps, setSteps] = React.useState([]);
    const [tab, setTab] = React.useState("Steps");
    const [changes_made, setChangesMade] = React.useState(false);

    useEffect(() => {
        if (!flow && community.uid && !fetching) {
            initialLoadFlow();
        }
    }, [flow, fetching, community.uid]);

    function handleRemoveBlock(step_id, block_id) {
        console.log("REMOVE BLOCK", step_id, block_id)

        setSteps(steps => {
            return steps.map(step => {
                if (step.id === step_id) {
                    return {
                        ...step,
                        blocks: step.blocks.filter(a => a.id !== block_id)
                    }
                }
                return step;
            })
        })

        setChangesMade(true);
    }

    function handleAddBlock(step_id, type, block_id) {
        console.log("ADD BLOCK", step_id, type)

        setSteps(steps => {
            return steps.map(step => {
                if (step.id === step_id) {
                    return {
                        ...step,
                        blocks: [
                            ...step.blocks,
                            {
                                type,
                                id: block_id,
                                data: {}
                            }
                        ]
                    }
                }
                return step;
            })
        })

        setChangesMade(true);
    }

    function handleChange(step_id, field, value) {
        console.log("CHANGE", step_id, field, value)

        if (step_id === "intro") {
            setFlow(flow => {
                return {
                    ...flow,
                    intro: {
                        ...flow.intro,
                        [field]: value
                    }
                }
            })
        } else if (step_id === "outtro") {
            setFlow(flow => {
                return {
                    ...flow,
                    outtro: {
                        ...flow.outtro,
                        [field]: value
                    }
                }
            })
        } else {
            setSteps(steps => {
                return steps.map(step => {
                    if (step.id === step_id) {
                        return {
                            ...step,
                            [field]: value
                        }
                    }
                    return step;
                })
            })
        }

        setChangesMade(true);
    }

    function handleAddStep() {
        setSteps(steps => {
            return [
                ...steps,
                buildNewStep()
            ]
        })

        setChangesMade(true);
    }

    function initialLoadFlow() {
        setFetching(true);
        fetchFlow(community.uid)
            .then(fl => {
                if (!fl) {
                    alert("No flow found");
                    setError("not-found");
                    setFetching(false);
                    return;
                }
                const parsed = parseFlow(fl);
                setSteps(transformParsedSteps(parsed.steps));
                setFlow(parsed);
                console.log("FLOW LOADED")
                setFetching(false);
            })
    }

    function moveStep(step_id, direction) {
        const index = steps.findIndex(a => a.id === step_id);
        const new_index = index + direction;
        const new_steps = [...steps];
        const removed = new_steps.splice(index, 1)[0];
        new_steps.splice(new_index, 0, removed);

        setSteps(new_steps);

        setChangesMade(true);
    }

    function handleRemoveStep(step_id) {
        setSteps(steps => {
            return steps.filter(a => a.id !== step_id);
        })

        setChangesMade(true);
    }

    function handleSaveChanges() {
        setSaving(true);

        const update = {
            ...flow,
            steps: mergeUpdatedSteps(flow.steps, steps)
        };

        const id = flow.id;

        updateCommunityOnboardingFlow(community.uid, id, update)
            .then(() => {
                setSaving(false);
                setChangesMade(false);
                toasts.addToast({
                    text: `Changes saved`,
                    intent: "success",
                })
            })
    }

    console.log("FLOW", {
        flow, fetching, error
    });

    let content, header = null, footer = null, actions = [];

    if (fetching) {
        content = <div></div>
    } else if (error) {
        content = <div></div>
    } else if (!flow) {
        content = <div></div>
    } else {
        const block_data = getBlockData(community);

        const available_blocks = block_data;

        const designer = {
            data: {
                block_data,
                available_blocks
            },
            state: {
                steps,
                total_steps: steps.length
            },
            fns: {
                handleAddBlock,
                moveStep,
                handleRemoveBlock,
                handleRemoveStep,
                handleChange,
            }
        };

        const can_add_step = steps.length < 6;

        actions.push(<M3_A_Button onClick={()=>{
            const url = `/c/${community.domain}/onboarding?preview=true`;
            window.open(url, '_blank');
        }} text="Preview"/>);

        actions.push(<M3_A_Button disabled={!changes_made} onClick={handleSaveChanges} loading={saving} intent="success" text="Save Changes"/>);

        if(tab === "General") {
            content = <ModalContentBlock background>
                    <M3_A_FormControl label="Flow Name">
                        <M3_A_Input fill name={"flow name"} value={flow?.header?.title} type="text" onChange={e => {
                            // update flow
                            setFlow(flow => {
                                return {
                                    ...flow,
                                    header: {
                                        ...flow.header,
                                        title: e.target.value
                                    }
                                }
                            })

                            setChangesMade(true);
                        }}/>
                    </M3_A_FormControl>
            </ModalContentBlock>;
        } else {
            content = <ModalContentBlock background>
                    <M3_A_Card elevated={false}>
                        <StepMetadata designer={designer} id="intro" title="Intro" type="intro" step={flow.intro}/>
                    </M3_A_Card>
                    {steps.map((step, index) => {
                        return <M3_A_Card elevated={false} key={index}>
                            <StepMetadata step_index={index} designer={designer} title={`Step ${index + 1}`} type="step" id={`${step.id}`}
                                          key={index} step={step}/>
                        </M3_A_Card>
                    })}

                    {can_add_step && <div className="py-2">
                        <M3_A_Button onClick={handleAddStep} text="Add Step"/>
                    </div>}
                    <M3_A_Card elevated={false}>
                        <StepMetadata designer={designer} id="outtro" title="Outro" type="outtro" step={flow.outtro}/>
                    </M3_A_Card>
            </ModalContentBlock>;
        }
    }

    const tabs = [
        {
            label: "Steps"
        },
        {
            label: "General"
        }
    ];

    return <GenericModal onClose={onClose} active_tab={tab} actions={actions} title={"Onboarding Designer"} onChangeTab={setTab} tabs={tabs}>
        {content}
    </GenericModal>
}

export const GenericModal = ({title, onClose, children, active_tab, actions, onChangeTab, tabs}) => {

    return <M3_A_Modal>
        <ModalHeader buttons={actions} active_tab={active_tab} onChangeTab={onChangeTab} tabs={tabs} title={title} onClose={onClose}/>
        <ModalBody no_padding>
            {children}
        </ModalBody>
    </M3_A_Modal>
}