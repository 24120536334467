import {APP_SERVER_URL, EXTERNAL_SERVER_URL} from "../app/config/defaults";
import {useAuth} from "../app/config/auth";
import {getAuth} from "firebase/auth";

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function externalAuthFetch(endpoint = "", handleResult = (result) => {
}, handleError = (error) => {
}, type = "GET", options = {}, token = null) {
    let url = EXTERNAL_SERVER_URL + endpoint;

    //console.log("access_token", access_token)
    let fetch_options = {
        method: type,
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    };


    if (type === 'POST') {
        let body_options = {};

        for (var i = 0; i < Object.keys(options).length; i++) {
            let key = Object.keys(options)[i];

            body_options[key] = options[key];
        }

        fetch_options.body = JSON.stringify(body_options);
    }

    try {
        fetch(url, fetch_options).then(res => res.json())
            .then(result => handleResult(result))
            .catch(error => {
                    handleError(error);
                    console.error("error", error)
                  //  throw(error);
                }
            );
    } catch (e) {
        console.error("error", e)
    }
}

export function customAPICall(url, handleRes, handleErr, type = "GET", options, token) {
    let fetch_options = {
        method: type,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        credentials: "same-origin"
    };

    if (type === 'POST') {
        let body_options = {};

        for (var i = 0; i < Object.keys(options).length; i++) {
            let key = Object.keys(options)[i];

            body_options[key] = options[key];
        }

        fetch_options.body = JSON.stringify(body_options);
    }

    fetch(url, fetch_options)
        .then(res => res.json())
        .then(result => handleRes(result))
        .catch(error => {
            console.log('error')
            handleErr(error);
        });
}

export function authFetch(endpoint = "", handleResult = (result) => {
}, handleError = (error) => {
}, type = "GET", options = {}, token = null) {
    let url = APP_SERVER_URL + endpoint;
    let access_token = token ? token : getCookie('__session');

    const call = (a, t, o, e, hr, he, flag) => {
        let fetch_options = {
            method: a,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + t
            },
            credentials: "same-origin"
        };

        const is_retry = !!flag;
        if(is_retry) {
            //
        }
        if (type === 'POST') {
            let body_options = {};

            for (var i = 0; i < Object.keys(o).length; i++) {
                let key = Object.keys(o)[i];

                body_options[key] = o[key];
            }

            fetch_options.body = JSON.stringify(body_options);
        }

        fetch(e, fetch_options)
            .then(res => res.json())
            .then(result => hr(result))
            .catch(error => {
                console.log('error')
                he(error);
            });
    };
    if (access_token === '') {
        console.error("access_token is not set");
        updateToken(call, type, options, url, handleResult, handleError);
    } else {
        call(type, access_token, options, url, handleResult, handleError);
    }
}

function updateToken(cb, type, options, url, handleResult, handleError) {
    getAuth().currentUser.getIdToken(true).then(function (idToken) {
        document.cookie = '__session=' + idToken + ';max-age=3600';

        return cb(type, idToken, options, url, handleResult, handleError, true);
    });
}