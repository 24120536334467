import React from 'react';
import {RichTextEditor} from "../../../features/forum/editor/rich-text-editor";
import {convertFromRaw, convertToRaw} from "draft-js";

// init is null or a raw signature string of draftjs utils
export function SignatureEditor({init,handleChange=()=>{}}) {

    const content_state = init ? convertFromRaw(JSON.parse(init)) : null;

    return <RichTextEditor
                           content_state={content_state} placeholder={"Add your signature"} template={null}
                           onChange={(editor_state) => {
                               const converted_to_raw = JSON.stringify(convertToRaw(editor_state.getCurrentContent()));
                               handleChange(converted_to_raw)
                           }}/>
}