import React from "react";

/*
* This is the Prose atom
 */

const _props = {
    children: {
        type: "string",
        required: true
    },
    size: {
        type: "string",
        required: false,
        fallback: "sm",
        options: [
            "sm",
            "base",
            "lg",
            "xl",
            "2xl",
            "3xl",
            "4xl",
            "5xl",
            "6xl"
        ]
    }
};

export default function M3_A_Prose({
                                       children,
                                       size = "sm"
                                   }) {
    let classes = "";

    classes += ` prose prose-${size}`;

    return (
        <div className={classes}>
            {children}
        </div>
    );
}