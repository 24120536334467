import React from 'react';
import {FlowHandler} from "../../../features/gates/flow/handler";
import {useCommunity} from "../../../config/community";
import ReactDOM from "react-dom";
import {authFetch} from "../../../../api/network";

export async function updateFlowStatus(community, update={}) {
    const payload = {
        community_uid: community.uid,
        member_id: community.member_id,

     ...update
    }
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        authFetch("/flows/update-status", res, res, "POST", {payload});
    })
}

export function FlowPage() {
    const community = useCommunity();
    const [flow_id, setFlowId] = React.useState(null);

    const context = {
        uid: community.uid,
        domain: community.domain,
        member_id: community.member_id,
        community_uid: community.uid,
        member: community.member,
        custom_fields: community.custom_fields
    }
    function handleStart() {
        updateFlowStatus(community, {
            flow_id,
            status: "started"
        })
            .then((resp) => {
                console.log("done", resp)
            })
    }

    function handleComplete() {
        updateFlowStatus(community, {
            flow_id,
            status: "complete"
        })
            .then((resp) => {
                console.log("done", resp)
            })
    }

    const content = <div className="fixed top-0 left-0 bg-white right-0 z-50 bottom-0 h-screen">
        <FlowHandler onStart={()=>{}} onComplete={()=>{}} context={context} />
    </div>;
    return ReactDOM.createPortal(
        content,
        document.body
    );
}