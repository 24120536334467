import React from "react";
import {
    EditorBlock
} from "draft-js";
import {EB_CommunityInvite} from "./blocks/community-invite";
import {EB_Poll} from "./blocks/poll";
import {EB_Image} from "./blocks/image";
import {Draggable} from "react-beautiful-dnd";
import styled from "styled-components";
import {UpcomingEventsWidget} from "../widgets/upcoming-events";
import {PollLiveWrapper} from "../../../m3/_legacy_components/poll/live-wrapper";
import {m3_icon_map} from "../../../m3/icons/icon-map";

// It is strongly recommended that you use editable: false if your custom component will not contain text.

export const DragHandle = styled.div`
  outline: none !important;
  height: 20px;
  display: flex;
  align-items: center;
  position: absolute;
  left: -20px;
  margin-top: 3px;
  width: 18px;

  > svg {
    opacity: ${props => props.disabled ? '0' : '1'};
    height: 18px;
  }
`;

const styles = {
    media: {
        // Fix an issue with Firefox rendering video controls
        // with 'pre-wrap' white-space
        whiteSpace: 'initial'
    },
};

const Audio = (props) => {
    return <audio controls src={props.src} style={styles.media}/>;
};

const Video = (props) => {
    return <video controls src={props.src} style={styles.media}/>;
};

/*
    <Draggable key={id} draggableId={id} index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                    )}
                >
 */

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    ...draggableStyle,

    // override z-index
    zIndex: 9999,
});

function BlockWrapper({children, className = ""}) {

    return <div className={`group ${className || ""}`}>
        {children}
    </div>
}

const EBHeaderQuote = (props) => {
    let content = <EditorBlock {...props} />;

    if (props && props.blockProps && props.blockProps.hide) {
        return null;
    }

    if (!props.blockProps.can_drag) {
        return content;
    }
    const {block_index} = props.blockProps;
    const id = props.block.getKey();
    return <BlockWrapper>
        <DragWrapper id={id} index={block_index}>
            {content}
        </DragWrapper>
    </BlockWrapper>
}

const EBSignatureBreak = (props) => {
    let content = <EditorBlock {...props} />;

    if (props && props.blockProps && props.blockProps.hide) {
        return null;
    }

    if (!props.blockProps.can_drag) {
        return content;
    }
    const {block_index} = props.blockProps;
    const id = props.block.getKey();
    return <BlockWrapper className="">
        <DragWrapper id={id} index={block_index}>
            <div className="pb-2 pt-1"/>
        </DragWrapper>
    </BlockWrapper>
};

const EBBlockQuote = (props) => {
    let content = <EditorBlock {...props} />;

    if (props && props.blockProps && props.blockProps.hide) {
        return null;
    }

    if (!props.blockProps.can_drag) {
        return content;
    }
    const {block_index} = props.blockProps;
    const id = props.block.getKey();
    return <BlockWrapper className="">
        <DragWrapper id={id} index={block_index}>
            {content}
        </DragWrapper>
    </BlockWrapper>
}

function DragWrapper({index, id, children}) {
    return <Draggable key={id} draggableId={id} index={index}>
        {(provided, snapshot) => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                )}
            >
                <DragHandle disabled={false}
                            className={`text-gray-500 rounded-md hover:bg-gray-200 opacity-0 group-hover:opacity-100 transition-opacity`} {...provided.dragHandleProps}>
                    {m3_icon_map.custom['grab-handle']}
                </DragHandle>
                {children}
            </div>
        )}
    </Draggable>
}


function MemberListDisplay({members}) {

    return <div style={{paddingTop: '0.25rem', paddingBottom: '0.25rem'}}>
        {members.map((member, index) => {
            const member_data = typeof member === 'object' ? {...member} : Object.fromEntries(member);
            const {image, name, link, email} = member_data;
            return <div key={index} style={{marginBottom: '0.25rem', display: 'flex'}}>
                <div style={{flex: 'none'}}>
                    <img style={{height: '2.5rem', width: '2.5rem', borderRadius: '42%'}} alt={name} src={image}/>
                </div>
                <div style={{paddingLeft: '0.6rem', paddingTop: '0.3rem'}}>
                    <div style={{lineHeight: '1rem'}}>
                        <a style={{textDecoration: 'none', fontWeight: '600'}} rel="noreferrer" target="_blank"
                           href={link}>{name}</a>
                    </div>
                    <div style={{fontSize: '0.8rem', lineHeight: '1rem', marginTop: '0.25rem'}}>{email}</div>
                </div>
            </div>
        })}
    </div>
}

const MemberList = (props) => {
    if (props && props.blockProps && props.blockProps.hide) {
        return null;
    }

    const dt = Object.fromEntries(props.block.getData());

    let content = dt && dt.members ? <MemberListDisplay members={Array.from(dt.members)}/> : <div></div>;

    if (!props || !props.blockProps || !props.blockProps.can_drag) {
        return content;
    }

    const {block_index} = props.blockProps;
    const id = props.block.getKey();
    return <BlockWrapper>
        <DragWrapper id={id} index={block_index}>
            {content}
        </DragWrapper>
    </BlockWrapper>;
}

function StatsDisplay({stats}) {

    return <div
        style={{display: 'grid', paddingTop: '1rem', paddingBottom: '1rem', gridTemplateColumns: '1fr 1fr 1fr'}}>
        {stats.map((stat, index) => {
            const stat_data = typeof stat === 'object' ? {...stat} : Object.fromEntries(stat);
            return <div key={index} style={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
                <div style={{fontSize: "2rem", color: '#222222', fontWeight: '700'}}>{stat_data.value}</div>
                <div style={{
                    fontSize: '0.85rem',
                    color: '#777777',
                    fontWeight: '500',
                    marginTop: '0.25rem'
                }}>{stat_data.label}</div>
            </div>
        })}
    </div>
}

function getDataFromMap(map) {
    const data = {};
    if(typeof map === 'object') {
        return map;
    }
    if(!map) return data;
    map.forEach((value, key) => {
        data[key] = value;
    });
    return data;
}



const custom_widget_map = {
    'google_calendar-upcoming_events': {
        component: UpcomingEventsWidget
    }
}

function renderComponentWithProps(Component, props) {
    return <Component {...props} />
}

const CustomWidget = (props) => {
    if (props && props.blockProps && props.blockProps.hide) {
        return null;
    }

    const read_only = props.blockProps.read_only;

    const dt = Object.fromEntries(props.block.getData());

    const block_data = getDataFromMap(dt.data);

    const widget_exists = custom_widget_map[dt.id];

    const widget_props = {
        data: block_data,
        id: dt.id,
        read_only
    };

    let content = dt ? <div>
        {widget_exists ? renderComponentWithProps(widget_exists.component,widget_props) :
            <div className="p-4 text-center text-xs text-gray-600">Widget not found</div>}
    </div> : <div></div>;

    if (!props || !props.blockProps || !props.blockProps.can_drag) {
        return content;
    }

    const {block_index} = props.blockProps;
    const id = props.block.getKey();
    return <BlockWrapper>
        <DragWrapper id={id} index={block_index}>
            {content}
        </DragWrapper>
    </BlockWrapper>;
};

const PollWidget = (props) => {
    if (props && props.blockProps && props.blockProps.hide) {
        return null;
    }

    const dt = Object.fromEntries(props.block.getData());

    let content = dt && dt.id ? <div className="py-2">
        <PollLiveWrapper id={dt.id}/>
    </div> : <div/>;

    if (!props || !props.blockProps || !props.blockProps.can_drag) {
        return content;
    }

    const {block_index} = props.blockProps;
    const id = props.block.getKey();
    return <BlockWrapper>
        <DragWrapper id={id} index={block_index}>
            {content}
        </DragWrapper>
    </BlockWrapper>;
}

const StatsSummary = (props) => {
    if (props && props.blockProps && props.blockProps.hide) {
        return null;
    }

    const dt = Object.fromEntries(props.block.getData());

    let content = dt && dt.stats ? <StatsDisplay stats={Array.from(dt.stats)}/> : <div></div>;

    if (!props || !props.blockProps || !props.blockProps.can_drag) {
        return content;
    }

    const {block_index} = props.blockProps;
    const id = props.block.getKey();
    return <BlockWrapper>
        <DragWrapper id={id} index={block_index}>
            {content}
        </DragWrapper>
    </BlockWrapper>;
}

const Generic = (props) => {

    if (props && props.blockProps && props.blockProps.hide) {
        return null;
    }

    let content = <EditorBlock {...props} />;

    if (!props || !props.blockProps || !props.blockProps.can_drag) {
        return content;
    }
    const {block_index} = props.blockProps;
    const id = props.block.getKey();
    return <BlockWrapper>
        <DragWrapper id={id} index={block_index}>
            {content}
        </DragWrapper>
    </BlockWrapper>;
};


const Media = (props) => {
    const entity_at = props.block.getEntityAt(0);

    if (props && props.blockProps && props.blockProps.hide) {
        return null;
    }

    let data = {
        getData: () => {
            return {}
        }
    }, type = "";

    if (entity_at) {
        data = props.contentState.getEntity(
            entity_at
        );
        type = data.getType();
    }

    let content;
    if (type === 'audio') {
        content = <Audio props={props} {...data.getData()} />;
    } else if (type === 'image') {
        content = <EB_Image props={props}  {...data.getData()} />;
    } else if (type === 'video') {
        content = <Video props={props}  {...data.getData()} />;
    } else if (type === 'community-invite') {
        content = <EB_CommunityInvite {...data.getData()} />
    } else if (type === 'poll') {
        content = <EB_Poll props={props} {...data.getData()} />
    } else {
        content = <Generic {...props} />
    }

    if (!props.blockProps.can_drag) {
        return content;
    }
    const {block_index} = props.blockProps;
    const id = props.block.getKey();

    return <div>
        <DragWrapper id={id} index={block_index}>
            {content}
        </DragWrapper>
    </div>;
};

function shouldHide(text_only, type) {
    return text_only && (type === "image-with-caption" || type === "atomic" || type === 'widget-poll' || type === 'custom-widget');
}

export const scEditorBlockRenderer = (setEditorState, read_only = false, current_content, can_rearrange, display, is_mobile) => contentBlock => {
    const type = contentBlock.getType();

    const block_map = current_content ? current_content.getBlockMap() : {};

    const text_only = display?.text_only || false;

    const block_index = !current_content ? 0 : block_map.keySeq().findIndex(k => k === contentBlock.getKey());
    // block_count,block_preview_count

    const onOpenLighbox = display?.fns?.onOpenLighbox || (() => {
    });

    const hide = !display ? false : block_index > display.block_preview_count;
    const hide_empty = display?.compact && (type === "unstyled" || type === "atomic") && contentBlock.getText().length === 0;
    const block_props = {
        can_drag: can_rearrange && !read_only && block_map.size > 1,
        block_index,
        hide: hide_empty || hide || shouldHide(text_only, type),
    };

    if (type === 'header-one' || type === 'header-two' || type === 'header-three') {
        return {
            component: EBHeaderQuote,
            editable: !read_only,
            props: {...block_props},
        };
    } else if (type === "image-with-caption") {
        return {
            component: EB_Image,
            editable: !read_only,
            props: {
                setEditorState,
                read_only,
                onOpenLighbox: read_only ? onOpenLighbox : null,
                ...block_props
            }
        };
    } else if (type === 'members-list') {
        return {
            component: MemberList,
            editable: false,
            props: {
                ...block_props
            },
        }
    } else if (type === 'custom-widget') {
        return {
            component: CustomWidget,
            editable: false,
            props: {
                read_only,
                ...block_props
            },
        }
    } else if (type === 'widget-poll') {
        return {
            component: PollWidget,
            editable: false,
            props: {
                ...block_props
            }
        }
    } else if (type === 'stats-summary') {
        return {
            component: StatsSummary,
            editable: false,
            props: {
                ...block_props
            },
        };
    } else if (type === 'atomic') {
        return {
            component: Media,
            editable: !read_only,
            props: {
                ...block_props
            },
        };
    } else if (type === 'blockquote') {
        return {
            component: EBBlockQuote,
            editable: !read_only,
            props: {
                ...block_props
            },
        };
    } else if(type === "signature-break") {
        return {
            component: EBSignatureBreak,
            editable: !read_only,
            props: {
                ...block_props
            },
        };
    } else {
        return {
            component: Generic,
            editable: !read_only,
            props: {
                ...block_props
            },
        };
    }
}