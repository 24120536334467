import React, {useState} from "react"
import './styles.css';
import Modal from "../modal";
import {XMarkIcon} from "@heroicons/react/24/outline";

function MenuItem({active, onClick, label, icon}) {
    const colors = active ? 'bg-blue-600 font-semibold' : 'hover:bg-gray-100';
    return <div onClick={() => {
        if (onClick) {
            onClick();
        }
    }} className={`pl-1.5 pr-4  cursor-pointer items-center flex rounded-lg grid gap-2 ${colors}`}
                style={{gridTemplateColumns: 'auto 1fr'}}>
        <div className={`h-5 w-5 ${active ? "text-gray-100" : "text-gray-500"}`}>
            {icon}
        </div>
        <div className={`${active ? "text-white" : "text-gray-800"} text-sm leading-7`}>{label}</div>
    </div>
}

function ModalMenu({options, onChange, active}) {
    return <div className="space-y-0.5 flex sm:block sm:space-x-0 space-x-2">
        {options.map((opt, index) => {
            if (opt.divider) {
                return <div className="mx-2" key={index}>
                    <div className="border-b border-gray-200 pt-3 "/>
                    <div className="pt-3"/>
                </div>
            }
            return <MenuItem onClick={() => onChange(opt.label)} active={opt.label === active} {...opt} key={index}/>
        })}
    </div>
}

function ArrowAction({children, disabled, onClick}) {
    const cl = disabled ? "text-gray-200 cursor-not-allowed" : "text-gray-500 cursor-pointer hover:text-gray-800";
    return <div onClick={() => {
        if (onClick) {
            onClick();
        }
    }} className={`${cl} w-7 h-7 rounded-full flex items-center justify-center hover:bg-gray-100 `}>
        <div className="w-5 h-5">
            {children}
        </div>
    </div>
}

export default function PreferencesModal({onClose, size="large", title="Preferences", starting_tab = "", options = []}) {
    const [tab, setTab] = useState(starting_tab)

    function renderTab() {
        const index = options.findIndex(a => a.label === tab);
        if (index !== -1) {
            return options[index].comp;
        } else {
            return <div>--</div>
        }
    }

    return <Modal min_height="64vh" center size={size}>
        <div className="px-4 pt-3.5 pb-3 flex">
            <div className={"flex-grow"}>
                <div className="text-lg tracking-tight font-bold text-gray-800">{title}</div>
            </div>
            <div>
                <ArrowAction onClick={() => onClose()}>
                    <XMarkIcon/>
                </ArrowAction>
            </div>
        </div>
        <div className="border-b border-gray-200 my-0.5"/>
        <div className="flex flex-col sm:grid px-4 gap-6 pb-12" style={{gridTemplateColumns: '12rem 1fr'}}>
            <div className="pt-2 -mx-1.5 overflow-x-auto no-scrollbars">
                <ModalMenu active={tab} onChange={nt => setTab(nt)} options={options}/>
            </div>
            <div className="space-y-4 pt-2">
                {renderTab()}
            </div>
        </div>
    </Modal>
};