import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {useIsMobile} from "../hooks/is-mobile";

/*
* This is the Portal atom
 */

const _props = {
    children: {
        type: "node",
        required: true
    },
    id: {
        type: "string",
        required: true
    },
    open: {
        type: "boolean",
        required: false,
        default: true
    },
    onClose: {
        type: "function",
        required: false
    },
    background: {
        type: "boolean",
        required: false
    }
};

export default function M3_A_Portal({
                                        children,
                                        id,
                                        open = true,
                                        onClose,
                                        close_on_escape = true,
                                        background
                                    }) {

    useEffect(() => {
        if(!open) {
            return;
        }
        function handleEscape(e) {
            if (e.key === 'Escape') {
                onClose();
            }
        }

        if (close_on_escape) {
            document.addEventListener('keydown', handleEscape);
        }

        return () => {
            if (close_on_escape) {
                document.removeEventListener('keydown', handleEscape);
            }
        };
    }, [open]);

    if (!open) {
        return null;
    }

    let classes = "m3-a-portal";

    if (background) {
        classes += " bg-black bg-opacity-70 fixed inset-0 z-50";
    }

    classes += " pt-4 sm:pt-12 ";

    const c = <div className={classes} id={id}>
        {children}
    </div>;

    return ReactDOM.createPortal(
        c,
        document.body
    );
}