import React from "react";
import {LayoutStandard} from "../../../m3/_legacy_components/app-frame/layouts";
import {SectionHeader} from "../../../m3/_legacy_components/entity-grid";
import {collection, getDocs, limit, orderBy, query, where} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import dayjs from "dayjs";
import EntityTabs from "../../../m3/_legacy_components/entity-tabs";

function getAdjustedDate(tab) {
    const now = new Date();
    switch (tab) {
        case 'week':
            return now.setDate(now.getDate() - 7);
        case 'month':
            return now.setDate(now.getDate() - 30);
        case 'year':
            return now.setDate(now.getDate() - 365);
        default:
            return now.setDate(now.getDate() - 7);
    }
}

function RecentSignUps() {
    const [data, setData] = React.useState([]);
    const [tab,setTab] = React.useState('week');

    React.useEffect(() => {
        loadData();
    }, [tab])

    function loadData() {
        const date = getAdjustedDate(tab);
        const ref = collection(db, "users");
        const q = query(ref, where('created_at', '>', date), orderBy('created_at','desc'), limit(500));
        getDocs(q)
            .then((querySnapshot) => {
                const a = [];
                querySnapshot.forEach((doc) => {
                    a.push(doc.data());
                });
                setData(a);
            })
    }

    const tabs = [
        {
            label: 'Week',
            id: 'week',
        },
        {
            label: 'Month',
            id: 'month',
        },
        {
            label: 'Year',
            id: 'year',
        }
    ]

    const suffix = data.length > 499 ? `(500+)` : `(${data.length})`;

    return <div>
        <SectionHeader title="Recent Sign Ups" suffix={suffix} />
        <div>
            <EntityTabs active={tab} onChangeTab={(l,it)=>{
                setTab(it.id)
            }} tabs={tabs} />
        </div>
        <div className="pt-3 pb-40">
            <div className="grid gap-3 border-b border-gray-200 py-2" style={{gridTemplateColumns:'35% 45% 20%'}}>
                <div className="text-sm text-gray-500">Name</div>
                <div className="text-sm text-gray-500">Email</div>
                <div className="text-sm text-gray-500">Date</div>
            </div>
            <div>
                {data.map((item, index) => {
                    return <div key={index} className="grid gap-3 border-b border-gray-200 py-2" style={{gridTemplateColumns:'35% 45% 20%'}}>
                        <div className="text-sm">{item.name}</div>
                        <div className="text-sm">{item.account_email}</div>
                        <div className="text-sm">{dayjs(item.created_at).fromNow()}</div>
                    </div>
                })}
            </div>
        </div>
    </div>
}

export function ManageAnalyticsPage() {

    return <LayoutStandard>
        <RecentSignUps />
    </LayoutStandard>
}