import {getItemFromLocalStorage} from "../../../m3/utilities/localstorage";
import {api_updateListView, handleBulkChanges} from "./api";
import {getDirectoryData} from "../../../m3/_legacy_components/directory/layouts/gallery";
import {getManyMembers} from "../members/create";
import {composer_preparePrefillFromTemplate} from "../write/utilities";
import {chunk, getManyDocuments} from "../../../config/community";
import {documentId} from "firebase/firestore";
import {data_autocomplete_map} from "../../../../common/data/autocomplete-map";
import {getMembershipLevelOptions} from "../../../../common/utilities/general";

function convertArrToObj(arr) {
    const obj = {};
    arr.forEach(a => {
        obj[a.id] = a;
    });
    return obj;
}


const attribute_transforms = [
    {
        accessorKey: "member_type",
        colDataTransform: (col_data, community) => {
            if (!community.member_types) {
                return col_data;
            }

            col_data.display["value_options"] = getMembershipLevelOptions(community);
            col_data.display.autocomplete = "value_options";

            return col_data;
        }
    },
    {
        accessorKey: "membership_status",
        colDataTransform: (col_data, community) => {
            // use data from data_autocomplete_map?.[display.autocomplete]?.data
            const dt = data_autocomplete_map?.[col_data.display.autocomplete]?.data;

            if(!dt) {
                return col_data;
            }

            col_data.display["value_options"] = dt;

            return col_data;
        }
    }
];

export function contacts_buildContactsAttributes(atts, community) {

    attribute_transforms.forEach(transform => {
        const index = atts.findIndex(att => att.col_data.accessorKey === transform.accessorKey);
        if (index > -1) {
            atts[index].col_data = transform.colDataTransform(atts[index].col_data, community);
        }
    })

    return atts;
}

export function contacts_parseListViewData(obj) {
    Object.keys(obj.views).forEach(key => {
        obj.views[key].config = JSON.parse(obj.views[key].config);
    })

    return obj;
}

export function contacts_getInitialActiveList() {
    // check url param list = list_id

    const url_params = new URLSearchParams(window.location.search);

    if (url_params.has("list")) {
        return url_params.get("list");
    }

    return "";
}

export function contacts_buildSortOptions(attributes, community) {
    let a = [];

    attributes.filter(a => a.col_data._can_sort).forEach(att => {
        a.push({
            label: att.label,
            value: att.col_data.accessorKey,
        });
    })

    return a;
}

export function contacts_getActiveViewId(community_uid, parsed_data, param_view_id) {
    // check for pref
    const last_viewed_view_id = getItemFromLocalStorage(`${community_uid}-${parsed_data.id}-view`, "");
 // console.log("PARAM VIEW ID", param_view_id, "LAST VIEWED VIEW ID", last_viewed_view_id, "PARSED DATA", parsed_data, "VIEWS", parsed_data.views, "DEFAULT VIEW", Object.keys(parsed_data.views).find(key => parsed_data.views[key].is_default))
    if(param_view_id && parsed_data.views[param_view_id]) {
        return param_view_id;
    } if (last_viewed_view_id && parsed_data.views[last_viewed_view_id]) {
        return last_viewed_view_id;
    }
    return Object.keys(parsed_data.views).find(key => parsed_data.views[key].is_default);
}

function handleChangeSort(_updated, config) {
    const {field, label, dir} = _updated;

    // update active view data
    const updated = {
        ...config.state.active_view_data,
        config: {
            ...config.state.active_view_data.config,
            sort: [
                {
                    field,
                    dir,
                    label
                }
            ]
        }
    }

    config.fns.setActiveViewData(updated);

    config.fns.setUnsavedChanges(true);
}

function handleUpdateAttributes(new_atts, config) {
    if (JSON.stringify(new_atts) !== JSON.stringify(config.state.active_view_data.config.attributes)) {
        // need to set unsaved changes to view
        let view_data = {...config.state.active_view_data};

        view_data.config.attributes = new_atts;

        config.fns.setActiveViewData(view_data);

        config.fns.setUnsavedChanges(true);
    }
}

function handleSaveViewChanges(config) {
    if (!config.state.active_view_data) {
        return;
    }

    api_updateListView({
        community_uid: config.context.community_uid,
        member_id: config.context.member_id
    }, {
        list_id: config.data.list.id,
        view_id: config.state.active_view_id,
        name: config.state.active_view_data.name,
        is_default: config.state.active_view_data.is_default,
        layout: config.state.active_view_data.layout,
        config: JSON.stringify(config.state.active_view_data?.config || {})
    })
        .then((view_id) => {
            config.fns.setUnsavedChanges(false);
        })
}

const transformRecords = (records, transforms, transformPartial = () => {}) => {
    return records.map(record => {
        if(record.__partial) {
            console.log("is partial",record)
            record = transformPartial(record);
        }

        transforms.forEach(transform => {
            // also account for nested field
            const nested = transform.field.split(".");
            if (nested.length > 1) {
                if (nested[0] in record) {
                    record[nested[0]][nested[1]] = transform.transform(record[nested[0]][nested[1]],record);
                }
            } else if (transform.field in record) {
                record[transform.field] = transform.transform(record[transform.field],record);
            }
        })
        return record;
    })
};

async function handleGetData(config, active_view_config, query = "", page_size = 25, start_after = null, existing_size = 0) {

    // active_view_config - filters, sort, attributes
    return new Promise((resolve, reject) => {
        //cid, view, defs, len, page_size = 25, start_after, group_id, query, filters, sort, attributes

        getDirectoryData(config.context.community_uid, {}, {}, existing_size, page_size, start_after, active_view_config.space, query, active_view_config.filters, active_view_config.sort, active_view_config.attributes)
            .then((docs) => {
                return resolve([transformRecords(docs.map(doc => {
                    return {
                        __partial: !!doc.partial,
                        ...doc.data(),
                        id: doc.id,
                    }
                }), config.data.transforms, config.fns.transformPartial), docs[docs.length - 1]]);
            });
    });
}

function handleDeletedView(config, _view_id, _default_view_id) {
    const updated = {
        ...config.data.list,
        views: {
            ...config.data.list.views
        }
    }
    delete updated.views[_view_id];

    // ensure default view is set
    updated.views[_default_view_id].is_default = true;

    // ensure no other views are set to default
    Object.keys(updated.views).forEach((k) => {
        if(k!==_default_view_id) {
            updated.views[k].is_default = false;
        }
    });

    config.fns.setListData(updated);
    if(config.state.active_view_id === _view_id) {
        // now set active view to default view
        config.fns.onChangeView(_default_view_id);
        config.fns.setActiveViewData(updated.views[_default_view_id]);
    }
}

function handleEditedAttribute(config, _view_id, _view_data, _attribute_id, _new_config) {

    const updated = {
        ...config.data.list,
        views: {
            ...config.data.list.views,
            [_view_id]: {
                ..._view_data,
                id: _view_id
            }
        }
    }

    config.fns.setListData(updated);

    if(config.state.active_view_id === _view_id) {
        config.fns.setActiveViewData(updated.views[config.state.active_view_id]);
    }

    config.fns.update();
}

function handleNewView(config, _view_id, _view_data, flow) {
    const updated = {
        ...config.data.list,
        views: {
            ...config.data.list.views,
            [_view_id]: {
                ..._view_data,
                id: _view_id
            }
        }
    }
    config.fns.setListData(updated);
    if(config.state.active_view_id === _view_id) {
        config.fns.setActiveViewData(updated.views[config.state.active_view_id]);
    }

    if(flow==="save-new-view") {
        config?.fns?.discardViewChanges();
        // set view as active
        config?.fns?.onChangeView(_view_id);
    }
}

function handleOpenComposer(config, openComposer, template_id = "new-member-invite", type = 'invite', custom_data = {}) {
    getManyMembers(config.context.community_uid, config.state.selected)
        .then((member_docs) => {
            const records = member_docs.map(doc => {
                return {
                    ...doc.data(),
                    id: doc.id,
                }
            });
            composer_preparePrefillFromTemplate(config.context.community_uid, template_id, type, records, () => {
                config.fns.addToast({text: "Invite sent", intent: "success"});
                config.fns.clearSelected();
            }, custom_data).then((prefill) => {
                openComposer(prefill)
            })
        })
}

function handleUpdateRecord(config, id, field, value, dt) {
    const change = {
        id,
        field,
        value
    };
    handleBulkChanges(config.context.community_uid, config.context.member_id, [change])
        .then(a => {
            console.log("DONE", a)
        })
}

const transformPartials = (a = [], transformPartial = () => {}) => {
    return a.map(b=>transformPartial(b));
}
async function getPartialsData(config, fids) {
    let p = [];
    const chunks = chunk(fids, 10);
    chunks.forEach(a => {
        p.push(getManyDocuments('community_members', config.context.community_uid, 'members', documentId(), a));
    })
    const all_results = await Promise.all(p).then(arrs => {
        return arrs;
    });
    return transformRecords(all_results.flat().map(doc => {
        return {
            ...doc.data(),
            id: doc.id,
        }
    }), config.data.transforms);
}

/*
{
    "__partial": true,
    "id": "HTH4BWX89I",
    "name": "Eric Clark",
    "member_type": "X9FBYJABR3",
    "handle": "eric-clark",
    "profile_picture": "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2F1868I1QUR5",
    "account_status": "not-invited",
    "membership_status": "pending"
}
 */
function transformPartialResult(result) {
    return {
        __partial: true,
        id: result.id,
        about: {
            first_name: "",
            last_name: "",
            location: "",
            occupation: ""
        },
        name: result?.name,
        profile_picture: result?.profile_picture,
        member_type: result?.member_type,
        account_email: result?.account_email,
        membership_status: result?.membership_status,
        account_status: result?.account_status,
        contact: {
            email: "",
            phone: ""
        },
        stats: {
            sessions: 0
        }
    }
}

export const contacts_actions = {
    transformPartialResult,
    getPartialsData,
    handleEditedAttribute,
    handleUpdateRecord,
    handleNewView,
    handleDeletedView,
    handleOpenComposer,
    handleGetData,
    handleChangeSort,
    handleSaveViewChanges,
    handleUpdateAttributes
};