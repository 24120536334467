import React, {useEffect, useState} from 'react';
import {gapiAuth, initializeGapi, isGapiInitialized, saveGapiAuthInfo} from "./gapi";
import {gapi} from "gapi-script";
import {useCommunity} from "../../../config/community";
import Button from "../../../m3/_legacy_components/button";
import {GoogleIcon} from "../../auth/sign-in-with-google/google-icon";
import config from "../../../config/firebase-config";
import {APP_SERVER_URL, EXTERNAL_SERVER_URL, SERVER_BASE_URL} from "../../../config/defaults";

export const loadGoogleIdentityScript = (src="https://accounts.google.com/gsi/client") =>
    new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) return resolve()
        const script = document.createElement('script')
        script.src = src
        script.onload = () => resolve()
        script.onerror = (err) => reject(err)
        document.body.appendChild(script)
    })


export function ConnectGoogleAccountField({value, onChange}) {
    const community = useCommunity();
    const [authed, setAuthed] = useState(false);
    const [error, setError] = useState(null);

    useEffect(function () {
        setAuthed(!!value);
    }, [value])

    useEffect(function () {
        if (!authed) {
            checkIfGapiLoaded();
        }
    }, [authed])

    function checkIfGapiLoaded() {
        if (authed) {
            return;
        }
        const is_initiated = isGapiInitialized();
        if (is_initiated) {
            console.log("IS INITIATED")
            // connectToGoogleCalendar();
            //setAuthed(true);
        } else {
            initiateGapi();
        }
    }

    function initiateGapi() {
        initializeGapi()
            .then((res) => {
                console.log("INITIALIZED", res)
                /*
                    connectToGoogleCalendar();
                 */
            })
    }

    function handleSignOut() {
        if (!gapi.auth2) {
            console.error("GAPI NOT INITIALIZED")
            return;
        }

        const auth2 = gapi.auth2.getAuthInstance();

        if (!auth2.isSignedIn.get()) {
            console.log("NOT SIGNED IN")
            return;
        } else {
            console.log("SIGNED IN")
            // now sign out
            auth2.signOut();
        }
    }

    function connectToGoogleCalendar() {
        if (!gapi.auth2) {
            console.error("GAPI NOT INITIALIZED")
            return;
        }

        // load https://accounts.google.com/gsi/client
        // https://developers.google.com/identity/gsi/web/reference/js-reference

        const src = 'https://accounts.google.com/gsi/client'

        const redirect_uri = `${EXTERNAL_SERVER_URL}/google-auth-callback`;

        loadGoogleIdentityScript(src)
            .then(() => {
                console.log("LOADED")
                console.log("CLIENT", !!window.google)
                if (window.google) {
                    const auth_state = {
                        community_uid: community.uid,
                        member_id: community.member_id,
                        user_uid: community.user_uid,
                        redirect_to: `${window.location.href}`
                    };
                    const client = window
                        .google
                        .accounts
                        .oauth2
                        .initCodeClient({
                            client_id: config.calendarClientID,
                            scope: 'https://www.googleapis.com/auth/calendar.readonly',
                            ux_mode: 'redirect', // must use redirect because i need refresh token
                            redirect_uri: redirect_uri,
                            state: JSON.stringify(auth_state)
                        });

                    client.requestCode();
                }
            })
        /*
        gapi.client.request({
          path: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events`,
        });
         */
        /*
        const client = window.google.accounts.oauth2.initCodeClient({
            client_id: config.calendarClientID,
            scope: 'https://www.googleapis.com/auth/calendar.readonly',
            ux_mode: 'redirect',
            redirect_uri: `${APP_SERVER_URL}/google-auth-callback`,
            state: "CalendarConnect"
        });

        client.requestCode();

         */

        return;

        const auth2 = gapi.auth2.getAuthInstance();

        const code_client = gapi.oauth

        if (!auth2.isSignedIn.get()) {


// sign in first?
            auth2.signIn({
                prompt: 'consent',
                approval_prompt: 'force',
                access_type: 'offline'
            })
                .then((res) => {
                    // now we need to get authresult
                    const authResult = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true);

                    const refresh_token = authResult.refresh_token;

                    console.log("REFRESH TOKEN", refresh_token)

                    console.log("AUTH RESULT", authResult)
                    if (authResult && authResult.access_token) {
                        saveGapiAuthInfo(community.uid, community.member_id, authResult);
                        getUserInformation(res);
                    }
                })
        }
    }

    function getUserInformation(res) {
        // from the gapi auth instance, get the user's information
        const auth2 = gapi.auth2.getAuthInstance();
        console.log("AUTH 2", auth2)
        console.log("is signed in", auth2.isSignedIn.get())
        if (!auth2.isSignedIn.get()) {
            // sign in first?
            console.log("NOT SIGNED IN")

        }

        const user = auth2.currentUser.get();
        // not actually getting user here, Why?

        console.log("RES", res)
        console.log("User", user)

        if (!user) {
            console.log("NO USER")
            alert("something went wrong..");
            return;
        }
        const profile = user.getBasicProfile();
        if (!profile) {
            console.log("NO PROFILE")
            alert("something went wrong..");
            return;
        }
        const email = profile.getEmail();
        if (!email) {
            console.log("NO EMAIL")
            alert("something went wrong..");
            return;
        }
        onChange({
            email,
            access_token: res.access_token,
            refresh_token: res.refresh_token,
            id_token: res.id_token,
            member_id: community.member_id
        });
    }

    function openPopup() {
        connectToGoogleCalendar();
    }

    function disconnectAccount() {
        onChange({email: "", member_id: community.member_id});
    }

    console.log("authed", authed)

    if (authed) {
        return <div>
            <div className="text-sm text-gray-800 font-semibold">
                Connected to {value}
            </div>
            <div className="pt-1 text-xs text-gray-600">
                <a onClick={() => disconnectAccount()} className="text-blue-600 cursor-pointer hover:text-blue-800">Disconnect
                    account</a>
            </div>
        </div>
    }

    return <div>
        <Button left_icon={{icon: <div className="h-5 w-5"><GoogleIcon/></div>}} text="Connect Google Calendar"
                onClick={() => openPopup()}/>

        <Button text="test sign out" onClick={handleSignOut}/>
    </div>
}