import React, {useState} from 'react';
import FollowButton from "../../../m3/_legacy_components/follow-button";
import {useCommunity} from "../../../config/community";
import Button from "../../../m3/_legacy_components/button";
import {api_joinGroup} from "../../../../api/groups/join";
import {GroupMute} from "./group-mute";
import {getGroupNotificationSettings, getNotificationPreferences} from "../preferences/tabs/notifications";
import {BellSlashIcon, CheckIcon} from "@heroicons/react/20/solid";
import ContentPopup from "../../../m3/_legacy_components/content-popup";
import {
    ContentPopupDivider,
    ContentPopupMenu,
    ContentPopupSubheader
} from "../../../m3/_legacy_components/content-popup/components";
import SimpleTooltip from "../../../m3/_legacy_components/tooltip";
import {api_muteGroup, api_unmuteGroup} from "../../../../api/groups/mute";
import {api_setGroupNotificationSettings} from "../../../../api/groups/notifications";

const base = {
    method: 'email',
    muted: false,
    preference: 'every-post'
};

export function getEntityNotificationPreferences(n, type, id) {
    const field = `${type}-${id}`;
    if(!n) return {...base};
    if (n?.[field]) {
        return {
            method: n[field].method ? n[field] : 'email',
            muted: !!n[field].muted,
            preference: n[field].preference ? n[field].preference : 'every-post'
        };
    }
    return {...base};
}

export function GroupPrimaryButton({id, group = {user_uids: {}}}) {
    const community = useCommunity();
    const [joined, setJoined] = useState(group && group.user_uids ? group.user_uids[community.member_id] : false);
    const [loading, setLoading] = useState(false);
    const community_uid = community.uid;
    if (joined) {
        const all_prefs = getNotificationPreferences(community.member, "America/Los_Angeles");
        const group_notification_settings = getGroupNotificationSettings(community.member, all_prefs, id)

        const mailing_list = group?.['power_ups']?.['unaty__mailing_lists']?.config?.email_address || "";
        const notification_prefs = getEntityNotificationPreferences(community.member.notifications, 'group', id);

        const content = <ContentPopup>
            <ContentPopupSubheader top>
                Notify me about:
            </ContentPopupSubheader>
            <ContentPopupMenu can_check items={[
                {
                    label: "All new messages",
                    checked: group_notification_settings.notify_me_about === 'all',
                    onClick: () => {
                        community.simpleMemberUpdate({
                            notification_settings: {
                                ...all_prefs,
                                group_messages: {
                                    ...all_prefs.group_messages,
                                    [id]: {
                                        ...all_prefs.group_messages[id],
                                        notify_me_about: 'all'
                                    }
                                }
                            }
                        })
                        api_setGroupNotificationSettings(community_uid, community.member_id, id, {
                            ...all_prefs,
                            notify_me_about: 'all'
                        })
                            .then(() => {

                            })
                    }
                },
                {
                    label: "Organizers or members I follow",
                    checked: group_notification_settings.notify_me_about === 'custom',
                    onClick: () => {
                        community.simpleMemberUpdate({
                            notification_settings: {
                                ...all_prefs,
                                group_messages: {
                                    ...all_prefs.group_messages,
                                    [id]: {
                                        ...all_prefs.group_messages[id],
                                        notify_me_about: 'custom'
                                    }
                                }
                            }
                        })
                        api_setGroupNotificationSettings(community_uid, community.member_id, id, {
                            ...all_prefs,
                            notify_me_about: 'custom'
                        })
                            .then(() => {

                            })
                    }
                },
                {
                    label: "Nothing",
                    checked: group_notification_settings.notify_me_about === 'none',
                    onClick: () => {
                        community.simpleMemberUpdate({
                            notification_settings: {
                                ...all_prefs,
                                group_messages: {
                                    ...all_prefs.group_messages,
                                    [id]: {
                                        ...all_prefs.group_messages[id],
                                        notify_me_about: 'none'
                                    }
                                }
                            }
                        })
                        api_setGroupNotificationSettings(community_uid, community.member_id, id, {
                            ...all_prefs,
                            notify_me_about: 'none'
                        })
                            .then(() => {

                            })
                    }
                }
            ]}/>
            {mailing_list && <ContentPopupDivider/>}
            {mailing_list && <ContentPopupMenu can_check items={[
                {
                    onClick: () => {
                        community.simpleMemberUpdate({
                            notifications: {
                                ...community.member.notifications,
                                [`group-${id}`]: {
                                    ...notification_prefs,
                                    muted: !notification_prefs.muted
                                }
                            }
                        })
                        if (notification_prefs.muted) {
                            api_unmuteGroup(community_uid, community.member_id, id)
                                .then(() => {

                                })
                        } else {
                            api_muteGroup(community_uid, community.member_id, id)
                                .then(() => {

                                })
                        }
                    },
                    label: "Subscribe to Mailing List",
                    checked: !notification_prefs.muted,
                    desc: mailing_list
                }
            ]}/>}
        </ContentPopup>

        const icon = group_notification_settings.notify_me_about === 'none' ? {icon: <BellSlashIcon/>} : {icon: <CheckIcon/>};

        const text = group_notification_settings.notify_me_about === 'none' ? "Muted" : "Subscribed";

        return <SimpleTooltip usePortal simple trigger="click" interactive hideOnClick={false} placement="bottom-end"
                              text={content}>
            <Button dropdown left_icon={icon} text={text}/>
        </SimpleTooltip>;
        return <GroupMute notification_prefs={notification_prefs} member_id={community.member_id}
                          community_uid={community.id} id={id}/>
    }

    if (group.closed) {
        return null;
    }

    return <Button minimal inverted size="sm" height="32px" loading={loading} text='Join' intent='success'
                   onClick={() => api_joinGroup(id, community)}/>
}