import {authFetch} from "../network";
import {collection, getDocs, limit, query, where} from "firebase/firestore";
import {db} from "../../app/config/setup-firestore";

export async function getDirectoryViewStacks(cid, page_key = "leaders") {
    const ref = collection(db, "community_entities", cid, "directory_view_stack");
    const q = query(ref, where("type", "==", page_key), limit(50));
    return await getDocs(q)
        .then(snap => {
            return snap.docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            }).filter(stack => {
                return stack.stack&&stack.stack.length>0
            });
        })
}

export async function handleCreateDefaultStack(community_uid, member_id, type = "leaders", title="Leaders", views=[], default_view = {
    label: "All",
    id: "all",
}) {
    const payload = {
        community_uid: community_uid,
        member_id: member_id,

        type: type,
        title,
        purpose: "",

        stack: [
            default_view,
            ...views
        ]
    }
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        authFetch("/directory-view-stack/create", res, res, "POST", {payload});
    })
}

export async function updateDirectoryViewStack(community_uid, member_id, id, update) {
    const payload = {
        community_uid: community_uid,
        member_id: member_id,
        id,
        update
    }
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        authFetch("/_legacy_directory-view-stack/update", res, res, "POST", {payload});
    })
}

export async function deleteDirectoryViewStack(payload) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        authFetch("/_legacy_directory-view-stack/delete", res, res, "POST", {payload});
    })
}