/*
     <div className="hidden">
            It’s nice to see you again, since you last logged in <Bold>Nettra Pan became President</Bold> and <Bold>3
            new groups</Bold> were created. Also, <Bold>4 new members
            joined</Bold>.
        </div>


        change membership levels -> Nettra Pan is now an Editor.
 */

function generateUpdate(type, events) {

    let items = {};

    events.reverse().forEach(evt => {
        items[evt.scope_id] = {
            ...evt
        }
    })

    return {
        type,
        items: Object.values(items)
    };
}

export function processEventsForSemanticWelcome(evts) {
    let types = {};

    evts.forEach(evt => {
        if (!types[evt.type]) {
            types[evt.type] = 0;
        }
        types[evt.type]++;
    });

    const entries = Object.entries(types);

    let updates = [];

    entries
        .sort((a, b) => a > b ? 1 : -1)
        .forEach(([type]) => {
            const items = evts.filter(a => a.type === type);

            updates.push(generateUpdate(type, items))
        })

    return updates;
}