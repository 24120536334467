import {authFetch} from "../network";

export async function api_updatePosition(community, pl) {
   if(!pl.id) {
       alert("Missing position id");
       return;
   }
    return await new Promise((resolve, reject) => {
        const res = (r) => {
            resolve(r);
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,

            id: pl.id,
            ...pl
        };
        authFetch("/roles/update", res, res, "POST", {payload});
    })
}