import React, {useEffect, useRef, useState} from "react"
import './styles.css';
import Modal from "../../../m3/_legacy_components/modal";
import {
    AdjustmentsVerticalIcon, ArrowUpIcon, SwatchIcon, CircleStackIcon,
    EyeIcon,
    PaperClipIcon,
    PhotoIcon, SparklesIcon,
    XMarkIcon, FilmIcon, LinkIcon, DocumentIcon, Bars3BottomLeftIcon, CheckBadgeIcon, CalendarDaysIcon, PencilIcon
} from "@heroicons/react/24/outline";
import {convertToRaw} from 'draft-js';
import {WriteTo} from "./to";
import {CheckIcon} from "@heroicons/react/24/solid";
import SimplePortal from "../../../m3/_legacy_components/simple-portal";
import {WriteBody} from "./body";
import {WriteSubject} from "./subject";
import {useCommunity} from "../../../config/community";
import {CT_Invite} from "./system-templates/invite";
import {CT_AccessDetails} from "./system-templates/access-details";
import {InlineLoader} from "../../../m3/_legacy_components/admin-activity";
import {ArrowAction} from "../manage-member/edit-member";
import {NewFormatIcon} from "../../../m3/_legacy_components/app-frame/icons";
import {ArrowLeftIcon} from "@heroicons/react/20/solid";
import {authFetch} from "../../../../api/network";
import {FieldLabel} from "../../../m3/_legacy_components/form/field";
import {ThreadItemPreview} from "../../../features/forum/forum/threads-view";
import {NewPollModal} from "../../../m3/_legacy_components/poll/new-poll-modal";
import {useToasts} from "../../../config/toasts";
import {SignatureEditor} from "./signature-editor";
import IconSelectMenu from "../../../m3/_legacy_components/icon-select-menu";
import {logEngagementEvent} from "../../../config/firebase-setup";
import SimpleTooltip from "../../../m3/_legacy_components/tooltip";
import Button from "../../../m3/_legacy_components/button";
import {ToggleWithTitle} from "../../../m3/_legacy_components/toggle-with-title";
import {CT_PolicyUpdate} from "./system-templates/policy-update";
import {CT_OptIn} from "./system-templates/opt-in";
import {api_editPost} from "../../../../api/messages/edit-post";
import {
    composer_handleSendEmail,
    composer_handleSendCustom,
    composer_handleSendInvite,
    composer_handleSendPost
} from "../../../../api/messages/group";

function getBg(active, intent) {
    if (intent) {
        return "bg-blue-500 text-white group-hover:bg-blue-400";
    }
    return active ? "bg-gray-200 text-gray-800" : "group-hover:bg-gray-200 group-hover:text-gray-700 text-gray-600"
}

export function ModalMenuButton({label, loading, onClick, disabled, intent, active, icon}) {
    const bg = getBg(active, intent);
    /*
    <div className="pt-0.5">
            <div className="text-xs font-semibold text-gray-600 tracking-tight">{label}</div>
        </div>
     */

    const has_icon = icon !== undefined;

    const content = <div onClick={() => {
        if (!disabled && onClick) {
            onClick();
        }
    }}
                         className={`flex cursor-pointer group flex-col items-center ${disabled ? "pointer-events-none cursor-not-allowed opacity-50" : ""}`}>
        <div className="">
            <div
                className={`${bg} transition-colors  flex items-center justify-center rounded-md ${has_icon ? "w-9" : "px-2"} h-7`}>
                <div
                    className={`${has_icon ? "w-5 h-5 flex items-center justify-center svg-child-inherit-height" : ""}`}>
                    {loading ? <InlineLoader mini inverted/> : has_icon ? icons[icon] :
                        <span className="text-sm font-medium">{label}</span>}
                </div>
            </div>
        </div>
    </div>;

    return content;
}

function MMBWrapper({children}) {

    return <div className="flex gap-2">
        {children}
    </div>
}

function NotifyMessage({to}) {
    const [active, setIsActive] = useState(false);
    const total = to.map(a => a.count).reduce((partialSum, a) => partialSum + a, 0);
    if (total === 0) {
        return;
    }
    return <div className="flex ml-8 pl-1.5 items-center text-xs text-gray-500 space-x-2">
        <div><span className="text-gray-600 font-medium">{total} member{total === 1 ? "" : "s"}</span> will be notified
            via <span
                className="underline">email</span>.
        </div>
    </div>
}

// todo move to icons
const icons = {
    'media': <PhotoIcon/>,
    'attach': <PaperClipIcon/>,
    'check': <CheckIcon/>,
    'format': <NewFormatIcon/>,
    'widgets': <SparklesIcon/>,
    'events': <CalendarDaysIcon/>,
    'signature': <PencilIcon/>,
    'poll': <CheckBadgeIcon/>,
    'send': <ArrowUpIcon/>,
    'variables': <CircleStackIcon/>,
    'preview': <EyeIcon/>,
    'options': <AdjustmentsVerticalIcon/>,
};


const template_component = {
    'policy-update': CT_PolicyUpdate,
    'opt-in': CT_OptIn,
    'new-member-invite': CT_Invite,
    'system-invite': CT_Invite,
    'access-details': CT_AccessDetails,
};

const type_mappings = {
    'group': {
        label: "Post"
    },
    'post': {
        label: "Post"
    },
    'email': {
        label: "Email"
    },
    'invite': {
        label: "Invite"
    },
    'access-details': {
        label: "Access Details"
    },
    'policy-update': {
        label: "Policy Update Email"
    },
    'opt-in': {
        label: "Opt-In Email"
    },
};

export function getImagesFromContentState(cs) {
    let images = [];

    const bma = cs.getBlocksAsArray();

    bma.forEach(cb => {
        const data = Object.fromEntries(cb.getData());

        if (cb.getType() === 'image-with-caption') {
            images.push({
                url: data.url,
                width: data.width,
                name: "",
                height: data.height,
                caption: data.text,
            });
        }
    })

    return images;
}


const default_features = {
    can_add_to: true,
    can_edit_to: true
}

async function api_addFilesToDrive(community, {files, folder_id}) {

    if (!folder_id) {
        alert("No folder id");
        return;
    }

    const payload = {
        community_uid: community.uid,
        member_id: community.member_id,
        folder: folder_id,
        files: files.map(f => {
            return {
                name: f.name,
                link: f.url,
                url: f.url,
                size: f.size,
                type: f.type,
                folder: f.folder,
            }
        })
    };
    return new Promise(resolve => {
        const r = () => {
            resolve();
        };
        authFetch('/files/create-many', r, r, "POST", {payload});
    })
}

function RichToolbar({
                         can_add_poll = true,
                         addPoll = () => {
                         },
                         addSignature = () => {
                         },
                         addEvents,

                         toggleFormatting, formatting_active,

                         openAddMedia = () => {
                         },
                         openAddAttachment = () => {
                         },
                         props = {},
                         has_signature
                     }) {

    return <div className="bg-white px-5 pb-1 pt-2">
        <MMBWrapper>
            <ModalMenuButton onClick={() => {
                openAddMedia()
            }} icon='media' label="Media"/>
            <ModalMenuButton onClick={() => {
                openAddAttachment()
            }} icon='attach' label="Attach"/>
            <ModalMenuButton onClick={() => toggleFormatting()} active={formatting_active} icon='format'
                             label="Format"/>
            {can_add_poll && <ModalMenuButton onClick={() => {
                addPoll()
            }} icon='poll' label="Poll"/>}
            {!!addEvents && <ModalMenuButton onClick={() => {
                addEvents()
            }} icon='events' label="Events"/>}

        </MMBWrapper>
    </div>
}

function StartingPrompt({
                            handleClick = () => {
                            },
                            can_add_poll,
                            title = ""
                        }) {

    let items = [
        {
            label: "Text",
            type: "text",
            onClick: () => handleClick("text")
        },
        {
            label: "Image",
            type: "image",
            onClick: () => handleClick("image")
        },
        /*
        {
            label: "Video",
            type: "video",
            onClick: () => handleClick("video")
        },
 {
            label: "Link",
            type: "link",
            onClick: () => handleClick("link")
        },
         */

        {
            label: "File",
            type: "file",
            onClick: () => handleClick("file")
        }
    ];

    if (can_add_poll) {
        items.push({
            label: "Poll",
            type: "poll",
            onClick: () => handleClick("poll")
        })
    }

    return <IconSelectMenu title={title} items={items}/>;
}

function ContentModals({
                           open = "", onCreatePoll = () => {
    }, onClose = () => {
    }
                       }) {
    return <div>
        {open === 'poll' && <NewPollModal onCreatePoll={(poll_id) => {
            onCreatePoll(poll_id);
            onClose();
        }} onClose={() => onClose()}/>}
    </div>
}

function getToastText(type, state = 'sending') {
    switch (type) {
        case 'email':
            return state === "sending" ? "Sending email" : "Email sent";
        case 'invite':
            return state === "sending" ? "Sending invite" : "Invite sent";
        case 'access-details':
            return state === "sending" ? "Sending access details" : "Access details sent";
        case 'data-check':
            return state === "sending" ? "Sending data check" : "Data check sent";
        case 'policy-update':
            return state === "sending" ? "Sending policy update" : "Policy update sent";
        case 'opt-in':
            return state === "sending" ? "Sending opt-in" : "Opt-in sent";
        default:
            return state === "sending" ? "Creating post" : "Post created";
    }
}

export default function WritePage(props) {
    const [edit, setEdit] = useState(props.edit || false);
    const [type, setType] = useState(props.type || "post");
    const [portal, setPortal] = useState(true);
    const [to, setTo] = useState(props.to || []);
    const community = useCommunity();
    const [features, setFeatures] = useState({
        ...default_features,
        ...(props.features || {})
    });
    const [editor_action, setEditorAction] = useState({});
    const [phase, setPhase] = useState(props.phase || "start");
    const [state, setState] = useState(props.state || "");
    const [attachments, setAttachments] = useState(props.attachments || {});
    const [editor_state, setEditorState] = useState(null);
    const [show_formatting_bar, setShowFormattingBar] = useState(false);
    const [subject, setSubject] = useState(props.subject || "");
    const [template, setTemplate] = useState(props.template || "");
    const [addToDrive, setAddToDrive] = useState(false);
    const [custom_data, setCustomData] = useState(props.custom_data || {});
    const [send_email, setSendEmail] = useState(true);
    const [folder_id, setFolderId] = useState(props.folder_id || "");
    const [open, setOpen] = useState("null");
    const toasts = useToasts();

    const [signature, setSignature] = useState(community.member_signature_raw || null);

    const action_flags = useRef({});

    const default_settings = {
        header_style: "default",
        header_custom: "",
        from_name: community.member.name,
        reply_to: community.member.account_email,
        track_delivery: true,
        enable_replies: true
    };

    function handleSave() {
        if (!props.post_id) {
            alert('No post id provided');
            return;
        }

        setState("loading");
        const final_raw = convertToRaw(editor_state.getCurrentContent());

        api_editPost(community, {
            id: props.post_id,
            subject,
            raw: final_raw
        })
            .then(res => {
                setState("");
                props.onClose({
                    subject,
                    raw: final_raw
                });
            })
    }

    function handleSend() {
        if (state === 'loading') {
            return;
        }

        toasts.addToast({
            text: getToastText(type),
            intent: "info"
        })

        setState("loading");
        const contentState = editor_state.getCurrentContent();
        console.log("SUBMIT", {
            attachments,
            to
        })
        const payload = {
            series: "",
            community_uid: community.uid,
            member_id: community.member_id,

            message_type: type === "post" ? "group" : type,

            cc: "",
            cc_data: {},

            to: to.filter(a => a.email).map(it => `${it.label ? it.label : it.name} <${it.email}>`).join(','),
            to_data: to,
            send_email,
            settings: default_settings,
            images: getImagesFromContentState(contentState),
            attachments: Object.entries(attachments).filter(a => a[1].status === 'done').map(ent => {
                return {
                    id: ent[1].id,
                    folder: folder_id || ent[1].folder,
                    url: ent[1].url,
                    name: ent[1].name,
                    type: ent[1].type,
                    size: ent[1].size
                }
            }),
            subject,
            subtitle: "",

            custom_data,

            raw: JSON.stringify(convertToRaw(contentState)),
            plain_text: contentState.getPlainText(),

            // already converted to raw and stringified
            signature_raw: signature
        };

        const res = (response) => {
            setState("");
            toasts.addToast({
                text: getToastText(type, 'success'),
                intent: "success"
            })
            if (props.onSend) {
                props.onSend();
            }
            if (props.onClose) {
                props.onClose();
            }
        };

        console.log("PAYLOAD", payload)

        if (type === 'invite') {
            logEngagementEvent("send_invite", {}, community.uid);
            composer_handleSendInvite(payload, {community}, res);
        } else if (type === 'access-details') {
            logEngagementEvent("send_access_details", {}, community.uid);
            composer_handleSendInvite(payload, {community}, res);
        } else if (type === 'email') {
            logEngagementEvent("send_email", {}, community.uid);
            composer_handleSendEmail(payload, {community}, res);
        } else if (type === 'data-check') {
            logEngagementEvent("send_data_check", {}, community.uid);
            composer_handleSendCustom(payload, {community}, res);
        } else if (type === 'policy-update') {
            logEngagementEvent("send_policy_update", {}, community.uid);
            composer_handleSendCustom(payload, {community}, res);
        } else if (type === 'opt-in') {
            logEngagementEvent("send_opt_in", {}, community.uid);
            composer_handleSendCustom(payload, {community}, res);
        } else {
            logEngagementEvent("create_post", {}, community.uid);
            composer_handleSendPost(payload, {community}, res);
        }

        // TODO add files to drive
        if (addToDrive) {
            api_addFilesToDrive(community, {
                folder_id: folder_id,
                files: Object.entries(attachments).filter(a => a[1].status === 'done').map(ent => {
                    return {
                        id: ent[1].id,
                        folder: ent[1].folder,
                        url: ent[1].url,
                        name: ent[1].name,
                        type: ent[1].type,
                        size: ent[1].size
                    }
                })
            })
        }
    }

    useEffect(() => {
        // on startup
        /*
        if(community.member_signature_raw) {
            if(action_flags.current['insert-signature']) {
                return;
            }
            setEditorAction({
                type: 'insert-signature',
                raw: community.member_signature_raw
            })
            action_flags.current['insert-signature'] = true;
        }

         */
    }, [])

    function handleClose() {
        if (props.onClose) {
            props.onClose();
        } else {
            setPortal(!portal)
        }
    }

    function handleInsertPoll(poll_id) {
        setEditorAction({
            type: 'insert-poll',
            id: poll_id
        })
    }

    function handleInsertWidget(widget_id) {
        setEditorAction({
            type: 'insert-widget',
            id: widget_id,
            data: {
                group_id: props?.group_id || ""
            }
        })
    }

    function handlePromptClick(prompt_id) {
        let el;
        switch (prompt_id) {
            case 'poll':
                setOpen("poll");
                break;
            case 'file':
                el = document.getElementById('compose-attachment-listener');
                if (el) {
                    el.focus();
                } else {
                    console.log('no element')
                }
                break;
            case 'image':
                el = document.getElementById('compose-media-listener');
                if (el) {
                    el.focus();
                } else {
                    console.log('no element')
                }
                break;
            case 'text':
                el = document.getElementById('compose-focus-listener');
                if (el) {
                    el.focus();
                } else {
                    console.log('no element')
                }
                break;
            case 'link':

                break;
        }
    }

    const can_send = subject && to.length;

    const can_update = subject;

    const body_is_empty = editor_state && editor_state.getCurrentContent().getPlainText().length === 0;

    const tc = template && template_component[template] ? {component: template_component[template]} : null;

    let top_label = "";

    if (edit) {
        top_label = "Edit Post";
    } else {
        top_label = `New ${type_mappings[type]?.label || ""}`;
    }

    let core;

    const cs = editor_state ? editor_state.getCurrentContent() : null;

    //    {to.length > 0 && <NotifyMessage to={to}/>}
    if (phase === "review") {

        let build_preview = {
            community_uid: community.uid,
            attachments: Object.entries(attachments)
                .filter(a => a[1].status === 'done')
                .map(ent => {
                    return {
                        id: ent[1].id,
                        folder: ent[1].folder,
                        url: ent[1].url,
                        name: ent[1].name,
                        type: ent[1].type,
                        size: ent[1].size
                    }
                }),
            images: getImagesFromContentState(cs),
            raw_content: convertToRaw(cs),
            subject: subject || "",
            author_image: community.member?.profile_picture,
            group_id: to[0]?.group_id || "",
            author_id: community.member_id,
            author_name: community.member?.name
        };


        const show_send_email_section = to?.[0]?.['email'];
        /*
          <div>
                    Send email checkbox
                </div>
         */
        /*
         addSignature={()=>{
                                    setSignature("");
                                }}
                                has_signature={signature!==null}
         */
        core = <div className="flex-grow bg-white rounded-b-lg flex flex-col">
            {!edit && <div>
                <div className="px-5 pt-3 pb-1">
                    <WriteTo demo={false} features={features} type={type} to={to} onChange={nto => setTo([...nto])}/>
                </div>
            </div>}
            <div className="pt-3 px-5 grid gap-3 overflow-auto">
                {type === 'post' && <div className="overflow-auto">
                    <FieldLabel label={"Post Preview"}/>

                    <ThreadItemPreview {...build_preview}/>
                </div>}

                {type === 'post' && show_send_email_section && <div>
                    <FieldLabel label={"Email"}/>
                    <ToggleWithTitle
                        title={"Send Post via Email"}
                        description={"If enabled, this post will be immediately sent via email to all mailing list subscribers."}
                        checked={send_email} onToggle={() => setSendEmail(!send_email)}/>
                </div>}

                <div className="grid gap-2">
                    <FieldLabel label={"Email Signature"}/>
                    {signature !== null && <div className="pt-1 pb-8">
                        <div className="text-gray-500 flex pb-1 text-xs">
                            <div className="flex-grow">Added below your message on emails.</div>
                            <div className=""><a onClick={() => {
                                setSignature(null)
                            }} className="underline cursor-pointer">Remove</a></div>
                        </div>
                        <div className="">
                            <SignatureEditor handleChange={ns => {
                                setSignature(ns);
                            }} init={signature}/>
                        </div>
                    </div>}
                    {signature === null && <div className="pt-1 pb-8">
                        <Button onClick={() => {
                            setSignature("");
                        }} text={"Add Signature"}/>
                    </div>}
                </div>
            </div>
        </div>;

    } else {
        //  {to.length > 0 && <NotifyMessage to={to}/>}
        core = <>
            <div className="flex-grow bg-white rounded-b-lg flex flex-col">
                {!edit && <div>
                    <div className="px-5 pt-3 pb-1">
                        <WriteTo demo={false} features={features} type={type} to={to}
                                 onChange={nto => setTo([...nto])}/>
                    </div>
                </div>}
                <div className=" relative flex-grow">
                    <div className="px-5 pt-2 pb-1.5">
                        <WriteSubject placeholder={type === "email" ? "Subject" : "Title"} autoFocus={!edit}
                                      features={features} subject={subject}
                                      onChange={a => setSubject(a)}/>
                    </div>
                    <div className="px-5">
                        <div className="border-b border-[#D7D7D7]"/>
                    </div>
                    <div className="px-5 grid gap-4 pb-10">
                        <WriteBody editor_action={editor_action} features={features} raw={props.raw || null}
                                   editor_state={editor_state} onUpdateAttachmentsFolder={(nfid) => {
                            setFolderId(nfid);
                            setAddToDrive(!!nfid);
                        }}
                                   hover_formatting={!show_formatting_bar} show_formatting_bar={show_formatting_bar}
                                   folder_id={folder_id || ""} drive_id={props.drive_id || ""}
                                   updateAttachments={(na) => {
                                       setAttachments(na);
                                   }}
                                   add_files_to_folder={addToDrive}
                                   content={props.content || null} onChange={es => setEditorState(es)}/>

                        <div className="pt-1 pb-8">
                            {tc && <tc.component community={community} custom_data={custom_data} {...tc.props} />}
                        </div>

                    </div>

                    <div className="absolute bg-white bottom-0 left-0 right-0 pb-1.5 border-t border-gray-300">
                        {!body_is_empty && <RichToolbar can_add_poll={type === 'post'}
                                                        addPoll={() => {
                                                            handlePromptClick("poll");
                                                        }}

                                                        openAddAttachment={() => {
                                                            handlePromptClick("file");
                                                        }} openAddMedia={() => {
                            handlePromptClick("image")
                        }} toggleFormatting={() => {
                            setShowFormattingBar(!show_formatting_bar);
                        }} formatting_active={show_formatting_bar}/>}
                        {body_is_empty && <StartingPrompt
                            title={type === "email" ? "What would you like to send?" : "What would you like to post?"}
                            can_add_poll={type === 'post'} handleClick={handlePromptClick}/>}
                        <ContentModals open={open} onClose={() => setOpen(null)} onCreatePoll={handleInsertPoll}/>
                    </div>
                </div>
            </div>
        </>;
    }

    // if type === invite, then show send straightaway
    const proceed_button = edit ? "edit" : type === "invite" ? "send" : phase === 'review' ? "send" : "next";

    let m = <Modal classes={"sm:mt-12 sm:mx-auto sm:min-h-[60vh]"}>
        <div className="px-4 border-b border-[#D7D7D7] pt-3 pb-1 rounded-t-xl">
            <div className="flex items-center gap-2 pb-2">
                <div className="flex-none">
                    <ArrowAction onClick={() => {
                        if (phase === "start") {
                            handleClose();
                        } else {
                            setPhase("start");
                        }
                    }}>
                        {phase === "start" ? <XMarkIcon/> : <ArrowLeftIcon/>}
                    </ArrowAction>
                </div>
                <div className="flex-grow">
                    <div className="font-medium text-sm text-gray-600 tracking-tight">{top_label}</div>
                </div>
                <div>
                    {proceed_button === "next" && <ModalMenuButton loading={state === "loading"} onClick={() => {
                        setPhase("review");
                    }} disabled={!can_send} intent="primary" label="Next"/>}
                    {proceed_button === "send" &&
                        <ModalMenuButton loading={state === "loading"} label="Send" intent="primary"
                                         disabled={!can_send} onClick={() => {
                            handleSend();
                        }}/>}
                    {proceed_button === 'edit' &&
                        <ModalMenuButton loading={state === "loading"} onClick={handleSave} disabled={!can_update}
                                         intent="primary" label="Save"/>}
                </div>
            </div>
        </div>
        {core}
    </Modal>;

    return <div className="" onClick={e => e.stopPropagation()}>
        {portal ? <SimplePortal>
            <div className="w-full sm:flex sm:justify-center h-full">
                {m}
            </div>
        </SimplePortal> : m}
    </div>
};