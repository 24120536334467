export const data_sexes_map = {
    'male': {
        label: "Male"
    },
    'female': {
        label: "Female"
    },
    'intersex': {
        label: "Intersex"
    },
    'prefer-not-to-say': {
        label: "Prefer not to say"
    }
};

export const data_sexes_arr =  Object.entries(data_sexes_map).map(([k, v]) => {
    return {
        value: k,
        label: v.label,
    }
})