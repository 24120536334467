import React, {useEffect, useState} from 'react';
import {Avatar} from "../avatar";
import {DEFAULT_USER_PHOTO, useCommunity} from "../../../config/community";
import {
    CheckIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronUpDownIcon,
    ClockIcon,
    XMarkIcon
} from "@heroicons/react/20/solid";
import SortSelect from "../sort-select";
import {ArrowAction} from "../../../routes/community/manage-member/edit-member";
import dayjs from "dayjs";
import {DateTimeFormat} from "../../../routes/community/calendar/components/datetime-selector";
import InlineSelectTimezone from "../inline-select-timezone";

import DataWrapper from "../data-wrapper";
import {buildEventTimeString} from "../../../routes/community/calendar/utilities";
import {getCurrentTimezone} from "../../../../common/utilities/lang-region";
import {buildImageUrl} from "../../../../common/utilities/images";

const grid_cols = `grid grid-cols-4`;

function Cell({children, padding="p-1", classes, top, align_top}) {
    return <div
        className={`p-1 flex ${align_top ? "" : "justify-center items-center"} ${top ? "h-32" : "h-8"} ${classes}`}>{children}</div>
}

function AnswerCell({children}) {
    let classes = ``;
    return <div className={`p-2 ${classes}`}>{children}</div>
}

function Checkbox({onChange, value}) {
    const [status, setStatus] = React.useState(value || 0); // yes, no, maybe
    useEffect(function () {
        onChange(status);
    }, [status])
    let colors = "";
    if (status === 1) {
        colors = "bg-green-600 border-green-600 text-white hover:bg-green-400";
    } else if (status === 0) {
        colors = "bg-red-600 border-red-600 text-white hover:bg-red-400";
    } else if (status === 2) {
        colors = "bg-yellow-600 border-yellow-600 text-white hover:bg-yellow-400";
    } else {
        colors = "bg-white hover:bg-gray-200 border-gray-300";
    }
    return <div>
        <div onClick={() => {
            if (status === 1) {
                setStatus(2)
            } else if (status === 0) {
                setStatus(1)
            } else if (status === 2) {
                setStatus(0)
            } else {
                setStatus(1)
            }
        }} className={` w-5 m-0.5 cursor-pointer p-0.5 h-5 flex ${colors} transition-colors rounded-md border `}>
            {status === 1 && <div className="w-4 h-4">
                <CheckIcon/>
            </div>}
            {status === 0 && <div className="w-4 h-4">
                <XMarkIcon/>
            </div>}
            {status === 2 && <div className="w-4 h-4">
                <ChevronUpDownIcon/>
            </div>}
        </div>
    </div>
}

function EntryResultBox({value = 0, user_field, onChange, can_edit}) {
    let cell_classes = ``;
    if (value === -1 || (!user_field && value === 0)) {
        cell_classes = `bg-gray-100 `;
    } else if (value === 0 && user_field) {
        cell_classes = `bg-red-100 text-red-700`;
    } else if (value === 1) {
        cell_classes = `bg-green-100 text-green-700`;
    } else if (value === 2) {
        cell_classes = `bg-yellow-100 text-yellow-700`;
    }
    return <Cell classes={`${cell_classes} ${!user_field ? "rounded-xl" : "rounded-b-xl"}`}>
        <div className="flex items-center justify-center">
            <div className="h-6 w-6">
                {!can_edit && value === 1 && <CheckIcon/>}
                {!can_edit && value === 0 && <span className="opacity-40"><XMarkIcon/></span>}
                {!can_edit && value === 2 && <ChevronUpDownIcon/>}
                {!can_edit && value === -1 && <ClockIcon/>}
                {can_edit && <Checkbox onChange={onChange} value={value}/>}
            </div>
        </div>
    </Cell>
}

const sort_types = [
    {
        label: "Date",
        value: "date"
    },
    {
        label: "Popular",
        value: "popular"
    }
];

function LegendIcon({children, colors = ""}) {

    return <div className={`w-5 h-5 p-0.5 rounded-md ${colors}`}>
        <div className="w-4 h-4">
            {children}
        </div>
    </div>
}

function LegendItem({
                        text = "",
                        icon = null,
                        colors = ""
                    }) {
    return <div className="flex items-center text-xs gap-1.5 text-gray-600">
        <LegendIcon colors={colors}>
            {icon}
        </LegendIcon>
        <div>{text}</div>
    </div>
}

function Legend() {
    return <div className="flex gap-4">
        <LegendItem text="Yes (1 click)" icon={<CheckIcon/>} colors={"bg-green-100 text-green-800"}/>
        <LegendItem text="If need be (2 clicks)" icon={<ChevronUpDownIcon/>} colors={"bg-yellow-100 text-yellow-800"}/>
        <LegendItem text="No" icon={<XMarkIcon/>} colors={"bg-red-100 text-red-800"}/>
        <LegendItem text="Pending" icon={<ClockIcon/>} colors={"bg-gray-100 text-gray-400"}/>
    </div>
}

function SimplePaginate({
                            options_count = 0, show_column_count,
                            can_paginate,
                            selected_index,
                            setSelectedIndex,
                            max_select_index
                        }) {

    const can_go_left = selected_index > 0;
    const can_go_right = selected_index < max_select_index;

    return <div className="flex gap-2">
        <div className="text-gray-600 items-center inline-flex text-xs">
            <span>{options_count} options</span>
        </div>
        {can_paginate && <div className="flex gap-0.5">
            <ArrowAction disabled={!can_go_left} onClick={() => {
                if (can_go_left) {
                    setSelectedIndex(selected_index - 1);
                }
            }}>
                <ChevronLeftIcon/>
            </ArrowAction>
            <ArrowAction disabled={!can_go_right} onClick={() => {
                if (can_go_right) {
                    setSelectedIndex(selected_index + 1);
                }
            }}>
                <ChevronRightIcon/>
            </ArrowAction>
        </div>}
    </div>
}

function TopActionsBar(props) {

    return <div className="flex h-8 items-center">
        <div className="flex-grow">
            <SortSelect active={props.sort} setSort={ns => props.setSort(ns)} items={sort_types}/>
        </div>
        <div>
            <SimplePaginate {...props}/>
        </div>
    </div>
}

function OptionColumn({data, show_badge, voted, value, handleChange}) {
    // const top_color = "text-gray-800 bg-gray-100";
    //const top_color = "text-green-800 bg-green-100";
    // let top_color = "text-yellow-800 bg-yellow-100";
    let top_color = "";
    if (value === -1) {
        top_color = "text-gray-800 bg-gray-100";
    } else if (value === 0) {
        top_color = "text-red-800 bg-red-100";
    } else if (value === 1) {
        top_color = "text-green-800 bg-green-100";
    } else if (value === 2) {
        top_color = "text-yellow-800 bg-yellow-100";
    }
    return <div className="">
        <Cell top classes={`py-2 relative rounded-t-xl ${top_color}`}>
            <div className=" flex flex-col gap-0.5 items-center">
                <div className="text-xs pt-1 leading-3 font-semibold opacity-70">{data?.weekday}</div>
                <div className="text-3xl py-px font-semibold opacity-90">{data?.date}</div>
                <div className="text-sm leading-3 font-medium opacity-60">{data?.month}</div>
                <div className="text-sm text-center leading-5 pt-1.5 font-bold opacity-80 px-2">{data?.time_string}</div>
            </div>
            {show_badge&&<div className="absolute top-1 right-1 py-0.5 px-1 bg-blue-100 text-xs font-medium rounded-md text-blue-700">
                Best
            </div>}
        </Cell>
        <div className="grid gap-1">
            <EntryResultBox value={value} user_field can_edit={!voted} onChange={handleChange}/>
        </div>
    </div>
}

function EntryName({data}) {
    return <div className="flex gap-2 py-1 h-6 items-center">
        <div className="flex-none">
            <Avatar url={buildImageUrl(data?.profile_picture||DEFAULT_USER_PHOTO)} size="h-5 w-5"/>
        </div>
        <div className="text-sm font-medium text-gray-800">
            {data.name||""}
        </div>
    </div>
}

function EntryRow({index, community_uid, data, children}) {

    return <>
        <div className="col-span-1">
            <div className={`py-1 h-8 ${index === 0 ? "mb-2" : ""}`}>
                <DataWrapper id={data.member_id} type={`community_members.${community_uid}.members`}>
                    <EntryName />
                </DataWrapper>
            </div>
        </div>
        <div className="col-span-3 grid gap-1 grid-cols-3">
            {children}
        </div>
    </>
}

function buildOptions(poll, user_timezone) {
    let a = [];

    poll.choices.forEach(choice_id => {
        const choice_data = poll.choices_data[choice_id];

        const split = choice_data.text.split(" || ");

        const timezone = split[3];

        const timezone_mismatch = timezone !== user_timezone;

        // data is string like "2023-05-30 || 2023-05-30 09:15:00 || 2023-05-30 10:15:00 || America/Los_Angeles"
        const start_date = dayjs.tz(split[1], timezone);
        const end_date = dayjs.tz(split[2],timezone);

        const adjusted_start_date = timezone_mismatch ? start_date.tz(user_timezone) : start_date;

        const adjusted_end_date = timezone_mismatch ? end_date.tz(user_timezone) : end_date;

        a.push({
            choice_id,
            full_start_date: adjusted_start_date.format(DateTimeFormat),
            date: adjusted_start_date.format("D"),
            month: adjusted_start_date.format("MMM"),
            weekday: adjusted_start_date.format("ddd"),
            start_time: adjusted_start_date.format("h:mm"),
            end_time: adjusted_end_date.format("h:mm"),
            time_string: buildEventTimeString(adjusted_start_date, adjusted_end_date, null, true),
        })
    })

    return a;
}

function getDateTimeVotes(choices, vote) {
    let b = {};

    choices.forEach(choice_id => {
        b[choice_id] = !isNaN(vote?.[choice_id]) ? vote[choice_id] : -1;
    })

    return b;
}

function buildOtherResponses(poll, voted, my_member_id) {
    const other_ids = Object.keys(poll.voted).filter(member_id => member_id !== my_member_id);

    let a = [];

    other_ids.forEach(member_id => {
        // getDateTimeVotes(poll.choices, vote)
        let vote = {};
        poll.choices.forEach(choice_id => {
            vote[choice_id] = !isNaN(poll.votes?.[choice_id]?.[member_id]) ? poll.votes?.[choice_id]?.[member_id] : -1;
        })
        // vote should be an object of [choice_id]: response<0/1/2>
        const vote_choices = getDateTimeVotes(poll.choices, vote);
        a.push({
            member_id,
            vote_choices
        })
    })

    return a;
}

function TopLeftCellInfo({
                             user_timezone,
                             setUserTimezone
                         }) {

    return <Cell padding="py-1" top align_top>
        <div className="text-xs text-gray-600 leading-normal">
            Times shown for <InlineSelectTimezone onChange={ntz=>setUserTimezone(ntz)} value={user_timezone} />
        </div>
    </Cell>
}

function TotalsEntry({data}) {
    return  <div className="text-xs gap-1 py-1 grid grid-cols-3 font-semibold">
        <div className="bg-green-100 text-center leading-6 rounded-lg text-green-700">
            {data[1]}
        </div>
        <div className="bg-yellow-100 text-center leading-6 rounded-lg text-yellow-700">
            {data[2]}
        </div>
        <div className="bg-gray-100 text-center leading-6 rounded-lg text-gray-700">
            {data[0]}
        </div>
    </div>
}

// selectChoice must pass a full object of { [choice_id]: 0/1/2 }
// 0 = no, 1 = yes, 2 = if need be
export function EventDatetimeView({
                                      poll, vote, totals, status, voted, show_results, selection, selectChoice
                                  }) {
    const community = useCommunity();
    const [sort, setSort] = useState("date");
    // todo get from member / local defaults
    const [user_timezone, setUserTimezone] = useState(getCurrentTimezone());
    const [selected_index, setSelectedIndex] = useState(0);

    const user_selections = getDateTimeVotes(poll.choices, vote);

    const date_options = buildOptions(poll, user_timezone);

    const sorted_options = date_options.sort((a, b) => {
        if (sort === "popular" && totals.total > 0) {
            return totals.choices[b.choice_id].score - totals.choices[a.choice_id].score;
        }

        return a.full_start_date.localeCompare(b.full_start_date);
    });

    const show_column_count = 3;

    const can_paginate = sorted_options.length > show_column_count;

    const max_select_index = sorted_options.length - show_column_count;

    const other_responses = buildOtherResponses(poll, voted, community.member_id);

    const payload = {
        options_count: sorted_options.length,
        sorted_options: sorted_options,
        show_column_count,
        can_paginate,
        selected_index,
        setSelectedIndex,
        max_select_index
    };

    // todo adjust to more than 4 other responses
    const show_totals_bar = (totals.total > 0 && other_responses.length > 4) || (show_results && status ==="closed");

    const selected_options = sorted_options.slice(selected_index, selected_index + show_column_count);

    const first_option_totals = totals.choices[selected_options[0].choice_id];
    const second_option_totals = totals.choices[selected_options[1].choice_id];
    const third_option_totals = selected_options[2]?totals.choices[selected_options[2].choice_id]:null;

    const show_badges = show_results && status === "closed";
    const top_choice = totals?.ranked?.[0] || "";

  //  console.log("other_responses",other_responses)

    return <div className="select-none">
        <div className={`${grid_cols}`}>
            <div className="col-span-1">
                <div className="flex h-8">

                </div>
                <TopLeftCellInfo user_timezone={user_timezone} setUserTimezone={setUserTimezone}/>
                <div className="pb-2">
                    <div className={`py-1 h-8`}>
                        <div className="flex gap-2 py-1 h-6 items-center">
                            <div className="flex-none">
                                <Avatar url={buildImageUrl(community.member.profile_picture||DEFAULT_USER_PHOTO)} size="h-5 w-5"/>
                            </div>
                            <div className="text-sm font-medium text-gray-800">
                                You
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-3">
                <TopActionsBar {...payload} sort={sort} setSort={setSort}/>

                <div className="grid gap-1 grid-cols-3">
                    {selected_options.map((item, index) => {
                        const value = user_selections[item.choice_id];
                        const show_option_badge = item.choice_id === top_choice && show_badges;
                        return <OptionColumn show_badge={show_option_badge} voted={voted} handleChange={(nv) => {
                            selectChoice(item.choice_id, {
                                value: nv
                            })
                        }} {...payload} value={value} key={item.full_start_date} data={item}/>
                    })}
                </div>
            </div>
        </div>
        <div className={`${grid_cols} grid gap-1 pb-2 overflow-y-auto no-scrollbars max-h-[12rem]`}>
            {show_totals_bar && <>
                <div className="py-1 h-8 flex items-center">
                    <div className="text-xs text-gray-800">Totals</div>
                </div>
                <div className="col-span-3 grid gap-1 grid-cols-3">
                    <TotalsEntry data={first_option_totals} />
                    <TotalsEntry data={second_option_totals} />
                    {third_option_totals&&<TotalsEntry data={third_option_totals} />}
                </div>
            </>}
            {other_responses.map((item, index) => {
                return <EntryRow community_uid={community.uid} data={item} index={(index + 1)} key={index}>
                    {selected_options.map((option, option_index) => {
                        const value = item.vote_choices[option.choice_id];
                        return <EntryResultBox key={option_index} value={value}/>
                    })}
                </EntryRow>
            })}
            {other_responses.length === 0 && <>
                <div className="py-1 h-8 flex items-center">
                    <div className="text-xs italic text-gray-600">No more responses</div>
                </div>
                <div className="col-span-3 grid gap-1 grid-cols-3">
                    <div className="py-1 h-8 bg-gray-50 rounded-xl"/>
                    <div className="py-1 h-8 bg-gray-50 rounded-xl"/>
                    <div className="py-1 h-8 bg-gray-50 rounded-xl"/>
                </div>
            </>}
        </div>
        <div className="border-t flex border-gray-200 pt-2">
            <div className="flex-grow"></div>
            <div className="flex-none">
                <Legend/>
            </div>
        </div>
    </div>
}