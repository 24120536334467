import React from "react";
import {PastAssignees} from "../past-assignees";

export function EntityPastAssigneesTab({getRole,data,id}) {

    return <div>
        <PastAssignees data={data} id={id} getRole={() => {
            getRole(id);
        }}/>
      </div>
}