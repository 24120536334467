import React from 'react';
import {ArrowsUpDownIcon, ChevronDownIcon} from "@heroicons/react/20/solid";
import M3_A_Icon from "./icon";

/*
* This is the Button atom
* It is a simple button component
* It can be used to display a button with different colors and sizes
 */

const _props = {
    children: {
        type: "node",
        required: false,
    },
    intent: {
        type: "string",
        required: false,
        fallback: "default",
        options: [
            "default",
            "primary",
            "success",
            "warning",
            "danger",
            "info",
            "secondary",
            "tertiary",
            "severe"
        ]
    },
    size: {
        type: "string",
        required: false,
        fallback: "base",
        options: [
            "xs",
            "sm",
            "base",
            "lg",
            "xl"
        ]
    },
    onClick: {
        type: "function",
        required: false
    },
    disabled: {
        type: "boolean",
        required: false,
        default: false
    },
    loading: {
        type: "boolean",
        required: false,
        default: false
    },
    fluid: {
        type: "boolean",
        required: false,
        default: false
    },
    variants: {
        type: "string",
        required: false,
        fallback: "default",
        options: [
            "default",
            "minimal",
            "outline",
            "link",
            "ghost"
        ]
    }
};

// schema: {VARIANT}-{INTENT}
const classes_map = {
    "default-default": "text-gray-700 bg-white border border-gray-300 hover:border-gray-400 hover:bg-gray-50 focus:bg-gray-100",
    "default-primary": "text-white bg-secondary-600 hover:bg-secondary-700 focus:bg-secondary-700",
    "default-success": "text-white bg-success-600 hover:bg-success-700 focus:bg-success-700",
    "default-warning": "text-white bg-warning-600 hover:bg-warning-700 focus:bg-warning-700",
    "default-danger": "text-white bg-danger-600 hover:bg-danger-700 focus:bg-danger-700",
    "default-info": "text-white bg-info-600 hover:bg-info-700 focus:bg-info-700",
    "default-secondary": "border-transparent hover:text-gray-700 hover:border-gray-100 focus:border-gray-200 text-gray-600 transparent hover:bg-gray-100 focus:bg-gray-200",
    "default-tertiary": "text-gray-400 hover:text-gray-600",
    "default-severe": "text-white bg-severe-600 hover:bg-severe-700 focus:bg-severe-700",

    "minimal-default": "text-gray-500 bg-transparent hover:bg-gray-100 focus:bg-gray-200",
    "minimal-primary": "text-secondary-600 bg-transparent hover:bg-gray-100 focus:bg-gray-200",

    "outline-default": "text-gray-900 bg-white border border-gray-300 hover:border-gray-400 hover:bg-gray-50 focus:bg-gray-100",

    "link-default": "text-gray-900 bg-white border border-gray-300 hover:border-gray-400 hover:bg-gray-50 focus:bg-gray-100",

    "ghost-default": "text-gray-600 bg-transparent border border-gray-200 hover:border-gray-300 bg-gray-200 focus:bg-gray-300",

    "dark-default": "text-white bg-gray-700/50 hover:bg-gray-800",
    "dark-primary": "text-white bg-gray-700/50 hover:bg-gray-800",

    "light-default": "bg-transparent hover:bg-gray-900/10 active:bg-gray-900/20",

    "inherit-default": "bg-transparent hover:bg-gray-900/10 active:bg-gray-900/20",
    "inherit-primary": "bg-transparent hover:bg-gray-900/10 active:bg-gray-900/20",
};

// schema: {SIZE}-{COMPACT}
const sizing_class_map = {
    "xs-false": "px-1 text-xs h-5",
    "sm-false": "px-2 text-sm h-6",
    "base-false": "px-3 text-sm h-8",
    "lg-false": "px-4 text-base h-9",
    "xl-false": "px-6 text-lg h-10",

    "xs-true": "px-px text-xs h-5",
    "sm-true": "px-px text-sm h-6",
    "base-true": "px-0.5 text-sm h-8",
    "lg-true": "px-1 text-base h-9",
    "xl-true": "px-2 text-lg h-10",
};

const square_width_map = {
    "xs": "w-5",
    "sm": "w-6",
    "base": "w-8",
    "lg": "w-9",
    "xl": "w-10",
};

export default function M3_A_Button({
                                        children,
                                        text,
                                        intent = "default",
                                        size = "base",
                                        disabled,
                                        switcher,
                                        variant = "default",
                                        dropdown,
                                        compact,
                                        square,
                                        loading,
                                        fluid,
                                        sx,
                                        inner_sx,
                                        onClick
                                    }) {
    let classes = " flex items-center text-sm rounded-lg relative font-medium transition-colors",
        style = {}, gap = "";

    if (compact) {
        gap += " gap-px";
    } else {
        gap += " gap-1";
    }

    if(disabled) {
        classes += " cursor-not-allowed opacity-50";
    } else {
        classes += " cursor-pointer";
    }

    const mapped_classes = classes_map[`${variant}-${intent}`];

    if (mapped_classes) {
        classes += ` ${mapped_classes}`;
    } else {
        console.warn(`No classes found for variant: ${variant} and intent: ${intent}`)
        classes += ` ${classes_map["default-default"]}`;
    }

    const size_classes = sizing_class_map[`${size}-${compact}`];

    if (size_classes) {
        classes += ` ${size_classes}`;
    } else {
        console.warn(`No size classes found for size: ${size} and compact: ${compact}`)
        classes += ` ${sizing_class_map[`${size}-false`]}`;
    }

    if(square) {
        classes += ` ${square_width_map[size]} justify-center`;
    }

    if (fluid) {
        classes += " justify-center w-full";
    }

    if (sx) {
        style = {...sx};
    }

    let inner_style = {};

    if (inner_sx) {
        inner_style = {...inner_sx};
    }

    function clickHandler() {
        if (disabled || loading) return;
        if (!onClick) return;
        onClick();
    }

    let loader = null;

    if (loading) {
        loader = <div className={`flex items-center absolute z-10 left-0 right-0 top-0 bottom-0 justify-center h-full`}>
            <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                 fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                        strokeWidth="4"/>
                <path className="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
            </svg>
        </div>
    }

    return (
        <button className={classes} style={style} onClick={clickHandler}>
            <span style={inner_style}
                  className={`${loader ? "opacity-0" : ""} inline-flex whitespace-nowrap items-center ${gap}`}>{children}{text}</span>
            {loader}
            {switcher && <M3_A_Icon>
                <ArrowsUpDownIcon/>
            </M3_A_Icon>}
            {dropdown && <span className="-mr-1 ml-0.5"><M3_A_Icon>
                <ChevronDownIcon/>
            </M3_A_Icon></span>}
        </button>
    );
}