import React from 'react';
import Button from "../../../../m3/_legacy_components/button";

export function CT_PolicyUpdate({community}) {

    return <div className="pt-3">
        <div>
            <Button size="lg" intent="success" text="Review Policy" />
        </div>
    </div>
}