import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

/*
* This is the Markdown atom
 */

const _props = {
    children: {
        type: "string",
        required: true
    },
    skeleton: {
        type: "boolean",
        required: false,
        default: false
    },
    skeleton_lines: {
        type: "number",
        required: false,
        default: 5
    }
};

const skeleton_line_width_options = {
    0: "w-5/6",
    1: "w-5/6",
    2: "w-4/5",
    3: "w-3/4",
    4: "w-1/2"
};

export default function M3_A_Markdown({
                                         children,
                                         skeleton,
    skeleton_lines = 5
                                     }) {
    if(skeleton) {
        // render a skeleton with X (skeleton_lines) lines where the width is random between 50% and 100%.
        // after every 6 lines, add a blank line
        return <div className="flex flex-col gap-2 py-2">
            {Array.from(Array(skeleton_lines).keys()).map(function (i) {
                if(i!== 0 && i % 5 === 0) return <div key={i} className={`w-full`}>
                    &nbsp;
                </div>;
                const width = skeleton_line_width_options[i % 5];
                return <div key={i} className={`${width} bg-gray-200 my-0.5 rounded-md`}>
                    &nbsp;
                </div>
            })}
        </div>;
    }

    return <ReactMarkdown remarkPlugins={[remarkGfm]}>{children}</ReactMarkdown>
}