import React, {useState} from 'react';
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import {DEFAULT_USER_PHOTO, useCommunity} from "../../../../config/community";
import DataWrapper from "../../../../m3/_legacy_components/data-wrapper";
import {Avatar} from "../../../../m3/_legacy_components/avatar";
import {TextAction} from "../../../auth/sign-in";
import {EditPastAssigneeModal} from "./edit";
import {buildImageUrl} from "../../../../../common/utilities/images";

export function formatStartDate(m, y) {
    let mom;
    if (!y) {
        return '';
    } else if (!m) {
        mom = dayjs().year(y);
        return `${mom.format('YYYY')}`;
    } else {
        mom = dayjs().month(m).year(y);
        return `${mom.format('MMM YYYY')}`;
    }
}

export function formatEndDate(m, y, c) {
    if (c) {
        return 'Present';
    }
    if (!y || y === '') {
        return '';
    }
    let mom;
    if (!m) {
        mom = dayjs().year(y);
        return `${mom.format('YYYY')}`;
    } else {
        mom = dayjs().month(m).year(y);
        return `${mom.format('MMM YYYY')}`;
    }

}


export function getSortableMonthYear(m, y) {
    const sm = m > 9 ? `${m}` : `0${m}`;
    return parseInt(`${y}${sm}`);
}

function Item({
                  data,
                  name = "",
                  onEdit,
                  base_link,
                  can_edit = false,
                  show_line = true,
                  formatted_start_date,
                  formatted_end_date,
                  profile_picture = DEFAULT_USER_PHOTO
              }) {

    //  {show_line && <div className="absolute w-0.5 -top-3 left-4 bg-gray-200 h-4"></div>}
    return <div className="flex relative items-center space-x-2">
        <div className="flex-none">
            <div className="border-2 border-white border-transparent h-11 w-11">
                <Avatar size="h-10 w-10" url={buildImageUrl(data?.profile_picture,"_medium",DEFAULT_USER_PHOTO)}/>
            </div>
        </div>
        <div className="flex-grow">
            <div className="-mt-1 pb-0.5">
                {data ? <Link to={`${base_link}${data.handle}`}
                              className="text-gray-800 text-sm font-bold hover-opacity">{data ? data.name : name}</Link> :
                    <div className="text-gray-800 text-sm font-bold">{data ? data.name : name}</div>}
            </div>
            <div className="text-xs space-x-1 text-gray-600">
                {formatted_start_date && <span>{formatted_start_date}</span>}
                {formatted_start_date && <span>-</span>}
                <span>{formatted_end_date}</span>
            </div>
        </div>
        {can_edit && <div className="flex-none">
            <TextAction text="Edit" onClick={onEdit} />
        </div>}

    </div>
}

function List({current, items, startEditing, is_admin, community_uid}) {
    return <div className="space-y-2">
        {current.member_id &&
        <DataWrapper key={`current-it`} community_uid={community_uid} id={current.member_id} type={`community_members.${community_uid}.members`}>
            <Item can_edit={false} show_line={false} {...current} />
        </DataWrapper>}
        {!current.member_id && <Item can_edit={false} show_line={false} {...current} name={"Unassigned"}/>}
        {items.map((it, k) => {
            return <DataWrapper key={it.id} community_uid={community_uid} id={it.member_id} type={`community_members.${community_uid}.members`}>
                <Item onEdit={() => startEditing(it.id, it)} can_edit={false}
                      {...it} />
            </DataWrapper>
        })}
    </div>
}

export function PastAssignees(props) {
    const community = useCommunity();
    const [modal, setModal] = useState("");
    const [modal_data, setModalData] = useState({});

    function handleClose() {
        setModal("");
        setModalData(null);
        props.getRole(props.id);
    }
    function handleStartEditing(id, data) {
    setModalData({id, ...data});
    setModal("edit-past-assignee");
    }

    const past_assignees = props.data.past_assignee_data ? Object.entries(props.data.past_assignee_data) : [];

    const items = past_assignees.map(([id, it]) => {
        return {
            id,
            ...it,
            can_edit: true,
            sort_date: getSortableMonthYear(it.from_month, it.from_year),
            formatted_start_date: formatStartDate(it.from_month, it.from_year),
            profile_picture: community.default_member_profile_picture,
            base_link: `/c/${community.profile.handle}/member/`,
            formatted_end_date: formatEndDate(it.to_month, it.to_year, false)
        }
    }).sort((a, b) => a.sort_date < b.sort_date ? 1 : -1);


    const current = {
        member_id: props.data.assignee,
        formatted_end_date: 'Now',
        base_link: `/c/${community.profile.handle}/member/`,
        formatted_start_date: items.length > 0 && items[0].formatted_end_date
    };

    return <div>
        <List community_uid={community.uid} startEditing={handleStartEditing} is_admin={community.flags.is_admin}
                 items={items}
                 current={current}/>
        <div className="pt-2">
            <TextAction text="Add Past Assignee" onClick={()=>{
                setModal("add-past-assignee");
            }} />
        </div>
        {modal==="add-past-assignee"&&<EditPastAssigneeModal id={props.id} onClose={handleClose} />}
        {modal==="edit-past-assignee"&&<EditPastAssigneeModal item_id={modal_data.id} id={props.id} data={modal_data} onClose={handleClose} />}

    </div>
}