import React, {useContext, useEffect, useState} from 'react';
import {PollView} from "./view";
import {useCommunity} from "../../../config/community";
import {collection, doc, limit, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {InlineLoader} from "../admin-activity";

export function PollLiveWrapper({id}) {
    const community = useCommunity();
    const [data,setData] = useState(null);

    useEffect(function () {
        let sub = () => {
        };
        const docRef = doc(db, "community_content", community.id, "polls", id);

        sub = onSnapshot(docRef, (doc) => {
            if (doc.exists()) {
                setData({...doc.data()});
            } else {
                setData(null);
            }
        });

        return () => {
            sub();
        };
    }, [id])

    if(!data) {
        return <div>
            <InlineLoader padding />
        </div>
    }

    if(!data) {
        return <PollView id={id} loading />
    }

    return <div>
        <PollView id={id} livedata={data} />
    </div>
}