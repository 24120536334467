import React from "react";
import M3_C_Header from "../components/header";
import {m3_icon_map} from "../icons/icon-map";
import M3_E_FilterBarExample from "./filter-bar-example";

const configs = [
    {
        title: "Contacts",
        actions: [
            {
                type: "icon",
                icon: m3_icon_map.outlines.add,
                onClick: () => console.log("Add contact")
            }
        ]
    },
    {
        title: "Leaders",
        actions: [
            {
                type: "icon",
                icon: m3_icon_map.outlines.add,
                onClick: () => console.log("Add position")
            }
        ]
    },
    {
        title: "Good morning",
        actions: [
            {
                type: "icon",
                icon: m3_icon_map.outlines.notifications,
                onClick: () => console.log("open notifications")
            }
        ]
    },
    {
        title: "Calendar",
        subnav_bar: {
          variant: "filter-bar",
            children: <M3_E_FilterBarExample />
        },
        actions: [
            {
                type: "icon",
                icon: m3_icon_map.outlines.calendar,
                onClick: () => console.log("subscribe")
            },
            {
                type: "icon",
                icon: m3_icon_map.outlines.add,
                onClick: () => console.log("Add event")
            }
        ]
    },
    {
        title: "Spaces",
        actions: [
            {
                type: "icon",
                icon: m3_icon_map.outlines.add,
                onClick: () => console.log("Add space")
            }
        ]
    },
    {
        title: "Search",
        subnav_bar: {
            variant: "search",
            onEnter: (query) => console.log("search", query)
        }
    }
];

const configs2 = [
    {
        title: "Everyone",
        divider: true,
        actions: [
            {
                type: "icon",
                icon: m3_icon_map.outlines.add,
            }
        ],
        subnav_bar: {
            variant: "search",
            left_component: <div>Selector</div>,
            onEnter: (query) => console.log("search", query)
        },
        onBack: () => console.log("back")
    },
    {
        title: "Annual Picnic",
        divider: true,
        onBack: () => console.log("back")
    },
    {
        title: "Jamie Smith",
        divider: true,
        onBack: () => console.log("back")
    }
];

function MobileWrapper({children}) {
    return <div className="max-w-sm border-gray-500 border h-40">
        {children}
    </div>
}

export default function M3_E_Headers() {
    return (
        <div className="grid grid-cols-2 gap-6">
            <div className="grid gap-6 p-20">
                {configs.map((config, index) => (
                    <MobileWrapper key={index}>
                        <M3_C_Header size="xl" {...config}/>
                    </MobileWrapper>
                ))}
            </div>
            <div className="grid gap-6 p-20">
                {configs2.map((config, index) => (
                    <MobileWrapper key={index}>
                        <M3_C_Header size="sm" {...config}/>
                    </MobileWrapper>
                ))}
            </div>
        </div>
    );
}