import React, {useState} from 'react';
import {useCommunity} from "../../../config/community";
import HandleSelector from "../../auth/create-account/handle-selector";
import Modal from "../../../m3/_legacy_components/modal";
import SimplePortal from "../../../m3/_legacy_components/simple-portal";
import MetaHeader from "../../../m3/_legacy_components/meta-header";
import Button from "../../../m3/_legacy_components/button";

export function ChangeHandleModal({community_uid="",data,type='member',onConfirm,message,onClose,loading}) {
    const community = useCommunity();
    const [new_handle,setNewHandle] = useState('');
    const [_checking_handle,setCheckingHandle] = useState(false);

    const scope = community_uid === '' ? 'global' : 'community';
    const scope_id = community_uid === '' ? '' : community_uid;

    const body = <div>
        <div className="text-gray-600 text-sm pb-4">
            <span>Current handle:</span> <span className="font-semibold">@{data.handle}</span>
        </div>
        <div>
            <HandleSelector checkingHandle={() => setCheckingHandle(true)}
                            default_text={""} change_mode
                            setHandle={(h) => {
                                setNewHandle(h);
                                setCheckingHandle(false);
                            }} type={type} autoFocus
                            scope={scope} scope_id={scope_id}/>
        </div>
        {message&&<div className="pt-2">
            {message}
        </div>}
    </div>;

    const should_disable = new_handle==='';

    return <SimplePortal>
        <Modal center size="small">
            <div className="py-2 px-4 relative">
                <MetaHeader can_close title="Change Handle" onClose={onClose}/>
                <div className="pt-2 space-y-4">
                    {body}
                </div>
                <div className="flex pt-3 pb-1">
                    <div className="flex-grow"></div>
                    <div>
                        <Button intent={should_disable?'secondary':`success`} loading={loading} onClick={()=>{
                            if(new_handle) {
                                onConfirm(new_handle);
                            }
                        }} text={should_disable?"Check":`Change`} />
                    </div>
                </div>
            </div>
        </Modal>
    </SimplePortal>
}