import React from 'react';
import Button from "../../../../m3/_legacy_components/button";
import {Avatar} from "../../../../m3/_legacy_components/avatar";

export function CT_OptIn({community}) {

    return <div className="pt-3">
        <div>
            <Button size="lg" intent="success" text="Review & Opt-in" />
        </div>
    </div>
}