import React, {useEffect, useState} from "react"
import './styles.css';
import {Avatar} from "../avatar";
import {prefillContentState} from "../../../routes/community/write/body";
import {DEFAULT_USER_PHOTO, useCommunity} from "../../../config/community";
import {api_addNote, api_deleteNote, api_editNote} from "../../../../api/notes";
import {useToasts} from "../../../config/toasts";
import {ViewDraftJsContent} from "../../../features/forum/utilities/view-content";
import {convertFromRaw, convertToRaw} from "draft-js";
import {collection, where, orderBy, getDocs, query, onSnapshot} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {WriteSendIconButton} from "../../../routes/community/message/replies/new-reply";

import DataWrapper from "../data-wrapper";
import HoverActions from "../hover-actions";
import {TrashIcon,PencilIcon} from "@heroicons/react/24/outline";
import {RichTextEditor} from "../../../features/forum/editor/rich-text-editor";
import {buildImageUrl} from "../../../../common/utilities/images";

function NewNoteBody({
                         editor_state, content, onChange = () => {
    }, refresh
                     }) {


    const initial_content = editor_state ? editor_state.getCurrentContent() : prefillContentState(content || null);

    return <RichTextEditor features={{
        hover_toolbar: false,
        block_arrange: false
    }} refresh={refresh} auto_focus={false} show_add_signature={true}
                           show_tools debug={false}
                           upload_files_to_folder={() => {
                           }}
                           handleClose={() => {
                           }} mode={"full-screen"}
                           updateAttachments={() => {
                           }} folder_id={""} fc={0}
                           content_state={initial_content} placeholder={"Add Note"}
                           onChange={(editor_state) => {
                               onChange(editor_state)
                           }}
    />
}

function NoteWrapper({children, image}) {
    return <div className="flex gap-2">
        <div className="w-8 flex-none">
            <Avatar url={buildImageUrl(image, '_medium', DEFAULT_USER_PHOTO)} size="h-8 w-8"/>
        </div>
        <div className="flex-grow">
            {children}
        </div>
    </div>
}

function Note({note, data, fns}) {
    const content_state = note.raw ? convertFromRaw(note.raw) : null;
    const [new_editor_state, setNewEditorState] = useState(null);
    const [editing, setEditing] = useState(false);
    const [state, setState] = useState("");

    if (editing) {

        const cs = new_editor_state ? new_editor_state.getCurrentContent() : {getPlainText: () => ""};

        const can_send = cs.getPlainText();

        return <NoteWrapper image={data.profile_picture}>
            <EditNoteWrapper onClick={()=>{
                const cs = new_editor_state.getCurrentContent();
                fns.editNote(
                    note.id,
                    convertToRaw(cs),
                    cs.getPlainText()
            );
                setEditing(false);
            }} can_send={can_send} state={state}>
            <NewNoteBody content={content_state} onChange={(editor_state) => {
                setNewEditorState(editor_state);
            }}/>
            </EditNoteWrapper>
        </NoteWrapper>
    }

    const actions = [
        {
            label: "Edit",
            onClick: () => {
                setEditing(true);
            },
            icon: <PencilIcon />
        },
        {
            label: "Delete",
            onClick: () => {
                if (window.confirm("Are you sure you want to delete this note?")) {
                    fns.deleteNote(note.id);
                }
            },
            icon: <TrashIcon />
        }
    ];
    return <HoverActions actions={actions}>
        <NoteWrapper image={data.profile_picture}>
            <div className="bg-yellow-200 text-gray-800 px-3 py-1.5 rounded-lg">
                {content_state &&
                    <ViewDraftJsContent style={{}} classes='prose prose-base text-gray-800'
                                        content_state={content_state}/>}
            </div>
        </NoteWrapper>
    </HoverActions>
}

function EditNoteWrapper({children,state,can_send,onClick}) {
    return <div className="bg-yellow-50 border border-gray-300 pl-2.5 pr-5 relative py-1 rounded-md"
                style={{minHeight: '4rem'}}>
        {children}
        <div className="absolute right-1 bottom-1">
            <WriteSendIconButton loading={state === "loading"} disabled={!can_send} onClick={onClick}/>
        </div>
    </div>
}

export function WriteInternalNote({scope_id = "",scope="member"}) {
    const community = useCommunity();
    const toasts = useToasts();
    const [state, setState] = useState("");
    const [editorState, setEditorState] = React.useState(null);
    const [refresh, setRefresh] = useState(0);
    const cs = editorState ? editorState.getCurrentContent() : {getPlainText: () => ""};

    const can_send = cs.getPlainText();

    function addNote() {
        if (!can_send) {
            return;
        }
        setState("loading");
        const pl = {
            raw: convertToRaw(editorState.getCurrentContent()),
            plain_text: cs.getPlainText(),
            scope: scope,
            scope_id: scope_id
        };
        api_addNote(community, pl)
            .then(() => {
                toasts.addToast({text: "Note added", intent: "success"});
                setRefresh(refresh + 1);
                setState("");
            })
    }

    // DRAFT JS
    return <NoteWrapper image={community.member.profile_picture}>
        <EditNoteWrapper onClick={addNote} can_send={can_send} state={state}>
            <NewNoteBody refresh={refresh} onChange={es => setEditorState(es)}/>
        </EditNoteWrapper>
    </NoteWrapper>
}

export default function InternalNotes({scope_id = "", scope = "member"}) {
    const community = useCommunity();
    const toasts = useToasts();
    const [notes, setNotes] = useState([]);

    useEffect(function () {
        if (!scope_id) return;
        const ref = collection(db, 'community_content', community.id, 'notes');
        const q = query(ref, where("scope", "==", scope), where("scope_id", "==", scope_id), orderBy("created_at", "desc"));

        const snapshot = onSnapshot(q, (querySnapshot) => {
            const notes = [];
            querySnapshot.forEach((doc) => {
                notes.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            setNotes(notes);
        })

        return () => {
            snapshot();
        };
    }, [scope_id,scope])

    function deleteNote(id) {
        api_deleteNote(community, {id})
            .then(() => {
                setNotes(notes.filter(n => n.id !== id));
                toasts.addToast({text: "Note deleted", intent: "success"});
            })
    }

    function editNote(id, raw, plain_text) {
        api_editNote(community, {id, raw, plain_text})
            .then(() => {
                setNotes(notes.map(n => n.id === id ? {...n, raw, plain_text} : n));
                toasts.addToast({text: "Note edited", intent: "success"});
            })
    }

    const fns = {
        deleteNote,
        editNote
    };

    return <div>
        <WriteInternalNote scope={scope} scope_id={scope_id}/>
        <div className="space-y-4 pt-4">
            {notes.map((n, i) => {
                return <div>
                    <DataWrapper id={n.created_by} type={`community_members.${community.id}.members`}>
                        <Note fns={fns} key={i} note={n} data={community.member}/>
                    </DataWrapper>
                </div>
            })}
        </div>
    </div>
};