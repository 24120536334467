export const custom_field_types = {
    'text': {
        label: "Text",
    },
    'textarea': {
        label: "Multiline Text",
    },
    'url': {
        label: "Website Link",
    },
    'email': {
        label: "Email",
    },
    'phone': {
        label: "Phone",
    },
    'checkbox': {
        label: "Checkbox",
    },
    'date': {
        label: "Date",
    },
    'number': {
        label: "Number",
    },
    'select': {
        label: "Select",
    },
    'multi-select': {
        label: "Multi-select",
    },
    'member': {
        label: "Member",
    },
    'mentor': {
        label: "Mentor",
    },
    'mentees': {
        label: "Mentees",
    },
    'address': {
        label: "Address",
    },
    'group': {
        label: "Group",
    },
    'country': {
        label: "Country",
    },
    'select-taxonomy': {
        label: "Select Taxonomy"
    },
    'multi-select-taxonomy': {
        label: "Multi-select Taxonomy"
    }
};