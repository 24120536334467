import {data_sexes_arr} from "./data/sex";
import {data_continents_arr} from "./data/continents";
import {data_countries_arr} from "./data/countries";
import {data_months_arr} from "./data/months";
import {data_membership_status_arr} from "./data/membership-status";
import {data_data_integrity_arr} from "./data/data-integrity";
import {data_languages_arr} from "./data/languages";

export const data_autocomplete_map = {
    "sex": {
        type: "select",
        data: data_sexes_arr
    },
    "continent": {
        type: "select",
        data: data_continents_arr
    },
    "country": {
        type: "select",
        data: data_countries_arr
    },
    "month": {
        type: "select",
        data: data_months_arr
    },
    "language": {
        type: "select",
        data: data_languages_arr
    },
    "membership-status": {
        type: "select",
        data: data_membership_status_arr
    },
    "member-types": {
        type: "dynamic-select",
    },
    "data-integrity": {
        type: "select",
        data: data_data_integrity_arr
    }
};