import React from 'react';
import {SharedLinkPage} from "./index";
import {collection, getDocs, limit, query, where} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import ReactDOM from "react-dom";
import {InlineLoader} from "../../../m3/_legacy_components/admin-activity";
import {FlowHandler} from "../../../features/gates/flow/handler";
import {useUnaverse} from "../../../config/unaverse";
import {useNavigate} from "react-router-dom";

function buildManifest() {
    return {
        name: "Welcome to Orange",
        type: "onboarding",

        branding: {
            accent_color: "#f15f31",
        },

        triggers: {
            type: "welcome-email" // role change
        },

        context: "community",
        context_id: "",

        features: {
            skippable: false,
            metrics: false
        },

        state: {

        },

        intro: {
            title: "👋 Welcome to Orange!",
            layout: "standard",
            description: "First things first, let's get your account setup.",

            blocks: [
                {
                    type: "join_community"
                }
            ],

            complete_rules: [
                {
                    type: 'state-exists',
                    field: 'account_complete'
                }
            ],

            actions: {
                primary: "Continue",
                primary_action: {
                    type: "redirect",
                    to: 'home?ref=flow&refid=joincommunity'
                },
            },

            features: {
                action_hidden_until_valid: true,
                skippable: false,
                can_press_enter: false
            }
        },

        steps: []
    }
}

export async function getCommunityFromDomain(community_domain) {
    const col = collection(db, 'communities');

    let q = query(col, where('handle','==',community_domain), limit(1));

    const snap = await getDocs(q);

    if (snap.empty) {
        return null;
    } else {
        return {
            id: snap.docs[0].id,
            ...snap.docs[0].data()
        }
    }
}

export function CommunityLinkPage() {
    const unaverse = useUnaverse();
    const navigate = useNavigate();
    const [community, setCommunity] = React.useState(null);

    React.useEffect(() => {
        if(!unaverse.user) {
            navigate(`/c/${window.location.pathname.split('/')[2]}/sign-in`);
            return;
        }
    }, [unaverse.user])

    React.useEffect(() => {
        const community_domain = window.location.pathname.split('/')[2];
        getCommunityFromDomain(community_domain).then((comm) => {
            setCommunity(comm);
        })
    }, [])


    function handleStart() {

    }

    function handleComplete() {

    }

    const is_ready = !!community;

    const manifest = buildManifest();

    let content;

    if(!is_ready) {
        content = <div className="fixed top-0 left-0 bg-white right-0 z-50 bottom-0 h-screen">
            <div className="flex flex-col items-center justify-center h-full">
                <InlineLoader />
            </div>
        </div>
    } else {
        const context = {
            uid: community.uid,
            domain: community.handle,
            name: community.name,
            member_id: "",
            community_uid: community.uid,
            important_custom_fields: [],
            member: {
                about: {
                    first_name: "",
                    last_name: "",
                },
                profile_picture: ""
            },
            custom_fields: []
        }
        content = <div className="fixed top-0 left-0 bg-white right-0 z-50 bottom-0 h-screen">
            <FlowHandler manifest={manifest} onStart={handleStart} onComplete={handleComplete} context={context} />
        </div>;
    }

    return ReactDOM.createPortal(
        content,
        document.body
    );
}