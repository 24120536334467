import React, {useEffect, useState} from "react"

import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {
    handleResendResetPasswordLink,
    handleResetPassword, handleSentId,
    isEmailSignInLink,
    verifyPasswordOobCode,
    verifyUserAccount
} from "./api";
import {authFetch, externalAuthFetch} from "../../../../api/network";
import {authApp, emailSignIn} from "../../../config/auth";
import {collection, doc, getDoc} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {InlineLoader} from "../../../m3/_legacy_components/admin-activity";
import Button from "../../../m3/_legacy_components/button";
import {checkPasswordStrength, PasswordField} from "./password";
import Field from "../../../m3/_legacy_components/form/field";
import {SharedPageTitle} from "../../unaverse/memberships";
import ExternalAuthPage from "../../../m3/_legacy_components/external-auth-page";
import {UnaverseCategoryBackground} from "../create-account";
import {useUnaverse} from "../../../config/unaverse";

function AuthTitle({children}) {
    return <SharedPageTitle>
        {children}
    </SharedPageTitle>
}

function ErrorMsg({error_code, send_pending, resending_link, backToSignIn, email, resendResetPasswordLink}) {
    switch (error_code) {
        case 'auth/invalid-action-code':
            return <div>
                <AuthTitle>Link has already been used</AuthTitle>
                <div className="prose prose-sm text-gray-700 py-4">
                    To make sure your account is safe, we sent you a new link to {email} to reset your password.
                </div>
                <Button intent='primary' loading={send_pending} fill size='large'
                        onClick={resendResetPasswordLink} text='Get new Link'/>
            </div>
        case 'auth/expired-action-code':
            return <div>
                <AuthTitle>Link has expired</AuthTitle>
                <div className="prose prose-sm text-gray-700 py-4">
                    To make sure your account is safe, we sent you a new link to {email} to reset your password.
                </div>
                <Button intent='primary' loading={send_pending} fill size='large'
                        onClick={resendResetPasswordLink} text='Get new Link'/>
            </div>;
        case 'auth/user-disabled':
            return <div>
                <AuthTitle>Something went wrong</AuthTitle>
                <div className="prose prose-sm text-gray-700 pt-4">
                    Try to <Link className='color-link' to='/sign-in'>sign in</Link> again or contact us
                    at <a className='color-link' href='mailto:hey@getunaty.com'>hey@getunaty.com</a> if you
                    need any help!
                </div>
            </div>;
        case 'auth/user-not-found':
            return <div>
                <AuthTitle>Something went wrong</AuthTitle>
                <div className="prose prose-sm text-gray-700 pt-4">
                    Try to <Link className='color-link' to='/sign-in'>sign in</Link> again or contact us
                    at <a className='color-link' href='mailto:hey@getunaty.com'>hey@getunaty.com</a> if you
                    need any help!
                </div>
            </div>;
        default:
            return <div>
                <AuthTitle>Invalid Link</AuthTitle>
                <Button intent='primary' fill size='large' onClick={() => {
                    backToSignIn();
                }} text='Back to Sign In'/>
            </div>;
    }
}

export function handleUpdateBasicInfo(payload) {
    const res = () => {
    };
    authFetch("/update-basic-user-info", res, res, "POST", {payload});
}

export function SharedLinkPage({join_community = false, joinCommunityCallback, cname, passUserData, profile}) {
    const unaverse = useUnaverse();
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [phase, setPhase] = useState("");
    const [error, setError] = useState("");
    const [password, setPassword] = useState("");
    const [mode, setMode] = useState(searchParams.get('mode'));
    const [oobCode, setOobcode] = useState(searchParams.get('oobCode'));
    const [email, setEmail] = useState(searchParams.get('_email'));
    const [sent_id, setSentId] = useState(searchParams.get('sent_id'));
    const [template, setTemplate] = useState(searchParams.get('_template'));
    const [flags, setFlags] = useState({reset_link_sent: false, invalid: false})
    const [extras, setExtras] = useState({})
    const [continueTo, setContinueTo] = useState(searchParams.get('_continueTo'));
    const [cdata, setCommunityData] = useState(null);
    const [udata, setUserData] = useState(null);
    const [community, setCommunity] = useState(searchParams.get('_community') || "");

    useEffect(function () {
        const ghost_mode = searchParams.get('__ghost');

        if(ghost_mode) {
            unaverse.setGhostMode(true);
            console.log("SET GHOST MODE");
        }
    }, [])

    useEffect(function () {
        if (udata && passUserData) {
            passUserData(udata);
        }
    }, [udata])

    useEffect(function () {
        if (!phase) {
            if (community) {
                getCommunity()
                    .then(cd => {
                        setCommunityData(cd);
                        handleStart();
                    })
            } else {
                handleStart();
            }

            if (email) {
                getUser()
                    .then(us => {
                        setUserData(us);
                    })
            }
        }
    }, [oobCode, flags, phase, community, email, mode])

    async function getUser() {
        return new Promise(resolve => {
            const res = (resp) => {
                if (resp && resp.data && resp.data.user) {
                    resolve(resp.data.user);
                }
            };

            const payload = {
                email
            };

            externalAuthFetch("/get-user-info", res, res, "POST", {payload});
        })
    }

    async function getCommunity() {
        const ref = doc(db, 'communities', community);
        return await getDoc(ref)
            .then(doc => {
                return doc.exists() ? doc.data() : null;
            });
    }

    function handleStart() {
        if (!phase && oobCode && mode === "resetPassword") {
            setPhase('verify-code')
            verifyPasswordOobCode(authApp, oobCode)
                .then(resp => {
                    console.log("verifyPasswordOobCode", resp)
                    if (resp.ok === 'yes') {
                        setEmail(resp.email);
                        setPhase("email-loaded")
                    } else {
                        setError(resp.error.code);
                        setFlags({...flags, invalid: true})
                    }
                })
        }

        if (!phase && oobCode && email && mode === "signIn") {
            setPhase('verify-code')
            isEmailSignInLink(authApp, email)
                .then((result) => {
                    window.localStorage.removeItem('emailForSignIn');
                    // You can access the new user via result.user
                    // Additional user info profile not available via:
                    // result.additionalUserInfo.profile == null
                    // You can check if the user is new or existing:
                    // result.additionalUserInfo.isNewUser

                    navigate('/home');
                })
        }
        if (!phase && oobCode && mode === "verifyEmail") {
            setPhase('verify-email')
            console.log('verify code')
            verifyUserAccount(authApp, oobCode)
                .then((result) => {
                    console.log('verifyUserAccount', result)
                    if (result.ok === 'yes') {
                        setPhase("email-verified");
                        console.log('OK')
                        externalAuthFetch('/confirm-verify-email', () => {
                        }, () => {
                        }, 'POST', {email});

                        setTimeout(() => {
                            navigate(`/sign-in?email=${email}`);
                        }, 3000);

                    } else {
                        setError('verification-error')
                    }
                })
        }
    }

    function backToSignIn() {
        navigate('/sign-in')
    }

    function resetPassword() {
        setPhase("loading");

        handleResetPassword(authApp, oobCode, password)
            .then((resp) => {
                if (resp.ok === 'yes') {
                    setPhase("password-updated");
                    const payload = {
                        profile_picture: extras.user_profile_picture || "",
                        profile_picture_color: extras.user_profile_picture_color || "",
                        name: extras.user_name || "",
                        email: email
                    };

                    externalAuthFetch('/confirm-password-reset', () => {
                    }, () => {
                    }, 'POST', {email: email, payload});

                    emailSignIn(email, password)
                        .then(() => {
                            if (continueTo) {
                                if (sent_id && community) {
                                    handleSentId(sent_id, community);
                                }
                                if (payload && payload.name) {
                                    handleUpdateBasicInfo(payload)
                                }
                                if (join_community) {
                                    joinCommunityCallback();
                                } else {
                                    setTimeout(() => {
                                        navigate(`/${continueTo}`)
                                    }, 2000);
                                }


                            } else {
                                if (join_community) {
                                    joinCommunityCallback();
                                } else {
                                    setTimeout(() => {
                                        navigate(`/`)
                                    }, 2000);
                                }
                            }
                        })
                } else {

                }
            })
    }

    function resendResetPasswordLink() {
        setFlags({
            ...flags,
            send_pending: true
        })
        handleResendResetPasswordLink(email)
            .then(resp => {
                setFlags({
                    ...flags,
                    send_pending: false,
                    ...resp
                })
            })
    }

    const loading = !phase;

    let content;

    let show_choose = (template && template === 'community-set-password');

    const show_email = (template && template === 'community-set-password') || mode === 'resetPassword';
console.log("FLAGS",flags,error)
    if (!mode) {
        content = <div>
            No mode
        </div>
    } else if (!oobCode || flags.invalid) {
        if (flags.reset_link_sent) {
            content = <div>
                <AuthTitle>Reset Password Email sent</AuthTitle>
                <div className="prose prose-sm text-gray-700 pt-4">
                    If you don’t see an email from us within a few minutes, a few things might have happened:
                    <br/><br/>
                    - The email landing in your spam folder.<br/>
                    - The email address you entered had a mistake or typo. (Happens to everyone!)<br/>
                    - You accidentally gave us another email address. (Maybe a work or personal email?)<br/>
                    - We can’t deliver the email to this address. (Likely corporate firewalls or filtering.)
                </div>
            </div>
        } else {
            if(error) {
                content = <div>
                    <ErrorMsg {...{
                        send_pending: flags.send_pending,
                        error_code: error,
                        resending_link: flags.resending_link,
                        backToSignIn,
                        email,
                        resendResetPasswordLink
                    }} />
                </div>
            } else {
                // loading state from magic sign in
                content = <div>
                    <InlineLoader padding />
                </div>
            }
        }

    } else if (loading) {
        content = <div>
            <InlineLoader/>
        </div>
    } else if (mode === 'resetPassword') {
        let result = checkPasswordStrength(password);
        if (phase === "password-updated") {
            content = <div className="space-y-4">
                <AuthTitle>Password successfully reset</AuthTitle>
                <div className="prose prose-sm text-gray-700 pt-4">
                    You'll be redirected shortly to the home page.
                </div>
            </div>
        } else {
            content = <div className="space-y-4">
                {!join_community && <AuthTitle>Choose a new password</AuthTitle>}

                {join_community && profile && <div>
                    {profile}
                </div>}
                {!join_community && <Field value={email} input_props={{disabled: true}} label="Email"/>}
                <PasswordField label="Set your Password" placeholder="New Password" autoFocus onChange={(a, v) => {
                    setPassword(v);
                }} score={result.score}
                               value={password}/>
                <Button intent='primary' fill size='large' disabled={result < 2}
                        onClick={resetPassword}
                        text={show_choose ? (join_community ? `Continue to ${cname} Onboarding` : 'Set Password & Login') : 'Set new Password'}
                        loading={phase === "loading"}/>

                {join_community && <div className="pt-2 text-gray-600 text-xs leading-5">
                    You're creating an account for <span className="font-medium">{email}</span>.<br/>
                    If you want to use a different email, you can <span className="underline cursor-pointer">change your account email</span>.
                    <br/>
                    <br/>
                    If you already have an account, you can <Link className="underline"
                                                                  to={`/c/${window.location.pathname.split('/')[2]}/sign-in?ref=join-community`}>sign
                    in with another email</Link>.
                </div>}
            </div>
        }

    } else if (phase === "verify-email") {
        content = <div className="space-y-4">
            <AuthTitle>Verifying your email...</AuthTitle>
            <div className="p-4">
                <InlineLoader/>
            </div>
        </div>
    } else if (phase === "email-verified") {
        content = <div className="space-y-4">
            <AuthTitle>Email successfully verified!</AuthTitle>
            <div className="prose prose-sm text-gray-700 pt-4">
                You'll be redirected shortly to the home page.
            </div>

        </div>;
    } else if (error) {
        content = <div className="space-y-4">
            <AuthTitle>Something went wrong.</AuthTitle>
            <div className="prose prose-sm text-gray-700 pt-4">
                We're sorry about that, could you reach out at hey@getunaty.com?
            </div>
        </div>;
    } else {
        console.log("ELSE",{
            cdata,
            udata,
            phase,
            error,
            mode,
            oobCode,
            email,
            continueTo,
            community
        })
        content = <div>
            <InlineLoader padding />
        </div>
    }
    return content;
}

export default function GeneralLinkPage() {
    return <ExternalAuthPage show_logo background={<UnaverseCategoryBackground/>}>
        <div className="w-full space-y-6 max-w-sm">
            <SharedLinkPage/>
        </div>
    </ExternalAuthPage>
}