import React, {useEffect, useState} from "react";

export function FrameLoader({progress = 0.67,inline,onReset=()=>{}}) {
    const [p,setP] = useState(progress);

    useEffect(function () {
        setP(progress);
    }, [progress]);

    useEffect(function () {
        if(p>=1) {
            setTimeout(function() {
                onReset();
            }, 350)
        }
    }, [p])

    const bg = inline ? "bg-white" : "bg-gray-200";
    const h = inline ? "h-0.5" : "h-1";

    return <div style={{zIndex: '110'}} className={`absolute top-0 left-0 right-0 ${h} ${bg}`}>
        <div className={`${h} rounded-md`} style={{
            width: `${Math.floor(p * 100)}%`,
            backgroundColor: '#12A6FF',
            transition: 'width',
            transitionTimingFunction: "cubicBezier(0.4, 0, 0.2, 1)",
            transitionDuration: "0.4s"
        }} />
    </div>
}