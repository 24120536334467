import React from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "../../../config/auth";
import {useUnaverse} from "../../../config/unaverse";
import {CommunityContextProvider, useCommunity} from "../../../config/community";
import {useIsMobile} from "../../hooks/is-mobile";
import {useEffect, useRef} from "react";
import {setFavicon} from "../../utilities/set-document-title";
import {getEmojiData} from "../emoji-picker";
import {FullScreenLoader} from "../../../routes/community/onboarding";
import EditModal from "../edit-modal";
import WritePage from "../../../routes/community/write";
import {MobileMenu} from "./menus";
import {buildCommunityNavConfig} from "./community-nav-config";
import PreviewOverlay from "../preview-overlay";
import AppLayout from "../../../m3/layouts/layout";
import SingleImageViewer from "../simple-image-viewer";
import {FrameLoader} from "./loader";
import {MemberPreferencesModal} from "../../../routes/community/preferences/member-preferences";
import DebugConsole from "../debug";
import {LightboxWrapper} from "../lightbox";
import {buildUserCommunities} from "./utilities";
import {GateEnforcer} from "../../../features/gates/enforcer";
import M3_A_Link from "../../../m3/atoms/link";
import {getItemFromLocalStorage, saveItemToLocalStorage} from "../../../m3/utilities/localstorage";
import EmptyState from "../empty-state";
import M3_A_Button from "../../atoms/button";

const hide_frame = [
    "/sign-in",
    "/sign-out",
    '/create-community',
    "/create-account",
    "/forgot-password",
];

function shouldHideFrame(path) {
    return hide_frame.includes(path) || path.endsWith('/sign-in');
}

const loading_status = ["loading-community", "loading-user", "loading-member", "loading-memberships"];

const navigation_exclusions = ['you', 'home'];

function getMemberAccessBlocked(community) {
    let blocked = false, code = "";

    if (community?.profile?.preferences?.build_mode && !community?.is_admin && community?.is_member) {
        blocked = true;
        code = "build-mode";
    }

    return {blocked, code};
}

function useEmojiData() {
    const gotData = useRef(false);

    useEffect(() => {
        setTimeout(() => {
            getData()
        }, 2500);
    }, []);

    function getData() {
        if (gotData.current) {
            return;
        }
        console.log('getting emoji data')
        getEmojiData(true)
            .then(() => {
                gotData.current = true;
            });
    }

    return !!gotData;
}

export function CommunityFrame({children, debug}) {
    const location = useLocation();
    const {user, is_logged_in} = useAuth();
    const unaverse = useUnaverse();
    const community = useCommunity();
    const navigate = useNavigate();
    const is_mobile = useIsMobile();
    const profile_path = `/c/${community.profile.handle}/member/${community?.member?.handle}`;
    const loadEmojiData = useEmojiData();

    const show_loading = loading_status.includes(community.loading_status);

    const has_redirected = useRef(false);

    const is_localhost = window.location.hostname === "localhost";
    const is_access_blocked = getMemberAccessBlocked(community);

    if (is_localhost) {
        console.log('CONTEXT', {
            community_uid: community.uid,
            is_access_blocked,
            is_logged_in,
            loading_status,
            is_admin: community?.is_admin,
            is_member: community?.is_member,
        })
    }

    // todo convert to hook
    useEffect(() => {
        // remember last placed

        if (is_mobile) {
            return;
        }

        const url_params = new URLSearchParams(location.search);

        // if has preview key, return
        if (url_params.get('preview')) {
            return;
        }

        if (location && community.id && !has_redirected.current) {
            const last_page = getItemFromLocalStorage(`last_page-${community.id}`, "");
            if (last_page && last_page !== `${location.pathname}${location.search}` && location.pathname.split("/")[3] === `home`) {
                const path_split = last_page.split(`${location.pathname.split("/")[2]}/`)[1];
                if (navigation_exclusions.includes(path_split)) {
                    return;
                }
                navigate(last_page, {
                    replace: true
                });
            }
            has_redirected.current = true;
        }

        return () => {
            // save to local storage
            saveItemToLocalStorage(`last_page-${community.id}`, `${location.pathname}${location.search}`);
        }
    }, [community.id, is_mobile, location]);

    useEffect(function () {
        setFavicon((community.profile.profile_picture ? `${community.profile.profile_picture}_small?alt=media` : ''))

        return () => {
            setFavicon('');
        }
    }, [community.profile.profile_picture])

    useEffect(function () {
        if (community.loading_status === "no-user") {
            navigate(`/c/${community.domain}/sign-in?ref=not-signed-in&redirectTo=${encodeURIComponent(location.pathname)}${encodeURIComponent(location.search)}`);
        } else if (community.loading_status === "not-a-member") {
            navigate(`/c/${community.domain}/sign-in?ref=not-a-member`);
        } else if (community.loading_status === "community-not-found") {
            navigate(`/c/${community.domain}/not-found`);
        }
    }, [community.loading_status]);

    if (show_loading) {
        return <FullScreenLoader/>
    } else if (is_access_blocked.blocked) {
        return <div className="h-screen flex justify-center items-center">
            <EmptyState emoji="👋"
                        title={`${community.profile.name} is currently in build mode`}
                        subtitle={<>
                            Only admins can currently access the community, please try again later.
                        </>}
                        button={<M3_A_Button text="Back to Memberships" intent="primary" onClick={() => {
                            navigate(`/`);
                        }}/>}
            />
        </div>
    }

    const community_content = <>
        {community.edit_modal && <EditModal open={!!community.edit_modal}
                                            {...community.edit_modal} onClose={() => {
            if (community.edit_modal.closeCb) {
                community.edit_modal.closeCb();
            }
            community.closeEditModal()
        }}/>}
        {community.view_modal && <EditModal open={!!community.view_modal}
                                            {...community.view_modal} onClose={() => {
            if (community.view_modal.closeCb) {
                community.view_modal.closeCb();
            }
            community.closeViewModal()
        }}/>}
        {community.composer_prefill && <WritePage onClose={(dt) => {
            if (community.composer_prefill.closeCb) {
                community.composer_prefill.closeCb(dt);
            }
            community.closeComposer();
        }} {...community.composer_prefill} />}
    </>;

    const lightbox = community.lightbox &&
        <LightboxWrapper onClose={() => {
            if (community.lightbox.closeCb) {
                community.lightbox.closeCb();
            }
            community.setLightbox(null)
        }} {...community.lightbox} />;

    // need to block non-admins from accessing the community if build mode is activated
    // community?.profile?.preferences?.build_mode
    const is_ready = !show_loading || (location.pathname.endsWith('/sign-in'));
    if (is_mobile) {
        return <>
            {is_ready&&<>
                {children}
                <Outlet/>
                {community_content}
            </>}
            {is_logged_in && community.show_mobile_menu && !shouldHideFrame(location.pathname) &&
                <MobileMenu unread={community.unread} profile_path={profile_path} community={community}
                            is_logged_in={is_logged_in} color={"#000"}/>}
            {community.uid && <GateEnforcer scope_id={community.uid} scope={'community'}/>}
            {lightbox}
        </>
    }

    let nav_config = buildCommunityNavConfig(community, community?.member?.handle || "", community.sidebar_sections);
    const communities = buildUserCommunities(Object.keys(unaverse?.memberships?.communities || {}), unaverse.communitiesData);
    //     {community.image && <SingleImageViewer onClose={() => community.closeImage()} image={community.image}/>}
    const right_sidebar = community.preview_overlay ?
        <PreviewOverlay onClose={() => community.closePreviewOverlay()} {...community.preview_overlay} /> : null;

    const has_right_sidebar = !!community.preview_overlay;

    let mc = is_ready && <>
        {children}
        <Outlet/>
        {community.edit_modal && <EditModal open={!!community.edit_modal}
                                            {...community.edit_modal} onClose={() => {
            if (community.edit_modal.closeCb) {
                community.edit_modal.closeCb();
            }
            community.closeEditModal()
        }}/>}
        {community.view_modal && <EditModal open={!!community.view_modal}
                                            {...community.view_modal} onClose={() => {
            if (community.view_modal.closeCb) {
                community.view_modal.closeCb();
            }
            community.closeViewModal()
        }}/>}
        {community.composer_prefill && <WritePage onClose={(dt) => {
            if (community.composer_prefill.closeCb) {
                community.composer_prefill.closeCb(dt);
            }
            community.closeComposer();
        }} {...community.composer_prefill} />}
    </>;

    let footer_message;

    if (community?.profile?.preferences?.build_mode) {
        footer_message = {
            children: <div>
                Build mode is activated, only admins can currently access the community. <M3_A_Link onClick={() => {
                community.goToAdminApp(`build-mode`)
            }}>Manage</M3_A_Link>
            </div>
        };
    }

    if (!community.member || !is_logged_in || community.loading_status === "not-a-member" || community.loading_status === "no-user") {
        return <div key="main-content">
            {mc}
        </div>;
    }

    return <AppLayout hideSidebar={unaverse.hideSidebar} showSwitcher={unaverse.showSwitcher}
                       raw_community={community} footer_message={footer_message}
                       defaults_key={"global-defaults"} has_right_sidebar={has_right_sidebar}
                       right_sidebar={right_sidebar} communities={communities} init_config={nav_config}>
        {mc}
        <div id="hidden-drop" className="hidden"/>

        {community.image && <SingleImageViewer onClose={() => community.closeImage()} image={community.image}/>}
        {community.loader > 0 && show_loading &&
            <FrameLoader onReset={() => community.setLoader(0)} progress={community.loader}/>}
        {community.settings && <MemberPreferencesModal/>}
        {debug && <DebugConsole/>}
        {lightbox}
        {community.uid && <GateEnforcer scope_id={community.uid} scope={'community'}/>}
    </AppLayout>
}

export function CommunityWrapper({children, debug}) {
    return <CommunityContextProvider>
        <CommunityFrame debug={debug}>
            {children}
        </CommunityFrame>
    </CommunityContextProvider>
}