import React, {useContext, useState} from 'react';
import {
    CheckCircleIcon,
    CheckIcon,
    ClockIcon, ExclamationCircleIcon, MinusCircleIcon,
    MinusIcon,
    QuestionMarkCircleIcon,
    XMarkIcon
} from "@heroicons/react/20/solid";
import {useUnaverse} from "../../../config/unaverse";
import Button from "../button";
import {validateEmail} from "../../../../common/utilities/lang-region";
import {authFetch} from "../../../../api/network";
import {useToasts} from "../../../config/toasts";
import Field from "../form/field";
import Modal from "../modal";
import SimpleTooltip from "../tooltip";
import {isAnonymousUnatyEmail} from "../../../m3/utilities/email";
import {TextAction} from "../../../routes/auth/sign-in";
import {AdMeActionChangeAccountEmail} from "./change-account-email";
import {DoubleCheckModal} from "../double-check-modal";
import SimplePortal from "../simple-portal";
import {ModalBody, ModalContentBlock, ModalFooter, ModalHeader} from "../preview-overlay";
import {VerifyAccountPin} from "../../../routes/auth/verify-pin";
import {utils_strings_isEmail} from "../../../../common/utilities/strings";
import {adminAddSecondaryEmail} from "../../../routes/manage/pages/user-profile";

const status_info_map = {
    'unverified': {
        message: "Unverified",
        icon: <QuestionMarkCircleIcon/>,
        color: "text-gray-500"
    },
    'verified': {
        message: "Verified",
        icon: <CheckCircleIcon/>,
        color: "text-success"
    },
    'pending': {
        message: "Pending",
        icon: <ClockIcon/>,
        color: "text-gray-500"
    },
    'undeliverable': {

        message: "Undeliverable",
        icon: <ExclamationCircleIcon/>,
        color: "text-danger"
    },
    'not-found': {
        message: "Pending",
        icon: <QuestionMarkCircleIcon/>,
        color: "text-amber-500"
    }
};

const callout_styles = {
    'danger': {
        pill: 'bg-red-500',
        text: 'text-gray-800'
    },
    'warning': {
        pill: 'bg-amber-500',
        text: 'text-gray-800'
    },
    'primary': {
        pill: 'bg-primary',
        text: 'text-gray-800'
    },
    'secondary': {
        pill: 'bg-blue-500',
        text: 'text-gray-800'
    },
};

export function SimpleCallout({message, action, intent}) {

    const {
        pill, text
    } = intent ? callout_styles[intent] : callout_styles['secondary'];

    return <div className="flex space-x-2 max-w-xl">
        <div className={`w-1.5 flex-none rounded-xl ${pill}`}/>
        <div className={`py-px text-sm ${text} font-medium`}>
            {message}
            {action && <div className="pt-2">
                {action}
            </div>}
        </div>
    </div>
}

export async function sendEmailVerificationCode(user_uid,email,use_code) {
    const payload = {
        use_code: !!use_code,
        email,
        user_uid
    };

    return new Promise((resolve, reject) => {
        const res = (resp) => {
            resolve(resp)
        };

        authFetch("/users/send-secondary-verification-email", res, res, "POST", {payload});
    })
}

export async function addSecondaryEmailAddress(user_uid,email) {
    const payload = {
        _user_uid: user_uid,
        email
    };

    return new Promise((resolve, reject) => {
        const res = (resp) => {
            resolve(resp)
        };

        authFetch("/members/add-secondary-email", res, res, "POST", {payload});
    })
}

export default function AddSecondaryEmail(props) {
    const unaverse = useUnaverse();
    const toasts = useToasts();
    const [state, setState] = useState('');
    const [email, setEmail] = useState('');

    const {
        onClose = () => {
        },
        community_uid = '',
        use_code = false
    } = props;

    function handlePinSuccess() {
        console.log("pin success");
        addSecondaryEmailAddress(props.user_uid,email)
            .then((r) => {
                console.log("add secondary email",r);
                toasts.addToast({
                    text: "Email Added",
                    intent: "success"
                });
                onClose();
            })
    }

    let body, footer_right;

    if (state !== 'sent') {
        const loading = state === 'sending' || state === 'checking';
        footer_right = <div className="flex gap-2">
            {unaverse.is_superadmin&&<Button text={"Add Email (Superadmin)"} loading={loading} disabled={!utils_strings_isEmail(email)} onClick={()=>{
                checkIfEmailAvailable(() => {
                    superadminAddSecondaryEmail(email)
                })
            }} />}
            <Button text={use_code?"Get verification code":`Send verification email`} intent="primary" disabled={!utils_strings_isEmail(email)}
                    onClick={() => checkIfEmailAvailable(()=>{
                        sendEmail(email)
                    })} loading={loading}/>
        </div>;
        body = <div>
            <Field disabled={loading} autoFocus={true} type={"email"} label="New Email" onChange={(a,v) => {
                setEmail(v);
                setState('');
            }} value={email||""}/>
            {state === 'is-member' && <div className="pt-4">
                <SimpleCallout intent="danger"
                               message="This email is already taken by another member in this community - contact your community admins for help."/>
            </div>}
            {state === 'in-use' && <div className="pt-4">
                <SimpleCallout intent="danger" message="This email is already taken by another account."/>
            </div>}
        </div>;
    } else {

        if(use_code) {
            footer_right = null;
            body = <div>
                <VerifyAccountPin code_sent onSuccess={handlePinSuccess} username={props.account_email} />
            </div>;
        } else {
            footer_right = null;
            body = <div>
                <div>Verification Email Sent</div>

            </div>;
        }

    }

    function checkIfEmailAvailable(onSuccess) {
        setState('checking');

        const em = email;
        const payload = {
            email: em,
            community_uid
        };

        const res = (r) => {
            if (!r) {
                return;
            }
            if (r.data.status === 'is-community-member') {
                setState('is-member');
                setEmail('');
            } else if (r.data.status === 'email-taken') {
                setState('in-use');
                setEmail('');
            } else {
                if(onSuccess) {
                    onSuccess()
                }
            }
        };

        authFetch("/accounts/check-if-email-is-available", res, res, "POST", {payload});
    }

    function superadminAddSecondaryEmail(em) {
        adminAddSecondaryEmail(props.user_uid,em)
            .then((r) => {
                console.log("add secondary email",r);
                toasts.addToast({
                    text: "Email Added",
                    intent: "success"
                });
                onClose();
            })

    }

    function sendEmail() {
        setState('sending');
        sendEmailVerificationCode(props.user_uid ? props.user_uid : unaverse.user_uid,email,props.use_code)
            .then((r) => {
                if (!r) {
                    return;
                }
                if (r.data.ok === 'yes') {
                    setState('sent');
                    toasts.addToast({text: 'Verification Email Sent', intent: 'success'});
                } else {
                    setState('in-use');
                    toasts.addToast({text: 'Email already in use', intent: 'danger'});
                }
            })
    }

    return <SimplePortal>
        <Modal size={'small'} open={true} center>
            <ModalHeader title="Add Secondary Email" onClose={onClose}/>
            <ModalBody>
                <ModalContentBlock>
                {body}
                </ModalContentBlock>
            </ModalBody>
            <ModalFooter right={footer_right}/>
        </Modal>
    </SimplePortal>
}

const StatusItem = ({status = "verified"}) => {
    const it = status_info_map[status];
    if (!it) {
        // logError("No Item for Status Item");
        return null;
    }
    const {message, icon, color} = status_info_map[status];
    return <div className="flex items-center">
        <SimpleTooltip text={`${message}`}>
            <div className={`h-svg-4 ${color}`}>
                {icon}
            </div>
        </SimpleTooltip>
    </div>
}

const badge_colors = {
    'primary': 'bg-blue-100 text-blue-700',
    'warning': 'bg-yellow-100 text-yellow-700',
    'danger': 'bg-red-100 text-red-700',
    'success': 'bg-green-100 text-green-700',
    'secondary': 'bg-gray-100 text-gray-700',
    'admin': 'bg-purple-100 text-purple-700',
    'owner': 'bg-orange-100 text-orange-700',
};

export function Badge({children,color="primary",size="small",className=""}) {
    return <div className={`text-xs inline-block font-medium ${badge_colors[color]} leading-6 rounded-md px-1.5 ${className}`}>
        {children}
    </div>
}

function RemoveSign() {
    return <div className="h-5 w-5 text-red-500">
        <MinusCircleIcon/>
    </div>
}

function EmailItem({badge, can_make_primary, email, status, can_remove, onRemove}) {
    return <div className="flex items-center space-x-1.5 pb-0.5 pt-1">
        {can_remove && <div onClick={onRemove} className="flex cursor-pointer items-center">
            <RemoveSign/>
        </div>}
        <div className="flex space-x-1 flex-grow items-center">
            <div className="truncate text-base" style={{maxWidth: "65%"}}>{email}</div>
            <StatusItem status={status}/>
            {badge}
        </div>
    </div>
}

function buildEmailsFromUser(user) {
    let arr = [];
    if (isAnonymousUnatyEmail(user.account_email)) {
        return arr;
    }
    if (user.emails_status && user.emails_status[user.account_email]) {
        arr.push({
            email: user.account_email,
            status: user.emails_status[user.account_email].status
        });
    } else {
        arr.push({
            email: user.account_email,
            status: 'unverified'
        })
    }

    if (user.secondary_emails && user.emails_status) {
        user.secondary_emails.forEach(se => {
            const se_status = user.emails_status[user.account_email] ? user.emails_status[user.account_email].status : 'verified';
            arr.push({
                email: se,
                status: se_status
            });
        });
    }

    return arr;
}

export function UserEmailManager(props) {
    const unaverse = useUnaverse();
    const toasts = useToasts();
    const {community} = props;
    const emails = buildEmailsFromUser(unaverse.profile);
    const [loading, setLoading] = useState('');
    const [editing, setEditing] = useState(false);
    const [modal, setModal] = useState(null);
    const [modal_data, setModalData] = useState(null);

    function removeSecondaryEmail() {
        setLoading('removing-email');
        const res = () => {
            setLoading('');
            setModal(null);
            setModalData(null);
            setEditing(false);
            toasts.addToast({text: 'Secondary Email Removed', intent: 'success'});
        };
        const payload = {
            email: modal_data.email,
            user_uid: unaverse.user_uid
        };
        authFetch("/users/remove-secondary-email", res, res, "POST", {payload});
    }

    function makePrimary() {
        setLoading('changing-account-email');
        const res = () => {

            setLoading('');
            setModal(null);
            setEditing(false);
            toasts.addToast({text: 'Primary Email Changed', intent: 'success'});
        };
        const payload = {
            new_email: modal_data.email,
            user_uid: unaverse.user_uid
        };
        authFetch("/users/change-account-email", res, res, "POST", {payload});
    }

    function user_changeAccountEmail(new_email) {
        setLoading('changing-account-email');
        const res = () => {
            setLoading('');
            setModal(null);
            setEditing(false);
            toasts.addToast({text: 'Primary Email Changed', intent: 'success'});
        };
        const payload = {
            new_email: new_email,
            user_uid: unaverse.user_uid
        };
        authFetch("/users/change-account-email", res, res, "POST", {payload});
    }

    function member_changeAccountEmail(new_email) {
        setLoading('changing-account-email');
        toasts.addToast({text: `Changing account email`, intent: 'info'});

        const res = (resp) => {
            setLoading('');
            setModal(null);

            if (resp.data.ok === 'yes') {
                // history.push(`/${community.data.handle}/admin/member/${resp.data.id}`);
                toasts.addToast({text: `Account Email changed`, intent: 'success'});
            } else {
                // no error is shown
            }
        };

        const payload = {
            member_id: community.member_id,
            community_uid: community.uid,
            id: community.member_id,
            new_email,
            member_user_uid: community.member.user_uid,
            user_uid: community.member.user_uid,
        };

        authFetch("/members/change-account-email", res, res, "POST", {payload});
    }

    function changeAccountEmail(new_email) {

        if (community) {
            member_changeAccountEmail(new_email);
        } else {
            user_changeAccountEmail(new_email);
        }

    }

    const can_edit = emails.length > 1;
    const action = emails.length < 5 && <TextAction onClick={() => {
        setModalData(emails.map(em => em.email));
        setModal('add-secondary');
    }} text="Add Secondary Email"/>;

    const edit_action = !can_edit ? null : editing ?
        <TextAction onClick={() => setEditing(false)} text="Done" inverse/> :
        <TextAction onClick={() => setEditing(true)} text="Edit" inverse/>;

    const verified_count = emails.filter(a => a.status === 'verified').length;

    return <div className="max-w-md">
        <Field type="" compact label="Email" corner_hint={edit_action}>
            <div className="space-y-1 divide-y divide-gray-300">
                {emails.length === 0 && <div>
                    <div onClick={() => setModal('add-account-email')}>Missing Email</div>

                </div>}
                {emails.map((em, i) => {
                    const can_remove = i > 0 && verified_count > 1 && editing;
                    const can_make_primary = emails.length > 1 && i !== 0 && em.status === 'verified' && editing;
                    const badge = emails.length > 1 && i === 0 ?
                        <Badge>Primary</Badge> : can_make_primary ? <TextAction onClick={() => {
                            setModalData({email: em.email});
                            setModal('change-primary');
                        }} text="Make Primary"/> : null;
                    return <EmailItem status={em.status} badge={badge} key={em.email} email={em.email}
                                      can_remove={can_remove} onRemove={() => {
                        setModalData({email: em.email});
                        setModal('delete-email');
                    }}/>
                })}
            </div>
        </Field>
        {action && <div>
            {action}
        </div>}
        {modal === "add-secondary" && <AddSecondaryEmail
            community_uid={community ? community.uid : ""}
            onClose={() => {
                setModal(null);
                setModalData(null);
            }}/>}
        {modal === "delete-email" && <DoubleCheckModal
            onCancel={() => {
                setModal(null);
                setModalData(null);
            }}
            onConfirm={() => {
                removeSecondaryEmail();
            }}
            type="user_remove_email"/>}
        {modal === "change-primary" && <DoubleCheckModal
            onCancel={() => {
                setModal(null);
                setModalData(null);
            }}
            onConfirm={() => {
                makePrimary();
            }}
            type="user_change_primary_email"/>}

        {modal === 'add-account-email' && <AdMeActionChangeAccountEmail
            default_email={""}
            loading={loading === 'changing-account-email'} community_uid={community ? community.uid : ""}
            onConfirm={(h) => changeAccountEmail(h)}
            onClose={() => {
                setModal(null);
            }} onCancel={() => {
            setModal(null);
        }}
        />}
    </div>
}