import React from 'react';
import {
    BellIcon,
    CalendarIcon,
    HomeIcon,
    LifebuoyIcon,
    MagnifyingGlassIcon,
    QueueListIcon,
    UserCircleIcon,
    ChevronLeftIcon,
    PlusIcon,
    UsersIcon,
    HeartIcon,
    MapPinIcon,
    CakeIcon,
    BriefcaseIcon,
    CalendarDaysIcon,
    ClockIcon,
    EnvelopeIcon,
    AtSymbolIcon,
    InformationCircleIcon,
    TruckIcon,
    PhoneIcon,
    KeyIcon,
    HomeModernIcon,
    LinkIcon,
    PhotoIcon,
    IdentificationIcon,
    GlobeAltIcon,
    CheckCircleIcon,
    FlagIcon,
    CurrencyDollarIcon,
    ChatBubbleBottomCenterIcon,
    MusicalNoteIcon,
    PaperClipIcon,
    PaintBrushIcon,
    FingerPrintIcon,
    SparklesIcon,
    CodeBracketSquareIcon,
    ArchiveBoxIcon,
    GlobeAmericasIcon,
    CheckBadgeIcon,
    AdjustmentsHorizontalIcon,
    BuildingOfficeIcon,
    ArrowsUpDownIcon,
    Bars2Icon,
    Bars3Icon,
    PlayIcon,
    BuildingLibraryIcon,
    Square2StackIcon,
    StarIcon,
    CameraIcon,
    TagIcon,
    HashtagIcon,
    ListBulletIcon,
    UserIcon,
    PencilIcon,
    PencilSquareIcon,
    CheckIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    ArrowUpIcon,
    ArrowDownIcon,
    TableCellsIcon,
    ChevronRightIcon,
    EyeIcon,
    EyeSlashIcon,
    ArrowDownTrayIcon,
    CogIcon,
    Cog8ToothIcon,
    ArrowUpTrayIcon,
    BarsArrowDownIcon,
    BarsArrowUpIcon,
    Squares2X2Icon,
    XMarkIcon,
    XCircleIcon,
    ClipboardDocumentCheckIcon,
    CircleStackIcon,
    ArrowRightEndOnRectangleIcon,
    UserPlusIcon,
    BookmarkSquareIcon,
    SquaresPlusIcon,
    WrenchScrewdriverIcon,
    EllipsisHorizontalIcon,
    CloudIcon,
    GiftIcon,
    TrashIcon,
    ChevronUpIcon, ChevronDownIcon
} from "@heroicons/react/24/outline";

export const _m3_icon_outlines = {
    // navigation
    home: <HomeIcon/>,
    search: <MagnifyingGlassIcon/>,
    leaders: "",
    calendar: <CalendarIcon/>,
    spaces: "",
    lists: "",
    contacts: <UsersIcon/>,
    notifications: <BellIcon/>,
    preferences: "",
    profile: <UserCircleIcon/>,
    help: <LifebuoyIcon/>,
    gift: <GiftIcon/>,

    cloud: <CloudIcon />,

    ["add-to-space"]: <SquaresPlusIcon />,
    ["bulk-edit"]: <WrenchScrewdriverIcon />,

    xcircle: <XCircleIcon />,
    ellipsis: <EllipsisHorizontalIcon />,

    // objects
    community: "",
    member: <UserIcon/>,
    mentor: <UserIcon/>,
    mentees: <UsersIcon/>,
    position: "",
    list: "",
    post: "",
    space: <KeyIcon/>,
    event: "",
    file: "",
    folder: "",
    media: "",
    policy: <ClipboardDocumentCheckIcon/>,
    ["opt-in"]: <CheckCircleIcon/>,
    gate: "",
    catch_up: <QueueListIcon/>,
["import"]: <ArrowDownTrayIcon />,
    cog: <Cog8ToothIcon />,
    ["export"]: <ArrowUpTrayIcon />,


    //// Profile
    // Core
    key: <KeyIcon/>,
    id: <FingerPrintIcon/>,
    name: <IdentificationIcon/>,
    email: <EnvelopeIcon/>,
    phone: <PhoneIcon/>,
    handle: <AtSymbolIcon/>,
    address: <HomeModernIcon/>,
    location: <MapPinIcon/>,
    avatar: <PhotoIcon/>,
    "cover-photo": <CameraIcon/>,
    join_date: <CalendarDaysIcon/>,
    occupation: <BriefcaseIcon/>,
    "member-type": <UserIcon/>,
    office: <BuildingOfficeIcon/>,
    education: <BuildingLibraryIcon/>,
    "membership-status": <CheckCircleIcon/>,
    clock: <ClockIcon/>,
    globe: <GlobeAmericasIcon/>,
    "data-integrity": <CheckBadgeIcon/>,

    grid: <Squares2X2Icon />,
    table: <TableCellsIcon/>,
pinboard: <BookmarkSquareIcon />,

    generated: <SparklesIcon/>,
    code: <CodeBracketSquareIcon/>,
    tag: <TagIcon/>,

    //  actions: {
    compose: "",
    headline: <StarIcon/>,
    filter: <AdjustmentsHorizontalIcon/>,
    switch: "",
    goto: <ArrowRightEndOnRectangleIcon />,
    add: <PlusIcon/>,
    add_member: <UserPlusIcon/>,
    back: <ChevronLeftIcon/>,
    reply: <ChatBubbleBottomCenterIcon/>,
    edit: <PencilIcon />,
    delete: <TrashIcon />,
    close: <XMarkIcon />,
    save: "",
    archive: <ArchiveBoxIcon/>,
    cancel: "",
    check: <CheckIcon />,
    upload: "",
    download: "",
    share: "",
    copy: <Square2StackIcon />,
    info: <InformationCircleIcon/>,
    move: "",
    link: "",
    unlink: "",
    lock: "",
    unlock: "",

    flag: <FlagIcon/>,
    attachment: <PaperClipIcon/>,
    style: <PaintBrushIcon/>,
    sort: <ArrowsUpDownIcon/>,

    sort_ascending: <BarsArrowDownIcon />,
    sort_descending: <BarsArrowUpIcon />,

    left_arrow: <ArrowLeftIcon />,
    right_arrow: <ArrowRightIcon />,
    down_arrow: <ArrowDownIcon />,
    up_arrow: <ArrowUpIcon />,

    chevron_right: <ChevronRightIcon />,
    chevron_left: <ChevronLeftIcon />,
    chevron_up: <ChevronUpIcon />,
    chevron_down: <ChevronDownIcon />,

    show: <EyeIcon />,
    hide: <EyeSlashIcon />,


    // PROFILE
    moved: <TruckIcon/>,
    //  },

    //// Fields
    //  fields: {
    interests: <HeartIcon/>,
    "text": <Bars2Icon/>,
    "long-text": <Bars3Icon/>,
    number: <HashtagIcon/>,
    date: <CalendarIcon/>,
    time: <ClockIcon/>,
    datetime: <ClockIcon/>,
    checkbox: <CheckCircleIcon/>,
    language: <GlobeAltIcon/>,
    select: <CheckCircleIcon/>,
    "multi-select": <ListBulletIcon/>,
    "multi-select-taxonomy": <CircleStackIcon/>,
    "select-taxonomy": <CircleStackIcon/>,
    radio: "",
    url: <LinkIcon/>,
    birthday: <CakeIcon/>,
    image: <PhotoIcon/>,
    video: <PlayIcon/>,
    audio: <MusicalNoteIcon/>,
    currency: <CurrencyDollarIcon/>
};