import M3_C_Header from "../../../m3/components/header";
import M3_A_NavList from "../../../m3/atoms/nav-list";
import React from "react";
import {buildSpaceLeadingIndicator} from "../../../m3/layouts/layout/sidebar-components";

export function ContactsSidebar(props) {
    const items = [
        {
            type: "subheader",
            label: "Spaces"
        },
        ...props?.lists.map(list => {
            // list.count

            return {
                id: list.list_id,
                label: list.title,
                active: props.active === list.list_id,
                trailing_text: list?.count || "",
                onClick: () => {
                    props?.setActiveList(list.list_id);
                },
                opens_submenu: props.mobile,
                leading_indicator: buildSpaceLeadingIndicator(list, {
                    fontSize: "0.75rem",
                    textAlign: "center",
                    backgroundColor: list?.color || "#363636",
                    color: "#ffffff"
                })
            }
        })
    ];

    return <div className="">
        {!props.mobile && <M3_C_Header inline context_bar={{
            variant: "panel",
            onClose: () => {
                props?.layout_onToggleOpen();
            }
        }} size="sm" title="Contacts"/>}
        <div className="px-2 pb-6">
            <M3_A_NavList size={props.mobile?"base":"sm"} items={items}/>
        </div>
    </div>
}