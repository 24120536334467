import React, {useState} from "react"
import './styles.css';
import {useLocation, useParams} from "react-router-dom";
import {useAuth} from "../../../config/auth";
import {useUnaverse} from "../../../config/unaverse";
import {useCommunity} from "../../../config/community";
import {useIsMobile} from "../../../m3/hooks/is-mobile";

export default function DebugConsole() {
    const [expanded, setExpanded] = useState(false);
const location = useLocation();
const is_mobile = useIsMobile();
const params = useParams();
const community = useCommunity();
const {user,__ready} = useAuth();
    const unaverse = useUnaverse();
    const data = {
        router: {
            location,
            params
        },
        auth: {
            logged_in: !!user,
            user: user && {
                uid: user.uid,
                email: user.email,
            }
        },
        unaverse,
        community
    };
    if(is_mobile) return null;

    return <div className="fixed bottom-4 z-99 left-0 flex items-center text-white rounded-r-xl px-4 py-2 bg-gray-800">
        {expanded && <div className={""}>
            <div className="max-w-xl">
 <pre className="text-base font-medium text-white overflow-y-scroll text-left"
      style={{maxHeight: '50vh'}}>{JSON.stringify(data, undefined, 2)}</pre>
            </div>
            <div className="pt-2">
                <a onClick={() => setExpanded(false)}
                   className="font-semibold text-sm hover:underline cursor-pointer">Hide Debug</a>
            </div>
        </div>}
        {!expanded && <div className="flex flex-col gap-2">
        <a onClick={() => setExpanded(true)} className="font-semibold text-sm hover:underline cursor-pointer">Debug</a>
        <a href="https://www.figma.com/file/r6AgNk2BZ1QpsRR5dRDb0t/Roadmap?node-id=1%3A155&t=6z3IcfQBegAu48m5-0" target="_blank" className="font-semibold text-sm hover:underline cursor-pointer">Roadmap</a>
        <a href="https://trello.com/b/9RehUKHM/unaty-m2" target="_blank" className="font-semibold text-sm hover:underline cursor-pointer">Backlog</a>
        </div>}
    </div>
};