import React from "react";
import {MessageReference} from "../../../../../m3/_legacy_components/notifications";
import {db} from "../../../../../config/setup-firestore";
import {doc, getDoc} from "firebase/firestore";

export function WB_MessagesPost({post_id,community_uid}) {
    const [post, setPost] = React.useState(null);
    React.useEffect(()=>{
        if(post_id) {
            const ref = doc(db, 'community_content', community_uid, 'posts', post_id);
            getDoc(ref)
                .then(doc => {
                    setPost(doc.exists() ? {...doc.data(), id: doc.id} : null);
                });
        }
    }, [])
    if(!post) {
        return null;
    }
    return <div>
        <MessageReference post={post} author_id={post.author_id} community_uid={community_uid} text={post.plain_text.slice(0,240)} title={post.subject} fill show_title />
    </div>
}