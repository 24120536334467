import {IMAGE_RESIZER_URL} from "../app/config/defaults";

export function migrateImageRequest(endpoint = "", handleResult = (result) => {
}, handleError = (error) => {
}, type = "GET", options = {}, token = null) {
    let url = IMAGE_RESIZER_URL + endpoint;

    //console.log("access_token", access_token)
    let fetch_options = {
        method: type,
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    };


    if (type === 'POST') {
        let body_options = {};

        for (var i = 0; i < Object.keys(options).length; i++) {
            let key = Object.keys(options)[i];

            body_options[key] = options[key];
        }

        fetch_options.body = JSON.stringify(body_options);
    }

    fetch(url, fetch_options).then(res => res.json())
        .then(result => handleResult(result))
        .catch(error => {
                handleError(error);
                throw(error);
            }
        );
}

export function imageResizeRequest(file, handleResult = () => {
}, type = "POST", options = {}, token = null) {
    let endpoint = "/resize";
    let url = `${IMAGE_RESIZER_URL}${endpoint}`;

    const formData = new FormData();
    formData.append("file", file);

    for (const name in options) {
        formData.append(name, options[name]);
    }
    let fetch_options = {
        method: type,
        body: formData
    };

    console.log("CALL URL", url, type)
    try {
        fetch(url, fetch_options)
            .then(res => res.json())
            .then(result => handleResult(result))
            .catch(error => {
                    console.error("ERROR 2", error)
                }
            );
    } catch (e) {
        console.error("ERROR", e)
    }

}
