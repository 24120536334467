import React, {useEffect, useState} from 'react';
import MetaHeader from "../../../m3/_legacy_components/meta-header";
import {activity_types} from "../../../m3/_legacy_components/admin-activity/types";
import {hydrateDynamicText, renderDynamicText} from "../../../m3/_legacy_components/admin-activity/item";
import DataWrapper from "../../../m3/_legacy_components/data-wrapper";
import {DEFAULT_USER_PHOTO, useCommunity} from "../../../config/community";
import {Avatar} from "../../../m3/_legacy_components/avatar";

import EntityTabs from "../../../m3/_legacy_components/entity-tabs";
import {RulesInlineSelect} from "../../../m3/_legacy_components/rules-inline-select";
import {UpdatesTableView} from "./table-view";
import {getEventsFromFeed, readAllEvents} from "../../../m3/_legacy_components/semantic-welcome/api";
import {TableOfContents} from "../../../m3/_legacy_components/table-of-contents";
import {InlineLoader} from "../../../m3/_legacy_components/admin-activity";
import {TextAction} from "../../auth/sign-in";
import {utils_dates_format} from "../../../../common/utilities/dates";
import {buildImageUrl} from "../../../../common/utilities/images";

function Contents({data={}, parent_data={}, show_time, ts, msg}) {
    const final = hydrateDynamicText(msg||"", {...parent_data,member_name:data.name||parent_data.member_name||""});
    const f_arr = final.split(/##/g);
    const text = renderDynamicText(f_arr, {});
    const img = buildImageUrl(data.profile_picture, '_medium', DEFAULT_USER_PHOTO);
    return <div className="flex space-x-2">
        <div className="flex-none">
            <Avatar size={`h-8 w-8`} url={img}/>
        </div>
        <div className="flex items-center">
            <div>
            <div className="text-gray-800 text-base">{text}</div>
            {show_time&&<div className="pt-1 text-xs text-gray-500">{utils_dates_format(ts).relative}</div>}
            </div>
        </div>
    </div>
}

function Item({type, ts, connector, message_key='message', __date, show_time, scope_id, cid, data}) {
    const activity_info = activity_types[type];
    const [error, setError] = useState(false);
    if (!activity_info) {
        console.error('missing info', type)
        return null;
    }

    /*
     {connector==='line'&&<div className="absolute top-1.5 bottom-0 left-1.5 justify-center flex w-5 ">
            <div className="w-0.5 bg-gray-400 h-full" />
        </div>}
     */
    const msg = activity_info[message_key];
    if(!msg) {
        console.warn('missing message', type, message_key)
    }
    return <DataWrapper onError={()=>{
        setError(true);
    }} id={scope_id} type={`community_members.${cid}.members`}>
        <Contents show_time={show_time} ts={ts} parent_data={data} msg={msg}/>
    </DataWrapper>
}

function LayoutDropdown({layout, setLayout}) {
    return <div>
        <RulesInlineSelect layout="minimal" minimal inline selected={layout} options={[
            {
                id: "timeline",
                label: "Timeline"
            },
            {
                id: "table",
                label: "Table"
            }
        ]} placeholder={""} onSelect={(no) => setLayout(no)}/>
    </div>
}

async function getAllUpdates(community, timeframe, layout) {

    // last year
        return getEventsFromFeed(community,timeframe)
            .then(evts => {
                return evts
            });
}

function buildTimelineSections(data,community) {
    let a = [];

    data.forEach(evt=>{
        const full_date = evt.__date;
        const year_index = a.findIndex(b=>`${full_date.year}`===b.title);
        let yi;
        if(year_index===-1) {
            a.push({
                title: `${full_date.year}`,
                key: full_date.year,
                items: []
            });
            yi = a.length-1;
        } else {
            yi = year_index;
        }
        const month_index = a[yi].items.findIndex(c=>c.label===full_date.month);
        if(month_index===-1) {
            a[yi].items.push({
                label: full_date.month,
                key: full_date.month,
                count: 1,
                icon: null
            });
        } else {
            a[yi].items[month_index].count++;
        }
    })

    return a;
}

function getConnector(len,index) {
    if((index+1)===len) {
        // last one
        return 'none';
    } else {
        return 'line'
    }
}

function UpdatesTimelineView({data, community}) {
    let sections = buildTimelineSections(data,community);

    let obj = {
        'today': {events: [], title: 'Today'},
        'yesterday': {events: [], title: 'Yesterday'},
        'this-week': {events:[],title: 'This Week'},
        'last-month': {events:[],title: 'Last Month'},
        'last-year': {events:[],title: 'Last Year'},
        'beyond': {events:[],title: 'Beyond'},
    };

    data.forEach(evt=>{
        obj[evt.__date.sort].events.push(evt);
    });

    const event_secs = Object.values(obj).filter(a=>a.events.length>0);

    return <div className="px-4 grid gap-2.5 sm:gap-8 grid-cols-1 sm:grid-cols-4 pb-3">
        <div className="sm:block hidden">
            <TableOfContents no_top_padding sections={sections}/>
        </div>
        <div className="space-y-3 col-span-3">
            {event_secs.length===0&&<div className="p-4 text-center text-sm text-gray-600">
                No updates found
            </div>}
            {event_secs.map((evtsec,ind)=>{
                return <div key={ind}>
                    <div className="text-gray-800 text-base font-medium pt-1.5 pb-1">
                        {evtsec.title}
                    </div>
                    <div className="space-y-3 pt-0.5 relative">
                        {evtsec.events.map((event,k)=>{
                            const show_time = k===0?true:evtsec.events[k-1].date_formatted!==event.date_formatted;
                            return <Item message_key={'catch_up'} show_time={show_time} connector={getConnector(evtsec.events.length, k)} key={event.id} cid={community.uid} {...event} />
                        })}
                    </div>
                </div>
            })}
        </div>
    </div>
}

function processData(dt) {
    return dt.map(it=>{
        return {...it,
            __date: utils_dates_format(it?it.ts:Date.now())}
    })
}

export function CatchUp({data, onClose}) {
    const community = useCommunity();
    const tabs = [{label: "New"}, {label: "Last month"}, {label: "Last year"}];
    const [updated,setUpdated] = useState(false);
    const [state, setState] = useState("loading-init");
    const [dt, setDt] = useState([]);
    const [tab, setTab] = useState(tabs[0].label);
    const [layout, setLayout] = useState("timeline");

    useEffect(function () {
        setState("loading-init")
        getAllUpdates(community, tab, layout)
            .then((evts)=>{
                setDt([...evts]);
                setState("");
            })
    }, [tab])

    function markAsRead() {
          setUpdated(true);
        readAllEvents(community)
            .then(()=>{

            })
    }

    const unread_count = dt.filter(a=>a.read===false).length;

    const actions = unread_count > 0 && !updated ? [{button:<TextAction onClick={()=>markAsRead()} text="Mark as read"/>}] : [];

    const header = <MetaHeader actions={actions} top={{
        type: "text",
        text: "Catch-up"
    }} title={"Community Updates"} onClose={onClose}/>;

    const processed = processData(dt);

    function getView() {
        if (state === "loading-init") {
            return <div className="p-4 flex items-center justify-center">
                <InlineLoader />
            </div>
        }
        if (layout === 'table') {
            return <UpdatesTableView data={processed} community={community}/>
        } else {
            return <UpdatesTimelineView data={processed} community={community}/>
        }
    }

    console.log("PROCESSED", processed)

    // todo modaltabswrapper
    return <div className="pb-12" style={{minHeight: '16rem'}}>
        <div className="px-4 pt-1.5 pb-1.5">
            {header}
            <div className=" mb-3 flex items-baseline">
                <div className="flex-grow">
                    <EntityTabs tabs={tabs} active={tab} onChangeTab={t => setTab(t)}/>
                </div>
                <div>
                    <LayoutDropdown layout={layout} setLayout={setLayout}/>
                </div>

            </div>
        </div>

        {getView()}
    </div>
}