import React from 'react';
import {getItemFromLocalStorage, saveItemToLocalStorage} from "../utilities/localstorage";
import {AnimatePresence, motion} from "framer-motion";
import M3_A_Text from "../atoms/text";

// this is for the left sidebar
const minSidebarWidth = 220;
const maxSidebarWidth = 400;

// this is for the right sidebar
const minRightSidebarWidth = 360;
const maxRightSidebarWidth = 620;

// this is for the content
const minContentWidth = 450;

const hide_sidebars_breakpoint = 768;
const left_sidebar_breakpoint = 1024;

function getInitialValues(inits, default_key) {
    let sidebarWidth = 250;
    let collapseSidebar = false;
    let rightSidebarWidth = 400;
    let rightSidebarCollapsed = true;

    const localstorage_data = getItemFromLocalStorage(default_key, null);
    /*
        if (inits.sidebarWidth) {
            sidebarWidth = inits.sidebarWidth;
        } else if (localstorage_data && localstorage_data.sidebarWidth) {
            sidebarWidth = localstorage_data.sidebarWidth;
        }

     */

    if (inits.collapseSidebar) {
        collapseSidebar = inits.collapseSidebar;
    } else if (localstorage_data && localstorage_data.collapseSidebar) {
        collapseSidebar = localstorage_data.collapseSidebar;
    }

    if (inits.rightSidebarWidth) {
        rightSidebarWidth = inits.rightSidebarWidth;
    } else if (localstorage_data && localstorage_data.rightSidebarWidth) {
        rightSidebarWidth = localstorage_data.rightSidebarWidth;
    }

    if (inits.rightSidebarCollapsed) {
        rightSidebarCollapsed = inits.rightSidebarCollapsed;
    } else if (localstorage_data && localstorage_data.rightSidebarCollapsed) {
        rightSidebarCollapsed = localstorage_data.rightSidebarCollapsed;
    }

    return {
        sidebarWidth,
        collapseSidebar,
        rightSidebarWidth,
        rightSidebarCollapsed
    }
}

function getContentWidth(sidebarWidth, rightSidebarWidth, collapseSidebar, rightSidebarCollapsed) {
    let width = window.innerWidth - sidebarWidth - rightSidebarWidth;

    if (collapseSidebar) {
        width += sidebarWidth;
    }

    if (rightSidebarCollapsed) {
        width += rightSidebarWidth;
    }

    return width;
}

function DragElement({
                         children,
                         currentWidth,
                         offset = 0,
                         placement = 'right',
                         minWidth = 0,
                         maxWidth = 400,
                         toggleWidth = -1,
                         onChange
                     }) {
    const [isDragging, setIsDragging] = React.useState(false);

    function handleDrag(e) {
        pauseEvent(e);
        const final_offset = placement === 'right' ? offset : window.innerWidth - offset;
        const newProposedWidth = placement === 'right' ? e.clientX - final_offset : window.innerWidth - e.clientX;
        if (newProposedWidth > minWidth && newProposedWidth < maxWidth) {
            onChange(newProposedWidth);
        } else if (newProposedWidth < minWidth) {
            if (toggleWidth > 0 && newProposedWidth < toggleWidth) {
                onChange('close')
            } else {
                onChange('min');
            }
        } else if (newProposedWidth > maxWidth) {
            onChange('max');
        }

    }

    function handleDragEnd(e) {
        setIsDragging(false);
        document.removeEventListener("mousemove", handleDrag);
        document.removeEventListener("mouseup", handleDragEnd);
    }

    function handleDragStart(e) {
        // if sidebar is hidden, open it
        pauseEvent(e);
        setIsDragging(true);
        document.addEventListener("mousemove", handleDrag);
        document.addEventListener("mouseup", handleDragEnd);
    }

    function pauseEvent(e) {
        if (e.stopPropagation) e.stopPropagation();
        if (e.preventDefault) e.preventDefault();
        e.cancelBubble = true;
        e.returnValue = false;
        return false;
    }

    return <div style={{cursor: "ew-resize"}}
                className={`al2-sidebar-drag z-20 
                  ${isDragging ? "bg-blue-400 border-blue-400" : "bg-transparent border-transparent"}
                  ${placement === 'right' ? "right-0 border-r" : "left-0 border-l"}
                  absolute transition-all hover:bg-blue-400 w-1 bottom-0 top-0 hover:border-blue-400`}
                onMouseDown={handleDragStart}/>
}

export function DynamicAppFrame({
                                    defaults_key = 'global-defaults',
                                    sidebarCollapsed = false,
                                    left_sidebar = null,
                                    updateParent = () => {
                                    },
                                    theme,
                                    footer_message,
                                    hideSidebar = false,
                                    showRightSidebar = false,
                                    right_sidebar = null,
                                    showSidebarHover,
                                    debug = false,
                                    children,
                                    config,
                                    inits = {}
                                }) {
    const initial_values = getInitialValues(inits, defaults_key);
    const [collapseSidebar, setCollapseSidebar] = React.useState(initial_values.collapseSidebar);
    const [sidebarWidth, setSidebarWidth] = React.useState(initial_values.sidebarWidth);
    const [rightSidebarWidth, setRightSidebarWidth] = React.useState(initial_values.rightSidebarWidth);
    const [rightSidebarCollapsed, setRightSidebarCollapsed] = React.useState(initial_values.rightSidebarCollapsed);

    const sidebarIsCollapsed = React.useRef(collapseSidebar);
    const rightSidebarIsCollapsed = React.useRef(rightSidebarCollapsed);

    React.useEffect(() => {
        if (sidebarIsCollapsed.current && !sidebarCollapsed) {
            setCollapseSidebar(false);
        } else if (!sidebarIsCollapsed.current && sidebarCollapsed) {
            setCollapseSidebar(true);
        }
    }, [sidebarCollapsed])

    React.useEffect(() => {
        if (rightSidebarIsCollapsed.current && showRightSidebar) {
            // show the sidebar
            setRightSidebarCollapsed(false);
        } else if (!rightSidebarIsCollapsed.current && !showRightSidebar) {
            // hide the sidebar
            setRightSidebarCollapsed(true);
        }
    }, [showRightSidebar])

    // on window resize, we need to update the content width
    React.useEffect(() => {
        // if the window is less than the mobile breakpoint, we need to collapse the sidebar
        document.body.style.overflow = "hidden";

        // if sidebar is open, add class to body
        if (showSidebarHover) {
            document.body.classList.add("show-sidebar-hover");
        } else {
            document.body.classList.remove("show-sidebar-hover");
        }

        if (collapseSidebar) {
            document.body.classList.add("sidebar-collapsed");
        } else {
            document.body.classList.remove("sidebar-collapsed");
        }

        if (rightSidebarCollapsed) {
            document.body.classList.add("right-sidebar-collapsed");
        } else {
            document.body.classList.remove("right-sidebar-collapsed");
        }

        checkWidths()

        function handleResize() {
            const currentContentWidth = getContentWidth(sidebarWidth, rightSidebarWidth, collapseSidebar, rightSidebarIsCollapsed.current);
            if (currentContentWidth < minContentWidth && !sidebarIsCollapsed.current) {
                // close sidebar
                setCollapseSidebar(true);
            } else if ((currentContentWidth < minContentWidth) && !rightSidebarIsCollapsed.current) {
                // close right sidebar
                const newRightSidebarWidth = Math.floor(rightSidebarWidth - (rightSidebarWidth * 0.025));
                if (newRightSidebarWidth > minRightSidebarWidth) {
                    setRightSidebarWidth(newRightSidebarWidth);
                } else {
                    setRightSidebarCollapsed(true);
                }
            } else if ((currentContentWidth < minContentWidth) && !collapseSidebar) {

                setCollapseSidebar(true);
            }
            // if the content width is less than min content width + sidebar width + right sidebar width, we need to collapse the right sidebar
            else if (currentContentWidth < minContentWidth && !rightSidebarIsCollapsed.current) {
                // let's reduce the right sidebar width gradually until we have to collapse it
                // let's reduce it by 10% of the current width
                const newRightSidebarWidth = Math.floor(rightSidebarWidth - (rightSidebarWidth * 0.025));
                if (newRightSidebarWidth > minRightSidebarWidth) {
                    setRightSidebarWidth(newRightSidebarWidth);
                } else {
                    setRightSidebarCollapsed(true);
                }
            } else if (window.innerWidth > left_sidebar_breakpoint && currentContentWidth > (minContentWidth + maxSidebarWidth) && collapseSidebar) {
                // setCollapseSidebar(false);
            }
        }

        // update sidebar collapsed state
        sidebarIsCollapsed.current = collapseSidebar;
        rightSidebarIsCollapsed.current = rightSidebarCollapsed;

        window.addEventListener('resize', handleResize);

        // TODO CHECK FOR TOO MANY RENDERS
        updateParent(collapseSidebar);

        updateLocalstorageDefaults();

        return () => {
            document.body.style.overflow = "auto";
            window.removeEventListener('resize', handleResize);
        }
    }, [sidebarWidth, rightSidebarWidth, collapseSidebar, rightSidebarCollapsed]);

    function updateLocalstorageDefaults() {
        const defaults = {
            collapseSidebar: collapseSidebar,
            sidebarWidth: sidebarWidth,
            rightSidebarWidth: rightSidebarWidth,
            rightSidebarCollapsed: rightSidebarCollapsed
        };
        saveItemToLocalStorage(defaults_key, defaults);
    }

    function checkWidths() {
        const currentContentWidth = getContentWidth(sidebarWidth, rightSidebarWidth, collapseSidebar, rightSidebarCollapsed);
        if (currentContentWidth < minContentWidth && !sidebarIsCollapsed.current) {
            // close sidebar
            setCollapseSidebar(true);
        }
            // if the window is less than the left sidebar breakpoint, we need to collapse the sidebar
        // if the content width is less than the min content width, we need to collapse the sidebar
        else if ((window.innerWidth > left_sidebar_breakpoint) && (currentContentWidth > (minContentWidth + maxSidebarWidth)) && collapseSidebar) {
            // setCollapseSidebar(false);
        }
    }

    function handleSidebarWidthChange(val) {
        if (sidebarIsCollapsed.current && val !== 'close') {
            setCollapseSidebar(false);
            return;
        }

        if (val === 'close') {
            setCollapseSidebar(true);
        } else {
            if (sidebarIsCollapsed.current) {
                setCollapseSidebar(false);
            }
            if (val === 'min') {
                setSidebarWidth(minSidebarWidth);
            } else if (val === 'max') {
                setSidebarWidth(maxSidebarWidth);
            } else if (!isNaN(val)) {
                setSidebarWidth(val);
            }
        }
    }

    function handleRightSidebarWidthChange(val) {
        if (val === 'min') {
            setRightSidebarWidth(minRightSidebarWidth);
        } else if (val === 'max') {
            setRightSidebarWidth(maxRightSidebarWidth);
        } else if (!isNaN(val)) {
            setRightSidebarWidth(val);
        }
        checkWidths();
    }

    const contentWidth = getContentWidth(sidebarWidth, rightSidebarWidth, collapseSidebar, rightSidebarCollapsed);

    const canShowRightSidebar = window.innerWidth > hide_sidebars_breakpoint;

    /*

                <DragElement onChange={handleSidebarWidthChange} currentWidth={sidebarWidth} toggleWidth={160}
                             offset={0} minWidth={minSidebarWidth}
                             maxWidth={maxSidebarWidth}/>
     */

    let main_styles = {}, content_bg_color = "";

    if (theme?.mode === "dark") {
        // if light mode, add shadow based on values in theme like shadow_color, shadow_opacity, and shadow_blur
        main_styles.border = `1px solid ${theme?.border_color}`;
    } else {
        // if dark mode, add border based on values in theme
        main_styles.boxShadow = theme?.box_shadow;
    }

    content_bg_color = theme?.foreground;

    const content_height = footer_message ? "calc(100vh - 3.5rem)" : "calc(100vh - 0.5rem)";

    return (<div className="h-screen flex flex-col" style={{
            backgroundColor: theme.background
        }}>
            <div className="flex relative flex-grow">

                {!hideSidebar && <div style={{
                    width: collapseSidebar ? 56 : sidebarWidth,
                   // transition: "width 0.05s"
                }} id="left-sidebar" className="transition relative py-1 h-full flex-none">
                    <div className="" style={{height: content_height}}>
                        {left_sidebar}
                    </div>
                </div>}
                <div id="content" className="flex-grow h-full p-1 w-full overflow-x-hidden">
                    {debug && <div className="p-4">
                        Debug:<br/>
                        Content Width: {contentWidth}<br/>
                        Sidebar Width: {sidebarWidth}<br/>
                        Right Sidebar Width: {rightSidebarWidth}<br/>
                        Collapse Sidebar: {collapseSidebar.toString()}<br/>
                    </div>}
                    <div className="relative overflow-auto rounded-lg flex flex-col" id="main"
                         style={{height: content_height, background: content_bg_color, ...main_styles}}>
                        {children}
                    </div>
                </div>
                {!rightSidebarCollapsed && <div style={{
                    width: rightSidebarWidth,
                    minWidth: rightSidebarWidth,
                   // transition: "width 0.05s"
                }} id="right-sidebar" className="pr-1 py-1 relative h-full flex-none">
                    <div className="bg-white rounded-lg overflow-hidden" style={{
                        height: content_height
                    }}>
                        {right_sidebar}
                    </div>
                    <DragElement onChange={handleRightSidebarWidthChange} currentWidth={rightSidebarWidth}
                                 minWidth={minRightSidebarWidth} maxWidth={maxRightSidebarWidth}
                                 offset={contentWidth + (collapseSidebar ? 0 : sidebarWidth)}
                                 placement="left"/>
                </div>}
                {showSidebarHover &&
                    <div className="h-screen z-10 fixed top-0 left-0 bottom-0"
                         style={{width: "320px"}}
                         id="sidebar-hover">
                        <AnimatePresence>
                            <motion.div
                                initial={{opacity: 0, x: -320}}
                                animate={{opacity: 1, x: 0}}
                                exit={{opacity: 0, x: -320}}
                            >
                                <div className="bg-white shadow-lg h-screen border-r border-gray-200">
                                    {left_sidebar}
                                </div>
                            </motion.div>
                        </AnimatePresence>
                    </div>}
            </div>
            {footer_message && <div className="h-12 flex-none pb-1 px-1">
                <div
                    className={"rounded-lg shadow-sm bg-gray-800 justify-center items-center flex text-center text-white h-full"}>
                    <M3_A_Text weight="font-medium">
                        {footer_message.children}
                    </M3_A_Text>
                </div>
            </div>}
        </div>
    );
}