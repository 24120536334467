function makeTextFile(text,format="text/vcard") {
    let textFile = null;
    let data = new Blob([text], {type: format, charset: 'utf-8'});

    // If we are replacing a previously generated file we need to
    // manually revoke the object URL to avoid memory leaks.
    if (textFile !== null) {
        window.URL.revokeObjectURL(textFile);
    }

    textFile = window.URL.createObjectURL(data);

    // returns a URL you can use as a href
    return textFile;
}

export function clientDownloadFile(name,data,format) {
    if(!name || !data) {
        return;
    }
    const file = makeTextFile(data,format);

    let link = document.createElement('a');
    link.setAttribute('download', name);
    link.href = file;
    document.body.appendChild(link);

    // wait for the link to be added to the document
    window.requestAnimationFrame(function () {
        let event = new MouseEvent('click');
        link.dispatchEvent(event);
        document.body.removeChild(link);
    });
}