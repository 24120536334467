import React, {useEffect, useState} from 'react';
import {InlineLoader} from "../../../../../m3/_legacy_components/admin-activity";
import EntityList from "../../../../../m3/_legacy_components/entity-list";
import {DEFAULT_USER_PHOTO, useCommunity} from "../../../../../config/community";
import {getMemberSubtitle} from "../../add-people/modal";
import {getManyMembers} from "../../../members/create";
import {buildImageUrl} from "../../../../../../common/utilities/images";
export function buildEntityForList(type,raw,community,group) {
    if(type==="member"||type==="members") {
        return {
            title: raw.name,
            subtitle: getMemberSubtitle({...raw,type:"member"},community.member_types,"member_type"),
            id: raw.id,
            sub_icon: group&&group.moderators?(group.moderators.user_uids[raw.id]?"organizer":""):"",
            icon: {
                type: "small-image",
                image: `${buildImageUrl(raw.profile_picture, "_medium", DEFAULT_USER_PHOTO)}`
            },
            type: "member",
            actions: [],
            onClick: null
        }
    }
    if(type==="member_type"||type==="member_types") {
        return {
            title: raw.plural,
            subtitle: `${raw.count} ${raw.count===1?"member":"members"}`,
            id: raw.id,
            sub_icon: "",
            icon: {
                type: "count",
                count: `${raw.count}`
            },
            type: "member_type",
            actions: [],
            onClick: null
        }
    }
    if(type==="filters"||type==="filter") {
        return {
            title: raw.name,
            subtitle: "",
            id: raw.id,
            sub_icon: "",
            icon: {
                type: "count",
                count: `${raw.count}`
            },
            type: "filter",
            actions: [],
            onClick: null
        }
    }
    return {
        title: raw.name,
        subtitle: raw.assignee ? raw.assignee_data.name : "Unassigned",
        id: raw.id,
        sub_icon: "role",
        icon: {
            type: "small-image",
            image: `${buildImageUrl(raw.assignee_data.profile_picture, "_medium", DEFAULT_USER_PHOTO)}`
        },
        type: "role",
        actions: [

        ],
        onClick: null
    }
}

function buildItems(community,data,roles_order,role_ids,member_ids,member_data,flags,fns) {
    let a = [];
    let le;

    roles_order.forEach(rid=>{
        if(role_ids.includes(rid)) {
            const role_data = community.all_roles.filter(a=>a.id===rid)[0]
            if(role_data) {
                le = buildEntityForList("role",role_data);
                le.actions.push({
                    type: "group-moderator-more",
                    actions: {
                        removeOrganizer: ()=>fns.removeOrganizer(rid,"role",role_data,()=>{}),
                    },
                    metadata: {
                        can_remove: flags.can_manage
                    }
                })
                a.push(le);
            }
        }
    })

    role_ids.forEach(rid=>{
        if(!roles_order.includes(rid)) {
            const role_data = community.all_roles.filter(a=>a.id===rid)[0]
            if(role_data) {
                le = buildEntityForList("role",role_data,community)
                le.actions.push({
                    type: "group-moderator-more",
                    actions: {
                        removeOrganizer: ()=>fns.removeOrganizer(rid,"role",role_data,()=>{}),
                    },
                    metadata: {
                        can_remove: flags.can_manage
                    }
                })
                a.push(le);
            }
        }
    })

    member_ids.forEach(mid=>{
        const index = member_data.findIndex(a=>a.id===mid);
        if(member_data[index]) {
            le = buildEntityForList("member",member_data[index],community)
            le.actions.push({
                type: "group-moderator-more",
                actions: {
                    removeOrganizer: ()=>fns.removeOrganizer(mid,"member",member_data[index],()=>{}),
                },
                metadata: {
                    can_remove: flags.can_manage
                }
            })
            a.push(le);
        }
    })

    return a;
}

export function GMMOrganizers({data, flags, fns, id}) {
    const community = useCommunity();
    const [state, setState] = useState("loading");
    const [member_data,setMemberData] = useState([]);

    const moderator_role_ids = Object.keys(data.moderators.roles);
    const moderator_member_ids = Object.keys(data.moderators.members);

    useEffect(function () {
        setState("");
        getMemberData()
    }, [data.moderators.members]);

    function getMemberData() {
        getManyMembers(community.id,moderator_member_ids)
            .then(docs=>setMemberData(docs.map(doc=>{
                return {
                    id: doc.id,
                    ...doc.data()
                }
            })))
    }

    if (state === "loading") {
        return <div className="flex justify-center items-center">
            <InlineLoader padding="p-8"/>
        </div>
    }

    const roles_order = data.roles_order || [...moderator_role_ids];

    const items = buildItems(community,data,roles_order,moderator_role_ids,moderator_member_ids,member_data,flags,fns);
console.log("data",data, items)
    return <div className="pt-3 pb-8">
        <div className="-mx-4">
            <EntityList interactive minimal items={items}
                        community_uid={community.id}/>
        </div>
    </div>
}