import {data_countries_map} from "../data/data/countries";

export function formatAddress(a) {
    let str = '';
    if (!a) {
        return '';
    }
    if (a.line_one) {
        str += `${a.line_one}\n`;
    }
    if (a.line_two) {
        str += `${a.line_two}\n`;
    }
    if (a.city) {
        str += `${a.city}`;
    }
    if (a.city && a.zip_code) {
        str += `, `;
    }
    if (a.zip_code) {
        str += `${a.zip_code}`;
    }
    if (a.city || a.zip_code) {
        str += `\n`;
    }
    if (a.state) {
        str += `${a.state}, `;
    }
    if (a.country) {
        str += `${data_countries_map[a.country]}`;
    }
    return str;
}