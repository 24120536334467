import {authFetch} from "../network";
import {logEngagementEvent} from "../../app/config/firebase-setup";

export function noNewEntities(en) {
    let roles = Object.keys(en.roles).length === 0;
    let member_types = Object.keys(en.member_types).length === 0;
    let members = Object.keys(en.members).length === 0;
    let groups = Object.keys(en.groups).length === 0;
    let filters = Object.keys(en.filters).length === 0;

    return roles && members && member_types && groups && filters;
}

export async function api_addMembersToGroup(ctx,id,member_ids) {
    let a = {
        roles: {},
        members: {},
        member_types: {},
        groups: {},
        filters: {},
    };

    member_ids.forEach(id => {
        a.members[id] = true;
    });

    return await api_addPeopleToGroup(ctx,id,a);
}
export async function api_addPeopleToGroup(ctx,id,entities) {

    if(noNewEntities(entities)) {
        return {
            ok: 'yes',
            reason: "No new entities"
        }
    }
    return new Promise(resolve => {
        const res = (resp) => {
            logEngagementEvent("add_members_to_space", {
                content_id: id
            }, ctx.community_uid);
            resolve(resp)
        };
        const payload = {
          ...ctx,
          id,
          entities
        };

        authFetch("/groups/add-new-entities", res, res, "POST", {payload});
    })

}