export function constructMemberCollections(community,selected_collection_id) {
   // console.log("constructMemberCollections", community)
    if (community?.shortcuts.length > 0) {
     //   console.log("community.shortcuts", community.shortcuts)
        return community.shortcuts.map((shortcut) => {

            return {
                list_id: `space__${shortcut.id.split("__")[1]}`,
                id: shortcut.id.split("__")[1],
                title: shortcut.label,
                type: "members",
                subtype: "group",
                context: "space",
                context_id: shortcut.id.split("__")[1],
                count: shortcut?.member_count||0,
                _sort_name: shortcut._sort_name,

                letter: shortcut.label.charAt(0).toUpperCase(),
                emoji: shortcut.emoji,
                color: shortcut.color,
                closed: shortcut.closed,
                image: shortcut.image,

                stack: [
                    {
                        id: `default`,
                        emoji: `${shortcut.emoji}`,
                        color: `${shortcut.color}`,
                        click: shortcut.click,
                        image: `${shortcut.image}`,
                        is_private: false,
                        label: `${shortcut.label}`,
                        filters: [],
                        sort: {
                            field: "about.last_name",
                            direction: "asc",
                        },
                    },
                ],
            }
        })
            .sort((a, b) => {
                // first return the item with the everyone_group_id as the first item

                if(selected_collection_id&&a.id===selected_collection_id) {
                    return -1;
                } else if(!selected_collection_id&&a.id===community.profile.everyone_group_id) {
                    return -1;
                }

                // then sort alphabetical by _sort_name
                return a._sort_name.localeCompare(b._sort_name);
            })
    } else {
        return [];
    }
}