import React from 'react';
import {
    UserIcon,
    EnvelopeIcon,
    PhoneIcon,
    CakeIcon,
    AtSymbolIcon,
    ArrowRightOnRectangleIcon,
    IdentificationIcon,
    CalendarIcon,
    HeartIcon,
    PencilSquareIcon,
    ChatBubbleBottomCenterTextIcon, BriefcaseIcon, MapPinIcon, StarIcon, CheckCircleIcon
} from "@heroicons/react/24/outline";
import {valuePipeline} from "../../../m3/_legacy_components/profile-field-renderer/value-pipeline";
import {isAnonymousUnatyEmail} from "../../../m3/utilities/email";
import {
    DLCAccountEmail, DLCBirthday, DLCGeneral, DLCGroupOrganizer,
    DLCHandle,
    DLCID, DLCJoined, DLCLastLogin, DLCMembershipStatus, DLCMemberType,
    DLCPhone, DLCPositions
} from "./detail-list-components";
import RoleIcon from "../../../m3/icons/_legacy/custom/RoleIcon";
import {CIModerator} from "../../../m3/icons/_legacy/custom/Moderator";
import {MemberPoliciesList} from "./policies/list";
import {MemberOptInDetails} from "./opt-in";
import {data_membership_status_map} from "../../../../common/data/data/membership-status";

function getEmail(email) {
    if (isAnonymousUnatyEmail(email)) {
        return "Anonymous";
    }
    return email;
}

function getMemberTypeName(member, community) {
    if (!member) {
        return '';
    }

    if (member.member_type_name) {
        return member.member_type_name;
    }

    const mt = community.member_types.filter(a => a.id === member.member_type)[0];

    if (mt) {
        return mt.singular;
    }

    return member.member_type;
}

function getMembershipStatusLabel(data) {
    if (!data||!data.membership_status) {
        return "--"
    }
    return data_membership_status_map?.[data.membership_status]?.label || "--";
}

export function manageMemberDetailList(data, community, flags, fns, ps, custom_fields, add_empty_field, follow_data, profile_quality_level) {
    let s = [];

    /*
    Member type, policies, birthday, location, joined, last login, id, handle, profile quality
     */
    s.push({
        title: "Details",
        actions: [],
        items: [
            {
                interactive: true,
                onClick: () => {
                },
                icon: <EnvelopeIcon/>,
                label: "Account Email",
                type: 'email',
                component: <DLCAccountEmail openModal={fns.openChangeEmailModal} data={data}/>,
                value: getEmail(data.account_email),
                meta: {}
            },

            {
                interactive: true,
                onClick: () => {
                },
                icon: <PhoneIcon/>,
                label: "Phone",
                type: 'phone',
                component: <DLCPhone onClick={fns.openPhone} data={data}/>,
                value: data.contact.phone,
                meta: {}
            },
            {
                label: "Profile Quality",
                icon: <StarIcon/>,
                type: 'string',
                component: <DLCGeneral fallback={"Incomplete"} value={profile_quality_level}/>,
                meta: {}
            },
            {
                label: "ID",
                icon: <IdentificationIcon/>,
                type: 'string',
                tag: "copy",
                component: <DLCID data={data}/>,
                value: data?.id || "",
                meta: {}
            },
            {
                label: "Membership Level",
                type: 'string',
                icon: <UserIcon/>,
                component: <DLCMemberType value={getMemberTypeName(data, community)} onClick={fns.openMemberType}
                                          data={data}/>,
                value: getMemberTypeName(data, community),
                meta: {}
            },
            {
                label: "Membership Status",
                type: "string",
                icon: <CheckCircleIcon/>,
                component: <DLCMembershipStatus value={getMembershipStatusLabel(data, community)}
                                                onClick={fns.openMembershipStatus} data={data}/>,
                value: data?.membership_status || "active",
                meta: {}
            },
            {
                label: "Birthday",
                icon: <CakeIcon/>,
                type: 'string',
                component: <DLCBirthday onClick={fns.openBirthday} data={data}/>,
                value: valuePipeline(data.birthday || {}, {type: 'birthday'}),
                meta: {}
            },
            {
                label: "Handle",
                icon: <AtSymbolIcon/>,
                type: 'string',
                component: <DLCHandle openModal={fns.openChangeHandleModal} data={data}/>,
                value: valuePipeline(data.handle, {type: 'handle'}),
                meta: {}
            },
            {
                label: "Joined",
                type: 'string',
                icon: <CalendarIcon/>,
                component: <DLCJoined onClick={fns.openJoinDate} data={data}/>,
                value: valuePipeline(data.join_date || "", {type: 'join_date'}),
                meta: {}
            },
            {
                label: "Location",
                icon: <MapPinIcon/>,
                type: 'string',
                component: <DLCGeneral fallback={"Add Location"}
                                       value={valuePipeline(data.about.location || "", {type: 'location'})}
                                       onClick={fns.openLocation} data={data}/>,
                meta: {}
            },
            {
                label: "Occupation",
                icon: <BriefcaseIcon/>,
                type: 'string',
                component: <DLCGeneral fallback={"Add Occupation"}
                                       value={valuePipeline(data.about.occupation || "", {type: 'occupation'})}
                                       onClick={fns.openOccupation} data={data}/>,
                meta: {}
            }
        ]
    })

    if(community?.profile?.preferences?.members?.enable_member_opt_in) {
        s.push({
            title: "Opt-in",
            actions: [],
            items: [
                {
                    component: <MemberOptInDetails data={data} community={community}/>
                }
            ]
        });
    }

    s.push({
        title: "Policies",
        actions: [],
        items: [
            {
                component: <MemberPoliciesList data={data} community={community}/>
            }
        ]
    });

    s.push({
        title: "Leadership",
        actions: [],
        items: [
            {
                label: "Positions",
                icon: <RoleIcon/>,
                type: 'string',
                component: <DLCPositions data={data}/>,
                value: valuePipeline(data.last_sign_in || null, {type: 'last_sign_in'}),
                meta: {}
            },
            {
                label: "Organizer",
                icon: <CIModerator/>,
                type: 'string',
                component: <DLCGroupOrganizer data={data}/>,
                value: valuePipeline(data.last_sign_in || null, {type: 'last_sign_in'}),
                meta: {}
            },
        ]
    });

    s.push({
        title: "Stats",
        actions: [],
        items: [
            {
                label: "Last login",
                icon: <ArrowRightOnRectangleIcon/>,
                type: 'string',
                component: <DLCLastLogin data={data}/>,
                value: valuePipeline(data.last_sign_in || null, {type: 'last_sign_in'}),
                meta: {}
            },
            {
                label: "Followers",
                type: 'string',
                icon: <HeartIcon/>,
                value: `${follow_data && follow_data.following_count || 0} Followers`,
                meta: {}
            },
            {
                label: "Following",
                type: 'string',
                icon: <HeartIcon/>,
                value: `${follow_data && follow_data.following_count || 0} Following`,
                meta: {}
            },
            {
                label: "Posts",
                type: 'string',
                icon: <PencilSquareIcon/>,
                value: `0 Posts`,
                meta: {}
            },
            {
                label: "Messages",
                type: 'string',
                icon: <ChatBubbleBottomCenterTextIcon/>,
                value: `0 Messages`,
                meta: {}
            }
        ]
    });

    const count = s.length;

    return s;
}