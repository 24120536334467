import React from "react";
import {Link, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {useCommunity} from "../../../config/community";
import {setDocumentTitle} from "../../../m3/utilities/set-document-title";

function Index() {
    return <div>
        index /
        <Link to={`folder1`}>folder1</Link>
    </div>
}

function Folder() {
    const navigate = useNavigate();
    return <div>
        FOLDER /
        <Link to={`file1`}>file1</Link> /
        <div onClick={()=>navigate(-1)}>back</div>
        <Routes>
            <Route path=":fileId" element={<File/>}/>
        </Routes>
    </div>
}

function File() {
    const navigate = useNavigate();
    return <div>
        FILE /
        <div onClick={()=>navigate(-1)}>back</div>
    </div>
}

export function DrivePage() {
    const community = useCommunity();

    setDocumentTitle(`Drive`, `${community.profile.name}`);

    return <div className="pt-20">
        <Routes>
            <Route path="/" element={<Index/>}/>
            <Route path=":folderId/*"  element={<Folder/>}/>
        </Routes>
    </div>
}