import React, {useContext, useEffect, useState} from 'react';
import {useAuth} from "./auth";
import {getDoc, doc, documentId} from "firebase/firestore";
import {db} from "./setup-firestore";
import {authFetch} from "../../api/network";
import {getManyDocuments} from "./community";
import {getItemFromLocalStorage} from "../m3/utilities/localstorage";

async function getUserProfile(uid) {
    const ref = doc(db, "users", uid);
    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? doc.data() : null;
        });
}

async function getMemberships(uid) {
    const ref = doc(db, "user_memberships", uid);
    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? doc.data() : null;
        });
}

const UnaverseContext = React.createContext(null);

function getLoadingStatus(memberships, auth) {
    return "ready";

    // return "loading";
}

async function getPoliciesData() {
    const ref = doc(db, 'remote_config', 'policies');
    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? doc.data() : null;
        });
}

const superadmins_uids = [
    "1qryWMaxP4huFshdy4yIgokxjTH2",
    "n89GVvJLvvS3A4XucQWqg2VHi053",
    "Onpe5LniB6eAtnhV9fByEWuvsuC2",
    "BDdL16MmYIW2LyKAtKoykEzKcwP2"
];

function getLsVal(k='showSwitcher',df=false) {
    const it = getItemFromLocalStorage('global-defaults',null);

    return it && !!it[k] ? it[k] : df;
}

const UnaverseContextProvider = ({children}) => {
    const {user, __ready} = useAuth();
    const [loader, setLoader] = useState(0);
    const [image, setImage] = useState();
    const [policies_data, setPoliciesData] = useState(null);
    const [focus_search, setSearchFocus] = useState(false);
    const [edit_modal, setEditModal] = useState();
    const [ghost_mode, setGhostMode] = useState(false);
    const [settings, setSettings] = useState(false);
    const [communitiesData, setCommunitiesData] = useState({});
    const [showSwitcher, setShowSwitcher] = useState(getLsVal('showSwitcher',false));
    const [collapseSidebar, setCollapseSidebar] = useState(false);
    const [showSidebarHover, setShowSidebarHover] = useState(false);
    const [memberships, setMemberships] = useState(() => {
        return {
            communities: null,
            member_ids: null,
        };
    })
    const [profile, setProfile] = useState(() => {
        return {};
    });

    useEffect(() => {
        if (user) {
            if(ghost_mode) {
                console.log('prevented register sign in')
                return;
            }

            authFetch('/users/record-sign-in', () => {
            }, () => {
            }, 'POST', {});
        }
    }, [user,ghost_mode]);

    async function refreshMemberships() {
        return await handleUpdateUserProfile(true);
    }

    async function handleUpdateUserProfile(get_memberships) {
        if (user && user.uid) {
            await getUserProfile(user.uid)
                .then(pr => {
                    setProfile({...pr});
                    return pr;
                })
            if (get_memberships) {
                await getMemberships(user.uid)
                    .then(resp => {
                        setMemberships({...resp});
                    })
            }
        }
    }

    useEffect(function () {
        checkForMissingCommunityData();
    }, [memberships, communitiesData])

    function checkForMissingCommunityData() {
        const community_ids = Object.keys(memberships.communities || {});
        const missing_community_ids = community_ids.filter((cid) => {
            return !communitiesData[cid];
        });

        if(missing_community_ids.length === 0) {
            return;
        }

        if (missing_community_ids.length > 0) {
            // set temporary loading status
            let new_communities_data = {...communitiesData};
            missing_community_ids.forEach((cid) => {
                new_communities_data[cid] = {
                    loading: true
                }
            });
            setCommunitiesData(new_communities_data);

            // fetch missing data
            getManyDocuments("communities", null, null, documentId(), missing_community_ids)
                .then(result => {
                    let new_communities_data = {...communitiesData};
                    result.forEach((doc) => {
                        new_communities_data[doc.id] = doc.data();
                    });
                    setCommunitiesData(new_communities_data);
                })
        }
    }

    useEffect(function () {
        getPoliciesData()
            .then((pd) => setPoliciesData(pd))
    }, [])

    useEffect(() => {
        handleUpdateUserProfile(true)
    }, [user]);

    const loading_status = getLoadingStatus(memberships, user);

    const is_superadmin = !user ? false : superadmins_uids.includes(user.uid);

    const v = {
        toggleSettings: () => {
            setSettings(!settings)
        },

        showSidebarHover,
        toggleSidebarHover: ()=>setShowSidebarHover(!showSidebarHover),

        showSwitcher,
        toggleSwitcher: ()=>setShowSwitcher(!showSwitcher),

        collapseSidebar,
        setSidebarCollapsed: (c)=>setCollapseSidebar(c),
        toggleSidebar: ()=>setCollapseSidebar(!collapseSidebar),

        settings,
        loading_status,
        setPoliciesData: (pd) => setPoliciesData({...pd}),
        focus_search,
        toggleSearchFocus: () => setSearchFocus(!focus_search),
        stopSearchFocus: () => setSearchFocus(false),
        auth_check_ready: !!user,
        user: profile,
        user_uid: user && user.uid ? user.uid : "",
        id: user && user.uid ? user.uid : "",
        policies_data,
        uid: user && user.uid ? user.uid : "",

        updateUserProfile: handleUpdateUserProfile,

        image,
        openImage: (image) => {
            setImage(image)
        },
        closeImage: () => {
            setImage(null);
        },

        openEditOverlay: (pl) => {
            console.log("PRP", pl)
            setEditModal(pl);
        },
        closeEditModal: () => {
            setEditModal(null)
        },
        language: user?.preferences?.language || "en",
        edit_modal,

        refreshMemberships,

        setLanguage: () => {
        },

        is_superadmin,
        ghost_mode,

        memberships,
        communitiesData,
        profile,

        setGhostMode: (gm) => setGhostMode(!!gm),

        loader,
        setLoader
    };

    return <UnaverseContext.Provider value={v}>{children}</UnaverseContext.Provider>
}

const useUnaverse = () => {
    const data = useContext(UnaverseContext);

    return data;
};

export {useUnaverse, UnaverseContextProvider}