import React from "react";
import M3_A_InlineEntity from "../../atoms/inline-entity";
import M3_A_NodeSelector from "../../atoms/node-selector";
import M3_A_NavList from "../../atoms/nav-list";
import {m3_icon_map} from "../../icons/icon-map";
import M3_A_IconButton from "../../atoms/icon-button";
import {UpdateCircle} from "../../../routes/community/contacts/components";
import {m3_adjust_size} from "../../utilities";

export function AL2SidebarName({name, theme, collapsed, logo}) {
    /*
    token_sx={{
                backgroundColor: theme?.background_text_80,
            }}
     */
    // avatar={logo}
    // letter={name.charAt(0).toUpperCase()}

    let entity_props = {
        truncate: true,
        label_sx: {
            maxWidth: "172px",
            fontWeight: "600"
        },
        narrow: true,
        size: "lg"
    };
    if(collapsed) {
        entity_props = {
            ...entity_props,
            letter: name.charAt(0).toUpperCase(),
            avatar: logo,
            token_sx: {
                backgroundColor: theme?.background_text_80,
                color: theme?.background_active_text
            }
        }
    }
    return <div className="inline-flex items-center" style={{
        color: theme?.background_text
    }}>
        <M3_A_NodeSelector variant={theme.mode==="light"?"light":"dark"} icon_sx={{
            color: theme?.background_text_40
        }} dropdown={!collapsed} size="lg" square={collapsed}>
            <M3_A_InlineEntity auto_height {...entity_props}>{collapsed ? null : name}</M3_A_InlineEntity>
        </M3_A_NodeSelector>
    </div>
}

export function Al2SidebarNotifications({goTo,theme,unread}) {
    const show_update = !!(unread && unread > 0);
    // theme.mode==="light"?"light":"dark"
    return <div>
        <div className="relative" style={{
            color: theme?.background_text_40
        }}>
            <M3_A_IconButton square size="base" adjust_icon_size={-1} variant={"light"} compact
                             onClick={goTo} icon={m3_icon_map.outlines.notifications}/>
            {show_update && <UpdateCircle border_color={theme?.background} color={theme?.notification} position="top-0.5 right-1"/>}
        </div>
    </div>
}

export function AL2SidebarUser({name, theme, goToProfile, letter, avatar, config, collapsed}) {
    return <div className="h-8 flex items-center" style={{
        color: theme?.background_text
    }}>
        <M3_A_NodeSelector onClick={() => {
            goToProfile();
        }} icon_sx={{
            color: theme?.background_text_40
        }} dropdown={false} size="base">
            <M3_A_InlineEntity token_sx={{
                backgroundColor: theme?.background_text_80,
                color: theme?.background_active_text
            }} narrow letter={letter} avatar={avatar} size="base">{collapsed ? null : name}</M3_A_InlineEntity>
        </M3_A_NodeSelector>
    </div>
}

export const sidebar_header_items_index = {
    "home": {
        name: "Home",
        active_path: "/home",
        icon: "home"
    },
    "calendar": {
        name: "Calendar",
        active_path: "/calendar",
        icon: "calendar",
    },
    "search": {
        name: "Search",
        active_path: "/search",
        icon: "search",
    },
    "notifications": {
        name: "Notifications",
        active_path: "/notifications",
        icon: "notifications",
    },
    "contacts": {
        name: "Contacts",
        active_path: "/contacts",
        icon: "contacts",
    },
    "profile": {
        name: "Profile",
        active_path: "/{profile_path}",
        icon: "profile",
    },
    "leaders": {
        name: "Leaders",
        active_path: "/leaders",
        icon: "leaders",
    },
    'help': {
        name: "Help",
        icon: "help",
        simple_icon: true
    }
}

function buildHeaderItems(items, profile_path, base_path, theme, location, collapsed, navigate) {
    return items.map((id, index) => {
        const info = sidebar_header_items_index[id];
        const icon = m3_icon_map.custom[id];
        const path_check = info?.active_path?.replace("{profile_path}", profile_path);
        const active = location?.pathname?.startsWith(`${base_path}${path_check}`);
        return {
            label: collapsed ? "" : info.name,
            active,
            leading_indicator: {
                type: "icon",
                icon,
                adjust_size: -1,
                sx: {
                    color: active ? "" : theme?.background_text_60
                }
            },
            onClick: () => {
                if (active) return;
                navigate(`${base_path}${path_check}`)
            }
        }
    });
}

export function AL2SidebarHeader({items, location, navigate, theme, config, collapsed}) {
    const {profile_path, base_path} = config;
    const nav_list_items = buildHeaderItems(items, profile_path, base_path, theme, location, collapsed, navigate);

    const styles = {
        item: {
            hover: {
                background_color: theme.background_hover,
            },
            focus: {
                background_color: theme.background_pressed
            },
            active: {
                background_color: theme.background_active,
                text_color: theme.background_active_text,
                token_color: theme.background_active_text,
                font_weight: "600"
            },
            resting: {
                text_color: theme.background_text,
                token_color: theme.background_text,
                font_weight: "500"
            }
        },
        header: {
            icon: {
                resting: {}
            }
        }
    };

    return <div className="" style={{
        width: collapsed ? "min-content" : ""
    }}>
        <M3_A_NavList styles={styles} items={nav_list_items}/>
    </div>;
}

export function buildSpaceLeadingIndicator(it, sx) {
    if (it.emoji) {
        return {
            type: "emoji",
            emoji: it.emoji
        }
    } else if (it.letter) {
        return {
            type: "letter",
            letter: it.letter,
            sx
        }
    }
    return {
        type: "icon",
        icon: m3_icon_map.outlines.space
    }
}

function buildBodyItems(arr, theme, collapsed, location, navigate) {

    return arr.map((it, index) => {
        let to_return = {
            label: collapsed ? "" : it.name
        };
        const active = location.pathname.startsWith(it.link);

        to_return.emphasized = !!it.count;

        to_return.active = active;

        if (it.emoji) {
            to_return.leading_indicator = buildSpaceLeadingIndicator(it, {
                fontSize: "0.65rem",
                backgroundColor: active ? theme?.background_active_text : theme?.background_text_20,
                color: active ? theme?.background_active : theme?.background_active_text
            });
        } else if (it.letter) {
            to_return.leading_indicator = buildSpaceLeadingIndicator(it, {
                fontSize: "0.65rem",
                backgroundColor: active ? theme?.background_active_text : theme?.background_text_20,
                color: active ? theme?.background_active : theme?.background_active_text
            });
        }

        if (!collapsed && !active) {
            if (it.is_external) {
                to_return.trailing_indicator = {
                    type: "icon",
                    icon: m3_icon_map.solid.external_link,
                    sx: {
                        color: theme?.background_text_20
                    }
                };
            } else if (it.count) {
                to_return.trailing_indicator = {
                    type: "counter-label",
                    count: `${it.count}`,
                    adjust_size: -1,
                    sx: {
                        borderColor: theme.notification,
                        backgroundColor: theme.notification,
                        color: theme.notification_text
                    }
                };
            }
        }
        to_return.onClick = () => {
            if (!it.link) return;
            if (it.is_external) {
                window.open(it.link, '_blank', 'noopener,noreferrer');
            } else {
                navigate(it.link);
            }
        };

        return to_return;
    })
}

function BodySec({section, theme, styles, sidebar_collapsed, navigate, location}) {
    const [is_collapsed, setIsCollapsed] = React.useState(false);

    function toggleCollapsed() {
        setIsCollapsed(!is_collapsed);
    }

    const items = [
        {
            type: "subheader",
            label: sidebar_collapsed ? "" : section.name,
            collapsed: is_collapsed,
            size: "base",
            onClick: section?.link ? () => {
                navigate(section.link);
            } : null,
            label_sx: {
                fontWeight: "400",
                color: theme?.background_text_60
            },
            toggleCollapsed: toggleCollapsed,
            icon_props: {
                compact: true,
                variant: "light",
                adjust_icon_size: -1,
                size: "sm",
                sx: {
                    color: theme?.background_text_40
                },
                intent: "default"
            }
        },
        ...buildBodyItems(section.items, theme, sidebar_collapsed, location, navigate)
    ]

    return <div className="">
        {!is_collapsed && <M3_A_NavList collapsible size="sm" styles={styles}
                                        items={items}/>}
    </div>;
}

export function AL2SidebarBody({sections = [], theme, navigate, location, collapsed}) {

    const styles = {
        item: {
            hover: {
                background_color: theme.background_hover,
            },
            focus: {
                background_color: theme.background_pressed
            },
            active: {
                background_color: theme.background_active,
                text_color: theme.background_active_text,
                token_color: theme.background_active_text,
                font_weight: "600"
            },
            resting: {
                text_color: theme.background_text,
                token_color: theme.background_text,
            },
            emphasis: {
                font_weight: "600"
            }
        },
        subheader: {
            resting: {
                text_color: theme.background_text
            }
        }
    };

    return <div className="grid gap-6 pb-12" style={{
        width: collapsed ? "min-content" : ""
    }}>
        {sections.map((section, index) => {
            return <BodySec theme={theme} location={location} styles={styles} section={section} key={index}
                            sidebar_collapsed={collapsed} navigate={navigate}/>
        })}
    </div>
}