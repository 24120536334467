import {authFetch} from "../network";

export async function api_joinGroup(id,community) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        const payload = {
            id,
            member_id: community.member_id,
            community_uid: community.uid
        };

        authFetch("/groups/join", res, res, "POST", {payload});
    })
}