import React, {useState, useEffect} from "react"
import './styles.css';
import {AdminActivityFeed} from "./feed";
import {collection, limit, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";

export function InlineLoader({padding, mini, inverted}) {

    return <div className={`${padding ? "p-8" : ""} ${mini ? "flex items-center" : "flex justify-center"}`}>
        <div className={`inlineloader ${inverted ? "inverted" : ""} ${mini ? "mini" : ""}`}>

        </div>
    </div>
}

export default function AdminActivity({community, scope, scope_id, scope_data}) {
    const [ids, setIds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    function handleSnap(snap) {
        if (snap.empty) {
            console.log('snap empty')
        } else {
            const lastVisible = snap.docs[snap.docs.length - 1];
            console.log("last", lastVisible);
        }
        let new_ids = [];
        let new_data = {...data};
        snap.docs.forEach(a => {
            new_ids.push(a.id);
            new_data[a.id] = a.data();
        });
        setData(new_data);
        setLoading(false);
        setIds(new_ids);
    }

    useEffect(function () {
        let sub = () => {
        };

        const ref = collection(db, "community_members", community.id, "activity");

        const q = query(ref, where('context', '==', scope), where('context_id', '==', scope_id), orderBy('ts', 'desc'), limit(50));

        sub = onSnapshot(q, (s) => {
            handleSnap(s, true)
        });

        return () => {
            sub();
        };
    }, [scope_id, scope, community.uid]);

    return <div className="">
        {!loading && <AdminActivityFeed events={ids.map(id => {
            return data[id]
        })} context={{community_uid: community.uid}}/>}
        {loading && <div className={"p-4 flex justify-center"}>
            <InlineLoader/>
        </div>}
    </div>
}