import React from "react"
import './styles.css';
import ContentPopup from "../content-popup";
import {ContentPopupMenu} from "../content-popup/components";
import SimpleTooltip from "../tooltip";
import {useIsMobile} from "../../../m3/hooks/is-mobile";

function getTextClasses(active,layout) {
    if(layout === "standard") {
        return active ? "text-gray-900 font-semibold tracking-tight" : "tracking-tight text-gray-500 font-medium hover:text-gray-800";
    } else {
        return active ? "text-blue-600 font-semibold" : "text-gray-500 font-medium hover:text-gray-700";
    }
}

function getDivClasses(active,layout,base="bg-white") {
    if(layout==="standard") {
        return active ? "bg-blue-500" : `${base} group-hover:bg-blue-200`;
    } else {
        return active ? "bg-blue-500" : `${base} group-hover:bg-blue-200`;
    }
}

function getPaddingClasses(active,layout) {
    if(layout==="standard") {
        return "px-1 pt-2 pb-2";
    } else {
        return "pt-0.5 px-0.5 pb-0.5";
    }
}

function Tab({is_active,right_component,onChangeTab,tab,layout='standard',base}) {

    const padding = getPaddingClasses(is_active,layout);
    const text = getTextClasses(is_active,layout);
    const classes = getDivClasses(is_active,layout,base);
    return <div onClick={()=>onChangeTab(tab.label,tab)} className={`cursor-pointer group relative flex  ${padding} ${text} `}>
        {tab.icon&&<div className={`h-5 w-5 ${text}`}>{tab.icon}</div>}
        <span className="whitespace-nowrap">{tab.label}</span>
        {tab.has_update&&<span className="h-1.5 w-1.5 rounded-full bg-red-600 absolute -right-1.5 top-2.5" />}
        {tab.sublabel&&<span className="pl-1.5 font-normal">{tab.sublabel}</span>}
        {tab.badge&&<span className="ml-1.5 px-1 self-center leading-5 rounded-md text-xs font-medium h-5 bg-gray-100 text-gray-500">{tab.badge}</span>}
        {!!right_component&&<span className="ml-0.5">{right_component}</span>}
        {layout==="standard"&&<div style={{height:'0.2rem'}} className={`absolute bottom-0 left-0 right-0 ${classes} rounded-md `} />}
    </div>
}

function OverflowMenu({items,layout,base}) {
    const popup_items = items.map((item,i) => {
      return {
            label: item.label,
      }
    })
    const contents = <div className="w-72 bg-white content-popup-shadow max-w-xs rounded-lg border border-gray-200">
        <ContentPopupMenu items={popup_items} />
    </div>
    return <SimpleTooltip appendTo={()=>document.body} hideOnClick simple trigger='click' interactive text={contents} placement='bottom-end'>
          <Tab onChangeTab={()=>{}} tab={{label: `${items.length} more..`}} base={base} layout={layout} />
    </SimpleTooltip>
}

function getShowTabCount(max_width, tab_widths, is_mobile) {
    if(max_width===-1) {
        return tab_widths.length
    }
    if(is_mobile) {
        return 1;
    }
    let show_tab_count = 0;
    let width = 0;
    while(width < max_width && show_tab_count < tab_widths.length) {
        width += tab_widths[show_tab_count];
        show_tab_count++;
    }
    return show_tab_count;
}

export default function EntityTabs({tabs=[],show_switcher,count,switcher,max_width=-1,style={},behavior="scroll",right_action,base='bg-white',layout="standard",onChangeTab=()=>{},active}) {
    const font_size = layout === "standard" ? "text-base" : "text-sm";
    const tabs_widths = tabs.map(t => t.label.length * 10);
    const is_mobile = useIsMobile();
    const show_tab_count = getShowTabCount(max_width, tabs_widths, is_mobile);

    const final_tabs = tabs.slice(0,show_tab_count);
    const overflow_tabs = tabs.slice(show_tab_count);

    return <div style={{...style}} className={`flex space-x-4 overflow-x-auto no-scrollbars relative ${font_size}`}>
        {final_tabs.map((tab,k)=>{
            const is_active = tab.id && tab.id === active || tab.label === active;
            const right_component = show_switcher&&k===0&&!!switcher?switcher:null;
            return <Tab key={`${k}-${tab.label}`} right_component={right_component} base={base} is_active={is_active} tab={tab} layout={layout} onChangeTab={onChangeTab} />
        })}
        {overflow_tabs.length>0&&<OverflowMenu layout={layout} base={base} items={overflow_tabs} />}
        {right_action&&<div className="absolute flex items-center top-0 bottom-0 right-0 bg-white pl-1.5">
            {right_action}
        </div>}
    </div>
};