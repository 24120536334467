import {useCallback, useEffect, useState} from "react";
import {copyTextToClipboard} from "../../utilities/copy-to-clipboard";
import {all_field_types} from "../../../../common/data/field-types";

function getRawValue(entry, key) {
    // key can be nested up to 3
    const keys = key.split(".");

    if (keys.length === 1) {
        return entry[key];
    } else if (keys.length === 2) {
        return entry[keys[0]][keys[1]];
    } else if (keys.length === 3) {
        return entry[keys[0]][keys[1]][keys[2]];
    } else {
        return "";
    }
}

export function useTableKeyHandlers(tableContainerRef, cell_refs, rows, columns, handleDataUpdate, rowSelection, setRowSelection, addCommand, hasUndo, hasRedo, undo, redo, left_width_px) {
    const [selected_cell, setSelectedCell] = useState(null);
    const [character, setCharacter] = useState(null);

    useEffect(() => {
        if (selected_cell) {
            // focus on element from ref
            const element = cell_refs.current[selected_cell];
            if (element) {
                //{behavior: "smooth", block: "nearest", inline: "nearest"}
                //element.scrollIntoView();
                const [row_index, col_index] = selected_cell.split("-").map((i) => parseInt(i));
                const element_rect = element.getBoundingClientRect();
                const container_rect = tableContainerRef.current.getBoundingClientRect();

                const horizontal_element_in_view = element_rect.left >= container_rect.left && element_rect.right <= container_rect.right;

                // if a row is not in view, then we need to scroll to it

                // if col === 1, then we need to scroll to the left
                const scroll_left = tableContainerRef.current.scrollLeft;

                let scroll_to_obj = {}
                if (!horizontal_element_in_view) {
                    if (col_index === 1) {
                        scroll_to_obj.left = 0;
                    } else {
                        scroll_to_obj.left = scroll_left + (element_rect.right - container_rect.right);
                        // - left_width_px;
                    }
                }

                const _item_height = element_rect.height + 1;

                const first_row_in_view = Math.floor(tableContainerRef.current.scrollTop / _item_height);
                const rows_in_view = Math.floor(container_rect.height / _item_height) - 2; // 2 is a magic number for padding

                const last_row_in_view = first_row_in_view + rows_in_view;

                //const scroll_to_rows = Math.floor(((element_rect.top - tableContainerRef.current.scrollTop - container_rect.height) / _item_height));
                let top_row_index;

                if (row_index === 0) {
                    scroll_to_obj.top = 0;
                } else if (row_index > last_row_in_view) {
                    // top row index should be
                    top_row_index = row_index - rows_in_view;
                    scroll_to_obj.top = top_row_index * _item_height;
                } else if (row_index < first_row_in_view) {
                    // top row index should be
                    top_row_index = row_index;
                    scroll_to_obj.top = top_row_index * _item_height;
                }

                tableContainerRef.current.scrollTo(scroll_to_obj);
            }
        }
        setCharacter(null);
    }, [selected_cell, tableContainerRef, left_width_px]);

    function handleCopyCellValue(selected_cell) {
        const [row_index, col_index] = selected_cell.split("-").map((i) => parseInt(i));

        const row = rows[row_index];
        const column = columns[col_index];

        const raw_value = getRawValue(row.original, column.accessorKey);
        const type = column.meta.type;
        // first let's get the type
        // then let's see if there's an isValid fn for the type
        if (!!all_field_types[type]?.isValid) {
            let dt = {};
            let opts = {...(column.display||{})};
            if (all_field_types[type].isValid(raw_value, dt, opts)) {
                copyTextToClipboard(all_field_types[type].formatValue(raw_value, dt, opts));
            } else {
                console.warn("Value is not valid for type", type, raw_value)
            }
        } else {
            console.warn("No isValid fn for type", type)
        }
    }

    function handlePasteCellValue(selected_cell, paste_value) {
        const [row_index, col_index] = selected_cell.split("-").map((i) => parseInt(i));

        const row = rows[row_index];
        const column = columns[col_index];
        const type = column.meta.type;
        // need to get type of field
        if (!!all_field_types[type]?.isValid) {
            let dt = {};
            let opts = {...(column.display||{})};
            if (all_field_types[type].isValid(paste_value, dt, opts)) {
                console.log("handlePasteCellValue Value is valid for type", type, paste_value, column.accessorKey)
                const final_value = all_field_types[type].getProcessedValue(paste_value,dt,opts);
                console.log("final_value", final_value)
                addDataSaveAction(row.original.id, column.accessorKey, final_value, getRawValue(row.original, column.accessorKey), row.original);
            } else {
                console.log("Value is not valid for type", type, paste_value)
            }
        } else {
            console.log("No isValid fn for type", type)
        }
    }

    function addDataSaveAction(id, key, new_value, old_value, record) {
        handleDataUpdate(id, key, new_value, record)

        addCommand({
            type: "data-update",
            undo: () => {
                handleDataUpdate(id, key, old_value, record)
            },
            redo: () => {
                handleDataUpdate(id, key, new_value, record)
            }
        })
    }

    function handleKeyPress(e, key, selected_cell) {
        const [row_index, col_index] = selected_cell.split("-").map((i) => parseInt(i));

        const shift_is_pressed = e?.shiftKey;
        const shift_keys = ["ArrowUp", "ArrowDown"];

        const cmd_is_pressed = e?.metaKey || e?.ctrlKey;

        // if focus inside portal, then don't handle key press
        const portal = e?.target?.closest(".cell-portal");
        if (portal) {
            return;
        }

        if (!e) {
            if(selected_cell&&key === "Enter") {
                setCharacter(null);
                if (row_index < rows.length - 1) {
                    setSelectedCell(`${row_index + 1}-${col_index}`);
                }
            } else if(selected_cell&&key === "Tab") {
                setCharacter(null);
                if (col_index < columns.length - 2) {
                    setSelectedCell(`${row_index}-${col_index + 1}`);
                }
            }

            return;
        }

        if (cmd_is_pressed) {
            if (key === "c") {
                handleCopyCellValue(selected_cell)
            } else if (key === "v") {
                navigator.clipboard.readText()
                    .then(text => {
                        handlePasteCellValue(selected_cell, text)
                    })
                    .catch(err => {
                        console.error('Failed to read clipboard contents: ', err);
                    });
            } else if (key === "z") {
                if (shift_is_pressed) {
                    // redo
                    if (hasRedo()) {
                        redo();
                    }
                } else {
                    // undo
                    if (hasUndo()) {
                        undo();
                    }
                }
            }
            return;
        }

        if (shift_is_pressed && shift_keys.includes(key)) {
            let new_row_selection = {...rowSelection};
            let new_cell_selection = ``;
            if (key === "ArrowUp") {
                // select the current row and if there is a row above, select that one too
                e.preventDefault();
                const new_row_selection = {...rowSelection};
                // need to select rows by their id

                new_row_selection[rows[row_index].id] = true;
                if (row_index > 0) {
                    new_row_selection[rows[row_index - 1].id] = true;
                    // also select the cell above
                    new_cell_selection = `${row_index - 1}-${col_index}`;
                }
                setRowSelection(new_row_selection);
                setSelectedCell(new_cell_selection);
            } else if (key === "ArrowDown") {
                // select the current row and if there is a row below, select that one too
                e.preventDefault();
                const new_row_selection = {...rowSelection};

                new_row_selection[rows[row_index].id] = true;
                if (row_index < rows.length - 1) {
                    new_row_selection[rows[row_index + 1].id] = true;
                    // also select the cell below
                    new_cell_selection = `${row_index + 1}-${col_index}`;
                }
                setRowSelection(new_row_selection);
                setSelectedCell(new_cell_selection);
            }
            return;
        }

        if (cmd_is_pressed) {
            return;
        }

        switch (key) {
            case "ArrowUp":
                // Move up a row, subtract num cols from index
                // check if can move up
                e.preventDefault();
                if (row_index > 0) {
                    setCharacter(null);
                    setSelectedCell(`${row_index - 1}-${col_index}`);
                }
                break;
            case "ArrowDown":
                // Move down a row, add num cols to index
                // check if can move down
                e.preventDefault();
                if (row_index < rows.length - 1) {
                    setCharacter(null);
                    setSelectedCell(`${row_index + 1}-${col_index}`);
                }
                break;
            case "Tab":
                // Move one col right, add one
                // check if can move right
                e.preventDefault();
                if (col_index < columns.length - 2) {
                    setCharacter("Tab");
                }
                break;
            case "ArrowRight":
                // Move one col right, add one
                // check if can move right
                e.preventDefault();
                if (col_index < columns.length - 2) {
                    setSelectedCell(`${row_index}-${col_index + 1}`);
                }
                break;
            case "ArrowLeft":
                // Move one col left, subtract one
                // check if can move left
                e.preventDefault();
                if (col_index > 1) {
                    setCharacter(null);
                    setSelectedCell(`${row_index}-${col_index - 1}`);
                }
                break;
            case "Enter":
                // Start editing
                // go down a row if we can
                e.preventDefault();
                if (row_index < rows.length - 1) {
                    setCharacter("Enter");
                }
                break;
            case "Escape":
                // Stop navigating
                e.preventDefault();
                setCharacter(null);
                setSelectedCell(null);
                break;
            case "Shift":
            case "Control":
            case "Alt":
            case "Meta":
            case "CapsLock":
            case "Home":
            case "End":
                break;
            default:
                const is_letter_or_number = key.match(/^[a-zA-Z0-9]+$/);
                if (is_letter_or_number) {
                    setCharacter(key);
                }
                break;
        }
    }

    const handleKeyDown = useCallback(
        (e) => {
            if (selected_cell) {
                const {key} = e;

                // check if is in input
                const element = e.target.closest(".editable-cell-wrapper");
                if (element) {
                    return;
                }

                handleKeyPress(e, key, selected_cell);
            }
        },
        [selected_cell, columns.length, rows.length, hasUndo, hasRedo]
    );

    const handleMouseDown = useCallback(
        (e) => {
            if (tableContainerRef.current && !tableContainerRef.current.contains(e.target) && selected_cell) {
                // if the user clicks inside a selected cell, don't clear the selection
                // check if is in div with classname editable-cell-wrapper
                const element = e.target.closest(".editable-cell-wrapper");
                const portal = e.target.closest(".cell-portal");
                if (!element && !portal) {
                    setSelectedCell(null);
                }
            }
        },
        [tableContainerRef, selected_cell]
    );

    useEffect(() => {
        window.addEventListener("mousedown", handleMouseDown);
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("mousedown", handleMouseDown);
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    return [selected_cell, setSelectedCell, handleKeyPress, character, setCharacter]
}