import React from "react";
import {CreateMembers} from "../members/create";

export function CreateMember({onClose,data}) {

    return <div className="" style={{minHeight: '16rem'}}>
        <CreateMembers space_id={data?.space_id} onClose={onClose} onConfirmed={(member_ids) => {
            onClose(member_ids)
        }} flow={data?.flow||"generic"} initial={[]}/>
    </div>
}