import React, {useEffect, useRef, useState} from 'react';
import {useCommunity} from "../../../../config/community";
import {convertFromRaw, convertToRaw} from "draft-js";
import {ViewDraftJsContent} from "../../../../features/forum/utilities/view-content";
import {
    MessageHeader
} from "../index";
import {DraftComposer} from "./draft-composer";
import Button from "../../../../m3/_legacy_components/button";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../../../config/setup-firestore";
import {InlineLoader} from "../../../../m3/_legacy_components/admin-activity";
import dayjs from "dayjs";
import {MessageReactionsActionsWrapper, updateReactionData} from "../reactions/actions-reactions-wrapper";
import {api_getPostReplies} from "../../../../../api/messages/get-post-replies";
import {messages_deleteReply} from "../../../../../api/messages/delete";
import {api_editReply} from "../../../../../api/messages/edit-reply";
import {utils_dates_format} from "../../../../../common/utilities/dates";


function EditReply(props) {
    const community = useCommunity();
    const [state, setState] = useState("");
    const [editorState, setEditorState] = useState(null);
    const [attachments, setAttachments] = useState([]);

    function handleSave() {
        setState("saving");
        const raw = convertToRaw(editorState.getCurrentContent());
        api_editReply(community, {
            raw,
            reply_id: props.reply_id,
            post_id: props.post_id
        })
            .then((r) => {
                props.onSuccess({
                    raw
                });
            })
    }

    function handleCancel() {
        props.onCancel();
    }

    return <div className="flex flex-col gap-2">
        <div className="border border-blue-400 shadow-sm bg-white mt-1 rounded-md px-2 py-1">
            <DraftComposer content_state={convertFromRaw(props.raw)} auto_focus show_toolbar={false} type="reply"
                           update={(es, att) => {
                               setEditorState(es);
                               setAttachments(att);
                           }}/>
        </div>
        <div className="flex">
            <div className="flex-grow"></div>
            <div className="flex gap-2">
                <Button onClick={handleCancel} text="Cancel"/>
                <Button onClick={handleSave} loading={state === 'saving'} intent="success" text="Save"/>
            </div>
        </div>
    </div>
}

async function getReplyData(community_uid, post_id, reply_id) {
    let aref = doc(db, 'community_content', community_uid, 'posts', post_id, 'replies', reply_id);

    const doc_data = await getDoc(aref);

    const dt = doc_data.data();

    return dt;
}

function getDayJSTimeAgo(date) {
    let djs = dayjs.unix(date / 1000);
    return djs.fromNow();
}

export function EditedMessage({data}) {
    return <div className="text-gray-500 text-xs">
        Edited {getDayJSTimeAgo(data.edited_at)}
    </div>
}

export function MessageReply(props) {
    const community = useCommunity();
    const [data, setData] = useState(props);
    const [state, setState] = useState("");
    const [loading, setLoading] = useState(false);
    const [thread, setThread] = useState(null);
    const [page, setPage] = useState(0);
    const [last_page_ref, setLastPageRef] = useState(null);
    const [can_load_more, setCanLoadMore] = useState(false);

    const can_edit = props.author_id === community.member_id;

    const can_manage = community.is_admin || can_edit;

    const current_data = useRef(data);

    useEffect(function () {
        current_data.current = data;
    }, [data]);

    function updateReplyThreads() {
        getReplyData(community.uid, props.post_id, props.id)
            .then((r) => {
                if (r) {
                    setData(r);
                    getThreadReplies();
                }
            })
    }

    useEffect(function () {
        // replies_count
        if (!thread) {
            setThread([]);
            getThreadReplies()
        }
    }, [thread, props.replies_count, props.id])

    function loadReplies() {
        setLoading(true);
        getThreadReplies();
    }

    function getThreadReplies() {

        if(!props.replies_count || props.replies_count === 0) return;

        if(props.replies_count>2&&!can_load_more) {
            setCanLoadMore(true);
            return;
        }

        api_getPostReplies(community.id, props.post_id, props.id, last_page_ref, page, 50)
            .then(result => {
                setThread(result.replies);
                setLastPageRef(result.last_ref);
                setLoading(false);
                setCanLoadMore(false);
            })
    }

    function handleDeleteReply() {
        const group_id = data.group_id;
        messages_deleteReply(community, {
            post_id: data.post_id,
            group_id,
            reply_id: data.id
        })
            .then(() => {
                if (props.onDeleteReply) {
                    props.onDeleteReply(data.id)
                }
            })
    }

    function updatePostReactions({reaction_id, action}) {
        const {
            new_reactions,
            new_reactions_counts,
            new_reactions_members,
            new_reactions_order
        } = updateReactionData(reaction_id, action, current_data.current, community);

        const new_object = {
            reactions: new_reactions,
            reactions_counts: new_reactions_counts,
            reactions_members: new_reactions_members,
            reactions_order: new_reactions_order
        };

        setData({
            ...data,
            ...new_object
        })
    }

    let content_state = data.raw ? convertFromRaw(data.raw) : null;
    const dt = utils_dates_format(data.created_at);

    let options = [];

    if (can_edit) {
        options.push({
            label: 'Edit Reply',
            onClick: () => {
                setState("edit")
            }
        })
    }

    if (can_manage) {
        options.push({
            label: 'Delete Reply',
            onClick: () => {
                if (window.confirm("Are you sure you want to delete this reply?")) {
                    handleDeleteReply();
                }
            }
        });
    }

    const margin_top = props.inline ? "-mt-1" : "-mt-3";
    const padding_left = props.inline ? "pl-7" : "pl-8";

    const px = props.inline ? "pl-2" : "";

    const reverse_sorted_threads = thread ? thread.slice().reverse() : [];

    const bg_color = state === "edit" ? "bg-yellow-100" : "bg-white";

    return <div id={`post-reply-${props.id}`} className={`py-2 ${px} ${bg_color}`}>
        <div className="rounded-lg">
            <div className="">
                <MessageHeader size={props.inline ? "small" : "medium"} options={options} context={"reply"}
                               date={dt.relative} top_date={dt.relative}
                               flags={props.flags || {}}
                               member_id={community.member_id || ""}
                               author_id={props?.author_id || ""}
                               author_image={props?.author_image || ""}
                               author_name={props?.author_name || ""}/>
                <div className={`${padding_left} ${margin_top}`}>
                    {content_state && state !== 'edit' &&
                        <ViewDraftJsContent style={{}} classes='prose prose-sm text-gray-800'
                                            content_state={content_state}/>}
                    {state === 'edit' && <div>
                        <EditReply
                            raw={data.raw}
                            post_id={data.post_id}
                            reply_id={data.id}
                            onSuccess={(resp) => {
                                if (resp && resp.raw) {
                                    setData({
                                        ...data,
                                        raw: resp.raw
                                    })
                                }
                                setState("");
                            }}
                            onCancel={() => {
                                setState("");
                            }}/>
                    </div>}
                </div>
                {data.edited_at&&<div className={`${padding_left} pt-1 pb-0.5`}><EditedMessage data={data} /></div>}
                <div className={`${padding_left} pt-1`}>
                    <MessageReactionsActionsWrapper pt="pt-px" show_reply_count={false} text_size="text-xs" onReply={()=>{
                        if(props.onStartReply) {
                            props.onStartReply({
                                post_id: data.post_id,
                                reply_to_id: props.override_reply_to_id||data.id,
                                thread_id: props.thread_id||"",
                                focus: true,
                                reply_to_name: props.author_name||"",
                                type: "",
                                onSendReply: () => {
                                    updateReplyThreads()
                                }
                            })
                        } else {
                            setState("reply")
                        }
                    }} getUpdatedData={()=>current_data.current} post_id={data.post_id} reply_id={data.id} updatePostReactions={updatePostReactions} data={data} />
                </div>
            </div>
        </div>
        {thread && thread.length > 0 && <div className="pl-6 pt-2">{reverse_sorted_threads.map(thread => {
            return <MessageReply flags={props.flags || {}} onStartReply={props.onStartReply} key={thread.id} thread_id={thread.id} override_reply_to_id={props.id} inline {...thread} />
        })}</div>}
        {can_load_more&&!loading&&<div className="pt-2 pl-8">
            <div onClick={loadReplies} className="text-xs cursor-pointer flex hover:text-gray-700 items-center gap-2 text-gray-500 font-medium">
                <span className="inline-block h-0.5 w-5 bg-gray-200" />
            <span>Load {props.replies_count} replies</span>
        </div>
        </div>}
        {loading&&<div className="pt-2 pl-8">
            <InlineLoader mini />
        </div>}
    </div>
}