import dayjs from "dayjs";
import {data_months_map} from "../data/data/months";

export function formatBirthday(input, show_year, empty = "") {
    if (!input) {
        return empty
    }

    const {date, month, year} = input;

    if (year && !date && month === null) {
        return `${year}`;
    } else if (!year && date && month) {
        const mi = typeof month === 'string' ? (parseInt(month) + 1) : month + 1;
        return `${data_months_map[mi].label} ${date}`;
    } else if (year && month && !date) {
        const mi = typeof month === 'string' ? (parseInt(month) + 1) : month + 1;
        return `${data_months_map[mi].label} ${year}`;
    } else if (month === null && date && year) {
        return `${year}`;
    } else if (!date && month === null && !year) {
        return empty;
    } else if (!year) {
        return empty;
    }
    const format = show_year ? 'MMMM D, YYYY' : 'MMMM Do';
    return dayjs().year(year).month(month).date(date).format(format);
}