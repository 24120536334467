import React, {useEffect, useRef, useState} from "react";
import debounce from "lodash/debounce";
import {ACItem, ACSuggestionsContainer, ACWrapper} from "./shared";
import {Avatar} from "../avatar";
import {
    BoltIcon,
    DocumentIcon, FolderIcon,
    MagnifyingGlassIcon,
    PlusIcon,
    UsersIcon,
    XCircleIcon
} from "@heroicons/react/24/outline";
import {FrameLoader} from "../app-frame/loader";
import ReactDOM from "react-dom";

const DEBOUNCE = 200;

const demo_items = [
    [
        {
            title: "Associations"
        },
        {
            label: "Sandbox",
            id: "community-sandbox",
            image: null
        }
    ],
    [
        {
            title: "People"
        },
        {
            label: "Adam Smith",
            id: "user-asmith",
            image: ""
        },
        {
            label: "John Keynes",
            id: "user-jkeynes",
            image: ""
        }
    ],
];


const searchFun = async (
    searchFn,
    queryParam,
    setResults,
    setIsLoading,
    setActive,
    setMetadata
) => {
    searchFn(queryParam)
        .then((resp) => {
            let it;
            if (resp.items) {
                it = [...resp.items];
            } else if (resp) {
                it = [...resp];
            }
            setIsLoading(false);
            setMetadata(resp.metadata || []);
            setResults(it);
            setActive(0);
        })
};

const debouncedSearch = debounce(searchFun, DEBOUNCE);

function DirectSearch({layout, label}) {
    const fontsize = layout === "large" ? "text-base" : "text-sm";
    return <>
        <div className="w-10 flex justify-center items-center">
            <div className="h-5 w-5 text-gray-600">
                <MagnifyingGlassIcon/>
            </div>
        </div>
        <div>
            <div className={`${fontsize}`}>{label}</div>
        </div>
    </>
}

export const search_type_icons = {
    'file': <DocumentIcon/>,
    'folder': <FolderIcon/>,
    'plus': <PlusIcon/>,
    'group': <UsersIcon/>,
    'role': <BoltIcon/>,
};

function Item({label, sublabel, disabled, note, icon, layout, image}) {
    const size = layout === "large" ? "h-10 w-10" : "h-5 w-5";
    const fontsize = layout === "large" ? "text-base" : "text-sm";
    return <div className={`flex w-full`}>
        <div className="flex-grow flex space-x-3">
            {image && <div className="">
                <Avatar url={image} size={size}/>
            </div>}
            {icon && <div className="w-10 flex justify-center">
                <div className="h-svg-15 text-gray-500">
                    {search_type_icons[icon]}
                </div>
            </div>}
            <div>
                <div className={`${fontsize} font-semibold -mt-px`}>{label}</div>
                {sublabel && <div className={`text-sm -mt-1 pt-px opacity-70`}>{sublabel}</div>}
            </div>
        </div>
        <div className="flex items-center">
            {note && <div className="text-xs text-gray-500">
                {note}
            </div>}
        </div>
    </div>
}

function Demo() {
    return <div>
        <ACSuggestionsContainer>
            <ACWrapper>
                {demo_items.map((item, index) => <ACItem key={item.label} active={index === 0}>
                    <Item {...item} />
                </ACItem>)}
            </ACWrapper>
        </ACSuggestionsContainer>
    </div>
}

const getFlattenedResults = (results, has_categories, onDirectSearch) => {
    const cats = has_categories ? results.length : 0;
    let flattened_items = results.flat();
    flattened_items.forEach((it, ind) => {
        if (it.title) {
            flattened_items.splice(ind, 1);
        }
    })
    return flattened_items;
}

function LoadingInline() {
    return <div className="px-3 justify-center flex py-3 text-gray-500">
        <div className="w-10 h-5 flex items-center justify-center">
            <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                 fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                        strokeWidth="4"/>
                <path className="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
            </svg>
        </div>
        <div className=" font-medium text-sm">Loading..</div>
    </div>
}

/*
TODO
- todo proper display: max height, items
- todo no autosselect index
- todo on enter handling
 */

function FakeProgress() {
    const [p, setP] = useState(0.1);

    useEffect(function () {
        if (p < 1) {
            setTimeout(function () {
                setP(p + 0.1);
            }, 350 * p)
        }
    }, [p])

    return <FrameLoader progress={p} inline/>
}

function buildFinalItems(results, query, onDirectSearch) {
    const can_direct = query && !!onDirectSearch;
    let f = [];
    if (can_direct) {
        f.push({
            key: 'direct-search',
            label: query,
            icon: 'search'
        })
    }
    f = f.concat(results);
    return f;
}

const Wrapper = ({children, rect}) => {
    return <div className="absolute z-9999 w-auto" style={{
        top: `${rect.top + rect.height}px`,
        left: `${rect.left}px`,
        minWidth: '16rem',
        maxWidth: '28rem',
        width: '100%',
        right: `auto`
    }}>
        {children}
    </div>
}

function ResultsBoxPortal({children, _ref}) {
    const rect = _ref.current.getBoundingClientRect();

    return ReactDOM.createPortal(
        <Wrapper rect={rect}>
            {children}
        </Wrapper>,
        document.body
    );
}

export function renderSearchResults(has_categories, handleClick, flattened_results, final_data, active, results, query, onDirectSearch = () => {
}, layout = "small") {
    if (has_categories) {
        let counts = {};
        return <div className="space-y-1.5">
            {final_data.map((cat, ind) => {
                return <div className="pt-1" key={`${cat[0].title}-${ind}`}>
                    <div className={"px-4 text-base font-medium text-gray-800 mb-1"}>{cat[0].title}</div>
                    <div>
                        {cat.slice(1, cat.length).map((result, index) => {
                            const item_index = flattened_results.findIndex(it => it.id === result.id);
                            const rendered = result.key === "direct-search" ?
                                <DirectSearch layout={layout} {...result} /> : <Item layout={layout} {...result} />;
                            return <ACItem layout={layout} disabled={!!result.disabled} onClick={() => {
                                handleClick(item_index)
                            }} active={active === item_index}
                                           key={`${result.id}-${index}`}>
                                {rendered}
                            </ACItem>
                        })}
                    </div>
                </div>
            })}
        </div>
    }
    return final_data.map((result, index) => {
        const rendered = result.key === "direct-search" ? <DirectSearch layout={layout} {...result} /> :
            <Item layout={layout} {...result} />;
        return <ACItem layout={layout} disabled={!!result.disabled} onClick={() => handleClick(index)}
                       active={active === index}
                       key={`${result.id}-${index}`}>
            {rendered}
        </ACItem>
    })
}

export function handleSearchClick(index, onClick = () => {
}, onDirectSearch = () => {
}, flattened_results, setQuery = () => {
}, setShowSuggestions = () => {
}, setFocus = () => {
}, setActive = ()=>{}, input_ref, reset_on_click) {
    setActive(0);
    const result_data = flattened_results[index];

    if (!result_data) {
        return;
    }

    if (result_data.disabled) {
        return;
    }
    if (result_data.key === "direct-search") {
        onDirectSearch(flattened_results[index].label);
        setQuery("");
        setShowSuggestions(false);
        setFocus(false);
    } else {
        onClick(flattened_results[index]);
        if (reset_on_click) {
            setQuery("");
        } else {
            setQuery(flattened_results[index].label);
        }

        setShowSuggestions(false);
        setFocus(false);
    }
    if (input_ref && document.activeElement === input_ref.current) {
        input_ref.current.blur()
    }
}

export function AsyncSuggestions({
                                     portal,
                                     external_focus,
                                     demo,
                                     onBlur,
                                     stopSearchFocus,
                                     show_no_results = true,
                                     onClick,
                                     layout = "small",
                                     clearable,
                                     reset_on_click = false,
                                     default_options,
                                     onDirectSearch,
                                     show_menu_focus,
                                     handleSearch = async () => {
                                         return {
                                             items: [],
                                             metadata: []
                                         }
                                     },
                                     value,
                                     icon,
                                     placeholder = "Search",
                                     input_classes = ""
                                 }) {
    const [active, setActive] = useState(0);
    const [results, setResults] = useState([]);
    const [metadata, setMetadata] = useState([]);
    const [query, setQuery] = useState(value || "");
    const [hovering, setHovering] = useState(false);
    const [show_suggestions, setShowSuggestions] = useState(false);
    const [focus, setFocus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const input_ref = useRef(null);

    useEffect(function () {
        if (external_focus && !focus) {
            input_ref.current.focus();
        }
    }, [external_focus])

    const final_data = buildFinalItems(!query && default_options ? default_options : results, query, onDirectSearch);

    const has_categories = final_data[0] && Array.isArray(final_data[0]);
    let flattened_results = getFlattenedResults(final_data, has_categories);

    const onSearch = (v) => {
        const search = debouncedSearch;
        setQuery(v);
        if (!v) {
            // when the user clear the field we don't want to perform a search, we need to clear the state and do nothing else
            debouncedSearch.cancel();
            setResults([]);
            setIsLoading(false);
            setShowSuggestions(false);
        } else {
            setIsLoading(true);
            setShowSuggestions(true);
            search(handleSearch, v, setResults, setIsLoading, setActive, setMetadata);
        }
    };

    function handleClick(index) {

        handleSearchClick(index, onClick, onDirectSearch, flattened_results, setQuery, setShowSuggestions, setFocus, setActive, input_ref, reset_on_click)
    }

    function onKeyDown(e) {
        // User pressed the enter key
        if (e.keyCode === 13) {
            handleClick(active)
        } else if (e.keyCode === 38) {
            e.preventDefault();
            if (active === 0) {
                return;
            }

            setActive(active - 1);
        } else if (e.keyCode === 40) {
            if (active + 1 === flattened_results.length) {
                return;
            }

            setActive(active + 1);
        }
    }

    function onClear() {
        setQuery("");
        setFocus(false);
    }

    function renderResultsBox() {
        const a = <div onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}
                       style={{maxHeight: '40vh'}}
                       className="absolute z-10 overflow-y-auto border border-gray-200 shadow-lg bg-white my-1.5 rounded-md overflow-x-hidden left-0 right-0">
            <div className="relative">
                {isLoading && <FakeProgress/>}
                {isLoading && !final_data.length && (
                    <LoadingInline/>
                )}
                {!isLoading && !final_data.length && <div className="p-4 text-sm text-gray-600">
                    No results
                </div>}
                {!!final_data.length && <ACSuggestionsContainer>
                    <ACWrapper>
                        {renderSearchResults(has_categories, handleClick, flattened_results, final_data, active, results, query, onDirectSearch, layout)}
                    </ACWrapper>
                </ACSuggestionsContainer>}
            </div>
        </div>;
        if (portal) {
            return <ResultsBoxPortal _ref={input_ref}>
                {a}
            </ResultsBoxPortal>
        }
        return a;
    }

    const focus_styles = "input-focus";

    const show_box = (show_menu_focus && focus && (show_no_results || final_data.length > 0)) || query.length > 0 && focus && flattened_results.length > 0 && show_suggestions;

    return (
        <div className="relative w-full">
            {icon}
            <input
                onFocus={() => setFocus(true)}
                value={query}
                onBlur={() => {
                    if (!query && !hovering) {
                        setFocus(false);
                    }
                    if (stopSearchFocus) {
                        stopSearchFocus();
                    }
                    if (onBlur) {
                        onBlur(query);
                    }
                }}
                onKeyDown={onKeyDown}
                ref={input_ref}
                className={`form-control outline-none ${focus_styles} ${input_classes}`}
                placeholder={placeholder}
                onChange={(e) => onSearch(e.target.value)}
            />
            {clearable && query &&
                <div onClick={() => onClear()} className="absolute cursor-pointer top-2 right-3 h-6 text-gray-600 w-6">
                    <XCircleIcon/>
                </div>}
            {show_box && renderResultsBox()}
            {demo && <Demo/>}
        </div>
    );
}