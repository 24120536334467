import React, {useEffect, useState} from "react";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/24/outline";
function getBlockStyles(selected,success,disabled) {
    if(selected) {
        return success?'border-success bg-success':'border-selection bg-selection';
    } else if(success) {
        return 'border-success';
    } else if(disabled) {
        return 'border-gray-300 bg-gray-200';
    } else {
        return 'border-gray-200';
    }
}

function getIconStyle(selected,success,disabled) {
    if(selected) {
        return 'text-white';
    } else if(success) {
        return 'text-success';
    } else if(disabled) {
        return 'text-gray-600';
    } else {
        return 'text-gray-800';
    }
}

function getLabelStyle(selected,success,disabled) {
    if(selected) {
        return 'text-white';
    } else if(success) {
        return 'text-success';
    } else if(disabled) {
        return 'text-gray-600';
    } else {
        return 'text-gray-800';
    }
}

function getBlockIcon(selected,success,disabled) {
    if(selected) {
        return null;
    } else if(success) {
        return <CheckCircleIcon />;
    } else if(disabled) {
        return <XCircleIcon/>;
    } else {
        return selected?null:<div className="border-2 rounded-full h-4 w-4 border-gray-200" />;
    }
}

function MiniBlock({selected = false, meta, id="", onSelect=()=>{}, success = false, disabled = false, label = "Block name"}) {
    return <div onClick={()=>{
       onSelect(id)
    }} className={`relative transition-colors transition-opacity hover:opacity-80 cursor-pointer border-2 ${getBlockStyles(selected,success,disabled)} flex-none rounded-md px-2 py-1 w-32 h-14`}>
        <div className={`text-sm pr-2 truncate whitespace-nowrap font-medium ${getLabelStyle(selected,success,disabled)}`}>{label}</div>
        {meta&&<div className={`absolute bottom-1.5 left-1.5 ${getLabelStyle(selected,success,disabled)}`}>
            {meta}
        </div>}
        <div className={`absolute bottom-0.5 right-0.5 ${getIconStyle(selected, success, disabled)}`}>
            <div className="h-5 w-5">
                {getBlockIcon(selected,success,disabled)}
            </div>
        </div>
    </div>
}

const mock_blocks = [
    {label:"Account Email",id:'account_email'},
    {label:"Member ID",id:'id'},
    {label:"Phone",id:'phone',meta:<span></span>},
    {label:"City",id:'city'},
    {label:"Website",id:'website'},
    {label:"Birthday",id:'birthday'}
];

export function MiniBlockSelector({id="mini-block-selector",selected,onSelectBlock=()=>{},success=['id'],disabled=['website'],blocks=mock_blocks}) {
    const [active,setActive] = useState(selected?selected:blocks[0].id);
    useEffect(function () {
        setActive(selected);
    }, [selected]);
    function handleSelect(id) {
        setActive(id);
    }
    useEffect(function() {
        if(active) {
            onSelectBlock(active)
        }
    }, [active]);
    return <div id={id} className="flex space-x-2 overflow-x-auto hide-scrollbar">
        {blocks.map(b=><MiniBlock disabled={disabled.includes(b.id)} success={success.includes(b.id)} onSelect={handleSelect} selected={active===b.id} key={b.id} {...b} />)}
    </div>
}