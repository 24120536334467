import React, {useEffect, useRef, useState} from 'react';
import {getDirectoryData} from "../../../../../m3/_legacy_components/directory/layouts/gallery";
import {useCommunity} from "../../../../../config/community";
import {InlineLoader} from "../../../../../m3/_legacy_components/admin-activity";
import {buildEntityForList} from "./organizers";
import EntityList from "../../../../../m3/_legacy_components/entity-list";
import {LocalSearchBar} from "../../../../../m3/_legacy_components/local-search-bar";
import Button from "../../../../../m3/_legacy_components/button";
import {UserPlusIcon} from "@heroicons/react/24/outline";

function buildListItems(m = [], community, group, flags, fns={}, removed, removeItem) {
    let a = [];

    m.forEach(member => {
        let le = buildEntityForList("member", member, community, group);
        const is_member = group?.user_uids[member.id];
        const is_organizer = !!group?.moderators?.user_uids[member.id];
        const avr = is_member&&!group?.entities?.members[member.id]
        if(!removed[`member-${member.id}`]) {
            le.actions.push({
                type: "group-member-more",
                actions: {
                    makeMemberOrganizer: ()=>fns.makeMemberOrganizer(member.id,"member",member,()=>{}),
                    removeEntityFromGroup: ()=> {
                        removeItem('member',member.id);
                        fns.removeEntityFromGroup(member.id, 'member', member, () => {})
                    }
                },
                metadata: {
                    can_make_organizer: !is_organizer,
                    can_remove: !is_organizer&&!avr,
                    is_organizer,
                    is_admin: community.is_admin,
                    is_member,
                    added_via_rule: avr
                }
            })
            a.push(le);
        }
    })

    return a;
}

function AddMember({onClick}) {
    return <div className="flex px-2 -mx-2 rounded-lg hover:bg-gray-100 py-0.5 cursor-pointer space-x-2.5" onClick={onClick}>
        <div className="p-0.5">
            <div className="h-10 w-10 flex items-center justify-center rounded-xl bg-blue-50 text-blue-600">
                <div className="h-6 w-6">
                    <UserPlusIcon />
                </div>
            </div>
        </div>
        <div className={`flex items-center text-base font-medium text-gray-700`}>
            Add member
        </div>
    </div>
}

const mergeArrays = (arr1 = [], arr2 = []) => {
    return [
        ...[...arr1, ...arr2]
            .reduce(
                (acc, curr) => acc.set(curr.id, {...acc.get(curr.id), ...curr}),
                new Map()
            )
            .values(),
    ];
};

export function GMMMembers({data, fns, flags, id, onAddMember}) {
    const page_size = 25;
    const community = useCommunity();
    const [removed,setRemoved] = useState({});
    const [state, setState] = useState("loading");
    const [members, setMembers] = useState([]);
    const start_after = useRef(null);
    const [query, setQuery] = useState("");
    const _results = useRef([]);
    const has_more = useRef(false);
    const query_id = useRef("");

    useEffect(function () {
        if (members.length === 0 && !query) {
            getMembers()
        }
    }, [data.user_uids, members, query])

    function getMembers() {
        const view = {};
        const defs = {};

        getDirectoryData(community.id, view, defs, _results.current.length, page_size, start_after.current, id, query, [], [
            {
                field: 'about.last_name',
                dir: 'asc'
            }
        ])
            .then(arr => {
                const dts = arr.map(d => {
                    return {id: d.id, ...d.data()}
                })

                setState("");
                has_more.current = arr.length === page_size;
                if (arr.length > 0) {
                    start_after.current = arr[arr.length - 1];
                }
                const nr = mergeArrays(_results.current, dts);
                _results.current = nr;
                setMembers(nr)
            })
    }

    function startSearch() {
        setState("searching");
        _results.current = [];
        setMembers([]);
        getMembers();
    }

    function handleNextPage() {
        setState("searching");
        if (members.length > 0 && !has_more.current) {
            return;
        }
        getMembers()
    }

    function removeItem(type,itid) {
        setRemoved({
            ...removed,
            [`${type}-${itid}`]: true
        })
    }

    function resetSearch() {
        setQuery("");
        setState("searching");
        _results.current = [];
        setMembers([]);
        getMembers();
    }

    const items = buildListItems(members, community, data, flags, fns, removed, removeItem);

    const showing_search_results = state!=="loading"&&state!=="searching"&&query;

    return <div className="pt-3 pb-8">
        <div>
            <div>
                <LocalSearchBar clearResults={() => {
                    resetSearch();
                }} loading={state === "searching"} onSearchSubmit={() => {
                    startSearch();
                }} query={query} onChange={q => {
                    setQuery(q);
                    setState("typing");
                }}/>
            </div>
            <div className="pt-3">
                {state!=="loading"&&state!=="searching"&&!showing_search_results&&<AddMember onClick={() => onAddMember()} />}
                {state==="loading"&&<div className="flex justify-center items-center">
                    <InlineLoader padding="p-8"/>
                </div>}
                {(state!=="loading"&&state!=="searching")&&query&&items.length===0&&<div className="text-sm text-gray-600 p-4 flex justify-center">
                    No results found
                </div>}
                {state!=="loading"&&<div className="-mx-4"><EntityList interactive minimal items={items}
                                                     community_uid={community.id}/></div>}
                {items.length === 0 && state === "searching" && <div className="flex justify-center items-center">
                    <InlineLoader padding="p-4"/>
                </div>}
                <div className="h-8">
                    {has_more.current && !state && <div className="p-2 flex justify-center">
                        <Button text="Load More" onClick={() => handleNextPage()} />
                    </div>}
                </div>
            </div>

        </div>
    </div>
}