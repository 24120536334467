import React, {useState} from "react";
import {externalAuthFetch} from "../../../../api/network";
import {useNavigate} from "react-router-dom";
import M3_A_BlankSlate from "../../../m3/atoms/blank-slate";
import {opt_in_getCurrentMembershipPeriodID, opt_in_getMembershipPeriods} from "../../../features/gates/opt-in-utilities";
import {OptInPromptWrapper} from "../../../features/gates/opt-in";

async function api_override_optIn(slug, label, community_uid, member_id, token) {
    return new Promise(resolve => {

        const res = (resp) => {
            if (!resp || !resp.data) {
                return resolve({
                    ok: "no",
                    data: {}
                });
            }
            return resolve(resp.data);
        };

        // with this, you could see any communities policy given the slug...
        externalAuthFetch("/handle-update", res, res, "POST", {
            payload: {
                action: "opt-in",
                data: {
                    slug,
                    label
                },
                community_uid: community_uid,
                token,
                member_id
            }
        })
    })
}

export function ExternalOptInWrapper(props) {
    const {member_id, token, member, community} = props;
    const [status, setStatus] = useState("");
    const navigate = useNavigate();

    function handleUpdate() {
        setStatus("updated");
    }

    if (status === "updated") {
        return <M3_A_BlankSlate
            title={"You're Opted-in"}
            description={"You're all set, feel free to close this tab or sign in to access the Community."}
            button={{
                children: `Go to Sign In`,
                intent: "primary",
                onClick: () => {
                    navigate(`/c/${community.handle}/sign-in`);
                }
            }}
        />
    }

    // first check if opt-in is enabled
    if (!community?.preferences?.members?.enable_member_opt_in) {
        return <M3_A_BlankSlate
            title={`${community.name} hasn't enabled Opt-in`}
            description={"You're all set, feel free to close this tab or sign in to access the Community."}
            button={{
                children: `Go to Sign In`,
                intent: "primary",
                onClick: () => {
                    navigate(`/c/${community.handle}/sign-in`);
                }
            }}
        />
    }

    const opt_in_periods = opt_in_getMembershipPeriods(community);

    const current_period_id = opt_in_getCurrentMembershipPeriodID(community,opt_in_periods);

    if(!current_period_id||member?.opt_in?.[current_period_id]) {
        return <M3_A_BlankSlate
            title={"You're already opted-in"}
            description={"You're all set, feel free to close this tab or sign in to access the Community."}
            button={{
                children: `Go to Sign In`,
                intent: "primary",
                onClick: () => {
                    navigate(`/c/${community.handle}/sign-in`);
                }
            }}
        />
    }

    return <div>
        <M3_A_BlankSlate
            title={`Membership Opt-in`}
            description={"Review and opt-in for Community Membership."}
        />
        <OptInPromptWrapper handleOptInMembership={async (_slug, _label) => {
            return await api_override_optIn(_slug, _label, community.uid, member_id, token);
        }} community_uid={community.uid} member_id={member_id}
                           community_data={community} updateParent={handleUpdate} current_period_id={current_period_id} gate_data={{}} period_data={opt_in_periods.find(a=>{
            return a.id === current_period_id;
        })} />
    </div>
}