import React, {useEffect, useLayoutEffect, useReducer, useState} from "react";
import custom_css from "../styles/content-view.css";

/*
* This is the Content View atom
 */

const _props = {
    children: {
        type: "string",
        required: true
    },
    max_height: {
        type: "string",
        required: false,
        default: "400px"
    },
    padding: {
        type: "string",
        required: false,
        default: "p-4"
    },
    container_classes: {
        type: "string",
        required: false
    },
};

function setClasses(el) {
    const isScrollable = el.scrollHeight > el.clientHeight;
    // GUARD: If element is not scrollable, remove all classes
    if (!isScrollable) {
        el.classList.remove('is-bottom-overflowing', 'is-top-overflowing');
        return;
    }

    // Otherwise, the element is overflowing!
    // Now we just need to find out which direction it is overflowing to (can be both).
    // One pixel is added to the height to account for non-integer heights.
    const isScrolledToBottom = el.scrollHeight < el.clientHeight + el.scrollTop + 1;
    const isScrolledToTop = isScrolledToBottom ? false : el.scrollTop === 0;

    el.classList.toggle('is-bottom-overflowing', !isScrolledToBottom);
  //  el.classList.toggle('is-top-overflowing', !isScrolledToTop);
}

// todo make scrollable and also add padding to bottom and also add white bottom gradient for seamless look
export default function M3_A_Content_View({children, flex_grow, header, onScroll, container_classes, padding, id = "content-view-1", max_height = "60vh"}) {
    let style = {}, classes = "m3-content-view overflow-auto relative";
    const [is_scrollable, setIsScrollable] = useState(false);

    useEffect(() => {
        const el = document.getElementById(id);
        setClasses(el);
        el.addEventListener('scroll', () => setClasses(el));

        if(el && el.scrollHeight > el.clientHeight) {
            setIsScrollable(true);
        }

        return () => el.removeEventListener('scroll', () => setClasses(el));
    }, [id])

    if (max_height) style.maxHeight = max_height;

    // add a bottom padding of pb-8 to the content view
    classes += ` ${padding}`;

    if(container_classes) {
        classes += ` ${container_classes}`;
    }

    if(is_scrollable) {
        classes += " is-scrollable is-bottom-overflowing";
    }

    if(flex_grow) {
        classes += " flex-grow";
    }

    return (<div id={id} className={classes}>
            {header}
        <div className={`gap-4 flex flex-col `} style={style}>
            {children}
        </div>
        </div>
    );
}