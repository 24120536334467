import React from "react";
import M3_A_Button from "./button";
import M3_A_CounterLabel from "./counter-label";
import M3_A_Icon from "./icon";
import M3_A_Text from "./text";
import {m3_adjust_size} from "../utilities";

/*
* This is the IconButton atom
 */

const _props = {};

export default function M3_A_IconButton({
                                            disabled,
                                            icon,
                                            onClick,
                                            text,
                                            counter,
                                            intent,
                                            size = "base",
                                            sx,
                                            square,
                                            adjust_icon_size = !text && !counter ? -1 : -2,
                                            adjust_text_size = -1,
                                            icon_size,
                                            fluid,
                                            variant,
                                            dropdown,
                                            compact,
                                            switcher
                                        }) {
    const icon_only = !text && !counter;

    const _icon_size = m3_adjust_size(size, adjust_icon_size);

    const _text_size = m3_adjust_size(size, adjust_text_size);

    let inner_sx = {};

    return (
        <M3_A_Button dropdown={dropdown} disabled={disabled} fluid={fluid} square={square} inner_sx={inner_sx}
                     compact={compact} variant={variant} onClick={onClick} sx={sx} intent={intent}
                     size={size} switcher={switcher}>
            {icon && <div className={icon_only ? "" : "-ml-1 mr-0.5"}><M3_A_Icon size={icon_size || _icon_size}>
                {icon}
            </M3_A_Icon></div>}
            {text && <M3_A_Text size={_text_size}>
                {text}
            </M3_A_Text>}
            {counter && <div>
                <M3_A_CounterLabel>{counter}</M3_A_CounterLabel>
            </div>}
        </M3_A_Button>
    );
}