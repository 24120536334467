import React, {useState, useRef, useEffect} from 'react';
import './styles.css';
import {ThreadsView} from "./threads-view";
import {useCommunity} from "../../../config/community";
import {
    getGroupInboxPosts,
    getGroupPostsForTopic,
    getMemberInboxPosts,
    getPostsMetadata,
    transformPostsToMessages
} from "./api";
import {api_recordPostImpressions} from "../../../../api/posts/posts/record-impressions";
import {useNavigate} from "react-router-dom";
import EntityTabs from "../../../m3/_legacy_components/entity-tabs";
import {TextAction} from "../../../routes/auth/sign-in";


function Title({title}) {
    return <div className="text-lg font-bold">
        {title}
    </div>
}

function buildTabs(topics = []) {
    let a = [];

    a.push({
        label: "Latest",
        id: "latest",
        type: "latest"
    })

    topics.forEach(function (topic) {
        a.push({
            label: topic.name,
            id: topic.id,
            type: "custom_topic"
        })
    })

    return a;
}

export function buildComposerConfig(space_id, space_data, folder_id = "", write_widgets = [], start = () => {
}) {
    let to = space_id ? [
        {
            type: 'group',
            value: space_id,
            id: space_id,
            label: space_data?.name,
            emoji: space_data?.emoji || "",
            letter: space_data?.name?.charAt(0).toUpperCase(),
            email: space_data?.['power_ups']?.['unaty__mailing_lists']?.config?.email_address || "",
            count: Object.keys(space_data?.user_uids || {}).length
        }
    ] : [];

    return {
        to: to,
        config: {},
        content: '',
        subject: '',
        write_widgets,
        group_id: space_id,
        folder_id: "",
        drive_id: folder_id,
        type: 'post',
        template: '',
        closeCb: () => {
            start();
        }
    }
}

export function Forum({
                          topics,
                          write_widgets = [],
                          create_message,
                          per_page = 8,
                          id,
                          show_title = true,
                          group = {},
                          folder_id = "",
                          show_create,
                          title = "",
                          onChangeTab,
                          type = 'home'
                      }) {
    const community = useCommunity();
    const navigate = useNavigate();
    const [inbox, setInbox] = useState(null);
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(0);
    const has_more = useRef(false);
    const [isFetching, setIsFetching] = useState(false);
    const total_posts = useRef(0);
    const [last_page_ref, setLastPageRef] = useState(null);
    const tabs = buildTabs(topics);
    const [tab, setTab] = useState(tabs[0].id);

    useEffect(function () {
        start();
    }, [community.uid, type, id, community.member_id, tab])

    function start() {
        if (type === 'home') {
            getPostsMetadata(community.uid, `member-${community.member_id}`, tab)
                .then(ddata => {
                    if (ddata) {
                        setInbox(ddata);
                        total_posts.current = ddata.total_count;
                        if (page === 0) {
                            getPosts(ddata, 0, false, tab);
                        } else {
                            setLoading(false);
                        }
                    } else {
                        setInbox({
                            unread: 0,
                            total_count: 0,
                            last_activity: null
                        });
                        setLoading(false);
                    }
                })
        } else if (type === 'group' && id) {
            getPostsMetadata(community.uid, `group-${id}`, tab)
                .then(ddata => {
                    if (ddata) {
                        setInbox(ddata);
                        total_posts.current = ddata.total_count;
                        if (page === 0) {
                            getPosts(ddata, 0, false, tab);
                        }
                    } else {
                        setInbox({
                            unread: 0,
                            total_count: 0,
                            last_activity: null
                        });
                    }
                })
        }
    }

    function handleShowMore() {
        setPage(page + 1);
        setLoading(true);
        getPosts(inbox, page + 1, true, tab);
    }

    function getPosts(inb, pg, concat, tb) {
        if (type === 'home') {
            getMemberInboxPosts(community.uid, community.member_id, last_page_ref, pg, inb, per_page)
                .then(result => {
                    has_more.current = result.posts.length === per_page && (items.length + result.posts.length) < total_posts.current;
                    if (concat) {
                        setItems([...items].concat(transformPostsToMessages(result.posts, community.uid)));
                    } else {
                        setItems(transformPostsToMessages(result.posts, community.uid));
                    }
                    setLoading(false);
                    setLastPageRef(result.last_ref);
                    setIsFetching(false);
                    api_recordPostImpressions(community, result.posts.map(it => it.id)).then(() => {
                    });
                })
        } else if (type === 'group') {
            if (tb === "latest") {
                getGroupInboxPosts(community.uid, id, "", community.member_id, last_page_ref, pg, inb, per_page)
                    .then(result => {
                        has_more.current = result.posts.length === per_page && (items.length + result.posts.length) < total_posts.current;
                        if (concat) {
                            setItems([...items].concat(transformPostsToMessages(result.posts, community.uid)));
                        } else {
                            setItems(transformPostsToMessages(result.posts, community.uid));
                        }
                        setLoading(false);
                        setLastPageRef(result.last_ref);
                        setIsFetching(false);
                        api_recordPostImpressions(community, result.posts.map(it => it.id)).then(() => {
                        });
                    })
            } else {
                getGroupPostsForTopic(community.uid, id, tb, last_page_ref, pg, per_page)
                    .then(result => {
                        has_more.current = result.posts.length === per_page;
                        if (concat) {
                            setItems([...items].concat(transformPostsToMessages(result.posts, community.uid)));
                        } else {
                            setItems(transformPostsToMessages(result.posts, community.uid));
                        }
                        setLoading(false);
                        setLastPageRef(result.last_ref);
                        setIsFetching(false);
                        api_recordPostImpressions(community, result.posts.map(it => it.id)).then(() => {
                        });
                    })
            }
        }
    }

    function handleChangeTab(nt, tab_data) {
        if (tab_data.id === tab) {
            return;
        }
        const tab_index = tabs.findIndex(it => it.id === tab_data.id);
        setPage(0);
        setLastPageRef(null);
        setItems([]);
        setTab(tab_data.id);
    }

    function handleOpenAttachment(attachment, raw, idx) {
        community.setLightbox({
            context: "group",
            context_id: id,

            type: "post",
            id: idx,
            data: raw,

            starting_index: raw.attachments.findIndex(it => it.id === attachment.id),
            items: [
                ...raw.attachments
            ]
        });
    }

    function handleOpenImage(item, raw, idx) {
        community.setLightbox({
            context: "group",
            context_id: id,

            type: "post",
            id: idx,
            data: raw,

            overview: {
                back: {
                    label: "Post Photos"
                },
                title: "Post Photos"
            },

            starting_index: raw.images.findIndex(it => it.url === item.url),
            items: [
                ...raw.images || []
            ]
        });
    }

    let right_action = null;

    if (show_create) {
        const config = buildComposerConfig(id, group, folder_id, write_widgets, start);
        right_action = <TextAction text="New Post" onClick={() => community.openComposer(config)}/>
    } else if (create_message) {
        right_action = <div>
            {create_message}
        </div>
    }

    return <div>
        {show_title && <div className="px-4 sm:px-0">
            <Title title={title}/>
        </div>}
        <div className="pb-1 px-4">
            <EntityTabs active={tab} onChangeTab={handleChangeTab}
                        right_action={right_action} layout="minimal" tabs={tabs}/>
        </div>
        <ThreadsView features={{
            show_space: !id
        }} goToMember={author_id => {
            community.openPreviewOverlay({
                type: 'member',
                id: author_id
            }, "member")
        }} handleOpenImage={handleOpenImage} has_more={has_more.current} showMore={handleShowMore} goToItem={(it) => {
            community.openPreviewOverlay({
                type: 'post',
                id: it.id,
                data: it
            }, "post")
        }} handleOpenAttachment={handleOpenAttachment} items={items}
                     onChangeTab={onChangeTab} topics={topics} type={type} is_loading={loading}/>
    </div>
}