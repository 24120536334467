import React from 'react';
import {PencilIcon} from "@heroicons/react/20/solid";

function EditButton({onClick=()=>{}}) {
    return <div onClick={onClick} className={`w-8 border-3 border-white rounded-full flex items-center justify-center h-8 bg-blue-600 hover:bg-black transition-colors text-white`}>
        <div className="w-4 h-4">
        <PencilIcon />
        </div>
    </div>
}

export function Avatar({size = "w-6 h-6", edit, onClick, large, style, url, border}) {
    let s = {};
    const interactive = !!onClick;
    if (style) {
        s = {...style};
    }

    if (url) {
        s.backgroundImage = `url(${url})`;
    }
    return <div onClick={()=>{
        if(onClick) {
            onClick(url);
        }
    }} style={{borderRadius: '34%', ...s}}
                className={`${size} ${interactive?"cursor-pointer":""} group relative bg-center bg-cover ${border ? `${large?"border-4":"border-3"} border-white` : ""} ${url ? "bg-gray-100" : "bg-gray-300"}`}>

        <div style={{borderRadius:'34%'}} className={`bg-white ${interactive?"group-hover:bg-opacity-30":""} bg-opacity-0  transition-opacity pointer-events-none absolute top-0 right-0 left-0 bottom-0 rounded-md`}>
        </div>

        {edit&&<div className="absolute bottom-0 -right-1"><EditButton onClick={(e)=>{
            e.stopPropagation();
            edit(e);
        }} /></div>}


    </div>
}