import {
    CommunityIndexPage, PageLayoutBlock, PageLayoutSection
} from "../../../m3/_legacy_components/app-frame/layouts";

import {ArrowLeftOnRectangleIcon, GlobeAmericasIcon} from "@heroicons/react/24/outline/index";
import QuickActions from "../../../m3/_legacy_components/quick-actions";
import React from "react";
import {useNavigate} from "react-router-dom";
import {setDocumentTitle} from "../../../m3/utilities/set-document-title";
import {useCommunity} from "../../../config/community";
import {M3_C_MobileWrapper} from "../../../m3/layouts/mobile-wrapper";
import M3_E_MemberCard from "../../../m3/examples/member-card";


export function CommunityMenuPage() {
    const community = useCommunity();
    const navigate = useNavigate();

    setDocumentTitle(`More`, `${community.profile.name}`);

    const options = [
        {
            onClick: () => {
                navigate('/')
            },
            icon: <GlobeAmericasIcon/>,
            label: "My Memberships"
        },
        /*
        {
            onClick: () => {
                navigate(`/c/${community.domain}/member/${community.member.handle}`)
            },
            icon: <UserIcon/>,
            label: "View Profile"
        },
        {
            onClick: () => {
                navigate(`/c/${community.domain}/member/${community.member.handle}?edit`)
            },
            icon: <PencilIcon/>,
            label: "Edit Profile"
        },

         */
        {
            onClick: () => {
                navigate(`/c/${community.domain}/sign-out`)
            },
            icon: <ArrowLeftOnRectangleIcon/>,
            label: "Sign out"
        }
    ];

    return <CommunityIndexPage header={{
        title: "You",
        actions: []
    }}>
        <PageLayoutSection divider={false} gap="gap-4">
            <PageLayoutBlock>
                <M3_E_MemberCard theme={community.active_theme} onEditProfile={() => {
                    navigate(`/c/${community.domain}/member/${community.member.handle}?edit`)
                }} onViewProfile={() => {
                    navigate(`/c/${community.domain}/member/${community.member.handle}`)
                }} member={community.member} member_types={community.member_types}/>
            </PageLayoutBlock>
            <PageLayoutBlock>
                <QuickActions fill show_title={false} items={options}/>
            </PageLayoutBlock>
        </PageLayoutSection>
    </CommunityIndexPage>

    return <M3_C_MobileWrapper header={{
        title: "You",
        sticky: true,
        actions: []
    }} theme={community.active_theme}>
        <PageLayoutSection>
            <PageLayoutBlock>
                <M3_E_MemberCard theme={community.active_theme} onEditProfile={() => {
                    navigate(`/c/${community.domain}/member/${community.member.handle}?edit`)
                }} onViewProfile={() => {
                    navigate(`/c/${community.domain}/member/${community.member.handle}`)
                }} member={community.member} member_types={community.member_types}/>
            </PageLayoutBlock>
            <PageLayoutBlock>
                <QuickActions fill show_title={false} items={options}/>
            </PageLayoutBlock>
        </PageLayoutSection>
    </M3_C_MobileWrapper>
}