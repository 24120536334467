import React, {useEffect, useRef} from "react";
import M3_A_Heading from "../atoms/heading";
import M3_A_ContextBar from "../atoms/context-bar";
import styled from "styled-components";
import M3_A_Text from "../atoms/text";
import M3_A_SubnavBar from "../atoms/subnav-bar";
import M3_A_NodeSelector from "../atoms/node-selector";
import M3_A_IconButton from "../atoms/icon-button";
import {m3_adjust_size} from "../utilities";
import {m3_icon_map} from "../icons/icon-map";
import M3_A_Avatar from "../atoms/avatar";
import {UpdateCircle} from "../../routes/community/contacts/components";
import {useIsMobile} from "../hooks/is-mobile";
import M3_A_Button from "../atoms/button";
import {generateUniqueID} from "../../../common/utilities/general";
/*
* This is the Header component
* should support a title and a subtitle that use the heading and subheading atoms
 */

const _props = {
    title: {
        type: "string",
        required: true
    },
    subtitle: {
        type: "string",
        required: false
    },
    size: {
        type: "string",
        required: false,
        fallback: "h1",
        options: ["xl"]
    },
    skeleton: {
        type: "boolean",
        required: false,
        default: false
    },
    context_bar: {
        type: "node",
        required: false
    }
};

const heading_size_map = {
    "xl": 'h3',
    "lg": 'h4',
    "base": 'h5',
    "sm": 'h6',
};

const Wrapper = styled.div`
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid transparent;

    &:hover {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    ${props => props.is_scrolled_down && `
   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    `}
`;

function showScrollUpContent(scroll_id, scroll_guide_ref, sticky_header_ref, last_scroll_top_ref) {
    if (!scroll_id) {
        return false;
    }
    const el = document.getElementById(scroll_id);
    if (!el) {
        return false;
    }
    let scroll_snap_guide, sticky_header_guide;
    if (scroll_guide_ref) {
        scroll_snap_guide = document.getElementsByClassName(scroll_guide_ref)[0];
    }

    if (sticky_header_ref) {
        sticky_header_guide = document.getElementsByClassName(sticky_header_ref)[0];
    }

    const scroll_direction = el.scrollTop > last_scroll_top_ref ? "down" : "up";
    const sticky_header_height = sticky_header_guide ? sticky_header_guide.clientHeight : 0;
    const scroll_snap_guide_bottom = scroll_snap_guide ? scroll_snap_guide.getBoundingClientRect().bottom : 0;

    if (scroll_direction === "down" && scroll_snap_guide_bottom <= sticky_header_height) {
        return false;
    } else if (scroll_direction === "up" && scroll_snap_guide_bottom > sticky_header_height) {
        return false;
    } else if (scroll_direction === "up" && scroll_snap_guide_bottom <= sticky_header_height) {
        return true;
    } else {
        return false;
    }
}

function reachedScrollSnap(scroll_id, scroll_snap_px, sticky_header_ref, last_scroll_top_ref, is_mobile) {
    if (!scroll_id) {
        return false;
    }
    const el = is_mobile ? {
        scrollTop: window.pageYOffset
    } : document.getElementById(scroll_id);

    if (!el) {
        return false;
    }
    let sticky_header_guide;

    if (sticky_header_ref) {
        sticky_header_guide = document.getElementsByClassName(sticky_header_ref)[0];
    }

    const scroll_direction = el.scrollTop > last_scroll_top_ref ? "down" : "up";
    const sticky_header_height = sticky_header_guide ? sticky_header_guide.clientHeight : 0;

    return el.scrollTop > scroll_snap_px;
    /*
    if (scroll_direction === "down" && scroll_snap_guide_bottom <= sticky_header_height) {
        return true;
    } else if (scroll_direction === "up" && scroll_snap_guide_bottom > sticky_header_height) {
        return false;
    } else if (scroll_direction === "up" && scroll_snap_guide_bottom <= sticky_header_height) {
        return true;
    }

     */
}

function Background({url, color, bg_height}) {
    let style = {};

    if (url) {
        style.backgroundImage = `url(${url})`;
    } else if (color) {
        style.backgroundColor = color;
    }

    return <div style={{
        height: `${bg_height}`
    }} className={` md:p-1 min-h-40 absolute top-0 left-0 right-0`}>
        <div style={style} className={`bg-center md:rounded-xl bg-cover flex flex-col h-full`}>
            <div className="flex-grow">

            </div>
            <div className="h-2/3 flex-none md:rounded-b-xl bg-gradient-to-t from-gray-900"/>
        </div>
    </div>
}

function getBackgroundHeight() {
    const height = window.innerHeight;
    const width = window.innerWidth;
    // if screen has height <500px, return 30vh.
    if (height < 500) {
        return "28vh";
    }
    // if screen has height >800px AND width > 1600px return 40vh;
    if (height > 800 && width > 1600) {
        return "28vh";
    }
    // else return 22vh
    return "22vh";
}

const getSizeAdjustment = (size, is_mobile) => {
    if (is_mobile) {
        if (size === "xl") {
            return ["base", 0]
        } else if (size === "lg") {
            return ["base", 0]
        } else if (size === "base") {
            return ["base", 0]
        }
        return ["base", 0]
    }

    if (size === "xl") {
        return ["lg", 0];
    } else if (size === "lg") {
        return ["base", 0];
    } else if (size === "base") {
        return ["base", 0];
    } else {
        return ["sm", 0];
    }
}

const renderAction = ({
                          icon,
                          intent,
                          label,
                          show_update,
                          update_border_color,
                          type,
                          onClick
                      }, size, icon_variant, icon_sx, is_mobile) => {
    const [adjust_size, adjust_icon_size] = getSizeAdjustment(size, is_mobile, icon_variant);
    if (type === "button") {
        return <div className="relative">
            <M3_A_Button variant={icon_variant} size={adjust_size} adjust_icon_size={adjust_icon_size}
                         onClick={onClick} intent={intent} sx={icon_sx} text={label}
            />
        </div>
    }

    return <div className="relative">
        <M3_A_IconButton variant={icon_variant} size={adjust_size} adjust_text_size={0}
                         adjust_icon_size={adjust_icon_size} square={!label}
                         onClick={onClick} intent={intent} sx={icon_sx} text={label}
                         icon={icon}/>
        {show_update && <UpdateCircle border_color={update_border_color} position="top-0.5 right-1"/>}
    </div>
}

export default function M3_C_Header({
                                        title,
                                        context_bar,
                                        subtitle,
                                        z_index = "z-10",
                                        show_background,
                                        actions,
                                        subnav_bar,
                                        title_sx,
                                        icon_sx,
                                        onTitleClick,
                                        background,
                                        default_icon_variant = "minimal",
                                        padding_top,
                                        has_background,
                                        icon,
                                        default_height = "h-12",
                                        padding_bottom = "pb-3",
                                        is_modal_header,
                                        sticky,
                                        divider,
                                        skeleton,
                                        onBack,
                                        watch_scroll_id,
                                        scroll_snap_px = 64,
                                        size = "xl"
                                    }) {
    const sticky_header_class = useRef(generateUniqueID("sticky-header"));
    const scroll_snap_class = useRef(generateUniqueID("scroll-snap"));
    const last_scroll_top = React.useRef(0);
    const is_mobile = useIsMobile();

    const [mode, setMode] = React.useState("default"); // search
    const [reached_scroll_snap, setReachedScrollSnap] = React.useState(reachedScrollSnap(watch_scroll_id, scroll_snap_px, sticky_header_class.current, last_scroll_top.current, is_mobile));
    const [scroll_up_show_content, setScrollUpShowContent] = React.useState(showScrollUpContent(watch_scroll_id, scroll_snap_class.current, sticky_header_class.current, last_scroll_top.current));

    useEffect(() => {
        if (!watch_scroll_id) {
            return;
        }

        const el = is_mobile ? window : document.getElementById(watch_scroll_id);

        if (!el) {
            return;
        }
        const setClasses = (el) => {
            const scroll_up_show_content = showScrollUpContent(watch_scroll_id, scroll_snap_px, sticky_header_class.current, last_scroll_top.current, is_mobile);

            setScrollUpShowContent(scroll_up_show_content);
            setReachedScrollSnap(reachedScrollSnap(watch_scroll_id, scroll_snap_px, sticky_header_class.current, last_scroll_top.current, is_mobile));

            last_scroll_top.current = el.scrollTop;
        }

        setClasses(el);

        el.addEventListener('scroll', () => setClasses(el));

        return () => el.removeEventListener('scroll', () => setClasses(el));
    }, [watch_scroll_id, is_mobile, scroll_snap_px])

    function renderTitle() {
        let c = <M3_A_Heading sx={{
            display: "inline",
            ...title_sx
        }} color={background ? "text-white" : "text-gray-900"} skeleton={skeleton}
                              size={heading_size}>
            {title}
        </M3_A_Heading>;

        if (onTitleClick) {
            return <M3_A_NodeSelector variant="dark" onClick={onTitleClick}>
                {c}
            </M3_A_NodeSelector>;
        }

        return c;
    }

    const heading_size = heading_size_map[size];

    let border_classes = "";

    if (divider && reached_scroll_snap) {
        border_classes = "border-b border-gray-200";
    } else if (divider) {
        border_classes = "border-b border-gray-300";
    } else if (reached_scroll_snap) {
        border_classes = "border-b border-gray-200";
    }

    const bg_height = getBackgroundHeight();

    const divider_top = mode === "search" || !subnav_bar ? "top-[2.75rem]" : "top-[5.25rem]";

    /*
      {subtitle && <M3_A_Subheading color={background ? "text-gray-200" : "text-gray-900"} skeleton={skeleton}
                                      size={heading_size}>
            {subtitle}
        </M3_A_Subheading>}
     */
    const primary_header = <>
        {title && renderTitle()}
    </>
// ${reached_scroll_snap ? "blur-white-bg" : content_bg}
    //${reached_scroll_snap ? "blur-white-bg" : content_bg}
    let header_classes = `${z_index} ${sticky_header_class.current}`, show_title = true,
        icon_variant = `${default_icon_variant}`;

    if (sticky) {
        header_classes += " sticky top-0";
        if (reached_scroll_snap && show_background) {
            header_classes += " blur-white-bg";
        }
    }

    if (has_background && !reached_scroll_snap) {
        icon_variant = "dark";
        show_title = !!reached_scroll_snap;
    }

    if (!has_background) {
        if (show_background) {
            header_classes += " blur-white-bg";
        }
    }

    if (padding_top) {
        header_classes += ` ${padding_top}`;
    }

    return <header className={header_classes} id="header">
        {background && <Background bg_height={bg_height} {...background} />}
        {context_bar && mode !== "search" && <div
            className={`${is_modal_header ? "rounded-t-lg" : ""} flex items-center w-full ${default_height} px-4`}>
            <M3_A_ContextBar size={size} transparent={reached_scroll_snap || !background} {...context_bar}>
                {show_title && primary_header}
            </M3_A_ContextBar>
        </div>}
        {mode !== "search" && !context_bar && <div style={{
            paddingTop: background ? `calc(${bg_height} - 2.75rem - 4.25rem)` : ""
        }} className={`px-4 pt-3 flex ${default_height} items-center ${subnav_bar ? "pb-1" : padding_bottom}`}>
            {onBack && <div className="flex-none -ml-2 pr-2 inline-flex items-center">
                <M3_A_IconButton onClick={onBack} compact variant={icon_variant} size={m3_adjust_size(size, 0)}
                                 adjust_icon_size={-1} square sx={icon_sx}
                                 icon={m3_icon_map.outlines.back}/>
            </div>}
            {icon && <div className="flex-none pr-2">
                <M3_A_Avatar letter={icon?.letter} size={icon?.size}
                             avatar={icon?.avatar}/>
            </div>}
            <div className="flex-grow truncate">
                {show_title && primary_header}
            </div>
            {actions && <div className="flex flex-none gap-2">
                {actions.map((action, index) => (
                    <div key={index}>
                        {renderAction(action, size, icon_variant, icon_sx, is_mobile)}
                    </div>
                ))}
            </div>}
        </div>}
        {subnav_bar && <div
            className={`${mode === "search" ? "pt-4" : "pt-1"} ${mode === "search" ? "h-14" : default_height} px-4`}>
            <M3_A_SubnavBar {...subnav_bar} onCloseSearch={() => {
                // add a header_is-searching class to the body
                document.body.classList.remove("header_is-searching");

                setMode("default");

                if (subnav_bar?.onCloseSearch) {
                    subnav_bar?.onCloseSearch();
                }
            }} onOpenSearch={() => {

                document.body.classList.toggle("header_is-searching");

                setMode("search");

                if (subnav_bar?.onOpenSearch) {
                    subnav_bar?.onOpenSearch();
                }
            }}/>
        </div>}

        {border_classes &&
            <div className={`${border_classes} transition-colors -mt-px ${divider_top}`}/>}
    </header>;
}