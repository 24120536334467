import React from "react";
import M3_A_Icon from "../atoms/icon";
import {_m3_icon_outlines} from "./outlines";
import {_m3_icon_solid} from "./solid";
import {menu_icon_map} from "../_legacy_components/app-frame/menus";
/*
This file contains the mappings of icons (currently from heroicons) to the names used in the app
 */

export const m3_icon_map = {
    outlines: _m3_icon_outlines,
    solid: _m3_icon_solid,
    custom: {
        ...menu_icon_map
    }
};

function Section({data,section_name,base_key}) {
    const icons = Object.entries(data);
    return <div className="py-20">
        <h2>{section_name}</h2>
        <div className="grid gap-6 grid-cols-6">
            {icons.map(([name,icon]) => {
                return <div key={name} className="text-center">
                    <div className="flex items-center h-20 w-20 justify-center bg-gray-200 mx-auto rounded-lg ">
                        {icon&&<M3_A_Icon size="xl">
                            {icon}
                        </M3_A_Icon>}
                    </div>
                    <h3>{name}</h3>
                </div>
            })}
        </div>
    </div>
}

export function IconMapViewer() {

    // we want to show in a grid all of the icons above in sections and render the outline version of the heroicon

    const sections_outlines = Object.entries(m3_icon_map.outlines);
    const sections_solid = Object.entries(m3_icon_map.outlines);

    return <div className="grid gap-6 max-w-4xl mx-auto">
        {sections_outlines.map(section => {
            return <Section key={section[0]} base_key={'outlines'} section_name={section[0]} data={section[1]}/>
        })}
    </div>
}