import React from "react";
import {AL2SidebarToggle} from "./index";
import {
    AL2SidebarBody,
    AL2SidebarHeader,
    AL2SidebarName,
    Al2SidebarNotifications,
    AL2SidebarUser
} from "./sidebar-components";
import {useLocation, useNavigate} from "react-router-dom";
import {useCommunity} from "../../../config/community";

import {SpaceMenuPopup} from "../../_legacy_components/app-frame/community-popup";
import {buildImageUrl} from "../../../../common/utilities/images";

/**
 * this component is the sidebar element for our app.
 * It has the following features:
 * custom styling with a background color, text color, and hover color
 * custom width
 * custom logo
 * custom header items
 * custom body sections
 * custom footer items
 */

const Toggle = ({collapsed, showSidebarHover, active_theme, toggleSidebar, toggleSidebarHover}) => {
    return <>
        {!collapsed && !showSidebarHover && <div className="flex-none">
            <AL2SidebarToggle theme={active_theme} toggleSidebar={toggleSidebar}/>
        </div>}
        {collapsed && !showSidebarHover && <div className="">
            <AL2SidebarToggle theme={active_theme} toggleSidebar={toggleSidebar}/>
        </div>}
        {showSidebarHover && <div className="">
            <AL2SidebarToggle theme={active_theme} close toggleSidebar={toggleSidebarHover}
            />
        </div>}
    </>
}

export function AL2Sidebar({
                               collapsed = false,
                               showSidebarHover,
                               toggleSidebarHover,
                               toggleSidebar,
                               raw_community,
                               config
                           }) {
    const community = useCommunity();
    const location = useLocation();
    const navigate = useNavigate();

    const {header, body, footer} = config;

    const notifications = <Al2SidebarNotifications theme={community.active_theme} unread={community?.unread}
                                                   goTo={() => {
                                                       navigate(`${config.base_path}/notifications`)
                                                   }}/>;


    return <div className="h-full flex select-none flex-col">
        <div style={{
            borderColor: community.active_theme.background_divider
        }}
             className={`flex-none flex mr-1.5 items-center ml-2.5 border-b pt-2 gap-1 pb-2 ${collapsed ? "flex-col" : "h-12 px-1"}`}>
            <div className="" style={{
                flex: "1 1 0",
                minWidth: 0
            }}>
                <SpaceMenuPopup community={raw_community}>
                    <AL2SidebarName theme={community.active_theme}
                                    collapsed={collapsed} name={community.name}
                                    logo={buildImageUrl(community?.profile?.profile_picture)}/>
                </SpaceMenuPopup>
            </div>
            <div className="flex-none">
                {notifications}
            </div>

        </div>
        <div className={`flex-grow pt-3 ${collapsed?"ml-3":"ml-2.5"} mr-1.5 overflow-y-auto gap-6 flex flex-col no-scrollbars`}>
            <div className="">
                <AL2SidebarHeader location={location} navigate={navigate} theme={community.active_theme}
                                  config={config}
                                  collapsed={collapsed} {...header} />
            </div>
            <div className="">
                <AL2SidebarBody location={location} navigate={navigate} theme={community.active_theme}
                                config={config}
                                collapsed={collapsed} {...body} />
            </div>
        </div>
        <div
            className={`flex-none flex items-center ml-2.5 mr-1.5 pb-1 pt-2 gap-1 border-t ${collapsed ? "flex-col-reverse" : "px-1"}`}
            style={{
                borderColor: community.active_theme.background_divider
            }}>
            <div className="flex-grow">
                <AL2SidebarUser goToProfile={() => {
                    navigate(`${config.base_path}/${config.profile_path}`)
                }} collapsed={collapsed} theme={community.active_theme} {...footer} />
            </div>
            <div>
                <Toggle {...{
                    collapsed,
                    showSidebarHover,
                    active_theme: community.active_theme,
                    toggleSidebar,
                    toggleSidebarHover
                }} />
            </div>
        </div>
    </div>
}