import {authFetch} from "../network";

export async function event_rsvp(event_id, response_status, community) {
    const p2 = {
        community_uid: community.uid,
        event_id,
        member_id: community.member_id,
        response_status
    };
    return new Promise(resolve => {
        const r = () => {
            resolve();
        };
        authFetch('/events/rsvp', r, r, "POST", {payload: p2});
    })
}