function hasAcceptedAny(policy_id, array) {
    const all = array.filter(a => a.startsWith(`${policy_id}__`)).sort();
    if (all.length > 0) {
        return all[all.length - 1];
    }
    return false;
}

function getStatus(no_policies, up_to_date, outdated, never_accepted) {
    if (no_policies) {
        return 'na';
    } else if (outdated.length === 0 && never_accepted.length === 0) {
        return 'up-to-date';
    } else if (outdated.length > 0) {
        return 'outdated'
    } else {
        return 'never-accepted'
    }
}

export function memberPoliciesCalculator(member, community) {
    let up_to_date = [], no_policies = false, outdated = [], never_accepted = [];

    no_policies = community.policies.length === 0;

    const accepted_policies = Object.keys(member.policies ? member.policies : {});

    community.policies.forEach(a => {
        const policy_id = a.id
        const {version} = a;
        const current_uid = `${policy_id}__${version}`;
        const any = hasAcceptedAny(policy_id, accepted_policies);
        if (member.policies[current_uid]) {
            up_to_date.push({
                id: policy_id,
                at: member.policies_meta[current_uid].ts
            });
        } else if (any) {
            outdated.push({
                id: policy_id,
                last_version_accepted: any.split('__')[1],
            })
        } else {
            never_accepted.push({
                id: policy_id
            });
        }
    });
    const status = getStatus(no_policies, up_to_date, outdated, never_accepted);

    return {
        up_to_date, outdated, policy_data: community.policies, never_accepted, status
    }
}