import {useCurrentLayout, useDimensions} from "../../hooks";
import React, {useCallback} from "react";
import {useInfiniteScrollData} from "../../../routes/community/contacts/hooks";
import {FixedSizeGrid as Grid} from "react-window";
import M3_A_Link from "../../atoms/link";
import M3_A_Text from "../../atoms/text";
import M3_A_Icon from "../../atoms/icon";
import {m3_icon_map} from "../../icons/icon-map";
import {M3_A_MiniLoadingIndicator, ViewCellValue} from "../list-view/components";
import M3_A_IconButton from "../../atoms/icon-button";
import ReactDOM from "react-dom";
import {ContactsEmptyState} from "../../../routes/community/contacts/components";
import {buildImageUrl} from "../../../../common/utilities/images";

function getColumnCount(container_width = 1440, format = 'small') {
    const {min, max} = sizing_index[format];

    const max_count = container_width / min;
    const min_count = container_width / max;

    return Math.floor((max_count + min_count) / 2);
}

const sizing_index = {
    small: {min: 180, max: 220},
    large: {min: 240, max: 360},
}

function getCardWidth(column_count, width) {
    return (width / column_count);
}

function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

function getCardHeight(card_width, cols) {

    // profile picture height will always be same size as width
    let base = card_width;

    // add 0.25rem bottom for padding
    base += convertRemToPixels(0.5);

    // the name will always take h-7 which is 1.75rem
    base += convertRemToPixels(1.75);
    //   console.log("getCardHeight", card_width, base, convertRemToPixels(2), convertRemToPixels(GUTTER_SIZE_REM * 2));
    // plus add height for each block below

    // add one row now for testing which is h-5
    cols.forEach(col => {
        base += convertRemToPixels(1.5);
    })

    // add 0.25rem bottom for padding
    base += convertRemToPixels(0.25);

    return base;
}

const GUTTER_SIZE_REM = 0.5;

const getDataIndex = (columnIndex, rowIndex, column_count) => {
    return (rowIndex * column_count) + columnIndex;
};

function parseValue(obj, nested_key, type) {
    if (!obj) {
        return null;
    }
    if (nested_key) {
        const keys = nested_key.split(".");
        let value = obj;
        keys.forEach(key => {
            value = value?.[key];
        })
        return value;
    } else {
        return obj;
    }
}

const CardElement = ({card_width, fns, item_data, data}) => {
    return <div className="h-full rounded-lg" style={{
        maxWidth: card_width,
        boxShadow: "0px 2px 3px -1px rgba(0,0,0,0.1), 0px 1px 0px 0px rgba(25,28,33,0.02), 0px 0px 0px 1px rgba(25,28,33,0.08)"
    }}>
        <div style={{
            height: card_width,
            width: card_width,
            backgroundImage: `url(${buildImageUrl(item_data?.profile_picture, '_medium')})`,
        }} className="bg-cover bg-center bg-no-repeat rounded-t-lg">

        </div>
        <div className="py-2 px-3">
            <div className="h-6 leading-6 whitespace-nowrap truncate">
                <M3_A_Link variant="minimal" onClick={() => {
                    fns.onViewProfile(item_data?.id, item_data);
                }}>
                    <M3_A_Text size="base" weight="font-bold" color="text-gray-800">
                        {item_data?.name}
                    </M3_A_Text>
                </M3_A_Link>
            </div>
            {data.cols.map((col, col_index) => {
                const info = {
                    getValue: () => {
                        return parseValue(item_data, col.accessorKey, col._type)
                    },
                    row: {
                        original: item_data
                    },
                    column: {
                        columnDef: {
                            meta: {
                                type: col._type
                            },
                            display: col.display
                        }
                    }
                };

                return <div key={`${item_data.id}-${col.accessorKey}`}
                            className="h-6 flex gap-1.5 items-center">
                    <div className="text-gray-600 flex-none w-5 flex justify-center">
                        {!col?._custom_label && <M3_A_Icon size="h-3.5 w-3.5">
                            {m3_icon_map?.outlines?.[col?.icon]}
                        </M3_A_Icon>}
                        {col?._custom_label && col?.label &&
                            <div className="-mt-px"><M3_A_Text size="xs" color="text-gray-600">
                                {col?.label.slice(0, 3)}
                            </M3_A_Text></div>}
                    </div>
                    <div className="text-gray-800 flex-grow truncate text-sm flex">
                        <ViewCellValue value={info.getValue()} display={info.column.columnDef.display}
                                       meta={info.column.columnDef.meta} original={info.row.original}/>
                    </div>
                </div>
            })}
        </div>

    </div>
}

// todo make common component
const Wrapper = ({children, fns, item_data, tools, onClose, rect}) => {
    let style = {};

    let toolbar_align, toolbar_value;

    if ((rect.right + 160) > window.innerWidth) {
        toolbar_align = "right";
        toolbar_value = window.innerWidth - rect.left;
    } else {
        toolbar_align = "left";
        toolbar_value = rect.right;
    }

    const top = rect.top + convertRemToPixels(0.5);
    const left = rect.left + convertRemToPixels(0.5);
    style = {
        top: `${top}px`,
        left: `${left}px`,
    };

    return <div
        onClick={e => {
            console.log('portal click')
            e.stopPropagation();
            e.preventDefault();
            onClose();
        }}
        className={`absolute z-9999 overflow-hidden bg-black/40`}
        style={{
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
        }}>
        <div className="absolute bg-white rounded-lg" style={{
            ...style
        }}>
            {children}
        </div>
        <div onClick={e => {
            e.stopPropagation();
            e.preventDefault();

        }}
             className={`absolute portal-toolbar flex flex-col gap-1.5 ${toolbar_align === "right" ? "items-end" : "items-start"}`}
             style={{
                 top: top,
                 [toolbar_align]: `${toolbar_value}px`,
             }}>
            <div>
                <M3_A_IconButton onClick={() => {
                    onClose();
                    fns.onViewProfile(item_data?.id, item_data)
                }} variant="outline" icon={m3_icon_map.outlines.member} text="View Profile"/>
            </div>

            <div>
                <M3_A_IconButton onClick={() => {
                    onClose();
                    fns.onManageRecord(item_data?.id, item_data)
                }} variant="outline" icon={m3_icon_map.outlines.edit} text="Edit Record"/>
            </div>
            <div>
                <M3_A_IconButton onClick={() => {
                    fns.editProfilePicture(item_data?.id, item_data, (url, color, id) => {
                        fns.updateRecord(id, 'profile_picture', url.split('_large?alt=media')[0]);
                    })
                    onClose();
                }} variant="outline" icon={m3_icon_map.outlines.avatar} text="Edit Profile Picture"/>
            </div>
        </div>
    </div>
}

function ContentPortal({children, item_data, fns, onClose, element_ref}) {
    const rect = !element_ref ? null : element_ref.getBoundingClientRect();

    if (!rect) {
        return null;
    }

    return ReactDOM.createPortal(
        <Wrapper fns={fns} item_data={item_data} onClose={onClose} rect={rect}>
            {children}
        </Wrapper>,
        document.body
    );
}

const Cell = ({columnIndex, rowIndex, style, data}) => {
    const data_index = getDataIndex(columnIndex, rowIndex, data.column_count);
    const card_width = style.width - convertRemToPixels(GUTTER_SIZE_REM * 2);
    const portal_ref = React.useRef(null);
    const [portal_open, setPortalOpen] = React.useState(false);

    const item_data = data.data[data_index];
    if (!item_data) {
        return <div style={{
            ...style,
        }}>

        </div>
    }
    const card = <CardElement fns={data.fns} card_width={card_width} data={data} item_data={item_data}/>;

    let content;

    if (portal_open && data?.show_editor_tools) {
        content = <ContentPortal item_data={item_data} fns={data.fns} onClose={() => {
            setPortalOpen(false);
        }} element_ref={portal_ref.current}>
            {card}
        </ContentPortal>
    } else {
        content = <>
            {card}
            {data?.show_editor_tools && <div className="absolute top-3 right-3 hidden group-hover:block">
                <M3_A_IconButton square onClick={() => {
                    setPortalOpen(true);
                }} icon={m3_icon_map.outlines.ellipsis} size="sm" adjust_icon_size={1} variant="dark"/>
            </div>}
        </>;
    }

    return (
        <div ref={portal_ref} style={{
            ...style
        }} className="p-2 relative group">
            {content}
        </div>
    )
}

function getPageSize(dimensions, column_count, card_height) {
    const {height} = dimensions;

    const max_items = Math.floor(height / card_height);

    const min_rows_to_load = Math.ceil(max_items / column_count) + 1;

    return (min_rows_to_load * 2) * column_count;
}

export function GridViewComponent({
                                      config,
                                      cols,
                                      inline_sidebar_width
                                  }) {
    const layout = useCurrentLayout(config?.state?.is_mobile);
    const dimensions = useDimensions(layout, config?.state?.show_advanced, inline_sidebar_width, config?.state?.is_mobile, config?.state?.search_open);

    const _inner_width = dimensions.width - convertRemToPixels(0.5);
    const gridRef = React.useRef(null);

    const column_count = config?.state?.is_mobile ? 2 : getColumnCount(_inner_width, 'small');
    const card_width = getCardWidth(column_count, _inner_width);
    const card_height = getCardHeight(card_width, cols);
    const page_size = getPageSize(dimensions, column_count, card_height);

    const [data, is_fetching, fetchNextPage, has_more_ref, refresh, updateRecord] = useInfiniteScrollData(config.state.active_view_data.config, config.state.query, page_size, config.context.community_uid, config.fns.getData, config.fns.getPartialsData);

    const row_count = Math.ceil(data.length / column_count);

    const overscan_height = 300;

    const show_empty_state = data.length === 0 && !is_fetching;

    const fetchMoreOnBottomReached = useCallback((event) => {
        if (event && gridRef.current) {
            const {scrollTop} = event;

            const scroll_height = gridRef.current?.props?.rowCount * gridRef.current?.props?.rowHeight;

            if (is_fetching || data.length === 0 || !has_more_ref.current) {
                return;
            } else if ((scroll_height - scrollTop - gridRef.current?.props?.height) < overscan_height) {
                fetchNextPage();
            }
        }
    }, [fetchNextPage, is_fetching, data.length])

    return <div className="relative px-1" style={{
        height: dimensions.height, width: dimensions.width,
    }}>
        <Grid
            className={`grid`}
            ref={gridRef}
            width={_inner_width}
            height={dimensions.height}
            columnCount={column_count}
            columnWidth={card_width}
            rowCount={row_count}
            onScroll={fetchMoreOnBottomReached}
            rowHeight={card_height}
            itemData={{
                data,
                column_count,
                cols,
                can_edit_records: config?.features?.can_edit_records,
                show_editor_tools: config?.features?.can_edit_records && !config?.state?.is_mobile,
                fns: {
                    updateRecord,
                    editProfilePicture: config?.fns?.editProfilePicture,
                    onViewProfile: config?.fns?.onViewProfile,
                    onManageRecord: config?.fns?.onManageRecord,
                },
                // items, onItemClick, properties: defs.properties, loading: fetching, format, column_count
            }}
        >
            {Cell}
        </Grid>

        {show_empty_state && <ContactsEmptyState query={config?.state?.query}/>}
        {is_fetching && <M3_A_MiniLoadingIndicator/>}
    </div>
}