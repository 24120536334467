import {authFetch} from "../network";

export async function api_setGroupNotificationSettings(community_uid,member_id,id,settings) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        const payload = {
            settings,
            member_id,
            community_uid,
            group_id: id
        };
        authFetch("/notifications/update-group-settings", res, res, "POST", {payload});
    })
}