import {authFetch} from "../network";

export async function getGroupPeopleSuggestions(community,id) {
    return new Promise(resolve => {
        const res = (response) => {
            resolve(response?.data?.suggestions || [])
        };

        const payload = {
            member_id: community.member_id,
            id,
            community_uid: community.uid,
        };
        console.log("GPS",payload)

        authFetch("/get-group-add-people-suggestions", res, res, "POST", {payload})
    })
}