import React from "react";

export function WB_CoreText({}) {
    return <div className="prose prose-base text-gray-800">
        <p>
            Liebe Corpsbrüder,
        </p>
        <p>
            fast eine Woche nach unserem Stiftungsfest möchte ich mich bei allen Aktiven, Verantwortlichen und
            Organisationen für diese wirklich unvergessliche und einmalige Veranstaltung mit einem grossen Kompliment
            bedanken.
        </p>
        <p>
            In eigener Sache:
            Ihr werdet mit den USB Sticks im Nachgang auch noch eine wirklich besondere und gelungene Fotodokumentation
            erhalten.
        </p>
        <p>
            Zu Eurem Hintergrund: sowohl der Begrüssungsabend als auch Kommers, Ball und Frühschoppen wurden von der
            jungen Fotografin Yana begleitet.
        </p>
        <p>
            Yana stammt aus Kiew und hat dort eigentlich ihr eigenes Fotostudio. Mit Kriegsbeginn ist sie mit ihrer
            Schwiegermutter und ihren beiden kleinen Kindern (4 Jahre und 6 Monate) nach Deutschland geflohen und wohnt
            seitdem bei uns. Ihr Mann ist unverändert in der Ukraine und darf das Land nicht verlassen.
        </p>
    </div>
}