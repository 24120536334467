import React, {useEffect, useRef, useState} from "react"
import ExternalAuthPage from "../../../m3/_legacy_components/external-auth-page";
import Button from "../../../m3/_legacy_components/button";
import Field from "../../../m3/_legacy_components/form/field";

import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {checkPasswordStrength, PasswordField} from "../link/password";
import styled from "styled-components";
import HandleSelector from "./handle-selector";
import {api_emailIsTaken, signUpUser} from "./api";
import {authApp, useAuth} from "../../../config/auth";
import {setDocumentTitle} from "../../../m3/utilities/set-document-title";
import {PrettyCheckbox} from "../../../m3/_legacy_components/toggle";
import M3_A_Link from "../../../m3/atoms/link";
import M3_A_Text from "../../../m3/atoms/text";
import M3_A_Heading from "../../../m3/atoms/heading";
import {utils_strings_isEmail} from "../../../../common/utilities/strings";

function getCopy(is_applying, is_community, is_group, community, group) {
    return {
        button_text: is_applying ? "Create Account & Apply to Join" : "Create Account",
        message: !is_community || !community ? "" : is_group && group ? `Why do you want to join ${group.name}?` : `Why do you want to join ${community.name}?`
    }
}

export function buildAuthUrlSuffix(email, group_data) {
    let e = "";
    if (email && utils_strings_isEmail(email)) {
        e = `email=${email}`
    }
    let g = "";
    if (group_data && group_data.id) {
        g = `group=${group_data.id}`;
    }
    if (!e && !g) {
        return ""
    }
    return `?${e}${e && g ? "&" : ""}${g}`;
}

const CustomizeHandle = styled.div`
  font-size: 13px;
  margin-top: 6px;
  max-width: 90%;

  > b {
    font-weight: 600;
  }
`;

export function getNameMeta(handle, _customizing, onChange, type) {
    if (handle !== '' && !_customizing) {
        return <CustomizeHandle className='text-gray-700'>Handle will be <b>@{handle}</b>, <a
            className="link"
            onClick={() => onChange('_customizing', true)}>customize it</a></CustomizeHandle>
    }
    return null;
}

export function UnaverseCategoryBackground({classes = ""}) {
    return <div style={{backgroundImage: `url()`}}
                className={`absolute overflow-hidden top-0 left-0 right-0 bottom-0 bg-gray-50 bg-center bg-cover ${classes}`}>
        <div className="h-full items-end w-full flex">
            <div className="bg-gradient-to-t hidden w-full from-gray-900 pb-6 px-6 pt-16">
                <div className="text-base text-white font-medium">Discover <span
                    className="font-bold">Vital Ground</span> and 348 other environmental associations on Orange.
                </div>
            </div>
        </div>
    </div>
}

function cleanEmail(email) {
    return email.toLowerCase();
}

export default function CreateAccountPage(props) {
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const auth = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [handle_name, setHandleName] = useState("");
    const [handle, setHandle] = useState("");
    const [message, setMessage] = useState("");
    const [flags, setFlags] = useState({
        _customizing: false,
        checked: false,
        checking_email: false,
        email_is_valid: true,
        checked_email: false,
        email_is_taken: false,
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const has_checked_auth = useRef(false);

    const is_create_community_flow = searchParams.get('ref') === 'create-community';

    setDocumentTitle(`Create an Account`);

    useEffect(() => {
        if(has_checked_auth.current) {
            return;
        }
        if(auth.__ready&&auth.user) {
            navigate('/?ref=already-signed-in');
        }
        has_checked_auth.current = true;
    }, [auth]);

    function disableBtn(score) {
        return (!utils_strings_isEmail(email) || password.length < 8 || name.length < 4 || !flags.checked || score < 2)
    }

    function handleChange(f, v) {
        if (f === 'email') {
            setEmail(v);
            setFlags({
                ...flags,
                checking_email: false,
                checked_email: false,
                email_is_valid: true
            })
        } else {
            if (f === 'name') {
                setName(v);
            } else {
                setHandle(v);
            }
        }
    }

    function handleBlur(type) {
        let value = '';
        if (type === 'email') {
            value = email;
            if (!utils_strings_isEmail(value)) {
                setFlags({
                    ...flags,
                    email_is_valid: false
                })
            } else {
                setFlags({
                    ...flags,
                    email_is_valid: true,
                    checked_email: false
                })
            }
        } else if (type === 'name') {
            if (handle === '' && name !== '') {

                setHandleName(name);
            }
        }
    }

    function handleSubmit() {
        const email_clean = cleanEmail(email);
        // validate inputs

        if (disabled) {
            return;
        }

        if (!utils_strings_isEmail(email_clean) || password.length < 8) {
            return;
        }

        setLoading(true);

        api_emailIsTaken(email_clean)
            .then(r => {
                console.log("api_emailIsTaken",r)
                if (!r) {
                    signUpUser(authApp, email_clean, password, name, handle)
                        .then((ra) => {
                            console.log("ALL DONE",ra,is_create_community_flow)
                            if(ra) {
                                // check for ref=create-community in url params and redirect to /create-community
                                if(is_create_community_flow) {
                                    navigate('/create-community?ref=create-account')
                                } else {
                                    navigate('/?ref=create-account')
                                }
                            } else {
                                setError('unknown')
                                setLoading(false)
                            }
                        })
                } else {
                    setFlags({
                        ...flags,
                        email_is_taken: true,
                        checked_email: true
                    })
                    setLoading(false);
                    setError("email-taken");
                }
            })
    }

    let result = checkPasswordStrength(password);

    const {is_community, is_group, community_data, group_data} = props;

    const is_applying = !!props.applying;

    const base_link = `${props.is_community ? `/${props.community_data.handle}` : ""}`;

    const copy = getCopy(is_applying, props.is_community, is_group, community_data, group_data);

    const suffix = buildAuthUrlSuffix(email, group_data);

    const disabled = disableBtn(result.score) || (props.is_community && !message);

    let content;

    content = <div className="space-y-4">
        <div>
            <Field placeholder="Your name" autoFocus input_props={{}} type="name" id="name" onChange={(id, v) => {
                handleChange('name', v)
            }} onBlur={() => handleBlur('name')} value={name} label="Full Name"/>
        </div>
        <div>
            <Field placeholder="Email" input_props={{}} type="email" id="email" onChange={(id, v) => {
                handleChange('email', v)
            }} onBlur={() => handleBlur('email')} value={email} label="Email"/>
            <div>
                {getNameMeta(handle, flags._customizing, () => setFlags({...flags, _customizing: true}), "user")}

                {flags.email_is_taken && flags.checked_email && <div className="p-2 rounded border-blue-100 bg-blue-50 mt-2 text-xs text-gray-700">
                    <div>An account with this email already exists. <Link className="link" to={`${base_link}/sign-in${suffix}`}>Sign in</Link>
                    </div>
                </div>}
                {!flags.email_is_valid &&
                <div className="mt-2 px-3 py-2 rounded-md bg-danger-50 text-danger-800">Please enter a valid
                    email</div>}
                <div className={`${flags._customizing ? "block mt-4" : "hidden"}`}>
                    <HandleSelector default_text={handle_name} setHandle={(hh) => {
                        setHandle(hh)
                    }} type='user' scope='global'/>
                </div>
            </div>
        </div>
        <PasswordField onChange={(a, v) => {
            setPassword(v);
        }} score={result.score}
                       value={password}/>
        {props.is_community && <div className="pt-4">
            <div>
                <div className="text-sm text-gray-700"><span>Message</span></div>
                {message}
            </div>
        </div>}
        <div>
            <PrettyCheckbox label={<span>I agree to the <Link target="_blank" className="link"
                                                              to="/policies/user-terms.html">
                                Terms
                            </Link>, <Link target="_blank" className="link"
                                           to="/policies/privacy.html">
                                    Privacy Policy
                            </Link> and <Link target="_blank" className="link"
                                              to="/policies/cookies.html">
                               Cookie Policy.
                            </Link></span>} active={flags.checked} onClick={() => setFlags({
                ...flags,
                checked: !flags.checked
            })}/>

        </div>
        <div>
            <Button intent='primary' fill size='large' disabled={disabled}
                    onClick={() => handleSubmit(disabled)} text={copy.button_text}
                    loading={loading}/>
        </div>
    </div>

    return <ExternalAuthPage show_logo bg_image={'/images/custom/seven.jpg'}>
        <div className="w-96 space-y-4">
            <div className="pb-2">
              <M3_A_Heading size="h2">Create an account</M3_A_Heading>
            </div>
            <div>
                {content}
            </div>
            <div className="pt-4">
                <M3_A_Text size="sm" color="text-gray-700">Already have an account? <M3_A_Link variant="secondary" onClick={() => navigate('/sign-in')}>Sign In</M3_A_Link></M3_A_Text>
            </div>
        </div>
    </ExternalAuthPage>
};