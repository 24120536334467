import React, {useEffect} from "react";
import {getStartedRecordUsage} from "../../search";
import {Routes, Route, useParams, useLocation, useNavigate} from "react-router-dom";
import {useCommunity} from "../../../../config/community";
import FilesScrollable from "../../drive/files-scrollable";
import {useIsMobile} from "../../../../m3/hooks/is-mobile";
import {useToasts} from "../../../../config/toasts";
import {InlineLoader} from "../../../../m3/_legacy_components/admin-activity";
import {saveItemToLocalStorage} from "../../../../m3/utilities/localstorage";
import {getFile,getFolder} from "../../../../../api/drive/folder";

function File() {
    const {fileId} = useParams();
    const community = useCommunity();
    const navigate = useNavigate();
    const [file, setFile] = React.useState(null);

    useEffect(function () {
        getFile(community.uid, fileId)
            .then(f => {
                setFile(f);
            });
    }, [fileId, community.uid]);

    useEffect(function () {
        if (file && !community.lightbox) {
            community.setLightbox({
                closeCb: () => {
                    // navigate back to folder
                    const url = window.location.pathname.split('/file')[0];
                    navigate(url);
                },
                context: "drive",
                context_id: "",
                right_sidebar: false,

                type: "file",
                id: fileId,
                data: file,

                starting_index: 0,
                items: [
                    {
                        id: fileId,
                        name: file.name,
                        url: file.link,
                        size: file.size,
                        type: file.type
                    }
                ]
            });
        }
    }, [file, community.lightbox])

    if (!file) {
        return <div>
            <InlineLoader padding/>
        </div>
    }

    return <div>

    </div>
}

function Folder({group, group_id}) {
    const {folderId} = useParams()
    const community = useCommunity();
    const is_mobile = useIsMobile();
    const toasts = useToasts();
    const [selected, setSelected] = React.useState([]);
    const [file_list, setFileList] = React.useState([]);
    const [update_list, setUpdateList] = React.useState(false);
    const [assets_len, setAssetsLen] = React.useState({});
    const [folder_names, setFolderNames] = React.useState({});
    const [can_mass_edit_files, setCanMassEditFiles] = React.useState(false);
    const [folder, setFolder] = React.useState(null);
    const [id, setId] = React.useState(folderId || group.folder_id);

    useEffect(function () {
        setFolder(null);
        if (folderId) {
            setId(folderId);
            getFolder(community.uid, folderId).then(f => {
                setFolder(f);
            })
        } else {
            setId(group.folder_id);
            getFolder(community.uid, group.folder_id).then(f => {
                setFolder(f);
            })
        }
        getStartedRecordUsage(community.uid, 'groupdrive');
    }, [folderId, community.uid, group.folder_id])

    const props = {
        hide_go_up: !folderId
    };

    const base = `/c/${community.profile.handle}/space/${group_id}/drive`;

    if (!folder) {
        return <div>
            <InlineLoader padding/>
        </div>
    }


    return <div>
        <FilesScrollable in_group={true} is_mobile={is_mobile}
                         group_top_level_folder={group.folder_id} base={base}
                         language={""} selected={selected}
                         updateSelected={(ids) => setSelected([...ids])}
                         community_handle={community.profile.handle} onAddToast={t => toasts.addToast(t)}
                         setList={(maps) => {
                             setFileList(maps);
                             setUpdateList(false)
                         }} setLength={(l, t) => {
            let nal = {...assets_len};
            nal[t] = l;
            setAssetsLen(nal);
        }} setNames={(n, t) => {
            /*
            let no = {...folder_names};
            no[t] = n;
            setFolderNames(no);

             */
        }} update_list={update_list} setUpdated={() => setUpdateList(false)}
                         parent_folder={folder.folder} can_edit={can_mass_edit_files} hide_go_up={props.hide_go_up}
                         folder={id} {...props} />

        <Routes>
            <Route path="/file/:fileId" element={<File group={group} group_id={group_id}/>}/>
        </Routes>
    </div>
}

export default function GroupDriveTab({group, group_id}) {
    const community = useCommunity();
    const location = useLocation();

    useEffect(function () {
        saveItemToLocalStorage(`getstarted-groupdrive-${community.uid}-${group.id}`, true)
    }, [])

    if (!group) {
        return <div className="">
            <InlineLoader padding/>
        </div>
    }

    return <div>
        <Routes>
            <Route index element={<Folder group={group} group_id={group_id}/>}/>
            <Route path="/folder/:folderId/*" element={<Folder group={group} group_id={group_id}/>}/>
        </Routes>
    </div>
};