import React from "react"
import {BasicSelect} from "./basic-select";
import {BirthdaySelector} from "./birthday";
import {LocationField} from "./location";
import {OccupationField} from "./occupation";
import {RichPhoneInput} from "../rich-phone-input";
import {CreateableAsyncSelect} from "../createable-async-select";
import {EntitiesAsyncSelect} from "../createable-async-select/entities";
import {Toggle} from "../toggle";
import {IndustryField} from "./industry";
import HandleSelector from "../../../routes/auth/create-account/handle-selector";
import IconSelector from "../icon-selector";
import Button from "../button";
import SecondaryEmails from "../secondary-emails";
import {SignatureField} from "./signature";

import {ProfilePictureField} from "./profile-picture";
import {DEFAULT_USER_PHOTO} from "../../../config/community";
import {ConnectGoogleAccountField} from "../../../routes/community/calendar/connect-google-account";
import {GoogleCalendarSelector} from "../../../routes/community/calendar/google-calendar-selector";
import {DriveFolderSelectorField} from "../folder-selector";
import {MemberJoinDate} from "../../utilities/joined-date";
import {data_languages_arr, data_languages_map} from "../../../../common/data/data/languages";
import {data_timezones_arr, data_timezones_map} from "../../../../common/data/data/timezones";
import {data_continents_arr} from "../../../../common/data/data/continents";
import {data_countries_arr, data_countries_map} from "../../../../common/data/data/countries";
import {data_sexes_arr, data_sexes_map} from "../../../../common/data/data/sex";
import {buildImageUrl} from '../../../../common/utilities/images';
import {LockClosedIcon} from "@heroicons/react/20/solid";

function valueFallback(v, f) {
    return v && v[f] ? v[f] : "";
}

function getMultiSelectValue(val, opts) {
    if (!val) {
        return [];
    }
    if (!Array.isArray(val) && opts) {
        return Object.keys(val).map(key => {
            const l = opts.filter(a => a.id === key)[0];
            return {
                value: key,
                label: l ? l.label : ""
            }
        });
    }
    return val;
}

function handleAddressChange(v, current, field, onChange) {
    let nv = {...current};
    nv[field] = v;
    onChange(nv);
}

function getCFOptions(props) {
    if (props.options && props.options.choices) {
        return props.options.choices.filter(a => !a.hidden).map(a => {
            return {
                label: a.text,
                id: a.value,
                value: a.value,
            }
        })
    } else if (props.options && Array.isArray(props.options)) {
        return props.options.filter(a => !a.hidden).map(a => {
            return {
                label: a.label || a.text || "",
                id: a.value,
                value: a.value,
            }
        })
    }

    return [];
}

function getSelectValue(v) {
    if (!v) {
        return '';
    }
    if (typeof v === 'string') {
        return v;
    } else {
        return v.label;
    }
}

export function getDefaultJoinDate(jd) {
    return !jd ? {type: '', value: {season: null, date: null, month: null, year: null}} : JSON.parse(JSON.stringify(jd))
}

function getLabel(value, a) {
    const it = a.filter(a => a.value === value)[0];
    return it ? (it.label || it.text || "") : "";
}

function getGroupValue(v) {
    if (!v) {
        return "";
    }
    if (typeof v === "object") {
        return Object.keys(v)[0];
    }
    return v ? `${v}` : "";
}

function getMemberValue(v) {
    if (!v) {
        return "";
    }
    if (typeof v === "object") {
        return Object.keys(v)[0];
    }
    return v ? `${v}` : "";
}

function getCheckboxLabel(props, value) {
    return !props.options || !props.options.checked_label ? "" : (value ? props.options.checked_label : props.options.unchecked_label);
}

function convertToObj(arr) {
    let a = {};
    arr.forEach(it => {
        a[it.value] = true;
    })
    return a;
}

function MonthDaySelector({value, onChange}) {
    return <div>
        <select value={value} onChange={onChange} name="monthday" className="htmlselect">
            {Array.from(Array(28).keys()).map(opt => {
                return <option key={opt + 1} value={`${opt + 1}`}>{getDayLabel(opt + 1)}</option>
            })}
        </select>
    </div>
}

function buildTimePickerOptions(interval = 15) {
    const array_length = (60 * 24) / interval;
    return Array.from(Array((array_length)).keys()).map(num => {
        const minutes = (num) * interval;
        const fh = Math.floor(minutes / 60);
        const val = `${padLeading(fh)}:${padLeading(pad(minutes % 60))}`;
        return {
            value: val,
            label: `${val} ${fh < 12 ? "am" : "pm"}`
        }
    });
}

function TimePicker({value, onChange}) {
    const options = buildTimePickerOptions();

    return <div>
        <select value={value} onChange={onChange} name="time" className="htmlselect">
            {options.map(({label, value}, key) => {
                return <option key={value} value={value}>{label}</option>
            })}
        </select>
    </div>
}

const nth = function (d) {
    const dString = String(d);
    const last = +dString.slice(-2);
    if (last > 3 && last < 21) return 'th';
    switch (last % 10) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
}

function getDayLabel(n) {
    return `${n}${nth(n)}`;
}

function parseComplexDateVal(type, value) {
    if (type === "month-day-time") {
        if (!value) {
            return [`1`, `9`, `0`]
        } else {
            return value;
        }
    } else if (type === "week-day-time") {
        if (!value) {
            return [`1`, `9`, `0`]
        } else {
            return value;
        }
    } else if (type === "day-time") {
        if (!value) {
            return [`9`, `0`]
        } else {
            return value;
        }
    }
    return [];
}

function pad(value, width = 2, padchar = "0") {

    while (value.length < width) {
        value += padchar;
    }
    return value;
}

function padLeading(num, size = 2) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

/*
   <svg className="transform -rotate-90" height="20" width="20">
            <circle strokeDashoffset={'0px'} cx="10" cy="10" r="8" strokeDasharray={"50.24"} stroke="#efefef"
                    strokeWidth="2" fill="transparent"/>
            <circle strokeDashoffset={pct} cx="10" cy="10" r="8" strokeDasharray={"50.24"} stroke={color}
                    strokeWidth="2" fill="transparent"/>
        </svg>
 */

function CharacterCounter({max = 280, value = 0}) {
    const r = 8;
    const c = Math.PI * (r * 2);
    let val = (value / max) * 100;
    let color = "#929292";
    let count = max - value;
    if (val < 0) {
        val = 0;
    } else if (val > 100) {
        val = 100;
        color = "#f44336";
    } else if (val > 85) {
        color = "#bb9611";
    }
    const pct = ((100 - val) / 100) * c;

    return <div className="absolute  bottom-2.5 right-2.5">
        <span className="text-xs  font-medium" style={{color}}>{count}</span>
    </div>
}

function ShortSelectOption({label, disabled, onClick, selected, value}) {
    const classes = selected ? "bg-blue-50 border-blue-300" : "border-gray-200 hover:border-gray-400 hover:bg-gray-50";
    return <div onClick={onClick}
                className={`${classes} ${disabled?"cursor-not-allowed pointer-events-none":"cursor-pointer"} pl-2 pr-3 flex space-x-2 items-center rounded-xl border `}>
        <div className="mt-0.5 flex-none flex items-center">
            <input onChange={onClick} checked={selected} className="transform scale-125" type="radio" id={label}
                   name={"label" + label}/>
        </div>
        <div className={`text-base leading-8 font-medium ${selected ? "text-blue-600" : "text-gray-700 "} `}>
            {label}
        </div>
    </div>
}

const isSelected = (selected, value) => {
    return !selected ? false : selected.value === value;
};

function ShortSelect({options, multi, disabled, value, onChange, onBlur}) {
    return <div className="flex flex-wrap gap-2">
        {options.map((opt, key) => {
            return <ShortSelectOption onClick={() => {
                onChange(opt);
            }} key={key} disabled={disabled} selected={isSelected(value, opt.value)} {...opt}/>
        })}
    </div>
}

function getInput(type, props = {}, value, handleChange, handleBlur) {
    let a, b;
    switch (type) {
        case 'color':
            return <div className="border items-center rounded-md relative flex py-2 text-base px-3 w-full">
                <div className="flex-none">
                    <input
                        className="relative cursor-pointer z-10 w-6 bg-white h-6 rounded-md"
                        type="color" value={value} onChange={e => {
                        handleChange(e.target.value);
                    }}/>
                </div>
                <div className="flex-grow pl-4 text-base text-gray-800">
                    <input onChange={(e)=>{
                        handleChange(e.target.value);
                    }} value={value} type="text" className="w-full" placeholder="#000000" />
                </div>
            </div>
        case 'profile_picture':

            a = value ? buildImageUrl(value, '_medium', DEFAULT_USER_PHOTO) : buildImageUrl(DEFAULT_USER_PHOTO, '_medium');

            return <div>
                <ProfilePictureField inline onProfilePictureChange={(url, color) => {
                    handleChange([url, color]);
                }} type={type} url={a}/>
            </div>
        case 'textarea':
            a = value === null ? "" : value;
            return <textarea autoFocus={props.autoFocus} rows={2} disabled={props.disabled} placeholder={props.placeholder || ""}
                             className={`input-focus border rounded-md py-2 text-base px-3 w-full ${props.disabled ? "disabled" : ""}`}
                             value={a} onChange={e => handleChange(e.target.value)} {...props} />
        case 'symbol':
            return <div>
                <IconSelector onChange={a => handleChange(a)} value={value} {...props} />
            </div>
        case 'handle':
            return <HandleSelector default_text={props.default_text || ""} setHandle={(hh) => {
                handleChange(hh)
            }} type={props.handle_type || "group"} scope={props.scope || "community"} show_label={false}
                                   scope_id={props.scope_id || ""}/>
        case 'bio':
            a = value === null ? "" : value;
            b = props.placeholder || "Introduce yourself to the community..";
            return <div className="relative">
                <textarea autoFocus={props.autoFocus} rows={2} placeholder={b}
                          className="input-focus border rounded-md py-2 text-base px-3 w-full"
                          value={a} onChange={e => handleChange(e.target.value)} {...props} />
                <CharacterCounter max={280} value={value?.length || 0}/>
            </div>
        case 'description':
            a = value === null ? "" : value;
            b = props.placeholder || "";
            return <div className="relative">
                <textarea autoFocus={props.autoFocus} rows={2} placeholder={b}
                          className="input-focus border rounded-md py-2 text-base px-3 w-full"
                          value={a} onChange={e => handleChange(e.target.value)} {...props} />
                <CharacterCounter max={2000} value={value?.length || 0}/>
            </div>
        case 'sex':
            return <BasicSelect onBlur={handleBlur} placeholder="Sex" searchable={true}
                                value={!value ? null : {value: value, label: data_sexes_map?.[value]?.label}}
                                onChange={(v) => {
                                    handleChange(v.value)
                                }} options={data_sexes_arr}/>
        case 'location':
            return <LocationField autocomplete={props?.autocomplete || ""} onBlur={(v) => {
                handleChange(v);
                handleBlur();
            }} onChange={(v, place_id) => {
                let extra;
                if (place_id) {
                    extra = {
                        place_id
                    }
                }
                handleChange(v, extra)
            }} value={value}/>
        case 'occupation':
            return <OccupationField onBlur={(v) => {
                handleChange(v);
                handleBlur();
            }} onChange={(v) => {
                handleChange(v)
            }} value={value}/>
        case 'industry':
            return <IndustryField onBlur={(v) => {
                handleChange(v);
                handleBlur();
            }} onChange={(v) => {
                handleChange(v)
            }} value={value}/>
        case "date-object":
        case 'birthday':
            return <div>
                <BirthdaySelector disabled={props.disabled} month_offset={0} autoFocus value={value} onChange={(v) => {
                    console.log("birthday",v)
                    handleChange({...v});
                }}/>
            </div>
        case 'drive-folder':

            if(!props.drive_id) {
                return <div>
                    No drive ID provided.
                </div>
            }

            return <div className="inline-flex">
                <DriveFolderSelectorField value={value||""} onChange={(folder_id)=>{
                handleChange(folder_id);
            }} drive_id={props.drive_id} />
            </div>
        case 'email-select':
            a = props.options || [];
            b = a.filter(a => a.value === value)[0];

            if (a.length === 0) {
                return <div className="py-1">
                    <Button text="Add Account Email" intent="primary" onClick={props.onAddEmail}/>
                </div>
            }

            // todo add another email
            return <BasicSelect onBlur={handleBlur} placeholder="Email"
                                value={!value ? null : {value: value, label: b ? b.label : "-"}}
                                onChange={(v) => {
                                    handleChange(v.value)
                                }} options={a}/>
        case 'phone':
            return <RichPhoneInput value={value} onChange={handleChange} onBlur={handleBlur} {...props} />
        case 'checkbox':
            // cf_data.options.checked_label?"":(value?cf_data.options.checked_label:cf_data.options.unchecked_label)
            a = !!value;

            b = getCheckboxLabel(props, a);

            return <Toggle checked={value}
                           label={b}
                           onToggle={() => {
                               //  onChange(!value)
                               handleChange(!a);
                           }}
            />
        case 'member':
            a = getMemberValue(value);
            return <EntitiesAsyncSelect community_uid={props.community_uid || ""}
                                        placeholder={props.placeholder || "Search"} value={a}
                                        onChange={(val) => {
                                            handleChange(!val ? "" : val.value);
                                        }}
                                        autoFocus={!!props.autoFocus}/>
        case 'group':
            a = getGroupValue(value);
            return <EntitiesAsyncSelect community_uid={props.community_uid || ""}
                                        placeholder={props.placeholder || "Search"} value={a}
                                        onChange={(val) => {
                                            handleChange(!val ? "" : val.value);
                                        }}
                                        type="groups"
                                        autoFocus={!!props.autoFocus}/>
        case 'assignee':
            a = getMemberValue(value);

            return <EntitiesAsyncSelect community_uid={props.community_uid || ""}
                                        placeholder={props.placeholder || "Search"} value={a}
                                        onChange={(val) => {
                                            handleChange(!val ? "" : val.value);
                                        }}
                                        autoFocus={!!props.autoFocus}/>
        case 'mentor':
            a = getMemberValue(value);
            return <EntitiesAsyncSelect community_uid={props.community_uid || ""}
                                        placeholder={props.placeholder || "Search"} value={a}
                                        onChange={(val) => {
                                            // onChange({label: val})\
                                            handleChange(!val ? "" : val.value);
                                        }}
                                        autoFocus={!!props.autoFocus}/>
        case 'mentees':
            a = getMultiSelectValue(value);

            return <EntitiesAsyncSelect multi community_uid={props.community_uid || ""}
                                        placeholder={props.placeholder || "Search"} value={a}
                                        onChange={(val) => {
                                            const nv = val ? convertToObj(val) : {};

                                            handleChange(!val ? {} : nv);
                                        }}
                                        autoFocus={!!props.autoFocus}/>
        case 'multi-select-taxonomy':
            a = getMultiSelectValue(value);
            b = props.taxonomy ? props.taxonomy : props.options && props.options.taxonomy ? props.options.taxonomy : "";
            return <CreateableAsyncSelect existing={a} multi taxonomy={b} placeholder={props.placeholder || "Search"}
                                          value={a} disabled={props.disabled}
                                          onChange={(val) => {
                                              //    const nv = val?convertToObj(val):{};
                                              handleChange(val || []);
                                          }}
                                          autoFocus={!!props.autoFocus}/>
        case 'multi-select':
            b = getCFOptions(props);
            a = getMultiSelectValue(value, b);
            return <BasicSelect multi onBlur={handleBlur} placeholder={props.placeholder || ""} searchable={true}
                                value={a} disabled={props?.disabled}
                                onChange={(v) => {
                                    handleChange(v)
                                }} options={b}/>
        case 'google-calendar-selector': {
            return <GoogleCalendarSelector value={value} onChange={(nv,item) => {
                handleChange(nv, item);
            }} />
        }
        case 'connect-google-account': {
            return <ConnectGoogleAccountField value={value} onChange={(nv) => {
                handleChange(nv.email, nv);
            }}/>
        }
        case 'select-taxonomy':
            a = getSelectValue(value);
            b = props.taxonomy ? props.taxonomy : props.options && props.options.taxonomy ? props.options.taxonomy : "";
            if (!b) {
                return null;
            }
            return <CreateableAsyncSelect taxonomy={b} placeholder={props.placeholder || "Search"} value={a} disabled={props?.disabled}
                                          onChange={(val) => {
                                              handleChange(val ? val.value : "");
                                          }}
                                          autoFocus={!!props.autoFocus}/>
        case 'address':
            return <div className="space-y-1">
                <FieldInput name={"address-line1"} value={valueFallback(value, 'line_one')}
                            onChange={(y, v) => handleAddressChange(v, value, 'line_one', handleChange)}
                            type="text"
                            placeholder="Street Address"/>
                <FieldInput name={"address-line2"} value={valueFallback(value, 'line_two')}
                            onChange={(y, v) => handleAddressChange(v, value, 'line_two', handleChange)} type="text"
                            placeholder="Apt"/>
                <div className="flex space-x-1">
                    <div className="flex-auto">
                        <FieldInput value={valueFallback(value, 'city')}
                                    onChange={(y, v) => handleAddressChange(v, value, 'city', handleChange)}
                                    type="text"
                                    placeholder="City"/>
                    </div>
                    <div className="flex-auto">
                        <FieldInput value={valueFallback(value, 'zip_code')}
                                    onChange={(y, v) => handleAddressChange(v, value, 'zip_code', handleChange)}
                                    type="text"
                                    placeholder="ZIP Code"/>
                    </div>
                </div>
                <FieldInput value={valueFallback(value, 'state')}
                            onChange={(y, v) => handleAddressChange(v, value, 'state', handleChange)} type="text"
                            placeholder="State"/>
                <BasicSelect placeholder="Country" usePortal name={"address-country"} layout="address"
                             value={valueFallback(value, 'country')} onChange={(v) => {
                    if (!v) {
                        handleAddressChange("", value, 'country', handleChange)
                    } else {
                        handleAddressChange(v.value, value, 'country', handleChange)
                    }

                }} clearable searchable options={data_continents_arr}/>
            </div>
        case 'country':
            return <BasicSelect onBlur={handleBlur} placeholder="Country" searchable={true} disabled={props?.disabled}
                                value={!value ? null : {value: value, label: data_countries_map[value]}}
                                onChange={(v) => {
                                    handleChange(v.value)
                                }} options={data_countries_arr}/>
        case 'join_date':
            a = getDefaultJoinDate(value);
            return <MemberJoinDate simple onBlur={handleBlur} onChange={(njd) => {
                handleChange(njd)
            }} existing={{...a}}/>
        case 'secondary_emails':
            if (!props.user_uid) {
                return null;
            }
            return <div>
                <SecondaryEmails user_uid={props.user_uid} updateMember={() => {
                }}/>
            </div>
        case 'timezone':
            return <BasicSelect onBlur={handleBlur} placeholder="Timezone" searchable={true}
                                value={!value ? null : {value: value, label: data_timezones_map[value]}}
                                onChange={(v) => {
                                    handleChange(v.value)
                                }} options={data_timezones_arr}/>
        case 'language':
            return <BasicSelect onBlur={handleBlur} placeholder="Language" searchable={true}
                                value={!value ? null : {value: value, label: data_languages_map[value]}}
                                onChange={(v) => {
                                    handleChange(v.value)
                                }} options={data_languages_arr}/>
        case 'select':
            console.log("RENDER SELECT",props,value)
            a = getCFOptions(props);
            b = getLabel(value, a);
            if (a.length < 5 && !props.classic) {
                return <ShortSelect onBlur={handleBlur} options={a} disabled={props?.disabled}
                                    value={value === '' ? null : {value: value, label: b}}
                                    onChange={(v) => {
                                        handleChange(v.value)
                                    }}/>
            }
            return <BasicSelect onBlur={handleBlur} placeholder={props.placeholder || ""} searchable={true}
                                value={value === '' ? null : {value: value, label: b}} disabled={props?.disabled}
                                onChange={(v) => {
                                    handleChange(v.value)
                                }} options={a}/>
        case 'month-day-time':
            const [dayofmonth, h, m] = parseComplexDateVal('month-day-time', value);
            a = `${padLeading(h)}:${pad(m)}`;
            return <div className="flex space-x-2">
                <div className="flex-none w-32">
                    <MonthDaySelector value={dayofmonth} onChange={(e) => {
                        handleChange([e.target.value, h, m])
                    }}/>

                </div>
                <div className="flex-grow">
                    <TimePicker value={a} onChange={e => {
                        const [hh, mm] = e.target.value.split(":");
                        handleChange([dayofmonth, hh, mm])
                    }}/>
                </div>
            </div>;
        case 'week-day-time':
            const [weekdays, h1, m1] = parseComplexDateVal('week-day-time', value);
            a = `${padLeading(h1)}:${pad(m1)}`;
            return <div className="flex space-x-2">
                <div className="flex-none">
                    <select value={weekdays} onChange={(e) => {
                        handleChange([e.target.value, h1, m1])
                    }} name="weekday" className="htmlselect">
                        {["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].map((label, key) => {
                            return <option key={key} value={`${key}`}>{label}</option>
                        })}
                    </select>
                </div>
                <div className="flex-grow">
                    <TimePicker value={a} onChange={e => {
                        const [hh, mm] = e.target.value.split(":");
                        handleChange([weekdays, hh, mm])
                    }}/>
                </div>
            </div>;
        case 'day-time':
            const [h2, m2] = parseComplexDateVal('day-time', value);

            const fv = `${padLeading(h2)}:${pad(m2)}`;

            return <div>
                <TimePicker value={fv} onChange={e => {
                    const [hh, mm] = e.target.value.split(":");
                    handleChange([hh, mm])
                }}/>
            </div>;
        case 'signature':
            a = value && typeof value === "object" ? {...value} : {
                url: "",
                valid: false,
                ts: null,
                type: ""
            };
            return <SignatureField onChange={handleChange} {...a} />
        case 'headline':
            a = value === null ? "" : value;
            return <input autoFocus={props.autoFocus} onBlur={handleBlur}
                          className="input-focus border rounded-md leading-10 text-base px-3 w-full" type={type}
                          value={a} onChange={e => handleChange(e.target.value)} {...props} />
        default:
            a = value === null ? "" : value;
            return <input autoFocus={props.autoFocus} onBlur={handleBlur}
                          className={`input-focus min-h-[2.5rem] border rounded-md bg-white leading-10 text-base px-3 w-full ${props.disabled ? "disabled" : ""}`}
                          type={type}
                          value={a} onChange={e => handleChange(e.target.value)} {...props} />
    }
}

export function FieldInput({
                               input_props = {}, placeholder, onChange, onBlur = () => {
    }, type, id, value, autoFocus, disabled
                           }) {
    function handleChange(v, extra) {
        if (onChange) {
            onChange(id, v, extra);
        }
    }

    let ic = {
        autoFocus: !!autoFocus,
        disabled: !!disabled,
        ...input_props
    };

    if (placeholder) {
        ic.placeholder = placeholder;
    }

    return getInput(type, ic, value, handleChange, onBlur);
}

export function FieldLabel({label, locked, optional, children, required, corner_hint}) {
    return <div className="pb-1 flex items-baseline">
        <div className="font-medium text-sm flex-grow text-gray-700">
            {label}{children}{optional&&<span className="text-gray-500 tracking-tight pl-1">(optional)</span>}
            {required && <span className="text-red-700 pl-0.5">*</span>}
            {locked && <span className="text-gray-500 pl-0.5">
                <LockClosedIcon style={{
                    height: "0.75rem",
                    width: "0.75rem",
                    display: "inline-block",
                    marginLeft: "2px",
                    marginTop: "-2px"
                }} />
            </span>}
        </div>
        {corner_hint && <div className="text-sm text-gray-500">{corner_hint}</div>}
    </div>
}

function RightIcon({icon, onClick}) {
    return <div className={"absolute top-0 right-0 bottom-0 flex items-center pr-3"}>
        <div className="w-5 h-5 cursor-pointer text-gray-400 hover:text-gray-700" onClick={(e) => {
            e.stopPropagation();
            if (onClick) {
                onClick();
            }
        }}>
            {icon}
        </div>
    </div>
}

export default function Field({
                                  name = "",
                                  required,
                                  children,
                                  locked,
                                  title,
                                  right_icon,
                                  optional,
                                  onBlur,
                                  disabled,
                                  corner_hint,
                                  input_children,
                                  max_length,
                                  placeholder = "",
                                  autoFocus,
                                  footer,
                                  footer_hint,
                                  label,
                                  id,
                                  onEnter,
                                  onChange,
                                  input_props,
                                  value,
                                  type = 'text'
                              }) {

    function handleKeyDown(e) {
        if (e.keyCode === 13) {
            if (onEnter) {
                onEnter(e);
            }
        }
    }

    const input = type && <FieldInput onChange={onChange} placeholder={placeholder} onBlur={onBlur}
                                      input_props={{autoFocus, onKeyDown: handleKeyDown, name, disabled, ...input_props}}
                                      type={type}
                                      id={id}
                                      value={value}/>

    return <div className={``}>
        {label && !title && <FieldLabel locked={locked} optional={optional} required={required} corner_hint={corner_hint} label={label}/>}
        {title && <div className="pb-1.5 text-lg leading-6 font-bold text-gray-900">
            {title}
        </div>}
        <div className="relative">
            {input}
            {right_icon && <RightIcon {...right_icon} />}
            {input_children}
        </div>
        {children}
        {footer_hint && <div className="pt-1 text-xs text-gray-600">
            {footer_hint}
        </div>}
        {footer && <div className="pt-1">
            {footer}
        </div>}
    </div>
};