import {initializeApp} from 'firebase/app';
import {getStorage} from "firebase/storage";
import {getPerformance} from "firebase/performance";
import {getAnalytics, logEvent} from "firebase/analytics";
import config from './firebase-config';

export const app = initializeApp(config);

export const analytics = getAnalytics(app);

export const storage = getStorage(app);

export const app_perf = getPerformance(app);


/*
param examples
- content_type, content_id
 */
export function logEngagementEvent(name = '', params = {}, scope_id="") {
    if (!name) return;
    logEvent(analytics, name, {
        ...params,
        scope_id
    });
}