import {db} from "../../../config/setup-firestore";
import {collection, getDocs, limit, query, where} from "firebase/firestore";

export async function handleIsUnique(handle, type = 'user', scope = 'global', community_uid = '', check_id) {
    // users and domains are global scoped against the same space
    // channels, lists, teams, roles are community scoped
    return await handleExists(handle, scope, community_uid, check_id);
}

async function handleExists(handle, scope, community_uid, check_id='') {
    if(scope==='global') {
        const ref = collection(db, "handles", "global", "handles");
        return await getDocs(query(ref,where("handle","==",handle),limit(1))).then(snap=>!snap.empty);
    } else {
        const ref = collection(db, "handles", community_uid, "handles");
        return await getDocs(query(ref,where("handle","==",handle),limit(1))).then(snap=>{
            if(!snap.empty) {
                const d = snap.docs[0];
                const dt = d.data();
                if(dt&&dt.member_id===check_id) {
                    return false;
                }
            }

            return !snap.empty;
        });
    }

}