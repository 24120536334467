import React, {useEffect, useState} from 'react';
import {prefillContentState} from "../../write/body";
import {RichTextEditor} from "../../../../features/forum/editor/rich-text-editor";

function uploadFilesToFolder(type) {
    if (type === 'post') {
        // folder id of group...
        return true;
    } else {
        return false;
    }
}

export function DraftComposer({
                                  handleCommandEnter,
                                  init_mode, content_state = null, auto_focus, show_toolbar = true, update = () => {
    }, type = "post", folder_id = "", handleClose = () => {
    }
                              }) {
    const [mode, setMode] = useState("full-screen");

    const [attachments, setAttachments] = useState({});
    const [editorState, setEditorState] = useState(null);
    const [focus_count, setFocusCount] = useState(0);

    useEffect(function () {
        update(editorState, attachments)
    }, [attachments, editorState]);

    function handleUpdateAttachments(na) {
        setAttachments({...na})
    }

    const initial_content = prefillContentState(content_state);

    return <RichTextEditor handleCommandEnter={handleCommandEnter} auto_focus={auto_focus} show_tools={show_toolbar}
                           upload_files_to_folder={uploadFilesToFolder(type)} handleClose={handleClose} mode={mode}
                           action_bar={mode === 'compose'} actions={<div>

    </div>} right_actions={<div>

    </div>} updateAttachments={handleUpdateAttachments} folder_id={folder_id} fc={focus_count}
                           content_state={initial_content} placeholder={"Start typing.."} template={null}
                           onChange={(editor_state) => {
                               setEditorState(editor_state)
                           }}/>
}