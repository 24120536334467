import React, {useState} from 'react';
import {AppDirectoryPage} from "../../apps/directory";
import {AppPage} from "../../apps/app-page";
import {apps_list} from "../../apps/apps-list";


function hydrateAppsList(group) {
    return apps_list.map(app => {
        return {
            ...app,
            added: !!group?.['power_ups']?.[app.id],
            config: group?.['power_ups']?.[app.id]?.['config'] || {}
        }
    })
}


export function GroupModalApps({
    setData,
                                   starting_section,
                                   updateGroupData,
                                        cb = () => {
    }, id, group
                                    }) {
    const [page, setPage] = useState(starting_section||"");

    const all_apps = hydrateAppsList(group);

    function handleGoToApp(id) {
        setPage(id);
    }

    if(page) {
        const app_data = all_apps.find(app => app.id === page);
        return <AppPage updateGroupData={updateGroupData} goBack={()=>{
            setPage("");
        }} app_data={app_data} app_id={page} group={group} />
    }

    return <AppDirectoryPage all_apps={all_apps} goToApp={handleGoToApp} group={group} id={id} setData={setData} />
}