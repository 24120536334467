import {sanitizationPipeline} from "./data-sanitization";

function getImportedValue(raw, block_data, mapping) {
    if (!mapping) {
        return {valid:false,value:'',code:'empty'};
    }
    return sanitizationPipeline(raw, mapping.field_details.type, mapping);
}

export function getEntryUpdateID(block_data) {
    if(block_data.id.charAt(1)==='-'||block_data.id.charAt(2)==='-'||block_data.id.charAt(3)==='-') {
        // could be id, could be 1-1ID
        const split = block_data.id.split('-');
        if(split[1]===block_data.label) {
            return split[1];
        }
        const try_two = split[1].replace(split[0],'');
        if(try_two===block_data.label) {
            return split[1];
        }
    }
    return block_data.id;
}

export function lie_getEntryRawValue(entry,block_data) {
    const entry_field_id = getEntryUpdateID(block_data);
    if(!entry_field_id||!entry[entry_field_id]) {
        return '';
    }
    return entry[entry_field_id].trim()
}

export function lie_buildRowData(row_number, entry, block_data, mapping_data, empty_is_invalid=false) {
    const raw_value = lie_getEntryRawValue(entry,block_data);

    const block_mapping_data = mapping_data[block_data.id];

    const sanitized_value = getImportedValue(raw_value, block_data, block_mapping_data, empty_is_invalid);

    // we use this for default membership levels for example
    return {
        number: row_number,
        error: !sanitized_value.valid?sanitized_value.code:null,
        info: sanitized_value.valid&&sanitized_value.code?sanitized_value.code:null,
        row_type: 'item',
        value: sanitized_value,
        // label === id here
        raw_value,
        block_data,
        mapping_data,
        data: entry
    }
}

function getColumnSelectOptions(entries,block_data,map_data) {
    let options = {};

    for(let i=0;i<entries.length;i++) {
        const [id,data] = entries[i];
        const data_key = block_data.id.split('-')[1];
        const entry_value = data[data_key];
        if(options[entry_value]) {
            options[entry_value]++;
        } else {
            options[entry_value] = 1;
        }
    }

    return Object.entries(options);
}

const grouping_fields = ['select','boolean-label'];

export function lie_buildRows(data, block_data, mapping_data, open) {
    const data_entries = Object.entries(data);
    let errors = 0,t1,t2;
    if (grouping_fields.includes(mapping_data[block_data.id].field_details.type)) {
        let counter = 1, to_return = [], temp = [], rest = [];
        const groups = getColumnSelectOptions(data_entries,block_data,mapping_data[block_data.id]);
        for(let i=0;i<groups.length;i++) {
            const [id,count] = groups[i];
            const is_open = open[`${getEntryUpdateID(block_data)}-${id}`];
            const selected_option = mapping_data[block_data.id].mapping[id];
            to_return.push({
                number: counter,
                row_type: 'group',
                error: selected_option===null||selected_option===''?"Select an option for mapping":null,
                info: null,
                label: id,
                id: id,
                selected_option: selected_option,
                options: mapping_data[block_data.id].field_details.options,
                count: count,
                open: is_open
            });
            counter += count;
            temp = data_entries.filter(a=>{
                return a[1][getEntryUpdateID(block_data)] === id
            }).map(([row_number, entry]) => lie_buildRowData(row_number, entry, block_data, mapping_data));
            if(is_open) {
                to_return = to_return.concat(temp);
            } else {
                rest = rest.concat(temp);
            }
        }
        t1 = to_return;
        errors = t1.filter(a=>!!a.error).length + rest.filter(a=>!!a.error).length;
        return {rows:t1,errors};
    }

    t1 = data_entries.map(([row_number, entry]) => lie_buildRowData(row_number, entry, block_data, mapping_data));
    errors = t1.filter(a=>!!a.error).length;
    return {rows:t1,errors};
}