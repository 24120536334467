import React, {useEffect, useRef, useState} from "react"
import './styles.css';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {IconAction, TextAction} from "../../../routes/auth/sign-in";
import Button from "../button";
import {ChevronLeftIcon, XMarkIcon} from "@heroicons/react/20/solid";
import {ArrowAction} from "../../../routes/community/manage-member/edit-member";
import Field, {FieldLabel} from "../form/field";
import {externalAuthFetch} from "../../../../api/network";
import {InlineLoader} from "../admin-activity";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {m3_icon_map} from "../../icons/icon-map";

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

function Drag({id, dragDisabled, index, dragClasses = '', ...props}) {

    if (dragDisabled) {
        return <div>
            {props.children}
        </div>
    }
    return <Draggable draggableId={id} index={index}>
        {(provided, snapshot) => {
            return (
                <div ref={provided.innerRef} {...provided.draggableProps} {...props}>
                    <div className="relative">
                        {!dragDisabled && <div
                            className={`text-gray-500 ${dragClasses} absolute rounded-md hover:bg-gray-200 h-5 w-4 inline-flex items-center justify-center transition-opacity`} {...provided.dragHandleProps}>
                            {m3_icon_map.custom['grab-handle']}
                        </div>}
                        {props.children}
                    </div>
                </div>
            );
        }}
    </Draggable>
}

function Drop({id, type, ...props}) {
    return <Droppable droppableId={id} type={type}>
        {(provided) => {
            return (
                <div ref={provided.innerRef} {...provided.droppableProps} {...props}>
                    {props.children}
                    {provided.placeholder}
                </div>
            );
        }}
    </Droppable>
}

/*
"post",
    "external-link",
    "group",
    "page",
    "file",
    "view"
 */

export const nav_item_types = [
    {
        id: 'post',
        label: 'Post',
        detect: (url) => {
// first check that it is on the same domain
            const is_same_domain = url.indexOf(window.location.origin) === 0;
            if (is_same_domain) {
                const is_post = url.indexOf("/post/") > -1;
                if (is_post) {
                    return true;
                }
            }
            return false;
        },
        schema: {
            sample: "{tld}/c/{community}/post/{id}"
        },
        getId: (url) => {
            // get part after /message/ and remove any trailing slashes and query params
            return url.split("/post/")[1].split("/")[0].split("?")[0].split("#")[0].replace(/\/$/, "");
        }
    },
    {
        id: 'external-link',
        label: 'External Link',
        detect: (url) => {
            // first check that it is not on the same domain
            const is_same_domain = url.indexOf(window.location.origin) === 0;
            if (!is_same_domain) {
                return true;
            }
            return false;
        },

    },
    {
        id: 'group',
        label: 'Space',
        schema: {
            sample: "{tld}/c/{community}/space/{id}"
        },
        detect: (url) => {
            // first check that it is on the same domain
            const is_same_domain = url.indexOf(window.location.origin) === 0;
            if (is_same_domain) {
                const is_group = url.indexOf("/space/") > -1;
                const not_drive = url.indexOf("/drive/") === -1;
                if (is_group && not_drive) {
                    return true;
                }
            }
            return false;
        },
        getId: (url) => {
            // sample url to parse: http://localhost:3000/c/student-organization/group/2W6ZYFqkvKOqNgKHpqrW/posts
            // get part after /group/ and remove any trailing slashes and query params
            return url.split("/space/")[1].split("/")[0].split("?")[0].split("#")[0].replace(/\/$/, "");
        }
    },
    {
        id: 'event',
        label: 'Event',
        schema: {
            sample: "{tld}/c/{community}/event/{id}"
        },
        detect: (url) => {
            // first check that it is on the same domain
            const is_same_domain = url.indexOf(window.location.origin) === 0;
            if (is_same_domain) {
                const is_event = url.indexOf("/event/") > -1;
                if (is_event) {
                    return true;
                }
            }
            return false;
        },
        getId: (url) => {
            return url.split("/event/")[1].split("/")[0].split("?")[0].split("#")[0].replace(/\/$/, "");
        }
    },
    {
        id: 'page',
        label: 'Page',
        detect: (url) => {
            // first check that it is on the same domain
            const is_same_domain = url.indexOf(window.location.origin) === 0;
            if (is_same_domain) {
                const valid_pages = [
                    "/leaders"
                ];
                const base_path = window.location.pathname.split("/").slice(0, 3).join("/");
                const is_page = valid_pages.find(p => {
                    // check if it matches a valid page
                    return url.indexOf(base_path + p) > -1;
                });

                if (is_page) {
                    return true;
                }
            }
            return false;
        },
    },
    {
        id: 'file',
        label: 'File',
        detect: (url) => {

            // first check that it is on the same domain
            const is_same_domain = url.indexOf(window.location.origin) === 0;
            if (is_same_domain) {
                const is_file = url.indexOf("/drive/folder/") > -1 && url.indexOf("/file/") > -1;
                if (is_file) {
                    return true;
                }
            }
            return false;
        },
        getId: (url) => {
            // get part after /file/ and remove any trailing slashes and query params
            return url.split("/file/")[1].split("?")[0].split("#")[0].replace(/\/$/, "");
        }
    },
    {
        id: 'view',
        label: 'View',
        detect: (url) => {
            const is_same_domain = url.indexOf(window.location.origin) === 0;
            if (is_same_domain) {
                // check if the url param has a view = id value
                const url_params = new URLSearchParams(url.split("?")[1]);
                const view_id = url_params.get("view");
                if (view_id) {
                    return true;
                }
            }
            return false;
        },
        getId: (url) => {
            // get part after /file/ and remove any trailing slashes and query params
            const url_params = new URLSearchParams(url.split("?")[1]);
            return url_params.get("view");
        }
    }
];

function buildSymbolValue({image, favicon, emoji, color, default_type="text"}) {
    let symbol_value;
    if (favicon) {
        symbol_value = {
            type: "favicon",
            image: favicon,
            emoji: "",
            color: ""
        }
    } else if (image) {
        symbol_value = {
            type: "favicon",
            image: image,
            emoji: "",
            color: ""
        }
    } else if (emoji) {
        symbol_value = {
            type: "emoji",
            emoji: emoji,
            color: "",
            image: ""
        }
    } else if (color) {
        symbol_value = {
            type: "text",
            emoji: "",
            color: color,
            image: ""
        }
    } else {
        symbol_value = {
            type: default_type,
            emoji: "",
            color: "",
            image: ""
        }
    }

    return symbol_value;
}

export function AddLinkItem({
                     handleAdd,
    community_id
                 }) {
    const [url, setUrl] = useState("");
    const [name, setName] = useState("");
    const [image, setImage] = useState("");
    const [emoji, setEmoji] = useState("");
    const [color, setColor] = useState("");
    const [type, setType] = useState("post");

    const [loading, setLoading] = useState(false);

    const type_data = nav_item_types.find(t => t.id === type);

    const is_valid = url.length > 0;

    useEffect(() => {
        if (url && type) {
            prefillData()
            if (type !== "external-link"&&!url.startsWith("/c/")) {
                setUrl(`/c/${url.split('/c/')[1]}`);
            }
        }
    }, [url, type])

    function prefillData() {
        if (type === "external-link") {
            // sample url: https://www.youtube.com/shorts/tSnaB5xeTcY
            const res = (resp) => {
                const data = resp.data;

                if (data) {
                    const {title, favicon} = data;
                    if (title) {
                        setName(title);
                    }
                    if (favicon) {
                        setImage(favicon);
                    }
                    setLoading(false);
                }
            };

            externalAuthFetch("/get-page-metadata", res, res, "POST", {url})
        } else {
            if (type === "post") {
                const ref = doc(db, `community_content/${community_id}/posts/${type_data.getId(url)}`);
                getDoc(ref)
                    .then(doc => {
                        if(doc.exists()) {
                            const data = doc.data();
                            setName(data.subject||"");
                        }
                        setLoading(false);
                    });
            } else if(type==="group") {
                let id = type_data.getId(url);
                const ref = doc(db, `community_entities/${community_id}/spaces/${type_data.getId(url)}`);
                getDoc(ref)
                    .then(doc => {
                        if(doc.exists()) {
                            const data = doc.data();
                            setName(data.name);
                            setEmoji(data.emoji||"");
                            setImage(data.profile_picture||"");
                        }
                        setLoading(false);
                    });
            }
        }
    }

    function handlePaste(url) {
        const is_valid_url = url.length > 0;
        if (is_valid_url) {
            const detected_type = nav_item_types.find(t => t.detect(url));

            if (detected_type) {
                setLoading(true);
                setType(detected_type.id);
                // if detected type is not external link, then we should shorten the url to be domain relative
            }
        }
    }

    let symbol_value = buildSymbolValue({image, emoji, color});

    return <div className="p-4">
        {!is_valid &&
            <Field autoFocus placeholder="Paste a url" type="text" id="url" value={url} onChange={(id, val) => {
                handlePaste(val);
                setUrl(val);
            }}/>}

        {loading && <div>
            <InlineLoader mini padding="p-4"/>
        </div>}

        {is_valid && !loading && <div className="mt-2 space-y-3">
            <div className="text-sm text-gray-600 truncate">
                {url}
            </div>
            <Field label="Name" type="text" id="name" value={name} onChange={(id, val) => {
                setName(val);
            }}/>

            <Field label={'Symbol'} type={"symbol"} name={"symbol"} input_props={{
                enable_image: true,
                initial: name?.charAt(0)?.toUpperCase()
            }} onChange={(id, nv) => {
                if (nv.type === "image") {
                    setImage(nv.image);
                    setEmoji("");
                    setColor("");
                } else if (nv.type === "emoji") {
                    setEmoji(nv.emoji);
                    setImage("");
                    setColor("");
                } else if (nv.type === "text" && nv.color) {
                    setColor(nv.color);
                    setImage("");
                    setEmoji("");
                }
            }} value={symbol_value}/>

            <div className="">
                <Button disabled={!name} text={`Add ${type_data.label}`} onClick={() => handleAdd({
                    url,
                    emoji,
                    image,
                    color,
                    name,
                    type
                })} intent="primary"/>
            </div>
        </div>}
    </div>
}

function EditSection({updateParent, data}) {
    const [name, setName] = useState(data.name || "New Section");
    const [emoji, setEmoji] = useState(data.emoji || "");
    const [favicon, setFavicon] = useState(data.favicon || "");

    useEffect(() => {
        updateParent({name,emoji,favicon});
    }, [name,emoji,favicon])

    let symbol_value = buildSymbolValue({favicon, emoji, color: "", default_type:"emoji"});

    return <div className="p-4 space-y-4">
        <Field label="Section Name" type="text" id="name" value={name} onChange={(id, val) => {
            setName(val);
        }}/>
        <Field value={symbol_value} input_props={{
            enable_image: true,
            disable_text: true
        }} onChange={(id,nv)=>{
            if (nv.type === "favicon") {
                setFavicon(nv.favicon||nv.image);
                setEmoji("");
            } else if (nv.type === "emoji") {
                setEmoji(nv.emoji);
                setFavicon("");
            }
        }} label="Image/Emoji" type="symbol"  />
    </div>
}

export function EditLinkItem({updateParent, data}) {
    const [name, setName] = useState(data.name || "New Item");
    const [image, setImage] = useState(data.image || "");
    const [emoji, setEmoji] = useState(data.emoji || "");
    const [color, setColor] = useState(data.color || "");
    useEffect(() => {
        updateParent({
            name,
            image,
            emoji,
            color
        });
    }, [name, image, emoji, color])

    const symbol_value = buildSymbolValue({image, emoji, color});

    return <div className="p-4 space-y-2">
        <Field label="Item Name" type="text" id="name" value={name} onChange={(id, val) => {
            setName(val);
        }}/>

        <Field label={'Symbol'} type={"symbol"} name={"symbol"} input_props={{
            enable_image: true,
            initial: name.charAt(0).toUpperCase()
        }} onChange={(id, nv) => {
            if (nv.type === "image") {
                setImage(nv.image);
                setEmoji("");
                setColor("");
            } else if (nv.type === "emoji") {
                setEmoji(nv.emoji);
                setImage("");
                setColor("");
            } else if (nv.type === "text" && nv.color) {
                setColor(nv.color);
                setImage("");
                setEmoji("");
            }
        }} value={symbol_value}/>
    </div>
}

function getData(type, id, raw_sections) {
    if (type === 'section') {
        return raw_sections.find(s => s.id === id);
    } else if (type === 'item') {
        const section = raw_sections.find(s => s.items.find(i => i.id === id));
        return section.items.find(i => i.id === id);
    }
    return null;
}

function EditSelected({selected, community_id, onUpdate, onAdd, sections, setSelected}) {
    const [temp_data, setTempData] = useState(null);
    const current_id = useRef("");

    useEffect(() => {
        if (selected) {
            if (selected.type === 'add-item') {
                reload({});
                return;
            }
            const dt = getData(selected.type, selected.id, sections);
            if (dt) {
                reload(dt);
                current_id.current = dt.id;
            } else {
                setSelected(null);
            }
        } else {
            setTempData(null);
        }
    }, [sections, selected])

    function reload(new_temp_data) {
        setTempData(null);
        setTimeout(() => {
            setTempData(new_temp_data);
        }, 50)
    }

    function handleUpdateTempData(ud) {
        setTempData({...temp_data, ...ud})
    }

    return <div className={` py-0.5  rounded-r-md
        ${selected ? '' : 'bg-gray-200'}
        `}>
        {selected && <div>
            <div className="flex h-10 items-center px-3 py-2 border-b border-gray-200">
                <div className="flex-grow">
                    <ArrowAction onClick={() => setSelected(null)}>
                        <ChevronLeftIcon/>
                    </ArrowAction>
                </div>
                <div>
                    {selected.type !== 'add-item' &&
                        <TextAction onClick={() => onUpdate(selected, temp_data)} text="Save Changes"/>}
                </div>
            </div>

            <div>
                {selected.type === 'add-item' && <AddLinkItem community_id={community_id} handleAdd={onAdd}/>}
                {selected.type === 'section' && temp_data &&
                    <EditSection data={temp_data} updateParent={handleUpdateTempData}/>}
                {selected.type === 'item' && temp_data &&
                    <EditLinkItem data={temp_data} updateParent={handleUpdateTempData}/>}
            </div>
        </div>}

    </div>
}

/*
Capabilities
- edit the header items displayed and order?
- edit the footer items displayed and order?
- edit body sections, what if we just start with custom sections, that's really most of it?
- groups section can be adjusted also, but not edited
 */
export default function NavBuilder({
                                       onUpdate,
                                       community_id,
                                       can_add_section = true,
                                       init = []
                                   }) {
    const [sections, setSections] = useState(init);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        onUpdate(sections);
    }, [sections])

    function addSection(afterIndex = 0) {
        const newSection = {
            id: Math.random().toString(36).substr(2, 9),
            name: "New Section",
            emoji: "",
            favicon: "",
            type: "standard",
            items: []
        };
        const updatedSections = sections;
        updatedSections.splice(afterIndex, 0, newSection);
        setSections([...updatedSections]);
    }

    function removeSection(id) {
        if (id === 'groups') {
            return;
        }
        const updatedSections = sections.filter((sec) => sec.id !== id);
        setSections([...updatedSections]);
    }

    function removeItem(sectionId, itemId) {
        const updatedSections = sections.map((sec) => {
            if (sec.id === sectionId) {
                const updatedItems = sec.items.filter((item) => item.id !== itemId);
                return {...sec, items: updatedItems};
            }
            return sec;
        });
        setSections([...updatedSections]);
    }

    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const {destination, type, source} = result;

        const sourceSectionId = source.droppableId;
        const destinationSectionId = destination.droppableId;
        // Reordering items
        // disallow drop in groups section
        // todo add item
        if (type === "droppable-item") {
            if (destinationSectionId === 'groups') return;
            // If drag and dropping within the same category
            if (sourceSectionId === destinationSectionId) {
                const updatedOrder = reorder(
                    sections.find((sec) => sec.id === sourceSectionId).items,
                    source.index,
                    destination.index
                );
                const updatedSections = sections.map((category) =>
                    category.id !== sourceSectionId
                        ? category
                        : {...category, items: updatedOrder}
                );

                setSections(updatedSections);
            } else {
                const sourceOrder = sections.find(
                    (sec) => sec.id === sourceSectionId
                ).items;
                const destinationOrder = sections.find(
                    (sec) => sec.id === destinationSectionId
                ).items;

                const [removed] = sourceOrder.splice(source.index, 1);
                destinationOrder.splice(destination.index, 0, removed);

                destinationOrder[removed] = sourceOrder[removed];
                delete sourceOrder[removed];

                const updatedSections = sections.map((sec) =>
                    sec.id === sourceSectionId
                        ? {...sec, items: sourceOrder}
                        : sec.id === destinationSectionId
                            ? {...sec, items: destinationOrder}
                            : sec
                );

                setSections(updatedSections);
            }
        }
        if (type === "droppable-section") {
            const updated_section = reorder(sections, source.index, destination.index);
            setSections(updated_section);
        }
    }

    function handleAddItem(obj) {
        const {section_id} = selected;
        const {url, name, type, image, emoji, color} = obj;
        const id = Math.random().toString(36).substr(2, 9);
        const updatedSections = sections.map((sec) => {
            if (sec.id === section_id) {
                const updatedItems = sec.items;
                updatedItems.push({id, url, color, image, emoji, name, type});
                return {...sec, items: updatedItems};
            }
            return sec;
        });
        setSections([...updatedSections]);
        setSelected(null);
    }

    function handleUpdate(selected, new_data) {
        if (selected.type === 'section') {
            const updatedSections = sections.map((sec) => {
                if (sec.id === selected.id) {
                    return {...sec, ...new_data};
                }
                return sec;
            });
            setSections([...updatedSections]);
        } else if (selected.type === 'item') {
            const updatedSections = sections.map((sec) => {
                if (sec.id === selected.section_id) {
                    const updatedItems = sec.items.map((item) => {
                        if (item.id === selected.id) {
                            return {...item, ...new_data};
                        }
                        return item;
                    });
                    return {...sec, items: updatedItems};
                }
                return sec;
            });
            setSections([...updatedSections]);
        }
        setSelected(null);
    }

    return <div className="grid max-w-4xl rounded-md border border-gray-200 grid-cols-2 min-h-[20rem]">
        <div className="border-r py-0.5 border-gray-200 rounded-l-md">
            <div className="flex px-3 py-2 border-b border-gray-200 h-10">
                <div className="flex-grow"></div>
                <div>
                    {can_add_section && <TextAction onClick={addSection} text="Add Section"/>}
                </div>
            </div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Drop id="drop" type="droppable-section">
                    <div className="divide-y divide-gray-200">
                        {!sections.length && <div className="text-center text-sm text-gray-400 py-4">No Sections</div>}
                        {sections.map((section, sectionIndex) => {
                            return <Drag
                                dragClasses={' left-1.5 top-3'}
                                className="bg-white"
                                dragDisabled={section.block_drag}
                                key={section.id}
                                id={section.id}
                                index={sectionIndex}
                            >
                                <div onClick={() => {
                                    if (selected && selected.id === section.id && selected.type === "section") {
                                        setSelected(null);
                                        return;
                                    }
                                    // cannot select groups section
                                    if (section.id === 'groups') return;

                                    setSelected({
                                        id: section.id,
                                        type: 'section'
                                    });
                                }} className={`flex py-2.5 items-center px-3
                            ${selected && selected.id === section.id && selected.type === "section" ? "bg-blue-100" : "hover:bg-gray-100"}
                            `}>
                                    <div className="flex-grow pl-4">
                                        <div className="text-sm font-medium text-gray-800">
                                            {section.name}
                                        </div>
                                    </div>
                                    <div>
                                        {section.note && <div className="mr-2 text-gray-400 text-xs font-medium">
                                            <span>{section.note}</span>
                                        </div>}
                                        {section.id !== 'groups' && <IconAction size="medium" onClick={() => {
                                            // open window confirm, then remove
                                            window.confirm("Are you sure you want to delete this section?") && removeSection(section.id);
                                        }}>
                                            <XMarkIcon/>
                                        </IconAction>}
                                    </div>
                                </div>
                                <div className="">
                                    {!section.block_drag &&
                                        <Drop key={section.id} id={section.id} type="droppable-item">
                                            {section.items.map((item, itemIndex) => {
                                                return (
                                                    <Drag
                                                        className="ml-5 border-t border-gray-200"
                                                        dragClasses={' left-1 top-2.5 mt-px'}
                                                        key={item.id}
                                                        id={item.id}
                                                        index={itemIndex}
                                                    >
                                                        <div
                                                            onClick={() => {
                                                                if (selected && selected.id === item.id && selected.type === "item") {
                                                                    setSelected(null);
                                                                    return;
                                                                }
                                                                setSelected({
                                                                    id: item.id,
                                                                    section_id: section.id,
                                                                    type: 'item'
                                                                });
                                                            }}
                                                            className={`flex py-2.5 items-center px-3
                                                 ${selected && selected.id === item.id && selected.type === "item" ? "bg-blue-100" : "hover:bg-gray-100"}
                                                `}>
                                                            <div className="flex-grow pl-4">
                                                                <div className="text-sm font-medium text-gray-800">
                                                                    {item.name}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <IconAction size="medium" onClick={() => {
                                                                    // open window confirm, then remove
                                                                    window.confirm("Are you sure you want to delete this item?") && removeItem(section.id, item.id);
                                                                }}>
                                                                    <XMarkIcon/>
                                                                </IconAction>
                                                            </div>
                                                        </div>
                                                    </Drag>
                                                );
                                            })}
                                        </Drop>}
                                    {!section.block_edit && <div className="ml-5 border-t border-gray-200 py-2.5">
                                        <TextAction onClick={() => {
                                            setSelected({type: "add-item", section_id: section.id});
                                        }} text="Add Item"/>
                                    </div>}
                                </div>
                            </Drag>
                        })}
                    </div>
                </Drop>
            </DragDropContext>
        </div>
        <EditSelected onAdd={handleAddItem} community_id={community_id} onUpdate={handleUpdate} sections={sections} selected={selected}
                      setSelected={setSelected}/>

    </div>
};