import React from "react";
import GroupRecentMedia from "../widgets/recent-images";
import {group_demo_data} from "../mock_items";
import {SectionHeader} from "../../../../m3/_legacy_components/entity-grid";
import {TextAction} from "../../../auth/sign-in";
import styled from "styled-components";

export const DynamicMediaGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  grid-gap: 0.5rem;
`;

function AlbumPreview({name,description,cover_image=""}) {
    return <div>
        <div className="bg-gray-100 bg-cover bg-center rounded-md" style={{
            aspectRatio: '1/1',
            backgroundImage: `url(${cover_image})`
        }} />
        <div className="pt-1.5">
            <div className="font-medium text-gray-900 text-sm tracking-tight leading-tight truncate">{name}</div>
            <div className="text-gray-600 text-xs">{description}</div>
        </div>
    </div>
}
function AlbumsContainer({albums=[]}) {
    return <DynamicMediaGrid>
        {albums.map((album) => {
            return <AlbumPreview key={album.id} {...album} />
        })}
    </DynamicMediaGrid>
}
export default function EntityMediaTab({entity_id, demo, entity_type}) {

    const data = demo ? group_demo_data.media : {
        albums: [],
        recent: []
    };

    return <div className="grid gap-4">
        {data.albums.length>0&&<div>
            <div className="pb-1">
                <SectionHeader title="Albums" actions={<>
                    <TextAction text="Add Album" onClick={() => {
                    }}/>
                </>}/>
            </div>
            <AlbumsContainer albums={data.albums} />
        </div>}
        <div>
            <div className="pb-1">
                <SectionHeader title="Recent" actions={<>
                    <TextAction text="Add Photos/Videos" onClick={() => {
                    }}/>
                </>} />
            </div>
            <GroupRecentMedia can_add media={data.recent} group_id={entity_id}/>
        </div>
    </div>
};