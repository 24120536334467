import React, {useState} from "react"
import './styles.css';
import {ChevronDownIcon, ChevronLeftIcon} from "@heroicons/react/24/outline";

export default function Accordion({title = "Details", open, padding_x, meta, subtitle, children, action}) {
    const [expanded, setExpanded] = useState(open);
    return <div className="">
        <div className={`flex h-10 py-1 items-center ${padding_x}`}>
            <div className="flex-grow flex items-baseline space-x-2">
                <div className="text-base tracking-tight font-bold text-gray-800">
                    {title}
                </div>
                <div className="text-sm text-gray-500">
                    {subtitle}
                </div>
            </div>
            <div>
                {action}
            </div>
            <div className="ml-1.5">
                <div onClick={()=>setExpanded(!expanded)} className={`w-5 h-5 p-0.5 rounded-md hover:bg-gray-200 cursor-pointer transition-colors text-gray-500`}>
                    <div className="w-4 h-4">
                        {!expanded?<ChevronLeftIcon />:<ChevronDownIcon/>}
                    </div>
                </div>
            </div>
        </div>
        {meta&&<div className={`pr-1.5 ${padding_x}`}>
            {meta}
        </div>}
        {expanded && <div className={`${padding_x}`}>{children}</div>}
    </div>
};