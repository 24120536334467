import {authFetch} from "../../../../../api/network";

export async function apps_updateConfig(community, group_id, app_id, config, cb) {

    const payload = {
        power_up_uid: app_id,
        community_uid: community.uid,
        member_id: community.member_id,
        group_id: group_id,
        ...config
    };

    return new Promise((resolve, reject) => {

        const res = (resp) => {
            resolve(resp);
            //   global.addToast({text: 'Group email settings updated', intent: 'success'});
        };

        authFetch("/groups/update-power-up", res, res, "POST", {payload});
    })
}

export async function apps_addApp(community,group_id,app_id,cb) {
    const payload = {
        power_up_uid: app_id,
        community_uid: community.uid,
        member_id: community.member_id,
        group_id: group_id,
    };


    return new Promise((resolve, reject) => {

        const res = (resp) => {
            resolve(resp);
            //   global.addToast({text: 'Group email settings updated', intent: 'success'});
        };

        authFetch("/groups/add-power-up", res, res, "POST", {payload});
    })
}

export async function apps_removeApp(community,group_id,app_id,cb) {
    const payload = {
        power_up_uid: app_id,
        community_uid: community.uid,
        member_id: community.member_id,
        group_id: group_id,
    };

    return new Promise((resolve, reject) => {

        const res = (resp) => {
            resolve(resp);
            //   global.addToast({text: 'Group email settings updated', intent: 'success'});
        };

        authFetch("/groups/remove-power-up", res, res, "POST", {payload});
    })
}