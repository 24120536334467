import React, {useState} from "react";
import FormFieldsRenderer, {
    getFieldValue,
    updateFieldValue
} from "../../../../../m3/_legacy_components/form-fields-renderer";
import {useCommunity} from "../../../../../config/community";
import {ModalHeader} from "../../../../../m3/_legacy_components/preview-overlay";
import Button from "../../../../../m3/_legacy_components/button";
import {PrefDivider, PrefSection} from "../../../../unaverse/preferences/tabs/your-account";
import {authFetch} from "../../../../../../api/network";
import {useToasts} from "../../../../../config/toasts";
import {DoubleCheckModal} from "../../../../../m3/_legacy_components/double-check-modal";
import {useNavigate} from "react-router-dom";
import {ToggleWithTitle} from "../../../../../m3/_legacy_components/toggle-with-title";

function GroupVisibility({group,id,fns}) {
    const community = useCommunity();
const toasts = useToasts();

    async function apiCall(endpoint, payload) {
        return await new Promise((resolve, reject) => {
            const r = (resp) => {
                resolve(resp);
            }
            authFetch(endpoint, r, r, "POST", {payload});
        });
    }

    function toggleGroupOpen() {
        const endpoint = group.closed?"/groups/visibility/make-open":"/groups/visibility/make-closed";
        const payload = {
            id,
            member_id: community.member_id,
            community_uid: community.id,
            closed: !group.closed,
            hide_from_non_members: false
        };
        fns.onUpdateCb({
            closed: !group.closed,
            hide_from_non_members: false
        })
        apiCall(endpoint, payload)
            .then(() => {
                toasts.addToast({text:"Settings updated",intent:"success"});
            });
    }


    function toggleHideGroupFromNonMembers() {
        const endpoint = "/groups/visibility/hide-non-members";
        const payload = {
            id,
            member_id: community.member_id,
            community_uid: community.id,
            hide_from_non_members: !group.hide_from_non_members
        };
        fns.onUpdateCb({
            hide_from_non_members: !group.hide_from_non_members
        });
        apiCall(endpoint, payload)
            .then(() => {
                toasts.addToast({text:"Settings updated",intent:"success"});
            });
    }

    function toggleLeadersPageDisplay() {
        const endpoint = "/groups/visibility/leaders-display-toggle";
        const payload = {
            id,
            member_id: community.member_id,
            community_uid: community.id,
            display_leaders: !group.display_leaders
        };

        fns.onUpdateCb({
            display_leaders: !group.display_leaders
        });
        apiCall(endpoint, payload)
            .then((resp) => {
                toasts.addToast({text:"Settings updated",intent:"success"});
            });
    }

    function togglePublicPageDisplay() {
        const endpoint = "/groups/visibility/public-page-toggle";
        const payload = {
            id,
            member_id: community.member_id,
            community_uid: community.id,
            enable_public_page: !group.enable_public_page
        };
        fns.onUpdateCb({
            enable_public_page: !group.enable_public_page
        });
        apiCall(endpoint, payload)
            .then(() => {
                toasts.addToast({text:"Settings updated",intent:"success"});
            });
    }

    function toggleAllowNewApplications() {
        const endpoint = "/groups/visibility/allow-new-applications";
        const payload = {
            id,
            member_id: community.member_id,
            community_uid: community.uid,
            enable_new_applications: !group.enable_new_applications
        };
        fns.onUpdateCb({
            enable_new_applications: !group.enable_new_applications
        });
        apiCall(endpoint, payload)
            .then(() => {
                toasts.addToast({text:"Settings updated",intent:"success"});
            });
    }

    const options = [
        {
            title: "Closed Space",
            subtitle: "Only members will be able to see content.",
            value: group.closed,
            handleChange: ()=>{
                toggleGroupOpen()
            }
        },
        {
            title: "Hide from Non-Members",
            subtitle: "Only members can see this space exists",
            value: group.hide_from_non_members,
            disabled: !group.closed,
            hide: !group.closed,
        handleChange: ()=>{
                toggleHideGroupFromNonMembers()
        }
        },
        {
            title: "Display on Leaders Page",
            subtitle: "This space and its roles will be displayed on the Leaders page.",
            value: group.display_leaders,
            handleChange: ()=>{
                toggleLeadersPageDisplay()
            }
        },
        {
            title: "Public Space Page",
            subtitle: "A public link visible to the world.",
            value: group.enable_public_page,
            disabled: group.closed,
            handleChange: ()=>{
                togglePublicPageDisplay()
            }
        },
        {
            title: "Allow New Space Member Applications",
            subtitle: "Get a unique link people can use to join your space, and the community itself. Organizers will be notified to approve new member applications.",
            value: group.enable_new_applications,
            handleChange: ()=>{
                toggleAllowNewApplications()
            }
        }
    ];

    return <div className="space-y-4">
        {options.map((opt,key)=>{
            if(opt.hide) {
                return null;
            }
            // title,handleChange,value,subtitle
            return <ToggleWithTitle key={key} title={opt.title} subtitle={opt.subtitle} checked={!!opt.value} onToggle={()=>{
                opt.handleChange()
            }} />
        })}
    </div>
}

export function ModalEditFieldBlock({
                                        title = "",
                                        goBack = () => {
                                        },
                                        initial,
                                        fields = [],
                                        endpoint = '',
                                        addToast,
                                        toast_success = "",
                                        handleSaved,
                                        base_payload = {},
                                        community
                                    }) {
    const [state, setState] = useState("");
    const [v, setV] = useState(initial || {});

    function handleOnChange(accessor, new_value, old_value, field, extra) {
        let nv2 = updateFieldValue(accessor, new_value, v, field);
        let ex = {};
        if(extra&&extra.place_id) {
            ex.location_place_id = extra.place_id;
        }
        setV({
            ...nv2,
            ...ex
        })
        setState("unsaved")
    }

    function handleSave() {
        setState("saving");

        let payload = {
            community_uid: community.id,
            member_id: community.member_id,
            ...base_payload
        };

        fields.forEach(f => {
            if (f.final_field) {
                payload[f.final_field] = getFieldValue(f.accessor, v);
            } else {
                if (!f.accessor && f.getData) {
                    payload = {
                        ...payload,
                        ...f.getData(v)
                    }
                } else {
                    payload[f.accessor] = getFieldValue(f.accessor, v);
                }

                if(f.extra_fields) {
                    f.extra_fields.forEach(ef=>{
                        const ef_value = getFieldValue(ef.accessor, v);
                        if(ef_value) {
                            payload[ef.accessor] = ef_value;
                        }
                    })
                }
            }
        })

        const res = () => {
            setState("");
            handleSaved();
            addToast({text: toast_success, intent: "success"});
        }

        console.log("payload", payload,)
        authFetch(endpoint, res, res, "POST", {payload});
    }

    return <div>
        <ModalHeader goBack={goBack} sizing="h-8 px-0"
                     divider={false}
                     title={title}/>
        <div className="pt-2">
            <FormFieldsRenderer fill onChange={handleOnChange} data={v} fields={fields}/>
        </div>
        {state && <div className="mt-4 flex">
            <div className="flex-grow"/>
            <div className="">
                <Button disabled={!v} loading={state === "saving"} text="Save" intent="success"
                        onClick={() => handleSave()}/>
            </div>
        </div>}
    </div>
}

function buildSections(group, community, setPage, flags) {
    let a = [];

    a.push({
        title: "Rename Space",
        subtitle: `${group.name}`,
        onClick: () => setPage("name")
    });


    a.push({
        title: "Edit Details",
        subtitle: `Purpose`,
        onClick: () => setPage("details")
    });

    a.push({
        title: "Edit Location",
        subtitle: `${group.location||""}`,
        onClick: () => setPage("location")
    });

    a.push({
        title: "Change Handle",
        subtitle: `@${group.handle}`,
        onClick: () => setPage("handle")
    });

    a.push({
        divider: true
    });

    a.push({
        title: "Visibility",
        subtitle: ``,
        onClick: () => setPage("visibility")
    });

    if(!flags.is_everyone_space) {
        a.push({
            divider: true
        });

        a.push({
            title: "Delete Space",
            subtitle: ``,
            intent: 'danger',
            onClick: () => setPage("delete")
        });
    }

    return a;
}

export function GroupManageGeneral({group, id, flags, fns, onDelete, onSaved}) {
    const community = useCommunity();
    const navigate = useNavigate();
    const [page, setPage] = useState("");

    const toasts = useToasts();

    function handleDelete() {
        const ep = "/groups/delete";
        const res = () => {
            navigate(`/c/${community.domain}/home?ref=deleted_group`);
            toasts.addToast({text: "Space Deleted", intent: "success"});
            onDelete();
        };
        const pl = {
            community_uid: community.id,
            member_id: community.member_id,
            id: group.id
        };
        authFetch(ep, res, res, "POST", {payload: pl});
    }

    function handleSaved() {
        if (onSaved) {
            onSaved();
        }
    }

    function renderPage() {
        switch (page) {
            case "location":
                return <div>
                    <ModalEditFieldBlock
                        goBack={() => setPage("")}
                        addToast={toasts.addToast}
                        handleSaved={handleSaved}
                        initial={group}
                        community={community}
                        base_payload={{
                            id: group.id
                        }}
                        fields={[
                            {
                                label: "Location",
                                accessor: "location",
                                type: "location",
                                input_props: {
                                    autocomplete: "cities"
                                },
                                extra_fields: [{accessor: 'location_place_id'}]
                            }
                        ]}
                        toast_success="Location changed"
                        endpoint="/groups/edit-location"
                        title="Edit Location"
                    />
                </div>
            case "name":
                return <div>
                    <ModalEditFieldBlock
                        goBack={() => setPage("")}
                        addToast={toasts.addToast}
                        handleSaved={handleSaved}
                        initial={group}
                        community={community}
                        base_payload={{
                            id: group.id
                        }}
                        fields={[
                            {
                                label: "Name",
                                accessor: "name",
                                type: "text",
                                required: true
                            },
                            {
                                label: "Symbol",
                                accessor: "",
                                getData: (a) => {
                                    return {
                                        profile_picture: a?.profile_picture,
                                        profile_picture_color: a?.profile_picture_color,
                                        color: a?.color,
                                        emoji: a?.emoji,
                                        type: a.profile_picture ? "small-image" : a.emoji ? "emoji" : "text"
                                    }
                                },
                                input_props: {
                                    initial: group.name.charAt(0).toUpperCase()
                                },
                                type: "symbol"
                            }
                        ]}
                        toast_success="Name changed"
                        endpoint="/groups/rename"
                        title="Rename Space"
                    />
                </div>
            case "details":
                return <div>
                    <ModalEditFieldBlock
                        goBack={() => setPage("")}
                        addToast={toasts.addToast}
                        handleSaved={handleSaved}
                        initial={group}
                        community={community}
                        base_payload={{
                            id: group.id
                        }}
                        fields={[
                            {
                                label: "Purpose",
                                accessor: "purpose",
                                type: "bio"
                            }
                        ]}
                        toast_success="Details changed"
                        endpoint="/groups/update-purpose"
                        title="Details"
                    />
                </div>
            case "visibility":
                return <div>
                    <ModalHeader goBack={()=>setPage("")} sizing="h-8 px-0"
                                 divider={false}
                                 title={"Visibility"}/>
                    <div className="pt-3">
                    <GroupVisibility fns={fns} flags={flags} id={id} group={group} />
                    </div>
                </div>
            case "access":
                return <div>
                    <ModalHeader goBack={()=>setPage("")} sizing="h-8 px-0"
                                 divider={false}
                                 title={"Access"}/>
                    <div>
                        Post to the Feed
                        Create a new Post on the Space feed
                        Reply to Messages
                        Leave replies to any Space Message
                        View Drive
                        General access to the drive contents
                    </div>
                </div>
            case "handle":
                return <div>
                    <ModalEditFieldBlock
                        goBack={() => setPage("")}
                        addToast={toasts.addToast}
                        handleSaved={handleSaved}
                        initial={group}
                        community={community}
                        base_payload={{
                            id: group.id
                        }}
                        fields={[
                            {
                                input_props: {
                                    default_text: group.handle,
                                    community_id: community.id,
                                },
                                label: "Handle",
                                accessor: "handle",
                                final_field: 'new_handle',
                                type: "handle"
                            }
                        ]}
                        toast_success="Handle changed"
                        endpoint="/groups/change-handle"
                        title="Change Handle"
                    />
                </div>
            default:
                return <div>
                    {sections.map((sec, ind) => {
                        if (sec.divider) {
                            return <PrefDivider key={ind} {...sec} />
                        }
                        return <PrefSection key={ind} {...sec} />
                    })}
                </div>
        }
    }

    const is_everyone_space = community?.profile?.everyone_group_id === group.id

    const sections = buildSections(group, community, setPage, {
        is_everyone_space
    });

    return <div className="">
        {renderPage()}

        {page === "delete" && !is_everyone_space && <DoubleCheckModal
            onCancel={() => {
                setPage("");
            }}
            onConfirm={() => {
                handleDelete();
            }}
            type="group_delete"/>}
    </div>
}