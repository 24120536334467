import React, {useState, useEffect} from 'react';
import dayjs from "dayjs";
import {formatFileSize} from "../../../m3/icons/file-helpers";
import {useCommunity} from "../../../config/community";
import {copyTextToClipboard} from "../../../m3/utilities/copy-to-clipboard";
import {useToasts} from "../../../config/toasts";
import {APP_BASE_URL} from "../../../config/defaults";
import {InlineLoader} from "../../../m3/_legacy_components/admin-activity";
import EntityCard from "../../../m3/_legacy_components/entity-card";
import {CloudArrowDownIcon, LinkIcon} from "@heroicons/react/24/outline";
import {getItemFromLocalStorage} from "../../../m3/utilities/localstorage";
import {SimpleTable} from "./simple-table";
import {collection, onSnapshot, query, where} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {useNavigate} from "react-router-dom";
import {getFileTypeIcon} from '../../../m3/icons/file-helpers';
import {SectionHeader} from "../../../m3/_legacy_components/entity-grid";
import {TextAction} from "../../auth/sign-in";
import {utils_dates_format, utils_dates_getPrettyTime} from "../../../../common/utilities/dates";

export function handleSetFiles(snap,community_uid) {
    let files = {};
    const docs = snap.docs;
    const len = docs.length;

    for (let i = 0; i < len; i++) {
        let doc = docs[i];
        let item = doc.data();
        item.id = doc.id;
        files[doc.id] = item;
    }

    return files;
}

function processAssets(assets, community_uid, formats) {
    return assets.map((asset, i) => {
        const {_type, id} = asset;

        if (_type === 'folder') {
            return {
                id: `folder-${id}`,
                icon: getFileTypeIcon(asset.type === 'group' ? 'group-folder' : 'folder'),
                type: 'folder',
                data: {
                    name: asset.name,
                    size: '—',
                    folder: asset.folder,
                    last_updated: {
                        date: utils_dates_getPrettyTime(dayjs.unix(asset.updated_at / 1000), '', formats?.relative),
                        datetime: dayjs.unix(asset.updated_at / 1000).format(formats?.date_time),
                        community_uid,
                        member_id: asset.updated_by ? asset.updated_by : asset.created_by
                    }
                },
                raw: {
                    last_updated: asset.updated_at,
                    size: null,
                    name: asset.name.toLowerCase(),
                    ...asset
                }
            };
        } else {
            // file
            return {
                id: `file-${id}`,
                icon: getFileTypeIcon(asset.type),
                type: 'file',
                data: {
                    name: asset.name,
                    folder: asset.folder,
                    link: asset.link,
                    size: formatFileSize(asset.size),
                    last_updated: {
                        date: utils_dates_getPrettyTime(dayjs.unix(asset.updated_at / 1000), '', formats?.relative),
                        datetime: dayjs.unix(asset.updated_at / 1000).format(formats?.date_time),
                        community_uid,
                        member_id: asset.updated_by ? asset.updated_by : asset.created_by
                    }
                },
                raw: {
                    last_updated: asset.updated_at,
                    size: asset.size,
                    name: asset.name.toLowerCase(),
                    ...asset
                }
            };
        }
    });
}

export function sortFilesFolders(files, folders) {
    let _folders = [];
    let _files = [];

    const keys = Object.keys(folders);

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        const item = folders[key];
        _folders.push({
            _type: 'folder',
            ...item
        });
    }
    const keys_files = Object.keys(files);

    for (let i = 0; i < keys_files.length; i++) {
        const key = keys_files[i];
        const item = files[key];
        _files.push({
            _type: 'file',
            ...item
        });
    }

    return _folders.sort((a, b) => (b.updated_at - a.updated_at)).concat(_files.sort((a, b) => (b.updated_at - a.updated_at)));
}

function getLastUpdated(ts,formats) {
    return `Last updated ${dayjs.unix(ts / 1000).format(formats?.weekday)}`;
}

function buildFolderItem(fo,formats) {

    return {
        id: fo.id,
        title: fo.data.name,
        subtitle: `${getLastUpdated(fo.raw.updated_at,formats)}`,

        tags: [],

        icon: {
            emoji: fo.data.emoji,
            profile_picture: "",
            profile_picture_color: "",
            color: ""
        },

        show_title: false,

        cover: {
            url: "",
            color: ""
        },

        title_icon: null,
        title_icon_description: "",

        meta: null,

        action: null,

        stats: []
    };
}


export function handleSetFolders(type, snap, community_uid,{setLength,setNames}) {
    let folders = {};
    const docs = snap.docs;
    const len = docs.length;
    for (let i = 0; i < len; i++) {
        let doc = docs[i];
        let item = doc.data();
        item.id = doc.id;
        folders[doc.id] = item;

        /*
        const lse = convertEntityForLocalStorage(community_uid, 'folders', item);

        saveEntityToLocalStorage(community_uid, 'folders', doc.id, lse);

         */
    }
    const key = type === 'public' ? 'public_folders' : 'private_folders';
    return folders;
}

export function getFileMap(files) {
    let items = Object.entries(files);
    let map = {};
    let ordered_hold = sortFilesFolders(files, []);
    let map_names = {};
    for (let i = 0; i < items.length; i++) {
        map[items[i][0]] = i;
        map_names[items[i][1].name] = true;
    }
    const ordered = ordered_hold.map(fil => {
        return {
            id: fil.id,
            name: fil.name
        }
    });
    return {
        map,
        ordered,
        map_names
    };
}

export default function FilesScrollable({
                                            folder,
                                            in_group,
                                            base,
                                            display = 'table',
                                            is_mobile,
                                            can_edit,
                                            selected,
                                            group_top_level_folder,
                                            hide_go_up,
                                            setNames,
                                            setLength,
                                            setList,
                                            updateSelected,
                                            language,
                                            top_level,
                                            parent_folder,
                                        }) {
    const toasts = useToasts();
    const navigate = useNavigate();
    const community = useCommunity();
    const [files, setFiles] = useState(!folder?[]:null);
    const [folders, setFolders] = useState(null);

    useEffect(function () {
        setFiles(!folder?[]:null);
        setFolders(null);
        const folder_ref = collection(db, 'community_entities', community.uid, 'folders');
        const folder_q = query(folder_ref, where('folder', '==', folder), where(`user_uids.${community.member_id}`, '==', true));
        let folders_sub = onSnapshot(folder_q, (snap) => {
                const f = handleSetFolders('public', snap, community.uid, {setNames, setLength});
                if (setLength) {
                    setLength(f.length, 'folders');
                }
                if (setNames) {
                    const arr = Object.values(f).map(fol => fol.name);
                    setNames(arr, 'public');
                }
                setFolders(f);
            });

        const files_ref = collection(db, 'community_entities', community.uid, 'files');
        const files_q = query(files_ref, where('folder', '==', folder));

        let files_sub = !folder ? ()=>{

        } : onSnapshot(files_q, (snap) => {
                const fi = handleSetFiles(snap, community.uid, {setNames, setLength});
                if (setList) {
                    setList(getFileMap(fi))
                }
                if (setLength) {
                    setLength(fi.length, 'files');
                }
                setFiles(fi);
            });

        return () => {
            folders_sub();
            files_sub();
        }
    }, [folder])

    function goToItem(itid,entry) {
        console.log('go to item:',itid,entry)
        let to = '';
        if (entry.type === 'folder') {
            to = `${base}/folder/${itid.split('-')[1]}`;
            console.log('to:',to);
            navigate(to)
        } else {
            to = `${base}/folder/${entry.data.folder}/file/${itid.split('-')[1]}`;
            console.log('to:',to);
            navigate(to)
        }
    }

    let headers = [
        {
            width: '2fr',
            label: 'Name',
            field: 'name',
            type: 'text',
            title: true
        }
    ];


    if (!in_group) {
        headers.push({
            width: '1.2fr',
            label: 'Last modified',
            type: 'date-member',
            hidden: is_mobile,
            field: 'last_updated'
        });
        headers.push({
            width: '0.8fr',
            label: 'Size',
            type: 'number',
            hidden: is_mobile,
            field: 'size'
        });
    } else {
        headers.push({
            width: '0.6fr',
            label: 'Last modified',
            type: 'date',
            hidden: is_mobile,
            field: 'last_updated'
        });
    }

    if (!files || !folders) {
        return <InlineLoader padding/>;
    }

    let assets = sortFilesFolders(files, folders);

    const formats = {
        relative: "MMMM D, YYYY",
        date_time: "MMMM D, YYYY",
        weekday: "dddd"
    };

    const final = processAssets(assets, community.uid, formats);

    function goUp() {
        if (parent_folder === '' || group_top_level_folder === parent_folder) {
            navigate(`${base}`)
        } else {
            navigate(`${base}/folder/${parent_folder}`)
        }
    }

    if (display === 'drive') {
        return <div className="grid gap-4 pt-1" style={{gridTemplateColumns: `repeat(auto-fill, minmax(300px, 1fr))`}}>
            {final.map(it => {
                const dt = buildFolderItem(it,formats);
                return <EntityCard onClick={(id) => {
                    goToItem(id,it);
                }} key={`${it.id}`} {...dt} />
            })}
        </div>
    }
    /*
      <TextAction text="New File/Folder" onClick={() => {
                }}/>
     */

    return <div className="relative">
        <div className="pb-1">
            <SectionHeader title="Drive" actions={<>
            </>} />
        </div>
        <SimpleTable localstorage_key={`folder-${folder}`} selected={selected}
                     updateSelected={(ids) => updateSelected(ids)} onGoUp={() => goUp()}
                     go_up={!top_level && !hide_go_up} actions={[{
            label: 'Copy Link',
            icon: <LinkIcon/>,
            types: ['folder', 'file'],
            onClick: (id, entry) => {
                // todo distinguish file and folder
                let path = '';
                if (entry.type === 'folder') {
                    path = `/${base}/folder/${id.split('-')[1]}`;
                } else {
                    path = `/${base}/folder/${entry.data.folder}/file/${id.split('-')[1]}`;
                }
                copyTextToClipboard(`${APP_BASE_URL}${path}`);
                toasts.addToast({text: "Link copied to clipboard!", intent: 'success'});
            }
        },
            {
                label: 'Download',
                icon: <CloudArrowDownIcon/>,
                types: ['file'],
                onClick: (id, entry) => {
                    window.open(entry.data.link, '_blank');
                }
            }]} onItemClick={(itid, entry) => goToItem(itid, entry)} items={final} headers={headers}
                     default_sort={getItemFromLocalStorage(`folder-${folder}`, {
                         field: 'last_updated',
                         dir: 'desc'
                     })} can_select={can_edit} stripe_color={"bg-white"}/>
    </div>;
}