import React from "react";
import {ProfilePictureField} from "../../../../m3/_legacy_components/form/profile-picture";
import FormFieldsRenderer from "../../../../m3/_legacy_components/form-fields-renderer";
import {buildImageUrl} from "../../../../../common/utilities/images";

const sec_1 = [
    {
        label: "First Name",
        accessor: "about.first_name",
        required: true
    },
    {
        label: "Last Name",
        accessor: "about.last_name"
    },
    {
        label: "Middle Name",
        accessor: "about.middle_name"
    },
    {
        label: "Title",
        accessor: "about.title",
        input_props: {
            placeholder: "Dr."
        }
    },
    {
        label: "Headline",
        accessor: "about.headline",
        type: 'headline',
        max_length: 160,
        corner_hint: "",
        col_span: 2
    },
    {
        label: "Bio",
        accessor: "about.bio",
        type: 'bio',
        max_length: 280,
        corner_hint: "",
        col_span: 2
    }
];

export function EditMemberAbout({data,onChange,type='member'}) {

    function handleOnChange(accessor,new_value,old_value,field) {
        onChange(accessor,new_value,old_value,field)
    }

    function handleProfilePictureChange(url,color) {
        handleOnChange(['profile_picture','profile_picture_color'],[url,color])
     }

    return <div className="space-y-3 pb-4" id="edit-intro">
        <div className="grid about-grid-cols gap-10">
            <div>
                <FormFieldsRenderer onChange={handleOnChange} data={data} fields={sec_1}/>
            </div>
            <div className="flex justify-center p-2">
                <ProfilePictureField onProfilePictureChange={handleProfilePictureChange} type={type} url={buildImageUrl(data.profile_picture)} />
            </div>
        </div>
    </div>
}