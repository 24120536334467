import React from "react";
import {useToasts} from "../../../config/toasts";
import {contacts_actions, contacts_buildSortOptions} from "./utilities";
import {getItemFromLocalStorage, saveItemToLocalStorage} from "../../../m3/utilities/localstorage";
import {useListData} from "./hooks";
import {InlineLoader} from "../../../m3/_legacy_components/admin-activity";
import {ContactsImportExportButton, ContactsSelectedActionsBar, ViewSelectorButton} from "./components";
import M3_C_GridView from "../../../m3/components/grid-view";
import M3_C_ListView from "../../../m3/components/list-view";
import M3_C_Header from "../../../m3/components/header";
import {useCommunity} from "../../../config/community";
import {ContactsModals} from "./modals";

import {member_changeProfilePicture} from "../member/api";
import {useParamActions, useUndoManager} from "../../../m3/hooks";
import {getCanShowAge} from "../../../m3/examples/field-privacy-control";
import {m3_icon_map} from "../../../m3/icons/icon-map";
import SimpleVirtualizedList from "../../../m3/components/simple-virtualized-list";
import {useNavigate} from "react-router-dom";
import {opt_in_getCurrentMembershipPeriodID} from "../../../features/gates/opt-in-utilities";
import {buildImageUrl} from "../../../../common/utilities/images";

function handleAddMemberOverlay(community, list, setAction) {
    community.openEditOverlay({
        type: 'add-member',
        data: {
            flow: 'add-to-group',
            space_id: list.context_id,
            group_id: list.context_id,
            group_data: {
                id: list.context_id,
                name: list.label
            }
        },
        closeCb: () => {
            setAction({type: "refresh"});
        }
    })
}

function buildActionsMessages(config,community) {
    let a = [];

    community.policies.forEach(policy => {
        a.push({
            label: `Send Email with Policy: ${policy.name}`,
            onClick: () => {
                contacts_actions.handleOpenComposer(config, community.openComposer, "policy-update", "policy-update", {policy});
            }
        });
    })

    if(community?.profile?.preferences?.members?.enable_member_opt_in) {
        const current_membership_period_data = opt_in_getCurrentMembershipPeriodID(community.profile, [], true)
        a.push({
            label: `Send Opt-in Email for ${current_membership_period_data.label}`,
            onClick: () => {
                contacts_actions.handleOpenComposer(config, community.openComposer, "opt-in", "opt-in", {
                    period: current_membership_period_data
                });
            }
        });
    }

    return [
        ...a,
        {
            label: "Data Check",
            onClick: () => contacts_actions.handleOpenComposer(config, community.openComposer, "data-check", "data-check")
        },
        {
            label: "Community Invite",
            onClick: () => contacts_actions.handleOpenComposer(config, community.openComposer, "new-member-invite", "invite")
        }
    ]
}

export function ContactsMain(props) {
    const toasts = useToasts();
    const community = useCommunity();
    const navigate = useNavigate();
    const [last_updated_at, setLastUpdatedAt] = React.useState(Date.now());
    const [query, setQuery] = React.useState("");
    const [selected, setSelected] = React.useState([]);
    const [modal, setModal] = React.useState("");
    const [modal_data, setModalData] = React.useState(null);
    const [action, setAction] = React.useState(null);
    const [search_open, setSearchOpen] = React.useState(false);
    const [unsaved_changes, setUnsavedChanges] = React.useState(false);
    const [show_advanced, setShowAdvanced] = React.useState(getItemFromLocalStorage(`${community.uid}-contacts-show-advanced`, false));
    const [list_data, setListData, active_view_id, setActiveViewId, active_view_data, setActiveViewData] = useListData(props.active, props.lists, community.uid);
    const actions_handler = useParamActions([
        {
            key: "action",
            value: "create",
            ready: !!list_data,
            handler: function () {
                handleAddMemberOverlay(community, list_data, setAction);
            }
        },
        {
            key: "modal",
            value: "export",
            ready: !!list_data,
            handler: function () {
                console.log("export NOW")
                setModal("export");
            }
        }
    ]);

    const [addCommand, clearCommands, undo, redo, hasUndo, hasRedo] = useUndoManager(10);

    if (!list_data || !active_view_id || !active_view_data) {

        return <div className="p-24">
            <InlineLoader/>
        </div>
    }

    const transforms = [
        {
            field: "profile_picture",
            transform: (value) => {
                return value || community.default_member_profile_picture
            }
        },
        {
            field: "about.headline",
            transform: (value) => {
                return value || ""
            }
        },
        {
            field: "birthday",
            transform: (value, record) => {
                // respect show age privacy
                if (!value || !value.year) {
                    return value;
                }
                const show_age = getCanShowAge(record);
                return {
                    ...value,
                    hide_year: !show_age
                }
            }
        }
    ];


    const list_config = {
        data: {
            list_id: list_data.id || props.active,
            list: list_data,
            attributes: props.attributes,
            transforms
        },

        context: {
            community_uid: community.uid,
            member_id: community.member_id
        },

        state: {
            last_updated_at,
            query,
            action,
            active_view_data,
            active_view_id,
            search_open,
            selected,
            unsaved_changes: unsaved_changes && !!community.member_access.manage_members,
            show_advanced,
            show_toolbar: !props.mobile,
            is_mobile: props.mobile
        },
        features: {
            can_customize: !!community.member_access.manage_members,
            can_edit_views: !!community.member_access.manage_members,
            can_add_views: !!community.member_access.manage_members,
            can_edit_records: !!community.member_access.manage_members,
            can_add_records: !!community.member_access.manage_members,
            can_select: !!community.member_access.manage_members
        },
        fns: {
            addCommand,
            clearCommands,
            undo,
            redo,
            hasUndo,
            hasRedo,

            update: () => {
                setLastUpdatedAt(Date.now());
            },

            goToSpace: list_data?.context === "space" ? () => {
                navigate(`/c/${community.domain}/space/${list_data.context_id}`)
            } : null,

            setQuery,
            transformPartial: contacts_actions.transformPartialResult,
            expandImage: (id, dt) => {
                community.openImage(buildImageUrl(dt.profile_picture, ''))
            },
            editColumnLabel: (accessor_key, dta) => {
                const dt = props.attributes.find(a => a?.col_data?.accessorKey === accessor_key);
                if (!dt) {
                    return;
                }
                setModalData({
                    data: dta,
                    id: dt.value
                })
                setModal("edit-attribute")
            },
            handleEditAttribute: (id, dt) => {
                const attribute_id = props.attributes.find(a => a?.col_data?.accessorKey === id)?.value;
                setModalData({
                    data: dt,
                    id: attribute_id
                })
                setModal("edit-attribute")
            },
            editProfilePicture: (id, dt, cb) => {
                community.openEditOverlay({
                    type: 'edit-profile-picture',
                    size: "33rem",
                    data: {
                        community_uid: community.uid,
                        onChange: (url, color) => {
                            member_changeProfilePicture(community, {url, color}, id)
                                .then(() => {
                                    console.log("DONE", url, color)
                                    if (cb) {
                                        cb(url, color, id);
                                    }
                                    community.closeEditModal();
                                })
                        }
                    },
                    onClose: () => {
                        community.closeEditModal()
                    }
                })
            },
            addToast: toasts.addToast,
            setActiveViewData,
            setUnsavedChanges,
            setListData,
            clearSelected: () => {
                setAction({type: "clear-selection"})
            },
            getPartialsData: (...rest) => contacts_actions.getPartialsData(list_config, ...rest),
            getData: (...rest) => contacts_actions.handleGetData(list_config, ...rest),
            clearAction: () => {
                setAction(null);
            },
            saveAsNewView: () => {
                console.log("save as new view", active_view_id, active_view_data)
                setModalData({
                    mode: "create",
                    flow: "save-new-view",
                    data: {
                        id: null,
                        name: "",
                        layout: active_view_data?.layout,
                        description: "",
                        config: active_view_data?.config,
                        sort: active_view_data?.config?.sort,
                        filters: active_view_data?.config?.filters,
                        attributes: active_view_data?.config?.attributes
                    }
                })
                setModal("view");
            },
            saveViewChanges: () => contacts_actions.handleSaveViewChanges(list_config),
            discardViewChanges: () => {
                setUnsavedChanges(false);
            },
            toggleAdvanced: () => {
                setShowAdvanced(!show_advanced);

                saveItemToLocalStorage(`${community.uid}-contacts-show-advanced`, !show_advanced)
            },
            onManageRecord: (id, dt) => {
                community.openEditOverlay({
                    type: 'manage-member',
                    data: dt,
                    handle: dt.handle,
                    id: id,
                    closeCb: () => {
                        // updateDocumentsByIds([doc.id])
                        community.closeEditModal()
                    }
                })
            },
            onViewProfile: (id, dt) => {
                community.openPreviewOverlay({
                    type: 'member',
                    handle: dt.handle,
                    id: dt.id,
                    data: dt
                })
            },
            handleUpdateRecord: (...rest) => contacts_actions.handleUpdateRecord(list_config, ...rest),

            onUpdateSort: ({dir, field, label}) => {
                contacts_actions.handleChangeSort({
                    field,
                    dir,
                    label
                }, list_config)
            },
            onUpdateAttributes: (new_atts) => {
                // check if changed from current view, if so indicate change
                contacts_actions.handleUpdateAttributes(new_atts, list_config)
            },
            onCreateView: () => {
                setModalData({
                    mode: "create",
                    flow: "standard"
                })
                setModal("view");
            },
            onChangeView: (view_id) => {
                setActiveViewId(view_id);
            },
            onEditView: (view_id, view_data) => {
                setModalData({
                    mode: "update",
                    flow: "standard",
                    id: view_id,
                    data: view_data
                })
                setModal("view");
            },
            updateSelected: new_selected => {
                setSelected(new_selected);
            }
        },

        sort: {
            options: contacts_buildSortOptions(props.attributes, community),
        },

        filter: {},

        group: {}
    };

    let header_config = {
        size: "base",
        title: list_data?.label,
        subtitle: `${list_data?.size} members`,
        padding_top: props.mobile ? "pt-1" : "",
        show_background: true,
        divider: false,
        sticky: true
    };

    let c, imp;

    if (props.mobile) {
        header_config.onBack = () => props.setActiveList("");
        header_config.divider = true;
        header_config.actions = [];
        if(list_config?.fns?.goToSpace) {
            header_config.actions.push({
                type: "icon",
                icon: m3_icon_map.outlines.goto,
                onClick: () => list_config.fns.goToSpace()
            })
        }
        if (community.member_access.manage_members) {
            header_config.actions.push({
                type: "icon",
                intent: "primary",
                icon: m3_icon_map.outlines.add_member,
                onClick: () => {
                    handleAddMemberOverlay(community, list_data, setAction);
                }
            })
        }
        header_config.subnav_bar = {
            left_component: <ViewSelectorButton mobile config={list_config} />, //<ViewSelectorButton mobile config={config}/>,
            variant: "search",
            onEnter: (query) => {
                list_config.fns.setQuery(query)
            },
            onOpenSearch: () => {
                setSearchOpen(true);
            },
            onCloseSearch: () => {
                setSearchOpen(false);
            }
        };
    } else {
        header_config.inline = true;
        header_config.context_bar = {
            variant: "nav",
            icon: "",
            actions: []
        };
        if (!props.layout_open) {
            header_config.context_bar.icon = "menu";
            header_config.context_bar.onIconClick = () => {
                props?.layout_onToggleOpen();
            }
        }

        if (community.member_access.manage_members) {
            imp = <ContactsImportExportButton onImport={() => {
                setModal("import");
            }} onExport={() => {
                setModal("export");
            }}/>;

            header_config.context_bar.actions.push({
                type: "icon",
                intent: "primary",
                icon_name: "add_member",
                onClick: () => {
                    handleAddMemberOverlay(community, list_data, setAction);
                }
            });
        }
    }

    const viewer_props = {
        inline_sidebar_width: props.inline_sidebar_width,
        config: list_config,
        renderEmptyState: () => {
            // <M3_A_BlankSlate title="Add a Member" icon={m3_icon_map.outlines.member}/>
            return null;
        }
    };

    if(props.mobile) {
        if (active_view_data.layout === "grid") {
            c = <M3_C_GridView {...viewer_props}>
                {imp}
            </M3_C_GridView>
        } else {
            const mobile_page_size = Math.floor((window.innerHeight / 41) * 1.5);
            c = <SimpleVirtualizedList page_size={mobile_page_size} config={list_config}/>
        }
    } else {
        if (active_view_data.layout === "grid") {
            c = <M3_C_GridView {...viewer_props}>
                {imp}
            </M3_C_GridView>
        } else {
            c = <M3_C_ListView {...viewer_props}>
                {imp}
            </M3_C_ListView>;
        }
    }

    return <>
        <M3_C_Header {...header_config} />
        {c}
        {list_config.state.selected.length > 0 && <ContactsSelectedActionsBar
            selected={list_config.state.selected}
            onClearSelected={list_config.fns.clearSelected}
            actions_messages={buildActionsMessages(list_config,community)}
            onAddToSpace={()=>{
                setModal("add-to-space");
            }}
            onBulkEdit={() => {
                setModal("bulk-edit");
            }}
        />}
        <ContactsModals {...{modal, setModal, modal_data, setModalData, config: list_config}} />
    </>
}