import React from 'react';
import {useCommunity} from "../../../config/community";
import {m3_icon_map} from "../../../m3/icons/icon-map";
import {CommunityIndexPage, PageLayoutSection} from "../../../m3/_legacy_components/app-frame/layouts";

export function ContactsMobileSelectionPage({
                                                children
                                            }) {
    const community = useCommunity();

    return <CommunityIndexPage header={{
        title: "Contacts",
        actions: [
            {
                type: "icon",
                icon: m3_icon_map.outlines.add_member,
                intent: "primary",
                onClick: () => {
                    community.openEditOverlay({
                        type: 'add-member',
                        closeCb: () => {
                            console.log("DONE")
                            // setAction({type: "refresh"});
                        }
                    })
                }
            }
        ]
    }}>
        <PageLayoutSection padding_x="" divider={false}>
        {children}
        </PageLayoutSection>
    </CommunityIndexPage>;
}