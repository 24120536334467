import React, {useEffect, useState} from "react";
import Form from "./index";
import Field from "./field";
import Button from "../button";
import {PrettyCheckbox} from "../toggle";

export default function FormBuilder({options={},changeHook=()=>{},onSubmit,canSubmit=()=>true,submit_text="Save Changes",loadData,init_data={},fields=[]}) {
    const [_data,setData] = useState({...init_data});
    const [changes,setChanges] = useState({});
    const [add_another,setAddAnother] = useState(false);

    useEffect(function () {
        if(loadData) {
            loadData()
                .then(dt=>{
                    setData({...dt});
                })
        }
    }, []);

    const is_loaded = !loadData || (!!loadData && Object.keys(_data).length>0);

    const changes_made = Object.keys(changes).length>0;

    function handleChange(id,v) {
        let nd = {..._data};
        nd[id] = v;
        let nc = {...changes};
        nc[id] = v;

        setData(nd);

        setChanges(nc);

        changeHook(nd,setData,id)
    }

    function errCb(err) {
        console.error(err)
        alert('something went wrong.. check console')
        setData({...init_data});
        setChanges({});
    }

    function saveCb() {
        setData({...init_data});
        setChanges({});
    }

    if(!is_loaded) {
        return <div>
            loading..
        </div>
    }

    return <Form onSave={onSubmit}>
        {fields.map((field,index)=>{
            const value = _data[field.id];
            return <div key={`${field.id}-${index}`}>
                <Field autoFocus={index===0} label={field.label} id={field.id} onChange={handleChange} value={value} type={field.input_type} input_props={field.input_props} />
            </div>
        })}
        <div className="flex items-center">
            <div className="flex-grow">
                {options.enable_add_another&&<div>
                    <PrettyCheckbox label="Add Another" active={add_another} onClick={()=>setAddAnother(!add_another)} />
                </div>}
            </div>
            <div className="flex space-x-2">

                <Button disabled={!changes_made||!canSubmit(_data)} onClick={()=>onSubmit(_data,saveCb,errCb,changes)} text={submit_text} intent="success" />
            </div>
        </div>
    </Form>
};