import React from "react";
import {useNavigate} from "react-router-dom";
import {Bars2Icon, XMarkIcon} from "@heroicons/react/20/solid";
import {useAuth} from "../../../config/auth";
import {LogoWordMark} from "./icons";


const navigation = [
    {
        name: "Features",
        href: "https://theorange.app#features",
    },
    {
        name: "What's New",
        href: "https://theorange.app/product-updates",
    }
];

const getButtonMetadata = (is_logged_in) => {
    return is_logged_in ? {
        secondary: {
            label: "Learn more",
            href: "https://theorange.app#learn-more"
        },
        primary: {
            label: "Create a new Community",
            link: "/create-community"
        }
    } : {
        secondary: {
            label: "Sign in",
            link: "/sign-in"
        },
        primary: {
            label: "Start for free",
            link: "/create-account?ref=create-community"
        }
    };
};

// <span aria-hidden="true">&rarr;</span>
export function MarketingHeader(props) {
    const navigate = useNavigate();
    const {user} = useAuth();

    const is_logged_in = !!user;

    function renderButtons() {
        const metadata = getButtonMetadata(is_logged_in);

        let sp = {}, pp = {};

        if (metadata.secondary.link) {
            sp = {
                onClick: () => navigate(metadata.secondary.link)
            }
        } else {
            sp = {
                href: metadata.secondary.href
            }
        }

        if (metadata.primary.link) {
            pp = {
                onClick: () => navigate(metadata.primary.link)
            }
        } else {
            pp = {
                href: metadata.primary.href
            }
        }

        return <>
            <a {...sp}
               className="text-sm font-medium whitespace-nowrap leading-6 text-gray-800 px-3 inline-flex items-center border rounded-xl h-9 border-gray-300 hover:border-gray-500">
                {metadata.secondary.label}
            </a>
            <a {...pp}
               className="text-sm font-medium  whitespace-nowrap leading-6 text-white px-3 inline-flex items-center border rounded-xl h-9 border-gray-800 bg-gray-800 hover:bg-gray-900">
                {metadata.primary.label}
            </a>
        </>
    }

    const [menu_open, setMenuOpen] = React.useState(false);
    return <header className="udk-menublur border-b border-gray-200 sticky inset-x-0 top-0 z-50">
        <nav className=""
             aria-label="Global">
            <MarketingPageContainer className="flex items-center justify-between gap-12">
            <div className="flex-none flex h-10 items-center">
                <a onClick={()=>{
                    navigate("/");
                }} className="cursor-pointer">
                    <span className="sr-only">Orange</span>
                    <LogoWordMark height="30" width="auto"/>
                </a>
            </div>
            <div className="hidden sm:flex gap-8 flex-grow h-9 items-center">
                {navigation.map((item) => {

                    return <a key={item.name} href={item.href}
                              className="text-base font-medium leading-6 text-gray-700">
                        {item.name}
                    </a>
                })}
            </div>
            <div className="hidden cursor-pointer gap-4 lg:flex lg:flex-1 lg:justify-end h-9 items-center">
                {renderButtons()}

            </div>
            <div className="flex flex-none sm:hidden">
                <div className="h-8 w-8 p-1 text-gray-800" onClick={() => {
                    setMenuOpen(!menu_open);
                }}>
                    <div className="h-6 w-6 ">
                        {menu_open ? <XMarkIcon/> : <Bars2Icon/>}
                    </div>
                </div>
            </div>

            </MarketingPageContainer>
        </nav>
        {menu_open ?
            <div
                className="bg-white fixed border-t border-gray-300 top-16 left-0 right-0 min-h-[20rem] max-h-[36rem] overflow-auto shadow-lg">
                <div className="px-3">
                    {navigation.map((item) => (
                        <a key={item.name} href={item.href}
                           className="px-1 h-14 border-b border-gray-300 block leading-[3.5rem] text-base font-medium text-gray-800">
                            {item.name}
                        </a>
                    ))}
                </div>
            </div> : null}
    </header>
}

export function MarketingPageContainer({children,className}) {
    return <div className={` max-w-[1440px]  px-4 sm:px-6 mx-auto sm:py-4 py-3 ${className}`}>
        {children}
    </div>
}

export function MarketingPage(props) {
    return <div className="bg-white">
        <MarketingHeader/>
        {props.children}
    </div>
}