import React from "react";
import M3_A_Input from "./input";

/*
    This is the ColorInput atom
 */

const _props = {};

/*


                  <input onChange={(e)=>{
                    handleChange(e.target.value);
                }} value={value} type="text" className="w-full" placeholder="#000000" />
 */
export default function M3_A_ColorInput({
                                            onChange,
                                            size,
                                            value
                                        }) {

    return (
        <div className="flex flex-row gap-2">
            <div className="flex-none flex items-center">
                <input
                    className="relative cursor-pointer z-10 w-6 bg-white h-6 rounded-md"
                    type="color" value={value} onChange={onChange}/>
            </div>
            <div className="flex-grow">
                <M3_A_Input value={value} size={size} placeholder="#000000" type="text" onChange={onChange}/>
            </div>
        </div>
    );
}