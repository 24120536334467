import React, {useEffect, useState} from "react";
import M3_A_Text from "../../atoms/text";
import M3_A_Link from "../../atoms/link";
import {copyTextToClipboard} from "../../utilities/copy-to-clipboard";
import M3_A_Label from "../../atoms/label";
import M3_A_InlineEntity from "../../atoms/inline-entity";
import M3_A_Avatar from "../../atoms/avatar";
import M3_A_IconButton from "../../atoms/icon-button";
import {m3_icon_map} from "../../icons/icon-map";
import M3_A_Icon from "../../atoms/icon";
import {getLastName} from "../simple-virtualized-list";
import {M3DataWrapper} from "../../utilities";
import dayjs from "dayjs";
import {InlineLoader} from "../../_legacy_components/admin-activity";
import {data_autocomplete_map} from "../../../../common/data/autocomplete-map";
import {data__member_profile_fields} from "../../../../common/data/data-fields";
import {all_field_types} from "../../../../common/data/field-types";
import {data_system_interests_map} from "../../../../common/data/data/interests";
import {buildImageUrl} from "../../../../common/utilities/images";

function EmptyCellValue() {
    return <M3_A_Text color="text-gray-500">
        —
    </M3_A_Text>
}

function Cell_Link({display, type, value}) {
    const display_value = display?.format ? all_field_types[type]?.formatValue(value) : value;

    if (!value) {
        return <EmptyCellValue/>
    }
    return <M3_A_Text>
        <M3_A_Link onClick={() => {
            copyTextToClipboard(value);
        }} variant="secondary">{display_value}</M3_A_Link>
    </M3_A_Text>
}

function Cell_SelectTaxonomy({type, context, original, value, display}) {
    if(!value || !value?.label) {
        return <EmptyCellValue/>
    }
    return <M3_A_InlineEntity text_color="text-gray-800" size="xs" children={value.label}/>
}

function Cell_MultiSelectTaxonomy({type, context, original, value, display}) {
    if(!value||value.length === 0) {
        return <EmptyCellValue/>
    }
    return <EntityWrapper>
        {value.map((v, index) => {
            return <div key={index}>
                <M3_A_InlineEntity text_color="text-gray-800" size="xs" children={v.label}/>
            </div>
        })}
    </EntityWrapper>
}

function Cell_Select({display, value}) {
    let v = "", data = {}, token_type = "", token_color = "";

    if (!!value && display?.autocomplete === "value_options") {
        data = display?.value_options?.find(item => item.value === value);
        if (display.variant === "label") {
            return <M3_A_Label children={data?.label || ""}/>;
        }
    } else if (display?.autocomplete && !!value && display?.variant) {
        data = data_autocomplete_map?.[display.autocomplete]?.data.find(item => item.value === value);
        if (display.variant === "label") {
            return <M3_A_Label children={data?.label || ""}/>;
        } else {
            token_type = display.variant;
            if (data) {
                v = data.label;
                if (data?._meta?.color) {
                    token_color = data._meta.color;
                }
            }
        }
    }

    return <M3_A_InlineEntity compact is_interactive color_shape={token_type}
                              color={token_color}>{v}</M3_A_InlineEntity>;
}

function Cell_Date({display, original, context, type, value}) {
    if (!value) {
        return <EmptyCellValue/>
    }

    const _format_type = display?.format || "date";
    const formatted_value = all_field_types[type]?.formatValue(value, {format: _format_type});

    if (!formatted_value) {
        return <EmptyCellValue/>
    }

    return <M3_A_Text>
        {formatted_value}
    </M3_A_Text>
}

const renderMember = (member, display, meta) => {
    if (!member) {
        return <EmptyCellValue/>
    }
    return <M3_A_InlineEntity is_interactive text_color="text-gray-800" size="xs" onClick={() => {
        if (meta?.onViewProfile) {
            meta.onViewProfile(member.id, member);
        }
    }} avatar={buildImageUrl(member?.profile_picture, "_small")} children={member?.name}/>
};

function CellMember({original, context, value, display, meta}) {
    if (!value) {
        return <EmptyCellValue/>
    }
    return <M3DataWrapper context_id={context?.id || original.community_uid} ids={[value]} renderChildren={(records = []) => {
        const member = records.find(item => item?.id === value);
        return renderMember(member, display, meta);
    }}/>
}

const EntityWrapper = ({children}) => {
    return <div className="flex gap-1">
        {children}
    </div>
}

function CellMultiMember({original, value, context, display, meta}) {
    if(!value || Object.keys(value).length === 0) {
        return <EmptyCellValue/>
    }
    const ids = Object.keys(value);

    return <M3DataWrapper context_id={context?.id || original.community_uid} ids={ids} renderChildren={(records = []) => {
            return <EntityWrapper>
                {ids.map((id, index) => {
                    const member = records.find(item => item?.id === id);
                    return <div key={id}>
                        {renderMember(member, display, meta)}
                    </div>
                })}
            </EntityWrapper>
        }}/>
}

function CellAddress({display, value}) {
    if (!value) {
        return <EmptyCellValue/>
    }

    const formatted_value = all_field_types["address"]?.formatValue(value);

    if (!formatted_value) {
        return <EmptyCellValue/>
    }

    return <M3_A_Text>
        {formatted_value}
    </M3_A_Text>
}

function Cell_Number({display, value}) {
    return <M3_A_Text>
        {value}
    </M3_A_Text>
}

function Cell_Text({display, value}) {
    if (typeof value !== "string" || !value) {
        return <EmptyCellValue/>

    }
    if (display?.variant === "id") {
        return <span onClick={() => {
            copyTextToClipboard(value);
        }} style={{
            fontFamily: "monospace", fontSize: "medium", fontWeight: 500
        }}>
{value}
        </span>
    }
    return <M3_A_Text>
        {value}
    </M3_A_Text>;
}

function Cell_MobileName({original, value, display, meta}) {
    if (!value) {
        return <EmptyCellValue/>
    }

    const pp_field = data__member_profile_fields['profile-picture'];
    const avatar = original?.profile_picture ? all_field_types[pp_field.type]?.formatValue(original?.profile_picture, pp_field.display) : "";
    const name = original?.name;

    return <div onClick={() => {
        if (meta?.onViewProfile) {
            meta.onViewProfile(original.id, original);
        }
    }} className="flex h-full gap-2.5 text-gray-800 items-center">
        <div>
            <M3_A_Avatar avatar={avatar} size="base"/>
        </div>
        <div className="flex-grow border-b border-gray-300 h-full flex items-center">
            <M3_A_Text size="base">
                {boldLastName(name)}
            </M3_A_Text>
        </div>
    </div>
}

function boldLastName(val) {
    const parts = val.split(" ");
    const last_name = getLastName(val);
    const first_name = val.replace(last_name, "").trim();
    return <>
        {parts.length === 1 && <span className="font-bold">{val}</span>}
        {parts.length === 2 && <span>{parts[0]} <span className="font-bold">{parts[1]}</span></span>}
        {parts.length > 2 && <span>{first_name} <span
            className="font-bold">{last_name}</span></span>}
    </>
}

function Cell_Subheader({value, original, meta}) {
    return <div className="flex h-full gap-2.5 text-gray-500 items-end">
        <div className="flex-grow border-b border-gray-300 h-full flex items-end pb-1">
            <M3_A_Text weight="font-semibold" size="xs">{value}</M3_A_Text>
        </div>
    </div>
}

function Cell_Chip({original, meta, value}) {
    if (!value) {
        return <EmptyCellValue/>
    }

    const pp_field = data__member_profile_fields['profile-picture'];
    // meta.primary
    // meta.chip_type === "member"
    const avatar = original?.profile_picture ? all_field_types[pp_field.type]?.formatValue(original?.profile_picture, pp_field.display) : "";
    const name = original?.name;

    return <div className="-mx-0.5">
        <M3_A_InlineEntity is_interactive text_color="text-gray-800" size="sm" onClick={() => {
            if (meta?.onViewProfile) {
                meta.onViewProfile(original.id, original);
            }
        }}
                           avatar={avatar} label_sx={{
            textDecoration: "underline",
            textDecorationColor: "#a1a1a1",
            textUnderlineOffset: "3px"
        }}
                           children={name}/>
    </div>
}

// we shouldn't take the type of field directly, e.g. url, email, phone are all just a link
// todo add a copy item

const renderPolicyValue = (value, original, display) => {
    let policy_meta;
    if(value) {
        // accepted
        policy_meta = original?.policies_meta?.[display?.data?.slug];
        return `${policy_meta?.ts ? `${dayjs.unix((policy_meta.ts / 1000)).format('M/D/YY [at] h:mma')}` : ""}`
    }
    return "";
};

function Cell_Policy({display, original, value}) {
    if(!value) {
        return <EmptyCellValue/>
    }

    return <M3_A_Text>
        {renderPolicyValue(value, original, display)}
    </M3_A_Text>
}

const renderOptInValue = (value, original, display) => {
    let opt_in_meta;
    if(value) {
        // accepted
        opt_in_meta = original?.opt_in_meta?.[display?.data?.slug];

        return `${opt_in_meta?.ts ? `${dayjs.unix((opt_in_meta.ts / 1000)).format('M/D/YY [at] h:mma')}` : ""}`
    }
    return "";
}

function Cell_OptIn({display, original, value}) {
    if(!value) {
        return <EmptyCellValue/>
    }

    return <M3_A_Text>
        {renderOptInValue(value, original, display)}
    </M3_A_Text>
}

function Cell_Interests({display, context, original, value}) {
    // show up to 3 interests
    if(!value||Object.keys(value).length === 0) {
        return <EmptyCellValue/>
    }
    const ids = Object.keys(value).slice(0,3);
    return <div className="flex gap-2">
        {ids.map((id, index) => {
            const int = data_system_interests_map[id];
            if(!int) {
                return null;
            }
            return <div key={id}>
                <M3_A_Text>
                    {int.emoji} {int.name}
                </M3_A_Text>
            </div>
        })}
    </div>
}

// todo unify with profile?
export const ViewCellValue = ({original, context, value, display, meta}) => {
    const _type = meta?.type || "text";
    switch (_type) {
        case "blank":
            return <div>

            </div>;
        case "policy":
            return <Cell_Policy original={original} value={value} display={display}/>
        case "opt-in":
            return <Cell_OptIn original={original} value={value} display={display}/>
        case "interests":
            return <Cell_Interests context={context} original={original} value={value} display={display}/>
        case "mobile-name":
            if (original?.type === "subheader") {
                return <Cell_Subheader original={original} meta={meta} value={value}/>
            }
            return <Cell_MobileName original={original} value={value} display={display} meta={meta}/>
        case "chip":
            return <Cell_Chip original={original} value={value} display={display} meta={meta}/>;
        case "inline-entity":
            return <M3_A_InlineEntity is_interactive text_color="text-gray-800" size="base"
                                      avatar="https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2F1868I1QUR5_small?alt=media"
                                      children={value}/>;
        case "text":
            return <Cell_Text value={value} display={display}/>
        case "number":
            return <Cell_Number value={value} display={display}/>
        case "address":
            return <CellAddress value={value} display={display}/>
        case "member":
        case "mentor":
            return <CellMember context={context} original={original} value={value} display={display} meta={meta}/>
        case "mentees":
            return <CellMultiMember context={context} original={original} value={value} display={display} meta={meta}/>
        case "datetime":
        case "date-object":
            return <Cell_Date original={original} context={context} value={value} type={_type} display={display}/>
        case "email":
        case "phone":
        case "url":
            return <Cell_Link value={value} type={_type} display={display}/>
        case "select":
            return <Cell_Select value={value} display={display}/>
        case "select-taxonomy":
            return <Cell_SelectTaxonomy context={context} original={original} value={value} display={display}/>
        case "multi-select-taxonomy":
            return <Cell_MultiSelectTaxonomy context={context} original={original} value={value} display={display}/>
        case "avatar":
            return <M3_A_Avatar avatar={value} size="base"/>
        case "display-name":
            // render where the last name is bold. if it's a single name, render it as bold. if it's a name with more than two parts, render the last part bold
            return <M3_A_Text>
                {boldLastName(value)}
            </M3_A_Text>;
        default:
            return <Cell_Text value={value} display={display}/>
    }
}

const CellAction = ({type = 'edit', original, value, onClick}) => {
    if (type === "edit") {
        return <M3_A_IconButton onClick={() => onClick(original.id, original)} adjust_icon_size={-1} square compact
                                icon={m3_icon_map.outlines['edit']} size="sm"/>;
    } else if (type === "copy") {
        //   return <CopyButton value={value} />
    }
    return null;
};

const Cell = ({info}) => {
    // need to get state.context_id
    const state = info.table.getState();
    const value = info.getValue();
    return <>
        <div className="px-1.5 truncate max-w-full flex-grow">
            <ViewCellValue context={state?.context} original={info.row?.original} value={value} {...info.column?.columnDef} />
        </div>
        {info.column?.columnDef?.action && <div className="flex-none group-hover:block hidden pr-1">
            <CellAction context={state?.context} original={info.row.original} value={value} {...info.column.columnDef.action} />
        </div>}
    </>
};

const Header = ({info}) => {
    const parent_label = info.column?.columnDef?.meta?.parent?.label;
    const content = <div className={`inline-flex justify-start items-center gap-2 px-1.5 w-full`}>
        {info.column?.columnDef?.meta?.icon && <div className={`flex-none w-4 text-gray-600`}>
            <M3_A_Icon size="xs"
                       icon={m3_icon_map.outlines[info.column?.columnDef?.meta?.icon]}/>
        </div>}
        {parent_label && <div className={`${info.header?.index > 0 && `truncate`} shrink text-left`}
                              style={{maxWidth: info.header?.index > 0 ? `${info.column.getSize() - 20}px` : ""}}>
            {parent_label}
        </div>}
        {parent_label && <div className="flex-none -mx-1">
            <M3_A_Icon size="2xs" icon={m3_icon_map.outlines.chevron_right}/>
        </div>}
        <div className={`${info.header?.index > 0 && `truncate`} flex-grow text-left`}
             style={{maxWidth: info.header?.index > 0 ? `${info.column.getSize() - 20}px` : ""}}>
            {info.column?.columnDef?.label}
        </div>
        {info.column?.columnDef?.meta?.enriched &&
            <div className="flex-none w-4 text-orange-600">
                <M3_A_Icon size="xs"
                           icon={m3_icon_map.solid.enriched}/>
            </div>}
        {info.column?.columnDef?.meta?.read_only &&
            <div className="flex-none w-4 text-gray-400">
                <M3_A_Icon size="xs"
                           icon={m3_icon_map.solid.read_only}/>
            </div>}
    </div>;

    return content;
}

function CopyButton({value = ""}) {
    const [copied, setCopied] = useState(false);

    useEffect(function () {
        if (copied) {
            setTimeout(function () {
                setCopied(false)
            }, 500)
        }
    }, [copied])

    function handleCopy() {
        copyTextToClipboard(value, () => {
            setCopied(true);
        });
    }

    if (copied) {
        return <M3_A_IconButton adjust_icon_size={-1} intent="success" square compact icon={m3_icon_map.outlines.check}
                                size="base"/>;
    }

    return <M3_A_IconButton onClick={handleCopy} adjust_icon_size={-1} square compact icon={m3_icon_map.outlines.copy}
                            size="base"/>;
}

export const M3_A_MiniLoadingIndicator = () => {
    return <div style={{
        boxShadow: "0 3px 10px rgba(0,0,0,0.2)"
    }} className="absolute bottom-4 left-4 udk-menublur z-10 px-2.5 py-1.5 rounded-lg flex gap-2">
        <InlineLoader mini/>
        <M3_A_Text size="sm" color="text-gray-700">
            Loading
        </M3_A_Text>
    </div>
};

export const _m3_c_list_view_components = {
    Cell,
    Header
};