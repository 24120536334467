import React from 'react';
import {useIsMobile} from "../../../m3/hooks/is-mobile";
import DataWrapper from "../../../m3/_legacy_components/data-wrapper";
import M3_A_Heading from "../../../m3/atoms/heading";

function Name({data}) {
    return <span>{data?.name}</span>
}

function Item({
                  title = "Name of Event",
                  subtitle,
                  meta,
                  all_day,
                  onClick,
                  is_mobile,
                  time_string = "2pm - 4pm"
              }) {

    function handleClick() {
        if (onClick) {
            onClick();
        }
    }

    if (is_mobile) {
        return <div onClick={handleClick} className="px-2 py-1.5 w-full rounded-md bg-blue-100 text-blue-900">
            <div className="text-sm leading-4 font-medium cursor-pointer hover:underline">{title}</div>
            <div className="pt-px">
                <div className="text-xs leading-4 opacity-80">
                    {all_day ? "All day" : time_string}
                </div>
                {subtitle && <div className="text-xs leading-4 opacity-80">{subtitle}</div>}
            </div>
        </div>
    }

    return <div className="flex hover:bg-gray-100 px-3 rounded-xl transition-colors items-center h-10">
        <div className="w-32 text-sm flex-none text-gray-600">
            {all_day ? "All day" : time_string}
        </div>
        <div className="flex-grow flex gap-3 items-baseline">
            <div onClick={handleClick}
                 className={`text-sm font-medium text-gray-800 cursor-pointer hover:underline`}>{title}</div>
            <div className="flex flex-wrap gap-x-3 gap-y-0.5">
                {subtitle && <div className="text-xs text-gray-600">{subtitle}</div>}
                {meta && <div className="text-xs text-gray-600 flex gap-2">
                    {meta.map((item, index) => {
                        if (item.type === "group" && item.id && item.community_uid && item?.type !== 'birthday') {
                            return <DataWrapper key={item.id} id={item.id}
                                                type={`community_entities.${item.community_uid}.groups`}>
                                <Name/>
                            </DataWrapper>
                        }
                        return <span key={index}>{item}</span>
                    })}
                </div>}
            </div>
        </div>
    </div>
}

function ItemList({children, is_mobile, is_today, date, month, weekday_full, weekday}) {

    const date_box = <div
        className={`text-lg h-6 w-6 text-center flex items-center justify-start rounded-full ${is_today ? "font-black text-red-700" : "font-semibold text-gray-700"}`}>
        {date}
    </div>;

    if (is_mobile) {
        return <div className="min-h-4 flex gap-3 pt-1 pb-3 px-4">
            <div className="flex-none w-8">
                <div className="w-8 text-left">
                    <div className="text-gray-500 text-[0.7rem] uppercase font-medium">{weekday}</div>
                </div>
                {date_box}
            </div>
            <div className="grid grid-cols-1 gap-2 flex-grow pt-0.5">
                {children}
            </div>
        </div>
    }

    return <div className="border-b pl-4 flex gap-2 border-gray-200 min-h-[3.25rem]">
        <div className="flex-none flex pt-0.5 items-baseline h-12">
            <div className="flex-none w-10 h-12 flex items-center">
                {date_box}
            </div>
            <div className="flex-none w-28 h-12 flex items-center">
                <div
                    className={`text-sm tracking-wide ${is_today ? "text-red-700 font-medium" : "text-gray-500"} `}>
                    {weekday_full}
                </div>
            </div>
        </div>

        <div className="flex-grow py-1.5">
            {children}
        </div>
    </div>
}


export default function ScheduleView({items = [], query = ""}) {
    const is_mobile = useIsMobile();

    const empty = items.length === 1 && items[0].items.length === 0;

    // todo use empty state
    return <div className="">
        {(!empty || !query) && items.map(item => {

            if(item.type === "month") {
                return <div className="pt-2 px-4"><M3_A_Heading size={"h6"} key={item.id}>{item.label}</M3_A_Heading></div>
            }

            return <ItemList is_mobile={is_mobile} {...item} key={item.id}>
                {item.items.map(evt => {
                    return <Item is_mobile={is_mobile} key={evt.id} {...evt} />
                })}
            </ItemList>
        })}
        {empty && query && <div className="text-center text-sm text-gray-500 py-4">No events found</div>}
    </div>
}