import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {db} from "../../../config/setup-firestore";
import {collection, doc, getDoc, getDocs, limit, orderBy, query, where} from "firebase/firestore";
import Button from "../../../m3/_legacy_components/button";
import {authFetch} from "../../../../api/network";
import EntityTabs from "../../../m3/_legacy_components/entity-tabs";
import MetaHeader from "../../../m3/_legacy_components/meta-header";
import {ManageModalFrame} from "../../community/manage-member";
import EditModal from "../../../m3/_legacy_components/edit-modal";
import dayjs from "dayjs";
import Accordion from "../../../m3/_legacy_components/accordion";
import DetailList from "../../../m3/_legacy_components/detail-list";
import {ArrowRightIcon, AtSymbolIcon, CircleStackIcon, UsersIcon} from "@heroicons/react/24/outline";
import {useToasts} from "../../../config/toasts";
import {TextAction} from "../../auth/sign-in";
import {HandleFixerFrame} from "./handle-fixer";
import {sample_flow_manifest} from "../../../features/gates/flow/sample-manifest";
import {FieldInput} from "../../../m3/_legacy_components/form/field";
import {buildImageUrl} from "../../../../common/utilities/images";


function DuplicatesTab({id}) {
    return <div>
        <DuplicateReport community_uid={id}/>
        <HandleFixerFrame community_uid={id}/>
    </div>
}

async function getReport(cid) {
    return await new Promise((resolve, reject) => {
        const res = (resp) => {
            if (resp?.data?.ok) {
                resolve([resp.data.links, resp.data.analysis])
            }
        };

        const payload = {
            cid: cid
        };

        authFetch("/superadmin/generate-reports", res, res, "POST", {payload});
    });
}

function LeadersTab({id, analysis}) {
    if (!analysis || !analysis.leaders) {
        return ".."
    }
    return <ul className="divide-y divide-gray-200">
        {analysis.leaders.map(leader => {
            const {meta, tags} = leader;
            return <li className="py-2 px-3" key={leader.id}>
                <div className="font-bold text-base">{leader.name}</div>
                <div className="text-sm text-gray-700">
                    <div>status: {leader.account_status}</div>
                    <div>email: {leader.email}</div>
                    <div>last sign
                        in: {leader.last_sign_in ? dayjs.unix(leader.last_sign_in / 1000).format('MMM D, YYYY h:mm a') : "-"}</div>
                    <div>groups: {leader.groups.join(", ")}</div>
                    <div>tags: {leader.tags.join(", ")}</div>
                </div>
            </li>
        })}
    </ul>
}

function GroupsTab({id, analysis}) {
    if (!analysis || !analysis.groups) {
        return ".."
    }
    return <ul className="divide-y divide-gray-200">
        {analysis.groups.map(group => {
            const {meta, tags, member_count} = group;
            return <li className="py-2 px-3" key={group.id}>
                <div className="font-bold text-base">{group.name}</div>
                <div className="text-sm text-gray-700">
                    <div>{member_count} members / {meta.mods_counts} mods / {meta.mods_logged_in_count} logged in
                        ({meta.mods_logged_in_percent}%)
                    </div>
                    <div>{group.leader_names}</div>
                    <div>{group.tags.join(", ")}</div>
                </div>
            </li>
        })}
    </ul>
}

async function getCommunity(id) {

    const ref = doc(db, "communities", id);
    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? doc.data() : null;
        });
}

function createTable(columns = ['name'], rows = [["sean"], ["fabian"]], id = "test") {
    let body = document.getElementsByTagName('body')[0];
    let tbl = document.createElement('table');
    tbl.id = id;
    tbl.style.width = '100%';
    tbl.style.opacity = '0%';
    tbl.setAttribute('border', '1');
    let tbdy = document.createElement('tbody');

    // header
    let tr = document.createElement('tr');
    columns.forEach((col, col_index) => {
        let td = document.createElement('td');
        td.appendChild(document.createTextNode(col))
        tr.appendChild(td)
    })
    tbdy.appendChild(tr);

    // contens
    rows.forEach(row => {
        let tr = document.createElement('tr');
        columns.forEach((col, col_index) => {
            let td = document.createElement('td');
            td.appendChild(document.createTextNode(row[col_index]))
            tr.appendChild(td)
        })
        tbdy.appendChild(tr);
    })

    tbl.appendChild(tbdy);
    body.appendChild(tbl);
}

function selectElementContents(el) {
    let body = document.body, range, sel;
    if (document.createRange && window.getSelection) {
        range = document.createRange();
        sel = window.getSelection();
        sel.removeAllRanges();
        try {
            range.selectNodeContents(el);
            sel.addRange(range);
        } catch (e) {
            range.selectNode(el);
            sel.addRange(range);
        }
    } else if (body.createTextRange) {
        range = body.createTextRange();
        range.moveToElementText(el);
        range.select();
    }
}

function copyDOMTable(cols, rows, id = "demo", cb = () => {
}) {

    createTable(cols, rows, id)

    const el = document.getElementById(id);

    if (!el) {
        console.error('no element');
        return;
    }

    selectElementContents(el);
    el.focus();

    document.execCommand('Copy');

    document.body.removeChild(el);

    cb();
}

function PositionStats({analysis}) {
    const toasts = useToasts();
    if (!analysis) {
        return null;
    }
    const cols = ["Position Name", "Member Name", "Email"];

    const rows = [
        ...analysis.positions.map(leader => {
            return [
                leader.name,
                leader.member_name,
                leader.member_email
            ]
        })
    ].sort((x, y) => x.name > y.name ? 1 : -1);

    const cb = () => {
        toasts.addToast({text: "Copied to clipboard", intent: "info"})
    };

    return <div className="text-xs text-gray-700">
        <div className="mt-2">
            <Button onClick={() => copyDOMTable(cols, rows, "positions-stats", cb)} text="Copy Position Stats"/>
        </div>
    </div>
}

function LeaderStats({analysis}) {
    const toasts = useToasts();
    if (!analysis) {
        return null;
    }

    const cols = ["Name", "Email", "Account Status", "Spaces"];

    const rows = [
        ...analysis.leaders.map(leader => {
            return [
                leader.name,
                leader.email,
                leader.account_status,
                leader.groups.join(", "),
            ]
        })
    ];

    const cb = () => {
        toasts.addToast({text: "Copied to clipboard", intent: "info"})
    };

    return <div className="text-xs text-gray-700">
        <div>Leaders that have not logged in: {analysis.meta.leaders_not_logged_in}</div>
        <div className="mt-2">
            <Button onClick={() => copyDOMTable(cols, rows, "leaders-stats", cb)} text="Copy Leader Stats"/>
        </div>
    </div>
}

function GroupStats({analysis}) {
    const toasts = useToasts();
    if (!analysis) {
        return null;
    }

    const cols = ["Name", "Mods", "Size", "Mods logged in"];

    const rows = [
        ...analysis.groups.map(group => {
            return [
                group.name,
                group.leader_names,
                group.member_count,
                group.meta.mods_logged_in_count,
            ]
        })
    ];

    const cb = () => {
        toasts.addToast({text: "Copied to clipboard", intent: "info"})
    };

    return <div className="text-xs text-gray-700">
        <div>Groups without moderator: {analysis.meta.groups_without_moderator}</div>
        <div>Groups where mods have not logged in: {analysis.meta.groups_without_moderator_logins}</div>
        <div className="mt-2">
            <Button onClick={() => copyDOMTable(cols, rows, "groups-stats", cb)} text="Copy Spaces Stats"/>
        </div>
    </div>
}

function buildOnboardingFlow(community, custom_fields) {
    let base = {
        ...sample_flow_manifest,
        name: `${community.name} Onboarding`,

        branding: {
            accent_color: community.branding.color,
        },

        header: {
            title: `${community.name} Onboarding`,
            logo: {
                type: "image",
                url: buildImageUrl(community.profile_picture, '_medium')
            },
        },

        intro: {
            title: `👋 Welcome to ${community.name} on Orange!`,
            layout: "standard",
            description: "We're excited to have you here. Let's get you set up.",

            blocks: [
                {
                    type: "global_policies"
                }
            ],

            complete_rules: [
                {
                    type: "global_policies_up_to_date"
                }
            ],

            actions: {
                primary: "Let's Start",
                primary_action: {
                    type: "next"
                },
            },

            features: {
                skippable: false,
                can_press_enter: true
            }
        },

        steps: [
            {
                id: "134",
                name: "Name",
                type: "standard",

                title: "Tell us a bit about yourself:",
                description: "",

                complete_rules: [
                    {
                        type: 'state-exists',
                        field: 'first_name'
                    },
                    {
                        type: 'state-exists',
                        field: 'last_name'
                    }
                ],

                actions: {
                    primary: "Continue"
                },

                blocks: [
                    {
                        type: "full_name"
                    }
                ],

                data: {}
            },
            {
                id: "234",
                name: "Contact",
                type: "standard",

                title: "What's the best way to reach you?",
                description: "",

                complete_rules: [
                    {
                        type: 'state-exists',
                        field: 'account_email'
                    }
                ],

                actions: {
                    primary: "Continue"
                },

                blocks: [
                    {
                        type: "contact"
                    }
                ],

                data: {}
            }
        ]
    };

    custom_fields.forEach(section => {
        base.steps.push({
            id: section.id,
            name: `${section.name}`,
            type: "standard",

            title: "Complete your Profile",
            description: "",

            complete_rules: [],

            actions: {
                primary: "Continue"
            },

            blocks: [
                {
                    type: "custom_fields_section",
                    id: section.id
                }
            ],

            data: {}
        })
    })


    return base;
}

export async function createCommunityOnboardingFlow(community_uid, data, custom_fields) {
    const payload = {
        community_uid: community_uid,

        ...buildOnboardingFlow(data, custom_fields),

        name: `${data.name} Onboarding`,
        type: "onboarding",
        trigger_type: 'first-sign-in'
    };

    return new Promise((resolve, reject) => {
        const res = (resp) => {
            resolve(resp);
        };
        authFetch(`/flows/create`, res, res, "POST", {payload});
    })
}

function ActionsTab({id, data, custom_fields}) {
    const [loading, setLoading] = useState(false);

    function handleCreateOnboardingFlow() {
        setLoading(true);

        createCommunityOnboardingFlow(id, data, custom_fields)
            .then(resp => {
                setLoading(false);
            })
    }

    return <div>
        <Button loading={loading} onClick={handleCreateOnboardingFlow} text="Create Onboarding"/>
    </div>
}

function getCalculation(data,per_member_price,days_in_month) {

    if(!data) {
        return null;
    }

    let a = {
        members_at_start: 0,
        members_at_end: 0
    };

    // first sort by date
    data.sort((x,y)=>x.date>y.date?1:-1);

    // get members at start
    const first = data[0];
    a.members_at_start = first.total_members;

    // get members at end
    const last = data[data.length-1];
    a.members_at_end = last.total_members;

    if(per_member_price&&days_in_month) {
        const per_member_per_day_price = per_member_price/days_in_month;

        // now loop through days and calculate
        let total = 0;

        data.forEach((item,index)=>{
            total += item.total_members * per_member_per_day_price;
        })

        a.monthly_price = total;
    }

    return a;
}

function MonthData({community_uid,days_in_month,per_member_price,month_key,year_key}) {
    const [data,setData] = useState(null);

    const [loading,setLoading] = useState(false);

    function fetchData() {
        setLoading(true);
        getData(community_uid,month_key,year_key)
            .then(snap=>{
                if(!snap.empty) {
                    setData(snap.docs.map(doc=>doc.data()));
                    setLoading(false);
                } else {
                    setLoading(false);
                    alert("Error!");
                }
            })
    }

    async function getData(cid,mk,yk) {
        const ref = collection(db, "communities", cid, "insights");
        const q = query(ref, where("year", "==", yk), where("month", "==", mk), limit(35));
        return await getDocs(q);
    }

    if(data) {
        console.log("days_in_month",days_in_month)
        console.log("data",data)
    }

    let calculations = getCalculation(data,per_member_price,days_in_month);

    return <div>
        {!data&&<Button text="Get Data" onClick={()=>fetchData()} loading={loading} />}
        {data&&calculations&&<div>
            Members at start: {calculations.members_at_start}<br/>
            Members at end: {calculations.members_at_end}<br/>
            Monthly price: {calculations.monthly_price?calculations.monthly_price.toFixed(4):"N/A"}<br/>
        </div>}
    </div>
}

function BillingTab({id,data}) {
    console.log("BILLING TAB", id, data)
    const [per_member_price, setPerMemberPrice] = useState(0);

    const created_at_month = dayjs(data.created_at).format('MMM YYYY');
    const created_at_date = dayjs(data.created_at).format('MMM D, YYYY');

    const meta = [
        `Community created on ${created_at_date}`,
        `Monthly auto-renewal`,
        `Subscription status: active`,
        `Cancellation date is N/A`
    ];

    const months_since_created = dayjs().diff(dayjs(data.created_at), 'month');

    const months_array = [...Array(months_since_created).keys()].map(x=>x+1);

    return <div>
        <div className="gap-2">
            {meta.map((x,i)=><div key={i}>{x}</div>)}
            <div className="py-4">
                <div>Per Member Price Calculator</div>
                <FieldInput id="pmp" placeholder={"Per-member price"} type={"text"} onChange={(a, b) => setPerMemberPrice(b)} value={per_member_price}/>
            </div>

        </div>
        <div>
            <div className="text-base font-bold text-gray-800">Billing History</div>
            <div className="grid gap-2">
                {months_array.map((x,i)=> {
                    const month = dayjs().subtract(x, 'month');
                    const month_key = (parseInt(month.format('M')) - 1);
                    const year_key = parseInt(month.format('YYYY'));
                    const days_in_month = month.daysInMonth();
                    return <div key={i} className="grid grid-cols-2 gap-2">
                        <div className="text-sm text-gray-700">{dayjs().subtract(x, 'month').format('MMM YYYY')}</div>
                        <MonthData days_in_month={days_in_month} per_member_price={per_member_price} month_key={month_key} year_key={year_key} community_uid={id}/>
                    </div>
                })}
            </div>
        </div>
    </div>
}

function DuplicateReport({community_uid}) {
    const toasts = useToasts();
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deleted, setDeleted] = useState({});

    function generateReport() {
        setLoading(true);
        // superadmin/cleaner
        const payload = {
            community_uid
        };
        const res = (resp) => {
            console.log("RESP", resp)
            setLoading(false);
            setResult(resp.data);
        };
        authFetch(`/superadmin/cleaner`, res, res, "POST", {payload});
    }

    function deleteHandle(id) {

        setDeleted({...deleted, [id]: true})
        const payload = {
            id,
            job: 'delete-handle',
            community_uid
        };
        toasts.addToast({text: "Deleting..", intent: "info"})
        const res = (resp) => {
            toasts.addToast({text: "Deleted handle", intent: "success"})
        };
        authFetch(`/superadmin/cleaner`, res, res, "POST", {payload});
    }

    function autoResolveHandle(id, metadata) {
        setDeleted({...deleted, [id]: true})
        const payload = {
            id,
            job: 'auto-resolve-handle',
            metadata,
            community_uid
        };
        toasts.addToast({text: "Resolving..", intent: "info"})
        const res = (resp) => {
            toasts.addToast({text: "Resolved handle", intent: "success"})
        };
        authFetch(`/superadmin/cleaner`, res, res, "POST", {payload});
    }

    function handleClean() {
        setLoading(true);
        // superadmin/cleaner
        const payload = {
            job: 'clean',
            community_uid
        };
        const res = (resp) => {
            console.log("RESP", resp)
            setLoading(false);
        };
        authFetch(`/superadmin/cleaner`, res, res, "POST", {payload});
    }

    return <div>
        <div className="space-x-2 flex py-2">
            <Button text="Duplicate Report" onClick={generateReport}/>
            <Button text="Clean" onClick={handleClean}/>
        </div>
        {result && <div className="p-4 border-gray-200 border space-y-4">
            <div>
                <div>Empty ({result.empty.length})</div>
                <div>
                    {result.empty.map((x, i) => {
                        if (deleted[x.id]) {
                            return null;
                        }
                        return <div className="flex space-x-2 p-1" key={i}>
                            <div>{x.handle}</div>
                            <div>{x.id}</div>
                            <div>{x.type}</div>
                            <div>{x.entity_id}</div>
                            <div>
                                <TextAction text="Delete" onClick={() => deleteHandle(x.id)}/>
                            </div>
                        </div>
                    })}
                </div>
            </div>
            <div>
                <div>Duplicates ({result.duplicate.length})</div>
                <div>
                    {result.duplicate.map((x, i) => <div className="flex space-x-2 p-1" key={i}>
                        <div>{x.handle}</div>
                        <div>{x.id}</div>
                        <div>{x.entity_id}</div>
                        <div>{x.type}</div>
                        <div>
                            <TextAction text="Auto-resolve" onClick={() => autoResolveHandle(x.id, x)}/>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>}

    </div>
}

async function getCustomProfileSections(cid) {
    const ref = collection(db, "community_members", cid, "profile_sections");
    const q = query(ref, limit(50));
    return await getDocs(q);
}

export function ManageCommunityProfile({}) {
    const {id} = useParams();
    const navigate = useNavigate();
    const tabs = [{label: "Spaces"}, {label: "Billing"}, {label: "Leaders"}, {label: "Actions"}, {label: "Positions"}, {label: "Duplicates"}];
    const onClose = () => navigate(`/manage/communities`);
    const [tab, setTab] = useState(tabs[0].label);
    const [data, setData] = useState();
    const [custom_fields, setCustomFields] = useState([]);

    const [analysis, setAnalysis] = useState(null);

    useEffect(function () {

        if (data) {
            setData(null);
        }
        if (id) {
            getCommunity(id).then(d => {
                if (d) {
                    setData({...d});
                    generate();
                    getCustomProfileSections(id)
                        .then(snap => {
                            setCustomFields(snap.docs.map(doc => {
                                return {
                                    ...doc.data(),
                                    id: doc.id
                                }
                            }));
                        })
                } else {
                    setData({
                        does_not_exist: true
                    })
                }
            });
        }
    }, [id]);

    function generate() {

        getReport(id)
            .then(([li, an]) => {
                setAnalysis(an)
            })
    }

    let c, h, t, s;

    function renderTab() {
        switch (tab) {
            case "Billing":
                return <BillingTab data={data} analysis={analysis} id={id} />
            case "Duplicates":
                return <DuplicatesTab id={id}/>
            case "Groups":
                return <GroupsTab analysis={analysis} id={id}/>
            case "Leaders":
                return <LeadersTab analysis={analysis} id={id}/>
            case "Actions":
                return <ActionsTab custom_fields={custom_fields} data={data} id={id}/>
            default:
                return <div>
                    ...
                </div>
        }
    }

    if (!id) {
        c = <div>
            no id
        </div>
    } else if (!data) {
        c = <div>
            loading..
        </div>
    } else if (data.does_not_exist) {
        c = <div>
            community not found
        </div>
    } else {
        const mh = {
            title: data.name
        };

        const dli = [
            {
                icon: <AtSymbolIcon/>,
                label: "Handle",
                value: `@${data.handle}`
            },
            {
                icon: <UsersIcon/>,
                label: "# of leaders",
                value: `${analysis?.meta?.leader_count || "-"}`
            },
            {
                icon: <CircleStackIcon/>,
                label: "# of groups",
                value: `${analysis?.groups?.length || "-"}`
            },
            {
                icon: <ArrowRightIcon/>,
                label: "Leader login",
                value: `${analysis?.meta?.leader_login || "-"}`
            }
        ];

        h = <MetaHeader {...mh} onClose={onClose}/>;
        s = <>
            <Accordion open title="Details">
                <DetailList items={dli}/>
            </Accordion>
            <Accordion open title="Organizer Stats">
                <LeaderStats analysis={analysis}/>
            </Accordion>
            <Accordion open title="Position Stats">
                <PositionStats analysis={analysis}/>
            </Accordion>
            <Accordion open title="Group Stats">
                <GroupStats analysis={analysis}/>
            </Accordion>
        </>;
        c = <div>
            <div className="">

                {renderTab()}
            </div>
        </div>
    }


    return <EditModal type="custom" onClose={() => onClose} open>
        <ManageModalFrame sidebar={s} tabs={t} header={h}>
            <EntityTabs active={tab} onChangeTab={nt => setTab(nt)} tabs={tabs}/>
            {c}
        </ManageModalFrame>
    </EditModal>
}