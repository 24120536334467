import dayjs from "dayjs";
import {data_months_map} from "../../../common/data/data/months";

const default_membership_period = {
    type: "annual",
    frequency: 12,
    start_month: 0,
    labels: JSON.stringify({})
};

// we track member compliance with the current membership period, why not always do

function parseConfig(cf) {
    return {
        ...cf,
        labels: JSON.parse(cf?.labels || "{}")
    }
}

// currently we support start_date and end_date as variables
// we support YY, YYYY, MM, MMM, MMMM as date formats
const variables = {
    'start_date': {
        accessor: "start_date",
        type: 'date',
        input_format: 'YYYY-MM-DD',
        label: 'Start Date',
        output_formats: [
            "YYYY",
            "YY",
            "MM",
            "MMM",
            "MMMM"
        ]
    },
    'end_date': {
        accessor: "end_date",
        type: 'date',
        input_format: 'YYYY-MM-DD',
        label: 'End Date',
        output_formats: [
            "YYYY",
            "YY",
            "MM",
            "MMM",
            "MMMM"
        ]
    }
};

// we support custom labels with variables.
// currently the support
// tags are like {{start_date.YY}}/{{end_date.YY}} OR {{start_date.YYYY}}
function hydrateLabel(start_month, frequency, period_data, label) {
    const variable_data = {
        start_date: period_data.start_date,
        end_date: period_data.end_date
    };

    // check label for tags
    const label_parts = label.split("{{");

    const hydrated_label_parts = label_parts.map(part => {
        if(part.includes("}}")) {
            // this is a tag
            const tag = part.split("}}")[0];
            const variable = tag.split(".")[0];
            const format = tag.split(".")[1];
            if(variable_data[variable]) {
                const value = dayjs(variable_data[variable]).format(format);
                return `${value}${part.split("}}")[1]}`;
            } else {
                return "ERROR";
            }
        } else {
            return part;
        }
    });

    return hydrated_label_parts.join("");
}

function getPeriodLabel(today_start_month, frequency, period_data, custom_labels) {
    const {start_year, start_month, end_year, end_month} = period_data;
    if (custom_labels && custom_labels[period_data?.label_index]) {
        return hydrateLabel(start_month, frequency, period_data, custom_labels[period_data?.label_index]);
    }
    if (frequency === 12) {
        // show year or years depending on start date
        if (start_year === end_year) {
            return `${start_year}`;
        } else {
            return `${start_year} - ${end_year}`;
        }
    } else {
        // show month and year
        let start_month_label = data_months_map[start_month].label;
        let end_month_label = data_months_map[end_month].label;
        if (start_year === end_year) {
            return `${start_month_label} - ${end_month_label} ${start_year}`;
        } else {
            return `${start_month_label} ${start_year} - ${end_month_label} ${end_year}`;
        }
    }
}

function getLabelIndex(frequency = 6, start_month = 3, period_data = {
    start_year: 2021,
    start_month: 3,
    end_year: 2022,
    is_current_period: true,
    end_month: 8
}) {
    // if frequency === 12, then it's label index 0
    if (frequency === 12) {
        return 0;
    }
    // if frequency === 6, then it's label index 0 or 1
    if (frequency === 6) {
        // this means it's bi-annual
        // so it depends on the start month. If start_month = 0 and period_start_month
        if (start_month === period_data.start_month) {
            return 0;
        } else {
            return 1;
        }
    }
    // if frequency === 4, then it's label index 0, 1, 2
    if (frequency === 4) {
        // this means it's quarterly
        // so it depends on the start month. If start_month = 0 and period_start_month
        if (start_month === period_data.start_month) {
            return 0;
        } else if (start_month === ((period_data.start_month + 3) % 12)) {
            return 1;
        } else {
            return 2;
        }
    }
    // if frequency === 3, then it's label index 0, 1, 2, 3
    if (frequency === 3) {
        // this means it's quarterly
        // so it depends on the start month. If start_month = 0 and period_start_month
        if (start_month === period_data.start_month) {
            return 0;
        } else if (start_month === ((period_data.start_month + 3) % 12)) {
            return 1;
        } else if (start_month === ((period_data.start_month + 6) % 12)) {
            return 2;
        } else {
            return 3;
        }
    }
    // if frequency === 1, then it's label index 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11
    if (frequency === 1) {
        // this means it's monthly
        // so it depends on the start month. If start_month = 0 and period_start_month
        return period_data.start_month;
    }
}

function buildMembershipPeriodsArray(start_month, frequency, labels, start_years_ago = 2, until_years_ahead = 2) {
    const current_month_start_date = dayjs().startOf('month');

    const total_start_date = dayjs().startOf('month').subtract(start_years_ago, 'year');

    const total_end_date = dayjs().startOf('month').add(until_years_ahead, 'year');

    let periods = [];

    // start with start_month in total_start_date year

    let current_period_start = dayjs(total_start_date).month(start_month);

    while (current_period_start.isBefore(total_end_date)) {
        let current_period_end = dayjs(current_period_start).add(frequency, 'month');

        let p = {
            start_date: current_period_start.format('YYYY-MM-DD'),
            start_year: current_period_start.year(),
            start_month: current_period_start.month(),
            end_date: current_period_end.subtract(1, 'day').format('YYYY-MM-DD'),
            end_year: current_period_end.subtract(1, 'day').year(),
            end_month: current_period_end.subtract(1, 'day').month()
        };

        p.is_current_period = current_month_start_date.isBetween(current_period_start, current_period_end);

        p.id = `${p.start_month}_${p.start_year}__${p.end_month}_${p.end_year}`;

        p.label_index = getLabelIndex(frequency, start_month, p);

        p.label = getPeriodLabel(start_month, frequency, p, labels);

        periods.push(p);

        current_period_start = dayjs(current_period_end);
    }

    return periods;
}

export function opt_in_getMembershipPeriods(community_data) {
    const period = parseConfig(community_data?.preferences?.members?.period || {...default_membership_period});
    const {type, frequency, start_month} = period;
    return buildMembershipPeriodsArray(start_month, frequency, period.labels, 2, 2);
}

export function opt_in_getCurrentMembershipPeriodID(community_data,opt_in_periods = [],return_full_record) {

    if(!opt_in_periods.length) {
        opt_in_periods = opt_in_getMembershipPeriods(community_data);
    }

    if(return_full_record) {
        return opt_in_periods.filter(p => p.is_current_period)[0] || {};
    }

    return opt_in_periods.filter(p => p.is_current_period)[0]?.id || "";
}
