import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {db} from "../../../config/setup-firestore";
import {collection, doc, getDoc} from "firebase/firestore";
import SimplePortal from "../../../m3/_legacy_components/simple-portal";
import Modal from "../../../m3/_legacy_components/modal";
import Button from "../../../m3/_legacy_components/button";
import {authFetch} from "../../../../api/network";
import {useToasts} from "../../../config/toasts";
import {validateEmail} from "../../../../common/utilities/lang-region";
import {FieldLabel} from "../../../m3/_legacy_components/form/field";
import {utils_strings_isEmail} from "../../../../common/utilities/strings";

async function setUserFlag(uid,field,value) {
    return new Promise((resolve, reject) => {
        const handler = (resp) => {
            resolve(resp);
        };
        authFetch('/superadmin/set-user-flag', handler,handler,'POST',{
            payload: {
                user_uid: uid,
                field,
                value
            }
        })
    })
}


async function addUserToBetaProgram(uid) {
    return new Promise((resolve, reject) => {
        const handler = (resp) => {
            resolve(resp);
        };
        authFetch('/superadmin/add-user-to-beta', handler,handler,'POST',{
            payload: {
                user_uid: uid
            }
        })
    })
}

async function removeUserFromBetaProgram(uid) {
    return new Promise((resolve, reject) => {
        const handler = (resp) => {
            resolve(resp);
        };
        authFetch('/superadmin/remove-user-from-beta', handler,handler,'POST',{
            payload: {
                user_uid: uid
            }
        })
    })
}

async function handleDeleteUser(uid) {
    return new Promise((resolve, reject) => {
        const handler = () => {
            resolve(true);
        };
        authFetch('/superadmin/delete-user', handler,handler,'POST',{
            payload: {
                _user_uid: uid
            }
        })
    })
}

export async function adminAddSecondaryEmail(uid,email) {
    return new Promise((resolve, reject) => {
        const handler = (resp) => {
            console.log("RESP",resp)
            resolve(resp);
        };
        authFetch('/superadmin/add-secondary-email', handler,handler,'POST',{
            payload: {
                _user_uid: uid,
                email
            }
        })
    })
}

function AddSecondaryEmail({id}) {
    const toasts = useToasts();
    const [email,setEmail] = useState('');
    const [loading,setLoading] = useState(false);

    function handleAdd() {
        setLoading(true);
        if (utils_strings_isEmail(email)) {
            adminAddSecondaryEmail(id,email)
                .then(() => {
                    toasts.addToast({text: 'Added secondary email', intent: 'success'});
                    setEmail('');
                    setLoading(false);
                })
        }
    }

    return <div className="flex space-x-2">
        <div>
            <input placeholder="email@me.com" value={email} onChange={e=>setEmail(e.target.value)} />
        </div>
        <div>
            <Button onClick={handleAdd} intent="success" disabled={!utils_strings_isEmail(email)} loading={loading} text="Add" />
        </div>
    </div>
}

async function getUser(uid) {

    const ref = doc(db, "users", uid);
    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? doc.data() : null;
        });
}

export function ManageUserProfile({}) {
    const {id} = useParams();
    const toasts = useToasts();
    const navigate = useNavigate();
    const [data,setData] = useState();

    useEffect(function () {

        if(data) {
            setData(null);
        }
        if(id) {
           getData()
        }
    }, [id]);

    function getData() {
        getUser(id).then(d=>{
            if(d) {
                setData({...d});
            } else {
                setData({
                    does_not_exist: true
                })
            }
        });
    }

    let c;

    if(!id) {
        c = <div>
            no id
        </div>
    } else if(!data) {
        c = <div>
            loading..
        </div>
    } else if(data.does_not_exist) {
        c = <div>
            user not found
        </div>
    } else {
        c = <div>
            <pre className="text-xs bg-gray-200 font-medium text-gray-700 overflow-y-scroll text-left"
                 style={{maxHeight: '50vh'}}>{JSON.stringify(data, undefined, 2)}</pre>
        </div>
    }

    return <SimplePortal>
        <Modal center size="large">
            <div className="p-4 bg-white rounded-xl">
                <div className="flex space-x-2">
                    <Button text="Close" onClick={()=>navigate(`/manage/users`)} />
                </div>
                <div>
                    <Button text="Delete User" onClick={()=>{
                        if(window.confirm("Are you sure you want to delete this user?")) {
                            console.log("MORE")
                            handleDeleteUser(id)
                                .then(()=>{
                                    toasts.addToast({text: 'User deleted', intent: 'success'});
                                    navigate(`/manage/users`)
                                })
                        }
                    }} />
                    {data && !data.beta_program && <Button text="Add to Beta Program" onClick={()=>{
                        if(window.confirm("Are you sure you want to add this user to the beta program?")) {
                            addUserToBetaProgram(id)
                                .then(()=>{
                                    getData();
                                    toasts.addToast({text: 'Added to beta program', intent: 'success'});
                                })
                        }
                    }} />}
                    {data && data.beta_program && <div>
                        <Button text="Remove from Beta Program" onClick={()=>{
                            if(window.confirm("Are you sure you want to remove this user from the beta program?")) {
                                removeUserFromBetaProgram(id)
                                    .then(()=>{
                                        getData();
                                        toasts.addToast({text: 'Removed from beta program', intent: 'success'});
                                    })
                            }
                        }} />

                        {!data['scheduled-success-meeting'] && <Button text="Mark Success Meeting Scheduled" onClick={()=>{
                            setUserFlag(id,'scheduled-success-meeting',true)
                                .then(()=>{
                                    getData();
                                    toasts.addToast({text: 'Scheduled success meeting', intent: 'success'});
                                })
                        }} />}
                        {data['scheduled-success-meeting'] && !data['success-onboarding-completed'] && <div>
                            <Button text='Mark success meeting done' onClick={()=>{
                                setUserFlag(id,'success-onboarding-completed',true)
                                    .then(()=>{
                                        getData();
                                        toasts.addToast({text: 'Success meeting marked as done', intent: 'success'});
                                    })
                            }} />
                        </div>}
                    </div>}

                </div>
                <FieldLabel label="Add Secondary Email" />
                <AddSecondaryEmail id={id} />
            {c}
            </div>
        </Modal>
    </SimplePortal>
}