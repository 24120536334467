import React, {useState, useEffect, useContext} from 'react';
import {db} from "../../../../config/setup-firestore";
import {useCommunity} from "../../../../config/community";
import {collection, getDocs, limit, orderBy, query} from "firebase/firestore";
import {useNavigate} from "react-router-dom";
import StatsCards from "../../../../m3/_legacy_components/stats-cards";

function buildStats(group, group_id, insights, community, history) {

    const gi = insights?.groups?.[group_id];
    let a = [];

    if(!group || !group.user_uids) {
        return a;
    }
    /*
    if (gi) {
        a.push({
            label: "Member changes",
            stat: `${gi.members_count_delta > 0 ? `+${gi.members_count_delta}` : gi.members_count_delta}`
        });
    }

     */

    a.push({
        onClick: () => {
            history.push(`/${community.profile.handle}/space/${group_id}/directory`);
        },
        label: "Total Members",
        stat: Object.keys(group?.user_uids).length
    });

    if (gi) {
        a.push({
            onClick: () => {
                history.push(`/${community.profile.handle}/space/${group_id}/directory`);
            },
            label: "Logged in",
            stat: gi.logged_in_count
        });

        a.push({
            onClick: () => {
                history.push(`/${community.profile.handle}/space/${group_id}/directory`);
            },
            label: "Profile complete",
            stat: gi.profile_complete_count
        });
    }

    return a;
}

export default function GroupAnalyticsTab({group_id, group}) {
    const [data, setData] = useState(null);
    const navigate = useNavigate();
    const community = useCommunity();

    useEffect(function () {
        getData(group_id, community.uid)
            .then(snap=>{
                if(snap.empty) {
                    setData(null);
                } else {
                    setData({...snap.docs[0].data()});
                }
            })
    }, [community.uid, group_id]);

    async function getData(id, cid) {
        const ref = collection(db, 'communities', community.uid, 'insights');
        const q = query(ref, orderBy("created_at", "desc"), limit(1));
       return await getDocs(q);
    }

    if (!data) {
        return null;
    }

    const stats = buildStats(group, group_id, data, community, navigate);

    return  <StatsCards items={stats} />
}