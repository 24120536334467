import M3_A_Checkbox from "../../atoms/checkbox";
import React, {useEffect, useState} from "react";
import {_m3_c_list_view_components} from "./components";
import {m3_icon_map} from "../../icons/icon-map";
import {opt_in_getCurrentMembershipPeriodID, opt_in_getMembershipPeriods} from "../../../features/gates/opt-in-utilities";
import {data__member_profile_fields} from "../../../../common/data/data-fields";

function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

function getDimsMobile() {

    let height = window.innerHeight;
    let width = window.innerWidth;

    // header is 3 rem

    const header = document.getElementById("header");

    if(header) {
        height -= header.offsetHeight;
    } else {
        let header_height_rem = 6;
        height -= convertRemToPixels(header_height_rem);
    }

    return {
        height,
        width
    }
}

export function useMobileDimensions(search_open) {
    const [dimensions, setDimensions] = useState(getDimsMobile());

    useEffect(function () {
        const handleResize = () => {
            const new_dimensions = getDimsMobile();
            setDimensions(new_dimensions);
        };
        window.addEventListener('resize', handleResize);

        setDimensions(getDimsMobile());

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [search_open]);

    return dimensions;
}

function getDimensions(extra, inline_sidebar_width, layout, is_mobile) {
    // for height, take the window innerheight and subtract 0.5rem for the top and bottom padding on desktop > 540px then substract 3rem for the header
    // for width, take the window innerwidth and subtract 240px for the sidebar

    if(is_mobile) {
        return getDimsMobile();
    }

    const padding_rem = 0.5;

    let header_height_rem = 5.5;

    if (extra) {
        header_height_rem += extra;
    }

    let width = window.innerWidth - (convertRemToPixels(padding_rem));

    if (inline_sidebar_width) {
        // this is inline so we need to subtract the width

        width -= inline_sidebar_width;
    }

    if (layout[0]) {
        const el = document.getElementById("right-sidebar");

        if (el) {
            width -= el.offsetWidth;
        }
    }

    if (layout[1]) {
        // collapsed left sidebar
        width -= 56;
    } else {
        width -= 250;
    }

    const content_el = document.getElementById("content");

    const content_height = content_el ? content_el.offsetHeight : window.innerHeight;

    const height = (content_height || window.innerHeight) - (convertRemToPixels(padding_rem)) - (convertRemToPixels(header_height_rem));

    return {height, width};
}

const align_map = {
    "left": "justify-start", "right": "justify-end", "center": "justify-center"
};

function getAlignClass(align, fallback = "left") {
    return align_map[align || fallback] || "justify-center";
}

function getColorClass(color, fallback = "text-gray-800") {
    if (!color) {
        return fallback;
    }

    return color;
}

function getLeftPx(selectable, index, width0) {
    return index === 0 ? '0px' : index === 1 ? `${width0}px` : '';
}

function buildListColumns(cols, selectable) {
    let a = [];

    if (selectable) {
        a.push({
            id: 'select-col',
            size: 40,
            header: ({table}) => (
                <M3_A_Checkbox
                    checked={table.getIsAllRowsSelected()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    onChange={table.getToggleAllRowsSelectedHandler()} //or getToggleAllPageRowsSelectedHandler
                />
            ),
            cell: ({row}) => (
                <M3_A_Checkbox
                    checked={row.getIsSelected()}
                    disabled={!row.getCanSelect()}
                    onChange={row.getToggleSelectedHandler()}
                />
            ),
        });
    }

    cols.forEach((col, index) => {
        const is_last = index === cols.length - 1;
        a.push({
            size: col?.width || 200,
            accessorKey: col?.accessorKey || `empty`,
            header: info => <_m3_c_list_view_components.Header info={info}/>,
            cell: info => <_m3_c_list_view_components.Cell info={info}/>,
            action: col?.action,
            display: col?.display,
            label: col?.label || "",
            column_actions: {
                can_move_right: index > 0 && !is_last,
                can_move_left: index > 1,

                can_sort_asc: col?._can_sort,
                can_sort_desc: col?._can_sort,

                can_hide: index > 0
            },
            meta: {
                icon: col?.icon || "",
                type: col?._type || "text",
                enriched: col?._enriched || false,
                align: col?.align || "left",
                read_only: col?._read_only || false,
                locked: col?.locked || false,
                editable: col?._editable || false,
                parent: col?.parent || null,
                ...(col?.meta || {})
            }
        });
    });

    // add blank column for padding
    a.push({
        size: 200,
        accessorKey: `empty`,
        header: info => <_m3_c_list_view_components.Header info={info}/>,
        cell: info => <_m3_c_list_view_components.Cell info={info}/>,
        action: null,
        display: null,
        label: "",
        column_actions: {
            can_move_right: false,
            can_move_left: false,
            can_sort_asc: false,
            can_sort_desc: false,
            can_hide: false
        },
        meta: {
            icon: "",
            type: "blank",
            enriched: false,
            align: "left",
            locked: false,
            editable: false,
        }
    })

    return a;
}

// field_type
const list_columns_style_defaults = {
    "chip": {
        align: "left", color: "text-gray-800", width: 220
    },
    "text": {
        align: "left", color: "text-gray-800", width: 180
    }, "long-text": {
        align: "left", color: "text-gray-800", width: 300
    }, "number": {
        align: "right", color: "text-gray-700", width: 120
    }, "display-name": {
        align: "left", color: "text-gray-800", width: 180
    }, "avatar": {
        align: "center", color: "text-gray-800", width: 40
    }, "currency": {
        align: "right", color: "text-gray-700", width: 120
    }
};

function getAttrIcon(entry) {
    return entry?.icon || entry?.type;
}

const custom_fields_meta = {
    allowed_types: ["textarea", "url", "select-taxonomy", "multi-select-taxonomy", "number", "member", "mentor", "mentees", "text", "phone", "email", "address", "date", "select", "multi-select"], // select, multi-select, mentor, member, mentee, checkbox, date, group, country, select-taxonomy, multi-select-taxonomy
    editable_types: ["textarea", "url", "number", "text", "phone", "email", "date", "select"]
};

function mapCustomFieldType(type) {
    if (type === "textarea") {
        return "long-text";
    }
    return type;
}


function buildCFOptions(custom_field) {
    if (custom_field?.options?.choices) {
        return custom_field?.options?.choices.filter(a => {
            return !a.hidden;
        }).map(c => {
            return {
                value: c.value,
                label: c.text
            }
        });
    }
    return [];
}

function getCustomFieldsData(community) {
    let o = {};
    if (!community?.custom_fields) return {};
    Object.entries(community?.custom_fields).forEach(([key, cfs], index) => {
        cfs.fields.forEach(f => {
            const cfd = cfs.field_data[f];
            const accessor_key = `custom_fields.${f}`;
            const {type, name, required, options} = cfd;

            let autocomplete = type === "select" || type === "multi-select" ? "value_options" : type === "select-taxonomy" || type === "multi-select-taxonomy" ? "dynamic_options" : "";

            let autocomplete_options = {};

            if (type === "select-taxonomy" || type === "multi-select-taxonomy") {
                autocomplete_options = {
                    taxonomy: options?.taxonomy
                };
            }

            o[accessor_key] = {
                label: name,
                type: mapCustomFieldType(type),
                meta: {
                    block_from_contacts: !custom_fields_meta.allowed_types.includes(type),
                    can_sort: false,
                    can_filter: false,
                    can_search: false,
                    can_group: false,
                    read_only: false,
                    generated: false,
                    editable: custom_fields_meta.editable_types.includes(type),
                },
                member: {
                    accessor: accessor_key
                },
                display: {
                    autocomplete,
                    ...autocomplete_options
                },
                validation: {
                    required: !!required
                }
            };

            if (autocomplete === "value_options") {
                o[accessor_key].display.value_options = buildCFOptions(cfd);
                o[accessor_key].display.variant = "label";
            }
        });
    });
    return o;
}

function getAdditionalFields(community) {
    let a = {};
    // policies
    if (community?.policies?.length > 0) {
        community.policies.forEach(p => {

            if (p.status === "published") {
                a[`policies.${p.id}`] = {
                    label: `${p.name} (v${p.version})`,
                    type: "policy",

                    meta: {
                        read_only: true,
                    },

                    member: {
                        // privacy-policy__1
                        accessor: `policies.${p.id}__${p.version}`
                    },

                    parent: {
                        id: "policies",
                        label: "Policies"
                    },

                    display: {
                        autocomplete: "",
                        data: {
                            slug: `${p.id}__${p.version}`,
                            version: p.version
                        }
                    },

                    validation: {
                        required: false
                    }
                }
            }
        })
    }

    // opt-in

    const opt_in_periods = opt_in_getMembershipPeriods(community.profile);

    const current_period_id = opt_in_getCurrentMembershipPeriodID(community.profile, opt_in_periods);

    if (current_period_id) {
        const label = opt_in_periods.find(a => a.id === current_period_id)?.label || "Current";
        a[`opt_in.${current_period_id}`] = {
            label: `${label}`,
            type: "opt-in",

            meta: {
                read_only: true,
            },

            member: {
                accessor: `opt_in.${current_period_id}`
            },

            parent: {
                id: "opt_in",
                label: "Opt-In"
            },

            display: {
                autocomplete: "",
                data: {
                    slug: `${current_period_id}`,
                    opt_in_periods
                }
            },

            validation: {
                required: false
            }
        }
    }

    return a;
}

function getAttributes(community) {
    let a = [];

    const custom_fields = getCustomFieldsData(community);

    const additional_fields = getAdditionalFields(community);

    // get all member data fields, filter out those not available as attributes
    Object.entries({...data__member_profile_fields, ...custom_fields, ...additional_fields}).forEach(([key, entry]) => {
        if (!entry?.meta?.block_from_contacts) {
            const icon = getAttrIcon(entry);
            a.push({
                label: entry?.label || key,
                value: key,
                leading_indicator: {
                    type: "icon",
                    icon: m3_icon_map.outlines?.[icon]
                },
                col_data: {
                    _id: key,
                    icon: `${icon}`,
                    accessorKey: entry?.member?.accessor || key,
                    width: list_columns_style_defaults[entry?.type]?.width || 180,
                    display: {
                        // add autocomplete info for select and any custom color mapping
                        autocomplete: entry?.autocomplete,
                        ...(entry?.display || {})
                    },
                    validation: {
                        ...(entry?.validation || {})
                    },

                    parent: entry?.parent,
                    _editable: entry?.meta?.editable,
                    _can_sort: entry?.meta?.can_sort,
                    _can_filter: entry?.meta?.can_filter,
                    _can_search: entry?.meta?.can_search,
                    _can_group: entry?.meta?.can_group,
                    _read_only: entry?.meta?.read_only,
                    _generated: entry?.meta?.generated,
                    _type: entry?.type || "text",
                    label: entry?.label || key,
                }
            });
        }
    });

    return a;
}

export const _m3_c_list_view_utils = {
    getDimensions,
    getAttributes,
    buildListColumns,
    getLeftPx,
    getAlignClass,
    getColorClass,
    style_defaults: list_columns_style_defaults
};