import React from 'react';
import {PrettyCodeWrapper} from "./misc/pretty-code-wrapper";
import {getNotificationColorOptions, m3_generateTheme} from "./utilities/color-system";
import styled from "styled-components";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import M3_E_PolicyPrompt from "./examples/policy-prompt";
import M3_C_ListView from "./components/list-view";
import {IconMapViewer, m3_icon_map} from "./icons/icon-map";
import {M3_E_MobileContactsList} from "./examples/mobile-contacts-list";
import M3_E_MobileSpace from "./examples/mobile-space";
import M3_E_FilterBarExample from "./examples/filter-bar-example";
import M3_C_Layout from "./components/layout";
import M3_A_IconButton from "./atoms/icon-button";
import {hexToRgbStr} from "./utilities/colors";
import M3_E_FieldRendering from "./examples/field-rendering";
import M3_E_Popovers from "./examples/popovers";
import M3_E_ViewSwitcher from "./examples/view-switcher";
import {M3_E_SelectPanelFieldEditor} from "./examples/select-panel";
import M3_A_Select from "./atoms/select";
import M3_A_FormControl from "./atoms/form-control";
import M3_A_Input from "./atoms/input";
import FieldPrivacyControl from "./examples/field-privacy-control";
import M3_E_Headers from "./examples/headers";
import M3_C_FilterBar from "./components/filter-bar";

function ColorBlock({color, label}) {
    return <div>
        <div className="w-10 h-10 rounded-sm" style={{
            backgroundColor: color
        }}/>
        <div className="mt-1">
            {label}
        </div>
    </div>
}

function ModeColors({label, theme, background = "white"}) {
    return <div>
        <div>
            {label}
        </div>
        <div style={{
            backgroundColor: background,
            color: background === "white" ? "black" : "white"
        }} className="flex flex-wrap gap-4 p-4 border border-gray-200">
            <ColorBlock label="BG: Base" color={theme.background}/>
            <ColorBlock label="BG: Active" color={theme.background_active}/>
            <ColorBlock label="BG: Hover" color={theme.background_hover}/>
            <ColorBlock label="BG: Pressed" color={theme.background_pressed}/>
            <ColorBlock label="BG: Divider" color={theme.background_divider}/>
            <ColorBlock label="BG: Text" color={theme.background_text}/>
            <ColorBlock label="Primary" color={theme.primary}/>
            <ColorBlock label="Primary Text" color={theme.primary_text}/>
            <ColorBlock label="Primary Hover" color={theme.primary_hover}/>
            <ColorBlock label="Primary Pressed" color={theme.primary_pressed}/>
            <ColorBlock label="Primary Active" color={theme.primary_active}/>
            <ColorBlock label="Notification" color={theme.notification}/>
        </div>
    </div>
}

const SidebarItem = styled.div`
  background-color: ${props => props.active ? props.theme.background_active : "transparent"};
  color: ${props => props.active ? props.theme.background_active_text : props.theme.background_text};

  &:hover {
    background-color: ${props => !props.active && props.theme.background_hover};
  }

  &:active {
    background-color: ${props => !props.active && props.theme.background_pressed};
  }
`;

// NOW WE STRESS TEST

const test_logo = "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/community_logos%2FW8SLI1DA5N_small?alt=media";
const test_name = "Sandbox";

// #3444d9 works as do lighter colors
// #2f3a9d breaks it - why?
function ColorSystem() {
    const [preview, setPreview] = React.useState(false);

    const [color, setColor] = React.useState("#0266fb");
    const [mode, setMode] = React.useState("light");
    const [brightness, setBrightness] = React.useState(5);

    const [dynamic, setDynamic] = React.useState(false);

    const notification_options = getNotificationColorOptions(color, mode, brightness);

    const default_notification_value = notification_options.value || "#ff0000";

    const [notification, setNotification] = React.useState(default_notification_value);

    const theme = m3_generateTheme(color, mode, brightness, notification);

    if (preview) {

        // outer frame should be full screen and not scrollable
        const active_theme = theme.derived[mode];

        let main_styles = {
            backgroundColor: active_theme.foreground,
            color: active_theme.foreground_text,
        };


        if (mode === "light") {
            // if light mode, add shadow based on values in theme like shadow_color, shadow_opacity, and shadow_blur
            main_styles.boxShadow = active_theme.box_shadow;
        } else {
            // if dark mode, add border based on values in theme
            main_styles.border = `1px solid ${active_theme.border_color}`;
        }

        return <div onClick={() => {
            setPreview(false);
        }} className="m3-frame relative" style={{
            height: "100vh",
            width: "100vw",
            backgroundColor: active_theme.background
        }}>
            <div className="p-1.5 h-full m3-inner" style={{
                display: "grid",
                gridTemplateColumns: "240px 1fr",
                background: dynamic ? active_theme.dynamic_background : "none"
            }}>
                <div className="m3-sidenav flex px-2 gap-6 flex-col" style={{
                    color: active_theme.background_text,
                }}>
                    <div className="pt-2">
                        <div className="inline-flex items-center rounded-md hover:bg-black p-1">
                            <div className="flex-none w-6">
                                <img src={test_logo} alt="logo" className="w-6 h-6 rounded-md"/>
                            </div>
                            <div className="text-base pl-2 pr-1 font-medium">
                                {test_name}
                            </div>
                            <div className="flex-none w-4 opacity-80">
                                <ChevronDownIcon className="w-4 h-4"/>
                            </div>
                        </div>
                    </div>
                    <div>
                        Standard Text
                    </div>
                    <div className="h-px" style={{
                        backgroundColor: active_theme.background_divider
                    }}/>
                    <SidebarItem onClick={e => {
                        e.stopPropagation();
                    }} className="text-base px-2 leading-6 rounded-md transition-colors" active theme={active_theme}>Active
                        Item</SidebarItem>

                    <SidebarItem onClick={e => {
                        e.stopPropagation();
                    }} className="text-base px-2 leading-6 rounded-md  transition-colors" theme={active_theme}>Standard
                        Item</SidebarItem>
                    <div className="h-px" style={{
                        backgroundColor: active_theme.background_divider
                    }}/>
                    <div>
                        <div className="h-2 w-2 rounded-full" style={{
                            backgroundColor: active_theme.notification
                        }}/>
                    </div>
                </div>
                <div className="m3-main h-full rounded-lg" style={main_styles}>
                    <div className="m3-content py-2 px-4 flex flex-col gap-6" style={{}}>
                        <div className="flex items-baseline gap-2">
                            <div className="text-lg font-bold">San Diego Hub</div>
                            <div className="text-sm">202 Members</div>
                        </div>
                        <div>
                            <div className="text-base px-2 inline-block leading-8 rounded-md" style={{
                                backgroundColor: active_theme.primary,
                                color: active_theme.primary_text
                            }}>Button
                            </div>
                        </div>
                    </div>
                    <div className="m3-inspector p-6" style={{}}></div>
                </div>
            </div>
        </div>
    }

    return (
        <div className="gap-4 flex flex-col">
            <h1>Color System</h1>
            <div>
                <label>Accent Color</label>
                <input type="color" value={color} onChange={e => {
                    const value = e.target.value;
                    console.log('value', value);
                    setColor(value);
                }}/>
            </div>
            <div>
                <label>Mode</label>
                <select value={mode} onChange={e => setMode(e.target.value)}>
                    <option key="light" value="light">Light</option>
                    <option key="dark" value="dark">Dark</option>
                </select>
            </div>
            <div>
                <label>Brightness</label>
                <input type="range" min={1} max={5} value={brightness}
                       onChange={e => setBrightness(parseInt(e.target.value))}/>
            </div>
            <div>
                <label>Notification</label>
                <select value={notification} onChange={e => setNotification(e.target.value)}>
                    {notification_options.map(c => <option key={c.value} value={c.value}>{c.label}</option>)}
                </select>
            </div>

            <div>
                <label>Dynamic</label>
                <input type="checkbox" checked={dynamic} onChange={e => setDynamic(e.target.checked)}/>
            </div>

            <div>
                <ModeColors label="Light Mode" theme={theme.derived.light}/>

                <ModeColors background="#000000" label="Dark Mode" theme={theme.derived.dark}/>
            </div>
            <div>
                <button onClick={() => setPreview(!preview)}>Toggle Preview</button>
            </div>
            <div>
                <PrettyCodeWrapper data={theme}/>
            </div>
            <div>
                Hex -> RGB Utility
                <HexToRgb/>
            </div>
        </div>
    );
}

function HexToRgb() {
    const [hex, setHex] = React.useState("#0266fb");

    return <div className="flex flex-col gap-4">
        <div>
            <input type="text" value={hex} onChange={e => setHex(e.target.value)}/>
        </div>
        <div>
            {hex} {hex&&<div>
            <div>
                RGB: {hexToRgbStr(hex)}
            </div>
        </div>}
        </div>
    </div>;
}

const SampleSidebar = ({layout_onToggleOpen,layout_open}) => {
    return <div className="flex flex-col gap-4">
        <div>
            <M3_A_IconButton onClick={layout_onToggleOpen} compact icon_size="h-6 w-6" variant={`minimal`}
                             icon={m3_icon_map.solid["close"]}/>
        </div>
        <div>
            Sidebar is {layout_open ? "open" : "closed"}
        </div>
    </div>;
}

// get query param for view
export default function M3Playground() {
    const url_parameters = new URLSearchParams(window.location.search);

    const [view, setView] = React.useState(url_parameters.get("view") || "list");

    if(view === "filter-bar") {
        return <div>
            <M3_C_FilterBar items={[
                {
                    id: "upcoming",
                    label: "Upcoming"
                },
                {
                    id: "past",
                    label: "Past"
                }
            ]} />

            <M3_C_FilterBar size="base" variant="underline" items={[
                {
                    id: "upcoming",
                    label: "Upcoming"
                },
                {
                    id: "past",
                    label: "Past"
                }
            ]} />
        </div>
    }

    if (view === "icon-map") {
        return <div>
            <IconMapViewer/>
        </div>
    }

    if (view === "fields") {
        return <div>
            <M3_E_FieldRendering/>
        </div>
    }

    if (view === "mobile-contacts") {
        return <div className="">
            <M3_E_MobileContactsList/>
        </div>
    }

    if (view === "contacts") {
        return <div className="relative w-screen h-screen bg-green-200 overflow-hidden flex">
            <div className="w-[18rem] flex-none h-screen">

            </div>
            <div className="flex-grow p-1 h-screen">
                <div className="bg-white rounded-xl" style={{height: "calc(100vh - 0.5rem)"}}>
                    <M3_C_Layout variant="full-width" main={
                        <div>
                            hi!
                        </div>
                    } sidebar={<SampleSidebar/>}/>
                </div>
            </div>
        </div>
    }

    if (view === "space") {
        return <M3_E_MobileSpace/>
    }

    if (view === "selectpanel") {
        return <M3_E_SelectPanelFieldEditor type="date" />
    }

    return <div>
     <M3_E_Headers />
    </div>

    return <M3_E_ViewSwitcher/>

    return <div>
        <M3_E_Popovers />
    </div>

    return <ColorSystem/>

    return <M3_E_FilterBarExample />

    return <div className="bg-gray-200">
        <div className="mx-auto bg-white p-10" style={{maxWidth: "1400px", maxHeight: "800px"}}>
            <M3_C_ListView/>
        </div>
    </div>;

    return <M3_E_PolicyPrompt demo/>
    // return <ColorSystem/>
}