import React from "react";
import M3_A_Button from "./button";
import M3_A_Text from "./text";
import {useIsMobile} from "../hooks/is-mobile";

/*
* This is the Footer atom
 */

const _props = {
    children: {
        type: "string",
        required: true
    }
};

export default function M3_A_Footer({
                                        confirm_action,
                                        cancel_action,
                                        children,
                                        skeleton,
                                        caption
                                    }) {
    const is_mobile = useIsMobile();
    let classes = "px-4 py-3 gap-2 flex flex-none items-center sm:flex-row flex-col";
    return (
        <div
            className={`${classes}`}>
            <div className="flex-grow w-full sm:w-auto text-gray-700 leading-none">
                {caption && <div>
                    <M3_A_Text size="xs">
                        {caption}
                    </M3_A_Text>
                </div>}
            </div>
            <div className="flex-none flex gap-2 w-full sm:w-auto sm:flex-row flex-col-reverse">
                {children}
                {!!cancel_action && <M3_A_Button
                    fluid={is_mobile}
                    onClick={cancel_action.onClick}
                >{cancel_action.label}</M3_A_Button>}
                <M3_A_Button
                    fluid={is_mobile}
                    onClick={confirm_action.onClick}
                    disabled={confirm_action.disabled}
                    loading={confirm_action.is_loading}
                    intent={confirm_action.intent}
                >{confirm_action.label}</M3_A_Button>
            </div>
        </div>
    );
}