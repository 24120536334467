import React from 'react';
import {useImageResizeUploader} from "../../../../common/utilities/images";

const _image_mock = {
    size: 1713914278450614,
    type: "image/png",
    id: "test-1",
    width: 200,
    height: 200,
    path: "test-community-id/posts/test-post-id/images/test-1/test-1.png",
    name: "test-1.png",
    status: "loading", // loading, success, error
    outputs: [],
    media_link: null, // https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/files%2FxFHLCj9BZaQprNuZpfqx%2F20-04-2024%2F7683378_large.png?alt=media
    file: null
};

function UploadImageTest() {
    const [images, handleFileChange, error, metadata] = useImageResizeUploader([], "unaty-posts");

    // on file change handler check for files without a status and set to loading
    // on file change handler upload file to firebase storage

    return <div>
        <input multiple={false} type="file" onChange={handleFileChange}/>
        <div>
            {metadata?.is_loading&&<div>Loading...</div>}
        </div>
        {error&&<div>
            <div>Error</div>
            <div>{error}</div>
        </div>}
        <div>
            {images.map((image, index) => {
                return <div key={index}>
                    <img alt="image" src={URL.createObjectURL(image?.file)}/>
                    <div>
                        <div>Status: {image.status}</div>
                        <div>ID: {image.id}</div>
                        <div>Width: {image?.width}</div>
                        <div>Height: {image?.height}</div>
                        <div>Outputs: {image?.outputs?.length}</div>
                        <div>Media Link: {image?.media_link}</div>
                        <div>Type: {image?.type}</div>
                    </div>

                    <div>
                       <div>
                           outputs
                       </div>
                        <div>
                            {image?.outputs?.map((output, index) => {
                                return <div key={index}>
                                    <img alt="output" src={output.mediaLink}/>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            })}
        </div>
    </div>
}

export function CommunityTestingPage() {

    return <div>
        <UploadImageTest/>
    </div>
}