import React, {useState} from "react";
import {TableOfContents} from "../../../m3/_legacy_components/table-of-contents";
import Table from "../../../m3/_legacy_components/table";
import {manage_data_model} from "../data-model";
import FormBuilder from "../../../m3/_legacy_components/form/builder";
import Button from "../../../m3/_legacy_components/button";
import {LayoutComplex} from "../../../m3/_legacy_components/app-frame/layouts";
import {SimpleBackButton} from "../../community/message";
import {ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon} from "@heroicons/react/24/outline";

export function UnaverseSectionHeader({title, subtitle, actions}) {
    return <div className="flex">
        <div className="flex-grow">
            <div className="text-xl font-bold text-gray-800">
                {title}
            </div>
            {subtitle && <div className="text-sm text-gray-600">
                {subtitle}
            </div>}
        </div>
        <div className="flex space-x-2 items-center">
            {actions}
        </div>
    </div>
}

function buildMenu(page, setPage) {
    return [
        {
            title: "Pages",
            key: "routes",
            items: [
                {
                    label: "Home",
                    count: "",
                    key: "home",
                    icon: null,
                    onClick: () => setPage('pages-home'),
                    isActive: () => page === 'pages-home',
                    can_expand: false,
                    items: []
                },
                {
                    label: "Explore",
                    count: "",
                    key: "explore",
                    icon: null,
                    onClick: () => setPage('pages-explore'),
                    isActive: () => page === 'pages-explore',
                    can_expand: false,
                    items: []
                },
                {
                    label: "Create Account",
                    count: "",
                    key: "create_account",
                    icon: null,
                    onClick: () => setPage('pages-create_account'),
                    isActive: () => page === 'pages-create_account',
                    can_expand: false,
                    items: []
                },
                {
                    label: "Register Association",
                    count: "",
                    key: "register",
                    icon: null,
                    onClick: () => setPage('pages-register'),
                    isActive: () => page === 'pages-register',
                    can_expand: false,
                    items: []
                }
            ]
        },
        {
            title: "Locations",
            key: "locations",
            onTitleClick: () => {
            },
            can_add: true,
            onAdd: () => {
                setPage('create?locations-countries')
            },
            items: [
                {
                    label: "Continents",
                    count: "7",
                    key: "continents",
                    icon: null,
                    onClick: () => setPage('locations-continents'),
                    isActive: () => page === 'locations-continents',
                    can_expand: false,
                    items: []
                },
                {
                    label: "Countries",
                    count: "196",
                    key: "countries",
                    icon: null,
                    onClick: () => setPage('locations-countries'),
                    isActive: () => page === 'locations-countries',
                    can_expand: false,
                    items: []
                },
                {
                    label: "States",
                    count: "9k",
                    key: "states",
                    icon: null,
                    onClick: () => setPage('locations-states'),
                    isActive: () => page === 'locations-states',
                    can_expand: false,
                    items: []
                },
                {
                    label: "Counties",
                    count: "20k",
                    key: "counties",
                    icon: null,
                    onClick: () => setPage('locations-counties'),
                    isActive: () => page === 'locations-counties',
                    can_expand: false,
                    items: []
                },
                {
                    label: "Cities",
                    count: "100k",
                    key: "cities",
                    icon: null,
                    onClick: () => setPage('locations-cities'),
                    isActive: () => page === 'locations-cities',
                    can_expand: false,
                    items: []
                },
                {
                    label: "Tags",
                    count: "23",
                    key: "locations-tags",
                    icon: null,
                    onClick: () => setPage('tags-locations'),
                    isActive: () => page === 'tags-locations',
                    can_expand: false,
                    items: []
                }
            ]
        },
        {
            title: "Associations",
            key: "associations",
            onTitleClick: () => {
            },
            items: [
                {
                    label: "Profiles",
                    count: "1204",
                    key: "profiles",
                    icon: null,
                    onClick: () => setPage('associations-profiles'),
                    isActive: () => page === 'associations-profiles',
                    can_expand: false,
                    items: []
                },
                {
                    label: "Types",
                    count: "93",
                    key: "types",
                    icon: null,
                    onClick: () => setPage('associations-types'),
                    isActive: () => page === 'associations-types',
                    can_expand: false,
                    items: []
                },
                {
                    label: "Categories",
                    count: "1943",
                    key: "categories",
                    icon: null,
                    onClick: () => setPage('associations-categories'),
                    isActive: () => page === 'associations-categories',
                    can_expand: false,
                    items: []
                },
                {
                    label: "Networks",
                    count: "3",
                    key: "networks",
                    icon: null,
                    onClick: () => setPage('associations-networks'),
                    isActive: () => page === 'associations-networks',
                    can_expand: false,
                    items: []
                },
                {
                    label: "Tags",
                    count: "9",
                    key: "assoc-tags",
                    icon: null,
                    onClick: () => setPage('tags-associations'),
                    isActive: () => page === 'tags-associations',
                    can_expand: false,
                    items: []
                }
            ]
        }
    ]
}

function getInputProps(field_type) {
    let type = "text";

    if (field_type === 'string') {
        type = 'text';
    }

    return {
        type
    };
}

function buildFormFields(form, spec) {
    if (!form || !form.fields) {
        return [];
    }
    let a = [];
    form.fields.forEach(fi => {
        const field_data = spec[fi];
        const ip = getInputProps(field_data.type);
        a.push({
            ...field_data,
            id: fi,
            input_type: ip.type,
            input_props: ip
        });
    })
    return a;
}

function allStorageKeys() {

    let entries = [],
        keys = Object.keys(localStorage),
        i = keys.length;

    while (i--) {
        entries.push({
            key: keys[i],
            ...JSON.parse(localStorage.getItem(keys[i]))
        });
    }

    return entries;
}

function loadCollectionFromLocalstorage(db_key, page_index, page_size, sort_key='name') {
    let collection = allStorageKeys();

    let selection = collection.filter(a => a.key.startsWith(`${db_key}.`));

    return selection.sort((a, b) => (a[sort_key] > b[sort_key]) ? 1 : -1);
}

function buildColumns(cols, spec) {
    let a = [];

    cols.forEach(col => {
        const meta = spec[col];
        if (meta) {
            a.push({
                Header: meta.label,
                accessor: col
            });
        }
    })

    return a;
}

function buildTableConfig(table, spec) {
    const {columns, db_key} = table;
    return {
        ready: true,
        loadData: async (page, pp) => {
            const data = loadCollectionFromLocalstorage(db_key, page, pp);
            const base = page * pp;
            let slice = data.slice(base, base + pp);
            let has_more = slice.length >= pp;

            return {
                data: slice,
                has_more: has_more
            }
        },
        can_paginate: true,
        transformData: (d) => {
            let a = [];

            d.forEach((entry, index) => {
                let o = {};
                columns.forEach(col => {
                    o[col] = `${entry[col]||""}`;
                })
                a.push(o);
            })
            return a;
        },
        columns: buildColumns(columns, spec),
        per_page: 10
    };
}

function Page({page, id, can_create, goToCreate, layout = 'table', table, form, spec}) {

    const table_config = table ? buildTableConfig(table, spec) : {};

    return <div>
        <UnaverseSectionHeader
            title={page.title}
            subtitle={page.subtitle}
            actions={<div>
                {can_create&&<div>
                    <Button intent="primary" onClick={()=>goToCreate(id)}>Create</Button>
                </div>}
            </div>}
        />
        <div className="pt-3">
            {layout === 'form' && form &&
            <FormBuilder loadData={form.loadData} init_data={form.init_data} onSubmit={form.onSubmit}
                         fields={buildFormFields(form, spec)}/>}
            {layout === 'table' && table_config.ready && <Table {...table_config} />}
        </div>
    </div>
}

function SimpleSelect({selected, onChange, options = []}) {
    return <select onChange={onChange} value={selected} name="type" id="type">
        {options.map(opt => {
            return <option key={opt.id} value={opt.id}>{opt.label}</option>
        })}
    </select>
}

function buildTypeOptions() {
    return Object.entries(manage_data_model).filter(a => a[1].can_create).map(it => {
        return {
            id: it[0],
            label: it[1].name
        }
    });
}

function CreatePage({config, onChangeType, id}) {
    return <div>
        <UnaverseSectionHeader
            title={`Create ${config?.name}`}
            subtitle={config?.create?.description}
            actions={<div>
                <SimpleSelect options={buildTypeOptions()} onChange={(nt) => onChangeType(nt)}
                              selected={id.replace('create?', '')}/>
            </div>}
        />
        {!config && <div>No Config</div>}
        {config && config.create && <div className="pt-2">
            <FormBuilder options={config.create.options} changeHook={config.create.changeHook}
                         canSubmit={config.create.canSubmit} submit_text="Create"
                         init_data={config.create.init_data || {}} onSubmit={config.create.handleCreate}
                         fields={buildFormFields(config.create, config.spec)}/>
        </div>}
    </div>
}

function SidebarToggle({onClick,on}) {
    return <div onClick={onClick} className="pb-1.5">
        <SimpleBackButton icon={!on?<ArrowRightIcon />:<ArrowLeftIcon />}>
            {on?"Hide":"Show"}
        </SimpleBackButton>
    </div>
}

export function ManageUnaversePage() {
    const [page, setPage] = useState('pages-home');
    const [show_sidebar, setShowSidebar] = useState(true);
    const menu = buildMenu(page, setPage);
    const config = manage_data_model[page];
    return <LayoutComplex show_sidebar={show_sidebar} sidebar={<TableOfContents sections={menu}/>}>
        <div className="overflow-x-auto px-2">
            <SidebarToggle onClick={()=>setShowSidebar(!show_sidebar)} on={show_sidebar} />
            {page.startsWith('create') && <CreatePage onChangeType={(e) => {
                setPage(`create?${e.target.value}`)
            }} id={page} config={manage_data_model[page.replace('create?', '')]}/>}
            {config && <Page id={page} goToCreate={(type)=>{
                setPage(`create?${type}`)
            }} {...config} />}
        </div>
    </LayoutComplex>
}