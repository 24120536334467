import { getFirestore } from 'firebase/firestore';
import {app} from "./firebase-setup";
// local only
if (window.location.hostname === "localhost") {
    /*
    db.settings({
        host: "localhost:8080",
        ssl: false
    });

     */
}

export const db = getFirestore(app);