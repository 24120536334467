import React, {useEffect, useState} from 'react';
import {collection, documentId, getDocs, limit, query, where} from "firebase/firestore";
import {db} from "../../config/setup-firestore";
import {getManyDocuments, useCommunity} from "../../config/community";
import {opt_in_getCurrentMembershipPeriodID, opt_in_getMembershipPeriods} from "./opt-in-utilities";
import {OptInPromptWrapper} from "./opt-in";
import {PolicyGateWrapper} from "./policy";
import OnboardingGate from "./onboarding";
import {fetchFlow} from "../../routes/community/onboarding";

// first load all active gates based on scope
// then load all relevant data for each gate
// check if the user needs to do anything before they can access the scope
// if so, show them the gate

/*
EXAMPLE
[
    {
        "start_date": "2021-01-01",
        "start_year": 2021,
        "start_month": 0,
        "end_date": "2021-12-31",
        "end_year": 2021,
        "end_month": 11,
        "is_current_period": false,
        "id": "0_2021__11_2021",
        "label_index": 0,
        "label": "2021"
    },
    {
        "start_date": "2022-01-01",
        "start_year": 2022,
        "start_month": 0,
        "end_date": "2022-12-31",
        "end_year": 2022,
        "end_month": 11,
        "is_current_period": false,
        "id": "0_2022__11_2022",
        "label_index": 0,
        "label": "2022"
    },
    {
        "start_date": "2023-01-01",
        "start_year": 2023,
        "start_month": 0,
        "end_date": "2023-12-31",
        "end_year": 2023,
        "end_month": 11,
        "is_current_period": true,
        "id": "0_2023__11_2023",
        "label_index": 0,
        "label": "2023"
    },
    {
        "start_date": "2024-01-01",
        "start_year": 2024,
        "start_month": 0,
        "end_date": "2024-12-31",
        "end_year": 2024,
        "end_month": 11,
        "is_current_period": false,
        "id": "0_2024__11_2024",
        "label_index": 0,
        "label": "2024"
    },
    {
        "start_date": "2025-01-01",
        "start_year": 2025,
        "start_month": 0,
        "end_date": "2025-12-31",
        "end_year": 2025,
        "end_month": 11,
        "is_current_period": false,
        "id": "0_2025__11_2025",
        "label_index": 0,
        "label": "2025"
    }
]
 */

async function getGatesForScope(scope, scope_id) {
    // get all active gates for this scope from communities > gates where scope = scope and scope_id = scope_id and status = active

    const ref = collection(db, "communities", scope_id, "gates");
    const q = query(ref, where("status", "==", 'active'), limit(5));
    return await getDocs(q)
        .then(snap => {
            return snap.docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            });
        })
}

async function loadGatesMetadata(gates, community_uid) {
    const policy_ids = gates.filter(g => g.type === 'policy')[0]?.data?.policy_ids || [];

    const policies = await getManyDocuments('communities', community_uid, 'policies', documentId(), policy_ids)
        .then(docs => {
            return docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            });
        })

    return {
        policies
    }
}

function checkIfHasCompletedOnboarding(community_uid, member, onboarding_flow) {

    if(!member.flows) {
        return false;
    }
    // todo add support for multiple flows
    const key = Object.keys(member?.flows)[0];
    return member?.flows?.[key] === "complete";
    /*

    const onboarding_flow = await fetchFlow(community_uid, "onboarding");

    if (!onboarding_flow) {
        return 'n/a';
    } else {
        if (!member.flows) {
            return 'no';
        }
        return member.flows[onboarding_flow.id] ? 'yes' : 'no';
    }
     */
}

function getGatesToEnforce(community, gates, policies, opt_in_periods, preview_opt_in, preview_policy) {
    let member_policies = community?.member?.policies ? community?.member?.policies : {};

    let gates_to_enforce = [], membership_period_id = "";

    // add onboarding gate
    const onboarding_gate = gates.filter(g => g.type === 'onboarding')[0];

    if (onboarding_gate) {
        const has_completed_onboarding = checkIfHasCompletedOnboarding(community.uid, community.member);

        if (!has_completed_onboarding) {
            gates_to_enforce.push({
                type: "onboarding",
                slug: onboarding_gate.id,
                gate_data: onboarding_gate.data
            })
        }
    }

    for (let i = 0; i < policies.length; i++) {
        const {id, version} = policies[i];
        const uid = `${id}__${version}`;
        if (!member_policies[uid] || preview_policy) {
            gates_to_enforce.push({
                type: "policy",
                slug: id,
                version: version,
                gate_data: gates.filter(g => g.data.policy_ids.includes(id))[0]?.data
            });
        }
    }

    // add opt-in gates, see if we need the current gate
    if (community?.profile?.preferences?.members?.enable_member_opt_in) {
        // now see if we need to opt-in to the current period, what's the current ID
        membership_period_id = opt_in_getCurrentMembershipPeriodID(community.profile, opt_in_periods);

        // if membership_period_id and not opted in, add gate
        if (membership_period_id && !community?.member?.opt_in?.[membership_period_id] || preview_opt_in) {
            gates_to_enforce.push({
                type: "opt_in",
                slug: membership_period_id,
                gate_data: gates.filter(g => g.type === "opt_in")[0]?.data
            });
        }
    }

    return gates_to_enforce;
}

function previewIsActive(val) {
    if (!val || !window.location.search.includes('preview')) {
        return false;
    }
    const params = new URLSearchParams(window.location.search);
    const preview = params.get('preview');
    return preview === val;
}

export const usePreviewActive = (val) => {
    const [preview, setPreview] = useState(false);

    useEffect(() => {
        setPreview(previewIsActive(val));
    }, [val]);

    return preview;
};

export function GateEnforcer({scope, scope_id}) {
    const community = useCommunity();
    const [gates, setGates] = useState([]);
    const [policies, setPolicies] = useState([]);
    const preview_opt_in = usePreviewActive("opt_in-gate");
    const preview_policy = usePreviewActive("policy-gate");
    const preview_onboarding = usePreviewActive("onboarding-gate");
    function handleUpdate() {
        community.updateMemberProfile();
    }

    useEffect(() => {
        getGatesForScope(scope, scope_id)
            .then(gates => {
                setGates(gates);
            })
    }, [scope, scope_id]);

    useEffect(function () {
        if (!gates || gates.length === 0) {
            return;
        }
        loadGatesMetadata(gates, community.uid)
            .then(({policies}) => {
                setPolicies(policies);
            })
    }, [gates, community.uid])

    if (!community || !community.uid || !community.profile || !community.member || gates.length === 0) {
        return null;
    }

    const opt_in_periods = opt_in_getMembershipPeriods(community.profile);

    const gates_to_enforce = getGatesToEnforce(community, gates, policies, opt_in_periods, preview_opt_in, preview_policy);

    const onboarding_gates = gates_to_enforce.filter(g => g.type === 'onboarding');
    const policy_gates = gates_to_enforce.filter(g => g.type === 'policy');
    const opt_in_gates = gates_to_enforce.filter(g => g.type === 'opt_in');

    if (onboarding_gates.length > 0) {
        return <OnboardingGate community_data={community.profile} preview={preview_onboarding}
                               member_id={community.member_id} community_uid={community.uid}
                               gate_data={onboarding_gates[0].gate_data}
                               updateParent={handleUpdate}
        />
    } else if (opt_in_gates.length > 0) {
        return <OptInPromptWrapper community_data={community.profile} preview={preview_opt_in}
                                   member_id={community.member_id}
                                   community_uid={community.uid} gate_data={opt_in_gates[0].gate_data}
                                   period_data={opt_in_periods.find(a => {
                                       return a.id === opt_in_gates[0].slug;
                                   })} current_period_id={opt_in_gates[0].slug} updateParent={handleUpdate}/>;
    } else if (policy_gates.length > 0) {
        return <PolicyGateWrapper community_data={community.profile} preview={preview_policy}
                                  member_id={community.member_id}
                                  community_uid={community.uid} updateParent={handleUpdate}
                                  policies_to_accept={policy_gates}/>
    } else {
        return null;
    }
}