import React, {forwardRef} from 'react';

/*
* This is the Overlay atom
 */
const Children = forwardRef((props, ref) => {
    return <div ref={ref}>{props.children}</div>;
});

function M3_A_Overlay({children}) {

}

export default Object.assign(M3_A_Overlay)