import React from "react";
import M3_A_Heading from "./heading";

/*
* This is the SectionHead atom
* It is a simple sectionhead component
 */

const _props = {
    children: {
        type: "string",
        required: true
    },
    skeleton: {
        type: "boolean",
        required: false,
        default: false
    }
};

export default function M3_A_SectionHead({
                                             children,
                                             skeleton
                                         }) {
    return (
        <div className="flex gap-4">
            <div className="flex-grow">
                <M3_A_Heading skeleton={skeleton} size="h5">
                    {children}
                </M3_A_Heading>
            </div>
            <div className="flex-none">

            </div>
        </div>
    );
}