import React from 'react';

const ACCENT_COLORS = [
    '#027581',
    '#2A3643',
    '#B91C1C',
    '#FBBF24',
    '#2ca7ec',
    '#4C1D95',
    '#F472B6',
    '#0F766E',
    '#374151',
    '#65A30D',
    '#F15F31',
    '#FB7185',
];
const ACCENT_GRADIENTS = [
    'linear-gradient(to right, #e65c00, #f9d423)',
    'linear-gradient(to right, #2193b0, #6dd5ed)',
    'linear-gradient(to right, #cc2b5e, #753a88)',
    'linear-gradient(to right, #ec008c, #fc6767)',
    'linear-gradient(to right, #1488cc, #2b32b2)',
    'linear-gradient(to right, #0f2027, #203a43, #2c5364)',
    'linear-gradient(to right, #12c2e9, #c471ed, #f64f59)',
    'linear-gradient(to right, #f12711, #f5af19)',
    'linear-gradient(to right, #659999, #f4791f)',
    'linear-gradient(to right, #8360c3, #2ebf91)',
    'linear-gradient(to right, #654ea3, #eaafc8)',
    'linear-gradient(to right, #8a2387, #e94057, #f27121)',
];

function Item({initial,onSelect,color}) {
    return <div onClick={onSelect} style={{background:color}} className={`h-8 w-8 transform hover:scale-110 transition-transform cursor-pointer hover:opacity-60 text-lg font-semibold inline-flex justify-center leading-8 text-white rounded-md bg-gray-400`}>
        {initial}
    </div>
}

const colors = [
    ...ACCENT_COLORS,
    ...ACCENT_GRADIENTS
];

export function EntityColorSelector({onSelect,initial="U"}) {

    return <div className="grid grid-cols-8 gap-4 px-4 py-1">
        {colors.map((co,k)=><Item onSelect={()=>onSelect(co)} key={k} color={co} initial={initial} />)}
    </div>
}