import React, {useEffect, useRef, useState} from "react";
import './styles.css';
import {
    ArrowLeftIcon,
    ArrowRightIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    XMarkIcon
} from "@heroicons/react/24/outline";
import SimplePortal from "../simple-portal";
import {hexToRgbStr, setAlpha} from "../../../m3/utilities/colors";
import {FPPreviewer} from "./previews/previewer";
import {useIsMobile} from "../../../m3/hooks/is-mobile";
import {PostSidebarView} from "../../../routes/community/message";

function ActionCircle({onClick, icon}) {
    return <div onClick={() => {
        if (onClick) {
            onClick();
        }
    }}
                style={{backgroundColor: 'rgba(0,0,0,0.3)', backdropFilter: 'hue-rotate(25deg)'}}
                className="h-10 cursor-pointer hover:opacity-60 transition-opacity w-10 flex items-center justify-center text-white rounded-full">
        <div className="w-6 h-6">
            {icon}
        </div>
    </div>
}

function TopBar({setShowSidebar, show_toggle, show_sidebar, onClose}) {
    return <div className="flex">
        <div className="flex-grow">
        </div>
        <div>
            {show_toggle && <ActionCircle icon={show_sidebar ? <ChevronDoubleRightIcon/> : <ChevronDoubleLeftIcon/>}
                                          onClick={() => setShowSidebar()}/>}
            <div onClick={() => onClose()} className="text-white pl-3 pr-2 py-1.5 rounded-full bg-black bg-opacity-60 hover:bg-opacity-80 flex gap-1 hover:opacity-70 cursor-pointer">
                <div className="text-base font-semibold">Close</div>
                <div className="h-6 w-6">
                    <XMarkIcon/>
                </div>
            </div>
        </div>
    </div>
}

function BottomBar({}) {
    return <div className="absolute bottom-0 left-0 right-0">

    </div>
}

function LeftArrow({onClick}) {
    return <div onClick={onClick} className="absolute flex items-center left-4 top-0 bottom-0">
        <ActionCircle icon={<ArrowLeftIcon/>}/>
    </div>
}

function RightArrow({onClick}) {
    return <div onClick={onClick} className="absolute flex items-center right-4 top-0 bottom-0">
        <ActionCircle icon={<ArrowRightIcon/>}/>
    </div>
}

function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {

    let ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

    return { width: srcWidth*ratio, height: srcHeight*ratio };
}

function ImageRenderer({maxWidth,maxHeight,is_mobile,...rest}) {

    let image = rest?.image;

    if(!image) {
        return null;
    }

    // ensure non zero width and height
    if(!image.width || !image.height) {
        image.width = 1;
        image.height = 1;
    }

    const url = typeof image === 'string' ? image : image.url ? image.url : "";

    const portrait = image.width && image.height && image.height > image.width;

    if (!url) {
        return null;
    }

    const fit = calculateAspectRatioFit(image.width, image.height, maxWidth, maxHeight);
    let styles = {};

    if (image.height && portrait) {
        styles.backgroundSize = `auto ${image.height}`;
    } else if (image.height) {
        styles.backgroundSize = `${image.width} auto`;
    }

    styles.backgroundImage = `url('${url}')`;

    return <img alt="Post Image" width={fit.width} height={fit.height} src={url}
    className="" />
}

function FileViewer({data, is_mobile}) {
    return <FPPreviewer is_mobile={is_mobile} url={data?.url || ""} meta={{
        type: data?.type || "",
        name: data?.name || "",
        size: data?.size || 0,
    }}/>
}

export function LightboxWrapper({
                                    context = "group",
                                    context_id = "",
                                    type = "post",
                                    data,
                                    id = "",
                                    onClose = () => {
                                    },
                                    overview,
                                    starting_index = 0,
                                    items = []
                                }) {

    console.log(" LightboxWrapper data", data, id)
    const sidebar = type !== 'post' ? null : <div>
        {type === 'post' && id && data && <PostSidebarView data={data} id={id}/>}
    </div>;

    return <Lightbox overview={overview} onClose={onClose} sidebar={sidebar} items={items}
                     init_selected={starting_index}/>
}

const image_extensions = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "svg",
    "webp",
    "bmp",
    "tiff",
    "tif"
]

function urlHasExtension(url) {
    const ext = url.split('.').pop().split('?')[0].toLowerCase();
    return image_extensions.includes(ext);
}

function itemIsImage(item_data) {
    return typeof item_data === 'string' || item_data?.type?.startsWith("image/") || urlHasExtension(item_data?.url || "");
}

function LargeButton({
                         label = "",
                         onClick
                     }) {

    return <div onClick={onClick}
                className="rounded-md flex items-center gap-2 text-white px-3 py-1.5 hover:opacity-70 transition-opacity cursor-pointer"
                style={{backgroundColor: "rgba(63,63,63,0.85)"}}>
        <div className="h-5 w-5">
            <ArrowLeftIcon/>
        </div>
        <div className="text-base font-semibold">{label}</div>
    </div>
}

function ItemsGrid({
                       items = [], onSelectItem = () => {
    }
                   }) {
    return <div className="grid gap-4" style={{gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))'}}>
        {items.map((item, index) => {
            const is_image = itemIsImage(item);

            if (is_image) {
                return <div key={`item-${index}`}
                            onClick={() => {
                                onSelectItem(index)
                            }}
                            className={`rounded-lg h-full w-full cursor-pointer bg-gray-200 rounded-md`}
                            style={{
                                aspectRatio: '1/1',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundImage: `url(${item.url})`
                            }}
                />
            }
            return <div key={index} className="w-full h-full">

            </div>
        })}
    </div>
}

export default function Lightbox({
                                     right_sidebar = true,
                                     onClose = () => {
                                     },
                                     sidebar,
                                     overview,
                                     simple,
                                     init_selected = 0,
                                     items = []
                                 }) {
    const is_mobile = useIsMobile();
    const [maxWidth, setMaxWidth] = useState(0);
    const [maxHeight, setMaxHeight] = useState(0);
    const [mode, setMode] = useState("single-media");
    const [selected, setSelected] = useState(init_selected);
    const [show_sidebar, setShowSidebar] = useState(right_sidebar && !!sidebar);
    const mediaContainerRef = useRef(null);

    const has_overview = overview && items.length > 1;

    useEffect(function () {
        if(mediaContainerRef.current) {
            if(is_mobile) {
                setMaxWidth(window.innerWidth);
                setMaxHeight(window.innerHeight);
            } else {
                setMaxWidth(mediaContainerRef.current.clientWidth);
                setMaxHeight(mediaContainerRef.current.clientHeight);
            }
        }
    }, [])

    function next() {
        setSelected(selected + 1);
    }

    function previous() {
        setSelected(selected - 1);
    }

    const item_data = items[selected];

    const is_image = itemIsImage(item_data);

    const rgb = item_data?.color?.startsWith("#") ? hexToRgbStr(item_data?.color) : item_data?.color;

    const image_color = item_data?.color ? setAlpha(rgb, 0.85) : "rgba(0,0,0,0.85)";

    const can_go_next = (selected + 1) !== items.length && items.length > 0;

    const can_go_previous = selected !== 0;

    let content;

    if(simple) {
        return <SimplePortal onClose={()=>onClose()} background_color={image_color}>
            <div className={`grid`}>
                <div className="relative h-screen">
<div className="absolute top-4 right-4">
                    <TopBar onClose={()=>onClose()} />
</div>
                    <div className="px-8 pt-12 pb-12 h-full flex items-center justify-center">
                        <ImageRenderer image={item_data}/>
                    </div>
                </div>
            </div>
        </SimplePortal>
    }

    if (mode === "single-media") {
        content = <div className={`${show_sidebar && sidebar ? "lightbox-grid" : "grid-cols-1"} h-full grid sm:rounded-lg`}>
            <div className="relative bg-black sm:rounded-l-lg">
                <div ref={mediaContainerRef} className={is_image ? `flex items-center justify-center h-full` : `h-full sm:rounded-l-lg overflow-hidden`}>
                    {is_image && maxWidth && maxHeight && <ImageRenderer maxWidth={maxWidth} maxHeight={maxHeight} is_mobile={is_mobile} image={items[selected]}/>}
                    {!is_image && <FileViewer is_mobile={is_mobile} data={items[selected]}/>}
                </div>
                {has_overview && mode === "single-media" && <div className="absolute left-4 top-4">
                    <LargeButton {...overview.back} onClick={() => setMode("overview")}/>
                </div>}
                {can_go_previous && <LeftArrow onClick={() => previous()}/>}
                {can_go_next && <RightArrow onClick={() => next()}/>}
                <BottomBar/>
            </div>
            {show_sidebar && sidebar &&
                <div style={{maxHeight: 'calc(100vh - 5rem)'}}
                     className="bg-white rounded-r-lg overflow-y-auto px-6 pt-6 pb-12 tablet-hide">
                    {sidebar}
                </div>}
        </div>
    } else {
        content = <div className="bg-white divide-y divide-gray-300 h-full sm:rounded-lg ">
            <div className="px-6 pt-6 pb-6">
                <div>
                    <div className="text-xl font-bold text-gray-900">
                        {overview.title || "Photos"}
                    </div>
                </div>
                <div></div>
            </div>
            <div className="overflow-y-auto px-6 pt-6 pb-12">
                <ItemsGrid onSelectItem={(itemIndex) => {
                    setSelected(itemIndex)
                    setMode("single-media")
                }} items={items}/>
            </div>
        </div>
    }

    return <SimplePortal onClose={()=>{
        onClose();
    }} escape_to_close background_color={"rgba(0,0,0,0.85)"}>
        <div className={`sm:px-8 sm:py-6 h-full flex flex-col`}>
            <div className="pb-2 flex-none pr-2 pt-2">
                <TopBar show_toggle={false} show_sidebar={show_sidebar} onClose={() => onClose()}
                        setShowSidebar={() => setShowSidebar(!show_sidebar)}/>
            </div>
            <div className="flex-grow">
                {content}
            </div>
        </div>
    </SimplePortal>
};