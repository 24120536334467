import React from "react";
import {opt_in_getCurrentMembershipPeriodID, opt_in_getMembershipPeriods} from "../../../features/gates/opt-in-utilities";
import dayjs from "dayjs";

export function MemberOptInDetails({data, community}) {
    const opt_in_periods = opt_in_getMembershipPeriods(community.profile);

    const current_period_id = opt_in_getCurrentMembershipPeriodID(community.profile,opt_in_periods);

    if (!current_period_id) {
        // no community policies
        return <p>
            No current membership period
        </p>
    }

    const member_current_opt_in = data?.opt_in?.[current_period_id];
    const member_current_opt_in_meta = data?.opt_in_meta?.[current_period_id];

    return <div>
        <p>
            {member_current_opt_in&&<span>Member opted-in on {dayjs.unix((member_current_opt_in_meta.ts/1000)).format('MMMM Do YYYY [at] h:mma')}</span>}
            {!member_current_opt_in&&<span>Member has not opted-in</span>}
        </p>
    </div>
}