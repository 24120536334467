import React from "react";
import {m3_icon_map} from "../icons/icon-map";
import M3_A_Select from "../atoms/select";

/*
* This is the FieldPrivacyControl atom
 */

export function getCanShowAge(member={},field="birthday_date_visibility") {
    return getFieldPrivacyGetDefaultValue(member,field) === "visible";
}

export function getFieldPrivacyGetDefaultValue(member={},field="birthday_date_visibility") {
    if(member?.[field]) {
        return member[field];
    }

    return "visible";
}

const options = [
    {
        label: "Only you",
        value: "only-you"
    },
    {
        label: "All members, without year",
        value: "date-only"
    },
    {
        label: "All members, including year",
        value: "visible"
    }
];

export default function FieldPrivacyControl({onChange, value, sx, ...rest}) {

    return <div>
        <M3_A_Select leading_indicator={{
        type: "icon",
        icon: m3_icon_map.outlines.show
    }} onChange={e => {
        onChange?.(e.target.value)
    }} value={value} options={options}/>
    </div>
}