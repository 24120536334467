import React, {useEffect} from 'react';
import M3_A_Text from "./text";
import M3_A_ListItem, {m3_getStyle} from "./list-item";
import M3_A_IconButton from "./icon-button";
import {CheckIcon, ChevronDownIcon, ChevronRightIcon, XMarkIcon} from "@heroicons/react/20/solid";
import M3_A_Icon from "./icon";
import M3_A_Checkbox from "./checkbox";
import {m3_adjust_size} from "../utilities";
import styled from "styled-components";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import M3_A_SearchInput from "./search-input";
import {useProvidedStateOrCreate} from "../hooks";
import {m3_icon_map} from "../icons/icon-map";
import {useDraggableInPortal} from "../_legacy_components/directory/customize-layout";
import M3_A_NodeSelector from "./node-selector";

/*
* This is the ActionList component
 */

function Divider() {
    return <div className="border-t my-2 border-gray-200"></div>
}

if (!document.body) {
    throw new Error('body not ready for portal creation!');
}

const SubHeaderWrapper = styled.div`
    color: ${props => m3_getStyle(props?.styles?.resting, "text_color", "#3b3b3b")};

    &:hover {
    }

    &:active {
    }
`;

const renderLabel = (label,size,label_sx,onClick) => {
    if(!label) return null;
    const c = <M3_A_Text sx={label_sx} size={size}>
        {label}
    </M3_A_Text>;

    if(onClick) {
        return <M3_A_NodeSelector dropdown={false} onClick={onClick}>
            {c}
        </M3_A_NodeSelector>;
    } else {
        return c;
    }
}

function SubHeader({label, styles, label_sx, onClick, list, size, icon_props = {}}) {
    const _size = m3_adjust_size(size || list.size, -1);
    return <SubHeaderWrapper styles={styles}
                             className={` flex gap-1 items-center ${list.collapsible ? "-ml-0.5 px-1.5" : "px-2"}`}>
        {list.collapsible && <M3_A_IconButton sx={{padding: 0, border: "none"}} size="xs" intent="secondary" square
                                              onClick={list.toggleCollapsed} {...icon_props}
                                              icon={list.collapsed ? <ChevronRightIcon/> : <ChevronDownIcon/>}/>}
        {renderLabel(label,_size,label_sx,onClick)}
    </SubHeaderWrapper>
}

function renderComponent(type, index, data, id, list, styles, fns) {
    switch (type) {
        case "divider":
            return <Divider key={id} index={index} id={id} styles={styles?.['divider']} {...data} />;
        case "subheader":
            return <SubHeader key={id} index={index} list={list} id={id} styles={styles?.['subheader']} {...data} />;
        default:
            return <ListItemWrapper index={index} key={id} id={id} styles={styles?.['item']} data={data} list={list}
                                    fns={fns}/>;
    }
}

function ListItemWrapper({data, id, list, index, styles, fns}) {
    // leading indicator is for e.g. avatar, color, icon
    // trailing indicator is for e.g. counter
    // leading action is for e.g. checkbox, drag handle
    let props = {
        list,
        size: list.size
    };

    // if can_select, add onSelect as onClick
    if (list.can_select) {
        props.onClick = () => fns.handleSelect(id);
        if (list.multi_select) {
            props.is_selected = list.selected.includes(id);

            props.leading_action = <M3_A_Checkbox checked={props.is_selected}/>;
        } else {
            props.is_selected = list.selected === id;
            props.leading_action = <M3_A_Icon sx={{opacity: props.is_selected ? "100%" : "0%"}} icon={<CheckIcon/>}/>;
        }
    } else {
        if (list.can_drag) {

            props.leading_action = <div className=""/>;

        }
        if (data.can_edit || data.can_delete) {
            props.trailing_actions = [];
            if (data.can_edit) {
                props.trailing_actions.push(<M3_A_IconButton intent="secondary" square adjust_icon_size={-1}
                                                             size={list.size} sx={{
                    padding: 0,
                    border: "none"
                }} onClick={() => {
                    fns.handleEdit(id, data);
                }} icon={m3_icon_map.outlines.edit}/>);
            }
            if (data.can_delete) {
                props.trailing_actions.push(<M3_A_IconButton intent="secondary" square adjust_icon_size={-1}
                                                             size={list.size} sx={{
                    padding: 0,
                    border: "none"
                }} onClick={() => {
                    fns.handleDelete(id, data);
                }} icon={m3_icon_map.outlines.close}/>);
            }
        }
        if (data.opens_submenu) {
            props.trailing_actions = [<M3_A_IconButton intent="secondary" square adjust_icon_size={0} size={m3_adjust_size(list.size, -1)} sx={{
                    padding: 0,
                    border: "none"
                }} onClick={data.onClick} icon={<ChevronRightIcon/>}/>];
        }
        if (data.onClick) {
            props.onClick = data.onClick;
        }
    }

    return <M3_A_ListItem styles={styles} {...data} {...props} />;
}

const _props = {
    items: {
        type: "array",
        required: true,
        default: []
    },
    size: {
        type: "string",
        required: false,
        default: "base",
        options: ["sm", "base", "lg"]
    },
    can_select: {
        type: "boolean",
        required: false,
        default: false
    },
    selected: {
        type: "string | array",
        required: false,
        default: null
    },
    multi_select: {
        type: "boolean",
        required: false,
        default: false
    },
    can_drag: {
        type: "boolean",
        required: false,
        default: false
    }
};

const default_styles = {
    item: {
        hover: {
            background_color: "#eaeaea",
        },
        focus: {
            background_color: "#e1e1e1"
        },
        active: {
            background_color: "#ffffff",
            text_color: "#000000",
            token_color: "#3f3f3f"
        },
        resting: {
            text_color: "#000000",
            token_color: "#3f3f3f"
        }
    },
    divider: {},
    subheader: {}
};

function reorder(arr, from, to) {
    const result = Array.from(arr);
    const [removed] = result.splice(from, 1);
    result.splice(to, 0, removed);
    return result;
}

function ReorderableListWrapper({
                                    items,
                                    handleOnDragEnd = (result) => {
                                    },
                                    collapsed,
                                    list_props,
                                    styles,
                                    fns
                                }) {
    const renderDraggable = useDraggableInPortal();

    return <div className="relative">
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="action-list-id">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>

                        {items.map((item, index) => {
                            let id = item.id || index;
                            if (collapsed && item.type !== "subheader") {
                                return null;
                            }

                            return <Draggable isDragDisabled={false} key={id} draggableId={id}
                                              index={index}>
                                {renderDraggable((provided, snapshot) => {
                                    return <div ref={provided.innerRef}
                                                className="relative"
                                                key={id} {...provided.draggableProps} style={{
                                        ...provided.draggableProps.style,
                                        zIndex: snapshot.isDragging ? 10000 : 1
                                    }}>
                                        <div
                                            className="absolute top-0 left-0 w-8 justify-center bottom-0 inline-flex items-center">
                                            <div {...provided.dragHandleProps}>
                                                <M3_A_IconButton sx={{
                                                    padding: 0,
                                                    transform: "rotate(90deg)",
                                                    border: "none",
                                                    cursor: "grab"
                                                }} adjust_icon_size={-1} size={list_props.size} intent="tertiary"
                                                                 icon={m3_icon_map.custom['grab-handle']}/>
                                            </div>
                                        </div>
                                        {renderComponent(item.type, index, item, id, list_props, styles, fns)}
                                    </div>
                                })}
                            </Draggable>
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    </div>
}

function filterItems(q, items) {
    return items.filter((item) => {
        if (item.type === "subheader") return true;
        if (!q) return true;
        if (item.label.toLowerCase().includes(q.toLowerCase())) return true;
        return false;
    });
}

export default function M3_A_ActionList({
                                            items,
                                            can_select,
                                            handleEdit,
                                            initial_query,
                                            enable_search_filter,
                                            selected = null,
                                            onSelect,
                                            collapsible,
                                            styles = {...default_styles},
                                            can_delete,
                                            handleOnDragEnd = (result) => {
                                                console.log("RESULT", result)
                                            },
                                            handleDelete = () => {
                                            },
                                            multi_select,
                                            can_drag,
                                            size = "base"
                                        }) {
    const [q, setQ] = React.useState(initial_query || "");
    const [s, setS] = useProvidedStateOrCreate(selected, onSelect, multi_select ? [] : "");
    const [collapsed, setCollapsed] = React.useState(false);

    useEffect(() => {
        if (onSelect) {
            onSelect(s);
        }
    }, [s]);

    function handleSelect(id) {
        // todo can unselect
        if (multi_select) {
            if (s.includes(id)) {
                setS(s.filter((i) => i !== id));
            } else {
                setS([...s, id]);
            }
        } else {
            if (s === id) {
                setS("");
            } else {
                setS(id);
            }
        }
    }

    const fns = {
        handleSelect,
        handleDelete,
        handleEdit
    };

    const list_props = {
        can_drag,
        can_select,
        multi_select,
        selected: s,
        size,
        can_delete,
        collapsible,
        collapsed,
        toggleCollapsed: () => setCollapsed(!collapsed)
    };

    if (!!can_drag) {

        return <ReorderableListWrapper {
                                           ...{
                                               items,
                                               handleOnDragEnd,
                                               collapsed,
                                               list_props,
                                               styles,
                                               fns
                                           }}
        />
    }

    const filtered_items = filterItems(q, items);

    return <div className="relative">
        {enable_search_filter && <>
            <M3_A_SearchInput value={q} show_icon={false} size="sm" variant="minimal" auto_focus onChange={e => {
                setQ(e.target.value);
            }}/>
            {renderComponent("divider", 0, {}, "search-divider", list_props, styles, fns)}
        </>}
        {filtered_items.map((item, index) => {
            let id = item.id || index;
            if (collapsed && item.type !== "subheader") {
                return null;
            }
            return renderComponent(item.type, index, item, id, list_props, styles, fns);
        })}
        {filtered_items.length === 0 && q &&
            <div className="p-2 text-center"><M3_A_Text color="text-gray-500">No results</M3_A_Text></div>}
    </div>
}