import React, {useEffect, useState} from 'react';
import M3_A_SearchInput from "./search-input";
import M3_A_Button from "./button";

/*
* This is the subnav bar atom
 */

export default function M3_A_SubnavBar({
                                           variant = "search",
                                           onEnter,
                                           left_component,
                                           children,
    ...rest
                                       }) {
    const [query, setQuery] = useState("");
    const [is_searching, setIsSearching] = React.useState(false);

    useEffect(function () {
        if (is_searching) {
            if(rest?.onOpenSearch)
                rest?.onOpenSearch();
        } else {
            if(rest?.onCloseSearch)
                rest?.onCloseSearch();
        }
    }, [is_searching])

    if(variant==="filter-bar") {
        return <div className="flex gap-2 -mx-4">
            {children}
        </div>
    }

    //  {!is_searching && <M3_A_IconButton enlarge_icon={false} size="base" variant="ghost" icon={m3_icon_map.outlines.filter}/>}
    return <div className="flex gap-2">
        {left_component && !is_searching && <div>{left_component}</div>}
        <div className="flex-grow">
            <M3_A_SearchInput fill placeholder={rest?.placeholder} value={query} adjust_icon_size={-2} onChange={(e) => {
                setQuery(e?.target?.value);
            }} onEnter={(e) => {
                if (e?.target?.value) {
                    onEnter(e?.target?.value)
                }
            }} size="base" onBlur={() => {

            }} onFocus={() => {
                setIsSearching(true);
            }}/>
        </div>
        {is_searching&&<div>
            <M3_A_Button size="base" variant="minimal" intent="primary" compact onClick={() => {
                setIsSearching(false);
                setQuery("");
                onEnter("");
            }} text="Cancel"/>
        </div>}
    </div>
}