import React from "react";
import {InlineLoader} from "../../../../m3/_legacy_components/admin-activity";
/*
const props = {
    className?: string;
    onMouseDown(event: MouseEvent): void;
    onMouseUp(event: MouseEvent): void;
    onMouseEnter(event: MouseEvent): void;
    role: string;
    id: string;
    'aria-selected'?: boolean | 'false' | 'true';
    theme?: MentionPluginTheme;
    mention: MentionData;
    isFocused: boolean;
    searchValue?: string;
}

 */

export function MentionEntry(props) {
    const {
        mention,
        theme,
        searchValue,
        isFocused,
        ...parentProps
    } = props;

    // todo add loading item
    // todo add no results items
    const bg = isFocused ? "bg-selection" : "bg-white hover:bg-gray-100";
    const color = isFocused ? "text-white" : "text-gray-600";
    const color_2 = isFocused ? "text-gray-300" : "text-gray-600";

    if(mention.no_results) {
        return <div className={`transition-colors pt-px pb-px`}>
            <div className="flex px-3 py-1.5">
                <div className="flex-grow flex items-end">
                    <div className={`w-auto font-medium text-sm text-gray-500`}>
                        {mention.label}
                    </div>
                </div>
            </div>
        </div>
    }

    if(mention.loading) {
        return <div className={`transition-colors pt-px pb-px`}>
            <InlineLoader padding="p-2" mini />
        </div>
    }

    //  <GroupDisplayIcon size="xs" type="member" profile_picture={mention.image?mention.image:""} name={mention.name} emoji={mention.emoji?mention.emoji:""} />
    return (
        <div className={`${bg} transition-colors cursor-pointer pt-px pb-px`}>
            <div className="flex px-3 py-1.5">
                <div className="flex-grow flex items-center">
                    <div className={`w-5 mr-2 flex`} style={{height:'20px'}}>

                    </div>
                    <div className={`text-sm font-medium mr-4 ${color}`}>{mention.name}</div>
                    {mention.meta&&<div style={{lineHeight:'18px'}} className={`${color} text-xs`}>{mention.meta}</div>}
                </div>
                {mention.note&&<div className={`text-xs pt-px flex font-medium items-center ${color_2}`}>
                    {mention.note}
                </div>}
            </div>
        </div>
    );
}