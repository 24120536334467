import React, {useState} from 'react';
import {useCommunity} from "../../../config/community";
import {useToasts} from "../../../config/toasts";
import Button from "../../../m3/_legacy_components/button";
import Field from "../../../m3/_legacy_components/form/field";
import {api_createGroup} from "../../../../api/groups/create";
import HandleSelector from "../../auth/create-account/handle-selector";
import {slugify} from "../../../m3/utilities/slugify";
import {getNameMeta} from "../../auth/create-account";
import {useNavigate} from "react-router-dom";
import {ModalBody, ModalContentBlock, ModalFooter, ModalHeader} from "../../../m3/_legacy_components/preview-overlay";
import {PrettyCheckbox, Toggle} from "../../../m3/_legacy_components/toggle";
import {renderField} from "../../../m3/_legacy_components/profile-field-renderer";
import IconSelector from "../../../m3/_legacy_components/icon-selector";
import {handleSetSpaceLocation} from "../group/api";
import {SuggestAddLocation} from "../group/misc-components";
import {ToggleWithTitle} from "../../../m3/_legacy_components/toggle-with-title";
import {logEngagementEvent} from "../../../config/firebase-setup";
import {getNameInitials} from "../../../m3/utilities/get-name-initials";

// Groups help members stay connected and organized.
// If your group is closed, only members you add can view group contents.

/*
   {getNameMeta(handle, flags._customizing, () => setFlags({...flags, _customizing: true}), "group")}
                    <div className={`${flags._customizing ? "block mt-4" : "hidden"}`}>
                        <HandleSelector default_text={handle_name} setHandle={(hh) => {
                            setHandle(hh)
                        }} type='group' scope={"community"} scope_id={community.uid}/>
                    </div>
 */

export function AddGroup({onClose}) {
    const toasts = useToasts();
    const community = useCommunity();
    const [create_another, setCreateAnother] = useState(false);
    const [state, setState] = useState("");
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [handle_name, setHandleName] = useState("");
    const [purpose, setPurpose] = useState("");
    const [location, setLocation] = useState("");
    const [location_place_id, setLocationId] = useState("");
    const [handle, setHandle] = useState("");
    const [color, setColor] = useState("");
    const [profile_picture, setProfilePictureColor] = useState("");
    const [emoji, setEmoji] = useState("");
    const [closed, setClosed] = useState(false);

    const [flags, setFlags] = useState({
        _customizing: false
    })

    const can_create_group = name.length > 1;

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    function handleCreate() {
        const payload = {
            name,
            purpose,
            handle,
            color,
            location,
            closed,
            location_place_id,
            profile_picture,
            emoji,
            entities: {
                filters: {},
                members: {},
                member_types: {},
                roles: {}
            },
            moderators: {
                user_uids: {},
                roles: {},
                members: {}
            },
            filter_id: "",
            member_id: community.member_id,
            community_uid: community.uid,
            interests: {}
        };
        setState("loading");
        api_createGroup(payload)
            .then((response) => {
                setState("");
                if (!response.ok || response?.ok === 'no') {
                    console.error("ERROR", response);
                    toasts.addToast({text: "something went wrong", intent: "danger"})
                    return;
                } else if (response?.data?.id) {
                    logEngagementEvent("create_space", {
                        content_id: response?.data?.id,
                    }, community.uid);
                    postCreateSpaceHooks(response?.data?.id);
                    toasts.addToast({text: "Space created", intent: "success"})
                    if (!create_another) {
                        navigate(`/c/${community.domain}/space/${response.data.id}?ref=created-group`)
                    } else {
                        setName("");
                        setPurpose("");
                        setHandle("");
                        setColor("");
                        setLocation("");
                        setLocationId("");
                        setProfilePictureColor("");
                        setEmoji("");
                        setClosed(false);
                    }
                }
            })
    }

    function postCreateSpaceHooks(nid) {
        community.updateMyEntities('groups', nid, {
            last_read_at: Date.now(),
            updates: 0
        });
    }

    //  <Field value={purpose} onChange={(fid,val)=>setPurpose(val)} label="Purpose" placeholder="What is this space about?" />

    const name_placeholder = "e.g. New York Hub";

    // ${flags._customizing ? "block mt-4" : "hidden"}

    const icon_selector = <div className="absolute top-0 left-0 bottom-0 flex items-center pl-2 pr-1 ">
        <IconSelector onChange={a => {
            setEmoji(a.emoji);
            setColor(a.color);
            setProfilePictureColor(a.profile_picture);
        }} value={{
            type: emoji ? "emoji" : "text",
            emoji: emoji,
            color: color,
            profile_picture: profile_picture,
            profile_picture_color: "",
        }} {...{
            initial: !name?"S":getNameInitials(name, [], 1)
        }} />
    </div>;

    const location_suggest = <SuggestAddLocation simple onSetLocation={(location_label, location_place_id) => {
        setLocation(location_label);
        setLocationId(location_place_id);
    }} all_names={[]} data={{id: "", name}}/>

    return <div className="" style={{minHeight: '16rem'}}>
        <ModalHeader title={"Create Space"} onClose={handleClose}/>
        <ModalBody>
            <ModalContentBlock>
            <div className="pb-5">
                <p className="text-sm text-gray-600">Spaces are where your groups communicate. They're best organized
                    around hubs, teams, or topics.</p>
            </div>
            <div className="space-y-4">

                <Field autoFocus onBlur={() => setHandleName(slugify(name))}
                       input_children={icon_selector} input_props={{
                    style: {
                        paddingLeft: '3.5rem'
                    }
                }}
                       value={name} onChange={(fid, val) => setName(val)} label="Name"
                       placeholder={name_placeholder}/>
                <div className={`hidden`}>
                    {getNameMeta(handle, flags._customizing, () => setFlags({...flags, _customizing: true}), "group")}

                    <HandleSelector default_text={handle_name} setHandle={(hh) => {
                        setHandle(hh)
                    }} type='group' scope={"community"} scope_id={community.uid}/>
                </div>

                <Field optional corner_hint={location_suggest}
                       footer_hint={"Add your space to the community map with a location."} value={location}
                       onChange={(fid, val) => setLocation(val)}
                       label="Location" placeholder={"Location"}/>

                <ToggleWithTitle checked={closed} onToggle={() => setClosed(!closed)}/>
            </div>
            </ModalContentBlock>
        </ModalBody>
        <ModalFooter right={
            <Button loading={state === "loading"} intent="primary" disabled={!can_create_group} text="Create"
                    onClick={handleCreate}/>
        } left={<div>
            <PrettyCheckbox active={create_another} onClick={() => setCreateAnother(!create_another)}
                            label="Create another space"/>
        </div>}/>
    </div>
}