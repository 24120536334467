import React from "react"
import './styles.css';
import {DEFAULT_USER_PHOTO, useCommunity} from "../../../../config/community";
import {SectionHeader} from "../../entity-grid";
import {TextAction} from "../../../../routes/auth/sign-in";
import EmptyState from "../../empty-state";
import {CalendarDaysIcon} from "@heroicons/react/24/outline";
import {group_demo_data} from "../../../../routes/community/group/mock_items";
import {useNavigate} from "react-router-dom";

export function EventAttendees({attendees, max_names = 2, attendee_count}) {
    if (attendee_count === 0) {
        return null;
    }

    const s = attendees.slice(0, 3);

    let str = "";

    if (attendee_count === 1) {
        str = `Pablo is going`;
    } else if (max_names === 0) {
        str = `${attendee_count} people are going`
    } else if (attendee_count === 2 && max_names > 1) {
        str = `Pablo and James are going`;
    } else if (attendee_count === 3) {
        str = `Pablo and 2 others are going`;
    } else if (attendee_count === 4 && max_names > 1) {
        str = `Deandra, Jamie, and ${attendee_count - 2} others are going`;
    } else if (max_names === 1) {
        str = `Sam and ${attendee_count - 1} others are going`;
    } else {
        str = `Sam, Tommy, and ${attendee_count - 2} others are going`;
    }

    return <div className="text-xs pt-1 pb-0.5 flex space-x-1.5 text-gray-600">
        <div className="flex">
            {s.map((it, k) => {
                return <div style={{
                    backgroundImage: `url('${DEFAULT_USER_PHOTO}_small?alt=media')`,
                }} key={k} className={`h-4 w-4 bg-center bg-cover border border-white rounded-md bg-gray-300 
                
                `}/>
            })}
        </div>
        <div>{str}</div>
    </div>
}

// <EventAttendees attendees={attendees} attendee_count={attendee_count} />
export function EventItem({
                              summary,
                              hangoutLink,
                              conferenceData,
                              datetime_string,
                              attendees,
                              attendee_count = 0,
                              _month_name,
                              _day,
                              goToItem=()=>{},
                              location,
                              starts_at
                          }) {

    return <div className="grid gap-3 px-1.5 py-1 group hover:bg-gray-100 cursor-pointer rounded-lg"
                style={{gridTemplateColumns: '2rem 1fr auto'}}>
        <div className="">
            <div className="h-10 w-8 text-center rounded-lg ">
                <div style={{fontSize: '0.64rem'}}
                     className="text-red-500 tracking-wide pt-0.5 uppercase font-bold">{_month_name}</div>
                <div className="text-gray-800 leading-5 text-xl pt-px font-bold">{_day}</div>
            </div>
        </div>
        <div>
            <div onClick={()=>goToItem()} className="text-gray-800 hover:underline leading-5 pt-px font-semibold text-sm">
                {summary}
            </div>
            <div className="flex gap-4 pt-0.5">
                <div className="text-xs flex space-x-2 text-gray-600">
                    {datetime_string}
                </div>
                {location && <div className="text-xs flex text-gray-600">
                    {location}
                </div>}
            </div>
            {hangoutLink && <div className="text-xs pt-1 pb-0.5 flex space-x-1.5 text-gray-600">
                <a href={hangoutLink} target="_blank" className="hover:underline">{hangoutLink}</a>
            </div>}
            {attendee_count > 0 && <div>
                {attendee_count === 1 && <div className="text-xs pt-1 pb-0.5 flex space-x-1.5 text-gray-600">
                    1 person is going
                </div>}
                {attendee_count > 1 && <div className="text-xs pt-1 pb-0.5 flex space-x-1.5 text-gray-600">
                    {attendee_count} people are going
                </div>}
            </div>}
        </div>
        <div>
        </div>
    </div>
}

function Wrapper({children}) {
    return <div className="-mx-1.5 space-y-0.5">
        {children}
    </div>
}

export default function WidgetEventsSidebar({items = [], group_id, demo}) {
const community = useCommunity();
const navigate = useNavigate();
    const demo_data = group_demo_data;

    const final_items = demo ? demo_data.events : items;

    function handleGoToItem(eid) {
        community.openPreviewOverlay({
            type: 'event',
            id: eid
        })
    }

    return <div>
        <SectionHeader title="Upcoming" actions={<>
            <TextAction onClick={()=>{
                navigate(`/c/${community.domain}/calendar`)
            }} text="Calendar"/>

            <TextAction onClick={()=>{
                community.openEditOverlay({
                    type: 'add-event',
                    size: 'plus',
                    data: {
                        group_id
                    }
                })
            }} text="New Event"/>
        </>}/>
        {!final_items.length && <EmptyState title={"No Events found"} icon={<CalendarDaysIcon/>}/>}
        <Wrapper>
            {final_items.map(it => <EventItem goToItem={()=>handleGoToItem(it.id)} key={it.id} {...it} />)}
        </Wrapper>
    </div>
};