import React, {useState, useContext} from 'react';
import {useUnaverse} from "../../../../config/unaverse";
import {useCommunity} from "../../../../config/community";
import {useIsMobile} from "../../../../m3/hooks/is-mobile";
import {authFetch} from "../../../../../api/network";
import {useToasts} from "../../../../config/toasts";
import {ExperienceHistory} from "../experience-history";
import {TextAction} from "../../../auth/sign-in";
import {ProfileSection} from "../../../../m3/_legacy_components/profile-field-renderer";

export function validateCareer(obj) {
    let to_return = Object.assign({}, obj);

    if (obj.org_data.title === '') {
        to_return.org_data.title = '-';
    }

    return to_return;
}

export function MemberCareer({can_add, career, my_profile = false, visibility = 'all-members', cb, user_uid}) {
    const unaverse = useUnaverse();
    const toasts = useToasts();
    const community = useCommunity();
    const [modal, setModal] = useState(null);
    const [loading, setLoading] = useState(null);
    const is_mobile = useIsMobile();

    function addCareer(ed, id) {
        setLoading('add');
        const payload = {
            position: validateCareer(ed),
            community_uid: community.uid,
            user_uid: user_uid,
            position_id: id
        };

        const res = async (r) => {
            cb();
            setTimeout(() => {
                toasts.addToast({text: 'Position Added', intent: 'success'});
                setLoading(null);
                setModal(null);
            }, 1500);
        };

        authFetch('/members/career-add', res, res, "POST", {payload});
    }

    function editCareer(ed, id) {
        setLoading('edit');
        const payload = {
            position: validateCareer(ed),
            community_uid: community.uid,
            user_uid: user_uid,
            position_id: id
        };

        const res = async (r) => {
            cb();
            setTimeout(() => {
                toasts.addToast({text: 'Position Updated', intent: 'success'});
                setLoading(null);
                setModal(null);
            }, 1500);
        };

        authFetch('/members/career-edit', res, res, "POST", {payload});
    }

    function deleteCareer(id) {
        setLoading('add');
        const payload = {
            community_uid: community.uid,
            user_uid: user_uid,
            position_id: id
        };

        const res = async () => {
            cb();
            setTimeout(() => {
                toasts.addToast({text: 'Position Deleted', intent: 'success'});
                setLoading(null);
                setModal(null);
            }, 1500);
        };

        authFetch('/members/career-delete', res, res, "POST", {payload});
    }


    let actions = can_add ? [<TextAction text="Add" onClick={() => {
        community.openEditOverlay({
            type: 'add-career',
            size: 'medium',
            closeCb: () => {
                console.log('closeCb')
            }
        })
    }}/>] : [];

    // block={true} add={actions} layout={is_mobile?"":"desktop"} section={{autocomplete:[]}} visibility={visibility} title="Career"
    return <>
        <ProfileSection title="Experience" visibility={visibility} actions={actions}>
            <ExperienceHistory type='career' can_add={can_add} loading={loading} editEducation={editCareer}
                               deleteEducation={deleteCareer} modal={modal} updateModal={(a) => {
                setModal(a)
            }} data={career}/>
        </ProfileSection>
    </>
}