import React from "react"
import './styles.css';
import {ChevronDownIcon, ChevronUpDownIcon} from "@heroicons/react/20/solid";

function getSizeClasses(size) {

    if(size==='large') {
        return "rounded-xl px-3 leading-10 h-10 text-base"
    } else if(size==='small') {
        return "rounded-lg flex items-center h-7 px-1.5 text-sm ";
    }

    return "rounded-lg leading-7 pt-px h-8 px-2.5 text-sm";
}

function getStyleClasses(intent,outline,disabled,hover_intent="") {
    let colors;
    let font_weight;

    if(intent==='minimal') {
        if(outline) {
            colors = "border-gray-400 text-gray-700";
            font_weight = "font-medium";
        } else {
            colors = "border-white bg-white hover:border-gray-100 hover:bg-gray-100 text-gray-500";
            font_weight = "font-medium";
        }
    } else if(intent==='minimal-active') {
        if(outline) {
            colors = "border-gray-400 text-gray-700";
            font_weight = "font-medium";
        } else {
            colors = "border-white bg-white hover:border-gray-100 hover:bg-gray-100 text-blue-600";
            font_weight = "font-semibold";
        }
    } else if(intent==='primary') {
        if(outline) {
            colors = "border-blue-500 text-blue-700";
            font_weight = "font-medium";
        } else {
            colors = `border-blue-500 bg-blue-500 text-white ${disabled?"":"hover:bg-blue-600"}`;
            font_weight = "font-semibold";
        }
    } else if(intent==='danger') {
        if(outline) {
            colors = "border-red-500 text-red-700";
            font_weight = "font-medium";
        } else {
            colors = "border-red-500 bg-red-500 text-white";
            font_weight = "font-semibold";
        }
    } else if(intent==='success') {
        if(outline) {
            colors = "border-green-600 text-green-700";
            font_weight = "font-medium";
        } else {
            colors = `border-green-600 bg-green-600 text-white ${disabled?"":"hover:bg-green-700"}`;
            font_weight = "font-semibold";
        }
    } else if(intent==='dark') {
        if(outline) {
            colors = "border-gray-800";
            font_weight = "font-medium";
        } else {
            colors = "bg-gray-800 border-gray-800 text-white";
            font_weight = "font-semibold";
        }
    } else {
        if(outline) {
            colors = "";
            font_weight = "font-medium";
        } else {
            colors = `text-gray-700 bg-white border-gray-300 ${disabled?"":"hover:bg-gray-100"}`;
            font_weight = "font-medium tracking-tight";
        }
    }

    if(disabled) {
        if(outline) {

        } else {
            colors = "bg-gray-200 text-gray-600";
        }
    }

    return `${colors} ${font_weight} ${hover_intent}`;
}

export default function Button({children,right_icon,selector,dropdown,hover_text,hover_intent,style,left_icon,loading,disabled,text,onClick,size,fill,intent,outline}) {

    const sc = getStyleClasses(intent,outline,disabled,hover_intent);

    let s = {};

    if(style) {
        s = {
            ...style,
            ...s
        };
    }
    const width = fill ? "w-full" : "";

    const size_classes = getSizeClasses(size);

    const disabled_classes = disabled?`cursor-not-allowed`:"";

    function handleClick(e) {
        if(disabled) {
            return;
        }
        if(onClick) {
            onClick(e);
        }
    }

    let c;

    if(loading) {
        c = <div className="flex items-center justify-center h-full">
            <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                 fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                        strokeWidth="4" />
                <path className="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
        </div>
    } else {
        c = <div className="flex justify-center items-center space-x-2">
            {left_icon&&<div className={`h-4 w-4 ${hover_text?"group-hover:opacity-0":""} -ml-0.5 -mr-0.5`}>{left_icon.icon}</div>}
            {text&&<span className={`${size === 'small' ? `` : ``} ${hover_text?"group-hover:opacity-0":""}`}>{text}</span>}
            {hover_text&&<span style={{marginLeft:0}} className={`${size === 'small' ? `font-semibold` : `font-semibold`} absolute left-0 right-0 text-center group-hover:block hidden`}>{hover_text}</span>}
            {children}
            {dropdown&&<div style={{marginLeft:'4px',marginRight:'-4px'}} className="w-3.5 h-3.5"><ChevronDownIcon /></div>}
            {selector&&<div style={{marginLeft:'4px',marginRight:'-4px'}} className="w-3.5 h-3.5"><ChevronUpDownIcon /></div>}
            {right_icon&&right_icon.icon&&<div className={`h-4 w-4 ${hover_text?"group-hover:opacity-0":""} -mr-0.5 -ml-0.5`}>{right_icon.icon}</div>}
        </div>
    }

    return <div style={s} onClick={handleClick} className={`${sc} ${width} ${size_classes} ${disabled_classes} group transition-opacity select-none transition-colors inline-block text-center relative cursor-pointer border`}>
        {c}
    </div>
};