import React, {useState} from "react";
import {collection, getDocs, limit, orderBy, query, startAfter, where} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import debounce from "lodash/debounce";
import Table from "../../../m3/_legacy_components/table";
import {useNavigate} from "react-router-dom";

export function buildTableColumns(cols, spec) {
    let a = [];

    cols.forEach(col => {
        const meta = spec[col];
        if (meta) {
            a.push({
                Header: meta.label,
                type: meta.type,
                meta: meta.meta,
                accessor: col,
                id: col,
            });
        }
    })

    return a;
}

function analyzeQuery(q) {
    let field = "", sanitized = q.toLowerCase().trim();

    if (q.startsWith('@')) {
        field = 'handle';
        sanitized = sanitized.replace('@', '');
    } else if (q.includes('@')) {
        field = 'account_email';
    } else if (q.length === 28) {
        field = 'uid';
    } else {
        field = 'handle';
    }

    return {
        sanitized,
        field
    }
}

async function loadUsers(page, pp = 10, start_after, qq) {
    const usersCol = collection(db, 'users');

    const qa = qq ? analyzeQuery(qq) : {};
    let q;
    if (qq && qa.sanitized) {
        if (start_after && page > 0) {
            q = query(usersCol, where(qa.field, '==', qa.sanitized), startAfter(start_after), limit(pp));
        } else {
            q = query(usersCol, where(qa.field, '==', qa.sanitized), limit(pp));
            // q = query(usersCol, orderBy("about.last_name"), limit(pp));
        }
    } else {
        if (start_after && page > 0) {
            q = query(usersCol, orderBy("about.last_name"), startAfter(start_after), limit(pp));
        } else {
            q = query(usersCol, orderBy("about.last_name"), limit(pp));
        }
    }

    const snap = await getDocs(q);
    return snap.docs;
}

export function buildTableConfig(info_item, query, onEditMember, navigate, _loadUsersFn) {
    return {
        ready: true,
        onRowClick: (id) => navigate(`/manage/user/${id}`),
        loadData: async (page, pp, start_after, query) => {
            let docs;
            if (!!_loadUsersFn) {
                console.log("custom get docs")
                docs = await _loadUsersFn(page, pp, start_after, query);
                console.log("docs", docs.length)
            } else {
                docs = await loadUsers(page, pp, start_after, query);
            }
            const last_visible = docs[docs.length - 1];
            const data = docs.map(doc => {
                return {
                    ...doc.data(),
                    id: doc.id
                }
            });

            let has_more = docs.length >= pp;

            return {
                data: data,
                last_ref: last_visible,
                has_more: has_more
            }
        },
        actions: [
            {
                type: "button",
                onClick: (pl) => {
                    onEditMember(pl);
                },
                props: {
                    text: "Edit"
                }
            }
        ],
        can_paginate: true,
        transformData: (d) => {
            let a = [];

            d.forEach((entry, index) => {
                let o = {
                    ...entry
                };
                info_item.columns.forEach(col => {
                    o[col] = `${entry[col] || ""}`;
                })
                a.push(o);
            })
            return a;
        },
        columns: buildTableColumns(info_item.columns, info_item.spec),
        per_page: 10,
        query,
        total_count: 2600
    };
}

function SearchBar({
                       autoFocus,
                       placeholder = `Search by UID, email, or @handle`,
                       onToggleLoading,
                       onClear,
                       debouncedSearch
                   }) {
    const [query, setQuery] = useState("");

    const onSearch = (v) => {
        const search = debouncedSearch;
        setQuery(v);
        if (!v) {
            // when the user clear the field we don't want to perform a search, we need to clear the state and do nothing else
            debouncedSearch.cancel();
            onClear([]);
            onToggleLoading(false);
        } else {
            onToggleLoading(true);
            search(v);
        }
    };

    return <input placeholder={placeholder} autoFocus={false}
                  className="input-focus border rounded-md leading-8 text-sm text-base px-3 w-96" type={"text"}
                  value={query} onChange={e => onSearch(e.target.value)}/>
}

export function SimpleQueryBar({
                                   placeholder,
                                   autoFocus, onStartSearch = () => {
    }, onClear = () => {
    }, onToggleLoading = () => {
    }, handleChange, type = 'text', value
                               }) {
    const DEBOUNCE = 500;

    const searchFun = (
        queryParam
    ) => {
        onStartSearch(queryParam);
    };

    const debouncedSearch = debounce(searchFun, DEBOUNCE);

    return <div>
        <SearchBar placeholder={placeholder} debouncedSearch={debouncedSearch} onClear={onClear}
                   onToggleLoading={onToggleLoading}/>
    </div>
}

export function SharedUsersTable({query = '', table_config = {}}) {
    const navigate = useNavigate();

    return <div>
        <Table {...table_config} />
    </div>
}