
function getMinHeight(m, s, f) {
    if (s) {
        return '34px';
    } else if (f) {
        return '32px';
    } else {
        return m;
    }
}

function getBoxShadow(focus, s, f) {
    if (focus) {
        return 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(58, 151, 212, 0.36) 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px';
    } else if (s) {
        return '';
    } else if (f) {
        return '0 1px 2px 0 rgba(0, 0, 0, 0.05)';
    } else {
        return '0 1px 2px 0 rgba(0, 0, 0, 0.05)';
    }
}

function getFontSize(s, f) {
    if (s) {
        return '14px';
    } else if (f) {
        return '14px';
    } else {
        return '15px';
    }
}

export const advanced_select_styles = {
    singleValue: (base, state) => ({
        ...base,
        fontSize: getFontSize(false, false),
        fontWeight: '500',
        paddingLeft: state.pad_left ? '32px' : ''
    }),
    multiValue: (base) => ({
        ...base,
        borderRadius: '8px',
        backgroundColor: "#e6e6e6",
        gap: "0.25rem",
        whiteSpace: "nowrap",
        alignItems: 'center'
    }),
    multiValueLabel: (base, state) => ({
        ...base,
        paddingLeft: "0.125rem",
        paddingRight: "0.125rem",
        fontWeight: 500,
        color: '#2b2b2b'
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 99999
    }),
    menu: (base) => ({
        ...base,
        zIndex: 99999
    }),
    indicatorSeparator: (base) => ({
       ...base,
        display: 'none'
    }),
    multiValueRemove: (base) => ({
        ...base,
        marginRight: '2px',
        background: 'transparent !important',
        color: '#5f5f5f',
        cursor: 'pointer'
    }),
    valueContainer: (base) => ({
        ...base,
        display: 'flex',
        flexWrap: 'wrap',
        padding: '2px'
    }),
    container: base => ({
        ...base
    }),
    clearIndicator: base => ({
        ...base,
        cursor: 'pointer'
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: '2px'
    }),
    placeholder: (base) => ({
        ...base,
        position: 'absolute'
    }),
    option: (base, state) => ({
        ...base,
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.875rem',
        opacity: state.isDisabled ? 0.7 : "",
        cursor: state.isDisabled ? "not-allowed" : "",
        color: state.isSelected ? "#ffffff" : "#393939",
        fontWeight: 500,
        backgroundColor: state.isSelected ? "#1164A3" : state.isFocused ? "#ececec" : "#ffffff",
        "&:hover": {
            background: state.isSelected ? "#1164A3" : "#ececec"
        },
    }),
    control: (base, state) => ({
        ...base,
        paddingLeft: '0.5rem',
        borderRadius: "0.375rem",
        borderColor: state.isFocused ? 'transparent !important' : 'rgba(212, 212, 216, 1) !important',
        boxShadow: getBoxShadow(state.isFocused, false, false),
        border: '1px solid rgba(212, 212, 216, 1)',
        minHeight: getMinHeight('42px', false, false)
    })
};