import {authFetch} from "../network";

export async function api_addPastAssignee(community, pl) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        const payload = {
            member_id: community.member_id,
            community_uid: community.uid,
            ...pl
        };
        authFetch("/roles/past-assignees/add", res, res, "POST", {payload});
    })
}

export async function api_editPastAssignee(community, pl) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        const payload = {
            member_id: community.member_id,
            community_uid: community.uid,
            ...pl
        };
        authFetch("/roles/past-assignees/edit", res, res, "POST", {payload});
    })
}

export async function api_deletePastAssignee(community, pl) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        const payload = {
            member_id: community.member_id,
            community_uid: community.uid,
            ...pl
        };
        authFetch("/roles/past-assignees/delete", res, res, "POST", {payload});
    })
}