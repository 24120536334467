import React, {useEffect, useState} from "react";
import {useToasts} from "../../config/toasts";
import {getPolicy} from "../../routes/community/policy/viewer";

import M3_E_PolicyPrompt from "../../m3/examples/policy-prompt";
import {authFetch} from "../../../api/network";
import {utils_dates_format} from "../../../common/utilities/dates";
import {buildImageUrl} from "../../../common/utilities/images";

export function PolicyGateWrapper({policies_to_accept = [], preview, handleAcceptPolicy, policy_data = null, selected_policy = "", member_id, community_uid, community_data, updateParent}) {
    const [loading, setLoading] = useState("");
    const [_selected_policy, setSelectedPolicy] = useState(selected_policy);
    const [_policy_data, setPolicyData] = useState(policy_data);
    const toasts = useToasts();

    useEffect(function () {
        if (policies_to_accept.length > 0) {
            setSelectedPolicy(policies_to_accept[0].slug);
        }
    }, [policies_to_accept])

    useEffect(function () {
        if (_selected_policy && !_policy_data) {
            getPolicy(community_uid, _selected_policy).then((d) => {
                setPolicyData(d);
            });
        }
    }, [_selected_policy, _policy_data])

    function acceptPolicy(slug, version) {
        setLoading('accepting');

        const res = (result) => {
            setLoading('accepted');
            toasts.addToast({
                text: `${_policy_data?.name} accepted, thank you!`,
                intent: "success"
            });
            updateParent();
        };

        if(handleAcceptPolicy) {
            return handleAcceptPolicy(slug, version)
                .then(res);
        }

        const payload = {
            community_uid: community_uid,
            member_id: member_id,
            slug, version
        };

        authFetch("/members/accept-policy", res, res, "POST", {payload});
    }

    const policy_to_accept = policies_to_accept[0] ? policies_to_accept[0] : null;

    if (!policy_to_accept || !policy_to_accept.slug) {
        console.log("NO POLICY TO ACCEPT")
        return null;
    }

    const policy_prompt_config = {
        is_initializing: !_policy_data,
        is_loading: loading === "accepting",

        header: {
            title: `Review ${_policy_data?.name}`,
            subtitle: `Updated ${utils_dates_format(_policy_data?.updated_at)?.full_date}`,
            context_bar: {
                type: "breadcrumbs",
                data: {
                    crumbs: [
                        {
                            type: "inline-entity",
                            data: {
                                size: "sm",
                                avatar: buildImageUrl(community_data.profile_picture, "_small"),
                                children: community_data.name
                            }
                        },
                        {
                            type: "text",
                            data: {
                                size: "sm",
                                children: "Policies"
                            }
                        }
                    ]
                }
            },
        },

        footer: {
            caption: "By clicking \"Accept\", you confirm you agree to the above policy.",
            actions: {
                confirm: {
                    label: "Accept",
                    intent: "primary",
                    onClick: () => {
                        if(preview) {
                            alert("Preview mode, not accepting policy");
                            return;
                        }
                        acceptPolicy(policy_to_accept.slug, policy_to_accept.version);
                    }
                }
            },
        },

        content: {
            key_updates: _policy_data?.key_updates,
            policy_markdown: _policy_data?.content
        },

        dialog_count: policies_to_accept.length
    };

    return <M3_E_PolicyPrompt config={policy_prompt_config}/>;
}