import React from "react";
import styled from "styled-components";
import M3_A_Token from "./token";
import M3_A_Text from "./text";
import {m3_adjust_size} from "../utilities";
import M3_A_CounterLabel from "./counter-label";

/*
* This is the ListItem atom
 */

const _props = {};

export function m3_getStyle(styles, key, fallback) {
    if (styles && styles[key]) {
        return styles[key];
    } else {
        return fallback;
    }
}

const TokenWrapper = styled.div`
    color: ${props => props.active ? m3_getStyle(props.styles.active, "token_color", "#ffffff") : m3_getStyle(props.styles.resting, "token_color", "#3b3b3b")};
`;

const ItemWrapper = styled.div`
    color: ${props => props.active ? m3_getStyle(props.styles.active, "text_color", "#ffffff") : m3_getStyle(props.styles.resting, "text_color", "#3b3b3b")};
    background-color: ${props => props.active ? m3_getStyle(props.styles.active, "background_color", "transparent") : m3_getStyle(props.styles.resting, "background_color", "transparent")};
    font-weight: ${props => props.active ? m3_getStyle(props.styles.active, "font_weight", "600") : props.emphasized ? m3_getStyle(props.styles.emphasis, "font_weight", "500") : m3_getStyle(props.styles.resting, "font_weight", "400")};

    &:hover {
        background-color: ${props => props.active ? "" : m3_getStyle(props.styles.hover, "background_color", "transparent")};
    }

    &:active {
        background-color: ${props => props.active ? "" : m3_getStyle(props.styles.focus, "background_color", "transparent")};
    }
`;

function getTokenColor(intent, styles) {
    if (styles && styles?.resting?.token_color) {
        return styles.resting.token_color;
    }
    return "#808080";
}

function getLabelColor(intent, styles) {
    if (styles && styles?.resting?.text_color) {
        return styles.resting.text_color;
    }
    switch (intent) {
        case "success":
            return "text-green-600";
        case "warning":
            return "text-yellow-600";
        case "danger":
            return "text-red-600";
        case "info":
            return "text-blue-600";
        case "primary":
            return "text-blue-600";
        case "secondary":
            return "text-gray-600";
        case "tertiary":
            return "text-gray-500";
        default:
            return "text-gray-800";
    }
}

const sizing_classes_map = {
    "xs": "py-0.5 h-6",
    "sm": "py-1 h-8",
    "base": "py-1.5 h-10",
    "lg": "py-2 h-12",
};

const variant_classes_map = {
    'default': 'rounded-lg',
    'fluid': ''
};

const token_size_map = {
    "xs": "w-3",
    "sm": "w-4",
    "base": "w-5",
    "lg": "w-6",
    "xl": "w-7"
};

const leading_text_map = {
    "xs": "leading-4",
    "sm": "leading-5",
    "base": "leading-8",
    "lg": "leading-9",
    "xl": "leading-10"
};

const gap_map = {
    "xs": "gap-1",
    "sm": "gap-2",
    "base": "gap-2",
    "lg": "gap-2.5",
};

/*
sample token
{
    type: "avatar",
    data: {
        avatar: ""
    }
    }
 */
export default function M3_A_ListItem({
                                          label,
                                          leading_action,
                                          opens_submenu,
                                          opens_dialog,
                                          intent,
                                          onClick,
                                          leading_indicator,
                                          trailing_text,
                                          trailing_indicator,
                                          trailing_actions,
                                          sub_label,
                                          styles,
                                          selected,
                                          disabled,
                                          emphasized,
                                          active,
                                          size = "base",
                                          list,
                                          variant = 'default'
                                      }) {

    let wrapper_classes = `flex px-2 cursor-pointer items-center ${gap_map[size]}`;

    if (!label && leading_indicator) {
        wrapper_classes += " justify-center";
    }

    wrapper_classes += " " + variant_classes_map[variant];

    wrapper_classes += " " + sizing_classes_map[size];

    // m3_adjust_size(size, -2)
    const token_size = m3_adjust_size(size, leading_indicator?.adjust_size !== undefined ? leading_indicator?.adjust_size : -1);
    const trailing_token_size = m3_adjust_size(size, trailing_indicator?.adjust_size !== undefined ? trailing_indicator?.adjust_size : 0);
    const token_wrapper_size = m3_adjust_size(token_size, 1);
    const text_size = m3_adjust_size(size, 0);
    const text_size_smaller = m3_adjust_size(size, -1);

    wrapper_classes += " ";

    if (active) {
        wrapper_classes += " active";
    }

    if (emphasized) {
        wrapper_classes += " emphasis";
    }

    const leading_visual_type = leading_indicator?.type || "none";
    const trailing_visual_type = trailing_indicator?.type || "none";

    const token_color = getTokenColor(intent, styles);

    let leading_action_props = {
        className: "flex-none w-4 h-5 flex items-center justify-center"
    };

    return <ItemWrapper active={active} emphasized={emphasized} disabled={disabled}
                        selected={selected} styles={styles}
                        onClick={() => {
                            if (onClick) {
                                onClick();
                            }
                        }} className={wrapper_classes}>
        {leading_action && <div {...leading_action_props}>
            {leading_action}
        </div>}
        {leading_visual_type !== "none" &&
            <TokenWrapper active={active} emphasized={emphasized} styles={styles}
                          className={`flex-none ${token_size_map[token_wrapper_size]} flex justify-center items-center`}>
                <M3_A_Token type={leading_visual_type} color={token_color} data={{
                    size: token_size,
                    ...leading_indicator
                }}/>
            </TokenWrapper>}
        {label && <div className="flex-grow">
            {label && <div className={`${leading_text_map[text_size]} line-clamp-1`}>
                <M3_A_Text size={text_size}>
                    {label}
                    {opens_dialog && <span>...</span>}
                </M3_A_Text>
            </div>}
            {sub_label && <div className="line-clamp-1">
                <M3_A_Text size={text_size_smaller}>
                    {sub_label}
                </M3_A_Text>
            </div>}
        </div>}
        {(trailing_text || trailing_visual_type !== "none" || trailing_actions) && <div className="flex-none flex items-center gap-1">
            {trailing_text && <div className="flex-none flex items-center">
                <M3_A_Text color="opacity-60" size={text_size_smaller}>
                    {trailing_text}
                </M3_A_Text>
            </div>}
            {trailing_visual_type !== "none" && <div className="flex-none flex items-center">
                {trailing_indicator.type === "counter-label" &&
                    <M3_A_CounterLabel size={trailing_token_size} {...trailing_indicator}/>}
                {trailing_indicator.type === "icon" && <M3_A_Token type={"icon"} color={trailing_indicator?.color} data={{
                    size: trailing_token_size,
                    ...trailing_indicator
                }}/>}
            </div>}
            {trailing_actions && <div className="flex-none flex gap-1">
                {trailing_actions.map((action, index) => {
                    return <div key={index}>
                        {action}
                    </div>
                })}
            </div>}
        </div>}
    </ItemWrapper>
}