import React from "react";
import CsvExportModal from "../../../m3/_legacy_components/csv-export-modal";
import ImportMembersFromCSV from "../../../m3/_legacy_components/import-members";
import {ContactsEditAttributeModal, ContactsEditViewModal} from "./components";
import {contacts_actions} from "./utilities";
import ContactsModalAddToSpace from "./modals/add-to-space";
import ContactsModalBulkEdit from "./modals/bulk-edit";

export function ContactsModals({
                                   modal, setModal, modal_data, setModalData, config
                               }) {

    const ctx = {
        community_uid: config.context.community_uid,
        member_id: config.context.member_id,
    };

    function handleClose() {
        setModal("");
        setModalData(null);
    }

    return <>
        {modal === "export" && <CsvExportModal handleClose={handleClose} export_context={"group-"} query_payload={{
            filters: [],
            sort: [],
            group_id: ""
        }}/>}
        {modal === "import" && <ImportMembersFromCSV handleClose={handleClose}/>}
        {modal === "add-to-space" && <ContactsModalAddToSpace selected={config.state.selected} ctx={ctx} handleClose={handleClose} handleSuccess={handleClose}/>}
        {modal === "bulk-edit" && <ContactsModalBulkEdit selected={config.state.selected} ctx={ctx} handleClose={handleClose} handleSuccess={handleClose}/>}
        {modal === "edit-attribute" &&
            <ContactsEditAttributeModal ctx={ctx} data={modal_data?.data} onClose={handleClose}
                                        id={modal_data?.id} list_id={config.data.list_id}
                                        view_id={config?.state?.active_view_id}
                                        view_data={config?.state?.active_view_data}
                                        onSave={(_view_id, _view_data, _attribute_id, _new_config) => {
                                            console.log("DONE", _view_id, _view_data, _attribute_id, _new_config)
                                            contacts_actions.handleEditedAttribute(config, _view_id, _view_data, _attribute_id, _new_config);
                                            handleClose();
                                        }}/>}
        {modal === "view" && <ContactsEditViewModal mode={modal_data?.mode || "create"} id={modal_data?.id}
                                                    can_delete_view={Object.keys(config.data.list.views).length > 0}
                                                    onDelete={(_view_id, _default_view_id) => {
                                                        if (!_view_id || !_default_view_id) {
                                                            console.warn("missing view id")
                                                            return;
                                                        }
                                                        contacts_actions.handleDeletedView(config, _view_id, _default_view_id)
                                                      handleClose();
                                                    }}
                                                    data={modal_data?.data}
                                                    onSuccess={(_view_id, _view_data) => {
                                                        contacts_actions.handleNewView(config, _view_id, _view_data, modal_data?.flow);
                                                        handleClose();
                                                    }} ctx={ctx} list_id={config.data.list_id}
                                                    context_id={config.context_id} onClose={handleClose}/>}
    </>
}