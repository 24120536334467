import React, {useEffect, useRef} from 'react';
import {RichEmailActivityPreview} from "../../../m3/_legacy_components/admin-activity/item";
import {ModalBody, ModalHeader} from "../../../m3/_legacy_components/preview-overlay";
import {MiniHeader} from "./edit-member";
import MetaHeader from "../../../m3/_legacy_components/meta-header";
import {EnvelopeIcon} from "@heroicons/react/24/outline";
import {InlineLoader} from "../../../m3/_legacy_components/admin-activity";
import {useCommunity} from "../../../config/community";
import {collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {convertFromRaw} from "draft-js";
import {parseRawContent} from "../../../features/forum/forum/api";
import {ViewDraftJsContent} from "../../../features/forum/utilities/view-content";


const email_data_mock = {
    to: 'Sean McCall <seanmccall14@gmail.com>,',
    from: 'James Brand <james.brand@getunaty.com>',
    subject: 'Welcome to Unaty',
    sent_at: Date.now(),
    // max of 360 characters
    preview_text: "Hi Steve, this is a sample email. I'm testing out the email editor. It's pretty cool. This is only plain text meant to be a preview of the email.",
};

export function EmailPreviewModal({data, onClose}) {
    const community = useCommunity();
    console.log("EmailPreviewModal", data)
    const [dt, setDt] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    useEffect(function () {
        if (data && data.reference_id) {
            getData()
                .then(_dt => {
                    setDt(_dt);
                    setLoading(false);
                })
        }
    }, [data])

    async function getData() {
        const ref = doc(db, 'community_members', community.uid, 'activity_emails', data.reference_id);

        return await getDoc(ref)
            .then(doc => {
                return doc.exists() ? {...doc.data(), id: doc.id} : null;
            });
    }

    if (loading) {
        return <div style={{minHeight: '16rem'}} className="flex items-center justify-center">
            <InlineLoader/>
        </div>
    } else if (!dt || !dt.raw) {
        console.log("DT", dt)
        return <div>
            error, no data found..
        </div>
    }

    const mh = {
        top: {
            type: "entity-icon",
            name: "Email",
            icon: <EnvelopeIcon/>
        },
        divider: false,
        title: dt?.subject
    }

    const fns = {};

    const content_state = convertFromRaw(parseRawContent(dt.raw));

    return <div className="" style={{minHeight: '16rem'}}>
        <div className="px-6 sticky top-0 rounded-t-lg bg-white z-10 pt-2 pb-2 border-b border-gray-200">
            <MetaHeader {...mh} onClose={onClose}/>
        </div>
        <ModalBody>
            <div className="pb-4 flex flex-col gap-2 text-sm">
                <div className="flex gap-1.5">
                    <div className="text-gray-500">From</div>
                    <div className="text-gray-800">{dt.from}</div>
                </div>
                <div className="flex gap-1.5">
                    <div className="text-gray-500">To</div>
                    <div className="text-gray-800">{dt.recipients.to}</div>
                </div>
            </div>
            {content_state &&
                <ViewDraftJsContent fns={fns} style={{}} classes='prose prose-base text-gray-800'
                                    content_state={content_state}/>}
        </ModalBody>
    </div>
}

async function getMemberEmailData(community_uid,scope,scope_id) {
    const ref = collection(db, 'community_members', community_uid, 'activity_emails');
    const q = query(ref, where("context", "==", scope), where("context_id", "==", scope_id), orderBy('created_at', 'desc'), limit(10));
    return await getDocs(q);
}

export function AdminEmails({scope_id, scope}) {
    const community = useCommunity();
    const [items, setItems] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const isFirstRun = useRef(true);

    useEffect(function () {
        console.log("AdminEmails", scope_id, scope)
        if (scope_id && scope) {
            console.log("start",community.uid,scope,scope_id)
            getMemberEmailData(community.uid,scope,scope_id)
                .then(snap => {
                    console.log("RESULTS",snap.size)
                    if(snap.size===0) {
                        setItems(null);
                        setLoading(false);
                    } else {
                        setItems(snap.docs.map(doc => {
                            return {...doc.data(), id: doc.id}
                        }));
                        setLoading(false);
                    }
                })
        }
    }, [scope_id, scope, community.uid])

    if (items === null) {
        return <div className="text-sm p-8 text-center">
            <div>
                No emails sent yet.
            </div>
        </div>
    } else if (items.length === 0) {
        return <div className="text-sm p-8 text-center">
            <InlineLoader/>
        </div>
    }

    return <div className="gap-3 grid">
        {items.map((item, index) => {
            return <RichEmailActivityPreview key={index} data={{
                ok: true,
                reference_id: item.id,
                subject: item.subject,
                sent_at: item.created_at,
                preview_text: item.plain_text.slice(0, 280),
                from: item?.from||"Unknown",
                from_name: item?.from.split(' <')[0]||"Unknown",
                to: item?.recipients?.to||"Unknown",
                to_name: item?.recipients?.to.split(' <')[0]||"Unknown",
            }} />
            //  return <RichEmailActivityPreview key={index} data={item}/>
        })}
    </div>
}