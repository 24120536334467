import React from 'react';
import M3_C_Header from "../components/header";
import M3_A_Text from "../atoms/text";

/*
* This is a mobile contacts list component
 */

const names = [
    "Aaron Jacobs",
    "Beatrice Anderson",
    "Catherine Brown",
    "David Clark",
    "Edward Davis",
    "Frank Evans",
    "George Garcia",
    "Henry Hernandez",
    "Isabella Hill",
    "Jacob Johnson",
    "Kevin Jones",
    "Liam Lewis",
    "Lars Miller",
    "Lily Moore",
    "London Perez",
    "Mason Robinson",
    "Michael Rodriguez",
    "Nathan Sanchez",
    "Olivia Smith",
    "Oliver Taylor",
    "Parker Thomas",
    "Quinn Thompson",
    "Mia Martin",
    "Noah Martinez",
    "Olivia Miller",
    "Patrick Moore",
    "Quinn Nelson",
    "Ryan Perez",
    "Sophia Robinson",
    "Thomas Rodriguez",
    "Ulysses Sanchez",
    "Victor Smith",
    "William Taylor",
    "Xavier Thomas",
    "Yolanda Thompson",
    "Zachary Walker",
    "Zoe White"
];

function prepareRows(names) {
    const rows = [];

    let current_letter = null;
    let current_row = null;

    names.forEach((name, i) => {
        const letter = name[0];

        if (letter !== current_letter) {
            current_letter = letter;

            current_row = {
                type: "divider",
                letter,
                entries: []
            };

            rows.push(current_row);
        }

        current_row.entries.push({
            type: "entry",
            name
        });
    });

    return rows;
}

function Divider({letter}) {

    return <div className={`px-4 pt-0.5 sticky top-dynamic-header bg-white`}>
        <div className="border-b border-gray-300 text-gray-500">
            <M3_A_Text size="xs" weight="font-semibold">{letter}</M3_A_Text>
        </div>
    </div>
}

function renderName(name) {
    const split = name.split(" ");

    const first_name = split[0];
    const last_name = split[1];

    if (!last_name) {
        return <strong>{first_name}</strong>
    } else {
        return <span>{first_name} <strong>{last_name}</strong></span>
    }
}

function Entry({name}) {

    return <div className="px-4">
        <div className="border-b py-1.5 border-gray-300 text-gray-800">
            <M3_A_Text size="sm">
                {renderName(name)}
            </M3_A_Text>
        </div>
    </div>
}

function Sample() {

    const rows = prepareRows(names);

    return <div className="grid gap-3">
        {rows.map(row => {
            return <div>
                <Divider letter={row.letter}/>
                <div>
                    {row.entries.map(entry => {
                        return <Entry name={entry.name}/>
                    })}
                </div>
            </div>;
        })}
    </div>
}

export function M3_E_MobileContactsList() {

    const config = {
        size: "xl",

        watch_scroll_id: "scroll-container",
        title: "New York Hub",
        subtitle: "Recently Joined, 202 members",
        divider: true,

        context_bar: {
            variant: "nav",

            onBack: () => {
                console.log("back");
            },

            actions: [
                {
                    type: "icon",
                    icon_name: "add"
                }
            ]
        },

        subnav_bar: {
            variant: "search"
        }
    };

    return <div className="relative max-w-xl h-screen overflow-auto" id="scroll-container">
        <M3_C_Header {...config} />
        <div className="pt-6">
            <Sample/>
        </div>
    </div>
}