export const APP_ENV = process.env.REACT_APP_ENV;

function getServerURL(env) {
  switch (env) {
    case 'prod' : {
      // https://us-central1-unaty-prod.cloudfunctions.net/imageResizerApi
      return 'https://us-central1-unaty-prod.cloudfunctions.net'
    }
    case 'local-prod' : {
      return 'http://localhost:5001/unaty-prod/us-central1'
    }
    case 'staging' : {
      return 'https://us-central1-unaty-staging.cloudfunctions.net'
    }
    default : {
      return 'http://localhost:5001/unaty-staging/us-central1'
    }
  }
}

function getAppURL(env) {
  switch (env) {
    case 'prod' : {
      return 'https://getunaty.com'
    }
    case 'staging' : {
      return 'https://unaty-staging.web.app'
    }
    case 'local-prod' : {
      return 'http://localhost:3000'
    }
    default : {
      return 'http://localhost:3000'
    }
  }
}

export const PROJECT_SLUG = (APP_ENV === 'prod' || APP_ENV === 'local-prod') ? 'unaty-prod' : 'unaty-staging';
export const SERVER_BASE_URL = getServerURL(APP_ENV);

export const IMAGE_RESIZER_URL = `${SERVER_BASE_URL}/resize`;
export const EXTERNAL_SERVER_URL = `${SERVER_BASE_URL}/external`;
export const EMAIL_SERVER_URL = `${SERVER_BASE_URL}/email`;
export const APP_SERVER_URL = `${SERVER_BASE_URL}/server`;
export const STORAGE_BASE_URL = `https://firebasestorage.googleapis.com/v0/b/${PROJECT_SLUG}.appspot.com/o/`;

export const MAX_FILE_SIZE = 20*1024*1024;
export const MAX_IMAGE_SIZE = 5*1024*1024;
export const SUPPORT_EMAIL = 'hey@getunaty.com';
export const CUSTOM_DOMAIN_DEFAULT = 'm.getunaty.com';
export const APP_BASE_URL = getAppURL(APP_ENV);

export const POST_ACCEPTED_ATTACHMENT_TYPES = "*";
export const POST_ACCEPTED_IMAGE_TYPES = "image/jpg,image/png,image/gif,image/jpeg";

export const NANOID_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
export const NANOID_LENGTH = 10;

export const LEADER_COLOR = "rgb(45,160,95)";
export const MODERATOR_COLOR = "rgb(10,79,128)";