import React, {useState} from "react";
import ExternalAuthPage from "../../../m3/_legacy_components/external-auth-page";
import Button from "../../../m3/_legacy_components/button";
import Field from "../../../m3/_legacy_components/form/field";
import {useNavigate} from "react-router-dom";
import {SimpleErrorMessage, TextAction} from "../sign-in";
import {SharedPageTitle} from "../../unaverse/memberships";
import {externalAuthFetch} from "../../../../api/network";
import {setDocumentTitle} from "../../../m3/utilities/set-document-title";
import {utils_strings_isEmail} from "../../../../common/utilities/strings";

async function resetPasswordRequest(email) {

    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        externalAuthFetch("/send-password-reset-email", res, res, "POST", {email});
    })
}

export default function ForgotPasswordPage() {
const navigate = useNavigate();
    const [status, updateStatus] = useState("");
    const [error, setError] = useState("");
    const [unatyid, setUnatyId] = useState("");
    setDocumentTitle(`Forgot Password`);

    function handleFieldEnter() {
        if(unatyid) {
            sendRequest()
        }
    }

    function sendRequest() {
        if(!utils_strings_isEmail(unatyid)) {
            setError('invalid-email');
            return;
        }
        updateStatus("sending");
        resetPasswordRequest(unatyid)
            .then(()=>{
                updateStatus("sent");
            })
    }



    return <ExternalAuthPage show_logo bg_image={'/images/custom/seven.jpg'}>
        <div className="w-full space-y-6 max-w-sm">

            {status==="sent" ? <>
                <div>
                    <div>
                        <SharedPageTitle text="Email sent" />
                    </div>
                    <div>
                        <div className="text-sm text-gray-600">
                            If that email address is linked to an account, a password reset link has been sent to it, please check your inbox and spam folder if you do not see the email directly.
                        </div>
                    </div>
                </div>
            </> : <>
                <div>
                    <SharedPageTitle text="Forgot your Password" />
                </div>
                <div>
                    <div className="text-sm text-gray-600">
                        Enter the email address associated with your account and we'll send you a link to reset your password.
                    </div>
                </div>
                <div>
                    <Field name="email" onEnter={()=>handleFieldEnter()} value={unatyid} onChange={(a, b) => {
                        setUnatyId(b)
                        setError("");
                    }} type="email" label="Email"
                           input_props={{placeholder: ""}}>
                        {(error)&&<SimpleErrorMessage code={error} />}
                    </Field>
                </div>
                <div>
                    <Button loading={status==='sending'} disabled={!unatyid} onClick={sendRequest} size="large" intent="primary" fill>Get Email Reset Link</Button>
                </div>
            </>}
            <div>
            <TextAction onClick={()=>navigate('/sign-in')}>Back to sign in</TextAction>
            </div>
       </div>
    </ExternalAuthPage>
};