import {getItemFromLocalStorage} from "../../../m3/utilities/localstorage";

export function buildGetStartedGroupSetupManifest(group) {
    return {
        id: `group-setup-${group.id}`,
        title: "Space Setup",
        steps: [
            {
                id: "group-setup-1",
                title: "Add Mailing List",
                subtitle: "Add a mailing list to send emails to space members.",
                icon: "email",
                action: {
                    label: "",
                    type: "param",
                    param: "add-app=unaty__mailing_lists"
                },
                completion: (member, community, user, group) => {
                    return group.power_ups && group.power_ups['unaty__mailing_lists'];
                }
            },
            {
                id: "group-setup-2",
                title: "Add Calendar",
                subtitle: "Add a calendar to schedule events and meetings.",
                icon: "calendar",
                action: {
                    label: "",
                    type: "param",
                    param: "add-app=google_calendar"
                },
                completion: (member, community, user, group) => {
                    return group.power_ups && group.power_ups['google_calendar'];
                }
            },
            {
                id: "group-setup-3",
                title: "Add Files to Drive",
                subtitle: "Store files and documents in the drive.",
                icon: "folder",
                action: {
                    label: "",
                    type: "param",
                    param: "action=view-drive"
                },
                completion: (member, community, user, group) => {
                    return getItemFromLocalStorage(`getstarted-groupdrive-${community.id}-${group.id}`, false)
                }
            },
            {
                id: "group-setup-4",
                title: "Add links to pinboard",
                subtitle: "Add links to the space pinboard.",
                icon: "link",
                action: {
                    label: "",
                    type: "param",
                    param: "action=edit-pinboard"
                },
                completion: (member, community, user, group) => {
                    return getItemFromLocalStorage(`getstarted-grouppinboard-${community.id}-${group.id}`, false)
                }
            },
            {
                id: "group-setup-5",
                title: "Add Members",
                subtitle: "Add members to the space.",
                icon: "user",
                action: {
                    label: "",
                    type: "param",
                    param: "action=add-members"
                },
                completion: (member, community, user, group) => {
                    return getItemFromLocalStorage(`getstarted-groupaddmembers-${community.id}-${group.id}`, false)
                }
            },
            {
                id: "group-setup-6",
                title: "Check Rules",
                subtitle: "Apply rules to automate space membership.",
                icon: "cog",
                action: {
                    label: "",
                    type: "param",
                    param: "action=edit-smart-rules"
                },
                completion: (member, community, user, group) => {
                    return getItemFromLocalStorage(`getstarted-groupsmartrules-${community.id}-${group.id}`, false)
                }
            }
        ]
    };
}