import React from 'react';
import './styles.css';

function getSize(sz) {
    if (sz === 'xl') {
        return "max-w-4xl";
    } else if (sz === 'large') {
        return "max-w-3xl";
    } else if (sz === 'small') {
        return "max-w-lg"
    } else {
        return "max-w-2xl"
    }
}

export default function Modal({
                                  children,
                                  classes = "",
                                  min_height = "",
                                  center,
                                  size = 'standard',
                                  top = 'sm:mt-12',
                                  style = {}
                              }) {
    return <div style={{borderColor: "#D7D7D7", minHeight: min_height, ...style}}
                className={`popup-shadow ${classes} bg-white ${center ? "mx-auto" : ""} ${top} bg-white flex h-full sm:h-auto flex-col sm:border sm:rounded-xl sm:mb-40 main-modal w-full ${getSize(size)}`}>
        {children}
    </div>
}