import React, {useEffect, useState} from "react";
import {
    CommunityEntityPage, PageLayoutBlock, PageLayoutSection
} from "../../../m3/_legacy_components/app-frame/layouts";
import EntityProfileCard from "../../../m3/_legacy_components/entity-profile-card";
import {useCommunity} from "../../../config/community";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useUnaverse} from "../../../config/unaverse";
import EditImageModal from "../../../m3/_legacy_components/edit-image-modal";
import {
    api_downloadMemberContact,
    getMemberProfile,
    member_changeCoverPhoto,
    member_changeProfilePicture,
    updateMemberInterests
} from "./api";
import FollowButton from "../../../m3/_legacy_components/follow-button";
import {getEntityFollowData} from "../../../m3/_legacy_components/follow-button/api";
import {getProfileQualityLevel} from "../home/complete-your-profile";
import {getItemFromLocalStorage} from "../../../m3/utilities/localstorage";
import {SelectInterestsModal} from "../../../m3/_legacy_components/interests/select-interests";
import {m3_icon_map} from "../../../m3/icons/icon-map";
import {getMemberFlags} from "./utils";
import {buildMemberProfile} from "./member-profile-utils";
import M3_A_Button from "../../../m3/atoms/button";
import M3_A_ActionMenu from "../../../m3/atoms/action-menu";
import M3_A_IconButton from "../../../m3/atoms/icon-button";
import {clientDownloadFile} from "../../../../common/utilities/files";
import {api_generateGhostAccessLink} from "../../manage/pages/tools";
import {useToasts} from "../../../config/toasts";
import {copyTextToClipboard} from "../../../m3/utilities/copy-to-clipboard";
import {CommonMemberProfile} from "./profile";

// Removed
//   const profile_quality_level = getProfileQualityLevel(data, community, getItemFromLocalStorage(`${community.domain}-${community.member_id}-data-check-ignore`, {}));
export default function MemberPage() {
    let [searchParams, setSearchParams] = useSearchParams();
    const {handle} = useParams();
    const location = useLocation();
    const community = useCommunity();
    const toasts = useToasts();
    const [em, setEm] = useState(null);
    const {uid, custom_fields} = community;
    const unaverse = useUnaverse();
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [follow_data, setFollowData] = useState();
    const [error, setError] = useState();
    const [follows_you, setFollowsYou] = useState();
    const [following, setFollowing] = useState();
    const flags = getMemberFlags(uid, data, community, {following, follows_you, error});

    useEffect(function () {
        setData(null);
        getData();
    }, [handle, uid])

    function getData() {

        getMemberProfile(uid, handle)
            .then(dt => {

                if (dt && dt.does_not_exist) {
                    setError('does_not_exist')
                } else {
                    handleData(dt)
                    getEntityFollowData(community, dt.id, "member")
                        .then((fd) => {
                            if (!fd) {
                                fd = {};
                            }
                            setFollowData(fd)
                            setFollowsYou(!!fd['members'] && fd['members'][community.member_id])
                        })
                }
            })
    }

    // switch to use action collector
    useEffect(function () {
        if (data && data.name) {
            const edit = searchParams.has('edit')

            if (!!edit) {
                fns.onOpenEdit("");
                navigate(location.pathname);
            }
        }
    }, [data, location, searchParams])

    function handleSaveInterests(new_interests) {
        if (typeof new_interests === 'object') {
            updateMemberInterests(community, new_interests)
                .then(() => {
                    setEm(null)
                    community.updateMemberProfile()
                        .then(mp => {
                            setData(mp)
                        });
                })
        }
    }

    const fns = {
        onOpenInterests: () => setEm('edit-interests'),
        onOpenImageEditor: !flags.my_profile ? null : () => {
            community.openEditOverlay({
                type: 'edit-profile-picture',
                size: "33rem",
                data: {
                    community_uid: community.uid,
                    onChange: fns.handleProfilePictureChange
                },
                onClose: () => {
                    community.closeEditModal()
                }
            })
        },
        onOpenEditCoverPhoto: !flags.my_profile ? null : () => {
            community.openEditOverlay({
                type: 'edit-cover-photo',
                size: "42.5rem",
                data: {
                    community_uid: community.uid,
                    onChange: fns.handleCoverPhotoChange
                },
                onClose: () => {
                    community.closeEditModal()
                }
            })
        },
        onOpenEdit: (fi) => {
            community.openEditOverlay({
                type: 'edit-member',
                focus_field: fi,
                handle,
                data: {
                    ...data,
                    _highlighted_field: `field-${fi}`
                }
            })
        },
        handleProfilePictureChange: (url, color) => {
            member_changeProfilePicture(community, {url, color})
                .then(() => {
                    community.updateMemberProfile()
                        .then(mp => {
                            setData(mp)
                        });
                    community.closeEditModal();
                })
        },
        handleCoverPhotoChange: (url, color) => {
            member_changeCoverPhoto(community, {url, color})
                .then(() => {
                    community.updateMemberProfile()
                        .then(mp => {
                            setData(mp)
                        });
                    community.closeEditModal();
                })
        }
    };

    function handleData(dt) {
        setData(dt)
    }

    let spec = buildMemberProfile(data, flags, community, unaverse, fns);

    // todo add permissions to download contact
    let more_options = {
        items: [
            {
                label: "Download Contact",
                onClick: () => {
                    // members/download-contact
                    api_downloadMemberContact({
                        id: data?.id,
                        member_id: community.member_id,
                        community_uid: community.uid
                    })
                        .then((resp) => {
                            if (resp?.data?.vcard) {
                                clientDownloadFile(resp.data.name, resp.data.vcard, "text/vcard")
                            }
                        })
                }
            }
        ]
    };

    if (flags.can_manage) {
        more_options.items.push({
            label: "Copy Member ID",
            onClick: () => {
                copyTextToClipboard(data?.id, () => {
                    toasts.addToast({
                        text: "Member ID copied to clipboard",
                        intent: "success",
                    });
                })
            }
        });

        more_options.items.push({
            label: "Copy Member Account Email",
            onClick: () => {
                copyTextToClipboard(data?.account_email, () => {
                    toasts.addToast({
                        text: "Member Account Email copied to clipboard",
                        intent: "success",
                    });
                })
            }
        });
    }

    // if is super
    if (unaverse.is_superadmin) {
        more_options.items.push({
            label: "Copy Ghost Sign-in Link",
            onClick: () => {
                api_generateGhostAccessLink(data?.account_email)
                    .then(link => {
                        console.log("Link", link)
                        copyTextToClipboard(link, () => {
                            toasts.addToast({
                                text: "Link copied to clipboard",
                                intent: "success",
                            });
                        });
                    })
            }
        })
    }

    spec.buttons.push(<M3_A_ActionMenu renderAnchor={() => {
        return <div><M3_A_IconButton square icon={m3_icon_map.outlines.ellipsis}/></div>
    }} lists={[more_options]}/>);

    if (flags.can_edit) {
        spec.buttons.push(<M3_A_Button onClick={() => fns.onOpenEdit("")} text="Edit Profile"/>);
    }

    if (flags.can_follow && data) {
        spec.buttons.push(<FollowButton updateFollowing={(fl) => setFollowing(fl)} id={data.id} type="member"/>);
    }

    function renderTab(flags) {
        if (!data) return null;

        if (flags.deactivated) {
            return <PageLayoutBlock>
                <div>You are viewing the archives of a deactivated account.</div>
            </PageLayoutBlock>
        }

        return <>
            <CommonMemberProfile profile_actions={{
                onOpenEdit: (section_id) => {
                    if (section_id === 'interests') {
                        fns.onOpenInterests()
                    } else {
                        fns.onOpenEdit()
                    }
                },
                onAddField: (accessor) => {
                    if (accessor === "interests") {
                        fns.onOpenInterests()
                    } else {
                        fns.onOpenEdit(accessor)
                    }
                }
            }} following={following} follows_you={follows_you} mode="view" member_id={data?.id} member_data={data}/>
        </>
        /*
                // todo check ID
                return <ProfileFieldRenderer
                    my_profile={flags.my_profile}
                    sections={buildMemberProfileSections(data, custom_fields, community, unaverse, flags, fns)}>
                    {section_visibility.display_career &&
                        <MemberCareer key='career' my_profile={flags.my_profile} visibility={section_visibility.career_vis}
                                      cb={() => {
                                      }} career={data.career}
                                      user_uid={data.id} can_add={flags.can_edit}/>}

                    {section_visibility.display_education &&
                        <MemberEducation key='education' my_profile={flags.my_profile}
                                         visibility={section_visibility.education_vis}
                                         cb={() => {
                                         }}
                                         education={data.education}
                                         user_uid={data.id}
                                         can_add={flags.can_edit}/>}
                </ProfileFieldRenderer>;

         */
    }

    let header_actions = [];

    if (flags.can_manage) {
        header_actions.push({
            type: "button",
            label: "Manage",
            onClick: () => {
                community.openEditOverlay({
                    type: 'manage-member',
                    data,
                    handle,
                    closeCb: () => getData()
                })
            }
        });
    }

    const is_local = window.location.hostname === "localhost";
    if (is_local) {
        console.log("Data", data)
    }

    const modals = <>
        {em === "edit-profile-picture" &&
            <EditImageModal scope_id={community.uid} onChange={fns.handleProfilePictureChange}
                            onClose={() => setEm(null)}/>}
        {em === "edit-cover-photo" &&
            <EditImageModal scope_id={community.uid} type="cover-photo" onChange={fns.handleCoverPhotoChange}
                            onClose={() => setEm(null)}/>}
        {em === 'edit-interests' && <SelectInterestsModal onSave={handleSaveInterests} onClose={() => {
            setEm(null)
        }} interests={data?.interests}/>}
    </>;

    const card = <EntityProfileCard loading={!data && !error} {...spec}/>;

    //       {!flags.does_not_exist && renderTab(flags)}
    //  <CommonMemberProfile following={following} follows_you={follows_you} mode="view" member_id={data?.id} member_data={data} />
    return <CommunityEntityPage is_loading={!data && !error} card={card} sidebar={null} modals={modals} header={{
        title: spec.title,
        actions: header_actions
    }}>
        <PageLayoutSection>
            {!flags.does_not_exist && renderTab(flags)}
        </PageLayoutSection>
    </CommunityEntityPage>;
}