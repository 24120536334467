import React, {useEffect} from "react";
import Field, {FieldLabel} from "../../../../m3/_legacy_components/form/field";
import dayjs from "dayjs";
import {PrettyCheckbox} from "../../../../m3/_legacy_components/toggle";
import {TextAction} from "../../../auth/sign-in";
import {getCurrentTimezone} from "../../../../../common/utilities/lang-region";

export const DateFormat = "YYYY-MM-DD";
export const DateTimeFormat = "YYYY-MM-DD HH:mm:ss";

function generateStartTimeArr() {
    // start at 12:00am
    // then 12:15am
    // then 12:30am
    // then 12:45am
    // then 1:00am
    // then 1:15am

    let times = [];
    for (let i = 0; i < 24; i++) {
        let hour = i % 12;
        const ampm = (i < 12) ? "am" : "pm";
        if (hour === 0) hour = 12;
        times.push(`${hour}:00${ampm}`);
        times.push(`${hour}:15${ampm}`);
        times.push(`${hour}:30${ampm}`);
        times.push(`${hour}:45${ampm}`);
    }
    return times;
}

function getDuplicates(arr = []) {
    let a = [];

    arr.forEach((item, index) => {
        const count = arr.filter(i => i === item).length;
        if (count > 1) {
            a.push(`${item} -- ${count}`);
        }
    })

    return a;
}

function generateTimes(start_datetime = "") {
    // if start_datetime is set, add 15 minutes to it, minimum duration is 15 minutes
    const offset_datetime = start_datetime ? dayjs(start_datetime).add(15, 'minute') : null;

    const starting_hour = offset_datetime ? offset_datetime.hour() : 0;
    const starting_minute = offset_datetime ? offset_datetime.minute() : 0;

    // from 12:00am to 11:45pm
    let local_times = [];
    // want to start at 12:00am
    for (let i = 0; i < 24; i++) {

        // skip

        // take into account the starting hour
        //  const hour = (i + starting_hour) % 24;

        // hour should start at 12:00am
        const hour = (i + starting_hour) % 24;

        // am or pm
        const ampm = (hour < 12) ? "am" : "pm";

        let relative_hour = hour % 12;

        if (relative_hour === 0) relative_hour = 12;
        local_times.push(`${relative_hour}:00${ampm}`);
        local_times.push(`${relative_hour}:15${ampm}`);
        local_times.push(`${relative_hour}:30${ampm}`);
        local_times.push(`${relative_hour}:45${ampm}`);
    }

    const skip_count = starting_minute / 15;
    // return all but the first item
    return local_times.slice(skip_count)
}

/*
    if (show_duration && offset_datetime) {
            // desired: 12:00am (30m)
            // end_date is the base date = 2021-09-01
            // offset_datetime is the start = 2021-09-01 12:00:00
            let format00 = "";
            let format15 = "";
            let format30 = "";
            let format45 = "";

            times.push(`${hour}:00${ampm} (${format00})`);
            times.push(`${hour}:15${ampm} (${format15})`);
            times.push(`${hour}:30${ampm} (${format30})`);
            times.push(`${hour}:45${ampm} (${format45})`);
        } else {
 */
function TimeSelect({handleChange, show_duration = false, value, end_date = "", start_datetime = ""}) {
    const times = start_datetime ? generateTimes(start_datetime) : generateStartTimeArr();
    return <div>
        <select onChange={(e) => {
            handleChange(e.target.value);
        }} value={value} className="minimal-input">
            {times.map((time, index) => {
                // index === 0 is 15 minutes duration
                let label = time;
                if (show_duration && start_datetime) {
                    let duration = 15 * (index + 1);
                    // want 15m, 45m, 1h, 2.5h
                    if (duration >= 60) {
                        duration = duration / 60;
                        label = `${time} (${duration}h)`;
                    } else {
                        label = `${time} (${duration}m)`;
                    }
                }
                return <option key={time} value={time}>{label}</option>
            })}
        </select>
    </div>
}


export function getNearest15Minute(offset_minutes = 0) {
    let date_raw = dayjs();
    if (offset_minutes) {
        date_raw = date_raw.add(offset_minutes, 'minute')
    }
    let minutes_to_add = 15 - (date_raw.minute() % 15);
    let date = date_raw.add(minutes_to_add, 'minutes').startOf('minutes');

    return date.format(DateTimeFormat);
}

function getDate(init_start_date,offset_minutes) {
    let date;
    if(init_start_date) {
        date = dayjs(init_start_date);
    } else {
        date = dayjs();
    }
    if (offset_minutes) date.add(offset_minutes, 'minute');
    return date.format(DateFormat);
}

function parseTime(start_time = "") {
    // comes as YYYY-MM-DD HH:MM:SS
    // return HH:MMa
    const date = dayjs(start_time);
    return date.format("h:mma");
}

function isNextDay(start_time = "YYYY-MM-DD HH:MM:SS", h, m, ampm) {
    const day = dayjs(start_time);
    const hour = day.hour();
    const minute = day.minute();
    const is_next_day = (hour > h) || (hour === h && minute > m);
    return is_next_day;
}

export function DateTimeTimezoneSelector({
                                             hide_label,
                                             hide_allday_timezone,
                                             init_start_date = dayjs().format(DateFormat),
    init_start_time = getNearest15Minute(0),
                                             init_end_time,
                                             init_duration = 30,
                                             debug,
                                             onUpdate = () => {
                                             },
                                         }) {
    const [all_day, setAllDay] = React.useState(false);
    const [start_date, setStartDate] = React.useState(init_start_date); // YYYY-MM-DD
    const [start_time, setStartTime] = React.useState(init_start_time||getNearest15Minute(0)); // YYYY-MM-DD HH:MM:SS
    const [end_date, setEndDate] = React.useState(getDate(init_start_date,init_duration)); // YYYY-MM-DD
    const [end_time, setEndTime] = React.useState(init_end_time||getNearest15Minute(init_duration)); // YYYY-MM-DD HH:MM:SS
    const [timezone, setTimezone] = React.useState(getCurrentTimezone());
    const [edit_timezone, setEditTimezone] = React.useState(false);

    useEffect(function () {
        const payload = {
            start_date,
            start_time,
            end_date,
            end_time,
            timezone,
            all_day
        }
        onUpdate(payload);
    }, [start_date, start_time, all_day, end_date, end_time, timezone])

    function handleTimeChange(v, field = 'start_time') {
        let c1, c2, c3, current_duration, h, m, ampm;
        if (field === 'start_time') {
            // value comes as h:mma
            // need to convert to YYYY-MM-DD HH:MM:SS
            h = parseInt(v.split(":")[0]);
            m = parseInt(v.split(":")[1].replace("am", "").replace("pm", ""));
            ampm = v.includes("am") ? "am" : "pm";
            if (ampm === "pm") {
                h += 12;
            }
            c1 = dayjs(`${start_date}`).set('hour', h).set('minute', m).set('second', 0);

            // is after end time?
            c2 = dayjs(`${end_time}`);

            current_duration = Math.abs(dayjs(`${start_time}`).diff(dayjs(`${end_time}`), 'minute'));

            setStartDate(c1.format(DateFormat))
            setStartTime(c1.format(DateTimeFormat))

            if (c1.isAfter(c2)) {
                // update end time
                // set end time to 30 minutes after start time
                c3 = c1.add(current_duration, 'minute');
                setEndTime(c3.format(DateTimeFormat))
                setEndDate(c3.format(DateFormat))
            } else {
                // keep the current duration
                c3 = c1.add(current_duration, 'minute');
                setEndTime(c3.format(DateTimeFormat))
                setEndDate(c3.format(DateFormat))
            }
        } else if (field === 'end_time') {
            h = parseInt(v.split(":")[0]);
            m = parseInt(v.split(":")[1].replace("am", "").replace("pm", ""));
            ampm = v.includes("am") ? "am" : "pm";
            if (ampm === "pm") {
                h += 12;
            }

            // example
            // start time is 10:00pm
            // end time is 1:00am
            // duration is 180 minutes
            // need to convert to YYYY-MM-DD HH:MM:SS for end time, but also need to account for the next day
            const is_next_day = isNextDay(start_time, h, m, ampm);

            if (is_next_day) {
                // add a day
                c1 = dayjs(`${start_date}`).add(1, 'day').set('hour', h).set('minute', m).set('second', 0);

                setEndTime(c1.format(DateTimeFormat))
                setEndDate(c1.format(DateFormat))
            } else {
                // same day
                c1 = dayjs(`${start_date}`).set('hour', h).set('minute', m).set('second', 0);

                setEndTime(c1.format(DateTimeFormat))
                setEndDate(c1.format(DateFormat))
            }
        } else if (field === 'start_date') {
            let current_start_time = dayjs(`${start_time}`);
            current_duration = Math.abs(current_start_time.diff(dayjs(`${end_time}`), 'minute'));

            c1 = dayjs(`${v}`).set('hour', current_start_time.hour()).set('minute', current_start_time.minute()).set('second', 0);
            c2 = c1.add(current_duration, 'minute');
            setStartDate(c1.format(DateFormat))
            setStartTime(c1.format(DateTimeFormat))
            setEndDate(c2.format(DateFormat))
            setEndTime(c2.format(DateTimeFormat))
        } else if (field === 'end_date') {
            // handle if is before start date
            let current_end_time = dayjs(`${end_time}`);
            current_duration = Math.abs(dayjs(`${start_time}`).diff(current_end_time, 'minute'));

            c1 = dayjs(`${v}`).set('hour', current_end_time.hour()).set('minute', current_end_time.minute()).set('second', 0);

            if (c1.isBefore(dayjs(`${start_date}`))) {
                // use the current duration
                c2 = c1.subtract(current_duration, 'minute');
                setEndDate(c1.format(DateFormat))
                setEndTime(c1.format(DateTimeFormat))
                setStartDate(c2.format(DateFormat))
                setStartTime(c2.format(DateTimeFormat))
            } else {
                // use the current duration
                setEndDate(c1.format(DateFormat))
                setEndTime(c1.format(DateTimeFormat))
            }
        }
    }

    const different_start_end_dates = !dayjs(`${start_date}`).isSame(dayjs(`${end_date}`), 'day');

    return <div>
        {!hide_label&&<FieldLabel label={"Date"}/>}
        <div className="flex gap-3 flex-col sm:flex-row">
            <div>
                <input className="minimal-input min-w-[12rem] sm:min-w-auto" value={start_date}
                       onChange={e => handleTimeChange(e.target.value, 'start_date')} type="date"/>
            </div>
            {!all_day && <div>
                <TimeSelect handleChange={(a) => handleTimeChange(a, 'start_time')} value={parseTime(start_time)}/>
            </div>}
            {!all_day && <div>
                <TimeSelect handleChange={(a) => handleTimeChange(a, 'end_time')} value={parseTime(end_time)}
                            show_duration end_date={end_date}
                            start_datetime={different_start_end_dates ? null : start_time}/>
            </div>}
            {(all_day || different_start_end_dates) && <div>
                <input className="minimal-input min-w-[12rem] sm:min-w-auto" value={end_date}
                       onChange={e => handleTimeChange(e.target.value, 'end_date')} type="date"/>
            </div>}
        </div>
        {!hide_allday_timezone&&<div className="pt-3 flex">
            <div className="flex-grow">
                <PrettyCheckbox label="All Day?" active={all_day} onClick={() => {
                    setAllDay(!all_day)
                }}/>
            </div>
            {!edit_timezone && <div className="flex gap-1.5 text-sm text-gray-500">
                <div>Timezone is {timezone}</div>
                <TextAction onClick={() => {
                setEditTimezone(true)
            }
            } text="Edit"/>
            </div>}
        </div>}
        {edit_timezone&&<div className="pt-3">
            <Field label="Timezone" value={timezone} id="timezone" onChange={(a, b) => {
                setTimezone(b);
            }} type="timezone"/>
        </div>}
        {debug && <div>
            DEBUG
            <div>start_date: {start_date}</div>
            <div>start_time: {start_time}</div>
            <div>end_date: {end_date}</div>
            <div>end_time: {end_time}</div>

        </div>}
    </div>
}