import {isAnonymousUnatyEmail} from "../../../m3/utilities/email";
import {TextAction} from "../../auth/sign-in";
import {EnvelopeIcon, PhoneIcon} from "@heroicons/react/24/outline";
import FindMeOn from "../../../m3/_legacy_components/find-me-on";
import ViewInterests from "../../../m3/_legacy_components/view-interests";
import {
    getCFValue,
    handleProfileSocialFields,
    isCFEmpty
} from "../../../m3/_legacy_components/profile-field-renderer/value-pipeline";
import {MemberGroupsList} from "../../../m3/_legacy_components/profile-field-renderer";
import {manageMemberDetailList} from "./detail-list";
import {buildCardMeta} from "../../../m3/utilities/build-card-meta";
import {FollowsYouTag} from "../../unaverse/profile";
import React from "react";
import {buildImageUrl} from "../../../../common/utilities/images";

export function getPhoneServices(member) {
    if (!member.phone_services) {
        return {
            phone: [],
            secondary_phone: []
        }
    } else {

        return {
            phone: member.phone_services.phone ? member.phone_services.phone.split(",") : [],
            secondary_phone: member.phone_services.secondary_phone ? member.phone_services.secondary_phone.split(",") : [],
        }
    }
}

function getEmail(email) {
    if (isAnonymousUnatyEmail(email)) {
        return "Anonymous";
    }
    return email;
}

export function buildFullName(about = {}) {
    let s = "";
    if (about.title) {
        s += `${about.title} `;
    }
    if (about.first_name) {
        s += `${about.first_name} `;
    }
    if (about.last_name) {
        s += `${about.last_name}`;
    }
    return s.trim();
}

function standardFlow(data, community, flags, fns, ps, custom_fields, add_empty_field) {
    let s = [];

    s.push({
        title: "Contact Info",
        vis_key: `contact_info_vis`,
        display_key: `display_contact_info`,
        actions: [],
        items: []
    })

    if (flags.can_edit) {
        s[0].actions.push(<TextAction onClick={() => fns.onOpenEdit()}>Edit</TextAction>);
    }


    if (data.account_email) {
        s[0].items.push({
            interactive: true,
            onClick: () => {
            },
            icon: {
                icon: <EnvelopeIcon/>
            },
            label: "Email Address",
            type: 'email',
            value: getEmail(data.account_email),
            meta: {}
        });
    }

    if (data?.contact?.phone) {
        s[0].items.push({
            interactive: true,
            onClick: () => {
            },
            icon: {
                icon: <PhoneIcon/>
            },
            label: "Phone",
            type: 'phone',
            value: data?.contact?.phone,
            footer: <FindMeOn field="phone" id={data.id} my_profile={data.id === community.member.id}
                              active={ps[`phone`]}/>,
            meta: {}
        });
    }

    s.push({
        title: 'Interests',
        actions: [],
        vis_key: `interests_vis`,
        display_key: `display_interests`,
        items: [
            {
                custom: true,
                component: <div>
                    <ViewInterests my_profile={community.member_id === data.id} onAddClick={() => {
                        fns.onOpenInterests();
                    }} add_prompt={add_empty_field} items={Object.keys(data.interests || {})}
                                   cmi={data.interests || {}}/>
                </div>
            }
        ],
        cols: 1
    })
    if (flags.my_profile) {
        s[1].actions.push(<TextAction onClick={() => fns.onOpenInterests()}>Edit</TextAction>);
    }

    s.push({
        title: 'Details',
        vis_key: `details_viz`,
        actions: [],
        items: [
            {
                label: "Full Name",
                type: 'string',
                value: buildFullName(data?.about || {}),
                meta: {}
            },
            {
                label: "Membership Level",
                type: 'string',
                value: getMemberTypeName(data, community),
                meta: {}
            },
            {
                label: "Address",
                type: 'address',
                onAddClick: () => {
                    fns.onOpenEdit("address")
                },
                empty: isCFEmpty(data.address, "address"),
                add_prompt: add_empty_field,
                value: data.address,
                meta: {}
            }
        ]
    })

    s[2].items.push({
        label: "Spaces",
        type: 'groups',
        custom: true,
        component: <MemberGroupsList data={data} community={community}/>,
        meta: {}
    });

    if (data.roles && Object.keys(data.roles).length > 0) {
        s[2].items.push({
            label: "Positions",
            type: 'roles',
            community_uid: community.uid,
            value: data.roles || {},
            meta: {}
        });
    }

    if (flags.can_edit) {
        s[2].actions.push(<TextAction onClick={() => fns.onOpenEdit()}>Edit</TextAction>);
    }

    // custom fields pipeline
    Object.entries(custom_fields).map(([key, dt], index) => {
        s.push({
            title: dt.name,
            actions: [],
            vis_key: `${dt.id}_vis`,
            display_key: `display_custom.${dt.id}`,
            items: []
        })
        // todo account for multiple sections with index

        dt.fields.forEach(fk => {
            const fi = dt.field_data[fk];
            if (fi) {
                const v = getCFValue(fk, fi.type, data.custom_fields || {});
                s[index + 3].items.push({
                    label: fi.name,
                    hint: fi.hint,
                    desc: fi.desc,
                    type: fi.type,
                    add_prompt: add_empty_field,
                    onAddClick: () => fns.onOpenEdit(`custom_fields.${fk}`),
                    community_uid: community.uid,
                    required: fi.required,
                    value: v,
                    empty: isCFEmpty(v, fi.type),
                    options: fi.options || {}
                })
            }
        })
        if (flags.can_edit) {
            s[index + 3].actions.push(<TextAction onClick={() => fns.onOpenEdit()}>Edit</TextAction>);
        }
    })

    return s;
}

// todo shared
export function buildMemberProfileSections(data, custom_fields, community, unaverse, flags, fns, flow = 'standard', follow_data, profile_quality_level) {
    const add_empty_field = flags.my_profile || flags.can_manage;
    const ps = getPhoneServices(data);

    if (flow === 'manage') {
        return manageMemberDetailList(data, community, flags, fns, ps, custom_fields, add_empty_field, follow_data, profile_quality_level);
    } else {
        return standardFlow(data, community, flags, fns, ps, custom_fields, add_empty_field);
    }
}

// todo utility?
function getMemberTypeName(member, community) {
    if (!member) {
        return '';
    }

    if (member.member_type_name) {
        return member.member_type_name;
    }

    if (!community || !community.member_types) {
        return '';
    }
    const mt = community.member_types.filter(a => a.id === member.member_type)[0];

    if (mt) {
        return mt.singular;
    }

    return member.member_type;
}

export function buildMemberProfile(data, flags, community, unaverse, fns) {
    let title, message, ex, subtitle;

    if (flags.deactivated) {
        // deactivated
        title = `Account Deactivated`;
        subtitle = `@${data.handle}`;
    } else if (flags.does_not_exist) {
        title = "This account doesn't exist";
        subtitle = "Try searching for another.";
    } else if (flags.suspended) {
        title = `Account Suspended`;
        subtitle = `@${data.handle}`;
    } else {
        title = data?.name || "";
        subtitle = data?.about?.headline ? `${data.about.headline}` : "";
        ex = {
            desc: data?.about?.bio || "",
            image: buildImageUrl(data?.cover_photo, "_large"),
            image_color: data?.cover_photo_color,
            features: {
                edit_cover: !!flags.my_profile,
                openEditProfilePicture: !flags.my_profile ? null : () => fns.onOpenImageEditor(),
                onOpenEditCoverPhoto: !flags.my_profile ? null : () => fns.onOpenEditCoverPhoto()
            },

            icon: {
                onClick: () => {
                    community.openImage({
                        url: buildImageUrl(data?.profile_picture, ''),
                        color: data?.profile_picture_color
                    })
                },
                image: buildImageUrl(data?.profile_picture, '_medium', community.default_member_profile_picture),
                type: "image",
                interactive: true,
                fade_hover: true,
                large: true
            },
            meta: buildCardMeta({
                meta: {
                    member_type: getMemberTypeName(data || {}, community || null),
                    occupation: data?.about?.occupation,
                    join_date: data?.join_date,
                    birthday: data?.birthday,
                    location: data?.about?.location
                }
            }, handleProfileSocialFields(data?.social || {}), {
                is_admin: community.admins.all_user_uids[data?.id]
            }),
        }

        if (flags.follows_you) {
            ex.pre_suffix = <FollowsYouTag/>;
        }

        ex.follows_you = flags.follows_you;
        ex.following = flags.following;
        ex.can_follow = flags.can_follow;
    }

    return {
        title,
        subtitle,
        message,
        // subtitle: getMemberTypeName(data, community),

        icon: {
            image: "",
            type: "image",
            large: true
        },

        layout: {
            large_image: true
        },
        buttons: [],
        ...ex
    };
}

export function displayProfileSection(sec, is_admin, my_profile) {
    if (sec === 'all-members') {
        return true;
    } else if (is_admin && sec === 'admins-only') {
        return true;
    } else if ((is_admin && sec === 'private') || my_profile) {
        return true;
    } else if ((is_admin && sec === 'read-only') || my_profile) {
        return true;
    } else {
        return false;
    }
}

export function communityGetSectionsToDisplay(community, is_admin, my_profile = false) {
    let display_social = true, display_interests = true, display_education = true, display_career = true,
        display_custom = {};
    let social_vis = 'all-members', career_vis = 'all-members', education_vis = 'all-members',
        interests_vis = 'all-members';
    const dd = community.profile.design_defaults;
    if (dd) {
        if (community.profile.design_defaults.career_visibility) {
            career_vis = community.profile.design_defaults.career_visibility;
            display_career = displayProfileSection(community.profile.design_defaults.career_visibility, is_admin, my_profile);
        }
        if (community.profile.design_defaults.interests_visibility) {
            interests_vis = community.profile.design_defaults.interests_visibility;
            display_interests = displayProfileSection(community.profile.design_defaults.interests_visibility, is_admin, my_profile);
        }
        if (community.profile.design_defaults.education_visibility) {
            education_vis = community.profile.design_defaults.education_visibility;
            display_education = displayProfileSection(community.profile.design_defaults.education_visibility, is_admin, my_profile);
        }
        if (community.profile.design_defaults.social_visibility) {
            social_vis = community.profile.design_defaults.social_visibility;
            display_social = displayProfileSection(community.profile.design_defaults.social_visibility, is_admin, my_profile);
        }
    }

    const cf_sections = community.custom_fields;

    let ex = {};

    for (let i = 0; i < cf_sections.length; i++) {
        const cf_sec = cf_sections[i];
        display_custom[cf_sec.id] = displayProfileSection(community.custom_fields[i].visibility ? community.custom_fields[i].visibility : 'all-members', is_admin, my_profile);
        ex[`${cf_sec.id}_vis`] = community.custom_fields[i].visibility ? community.custom_fields[i].visibility : 'all-members';
    }
    return {
        display_social, display_custom, display_career, display_interests, display_education,
        social_vis, career_vis, education_vis, interests_vis, ...ex
    }
}

export function canEditProfileSection(sec, can_manage_members, my_profile) {
    if (sec === 'all-members') {
        return (can_manage_members || my_profile);
    } else if (can_manage_members && sec === 'admins-only') {
        return true;
    } else if ((can_manage_members || my_profile) && sec === 'private') {
        return true;
    } else if (can_manage_members && sec === 'read-only') {
        return true;
    } else {
        return false;
    }
}