import React, {useEffect, useState} from "react";
import {getItemFromLocalStorage, saveItemToLocalStorage} from "../../../../m3/utilities/localstorage";
import {FieldLabel} from "../../../../m3/_legacy_components/form/field";
import Button from "../../../../m3/_legacy_components/button";
import {authFetch} from "../../../../../api/network";
import {useCommunity} from "../../../../config/community";
import {PrettyCheckbox} from "../../../../m3/_legacy_components/toggle";

const default_flags = {
    catch_up_reminders: false,
    birthdays: false,
    contacts: false,
};

const data = {
    catch_up_reminders: {
        hide: true,
        label: "Catch-up Reminders"
    },
    birthdays: {
        hide: true,
        label: "Birthdays"
    },
    contacts: {
        label: "Contacts"
    }
};

export function getFeatureFlagValue(key = '') {
    let ffs = {
        ...default_flags, ...getItemFromLocalStorage('feature-flags', {
            ...default_flags
        })
    };

    return !!ffs[key];
}

export function downloadFile(href, name) {
    const link = document.createElement('a');
    link.href = href;
    link.target = '_blank';
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    link.remove();
    return true;
}

async function getMemberContacts(community) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };
        console.log("community", community)
        const payload = {
            member_id: community.member_id,
            user_uid: community.member.user_uid,
            community_uid: community.uid,
        };

        authFetch("/community/my-contacts.vcf", res, res, "POST", {payload})
    })
}

function ContactsSection({}) {
    const community = useCommunity();
    const [loading, setLoading] = useState("");
    const show = getFeatureFlagValue('contacts');

    if (!show) {
        return null;
    }

    return <div className={"pt-8"}>
        <Button text="Download Contacts" loading={loading === 'downloading'} onClick={() => {
            setLoading("downloading");
            getMemberContacts(community)
                .then(async resp => {
                    if (resp.data) {
                        const blob = new Blob([resp.data], {type: 'vcf'});
                        const href = await URL.createObjectURL(blob);
                        const name = `my-contacts-${community.domain}.vcf`;
                        downloadFile(href, name);
                        setLoading("");
                    }

                })
        }}/>
    </div>
}

export async function exportMembers(community, query_payload = {}, delimiter = "comma", date_format = "usa") {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };
        const payload = {
            member_id: community.member_id,
            user_uid: community.member.user_uid,
            community_uid: community.uid,
            delimiter,
            date_format,
            query_payload
        };

        authFetch("/members/export", res, res, "POST", {payload})
    })
}

function ExportSection({}) {
    const community = useCommunity();
    const [loading, setLoading] = useState("");
    const [link, setLink] = useState("");


    return <div className={"pt-8"}>
        <Button text="Export Members" loading={loading === 'exporting'} onClick={() => {
            setLoading("exporting");
            exportMembers(community)
                .then(async resp => {
                    if (resp) {
                        setLoading("");
                        setLink(resp.data.url);
                    }

                })
        }}/>
        <div className="pt-2 pt-2 text-xs max-w-sm" style={{overflowWrap: "break-word"}}>
            {link && <a href={link} className="link" target="_blank">{link}</a>}
        </div>
    </div>
}

export function UserPreferencesAdvanced() {
    const [flags, setFlags] = useState({
        ...default_flags, ...getItemFromLocalStorage('feature-flags', {
            ...default_flags
        })
    });

    function handleChange(key, value) {
        setFlags({
            ...flags,
            [key]: !value
        })
    }

    useEffect(function () {
        saveItemToLocalStorage('feature-flags', flags)
    }, [flags])

    const keys = Object.entries(flags);

    return <div>
        <FieldLabel>Feature Flags</FieldLabel>
        <div className="space-y-4">
            {keys.map(([key, value], index) => {
                if (data[key].hide) {
                    return null;
                }
                return <PrettyCheckbox label={data[key].label} key={index} active={value}
                                       onClick={() => handleChange(key, value)}/>
            })}
        </div>
        <ContactsSection/>
        <ExportSection/>
    </div>
}