import React from "react";
import Field from "../../../../../m3/_legacy_components/form/field";
import {DateRange} from "../../../position/past-assignees/date-range";

const types = [
    'Full-time',
    'Part-time',
    'Self-employed',
    'Freelance',
    'Seasonal',
    'Contract',
    'Internship',
    'Apprenticeship'
];

const CaStep = {
    title: '',
    current_role: false,

    type: '',
    location: '',

    description: '',

    media: [],

    start_date: {
        month: null,
        year: null
    },

    end_date: {
        month: null,
        year: null
    },

    duration_months: 0,

    org_data: {
        community_uid: '',
        title: '',
        image: '',
        handle: '',
        industry: ''
    }
};

export function AddCareerStep({updatePosition, position}) {
    const {
        title, from_year,
        from_month,
        to_year, location, current,
        to_month, description, org_data
    } = position;

    return <div className="flex flex-col gap-4">
        <Field placeholder='Select Title' taxonomy='position_titles' type='custom-add-select' label="Title"
               onChange={(v) => {
                   updatePosition('title', v)
               }} autoFocus
               value={title}/>

        <Field placeholder='Select Company' taxonomy='companies' type='custom-add-select' label="Company"
               onChange={(v) => {
                   updatePosition('org_data', v)
               }}
               value={org_data.title}/>

        <DateRange onChange={(v) => updatePosition('range', v)} range={{
            current,
            from_year,
            from_month,
            to_year,
            to_month
        }} enable_current_check={true} labels={{start: 'Start date', end: 'End Date'}}
                   current_check_text={`I'm currently working in this role`}
                   type='month-year'/>

        <Field optional type={"text"} label="Location" onChange={(v) => {
            updatePosition('location', v)
        }}
               value={location}/>

        <Field optional type={"textarea"} label="Description" onChange={(v) => {
            updatePosition('description', v)
        }}
               value={description}/>
    </div>
}