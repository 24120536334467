import React, {useEffect, useState} from "react";
import {ModalHeader} from "../../../../m3/_legacy_components/preview-overlay";
import EntityTabs from "../../../../m3/_legacy_components/entity-tabs";
import Button from "../../../../m3/_legacy_components/button";
import {AppSettingsPage} from "./app-settings";
import {apps_addApp, apps_removeApp, apps_updateConfig} from "./mailing-list-api";
import {useCommunity} from "../../../../config/community";
import {useToasts} from "../../../../config/toasts";
import {InlineLoader} from "../../../../m3/_legacy_components/admin-activity";

function Sidebar({app_data, group, addAppToGroup, app_id}) {
    const learn_more_link = app_data.learn_more || "https://getunaty.com";
    return <div className="flex flex-col gap-3">
        <div className="w-full" style={{aspectRatio: "1/1"}}>
            <img alt="App Image" className={"w-full h-full"} src={app_data.thumbnail}/>
        </div>
        <div className="flex flex-col gap-2">
            <div>
                {app_data.added ? <Button fill text={`Installed`}/> :
                    <Button onClick={() => addAppToGroup()} intent="success" fill text={`Add to Group`}/>}
            </div>
            <div>
                <Button onClick={()=>{
                    window.open(learn_more_link, '_blank', 'noopener,noreferrer')
                }} fill text={`Learn more`}/>
            </div>
        </div>
    </div>
}

function RenderDescription({app_data}) {
// description is a string, so it's safe to render it as HTML
    return <div className="py-2 text-gray-700 text-base" dangerouslySetInnerHTML={{__html: app_data.description}}/>
}

export function AppPage({
                            app_data,
                            app_id,
                            group,
                            goBack,
                            updateGroupData
                        }) {
    const community = useCommunity();
    const toasts = useToasts();
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState("Description");
    let tabs = [{label: "Description"}];
    if (app_data.added) {
        tabs.push({label: "Settings"});
    }

    useEffect(function () {
        const searchParams = new URLSearchParams(window.location.search);
        const cbflow = searchParams.has('cbflow');
        const google_email = searchParams.get('google_email');
        const google_auth = searchParams.get('google_auth');

        if(google_auth&&google_auth==="success"&&google_email&&cbflow) {
            console.log("SIGN IN TO GOOGLE, SHOW CALLOUT THAT CONNECT SUCCESSFUL")
            setTab("Settings");
        }
    }, [])

    function handleRemoveApp() {
        setLoading(true);
        apps_removeApp(community, group.id, app_id)
            .then(data => {
                updateGroupData();
                setLoading(false);
                goBack();
                toasts.addToast({intent: "success", text: "App removed from group"})
            })
    }

    function handleAddApp() {
        setLoading(true);
        apps_addApp(community, group.id, app_id)
            .then(data => {
                updateGroupData();
                setLoading(false);
                toasts.addToast({intent: "success", text: "App added to group"})
            })
    }

    function updateAppSettings(new_data) {
        setLoading(true);
        apps_updateConfig(community, group.id, app_id, new_data)
            .then(data => {
                updateGroupData();
                setLoading(false);
                toasts.addToast({intent: "success", text: "App settings updated"})
            })
    }

    function renderTab() {
        switch (tab) {
            case "Description":
                return <RenderDescription app_data={app_data} />
            case "Settings":
                return <div>
                    <AppSettingsPage handleRemoveApp={handleRemoveApp} updateAppSettings={updateAppSettings} group={group} app_data={app_data}
                                     app_id={app_id}/>
                </div>
        }
    }

    return <div className="relative">
        <ModalHeader goBack={goBack} sizing="h-8 px-0"
                     divider={false}
                     title={app_data.name}/>
        <div className="grid gap-4 grid-cols-4 pt-2">
            <div>
                <Sidebar addAppToGroup={() => handleAddApp(app_id, app_data)} app_data={app_data} app_id={app_id}
                         group={group}/>
            </div>
            <div className="col-span-3">
                <div>
                    <div>
                        <div>
                            <div className="text-lg font-bold text-gray-900">{app_data.name}</div>
                        </div>
                        <div className="flex gap-3 text-sm">
                            <div className="text-gray-500">Created by {app_data.author}</div>
                            <div className="text-gray-500">Version {app_data.version}</div>
                        </div>
                        <div className="pt-2">
                            <div className="text-sm text-gray-600">{app_data.short_description}</div>
                        </div>

                    </div>
                    <div>
                        <EntityTabs active={tab} onChangeTab={nt => setTab(nt)} tabs={tabs}/>
                    </div>
                    <div>
                        {renderTab()}
                    </div>
                </div>
            </div>
        </div>
        {loading && <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 flex items-center justify-center">
            <InlineLoader />
        </div>}
    </div>
}