import React, {useEffect} from "react";
import {useIsMobile} from "../../../m3/hooks/is-mobile";
import {useCommunity} from "../../../config/community";
import {constructMemberCollections} from "../_legacy_directory/constructMemberCollections";
import M3_C_Layout from "../../../m3/components/layout";
import {useSearchParams} from "react-router-dom";
import {contacts_buildContactsAttributes, contacts_getInitialActiveList} from "./utilities";
import {ContactsMobileSelectionPage} from "./mobile";
import {ContactsSidebar} from "./sidebar";
import {ContactsMain} from "./main";
import {_m3_c_list_view_utils} from "../../../m3/components/list-view/utils";

export default function CommunityContactsPage(props) {
    const is_mobile = useIsMobile();
    const community = useCommunity();
    const [search_params, setSearchParams] = useSearchParams();
    const lists = constructMemberCollections(community, "");

    const [attributes, setAttributes] = React.useState(contacts_buildContactsAttributes(_m3_c_list_view_utils.getAttributes(community),community));

    const [active_list, setActiveList] = React.useState(contacts_getInitialActiveList());

    useEffect(function () {
        setAttributes(contacts_buildContactsAttributes(_m3_c_list_view_utils.getAttributes(community), community));
    }, [community.member_types]);

    useEffect(() => {
        if (!active_list && lists.length > 0 && !is_mobile) {
            setActiveList(lists?.[0]?.list_id || "");
        }
    }, [lists, active_list]);

    useEffect(function () {
        if (active_list) {
            setSearchParams(params=>{
                params.set("list", `${active_list}`);
                return params;
            })
        } else {

            setSearchParams(params=>{
                params.delete(`list`);
                return params;
            })
        }
    }, [active_list])

    if (is_mobile) {
        if (active_list) {
            return <ContactsMain attributes={attributes} mobile active={active_list} setActiveList={setActiveList} lists={lists}/>
        }

        return <ContactsMobileSelectionPage attributes={attributes}>
            <ContactsSidebar mobile active={active_list} setActiveList={setActiveList} lists={lists}/>
        </ContactsMobileSelectionPage>
    }

    return <M3_C_Layout main={<ContactsMain attributes={attributes} active={active_list} setActiveList={setActiveList} lists={lists}/>}
        sidebar={<ContactsSidebar active={active_list} setActiveList={setActiveList} lists={lists}/>}/>
}