import React, {useContext} from "react";
import {useCommunity} from "../../../../config/community";
import {useNavigate} from "react-router-dom";
import AdminImport from "./index";

export function AdminImportWrapper(props) {
    const community = useCommunity();
    const navigate = useNavigate();
    return <div>
        <AdminImport community={community} onClose={()=>{
            props.onClose();
        }} goBackTo={() => navigate(`/c/${community.domain}/home`)}
                     scrollable={true} {...props} />
    </div>
}