import React, {useContext, useEffect, useRef, useState} from 'react';
import {doc, getDoc, collection, getDocs, where, documentId, query, orderBy, limit} from "firebase/firestore";
import {db} from "./setup-firestore";
import {useUnaverse} from "./unaverse";
import {useNavigate, useParams} from "react-router-dom";
import {useAuth} from "./auth";
import {authFetch} from "../../api/network";
import {getMemberSignature} from "../routes/community/preferences/tabs/posts-email";
import {getEntityNotificationPreferences} from "../routes/community/group/primary-button";
import {m3_generateTheme} from "../m3/utilities/color-system";
import {buildCommunityTheme} from "../m3/_legacy_components/app-frame/utilities";
import {getCurrentMemberFollowing} from "../m3/_legacy_components/follow-button/api";
import {data_membership_status_arr} from "../../common/data/data/membership-status";
import {getNestedValue} from "../../common/utilities/general";

export const DEFAULT_COMMUNITY_LOGO = 'https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2F6ER1AKWO1N';
export const DEFAULT_USER_PHOTO = 'https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2F1868I1QUR5';
export const DEFAULT_UNASSIGNED_ROLE = 'https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2F605UVB21IZ';
export const DEFAULT_COVER_PHOTO = '';

export const ADMIN_APP_URL = 'https://getunaty.com';

export const HELP_CENTER_URL = 'https://support.getunaty.com';
const CommunityContext = React.createContext(null);

async function getCommunityProfile(handle) {
    const ref = collection(db, "communities");
    const q = query(ref, where('handle', '==', handle), limit(1));
    const snap = await getDocs(q);

    return snap.docs[0] ? {
        ...snap.docs[0].data(),
        id: snap.docs[0].id
    } : null;
}

async function getAdminList(uid) {
    const ref = doc(db, 'community_admins', uid);
    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? doc.data() : null;
        });
}

export async function getMemberProfileById(uid, member_id) {
    if (!uid || !member_id) {
        console.error('getMemberProfile', uid, member_id)
        return null;
    }
    const ref = doc(db, 'community_members', uid, 'members', member_id);
    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? {...doc.data(), id: doc.id} : null;
        });
}

async function getMemberTypes(cid) {
    const ref = collection(db, "community_members", cid, "member_types");
    const q = query(ref, limit(50));
    return await getDocs(q);
}

async function getCustomProfileSections(cid) {
    const ref = collection(db, "community_members", cid, "profile_sections");
    const q = query(ref, limit(50));
    return await getDocs(q);
}

export async function getManyDocuments(col, cid, subcol, f = documentId(), arr) {
    const chunks = chunk(arr, 10);
    let p = [];
    let ref;
    if (!cid && !subcol) {
        ref = collection(db, col);
    } else {
        ref = collection(db, col, cid, subcol);
    }
    chunks.forEach(ch => {
        p.push(getDocs(query(ref, where(f, 'in', ch), limit(10))));
    });
    return await Promise.all(p).then(res => {
        return res.map(snap => snap.docs).flat();
    });
}

export function chunk(array, size) {
    const chunked_arr = [];
    for (let i = 0; i < array.length; i++) {
        const last = chunked_arr[chunked_arr.length - 1];
        if (!last || last.length === size) {
            chunked_arr.push([array[i]]);
        } else {
            last.push(array[i]);
        }
    }
    return chunked_arr;
}


/*
Logic
- show items with updates on top
- show muted items at the bottom
- sort each group by name

difference between features which means at top
and show update, which just means it does have an update generally
 */
function fullSort(a, b) {
    if (a.featured && !b.featured) return -1;
    if (!a.featured && b.featured) return 1;

    if (!a.show_muted && b.show_muted) return -1;
    if (a.show_muted && !b.show_muted) return 1;

    return a._sort_name.localeCompare(b._sort_name);
}

async function getGroups(cid, entities_groups) {
    const group_ids = Object.keys(entities_groups);

    let p = [];

    const chunks = chunk(group_ids, 10);

    chunks.forEach(ch => {
        p.push(getManyDocuments('community_entities', cid, 'groups', documentId(), ch));
    });

    return await Promise.all(p)
        .then(all => {
            let b = [];
            all.forEach(snap => {
                snap.forEach(doc => {
                    b.push({
                        ...doc.data(),
                        id: doc.id
                    })
                })
            })
            return b;
        });
}

async function getMemberEntities(uid, member_id) {
    if (!uid || !member_id) {
        console.error('getMemberEntities', uid, member_id)
        return {};
    }
    const ref = doc(db, 'community_members', uid, 'member_relationships', member_id);
    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? {...doc.data(), id: doc.id} : null;
        });
}

async function getAllPolicies(uid) {
    if (!uid) {
        return [];
    }
    const ref = collection(db, 'communities', uid, 'policies');
    const q = query(ref, where('status', '==', 'published'), limit(10));
    return await getDocs(q)
        .then(snap => {
            return !snap.empty ? snap.docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            }) : [];
        });
}

async function getAllRoles(uid) {
    if (!uid) {
        console.error('getAllRoles', uid)
        return [];
    }
    const ref = collection(db, 'community_entities', uid, 'roles');
    const q = query(ref, limit(100));
    return await getDocs(q)
        .then(snap => {
            return !snap.empty ? snap.docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            }) : [];
        });
}

async function getAllTeams(uid) {
    if (!uid) {
        console.error('getAllTeams', uid)
        return [];
    }
    const ref = collection(db, 'community_entities', uid, 'groups');
    const q = query(ref, where("display_leaders", "==", true), limit(50))
    return await getDocs(q)
        .then(snap => {
            return !snap.empty ? snap.docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            }) : [];
        });
}

function getLoadingStatus(user, ready, cp, member_ids, member, error) {

    if (error && error === 'community-not-found') {
        return "community-not-found";
    }

    if (!ready) {
        return "loading-user";
    }

    if (!user && ready) {
        return "no-user";
    }

    if (!cp || !Object.keys(cp).length) {
        return "loading-community";
    }

    if (!member_ids) {
        return "loading-memberships";
    }

    if (member_ids && !member_ids[cp.id]) {
        return "not-a-member";
    }

    if (!member || !Object.keys(member).length) {
        return "loading-member";
    }

    if (error && error === "member-not-found") {
        return "member-not-found";
    }

    if (member && member.name && cp && cp.name) {
        return "ready";
    }

    return "loading";
}

const mock_access = {
    community: false,
    all_roles: false,
    admins: true,
    teams: {},
    members: {},
    roles: {},
    member_types: {}
};

function getAccess(community, arr) {
    let final = {};

    arr.forEach(a => {
        if (community && community.access && community.access[a]) {
            final[a] = community.access[a]
        } else {
            final[a] = {...mock_access}
        }
    })

    return final;
}

function memberIsInTeam(team, member_id) {
    return team.user_uids[member_id];
}

function getMemberAccess(community_access, is_admin, all_roles, all_teams, member_types, member_id, member) {
    let permissions = {
        add_members: false,
        add_segments: false,
        create_spaces: true,
        manage_roles: false,
        manage_members: false
    };

    if(!member) {
        return permissions;
    }

    if (is_admin) {
        permissions.add_members = true;
        permissions.manage_roles = true;
        permissions.create_spaces = true;
        permissions.add_segments = true;
        permissions.manage_members = true;
    }

    if (community_access?.add_members?.community) {
        permissions.add_members = true;
    }

    if (community_access?.manage_roles?.community) {
        permissions.manage_roles = true;
    }

    if (!community_access?.create_spaces?.community) {
        permissions.create_spaces = false;
    }

    if (community_access?.manage_members?.community) {
        permissions.manage_members = true;
    }

    if (community_access?.add_segments?.community) {
        permissions.add_segments = true;
    }

    // teams
    const member_teams = Object.entries(all_teams).filter(a=>memberIsInTeam(a[1],member_id,all_roles))

    member_teams.forEach(a=>{
        if (community_access?.add_members?.teams?.[a[0]]) {
            permissions.add_members = true;
        }
        if (community_access?.manage_roles?.teams?.[a[0]]) {
            permissions.manage_roles = true;
        }
        if (community_access?.create_spaces?.teams?.[a[0]]) {
            permissions.create_spaces = true;
        }
        if (community_access?.add_segments?.teams?.[a[0]]) {
            permissions.add_segments = true;
        }
        if (community_access?.manage_members?.teams?.[a[0]]) {
            permissions.manage_members = true;
        }
    });

    // roles
    const member_roles = Object.entries(all_roles).filter(a=>{
        return a[1].assignee === member_id
    });

    if(member_roles.length>0) {
        if (community_access?.add_members?.all_roles) {
            permissions.add_members = true;
        }
        if (community_access?.add_segments?.all_roles) {
            permissions.add_segments = true;
        }
        if (community_access?.manage_roles?.all_roles) {
            permissions.manage_roles = true;
        }
        if (community_access?.create_spaces?.all_roles) {
            permissions.create_spaces = true;
        }
        if (community_access?.manage_members?.all_roles) {
            permissions.manage_members = true;
        }
    }

    member_roles.forEach(a=>{
        if (community_access?.add_members?.roles?.[a[0]]) {
            permissions.add_members = true;
        }
        if (community_access?.add_segments?.roles?.[a[0]]) {
            permissions.add_segments = true;
        }
        if (community_access?.manage_roles?.roles?.[a[0]]) {
            permissions.manage_roles = true;
        }
        if (community_access?.create_spaces?.roles?.[a[0]]) {
            permissions.create_spaces = true;
        }
        if (community_access?.manage_members?.roles?.[a[0]]) {
            permissions.manage_members = true;
        }
    });

    // member types
    if (community_access?.add_members?.member_types?.[member.member_type]) {
        permissions.add_members = true;
    }
    if (community_access?.add_segments?.member_types?.[member.member_type]) {
        permissions.add_segments = true;
    }
    if (community_access?.manage_roles?.member_types?.[member.member_type]) {
        permissions.manage_roles = true;
    }
    if (community_access?.create_spaces?.member_types?.[member.member_type]) {
        permissions.create_spaces = true;
    }
    if (community_access?.manage_members?.member_types?.[member.member_type]) {
        permissions.manage_members = true;
    }

    return permissions;
}

const type_nav_map = {
    "member": "member",
    "role": "position",
    "group": "space",
    "space": "space",
    "event": "event",
    "post": "post",
    "position": "position",
}

async function getTopLevelFolders(cid, mid) {
    const col = collection(db, 'community_entities', cid, 'folders');
    let q = query(col, where(`user_uids.${mid}`, '==', true), limit(100));
    const snap = await getDocs(q);
    return snap.docs.map(doc => {
        return {
            ...doc.data(),
            id: doc.id
        }
    });
}

function updateLocalStorageKeys(community, member) {
    if (community && member) {
        if (member._ignored_fields) {
            const convert_to_obj = member._ignored_fields.reduce((acc, curr) => {
                acc[curr] = true;
                return acc;
            });
            console.log("SET OBJ", convert_to_obj)
            //saveItemToLocalStorage(`${community.handle}-${member.id}-data-check-ignore`, convert_to_obj)
        }
    }
}

const getDefaultProfileSections = (community, custom_fields_sections) => {
    // how the logic works is that we include the base sections first and then we add the custom sections
    let a = ['basic-information', 'contact-information', 'membership', 'interests', 'details', 'work-and-education']

    if (custom_fields_sections.length > 0) {
        custom_fields_sections.forEach(s => {
            a.push(s.id)
        })
    }

    return a;
}

const addMissingCustomSections = (sections, custom_fields_sections) => {
    // if a custom section is not in the sections array, we add it to the end
    custom_fields_sections.forEach(s => {
        if (!sections.includes(s.id)) {
            sections.push(s.id)
        }
    });

    return sections;
}

const getValueWithDefault = (obj, key, def) => {
    if(!obj) return def;
    return getNestedValue(key, obj, def);
}

const CommunityContextProvider = ({children}) => {
    const unaverse = useUnaverse();
    const {user, __ready} = useAuth();
    const {domain} = useParams();
    const navigate = useNavigate();
    const [lightbox, setLightbox] = useState(null);
    const [error, setError] = useState();
    const [show_mobile_menu, setShowMobileMenu] = useState(true);
    const [all_roles, setAllRoles] = useState([]);
    const [all_teams, setAllTeams] = useState([]);
    const [settings, setSettings] = useState(false);
    const [image, setImage] = useState(null);
    const [composer_prefill, setComposerPrefill] = useState(null);
    const [shortcuts, setShortcuts] = useState([]);
    const [policies, setPolicies] = useState([]);
    const [unread, setUnread] = useState(0);
    const [local_entities, updateLocalEntities] = useState({});
    const [member_following, updateMemberFollowing] = useState({});
    const [prev_over, setPreviewOverlay] = useState();
    const [edit_modal, setEditModal] = useState();
    const [view_modal, setViewModal] = useState();
    const [sidebar_scheme_m3, setSidebarSchemeM3] = useState({});
    const [custom_fields, setCustomFields] = useState([]);
    const [top_level_folder_ids, setTopLevelFolderIds] = useState([]);
    const [sidebar_sections, setSidebarSections] = useState([]);
    const [member_signature_raw, setMemberSignatureRaw] = useState("");

    const loadingState = useRef('none');

    const [member, setMember] = useState(() => {
        return null;
    });

    const [member_types, setMemberTypes] = useState(() => {
        return [];
    });

    const [member_entities, setMemberEntities] = useState(() => {
        return {};
    });
    const [admins, setAdmins] = useState(() => {
        return {
            all_user_uids: {},
            members: {},
            roles: {}
        };
    });
    const [community_profile, setCommunityProfile] = useState(() => {
        return {};
    });
    const [loader, setLoader] = useState(0);

    let member_id = member ? member.id : "";

    let community_id = community_profile ? community_profile.id : "";

    useEffect(() => {
        if (community_id && member_id) {
            const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const payload = {
                community_id,
                member_id,
                timezone: tz
            };
            if (unaverse.ghost_mode) {
                console.log('prevented register sign in')
                return;
            }

            authFetch('/members/record-sign-in', () => {
            }, () => {
            }, 'POST', {payload});
        }
    }, [member_id, community_id]);

    function handleSwitchCommunity() {
        setCommunityProfile({});
        setMember(null);
        setMemberEntities({});
        setMemberTypes([]);
        setAdmins({
            all_user_uids: {},
            members: {},
            roles: {}
        });
        setAllRoles([]);
        setAllTeams([]);
        setSidebarSections([]);
        setShortcuts([]);
        setPolicies([]);
        setUnread(0);
        updateLocalEntities({});
        updateMemberFollowing({});
        setTopLevelFolderIds([]);
    }

    function handleLEUpdate(arr, type) {
        const cu = {...local_entities};
        if (type === 'groups') {
            arr.forEach(gr => {
                cu[`group__${gr.id}`] = {
                    label: gr.name,
                    name: gr.name,
                    value: gr.id,
                    id: gr.id,
                    image: gr.profile_picture,
                    color: gr.color,
                    count: Object.keys(gr.user_uids).length,
                    letter: gr.name[0].toUpperCase(),
                    emoji: gr.emoji,
                    email: gr?.['power_ups']?.['unaty__mailing_lists']?.config?.email_address || "",
                    purpose: gr.purpose,
                    handle: gr.handle,
                };
            })
        }
        updateLocalEntities(cu);
    }

    function handleUpdateShortcuts(arr, type, eref) {
        let sc = [...shortcuts];
        if (type === 'groups') {
            arr.forEach(g => {
                let id = `group__${g.id}`;
                const index = sc.findIndex(a => a.id === id);
                const entity_prefs = getEntityNotificationPreferences(member.notifications, 'group', g.id);

                let no = {
                    _sort_name: g.name.toLowerCase(),
                    label: g.name,
                    muted: !!entity_prefs?.muted,
                    click: {
                        action: 'navigate',
                        to: `/c/${domain}/space/${g.id}`
                    },
                    color: g.color,
                    emoji: g.emoji,
                    closed: g.closed,
                    show_lock: g.closed,
                    member_count: Object.keys(g.user_uids).length,
                    show_muted: !!entity_prefs?.muted,
                    image: g.profile_picture,
                    last_update: eref?.[g.id].last_update_at || null,
                    //count: eref[g.id].updates || 0,
                    show_update: eref?.[g.id]?.updates > 0 || false,
                    featured: (eref?.[g.id]?.updates > 0 && !entity_prefs?.muted) || false,
                    count: eref?.[g.id]?.updates || 0,
                    id
                };
                if (index === -1) {
                    sc.push({...no});
                } else {
                    sc[index] = {...no};
                }
            })
        }

        const final_sorted = sc.sort(fullSort);

        setShortcuts(final_sorted);
    }

    function goToPreview(dt, type = "member", inspector) {
        console.log("GO TO PREVIEW", dt, type, inspector)
        if (typeof dt === "object") {
            if (window.innerWidth > 767 && type !== "group" && type !== "space") {
                setPreviewOverlay({
                    type: type,
                    handle: dt.handle,
                    data: dt?.data || null,
                    id: dt.id ? dt.id : dt?.data?.id ? dt?.data?.id : "",
                })
            } else {
                if (type === "member") {
                    if (dt.handle) {
                        navigate(`/c/${community_profile.handle}/${type_nav_map[type]}/${dt.handle}`)
                    } else {
                        navigate(`/c/${community_profile.handle}/${type_nav_map[type]}/${dt.id}`)
                    }
                } else {
                    navigate(`/c/${community_profile.handle}/${type_nav_map[type]}/${dt.id}`)
                }
            }
        }
    }

    async function handleUpdateMemberProfile() {
        if (member_id && community_id) {
            return await getMemberProfileById(community_id, member_id)
                .then(m => {
                    setMember(m);
                    updateLocalStorageKeys(community_profile, m);
                    return m;
                })
        }
    }

    async function handleUpdateCommunityProfile() {
        if (community_id) {
            getCommunityProfile(domain)
                .then(c => {
                    if (c) {
                        setCommunityProfile(c);
                        updateLocalStorageKeys(c, member);
                    }
                })
        }
    }

    useEffect(function () {
        if (loader === 0 && (!community_profile || !Object.keys(community_profile).length)) {
            setLoader(0.2);
        } else if (loader > 0 && loader < 0.5 && (!!community_profile && Object.keys(community_profile).length > 0)) {
            setLoader(0.5);
        } else if (loader > 0.4 && loader < 1 && !!member) {
            setLoader(1);
        }

        // set timeout so loader never shows more than 5 seconds
        if (loader > 0 && loader < 1) {
            setTimeout(() => {
                setLoader(1);
            }, 5000);
        }
    }, [loader, community_profile, member])

    function handleLoadCommunityMemberInfo(c) {
        const member_id = unaverse.memberships.member_ids ? unaverse.memberships.member_ids[c.id] : "";

        if (member_id) {
            getMemberProfileById(c.id, member_id)
                .then(m => {
                    if (!m) {
                        setError("member-not-found");
                        return null;
                    }

                    setMember(m)
                    setUnread(m.unread_notifications || 0)
                    return m;
                });
        } else {
            setMember(null);
        }
    }

    useEffect(function () {
        if (member && community_profile) {
            loadSecondaryInformation(community_profile, member.id)
        }
    }, [community_profile, member])

    function loadSecondaryInformation(c, member_id) {

        getMemberSignature(c.id, member_id)
            .then(sig => {
                setMemberSignatureRaw(sig || "")
            })

        getMemberEntities(c.id, member_id)
            .then(me => {
                setMemberEntities(me)
            })

        getTopLevelFolders(c.id, member_id)
            .then(folders => {
                setTopLevelFolderIds(folders.map(f => f.id));
            })

        getAllRoles(c.id)
            .then(resp => setAllRoles(resp))

        getAllPolicies(c.id)
            .then(resp => setPolicies(resp))

        getAllTeams(c.id)
            .then(resp => setAllTeams(resp))

        getMemberTypes(c.id)
            .then(snap => {
                setMemberTypes(snap.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id
                    }
                }));
            })

        getCustomProfileSections(c.id)
            .then(snap => {
                setCustomFields(snap.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id
                    }
                }));
            })

        handleUpdateSidebarSections(c.id)

        handleUpdateMemberFollowing(c.id, member_id)

        getAdminList(c.id)
            .then(ad => {
                setAdmins(ad)
            })
    }

    useEffect(function () {
        // we should try not to do this too often, infinite loop?
        if (member_entities && member_entities.groups && community_id) {
            const group_ids = Object.keys(member_entities.groups);
            if (group_ids.length > 0) {
                // todo optimize
                console.log("member_entities",member_entities)
                getGroups(community_id, member_entities.groups || {})
                    .then(groups => {
                        handleLEUpdate(groups, 'groups');
                        handleUpdateShortcuts(groups, 'groups', member_entities.groups || {});
                    })
            }
        }

    }, [community_id, member_entities])

    function handleUpdateSidebarSections(cid) {
        const ref = doc(db, `communities/${cid}/meta/sidebar-sections`);
        getDoc(ref)
            .then(doc => {
                if (doc.exists()) {
                    const dt = doc.data();
                    // parsed
                    const parsed_string = dt.data ? JSON.parse(dt.data) : [];
                    setSidebarSections(parsed_string);
                }
            });
    }

    useEffect(function () {

        if (community_profile && Object.keys(community_profile).length > 0 && Object.keys(unaverse?.memberships?.member_ids?.length > 0)) {
            if (unaverse?.memberships?.member_ids?.[community_profile.id]) {
                // HOW TO PREVENT THIS FROM BEING CALLED MULTIPLE TIMES
                handleLoadCommunityMemberInfo(community_profile)
            }
        }

    }, [community_profile, unaverse?.memberships?.member_ids])

    useEffect(() => {
        // should be able to get from user, otherwise not member
        // currently have 2 calls to this
        if (unaverse.memberships) {
            // check if we should reset

            if (loadingState.current === `loaded-${domain}`) {
                return;
            }

            if (loadingState.current.startsWith('loaded') && loadingState.current !== `loaded-${domain}`) {
                handleSwitchCommunity();
                loadingState.current = 'none';
            }

            if (loadingState.current === 'loading') {
                return;
            }
            loadingState.current = 'loading';
            getCommunityProfile(domain)
                .then(c => {
                    loadingState.current = `loaded-${domain}`;
                    if (!c) {
                        setError('community-not-found')
                        return;
                    }
                    setCommunityProfile(c);
                    setSidebarSchemeM3(buildCommunityTheme({
                        id: c.id,
                        ...c
                    }, true));
                })
        }
    }, [unaverse.memberships, domain]);

    function handleUpdateMemberFollowing(cid, mid) {
        getCurrentMemberFollowing(cid, mid)
            .then(map => {
                updateMemberFollowing(map)
            })
    }

    let flags = {
        is_member: !!member,
        is_superadmin: unaverse?.is_superadmin,
        is_owner: member_id && community_profile.created_by === member_id,
        is_admin: member_id && admins.all_user_uids && admins.all_user_uids[member_id]
    };

    const loading_status = getLoadingStatus(user, __ready, community_profile, unaverse.memberships.member_ids, member, error);

    const community_access = getAccess(community_profile, ['add_members', 'manage_members', 'create_spaces', 'manage_roles']);
    const member_access = getMemberAccess(community_access, flags.is_admin, [], [], member_types, member_id, member);

    const theme_preferences = sidebar_scheme_m3;
    const generated_theme_all_variants = sidebar_scheme_m3 && sidebar_scheme_m3.accent_color ? m3_generateTheme(sidebar_scheme_m3.accent_color, sidebar_scheme_m3.mode, sidebar_scheme_m3.brightness, sidebar_scheme_m3.notification_color) : null;
    const active_theme = generated_theme_all_variants?.derived?.[(generated_theme_all_variants?.mode || "light")];

    const view_profile_sections = addMissingCustomSections(getValueWithDefault(community_profile, "design_defaults.profile_sections", getDefaultProfileSections(community_profile, custom_fields)), custom_fields);
    const summary_fields = getValueWithDefault(community_profile, "design_defaults.summary_fields", ['location','occupation','membership_level','birthday','website','social_links']);

    let v = {
        // the is the user preferences for the theme showing
        profile_definitions: {
            sections: view_profile_sections,
            summary_fields
        },

        theme_preferences,
        // this is how we calculate out how to apply the theme to all variants
        generated_theme_all_variants,
        // this is the active variant
        active_theme,
        setThemePreferences: (ns) => {
            setSidebarSchemeM3(ns);
        },


        all_roles,
        all_teams,

        policies,

        image,
        openImage: (image) => {
            setImage(image)
        },
        closeImage: () => {
            setImage(null)
        },

        updateMemberEntity: (entity_type, entity_id, update = {}) => {
            const new_entities = {...member_entities};
            if (!new_entities[entity_type]) {
                console.log("no entity type", entity_type)
                return;
            }
            if (!new_entities[entity_type][entity_id]) {
                console.log("no entity id", entity_id)
                return;
            }
            new_entities[entity_type][entity_id] = {
                ...new_entities[entity_type][entity_id],
                ...update
            };

            setMemberEntities(new_entities);
        },

        updateMyEntities: (ntype, nid, nupdate) => {
            getMemberEntities(community_id, member_id)
                .then(me => {
                    let final = {...me};
                    if (ntype && nid && final && final[ntype] && nupdate) {
                        final[ntype][nid] = {
                            ...(final[ntype][nid] || {}),
                            ...nupdate
                        }
                    }
                    setMemberEntities(final)
                })
        },

        member_entities,

        updateAllTeams: () => getAllTeams(community_id).then(r => setAllTeams(r)),
        updateAllRoles: () => getAllRoles(community_id).then(r => setAllRoles(r)),
        updatePolicies: () => getAllPolicies(community_id).then(r => setPolicies(r)),

        toggleSettings: () => {
            setSettings(!settings)
        },
        settings,

        community_access,
        member_access,

        loading_status,

        openPreviewOverlay: (pl) => {
            goToPreview(pl, pl.type || "member")

        },
        openMessage: (pl) => {

            navigate(`/c/${domain}/post/${pl.id}`);
        },
        closePreviewOverlay: () => {
            setPreviewOverlay(null);
        },
        preview_overlay: prev_over,

        composer_prefill,
        openComposer: (pl) => {
            setComposerPrefill(pl)
        },
        closeComposer: () => {
            setComposerPrefill(null)
        },

        updatePositions: () => {
            getAllRoles(community_id)
                .then(resp => setAllRoles(resp))
        },

        openEditOverlay: (pl) => {
            setEditModal(pl);
        },
        closeEditModal: () => {
            setEditModal(null)
        },

        openViewOverlay: (pl) => {
            setViewModal(pl);
        },
        closeViewModal: () => {
            setViewModal(null);
        },

        goToAdminApp: (param) => {
            if(param==='build-mode') {
                window.open(`${ADMIN_APP_URL}/${domain}/admin/settings/build-mode?param=${param}`, '_blank');
            } else {
                window.open(`${ADMIN_APP_URL}/${domain}/admin/settings/designer/member-profile?param=${param}`, '_blank');
            }
        },

        name: community_profile.name || "",

        admins,
        is_admin: admins.all_user_uids && admins.all_user_uids[member_id],
        edit_modal,
        view_modal,

        domain,

        shortcuts,

        important_custom_fields: community_profile.important_custom_fields ? community_profile.important_custom_fields : [],

        group_ids: member?.group_ids || [],

        member,
        member_following,
        handleUpdateMemberFollowing: () => handleUpdateMemberFollowing(community_id, member_id),
        profile: community_profile,

        membership_status: data_membership_status_arr,

        member_types,
        id: community_id,
        uid: community_id,
        community_id,
        member_id,
        user_uid: unaverse ? unaverse.user_uid : member ? member.user_uid : "",

        default_member_profile_picture: community_profile.design_defaults && community_profile.design_defaults.member_profile_picture ? community_profile.design_defaults.member_profile_picture : DEFAULT_USER_PHOTO,
        default_member_cover_photo: community_profile.design_defaults && community_profile.design_defaults.member_cover_photo ? community_profile.design_defaults.member_cover_photo : DEFAULT_COVER_PHOTO,

        top_level_folder_ids,

        goToPreview,

        custom_fields,

        lightbox,
        setLightbox: (l) => setLightbox(l),

        updateMemberProfile: handleUpdateMemberProfile,
        updateCommunityProfile: handleUpdateCommunityProfile,

        simpleMemberUpdate: (update) => {
            setMember({...member, ...update})
        },
        local_entities,

        unread,
        clearUnread: () => setUnread(0),

        showMobileMenu: () => {
            setShowMobileMenu(true)
        },
        hideMobileMenu: () => {
            setShowMobileMenu(false)
        },
        show_mobile_menu,
        // stored as a string that has been converted to raw utils if it exists
        member_signature_raw,
        setMemberSignature: (str) => {
            setMemberSignatureRaw(str);
        },

        sidebar_sections,
        updateSidebarSections: () => handleUpdateSidebarSections(community_id),
        flags,

        loader,
        setLoader
    };

    return <CommunityContext.Provider value={v}>{children}</CommunityContext.Provider>
}

const useCommunity = () => {
    const data = useContext(CommunityContext);

    return data;
};

export {useCommunity, CommunityContextProvider}