import React from 'react';

/*
* This is the Form atom
 */

export default function M3_A_Form({ children, className, as = "form" }) {
    let classes = "flex flex-col gap-4";

    if(className) classes += " " + className;

    return React.createElement(as, { className: classes }, children);
}