import {sanitizationPipeline} from "./data-sanitization";
import {lie_getEntryRawValue} from "./build-row-data";
import {lie_prepMemberForImport} from "../../import/helpers";

function buildRecord(de_id, all_blocks, de_data, mapping, mapped_fields, mapping_entries) {
    let obj = {
        account_email: '',
        member_id: '',
        full_name: '',
        first_name: '',
        last_name: ''
    };
    // add each key here

    for (let k = 0; k < mapped_fields.length; k++) {
        const [data_key, data_field] = mapped_fields[k];
        const mapped_field_data = mapping_entries.filter(a => a[0] === data_key)[0];
        const block = all_blocks.filter(a => a.id === data_key)[0];
        const raw_value = lie_getEntryRawValue(de_data, block);
        const final_value = sanitizationPipeline(raw_value, mapped_field_data[1].field_details.type, mapping[data_key], true);

        // todo full name
        if (data_field === 'about.first_name') {
            obj['first_name'] = final_value;
        } else if (data_field === 'docid') {
            obj['member_id'] = final_value;
        } else if (data_field === 'about.last_name') {
            obj['last_name'] = final_value;
        } else if(data_field === 'full_name') {
            obj['full_name'] = final_value;
        }

        obj[data_field] = final_value;
    }

    return obj;
}

function buildRecords(data, all_blocks, mapping, mapping_ref, mapping_entries) {
    const data_entries = Object.entries(data);
    let arr = [];

    const mapped_fields = Object.entries(mapping_ref);

    for (let i = 0; i < data_entries.length; i++) {
        const [de_id, de_data] = data_entries[i];

        arr.push(buildRecord(de_id, all_blocks, de_data, mapping, mapped_fields, mapping_entries));
    }


    return arr;
}

function buildMappingRefObj(mapping_entries) {
    let obj = {};
    for (let i = 0; i < mapping_entries.length; i++) {
        obj[mapping_entries[i][0]] = mapping_entries[i][1].data_field;
    }
    return obj;
}

function prepareAllMembers(records) {
    let arr = [];
    for (let i = 0; i < records.length; i++) {
        const record = records[i];
        arr.push(lie_prepMemberForImport(record));
    }
    return arr;
}

export function lie_prepareExport(data, mapping, all_blocks) {
    const mapping_entries = Object.entries(mapping);

    const mapping_ref = buildMappingRefObj(mapping_entries);

    const all_records = buildRecords(data, all_blocks, mapping, mapping_ref, mapping_entries);

    const all_members = prepareAllMembers(all_records);

    return all_members;
}