import {getMemberProfileById} from "../../../../config/community";
import {buildSearchResults, communitySearch, getSearchQueryMeta} from "../../../../m3/_legacy_components/app-frame/search";
import {getMemberProfile, getMemberProfileByEmail} from "../../member/api";

const communitySorter = (a, b) => a.disabled ? 1 : -1 || b.score - a.score;

const mergeArrays = (arr1 = [], arr2 = []) => {
    return [
        ...[...arr1, ...arr2]
            .reduce(
                (acc, curr) => acc.set(curr.id, {...acc.get(curr.id), ...curr}),
                new Map()
            )
            .values(),
    ];
};

export async function handleGroupPeopleSearch(query, community_id, group_id, search_meta={}, trans=()=>{}) {
    let types = ["member", "role"];
console.log("handleGroupPeopleSearch",community_id,group_id,search_meta)
    const meta = getSearchQueryMeta(query);

    const scope = `mainsearch_${community_id}`;
    const final_scope = scope.split("mainsearch_")[1];

    let p = [];
console.log("META",meta)
    if (meta.flags.is_email) {
        p.push(getMemberProfileByEmail(final_scope, meta.query)
            .then(res => {
                let m = [];
                if (res && !res.does_not_exist) {
                    m.push(res);
                }
                return {
                    items: buildSearchResults({
                        results: m,
                        page: 1
                    }, "member",trans),
                    metadata: {
                        page: {}
                    }
                }
            }));
    }
    if (meta.flags.is_handle) {
        p.push(getMemberProfile(final_scope, meta.query.replace(/@/g, ''))
            .then(res => {
                let m = [];
                if (res && !res.does_not_exist) {
                    m.push(res);
                }
                return {
                    items: buildSearchResults({
                        results: m,
                        page: 1
                    }, "member",trans),
                    metadata: {
                        page: {}
                    }
                }
            }));
    }

    if (meta.flags.is_id) {
        p.push(getMemberProfileById(final_scope, meta.query)
            .then(res => {
                let m = [];
                if (res && !res.does_not_exist) {
                    m.push(res);
                }

                return {
                    items: buildSearchResults({
                        results: m,
                        page: 1
                    }, "member",trans),
                    metadata: {
                        page: {}
                    }
                }
            }));
    }

    p.push(communitySearch(meta.query, final_scope, types, search_meta)
        .then(resp => {

            const results = buildSearchResults(resp, "", trans);

            // return buildResults(resp, "member");
            return {
                items: results.sort(communitySorter),
                metadata: {
                    page: resp.page
                }
            };
        }));

    return await Promise.all(p).then(a => {
        if (a[1]) {
            return {
                items: mergeArrays(a[0].items, a[1].items).sort(communitySorter),
                metadata: a.map(b => b.metadata)
            };
        }
        return {
            items: a[0].items.sort(communitySorter),
            metadata: a.map(b => b.metadata)
        }
    });
}