import React from "react";
import M3_C_FilterBar from "../components/filter-bar";
import {AtSymbolIcon, ChartBarIcon} from "@heroicons/react/24/outline";

/*
* This is the FilterBarExample example
 */

export default function M3_E_FilterBarExample({}) {

    const config = {
        onChange: (item_id) => {},
        active_id: "all-posts",
        items: [
            {
                id: "all-posts",
                label: "All Posts"
            },
            {
                id: "announcements",
                label: "Announcements"
            },
            {
                id: "discussions",
                label: "Discussions"
            },
            {
                icon: <AtSymbolIcon />,
                id: "questions",
                label: "Questions"
            },
            {
                icon: <ChartBarIcon />,
                id: "polls",
                label: "Polls"
            }
        ]
    };

    return <M3_C_FilterBar {...config} />
}