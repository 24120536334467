import React, {useEffect, useRef, useState} from "react"
import './styles.css';
import EntityTabs from "../entity-tabs";
import {EntityColorSelector} from "../../../routes/community/group/manage/tabs/color-selector";
import {EmojiPicker} from "../emoji-picker";
import SimpleTooltip from "../tooltip";
import {UniversalIcon} from "../entity-card";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {SelectImageIcon} from "./select-image-icon";

export default function IconSelector({value, onChange, disable_text, enable_image, initial = "A"}) {
    const [v, setV] = useState(value ? {...value} : {
        type: "text",
        emoji: "",
        color: "",
        profile_picture: "",
        profile_picture_color: "",
    });

    const isFirstRun = useRef(true);

    useEffect(function () {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        onChange(v);
    }, [v])

    let tabs = [];

    if (!disable_text) {
        tabs.push({label: "Color"});
    }

    tabs.push({label: "Emoji"});

    if (enable_image) {
        tabs.push({label: "Image"});
    }

    const [tab, setTab] = useState(tabs[0].label);

    function renderTab() {
        switch (tab) {
            case "Emoji":
                return <div>
                    <EmojiPicker changeEmoji={(emoji) => {
                        setV({
                            profile_picture: "",
                            profile_picture_color: "",
                            color: "",
                            type: "emoji",
                            emoji: emoji.native ? emoji.native : emoji.imageUrl
                        })
                    }}/>
                </div>
            case "Color":
                return <div className="pt-3">
                    <EntityColorSelector initial={initial} onSelect={(color) => {
                        // setOpen(false);setPhoto("color",color)
                        setV({
                            profile_picture: "",
                            profile_picture_color: "",
                            type: "text",
                            color: color,
                            emoji: ""
                        })
                    }}/>
                </div>
            case "Image":
                return <div className="pt-3">
                    <SelectImageIcon onChange={(url)=>{
                        // todo, heh why favicon here?
                        setV({
                            profile_picture: "",
                            profile_picture_color: "",
                            type: "favicon",
                            favicon: url,
                            image: url,
                            color: "",
                            emoji: ""
                        })
                    }} />
                </div>
            default:
                return null;
        }
    }

    const content = <div className="w-[24rem] bg-white">
        <div className="border border-gray-200 rounded-lg popup-shadow">
            <div className="border-b border-gray-200 px-3">
                <EntityTabs tabs={tabs} active={tab} onChangeTab={t => setTab(t)}/>
            </div>
            <div className="" style={{minHeight: "10rem"}}>
                {renderTab()}
            </div>
        </div>
    </div>;
    return <div className="inline-block">
        <SimpleTooltip trigger='click' hideOnClick={false} onOpen={() => {
        }} onClose={() => {
        }} interactive simple
                       text={content} placement='bottom-start'>
            <div
                className="space-x-0.5 inline-flex items-center pl-1 pr-0.5 py-1 rounded-md hover:bg-gray-200 cursor-pointer">
                <div className="h-5 w-5">
                    <UniversalIcon type="text" {...v} {...{mini: true, string: initial}} />
                </div>
                <div className="h-4 text-gray-500 w-4">
                    <ChevronDownIcon/>
                </div>
            </div>
        </SimpleTooltip>
    </div>
};