export function getAdvancedImageBkg(url,size='') {
    if (url.startsWith('pattern_')) {
      // deprecated
        return ``;
    } else if (url.startsWith('https://')) {
       console.log("Get Advanced Image Bkg: ", url, size)
        return `url('${url}${size?`${size}`:""}?alt=media')`;
    } else {
        return `${url}`;
    }
}

export function getAdvancedImageBackgroundStyle(type,url,color,bg_size='auto',img_size='_medium') {
    let style = {};
    if(url.indexOf('https://')!==-1) {
        style.backgroundColor = color?color:"#efefef";
        style.backgroundImage = getAdvancedImageBkg(url,img_size);
        style.backgroundPosition = "center";
        style.backgroundSize = 'cover';
    } else if(url.startsWith('pattern_')) {
        style.backgroundColor = color?color:"#efefef";
        style.backgroundImage = getAdvancedImageBkg(url,img_size);
        style.backgroundPosition = "center";
        style.backgroundSize = bg_size;
    } else if(url.indexOf('-gradient')!==-1) {
        style.background = `${url}`;
    } else {
        style.backgroundColor = type==='community'?"":"#e3e8ed";
    }
    return style;
}