import React from "react"
import './styles.css';

export default function EmptyState({icon, emoji, button, title, subtitle}) {

    return <div className="empty-state flex-col gap-2 w-auto p-6 flex items-center justify-center">
        {icon && <div className="empty-state-icon h-6 w-6 text-gray-500">
            {icon}
        </div>}
        {emoji && <div className="empty-state-emoji text-6xl">
            {emoji}
        </div>}
        <div className="empty-state-title text-gray-600 text-sm">
            {title}
        </div>
        {subtitle && <div className="empty-state-subtitle text-gray-500 text-xs">
            {subtitle}
        </div>}
        {button && <div className="empty-state-button pt-2">
            {button}
        </div>}
    </div>
};