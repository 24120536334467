import React, {useState} from "react";
import Button from "../button";
import EditImageModal from "../edit-image-modal";
import {useCommunity} from "../../../config/community";
import {member_changeProfilePicture} from "../../../routes/community/member/api";
import {user_changeProfilePicture} from "../../../routes/unaverse/manage-user/api";
import {useUnaverse} from "../../../config/unaverse";
import {buildImageUrl} from "../../../../common/utilities/images";


export function ProfilePictureField({
                                        url, inline, onProfilePictureChange, type = 'member', onChange = () => {
    }
                                    }) {
    const [em, setEm] = useState(null);
    const [a, setA] = useState(url);
    const community = useCommunity();
    const unaverse = useUnaverse();
    let s = {};

    function handleProfilePictureChange(url3, color) {
        // .replace(/%2F/g,'/')
        let url2 = url3.replace('?alt=media', '').split("_large")[0];

        if (onProfilePictureChange) {
            onProfilePictureChange(url2, color)
            setEm(null)
            setA(url2)
            return;
        }

        if (type === 'user') {
            user_changeProfilePicture(unaverse, {url: url2, color})
                .then(() => {
                    onChange(url2, color)
                    setA(url2)
                    unaverse
                        .updateUserProfile()
                        .then(mp => {
                        });
                    setEm(null)
                })
        } else {
            member_changeProfilePicture(community, {url: url2, color})
                .then(() => {
                    onChange(url2, color)
                    setA(url2)
                    community
                        .updateMemberProfile()
                        .then(mp => {
                        });
                    setEm(null)
                })
        }
    }

    if (a) {
        let iurl = buildImageUrl(a,'_medium');
        s.backgroundImage = `url(${iurl})`;
    }

    const m = em === "edit-profile-picture" &&
        <EditImageModal scope_id={community.id} onChange={handleProfilePictureChange} onClose={() => setEm(null)}/>;

    if (inline) {
        return <div className="flex gap-3">
            <div className="flex-none">
                <div className="h-16 w-16 bg-gray-300 bg-center bg-cover" style={{borderRadius: '34%', ...s}}/>
            </div>
            <div className="flex-grow">
                <div className="inline-block">
                <Button onClick={() => setEm("edit-profile-picture")} fill text="Choose an Image"/>
                </div>
            </div>
            {m}
        </div>
    }

    return <div>
        <div className="w-40">
            <div>
                <div className="h-40 w-40 bg-gray-300 bg-center bg-cover" style={{borderRadius: '34%', ...s}}/>
            </div>
            <div className="pt-3">
                <Button onClick={() => setEm("edit-profile-picture")} fill text="Choose an Image"/>
            </div>
        </div>
        {m}
    </div>
}