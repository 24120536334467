import React, {useEffect, useRef, useState} from 'react';
import {useCommunity} from "../../../../config/community";
import {useIsMobile} from "../../../../m3/hooks/is-mobile";
import {MessageReply} from "./reply";
import {NewReply} from "./new-reply";
import {MobileDrawer} from "../../../../m3/_legacy_components/tooltip/mobile-drawer-container";
import {api_getPostReplies} from "../../../../../api/messages/get-post-replies";


export function RepliesContainer({
                                     flags = {},
                                     replies_count = 0,
                                     post_id = ""
                                 }) {
    const community = useCommunity();
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(0);
    const [last_page_ref, setLastPageRef] = useState(null);
    const [new_reply_context, setNewReplyContext] = useState(getBasicReplyContext(false));
    const replyListener = useRef(null);

    function handleFocus() {
        setNewReplyContext(getBasicReplyContext(true))
    }

    useEffect(function () {
        if(new_reply_context.focus) {
            setNewReplyContext({
                ...new_reply_context,
                focus: false
            })
        }
    }, [new_reply_context])

    useEffect(function () {
        if(!replyListener.current) return;
        replyListener.current.addEventListener('focus', handleFocus);

        return () => {
            if(!replyListener.current) return;
            replyListener.current.removeEventListener('focus', handleFocus)
        }
    }, [])

    function getBasicReplyContext(f=false) {
        return {
            post_id,
            reply_to_name: "",
            thread_id: "",
            focus: f,
            reply_to_id: "",
            type: "",
            onSendReply: (pl) => {
                addTemporaryReply(pl);
                getReplies()
            }
        }
    }

    function getReplies() {
        console.log("get replies", page, last_page_ref, community.id, post_id)
        api_getPostReplies(community.id, post_id, "", last_page_ref, page, 40)
            .then(result => {
                setItems(result.replies);
                setLastPageRef(result.last_ref);
            })
    }

    useEffect(function () {
        if (replies_count > 0) {
            getReplies()
        }
    }, [replies_count, post_id])

    function handleDeleteReply(reply_id) {
        setItems(items.filter(it => it.id !== reply_id))
    }

    function addTemporaryReply(reply) {
        setItems([{
            ...reply,
            temporary: true
        }, ...items])
    }

    function handleNewReply() {

    }

    function handleStartReply(nl = {}) {
        setNewReplyContext({
            ...new_reply_context,
            ...nl
        })
    }

    return <div className="">
        <MobileDrawer onClose={()=>{
            setNewReplyContext(getBasicReplyContext(false))
        }}>
            <NewReply onClear={()=>{
                setNewReplyContext(getBasicReplyContext(false))
            }} {...new_reply_context} />
        </MobileDrawer>
        <div className="border-gray-200 divide-gray-200 divide-y">
            {items.map((it, x) => <div className="" key={`${it.id}-${post_id}`}>
                <MessageReply onStartReply={handleStartReply} flags={flags} onNewReply={handleNewReply}
                              onDeleteReply={handleDeleteReply} {...it} />
            </div>)}
        </div>
        <div tabIndex="0" id={`post-reply-listener`} ref={replyListener}/>
    </div>
}