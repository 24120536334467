import React from "react";
import M3_A_Button from "./button";
import M3_A_Heading from "./heading";
import M3_A_Icon from "./icon";

/*
* This is the BlankSlate atom
 */

const _props = {
    children: {
        type: "node",
        required: false
    },
    title: {
        type: "string",
        required: false,
        fallback: "No Data"
    },
    description: {
        type: "string",
        required: false,
        fallback: "There is no data to display"
    },
    icon: {
        type: "node",
        required: false
    }
};

export default function M3_A_BlankSlate({
                                            children,
                                            title,
                                            description,
                                            button,
                                            icon
                                        }) {
    return (
        <div className={`flex flex-col gap-4 text-center items-center justify-center`}>
            {icon && <M3_A_Icon icon={icon} size="base" color="text-orange-600"/>}
            <M3_A_Heading size="h4">{title}</M3_A_Heading>
            <div className="text-gray-500 text-sm px-10">{description}</div>
            {button && <div className="">
                <M3_A_Button {...button} />
            </div>}
            {children && <div className="">
                {children}
            </div>}
        </div>
    );
}