import React, {useCallback, useEffect, useMemo, useReducer, useRef, useState} from "react"
import './styles.css';
import styled from 'styled-components';
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/24/outline";
import {Avatar} from "../avatar";
import Button from "../button";
import {
    useReactTable,
    flexRender,
    getSortedRowModel,
    getCoreRowModel
} from "@tanstack/react-table";
import {useVirtualizer} from "@tanstack/react-virtual";
import {utils_dates_format} from "../../../../common/utilities/dates";

const Styles = styled.div`
${'' /* These styles are suggested for the table fill all available space in its containing element */}
  display: block;
${'' /* These styles are required for a horizontaly scrollable table overflow */}
  overflow: auto;
  font-size: 0.95rem;
  width: 100%;

  .table {
    border-spacing: 0;
    width: 100%;

    .thead {
    ${'' /* These styles are required for a scrollable body to align with the header properly */}
      overflow-y: auto;
      overflow-x: hidden;
    }

    .tbody {
    ${'' /* These styles are required for a scrollable table body */}
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }

      //  border-bottom: 1px solid black;
    }

    .th {

    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;
      //border-right: 1px solid black;

    ${'' /* In this example we use an absolutely position resizer,
       so this is required. */}
      position: relative;

      :last-child {
        border-right: 0;
      }

      .resizer {
        right: 0;
        //  background: #5bb9de;
        width: 3px;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
      ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action: none;

        &.isResizing {
          background: #3183a0;
        }
      }
    }
  }
`

const headerProps = (props, {column}) => getStyles(props, column.align)

const cellProps = (props, {cell}) => getStyles(props, cell.column.align)

const getStyles = (props, align = 'left') => [
    props,
    {
        style: {
            justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
            alignItems: 'flex-start',
            display: 'flex',
        },
    },
];

function ArrowAction({children, disabled, onClick}) {
    const cl = disabled ? "text-gray-200 cursor-not-allowed" : "text-gray-500 cursor-pointer hover:text-gray-800";
    return <div onClick={() => {
        if (onClick) {
            onClick();
        }
    }} className={`${cl} w-6 h-6 rounded-full flex items-center justify-center hover:bg-gray-100 `}>
        <div className="w-4 h-4">
            {children}
        </div>
    </div>
}

function Paginate({can_prev, can_next, handlePrev, handleNext}) {
    return <div className="flex items-center space-x-1">
        <ArrowAction disabled={!can_prev} onClick={() => handlePrev()}>
            <ChevronLeftIcon/>
        </ArrowAction>
        <ArrowAction disabled={!can_next} onClick={() => handleNext()}>
            <ChevronRightIcon/>
        </ArrowAction>
    </div>
}

function buildUrl(u) {
    return u ? `${u}_medium?alt=media` : "";
}

function CellWrapper({children, className}) {
    return <div className={`overflow-hidden text-sm text-gray-600 ${className}`}>
        {children}
    </div>
}

function CellHandle({value}) {
    return <CellWrapper>
        <span>@{String(value)}</span>
    </CellWrapper>
}

function CellId({value}) {
    return <CellWrapper>
        <span style={{fontFamily: 'monospace', fontSize: '0.8rem'}}>{String(value)}</span>
    </CellWrapper>
}

function CellTitleSubtitleImage({
                                    value, onRowClick = () => {
    }, meta, original
                                }) {
    return <CellWrapper className="flex space-x-2 ">
        <div>
            <Avatar url={buildUrl(String(original[meta.image]))} size="h-10 w-10"/>
        </div>
        <div>
            <div onClick={() => {
                onRowClick();
            }}
                 className="text-sm truncate pt-px hover:underline cursor-pointer leading-5 text-gray-800 font-semibold">
                {String(original[meta.title])}
            </div>
            <div className="text-xs truncate text-gray-600">{String(original[meta.subtitle])}</div>
        </div>
    </CellWrapper>
}

function CellString({value}) {
    return <div className="text-sm truncate text-gray-800">
        {String(value)}
    </div>
}

function renderActions(actions, row_data = {}) {
    return actions.map(act => {
        return <div>
            <Button onClick={() => act.onClick(row_data)} {...act.props} />
        </div>
    })
}

function CellActions({column, raw}) {
    const actions = column.actions;
    return <CellWrapper>
        <div className="flex space-x-2">
            {renderActions(actions, raw)}
        </div>
    </CellWrapper>
}

function CellRenderer({value, column, row}) {

    const {original} = row;

    switch (column.type) {
        case 'id':
            return <CellId value={value}/>
        case 'actions':
            return <CellActions raw={original} column={column}/>
        case 'handle':
            return <CellHandle value={value}/>
        case 'timestamp':
            return <CellString value={utils_dates_format(value).relative}/>
        case 'title-subtitle-image':
            return <CellTitleSubtitleImage onRowClick={() => column.onRowClick(original.id)} meta={column.meta}
                                           value={value} original={original}/>
        default:
            return <CellString value={value}/>;
    }
}

function TableElement({
                          columns,
                          total_count = -1,
                          page_size = 10,
                          has_more,
                          onRowClick = () => {
                          },
                          page_count,
                          is_loading,
                          debug,
                          actions,
                          handlePaginationChange,
                          options,
                          data
                      }) {
    const rerender = useReducer(() => ({}), {})[1];

    const tableContainerRef = React.useRef(null);

    const [{pageIndex, pageSize}, setPagination] =
        useState({
            pageIndex: 0,
            pageSize: 10,
        })

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    );

    const defaultColumn = useMemo(
        () => ({
            // When using the useFlexLayout:
            minWidth: 60, // minWidth is only used as a limit for resizing
            width: 180, // width is used for both the flex-basis and flex-grow
            maxWidth: 320, // maxWidth is only used as a limit for resizing,
            Cell: CellRenderer,
            onRowClick
        }),
        []
    )

    const table = useReactTable({
        columns,
        data,


        getCoreRowModel: getCoreRowModel(),
        getRowId: row => row.id,
        onPaginationChange: setPagination,

        state: {
            pagination
        },

        manualPagination: true,
        pageCount: page_count ?? -1,
        autoResetPage: true,

        initialState: {
            pageIndex: 0,
            pageSize: page_size,
        },

        debugTable: debug
    });

    const {rows} = table.getRowModel();

    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        estimateSize: () => 33, //estimate row height for accurate scrollbar dragging
        getScrollElement: () => tableContainerRef.current,
        //measure dynamic row height, except in firefox because it measures table border height incorrectly
        measureElement:
            typeof window !== 'undefined' &&
            navigator.userAgent.indexOf('Firefox') === -1
                ? element => element?.getBoundingClientRect().height
                : undefined,
        overscan: 5,
    })

    const height = "600px";

    return <div
        className={`container`}
        ref={tableContainerRef}
        id="list-view-table-container"
        onScroll={e => {

        }}
        style={{
            overflow: 'auto', //our scrollable table container
            position: 'relative', //needed for sticky header
            height: height, //should be a fixed height
        }}
    >
        <table style={{display: 'grid'}} className={``}>
            <thead
                style={{
                    height: "33px",
                    display: 'grid',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                }}
            >
            {table.getHeaderGroups().map(headerGroup => (
                <tr
                    key={headerGroup.id}
                    style={{display: 'flex', height: '33px', width: '100%'}}
                >
                    {headerGroup.headers.map((header, header_index) => {
                            return <th className="th text-sm text-gray-600 font-medium h-10 leading-6">
                                {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                )}
                            </th>
                        }
                    )}
                </tr>
            ))}
            </thead>
            <tbody
                style={{
                    display: 'grid',
                    height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
                    position: 'relative', //needed for absolute positioning of rows
                }}
            >
            {rowVirtualizer.getVirtualItems().map(virtualRow => {
                const row = rows[virtualRow.index];
                return (
                    <tr
                        data-index={virtualRow.index} //needed for dynamic row height measurement
                        ref={node => rowVirtualizer.measureElement(node)} //measure dynamic row height
                        key={row.id}
                        style={{
                            height: `33px`, //this should always be a `style` as it changes on scroll
                            display: 'flex',
                            position: 'absolute',
                            transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                            width: '100%',
                        }}
                    >
                        {row.getVisibleCells().map((cell, cell_index) => {
                            return (
                                <td className="td">
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </td>
                            )
                        })}
                    </tr>
                )
            })}

            </tbody>

        </table>
        <div className="border-r border-l border-b border-gray-200 py-2 px-3 rounded-b-lg">
            <div className="flex">
                <div className="flex-grow"></div>
                <div className='h-8 flex items-center space-x-2'>
                    <PaginationInfo total={total_count} current_page_count={data.length} per_page={page_size}
                                    pageIndex={pageIndex}/>
                    <Paginate handlePrev={() => table.previousPage()} handleNext={() => table.nextPage()}
                              can_next={has_more} can_prev={pageIndex > 0}/>
                </div>
            </div>
        </div>
    </div>
}

function PaginationInfo({total, per_page, current_page_count, pageIndex}) {
    let a;

    const base = pageIndex * per_page;

    if (pageIndex === 0 && current_page_count < per_page) {
        a = <div>
            Showing {(base) + 1} of {base + current_page_count}
        </div>
    } else if (current_page_count === 0) {
        a = <div>
            No more results
        </div>
    } else {
        a = <div>
            Showing {(base) + 1} to {base + current_page_count} {total ? `of ${total}` : ""}
        </div>
    }

    return <div className="text-sm text-gray-600">
        {a}
    </div>
}

export default function Table({
                                  columns = [
                                      {
                                          Header: 'Column 1',
                                          accessor: 'col1',
                                      },
                                      {
                                          Header: 'Column 2',
                                          accessor: 'col2',
                                      },
                                  ],
                                  actions = [],
                                  total_count = -1,
                                  onRowClick,
                                  loadData,
                                  transformData,
                                  query,
                                  can_paginate = false,
                                  per_page = 10
                              }) {
    const [{pageIndex, pageSize}, setPagination] = useState({pageIndex: 0, pageSize: per_page})
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [has_more, setHasMore] = useState(false);
    const current_page = useRef(0);
    const start_after = useRef(null);

    useEffect(function () {
        start_after.current = null;
        setPagination({
            pageSize: per_page,
            pageIndex: 0
        })
        getData();
    }, [query])

    useEffect(function () {
        getData();
    }, [pageIndex]);

    function getData() {
        setLoading(true);

        if (current_page.current === pageIndex) {
            //    return;
        }

        current_page.current = pageIndex;
        loadData(pageIndex, per_page, start_after.current, query)
            .then((resp) => {
                start_after.current = resp.last_ref;
                setData(resp.data);
                setLoading(false);
                setHasMore(resp.has_more);
            })
    }

    const transformed = transformData(data);

    const items = useMemo(
        () => transformed,
        [transformed]
    );

    const cols = React.useMemo(
        () => [
            ...columns
        ],
        []
    );

    console.log("COLS",cols)
    console.log("ITEMS",items)

    return <Styles>
        <TableElement total_count={total_count} onRowClick={onRowClick} actions={actions} has_more={has_more}
                      page_size={per_page} debug
                      is_loading={loading} handlePaginationChange={(a) => {
            setPagination({pageSize, pageIndex: a})
        }} pageIndex={pageIndex} pageSize={pageSize} options={{
            manualPagination: can_paginate
        }} columns={cols} data={items}/>
    </Styles>
};