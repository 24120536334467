import React from 'react';
import {SectionHeader} from "../../../../m3/_legacy_components/entity-grid";
import {TextAction} from "../../../../routes/auth/sign-in";
import NavBuilder from "../../../../m3/_legacy_components/navbuilder";
import {useCommunity} from "../../../../config/community";
import {authFetch} from "../../../../../api/network";


export async function quickPinItemToSidebar(community, section_index = 0, payload) {
    let current_ss = [...community.sidebar_sections || []];

    const {url = "", color = "", image = "", emoji = "", name = "", type = ""} = payload;

    const id = Math.random().toString(36).substr(2, 9);

    current_ss[section_index].items.push({
        id, url, color, image, emoji, name, type
    })

    const res = () => {
        community.updateSidebarSections();
    }

    authFetch('/communities/update-sidebar-sections', res, res, "POST", {
        payload: {
            sidebar_sections: JSON.stringify(current_ss),
            community_uid: community.uid,
            member_id: community.member_id
        }
    })
}

export function SidebarSectionEditor({}) {
    const community = useCommunity();
    const [secs, setSecs] = React.useState(community.sidebar_sections || []);
    const [changed, setChanged] = React.useState(false);

    function saveChanges() {
        // communities/update-sidebar-sections

        setChanged(false);
        const res = () => {
            community.updateSidebarSections();
        }

        authFetch('/communities/update-sidebar-sections', res, res, "POST", {
            payload: {
                sidebar_sections: JSON.stringify(secs),
                community_uid: community.uid,
                member_id: community.member_id
            }
        })
    }

    // enable adding up to 3 custom sections
    // enable editing the name and type of each section

    // add one more section because of default spaces section
    const can_add = secs.length < 4;

    return <div>
        <SectionHeader actions={changed && <TextAction text="Apply Changes" onClick={saveChanges}/>} size="text-lg">Custom
            Sections</SectionHeader>
        <div className="pt-2">
            <NavBuilder onUpdate={ns => {
                setSecs(ns);
                setChanged(true);
            }} community_id={community.id} init={secs} can_add_section={can_add}/>
        </div>
    </div>
}