import React, {useState} from 'react';
import {useCommunity} from "../../../config/community";
import {useToasts} from "../../../config/toasts";
import Button from "../../../m3/_legacy_components/button";
import Field from "../../../m3/_legacy_components/form/field";
import {useNavigate} from "react-router-dom";
import {ModalBody, ModalFooter, ModalHeader} from "../../../m3/_legacy_components/preview-overlay";
import {PrettyCheckbox} from "../../../m3/_legacy_components/toggle";
import {DateFormat, DateTimeTimezoneSelector} from "./components/datetime-selector";
import {api_createEvent, api_deleteEvent, api_updateEvent} from "../../../../api/events";
import dayjs from "dayjs";
import {logEngagementEvent} from "../../../config/firebase-setup";

function prepareEventPayload(raw, community) {
    return {
        member_id: community.member_id,
        community_uid: community.uid,


        name: raw.name,
        description: raw.description,
        group_id: raw.group_id,

        all_day: raw.all_day,

        start: raw.start,
        start_datetime: raw.start_datetime,

        end: raw.end,
        end_datetime: raw.end_datetime,

        virtual_link: raw.virtual_link || "",

        conference_data: {},

        event_id: raw.event_id || "",

        location: raw.location || "",
        timezone: raw.timezone || ""
    }
}

function convertToIso(string) {

    // input utils is YYYY-MM-DD HH:mm:ss

    // output is to iso date

    const day = dayjs(string);

    return day.toISOString();
}

export function EditEventWrapper({onClose, group_id = "", debug = true, data = {}}) {

    return <AddEvent is_editing onClose={onClose} _event={data || {}} group_id={group_id}/>;
}

function convertIsoToDateTime(iso) {
    // input utils is YYYY-MM-DDTHH:mm:ss.sssZ
    // output utils is YYYY-MM-DD HH:mm:ss

    const date_obj = new Date(iso);
    const year = date_obj.getFullYear();
    const month = date_obj.getMonth();
    const day = date_obj.getDate();
    const hour = date_obj.getHours();
    const minute = date_obj.getMinutes();
    const second = date_obj.getSeconds();

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}


function getTimeDifferenceInMinutes(first, second) {
    // todo check
    const diff = dayjs.duration(second - first);

    return diff.asMinutes();
}

export function AddEvent({onClose, is_editing, _event = {}, group_id = "", debug = false}) {
    const toasts = useToasts();
    const community = useCommunity();
    const [create_another, setCreateAnother] = useState(false);
    const [state, setState] = useState("");
    const navigate = useNavigate();
    const [name, setName] = useState(_event.summary || "");
    const [description, setDescription] = useState(_event.description || "");
    const [space_id, setSpaceId] = useState(_event.group_id || group_id);
    const [location, setLocation] = useState(_event.location || "");
    const [address, setAddress] = useState(_event.address || "");
    const [location_place_id, setLocationId] = useState(_event.location_place_id || "");
    const [start_date, setStartDate] = useState(_event.start || "");
    const [end_date, setEndDate] = useState(_event.end || "");
    const [start_time, setStartTime] = useState(_event.start_datetime ? _event.start_datetime : "");
    const [end_time, setEndTime] = useState(_event.end_datetime ? _event.end_datetime : "");
    const [timezone, setTimezone] = useState(_event.timezone || "America/New_York");
    const [all_day, setAllDay] = useState(_event.all_day || false);
    const [is_public, setIsPublic] = useState(_event.is_public || false);

    console.log("ADD EVENT",_event)

    const can_create = name.length > 1 && !!space_id && !!start_date && !!end_date && !!start_time && !!end_time && !!timezone;

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    function handleDateTimeTimezoneUpdate(payload) {
        setStartDate(payload.start_date);
        setEndDate(payload.end_date);
        setStartTime(payload.start_time);
        setEndTime(payload.end_time);
        setTimezone(payload.timezone);
        setAllDay(payload.all_day);
    }

    function handleDelete() {
        setState("loading");
        api_deleteEvent({
            community_uid: community.uid,
            member_id: community.member_id,
            event_id: _event.id,
        })
            .then((response) => {
                setState("");
                console.log("RESPONSE", response);
                if (!response.ok || response?.ok === 'no') {
                    console.error("ERROR", response);
                    toasts.addToast({text: "something went wrong", intent: "danger"})
                } else if (response?.data?.id) {
                    toasts.addToast({text: "Event deleted", intent: "success"})
                    navigate(`/c/${community.domain}/calendar`)
                }
            })
    }

    function handleCreate() {
        const pl = {
            name,
            description,
            group_id: space_id,
            location,
            location_place_id,
            start: start_date,
            end: end_date,
            start_datetime: convertToIso(start_time),
            end_datetime: convertToIso(end_time),
            all_day,
            timezone,
            is_public,
            event_id: _event?.id||""
        };
        const payload = prepareEventPayload(pl, community);
        setState("loading");

        if (is_editing) {
            console.log("PAYLOAD", payload);
            api_updateEvent(payload)
                .then((response) => {
                    setState("");
                    if (!response.ok || response?.ok === 'no') {
                        console.error("ERROR", response);
                        toasts.addToast({text: "something went wrong", intent: "danger"})
                    } else if (response?.data?.id) {
                        logEngagementEvent("create_event", {
                            content_id: response?.data?.id,
                        }, community.uid);
                        toasts.addToast({text: "Event updated", intent: "success"})
                        navigate(`/c/${community.domain}/event/${response.data.id}?ref=updated`)
                    }
                    handleClose();
                });
        } else {
            api_createEvent(payload)
                .then((response) => {
                    setState("");
                    if (!response.ok || response?.ok === 'no') {
                        console.error("ERROR", response);
                        toasts.addToast({text: "something went wrong", intent: "danger"})
                    } else if (response?.data?.id) {
                        toasts.addToast({text: "Event created", intent: "success"})
                        if (!create_another) {
                            navigate(`/c/${community.domain}/event/${response.data.id}?ref=created`)
                        } else {
                            setName("");

                            setLocation("");
                            setLocationId("");
                        }
                    }
                    handleClose();
                })
        }
    }

    const init_start_time = _event.start_datetime ? convertIsoToDateTime(_event.start_datetime) : null;
    const init_end_time = _event.end_datetime ? convertIsoToDateTime(_event.end_datetime) : null;
    const init_start_date = _event.start ? _event.start : dayjs().format(DateFormat);

    const init_duration = _event.start_datetime && _event.end_datetime ? getTimeDifferenceInMinutes(_event.start_datetime, _event.end_datetime) : 30;

    const title = is_editing ? "Edit Event" : "Create Event";
    const button_text = is_editing ? "Save" : "Create";

    return <div className="" style={{minHeight: '16rem'}}>
        <ModalHeader title={title} onClose={handleClose}/>
        <ModalBody>
            <div className="space-y-4">
                <Field autoFocus={!is_editing}
                       value={name} onChange={(fid, val) => setName(val)} label="Name"
                       placeholder={"Event name"}/>
                <div>
                    <DateTimeTimezoneSelector init_start_time={init_start_time} init_end_time={init_end_time}
                                              init_duration={init_duration} init_start_date={init_start_date}
                                              onUpdate={handleDateTimeTimezoneUpdate}/>
                </div>
                <div>
                    <Field type="location" value={location} onChange={(fid, val) => setLocation(val)} label="Location"
                           placeholder={"Add a location"}/>
                </div>
                <div>
                    <Field type="description" value={description} onChange={(fid, val) => setDescription(val)}
                           label="Description"
                           placeholder={"Description"}/>
                </div>
                <div>
                    <Field input_props={{community_uid: community.uid}} value={space_id} id="group"
                           onChange={(a, b) => {
                               setSpaceId(b);
                           }} type="group" label="Space"/>
                </div>
                {debug && <div>
                  <pre
                      className="text-xs bg-gray-200 font-medium text-gray-700 overflow-y-scroll text-left">{JSON.stringify({
                      name,
                      description,
                      space_id,
                      location,
                      address,
                      location_place_id,
                      start_date,
                      end_date,
                      start_time,
                      end_time,
                      timezone,
                      all_day,
                      is_public
                  }, undefined, 2)}</pre>
                </div>}
            </div>
        </ModalBody>
        <ModalFooter right={
            <Button loading={state === "loading"} intent="primary" disabled={!can_create} text={button_text}
                    onClick={handleCreate}/>
        } left={<div>
            {!is_editing && <PrettyCheckbox active={create_another} onClick={() => setCreateAnother(!create_another)}
                                            label="Create another event"/>}
            {is_editing && <Button intent="danger" text="Delete" onClick={() => {
// open window confirm first
                if (window.confirm("Are you sure you want to delete this event?")) {
                    handleDelete();
                }
            }}/>}
        </div>}/>
    </div>
}