import {authFetch} from "../network";

export function composer_handleSendEmail(payload, {community}, res) {

    let final_payload = {
        ...payload,
        app: 'orange',
        add_activity: true
    };

    authFetch("/composer/send-email", res, res, "POST", {payload: final_payload});
}

export function composer_handleSendCustom(payload, {community}, res) {

    let final_payload = {
        ...payload,
        app: 'orange',
        add_activity: true
    };

    authFetch("/composer/send-custom", res, res, "POST", {payload: final_payload});
}

export function composer_handleSendInvite(payload, {community}, res) {

    const final_payload = {
        ...payload,
        user_uid: community.member.user_uid,
        member_id: community.member_id,
        community_uid: community.uid,
        ids: payload.to_data.map(a => {
            let o = {};
            o.id = a.id ? a.id : a.value;
            return o;
        }),
        flow: 'personalized',
        app: 'orange'
    }

    authFetch("/members/bulk/invite", res, res, "POST", {payload: final_payload});
}

export function composer_handleSendPost(payload, {community}, res) {
    /*
    analytics().logEvent('send_message', {
        event_id: 'send_message',
        content_type: 'email',
        community_uid: community.uid,
        content_id: community.member.user_uid
    });

    logEngagement("send_post", {
        community_uid: community.uid,
        member_id: community.member_id,
        user_uid: community.member.user_uid
    });

     */

    let final_payload = {
        ...payload,
        app: 'orange'
    }

    authFetch("/posts/new-post", res, res, "POST", {payload: final_payload});
}