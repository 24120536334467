import React, {useEffect} from "react"
import './styles.css';
import ReactDOM from "react-dom";

export default function SimplePortal({
                                         open = true,
                                         onClose = () => {
                                         },
                                         children,
                                         escape_to_close,
                                         center,
                                         background_opacity,
                                         enable_background = true,
                                         background_color = "rgba(0,0,0,0.75)"
                                     }) {
    let c;

    let bg_color;

    if (window.innerWidth < 768) {
        bg_color = "#ffffff";
    } else {
        bg_color = background_color;
    }

    function handleEscapeClose(e) {
        if (e.key === "Escape") {
            onClose();
        }
    }

    useEffect(function () {

        if (!open) {
            document.body.classList.remove('modal-open');
        }
    }, [open])

    useEffect(function () {
        if (open) {
            document.body.classList.add('modal-open');
        }
        window.addEventListener('keyup', handleEscapeClose);
        return () => {
            document.body.classList.remove('modal-open');
            window.removeEventListener('keyup', handleEscapeClose)
        }
    }, [])

    if (enable_background) {
        c = <div className={`fixed top-0 left-0 right-0 h-full z-50 overflow-y-auto`}
                 style={{backgroundColor: bg_color}}>
            {children}
        </div>;
    } else {
        c = children;
    }

    if (!open) {
        return null;
    }

    return ReactDOM.createPortal(
        c,
        document.body
    );
}