import React, {useEffect, useRef, useState} from "react";
import {CheckIcon, ChevronDownIcon, Bars3Icon, XMarkIcon} from "@heroicons/react/20/solid";
import {LocalSearchBar} from "../local-search-bar";
import Button from "../button";
import SimpleTooltip from "../tooltip";

const mock_options = [
    {
        label: 'starts with',
        id: 'starts_with'
    },
    {
        label: 'contains',
        id: 'contains'
    },
    {
        label: 'ends with',
        id: 'ends_with'
    }
];

function SimpleCheckbox({selected}) {
    const colors = selected ? "border-selection bg-selection" : "border-gray-300";
    return <div className={`h-5 w-5 rounded-md border-2 ${colors}`}>
        {selected && <div className="w-4 h-4 text-white">
            <CheckIcon/>
        </div>}
    </div>
}

function Option({add = false, label, width, icon, multi, meta, size, selected, emoji, data, id, onSelect}) {

    let l = label||"";

    return <div onClick={() => onSelect(id, data)}
                className={`py-1 flex text-${size} -mx-3 px-3 ${selected ? "bg-gray-200" : "hover:bg-gray-200"} cursor-pointer transition-colors`}>
        <div className={"py-px flex-grow flex space-x-2.5"}>
            {emoji && <div className="flex flex-none items-center justify-center">
                <div className="text-xl leading-5">
                    {emoji}
                </div>
            </div>}
            {!emoji && icon && <div className="flex flex-none -m-px items-center justify-center">
                <div style={{width: '1.1rem', height: '1.1rem'}} className="text-gray-500 leading-5">

                </div>
            </div>}
            <div style={{width}}
                 className={`${selected ? "font-semibold text-gray-800" : "font-medium text-gray-600 hover:text-gray-800"} flex-grow truncate white-space-nowrap`}>
                {add ? <span>Add "<strong>{l}</strong>"</span> : l}
            </div>
            {meta && <div className={`text-gray-400 leading-6 flex-none min-w-12 pr-2 text-right text-sm`}>
                {meta}
            </div>}
            {multi && <div className="flex-none flex w-6 items-center">
                <SimpleCheckbox selected={selected}/>
            </div>}
            {!selected && !multi && <div className="w-6 flex-none">

            </div>}
            {!multi && selected && <div className="flex-none w-6 flex items-center">
                <div className="w-5 text-gray-600 h-5">
                    <CheckIcon/>
                </div>
            </div>}
        </div>

    </div>
}

function doTextSearch(q, arr) {
    if (!q) {
        return arr;
    }
    const ua = arr.filter(a => !!a.label);
    return ua.filter(a => a.label.toLowerCase().indexOf(q) !== -1);
}

function filterOptions(q, arr, ac, c_obj) {
    const results = doTextSearch(q, arr);
    // no categories for this one
    if (!c_obj) {
        return results;
    }
    // has categories but none active, add section headers
    if (!ac && q) {
        return results;
    } else if (!ac) {
        let final = [];
        const category_ids = Object.keys(c_obj);
        for (let i = 0; i < category_ids.length; i++) {
            const cid = category_ids[i];
            final.push({
                id: `cat-${cid}`,
                section_title: c_obj[cid].label
            });
            const items_in_category = [...arr.filter(a => {
                return a.categories.includes(cid);
            }).map(it => {
                return {
                    ...it,
                    key: `cat-${cid}-${it.id}`,
                    id: it.id
                }
            })];
            final = final.concat(items_in_category);
        }
        return final;
    }
    return results.filter(a => {
        return a.categories.includes(ac);
    })
}

function isSelected(selected, multi, id) {
    if (multi) {
        return selected.includes(id);
    } else {
        return selected === id;
    }
}

function getInitial(init, options, multi) {
    if (init !== null) {
        return init;
    }
    if (multi) {
        return [];
    }
    return options[0].id;
}

function getText(selected, options, multi, emoji, placeholder) {
    // todo show icon and text
    if (!multi && selected !== null) {
        const selected_item = options.filter(a => a.id === selected)[0];
        if (!selected_item) {
            return placeholder
        } else {
            if (emoji) {
                return selected_item.emoji;
            } else {
                // todo add icon
                return <div className="flex space-x-2 items-center">
                    {selected_item.icon && <div className="w-5 h-5 -m-0.5 text-gray-400"></div>}
                    <div>{selected_item.label}</div>
                </div>;
            }
        }
    } else if (multi && selected.length === 0) {
        return placeholder;
    } else if (multi && selected.length === 1) {
        const selected_item = options.filter(a => a.id === selected[0])[0];
        if (!selected_item) {
            return placeholder
        } else {
            if (emoji) {
                return selected_item.emoji;
            } else {
                return <div className="flex space-x-1 items-center">
                    {selected_item.icon && <div className="w-4 h-4 text-gray-400">{selected_item.icon}</div>}
                    <div>{selected_item.label}</div>
                </div>;
            }
        }
    } else if (multi && selected.length > 0) {
        return emoji ? `${selected.length}` : `${selected.length} selected`;
    } else {
        return placeholder
    }
}

function getContentPopupStyle(inline, multi) {
    if (inline && multi) {
        return {minWidth: '32px', width: '400px', itemWidth: '220px', maxWidth: '400px'}
    } else if (inline) {
        return {minWidth: '32px', maxWidth: '340px', itemWidth: '198px', width: '340px'}
    } else if (multi) {
        return {minWidth: '200px', itemWidth: '220px', width: '360px'}
    } else {
        return {minWidth: '200px', itemWidth: 'auto', width: 'auto'}
    }

}

function getContainerStyle(inline, multi, emoji) {
    if (inline && emoji) {
        return {minWidth: '32px'}
    }
    if (inline) {
        return {minWidth: '64px'}
    } else if (multi) {
        return {minWidth: '96px'}
    } else {
        return {minWidth: '96px'}
    }

}

function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

function CategoryHeaderItem({
                                children, onClick = () => {
    }, active
                            }) {
    return <div onClick={onClick}
                className={`${active ? "text-selection border-selection" : "text-gray-400 border-gray-200"} w-10 border-b-2 flex-none cursor-pointer hover:bg-gray-100 transition-colors flex items-center justify-center`}>
        {children}
    </div>
}

function getTextColor(layout, selected, multi) {
    if (layout === 'meta') {
        return "text-gray-800"
    } else if (layout === 'minimal') {
        if ((multi && selected.length === 0) || !selected) {
            return 'text-gray-400'
        } else {
            return 'text-gray-600'
        }
    } else {
        return "text-gray-600"
    }
}

function getTrigger(selected, inline, minimal, layout, emoji, size, aselected, placeholder, multi, options) {
    const text_color = getTextColor(layout, selected, multi);
    return <div className={`flex ${inline ? "space-x-px" : "space-x-1"}`}>
        <div
            className={`${emoji ? "text-xl leading-6" : `text-${size}`} flex items-center flex-grow font-medium ${text_color}`}>
            {getText(aselected, options, multi, emoji, placeholder)}
        </div>
        <div className={`flex items-center flex-none ${text_color}`}>
            <div className="w-4 h-4">
                <ChevronDownIcon/>
            </div>
        </div>
    </div>
}

export function RulesInlineSelect({
                                      add_option = "",
                                      custom_trigger = null,
                                      onAddOption = () => {
                                      },
                                      placement = "bottom-start",
                                      empty_state,
                                      allow_deselect,
                                      loading = false,
                                      minimal = false,
                                      can_create = false,
                                      create_meta,
                                      usePortal = false,
                                      categories = null, highlight = false,
                                      layout = 'standard', placeholder = "-", size = "base",
                                      bg = 'white',
                                      inline = false, emoji = false,
                                      selected = '', searchable = false, multi = false, onSelect = () => {
    }, options = mock_options, max = 10
                                  }) {
    const [query, setQuery] = useState('');
    const [create_value, setCreateValue] = useState('');
    const [active_category, setActiveCategory] = useState('');
    const [open, setOpen] = useState(false);
    const [aselected, setSelected] = useState(getInitial(selected, options, multi));
    // avoid first render
    const isFirstRun = useRef(true);
    const value_ref = useRef();

    useEffect(function () {
        if (multi && value_ref && value_ref.current && arraysEqual(value_ref.current, selected)) {
            return;
        }
        setSelected(selected);
    }, [selected, multi]);

    useEffect(function () {

        if (multi && typeof aselected === 'string') {
            if (aselected) {
                setSelected([aselected]);
            } else {
                setSelected([]);
            }
        } else if (!multi && (typeof aselected !== 'string' && aselected !== true && aselected !== false)) {
            setSelected('');
        }
    }, [multi, aselected]);

    useEffect(function () {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        if (!multi) {
            setOpen(false);
        }
        if (typeof aselected !== 'string') {
            onSelect(aselected, null);
        } else {
            onSelect(aselected, options.filter(a => a.id === aselected)[0]);
        }
        value_ref.current = aselected;
    }, [aselected, multi]);

    function handleSelect(nid) {
        let cs = aselected;
        if (multi) {
            if (cs.includes(nid)) {
                // already in
                cs.splice(cs.findIndex(a => a === nid), 1);
            } else if (cs.length < max) {
                cs.push(nid);
            }
            setSelected([...cs]);
        } else {
            if (selected === nid && allow_deselect) {
                setSelected("");
            } else {
                setSelected(nid);
            }

        }
    }

    const filtered = filterOptions(query, options, active_category, categories);

    const content_style = getContentPopupStyle(inline, multi)

    /*

            {filtered.map(opt => {
                if (opt.divider) {
                    return <div className="" key={opt.id}>
                        <div className="border-b border-gray-200 pt-2 mb-2"></div>
                    </div>
                } else if (opt.section_title) {
                    return <div key={opt.id} className="pt-2 pb-0.5">
                        <div className="text-xs font-medium text-gray-500">
                            {opt.section_title}
                        </div>
                    </div>
                }
                return <Option size={size} width={content_style.itemWidth} multi={multi}
                               selected={isSelected(aselected, multi, opt.id)}
                               onSelect={handleSelect} key={opt.key ? opt.key : opt.id} {...opt} data={opt}/>
            })}

     */

    const content = <div className="bg-white rounded-md popup-shadow" style={content_style}>
        {categories && <div className="flex h-10 border-gray-200 overflow-x-auto">
            <CategoryHeaderItem onClick={() => setActiveCategory('')} active={active_category === ''}>
                <div className="w-5 h-5">
                    <Bars3Icon/>
                </div>

            </CategoryHeaderItem>
            {Object.entries(categories).map(([cat_id, cat_data]) => <CategoryHeaderItem
                onClick={() => setActiveCategory(cat_id)} active={active_category === cat_id} key={cat_id}>
                <div className="w-5 h-5">
                    {cat_data.icon}
                </div>
            </CategoryHeaderItem>)}
            <div className='flex-grow border-b-2 border-gray-200'/>
            {aselected && <CategoryHeaderItem onClick={() => handleSelect('')}>
                <div className="w-5 h-5">
                    <XMarkIcon/>
                </div>
            </CategoryHeaderItem>}
        </div>}
        {searchable && <div className="p-3 border-gray-200 border-b">
            <LocalSearchBar onChange={(v) => setQuery(v.toLowerCase())} autoFocus/>
        </div>}
        <div className="overflow-y-auto py-1.5 px-3" style={{maxHeight: '240px'}}>
            {filtered.length === 0 && !empty_state && <div className="p-2 text-gray-500 text-center">
                No results
            </div>}
            {filtered.map(opt => {
                if (opt.divider) {
                    return <div className="" key={opt.id}>
                        <div className="border-b border-gray-200 pt-2 mb-2"></div>
                    </div>
                } else if (opt.section_title) {
                    return <div key={opt.id} className="pt-2 pb-0.5">
                        <div className="text-xs font-medium text-gray-500">
                            {opt.section_title}
                        </div>
                    </div>
                }
                return <Option size={size} width={content_style.itemWidth} multi={multi}
                               selected={isSelected(aselected, multi, opt.id)}
                               onSelect={handleSelect} key={opt.key ? opt.key : opt.id} {...opt} data={opt}/>;
            })}

            {!query && add_option &&
                <Option add size={size} width={content_style.itemWidth} multi={multi} selected={false}
                        onSelect={onAddOption} label={add_option} id="add" data={{}}/>}
            {!query && empty_state && filtered.length === 0 && <div className="text-sm text-gray-600 p-4">{empty_state.placeholder}</div>}

        </div>


        {can_create && <div className="border-t flex space-x-1.5 pt-2 pb-2 border-gray-200 px-3">
            <input placeholder={create_meta.placeholder} value={create_value}
                   onChange={(e) => setCreateValue(e.target.value)}/>
            <div className="flex-none">
                <Button intent="secondary" loading={create_meta.loading} text={create_meta.create_text}
                        disabled={create_value.length === 0} onClick={() => {
                    const cb = () => {
                        setCreateValue('')
                    };
                    create_meta.handleCreate(create_value, cb);
                }}/>
            </div>

        </div>}
    </div>;
    // Option({label, width, icon, multi, meta, size, selected, emoji, data, id, onSelect})

    const trigger = custom_trigger ? custom_trigger : getTrigger(selected, inline, minimal, layout, emoji, size, aselected, placeholder, multi, options);

    return <SimpleTooltip usePortal placement="bottom-start" interactive text={content} simple trigger="click" onOpen={() => setOpen(true)} isOpen={open && !loading}
                          onClose={() => setOpen(false)} minimal
    >
        <div style={getContainerStyle(inline, multi, emoji)}
             className={custom_trigger?"":`inline-select ${layout === 'meta' ? "px-2" : inline ? "inline-flex pr-px pl-1 mx-1" : "mx-1 pr-1.5 pl-2"}   ${layout === 'minimal' || layout === 'meta' ? `border-${bg} hover:border-gray-200 bg-${bg} hover:bg-gray-200 ${highlight ? "border-selection" : ""}` : `border-gray-200 hover:border-gray-300 bg-gray-200 hover:bg-gray-300`} border-2  py-0.5 rounded-md -my-0.5  cursor-pointer  transition-colors`}
             onClick={(e) => {
                 e.stopPropagation();
                 setOpen(true)
             }}>
            {trigger}
        </div>
    </SimpleTooltip>;
}