import React from "react";
import {LayoutFull} from "../../../m3/_legacy_components/app-frame/layouts";
import {UnaverseSectionHeader} from "./unaverse";
import {buildTableConfig, SharedUsersTable} from "../shared/users-table";
import {useNavigate} from "react-router-dom";
import {collection, getDocs, limit, orderBy, query, startAfter, where} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";

const info = {
    columns: [
        "name",
        "last_sign_in",
    ],
    spec: {
        "id": {
            label: "ID",
            type: "id",
            required: true
        },
        "name": {
            label: "Name",
            type: "title-subtitle-image",
            meta: {
                title: "name",
                subtitle: "account_email",
                image: "profile_picture",
                image_color: "profile_picture_color",
            },
            required: true
        },
        "last_sign_in": {
            label: "Last Sign In",
            type: "timestamp",
            required: true
        },
        "handle": {
            label: "Handle",
            type: "handle",
            required: true
        },
        "account_email": {
            label: "Account Email",
            type: "string",
            required: true
        },
    }
};

async function loadUsers(page, pp = 10, start_after) {
    const usersCol = collection(db, 'users');

    let q;

    if (start_after && page > 0) {
        q = query(usersCol, where('beta_program', '==', true), orderBy("last_sign_in"), startAfter(start_after), limit(pp));
    } else {
        q = query(usersCol, where('beta_program', '==', true), orderBy("last_sign_in"), limit(pp));
    }

    const snap = await getDocs(q);

    return snap.docs;
}

export function ManageBetaProgram() {
    const navigate = useNavigate();

    function onEditMember(member) {
        if(member&&member.id) {
            navigate(`/manage/user/${member.id}`)
        }
    }

    const table_config = buildTableConfig(info, '', onEditMember, navigate, loadUsers);
    const subtitle = <span>
        Users with beta access - Rollout doc: <a className="link" href="https://docs.google.com/document/d/1seY4Y-zC7hbVacXxjTFOwarvpnXAc3GHksiFB2YSn_4/edit" target="_blank" rel="noreferrer">here</a>.
    </span>;
    return <LayoutFull>
        <div className="space-y-4">
            <UnaverseSectionHeader
                title={"Beta Users"}
                subtitle={subtitle}
            />

            <SharedUsersTable table_config={table_config} query={''}/>
        </div>
    </LayoutFull>
}