import {authFetch} from "../../../../api/network";

export async function sendQPUEmail() {
    /*
    setLoading('qpu');
    const res = () => {
        setTimeout(() => {
            setLoading('');
            global.addToast({text: `Quick Profile Update Email sent`, intent: 'success'});
        }, 1000);
    };

    const payload = {
        requesting_member_id: community.member.user_uid,
        community_uid: community.uid,
        member_id: community.member_id,
        id: member_id,
        user_uid: community.member.user_uid,
    };

    authFetch("/members/send-qpu-email", res, res, "POST", {payload})

     */
}

export async function sendMemberInvite() {

}

export async function deleteMember(community,member_id) {

    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        const payload = {
            member_id: community.member_id,
            id: member_id,
            user_uid: community.member.user_uid,
            community_uid: community.uid,
        };

        authFetch("/members/delete", res, res, "POST", {payload})
    })
}

export async function sendMemberResetPassword() {
    /*
    setLoading('_sending_invite');

    const prefill = {
        to: membersBuildInviteTo([member]),
        config: {},
        content: `Hi ${member.name}, we wanted to make sure you had everything you need to get logged into our membership platform.`,
        subject: `Your Access Details for ${community.data.name}`,
        type: 'access-details',
        template: 'standard'
    };

    community.openComposer(prefill)

     */
}

export async function archiveMember(community, member_id) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        const payload = {
            member_id: community.member_id,
            id: member_id,
            user_uid: community.member.user_uid,
            community_uid: community.uid,
        };

        authFetch("/members/archive", res, res, "POST", {payload})
    })
}

export async function unarchiveMember(community, member_id) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        const payload = {
            member_id: community.member_id,
            id: member_id,
            user_uid: community.member.user_uid,
            community_uid: community.uid,
        };

        authFetch("/members/unarchive", res, res, "POST", {payload})
    })
}