import React, {useRef, useState, useEffect} from "react"
import './styles.css';
import SimpleTooltip from "../tooltip";
import {useCommunity} from "../../../config/community";

const services = [
    {
        id: 'whatsapp',
        name: 'WhatsApp',
        onClick: () => {
        },
        image: '/images/logos/whatsapp.png'
    },
    {
        id: 'imessage',
        scaled: true,
        name: 'iMessage',
        onClick: () => {
        },
        image: '/images/logos/imessage.png'
    },
    {
        id: 'telegram',
        scaled: true,
        name: 'Telegram',
        onClick: () => {
        },
        image: '/images/logos/telegram.png'
    },
    {
        id: 'signal',
        scaled: true,
        name: 'Signal',
        onClick: () => {
        },
        image: '/images/logos/signal.png'
    },
    {
        id: 'facebook-messenger',
        scaled: true,
        name: 'Facebook Messenger',
        onClick: () => {
        },
        image: '/images/logos/facebook-messenger.png'
    },
    {
        id: 'wechat',
        name: 'WeChat',
        onClick: () => {
        },
        image: '/images/logos/wechat.png'
    }
];

function Service({
                     can_edit = false,
                     active = false,
                     id,
                     scaled = false,
                     name = "",
                     image,
                     onSelect = () => {
                     },
                     onClick = () => {
                     }
                 }) {
    const cont = active ? "" : "hover:opacity-100 opacity-70";
    const img = active ? "" : "grayscale group-hover:grayscale-0";

    if (!can_edit) {
        return <div className={`h-6 w-6 flex items-center justify-center group border-white p-0.5`}>
            <div className={`${scaled ? "h-4 w-4" : "h-5 w-5"} bg-center filter bg-cover`}
                 style={{backgroundImage: `url('${image}')`}}/>
        </div>
    }

    return <SimpleTooltip text={name}>
        <div onClick={() => {
            if (can_edit) {
                onSelect()
            } else {
                onClick()
            }

        }}
             className={`h-6 w-6 flex items-center justify-center group border-1 border-white p-0.5 rounded-md hover:bg-gray-200 ${can_edit ? "cursor-pointer" : ""} hover:border-gray-200 transition-colors ${cont}`}>
            <div className={`${scaled ? "h-4 w-4" : "h-5 w-5"} bg-center filter bg-cover ${img}`}
                 style={{backgroundImage: `url('${image}')`}}>

            </div>
        </div>
    </SimpleTooltip>;
}

export default function FindMeOn({
                                     my_profile = false,
                                     can_edit = false,
                                     field = '',
                                     updateMember = () => {
                                     },
                                     id = '',
                                     active = []
                                 }) {
    const community = useCommunity();
    const [changes_made, setChangesMade] = useState(false);
    const [selected, setSelected] = useState(active);
    const stateRef = useRef();
    const selectedRef = useRef();
    stateRef.current = changes_made;
    selectedRef.current = selected;

    useEffect(function () {
        stateRef.current = changes_made;
    }, [changes_made]);


    useEffect(function () {
        return () => {
            handleSave();
        }
    }, []);

    function handleSave() {
        if (stateRef.current && id) {
            setChangesMade(false);
            stateRef.current = false;
            const payload = {
                community_uid: community.id,
                member_id: community.member_id,
                user_uid: community.member.user_uid,
                id,
                field,
                value: selectedRef.current.join(",")
            };

            const res = async () => {
            };
            //   authFetch("/members/update-phone-service", res, res, "POST", {payload});
        }
    }

    function handleSelect(s) {
        if (selected.indexOf(s.id) !== -1) {
            let ns = [...selected];
            ns.splice(ns.indexOf(s.id), 1);
            setSelected(ns);
            setChangesMade(true);
            updateMember('phone_services', field, ns.join(","));
            setTimeout(() => {
                setTimeout(() => handleSave(), 4000);
            }, 100);
        } else {
            let ns = [...selected];
            ns.push(s.id);
            setSelected(ns);
            setChangesMade(true);
            updateMember('phone_services', field, ns.join(","));
            setTimeout(() => handleSave(), 4000);
        }
    }

    if (!active.length && (!can_edit)) {
        return null;
    }

    return <div className="flex items-center space-x-2">
        <div className="text-xs flex-none text-gray-500 hidden sm:block">
            Find me on:
        </div>
        <div className="flex space-x-0.5">
            {services.map(s => {
                const act = selected.indexOf(s.id) !== -1;
                // can_edit
                if (!can_edit && !act) {
                    return null;
                }
                return <Service key={s.id} can_edit={can_edit} active={selected.indexOf(s.id) !== -1}
                                onSelect={() => handleSelect(s)} {...s} />
            })}
        </div>
    </div>
};