import React from "react";

/*
* This is the Heading atom
 */

const _props = {
    children: {
        type: "string",
        required: true
    },
    size: {
        size: "string",
        required: false,
        default: "h1",
        options: ["h1", "h2", "h3", "h4", "h5", "h6"]
    },
    skeleton: {
        type: "boolean",
        required: false,
        default: false
    }
};

export const skeleton_height_map = {
    "h1": "h-10",
    "h2": "h-9",
    "h3": "h-8",
    "h4": "h-7",
    "h5": "h-7",
    "h6": "h-6"
};

export const skeleton_width_map = {
    "h1": "w-3/4",
    "h2": "w-2/3",
    "h3": "w-2/3",
    "h4": "w-1/3",
    "h5": "w-1/4",
    "h6": "w-1/5"
};

const heading_size_map = {
    "h1": "text-4xl",
    "h2": "text-3xl",
    "h3": "text-2xl",
    "h4": "text-xl",
    "h5": "text-lg",
    "h6": "text-base"
};

const heading_size_weight = {
  "h1": "font-bold",
    "h2": "font-bold",
    "h3": "font-bold",
    "h4": "font-semibold",
    "h5": "font-semibold",
    "h6": "font-semibold"
};

export default function M3_A_Heading({
                                         children,
                                         skeleton,
                                         color,
    sx,
                                         size = "h1"
                                     }) {
    let classes = "", styles = {};
    const size_classname = heading_size_map[size];

    classes += ` ${size_classname}`;

    classes += ` ${heading_size_weight[size]}`;

    if (color) {
        classes += ` ${color}`;
    }

    if(size === "h1" || size === "h2") {
        styles.lineHeight = "normal";
    }

    if(sx) {
        styles = {...styles, ...sx};
    }

    const renderChildren = () => {
        if (skeleton) {
            return <div className={`${skeleton_height_map[size]} py-1`}>
                <div className={`bg-gray-400 rounded-xl h-full ${skeleton_width_map[size]}`}/>
            </div>;
        } else {
            return children;
        }
    };

    return React.createElement(size, {
        className: classes,
        style: styles
    }, renderChildren());
}