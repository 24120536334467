import {authFetch} from "../network";
import {chunk, getManyDocuments} from "../../app/config/community";
import {documentId} from "firebase/firestore";

export async function createList(payload) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        authFetch("/", res, res, "POST", {payload});
    })
}

export async function updateList(payload) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        authFetch("/", res, res, "POST", {payload});
    })
}

export async function deleteCollection(payload) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        authFetch("/", res, res, "POST", {payload});
    })
}

export async function getManyLists(cid,fids) {
    let p = [];
    const chunks = chunk(fids,10);
    chunks.forEach(a=>{
        p.push(getManyDocuments('community_entities', cid, 'filters', documentId(), a));
    })
    return await Promise.all(p).then(arrs=>{
        return arrs.flat();
    });
}