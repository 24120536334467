import React from 'react';
import {ModalBody, ModalContentBlock, ModalFooter, ModalHeader} from "../../../m3/_legacy_components/preview-overlay";
import {isValidHttpUrl} from "../../../m3/_legacy_components/profile-field-renderer/value-pipeline";
import {FieldInput, FieldLabel} from "../../../m3/_legacy_components/form/field";
import SimplePortal from "../../../m3/_legacy_components/simple-portal";
import Modal from "../../../m3/_legacy_components/modal";
import Button from "../../../m3/_legacy_components/button";

function getFinalLinkUrl(sl) {
    if (!sl) {
        return sl;
    }
    if (sl.includes('.') && !sl.includes('http') && !sl.includes('//')) {
        sl = `https://${sl}`;
    }
    return sl;
}

export function AddLinkModal({
                                 meta, handleMetaChange, onAdd = () => {
    }, onClose = () => {
    }
                             }) {
    const final = getFinalLinkUrl(meta.selection_link);
    const disabled = !isValidHttpUrl(final) || !final;
    console.log("AddLinkModal",meta)
    const body = <div className="space-y-4">
        <FieldLabel label="Link URL">
            <FieldInput onEnter={(e) => {
                if (e) {
                    e.preventDefault();
                    e.stopPropagation();
                }
                if (!disabled) {
                    onAdd();
                }
            }} placeholder="Website URL" autoFocus onChange={(a, v) => {
                handleMetaChange({
                    selection_link: v
                });
            }} value={meta?.selection_link||""}/>
        </FieldLabel>
        <FieldLabel label="Link Text">
            <FieldInput placeholder="" onChange={(v) => {
                handleMetaChange({
                    selection_text: v
                });
            }} value={meta?.selection_text||""}/>
        </FieldLabel>
    </div>;

    const buttons = <div>
        <Button onClick={() => {
            onAdd();
        }} text="Add Link" intent="success" disabled={disabled}/>
    </div>;

    const left_button = <div>

    </div>;

    return <SimplePortal>
        <Modal center size="small">
            <ModalHeader title={"Add Link"} onClose={() => {
                if (onClose) {
                    onClose();
                }
            }}/>
            <ModalBody>
                <ModalContentBlock>
                {body}
                </ModalContentBlock>
            </ModalBody>
            <ModalFooter right={buttons} left={left_button}/>
        </Modal>
    </SimplePortal>
}