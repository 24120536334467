import React, {useState} from "react"
import './styles.css';
import {
    CalendarIcon,
    EyeSlashIcon,
    GlobeAltIcon,
    LinkIcon,
    LockClosedIcon,
    UsersIcon
} from "@heroicons/react/24/outline";

import {useNavigate} from "react-router-dom";
import {EntityTags, UniversalIcon} from "../entity-card";
import {cleanSocialForProfile, cleanWebsiteForProfile} from "../profile-field-renderer/value-pipeline";
import Button from "../button";
import TwitterIcon from "../../icons/_legacy/social/Twitter";
import FacebookIcon from "../../icons/_legacy/social/Facebook";
import LinkedInIcon from "../../icons/_legacy/social/LinkedIn";
import RedditIcon from "../../icons/_legacy/social/Reddit";
import GithubIcon from "../../icons/_legacy/social/Github";
import InstagramIcon from "../../icons/_legacy/social/Instagram";
import EthIcon from "../../icons/_legacy/social/Eth";
import SkypeIcon from "../../icons/_legacy/social/Skype";
import {copyTextToClipboard} from "../../utilities/copy-to-clipboard";
import M3_A_Button from "../../atoms/button";
import M3_A_IconButton from "../../atoms/icon-button";
import {m3_icon_map} from "../../icons/icon-map";

function Stat({count, onClick, label}) {
    return <div onClick={() => {
        if (onClick) {
            onClick();
        }
    }} className={`space-x-1 text-sm group ${onClick ? "cursor-pointer" : ""}`}>
        <span className="text-gray-800 font-semibold">{count}</span>
        <span className={`text-gray-600 ${onClick ? "group-hover:underline" : ""}`}>{label}</span>
    </div>
}

export function UniversalStats({stats = []}) {
    return <div
        className={`whitespace-nowrap flex gap-3 items-baseline`}>
        {stats.map((stat, k) => {
            return <Stat key={k} {...stat} />
        })}
    </div>
}

function Cover({setExpanded, features, color, cover_style, loading, image, expanded}) {
    let style = {}, is_empty = !image && !loading;
    if (!loading) {
        if (image) {
            style = {backgroundImage: `url(${image})`};
        } else {
            style = {};
        }
    }

    if (cover_style) {
        style = {...cover_style}
    }

   // const as = image || loading ? "aspect-[5/2] sm:aspect-[3/1]" : "min-h-[10rem] sm:min-h-0 sm:aspect-[5/1]";
    const as = "aspect-[3/1]";

    const bg_color = loading ? "bg-gray-300" : !image ? "bg-gray-100" : "bg-gray-50";

    return <div style={{...style}} onClick={(e) => {
        e.stopPropagation();
        setExpanded(!expanded);
    }}
                className={`${bg_color} w-full group relative bg-center bg-cover ${as}`}>
        {features.edit_cover &&
            <div className="absolute space-x-2 group-hover:opacity-100 transition-opacity opacity-0 bottom-2 right-4">
                <M3_A_Button onClick={(e) => {
                    if (e) {
                        e.stopPropagation();
                    }
                    if (features.onOpenEditCoverPhoto) {
                        features.onOpenEditCoverPhoto();
                    }
                }} variant="dark" size="base" text={`${is_empty?"Add":"Change"} Cover`}/>
            </div>}
    </div>
}

function Header({
                    title,
                    onTitleClick,
                    bottom_padding,
                    onSubtitleClick,
                    pre_suffix,
                    subtitle_suffix,
                    loading,
                    suffix,
                    compact,
                    subtitle
                }) {

    return <>
        <div className="flex items-center space-x-2 pt-1">
            <div onClick={() => {
                if (onTitleClick) {
                    onTitleClick();
                }
            }}
                 className={`${compact ? "text-base" : "text-2xl"} ${onTitleClick ? "cursor-pointer hover:underline" : ""} tracking-tight leading-6 text-gray-800 font-bold`}>
                {loading ? <span className="h-5 inline-block w-64 rounded-md bg-gray-400"/> : title}
            </div>
            {pre_suffix}
            {suffix}
        </div>

        {subtitle &&
            <div
                className={`${compact ? "-mt-px text-xs" : "mt-1 text-[0.9rem]"} leading-5 flex items-baseline space-x-2 text-gray-700 font-normal`}>
                {loading ? <div className="h-3 inline-block w-28 rounded-md bg-gray-300"/> :
                    <div className={`${!!onSubtitleClick ? "cursor-pointer hover:underline" : ""}`} onClick={() => {
                        if (onSubtitleClick) {
                            onSubtitleClick()
                        }
                    }}>{subtitle}</div>}
                {subtitle_suffix}
            </div>}
    </>
}

function ManageButton({
                          onClick = () => {
                          }
                      }) {
    const classes = "text-blue-500 border hover:bg-blue-50 hover:border-blue-50";
    return <div onClick={() => onClick()} style={{borderColor: "transparent"}}
                className={`${classes} inline-block cursor-pointer transition-colors text-sm tracking-tight leading-7 font-semibold  rounded-lg   px-2.5`}>
        Manage
    </div>
}

function cleanLink(l) {
    let a = l.replace("__CUSTOM__", "");
    return a;
}

function SocialIconWrapper({children, onClick}) {
    return <div onClick={onClick} className="h-4 w-4">
        {children}
    </div>
}

function handleOpenLink(link) {
    if (!link) {
        return;
    }
    const l = cleanLink(link);
    if (!l) {
        return;
    }
    window.open(cleanLink(link), '_blank', 'ref=nofollow,noreferrer')
}

function getSocialIcon(type, link, minimal) {
    switch (type) {
        case 'url-twitter' : {
            return <SocialIconWrapper onClick={() => handleOpenLink(link)}><TwitterIcon/></SocialIconWrapper>
        }
        case 'url-facebook' : {
            return <SocialIconWrapper onClick={() => handleOpenLink(link)}><FacebookIcon/></SocialIconWrapper>
        }
        case 'url-linkedin' : {
            return <SocialIconWrapper onClick={() => handleOpenLink(link)}><LinkedInIcon/></SocialIconWrapper>
        }
        case 'url-reddit' : {
            return <SocialIconWrapper onClick={() => handleOpenLink(link)}><RedditIcon/></SocialIconWrapper>
        }
        case 'url-github' : {
            return <SocialIconWrapper onClick={() => handleOpenLink(link)}><GithubIcon/></SocialIconWrapper>
        }
        case 'url-discord' : {
            return <SocialIconWrapper onClick={() => handleOpenLink(link)}></SocialIconWrapper>
        }
        case 'url-instagram' : {
            return <SocialIconWrapper onClick={() => handleOpenLink(link)}><InstagramIcon/></SocialIconWrapper>
        }
        case 'url-eth_address' : {
            return <SocialIconWrapper onClick={() => {
                copyTextToClipboard(link)
            }}><EthIcon/></SocialIconWrapper>
        }
        case 'url-skype' : {
            return <SocialIconWrapper onClick={() => handleOpenLink(link)}><SkypeIcon/></SocialIconWrapper>
        }
        case 'website' : {
            return <MetaIcon minimal={minimal} icon={<LinkIcon/>}/>
        }
        default :
            return null
    }
}

const show_texts = {
    'url-discord': (v) => cleanSocialForProfile(v, 'discord'),
    'website': (v) => cleanWebsiteForProfile(v),
    'url-eth_address': (v) => cleanSocialForProfile(v, 'eth'),
    'url-skype': (v) => cleanSocialForProfile(v, 'skype'),
};

function getSocialText(item, minimal) {
    if (Object.keys(show_texts).includes(item.type)) {
        return <MetaLabel link={item.value} minimal={minimal}>
            {show_texts[item.type](item.value)}
        </MetaLabel>
    }
    return null;
}

export function UniversalMeta({minimal, single_row, meta = [], clean, preview, footer}) {

    const full_width_items = preview || single_row;

    const items = meta.map((it, index) => {
        if (it.type && (it.type.startsWith('website') || it.type.startsWith('url-'))) {
            return <div className={`${minimal ? "h-5" : "h-6"} cursor-pointer space-x-1.5 pl-2 pr-1 flex items-center`}
                        key={index}>
                {getSocialIcon(it.type, it.value, minimal)}

                {getSocialText(it, minimal)}
            </div>
        }
        return <div className={`${single_row ? "" : minimal ? "h-5" : "h-6"}`} key={index}>
            <MetaItem fill={full_width_items} minimal={minimal} clean={clean} {...it}  />
        </div>
    })

    if (minimal) {
        return items;
    }

    return <div
        className={`${full_width_items ? "block space-y-1" : "flex-wrap flex"} whitespace-nowrap items-baseline`}>
        {items}
    </div>
}

function People({profiles = [], prefix = "", suffix = ""}) {
    return <div className="px-4 flex space-x-2 pb-3 pt-2">
        <div className="flex space-x-0.5">
            <div className="bg-gray-200 w-4 h-4 rounded-md"/>
            <div className="bg-gray-200 w-4 h-4 rounded-md"/>
        </div>
        <div className="text-xs text-gray-600">
            <span>{prefix}&nbsp;</span>
            <span>James, Ryan, and 58 others</span>
            <span>&nbsp;{suffix}</span>
        </div>
    </div>
}

function highlightHashtags(str) {
    return str.replace(new RegExp(/\S*#(\[[^\]]+\]|\S+)/, "gi"), (match) => `/-/${match}/-/`);
}

function highlightMentions(str) {
    return str.replace(new RegExp(/\B(\@[a-z0-9_-]+)/, "gi"), (match) => `/-/${match}/-/`);
}

function isURL(str) {
    const urlRegex = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
    const url = new RegExp(urlRegex, 'i');
    return str.length < 2083 && url.test(str);
}

function DescAction({show_more, onClick}) {
    if (show_more) {
        return <span className="cursor-pointer link right-0" onClick={() => onClick(false)}>Hide</span>
    } else {
        return <span className="cursor-pointer link right-0" onClick={() => onClick(true)}>Show more</span>
    }
}

export function RichDescription({str, navigate, inline, chars = 165, line_clamp}) {
    const [show_more, setShowMore] = useState(false);
    let astr = inline ? str.replace(/\n/g, ' ') : str;
    const has_overflow = str && str.length > chars;

    // cut the string to the first 165 characters
    if(!show_more && has_overflow) {
        astr = astr.substring(0, chars);
    }

    const parts = highlightMentions(highlightHashtags(astr)).replace(/\n/g, '\n ').split("/-/");

console.log("HAS OVERFLOW", has_overflow, str.length, chars, str)
    const line_clamp_class = show_more ? "" : line_clamp ? `line-clamp-${line_clamp}` : "line-clamp-6";

    return <div className="relative">
        <span className={`whitespace-pre-line ${line_clamp_class}`}>
        {parts.map((pt, index) => {

            if (pt.startsWith("#")) {
                return <span key={`${index}+${pt}`}
                             onClick={() => navigate(`/search?term=${pt}&type="hashtag"&source=community`)}
                             className="cursor-pointer text-blue-600">{pt}</span>;
            }
            if (pt.startsWith("@")) {
                return <span key={`${index}+${pt}`}
                             onClick={() => navigate(`/search?term=${pt}&type="mention"&source=community`)}
                             className="cursor-pointer text-blue-600">{pt}</span>;
            }
            const subparts = pt.split(" ");
            return subparts.map((spt, key) => {
                if (isURL(spt.replace(/\n/g, ''))) {
                    return <a key={`${key}-${spt}`} href={spt} className="link" target="_blank"
                              rel="nofollow noreferrer">{cleanLink(spt)}</a>
                }
                return <span key={`${key}-${spt}`}>{spt + ' '}</span>;
            })

        })}
            {has_overflow ?
                <span>{!show_more?"...":""} <DescAction onClick={() => setShowMore(!show_more)} show_more={show_more}/></span> : null}
    </span>
    </div>;
}

function ParagraphLoading() {
    return <div className="space-y-2.5 max-w-sm">
        <div className="flex items-center space-x-2 w-full">
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-600 w-24"></div>
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-600 w-full"></div>
        </div>
        <div className="flex items-center w-full space-x-2 max-w-[480px]">
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-600 w-full"></div>
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-600 w-24"></div>
        </div>
    </div>
}

function MetaLabel({children, minimal, link, maxWidth = '12rem'}) {
    return <div onClick={() => handleOpenLink(link)}
                className={`flex-grow ${minimal ? "text-xs pt-px" : "text-sm"} whitespace-nowrap  ${link ? "link" : "text-gray-600"}`}>
        <div className="text-ellipsis truncate overflow-hidden" style={{maxWidth}}>{children}</div>
    </div>
}

function MetaIcon({minimal, icon}) {
    return <div className="">
        <div className={`${minimal ? "w-3.5 h-3.5" : "h-4 w-4"} text-gray-600`}>{icon}</div>
    </div>
}

function MetaItem({icon, sublabel, onClick, action, fill, type, label, minimal, clean, editable}) {
    let maxWidth = fill ? "40rem" : '12rem';
    if (type === 'occupation' || type === 'location') {
        maxWidth = '16rem';
    }
    return <div onClick={() => {
      if(onClick) {
          onClick()
      }
    }}
        className={`${fill ? "flex" : "inline-flex"} ${!!onClick?"cursor-pointer":""} group pr-1 space-x-1.5 py-px ${minimal ? "" : "px-1.5"} rounded-lg ${editable ? "hover:bg-gray-200 cursor-pointer" : ""} items-center`}>
        <div className="flex-none">
            <MetaIcon minimal={minimal} icon={icon}/>
        </div>
        <div className="flex-grow">
            <MetaLabel maxWidth={maxWidth} minimal={minimal}>
                {label}
            </MetaLabel>
            {sublabel && <MetaLabel maxWidth={maxWidth} minimal={minimal}>
                {sublabel}
            </MetaLabel>}
        </div>
        {action && <div className="flex-none text-xs pr-4">
            <a onClick={() => action.onClick()} className="underline cursor-pointer text-blue-500">{action.label}</a>
        </div>}
    </div>
}

export default function EntityProfileCard({
                                              onTitleClick,
                                              header = {enabled: false},
                                              onExpand = () => {
                                              },
                                              onSubtitleClick,
                                              preview,
                                              invert_colors,
                                              subtitle_suffix,
                                              loading,
                                              layout = {},
                                              image_color,
                                              compact_actions,
                                              suffix,
                                              pre_suffix,
                                              people,
                                              footer,
                                              tags = [],
                                              stats = [],
                                              meta = [],
                                              cover_style,
                                              compact = false,
                                              features = {},
                                              subtitle = "",
                                              image,
                                              buttons = [],
                                              title = "",
                                              icon = {emoji: ""},
                                              desc = "",
                                          }) {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);

    function renderButtons() {
        return buttons.map((btn, ind) => {
            return <div key={ind}>
                {btn}
            </div>
        });
    }

    const dynamic_padding = "px-4 sm:px-6";
    const bottom_padding = meta.length === 0 && !footer ? "pb-4" : "";

    return <div className={`overflow-hidden ${loading ? "animate-pulse" : ""} relative pb-3`}>
        {!compact && !preview &&
            <Cover cover_style={cover_style} features={features} color={image_color} loading={loading}
                   setExpanded={setExpanded} image={image} expanded={expanded}/>}
        <div onClick={() => {
            if (compact && onExpand) {
                onExpand()
            }
        }} className={`${bottom_padding} transition-colors relative `}>
            {!preview && <div className={`relative ${layout.large_image ? "h-14" : "h-7"}`}>
                <div className={`absolute ${layout.large_image ? "-top-16 left-4" : "-top-5 left-4"} `}>
                    <div
                        className={`${layout.large_image ? "h-32 w-32" : "h-14 w-14"} flex justify-center items-center`}>
                        <UniversalIcon features={features} {...icon} loading={loading}/>
                    </div>
                </div>
                <div className="absolute top-2 right-4 flex space-x-2">
                    {renderButtons()}
                </div>
            </div>}
            {preview && <div className="pt-4 pb-2 flex justify-center">
                <div className={icon?.type === 'image' ? `h-40 w-40` : "h-16 w-16"}>
                    <UniversalIcon xl={true} {...icon} loading={loading}/>
                </div>
            </div>}
            <div className={`${dynamic_padding} pt-5`}>
                <Header onSubtitleClick={onSubtitleClick} onTitleClick={onTitleClick} pre_suffix={pre_suffix}
                        subtitle_suffix={subtitle_suffix} loading={loading} suffix={suffix}
                        compact={compact} subtitle={subtitle} title={title}/>
            </div>
            {compact && <div className="absolute top-3 mt-px right-4 flex space-x-2">
                {compact_actions}
                {header.can_manage && <ManageButton onClick={() => header.onManage()}/>}
                {renderButtons()}
            </div>}
        </div>
        {desc && <div className={`max-w-xl pt-2.5 ${dynamic_padding}`}>
            <div className="text-[0.95rem] text-gray-900">
                {loading ? <ParagraphLoading/> :
                    <RichDescription chars={features?.desc_chars || 140} navigate={navigate} str={desc}/>}
            </div>
        </div>}
        {meta.length > 0 && <div className={`max-w-lg pt-2.5 px-3 sm:px-5`}>
            <UniversalMeta single_row={features?.single_row_meta} preview={false} meta={meta}
                           footer={!!people || stats.length}/>
        </div>}
        {people && <People {...people} />}
        {footer && <div className={`${dynamic_padding} pt-2`}>
            {footer}
        </div>}

    </div>
};

//  {tags?.length > 0 && <EntityTags config={{tags, invert_colors}}/>}