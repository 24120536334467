import React from 'react';
import {CheckIcon, ChevronLeftIcon, ComputerDesktopIcon, FolderOpenIcon} from "@heroicons/react/24/outline";
import {Avatar} from "../avatar";
import {CheckBadgeIcon, ChevronRightIcon} from "@heroicons/react/24/solid";

const mock_menu_items = [
    {
        type: "",
        right_label: "",
        icon: <ComputerDesktopIcon/>,
        label: "Upload files from your Computer",
    },
    {
        type: "",
        right_label: "",
        icon: <FolderOpenIcon/>,
        label: "Browse files on Orange",
    },
];

export function ContentPopupDivider() {
    return <div className="border-b border-gray-200 my-0.5"/>
}

export function ContentPopupSubheader({children, top}) {
    return <div className={`px-3 ${top ? "pt-3" : ""}`}>
        <div className="text-xs leading-4 font-semibold text-gray-600 tracking-tight">
            {children}
        </div>
    </div>
}

export function NameWrapper({badge, name}) {
    /*
     {badge&&<div className="h-[1.2rem] w-[1.2rem] text-blue-500">
            <CheckBadgeIcon />
        </div>}
     */
    return <div className="flex items-center space-x-1.5">
        <div className="text-base tracking-tight font-bold leading-5 text-gray-800">{name}</div>

    </div>
}

export function ContentPopupAvatarHeader({name = '', image, desc = '', type = 'member'}) {
    return <div className="flex space-x-2 px-3 py-3">
        {image && <div className="flex-none">
            <Avatar url={image} size="h-10 w-10"/>
        </div>}
        <div className="pt-px overflow-hidden">
            <NameWrapper name={name} badge={true}/>
            <div className="truncate text-sm text-gray-500">{desc}</div>
        </div>
    </div>
}

export function ContentPopupMenu({can_check, padded = true, items = [...mock_menu_items]}) {

    return <div className={`${padded ? "py-1 px-1" : ""} space-y-0.5`}>
        {items.map((item, k) => {
            const hover_styles = item.type === 'simple' ? "" : "hover:bg-gray-100 cursor-pointer";

            if (item.type && item.type === "subheader") {
                return <div className="pt-2 px-2 pb-1" key={k}>
                    <div className="font-medium text-xs text-gray-600">{item.label}</div>
                </div>
            }

            return <div onClick={(e) => {
                if (item.onClick) {
                    item.onClick(e);
                }
            }} className={`flex items-center space-x-2 px-2 py-1.5 group ${hover_styles}  rounded-md`} key={k}>
                {item.icon && <div className="flex-none w-5">
                    <div className={`w-5 h-5 text-gray-500`}>
                        {item.icon}
                    </div>
                </div>}
                {item.image && <div className="flex-none w-5">
                    <Avatar size="h-5 w-5"/>
                </div>}
                {item.emoji && <div className="flex-none w-5">
                    <div className="w-5 h-5 text-base text-gray-500">
                        {item.emoji}
                    </div>
                </div>}
                {can_check && <div className="flex-none pl-1 w-5 items-center flex">
                    {item.checked && <div className={` h-4 w-4 text-blue-600`}><CheckIcon/></div>}
                </div>}
                <div className="flex-grow">
                    <div
                        className={`${can_check && item.checked ? "text-blue-700" : "text-gray-800"} font-medium ${item.large ? "text-base" : "text-sm"} `}>
                        {item.label}
                    </div>
                    {item.desc &&
                        <div className={`${can_check && item.checked ? "text-blue-700" : "text-gray-600"} text-xs`}>
                            {item.desc}
                        </div>}
                </div>
                <div className="text-xs text-gray-500">
                    {item.navigate_link && <div className="h-4 w-4 text-gray-600">
                        <ChevronRightIcon/>
                    </div>}
                    {item.right_label && <div className="text-gray-600">
                        {item.right_label}
                    </div>}
                </div>
            </div>
        })}
    </div>
}

function Title({children,minimal}) {
    return <div className={minimal?`font-bold text-sm text-gray-700`:`font-bold text-gray-800 text-base`}>{children}</div>
}

export function ContentMenuSection({title = "", side_padding=true, minimal, divider, actions, onBack, children}) {
    return <div className={` `}>
        <div className={`flex space-x-2 ${minimal?"h-10":"h-12"} px-3  items-center border-gray-200 ${divider ? "border-b" : ""}`}>
            <div className="flex-grow flex items-center space-x-2">
                {onBack && <div>
                    <div onClick={onBack} className="w-4 h-4 text-gray-500 cursor-pointer hover:text-gray-800">
                        <ChevronLeftIcon/>
                    </div>
                </div>}
                <Title minimal={minimal}>{title}</Title>
            </div>
            {actions && <div className={`flex ${minimal?"gap-4":"gap-2"}`}>
                {actions}
            </div>}
        </div>
        <div className={`py-2 ${side_padding?"px-3":""} `}>
            {children}
        </div>
    </div>
}