import memoize from "lodash/memoize";
import {addDoc, collection, doc, getFirestore} from "firebase/firestore";
import {waitForResult} from "../directory/build-elasticsearch-query";
import {getItemFromLocalStorage, saveItemToLocalStorage} from "../../../m3/utilities/localstorage";

export async function taxonomySearch(query,taxonomy="occupations") {
    const req = buildQuery(query,taxonomy);
    return await queryTaxonomyContent(req)
        .then((resp)=>{
            return resp;
        });
}

function buildQuery(query,taxonomy) {
    const page = 0;
    const size = 25;
    return {
        current: page,
        size: size,
        result_fields: {
            title: {raw: {}},
            image: {raw: {}},
            id: {raw: {}},
            tags: {raw: {}},
            count: {raw: {}},
            description: {raw: {}},
            data: {raw: {}},
        },
        search_fields: {
            title: {}
        },
        sort: {
            _score: "desc"
        },
        query,
        filters: {
            all: [
                {
                    "taxonomy": taxonomy
                }
            ],
            any: [],
            none: [],
        },
    }
}

export const queryTaxonomyContent = memoize(async (request) => {
    const col = collection(getFirestore(), "searches");

    const ref = await addDoc(col, {
        type: 'all',
        engine: 'taxonomy',
        request,
        response: null
    });

    const nref = doc(col,ref.id);

    return await waitForResult(nref);
});