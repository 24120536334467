import React, {useEffect, useRef} from 'react';

import {Picker} from 'emoji-mart';
import styled from "styled-components";
import {getItemFromLocalStorage, saveItemToLocalStorage} from "../../../m3/utilities/localstorage";

const Container = styled.div`

`;

function getCustomIcons(community) {
    if (!community.profile) {
        // global
        return [];
    }

    if (!community.community_emojis) {
        return [];
    }

    const values = Object.values(community.community_emojis);

    return values.map((em) => {
        const h = em.handle.replace(/:/g, '')
        return {
            name: h,
            short_names: [h],
            text: '',
            emoticons: [],
            keywords: h.split('-'),
            imageUrl: em.url
        }
    });
}

export async function getEmojiData(override) {
    const emoji_data = checkForEmojiData();
    if (emoji_data && !override) {
        return emoji_data;
    }
    const data = await fetchEmojiData();

    return data;
}

function checkForEmojiData() {
    return getItemFromLocalStorage('emoji_data', null);
}

async function fetchEmojiData() {
    const response = await fetch("https://cdn.jsdelivr.net/npm/@emoji-mart/data");

    const resp_json = await response.json();

    if (!resp_json) {
        return null;
    } else {
        saveItemToLocalStorage('emoji_data', resp_json);
        return resp_json;
    }
}

// emojiIndex.search('christmas').map((o) => o.native)
export const EmojiPicker = ({
                                autoFocus = true,
                                changeEmoji = () => {
                                }, layout = 'standard'
                            }) => {
    const ref = useRef();
    useEffect(function () {
        new Picker({
            data: async () => await getEmojiData(),
            ref,
            onEmojiSelect: changeEmoji,
            searchPosition: "top",
            previewPosition: "none",
            navPosition: "top",
            autoFocus: autoFocus,
            theme: "light",
            dynamicWidth: true,
            title: "",
            emoji: "",
            maxFrequentRows: 0,
            sheetSize: 32,
            perLine: 10,
            color: "#4285f4",
            ...{}
        })
    }, [])
    return <Container layout={layout}>
        <div className={""} ref={ref}/>
    </Container>
};