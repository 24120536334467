import React, {Component, useState} from "react"
import './styles.css';
import AvatarEditor from 'react-avatar-editor';
import Button from "../button";
import {SimpleImageDropzone} from "../badge-builder/simple-image-dropzone";

function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}
export default class ImageEditor extends Component {
    constructor(props) {
        super(props);

        this.editor = React.createRef()

        this.input = React.createRef()

        this.state = {
            image: null,
            allowZoomOut: false,
            position: {x: 0.5, y: 0.5},
            scale: props.scale||1,
            rotate: 0,
            borderRadius: props.borderRadius||34,
            preview: undefined,
            border: props.border||50,
            width: props.width||275,
            height: props.height||275,
            disableCanvasRotation: false,
            isTransparent: false,
            backgroundColor: undefined,
        }
    }

    componentDidMount() {
        if(!this.state.image) {
            this.input.current.click();
        }
    }

    handleSetImage(img) {
        this.setState({image: img})
    }

    handleNewImage = (e) => {
        if (e.target.files?.[0]) {
            this.setState({image: e.target.files[0]})
        }
    }

    getImageUrl = async () => {
        const dataUrl = this.editor.current.getImage().toDataURL()
        const result = await fetch(dataUrl)
        const blob = await result.blob()

        return window.URL.createObjectURL(blob)
    }

    handleSave = () => {
        const img = this.editor.current?.getImageScaledToCanvas()
        const img_url = img.toDataURL()
        const rect = this.editor.current?.getCroppingRect()

        if (!img_url || !rect) return

        this.setState({
            preview: {
                img: img_url,
                rect,
                scale: this.state.scale,
                width: this.state.width,
                height: this.state.height,
                borderRadius: this.state.borderRadius,
            },
            loading: true
        })

        if(this.props.onSave) {
            console.log("SAVE UPLOAD IMAGE")
            fetch(img_url)
                .then(res => res.blob())
                .then(blob => this.props.onSave(blob));

        }
    }

    handleScale = (e) => {
        const scale = parseFloat(e.target.value)
        this.setState({scale})
    }

    handleAllowZoomOut = (e) => {
        this.setState({allowZoomOut: e.target.checked})
    }

    handleDisableCanvasRotation = (e) => {
        this.setState({disableCanvasRotation: e.target.checked})
    }

    rotateScale = (e) => {
        e.preventDefault()
        this.setState({rotate: parseFloat(e.target.value)})
    }

    rotateLeft = (e) => {
        e.preventDefault()
        this.setState({rotate: (this.state.rotate - 90) % 360})
    }

    rotateRight = (e) => {
        e.preventDefault()
        this.setState({rotate: (this.state.rotate + 90) % 360})
    }

    handleBorderRadius = (e) => {
        this.setState({borderRadius: parseInt(e.target.value)})
    }

    handleXPosition = (e) => {
        this.setState({
            position: {...this.state.position, x: parseFloat(e.target.value)},
        })
    }

    handleYPosition = (e) => {
        this.setState({
            position: {...this.state.position, y: parseFloat(e.target.value)},
        })
    }

    handleWidth = (e) => {
        this.setState({width: parseInt(e.target.value)})
    }

    handleHeight = (e) => {
        this.setState({height: parseInt(e.target.value)})
    }

    logCallback(e) {
        console.log('callback', e)
    }

    handlePositionChange = (position) => {
        this.setState({position})
    }

    setBackgroundColor = (e) => {
        this.setState({backgroundColor: e.target.value})
    }

    setTransparent = (e) => {
        const isTransparent = e.target.checked
        // set color to white initially
        const backgroundColor = isTransparent ? '#fff' : undefined

        this.setState({backgroundColor, isTransparent})
    }

    render() {
        const inner_width = window.innerWidth - 32;
        const max_w = this.state.width+(this.state.border*2);
        const final_width = (inner_width > max_w ? max_w : inner_width) - convertRemToPixels(1.125);
        const ratio = this.state.height / this.state.width;
        const final_height = final_width * ratio;
        return (
            <div style={{maxWidth:'100%'}}>
                <SimpleImageDropzone drop_only onChange={(image)=>{
                this.handleSetImage(image)
                }} id={this.props.id||"profile-pic"}>
                    <div className="preview canvas-editor relative" style={{height:`${final_height}px`,width:`${final_width}px`, maxWidth: '100%'}}>
                        <AvatarEditor
                            ref={this.editor}
                            scale={this.state.scale}
                            width={final_width}
                            border={this.state.border}
                            height={final_height}
                            color={[232,232,232,1]}
                            position={this.state.position}
                            onPositionChange={this.handlePositionChange}
                            rotate={this.state.rotate}
                            borderRadius={
                                final_width / (100 / this.state.borderRadius)
                            }
                            style={{maxWidth:`${final_width}px`,maxHeight:`${final_height}px`}}
                            backgroundColor={this.state.backgroundColor}
                            onLoadFailure={this.logCallback.bind(this, 'onLoadFailed')}
                            onLoadSuccess={this.logCallback.bind(this, 'onLoadSuccess')}
                            onImageReady={this.logCallback.bind(this, 'onImageReady')}
                            image={this.state.image}
                            disableCanvasRotation={this.state.disableCanvasRotation}
                        />

                        <div className="absolute bottom-3 left-0 right-0 flex justify-center">
                            <input
                                name="scale"
                                type="range"
                                onChange={this.handleScale}
                                min={this.state.allowZoomOut ? '0.1' : '1'}
                                max="2"
                                step="0.01"
                                defaultValue="1"
                            />
                        </div>
                    </div>
                </SimpleImageDropzone>

                <div className="flex pt-3">
                    <div className="flex-grow">
                        <Button onClick={()=>this.input.current.click()} text="Change Photo" />
                    </div>
                    <div className="flex space-x-2">
                        <Button onClick={()=>{
                            if(this.props.onCancel) {
                                this.props.onCancel()
                            }
                        }} text="Cancel" />
                        <Button loading={this.state.loading} disabled={!this.state.image} onClick={this.handleSave} intent='success' text="Save" />
                    </div>
                </div>
                <input ref={this.input} onChange={(e) => {
                    if (e.target.files[0]) {
                        this.handleSetImage(e.target.files[0]);
                    }
                }} accept="image/*" className="hidden" type="file" name="file2" id="file" multiple={false}/>
            </div>
        )
    }
}
