import React, {forwardRef, useCallback, useId as useReactId, useState} from "react";
import M3_A_Overlay from "./overlay";
import {useId, useProvidedRefOrCreate} from "../hooks";
import Tippy from "@tippyjs/react/headless";
import {useIsMobile} from "../hooks/is-mobile";

/*
* This is the AnchoredOverlay atom
 */


/*
    {renderAnchor &&
            renderAnchor({
                ref: anchor_ref,
                id: anchor_id
            })}
        {open ? (
            <M3_A_Overlay
                returnFocusRef={anchor_ref}
                onClickOutside={onClickOutside}
                ignoreClickRefs={[anchor_ref]}
                onEscape={onEscape}
            //    ref={updateOverlayRef}
                role="none"
                height={'200px'}
                width={'200px'}
            >
                {children}
            </M3_A_Overlay>
        ) : null}
 */


const _props = {};

const max_height_map = {
    'xs': 'max-h-56',
    'sm': 'max-h-60',
    'md': 'max-h-64',
    'lg': 'max-h-80',
    'xl': 'max-h-96'
};

const width_map = {
    xs: 'w-56',
    sm: 'w-60',
    md: 'w-64',
    lg: 'w-80',
    xl: 'w-96',
};

function M3_A_AnchoredOverlay({
                                  open,
                                  placement = 'auto',
                                  onClose,
                                  renderAnchor,
                                  external_anchor_ref,
                                  overlay_ref,
                                  hide_on_click = true,
                                  in_portal,
                                  size = 'md',
                                  trigger = 'click',
                                  anchor_id: external_anchor_id,
                                  append_to = 'parent',
                                  children
                              }) {
    const is_mobile = useIsMobile();
    const anchor_ref = useProvidedRefOrCreate(external_anchor_ref);
    const tippy_ref = useProvidedRefOrCreate(overlay_ref);
    const anchor_id = useId(external_anchor_id)
    const [mounted, setMounted] = useState(false);

    const lazyPlugin = {
        fn: () => ({
            onMount: () => setMounted(true),
            onHidden: () => setMounted(false),
        }),
    };

    if (in_portal || is_mobile) {
        append_to = () => document.body;
    }

    const plugins = [lazyPlugin];

    let options = {
        strategy: 'fixed',
        modifiers: [
            {
                name: 'flip',
                options: {
                    fallbackPlacements: ['bottom-end', 'top-end'],
                },
            },
            {
                name: 'preventOverflow',
                options: {
                    altAxis: true,
                    tether: false,
                },
            },
        ],
    };

    return <Tippy popperOptions={options} appendTo={append_to} plugins={plugins} trigger={trigger} interactive={true} offset={[0, 4]}
                  placement={placement} onHide={() => {
        if (onClose) {
            onClose();
        }
    }}
                  ref={tippy_ref}
                  render={(attrs) => {
                      if (!mounted) {
                          return null;
                      }
                      const max_height = max_height_map[size];
                      const width = width_map[size];
                      return <div onClick={() => {
                          if (hide_on_click) {
                              tippy_ref?.current?._tippy?.hide();
                          }
                      }}
                                  className={`content-popup-shadow z-50 content-popup overflow-y-auto bg-white border border-gray-200 rounded-md ${width} ${max_height}`}>
                          {children}
                      </div>;
                  }}
    >
        {renderAnchor &&
            renderAnchor({
                ref: anchor_ref,
                id: anchor_id
            })}
    </Tippy>
}

export default Object.assign(M3_A_AnchoredOverlay);