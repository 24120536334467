import React from 'react';
import {DocumentIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {ChevronDownIcon} from "@heroicons/react/24/solid";
import HoverActions from "../../../../m3/_legacy_components/hover-actions";
import {IconAction, TextAction} from "../../../auth/sign-in";
import {formatFileSize, getFileTypeIcon} from "../../../../m3/icons/file-helpers";
import ContentPopup from "../../../../m3/_legacy_components/content-popup";
import SimpleTooltip from "../../../../m3/_legacy_components/tooltip";
import Button from "../../../../m3/_legacy_components/button";
import FolderSelector from "../../../../m3/_legacy_components/folder-selector";
import {useCommunity} from "../../../../config/community";
import DataWrapper from "../../../../m3/_legacy_components/data-wrapper";
import {InlineLoader} from "../../../../m3/_legacy_components/admin-activity";

function AttachmentItem({
                            is_loading,
                            can_remove, onRemove = () => {
    }, name = "", type = "image/jpg", size = 0
                        }) {
    return <div className="flex pl-1 pr-2 py-1 rounded-md border border-gray-200 items-center space-x-1.5">
        {!is_loading&&<div className="w-5 flex-none">
            <div className="w-5 h-5">
                {getFileTypeIcon(type)}
            </div>
        </div>}
        {is_loading && <div className="flex-grow w-5">
            <InlineLoader mini />
        </div>}
        <div className="flex items-baseline space-x-2 text-sm ">
            <div className="text-gray-800 font-medium max-w-[10rem] truncate">{name}</div>
            <div className="text-gray-500">{formatFileSize(size, 0)}</div>
        </div>
        {can_remove && <div>
            <IconAction onClick={onRemove}>
                <XMarkIcon/>
            </IconAction>
        </div>}
    </div>
}

function Name({data}) {
    return <span>{data.name}</span>
}

function FolderPreview({id}) {
    const community = useCommunity();

    return <TextAction text={<span>
    Upload to <DataWrapper id={id} type={`community_entities.${community.uid}.folders`}><Name /></DataWrapper>
    </span>} />
}

function UploadNewFilesTo({
                              drive_id,
                              selected_folder_id,
                              onSelectFolder = () => {
    }
                          }) {
    // <FolderSelector />

    const content = <div className="content-popup-shadow max-w-md bg-white border border-gray-200 rounded-lg">
        <FolderSelector drive_id={drive_id} onSelect={onSelectFolder}/>
    </div>

    return <SimpleTooltip usePortal simple trigger="click" interactive hideOnClick={false} placement="bottom-end"
                          text={content}>
        {selected_folder_id?<FolderPreview id={selected_folder_id} />:<TextAction text="Add to drive?"/>}
    </SimpleTooltip>;
}

export function WriteManageAttachments({
                                           drive_id,
                                           attachments, handleSelectFolder, selected_folder_id, handleRemove = () => {
    }, onChange
                                       }) {
    return <div className="">
        <div className="flex items-baseline">
            <div className="flex-grow">
                <div className="text-gray-800 font-medium text-sm">Attachments</div>
            </div>
            <div>
                {drive_id && <UploadNewFilesTo drive_id={drive_id} selected_folder_id={selected_folder_id}
                                               onSelectFolder={(folder_id) => {
                                                   handleSelectFolder(folder_id);
                                               }}/>}
            </div>
        </div>
        <div className="flex gap-1.5 pt-1 flex-wrap">
            {attachments.map((attachment, index) => <AttachmentItem onRemove={() => {
                // window.confirm first
                window.confirm("Are you sure you want to remove this attachment?") && handleRemove(attachment, index)
            }} {...attachment} can_remove key={index}/>)}
        </div>
    </div>
}