import React from "react";
import M3_A_Content_View from "../atoms/content-view";
import M3_A_Spinner from "../atoms/spinner";
import M3_A_ProgressBar from "../atoms/progress-bar";
import M3_A_Modal from "../atoms/modal";
import M3_C_Header from "./header";
import M3_A_Footer from "../atoms/footer";

/*
* This is the Dialog component
* It is a simple dialog component
 */

const _props = {
    children: {
        type: "node",
        required: false,
    },
    header: {
        type: "node",
        required: false,
    },
    confirm_text: {
        type: "string",
        required: false,
        fallback: "Confirm"
    },
    confirm_intent: {
        type: "string",
        required: false,
        fallback: "primary"
    },
    cancel_text: {
        type: "string",
        required: false
    },
    custom_footer_left: {
        type: "node",
        required: false
    },
    custom_footer_right: {
        type: "node",
        required: false
    },
    topper: {
        type: "node",
        required: false
    },
    is_loading: {
        type: "boolean",
        required: false
    },
    is_initializing: {
        type: "boolean",
        required: false
    }
};

export default function M3_C_Dialog({
                                        children,
                                        header,
                                        footer,
                                        is_loading,
                                        dialog_count,
                                        is_initializing,
                                        max_width = "max-w-xl"
                                    }) {

    let loading_overlay = null, top_progress_bar = null;

    if (is_initializing) {
        loading_overlay =
            <div className="absolute inset-0 bg-white bg-opacity-50 rounded-md flex items-center justify-center">
                <div className="w-12 h-12">
                    <M3_A_Spinner/>
                </div>
            </div>
    }

    if (is_loading) {
        top_progress_bar = <M3_A_ProgressBar fakeload={2500}/>;
    }


    const _header = header && <M3_C_Header show_background is_modal_header watch_scroll_id={"dialog-content-view"} sticky skeleton={is_initializing}
                                           size={header?.size||"xl"} context_bar={header?.context_bar} title={header?.title}
                                           subtitle={header?.subtitle}/>;

    return (
        <M3_A_Modal stack_count={dialog_count} max_width={max_width}>
            {top_progress_bar}
            <M3_A_Content_View header={_header} container_classes={`${is_initializing && "animate-pulse"}`} id="dialog-content-view"
                               padding="pb-24" max_height="">
                <div className="px-4 pt-2 flex flex-col gap-4">
                    {children}
                </div>
            </M3_A_Content_View>
            {footer && <M3_A_Footer caption={footer?.caption} confirm_action={footer?.actions?.confirm}
                                    cancel_action={footer?.actions?.cancel} skeleton={is_initializing}/>}
            {loading_overlay}
        </M3_A_Modal>
    );
}