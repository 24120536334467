export const mock_notifications = [
    {
        top: {},
        type: "assigned-to-role",
        text: "assigned you to the role **Senior**.",
        data: {},
        author_name: "Christina Reiner",
        date: "Jul 6th"
    },
    {
        top: {},
        type: "unassigned-from-role",
        text: "unassiged you from the role **Senior**.",
        data: {},
        author_name: "Teagan Evans",
        date: "Jul 6th"
    },
    {
        type: "added-to-group",
        text: "added you to **Bogota Hub**.",
        data: {},
        author_name: "Rachel Sanchez",
        date: "Jul 6th"
    },
    {
        type: "auto-added-to-group",
        text: "added you to **New York Hub**.",
        data: {},
        author_name: "Rachel Sanchez",
        date: "Jul 6th"
    },
    {
        top: {
            name: "Myla Graham",
            action: "reacted to",
            target: "NY Summer 2022 Plans",
        },
        type: "reacted-to-post",
        text: "POST PREVIEW",
        emoji: "🙏",
        reactions: {
            'pray': {
                count: 4,
                emoji: '🙏'
            },
        },
        author_name: "Jason Smith",
        data: {},
        date: "Jul 6th"
    },
    {
        top: {},
        type: "followed-you",
        icon: {
            slug: 'member',
            color: 'text-blue-500'
        },
        main: 'member-list',
        data: {
            count: 1,
            followers: [
                {
                    name: "Christina Reiner"
                }
            ]
        }
    },
    {
        type: "followed-member-i-follow",
        main: 'member-list',
        icon: {
            slug: 'sparkle',
            color: 'text-orange-500'
        },
        data: {
            count: 2,
            followers: [
                {
                    name: "Myra Green"
                },
                {
                    name: "Taylor Lock"
                }
            ],
            target: {
                name: "Jim Beam"
            }
        }
    },
    {
        type: "followed-you",
        icon: {
            slug: 'member',
            color: 'text-blue-500'
        },
        main: 'member-list',
        data: {
            count: 7,
            followers: [
                {
                    name: "Jamie Dillon"
                },
                {
                    name: "Joe Schulz"
                },
                {
                    name: "Tim Garcia"
                },
                {
                    name: "Nikki Grand"
                },
                {
                    name: "Pablo Santiago"
                },
                {
                    name: "Michael Moritz"
                },
                {
                    name: "Sven James"
                }
            ]
        }
    },
    {
        icon: {
            slug: 'message',
            color: 'text-blue-800'
        },
        main: "message-reference",
        type: "direct-message",
        data: {
            thread_subject: "Your Membership Status",
            sender: {
                name: "Pablo Sanchez"
            }
        }
    },
    {
        icon: {
            slug: 'plus',
            color: 'text-blue-800'
        },
        main: "follow-request",
        type: "follow-request",
        data: {
            target: {
                name: "Pablo Sanchez"
            }
        }
    },
    {
        icon: {
            slug: 'plus',
            color: 'text-blue-800'
        },
        main: "follow-request-accepted",
        type: "follow-request-accepted",
        data: {
            target: {
                name: "Jim Beam"
            }
        }

    },
    {
        icon: {
            slug: 'archive',
            color: 'text-gray-600'
        },
        main: "message-reference",
        type: "closed-thread",
        data: {
            thread_subject: "200. Stiftungsfest Diskussion",
            target: {
                name: "Pablo Sanchez"
            }
        }
    },
    {
        icon: {
            slug: 'chart',
            color: 'text-orange-500'
        },
        main: "poll",
        type: "closed-poll",
        data: {
            name: "Where should we have our summer retreat?"
        },
        date: "Jul 6th"
    },
    {
        icon: {
            slug: 'airplane',
            color: 'text-green-500'
        },
        main: "message-reference",
        type: "message-delivered",
        data: {
            count: 49,
            thread_subject: "Motion to update Meeting Agenda"
        }
    },
    {
        type: "membership-application-declined",
        author_name: "Sandbox Admins",
        text: "Your membership application has been declined.",
        data: {},
        date: "Jul 6th"
    },
    {
        type: "membership-application-accepted",
        author_name: "Sandbox Admins",
        text: "Congratulations, your membership application has been accepted!",
        data: {},
        date: "Jul 6th"
    },
    {
        icon: {
            slug: 'plus',
            color: 'text-blue-500'
        },
        type: "new-membership-application",
        main: "membership-application",
        data: {
            target: {
                name: "Jimmy Jones"
            },
            group_name: "Bogota Hub"
        },
        date: "Jul 6th"
    },
    {
        top: {
            name: "Tommy Blanchard",
            action: "reacted to your reply in",
            target: "Web3 Initiative Plans for Q3",
        },
        type: "reacted-to-reply",
        text: "REPLY PREVIEW",
        emoji: "💯",
        reactions: {
            '100': {
                count: 1,
                emoji: '💯'
            },
        },
        author_name: "Jason Smith",
        data: {},
        date: "Jul 6th"
    },
    {
        top: {
            name: "Pablo Santiago",
            action: "replied to",
            target: "Madrid Hub Summer 2022",
        },
        author_name: "Pablo Santiago",
        type: "replied-to-your-post",
        text: "REPLY PREVIEW",
        data: {},
        date: "Jul 6th"
    },
    {
        top: {
            name: "Angie Reyes",
            action: "replied in",
            target: "Madrid Hub Summer 2022",
        },
        author_name: "Angie Reyes",
        type: "replied-to-your-reply",
        text: "REPLY PREVIEW",
        data: {},
        date: "Jul 6th"
    },

    {
        top: {
            name: "Tony Hitchens",
            action: "messaged",
            target: "Madrid Hub",
        },
        type: "sent-a-message",
        author_name: "Tony Hitchens",
        text: "MESSAGE PREVIEW",
        data: {},
        date: "Jul 6th"
    },
    {
        top: {
            name: "Jamie Rush",
            action: "mentioned you in",
            target: "Madrid Hub Summer 2022",
        },
        type: "mentioned-you-in-a-message",
        author_name: "Jamie Rush",
        text: "MESSAGE PREVIEW",
        data: {},
        date: "Jul 6th"
    },
    {
        type: "added-to-community",
        author_name: "Jamie Rush",
        text: "invited you to join **Sandbox** (@sandbox).",
        data: {
            name: "Sandbox"
        },
        date: "Jul 6th"
    },
    {
        type: "made-admin",
        author_name: "Jamie Rush",
        text: "made you an community admin.",
        data: {},
        date: "Jul 6th"
    },
    {
        type: "member-type-changed",
        author_name: "Betty Gray",
        text: "changed your membership level to **Alter Herr**",
        data: {},
        date: "Jul 6th"
    },
    {
        type: "made-group-moderator",
        author_name: "Justin Chan",
        text: "made you a moderator of **New York Hub**.",
        data: {},
        date: "Jul 6th"
    }
];