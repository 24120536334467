import React, {useCallback, useEffect, useRef, useState} from "react";
import {_m3_c_list_view_utils} from "./utils";
import {flexRender, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {useVirtualizer} from "@tanstack/react-virtual";
import {useInfiniteScrollData} from "../../../routes/community/contacts/hooks";
import M3_A_AnchoredOverlay from "../../atoms/anchored-overlay";
import {m3_icon_map} from "../../icons/icon-map";
import M3_A_ActionList from "../../atoms/action-list";
import {useCurrentLayout, useDimensions} from "../../hooks";
import {TableEditableCell} from "./cell-editing";
import {useTableKeyHandlers} from "./key-handlers";
import {M3_A_MiniLoadingIndicator} from "./components";
import {ContactsEmptyState} from "../../../routes/community/contacts/components";

function buildActions(actions_meta, actions_fns, context) {
    let a = [];

    if (actions_meta?.can_move_left) {
        a.push({
            id: "move-left",
            label: "Move left",
            onClick: actions_fns?.onMoveLeft,
            leading_indicator: {
                type: "icon",
                icon: m3_icon_map.outlines.left_arrow
            }
        })
    }

    if (actions_meta?.can_move_right) {
        a.push({
            id: "move-right",
            label: "Move right",
            onClick: actions_fns?.onMoveRight,
            leading_indicator: {
                type: "icon",
                icon: m3_icon_map.outlines.right_arrow
            }
        })
    }

    if (actions_meta?.can_sort_asc) {
        a.push({
            id: "sort-asc",
            label: "Sort ascending",
            onClick: actions_fns?.onSortAsc,
            leading_indicator: {
                type: "icon",
                icon: m3_icon_map.outlines.up_arrow
            }
        })
    }

    if (actions_meta?.can_sort_desc) {
        a.push({
            id: "sort-desc",
            label: "Sort descending",
            onClick: actions_fns?.onSortDesc,
            leading_indicator: {
                type: "icon",
                icon: m3_icon_map.outlines.down_arrow
            }
        })
    }

    if (actions_meta?.can_hide) {
        if (a.length > 0) {
            a.push({
                type: "divider"
            });
        }
        a.push({
            id: "rename",
            label: "Edit column label",
            onClick: actions_fns?.onEditColumnLabel,
            leading_indicator: {
                type: "icon",
                icon: m3_icon_map.outlines.edit
            }
        })
        a.push({
            id: "hide",
            label: "Hide from view",
            onClick: actions_fns?.onHide,
            leading_indicator: {
                type: "icon",
                icon: m3_icon_map.outlines.hide
            }
        })
    }

    return a;
}

const HeaderWrapper = ({children, actions, context, show_options}) => {
    const list_actions = buildActions(context?.column?.columnDef?.column_actions, actions, context);
    const renderAnchor = () => <div
        className={show_options ? `hover:bg-gray-200 cursor-pointer w-full h-full flex rounded` : "w-full h-full flex justify-center items-center"}>
        {children}
    </div>;

    if (list_actions.length === 0) {
        return children;
    }

    return <M3_A_AnchoredOverlay placement="bottom-start" in_portal renderAnchor={renderAnchor}>
        <div className="p-1">
            <M3_A_ActionList size="sm" items={list_actions}/>
        </div>
    </M3_A_AnchoredOverlay>
};

function getRawValue(entry, key) {
    // key can be nested up to 3
    const keys = key.split(".");

    if (keys.length === 1) {
        return entry[key];
    } else if (keys.length === 2) {
        return entry[keys[0]][keys[1]];
    } else if (keys.length === 3) {
        return entry[keys[0]][keys[1]][keys[2]];
    } else {
        return "";
    }
}

export function ListView_Table({
                                   config,
                                   page_size,
                                   handleReorderCols,
                                   handleHideCol,
                                   handleEditColumnLabel,
                                   inline_sidebar_width,
                                   renderEmptyState,
                                   cols = []
                               }) {
    const layout = useCurrentLayout();
    const dimensions = useDimensions(layout, config?.state?.show_advanced, inline_sidebar_width);
    const selectable = config?.features?.can_select;

    const tableContainerRef = React.useRef(null);
    const [rowSelection, setRowSelection] = useState({});
    const cell_refs = useRef({});
    const item_height_px = 37;
    const left_width_px = selectable ? 260 : 232;
    const sticky_col_count = selectable ? 2 : 1;

    const [data, is_fetching, fetchNextPage, has_more_ref, refresh, updateRecord] = useInfiniteScrollData(config.state.active_view_data.config, config.state.query, page_size, config.context.community_uid, config.fns.getData, config.fns.getPartialsData);

    const columns = React.useMemo(() => {
        return _m3_c_list_view_utils.buildListColumns(cols, selectable)
    }, [cols, selectable, config.state.last_updated_at]);

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        debugTable: false,
        onRowSelectionChange: setRowSelection,
        getRowId: row => row.id,
        state: {
            rowSelection,
            context: {
                id: config.context.community_uid
            }
        }
    });

    const {rows} = table.getRowModel();

    const [selected_cell, setSelectedCell, handleKeyPress, character, setCharacter] = useTableKeyHandlers(tableContainerRef, cell_refs, rows, columns, handleDataUpdate, rowSelection, setRowSelection, config.fns.addCommand, config.fns.hasUndo, config.fns.hasRedo, config.fns.undo, config.fns.redo, left_width_px);

    function handleUpdateRecord(_id, accessorKey, value, original) {
        const old_value = getRawValue(original, accessorKey);
        config.fns.addCommand({
            type: "data-update",
            undo: () => {
                handleDataUpdate(_id, accessorKey, old_value, original)
            },
            redo: () => {
                handleDataUpdate(_id, accessorKey, value, original)
            }
        });

        handleDataUpdate(_id, accessorKey, value, original);
    }

    function handleDataUpdate(_id, _field, _value, _record) {
        // handleUpdateRecord
        updateRecord(_id, _field, _value, _record);
        // updateRecord in data hook
        config.fns.handleUpdateRecord(_id, _field, _value, _record);

        reflectSuccessfulUpdate(_id, _field, _value, _record);
    }

    function reflectSuccessfulUpdate(_id, _field, _value, _record) {
        // todo get the row and column id from the member id and field as accessorKey
        const row = rows.find(row => row?.original?.id === _id);
        const column_index = columns.findIndex(column => column?.accessorKey === _field);

        if (row && column_index > -1) {
            const cell_id = `${row.index}-${column_index}`;

            const cell = cell_refs.current[cell_id];
            if (cell) {
                // let's add a transition property first for bg color
                cell.style.transition = "background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1)";
                cell.classList.remove("bg-white");
                cell.classList.add("bg-green-100");
                setTimeout(() => {
                    cell.classList.add("bg-white");
                    cell.classList.remove("bg-green-100");
                    setTimeout(() => {
                        cell.style.transition = "";
                    }, 250)
                }, 600);
            }
        }
    }

    useEffect(function () {
        if (config?.state?.action) {
            if (config?.state?.action.type === "clear-selection") {
                setRowSelection({});
                config?.fns?.clearAction();
            } else if (config?.state?.action.type === "refresh") {
                refresh();
                config?.fns?.clearAction();
            }
        }
    }, [config?.state?.action])

    useEffect(() => {
        config.fns.updateSelected(Object.keys(rowSelection));
    }, [rowSelection]);

    const fetchMoreOnBottomReached = useCallback((containerRefElement) => {
        if (containerRefElement) {
            const {scrollHeight, scrollTop, scrollLeft, clientHeight} = containerRefElement
            //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
            // also handleStickyColumnShadow
            const element = document.getElementById("h-scroll-shadow");
            if (element) {
                if (scrollLeft > 0) {
                    element.classList.add("active");
                } else {
                    element.classList.remove("active");
                }
            }
            if (is_fetching || data.length === 0 || !has_more_ref.current) {
                return;
            } else if ((scrollHeight - scrollTop - clientHeight) < 300) {

                fetchNextPage();
            }

        }
    }, [fetchNextPage, is_fetching, data.length])

    //a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
    React.useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current)
    }, [fetchMoreOnBottomReached])

    //The virtualizer needs to know the scrollable container element

    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        estimateSize: () => item_height_px, //estimate row height for accurate scrollbar dragging
        getScrollElement: () => tableContainerRef.current, //measure dynamic row height, except in firefox because it measures table border height incorrectly
        measureElement: typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1 ? element => element?.getBoundingClientRect().height : undefined,
        overscan: 5,
    });

    const standard_cell_display = `items-center border-gray-300`;

    const standard_text_classes = "text-sm leading-[1rem] font-medium whitespace-nowrap overflow-hidden overflow-ellipsis";

    const cell_selection_active = Object.keys(rowSelection).length === 0;

    const can_edit_records = config?.features?.can_edit_records;

    const show_empty_state = data.length === 0 && !is_fetching;

    return <div className="relative" style={{
        height: dimensions.height, width: dimensions.width,
    }}>
        <div
            className={`list-view-container`}
            onClick={(e) => {
                // if click is not in cell by seeing if it is in a td and if there is a selected cell
                if (!e.target.closest("td") && selected_cell) {
                    setSelectedCell(null)
                }
            }}
            ref={tableContainerRef}
            id="list-view-table-container"
            onScroll={e => {
                fetchMoreOnBottomReached(e.target);
            }}
            style={{
                overflow: 'auto', //our scrollable table container
                position: 'relative', //needed for sticky header
                height: dimensions.height, //should be a fixed height
            }}
        >
            <table style={{display: 'grid'}}>
                <thead
                    style={{
                        height: `${item_height_px}px`, display: 'grid', position: 'sticky', top: 0, zIndex: 2,
                    }}
                >
                {table.getHeaderGroups().map(headerGroup => (<tr
                    key={headerGroup.id}
                    className="border-b border-gray-300 bg-white"
                    style={{display: 'flex', height: `${item_height_px}px`, width: '100%'}}
                >
                    {headerGroup.headers.map((header, header_index) => {
                        const header_align_class = header_index === 0 ? "justify-center" : "justify-start";
                        return (<th
                            key={header.id}
                            style={{
                                display: "flex",
                                height: `${item_height_px - 1}px`,
                                position: header_index < sticky_col_count ? 'sticky' : '',
                                zIndex: header_index < sticky_col_count ? 2 : '',
                                left: _m3_c_list_view_utils.getLeftPx(selectable, header_index, columns[0].size),
                                width: header.getSize(),
                            }}
                            className={`${standard_text_classes} ${standard_cell_display} ${header_align_class} bg-white text-gray-600 `}
                        >
                            <HeaderWrapper context={header.getContext()} actions={{
                                onMoveLeft: () => {
                                    handleReorderCols({
                                        destination: {index: header_index - 1},
                                        source: {index: header_index}
                                    })
                                },
                                onMoveRight: () => {
                                    handleReorderCols({
                                        destination: {index: header_index + 1},
                                        source: {index: header_index}
                                    })
                                },
                                onSortAsc: () => {
                                    config.fns.onUpdateSort({
                                        dir: "asc",
                                        field: header?.column?.columnDef?.accessorKey,
                                        label: header?.column?.columnDef?.label
                                    })
                                },
                                onSortDesc: () => {
                                    config.fns.onUpdateSort({
                                        dir: "asc",
                                        field: header?.column?.columnDef?.accessorKey,
                                        label: header?.column?.columnDef?.label
                                    })
                                },
                                onEditColumnLabel: () => {
                                    handleEditColumnLabel(header?.column?.columnDef?.accessorKey)
                                },
                                onHide: () => {
                                    handleHideCol(header?.column?.columnDef?.accessorKey);
                                }
                            }} show_options={selectable ? header_index > 0 : true}>
                                {flexRender(header.column.columnDef.header, header.getContext())}
                            </HeaderWrapper>
                        </th>)
                    })}
                </tr>))}
                </thead>
                <tbody
                    style={{
                        display: 'grid', height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
                        position: 'relative', //needed for absolute positioning of rows
                    }}
                >
                {rowVirtualizer.getVirtualItems().map(virtualRow => {
                    const row = rows[virtualRow.index];
                    const row_has_selected_cell = selected_cell && selected_cell.split("-")[0] === virtualRow.index.toString();
                    return (<tr
                        data-index={virtualRow.index} //needed for dynamic row height measurement
                        ref={node => rowVirtualizer.measureElement(node)} //measure dynamic row height
                        key={row.id}
                        className="border-b border-gray-300 relative group"
                        style={{
                            zIndex: row_has_selected_cell ? 3 : 1,
                            height: `${item_height_px}px`, //this should always be a `style` as it changes on scroll
                            display: 'flex', position: 'absolute', transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                            width: '100%',
                        }}
                    >
                        {row.getVisibleCells().map((cell, cell_index) => {
                            const styles = _m3_c_list_view_utils.style_defaults[cell?.column?.columnDef?.meta?.type];
                            // let's create an index for row_index and cell_index
                            const cell_id = `${virtualRow.index}-${cell_index}`;
                            const align_class = _m3_c_list_view_utils.getAlignClass(styles?.align, cell_index === 0 ? "justify-center" : cell.column.columnDef?.meta?.align === "right" ? "justify-end" : "justify-start");
                            const cell_is_selected = selected_cell && selected_cell === cell_id;
                            const row_is_selected = rowSelection[row.id];

                            const editable = cell.column.columnDef?.meta?.editable;
                            const color_class = _m3_c_list_view_utils.getColorClass(styles?.color, "text-gray-700");

                            const classes = `${standard_text_classes} ${standard_cell_display} ${color_class} ${align_class} flex w-full`;
                            return (<td
                                key={cell.id}
                                ref={node => {
                                    if (node) {
                                        cell_refs.current[cell_id] = node;
                                    }
                                }}
                                className={`${row_is_selected ? `bg-blue-100` : `bg-white`}`}
                                style={{
                                    padding: 0,
                                    display: 'flex',
                                    height: `${item_height_px - 1}px`,
                                    position: cell_index < sticky_col_count ? 'sticky' : '',
                                    left: _m3_c_list_view_utils.getLeftPx(selectable, cell_index, columns[0].size),
                                    zIndex: cell_index < sticky_col_count ? 2 : '',
                                    width: cell.column.getSize()
                                }}
                            >
                                {cell_is_selected && cell_selection_active && can_edit_records &&
                                    <div className={`relative select-none`}>
                                        <div
                                            className={`absolute top-0 left-0 rounded items-center flex border ${editable ? "border-blue-600" : "border-gray-400"}`}
                                            style={{
                                                minWidth: cell.column.getSize(),
                                                minHeight: `${item_height_px - 1}px`,
                                            }}
                                        >
                                            {editable && <div
                                                className={`editable-cell-wrapper w-full ${standard_text_classes}`}>
                                                <TableEditableCell character={character} id={cell_id} config={config}
                                                                   cell_height={item_height_px - 3}
                                                                   initial_value={cell.getContext().getValue()}
                                                                   width={cell.column.getSize()}
                                                                   onUpdateRecord={handleUpdateRecord}
                                                                   handleCallback={(e, event_name) => {
                                                                       if (event_name === "Enter") {
                                                                           handleKeyPress(e, event_name, cell_id);
                                                                       } else if (event_name === "Tab") {
                                                                           handleKeyPress(e, event_name, cell_id);
                                                                       }
                                                                   }}
                                                                   cell={cell.column.columnDef.cell}
                                                                   resetCharacter={() => {
                                                                       setCharacter(null)
                                                                   }}
                                                                   context={cell.getContext()}
                                                                   type={cell?.column?.columnDef?.meta?.type || "text"}/>
                                            </div>}
                                            {!editable && <div
                                                className={`${classes}`}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>}
                                        </div>
                                    </div>}
                                {(!cell_is_selected || !cell_selection_active) && <div
                                    className={`${classes} p-px`}
                                    onClick={() => {
                                        if (!can_edit_records || cell_index === 0 || cell_index > cols.length) {
                                            return;
                                        }
                                        setSelectedCell(cell_id);
                                    }}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </div>}
                            </td>)
                        })}
                    </tr>)
                })}
                </tbody>
            </table>
            {rowVirtualizer.getTotalSize() === 0 && !is_fetching && renderEmptyState()}
        </div>
        <div id="h-scroll-shadow" style={{
            position: 'absolute',
            bottom: 0,
            left: left_width_px,
            top: 0,
            right: 0,
            width: '12px',
            height: dimensions.height,
            pointerEvents: 'none',
            zIndex: 24,
        }}/>
        {show_empty_state && <ContactsEmptyState query={config?.state?.query}/>}
        {is_fetching && <M3_A_MiniLoadingIndicator/>}
    </div>
}