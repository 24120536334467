import React from "react";
import {useCommunity} from "../../../../config/community";
import {GroupManageGeneral} from "./tabs/general";
import {
    CalendarDaysIcon,
    ChatBubbleLeftEllipsisIcon,
    ClipboardIcon,
    InformationCircleIcon, KeyIcon, PlusCircleIcon
} from "@heroicons/react/24/outline";
import SimplePortal from "../../../../m3/_legacy_components/simple-portal";
import PreferencesModal from "../../../../m3/_legacy_components/preferences-modal";
import {GroupModalAccessTab} from "./tabs/access";
import {GroupModalApps} from "./tabs/apps";
import {GMMPinboard} from "./tabs/pinboard";
import {GMMForum} from "./tabs/forum";
import {GMMEvents} from "./tabs/events";

export function GroupManageModal({group,updateGroupData,onSaved,id,fns,flags,onDelete,starting_section,starting_tab="General",onClose}) {
   const p = {
       flags,
       fns,
       id,
       group,
       onDelete,
       starting_section,
       updateGroupData,
       onSaved
   };

    const opts = [
        {
            label: 'General',
            icon: <InformationCircleIcon/>,
            comp: <GroupManageGeneral {...p}  />
        },
        {
            label: 'Access',
            icon: <KeyIcon/>,
            comp: <GroupModalAccessTab {...p}  />
        },

        {
            divider: true
        },

        {
            label: "Pinboard",
            icon: <ClipboardIcon/>,
            comp: <GMMPinboard {...p} />
        },

        {
            label: "Posts & Media",
            icon: <ChatBubbleLeftEllipsisIcon/>,
            comp: <GMMForum {...p} />
        },

        {
            label: "Events",
            icon: <CalendarDaysIcon/>,
            comp: <GMMEvents {...p} />
        },


        {
            divider: true
        },

        {
            label: 'Apps',
            icon: <PlusCircleIcon/>,
            comp: <GroupModalApps {...p} />
        }

        /*
        {
            label: 'Messaging',
            icon: <PaperAirplaneIcon/>,
            comp: <GroupManageEmailPowerUp {...p} />
        },
        {
            label: 'Pinboard',
            icon: <ClipboardIcon/>,
            comp: <GMMPinboard {...p} />
        }

         */
    ];

    return <SimplePortal center>
        <PreferencesModal size="xl" title="Space Settings" onClose={()=>{
            onClose()
        }} starting_tab={starting_tab} options={opts} />
    </SimplePortal>
}