import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";import EntityProfileCard from "../entity-profile-card";
import Button from "../button";
import {PreviewHeader} from "./index";
import {getRoleData} from "../../../routes/community/position/api";
import {buildRoleProfileCard} from "../../../routes/community/position";
import {InlineLoader} from "../admin-activity";

/*
  <FollowButton updateFollowing={(fl) => {
            }} id={dt.id} type="member"/>
 */
export function RolePreview({onClose, id, community, unaverse, data}) {
    const navigate = useNavigate();

    const [dt, setDt] = useState(data);

    useEffect(function () {
        if (!data && id) {
            getRoleData(community.id, id)
                .then(pr => {
                    setDt({...pr,id:id});
                })
        }
    }, [data, id])

    useEffect(function () {
        if (dt && dt.id) {
            /*
            getEntityFollowData(community, dt.id, "role")
                .then((fd) => {
                    setFollowData(fd)
                })

             */
        }
    }, [dt])

    if(!dt) {
        return <div className="" style={{minHeight:'50vh'}}>
            <PreviewHeader title="Position" onClose={() => onClose()} />
            <div className="flex justify-center p-32 items-center">
                <InlineLoader />
            </div>
        </div>
    }

    const flags = {};
    const fns = {};

    const m = buildRoleProfileCard(dt,unaverse,community,flags);

    /*
     {community.flags.is_admin && <Button text="Manage" onClick={() => {
                community.openEditOverlay({
                    type: 'manage-role',
                    data: dt,
                    id: dt.id
                })
            }}/>}
     */
    return <div>
        <PreviewHeader title="Position" onClose={() => onClose()} />
        <EntityProfileCard onTitleClick={() => {
            navigate(`/c/${community.domain}/position/${dt.id}`);
        }} preview {...m} />
        <div className="px-4 flex pb-1 space-x-2">
            <Button text="Go to Position" onClick={() => {
                community.closePreviewOverlay();
                navigate(`/c/${community.domain}/position/${dt.id}`);
            }}/>

        </div>
        <div className='border-t mt-2 border-gray-200'>

        </div>
    </div>
}