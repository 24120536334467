import React, {useEffect, useRef} from "react";
import {useIsMobile} from "../../../m3/hooks/is-mobile";
import ReactDOM from "react-dom";

export function MobileDrawer({children,onClose=()=>{}}) {
    const is_mobile = useIsMobile();

    if (!is_mobile) return children;

    return <div className="mobile-drawer-fn overflow-x-hidden bg-white overflow-y-auto" style={{maxHeight: '74vh'}}>
        <div onClick={()=>onClose()} className="pt-2 pb-0.5 flex justify-center">
            <div className="rounded-lg h-1 w-16 bg-gray-300"/>
        </div>
        {children}
    </div>
}

function MDCPortal({children}) {

    return ReactDOM.createPortal(
        children,
        document.body
    );
}

export default function MobileDrawerContainer({children, trigger}) {
    const [open, setOpen] = React.useState(false);
    const closeListener = useRef(null);

    function handleFocus() {
        if(!open) {
            return;
        }
        setOpen(false)
    }

    useEffect(function () {
        if(!closeListener.current) return;
        closeListener.current.addEventListener('focus', handleFocus);

        return () => {
            if(!closeListener.current) return;
            closeListener.current.removeEventListener('focus', handleFocus)
        }
    }, [open])

    const body = <div className="fixed bottom-0 left-0 right-0 z-30" id="open-drawer">
        <MobileDrawer onClose={()=>{
            setOpen(false);
        }}>
            {children}
        </MobileDrawer>
        <div tabIndex="0" id={`drawer-close-listener`} ref={closeListener}/>
    </div>;

    return <>
        <span onClick={e => {
            if (e) {
                e.stopPropagation();
                e.preventDefault();
            }
            setOpen(!open);
        }}>{trigger}</span>
        {open && <MDCPortal>
            {body}
        </MDCPortal>}
    </>
}