export function saveItemToLocalStorage(key,value) {
    return localStorage.setItem(key, JSON.stringify(value));
}

export function getItemFromLocalStorage(key,default_value) {
    const item = localStorage.getItem(key);
    if (item&&item!=='undefined'&&item!=='null') {
        if(typeof item==='string' && item.includes('{') && item.includes('}')) {
            // check if is valid json
            let a = JSON.parse(item);
            if(typeof a === 'string') {
                return JSON.parse(a);
            } else {
                return a;
            }
        } else {
            // remove quotes from start and end
            if(item.startsWith('"')&&item.endsWith('"')) {
                return item.substring(1,item.length-1);
            } else {
                try {
                    return JSON.parse(item);
                } catch (e) {
                    return item;
                }
            }
        }
    } else {
        return default_value;
    }
}

export function removeItemFromLocalStorage(key) {
    const item = localStorage.getItem(`${key}`);
    if (item) {
        return localStorage.removeItem(`${key}`);
    } else {
        return true;
    }
}