import React from "react";
import M3_A_AnchoredOverlay from "./anchored-overlay";
import {useProvidedStateOrCreate} from "../hooks";
import M3_A_ActionList from "./action-list";
import M3_A_IconButton from "./icon-button";
import {m3_icon_map} from "../icons/icon-map";
import M3_A_Heading from "./heading";

/*
* This is the ActionMenu atom
 */

const _props = {

};

// contains an action list

function M3_A_ActionMenu({
                             renderAnchor = () => (
                                 <button>Click me</button>
                             ),
                             hide_on_click,
                             header,
                             overlay,
                             open,
                             onOpenChange = () => {
                             },
                             lists = []
                         }) {
    const [combined_open_state, setCombinedOpenState] = useProvidedStateOrCreate(open, onOpenChange, false)
    const onOpen = React.useCallback(() => setCombinedOpenState(true), [setCombinedOpenState])
    const onClose = React.useCallback(() => setCombinedOpenState(false), [setCombinedOpenState])

    const overlay_props = {
        placement: overlay?.placement || "bottom-end",
        size: overlay?.size || "base",
        hide_on_click
    };

    return <M3_A_AnchoredOverlay {...overlay_props} renderAnchor={renderAnchor}>
        {header && <div className="px-4 flex border-b py-2 border-gray-200">
            <div className="flex-grow flex">
                {!!header.onBack && <div className="flex-none -ml-1 mr-1">
                    <M3_A_IconButton compact variant={`minimal`} size="sm" adjust_icon_size={0} square
                                     onClick={header.onBack}
                                     icon={m3_icon_map.solid["back"]}/>
                </div>}
                <M3_A_Heading children={header.title} size="h6"/>
            </div>
            <div></div>

        </div>}
        <div className="p-2">
            {lists.map((list, i) => {
                return <M3_A_ActionList size="sm" key={i} {...list} />;
            })}
        </div>
    </M3_A_AnchoredOverlay>
}

export default Object.assign(M3_A_ActionMenu);