import React, {useEffect, useState} from 'react';
import PinboardEditor from "../../../../../m3/_legacy_components/pinboard/editor";
import {useCommunity} from "../../../../../config/community";
import {getStartedRecordUsage} from "../../../search";

export function GMMPinboard({id=""}) {
    const community = useCommunity();

    useEffect(function () {
        getStartedRecordUsage(community.id,'grouppinboard');
    }, [community.id])
    return <div>
        <PinboardEditor entity_type={"group"} entity_id={id} member_id={community.member_id} community_id={community.id} />
    </div>
}