import React from "react";
import {useCommunity} from "../../../../config/community";
import {buildCommonMemberProfileSections, buildCommonMemberMeta} from "./utilities";
import M3_A_Text from "../../../../m3/atoms/text";
import M3_A_FormControl from "../../../../m3/atoms/form-control";
import {valuePipeline} from "../../../../m3/_legacy_components/profile-field-renderer/value-pipeline";
import {AddFieldPrompt} from "../../../../m3/_legacy_components/profile-field-renderer";
import FindMeOn from "../../../../m3/_legacy_components/find-me-on";
import M3_A_CopyToClipboard from "../../../../m3/components/copy-to-clipboard";
import M3_C_Block from "../../../../m3/components/block";
import {m3_icon_map} from "../../../../m3/icons/icon-map";

/*
this component accepts basic information about member and community and builds
 */

const renderFieldFooter = (footer, field, member_id, member_data, profile_meta) => {

    const {type, data} = footer;

    if (type === "phone-services") {
        return <FindMeOn field="phone" id={member_id} my_profile={profile_meta.is_my_profile}
                         active={data}/>
    }

    return null;
}

function HighlightSideNav({sections}) {
    return <div className="w-56 flex-none">
        {sections.map((section, k) => {
            return <div key={k}>
                {section.label}
            </div>
        })}
    </div>
}

const getTopData = (section) => {
    if (section?.visibility === "read-only") {
        return {
            icon: m3_icon_map.outlines["hide"],
            intent: "private",
            label: `Only admins can edit this section`
        }
    } else if (section?.visibility === "admins-only") {
        return {
            icon: m3_icon_map.outlines["hide"],
            intent: "private",
            label: `Only admins can view and edit this section`
        }
    } else if (section?.visibility === "private") {
        return {
            icon: m3_icon_map.outlines["hide"],
            intent: "private",
            label: `Only you and admins can view and edit this section`
        }
    }
    return null;
};

export function CommonMemberProfileSectionFields({
                                                     community,
                                                     member_id,
                                                     member_data,
                                                     show_add_on_empty,
                                                     profile_meta,
                                                     profile_actions,
                                                     fields
                                                 }) {
    return fields.map((field, k) => {
        const field_props = {
            ...field,

            profile_meta,
            profile_actions,

            community_uid: community.id,
            fallback: "",
            type: field.type,
        };
        const hide_label = field?.field_data?.meta?.hide_label_in_profile;
        return <div className={`${field?.field_data?.display?.multi_column ? "col-span-2" : ""}`}
                    key={k}>
            <M3_A_FormControl group label={hide_label ? "" : field.label}>

                {field.is_empty ? (show_add_on_empty && field?.field_data?.meta?.editable ?
                    <AddFieldPrompt field={field.label} onClick={() => {
                        profile_actions?.onAddField?.(field.accessor)
                    }}/> : <M3_A_Text color="text-gray-500">—</M3_A_Text>) : <div className="flex">
                    <M3_A_Text size="base" color="text-gray-800">
                        {valuePipeline(field.value, field_props)}
                    </M3_A_Text>
                    {field?.field_data?.meta?.enable_easy_copy && <div
                        className="pl-2 bg-white opacity-0 group-hover:opacity-100 transition-opacity">
                        <M3_A_CopyToClipboard value={field.value}/>
                    </div>}
                </div>}
                {field.footer && renderFieldFooter(field.footer, field, member_id, member_data, profile_meta)}
            </M3_A_FormControl>
        </div>
    })
}

export function CommonMemberProfile({
                                        mode = "view",
                                        member_id,
                                        following,
                                        follows_you,
                                        profile_actions = {},
                                        member_data
                                    }) {
    const community = useCommunity();

    if (!member_id || !member_data) {
        return <>
            No member data
        </>
    }

    const {profile_definitions} = community;

    const {
        sections,
        summary_fields
    } = profile_definitions;

    const profile_meta = buildCommonMemberMeta(member_id, member_data, community, following, follows_you);

    let profile_sections = buildCommonMemberProfileSections(mode, member_id, member_data, sections, community);

    // filter out hidden sections and empty sections
    profile_sections = profile_sections.filter(a => !a.hide_section && a.visible_fields.filter(f => !f.is_empty).length > 0);

    console.log("DATA", {
        sections,
        summary_fields,
        profile_meta,
        profile_sections
    })
    /*
       <div>
            <HighlightSideNav sections={profile_sections} />
            </div>
     */
    return <div className="flex gap-4">
        <div className="flex-grow grid gap-6">
            {profile_sections.map((section, k) => {
                let top = getTopData(section);
                const show_add_on_empty = profile_meta.can_edit_profile && section.can_edit;

                // <VisibilityStatusSymbol visibility={section.visibility}/>
                return <M3_C_Block top={top} intent={top?.intent} title={section.label} actions={[
                    (!!profile_actions?.onOpenEdit && {
                        label: "Edit",
                        onClick: () => profile_actions?.onOpenEdit(section.id)
                    })
                ]} key={k}>
                    <div className="grid grid-cols-2 gap-4 break-all">
                        <CommonMemberProfileSectionFields profile_actions={profile_actions} member_id={member_id}
                                                          member_data={member_data}
                                                          show_add_on_empty={show_add_on_empty}
                                                          profile_meta={profile_meta} community={community}
                                                          fields={section.visible_fields}/>
                    </div>
                </M3_C_Block>
            })}
        </div>
    </div>
}