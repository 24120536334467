import React from "react"
import './styles.css';
import ImageEditor from "../image-editor";
import {ModalBody, ModalContentBlock, ModalHeader} from "../preview-overlay";
import Modal from "../modal";
import SimplePortal from "../simple-portal";
import {imageResizeRequest} from "../../../../api/image-resizer";

function getProps(type) {
    switch (type) {
        case 'cover-photo':
            return {
                modal_size: 'auto',
                borderRadius: 2,
                border: 18,
                height: 225,
                width: 600,
                id: "cover-photo",
            }
        default:
            return {
                modal_size: 'small',
                borderRadius: 34,
                border: 40,
                height: 400,
                width: 400,
                id: "profile-picture",
            }
    }
}

function getRequestOptions(type) {
    switch (type) {
        case 'cover-photo':
            return {
                cover_photo: true
            }
        default:
            return {
                profile_picture: true
            }
    }
}

function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export function EditProfilePictureWrapper({data, onClose}) {
    return <EditImageModal inline scope_id={data.community_uid} onChange={data.onChange} onClose={onClose}/>;
}

export function EditCoverPhotoWrapper({data, onClose}) {
    return <EditImageModal inline type="cover-photo" scope_id={data.community_uid} onChange={data.onChange} onClose={onClose}/>;
}

export default function EditImageModal({
                                           inline,
                                           onClose = () => {
                                           }, type = "profile-picture", onChange = () => {
    },
                                           scope_id = ""
                                       }) {

    const p = {
        ...getProps(type),
        onSave: (file) => {
            const res = (resp) => {
                console.log("RESP", resp)
                if (resp && resp.data && resp.data.url) {
                    onChange(resp.data.url, resp.data.color)
                } else {

                }
            };

            const uid = randomIntFromInterval(0, 14294323);

            const metadata = {
                path: `${scope_id ? `${scope_id}/` : ``}${type === 'profile-picture' ? "profile_pictures" : "cover_photos"}/${uid}`,
                uid
            };

            imageResizeRequest(file, res, "POST", {
                ...getRequestOptions(type),
                ...metadata
            });
        },
        onCancel: () => {
            onClose()
        }
    };

    let c = <>
        <ModalHeader onClose={() => onClose()} title="Edit Photo"/>
        <ModalBody>
            <ModalContentBlock>
            <ImageEditor {...p} />
            </ModalContentBlock>
        </ModalBody>
    </>;

    if (inline) {
        return c;
    }

    // onClose
    return <SimplePortal>
        <Modal size={p.modal_size} center>
            {c}
        </Modal>
    </SimplePortal>
};