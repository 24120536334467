import React from "react"
import './styles.css';
import {Toggle} from "../toggle";

export function ToggleWithTitle({
                                    title = "Make Private Space",
                                    subtitle = "When private, a space can only be viewed or joined by invitation.",
                                    checked,
                                    onToggle
                                }) {
    return <div className="flex gap-8 items-center py-1">
        <div className="flex-grow">
            <div className="font-bold text-sm flex-grow text-gray-800">{title}</div>
            <div className="text-sm text-gray-500">
                {subtitle}
            </div>
        </div>
        <div>
            <Toggle checked={checked} onToggle={() => {
                if(onToggle) {
                    onToggle();
                }
            }} layout={"switch"}/>
        </div>
    </div>
}