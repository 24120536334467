import React, {useState} from "react"
import './styles.css';
import {UniversalIcon} from "../entity-card";
import {MapPinIcon} from "@heroicons/react/24/outline";
import FollowButton from "../follow-button";
import DataWrapper from "../data-wrapper";
import {DEFAULT_USER_PHOTO, useCommunity} from "../../../config/community";

import {useNavigate} from "react-router-dom";
import {ChevronDownIcon, ChevronLeftIcon, EllipsisHorizontalIcon} from "@heroicons/react/20/solid";
import {ArrowAction} from "../../../routes/community/manage-member/edit-member";
import {XMarkIcon} from "@heroicons/react/24/solid";
import {ContentPopupMenu} from "../content-popup/components";
import SimpleTooltip from "../tooltip";
import {RichDescription} from "../entity-profile-card";
import {IconAction} from "../../../routes/auth/sign-in";
import {searchHighlightText} from "../../../routes/community/search";
import {CIRole} from "../../icons/_legacy/custom/Role";
import {CIModerator} from "../../icons/_legacy/custom/Moderator";
import {utils_dates_format} from "../../../../common/utilities/dates";
import {buildImageUrl} from "../../../../common/utilities/images";

function MiniImage({image}) {
    let s = {};
    if (image) {
        s.backgroundImage = `url(${image})`;
    }
    return <div className="w-4 h-4 bg-cover bg-center" style={{borderRadius: '34%', ...s}}/>
}

export function PopupContentWrapper({children}) {
    return <div className="w-72 bg-white content-popup-shadow max-w-xs rounded-lg">
        {children}
    </div>
}

function Action({type, onClick, id, actions, metadata}) {

    if (type && type === "group-member-more") {
        let items = [];

        if (metadata.can_remove  && (metadata.is_organizer || metadata.is_admin)) {
            items.push({
                onClick: actions.removeEntityFromGroup,
                label: "Remove from Group"
            });
        }
        if (metadata.can_make_organizer && (metadata.is_organizer || metadata.is_admin)) {
            items.push({
                onClick: actions.makeMemberOrganizer,
                label: "Make Organizer"
            });
        }

        if (items.length === 0) {
            return null;
        }

        const pp = <PopupContentWrapper>
            <ContentPopupMenu items={items}/>
        </PopupContentWrapper>;
        return <div>
            <SimpleTooltip hideOnClick simple trigger='click' interactive text={pp} placement='bottom-end'>
                <ArrowAction>
                    <EllipsisHorizontalIcon/>
                </ArrowAction>
            </SimpleTooltip>
        </div>
    }
    if (type && type === "group-moderator-more") {
        let items = [];

        if (metadata.can_remove) {
            items.push({
                onClick: actions.removeOrganizer,
                label: "Remove Organizer"
            });
        }

        if (items.length === 0) {
            return null;
        }

        const pp = <PopupContentWrapper>
            <ContentPopupMenu items={items}/>
        </PopupContentWrapper>;
        return <div>
            <SimpleTooltip hideOnClick simple trigger='click' interactive text={pp} placement='bottom-end'>
                <ArrowAction>
                    <EllipsisHorizontalIcon/>
                </ArrowAction>
            </SimpleTooltip>
        </div>
    }
    if (type && type === "follow") {
        return <FollowButton updateFollowing={(fl) => {
        }} id={id} type="member"/>
    }
    if (type && type === "remove") {
        return <div>
            <ArrowAction onClick={() => onClick()}>
                <XMarkIcon/>
            </ArrowAction>
        </div>
    }
    return null;
}

const sub_icon_set = {
    'role': <CIRole/>,
    'organizer': <CIModerator/>
};

function Item({
                  topper,
                  minimal,
                  interactive,
                  icon = {
                      type: "custom-icon",
                      icon: <MapPinIcon/>
                  },
                  onClick = () => {
                  },
                  meta = [],
                  action,
    simple_description,
                  actions,
                  expand_component,
                  expandable,
                  sub_icon,
                  subtitle,
                  loading,
                  description,
                  title = ""
              }) {
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();

    if (loading) {
        return <div
            className={`${minimal ? "" : "p-2 bg-gray-100 rounded-lg"} flex gap-2 rounded-lg px-2 pb-1.5 pt-1.5`}>
            <div className="h-10 w-10 flex-none rounded-lg bg-gray-100"/>
            <div className="flex-grow">
                <div className="h-4 w-1/2 rounded-lg bg-gray-100 mt-1"/>
                <div className="h-4 w-1/3 rounded-lg bg-gray-100 mt-1"/>
            </div>
        </div>;
    }
    return <>
        <div
            className={`${minimal ? "" : "p-2 bg-gray-100 rounded-lg"} ${interactive ? "hover:bg-gray-100 rounded-lg px-2 pb-1.5 pt-1.5" : ""}`}>
            {topper && <div>
                {topper}
            </div>}
            <div className={`flex space-x-2.5`}>
                <div className="flex-none flex items-start relative w-10 h-10">
                    <UniversalIcon {...icon} dims="h-10 w-10" minimal={minimal}/>
                    {sub_icon && <div style={{height: "1.1rem", width: "1.1rem"}}
                                      className="absolute -right-1 -bottom-1 bg-white rounded-md">
                        <div style={{height: "1.1rem", width: "1.1rem"}}>
                            {sub_icon_set[sub_icon]}
                        </div>
                    </div>}
                </div>
                <div className="flex-grow pl-0.5">
                    <div className="flex flex-grow items-center">
                        <div className="flex flex-grow flex-col items-baseline">
                            <div onClick={() => {
                                if (onClick) {
                                    onClick();
                                }
                            }}
                                 className={`text-base -mt-px ${!!onClick ? "hover:underline cursor-pointer" : ""}   text-gray-800 font-bold`}>
                                {title}
                            </div>
                            {subtitle && <div className="text-sm -mt-0.5 text-gray-600">
                                {subtitle}
                            </div>}
                        </div>
                        <div className="pr-2 space-x-2">
                            {action && <Action {...action} />}
                        </div>
                    </div>
                    {simple_description && description && <div className="pt-1 text-sm text-gray-700 pr-2">
                        {description}
                    </div>}
                    {!simple_description && description && <div className="pt-1 text-sm text-gray-700 pr-2">
                        <RichDescription str={description} navigate={navigate} inline={true} line_clamp={3}/>
                    </div>}
                    {meta.length > 0 && <div className="mt-1.5 flex flex-wrap gap-3">
                        {meta.map((mt, index) => {
                            return <div key={index} className="flex space-x-1 text-xs font-medium items-center">
                                {mt.icon && <div className="text-gray-500 w-4 h-4">{mt.icon}</div>}
                                {mt.image && <MiniImage image={mt.image}/>}
                                <div className="text-gray-600">{mt.label}</div>
                            </div>
                        })}
                    </div>}
                    <div></div>
                </div>
                {expandable || (actions && actions.length>0) &&<div className="flex items-center space-x-2">
                    {expandable && <div>
                        <IconAction onClick={() => {
                            // toggle
                            setExpanded(!expanded);
                        }}>
                            {expanded ? <ChevronDownIcon/> : <ChevronLeftIcon/>}
                        </IconAction>
                    </div>}
                    {actions && actions.map((act, key) => {
                        return <Action key={`${act.type}-${act.key}`} {...act} />
                    })}
                </div>}
            </div>
        </div>
        {expanded && <div>
            {expand_component}
        </div>}
    </>
}

function TopperContents({data, id}) {
    const navigate = useNavigate();
    const community = useCommunity();
    if (!data.name) {
        return null;
    }
    return <div className="pb-1">
        <div className="text-xs text-gray-600 ">
            <span>Posted in&nbsp;</span>
            <span onClick={() => {
                navigate(`/c/${community.domain}/space/${id}`);
            }} className="font-medium cursor-pointer hover:underline">{data.name || ""}</span>

        </div>

    </div>
}

function Topper({id, type}) {
    return <DataWrapper id={id} type={type}>
        <TopperContents id={id}/>
    </DataWrapper>
}

function getDescription(plain_text,query) {
    if(!plain_text) {
        return "";
    }

    if(!query) {
        return plain_text.slice(0, 200);
    }

    const total_length = plain_text.length;

    const index = plain_text.toLowerCase().indexOf(query.toLowerCase());

    if(index === -1) {
        return plain_text.slice(0, 200);
    }

    const start_index = Math.max(0, index - 40);

    // find word boundary after start_index
    const next_space_index = plain_text.indexOf(" ", start_index);

    let final_start_index = start_index;

    if(start_index<40) {
        final_start_index = 0;
    } else if(next_space_index !== -1) {
        final_start_index = next_space_index;
    }

    const end_index = Math.min(total_length, index + 160);

    let final_end_index = end_index;

    const str = plain_text.slice(final_start_index, final_end_index);

    const is_at_end = final_end_index === total_length;

    /*
    console.log('ANALYZE',{
        total_length,
        start_index,
        final_start_index,
        next_space_index,
        str_length: str.length,
        end_index
    });

     */


    return <span>{final_start_index===0?"":"..."}{searchHighlightText(str,query)}{is_at_end?"":"..."}</span>
}

function OverrideMessage({result, query, community_uid, data}) {
    const payload = {
        title: result.title,
        subtitle: `${data.author_name||""} · ${!data.created_at?"":utils_dates_format(data.created_at).full_date}`,
        icon: {
            type: "small-image",
            image: buildImageUrl(data.author_image || DEFAULT_USER_PHOTO)
        },
        onClick: result.onClick,
        topper: data.context_id ?
            <Topper id={data.context_id} type={`community_entities.${community_uid}.groups`}/> : null,
        description: getDescription(data.plain_text,query)
    };

    return <Item simple_description {...payload} />
}

export default function EntityList({items = [], extra_props={}, padding_x = "px-2", type = "roles", interactive, minimal, community_uid}) {

    return <div className={`flex ${minimal ? "" : "gap-2"} ${padding_x} flex-col`}>
        {items.map((it, index) => {
            // todo this is a wonky flow
            if (it.override) {
                return <DataWrapper key={`${it.id}`} id={it.id} type={`community_content.${community_uid}.posts`}>
                    <OverrideMessage {...extra_props} community_uid={community_uid} result={it}/>
                </DataWrapper>
            }
            return <Item {...extra_props} minimal={minimal} key={index} type={type} interactive={interactive} {...it} />
        })}
    </div>
};