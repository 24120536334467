import SimpleTooltip from "../../../m3/_legacy_components/tooltip";
import {LockClosedIcon} from "@heroicons/react/24/solid";
import {QuillIcon} from "../../../m3/icons/_legacy/custom/Quill";
import {ClockIcon, GlobeAltIcon, UsersIcon, MapPinIcon} from "@heroicons/react/24/outline";
import {GroupPrimaryButton} from "./primary-button";
import {getAdvancedImageBackgroundStyle} from "../../../m3/utilities/image-background";
import {getNameInitials} from "../../../m3/utilities/get-name-initials";
import React from "react";
import {getImageType, isInLast30Days} from "./utilities";
import {GroupInsight} from "./misc-components";
import {utils_dates_format} from "../../../../common/utilities/dates";
import {buildImageUrl} from "../../../../common/utilities/images";

export function buildGroupProfileCard(data, unaverse, community, flags = {all_names: []}, fns = {}) {
    let title, message, ex = {}, suffix = null, invert_colors, footer, tags = [], buttons = [], subtitle, meta = [],
        stats = [];
    // cover_photo = "pattern_jupiter_#ffffff"
    // cover_photo_color = "#094c73"
    const email_list_power_up = data?.['power_ups']?.['unaty__mailing_lists'];

    const default_size = ""; //_large

    const cover_photo_size = flags.cover_photo_size !== undefined ? flags.cover_photo_size : default_size;

    if (flags.does_not_exist) {
        title = "This group doesn't exist";
        subtitle = "Try searching for another.";
    } else if (data) {
        title = data.name;
        if (!flags.minimal && data.handle) {
            //subtitle = `@${data.handle}`;
        }

        if (data.closed && flags.show_title_icon) {
            suffix = <SimpleTooltip text="Closed">
                <span style={{width: '0.9rem'}} className="inline-flex h-auto text-gray-800">
                    <LockClosedIcon/>
                </span>
            </SimpleTooltip>;
        }

        if (isInLast30Days(data?.created_at)) {
            tags.push({
                type: 'new'
            })
        }

        if (data?.moderators?.user_uids?.[community.member_id]) {
            tags.push({
                type: 'organizer'
            })
        }


        if (flags.minimal) {
            meta.push({
                label: Object.keys(data?.user_uids || {}).length,
                icon: <UsersIcon/>
            })
            if (data?.total_posts) {
                meta.push({
                    icon: <QuillIcon/>,
                    label: data.total_posts || 0
                });
            }
        } else {
            meta.push({
                label: data?.closed ? "Closed" : "Open",
                icon: data?.closed ? <LockClosedIcon/> : <GlobeAltIcon/>
            })
const members = Object.keys(data?.user_uids || {}).length;
            const members_label = members === 1 ? "Member" : "Members";
            meta.push({
                label: `${members} ${members_label}`,
                icon: <UsersIcon/>,
                onClick: () => {
                    if (fns.onOpenMembers) {
                        fns.onOpenMembers();
                    }
                }
            })

            if (data?.location) {
                meta.push({
                    label: data.location,
                    icon: <MapPinIcon/>
                })
            }

            if (data?.last_activity) {
                meta.push({
                    label: `Last activity ${utils_dates_format(data.last_activity).short_date}`,
                    icon: <ClockIcon/>
                })
            }

            if (data?.user_uids) {
                let n = Object.keys(data?.user_uids || {}).length;
                stats.push({
                    count: n,
                    label: `Member${n === 1 ? "" : "s"}`,
                    onClick: () => {
                        if (fns.onOpenMembers) {
                            fns.onOpenMembers();
                        }
                    }
                });
            }

            if (data?.total_posts) {
                let n2 = data.total_posts || 0;
                stats.push({
                    count: n2 || 0,
                    label: `Post${n2 === 1 ? "" : "s"}`
                });
            }
        }

        if (data && data.user_uids) {
            buttons.push(<GroupPrimaryButton group={data} id={data?.id}/>);
        }

        footer = <GroupInsight flags={flags} group={data}/>;

        invert_colors = data?.cover_photo || data?.cover_photo_color;

        ex = {
            desc: data.purpose,
            image: buildImageUrl(data?.cover_photo, '_large'),
            image_color: data?.cover_photo_color,
            cover_style: getAdvancedImageBackgroundStyle("group", data?.cover_photo || "", data?.cover_photo_color || "", "auto", cover_photo_size),

            features: {
                edit_cover: !!fns.onOpenEditCoverPhoto,
                onOpenEditCoverPhoto: () => fns.onOpenEditCoverPhoto(),
            },

            stats,

            icon: {
                onClick: () => {
                    community.openImage({
                        url: buildImageUrl(data?.profile_picture, ''),
                        color: data?.profile_picture_color
                    })
                },
                string: getNameInitials(data?.name, flags.all_names || []),
                image: buildImageUrl(data?.profile_picture, '_medium'),
                type: getImageType(data, flags),
                color: data?.color || data?.profile_picture_color || "",
                emoji: data?.emoji,
                interactive: true,
                fade_hover: true,
                large: false
            },
            meta
        };
    } else {
        title = "Not found";
    }

    return {
        title,
        subtitle,
        suffix,
        footer,
        tags,
        loading: !!flags.loading,
        invert_colors,
        message,
        // subtitle: getMemberTypeName(data, community),

        icon: {
            image: "",
            type: "image",
            large: true
        },

        layout: {
            large_image: false
        },
        buttons,
        ...ex
    }
}