import React from "react";

/*
* This is the Modal atom
 */

const _props = {
    children: {
        type: "node",
        required: true
    },
    stack_count: {
        type: "number",
        required: false
    },
    max_width: {
        type: "string",
        required: false,
        fallback: "max-w-xl",
        options: ["max-w-xs", "max-w-sm", "max-w-md", "max-w-lg", "max-w-xl", "max-w-2xl", "max-w-3xl", "max-w-4xl", "max-w-5xl", "max-w-6xl", "max-w-7xl"]
    }
};

function Stack({count = 1, max_width = "max-w-xl"}) {
    return <div className="absolute top-1.5 sm:top-9 w-full">
        <div
            className={`${max_width} mx-auto bg-gray-100 z-10 border border-gray-300 h-3 rounded-t-md popup-shadow ${count === 2 && "opacity-0"}`}/>
        <div
            className={`${max_width} mx-auto bg-gray-50 z-20 border border-gray-200 h-4 -mt-2 rounded-t-md popup-shadow`}/>
    </div>
}

export default function M3_A_Modal({
                                       children,
                                       stack_count,
                                       max_width
                                   }) {
    let classes = `${max_width} z-10 flex min-h-[20rem] overflow-hidden flex-col border border-gray-200 popup-shadow mx-auto relative bg-white rounded-t-lg h-full sm:h-auto sm:rounded-b-lg sm:max-h-[70vh]`;

    return (<>
        <div className={classes}>
            {children}
        </div>
        {stack_count > 1 && <Stack count={stack_count}/>}
    </>);
}