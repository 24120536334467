import {authFetch} from "../../../../api/network";
import {collection, doc, getDoc, getDocs, limit, query, where} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";

export async function updateMemberInterests(community, interests) {
    const payload = {
        community_uid: community.uid,
        member_id: community.member.id,
        interests: interests
    };
    return new Promise((resolve, reject) => {
        const res = () => {
            resolve();
        }
        authFetch('/members/edit-interests/', res, res, 'POST', {payload})
    });
}

export async function api_downloadMemberContact(payload) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        authFetch("/members/download-contact", res, res, "POST", {payload});
    })
}
export async function getMemberProfileByEmail(uid, email) {
    if (!uid || !email) {
        console.error('getMemberProfile', uid, email)
        return null;
    }
    const ref = collection(db, 'community_members', uid, 'members');
    const q = query(ref, where("account_email", "==", email), limit(1));
    return await getDocs(q)
        .then(snap => {
            return !snap.empty ? {...snap.docs[0].data(), id: snap.docs[0].id} : {does_not_exist: true};
        });
}

export async function getMemberProfile(uid, handle) {
    if (!uid || !handle) {
        console.error('getMemberProfile', uid, handle)
        return null;
    }
    const could_be_member_id = handle && handle.length === 10 && (handle.toUpperCase() === handle);

    const ref = collection(db, 'community_members', uid, 'members');
    const q = query(ref, where("handle", "==", handle), limit(1));
    return await getDocs(q)
        .then(snap => {
            if (snap.empty) {
                if (could_be_member_id) {
                    return getDoc(doc(db, 'community_members', uid, 'members', handle))
                        .then(doc => {
                            if (doc.exists) {
                                return {
                                    id: doc.id,
                                    ...doc.data(),
                                    redirect_to_handle: true
                                };
                            }
                            return {does_not_exist: true};
                        })
                }
            }
            return !snap.empty ? {...snap.docs[0].data(), id: snap.docs[0].id} : {does_not_exist: true};
        });
}

export async function member_changeProfilePicture(community, {url, color}, member_id) {
    const p2 = {
        community_uid: community.uid,
        id: member_id||community.member_id,
        member_id: community.member_id,
        user_uid: community.member.user_uid,
        color: color,
        url: url.split("_large")[0].replace('?alt=media', '')
    };
    return new Promise(resolve => {
        const r = () => {
            community.updateMemberProfile()
                .then(mp=>{
                    resolve({
                        ...mp,
                        id: community.member_id||""
                    })
                });
        };
        authFetch('/members/set-profile-picture', r, r, "POST", {payload: p2});
    })
}

export async function member_changeCoverPhoto(community, {url, color}) {
    const p2 = {
        community_uid: community.uid,
        id: community.member_id,
        member_id: community.member_id,
        user_uid: community.member.user_uid,
        color: color,
        url: url.split("_large")[0].replace('?alt=media', '')
    };
    return new Promise(resolve => {
        const r = () => {
            community.updateMemberProfile()
                .then(mp=>{
                    resolve({
                        ...mp,
                        id: community.member_id||""
                    })
                });
        };
        authFetch('/members/set-cover-photo', r, r, "POST", {payload: p2});
    })
}

export async function member_editOtherProfile(community,id,{profile,updated_fields}) {
    const payload = {
        ...profile,
        community_uid: community.uid,
        id,
        member_id: community.member_id,
        user_uid: community.member.user_uid,
        _updated_fields: updated_fields
    };

    return new Promise(resolve => {
        const r = () => {
            resolve();
        };


        authFetch('/members/update-profile', r, r, "POST", {payload});
    })
}
export async function member_editProfile(community,id,{profile,updated_fields}) {
    const payload = {
        ...profile,
        community_uid: community.uid,
        id,
        member_id: community.member_id,
        user_uid: community.member.user_uid,
        _updated_fields: updated_fields
    };

    return new Promise(resolve => {
        const r = () => {
         resolve();
        };

        authFetch('/members/update-own-profile', r, r, "POST", {payload});
    })
}

export async function __api_template(community,{}) {
    const payload = {};
    return new Promise(resolve => {
        const r = () => {
            resolve();
        };
        authFetch('/', r, r, "POST", {payload});
    })
}