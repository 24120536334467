import { useEffect } from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../config/auth";

export default function RedirectHandler() {
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if(user) {
            const urlParams = new URLSearchParams(window.location.search);
            const redirectTo = urlParams.get('redirectTo');
            if(redirectTo) {
                navigate(redirectTo);
            }
        }
    }, [user]);

    return null;
}