import React, {useEffect, useRef, useState} from 'react';
import {ContentState, convertFromRaw, EditorState} from 'draft-js';
import {
    ensureExtension,
    image_uploadAndResize,
    RichTextEditor
} from "../../../../features/forum/editor/rich-text-editor";
import {MAX_FILE_SIZE, POST_ACCEPTED_ATTACHMENT_TYPES, POST_ACCEPTED_IMAGE_TYPES} from "../../../../config/defaults";
import {useToasts} from "../../../../config/toasts";
import dayjs from "dayjs";
import {useCommunity} from "../../../../config/community";
import {getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL} from "firebase/storage";
import {WriteManageAttachments} from "../attach/inline";
import {parseRawContent} from "../../../../features/forum/forum/api";
import {image_utilities} from "../../../../../common/utilities/images";

export function prefillContentState(content) {
    if (typeof content === 'string') {
        return ContentState.createFromText(content);
    } else if (content) {
        return content;
    } else {
        return null;
    }
}

function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

async function getImageDimensions(file) {
    return new Promise((resolve, reject) => {
        const fr = new FileReader;

        fr.onload = function () { // file is loaded
            const img = new Image;

            img.onload = function () {

                resolve({width: img.width, height: img.height});
            };

            img.onerror = function () {
                reject();
            }

            img.src = fr.result; // is the data URL because called with readAsDataURL
        };

        fr.readAsDataURL(file)
    })
}


function alreadyUploaded(file, attachments) {
    const file_id = `${file.name}____${file.type}`;
    return attachments[file_id] && attachments[file_id].size === file.size;
}

export function WriteBody({
                              editor_state,
                              onUpdateAttachmentsFolder,
                              updateAttachments = () => {
                              },
                              drive_id = "",
                              add_files_to_folder = false,
                              read_only,
                              folder_id = "",
                              show_formatting_bar,
                              hover_formatting,
                              editor_action,
                              raw,
                              content,
                              onChange
                          }) {
    const [ref3, setRef3] = useState(React.createRef());
    const [ref2, setRef2] = useState(React.createRef());
    const toasts = useToasts();
    const [modal, setModal] = useState(null);
    const [fc, setFc] = useState(0);
    const community = useCommunity();
    const [es_action, setESAction] = useState(null);
    const [files, setFiles] = useState([]);
    const attach_ref = useRef({});
    const [attachments_folder, setAttachmentsFolder] = useState(folder_id === 'posts' ? "" : folder_id);
    const [attachments, setAttachments] = useState({});

    const attachmentListener = useRef(null);
    const mediaListener = useRef(null);
    const focusListener = useRef(null);

    const initial_content = editor_state ? editor_state.getCurrentContent() : raw && typeof parseRawContent(raw) === 'object' ? EditorState.createWithContent(convertFromRaw(parseRawContent(raw))).getCurrentContent() : prefillContentState(content || null);

    const ae = Object.entries(attachments);

    function handleAttachmentTrigger() {
        document.activeElement.blur()
        openFileSelector()
    }

    function handleMediaTrigger() {
        document.activeElement.blur()
        openImageSelector();
    }

    function handleFocusTrigger() {
        document.activeElement.blur()

        setFc(fc + 1);
    }

    useEffect(function () {
        if (!attachmentListener.current) return;
        attachmentListener.current.addEventListener('focus', handleAttachmentTrigger);

        return () => {
            if (!attachmentListener.current) return;
            attachmentListener.current.removeEventListener('focus', handleAttachmentTrigger)
        }
    }, [])

    useEffect(function () {
        if (!mediaListener.current) return;
        mediaListener.current.addEventListener('focus', handleMediaTrigger);

        return () => {
            if (!mediaListener.current) return;
            mediaListener.current.removeEventListener('focus', handleMediaTrigger)
        }
    }, [])

    useEffect(function () {
        if (!focusListener.current) return;
        focusListener.current.addEventListener('focus', handleFocusTrigger);

        return () => {
            if (!focusListener.current) return;
            focusListener.current.removeEventListener('focus', handleFocusTrigger)
        }
    }, [])

    useEffect(function () {
        attach_ref.current = attachments;
        updateAttachments(attachments);
    }, [attachments])

    function removeAttachment(a, ind) {
        const uniq = `${a.name}____${a.type}`;

        const new_attachments = {...attachments};

        delete new_attachments[uniq];

        setAttachments(new_attachments);
    }

    function openImageSelector() {
        ref3.current.click();
    }

    function openFileSelector() {
        ref2.current.click();
    }

    function imageSelected(e) {
        fileSelected(e, 'image');
    }

    function fileSelected(e, type = 'file') {

        const ff = Array.from(e.target ? e.target.files : e);

        if (!ff[0]) {
            return;
        }

        // limit to max of 10 total attachments
        if (Object.keys(attachments).length + ff.length > 10) {
            toasts.addToast({
                text: `You can only attach up to 10 files`,
                intent: 'danger'
            });
            return;
        }

        const more_than_six = ff.length > 6;

        if (more_than_six) {
            toasts.addToast({
                text: `Add up to 6 files at a time`,
                intent: 'info'
            });
            return;
        }

        const final_cut = ff.slice(0, 6);

        let final_files = [];
        let images = [];

        ff.forEach(file => {
            let filesize = ((file.size / 1024) / 1024).toFixed(6); // MB

            const type_image = type === 'image';
            const type_file = type === 'file';

            const already_uploaded = alreadyUploaded(file, attachments);

            if (already_uploaded) {
                toasts.addToast({
                    text: `File already attached`,
                    intent: 'info'
                });
            } else if (filesize > MAX_FILE_SIZE) {
                toasts.addToast({
                    text: `${file.name} (${filesize}) is too large, it must be under 20 MB`,
                    intent: 'danger'
                });
            } else if (!type_image && !type_file) {
                toasts.addToast({
                    text: `${file.name} - invalid file type (${file.type})`,
                    intent: 'danger'
                });
            } else {

                if (type_image) {
                    if (images.length === 0) {
                        images.push(file);
                    }
                } else {
                    if (final_files.length > 5) {
                        toasts.addToast({
                            text: `You can upload up to six files, ${file.name} is being ignored`,
                            intent: 'danger'
                        });
                    } else {
                        final_files.push(file)
                    }
                }
            }
        })

        if (final_files.length === 0 && images.length === 0) {
            return;
        }

        if (images.length > 0) {
            toasts.addToast({text: 'Uploading images..', intent: 'info'});
        }

        // todo prevent continuing until all data is loadded

        console.log("IMAGES",images)
        images.forEach(img => {
            image_utilities
                .handleFileLoad(img, {
                    base_path: `${community.uid}/posts/_temp/images`,
                })
                .then(image => {
                    if (!image || !image.ok) {
                        console.log("Error loading image", image)
                        toasts.addToast({
                            text: "Error loading image",
                            intent: "danger"
                        });
                        return;
                    }
                    // add default block
                    // todo add file data url for preview
                    const init_id = randomIntFromInterval(1, 10000000);
                    setESAction({
                        id: init_id,
                        type: 'add-block',
                        block_type: 'image-with-caption',
                        data: {
                            url: "",
                            width: image.width,
                            height: image.height,

                            color: image?.color,

                            preview_file: image.file,

                            caption: "",
                            loading: true
                        }
                    })

                    const removeBlock = () => {
                        setESAction({
                            id: randomIntFromInterval(1, 10000000),
                            type: 'remove-block',
                            last_es_action_id: init_id,
                            data: {}
                        })
                    };

                    image_utilities
                        .uploadImageFile(image)
                        .then((data) => {
                            if(!data) {
                                toasts.addToast({
                                    text: "Error uploading image",
                                    intent: "danger"
                                });
                                removeBlock();
                                return;
                            }

                            const parsed_data = JSON.parse(data.data);
                            const successful_outputs = parsed_data.successful_outputs;

                            if (!successful_outputs[0].mediaLink) {
                                toasts.addToast({
                                    text: "Error uploading image",
                                    intent: "danger"
                                });
                                removeBlock();
                                return;
                            }

                            setESAction({
                                id: randomIntFromInterval(1, 10000000),
                                type: 'update-block-data',
                                last_es_action_id: init_id,
                                data: {url:successful_outputs[0].mediaLink, color: image?.color, loading: false}
                            })
                        })
                });
        });

        if (final_files.length > 0) {
            setFiles(final_files);
            /*
              setModal('select-attachments-folder');
             */

            setTimeout(function () {
                handleUploadAttachments(final_files);
            }, 100)
        }
    }

    function handleUploadAttachments(fl_a) {
        setModal("");
        let na = {...attachments};
        const files_iterate = fl_a ? fl_a : files;
        files_iterate.forEach(file => {
            na[`${file.name}____${file.type}`] = {
                status: "loading",
                name: file.name,
                type: file.type,
                size: file.size
            };
            const cb = (url, meta) => {
                let nad = {...attach_ref.current};
                nad[`${meta.name}____${meta.type}`] = {
                    status: "done",
                    name: meta.name,
                    id: randomIntFromInterval(0, 14294323),
                    type: meta.type,
                    url,
                    folder: attachments_folder,
                    size: meta.size
                };
                setAttachments(nad);
                setFiles([]);
            };
            uploadFile(file, file.type, community.uid, attachments_folder, file.name, file.size, {}, cb);
        });
        setAttachments(na);
    }

    function uploadAndResizeImage(file, type, scope_id, folder_id, name, size, extra = {}, cb) {
        const uid = randomIntFromInterval(0, 14294323);
        const fid = add_files_to_folder && folder_id ? folder_id : `${dayjs().format('DD-MM-YYYY')}`;
        const path = `${scope_id}/${fid}/${uid}-${ensureExtension(name)}`;
        image_uploadAndResize(file, path, {fid, uid}, type, extra, cb);
    }

    function uploadFile(file, type, scope_id, folder_id, name, size, extra = {}, cb) {
        const uid = randomIntFromInterval(0, 2423493);
        const fid = add_files_to_folder && folder_id ? folder_id : `${dayjs().format('DD-MM-YYYY')}`;
        const path = `${scope_id}/${fid}/${uid}-${ensureExtension(name)}`;
        const storage = getStorage();
        const fileRef = ref(storage, path);

        const metadata = {
            contentType: type
        };

        const uploadTask = uploadBytesResumable(fileRef, file, metadata);

        uploadTask.on('state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    const meta = {
                        type,
                        size,
                        name
                    };
                    console.log("Meta", meta)
                    cb(downloadURL, meta, extra);
                });

            }
        );
    }

    return <div>
        <RichTextEditor auto_focus={false} show_add_signature={true}
                        show_tools debug={false} editor_action={editor_action}
                        hover_formatting={hover_formatting} es_action={es_action}
                        show_formatting_bar={show_formatting_bar}
                        handleClose={() => {
                        }} mode={"full-screen"}
                        folder_id={""} fc={fc}
                        content_state={initial_content} placeholder={"Start typing.."}
                        onChange={(editor_state) => {
                            onChange(editor_state)
                        }}
        />
        {ae.length > 0 && <div className="pt-2">
            <WriteManageAttachments drive_id={drive_id} selected_folder_id={attachments_folder}
                                    handleSelectFolder={(nfid) => {
                                        setAttachmentsFolder(nfid);
                                        onUpdateAttachmentsFolder(nfid);
                                    }} handleRemove={(a, ind) => {
                removeAttachment(a, ind);
            }} attachments={ae.map(it => {
                return {
                    is_loading: it[1].status === "loading",
                    name: it[1].name,
                    type: it[1].type,
                    url: it[1].url,
                    size: it[1].size
                }
            })}/>
        </div>}
        {!read_only &&
            <input id='new-attachment' multiple type="file" name='attachments' value={[]}
                   onChange={fileSelected.bind(this)} ref={ref2}
                   accept={POST_ACCEPTED_ATTACHMENT_TYPES} style={{display: 'none'}}/>}

        {!read_only &&
            <input multiple={false} id='new-image' type="file" name='image' onChange={imageSelected.bind(this)}
                   ref={ref3} value={undefined}
                   accept={POST_ACCEPTED_IMAGE_TYPES} style={{display: 'none'}}/>}

        <div tabIndex="0" id={`compose-attachment-listener`} ref={attachmentListener}/>
        <div tabIndex="0" id={`compose-media-listener`} ref={mediaListener}/>
        <div tabIndex="0" id={`compose-focus-listener`} ref={focusListener}/>
    </div>
}