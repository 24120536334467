import React, {useEffect, useRef, useState} from "react"
import './styles.css';
import Button from "../button";
import {followEntity, isFollowingEntity, unfollowEntity} from "./api";
import {useCommunity} from "../../../config/community";
import {CheckIcon} from "@heroicons/react/20/solid";
import {BellAlertIcon, BellSlashIcon} from "@heroicons/react/24/outline";
import SimpleTooltip from "../tooltip";


function getText(type) {

    if(type === "post") {
        return {
            "follow": "Get Updates",
            "follow_icon": {
        icon: <CheckIcon/>
    },
            "hover_text": "Unsubscribe",
            "unfollow": "Subscribed"
        }
    }

    return {
        "follow": "Follow",
        "hover_text": "Unfollow",
        "unfollow": "Following"
    }
}

export default function FollowButton({updateFollowing=()=>{},icon_only,type = "member", id = ""}) {
    const [following, setFollowing] = useState(null);
    const community = useCommunity();
    const has_retrieved_data = useRef(false);
    useEffect(function () {
        if (id && type) {
            if(has_retrieved_data.current) {
                return;
            }
            has_retrieved_data.current = true;
            isFollowingEntity(community, id, type)
                .then(res=>setFollowing(res))
        }
    }, [id, type]);

    useEffect(function () {
        updateFollowing(following);
    }, [following])

    function handleUnfollow() {
        unfollowEntity(community,id,type)
            .then(()=>{
                community.handleUpdateMemberFollowing();
            })
        setFollowing(false);
    }

    function handleFollow() {
        followEntity(community,id,type)
            .then(()=>{
                community.handleUpdateMemberFollowing();
            })
        setFollowing(true);
    }

    if (following === null) {
        return null;
    }
    const text = getText(type);

    if(icon_only) {
        if(following) {
            return <SimpleTooltip text="Click to unsubscribe from updates">
                <div className="h-5 w-5 text-green-600 cursor-pointer hover:text-gray-700">
                <BellAlertIcon />
            </div>
            </SimpleTooltip>
        } else {
            return <SimpleTooltip text="Click to get updates">
                <div className="h-5 w-5 text-gray-500 cursor-pointer hover:text-gray-700">
                <BellSlashIcon />
            </div>
            </SimpleTooltip>
        }
    }

    if(following) {
        return <Button left_icon={text.follow_icon} onClick={handleUnfollow} hover_intent="hover:bg-red-600 hover:border-red-600" hover_text={text.hover_text} text={text.unfollow} intent="dark" />
    } else {
        return <Button onClick={handleFollow} text={text.follow}/>
    }
};