import React from "react";

/*
* This is the Card atom
 */

const _props = {};

export function M3_A_CardStack({cards = []}) {
    const [index, setIndex] = React.useState(0);
    const card = cards[index];

    const remaining = cards.length - index;

    if (!card) return null;

    return <div>
        <M3_A_Card {...card} />
    </div>;
}

export default function M3_A_Card({
                                      muted,
                                      children,
                                      sections = [],
    elevated = true
                                  }) {
    return (
        <div className={`border ${muted?"bg-gray-100":"bg-white"}  divide-y divide-gray-200 rounded-xl border-gray-200 ${elevated?"shadow-md":""}`}>
            {sections.map((section, i) => {
                return <div key={section.key} className="py-3 px-3">
                    {section.children}
                </div>;
            })}
            {children && <div className="p-3">
                {children}
            </div>}
        </div>
    );
}