import React from 'react';
import styled from "styled-components";

/*
* This is the Input atom
 */

const Wrapper = styled.div`
    outline: none;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    -webkit-appearance: none;
    position: relative;

    vertical-align: middle;
    background-color: rgb(255, 255, 255);

    display: inline-flex;
    -webkit-box-align: stretch;
    align-items: stretch;
`;

const StyledInput = styled.input`
  
`;

export const m3_input_height_map = {
    "xs": "h-6",
    "sm": "h-7",
    "base": "h-8",
    "lg": "h-9",
    "xl": "h-10"
};

/*
 flex items-center text-sm rounded-lg relative font-medium transition-colors text-gray-700 bg-white border border-gray-300 hover:border-gray-400 hover:bg-gray-50 focus:bg-gray-100 px-3 text-sm h-8
 */
export function M3_A_InputWrapper({variant, fill, size = "base", children}) {
    let s = {}, classes = "";

    const h = m3_input_height_map[size];
    return <Wrapper style={s} variant={variant} className={`rounded-lg ${classes} ${h} text-${size} items-center ${fill?"w-full":""}`}>
        {children}
    </Wrapper>
}

export default function M3_A_Input({
                                       auto_focus,
                                       onChange,
                                       size = "base",
                                       fill,
                                       onEnter,
                                       name = "",
                                       value,
                                       leading_indicator,
                                       placeholder,
                                       type = "text",
                                       trailing_indicator,
                                       variant = 'default',
                                       sx,
                                       ...rest
                                   }) {
    let styles = {
        outline: "none"
        },
        classes = "w-full border rounded-lg bg-white input-focus";

    if (size) {
        classes += ` ${m3_input_height_map[size]}`;
        classes += ` text-${size}`;
    }

    if (variant === "minimal") {
        classes += " border-transparent";
    } else {
        classes += " ";
    }

    if (fill) {
        classes += " w-full";
    }

    if (sx) {
        styles = {
            ...styles,
            ...sx
        };
    }

    if (leading_indicator) {
        classes += ` ${rest.leading_indicator_leftpad || "pl-7"}`;
    } else {
        classes += " pl-2";
    }

    if (trailing_indicator) {
        classes += " pr-7";
    } else {
        classes += " pr-2";
    }

    function handleKeyDown(e) {
        if (e.keyCode === 13) {
            if (onEnter) {
                onEnter(e);
            }
        }
    }

    let p = {
        onKeyDown: handleKeyDown,
        placeholder,
        type,
        onFocus: (e) => {
            if (rest.onFocus) {
                rest.onFocus(e);
            }
        },
        onBlur: (e) => {
            if (rest.onBlur) {
                rest.onBlur(e);
            }
        },
        onChange: (e) => {
            if (onChange) {
                onChange(e);
            }
        },
        style: styles,
        value
    };

    if (auto_focus) {
        p.autoFocus = true;
    }

    if (name) {
        p.name = name;
    }


    return <M3_A_InputWrapper fill={fill} size={size} variant={variant}>
        <StyledInput variant={variant} className={classes} {...p} />
        {leading_indicator && <div className="absolute inset-y-0 left-0 flex items-center pl-1.5 text-gray-600">
            {leading_indicator}
        </div>}
        {trailing_indicator && <div className="absolute inset-y-0 right-0 flex items-center pr-1.5">
            {trailing_indicator}
        </div>}
    </M3_A_InputWrapper>
}