import React from 'react';
import {useUnaverse} from "../../config/unaverse";
import {LayoutContentBlock, LayoutContentWrapper, LayoutFull} from "../../m3/_legacy_components/app-frame/layouts";
import {SectionHeader} from "../../m3/_legacy_components/entity-grid";
import Button from "../../m3/_legacy_components/button";
import {useCommunity} from "../../config/community";
import {useToasts} from "../../config/toasts";
import {authFetch} from "../../../api/network";

export function CommunityToolsPage() {
    const unaverse = useUnaverse();
    const community = useCommunity();
    const toasts = useToasts();
    const [loading, setLoading] = React.useState(false);

    if (!unaverse.is_superadmin) {
        return <div>
            You are not authorized to view this page.
        </div>
    }

    function runMigration(type, meta = {}) {
        const payload = {type, ...meta};
        setLoading(true);
        toasts.addToast({
            text: `Running migration... [${type}]`,
            type: "info",
        })
        const res = (resp) => {
            console.log("RESP", resp)
            setLoading(false);
            toasts.addToast({
                text: `Migration complete. [${type}]`,
                type: "success",
            })
        }
        authFetch("/superadmin/migrations", res, res, "POST", {payload});
    }

    return <LayoutFull>
        <LayoutContentWrapper directory fill>
            <LayoutContentBlock>
                <SectionHeader title="Actions"/>
                <div className="flex flex-wrap gap-3 pt-2">
                    <Button loading={loading} text="Create Everyone Group" onClick={() => {
                        // create_everyone_group
                        runMigration("create_everyone_group", {
                            community_uid: community.uid,
                            member_id: community.member_id
                        })
                    }}/>
                </div>
            </LayoutContentBlock>
        </LayoutContentWrapper>
    </LayoutFull>
}