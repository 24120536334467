function rgbToYIQ({r, g, b}) {
    return ((r * 299) + (g * 587) + (b * 114)) / 1000;
}

function hexToRgb(hex) {
    if (!hex || hex === undefined || hex === '') {
        return undefined;
    }

    const result =
        /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : undefined;
}

export function colorContrast(colorHex, threshold = 180) {
    if (colorHex === undefined) {
        return '#000000';
    }

    const rgb = hexToRgb(colorHex);

    if (rgb === undefined) {
        return '#000000';
    }

    return rgbToYIQ(rgb) >= threshold ? '#000000' : '#ffffff';
}

export function setAlpha(color,a) {
    const cs = color.replace('rgb(', '');
    const cs2 = cs.replace(')', '');

    const split = cs2.split(',');
    return `rgba(${split[0]},${split[1]},${split[2]},${a})`;
}

export function hexToRgbStr(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)})` : '';
}