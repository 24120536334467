import React from "react"
import './styles.css';
import {TrashIcon} from "@heroicons/react/24/outline";

function Item({icon,label,onClick}) {
    return <div onClick={()=>{
        if(onClick) {
            onClick();
        }
    }} className="group rounded-md hover:bg-gray-100 cursor-pointer w-7 h-7 flex items-center justify-center">
        <div className="h-5 w-5 text-gray-700">
            {icon}
        </div>
    </div>
}

export default function HoverActions({children,actions=[]}) {

    return <div className="relative group">
        <div>{children}</div>
        {actions.length>0&&<div className="absolute hidden group-hover:block content-popup-shadow rounded-md border border-gray-200 bg-white -top-5 -right-2.5">
            <div className="h-8 p-0.5 flex space-x-0.5">
                {actions.map((act,key)=>{
                    return <Item key={key} {...act} />
                })}
            </div>
        </div>}
    </div>
};