import React from "react";
import M3_A_AnchoredOverlay from "../atoms/anchored-overlay";
import M3_A_Text from "../atoms/text";
import {useProvidedStateOrCreate} from "../hooks";
import M3_A_IconButton from "../atoms/icon-button";
import {m3_icon_map} from "../icons/icon-map";

/*
* This is the SelectPanel component
 */

const _props = {};

function M3_C_SelectPanel({
                              active_panel,
                              initial_panel,
                              onPanelChange,
                              onClose = () => {
                              },
                              panels = [],
                              renderAnchor,
                              overlay
                          }) {
    const [panel, setPanel] = useProvidedStateOrCreate(active_panel, onPanelChange, initial_panel || panels[0].id);

    React.useEffect(() => {
        if (active_panel && active_panel !== panel) {
            setPanel(active_panel);
        }
    }, [active_panel]);

    const overlay_props = {
        placement: overlay?.placement || "bottom-start",
        size: overlay?.size || "base",
        hide_on_click: false
    };

    const panel_data = panels.find((p) => p.id === panel);

    const panel_content = <>
        {panel_data?.header && <div className="px-3 py-2 flex border-b border-gray-200">
            <div className="flex gap-0.5 items-center flex-grow">
                {panel_data.header.onBack && <div className="-ml-2" onClick={() => {
                    panel_data.header.onBack();
                }}>
                    <M3_A_IconButton icon={m3_icon_map.solid.back} size="sm" compact variant={`minimal`} square
                                     adjust_icon_size={0}/>
                </div>}
                <M3_A_Text size="sm" color="text-gray-600">{panel_data.header.title}</M3_A_Text>
            </div>
            <div>

            </div>
        </div>}
        <div className="px-1">
            {panel_data?.content?.map((item, index) => {
                if (!item.children) return null;
                return <div key={item.id}>
                    <div className={`py-2 ${item.no_padding ? "" : "px-2"}`}>
                        {item.children}
                    </div>
                    {(index + 1) !== panel_data.content.length && <div className="border-b border-gray-200 mx-3"/>}
                </div>
            })}
        </div>
        {panel_data?.footer && <div className="px-3 py-2 border-t border-gray-200">
            footer
        </div>}
    </>;

    if (!renderAnchor) {
        return panel_content;
    }

    return <M3_A_AnchoredOverlay onClose={onClose} {...overlay_props} renderAnchor={renderAnchor}>
        {panel_content}
    </M3_A_AnchoredOverlay>
}

export default Object.assign(M3_C_SelectPanel);