export function getMemberFlags(id, data, community, extras = {}) {
    const follows_you = !!extras.follows_you;
    const my_profile = data && data.id === community.member.id || false;

    const deactivated = (data && data.archived);
    const suspended = data && data.suspended;
    const does_not_exist = data && data.does_not_exist || (extras.error && extras.error === "does_not_exist");

    return {
        deactivated,
        suspended,
        follows_you,
        does_not_exist,
        can_follow: !my_profile && !deactivated && !suspended,
        my_profile,
        can_edit: !deactivated && !suspended && !does_not_exist && my_profile,
        can_manage: community.member_access.manage_members && !does_not_exist
    }
}