import React from 'react';
import {Avatar} from "../../../../m3/_legacy_components/avatar";
import {FHB_JoinCommunity} from "../blocks/join-community";
import {FHB_FullName, FHB_ProfileFieldsSection, FHB_Contact, FHB_GlobalPolicies, FHB_CustomFieldsSection} from "../blocks/basic";
import {CheckIcon} from '@heroicons/react/20/solid';
import {useNavigate} from "react-router-dom";
import {useIsMobile} from "../../../../m3/hooks/is-mobile";
import {useUnaverse} from "../../../../config/unaverse";
import {getUserPoliciesToAccept} from "../../../../m3/_legacy_components/policy-gates/user";

const EnterSVG = () => <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="14px" height="auto" viewBox="0 0 511.335795 351.447317">
    <g transform="translate(-81.549156,448.956928) rotate(-360.00) scale(0.095238,-0.095238)"
       fill="currentColor" stroke="none">
        <path d="M5784 4700 c-87 -31 -154 -91 -201 -177 l-23 -45 -5 -761 -6 -761
-28 -59 c-37 -74 -91 -128 -165 -165 l-59 -28 -1640 -3 c-903 -2 -1641 1
-1641 5 0 4 103 112 230 239 139 140 239 250 254 279 21 39 25 62 25 147 0 90
-3 104 -31 155 -35 63 -89 116 -155 151 -38 20 -61 24 -144 24 -85 0 -108 -4
-147 -24 -32 -17 -227 -204 -591 -567 -619 -617 -601 -596 -601 -747 1 -152
-20 -125 591 -739 392 -393 557 -552 595 -572 47 -25 65 -28 153 -28 87 0 106
3 152 28 65 34 125 98 158 170 20 42 25 71 25 133 0 131 -17 157 -284 425
-126 127 -230 235 -230 239 0 4 737 8 1637 8 1098 0 1664 3 1718 11 112 15
225 54 341 117 82 45 120 76 215 171 130 130 196 233 248 388 50 148 53 205
49 1008 l-3 746 -29 60 c-57 115 -175 187 -305 186 -35 0 -82 -7 -103 -14z"/>
    </g>
</svg>;

function FlowHeader({title, children, logo}) {
    return <div className="flex-none sticky top-0 bg-white z-50 flex px-4 h-14 border-b border-gray-200">
        <div className="flex flex-grow items-center space-x-2">
            {logo.url&&<div>
                <Avatar size={`h-8 w-8`} url={logo.url}/>
            </div>}
            <div className="text-sm font-semibold text-gray-900">
                {title}
            </div>
        </div>
        <div className={"flex-none flex items-center"}>
            {children}
        </div>
    </div>
}

function PrimaryAction({handleAction, small, skippable, actions, can_go_next, accent_color}) {
    const is_mobile = useIsMobile();
    const btn_colors = !can_go_next ? "grayscale opacity-60" : "hover:opacity-70 ";
    const sizes = small ? "rounded-md py-1 px-2" : "py-3 px-6 rounded-2xl w-full";
    const btn = <div tabIndex="0" onClick={() => {
        if (!can_go_next) {
            return;
        }
        handleAction();
    }} style={{backgroundColor: `${accent_color}`}}
                     className={`${btn_colors} transition-opacity ${sizes} cursor-pointer text-white  text-base font-semibold  text-center `}>
        <span>{small ? "Next" : actions.primary}</span>
    </div>;
    if (is_mobile) {
        return btn;
    }
    return <div>
        <div>
            {btn}
        </div>
        <div className="flex justify-center mt-3">
            {!skippable && !is_mobile && <div className="text-sm text-gray-500 flex space-x-2">
                <div className="">
                    Press Enter
                </div>
                <div className="h-5 w-5 text-gray-400">
                    <EnterSVG/>
                </div>
            </div>}
            {skippable && <div className="text-sm text-gray-500 tracking-tight flex space-x-2">

                <div className="text-blue-600 hover:text-blue-400 cursor-pointer font-medium" onClick={() => {
                    handleAction();
                }}>
                    Complete later
                </div>
            </div>}
        </div>
    </div>
}

function getStepData(step, manifest) {
    if (step === 'intro') {
        return manifest.intro;
    } else if (step === 'outtro') {
        return manifest.outtro;
    } else {
        return manifest.steps[step];
    }
}

function Steps({step, total_steps}) {
    return <div className="text-sm font-medium text-gray-600">
        Step {step + 1} / {total_steps + 1}
    </div>
}

function renderBlock(block, state, context, handlers, meta_map) {

    const pkg = {
        handlers,
        state,
        context,
        block,
        meta_map
    }

    switch (block.type) {
        case 'join_community':
            return <FHB_JoinCommunity {...pkg} />
        case "global_policies":
            return <FHB_GlobalPolicies {...pkg} />
        case 'introduction':
        case 'full_name':
            return <FHB_FullName {...pkg} />
        case 'contact-information':
        case 'contact':
            return <FHB_Contact {...pkg} />
        case 'custom_fields_section':
            return <FHB_CustomFieldsSection {...pkg} />
        case 'details':
        case 'basic-information':
            return <FHB_ProfileFieldsSection {...pkg} />
        default:
            return <div>
                {JSON.stringify(block)}
            </div>
    }
}

function StepHeader({title, description, step, metadata, type, data}) {
    return <div className="space-y-4">
        {metadata.show_steps && <Steps {...metadata} />}
        {step === "outtro" && <div className="p-4 flex justify-center">
            <div className="h-16 w-16 flex items-center justify-center rounded-full bg-green-200 text-green-800">
                <div className="h-8 w-8 flex svg-child-inherit items-center justify-center">
                    <CheckIcon/>
                </div>
            </div>
        </div>}
        <div className={`${step === "outtro" ? "text-center" : ""}`}>
            <div className="text-3xl tracking-tight font-semibold text-gray-900">
                {title}
            </div>
            {description && <div className="text-base mt-4 text-gray-600">
                {description}
            </div>}
        </div>
    </div>
}

const canGoToNextStep = (step, state, step_data, manifest, meta_map) => {
    if (step === 'intro') {
        return stepIsCompleteFunction(step_data, step, state, meta_map);
    } else if (step === 'outtro') {
        return true;
    } else {
        console.log("stepIsCompleteFunction", step_data, step, state, meta_map)
        return stepIsCompleteFunction(step_data, step, state, meta_map);
    }
}

const canGoToLastStep = (step, state, step_data, manifest, meta_map) => {
    if (step === 'intro') {
        return false;
    } else if (step === 'outtro') {
        return true;
    } else {
        return step !== 0;
    }
};

const sample_context = {
    member: {
        about: {
            first_name: "Jane",
            last_name: ""
        }
    }
};

function stepIsCompleteFunction(step_data, step, state, meta_map) {
    const rules = step_data.complete_rules || [];

    for (let i = 0; i < rules.length; i++) {
        const rule = rules[i];
        const {type, field} = rule;
        if (type === 'state-exists') {
            if(field&&(field==='account_email_verified'||field==='account_email')) {
                // pass thru for now

            } else if (state?.[step]?.[field] === undefined || state?.[step]?.[field] === null) {
                console.log('FALSE', rule, state)
                return false;
            }
        } else if (type === "global_policies_up_to_date") {
            return state?.[step]?.privacy_policy && state?.[step]?.terms_of_use && state?.[step]?.code_of_conduct;
        }
    }

    return true;
}

export function FlowHandler({onStart, is_preview, onComplete, manifest = {}, context = {...sample_context}}) {
    const [state, setState] = React.useState({...manifest.state});
    const [step, setStep] = React.useState('intro');
    const navigate = useNavigate();
    const unaverse = useUnaverse();
    const containerRef = React.useRef(null);
    const is_mobile = useIsMobile();

    const step_data = getStepData(step, manifest);

    const {
        skippable = false
    } = manifest.features;

    const user_policies_to_accept = getUserPoliciesToAccept(unaverse.user, unaverse);
    let meta_map = {
        user_policies_to_accept
    };

    React.useEffect(() => {
        if (containerRef.current) {
            containerRef.current.focus();
        }
        onStart();
    }, [])

    if (!step_data) {
        return <div>
            No step data
        </div>
    }

    function handleAction() {
        const pa_data = step_data.actions.primary_action || {type: "next"};
        const {type} = pa_data;
        const focused = document.activeElement;
        if (type === 'next') {
            if (step === 'intro') {
                setStep(0);
            } else if (step === 'outtro') {
                alert('close')
            } else {
                if (!stepIsCompleteFunction(step_data, step, state, meta_map)) {
                    alert('incomplete')
                    return;
                }
                if (step === manifest.steps.length - 1) {
                    setStep('outtro');
                } else {
                    setStep(step + 1);
                }
            }
        } else if (type === 'redirect') {
            onComplete(state);
            const to_path = `/c/${context.domain}/${pa_data.to}`;
            console.log("NAVIGATE TO",to_path)
            navigate(to_path)
        } else {
            alert('unsported action', type)
        }
    }

    function updateStateValue(key, value) {
      //  console.log("updateStateValue", key, value)
        if (Array.isArray(key)) {
            let new_step_data = {...state[step] || {}};
            key.forEach((k, i) => {
                new_step_data[k] = value[i];
            });
            setState({
                ...state,
                [step]: new_step_data
            })
        } else {
            setState({
                ...state,
                [step]: {
                    ...state[step] || {},
                    [key]: value
                }
            })
        }
    }

    function handleOnBlur() {

    }

    const submitHandler = (e) => {
        if (e.key === 'Enter') {
            if(step==='intro') {
                return;
            }
            handleAction();
        }
    };

    const handlers = {
        handleAction,
        updateStateValue,
        handleOnBlur
    };

    const can_go_next = canGoToNextStep(step, state, step_data, manifest, meta_map);
    const can_go_back = canGoToLastStep(step, state, step_data, manifest, meta_map);

    const total_steps = manifest.steps.length;

    const show_steps = !isNaN(step);

    const metadata = {
        show_steps,
        total_steps,
        step,
        can_go_next,
        can_go_back,
    };

    function renderStep() {

       // console.log("STEP METADATA",metadata)

        const hide_action = step_data?.features?.action_hidden_until_valid && !can_go_next;
        return <div className="space-y-4" key={`${step}`}>
            <StepHeader step={step} metadata={metadata} {...step_data} />
            <div className="space-y-4 py-4">
                {step_data.blocks.map((block, k) => {
                    return <div key={k}>
                        {renderBlock(block, state, context, handlers, meta_map)}
                    </div>
                })}
            </div>
            {!hide_action&&<div>
                <PrimaryAction skippable={step_data?.features?.skippable} actions={step_data.actions}
                               can_go_next={can_go_next} can_go_back={can_go_back}
                               accent_color={manifest?.branding?.accent_color} handleAction={handleAction}/>
            </div>}

        </div>
    }

    return <div ref={containerRef} tabIndex="1" className="bg-white overflow-y-auto h-screen outline-none w-full"
                onKeyUp={submitHandler}>
        {manifest.header&&<FlowHeader {...manifest.header}>
            {is_mobile && <PrimaryAction small skippable={step_data?.features?.skippable} actions={step_data.actions}
                                         can_go_next={can_go_next} can_go_back={can_go_back}
                                         accent_color={manifest?.branding?.accent_color} handleAction={handleAction}/>}
        </FlowHeader>}
        <div className="max-w-lg mx-auto px-6 flex-grow pt-20 pb-64 sm:pb-40 flex flex-col justify-center"
             style={{minHeight: 'calc(100vh - 3.5rem)'}}>
            {renderStep()}
        </div>
    </div>
}