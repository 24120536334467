import React from "react";
import M3_A_Breadcrumb from "../atoms/breadcrumb";
import M3_A_Text from "../atoms/text";

/*
* This is the Breadcrumbs component
 */

const _props = {
    crumbs: {
        type: "array",
        required: true
    },
    divider: {
        type: "node",
        required: false,
        default: "/"
    },
    gap: {
        type: "string",
        required: false,
        default: "gap-1"
    }
};

export default function M3_C_Breadcrumbs({
                                             crumbs,
                                             divider = "/",
                                             gap = "gap-1"
                                         }) {
    let classes = "flex items-center";

    classes += " " + gap;

    return (
        <div className={classes}>
            {crumbs.map((crumb, index) => {
                if (index > 0) {
                    return (
                        <React.Fragment key={index}>
                            <div className="px-0.5">
                            <M3_A_Text color="text-gray-500">
                                {divider}
                            </M3_A_Text>
                            </div>
                            <M3_A_Breadcrumb {...crumb}/>
                        </React.Fragment>
                    );
                } else {
                    return (
                        <M3_A_Breadcrumb key={index} {...crumb}/>
                    );
                }
            })}
        </div>
    );
}