import React, {useEffect} from "react"
import './styles.css';
import {
    ChevronLeftIcon,
    XMarkIcon
} from "@heroicons/react/24/outline";
import {useCommunity} from "../../../config/community";
import {useUnaverse} from "../../../config/unaverse";
import {MemberPreview} from "./member";
import {RolePreview} from "./role";
import {EventPreview} from "./event";
import {PostPreview} from "../../../routes/community/message/preview";
import {HelpPreview} from "./help";
import EntityTabs from "../entity-tabs";
import M3_A_Content_View from "../../atoms/content-view";

function ArrowAction({children, active, onClick}) {
    return <div onClick={() => {
        if (onClick) {
            onClick();
        }
    }} className={`w-8 h-8 rounded-full flex items-center justify-center  cursor-pointer 
    ${active ? "hover:bg-gray-200 text-gray-600 bg-gray-100 hover:text-gray-800" : "hover:bg-gray-100 text-gray-500 hover:text-gray-800"}
    `}>
        <div className="w-5 h-5">
            {children}
        </div>
    </div>
}

export function ModalBody({children, no_padding}) {
    return <M3_A_Content_View flex_grow>
        {children}
    </M3_A_Content_View>
    return <div className={no_padding ? "" : `px-6 py-3`}>
        {children}
    </div>
}

export function ModalFooter({left, right}) {
    return <div className="flex pb-6 pt-4 px-6">
        <div className="flex-grow flex items-center gap-2">{left}</div>
        <div className="flex items-center gap-2">{right}</div>
    </div>
}

export function ModalTabsWrapper({children}) {
    return <div className="-mt-3 px-6 -mb-1.5 border-b border-gray-200">
        {children}
    </div>
}

export function ModalContentBlock({children, simple, background, divider}) {
    let classes = "px-4";
    if (divider) {
        classes += " border-b border-gray-300";
    }
    if (background) {
        classes += " bg-gray-100";
    }
    return <div className={classes}>
        <div className={`${simple?"":"flex flex-col gap-3 py-3"}`}>
            {children}
        </div>
    </div>
}

export function ModalHeader({
                                onClose,
                                tabs,
                                onChangeTab,
                                active_tab,
                                fixed_translucent,
                                buttons,
                                sizing = '',
                                divider = true,
                                title = 'Profile',
                                goBack
                            }) {
    const position_transparency = fixed_translucent ? "udk-menublur z-10 sticky top-0 left-0 right-0" : "";
    return <ModalContentBlock simple divider>
        <div className={`${sizing} ${position_transparency} items-center flex ${divider ? "" : ""} pt-2 ${!tabs?"pb-2":""}`}>
            <div className={`flex-grow flex items-center space-x-1`}>
                {!!goBack && <div className="-ml-2">
                    <ArrowAction onClick={() => goBack()}>
                        <ChevronLeftIcon/>
                    </ArrowAction>
                </div>}
                <div className="text-xl tracking-tight font-bold text-gray-800">{title}</div>
            </div>
            <div className="flex items-center pt-0.5 space-x-2">
                {!!onClose && <ArrowAction active onClick={() => onClose()}>
                    <XMarkIcon/>
                </ArrowAction>}
            </div>
        </div>
        {buttons && <div className="flex gap-2 pt-1 pb-2">
            {buttons.map((btn, ind) => {
                return <div key={ind}>
                    {btn}
                </div>
            })}
        </div>}
        {tabs && <div className="-mt-2">
            <EntityTabs tabs={tabs} active={active_tab} onChangeTab={onChangeTab}/>
        </div>}
    </ModalContentBlock>
}


function getSize(type) {
    if (type === 'manage-member') {
        return {
            max_width: '54rem',
            width: "w-full"
        }
    }

    return {
        max_width: '24rem',
        width: "w-96"
    }
}

export function PreviewHeader({onClose, title}) {

    return <div
        className={`udk-menublur z-10 sticky top-0 left-0 right-0 flex items-center border-b border-gray-200 pt-1 h-12 px-4`}>
        <div className="flex-grow">
            <div className="text-base font-semibold text-gray-800 tracking-tight">
                {title}
            </div>
        </div>
        <div>
            {!!onClose && <ArrowAction onClick={() => onClose()}>
                <XMarkIcon/>
            </ArrowAction>}
        </div>
    </div>
}

function getContents(type, props) {
    switch (type) {
        case 'role':
            return <RolePreview {...props} />
        case 'event':
            return <EventPreview {...props} />
        case 'member':
            return <MemberPreview {...props} />
        case 'post':
            return <PostPreview {...props} />
        case 'help':
            return <HelpPreview {...props} />
        default:
            return <div>DEFAULT</div>
    }
}

export default function PreviewOverlay({
                                           type,
                                           data,
                                           id,
                                           onClose = () => {
                                           }
                                       }) {
    const community = useCommunity();
    const unaverse = useUnaverse();
    const {max_width, width} = getSize(type);
    useEffect(function () {
        const el = document.getElementById("preview-wrapper");
        if (el) {
            el.scrollTo({top: 0})
        }
    }, [data])
    return <div id="preview-wrapper"
                className={`flex-none relative preview-overlay w-full overflow-y-auto bg-white h-full`}>
        {getContents(type, {
            community, unaverse, data, onClose, id
        })}
    </div>
};