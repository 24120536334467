export const data_membership_status_map = {
    'active': {
        label: "Active",
        color: "green",
        desc: "Active members are full standing members of the community.",
        access_description: "Active members have full access to the community.",
        sort: 0
    },
    'inactive': {
        label: "Inactive",
        color: "gray",
        desc: "Inactive members are members who are still part of the community but not active.",
        access_description: "Inactive members have limited access to the community.",
        sort: 1
    },
    'pending': {
        label: "Pending",
        color: "yellow",
        desc: "Pending members have applied to join the community and are awaiting approval.",
        access_description: "Pending members have limited access to the community.",
        sort: 2
    },
    'lapsed': {
        label: "Lapsed",
        color: "red",
        desc: "Lapsed members are members who have not paid their dues or met another criteria.",
        access_description: "Lapsed members have limited access to the community.",
        sort: 3
    },
    'suspended': {
        label: "Suspended",
        color: "red",
        desc: "Suspended members have been suspended from the community.",
        access_description: "Suspended members have no access to the community.",
        sort: 4
    },
    'archived': {
        label: "Archived",
        color: "red",
        desc: "Archived members are members who have been archived.",
        access_description: "Archived members have no access to the community.",
        sort: 5
    }
};

export const data_membership_status_arr = Object.entries(data_membership_status_map).map(([k, v]) => {
    return {
        value: k,
        label: v.label,

        _meta: {
            sort: v.sort,
            color: v.color,
            desc: v.desc
        }
    }
})