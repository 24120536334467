import React from 'react';
import styled from "styled-components";
import {PDFPreview} from "./pdf-preview";
import {TXTPreview} from "./txt-preview";
import Button from "../../button";
import {ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/20/solid";

const ImageBox = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  > img {
    max-height: 95%;
    max-width: 95%;
  }
`;

const ArrowButton = styled.div`
  position: absolute;
  height: 44px;
  width: 44px;
  transition: 0.1s opacity ease;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  top: 48%;
  left: ${props => props.dir === 'left' ? '20px' : ''};
  right: ${props => props.dir === 'left' ? '' : '20px'};
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;

  > svg {
    height: 24px;
  }
`;

function ImagePreview({type, url, size}) {
    return <ImageBox>
        <img src={url}/>
    </ImageBox>
}

function PreviewNotPossible({type, url, size}) {
    return <div className="h-full flex flex-col items-center gap-4 justify-center">
        <div className='text-gray-700 text-center text-base' style={{maxWidth:'60vw'}}>
            We can't show a preview for these kinds of files yet.
        </div>
        <div>
            <Button onClick={() => {
                window.open(url, '_blank');
            }} text='Download File' intent='secondary'/>
        </div>
    </div>
}

function ShowPreview(props) {
    switch (props.type) {
        case 'image/bmp':
            return <ImagePreview {...props} />;
        case 'image/png':
            return <ImagePreview {...props} />;
        case 'image/svg+xml':
            return <ImagePreview {...props} />;
        case 'image/jpeg':
            return <ImagePreview {...props} />;
        case 'image/jpg':
            return <ImagePreview {...props} />;
        case 'image/gif':
            return <ImagePreview {...props} />;
        case 'image/tiff':
            return <ImagePreview {...props} />;
        case 'text/csv' :
            return <PreviewNotPossible {...props} />;
        case 'application/msword' :
            return <PreviewNotPossible {...props} />;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return <PreviewNotPossible {...props} />;
        case 'text/html' :
            return <PreviewNotPossible {...props} />;
        case ('text/javascript' || 'application/json' || 'application/x-httpd-php') :
            return <PreviewNotPossible {...props} />;
        case ('audio/mpeg') :
            return <PreviewNotPossible {...props} />;
        case ('video/mpeg') :
            return <PreviewNotPossible {...props} />;
        case ('application/vnd.ms-powerpoint' || 'application/vnd.openxmlformats-officedocument.presentationml.presentation') :
            return <PreviewNotPossible {...props} />;
        case ('application/vnd.rar' || 'application/zip') :
            return <PreviewNotPossible {...props} />;
        case 'text/plain' :
            return <TXTPreview url={props.url}/>;
        case ('application/vnd.ms-excel' || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') :
            return <PreviewNotPossible {...props} />;
        case 'application/pdf' :
            return <PDFPreview {...props} />;
        default:
            return <PreviewNotPossible {...props} />;
    }
}

export function FPPreviewer({url, is_mobile, meta = {type: '', ext: '', size: 0}}) {
    return <div className='h-full bg-gray-200 relative'>
        <ShowPreview url={url} type={meta.type} size={meta.size}/>
    </div>;
}