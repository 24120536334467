import React, {useEffect} from "react"
import './styles.css';
import ExternalAuthPage from "../../../m3/_legacy_components/external-auth-page";
import {useNavigate} from "react-router-dom";
import {signOut} from "../../../config/auth";
import {setDocumentTitle} from "../../../m3/utilities/set-document-title";
import {useCommunity} from "../../../config/community";

function getNavigateTo(community) {
    const url_params = new URLSearchParams(window.location.search);

    if(url_params.has('redirect')) {
        return url_params.get('redirect');
    } else {
        return community ? `/c/${community.domain}/sign-in` : '/';
    }
}

export default function SignOutPage() {
    const navigate = useNavigate();
    const community = useCommunity();
    useEffect(function () {

        const navigate_to = getNavigateTo(community);

        signOut()
            .then(() => {
                setTimeout(() => {
                    navigate(navigate_to)
                }, 150)
            })
    }, [community])

    setDocumentTitle(`Signing out`);

    return <ExternalAuthPage>

    </ExternalAuthPage>
};