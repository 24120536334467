import React, {useState} from 'react';
import {authFetch} from "../../../../api/network";
import Modal from "../../../m3/_legacy_components/modal";
import Button from "../../../m3/_legacy_components/button";
import {SimpleCallout} from "../../../m3/_legacy_components/user-email-manager";
import {FieldInput, FieldLabel} from "../../../m3/_legacy_components/form/field";
import {ArrowRightIcon} from "@heroicons/react/20/solid";
import MetaHeader from "../../../m3/_legacy_components/meta-header";
import SimplePortal from "../../../m3/_legacy_components/simple-portal";
import {isAnonymousUnatyEmail} from "../../../m3/utilities/email";
import {utils_strings_isEmail} from "../../../../common/utilities/strings";

export function AdMeActionChangeAccountEmail({
                                                 use_code = false,
                                                 user_uid,
                                                 loading = false,
                                                 onClose = () => {
                                                 },
                                                 onConfirm = () => {
                                                 },
                                                 default_email = '',
                                                 community_uid = '',
                                             }) {
    const [email, setEmail] = useState(isAnonymousUnatyEmail(default_email) ? "" : default_email);
    const [state, setState] = useState('');

    function checkIfEmailAvailable() {

        if (!utils_strings_isEmail(email)) {
            console.log("NOT VALID", email)
            return;
        }
        const em = `${email}`;
        setState('checking');

        const payload = {
            email: em,
            community_uid
        };
        const res = (r) => {
            if (!r) {
                return;
            }
            if (r.data.code === 'is-community-member') {
                setState('is-member');
                setEmail('');
            } else if (r.data.code === 'email-taken') {
                setState('in-use');
            } else {
                onConfirm(em)
            }
        };

        authFetch("/accounts/check-if-email-is-available", res, res, "POST", {payload});
    }

    const body = <div>
        <FieldLabel>New Email</FieldLabel>
        <FieldInput autoFocus placeholder="name@email.com" value={email} onChange={(a, v) => {
            setEmail(v);
            setState('');
        }}/>
        {state === 'in-use' && <div className="pt-4">
            <SimpleCallout intent="danger" action={<Button onClick={() => onConfirm(email)} text={"Switch Anyway"}/>}
                           message="This email is already taken by another account. If you switch this members account email, this member will be ressigned to the user account with the new email."/>
        </div>}
        {state === 'is-member' && <div className="pt-4">
            <SimpleCallout intent="danger"
                           message="This email belongs to another community member, please contact an admin to merge your profiles."/>
        </div>}
    </div>;

    return <SimplePortal>
        <Modal center size="small">
            <div className="py-2 px-4 relative">
                <MetaHeader can_close title="Change Account Email" onClose={onClose}/>
                <div className="pt-2 space-y-4">
                    {body}
                </div>
                <div className="flex pt-3 pb-1">
                    <div className="flex-grow"></div>
                    <div>
                        <Button intent='primary' loading={loading}
                                disabled={!utils_strings_isEmail(email) || state === 'checking' || default_email === email}
                                onClick={() => checkIfEmailAvailable()} text='Next' right_icon={<ArrowRightIcon/>}/>
                    </div>
                </div>
            </div>
        </Modal>
    </SimplePortal>
}