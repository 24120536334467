import {authFetch} from "../network";

export async function messages_reactToPost(community, payload, remove) {
    const {reaction_id, member_id, post_id} = payload;

    if (!reaction_id) {
        console.log("Missing reaction_id", {payload});
        return false;
    }

    if (!member_id) {
        console.log("Missing member_id", {payload});
        return false;
    }

    if (!post_id) {
        console.log("Missing post_id", {payload});
        return false;
    }

    return new Promise((resolve, reject) => {
        const res = (data) => {
            resolve(data);
        };

        let endpoint = "/posts/react";
        if(remove) {
            endpoint = "/posts/react-remove";
        }

        authFetch(endpoint, res, res, "POST", {
            payload: {
                community_uid: community.uid,
                member_id: community.member_id,
                user_uid: community.member.user_uid,
                ...payload,
            }
        });
    });
}

export async function messages_reactToReply(community,payload,remove) {
    const {reaction_id, member_id, reply_id, post_id} = payload;

    if (!reaction_id) {
        console.log("Missing reaction_id", {payload});
        return false;
    }

    if (!member_id) {
        console.log("Missing member_id", {payload});
        return false;
    }

    if (!reply_id) {
        console.log("Missing reply_id", {payload});
        return false;
    }

    if (!post_id) {
        console.log("Missing post_id", {payload});
        return false;
    }

    return new Promise((resolve, reject) => {
        const res = (data) => {
            resolve(data);
        };

        let endpoint = "/posts/reply/react";
        if(remove) {
            endpoint = "/posts/reply/react-remove";
        }
        authFetch(endpoint, res, res, "POST", {
            payload: {
                community_uid: community.uid,
                member_id: community.member_id,
                user_uid: community.member.user_uid,
                ...payload,
            }
        });
    });
}