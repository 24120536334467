import React, {useEffect, useRef, useState} from "react"
import './styles.css';
import {
    CommunityIndexPage,
    LayoutContentBlock,
    LayoutContentWrapper,
    LayoutStandard, PageLayoutBlock,
    PageLayoutSection, PageLayoutWrapper
} from "../../../m3/_legacy_components/app-frame/layouts";
import Button from "../../../m3/_legacy_components/button";
import EntityList from "../../../m3/_legacy_components/entity-list";
import {useNavigate, useSearchParams} from "react-router-dom";
import {handleMainSearch} from "../../../m3/_legacy_components/app-frame/search";
import {useCommunity} from "../../../config/community";
import EntityTabs from "../../../m3/_legacy_components/entity-tabs";
import {SectionHeader} from "../../../m3/_legacy_components/entity-grid";
import {InlineLoader} from "../../../m3/_legacy_components/admin-activity";
import {
    handleSearchClick,
    renderSearchResults,
    search_type_icons
} from "../../../m3/_legacy_components/simple-autocomplete/async";
import {BasicSelect} from "../../../m3/_legacy_components/form/basic-select";
import {FieldLabel} from "../../../m3/_legacy_components/form/field";
import {BookOpenIcon, UserGroupIcon} from "@heroicons/react/24/outline";
import RoleIcon from "../../../m3/icons/_legacy/custom/RoleIcon";
import {addRecentSearch, getRecentSearches} from "../../../m3/_legacy_components/app-frame/search-api";
import {useIsMobile} from "../../../m3/hooks/is-mobile";
import {Pagination} from "./pagination";
import {setDocumentTitle} from "../../../m3/utilities/set-document-title";
import {getItemFromLocalStorage, saveItemToLocalStorage} from "../../../m3/utilities/localstorage";
import {LocalSearchBar} from "../../../m3/_legacy_components/local-search-bar";
import SortSelect from "../../../m3/_legacy_components/sort-select";
import {logEngagementEvent} from "../../../config/firebase-setup";
import {M3_C_MobileWrapper} from "../../../m3/layouts/mobile-wrapper";
import {ContentPopupMenu} from "../../../m3/_legacy_components/content-popup/components";

function SearchPageComponents({
                                  hide_search_bar,
                                  startManualSearch, handleClear = () => {
    }, body, loading, header, initial_query = "", navigate, items = [], handleClick, recent, results
                              }) {

    return <div>
        {!hide_search_bar &&
            <LocalSearchBar clearResults={handleClear} query={initial_query} placeholder="Enter a search term"
                            onSearchSubmit={(a) => {
                                startManualSearch(a);
                            }}/>}

        {header}

        {body && <div>
            {body}
        </div>}

        {!body && <div className="divide-y divide-gray-200">
            <div className="-mx-2.5 py-3">
                <ContentPopupMenu items={items}/>
            </div>

            {recent.length > 0 && <div className="py-3">
                <SectionHeader size="text-sm" title="Recent Searches"/>

                <div className="-mx-4 space-y-0.5 pt-1">
                    {renderSearchResults(false, handleClick, recent, recent, null, results, "", () => {
                    }, "large")}
                </div>
            </div>}
        </div>}
    </div>
}

function getIcon(r) {
    if (r.image) {
        return {
            type: "small-image",
            image: r.image
        }
    } else if (r.icon) {
        return {
            type: "custom-icon",
            icon: search_type_icons[r.icon] || null
        }
    }
    return {
        type: "small-image",
        image: r.image
    }
}

function finalScore(r) {
    let s = r.data.score;

    if (r.data.type === "member" && r.data.boolean_one === "true") {
        s = s / 4;
    }

    return s;
}

export function searchHighlightText(str, query) {
    if (!query) return str;

    const regex = new RegExp(query, 'gi');

    const text = str.replace(regex, (match) => `####${match}####`);

    // now we need to replace the #### with <span class="highlight"> and </span>

    const parts = text.split("####");

    const highlighted = parts.map((part, i) => {
        if (i % 2 === 1) {
            return <span className="search-highlight">{part}</span>
        } else {
            return part;
        }
    })

    return <span>{highlighted}</span>;
}

function Results({results, query, handleClick, community_uid}) {
    const transformed = results.map(r => {
        return {
            title: searchHighlightText(r.label, query),
            subtitle: r.sublabel || "",
            override: r.override || "",
            description: r.description || "",
            type: r.type,
            action: r.action || null,
            id: r.id || "",
            raw: r,
            icon: getIcon(r),
            meta: [],
            onClick: () => handleClick(r),
            score: finalScore(r)
        };
    })
    return <div>
        <EntityList extra_props={{query}} padding_x="" items={transformed.sort((a, b) => a.score > b.score ? -1 : 1)}
                    community_uid={community_uid}/>
    </div>
}

//    association, location, event, group, user
export function SearchPageLoading() {
    return <div className="flex p-20 justify-center">
        <InlineLoader/>
    </div>
}

const _tabs = [
    {
        label: "Top"
    },
    {
        label: "Members",
        value: "member"
    },
    {
        label: "Posts",
        value: "message"
    },
    {
        label: "Positions",
        value: "role"
    },
    {
        label: "Spaces",
        value: "group"
    },
    {
        label: "Files",
        value: "file"
    },
    {
        label: "Folders",
        value: "folder"
    },
];

function getSublabel(obj, fb = "") {
    if (obj && obj.metadata && obj.metadata[0] && obj.metadata[0].page) {
        return obj.metadata[0].page.total_results > 0 ? obj.metadata[0].page.total_results : fb;
    }
    return fb;
}

export function handleSearchClicks(data, navigate, domain) {
    if (data.type === "user") {
        navigate(`/${data.data.handle}?ref=search`)
    }
    if (data.type === "action") {
        navigate(`/c/${domain}${data.action_link || ""}`)
    }
    if (data.type === "group") {
        navigate(`/c/${domain}/space/${data.id}?ref=search`)
    }
    if (data.type === "message") {
        navigate(`/c/${domain}/post/${data.id}`)
    }
    if (data.type === "role") {
        navigate(`/c/${domain}/position/${data.id}?ref=search`)
    }
    if (data.type === "community") {
        navigate(`/${data.data.handle}?ref=search`)
    }
    if (data.type === "member") {
        navigate(`/c/${domain}/member/${data.data.handle}?ref=search`)
    }
}

export function SearchPageNoResults() {
    return <div className="text-sm text-gray-600 p-4 flex justify-center">
        No results found
    </div>
}

export function transformTabs(ts, results) {

    return ts.map((it, key) => {
        const sl = getSublabel(results[key - 1]);

        return {
            ...it,
            sublabel: sl
        }
    })
}

function buildTopResults(results) {
    let a = [];

    results.forEach(result => {
        if (result) {
            result.items.forEach((b, k) => {
                if (k < 3) {
                    a.push(b)
                }
            })
        }
    })

    return a;
}

export function getFinalSearchPageResults(results, tab_index) {
    if (tab_index === -1) {
        return buildTopResults(results);
    }

    return results[tab_index] ? results[tab_index].items : [];
}

export function buildSearchPageMeta(type, filters = {}, sort = "_score:desc", page_size = 15, meta = {}) {
    let a = {
        size: page_size,
        filters: {
            all: [],
            any: [],
            none: [],
        },
        search_fields: []
    };

    if ((type === 'file' || type === 'folder') && meta.top_level_folder_ids) {
        // add permissions filters
        // string_three includes top level folder id
        // string_one security = private
        /*
        a.filters.any.push({
            "string_one": "public"
        })

         */
        a.filters.all.push({
            "id": meta.top_level_folder_ids.map(fid => `${meta.community_uid}-${type}-${fid}`) || []
        })
        a.filters.all.push({
            "string_three": meta.top_level_folder_ids || []
        })
    }

    if (type === 'member' && filters.only_active_members) {
        a.filters.all.push({
            "boolean_one": "false"
        })
    }

    if (type === 'file' && filters.file_type) {
        a.filters.all.push({
            "sub_type": filters.file_type
        })
    }

    if (type === "message") {
        a.search_fields.push("description");
    }

    if (sort) {
        a.sort = sort;
    }

    return a;
}

export function SearchPagination({
                                     loading,
                                     page_size = 15,
                                     total_count = 0,
                                     results,
                                     term,
                                     page = 1,
                                     handlePageChange,
                                     community_uid
                                 }) {

    function setCurrentPage(np) {
        handlePageChange(np);
    }

    return <div>
        <Pagination
            className="pagination-bar"
            currentPage={page}
            totalCount={total_count}
            pageSize={page_size}
            onPageChange={page => setCurrentPage(page)}
        />
    </div>
}

export function SearchHeader({term, show_title = true, tab, tabs, setTab, results}) {
    return <div className="">
        {show_title && <div className="">
            <SectionHeader size="text-lg" title={`Showing results for "${term}"`}/>
        </div>}
        <div>
            <EntityTabs active={tab} onChangeTab={(t) => setTab(t)} tabs={transformTabs(tabs, results)}/>
        </div>
    </div>
}

export function getStartedRecordUsage(community_id, key = 'search') {
    const full_key = `getstarted-${key}-${community_id}`;
    saveItemToLocalStorage(full_key, true);
}

const page_size = 15;

const sort_types = [
    {
        value: "_score:desc",
        label: "Relevance"
    },
    {
        value: "created_at:desc",
        label: "Newest"
    },
    {
        value: "created_at:asc",
        label: "Oldest"
    }
];

export default function CommunitySearchPage() {
    const community = useCommunity();
    const init_settings = getItemFromLocalStorage(`${community.id}__search-sort`, {
        sort: "_score:desc"
    });
    let [searchParams, setSearchParams] = useSearchParams();
    const [tab, setTab] = useState(_tabs[0].label);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [page_results, setPageResults] = useState([]);
    const [results, setResults] = useState([null, null, null, null, null, null]);
    const _results = useRef([null, null, null, null, null, null])
    const is_mobile = useIsMobile();
    const [sort, setSort] = useState(init_settings.sort || "_score:desc");

    const term = searchParams.get('term');

    useEffect(function () {
        saveItemToLocalStorage(`${community.id}__search-sort`, {sort});
    }, [sort])

    setDocumentTitle(`Search`, `${community.profile.name}`);

    const [filters, updateFilters] = useState({
        only_active_members: false,
        file_type: ""
    })

    useEffect(function () {
        startSearch()
    }, [searchParams, filters, sort])

    useEffect(function () {
        if (currentPage > 1) {
            startLoadingPageResults()
        }
    }, [currentPage])

    function startLoadingPageResults() {
        setPageResults([]);
        setLoading(true);
        const tab_index = _tabs.findIndex(it => it.label === tab);

        let tab_data = _tabs[tab_index];

        if (tab_data && tab_data.value) {
            let meta = buildSearchPageMeta(tab_data.value, filters, page_size, {
                top_level_folder_ids: community.top_level_folder_ids,
                community_uid: community.uid
            });
            meta.page = currentPage;
            meta.sort = sort;
            handleMainSearch(term, `mainsearch_${community.uid}`, [tab_data.value], false, meta)
                .then((resp) => {
                    setPageResults([...resp.items])
                    setLoading(false);
                })
        }
    }

    function startSearch() {
        const t = searchParams.get('term')
        getStartedRecordUsage(community.uid, 'search')
        if (!t) {
            return;
        }
        setResults([null, null, null, null, null, null])
        handleSearch(t, filters, sort)
    }

    useEffect(function () {
        _results.current = [...results];

        const is_loading = results.filter(a => !a).length > 0;

        setLoading(is_loading);
    }, [results])

    function handleSearch(term, filt, srt = "_score:desc") {
        if (term) {
            logEngagementEvent("search", {
                term: term
            }, community.uid);
        }
        setLoading(true);
        handleMainSearch(term, `mainsearch_${community.uid}`, ["member"], false, buildSearchPageMeta('member', filt, srt))
            .then((resp) => {
                const cr = _results.current;
                const nr = [resp, cr[1], cr[2], cr[3], cr[4], cr[5]];
                _results.current = [...nr];
                setResults(nr);
            })
        handleMainSearch(term, `mainsearch_${community.uid}`, ["message"], false, buildSearchPageMeta('message', filt, srt))
            .then((resp) => {
                const cr = _results.current;
                const nr = [cr[0], resp, cr[2], cr[3], cr[4], cr[5]];
                _results.current = [...nr];
                setResults(nr);
            })

        handleMainSearch(term, `mainsearch_${community.uid}`, ["role"], false, {sort: srt})
            .then((resp) => {
                const cr = _results.current;
                const nr = [cr[0], cr[1], resp, cr[3], cr[4], cr[5]];
                _results.current = [...nr];
                setResults(nr);
            })

        handleMainSearch(term, `mainsearch_${community.uid}`, ["group"], false, {sort: srt})
            .then((resp) => {
                const cr = _results.current;
                const nr = [cr[0], cr[1], cr[2], resp, cr[4], cr[5]];
                _results.current = [...nr];
                setResults(nr);
            })

        handleMainSearch(term, `mainsearch_${community.uid}`, ["file"], false, buildSearchPageMeta('file', filt, srt, page_size, {
            top_level_folder_ids: community.top_level_folder_ids,
            community_uid: community.uid
        }))
            .then((resp) => {
                const cr = _results.current;
                const nr = [cr[0], cr[1], cr[2], cr[3], resp, cr[5]];
                _results.current = [...nr];
                setResults(nr);
            })

        handleMainSearch(term, `mainsearch_${community.uid}`, ["folder"], false, buildSearchPageMeta('folder', filt, srt, page_size, {
            top_level_folder_ids: community.top_level_folder_ids,
            community_uid: community.uid
        }))
            .then((resp) => {
                const cr = _results.current;
                const nr = [cr[0], cr[1], cr[2], cr[3], cr[4], resp];
                _results.current = [...nr];
                setResults(nr);
            })
    }

    function handleClick(ind) {
        handleSearchClick(ind, (a) => {
            handleSearchClicks(a, navigate)
        }, (as) => {
            navigate(`/c/${community.domain}/search?term=${as}`)
        }, recent)
    }

    function startManualSearch(nt) {
        setSearchParams({term: nt})
    }

    function handlePageChange(new_page) {
        setCurrentPage(new_page);
    }

    const tab_index = _tabs.findIndex(a => a.label === tab) - 1;

    const final_r = getFinalSearchPageResults(results, tab_index);

    const recent = getRecentSearches(`mainsearch_${community.uid}`);

    const header = term && <SearchHeader show_title={!is_mobile} {...{
        term: searchParams.get('term'),
        tabs: _tabs,
        tab,
        setTab: (t) => {
            setTab(t);
            setCurrentPage(1);
        },
        results
    }} />;

    const total_count = getSublabel(results[tab_index], 0);

    const show_loading = loading && page_results.length === 0 && !results[tab_index + 1];

    const no_results = !loading && !final_r.length;

    const show_page_results = currentPage !== 1;

    const body = term && <div className="pt-2">
        <div className="pb-2 pt-0.5">
            <SortSelect active={sort} setSort={ns => setSort(ns)} items={sort_types}/>
        </div>
        {show_loading && <SearchPageLoading/>}
        {no_results && <SearchPageNoResults/>}
        {!show_loading && show_page_results && <Results community_uid={community.uid} query={term}
                                                        handleClick={it => handleSearchClicks(it, navigate, community.domain)}
                                                        results={page_results}/>}
        {!show_loading && !show_page_results && <Results community_uid={community.uid} query={term}
                                                         handleClick={it => handleSearchClicks(it, navigate, community.domain)}
                                                         results={final_r}/>}

        {!show_loading && !loading && total_count > page_size &&
            <SearchPagination page={currentPage} handlePageChange={handlePageChange} total_count={total_count}
                              page_size={page_size} loading={loading} results={results} term={term}/>}
    </div>;

    const c = <PageLayoutSection>
        <PageLayoutBlock fill>
            <SearchPageComponents hide_search_bar handleClear={() => {
                setSearchParams({term: ""})
            }} loading={loading && !results[tab_index + 1]} body={body} header={header} initial_query={term} items={[
                {
                    icon: <BookOpenIcon/>,
                    navigate_link: true,
                    large: true,
                    label: "Browse Contacts",
                    onClick: () => navigate(`/c/${community.domain}/contacts`),
                },
                {
                    icon: <UserGroupIcon/>,
                    navigate_link: true,
                    large: true,
                    label: "Browse Spaces",
                    onClick: () => navigate(`/c/${community.domain}/spaces`),
                },
                {
                    icon: <RoleIcon/>,
                    navigate_link: true,
                    large: true,
                    label: "Browse Leaders",
                    onClick: () => navigate(`/c/${community.domain}/leaders`),
                }
            ]} {...{startManualSearch, navigate, handleClick, recent, results}} />
        </PageLayoutBlock>
    </PageLayoutSection>

    let header2 = null;

    if (is_mobile) {
        header2 = {
            title: "Search"
        };
    }

    return <CommunityIndexPage sidebar={<div></div>} header={header2}>
        <PageLayoutSection divider={false}>
            <LocalSearchBar autoFocus onBlur={() => {
                if (term) {
                    addRecentSearch(`mainsearch_${community.id}`, `query-${term}`, {
                        key: 'direct-search',
                        label: term,
                        icon: 'search'
                    });
                }
            }} clearResults={() => setSearchParams({term: ""})} query={term} placeholder="Enter a search term"
                            onSearchSubmit={(a) => {
                                startManualSearch(a);
                            }}/>
        </PageLayoutSection>
        {c}
    </CommunityIndexPage>;

    if (is_mobile) {
        return <M3_C_MobileWrapper header={{
            title: "Search",
            sticky: true,
            actions: [],
            subnav_bar: {
                variant: "search",
                placeholder: "Find members, posts, and more",
                onEnter: (query) => startManualSearch(query),
                onOpenSearch: () => {
                },
                onCloseSearch: () => {
                }
            }
        }} theme={community.active_theme}>
            {c}
        </M3_C_MobileWrapper>
    }

    return <LayoutStandard>
        <PageLayoutWrapper>
            <PageLayoutSection>
                <LocalSearchBar autoFocus onBlur={() => {
                    if (term) {
                        addRecentSearch(`mainsearch_${community.id}`, `query-${term}`, {
                            key: 'direct-search',
                            label: term,
                            icon: 'search'
                        });
                    }
                }} clearResults={() => setSearchParams({term: ""})} query={term} placeholder="Enter a search term"
                                onSearchSubmit={(a) => {
                                    startManualSearch(a);
                                }}/>
            </PageLayoutSection>
            {c}
        </PageLayoutWrapper>
        <PageLayoutWrapper>
            <LayoutContentBlock>
                {term && results[tab_index + 1] && <div>
                    <div className="">
                        <SectionHeader size="text-lg" title={`Search filters`}/>
                    </div>

                    <div className="space-y-4">
                        <Button text="Only active members"
                                onClick={() => updateFilters({
                                    ...filters,
                                    only_active_members: !filters.only_active_members
                                })}
                                intent={filters.only_active_members ? "primary" : ""}/>

                        <div>
                            <FieldLabel>File Type</FieldLabel>
                            <BasicSelect value={filters.file_type} placeholder="File type"
                                         onChange={(v) => {
                                             updateFilters({
                                                 ...filters,
                                                 file_type: v.value
                                             })
                                         }} options={[
                                {
                                    value: "",
                                    label: "Any"
                                },
                                {
                                    value: "application/pdf",
                                    label: "PDF"
                                },
                                {
                                    value: "image/jpeg",
                                    label: "JPEG"
                                }
                            ]}/>
                        </div>
                    </div>
                </div>}
            </LayoutContentBlock>
        </PageLayoutWrapper>

    </LayoutStandard>
};