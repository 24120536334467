import React, {Component} from "react";
import {externalAuthFetch} from "../../../../api/network";
import Field from "../../../m3/_legacy_components/form/field";
import Button from "../../../m3/_legacy_components/button";
import {utils_strings_isEmail} from "../../../../common/utilities/strings";

function getUrlVars() {
    let vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
    });
    return vars;
}

class MagicLinkBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sending: false,
            email: props?.email || "",
            sent: false
        }
    }

    hc(f, v) {
        this.setState({
            [f]: v
        })
    }

    componentDidMount() {
        let email = getUrlVars()['email'];

        if (typeof email === 'string') {
            this.setState({
                email: email
            });
        }
    }

    handleSend(disable, e) {
        if (e) {
            e.preventDefault();
        }
        if (disable) {
            return;
        }

        const {sending, email} = this.state;
        if (sending) {
            return;
        }

        this.setState({
            sending: true
        });

        externalAuthFetch("/send-magic-email", this.handleResult.bind(this), this.handleError.bind(this), "POST", {email,app:'orange'});
    }

    handleResult() {
        this.setState({
            sending: false,
            sent: true
        })
    }

    handleError() {
        this.setState({
            sent: true,
            sending: false
        })
    }

    renderAction(sending, sent) {
        if (sent) {
            return <div className="">
                <div className='text-sm text-gray-600'>
                    If you have an account with us, you'll receive a Magic link sent, please check your spam if you
                    don't see it as well
                </div>
            </div>
        }

        const {email} = this.state;

        const disable = this.disableBtn();

        return <div className="gap-4 flex flex-col">
            <form className="flex flex-col gap-2" onSubmit={this.handleSend.bind(this, disable)}>


                <Field name="email" value={email} onChange={this.hc.bind(this, 'email')}
                       type="text" label="Email">
                </Field>

            </form>

            <div>
                <Button disabled={disable} loading={sending} onClick={this.handleSend.bind(this, disable)} size="large"
                        intent="primary" text="Get Magic Link" fill/>
            </div>

            <div className='text-sm text-gray-600 pt-2'>
                Enter the email address associated with your account and we'll send you a simple sign-in link.
            </div>


        </div>;
    }

    disableBtn() {
        let {email} = this.state;
        return (!utils_strings_isEmail(email))
    }

    render() {
        const {sending, sent} = this.state;
        return (
            <>
                {this.renderAction(sending, sent)}
            </>
        );
    }
}

export default MagicLinkBlock;