import dayjs from "dayjs";
import {data_timezones_arr} from "../data/data/timezones";

function fromNowOrNow(a, r = {}) {
    const ms = Math.abs(dayjs().diff(a));
    if (ms < 6000) {
        return r.now ? r.now : `now`;
    } else if (ms < 60000) {
        return r.now ? r.now : `${Math.round(ms / 1000)}s`;
    } else if (ms < 3600000) {
        return `${Math.round(ms / 60000)}m`;
    } else if (ms < 86400000) {
        return `${Math.round(ms / 3600000)}h`;
    }
}

let REFERENCE = dayjs();
let TODAY = REFERENCE.clone().startOf('day');
let ONE_HOUR = REFERENCE.clone().subtract(1, 'hours');
let YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
let A_WEEK_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');
let ONE_MONTH = REFERENCE.clone().subtract(30, 'days').startOf('day');
let ONE_YEAR = REFERENCE.clone().subtract(365, 'days').startOf('day');

function isToday(momentDate) {
    return momentDate.isSame(TODAY, 'd');
}

function isYesterday(momentDate) {
    return momentDate.isSame(YESTERDAY, 'd');
}

function isWithinAWeek(momentDate) {
    return momentDate.isAfter(A_WEEK_OLD);
}

function isWithinAnHour(momentDate) {
    return momentDate.isAfter(ONE_HOUR);
}

function isLessThanAYear(momentDate) {
    return momentDate.isAfter(ONE_YEAR);
}

function isLessThanAMonth(momentDate) {
    return momentDate.isAfter(ONE_MONTH);
}

function utils_dates_getPrettyTime(date, extra = '', r) {
    if (!date) return '';

    // show time if today, otherwise just days
    let last_date = dayjs(date), text = '', suffix = '';

    if (isWithinAnHour(last_date)) {
        text = fromNowOrNow(last_date, r);
    } else if (isToday(last_date)) {
        text = last_date.format(r ? r.today : 'h:mm A');
    } else if (isYesterday(last_date)) {
        text = r ? r.yesterday : 'Yesterday';
    } else if (isWithinAWeek(last_date)) {
        text = last_date.format(r ? r.week : 'dddd');
    } else if (isLessThanAYear(last_date)) {
        text = last_date.format(r ? r.year : 'MMM D');
    } else {
        text = last_date.format(r ? r.beyond : 'MMM YYYY')
    }

    return `${text}`;
}

function utils_dates_format(ts) {
    if (!ts) return null;
    const m2 = dayjs.unix(ts / 1000);
    const now = dayjs();
    let n, dt, sort, relative;
    if (isToday(m2)) {
        dt = m2.format(`h:mmA`);
        sort = 'today';
        //  relative = m2.fromNow();
    } else if (isYesterday(m2)) {
        dt = m2.format(`[Yesterday]`);
        sort = 'yesterday';
        //  relative = m2.utils('MMM D');
    } else if (isWithinAWeek(m2)) {
        dt = m2.format(`dddd`);
        sort = 'this-week';
        //    relative = m2.utils('MMM D');
    } else if (isLessThanAMonth(m2)) {
        n = now.diff(m2, "weeks");
        dt = `${n} week${n === 1 ? "" : "s"} ago`;
        sort = 'last-month';
        // relative = m2.utils('MMM D');
    } else if (isLessThanAYear(m2)) {
        n = now.diff(m2, "months");
        dt = `${n} month${n === 1 ? "" : "s"} ago`;
        sort = 'last-year';
        // relative = m2.utils('MMM D');
    } else {
        n = now.diff(m2, "years");
        dt = `${n} year${n === 1 ? "" : "s"} ago`;
        sort = 'beyond';
        //   relative = m2.utils('MMM D, YYYY');
    }
    relative = utils_dates_getPrettyTime(m2);
    return {
        full_date: m2.format('MMMM D, YYYY'),
        mid_date: m2.format('MMMM YYYY'),
        short_date: m2.format('MMM D'),
        date_formatted: dt,
        year: parseInt(m2.format('YYYY')),
        month: m2.format('MMMM'),
        relative,
        sort
    }
}

function getTimezoneOffset(tz) {
    return dayjs().tz(tz).utcOffset();
}

function utils_dates_getTimezoneGroups() {
    const tzs = [...data_timezones_arr];
    let final = [];
    tzs.forEach(([key, label])=>{
        const offset = getTimezoneOffset(key);
        const sign = offset<0 ? "-" : "+";
        const val = Math.abs(offset)/60;
        const hour = Math.floor(val);
        const minute = (val-hour)*60;
        final.push({
            value: key,
            label: `(UTC${sign}${hour>9?hour:`0${hour}`}:${!minute?"00":minute}) ${label}`
        })
    });

    return final;
}

export {
    utils_dates_getTimezoneGroups,
    utils_dates_format,
    utils_dates_getPrettyTime
};