import {data_autocomplete_map} from "../data/autocomplete-map";

export function generateUniqueID(prefix = "") {
    return `${prefix}_${Math.random().toString(36).substring(2) + Date.now().toString(36)}`;
}

export function getNestedValue(key, obj, fallback = "") {
    if (!key || typeof key !== "string") {
        return fallback;
    }
    let sp = key.split(".");

    if (!sp.length) {
        return fallback;
    }

    if (sp.length === 1) {
        return obj?.[key] || fallback;
    } else {
        return obj?.[sp[0]]?.[sp[1]] || fallback;
    }
}

export function shallowCompareValues(a, b) {
    // if object, ensure keys are ordered
    if (typeof a === "object" && typeof b === "object") {
        a = Object.keys(a).sort().reduce((r, k) => (r[k] = a[k], r), {});
        b = Object.keys(b).sort().reduce((r, k) => (r[k] = b[k], r), {});
    }

    return JSON.stringify(a) === JSON.stringify(b);
}

function convertArrToObj(arr) {
    const obj = {};
    arr.forEach(a => {
        obj[a.id] = a;
    });
    return obj;
}

export function getMembershipLevelOptions(community) {
    if(!community || !community.member_types) return [];
    return Object.entries(convertArrToObj(community.member_types)).map(([id, ent]) => {
        return {
            label: ent.singular,
            value: id
        }
    });
}

export function getMembershipStatusOptions(community) {
    return data_autocomplete_map?.['membership-status']?.data || [];
}