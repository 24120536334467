import React from 'react';
import {AccessBlock, getAccessMeta} from "../../../../../m3/_legacy_components/delegated-access-block";
import {useCommunity} from "../../../../../config/community";

export function getGroupAccess(group) {
    let post_to_feed = {
        community: false,
        all_roles: false,
        moderators: true,
        group_members: true,
        admins: true,
        teams: {},
        members: {},
        roles: {},
        member_types: {}
    }, reply_to_posts = {
        community: false,
        all_roles: false,
        moderators: true,
        group_members: true,
        admins: true,
        teams: {},
        members: {},
        roles: {},
        member_types: {}
    }, manage_drive = {
        community: false,
        all_roles: false,
        moderators: true,
        group_members: false,
        admins: true,
        teams: {},
        members: {},
        roles: {},
        member_types: {}
    }, view_drive = {
        community: false,
        all_roles: false,
        moderators: true,
        group_members: true,
        admins: true,
        teams: {},
        members: {},
        roles: {},
        member_types: {}
    };

    if (group && group.access) {
        if (group.access.post_to_feed) {
            post_to_feed = group.access.post_to_feed;
        }
        if (group.access.reply_to_posts) {
            reply_to_posts = group.access.reply_to_posts;
        }
        if (group.access.view_drive) {
            view_drive = group.access.view_drive;
        }
        if (group.access.manage_drive) {
            manage_drive = group.access.manage_drive;
        }
    }

    return {
        post_to_feed, reply_to_posts, manage_drive, view_drive
    };
}

export function GroupModalAccessTab({
                                        setData,
                                        cb = () => {},
                                        id,
                                        group
                                    }) {
    const community = useCommunity();

    const access_values = getGroupAccess(group);

    const access_items = [
        {
            title: "Post to the Feed",
            subtitle: "Create a new Post on the Group feed",
            field: "post_to_feed",
            value: access_values['post_to_feed']
        },
        {
            title: "Reply to Messages",
            subtitle: "Leave replies to any Group Message",
            field: "reply_to_posts",
            value: access_values['reply_to_posts']
        },
        {
            title: "View Drive",
            subtitle: "General access to the drive contents",
            field: "view_drive",
            value: access_values['view_drive']
        }
    ];

    const meta = getAccessMeta(community);

    return <div className={`grid gap-4`}>
        {access_items.map(a => {
            const ia = access_values[a.field];

            return <AccessBlock key={a.field}
                                scope={'group'}
                                cb={({field, value}) => {
                                    const na = {...access_values};
                                    na[field] = value;
                                    setData({
                                        access: na
                                    })
                                }}
                                {...a}
                                scope_id={id}
                                api={"/groups/update-access"}
                                usePortal={false}
                                types={['member_types', 'admins', 'roles', 'community', 'teams', 'all_roles', 'group_members', 'moderators']}
                                init_access={ia}
                                meta={meta}/>
        })}
    </div>
}