import React from "react";
import {Avatar} from "../../../m3/_legacy_components/avatar";

import {useCommunity} from "../../../config/community";
import {buildImageUrl} from "../../../../common/utilities/images";

function Role({title,id,image='',member_id,name}) {
    const community = useCommunity();

    return <div className="">
        <div>
            <Avatar url={buildImageUrl(image)} size="h-20 w-20" />
        </div>
        <div className="pt-1.5">
            <div onClick={()=>{
                community.openPreviewOverlay({
                    type: 'role',
                    id: id
                })
            }} className="font-semibold leading-5 text-gray-800 text-base cursor-pointer hover:underline">{title}</div>
            <div onClick={()=>{
                if(member_id) {
                    community.openPreviewOverlay({
                        type: 'member',
                        id: member_id
                    })
                }
            }} className={`text-gray-600 leading-5 text-sm ${member_id?"cursor-pointer hover:underline":""}`}>{name}</div>
        </div>
    </div>
}

export function LeadersTeam({title,id,roles}) {
    return <div id={`team-${id}`}>
        <div className="pb-2">
            <div className="font-semibold text-gray-800 text-lg">
                {title}
            </div>
        </div>
        <div className="grid gap-4 grid-cols-2 sm:grid-cols-4">
            {roles.map(r=><Role key={r.id} {...r} />)}
        </div>
    </div>
}