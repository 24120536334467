export const notification_types = {
    'catch-up': {
        system: true,
        added: true,
        message: 'You have {unread_count} new community update{unread_count_plural} to catch up on',
    },

    'new-post': {
        added: true,
        message: `[{author_name}](member={author_id}) posted in [{group_name}](group={id})`,
    },
    'new-reply-to-your-post': {
        added: true,
        message: `[{author_name}](member={author_id}) replied to your post [{post_subject}](post={post_id})`,
    },
    'new-reply-to-post-you-follow': {
        added: true,
        message: `[{author_name}](member={author_id}) replied to the post [{post_subject}](post={post_id})`,
    },

    'member-assigned-to-role': {
        added: true,
        message: '[{member_name}](member={id}) assigned you to the role [{name}](role={id})',
        type: 'role'
    },
    'member-assigned-to-role-self': {
        added: true,
        message: 'You assigned yourself to the role [{name}](role={id})',
        type: 'role'
    },

    'member-followed-invite': {
        added: true,
        message: '[{name}](member={id}) followed your invite and logged in',
        type: 'member'
    },
    'member-followed-data-check': {
        added: true,
        message: '[{name}](member={id}) completed the data check you sent',
        type: 'member'
    },

    'followed-you': {
        added: true,
        message: '[{author_name}](member={author_id}) followed you.',
        type: 'member'
    },

    'changed-member-type': {
        added: true,
        message: '{admin_name} changed your membership level to **{label}**',
        type: 'member_type'
    },

    'made-moderator': {
        added: true,
        message: 'You became a Moderator of [{name}](group={id})',
        type: 'group'
    },

    'joined-group': {
        added: true,
        message: 'You joined [{name}](group={id})',
        type: 'group'
    },

    'invited-to-group': {
        added: true,
        message: '[{author_name}](member={author_id}) added you to [{name}](group={id})',
        type: 'group'
    },

    'auto-added-to-group': {
        added: true,
        message: 'You were auto-added to [{name}](group={id})',
        type: 'group'
    },

    'invited-to-team': {
        added: true,
        message: 'You were invited to [{name}](group={id})',
        type: 'team'
    },

    'auto-added-to-team': {
        added: true,
        message: 'You were auto-added to [{name}](group={id})',
        type: 'team'
    }
};