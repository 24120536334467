import React, {useEffect, useState} from "react";
import {AsyncSuggestions} from "../simple-autocomplete/async";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";

import {addRecentSearch, getRecentSearches, mainSearch, removeRecentSearch} from "./search-api";
import {useNavigate} from "react-router-dom";
import {DEFAULT_USER_PHOTO, getMemberProfileById} from "../../../config/community";
import {useUnaverse} from "../../../config/unaverse";
import {getStartedRecordUsage, handleSearchClicks} from "../../../routes/community/search";
import {direct_queryContent} from "../directory/build-elasticsearch-query";
import {utils_strings_isEmail} from "../../../../common/utilities/strings";
import {fileFormats, formatFileSize} from "../../icons/file-helpers";
import {buildImageUrl} from "../../../../common/utilities/images";
import {getMemberProfile, getMemberProfileByEmail} from "../../../routes/community/member/api";

function getId(result) {
    if (result.id.indexOf('-') !== -1) {
        const sps = result.id.split('-');
        if (sps[2]) {
            return sps[2]
        } else {
            return sps[1]
        }
    }
    return result.id;
}

function getRoleData(result) {
    if(result&&result.assignee_data) {
        return result;
    }
    let r = result.data && typeof result.data === "string" ? JSON.parse(result.data) : {};

    return r;
}

function buildMemberLabel(result) {
    let a = `${result.name}`;

    if(result.boolean_one&&result.boolean_one==="true") {
        a += " (deactivated)";
    }

    return a;
}

function buildMemberSubLabel(result) {
    if(result.subtitle) {
        return result.subtitle;
    }

    let a = `@${result.handle}`;

    if(result.string_one) {
        a += ` · ${result.string_one}`
    }

    if(result.string_two) {
        a += ` · ${result.string_two}`
    }

    return a;
}

function getGroupLabel(string_one) {
    if(string_one&&string_one==="open") {
        return "Open Group";
    } else {
        return "Closed Group"
    }
}

function getFolderSublabel(result) {
    if(result.string_one==="private") {
        return "Folder";
    } else {
        return "Folder"
    }
}

export function constructSearchResult(result, type = '') {
    switch (type) {
        case "member":
            return {
                key: getId(result),
                id: `${getId(result)}`,
                type: 'member',
                description: result?.description || "",
                image: buildImageUrl(result.profile_picture ? result.profile_picture : result.image ? result.image : DEFAULT_USER_PHOTO),
                label: buildMemberLabel(result),
                sublabel: buildMemberSubLabel(result),
                data: result,
                sort: result.sort || 5,
                disabled: !!result.disabled,
                note: result.note||"",
                action: {
                    id: getId(result),
                    type: "follow"
                }
            }
        case "file":

            return {
                key: getId(result),
                id: `${getId(result)}`,
                type: 'file',
                icon: 'file',
                label: result.title,
                sublabel: `${fileFormats[result.sub_type]||""} · ${formatFileSize(result.number_one*1000)}`,
                sort: result.sort || 5,
                disabled: !!result.disabled,
                note: result.note||"",
                data: result
            }
        case "message":
            return {
                key: getId(result),
                id: `${getId(result)}`,
                override: 'message',
                type: 'message',
                sublabel: "",
                label: result.title,
                sort: result.sort || 2,
                disabled: !!result.disabled,
                note: result.note||"",
                data: result
            }
        case "group":
            return {
                key: getId(result),
                id: `${getId(result)}`,
                type: 'group',
                icon: 'group',
                label: result.title,
                sublabel: getGroupLabel(result.string_one),
                sort: result.sort || 5,
                disabled: !!result.disabled,
                note: result.note||"",
                data: result
            }
        case "folder":
            return {
                key: getId(result),
                id: `${getId(result)}`,
                type: 'folder',
                icon: 'folder',
                label: result.title,
                sublabel: getFolderSublabel(result),
                disabled: !!result.disabled,
                note: result.note||"",
                data: result
            }
        case "role":
            const dt = getRoleData(result);
            return {
                key: getId(result),
                id: `${getId(result)}`,
                image: buildImageUrl(dt.assignee_data && dt.assignee_data.profile_picture ? dt.assignee_data.profile_picture : DEFAULT_USER_PHOTO),
                type: 'role',
                label: result.title||result.name||"",
                sublabel: dt.assignee_data && dt.assignee_data.name ? dt.assignee_data.name : "Unassigned",
                sort: result.sort || 5,
                disabled: !!result.disabled,
                note: result.note||"",
                data: result
            }
        default:
            const id_sp = result.id.split('-');
            return {
                key: result.id,
                id: `${id_sp[id_sp.length - 1]}`,
                type: result.type,
                image: buildImageUrl(result.image || DEFAULT_USER_PHOTO),
                label: result.title,
                sort: result.sort || 5,
                sublabel: result.handle ? `@${result.handle}` : "",
                disabled: !!result.disabled,
                note: result.note||"",
                data: result
            }
    }
}

export function buildSearchResults(resp, type = '', tr = (a) => a) {
    let a = [];
    if (!resp.results || !resp.page) {
        return [];
    }
    resp.results.forEach(result => {
        a.push(constructSearchResult(tr(result), result.type || type))
    })
    return a;
}

export async function communitySearch(query, scope, types, search_meta) {

    const req = buildCommunityQuery(query, scope, types, search_meta);

    return await direct_queryContent(req)
        .then((resp) => {
            return resp;
        });
    /*
    const req = buildMembersQuery(query);
    return queryMembersData(req, scope)
        .then(resp => {
            return resp;
        });

     */
}

function parseSort(srt) {
    if(!srt) {
        return {
            _score: "desc"
        }
    }

    const split = srt.split(':');

    return {
        [split[0]]: split[1]
    }
}

function buildCommunityQuery(query, scope, types, search_meta) {
    const page = search_meta && search_meta.page ? search_meta.page : 0;
    const size = search_meta && search_meta.size ? search_meta.size : 15;
    const sort = parseSort(search_meta?.sort);
    let a = {
        current: page,
        size: size,
        result_fields: {
            title: {raw: {}},
            image: {raw: {}},
            id: {raw: {}},
            type: {raw: {}},
            sub_type: {raw: {}},
            string_one: {raw: {}},
            string_two: {raw: {}},
            string_three: {raw: {}},
            number_one: {raw: {}},
            boolean_one: {raw: {}},
            updated_at: {raw: {}},
            created_at: {raw: {}},
            handle: {raw: {}},
            description: {raw: {}},
            scope: {raw: {}},
            data: {raw: {}},
        },
        search_fields: {
            title: {},
            description: {},
            string_one: {},
            string_two: {},
            string_three: {},
            handle: {},
            keywords: {},
        },
        sort,
        query,
        filters: {
            all: [
                {
                    "scope": scope
                }
            ],
            any: [
                {
                    "type": types
                }
            ],
            none: [],
        },
    };

    if(search_meta&&search_meta.filters) {
        search_meta.filters.all.forEach(fi=>{
            a.filters.all.push(fi);
        })
        search_meta.filters.any.forEach(fi=>{
            a.filters.any.push(fi);
        })
        search_meta.filters.none.forEach(fi=>{
            a.filters.none.push(fi);
        })
    }

    return a;
}

function isUpperCase(str) {
    return str === str.toUpperCase();
}

export function getSearchQueryMeta(query) {
    let flags = {
        is_handle: false,
        is_email: false,
        is_id: false
    };

    if (query.indexOf('@') !== -1 && utils_strings_isEmail(query)) {
        flags.is_email = true;
    }

    if (query.startsWith('@')) {
        flags.is_handle = true;
    }

    if (query.length === 10 && isUpperCase(query)) {
        flags.is_id = true;
    }

    return {
        query: query.trim().slice(0, 180),
        flags
    }
}

// todo add to utilities
const mergeArrays = (arr1 = [], arr2 = []) => {
    return [
        ...[...arr1, ...arr2]
            .reduce(
                (acc, curr) => acc.set(curr.id, {...acc.get(curr.id), ...curr}),
                new Map()
            )
            .values(),
    ];
};

function getSortVal(ai) {
    if (ai.type === "member") {
        return 1;
    }
    return 2;
}

const transformer = (a) => {
    return {
        ...a,
        sort: getSortVal(a),
        name: a.title || ""
    };
};

const communitySorter = (a, b) => a.sort > b.sort ? 1 : -1;

function getQuickActions(query, scope) {
    if (scope === "global") {
        return [];
    }
    let a = [];
    a.push({
        key: "create-member",
        id: "create-member",
        action_link: "/members?action=create",
        type: "action",
        icon: "plus",
        label: "New Member",
        sort: 2,
    });
    return a.filter(a => a.label.toLowerCase().indexOf(query) !== -1);
}

export async function handleMainSearch(query, scope = "global", ts,  qa = true, search_meta) {
    let types = ts ? ts : scope === "global" ? ["user", "community"] : ["member", "group", "role"];
    const quick_actions = qa ? getQuickActions(query, scope) : [];
    const final_scope = scope.split("mainsearch_")[1];

    const meta = getSearchQueryMeta(query);

    if(scope!=='global') {
        getStartedRecordUsage(scope,'search')
    }

    if (final_scope !== "global") {
        let p = [];

        if (meta.flags.is_email) {
            p.push(getMemberProfileByEmail(final_scope, meta.query)
                .then(res => {
                    let m = [];
                    if (res && !res.does_not_exist) {
                        m.push(res);
                    }
                    return {
                        items: buildSearchResults({
                            results: m,
                            page: 1
                        }, "member"),
                        metadata: {
                            page: {}
                        }
                    }
                }));
        }
        if (meta.flags.is_handle) {
            p.push(getMemberProfile(final_scope, meta.query.replace(/@/g, ''))
                .then(res => {
                    let m = [];
                    if (res && !res.does_not_exist) {
                        m.push(res);
                    }
                    return {
                        items: buildSearchResults({
                            results: m,
                            page: 1
                        }, "member"),
                        metadata: {
                            page: {}
                        }
                    }
                }));
        }

        if (meta.flags.is_id) {
            p.push(getMemberProfileById(final_scope, meta.query)
                .then(res => {
                    let m = [];
                    if (res && !res.does_not_exist) {
                        m.push(res);
                    }

                    return {
                        items: buildSearchResults({
                            results: m,
                            page: 1
                        }, "member"),
                        metadata: {
                            page: {}
                        }
                    }
                }));
        }

        p.push(communitySearch(meta.query, final_scope, types, search_meta)
            .then(resp => {

                const results = buildSearchResults(resp, "", transformer);

                // return buildSearchResults(resp, "member");
                return {
                    items: results.sort(communitySorter),
                    metadata: {
                        page: resp.page
                    }
                };
            }));

        return await Promise.all(p).then(a => {
            if (a[1]) {
                return {
                    items: mergeArrays(mergeArrays(a[0].items, a[1].items), quick_actions).sort(communitySorter),
                    metadata: a.map(b => b.metadata)
                };
            }
            return {
                items: mergeArrays(a[0].items, quick_actions).sort(communitySorter),
                metadata: a.map(b => b.metadata)
            }
        });
    }

    return await mainSearch(meta.query, final_scope, types)
        .then(resp => {
            return {
                items: buildSearchResults(resp),
                metadata: [
                    {
                        page: resp?.page||{}
                    }
                ]
            }
        });
}

function getRecent(scope) {
    const items = getRecentSearches(scope);

    return [
        [
            {
                title: "Recent"
            },
            ...items.map((it, index) => {
                return {
                    ...it,
                    onRemove: () => {
                        removeRecentSearch(scope, index)
                    }
                }
            })
        ],
    ];
}

export function MenuSearchBar({loading, domain = '', scope = "global"}) {
    const unaverse = useUnaverse();
    const [focus, setFocus] = useState(false);
    const navigate = useNavigate();

    const bar_classes = focus ? "bg-white border-blue-400" : "bg-gray-100 hover:bg-white";
    const search_scope = `mainsearch_${scope}`;
    if (loading) {
        return <div className="flex-grow flex items-center h-full">
            <div className="h-10 flex rounded-2xl border border-gray-200 bg-gray-200 flex-grow"/>
        </div>
    }
    const recent = getRecent(search_scope);

    return <div className="flex-grow flex items-center h-full">
        <AsyncSuggestions stopSearchFocus={() => {
            unaverse.stopSearchFocus();
        }} external_focus={unaverse.focus_search} reset_on_click onDirectSearch={(q) => {
            if (domain) {
                navigate(`/c/${domain}/search?term=${q}`)
            } else {
                navigate(`/search?term=${q}`)
            }
            addRecentSearch(search_scope, `query-${q}`, {
                key: 'direct-search',
                label: q,
                icon: 'search'
            });
        }} clearable onClick={(data) => {
            addRecentSearch(search_scope, data.key, data);
            handleSearchClicks(data,navigate,domain)
        }} handleSearch={q => handleMainSearch(q, search_scope)} layout="large" default_options={recent} show_menu_focus
                          placeholder="Search"
                          icon={<div className="absolute top-2.5 left-2.5 h-5 text-gray-500 w-5">
                              <MagnifyingGlassIcon/>
                          </div>}
                          input_classes={`${bar_classes} pl-10 w-full focus-within:bg-white h-10 flex justify-start items-center flex-grow rounded-2xl border`}/>

    </div>
}