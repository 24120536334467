import {buildImageUrl} from "../../../../common/utilities/images";

export function buildCommunityNavConfig(community, user_handle, sidebar_sections) {
    let sections = [];

    if (sidebar_sections) {
        sidebar_sections.forEach((section) => {
            if (section.items.length > 0) {
                sections.push({
                    name: section.name,
                    icon: section.favicon || section.emoji ? {
                        emoji: section.emoji,
                        favicon: section.favicon,
                        type: section.favicon ? "favicon" : "emoji"
                    } : null,
                    items: section.items.map((item) => {
                        return {
                            name: item.name,
                            letter: item.name.charAt(0).toUpperCase(),
                            emoji: item.emoji,
                            type: item.type,
                            image: item.image || "",
                            show_update: false,
                            is_external: item.type === "external-link",
                            link: item.url
                        }
                    })
                })
            }
        })
    }

    if (community?.shortcuts.length > 0) {
        // shortcuts come sorted
        sections.push({
            name: 'Spaces',
            link: `/c/${community?.profile?.handle}/spaces`,
            create: {
                tooltip: "New Space",
                link: `/c/${community?.profile?.handle}/spaces?action=create`
            },
            items: community.shortcuts.map((shortcut) => {
                return {
                    name: shortcut.label,
                    letter: shortcut.label.charAt(0).toUpperCase(),
                    emoji: shortcut.emoji,
                    show_update: !!shortcut.show_update,
                    show_muted: !!shortcut.show_muted,
                    icon: shortcut?.show_lock ? "lock" : null,
                    featured: !!shortcut.featured,
                    count: shortcut?.count || 0,
                    link: shortcut.click.to
                }
            })
        })
    }

    return {
        flags: {
            show_communities: true,
            show_sidebar: true,
        },
        profile_path: `member/${user_handle}`,
        base_path: `/c/${community?.profile?.handle}`,
        community: {
            name: community.name,
            handle: community?.profile?.handle,
            uid: community.uid,
            // logo: `https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2FOUQZXK59QZ_medium?alt=media`,
            logo: `${buildImageUrl(community?.profile?.profile_picture)}`
        },
        mobile: {},
        sidebar: {
            width: 300,
            show_logo: true,
        },
        header: {
            items: [
                'home',
                'search',
                'contacts',
                'calendar'
            ]
        },
        body: {
            sections
        },
        footer: {
            items: [
                //    'help'
            ]
        }
    }
}