import React, {useState} from 'react';
import {TableOfContents} from "../../../m3/_legacy_components/table-of-contents";
import {LayoutComplex} from "../../../m3/_legacy_components/app-frame/layouts";
import {UnaverseSectionHeader} from "./unaverse";
import {useNavigate} from "react-router-dom";
import {collection, getDocs, limit, orderBy, query, startAfter, where} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {buildTableColumns} from "../shared/users-table";
import Table from "../../../m3/_legacy_components/table";

function buildMenu(page,setPage) {
    return [
        {
            title: "Communities",
            key: "communities",
            items: [
                {
                    label: "All Communities",
                    count: "421",
                    key: "all-communities",
                    icon: null,
                    onClick: () => setPage('communities-all'),
                    isActive: () => page === 'communities-all',
                    can_expand: false,
                    items: []
                },
            ]
        }
    ];
}

const info = {
    columns: [
        "name",
        "id",
        "handle",
    ],
    spec: {
        "id": {
            label: "ID",
            type: "id",
            required: true
        },
        "name": {
            label: "Name",
            type: "title-subtitle-image",
            meta: {
                title: "name",
                subtitle: "handle",
                image: "profile_picture",
                image_color: "profile_picture_color",
            },
            required: true
        },
        "handle": {
            label: "Handle",
            type: "handle",
            required: true
        }
    }
};

async function loadCommunities(page, pp = 10, start_after) {
    const col = collection(db, 'communities');

    let q;
        if (start_after && page > 0) {
            q = query(col, orderBy("name"), startAfter(start_after), limit(pp));
        } else {
            q = query(col, orderBy("name"), limit(pp));
        }

    const snap = await getDocs(q);
    return snap.docs;
}

function buildTableConfig(info_item,navigate) {
    return {
        ready: true,
        onRowClick: (id)=>navigate(`/manage/community/${id}`),
        loadData: async (page, pp, start_after, query) => {
            const docs = await loadCommunities(page, pp, start_after, query);
            const last_visible = docs[docs.length - 1];
            const data = docs.map(doc => {
                return {
                    ...doc.data(),
                    id: doc.id
                }
            });

            let has_more = docs.length >= pp;

            return {
                data: data,
                last_ref: last_visible,
                has_more: has_more
            }
        },
        actions: [
            {
                type: "button",
                onClick: (pl) => {
                    navigate(`/manage/community/${pl.id}`)
                },
                props: {
                    text: "Edit"
                }
            }
        ],
        can_paginate: true,
        transformData: (d) => {
            let a = [];

            d.forEach((entry, index) => {
                let o = {
                    ...entry
                };
                info_item.columns.forEach(col => {
                    o[col] = `${entry[col] || ""}`;
                })
                a.push(o);
            })
            return a;
        },
        columns: buildTableColumns(info_item.columns, info_item.spec),
        per_page: 10,
        query,
        total_count: 2600
    };
}

export function ManageCommunitiesPage() {
    const navigate = useNavigate();
    const [page,setPage] = useState("communities-all");
    const menu = buildMenu(page,setPage);
    const table_config = buildTableConfig(info, navigate);
    return <LayoutComplex show_sidebar={false} sidebar={<TableOfContents sections={menu}/>}>
        <div className="space-y-4">
            <UnaverseSectionHeader
                title={"Communities"}
                subtitle={"All communities"}
                actions={<div className="flex space-x-2">

                </div>}
            />

            <Table {...table_config} />
        </div>
    </LayoutComplex>
}