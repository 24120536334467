import React, {Component, useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {handleIsUnique} from "../utilities";
import Field from "../../form/field";
import {sanitizeHandle} from "../../createable-async-select/entities";
import {utils_strings_isHandle} from "../../../../../common/utilities/strings";

const Frame = styled.div`
  position: relative;
`;


function getMeta(scope) {
    if (scope === 'community') {
        return '2-80 characters. Letters, numbers, dashes, and underscores only.';
    } else {
        return '5-80 characters. Letters, numbers, dashes, and underscores only.';
    }
}

function getLabel(type, change_mode) {
    if (type === 'community') {
        return 'Domain'
    }
    return change_mode ? "New Handle" : "Handle"
}

export default function HandleSelector({
    // check against
                                           current_handle = '',
                                           type = '',
                                           check_id = '',
                                           invalid,
                                           default_text = '',
                                           autoFocus = false,
                                           show_label = true,
                                           change_mode = false,
                                           scope,
                                           scope_id,
                                           setHandle,
                                           checkingHandle
                                       }) {
    const [handleVal, setHandleVal] = useState(default_text);
    const [valid, setValid] = useState(false);
    const [checking, setChecking] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [checked, setChecked] = useState(false);
    const [meta_handle, setMetaHandle] = useState("");
    const [taken_handle, setTakenHandle] = useState("");
    const [taken, setTaken] = useState(false);

    const isFirstRun = useRef(true);

    useEffect(function () {
        // as long as it's invalid, keep trying to update
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        if (default_text.length > 1 && !valid && !checking && !checked) {
            const handle_new = sanitizeHandle(default_text);
            if (utils_strings_isHandle(handle_new, scope)) {
                checkIfHandleExists(handle_new).then(() => {
                });
            }
        }
    }, [default_text, valid, checking]);

    function handleChange(v2, blur = false) {
        let v = v2.replace(/ /g, '');
        setHandleVal(v.toLowerCase().trim());
        setChecked(false);
        setTaken(false);
        setTakenHandle("");
        if (blur) {
            handleBlur();
        }
    }

    function handleBlur() {
        console.log("handleBlur",handleVal,default_text)
        if (handleVal && handleVal === default_text) {
            // is initial
            return;
            setChecking(true);
            checkIfHandleExists(handleVal).then(() => {});
        } else if (!utils_strings_isHandle(handleVal, scope)) {
            setHandle('');
            setValid(false);
        } else {
            setValid(true);
            checkIfHandleExists(handleVal).then(() => {
            });
        }
    }

    function checkIfHandleExists(value, skip) {
        console.log("checkIfHandleExists")
        if (!skip) {
            setChecking(true);
            if (checkingHandle) {
                checkingHandle();
            }
        }

        if (skip && generating) {
         //
            return;
        } else if (skip && !generating) {
            setGenerating(true);
        }

        return handleIsUnique(value, type, scope, scope_id, check_id).then((taken) => {
            // handleIsUnique true sean member community J62oUDAvKbZFS91QiaTj
            console.log("taken",taken,value,scope,scope_id,check_id,default_text)
          if (taken && value === default_text) {
                if (value.length > 3) {
                    let rand = Math.floor(Math.random() * (999 - 100 + 1) + 100);
                    checkIfHandleExists(`${value}-${rand}`, true);
                }
                return false;
            } else if (taken) {
                // let parent know
                const val = Math.floor(1000 + Math.random() * 9000);
                setTakenHandle(handleVal);
                let nh = handleVal?`${handleVal}-${val}`:value?`${value}-${val}`:`group-${val}`;
                setChecking(false);
                setMetaHandle(handleVal);
                setHandleVal(nh);
                setChecked(true);
                setTaken(true);
                setValid(false);
                setHandle('');
                checkIfHandleExists(`${nh}`, true);
            } else {
                setHandleVal(value);
                setChecking(false);
                setChecked(true);
                setTaken(false);
                setValid(true);
                setHandle(value);
            }
            return taken;
        });
    }

    const success = valid && checked && !taken;
    const error = (taken && checked);
    const invalid_chars = !utils_strings_isHandle(handleVal,false);
    const too_long = handleVal.length>27;
    console.log("handleVal",handleVal)
    return <Frame>
        <Field type="" help_text={getMeta(scope)} label={getLabel(type, change_mode)}>
            <input id="handle" maxLength={80} minLength={2} type="handle" placeholder={"my-handle"} autoFocus={autoFocus}
                   value={handleVal} onBlur={e=>handleBlur()}
                   className="input-focus border rounded-md leading-10 text-base px-3 w-full"
                   onChange={(e)=>handleChange(e.target.value)}/>
        </Field>

        {taken_handle && <div className="text-sm text-red-700 mt-3"><strong>@{meta_handle}</strong> is already taken</div>}
        {invalid_chars && handleVal && <div className="text-sm text-red-700 mt-3">Handle may not contain: . , \ | / " ' ; : @ $ # ^ % * ( ) [ ] { } &#62; &#60; &  ? </div>}
        {too_long && handleVal && <div className="text-sm text-red-700 mt-3">Handle may contain up to 28 characters</div>}
        {handleVal && checked && !taken && !valid && <div className="text-sm text-red-700 mt-3">Handle is invalid</div>}
    </Frame>

}