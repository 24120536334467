import React, {useEffect} from "react";
import {AL2Sidebar} from "./sidebar";
import {DynamicAppFrame} from "../dynamic-frame";
import {useUnaverse} from "../../../config/unaverse";
import {useIsMenuBreakpoint} from "../../hooks/is-mobile";
import M3_A_Tooltip from "../../atoms/tooltip";
import M3_A_IconButton from "../../atoms/icon-button";

import {useCommunity} from "../../../config/community";
import {m3_icon_map} from "../../icons/icon-map";
import {buildImageUrl} from "../../../../common/utilities/images";

export function AL2SidebarToggle({theme, close, toggleSidebar}) {

    return <M3_A_Tooltip text="Toggle Sidebar" usePortal>
        <div style={{
            color: theme?.background_text_40
        }}>
            <M3_A_IconButton square size="base" adjust_icon_size={-1} onClick={toggleSidebar} variant="light" compact
                             icon={close ? m3_icon_map.outlines.close : m3_icon_map.custom.sidebar}/>
        </div>
    </M3_A_Tooltip>;
}

function hydrateConfig(c, color_scheme, raw_community) {

    c.footer = {
        avatar: raw_community?.member?.profile_picture ? buildImageUrl(raw_community?.member?.profile_picture, "_medium") : "",
        letter: raw_community?.member?.name.charAt(0),
        name: raw_community?.member?.name
    };

    return {
        ...c
    }
}

export default function AppLayout({
                                       raw_community,
                                       defaults_key = 'global-defaults',
                                       location = {},
                                       footer_message,
                                       hideSidebar,
                                       has_right_sidebar,
                                       init_config,
                                       right_sidebar,
                                       color_scheme,
                                       communities = [],
                                       children,
                                       sections = [],
                                       override_community = {},
                                       override_flags = {},
                                       ...rest
                                   }) {
    const unaverse = useUnaverse();
    const community = useCommunity();
    const isMenuBreakpoint = useIsMenuBreakpoint();
    const [config, setConfig] = React.useState(hydrateConfig({
        ...init_config,
        community: {
            ...init_config.community,
            ...override_community
        },
        flags: {
            ...init_config.flags,
            ...override_flags
        },
        body: {
            ...init_config.body,
            sections: [
                ...init_config.body.sections
            ]
        }
    }, color_scheme, raw_community));

    useEffect(function () {
        // check for changes in init_config
        const changed = Object.keys(init_config).some(function (key) {
            return init_config[key] !== config[key];
        });
        if (changed) {
            setConfig(hydrateConfig({
                ...init_config
            }, color_scheme, raw_community));
        }
    }, [init_config, raw_community])

    useEffect(function () {
        if (color_scheme && sections.length > 0 && override_community && override_flags) {
            setConfig(hydrateConfig({
                ...config,
                community: {
                    ...config.community,
                    ...override_community
                },
                flags: {
                    ...config.flags,
                    ...override_flags
                },
                body: {
                    ...config.body,
                    sections: [
                        ...sections
                    ]
                }
            }, color_scheme, raw_community));
        }
    }, [color_scheme, raw_community, sections, override_community, override_flags]);

    const hideLeftSidebar = !!isMenuBreakpoint;
    const collapsed = hideLeftSidebar || unaverse.collapseSidebar;

    let left_sidebar = <AL2Sidebar collapsed={collapsed} toggleSidebarHover={() => {
        unaverse.toggleSidebarHover();
    }} showSidebarHover={unaverse.showSidebarHover} raw_community={raw_community}
        toggleSidebar={() => {
        unaverse.toggleSidebar();
    }} config={config}/>;

    return <DynamicAppFrame showSidebarHover={unaverse.showSidebarHover} config={config} footer_message={footer_message}
                            toggleSidebarHover={unaverse.toggleSidebarHover} defaults_key={defaults_key}
                            sidebarCollapsed={collapsed} hideSidebar={hideSidebar} theme={community.active_theme}
                            showRightSidebar={has_right_sidebar} updateParent={sidebarIsCollapsed => {
        unaverse.setSidebarCollapsed(sidebarIsCollapsed);
    }}
                            left_sidebar={left_sidebar} right_sidebar={right_sidebar}>
        {children}
    </DynamicAppFrame>;
}