import React from "react";
import Field from "../../../../../m3/_legacy_components/form/field";
import {DateRange} from "../../../position/past-assignees/date-range";

const EdStep = {
    school: {
        community_uid: '',
        title: '',
        image: '',
        handle: ''
    },

    degree: '', // Bachelor of Science (B.Sc.)

    field_of_study: '', // BWL

    current: false,
    from_year: null,
    to_year: null,

    grade: '', // 1.0

    description: ''
};

export function AddEducationStep({education,updateEducation}) {
const {grade,field_of_study,from_year,to_year,org_data,degree,description} = education;

    return <div className="flex flex-col gap-4">
            <Field placeholder='Select Degree' autoFocus taxonomy='degrees' type='custom-add-select' label="Degree" onChange={(v)=>{updateEducation('degree',v)}}
                   value={degree} />

            <Field placeholder='Select Field of Study' taxonomy='fields_of_study' type='custom-add-select' label="Field of Study" onChange={(v)=>{updateEducation('field_of_study',v)}}
                   value={field_of_study} />

            <Field placeholder='Select School' taxonomy='schools' type='custom-add-select' label="School" onChange={(v)=>{updateEducation('org_data',v)}}
                   value={org_data.title} />

            <DateRange range={{
                current: false,
                from_year,
                from_month: null,
                to_year,
                to_month: null
            }} enable_current_check={false} onChange={(v)=>updateEducation('range',v)} labels={{start:'Start year',end:'End year (or projected)'}} type='year'/>

            <Field placeholder='Select Grade' taxonomy='grades' type='custom-add-select' label="Grade" onChange={(v)=>{updateEducation('grade',v)}}
                   value={grade} />

            <Field optional type={"textarea"} label="Description" onChange={(v)=>{updateEducation('description',v)}}
                   value={description}/>
    </div>
}