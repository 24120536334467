import React, {useEffect, useLayoutEffect} from "react";
import styled from "styled-components";

/*
* This is the Layout component
 */

const _props = {};
// {variant}_{collapsed}_{open}
const wrapper_grid_map = {
    'full-width_true_false': "1fr",
    'full-width_true_true': "1fr",
    'full-width_false_true': "320px 1fr",
    'full-width_false_false': "1fr",

    'right-sidebar_true_true': "1fr",
    'right-sidebar_true_false': "1fr",
    'right-sidebar_false_true': "1fr {SIDEBAR_WIDTH}px",
    'right-sidebar_false_false': "1fr {SIDEBAR_WIDTH}px",
};

const WrapperDiv = styled.div`
  grid-template-columns: ${props => wrapper_grid_map[`${props.variant}_${props.collapsed}_${props.open}`].replace("{SIDEBAR_WIDTH}", props.sidebar_width)};
`;

function getCollapse(main_min_width, variant, sidebar_width, open) {
    // if breakpoint is 1024
    const el = document.getElementById("scroll-wrapper");

    if (el) {
        const wrapper_width = el.offsetWidth;
        if (variant === "right-sidebar") {
            // if
            if (wrapper_width < (main_min_width + sidebar_width)) {
                return true;
            }
        } else if (variant === "full-width") {
            if (wrapper_width < (main_min_width + 320)) {
                return true;
            } else if (open) {
                return false;
            }
        }
        return false;
    } else {
        // get innerwidth to determine if we should collapse
        const inner_width = window.innerWidth;

        if (variant === "right-sidebar") {
            // if
            if (inner_width > (main_min_width + sidebar_width + (inner_width * 0.2))) {
                return false;
            }
        } else if (variant === "full-width") {
            if (open) {
                return false;
            }
        }

        return true;
    }
}

export default function M3_C_Layout({
                                        main,
                                        vertical_divider = true,
                                        main_min_width = 600,
                                        sidebar,
                                        variant = "full-width"
                                    }) {
    const sidebar_width = 320;
    const [open, setOpen] = React.useState(false);
    const [collapsed, setCollapsed] = React.useState(getCollapse(main_min_width, variant, sidebar_width, open));

    const collapsed_ref = React.useRef(collapsed);
    const open_ref = React.useRef(open);

    useEffect(function () {
        const listener = () => {

            const new_collapsed = getCollapse(main_min_width, variant, sidebar_width, open_ref.current);

            collapsed_ref.current = new_collapsed;

            if (new_collapsed !== collapsed_ref.current) {
                setCollapsed(new_collapsed);
            }
        };

        window.addEventListener("resize", listener);

        return () => {
            window.removeEventListener("resize", listener);
        }
    }, [main_min_width, sidebar_width]);

    let wrapper_classes = "grid relative h-full rounded-t-xl";

    const custom_props = {
        layout_onToggleOpen: () => {
            const new_open = !open;
            const new_collapse = getCollapse(main_min_width, variant, sidebar_width, new_open);
            open_ref.current = new_open;
            collapsed_ref.current = new_collapse;
            setOpen(new_open)
            setCollapsed(new_collapse);
        },
        layout_open: open,

        inline_sidebar_width: variant === "full-width" && !collapsed && open ? sidebar_width : 0,
    };

    function renderSidebar() {
        // render sidebar component function with custom props

        return React.Children.map(sidebar, child => {
            return React.cloneElement(child, {
                ...custom_props
            }, null)
        });
    }

    function renderMain() {
        // render main component function with custom props

        return React.Children.map(main, child => {
            return React.cloneElement(child, {
                ...custom_props
            }, null)
        });
    }

    let border = "";

    if (variant === "full-width" && !collapsed && open) {
    } else if (variant === "right-sidebar" && !collapsed && open) {
        border = "border-r border-gray-200";
    }

    return (
        <WrapperDiv variant={variant} id="scroll-wrapper" sidebar_width={sidebar_width} collapsed={collapsed}
                    open={open}
                    className={wrapper_classes}>
            {variant === "full-width" && <div id="scroll-sidebar" style={{transition:"width 0.1s ease-in-out"}}
                                                      className={`${open?"w-full":"hidden w-0"} ${collapsed ? "absolute top-0 bottom-0 left-0 z-30 w-[320px] border-r border-gray-200 drop-shadow-md" : "relative border-r border-gray-200"} bg-white h-full overflow-y-auto`}>
                {open&&renderSidebar()}
            </div>}
            <div className={`relative ${border}`} id="scroll-main">
                {renderMain()}
            </div>
            {!collapsed && variant === "right-sidebar" &&
                <div id="scroll-sidebar" className={`relative z-30 overflow-y-auto`}>
                    {renderSidebar()}
                </div>}
        </WrapperDiv>
    )
}