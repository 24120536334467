import React from "react";
import {IntroducingUnaty} from "./components/introducing-unaty";
import {setDocumentTitle} from "../../m3/utilities/set-document-title";

export function UnaversePage() {
    setDocumentTitle("Welcome to Orange");

    // <FindAnAssociationReference/>
    return <div>
        <IntroducingUnaty/>
    </div>
}