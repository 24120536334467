import React, {useState} from "react";
import {AdminActivityItem} from "./item";
import dayjs from "dayjs";
import {ChevronUpDownIcon} from "@heroicons/react/20/solid";
import {SectionHeader} from "../entity-grid";
import EmptyState from "../empty-state";
import {CalendarDaysIcon} from "@heroicons/react/24/outline";
import {utils_dates_format} from "../../../../common/utilities/dates";

function getConnector(len,index) {
    if((index+1)===len) {
        // last one
        return 'none';
    } else {
        return 'line'
    }
}

export function ListExpander({count=3,onClick=()=>{}}) {

    return <div onClick={onClick} className={`hover:opacity-80 transition-opacity flex space-x-2 cursor-pointer`}>
        <div className="w-5 h-5 rounded-full flex items-center justify-center shadow-md bg-white">
            <div className="w-4 h-4 text-gray-600">
                <ChevronUpDownIcon />
            </div>
        </div>
        <div className="text-gray-600 leading-5 text-xs font-medium">
            Show {count} more
        </div>
    </div>
}

function AAFeedSection({events, title, context}) {
    const len = events.length;
    const [show,setShow] = useState(5);
    return <div>
        <div className="text-base pb-1 tracking-tight font-bold text-gray-800">
            {title}
        </div>
        {events.slice(0,show).map((event,k) => {
            const show_time = k===0?true:events[k-1].date_formatted!==event.date_formatted;
            return <AdminActivityItem show_time={show_time} connector={getConnector(events.length, k)} context={context} key={`${event.id}-${k}`} {...event} />
        })}
        {events.length>show&&<div style={{backgroundImage:'linear-gradient(rgba(255,255,255,0), #ffffff, #ffffff, #ffffff)'}} className="px-1.5 relative pb-1 pt-3 -mt-2">
            <ListExpander count={events.length-show} onClick={()=>setShow(len)} />
        </div>}
    </div>
}

export function AdminActivityFeed({events=[],context}) {
    const final_events = events.filter(e=>!!e&&!!e.ts).map(evt=>{
        return {
            ...evt,
             ...utils_dates_format(evt?evt.ts:Date.now())
        }
    });

    let obj = {
        'today': {events: [], title: 'Today'},
        'yesterday': {events: [], title: 'Yesterday'},
        'this-week': {events:[],title: 'This Week'},
        'last-month': {events:[],title: 'Last Month'},
        'last-year': {events:[],title: 'Last Year'},
        'beyond': {events:[],title: 'Beyond'},
    };

    final_events.forEach(evt=>{
        obj[evt.sort].events.push(evt);
    });

    const sections = Object.values(obj).filter(a=>a.events.length>0);

    if(final_events.length===0) {
        return <EmptyState title="No Events found" icon={<CalendarDaysIcon/>} />
    }

    return <div className="space-y-2">
        {sections.map(sec=><AAFeedSection title={sec.title} key={sec.title} context={context} events={sec.events}/>)}
    </div>;
}