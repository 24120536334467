import memoize from "lodash/memoize";
import {addDoc, collection, doc, getFirestore} from "firebase/firestore";
import {direct_queryContent, waitForResult} from "../directory/build-elasticsearch-query";
import {getItemFromLocalStorage, saveItemToLocalStorage} from "../../../m3/utilities/localstorage";

export async function mainSearch(query,scope,types,search_meta) {

    const req = buildQuery(query,scope,types,search_meta);

    return await direct_queryContent(req)
        .then((resp)=>{
            return resp;
        });
    /*
    return await queryContent(req)
        .then((resp)=>{
            return resp;
        });

     */
}

function buildQuery(query,scope,types,search_meta) {
    const page = 0;
    const size = 25;
    let a = {
        current: page,
        size: size,
        result_fields: {
            title: {raw: {}},
            image: {raw: {}},
            id: {raw: {}},
            type: {raw: {}},
            handle: {raw: {}},
            description: {raw: {}},
            scope: {raw: {}},
            data: {raw: {}},
        },
        search_fields: {
            title: {},
            handle: {},
            keywords: {},
        },
        sort: {
            _score: "desc"
        },
        query,
        filters: {
            all: [
                {
                    "scope": scope
                }
            ],
            any: [
                {
                    "type": types
                }
            ],
            none: [],
        },
    }

    if(search_meta&&search_meta.filters) {
        search_meta.filters.all.forEach(fi=>{
            a.filters.all.push(fi);
        })
        search_meta.filters.any.forEach(fi=>{
            a.filters.any.push(fi);
        })
        search_meta.filters.none.forEach(fi=>{
            a.filters.none.push(fi);
        })
    }
    return a;
}

export const queryContent = memoize(async (request) => {
    const col = collection(getFirestore(), "searches");

    const ref = await addDoc(col, {
        type: 'all',
        engine: 'content',
        request,
        response: null
    });

    const nref = doc(col,ref.id);

    let resp1 = await waitForResult(nref);

    if(resp1.ok==='no') {
        resp1 = await waitForResult(nref,3000);
    }

    if(resp1.ok==='no') {
        resp1 = await waitForResult(nref,6000);
    }
    console.log("RESP1",resp1)

    return resp1;
});

function uniqueArray(items) {
    let arr = [];
    items.forEach(function(item){
        const i = arr.findIndex(x => x.id === item.id);
        if(i <= -1){
            arr.push(item);
        }
    });
    return arr;
}

export function addRecentSearch(scope,id,data) {
    let f = [
        {id,...data}
    ];
    const all_recent = getRecentSearches(scope);
    f = f.concat(all_recent);
    saveItemToLocalStorage(`${scope}`,uniqueArray(f).slice(0,5));
}

export function removeRecentSearch(scope,index) {
    let all_recent = getRecentSearches(scope);
    all_recent.splice(index,1);
    saveItemToLocalStorage(`${scope}`,all_recent.slice(0,5));
}

export function getRecentSearches(scope) {
    return getItemFromLocalStorage(scope,[]);
}