import React from 'react';

/*
* This is the Text atom
* It is a simple text component
* It can be used to display text at different sizes and weights and colors
 */

const _props = {
    children: {
        type: "node",
        required: false,
    },
    size: {
        type: "string",
        required: false,
        fallback: "base",
        options: [
            "2xs",
            "xs",
            "sm",
            "base",
            "lg",
            "xl"
        ]
    },
    color: {
        type: "string",
        required: false,
        fallback: "text-gray-900"
    },
    weight: {
        type: "string",
        required: false,
        fallback: "regular",
        options: [
            "thin",
            "extra-light",
            "light",
            "normal",
            "medium",
            "semi-bold",
            "bold",
            "extra-bold",
            "black"
        ]
    }
};

function getTextSize(size) {
    switch (size) {
        case "inherit":
            return "";
        case "2xs":
            return "text-2xs";
        case "xs":
            return "text-xs";
        case "sm":
            return "text-sm";
        case "base":
            return "text-base";
        case "lg":
            return "text-lg";
        case "xl":
            return "text-xl";
        default:
            return "text-base";
    }
}

export default function M3_A_Text({
                                      as = "span",
                                      children,
                                      bold_offset,
                                      truncate,
                                      sx,
                                      className,
                                      size = "inherit",
                                      color = "inherit",
                                      leading = "",
                                      weight = "font-regular"
                                  }) {
    let classes = "", props = {
        style: {}
    };

    classes += " ";

    if (leading) {
        classes += ` ${leading}`;
    }

    if (sx) {
        props.style = sx;
    }

    if (color && color.startsWith("#")) {
        props.style.color = color;
    } else if (color) {
        classes += ` ${color}`;
    }

    classes += ` ${getTextSize(size)}`;

    classes += ` ${weight}`;

    if (className) {
        classes += ` ${className}`;
    }

    if (truncate) {
        classes += " truncate";
    }

    if (bold_offset) {
        // set attr data-text to children if type string
        if (typeof children === "string") {
            props["data-text"] = children;

            // also add className of .bold-offset-label
            classes += " bold-offset-label";
        }
    }

    props.className = classes;


    return React.createElement(as, props, children);
}