import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {useUnaverse} from "../../config/unaverse";
import {collection, doc, getDoc} from "firebase/firestore";
import {db} from "../../config/setup-firestore";
import {useToasts} from "../../config/toasts";
import {TextAction} from "../../routes/auth/sign-in";
import {DoubleCheckModal} from "./double-check-modal";
import AddSecondaryEmail from "./user-email-manager";
import {authFetch} from "../../../api/network";

const List = styled.div`
    width: 400px;
    padding: 6px 0;
`;

const Item = styled.div`
    display: grid;
        padding-bottom: 6px;
    grid-template-columns: 1fr auto;
`;

export default function SecondaryEmails({user,community_uid='',hide_title=false,user_uid='',updateMember}) {
   const unaverse = useUnaverse();
   const toasts = useToasts();
    const [loading, setLoading] = useState('');
    const [email, setEmail] = useState('');
    const [modal, setModal] = useState(null);
    const [data, setData] = useState(user);

    const use_uid = user_uid==='' ? unaverse.user_uid : user_uid;

    useEffect(function () {
        if(!user&&user_uid!=='') {
            // get user
            const ref = doc(db, "users", user_uid);
            getDoc(ref)
                .then(doc => {
                    setData(doc.data());
                });
        }
    }, [user]);

    function removeSecondaryEmail() {
        setLoading('removing-email');
        const res = () => {
            setLoading('');
            setModal(null);
            setEmail('');
            toasts.addToast({text: 'Secondary Email Removed', intent: 'success'});
        };
        const payload = {
            email,
            user_uid: use_uid
        };
        authFetch("/users/remove-secondary-email", res, res, "POST", {payload});
    }

    function makePrimary() {
        setLoading('changing-account-email');
        const res = () => {
            if(user_uid!=='') {
                updateMember();
            }
            setLoading('');
            setModal(null);
            setEmail('');
            toasts.addToast({text: 'Primary Email Changed', intent: 'success'});
        };
        const payload = {
            new_email: email,
            user_uid: use_uid
        };
        authFetch("/users/change-account-email", res, res, "POST", {payload});
    }

    if(!data) {
        return null;
    }
    const secondary_emails = !data.secondary_emails || !data.secondary_emails.length ? [] : data.secondary_emails;

    // only users can remove their own
    const can_make_primary = user_uid=== '' || data.last_sign_in===null;
    const can_remove = user_uid === '';

    return <div>
         <List>
            {secondary_emails.map(em => <Item key={em}>
                <div className='text-gray-700 text-sm'>
                {em}
                </div>
                <div>
                    <a className={'color-link text-xs font-medium '+(can_make_primary?"":"hidden")} style={{marginRight:'12px'}} onClick={()=>{
                        setEmail(em);
                        setModal('double-check-make-primary');
                    }}>Make Primary</a>
                    {can_remove&&<a className='color-link text-xs font-medium' onClick={()=>{
                        setEmail(em);
                        setModal('double-check-delete');
                    }}>Remove</a>}
                </div>
            </Item>)}
            {secondary_emails.length === 0 ? 'No secondary emails added yet' : ''}
        </List>

        <TextAction text="Add Email" onClick={()=>setModal('add-email')} />


        {modal==='add-email'&&<AddSecondaryEmail community_uid={community_uid} user_uid={use_uid} onClose={()=>setModal(null)} />}

        {modal==='double-check-delete'&&<DoubleCheckModal onConfirm={()=>removeSecondaryEmail()} onClose={()=>{setModal(null);setEmail('')}} onCancel={()=>{setModal(null);setEmail('')}} type='user_remove_email' />}
        {modal==='double-check-make-primary'&&<DoubleCheckModal onConfirm={()=>makePrimary()} onClose={()=>{setModal(null);setEmail('')}} onCancel={()=>{setModal(null);setEmail('')}} type='user_change_primary_email' />}
    </div>
}