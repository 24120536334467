import React, {useState} from "react";
import M3_C_PromptDialog from "../../../../m3/components/prompt-dialog";
import M3_A_FormControl from "../../../../m3/atoms/form-control";
import Field from "../../../../m3/_legacy_components/form/field";
import {api_addMembersToGroup} from "../../../../../api/groups/add-people";

export default function ContactsModalAddToSpace({
                                                    selected,
                                                    ctx,
                                                    handleSuccess = () => {},
                                                    handleClose = () => {}
                                                }) {
    const [space, setSpace] = React.useState("");
    const [loading, setLoading] = useState(false);

    function handleAdd() {
        if(!space) return;
        setLoading(true);
        api_addMembersToGroup(ctx, space, selected)
            .then(resp => {
                handleSuccess(resp);
            })
    }

    return <M3_C_PromptDialog title={"Add Members to Space"} confirm_loading={loading}
                              onConfirm={() => {
                                  handleAdd();
                              }} confirm_label="Add" confirm_disabled={!space} onClose={() => {
        handleClose();
    }}>
        <M3_A_FormControl label="Space">
            <Field input_props={{community_uid: ctx.community_uid}} value={space} id="group"
                   onChange={(a, b) => {
                       setSpace(b);
                   }} type="group"/>
        </M3_A_FormControl>
    </M3_C_PromptDialog>
}