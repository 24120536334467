import React from 'react';
import M3_A_Input from "./input";
import {m3_icon_map} from "../icons/icon-map";
import M3_A_Icon from "./icon";
import {m3_adjust_size} from "../utilities";
import M3_A_IconButton from "./icon-button";

/*
* This is the SearchInput atom
 */

export default function M3_A_SearchInput({
                                             sx,
                                             fill = true,
                                             show_icon = true,
                                             placeholder = "Search",
                                             size = "sm",
                                             onEnter = () => {
                                             },
                                             onClear,
                                             adjust_icon_size = -1,
                                             ...props
                                         }) {

    const p = {
        ...props,
        size,
        sx,
        onEnter,
        placeholder,
        fill,
        trailing_indicator: !!onClear && props.value ? <M3_A_IconButton size={m3_adjust_size(size, -1)} onClick={() => {
            onClear();
        }} compact icon_size={m3_adjust_size(size, -1)} square variant={"minimal"}
                                                                        icon={m3_icon_map.solid["close"]}/> : null,
        leading_indicator: !show_icon ? null : <M3_A_Icon size={m3_adjust_size(size, adjust_icon_size)}>
            {m3_icon_map.solid.search}
        </M3_A_Icon>
    };

    return <M3_A_Input {...p}/>
}