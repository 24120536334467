import React, {useEffect, useState} from "react";
import {
    CommunityEntityPage
} from "../../../m3/_legacy_components/app-frame/layouts";
import EntityProfileCard from "../../../m3/_legacy_components/entity-profile-card";
import {useNavigate, useParams} from "react-router-dom";
import {getEventData} from "../../../../api/events";
import {useCommunity} from "../../../config/community";
import {buildEventProfileCard} from "../../../m3/_legacy_components/preview-overlay/event";
import {useUnaverse} from "../../../config/unaverse";
import {event_changeCoverPhoto} from "../../../../api/events/change-cover-photo";
import {m3_icon_map} from "../../../m3/icons/icon-map";
import {clientDownloadFile} from "../../../../common/utilities/files";
import M3_A_ActionMenu from "../../../m3/atoms/action-menu";
import M3_A_IconButton from "../../../m3/atoms/icon-button";
import {api_downloadEvent} from "../../../../api/events/utilities";

export default function EventPage() {
    const community = useCommunity();
    const unaverse = useUnaverse();
    const {id} = useParams();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(function () {
        getEvent();
    }, [id])

    function getEvent() {
        console.log("getEvent", id)
        getEventData(community.id, id)
            .then(dt => {
                console.log("update data", dt)
                if (!dt) {
                    setError("does_not_exist")
                } else {
                    setData(dt)
                }
            })
    }

    function handleCoverPhotoChange(url, color) {
        event_changeCoverPhoto(community, id, {url, color})
            .then(() => {
                getEvent();
                community.closeEditModal()
            })
    }


    const is_organizer_or_admin = true;

    const fns = {
        onOpenEditCoverPhoto: !is_organizer_or_admin ? null : () => {
            community.openEditOverlay({
                type: 'edit-cover-photo',
                size: "42.5rem",
                data: {
                    community_uid: community.uid,
                    onChange: handleCoverPhotoChange
                },
                onClose: () => {
                    community.closeEditModal()
                }
            })
        },
    };
    const flags = {};

    const loading = !data;

    let spec = buildEventProfileCard(data, unaverse, community, flags, fns, loading);
    const is_google_calendar_event = data?.source === 'google';

    const more_options = {
        items: [
            {
                label: "Download Event",
                onClick: () => {
                    console.log("Download Event", id)
                    api_downloadEvent({
                        id,
                        member_id: community.member_id,
                        community_uid: community.uid
                    })
                        .then((resp) => {
                            if (resp?.data?.event) {
                                clientDownloadFile(resp.data.name,resp.data.event,"text/calendar")
                            }
                        })
                }
            }
        ]
    };

    spec.buttons.splice(0,0,<M3_A_ActionMenu renderAnchor={() => {
        return <div><M3_A_IconButton square icon={m3_icon_map.outlines.ellipsis}/></div>
    }} lists={[more_options]}/>);

    const card = <EntityProfileCard {...spec} />;
    const sidebar = <></>;
    const modals = <></>;
    let header_actions = [];

    if (is_organizer_or_admin && data && !is_google_calendar_event) {

        header_actions.push({
            type: "icon",
            icon: m3_icon_map.outlines.edit,
            onClick: () => {
                community.openEditOverlay({
                    type: 'edit-event',
                    id,
                    data: {
                      ...data,
                        id
                    },
                    size: 'plus',
                    closeCb: () => {
                        getEvent();
                    }
                })
            }
        });
    }

    return <CommunityEntityPage card={card} sidebar={sidebar} modals={modals} header={{
        title: spec.title,
        actions: header_actions
    }}>

    </CommunityEntityPage>;
};