import React, {useEffect, useRef, useState} from 'react';
import "../../styles/list-view.css";
import {ListView_Table} from "./table";
import {ContactsPrimaryToolbar} from "../../../routes/community/contacts/components";
/*
* This is the ListView component
 */

const _props = {};

function reorder(arr, from, to) {
    const result = Array.from(arr);
    const [removed] = result.splice(from, 1);
    result.splice(to, 0, removed);
    return result;
}

function buildStartingCols(config) {
    let a = [{
        _id: "id",
        accessorKey: 'id',
        _type: "chip",
        width: 220,
        label: "Member",
        meta: {
            chip_type: "member",
            primary: true,
            align: "left",
            icon: "",
            locked: true,
            onViewProfile: config?.fns?.onViewProfile
        }
    }];

    if (config?.features?.can_edit_records) {
        a[0].action = {
            type: "edit",
            onClick: config?.fns?.onManageRecord
        };
    }

    if (!config.state.active_view_data.config.attributes) {
        return a;
    }

    config.state.active_view_data.config.attributes.forEach((col, index) => {
        const attribute = config.data.attributes.find(attribute => attribute.value === col);
        if (attribute) {
            a.push(attribute.col_data);
            // check for overrides
            const index = a.length - 1;
            const overrides = config.state.active_view_data.config?.overrides;
            if (overrides && overrides?.[attribute.value]) {
                a[index].label = overrides[attribute.value]?.label || attribute?.label;
            } else {
                a[index].label = attribute?.label;
            }
        }
    })

    return a;
}

function pageSizeCalculator(layout = 'list', max = 60) {
    const window_height = window.innerHeight;
    const item_height = 36;
    const multiplier = 1.5;

    const max_items = Math.floor(window_height / item_height) * multiplier;

    return Math.floor(Math.min(max_items, max));
}

export default function M3_C_ListView({
                                          children,
                                          config,
                                          renderEmptyState,
                                          inline_sidebar_width
                                      }) {
    const [cols, setCols] = useState([...buildStartingCols(config)]);
    const current_id = useRef(config.state.active_view_id);

    useEffect(() => {
        if (current_id.current !== config.state.active_view_id && config.state.active_view_id === config.state?.active_view_data?.id) {
            let new_cols = [...buildStartingCols(config)];
            setCols(new_cols);
            current_id.current = config.state.active_view_id;
        }
    }, [config, config.state.active_view_id]);

    useEffect(() => {
        if (config?.fns?.onUpdateAttributes) {
            config.fns.onUpdateAttributes(cols.map(a => a._id).filter(a => a !== 'id'));
        }
    }, [cols]);

    const page_size = pageSizeCalculator();

    function addColumn(col_data) {
        setCols([...cols, col_data])
        // add toast
        config.fns?.addToast({
            intent: "success",
            text: `${col_data.label} added to view`
        })
    }

    function removeColumn(col_id) {
        setCols(cols.filter(col => col.accessorKey !== col_id));
    }

    function handleReorderCols(result, offset = -1) {

        if (!isNaN(result.destination)) {
            return;
        }

        const {destination, source} = result;

        const new_cols = reorder(
            cols,
            offset + source.index,
            offset + destination.index
        );

        setCols(new_cols);
    }

    return <>
        {config.state.show_toolbar&&<ContactsPrimaryToolbar {...{handleReorderCols, col_offset: 1, cols, removeColumn, addColumn}} config={config}>
            {children}
        </ContactsPrimaryToolbar>}

        <ListView_Table renderEmptyState={renderEmptyState} config={config}
                        page_size={page_size} handleHideCol={removeColumn}
                        handleEditColumnLabel={(accessor_key)=>{
                            const col_data = cols.find(col => col.accessorKey === accessor_key);
                            config?.fns?.editColumnLabel(accessor_key,col_data)
                        }}
                        inline_sidebar_width={inline_sidebar_width}
                        handleReorderCols={handleReorderCols}
                        cols={cols}/>
    </>
}