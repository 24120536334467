import React from "react"
import {Avatar} from "../avatar";
import {colorContrast} from "../../../m3/utilities/colors";
import {useNavigate} from "react-router-dom";

function getStyles(invert, card_bg, color = "#dc7636") {
    let background = {}, text;

    if (invert) {
        text = "text-white";
    } else {
        text = "text-gray-800";
    }

    if (buildUrl(card_bg.image)) {
        background.backgroundColor = "#efefef";
        background.backgroundImage = `url(${buildUrl(card_bg.image)})`;
    } else {
        background.backgroundColor = card_bg.color || color;
    }

    return {
        text, background
    }
}

function prepData(data, member) {
    let loading = false, home, color, community_name, id, card_bg, community_logo, name;

    if (!data.name || !member.name) {
        loading = true;
    } else {
        home = `/c/${data.handle}/home`;
        color = data.branding.color;
        name = member.name;
        card_bg = data.member_card_design?.background;
        community_name = data.name;
        id = member.id;
        community_logo = data.profile_picture;
    }

    return {
        loading, card_bg, home, id, color, community_name, community_logo, name
    }
}

function buildUrl(u) {
    return u ? `${u}?alt=media` : "";
}

function Wrapper({background, loading, onClick, children}) {
    return <div onClick={() => {
        if (onClick) {
            onClick();
        }
    }} style={{...background}}
                className={`transform mx-auto ${loading?"animate-pulse":""} bg-center shadow-lg bg-cover transition-transform cursor-pointer content-popup-shadow relative rounded-xl max-w-sm`}>
        <div className="rounded-xl" style={{
            paddingBottom: '60%',
        background:"linear-gradient(354deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.3) 100%)"}}>
            {children}
        </div>
    </div>
}

export default function MembershipCard({image, member, community, onClick, color = "#dc7636"}) {
    const dt = prepData(community, member);
    const navigate = useNavigate();
    const contrast = colorContrast(color);
    let invert = !!dt?.card_bg?.image || contrast === '#ffffff';
    const {background, text} = getStyles(invert, dt.card_bg || {}, dt.color);
    let img_text_styles = !!dt?.card_bg?.image ? " overlay-text-shadow " : "";

    if (dt.loading) {
        return <Wrapper loading={true} background={{backgroundColor: "#dedede"}}/>
    }

    return <Wrapper onClick={() => {
       if(dt.home) {
           navigate(dt.home);
       }
    }} background={background}>
        <div className="absolute top-2.5 w-full left-2.5 space-x-1.5 items-center inline-flex">
            {dt.community_logo && <div>
                <div>
                    <Avatar url={buildUrl(dt.community_logo)} size="h-6 w-6"/>
                </div>
            </div>}
            <div style={{fontSize: '0.9rem',maxWidth:"65%"}}
                 className={`${img_text_styles} ${image ? "font-bold" : "font-semibold"} truncate ${text} flex-grow`}>{dt.community_name}</div>
            <div>

            </div>
        </div>
        <div className={`absolute hidden bottom-2.5 left-2.5 w-full`}>
            <div style={{maxWidth:"90%"}} className={`text-sm font-medium truncate ${img_text_styles} ${text}`}>
                {dt.name}
            </div>
            <div  style={{maxWidth:"90%"}}
                className={`text-xs hidden ${image ? "font-semibold" : "font-bold"} truncate  -mt-0.5 ${img_text_styles} ${text}`}>{dt.id}
            </div>
        </div>
    </Wrapper>
};