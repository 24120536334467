import React, {useEffect, useState} from "react"
import './styles.css';
import {useCommunity} from "../../../config/community";
import {getEventsFromFeed} from "./api";
import {processEventsForSemanticWelcome} from "./process";
import DataWrapper from "../data-wrapper";
import {Link, useNavigate} from "react-router-dom";
import {logEngagementEvent} from "../../../config/firebase-setup";
import {getPhoneNumberData} from "../../utilities/phone";

/*
     <div className="hidden">
            It’s nice to see you again, since you last logged in <Bold>Nettra Pan became President</Bold> and <Bold>3
            new groups</Bold> were created. Also, <Bold>4 new members
            joined</Bold>.
        </div>
 */
function RenderName({data}) {
    const community = useCommunity();

    function onClick() {
        community.goToPreview(data);
    }

    return <span onClick={()=>onClick()} className="underline cursor-pointer">{data.name||"loading"}</span>;
}

function MemberTag({id,cid}) {
    return <DataWrapper id={id} type={`community_members.${cid}.members`}>
        <RenderName />
    </DataWrapper>
}

function NamesChange({items=[]}) {
    const community = useCommunity();

    if(items.length===1) {
        return <span>
            <MemberTag id={items[0].scope_id} cid={community.id} />'s membership level changed to {items[0].data.new||""}
        </span>
    }

    if(items.length===2) {
        if(items[0].data.new===items[1].data.new) {
            return <span>
            <MemberTag id={items[0].scope_id} cid={community.id} /> and <MemberTag id={items[1].scope_id} cid={community.id} />'s membership level changed to {items[1].data.new}
        </span>
        }
        return <span>
            <MemberTag id={items[0].scope_id} cid={community.id} /> became {items[0].data.new} and <MemberTag id={items[1].scope_id} cid={community.id} />'s membership level changed to {items[1].data.new}
        </span>
    }

    return <span>
        {items.length} membership levels changed
    </span>
}

function GeneralChange({singular="bio was updated",multi='updated their bios',items=[]}) {
    const community = useCommunity();

    if(items.length===1) {
        return <span>
            <MemberTag id={items[0].scope_id} cid={community.id} />'s {singular}
        </span>
    }

    if(items.length===2) {

        return <span>
            <MemberTag id={items[0].scope_id} cid={community.id} /> and <MemberTag id={items[1].scope_id} cid={community.id} />'s {multi}
        </span>
    }

    return <span>
        {items.length} members {multi}
    </span>
}


function PhoneNumberChange({items=[]}) {
    const community = useCommunity();

    if(items.length===1) {
        const pn_data = getPhoneNumberData(items[0].data.phone);
        return <span>
            <MemberTag id={items[0].scope_id} cid={community.id} />'s new phone number is {pn_data.display}
        </span>
    }

    if(items.length===2) {

            return <span>
            <MemberTag id={items[0].scope_id} cid={community.id} /> and <MemberTag id={items[1].scope_id} cid={community.id} />'s phone numbers were updated
        </span>
    }

    return <span>
        {items.length} members updated their phone numbers
    </span>
}

function EmailChange({items=[]}) {
    const community = useCommunity();

    if(items.length===1) {
        const pn_data = getPhoneNumberData(items[0].data.phone);
        return <span>
            <MemberTag id={items[0].scope_id} cid={community.id} />'s new email is {pn_data.display}
        </span>
    }

    if(items.length===2) {

        return <span>
            <MemberTag id={items[0].scope_id} cid={community.id} /> and <MemberTag id={items[1].scope_id} cid={community.id} />'s emails were updated
        </span>
    }

    return <span>
        {items.length} members updated their emails
    </span>
}

function NewMemberAdded({items=[]}) {
    const community = useCommunity();

    if(items.length===1) {
        return <span>
            <MemberTag id={items[0].scope_id} cid={community.id} /> joined the community
        </span>
    }

    if(items.length===2) {
        return <span>
            <MemberTag id={items[0].scope_id} cid={community.id} /> and <MemberTag id={items[1].scope_id} cid={community.id} /> joined the community
        </span>
    }

    return <span>
        {items.length} members joined the community
    </span>
}

function CreatedPreview({items=[],_type="group"}) {
    const navigate = useNavigate();
    const community = useCommunity();
    if(items.length===1) {
        return <span>
            <MemberTag id={items[0].scope_id} cid={community.id} /> created the {_type}&nbsp;
            <span onClick={()=>{
                if(_type==="group") {
                    navigate(`/c/${community.domain}/space/${items[0].data.id}`)
                } else if(_type==="position") {
                    navigate(`/c/${community.domain}/position/${items[0].data.id}`)
                }
            }} className="underline cursor-pointer">{items[0].data.name}</span>
        </span>
    }
    if(!items[0]?.data?.type) {
        return <span>
            ...
        </span>
    }
    return <span>
        {items.length} new {items[0].data.type}s were created
    </span>
}

function PositionChange({items=[],type="position-changed-1"}) {
    const navigate = useNavigate();
    const community = useCommunity();
    if(items.length===1) {
        if(type==="position-change-1") {
            return <span>
                <MemberTag id={items[0].scope_id} cid={community.id} /> is assigned to <span onClick={()=>{
                    navigate(`/c/${community.domain}/positions/${items[0].data.id}`)
            }} className="underline cursor-pointer">{items[0].data.name}</span>
            </span>
        } else if(type==="position-change-2") {
            return <span>
                <MemberTag id={items[0].scope_id} cid={community.id} /> is no longer assigned to <span onClick={()=>{
                navigate(`/c/${community.domain}/positions/${items[0].data.id}`)
            }} className="underline cursor-pointer">{items[0].data.name}</span>
            </span>
        } else if(type==="position-change-3") {
            return <span>
                <MemberTag id={items[0].scope_id} cid={community.id} /> replaced {items[0].data.old_assignee_name} as <span onClick={()=>{
                navigate(`/c/${community.domain}/positions/${items[0].data.id}`)
            }} className="underline cursor-pointer">{items[0].data.name}</span>
            </span>
        }
        return <span>
            ...
        </span>
    }
    return <span>
        {items.length} positions were updated
    </span>
}

function renderComponent(upd) {
    switch (upd.type) {
        case 'bio-changed':
            return <GeneralChange {...upd} />
        case 'occupation-changed':
            return <GeneralChange singular="occupation changed" multi="updated their occupation" {...upd} />
        case 'location-changed':
            return <GeneralChange singular="location changed" multi="updated their location" {...upd} />
        case 'phone-changed':
            return <PhoneNumberChange {...upd} />
        case 'email-changed':
            return <EmailChange {...upd} />
        case 'new-member-added':
            return <NewMemberAdded {...upd} />
        case 'changed-member-type':
            return <NamesChange {...upd} />
        case 'group-created':
            return <CreatedPreview _type="group" {...upd} />
        case 'position-created':
            return <CreatedPreview _type="position" {...upd} />
        case 'position-change-1':
            return <PositionChange {...upd} />
        case 'position-change-2':
            return <PositionChange {...upd} />
        case 'position-change-3':
            return <PositionChange {...upd} />
        default:
            return <span>
                missing: {upd.type}
            </span>
    }
}

function getPrefix(index,len) {
    if(index>0&&(index+1)===len) {
        return " Also,"
    }
    return "";
}

function getOpeningGreeting(community,updates) {
    let suffix = "";
    if(updates.length>0) {
        suffix = ", recently";
    } else {
        suffix = ". ";
    }
    return `It's nice to see you again${suffix}`;
}

function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

function Suggestion({community}) {
    const num = React.useMemo(
        () => randomIntFromInterval(0,3),
        []
    );
    switch (num) {
        case 2:
            return <span>Did you know you can see all current <Link className="underline" to={`/c/${community.domain}/leaders`}>leaders</Link>?</span>
        case 1:
            return <span>Have you seen the member <Link className="underline" to={`/c/${community.domain}/members`}>directory</Link>?</span>
        default:
            return <span>Have you checked out the new <Link className="underline" to={`/c/${community.domain}/spaces?sort=new`}>Spaces</Link> lately?</span>;
    }
}

function Greeting({events,community,limit=250}) {
    const updates = processEventsForSemanticWelcome(events);

    return <div>
        <div className="text-base text-gray-600" style={{maxWidth: '90%'}}>

       <div>
            <span>{getOpeningGreeting(community,updates)}</span>{updates.length===0&&<Suggestion community={community} />}{updates.map((upd,index)=>{
                return <span key={index}>{getPrefix(index,updates.length)}&nbsp;{renderComponent(upd)}.</span>
            })}
        </div>
        </div>
        <div className="pt-3">
            <CatchupLink limit={limit} events={events} count={events.length}/>
        </div>
    </div>
}

function CatchupLink({count,events,limit}) {
    const community = useCommunity();

    function text() {
        if(count===0) {
            return `Recent updates`;
        }
        let plus = events.length === limit ? '+' : '';
        return `Catch up on ${count}${plus} change${count===1?"":"s"}`;
    }

    return <div onClick={()=>{
        logEngagementEvent("open_catch_up", {}, community.uid);
        community.openEditOverlay({
            type: 'catch-up',
            data: {
                events,
                count: events.length
            }
        })
    }} className="inline-flex text-base hover:text-gray-500 cursor-pointer  underline text-gray-700">
        {text()}
    </div>
}

export default function SemanticWelcome({limit=250}) {
    const community = useCommunity();
    const [events, setEvents] = useState([]);
    useEffect(function () {
        getEventsFromFeed(community,"New",limit)
            .then(evts => {
                setEvents(evts)
            });
    }, [])

    return <div>
        <Greeting community={community} events={events} limit={limit} />
    </div>
};