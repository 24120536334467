import React, {Component} from "react";


function getDynamicColor() {
    return "linear-gradient(to right, #FFCF40, #FFA03E)";
}

function getColor(intent,value) {
    switch (intent) {
        case "dynamic":
            return getDynamicColor(value);
        case 'success':
            return '#2d9665';
        case 'secondary':
            return '#2F80ED';
        case 'primary':
            return '#F15F31';
        case 'danger':
            return '#d84949';
        default:
            return 'gray';
    }
}

export function SimpleProgressBar({
    height = 16,
                                      value = 0,
                                      intent = 'secondary',
                                      stripes = false
                                  }) {

    const containerStyles = {
        height: height,
        width: 'auto',
        backgroundColor: "#e0e0de",
        borderRadius: height*2
    }

    const fillerStyles = {
        width: `${value*100}%`,
        background: getColor(intent,value)
    }

    return <div style={containerStyles} className={`${stripes?"simple-progress-bar-animate":""} simple-progress-bar`}>
        <span style={fillerStyles}><span></span></span>
    </div>
}

export default class ProgressBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            progress: 0.15
        };
    }

    componentDidMount() {
        this.startTimer()
    }

    startTimer(){
        const {seconds=10} = this.props;
        const ms = seconds * 1000;
        setTimeout(function() { //Start the timer
            this.setState({
                progress: 0.25
            })
        }.bind(this), (ms/8));

        setTimeout(function() { //Start the timer
            this.setState({
                progress: 0.45
            })
        }.bind(this), (ms/5));

        setTimeout(function() { //Start the timer
            this.setState({
                progress: 0.7
            })
        }.bind(this), (ms/2));

        setTimeout(function() { //Start the timer
            this.setState({
                progress: 0.95
            })
        }.bind(this), (ms/1.2));
    }

    render() {
        const {done,real_progress} = this.props;
        const {progress} = this.state;
        let val = real_progress ? real_progress : done?1:progress;
        return <SimpleProgressBar stripes value={val} intent={done ? 'success' : 'secondary'}/>
    }
}