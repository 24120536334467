import React, {useEffect} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {getMemberProfileById, useCommunity} from "../../../config/community";
import {getMemberProfile} from "./index";
import {FullScreenLoader} from "../onboarding";

export function RedirectPage() {
    let [searchParams, setSearchParams] = useSearchParams();
    const community = useCommunity();
    const navigate = useNavigate();

    useEffect(function () {
        console.log("REDIRECT PAGE", searchParams.has('edit'))
        let qstr = '';
        if (searchParams.has('edit')) {
            qstr = 'edit=true';
        }
        navigate(`/c/${community.domain}/member/${community.member.handle}?${qstr}`)
    }, [community])

    return <FullScreenLoader />
}

export function MemberIDRedirect() {
    const {id} = useParams();
    const community = useCommunity();
    const navigate = useNavigate();

    useEffect(function () {
        getMemberProfileById(community.id,id)
            .then(pr=>{
                navigate(`/c/${community.domain}/member/${pr.handle}`, {
                    replace: true
                })
            })
    }, [id,community])

    return <FullScreenLoader />
}