import React from 'react';
import {UniversalIcon} from "../../../../m3/_legacy_components/entity-card";
import {DEFAULT_USER_PHOTO, useCommunity} from "../../../../config/community";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import SimpleTooltip from "../../../../m3/_legacy_components/tooltip";
import ContentPopup from "../../../../m3/_legacy_components/content-popup";
import {ContentPopupMenu} from "../../../../m3/_legacy_components/content-popup/components";
import {BasicSelect} from "../../../../m3/_legacy_components/form/basic-select";
import {TextAction} from "../../../auth/sign-in";
import {buildImageUrl} from "../../../../../common/utilities/images";

function ToEntity({label="",image,letter="",color,email,emoji,onRemove,count=0}) {
    const icon = {
        dims: "h-6 w-6",
        minimal: true,
        image: buildImageUrl(image, '_medium', DEFAULT_USER_PHOTO),
        color: color||"",
        emoji: emoji||"",
        string: letter||label.charAt(0).toUpperCase(),
        count: count||0,
        mini: true,
        type: image ? "small-image" : emoji ? "emoji" : "text"
    }
    /*
      {count&&count!==1&&<div className="ml-1.5">
                <div className="text-xs text-gray-600">{count}</div>
            </div>}
              {onRemove&&<div onClick={onRemove} className="w-4 h-4 text-gray-500">
                <XMarkIcon />
            </div>}
     */
    const c = <div className="rounded-lg select-none -ml-1 -my-0.5 p-px hover:bg-gray-100 active:bg-gray-200 cursor-pointer transition-colors">
        <div className="h-7 items-center gap-1 flex pr-1.5">
            <div className="w-7 h-7 p-0.5 flex items-center justify-center rounded-l-md">
                <UniversalIcon {...icon} />
            </div>
            <div className="inline-flex h-7 items-center">
                <div className="">
                    <div className="text-sm font-semibold text-gray-700">{label}</div>
                </div>
            </div>
            {count&&count>1&&<div className="text-gray-500 w-4 h-4">
                <ChevronDownIcon />
            </div>}
        </div>
    </div>;

    const content = <ContentPopup>
        <ContentPopupMenu items={[
            {
                onClick: () => {},
                type: "heading",
                label: label,
                desc: `${count} members`,
            }
        ]}/>
    </ContentPopup>;

    return <SimpleTooltip usePortal simple trigger="click" interactive hideOnClick={false} placement="bottom-start"
                          text={content}>
        {c}
    </SimpleTooltip>
}

/*
FORMAT
 {
                type: 'group',
                value: id,
                id: id,
                label: name,
                emoji: group?.emoji||"",
                letter: name.charAt(0).toUpperCase(),
                email: group?.['power_ups']?.['unaty__mailing_lists']?.config?.email_address||"",
                count: Object.keys(group?.user_uids||{}).length
            }
 */

export function SpaceSelectorPosts({value,handleChange=()=>{}}) {
    const community = useCommunity();

    const all = community.group_ids.map(id=>{
        const it = community.local_entities[`group__${id}`];
      return {
          ...it||{},
          id
      };
    });

    function getLabel(id) {
        const it = community.local_entities[`group__${id}`];
        return it?.name||"";
    }

    // todo: filter out groups that member can't post to
    const can_post_to = all.filter(it=>!!it);

    return <div className="max-w-xs">
        <BasicSelect placeholder="Select a Space" searchable={true}
                     value={!value ? null : {value: value, label: getLabel(value)}}
                     onChange={(v) => {
                         const data = v ? community.local_entities[`group__${v.value}`] : null;
                         handleChange(v.value,data)
                     }} options={all}/>
    </div>
}

export function WriteTo({features,type='group',limit = 12, demo,to=[],onChange}) {
    const [show_all,setShowAll] = React.useState(false);
    //<div className="font-medium text-gray-500 text-sm pt-1">{type==='group'?"In":"To"}:</div>
    const is_empty = to.length===0;

    return <div className="">
        <div className="flex gap-2 flex-wrap max-h-[9rem]">
            {to.map((it,item_index)=>{

                if(item_index>=limit&&!show_all) return null;

                return <div key={`${it.type}-${it.id}`}>
                    <ToEntity {...it} onRemove={!features.can_add_to?null:()=>onChange([])} />
                </div>
            })}
            {to.length>limit&&!show_all&&<div className="flex items-center gap-1">
            <TextAction onClick={()=>setShowAll(true)} text={`Show ${to.length-limit} more`} />
            </div>}
            {is_empty&&<div className="flex-grow">
                <SpaceSelectorPosts handleChange={(v,d)=>{
                    if(!v) return;
                    onChange([d])
                }} />
            </div>}
            {demo&&<div>
            <ToEntity label="New York Hub" emoji={"🗽"} type="group" />
            </div>}
        </div>
    </div>
}