import React, {useState} from "react"
import './styles.css';
import {
    CalendarIcon, ClockIcon,
    EllipsisVerticalIcon,
    FolderOpenIcon,
    GlobeAltIcon,
    InformationCircleIcon, BoltIcon, LinkIcon, EnvelopeIcon,
    PaperAirplaneIcon,
    PlusCircleIcon,
    UserCircleIcon,
    UsersIcon
} from "@heroicons/react/24/outline";
import {SectionHeader} from "../entity-grid";

/*
How are building the links that appear here

6 base links, then dropdown that appears for more if applicable

saved recent actions: Message [Single Group], Create New Member, View NY Hub Members

Quick actions sources:
- base actions for every member
- if you're a group moderator, then more
- if you can create members, more
- if you're an admin

action catalogue:
- view profile
- create member
- discover new groups
-
 */
function buildLinks(context, flags, recent_actions) {
    let a = [];

    if (context === 'home') {

        a.push({
            label: "View New York Hub Members",
            icon: <UsersIcon/>
        });

        a.push({
            label: "Create new Members",
            icon: <PlusCircleIcon />
        });

        a.push({
            label: "Message Ambassadors",
            icon: <PaperAirplaneIcon/>
        });

        a.push({
            label: "Edit Profile",
            icon: <UserCircleIcon />
        });

        a.push({
            label: "Discover new Groups",
            icon: <GlobeAltIcon />
        });

        a.push({
            label: "View Community Leaders",
            icon: <BoltIcon />
        });

        a.push({
            label: "View Madrid Hub Drive",
            icon: <FolderOpenIcon />
        });
    } else if(context==='group') {
        a.push({
            label: "Create new Members",
            icon: <PlusCircleIcon />
        });
        a.push({
            label: "View Group Directory",
            icon: <UsersIcon />
        });
        a.push({
            label: "Add Group Mailing List",
            icon: <EnvelopeIcon />
        });
        a.push({
            label: "Copy link to join Group",
            icon: <LinkIcon />
        });

    }

    return a;
}

function Action({label,letter,emoji,minimal,onClick,icon}) {
    if(minimal) {
        return <div className="px-2 group cursor-pointer" onClick={()=>{if(onClick) {onClick();}}}>
            <div className="mx-auto h-10 w-10 flex items-center justify-center group-hover:bg-gray-200  cursor-pointer rounded-full bg-gray-100">
                <div className="h-6 w-6 text-gray-700">
                    {icon?icon:<InformationCircleIcon />}
                </div>
            </div>
            {label&&<div className="font-medium line-clamp-2 leading-4 pt-1 text-center text-gray-700 text-[0.80rem]">
                {label}
            </div>}
        </div>
    }

    return <div onClick={()=>{if(onClick) {onClick();}}}
        className="rounded-xl flex items-center gap-2.5 cursor-pointer group relative px-2.5 py-2 bg-[#f8f8fa]">
        <div>
            <div className={`
            h-6 w-6 mt-px flex-none
            ${emoji?"bg-white bg-opacity-50 rounded-md":""}
            ${letter?"bg-gray-700 bg-opacity-50 rounded-md":""}
            ${letter||emoji?'text-white flex items-center justify-center':'text-gray-800'}
            `}>
                {icon?icon:null}
                {emoji&&<span className="text-xl">{emoji}</span>}
                {letter&&<span className="text-base font-semibold">{letter}</span>}
            </div>
        </div>
        <div className="text-sm text-gray-800 font-semibold leading-tight"
             style={{maxWidth: '80%'}}>{label}</div>
    </div>
}

// repeat(3, minmax(150px, 1fr))
function Wrapper({children,min="150px",fill}) {
    return <div className={fill?'flex flex-col gap-3':`grid gap-3`} style={{gridTemplateColumns: `repeat(auto-fill, minmax(${min}, 1fr))`}}>
        {children}
    </div>
}

export default function QuickActions({context = 'home',fill,minimal,title, items, recent_actions = [], show_title = true}) {
const [show_more,setShowMore] = useState(false);
    let flags = {
        is_admin: true,
        is_moderator: true,
        is_leader: true
    };

    let its = items ? items : buildLinks(context, flags, recent_actions);

    const can_show_more = its.length>6;

    return <div>
        {show_title && <div className="pb-2"><SectionHeader size='text-lg' title={title}/></div>}
        <Wrapper fill={fill} min={minimal?"100px":"150px"}>
            {its.map((link,index) => {
                if(can_show_more&&!show_more&&index>4) {
                    return null;
                }
                return <Action minimal={minimal} key={link.label} {...link} />
            })}
            {can_show_more&&<Action minimal={minimal} onClick={()=>setShowMore(!show_more)} key='more' icon={<EllipsisVerticalIcon />} label={show_more?"Show less":"More"} />}
        </Wrapper>
    </div>
};