import React, {useEffect, useState} from "react";
import PinboardBadges from "./blocks/badges";
import PinboardLinks from "./blocks/links";
import {SectionHeader} from "../entity-grid";
import {TextAction} from "../../../routes/auth/sign-in";
import SimpleTooltip from "../tooltip";
import Button from "../button";
import {WidgetsPopupContent} from "../../../routes/community/write/widgets/popup";
import Field, {FieldLabel} from "../form/field";
import {AddLinkItem, EditLinkItem} from "../navbuilder";
import {authFetch} from "../../../../api/network";
import {InlineLoader} from "../admin-activity";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {saveItemToLocalStorage} from "../../utilities/localstorage";
import PinboardText from "./blocks/text";
import PinboardModerators from "./blocks/moderators";
import PinboardLeaderGroup from "./blocks/leader-group";
import PinboardLeader from "./blocks/leader";
import PinboardMemberList from "./blocks/member-list";

const DIcon = () => {
    return <svg fill="currentColor" x="0" y="0" viewBox="0, 0, 24, 24">
        <g id="Layer_15">
            <path
                d="M8.5,14 C7.395,14 6.5,13.105 6.5,12 C6.5,10.895 7.395,10 8.5,10 C9.605,10 10.5,10.895 10.5,12 C10.5,13.105 9.605,14 8.5,14 z"></path>
            <path
                d="M15.5,14 C14.395,14 13.5,13.105 13.5,12 C13.5,10.895 14.395,10 15.5,10 C16.605,10 17.5,10.895 17.5,12 C17.5,13.105 16.605,14 15.5,14 z"></path>
            <path
                d="M8.5,20 C7.395,20 6.5,19.105 6.5,18 C6.5,16.895 7.395,16 8.5,16 C9.605,16 10.5,16.895 10.5,18 C10.5,19.105 9.605,20 8.5,20 z"></path>
            <path
                d="M15.5,20 C14.395,20 13.5,19.105 13.5,18 C13.5,16.895 14.395,16 15.5,16 C16.605,16 17.5,16.895 17.5,18 C17.5,19.105 16.605,20 15.5,20 z"></path>
            <path
                d="M8.5,8 C7.395,8 6.5,7.105 6.5,6 C6.5,4.895 7.395,4 8.5,4 C9.605,4 10.5,4.895 10.5,6 C10.5,7.105 9.605,8 8.5,8 z"></path>
            <path
                d="M15.5,8 C14.395,8 13.5,7.105 13.5,6 C13.5,4.895 14.395,4 15.5,4 C16.605,4 17.5,4.895 17.5,6 C17.5,7.105 16.605,8 15.5,8 z"></path>
        </g>
    </svg>
}

function DragIcon() {
    return <div
        className="h-6 w-5 flex items-center text-gray-400 transition-colors hover:text-gray-600 justify-center hover:bg-gray-100 cursor-grabbing rounded-md">
        <div className="h-5 w-5 ">
            <DIcon/>
        </div>
    </div>
}

function SectionToggle({onToggle, manage}) {
    if (manage) {
        return <div onClick={onToggle}>
            <TextAction>Done</TextAction>
        </div>
    }
    return <div onClick={onToggle}>
        <TextAction>Edit</TextAction>
    </div>
}

function Section({data, label, id, community_id, onUpdateSection, onDeleteSection, type = '', title}) {
    const [manage, setManage] = useState(false);
    const [mode, setMode] = useState('');
    const [l, setL] = useState(label || title);
    const [dt, setDt] = useState(data);
    const [temp_data, setTempData] = useState(null);
    const [changed, setChanged] = useState(false);

    useEffect(function () {
        if (changed && dt) {
            onUpdateSection({
                title: title,
                label: l,
                data: dt,
                id,
                type
            });
            setChanged(false);
        }
    }, [dt, id, type, title, l, changed])

    function handleAdd(obj) {
        const {url, name, type, image, emoji, color} = obj;
        const id = Math.random().toString(36).substr(2, 9);
        /*
         icon: {type:"image",image:"https://cdn.sstatic.net/Sites/stackoverflow/Img/favicon.ico?v=ec617d715196"},
        text: "S/O",
        external: true
         */
        const item = {
            id,
            image,
            emoji,
            color,
            name,
            url,
            external: type === 'external-link',
            type
        };

        setDt([...dt, item]);
        setMode('');
        setChanged(true);
    }

    function handleDelete(id) {
        const new_data = dt.filter(item => item.id !== id);
        setDt([...new_data]);
        setMode('');
        setTempData(null);
        setChanged(true);
    }

    return <div className="relative">
        <div className="border border-gray-200 rounded-md">
            <div className="bg-gray-200 px-3 leading-8 flex items-center">
                <div className="flex-grow uppercase text-xs font-semibold tracking-wide text-gray-600">{title}</div>
                <div className="flex space-x-3 items-center">
                    <SectionToggle manage={manage} onToggle={() => !setManage(!manage)}/>
                </div>
            </div>
            {mode === "add" && <div className="" style={{minHeight: '3.2rem'}}>
                <div className="px-3 pt-3">
                    <TextAction onClick={() => setMode('')} text="Back"/>
                </div>
                <AddLinkItem community_id={community_id} handleAdd={handleAdd}/>
            </div>}
            {mode === "edit" && temp_data && <div className="px-3 pt-3">
                <div className="px-3">
                    <TextAction onClick={() => {
                        setMode('')
                        setTempData(null);
                    }} text="Back"/>
                </div>
                <EditLinkItem data={temp_data} updateParent={(ud) => {
                    setTempData({...temp_data, ...ud})
                }}/>
                <div className="px-3 pb-3 flex space-x-2">
                    <Button text="Delete" onClick={() => handleDelete(temp_data.id)}/>
                    <Button text="Save Changes" onClick={() => {
                        const new_data = dt.map(item => {
                            if (item.id === temp_data.id) {
                                return temp_data;
                            }
                            return item;
                        });
                        setDt([...new_data]);
                        setMode('');
                        setTempData(null);
                        setChanged(true)
                    }}/>
                </div>
            </div>}
            {!manage && !mode && <div className="pl-3 pt-3" style={{minHeight: '3.2rem'}}>
                {type === 'badges' && <PinboardBadges fill onAdd={() => {
                }} can_add badges={dt} manage onClick={(id) => {
                }}/>}

                {type === 'text' && <PinboardText handleChange={new_data => {
                    setDt(new_data);
                    setChanged(true);
                }} can_edit title={l} data={dt}/>}
                {type === 'member-list' && <PinboardMemberList title={l} data={dt}/>}
                {type === 'leader' && <PinboardLeader title={l} data={dt}/>}
                {type === 'leader-group' && <PinboardLeaderGroup title={l} data={dt}/>}
                {type === 'moderators' && <PinboardModerators title={l} data={dt}/>}

                {type === 'links' && <PinboardLinks onSelect={(id) => {
                    setTempData(dt.find(i => i.id === id));
                    setMode('edit');
                }} onAdd={() => {
                    setMode('add');
                }} can_add title={l} links={dt}/>}
                {type === 'apps' && <PinboardLinks onAdd={() => {
                    setMode('add');
                }} can_add title={l} links={dt}/>}
                {type === 'moderators' && <PinboardLinks onAdd={() => {
                }} can_add title={l} links={dt}/>}
            </div>}
            {manage && <div className="p-3 space-y-3" style={{minHeight: '3.2rem'}}>
                <Field label="Section Name" value={l} type="text" onBlur={() => {
                    setChanged(true);
                }} onChange={(a, b) => {
                    setL(b);
                }}/>
                <div>
                    <Button text="Delete Block" onClick={() => {
                        // window confirm
                        window.confirm("Are you sure you want to delete this block?") && onDeleteSection();
                    }}/>
                </div>
            </div>}
        </div>
        <div className="absolute hidden -top-px -left-6">
            <DragIcon/>
        </div>
    </div>
}

const widget_section_data = {
    'links': {
        title: "Links",
        type: "links",
        label: "Links",
        data: [],
    },
    'moderators': {
        title: "Moderators",
        type: "moderators",
        label: "Moderators",
        data: []
    },
    'text': {
        title: "Text",
        type: "text",
        label: "Text",
        data: []
    },
    'members-list': {
        title: "Members",
        type: "members-list",
        label: "Members",
        data: []
    },
    'leader': {
        title: "Leader",
        type: "leader",
        label: "Leader",
        data: []
    },
    'leaders-group': {
        title: "Leaders Group",
        type: "leaders-group",
        label: "Leaders Group",
        data: []
    },
    'badges': {
        title: "Badges",
        type: "badges",
        label: "Badges",
        data: []
    },
    'apps': {
        title: "Apps",
        type: "apps",
        label: "Apps",
        data: []
    }
};

export async function updateGroupPinboard(community_uid, entity_type, entity_id, member_id, data) {

    const payload = {
        community_uid,
        entity_type,
        entity_id,
        member_id,
        data: JSON.stringify(data)
    };

    return new Promise((resolve, reject) => {
        const res = () => {
            resolve();
        };

        authFetch("/entities/update-pinboard", res, res, "POST", {payload})
    });
}

export async function getPinboardData(community_uid, entity_type, entity_id) {
    const ref = doc(db, 'community_entities', community_uid, 'groups', entity_id, 'meta', 'pinboard');

    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? [...JSON.parse(doc.data().data)] : [];
        });
}

// <EditBadge />
export default function PinboardEditor({community_id = "", entity_type, entity_id, member_id}) {
    const [loading, setLoading] = useState(false);
    const [sections, setSections] = useState(null);

    useEffect(function () {
        if (community_id && entity_type === 'group' && entity_id) {
            saveItemToLocalStorage(`getstarted-grouppinboard-${community_id}-${entity_id}`, true)
        }
    }, [community_id, entity_type, entity_id]);

    useEffect(function () {
        if (!sections && !loading) {
            setLoading(true);
            getPinboardData(community_id, entity_type, entity_id)
                .then((data) => {
                    setSections(data || []);
                    setLoading(false);
                })
        }
    }, [sections, community_id, loading, entity_type, entity_id])

    useEffect(function () {
        // save on leave page
        return function () {
            // save
            if (!sections) return;
            updateGroupPinboard(community_id, entity_type, entity_id, member_id, sections)
                .then(() => {
                    console.log("saved");
                })
        }
    }, [sections, community_id, entity_type, entity_id, member_id])

    function addSection(type = "links") {
        let dt = {...widget_section_data[type]};
        const id = Math.random().toString(36).substr(2, 9);
        dt.id = id;

        setSections([...sections, dt]);
    }

    function handleDelete(index) {
        const new_sections = sections.filter((item, i) => i !== index);
        setSections(new_sections);
    }

    function handleUpdate(index, nsdt) {
        const new_sections = sections.map((item, i) => {
            if (i === index) {
                return nsdt;
            }
            return item;
        });
        setSections(new_sections);
    }

    if (!sections) {
        return <div className="p-4 flex items-center justify-center">
            <InlineLoader/>
        </div>
    }

    let can_add_widgets = [
        {
            label: "Links",
            desc: "Add a list of links",
            onClick: () => {
                addSection("links");
            }
        }
    ];

    const badges_count = sections.filter(item => item.type === 'badges').length;
    const images_count = sections.filter(item => item.type === 'images').length;

    can_add_widgets.push({
        label: "Text",
        desc: "Add a rich text section",
        onClick: () => {
            addSection("text");
        }
    });
    /*
        can_add_widgets.push({
            label: "Leader",
            desc: "Add a single leader position",
            onClick: () => {
                addSection("leader");
            }
        });

        can_add_widgets.push({
            label: "Leader Group",
            desc: "Add a group of leaders",
            onClick: () => {
                addSection("leader-group");
            }
        });

        can_add_widgets.push({
            label: "Moderators",
            desc: "Add list of moderators",
            onClick: () => {
                addSection("moderators");
            }
        });

        can_add_widgets.push({
            label: "Dynamic Member List",
            desc: "Add dynamic member list",
            onClick: () => {
                addSection("member-list");
            }
        });

        if (badges_count === 0) {
            can_add_widgets.push({
                label: "Badges",
                desc: "Add image badges",
                onClick: () => {
                    addSection("badges");
                }
            });
        }

        if (images_count === 0) {
            can_add_widgets.push({
                label: "Images",
                desc: "Add recent images",
                onClick: () => {
                    addSection("images");
                }
            });
        }

     */

    if (loading) {
        return <div className="p-4 flex items-center justify-center">
            <InlineLoader/>
        </div>
    }

    return <div className="">
        <SectionHeader title="Edit Pinboard" actions={<>
            <SimpleTooltip usePortal simple trigger="click" interactive placement="bottom-end"
                           text={<WidgetsPopupContent widgets={can_add_widgets}/>}>
                <TextAction text="Add Block"/>
            </SimpleTooltip>
        </>}/>
        <div className="pt-1 space-y-2">
            {sections.map((sec, index) => {
                return <Section onUpdateSection={(nsdt) => handleUpdate(index, nsdt)}
                                onDeleteSection={() => handleDelete(index)} community_id={community_id}
                                onEditBadge={() => {
                                }} key={sec.id} {...sec} />
            })}
        </div>
    </div>
};