import React from "react"
import {DocumentIcon} from "@heroicons/react/24/outline";
import {formatFileSize, getFileTypeIcon} from "../../../../m3/icons/file-helpers";

function AttachmentItem({size,name,type,id,onClick,folder}) {
    const icon = getFileTypeIcon(type);
    return <div onClick={onClick} className="flex cursor-pointer pl-1 pr-2 py-1 rounded-md border bg-gray-100 border-gray-100 items-center space-x-1.5">
        <div className="w-5 flex-none">
            <div className="w-5 h-5">
                {icon}
            </div>
        </div>
        <div className="flex items-baseline space-x-2 text-sm">
            <div className="text-gray-800 font-semibold truncate" style={{maxWidth: "12rem"}}>
                {name}
            </div>
            <div className="text-gray-500">{formatFileSize(size, 0)}</div>
        </div>
    </div>
}

export default function MessageAttachments({attachments=[],onOpenAttachment=()=>{}}) {

    return <div className="flex flex-wrap gap-1 overflow-x-auto">
        {attachments.map((attachment, index) => {
            return <AttachmentItem onClick={()=>onOpenAttachment(attachment,index)} key={`${attachment.id||index}`} {...attachment} />
        })}
    </div>
};