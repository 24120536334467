import React, {useEffect, useRef, useState} from "react";
import {isSupportedCountry, getCountryCallingCode} from "libphonenumber-js/min";
import styled from "styled-components";
import {RulesInlineSelect} from "../rules-inline-select";
import {getPhoneNumberData} from "../../utilities/phone";
import {data_countries_arr, data_countries_map} from "../../../../common/data/data/countries";

const phone_placeholders = {
    'de': '170 523 9230',
    'gb': '20 7946 0389',
    'fr': '93 205 0237',
    'us': '(216) 867-5309',
    'nl': '71 884 6843',
    '*': '000 0000'
};

export function getFlagByCountryCode(input) {
    return input ? input.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397)) : "";
}

function buildCountries() {
    // prioritize a few countries
    const priorities = ['de', 'fr', 'gb', 'us', 'nl'];
    let arr = [...priorities.map(id => {
        const label = data_countries_map[id];
        return {
            id,
            label,
            placeholder: phone_placeholders[id] ? phone_placeholders[id] : phone_placeholders['*'],
            emoji: getFlagByCountryCode(id),
            meta: `+${getCountryCallingCode(id.toUpperCase())}`
        }
    }), {
        id: 'divider-main',
        divider: true
    }];

    const world = data_countries_arr.filter(({value,label}) => {
        return !priorities.includes(value) && isSupportedCountry(value.toUpperCase());
    }).sort((a, b) => {
        return (a.label < b.label) ? -1 : 1;
    }).map((entry) => {
        return {
            label: entry.label,
            id: entry.value,
            value: entry.value,
            placeholder: phone_placeholders[entry.value] ? phone_placeholders[entry.value] : phone_placeholders['*'],
            emoji: getFlagByCountryCode(entry.value),
            meta: `+${getCountryCallingCode(entry.value.toUpperCase())}`
        };
    });

    return arr.concat(world);
}

const all_countries = buildCountries();

/*
parent only knows full number
if it contains a plus, it has a country, if not we try to get it

user should be able to select country
user should be able to paste in value and have country autodetected
 */

const Wrapper = styled.div`

    .inline-select {
        min-width: 32px;
        margin: 0;
        margin-left: 1px;
        height: 40px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

function getInitialData(val) {

    const pn = getPhoneNumberData(val);
    if (pn) {
        return {
            value: pn.edit,
            country: pn.country.toLowerCase()
        }
    }
    return {
        value: val,
        country: pn.country.toLowerCase()
    }
}

function getDisplayValue(value, val, focused, meta) {
    if (val) {
        const phone_data = getPhoneNumberData(val, meta && meta.country ? meta.country : "");

        return {input_value: phone_data.edit};
    }
    return {input_value: val ? val : ''};
}

function Input({
                   value, styles = {}, placeholder, onPaste, onBlur, meta, onFocus = () => {
    }, onEnter, onChange, onEmptyBackspace, disabled
               }) {
    const [val, setVal] = useState(value || "");
    const [focused, setFocused] = useState(false);
    const isFirstRun = useRef(true);

    useEffect(function () {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        setVal(value || "");
    }, [value]);

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            onEnter(e);
        } else if (e.keyCode === 8 && !value) {
            onEmptyBackspace();
        }
    }

    function handleFocus() {
        onFocus();
        setFocused(true);
    }

    function handleBlur(e) {
        if (onBlur) {
            onBlur();
        } else {

        }
        setFocused(false);
    }

    const oc = onChange ? (e) => {
        const v = typeof e === 'string' ? e : e.target.value;
        setVal(v);
        onChange(v)
    } : (e) => {
        const v = typeof e === 'string' ? e : e.target.value;
        setVal(v);
    };

    const display_value = getDisplayValue(value, val, focused, meta);

    return <input style={{...styles}} placeholder={placeholder}
                  className={`input-focus border rounded-md leading-10 text-base pl-10 pr-3 w-full ${disabled?"disabled":""}`}
                  value={display_value.input_value} disabled={disabled}
                  onBlur={handleBlur} onFocus={handleFocus} onChange={oc} onPaste={onPaste} onKeyDown={handleKeyDown}/>
}

export function RichPhoneInput({
                                   value = "", onChange = () => {
    }, onBlur = () => {}, autoFocus = false,
                                   disabled,
                                   onEnter = () => {
                                   }
                               }) {

    const [val, setVal] = useState(getInitialData(value).value);
    const [co, setCo] = useState(getInitialData(value).country);
    const inputRef = React.useRef();
    const isFirstRun = React.useRef(true);

    const inputFocus = () => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    };

    useEffect(function () {
        // get country from phone
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        if (!val) {
            onChange("");
            return;
        }

        const full = getPhoneNumberData(val, co);

        onChange(full.number)
    }, [val, co]);

    function handlePaste(e) {
        e.stopPropagation();
        e.preventDefault();
        const ve = e.clipboardData.getData('Text');

        let nv = `${ve}`;
        nv = nv.replace(/\)/g, '').replace(/\(/g, '').replace(/ /g, '');

        if (nv.indexOf('+') === -1 && co) {
            nv = `+${getCountryCallingCode(co.toUpperCase())}${nv}`;
        }

        const phone_data = getPhoneNumberData(nv);

        if (phone_data.valid && co !== phone_data.country.toLowerCase()) {
            setCo(phone_data.country.toLowerCase());
        }

        if (phone_data.valid) {
            setVal(phone_data.number);
        } else {
            setVal(nv);
        }
    }

    function handleCountryChange(nc) {
        const phone_data = getPhoneNumberData(val);
        if (phone_data.valid && nc !== phone_data.country.toLowerCase()) {
            // valid phone, switch number
            const new_country = all_countries.filter(a => a.id === nc)[0];

            const new_number = val.replace(`${phone_data.calling_code}`, `${new_country.meta}`);

            const phone_data_2 = getPhoneNumberData(new_number);

            if (phone_data_2.valid) {
                setVal(phone_data_2.edit);
            } else {
                setVal(phone_data_2.edit);
            }
        }
        setCo(nc);
        if (val.length < 5) {
            inputFocus();
        }
    }

    function handleBlur() {
        const full = getPhoneNumberData(val, co);

        onBlur(full.number);
    }

    function handleChange(v) {
        if (!v) {
            setCo('')
            setVal('');
        }

        let nv = `${v}`;

        // 015172888877
        // 015127 538238
        // +49 30 901820
        // add national utils
        // or select the country, add teh rest of the international utils
        nv = nv.replace(/\)/g, '').replace(/\(/g, '').replace(/ /g, '');

        if (nv && co && nv.indexOf("+") === -1) {
            nv = `+${getCountryCallingCode(co.toUpperCase())}${nv}`
        }

        const phone_data = getPhoneNumberData(nv);

        if (nv && !co && phone_data.valid && phone_data.country) {
            setCo(phone_data.country.toLowerCase());
        }

        if (nv && phone_data.valid) {
            setVal(phone_data.number);
        } else {
            setVal(nv);
        }
    }

    function onEmptyBackspace() {
        setCo('');
    }

    const country_data = all_countries.filter(a => a.id === co)[0];

    const has_country = !!country_data;
    return <div className="relative">
        <Input placeholder={has_country ? country_data.placeholder : '+1 123 456 7890'} disabled={disabled} styles={{
            paddingLeft: has_country ? '5.75rem' : '3.6rem'
        }} meta={{country: has_country ? co : "", country_code: !has_country ? "" : country_data.meta}}
               onEnter={onEnter}
               onBlur={handleBlur} onChange={handleChange} onEmptyBackspace={onEmptyBackspace}
               onPaste={handlePaste} value={val}/>
        <Wrapper className={`absolute items-center top-0 bottom-0 flex space-x-px ${disabled?"pointer-events-none cursor-not-allowed":""}`}>
            <RulesInlineSelect emoji searchable inline selected={co} options={all_countries} placeholder={"🌍"}
                               onSelect={(no) => handleCountryChange(no)}/>
            {has_country && <div style={{fontSize: '1rem', paddingLeft: '6px'}}
                                 className="text-gray-500 w-8 text-right">
                {country_data.meta}
            </div>}
        </Wrapper>
    </div>
}