import {authFetch} from "../network";

export async function messages_deletePost(community,payload) {
    const {post_id} = payload;

    if (!post_id) {
        console.log("Missing post_id", {payload});
        return false;
    }

    return new Promise((resolve, reject) => {
        const res = (data) => {
            resolve(data);
        };

let endpoint = '/posts/delete';
        authFetch(endpoint, res, res, "POST", {
            payload: {
                community_uid: community.uid,
                member_id: community.member_id,
                user_uid: community.member.user_uid,
                ...payload,
            }
        });
    });
}

export async function messages_deleteReply(community,payload) {
    const {post_id, reply_id} = payload;

    if (!post_id) {
        console.log("Missing post_id", {payload});
        return false;
    }

    if (!reply_id) {
        console.log("Missing reply_id", {payload});
        return false;
    }

    return new Promise((resolve, reject) => {
        const res = (data) => {
            resolve(data);
        };

        let endpoint = '/posts/reply/delete';
        authFetch(endpoint, res, res, "POST", {
            payload: {
                community_uid: community.uid,
                member_id: community.member_id,
                user_uid: community.member.user_uid,
                ...payload,
            }
        });
    });
}