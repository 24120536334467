import React, {useEffect, useRef} from "react"
import './styles.css';
import {useCommunity} from "../../../config/community";
import {getItemFromLocalStorage, saveItemToLocalStorage} from "../../../m3/utilities/localstorage";
import {IconAction, TextAction} from "../../../routes/auth/sign-in";
import {ArrowRightIcon, ChevronDownIcon, ChevronLeftIcon} from "@heroicons/react/20/solid";
import Button from "../button";
import {getStartedMemberManifest} from "./member-manifest";
import {
    ArrowRightCircleIcon,
    BookOpenIcon,
    CalendarIcon,
    CheckCircleIcon, CogIcon, EnvelopeIcon, FolderIcon, LinkIcon,
    MagnifyingGlassIcon,
    UserIcon
} from "@heroicons/react/24/outline";
import {ArrowAction} from "../../../routes/community/manage-member/edit-member";
import {useNavigate} from "react-router-dom";
import {useUnaverse} from "../../../config/unaverse";
import SimpleTooltip from "../tooltip";

const icon_map = {
    'user': <UserIcon/>,
    'email': <EnvelopeIcon/>,
    'folder': <FolderIcon/>,
    'link': <LinkIcon/>,
    'cog': <CogIcon/>,
    'calendar': <CalendarIcon/>,
    'search': <MagnifyingGlassIcon/>,
    'directory': <BookOpenIcon/>
};

function StepIcon({completed, icon}) {
    if (completed) {
        return <div className="text-green-600 h-5 w-5">
            <CheckCircleIcon/>
        </div>
    }
    return <div className="text-gray-500 h-5 w-5">
        {icon_map[icon] || null}
    </div>
}

function StepAction({label, handleClick, status, type, manifest}) {
    if (type === 'schedule') {
        if (status === 'completed') {
            return null;
        } else if (status === 'scheduled') {
            return <div className="py-1">
                <div className="text-yellow-600 text-xs font-semibold flex gap-1">
                    <div>Scheduled</div>
                    <div className="w-3.5 h-3.5"><CalendarIcon/></div>
                </div>
            </div>
        }
        return <div className="py-1">
            <div onClick={handleClick}
                 className="text-blue-600 cursor-pointer hover:opacity-80 transition-opacity text-xs font-semibold flex gap-1">
                <div>Schedule</div>
                <div className="w-3.5 h-3.5"><ArrowRightIcon/></div>
            </div>
        </div>
    }

    return <ArrowAction onClick={handleClick} colors={'text-blue-600 hover:text-blue-800'}>
        <ArrowRightCircleIcon/>
    </ArrowAction>
}

export default function GettingStarted({
                                           manifest = {},
                                           onLoad,
                                           can_collapse = true,
                                           renderItem,
                                           can_skip,
                                           d1 = {},
                                           placement = 'static'
                                       }) {
    const unaverse = useUnaverse();
    const community = useCommunity();
    const navigate = useNavigate();
    const [state, setState] = React.useState('');
    const [expanded, setExpanded] = React.useState(!can_collapse ? true : getItemFromLocalStorage(`${manifest.id}-${community.id}-expanded`) || false);
    const is_loaded = useRef(false);

    useEffect(() => {
        if (manifest && community.id && !is_loaded.current && !!onLoad) {
            onLoad(manifest, getItemFromLocalStorage(`${manifest.id}-${community.id}-completed`, false));
            is_loaded.current = true;
        }
    }, [manifest,community.id]);

    function markAsCompleted() {
        saveItemToLocalStorage(`${manifest.id}-${community.id}-completed`, true);
        setState('completed');
    }

    function handleClick(step) {
        if (!step.action) return;

        if (step.action.type === 'schedule') {
            const emailTo = "hey@getunaty.com";
            const emailSub = `Schedule onboarding call for ${community.name}`;
            const emailBody = `Hi Orange team, I'd like to schedule a call to get started. My community is ${community.name} and my name is ${community.member.name}.`;
            window.open('mailto:' + emailTo + '?subject=' + emailSub + '&body=' + emailBody, '_self');
        } else if (step.action.type === 'link') {
            if (step.action.onClick) step.action.onClick(community.member, community, unaverse.profile, d1);
            navigate(`/c/${community.domain}/${step.action.to}`);
        } else if (step.action.type === 'param') {
            navigate(`?${step.action.param}`)
        } else if (step.action.type === 'external') {
            window.open(step.action.to, '_blank');
        } else if (step.action.type === 'click') {
            step?.action?.onClick(community.member, community, unaverse.profile, d1);
        }
    }

    if (!manifest) return null;

    const already_completed = getItemFromLocalStorage(`${manifest.id}-${community.id}-completed`);

    if (already_completed) return null;

    const {steps, title} = manifest;

    const relevant_steps = steps.filter(step => {
        if (!step.filter) {
            return true;
        }
        return step.filter(community.member, community, unaverse.profile);
    });

    const completed = relevant_steps.filter(step => {
        const {completion} = step;
        return completion ? completion(community.member, community, unaverse.profile, d1) : false;
    }).length;

    const not_yet_completed = completed < relevant_steps.length;

    const subtitle = `${completed} of ${relevant_steps.length} steps completed`;

    const content = <div
        className={`${placement === 'static' ? "rounded-lg" : "rounded-t-lg"} divide-y divide-gray-200 border border-gray-200 bg-white`}>
        <div onClick={() => {
            if (!can_collapse) return;
            setExpanded(!expanded);
            saveItemToLocalStorage(`${manifest.id}-${community.id}-expanded`, !expanded);
        }} className="flex hover:bg-gray-50 px-3 py-2 rounded-lg cursor-pointer transition-colors">
            <div className="flex-grow">
                <div className="font-semibold text-base text-gray-900">
                    {title}
                </div>
                <div className="text-xs text-gray-500 -mt-px">
                    {subtitle}
                </div>
            </div>
            <div className={`flex items-center gap-2`}>
                {can_skip && <div>
                    <TextAction text="Skip" onClick={() => {
                        window.confirm("Are you sure you want to skip this?") && markAsCompleted();
                    }}/>
                </div>}
                <IconAction>
                    {expanded ? <ChevronDownIcon/> : <ChevronLeftIcon/>}
                </IconAction>
            </div>
        </div>
        {expanded && <div>
            {not_yet_completed && <div className="divide-y divide-gray-200">
                {relevant_steps.map((step, index) => {
                    const {id, title, subtitle, icon, status, action, completion} = step;
                    const completed = completion ? completion(community.member, community, unaverse.profile, d1) : false;
                    const status_result = status ? status(community.member, community, unaverse.profile, d1) : null;
                    const label_style = completed ? 'text-green-700 line-through font-medium' : 'text-gray-900 font-semibold';
                    return <div className="px-3 py-2" key={id}>
                        <div className="flex gap-2">
                            <div className="flex-none">
                                <StepIcon icon={icon} completed={completed}/>
                            </div>
                            <div className="flex-grow">
                                <div className={`text-sm  ${label_style}`}>{title}</div>
                                <div className="text-xs text-gray-500">{subtitle}</div>
                            </div>
                            <div className="flex-none">
                                {!completed && action &&
                                    <StepAction status={status_result} manifest={manifest}
                                                handleClick={() => handleClick(step)} {...action} />}
                            </div>
                        </div>
                    </div>
                })}
            </div>}
            {!not_yet_completed && <div className="space-y-2 p-4">
                <div className="text-sm text-gray-600">
                    You have completed all the steps in this guide.
                </div>
                <div>
                    <Button onClick={markAsCompleted} text="Got it!" intent="primary"/>
                </div>
            </div>}
        </div>}
    </div>

    if (placement === 'sidebar') {
        return <>
            <SimpleTooltip hideOnClick={false} simple trigger="click" interactive placement="bottom-start"
                           text={<div className="w-80">
                               {content}
                           </div>}>
                {renderItem({
                    completed_steps: completed,
                    total_steps: relevant_steps.length,
                    remaining_steps: relevant_steps.length - completed,
                })}
            </SimpleTooltip>
        </>
    } else if (placement === 'static') {
        return content;
    } else {
        return <div className="fixed bottom-0 max-w-sm w-full" style={{left: '10vw', zIndex: 49}}>
            {content}
        </div>
    }
};