function list_to_tree(list) {
    let map = {}, node, roots = [], i;

    for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.folder !== "") {
            // if you have dangling branches check that map[node.parentId] exists
            list[map[node.folder]].children.push(node);
        } else {
            roots.push(node);
        }
    }
    return roots;
}

export function buildFolderTree(folder_map = {}, root_id) {
    let base = {
        id: root_id,
        name: "Drive",
        depth: 0,
        children: []
    };

    const all_keys = Object.keys(folder_map);
    // start with the root as it isn't stored

    const root_level_folders = Object.values(folder_map).filter((folder) => {
        return folder.folder === root_id;
    });


    const final = list_to_tree([
        {
            id: root_id,
            name: "Drive",
            folder: "",
            depth: 0
        },
        ...Object
            .values(folder_map)
            .map(x=>{
                return {
                    name: x.name,
                    id: x.id,
                    folder: x.folder,
                    system_path: x.system_path,
                    children_ready: x.children_ready||"not_loaded",
                    depth: x.system_path.split("/").filter(a=>!!a).length,
                }
            }).sort((a, b) => {
                // sort by system_path length
                return a.system_path.length - b.system_path.length;
            })
    ]);


    if(!final[0]){
        return null;
    }

    return final[0];
    /*
    console.log("ATTEMPT FRESH", )

    root_level_folders.forEach((folder) => {
        base.children.push({
            id: folder.id,
            name: folder.name,
            depth: 1,
            children: []
        });
    });

    let remaining_folders = all_keys.filter((key) => {
        return folder_map[key].folder !== root_id;
    });

    console.log("remaining_folders", remaining_folders)

    console.log("READY")

    return base;

     */
}