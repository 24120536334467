import React, {useState} from "react";
import {GenericModal, GenericSectionMetadata} from "../../onboarding/designer";
import {useCommunity} from "../../../../config/community";
import M3_A_Button from "../../../../m3/atoms/button";
import {ModalContentBlock} from "../../../../m3/_legacy_components/preview-overlay";
import M3_A_Card from "../../../../m3/atoms/card";
import {data__member_profile_sections} from "../../../../../common/data/profile-sections";
import {m3_icon_map} from "../../../../m3/icons/icon-map";
import M3_A_IconButton from "../../../../m3/atoms/icon-button";
import {useToasts} from "../../../../config/toasts";
import {authFetch} from "../../../../../api/network";
import {getFieldMetadata, getSectionVisibility} from "../../member/profile/utilities";
import {
    data__profile_section_visibility,
    data__profile_section_visibility_arr
} from "../../../../../common/data/profile-section-visibility";
import M3_A_FormControl from "../../../../m3/atoms/form-control";
import M3_A_Select from "../../../../m3/atoms/select";
import Accordion from "../../../../m3/_legacy_components/accordion";
import {data__profile_field_visibility_arr} from "../../../../../common/data/profile-field-visibility";
import {data__profile_field_editability_arr} from "../../../../../common/data/profile-field-editability";
import M3_A_Heading from "../../../../m3/atoms/heading";
import M3_A_Text from "../../../../m3/atoms/text";

const SectionDetails = ({
                            label,
                            id,
                            onChangeVisibility,
                            onChangeEditability,
                            can_move_up,
                            visibility,
                            design_defaults,
    custom_fields,
                            can_move_down,
                            onMoveUp,
                            onMoveDown,
                            description,
                            fields
                        }) => {
    let buttons = [];

    if (can_move_up) {
        buttons.push(<M3_A_IconButton onClick={() => onMoveUp(id, 1)} adjust_icon_size={-1} square compact
                                      icon={m3_icon_map.outlines['chevron_up']} size="sm"/>);
    }

    if (can_move_down) {
        buttons.push(<M3_A_IconButton onClick={() => onMoveDown(id, 1)} adjust_icon_size={-1} square compact
                                      icon={m3_icon_map.outlines['chevron_down']} size="sm"/>);
    }

    const visibility_meta = data__profile_section_visibility?.[visibility];

    const enriched_fields = fields.map(field_id => {
        const field_metadata = getFieldMetadata(field_id, design_defaults, custom_fields);

        if (!field_metadata) {
            return null;
        }

        return {
            ...field_metadata
        };
    }).filter(a => !!a);

    const fields_count = enriched_fields.length;

    console.log("SECTION", {
        enriched_fields
    })

    return <GenericSectionMetadata buttons={buttons} title={label}>
        <div>
            <M3_A_FormControl label="Visibility:">
                <M3_A_Select onChange={e => {
                    onChangeVisibility(id, e.target.value)
                }} value={visibility} options={data__profile_section_visibility_arr}/>
            </M3_A_FormControl>
        </div>
        <div>
            <Accordion title={`Fields (${fields_count})`}>
                <div className="grid grid-cols-2 gap-3">
                    {enriched_fields.map(field => {
                        const show_editability = field.editability !== "derived" && field.editability !== "dynamic";
                        const is_field_hidden = field.visibility === "hidden";
                        const label_color = is_field_hidden ? "text-gray-400" : "text-gray-800";
                        return <M3_A_Card muted={is_field_hidden} elevated={false} key={field.id}>
                            <M3_A_Heading color={label_color} size="h6">{field.label}</M3_A_Heading>
                            <M3_A_Text size="sm" color="text-gray-500">
                                {field.type_label}
                                {field.is_internal && ` (Internal)`}
                                {field.is_derived && ` (Derived)`}
                                {field.is_dynamic && ` (Dynamic)`}
                            </M3_A_Text>
                            <div className="grid gap-3 grid-cols-2 pt-2">
                                <M3_A_FormControl label="Who can view:">
                                    <M3_A_Select onChange={e => {
                                        onChangeVisibility(`f_${field.id}`, e.target.value)
                                    }} value={field.visibility} options={data__profile_field_visibility_arr}/>
                                </M3_A_FormControl>
                                {show_editability&&<M3_A_FormControl label="Who can edit:">
                                    <M3_A_Select disabled={field.is_internal} onChange={e => {
                                        onChangeEditability(`f_${field.id}`, e.target.value)
                                    }} value={field.editability} options={data__profile_field_editability_arr}/>
                                </M3_A_FormControl>}
                            </div>
                        </M3_A_Card>
                    })}
                </div>
            </Accordion>
        </div>
    </GenericSectionMetadata>
}

const getSectionDetails = (section, custom_sections, community_design_defaults) => {
    const details = data__member_profile_sections[section];

    if (!details) {
        // is custom section
        const data = custom_sections.filter(a => a.id === section)[0];
        return {
            label: data.name,
            description: data.description,
            fields: data.fields.map(fid=>`custom_fields.${fid}`),

            visibility: community_design_defaults?.[`${section.id}_visibility`] || data?.visibility || "all-members"
        };
    } else {
        return {
            ...details,

            visibility: getSectionVisibility(section, community_design_defaults)
        };
    }
};

async function api_saveProfileSections(community_uid, profile_sections, section_visibility_changes) {
    const payload = {
        community_uid: community_uid,

        profile_sections
    };

    let p = [];

    p.push(new Promise((resolve, reject) => {
        const res = (resp) => {
            resolve(resp);
        };
        authFetch(`/community/customize/update-sections-order`, res, res, "POST", {payload});
    }));

    if (Object.keys(section_visibility_changes).length > 0) {
        const updates = Object.entries(section_visibility_changes).map(([k, v]) => {
            return {
                field: `design_defaults.${k}`,
                value: v
            }
        });

        p.push(new Promise((resolve, reject) => {
            const res = (resp) => {
                resolve(resp);
            };
            authFetch(`/communities/update-design-defaults`, res, res, "POST", {
                payload: {
                    community_uid,
                    updates
                }
            });
        }));
    }

    return await Promise.all(p);
}

export function ProfileDesignerModal({onClose}) {
    const community = useCommunity();
    const toasts = useToasts();
    const [tab, setTab] = React.useState("Sections");
    const [sections, setSections] = useState(community?.profile_definitions?.sections);
    const [summary_fields, setSummaryFields] = useState(community?.profile_definitions?.summary_fields);
    const [saving, setSaving] = useState(false);
    const [design_defaults, setDesignDefaults] = useState(community?.profile?.design_defaults || {});
    const [changes_made, setChangesMade] = useState(false);
    const [changes, setChanges] = useState({});

    const tabs = [
        {
            label: "Sections"
        },
        /*
        {
            label: "Summary"
        },
        {
            label: "Settings"
        }

         */
    ];

    function handleSaveChanges() {
        setSaving(true);
        console.log("CHANGES", changes)
        api_saveProfileSections(community.uid, sections, changes)
            .then(() => {
                setSaving(false);
                setChangesMade(false);
                setChanges({});
                toasts.addToast({
                    text: `Changes saved`,
                    intent: "success",
                })
            })
    }

    function handleChangeEditability(id, new_editability) {
        // also update design defaults
        const change_id = `${id}_editability`;
        setDesignDefaults({
            ...design_defaults,
            [change_id]: new_editability
        });
        const new_changes = {...changes};
        new_changes[change_id] = new_editability;
        setChanges(new_changes);
        setChangesMade(true);
    }

    function handleChangeVisibility(id, new_visibility) {
        // also update design defaults
        const change_id = `${id}_visibility`;
        setDesignDefaults({
            ...design_defaults,
            [change_id]: new_visibility
        });
        const new_changes = {...changes};
        new_changes[change_id] = new_visibility;
        setChanges(new_changes);
        setChangesMade(true);
    }

    function moveSection(current_index, direction) {
        const new_sections = [...sections];
        const temp = new_sections[current_index];
        new_sections[current_index] = new_sections[current_index + direction];
        new_sections[current_index + direction] = temp;
        setSections(new_sections);
        setChangesMade(true);
    }

    let actions = [], content = null;
    content = <ModalContentBlock background>
        {sections.map((section, idx) => {
            const details = getSectionDetails(section, community.custom_fields, design_defaults);
            const can_move_up = idx > 0;
            const can_move_down = idx < sections.length - 1;
            const props = {
                can_move_up,
                can_move_down,
                onChangeVisibility: handleChangeVisibility,
                onChangeEditability: handleChangeEditability,
                onMoveUp: () => moveSection(idx, -1),
                onMoveDown: () => moveSection(idx, 1),

                custom_fields: community.custom_fields,
                design_defaults
            };
            return <M3_A_Card elevated={false} key={section}>
                <SectionDetails id={section} {...props} {...details} />
            </M3_A_Card>;
        })}
    </ModalContentBlock>;

    actions.push(<M3_A_Button disabled={!changes_made} onClick={handleSaveChanges} loading={saving} intent="success"
                              text="Save Changes"/>);

    console.log("Profile Designer", {
        sections,
        summary_fields,
        design_defaults,
        community_profile: community?.profile
    })

    return <GenericModal onClose={onClose} active_tab={tab} actions={actions} title={"Profile Designer"}
                         onChangeTab={setTab} tabs={tabs}>
        {content}
    </GenericModal>;
}