import React, {useState} from "react";
import {LayoutComplex, LayoutStandard} from "../../../m3/_legacy_components/app-frame/layouts";
import {TableOfContents} from "../../../m3/_legacy_components/table-of-contents";
import Field from "../../../m3/_legacy_components/form/field";
import {slugify} from "../../../m3/utilities/slugify";

export function ManageRegistrationsPage() {
    const [value, setValue] = useState("");
    return <LayoutStandard>
        <div className="">
            <Field type="text" onChange={(id,v)=>setValue(v)} value={value} label="Example" />
            <div>
                <div>slugified:</div>
                <div>{slugify(value)}</div>
            </div>
        </div>
        <div>
            sidebar
        </div>
    </LayoutStandard>
}