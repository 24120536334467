import React, {useState} from "react"
import {TableOfContents} from "../../../m3/_legacy_components/table-of-contents";
import {LayoutComplex} from "../../../m3/_legacy_components/app-frame/layouts";
import {
    ViewColumnsIcon,
    DocumentDuplicateIcon, NewspaperIcon, ChatBubbleOvalLeftIcon
} from "@heroicons/react/24/outline";
import {DEFAULT_USER_PHOTO, useCommunity} from "../../../config/community";
import {getNameInitials} from "../../../m3/utilities/get-name-initials";

import {getItemFromLocalStorage} from "../../../m3/utilities/localstorage";
import {Forum} from "../../../features/forum/forum";
import {buildImageUrl} from "../../../../common/utilities/images";

function getImageType(data, flags = {}) {
    if (!data) {
        return "";
    }
    if (data.image) {
        return flags.minimal ? "small-image" : "image"
    }
    if (data.emoji) {
        return "emoji"
    }
    return "text"
}

export function buildUniversalIcon(name="",image="",color="",emoji="",all_names=[]) {
    return {
        mini: true,
        interactive: false,
        string: getNameInitials(name, all_names || []),
        image: buildImageUrl(image, '_medium', DEFAULT_USER_PHOTO),
        type: getImageType({image,emoji}, {}),
        emoji: emoji,
        color: ""
    }
}

function buildSections(community,tab,setTab) {
    let a = [];

    a.push({
        title: "",
        key: "overview",
        spacing: "space-y-2",
        items: [
            {
                label: "Threads",
                count: "",
                key: "threads",
                format: "icon_expanded",
                left_icon: <ChatBubbleOvalLeftIcon/>,
                onClick: () => {
                    setTab("threads")
                },
                isActive: () => tab==="threads",
                can_expand: true,
                open: true,
                items: []
            },
            {
                label: "Direct Messages",
                count: "",
                key: "dms",
                format: "icon_expanded",
                left_icon: <NewspaperIcon/>,
                onClick: () => {
                    setTab("dms")
                },
                isActive: () => tab==="dms",
                can_expand: false,
                open: true,
                items: []
            },
            /*
            {
                label: "Drafts",
                count: "",
                key: "drafts",
                utils: "icon_expanded",
                left_icon: <DocumentDuplicateIcon/>,
                onClick: () => {
                },
                isActive: () => false,
                can_expand: false,
                open: true,
                items: []
            },

             */
        ],
    })

    a.push({
        key: 'groups',
        title: "Spaces",
        items: [

        ]
    })
    /*
    joined_at
:
1623697029540
last_update_at
:
1623697029540
last_update_id
:
""
updates
:
1
     */

    const a_names = getItemFromLocalStorage(`${community.id}_group_names_index`, []);

    // ${update_info ? (update_info.updates || 0) : 0}
    community.group_ids.forEach(gid => {
        const le = `group__${gid}`;
        const it = community.local_entities[le];
        if (it) {
            const update_info = community.member_entities.groups[gid];

            a[1].items.push({
                key: le,
                format: "standard",
                onClick: () => {
                    setTab(le);
                },
                can_expand: false,
                open: true,
                u_icon: buildUniversalIcon(it.name,it.image,it.color,it.emoji,a_names),
                items: [],
                label: it.name,
                isActive: () => tab===le,
                count: ``
            });
        }
    });

    return a;
}

/*
  to build: long post + reply items, templates, drafts view, sent view, scheduled view, load more

 */

function GroupTab({name,per_page=8,id,topics=[]}) {
    return <div>
        <Forum name={name || ""} show_title={false} show_create={true} id={id} items={[]}
               topics={topics} type="group"
               config={{}}/>
    </div>
}

export default function MessagesPage() {
    const community = useCommunity();
    const [tab, setTab] = useState('threads');
    const secs = buildSections(community,tab,setTab);
    const sidebar = <TableOfContents sections={secs}/>;

    function renderTab() {
        if(tab.startsWith("group__")) {
            const id = tab.replace('group__','');
            const dt = community.local_entities[tab];

            return <div className="px-2.5">
                <GroupTab per_page={12} id={id} name={dt.name} />
            </div>
        }

        switch (tab) {
            case "threads":
                return <div className="px-2.5">
                    <Forum per_page={16} show_title={false} onChangeTab={nt => {}}/>
                </div>
            case "dms":
                return <div>
                    DMS
                </div>
            default:
                return <div>

                </div>
        }
    }

    return <LayoutComplex sidebar={sidebar}>
        <div className="pt-4">
            {renderTab()}
        </div>
    </LayoutComplex>
};