import React from "react";
import M3_A_Text from "../../m3/atoms/text";

const user_one = {
    name: "Fabian Ahrens",
    profile_picture: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/files%2Fprofile_pictures%2F8656697_large?alt=media",
};

const post_image = "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/files%2FxFHLCj9BZaQprNuZpfqx%2F05-04-2024%2F9159400_large.png?alt=media";

const side_padding = "px-4";

const posts = [
    {
        title: "Post Title",
        author: {
            name: user_one.name,
            profile_picture: user_one.profile_picture,
        },
        contents: {
            text: "This is a post with text."
        },
        images: []
    },
    {
        author: {
            name: user_one.name,
            profile_picture: user_one.profile_picture,
        },
        contents: {
            text: "This is a post with an image."
        },
        images: [
            post_image
        ]
    },
    {
        author: {
            name: user_one.name,
            profile_picture: user_one.profile_picture,
        },
        contents: {
            text: "This is a post with multiple images."
        },
        images: [
            post_image,
            post_image,
            post_image,
        ]
    }
];

function Top({post}) {
    const {author} = post;
    const {name, profile_picture} = author;
    return <div className={`flex py-2 ${side_padding}`}>
        <div className="flex-grow flex gap-2.5 items-center">
            <div>
                <div className="w-7 h-7 rounded-[12px] bg-cover bg-center" style={{
                    backgroundImage: `url(${profile_picture})`,
                }}/>
            </div>
            <div>
                <M3_A_Text weight="font-medium" color="text-gray-800" size="sm">
                    <span className="font-medium">{name}</span>
                    <span className="text-gray-500">&nbsp;in&nbsp;</span>
                    <span className="font-medium">Everyone</span>
                </M3_A_Text>
                <div></div>
            </div>
        </div>
        <div className="flex-none"></div>
    </div>;
}

function Image() {
    const url = post_image;
    return <div>
        <div className="w-full aspect-w-16 aspect-h-9">
            <img src={url} className="object-cover w-full h-full  rounded-xl" alt=""/>
        </div>
    </div>;
}

function Images({post}) {
    return <div className="grid">
        {post.images.map((url, index) => {
            return <Image key={index} url={url}/>;
        })}
    </div>;
}

function Content({post}) {
    return <div className={` ${side_padding} pb-1`}>
        {post.contents.text && <M3_A_Text size="base" color="text-gray-800">
            {post.contents.text}
        </M3_A_Text>}
        {post.images.length > 0 && <Images post={post}/>}
    </div>
}

function Action({label = "Reply"}) {
    return <div>
        {label}
    </div>
}

function Actions({post}) {
    return <div className={`flex gap-2 pb-1 ${side_padding}`}>
        <Action label="React"/>
        <Action label="Reply"/>
    </div>
}

function Replies() {
    return <div className={` ${side_padding}`}>

    </div>;
}

function V3FeedItem({post}) {
    return <div>
        <Top post={post} />
        <Content post={post} />
        <Actions post={post} />
        <Replies post={post} />
    </div>;
}

export function PlaygroundPage() {

    return <div className="max-w-[760px] mx-auto divide-y divide-gray-200">
        {posts.map((post, index) => {
            return <V3FeedItem key={index} post={post}/>;
        })}
    </div>;
}