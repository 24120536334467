import {Map} from "immutable";
import React from "react";

class HeaderWrapper extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className='sc-header-wrapper'>
                {/* here, this.props.children contains a <section> container, as that was the matching element */}
                {this.props.children}
            </div>
        );
    }
}

class AtomicWrapper extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className='sc-atomic-wrapper'>
                {/* here, this.props.children contains a <section> container, as that was the matching element */}
                {this.props.children}
            </div>
        );
    }
}

export const sc_block_render_map = Map({
    'header-one': {
        element: 'h1',
        wrapper: <HeaderWrapper size="h1" />
    },
    'header-two': {
        element: 'h2',
        wrapper: <HeaderWrapper size="h2" />
    },
    'header-three': {
        element: 'h3',
        wrapper: <HeaderWrapper size="h3" />
    },
    'atomic': {
      element: 'div' ,
      wrapper: <AtomicWrapper />
    },
    'blockquote': {
        element: 'blockquote',
    },
    'unstyled': {
        element: 'div',
    }
});