import React from "react";
import M3_A_Text from "./text";
import M3_A_InlineEntity from "./inline-entity";

/*
* This is the Breadcrumb atom
 */

const _props = {
    children: {
        type: "node",
        required: true
    },
    onClick: {
        type: "function",
        required: false
    }
};

function renderComponent(type, data) {
    switch (type) {
        case "inline-entity":
            return <M3_A_InlineEntity size={data?.size}
                                      avatar={data?.avatar}>{data.children}</M3_A_InlineEntity>
        case "text":
            return <M3_A_Text color="gray-700" size={data?.size}>{data.children}</M3_A_Text>
        default:
            return null;
    }
}

export default function M3_A_Breadcrumb({
                                            type,
                                            children,
                                            data,
                                            onClick = () => {
                                            }
                                        }) {
    let classes = "p-1 rounded-md flex items-center";
    return (
        <div className={classes} onClick={onClick}>
            {children}
            {type && renderComponent(type, data)}
        </div>
    );
}