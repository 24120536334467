import React, {useState} from "react"
import './styles.css';
import {MinusIcon} from "@heroicons/react/20/solid";
import {AddFieldPrompt} from "../profile-field-renderer";
import {useNavigate} from "react-router-dom";
import {useCommunity} from "../../../config/community";
import {data_system_interests_map} from "../../../../common/data/data/interests";

export function InterestTag({emoji="🍣",onClick,can_remove=false,onRemove=()=>{},name="Sushi",id="sushi",active}) {
    const bg = active ? "bg-gradient-to-br from-yellow-400 to-orange-500 text-white border-orange-300" : "hover:bg-gray-100 text-gray-600 border-gray-200";
    return <div onClick={()=>onClick?onClick():null} className={`relative select-none inline-flex items-center text-sm rounded-full border border-solid h-7 px-1.5  cursor-pointer ${bg}`}>
        {emoji&&<span className="pr-0.5 text-base ">{emoji}</span>}
        <span className=" px-1.5 font-medium whitespace-nowrap">{name}</span>
        {can_remove&&<div onClick={(e)=>{e.stopPropagation();onRemove()}} className="flex h-4 items-center justify-center absolute text-gray-600 hover:bg-gray-200 rounded-full h-5  w-5 -top-2 -left-2 bg-gray-100">
            <MinusIcon/>
        </div>}
    </div>
}

function InterestWrapper({data,editing,community,handleRemoveInterest,id,active}) {
    if(!data) {
        return null;
    }
    console.log('InterestWrapper',data)
    return <div className="mr-1 mb-1">
        <InterestTag onClick={()=>{
            if(!editing) {
             //   history.push(`/${community.data.handle}/interests/${id}`)
            }
        }} onRemove={()=>handleRemoveInterest(id)} can_remove={editing} active={active} {...data} />
    </div>
}

export default function ViewInterests({cmi={},my_profile=true,onAddClick,add_prompt,items=[]}) {
    const [editing, setEditing] = useState(false);
    const community = useCommunity();
    const navigate = useNavigate();

    const content = items.map((id,i)=>{

        const active = cmi[id];

        if(id.startsWith('default_')) {
            const int = data_system_interests_map[id];
            return <div key={int.id} className="">
                <InterestTag onClick={()=>{
                    if(!editing) {
                        navigate(`/c/${community.domain}/members?filters=[["interests","in","${id}"]/]`)
                       // history.push(`/${''}/interests/${id}`)
                    }
                }} onRemove={()=> {

                }} can_remove={false} active={active} key={int.id} {...int} />
            </div>
        } else {
            // todo data loading
            return null;
        }
    });

    return <div className="flex flex-wrap gap-1">
        {content}
        {add_prompt&&my_profile&&!items.length&&<AddFieldPrompt onClick={()=>{
            if(onAddClick) {
                onAddClick();
            }
        }} field="Interests" />}
    </div>
};