import React, {useEffect, useState} from "react";
import "./styles.css";
import MetaHeader from "../../../m3/_legacy_components/meta-header";
import Accordion from "../../../m3/_legacy_components/accordion";
import EntityTabs from "../../../m3/_legacy_components/entity-tabs";
import {IdentificationIcon} from "@heroicons/react/24/outline";
import DetailList from "../../../m3/_legacy_components/detail-list";

import {buildCardMeta} from "../../../m3/utilities/build-card-meta";
import {getMemberProfileById, useCommunity} from "../../../config/community";
import {useUnaverse} from "../../../config/unaverse";
import AdminActivity from "../../../m3/_legacy_components/admin-activity";
import {EditMember} from "./edit-member";
import {isAnonymousUnatyEmail} from "../../../m3/utilities/email";
import {useToasts} from "../../../config/toasts";
import {getEntityFollowData, getMemberGroups} from "../../../m3/_legacy_components/follow-button/api";
import {DoubleCheckModal} from "../../../m3/_legacy_components/double-check-modal";
import {archiveMember, deleteMember, unarchiveMember} from "./api";
import {useNavigate} from "react-router-dom";
import {MMGroupsView} from "./groups";
import {AdMeActionChangeAccountEmail} from "../member/change-account-email";
import {authFetch} from "../../../../api/network";
import {ChangeHandleModal} from "./change-handle";
import {smoothScrollTo} from "../leaders";
import {ManageMemberPreferences} from "./preferences";
import InternalNotes from "../../../m3/_legacy_components/notes";
import {getProfileQualityLevel} from "../home/complete-your-profile";
import {getItemFromLocalStorage} from "../../../m3/utilities/localstorage";
import {AdminEmails} from "./emails";
import {composer_preparePrefillFromTemplate} from "../write/utilities";
import {data_membership_status_map} from "../../../../common/data/data/membership-status";
import {buildMemberProfileSections} from "../member/member-profile-utils";
import {buildImageUrl} from "../../../../common/utilities/images";
import AddSecondaryEmail from "../../../m3/_legacy_components/user-email-manager";

export function ManageModalFrame({header, sidebar, children}) {
    return <div className="relative" style={{minHeight: '20rem'}}>
        <div style={{zIndex: 99}}
             className="px-4 sm:rounded-t-xl sticky top-0 bg-white z-10 pt-2 border-b border-gray-200">
            {header}
        </div>
        <div className="relative grid sm:grid-cols-3">
            <div className="col-span-2 sm:border-r border-gray-200 space-y-2 pb-12">
                {children}
            </div>
            <div className="relative hidden sm:block grid gap-4">
                <div className="sticky top-[7rem] space-y-3 divide-gray-200 divide-y pb-12">
                    {sidebar}
                </div>
            </div>
        </div>
    </div>
}

function MemberProfile({data, setModal, cb, community}) {
    return <div className="">
        <EditMember id={data.id} flow="manage" cb={cb} padding_x="" hide_header onClose={() => {
        }} data={data}/>
    </div>
}

function getMembershipStatusLabel(id) {
    if (!id) {
        return "--";
    }
    return data_membership_status_map[id].label || "--";
}

function getMemberTypeName(arr, id) {
    const index = arr.findIndex(a => a.id === id);
    if (index > -1) {
        return arr[index].singular;
    } else {
        return "";
    }
}

function buildMember(data, community, actions = []) {
    let title, image;

    title = data.name || "";

    image = buildImageUrl(data.profile_picture, "_medium", community.default_member_profile_picture)

    return {
        title,
        image,

        top: {
            type: "entity-icon",
            name: "Member",
            icon: <IdentificationIcon/>
        },
        meta: buildCardMeta({
            meta: {
                membership_status: getMembershipStatusLabel(data?.membership_status),
                member_type: getMemberTypeName(community.member_types, data.member_type),
                occupation: data?.about.occupation,
                join_date: data?.join_date,
                birthday: data?.birthday,
                location: data?.about?.location
            }
        }, [], {
            is_admin: community.admins.all_user_uids[data.id]
        }),
        actions_dropdown: actions
    }
}

function buildActions(member, community, fns) {
    let options = [];

    const member_is_admin = community.admins.all_user_uids[member.id];

    if (!member_is_admin) {
        options.push({
            label: 'Change Account Email',
            onClick: () => fns.openChangeEmailModal()
        });

        options.push({
            label: 'Change Handle',
            onClick: () => fns.openChangeHandleModal()
        });

        if (member.archived) {
            options.push({
                label: 'Reactivate account',
                onClick: () => fns.reactivateMember()
            });
            options.push({
                label: 'Delete account',
                onClick: () => fns.deleteMember()
            });
        } else {
            options.push({
                label: 'Deactivate account',
                onClick: () => fns.deactivateMember()
            });
        }
    }

    if (isAnonymousUnatyEmail(member.account_email) || member.archived) {
        // nothing
    } else if (member.last_sign_in) {
        /*
        options.push({
            type: 'item',
            title: 'Send Data Check Email',
            onClick: ()=>fns.sendQPUEmail()
        });

         */
        options.push({
            label: 'Send Account Details',
            onClick: () => fns.resetPassword()
        });
    } else {
        options.push({
            label: 'Send Invite',
            onClick: () => fns.sendInvite()
        });
    }

    options.push({
        label: 'Send Email',
        onClick: () => fns.sendEmail()
    });

    return options;
}

export function membersBuildInviteTo(members) {
    let a = [];
    members.filter(b => !isAnonymousUnatyEmail(b.account_email)).forEach(m => {
        a.push({
            email: m.account_email,
            handle: m.handle,
            image: m.profile_picture,
            isDisabled: false,
            label: m.name,
            id: m.id,
            count: 1,
            value: m.id,
            type: "member"
        });
    });
    return a;
}

const code_messages = {
    'known-member': 'Member has already logged in - they should change the email themselves',
    '': 'Not sure!',
    'email-belongs-to-existing-member': 'Email already taken by another member',
};

function buildTags(is_admin, is_owner, is_archived, is_anonymous) {
    let a = [];

    if (is_admin) {
        a.push({
            title: 'Admin',
            color: 'admin'
        })
    }

    if (is_owner) {
        a.push({
            title: 'Owner',
            color: 'owner'
        });
    }

    if (is_archived) {
        a.push({
            title: 'Deactivated',
            color: 'secondary'
        });
    }

    if (is_anonymous) {
        a.push({
            title: 'Missing Email',
            color: 'warning'
        });
    }

    return a;
}

export async function changeMemberHandle(community, id, handle) {
    return new Promise((resolve, reject) => {
        const res = (resp) => {
            resolve(resp);
        };

        const payload = {
            new_handle: handle,
            community_uid: community.uid,
            member_id: community.member_id,
            id: id,
            user_uid: community.member.user_uid,
        };

        authFetch("/members/change-handle", res, res, "POST", {payload})
    });
}

export function ManageMember({onClose, data = {}, init_tab = "Profile"}) {
    const all_tabs = [{label: "Activity"}, {label: "Profile"}, {label: "Emails"}, {label: "Notes"}, {label: "Spaces"}, {label: "Preferences"}];
    const [dt, updateDt] = useState({...data});
    const [tab, setTab] = useState(init_tab);
    const toasts = useToasts();
    const [loading, setLoading] = useState('');
    const navigate = useNavigate();
    const [modal, setModal] = useState("");
    const [member_groups, setMemberGroups] = useState([]);
    const [modal_data, setModalData] = useState(null);
    const [follow_data, setFollowData] = useState();
    const unaverse = useUnaverse();
    const community = useCommunity();

    const profile_quality_level = getProfileQualityLevel(dt, community, getItemFromLocalStorage(`${community.domain}-${community.member_id}-data-check-ignore`, {}));

    function refresh() {
        getMemberProfileById(community.id, data.id)
            .then(m => {
                if (!m) {
                    return;
                }
                updateDt({...m});
            })
    }

    function scrollTo(t = "Profile", sid = "edit-details") {
        setTab(t)
        setTimeout(function () {
            smoothScrollTo("modal-scrollarea", sid, -160);
        }, 50)
    }

    const fns = {

        openPhone: () => {
            scrollTo("Profile", "_edit-Contact")
        },

        openLocation: () => {
            scrollTo("Profile", "_edit-Details")
        },

        openOccupation: () => {
            scrollTo("Profile", "_edit-Details")
        },

        openMemberType: () => {
            scrollTo("Profile", "_edit-General")
        },

        openMembershipStatus: () => {
            scrollTo("Profile", "_edit-General")
        },

        openBirthday: () => {
            scrollTo("Profile", "_edit-Details")
        },

        openJoinDate: () => {
            scrollTo("Profile", "_edit-Dtails")
        },

        openChangeHandleModal: () => {
            setModal('change-handle');
        },

        openChangeEmailModal: () => {
            setModal('change-email');
        },

        deleteMember: () => {
            setModal("member_delete");
        },

        deactivateMember: () => {
            setModal("member_archive");
        },

        reactivateMember: () => {
            setModal("member_unarchive");
        },

        addSecondaryEmail: () => {
            setModal("add-secondary-email");
        },

        resetPassword: () => {
            alert('todo - send reset password')
        },
        sendEmail: () => {
            setLoading('_sending_email');

            const prefill = {
                to: membersBuildInviteTo([dt]),
                config: {},
                content: ``,
                subject: ``,
                type: 'email',
                template: '',
                features: {
                    can_add_to: false,
                    can_edit_to: false
                },
                onSend: () => {
                    toasts.addToast({text: "Email sent", intent: "success"});
                    setLoading('');
                }
            };

            community.openComposer(prefill)
        },
        sendInvite: async (md) => {
            setLoading('_sending_invite');

            await composer_preparePrefillFromTemplate(community.uid, "new-member-invite",  "invite", [dt], () => {
                toasts.addToast({text: "Invite sent", intent: "success"});
                setLoading('');
            }).then((prefill) => {
                community.openComposer(prefill)
            })
        }
    };

    const actions = buildActions(dt, community, fns);

    const mh = buildMember(dt, community, actions);

    useEffect(function () {
        getEntityFollowData(community, dt.id, "member")
            .then((fd) => {
                setFollowData(fd)
            })
        getMemberGroups(community.id, dt.id)
            .then(groups => {
                setMemberGroups([...groups])
            })
    }, []);


    function in_deleteMember() {
        toasts.addToast({text: "Deleting member", intent: 'info'});
        setLoading('deleting');
        deleteMember(community, dt.id)
            .then(() => {
                setLoading('');
                toasts.addToast({text: "Member was deleted", intent: 'success'});
                community.closePreviewOverlay();
                navigate(`/c/${community.profile.handle}/members?ref=member-deleted`);
                if (onClose) {
                    onClose();
                }
            })
    }

    function hc(f, v) {
        updateDt({...dt, [f]: v})
    }

    function in_deactivateMember() {
        toasts.addToast({text: "Deactivating member", intent: "info"})
        hc('archived', true);
        setLoading('_loading_archive');
        archiveMember(community, dt.id)
            .then(() => {
                toasts.addToast({text: "Member was deactivated", intent: "success"})
            })
    }

    function in_reactivateMember() {
        toasts.addToast({text: "Reactivating member", intent: "info"})
        hc('archived', false);
        setLoading('_loading_archive');
        unarchiveMember(community, dt.id)
            .then(() => {
                toasts.addToast({text: "Member was reactivated", intent: "success"})
            })
    }

    function changeHandle(h) {
        setLoading('handle');

        changeMemberHandle(community, dt.id, h)
            .then((resp) => {
                setLoading('');
                setModal("");
                updateDt({...dt, handle: resp?.data?.handle})

                toasts.addToast({text: `Member handle changed`, intent: 'success'});
            })
    }

    function changeAccountEmail(new_email) {
        setLoading('changing-account-email');
        toasts.addToast({text: `Changing account email`, intent: 'info'});

        const res = (resp) => {
            setLoading('');
            setModal("");
            console.log("RESP", resp);
            if (resp.data.ok === 'yes') {
                // history.push(`/${community.profile.handle}/admin/member/${resp.data.id}`);
                toasts.addToast({text: `Account Email changed`, intent: 'success'});
                if (resp.data && resp.data.member) {
                    console.log("ACCOUNT EMAIL CHANGED", resp.data.member)
                    updateDt({...dt, ...resp.data.member})
                }
            } else {
                // no error is shown
                toasts.addToast({
                    text: resp.data.code && code_messages[resp.data.code] ? code_messages[resp.data.code] : "Something went wrong..",
                    intent: 'danger'
                });
            }
        };

        const payload = {
            member_id: community.member_id,
            community_uid: community.uid,
            id: dt.id,
            new_email,
            member_user_uid: dt.user_uid,
            user_uid: community.member.user_uid,
        };

        authFetch("/members/change-account-email", res, res, "POST", {payload});
    }

    function renderTab() {
        switch (tab) {
            case "Preferences":
                return <div>
                    <ManageMemberPreferences data={dt} updateMember={() => {
                    }}/>
                </div>
            case "Emails":
                return <div className="p-4">
                    <AdminEmails key="emails" scope_id={dt.id} scope="member"/>
                </div>
            case "Activity":
                return <div className="p-4">
                    <AdminActivity scope_id={dt.id} scope={"member"} community={community} scope_data={dt}/>
                </div>;
            case "Profile":
                return <div className="px-4">
                    <MemberProfile cb={() => refresh()} data={dt}/>
                </div>
            case "Notes":
                return <div className="p-4">
                    <InternalNotes scope_id={dt.id} scope={"role"}/>
                </div>
            case "Spaces":
                return <div className="p-4">
                    <MMGroupsView member_id={dt.id} groups={member_groups.sort((x, y) => x.name > y.name ? 1 : -1)}/>
                </div>
            default:
                return null;
        }
    }

    const flags = {};

    const tags = buildTags(community.admins.all_user_uids[dt.id], community.profile.created_by === dt.id, dt.archived, isAnonymousUnatyEmail(dt.account_email));

    const tabs = <EntityTabs onChangeTab={t => setTab(t)} active={tab} tabs={all_tabs}/>;

    const header = <MetaHeader tags={tags} {...mh} tabs={tabs} onClose={onClose}/>;

    const sections = buildMemberProfileSections(dt, community.custom_fields, community, unaverse, flags, fns, 'manage', follow_data, profile_quality_level);

    const default_email = dt?.account_email || "";

    const sidebar = <>
        {sections.map((sec, index) => {
            return <div key={index} className="px-4 py-1">
                <Accordion open={true} title={sec.title}>
                    <DetailList items={sec.items}/>
                </Accordion>
            </div>
        })}
    </>;

    return <ManageModalFrame header={header} sidebar={sidebar} tabs={tabs}>
        {renderTab()}
        {['member_archive', 'member_delete', 'member_unarchive'].includes(modal) && <DoubleCheckModal
            onCancel={() => {
                setModal(null);
                setModalData(null);
            }}
            onConfirm={() => {
                setModal(null);
                if (modal === 'member_archive') {
                    in_deactivateMember();
                } else if (modal === 'member_delete') {
                    in_deleteMember();
                } else if (modal === 'member_unarchive') {
                    in_reactivateMember();
                }
            }}
            type={modal}/>}
        {modal === 'change-handle' &&
            <ChangeHandleModal loading={loading === 'handle'} community_uid={community.id} data={{handle: dt.handle}}
                               onConfirm={(h) => {
                                   changeHandle(h)
                               }}
                               onClose={() => setModal("")} onCancel={() => {
                setModal("");
            }} type='member'/>}
        {modal === 'change-email' && <AdMeActionChangeAccountEmail
            default_email={default_email}
            loading={loading === 'changing-account-email'} community_uid={community.uid}
            onConfirm={(h) => changeAccountEmail(h)}
            onClose={() => {
                setModal("");
            }} onCancel={() => {
            setModal("");
        }}
        />}
    </ManageModalFrame>
}