import React, {useState, useEffect} from 'react';
import {useCommunity} from "../../../config/community";
import {useNavigate, useParams} from "react-router-dom";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {MarkdownViewer} from "../../../m3/components/markdown-viewer";
import {LayoutContentBlock, LayoutContentWrapper, LayoutStandard} from "../../../m3/_legacy_components/app-frame/layouts";

export function PolicyPageWrapper({}) {
    const {id} = useParams();
    return <div>
        <PolicyViewer id={id}/>
    </div>
}

export async function getPolicy(community_uid, id) {
    const ref = doc(db, 'communities', community_uid, 'policies', id);
    console.log("getPolicy", community_uid, id);
    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? {...doc.data(), id: doc.id} : null;
        });
}

export function PolicyViewer({id, modal, preview}) {
    const community = useCommunity();
    const [loading, setLoading] = useState("data");
    const [data, setData] = useState({});
    const navigate = useNavigate();

    useEffect(function () {
        fetchPolicy();
    }, [community.uid, id]);

    function fetchPolicy() {
        getPolicy(community.uid, id).then((d) => {
            setData(d);
            setLoading("");
        });
    }

    const is_admin = community.is_admin;

    let c = null;

    if (loading === 'data' || (loading === '' && !data)) {
        c = null;
    } else if (!is_admin && loading === '' && data.status === 'draft') {
        c = <LayoutContentBlock>
            Not published yet
        </LayoutContentBlock>
    } else {
        c = <>
            <LayoutContentBlock>
                <div className="text-base font-bold">{data.name}</div>
                <div>
                    <div className="text-sm text-gray-500">Version {data.version}</div>
                </div>
            </LayoutContentBlock>

            {data.status === 'published' && <LayoutContentBlock>
                <MarkdownViewer fill size="prose-sm" content={data.content}/>
            </LayoutContentBlock>}
        </>
    }

    if(modal) {
        return c;
    }

    return <LayoutStandard topbar={!preview && <div/>}>
        <LayoutContentWrapper>
            {c}
        </LayoutContentWrapper>
    </LayoutStandard>
}