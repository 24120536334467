export function buildCalendarDirectory(view_stacks,all_teams) {
    let a = {
        customize_menu: null,
        primary_actions: [],

        features: {
            can_create_view: false,
            can_sort: false,
            can_filter: false,
            can_search: true,
            can_group: true,
            can_edit_views: true,
            can_customize: false
        },

        actions: [],
        collections: [

        ],

        sort: [
           /*
            {
                id: "summary",
                label: "Name",
                type: "string"
            }
            */
        ],

        filters: [
            {
                id: "group",
                label: "Spaces",
                selected_label: {
                    one: "Space",
                    many: "Spaces"
                },
                type: "select",
                options: all_teams.map(team=>{
                    return {
                        value: team.id,
                        label: team.name
                    }
                })
            }
        ],

        group_by: [
            {
                id: "group",
                label: "Group"
            }
        ]
    };

    if(view_stacks) {
        view_stacks.forEach(view_stack=>{
            a.collections.push({
                ...view_stack,
                views: view_stack.stack
            })
        })
    } else {
        a.collections = [
            {
                id: "calendar",
                title: "Calendar",
                views: [
                    {
                        label: "Upcoming",
                       // group_by: "date"
                    }
                ]
            }
        ]
    }

    return a;
}