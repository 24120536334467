import React from "react";
import M3_A_FormattedValue from "../atoms/formatted-value";
import M3_A_Icon from "../atoms/icon";
import {m3_icon_map} from "../icons/icon-map";
import {data__member_profile_fields} from "../../../common/data/data-fields";
import {all_field_types} from "../../../common/data/field-types";

/*
    This example shows how to render fields
 */

function InlineCode({children}) {
    return <code
        className="px-1 -mx-1 py-0.5 -my-0.5 border-gray-300 border text-red-700 text-sm font-medium bg-gray-200 rounded-md">{children}</code>
}

function Entry({id, type, autocomplete, icon, label, sample_value, member}) {
    const field_type_data = all_field_types[type];

    if(!field_type_data) {
        return <div>Field Type Not Found: {type}</div>
    }

    const icon_comp = m3_icon_map?.outlines?.[icon||type];

    return <div className="p-3 flex flex-col bg-white gap-3 border rounded-xl border-gray-200">
        <div>{id} - {label}</div>
        <div>
            <div>Icon</div>
            <div className="w-10 h-10">
                {icon_comp && <M3_A_Icon icon={icon_comp} size="base"/>}
                {!icon_comp && <div className="w-10 h-10 bg-gray-200 rounded-md"/>}
            </div>
        </div>
        <div>
            <div>Member Accessor</div>
            <div>
                <InlineCode>{member?.accessor}</InlineCode>
            </div>
        </div>
        <div>
            <div>Raw Data</div>
            <InlineCode>{JSON.stringify(sample_value)}</InlineCode>
        </div>
        <div>
            <div>Formatted</div>
            <div>
                <M3_A_FormattedValue options={{
                    autocomplete
                }} value={sample_value} type={type}/>
            </div>
        </div>
    </div>
}

export default function M3_E_FieldRendering({}) {

    const entries = Object.entries(data__member_profile_fields);

    return <div className="grid gap-3 p-6 bg-gray-600" style={{gridTemplateColumns: 'repeat(auto-fill, minmax(25rem, 1fr))'}}>
        {entries.map(([k, v]) => {
            return <Entry key={k} id={k} {...v}/>
        })}
    </div>
}