import React from 'react';
import ContentPopup from "../../../../m3/_legacy_components/content-popup";
import {ChevronRightIcon} from "@heroicons/react/24/outline";

function BlockPreview({image,desc,onClick=()=>{},label='Leader List'}) {
    return <div onClick={onClick} style={{minWidth:'10rem'}} className="group hover:opacity-80 cursor-pointer transition-opacity">
        {image&&<div style={{paddingBottom:'50%'}} className="w-full bg-red-200 rounded-md"></div>}
        <div className="flex pt-1 items-baseline">
            <span className="text-sm font-medium text-gray-800">{label}</span>
            <span className="ml-1 h-2.5 w-2.5">
                <ChevronRightIcon />
            </span>
        </div>
        {desc&&<div className="text-xs pr-8 text-gray-600 pt-0.5">
            {desc}
        </div>}
    </div>
}

function Title({children}) {
    return <div className="font-bold text-gray-800 text-base">{children}</div>
}

function Wrapper({children}) {
    return <div className="px-3 grid grid-cols-2 pb-3 gap-3">{children}</div>
}

const mw = [
    {
        label: "Poll",
    }
];

export function BlocksPopupContent({blocks=[],onClick,title=""}) {

    return <ContentPopup max_size={"max-w-sm"}>
        <div className="overflow-y-auto" style={{maxHeight:'20rem'}}>
        <div className={!title?"pt-3":""}>
            {title&&<div className="px-3 py-2">
                <Title>{title}</Title>
            </div>}
        <Wrapper>
            {blocks.map((wid,index)=>{
                return <BlockPreview onClick={()=>onClick(wid)} key={index} {...wid} />
            })}
        </Wrapper>
        </div>
        </div>
    </ContentPopup>
}

export function WidgetsPopupContent({title='',widgets=[...mw]}) {
    return <BlocksPopupContent blocks={widgets} title={title} />
}