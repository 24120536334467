import {verifyPasswordResetCode,confirmPasswordReset,signInWithEmailLink,applyActionCode,isSignInWithEmailLink} from "firebase/auth";
import {authFetch, externalAuthFetch} from "../../../../api/network";

export async function handleResendResetPasswordLink(email) {
    return new Promise(resolve => {
        const res = (result) => {
            resolve({
                resending_link: false,
                reset_link_sent: true
            })
        };
        externalAuthFetch("/send-password-reset-email", res, res, "POST", {email});
    })
}

export function handleSentId(sent_id, community_uid) {
    const res = () => {
    };
    authFetch("/followed-invite", res, res, "POST", {
        payload: {
            sent_id, community_uid
        }
    });
}

export async function handleResetPassword(auth,code,password) {

    return await confirmPasswordReset(auth, code, password)
        .then((result) => {
            // undefined?
            // need to sign in after this

            return {
                ok: 'yes'
            }

        })
        .catch((error) => {
            console.log('error', error);
            return {
                ok: 'no'
            }
        })
}

export async function verifyPasswordOobCode(auth, code) {
    console.log("verifyPasswordResetCode",code)
    return await verifyPasswordResetCode(auth,code)
        .then((email) => {
            return {
                ok: "yes",
                email
            };
        })
        .catch((error) => {
            console.error('error', error);
            // auth/expired-action-code - expired
            // auth/invalid-action-code - already used
            // auth/user-disabled - user disabled
            // auth/user-not-found - user not found
            return {
                ok: "no",
                error
            };
        })

}

export async function isEmailSignInLink(auth,email,window_location_href = window.location.href) {
    if (isSignInWithEmailLink(auth,window_location_href)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.

        // The client SDK will parse the code from the link for you.
        return await signInWithEmailLink(auth, email, window_location_href)
            .then((result) => {
                // Clear email from storage.
console.log("sign in with email link",result)
                return result;
            })
            .catch((error) => {
                // Some error occurred, you can inspect the code: error.code
                // Common errors could be invalid email and invalid or expired OTPs.
                console.warn("sign in with email link error:",error)
                return false;
            });
    } else {
        console.log("not sign in with email link")
    }
    return true;
}

export async function verifyUserAccount(auth,actionCode) {

    return await applyActionCode(auth,actionCode)
        .then((resp) => {
           return {
               ok: 'yes'
           };
        })
        .catch((error) => {
            // error, ask to reverify

            return {
                ok: 'no'
            }
        })
}