import React from "react";
import {
    BriefcaseIcon,
    CakeIcon,
    CalendarIcon,
    MapPinIcon,
    UserIcon
} from "@heroicons/react/24/outline";
import dayjs from 'dayjs';
import {getMemberJoinDate} from "./joined-date";

function transformDate(dt,fm='MMM DD, YYYY') {
    let d;

    if(typeof dt === 'object'&&dt.month!=="") {
        d = dayjs();
        if(dt.month!=="") {
            d = d.month(Number(dt.month));
        }

        if(dt.date!=="") {
            d = d.date(Number(dt.date));
        }

        if(dt.year!=="") {
            d = d.year(Number(dt.year));
        }

        if(!d.isValid()) {
            return "";
        }
        return d.format(fm);
    } else if (dt.length === 2) {
        // month
        d = dayjs();
        d = d.month(Number(dt));
        d = d.date(15);
        d = d.year(2000);

        return `${d.format('MMMM')}`;
    } else if (dt.length === 5) {
        // month and day
        d = dayjs();
        d = d.year(2000);
        d = d.month(Number(dt.slice(0,2)));
        d = d.date(Number(dt.slice(3,5)));
        return `${d.format('MMM DD')}`;
    } else if (dt.length === 4) {
        // year
        return `${dt}`;
    } else if (dt.length === 7) {
// month and year
        d = dayjs();
        d = d.month(Number(dt.slice(0,2)));
        d = d.date(15);
        d = d.year(Number(dt.slice(4,7)));

        return `${d.format('MMM YYYY')}`;
    }
    d = dayjs(dt);
    if (!d.isValid()) {
        return "";
    }
    return d.format(fm);
}

export function buildCardMeta(profile,social=[],flags={}) {
    const obj = profile.meta;
    let a = [];
    if (!obj) {
        return a;
    }

    if (obj.member_type) {
        a.push({
            icon: <UserIcon/>,
            type: "member_type",
            label: obj.member_type
        })
    }

    /*
    if(flags.is_admin) {
        a.push({
            icon: <ChevronDoubleUpIcon/>,
            label: 'Admin'
        })
    }

     */

    if (obj.type) {
        a.push({
            icon: <BriefcaseIcon/>,
            label: obj.type
        })
    }

    if (obj.occupation) {
        a.push({
            icon: <BriefcaseIcon/>,
            type: "occupation",
            label: obj.occupation
        })
    }

    if (obj.location) {
        a.push({
            icon: <MapPinIcon/>,
            type: "location",
            label: obj.location
        })
    }

    if (obj.birthday) {
        let bday = transformDate(obj.birthday, 'MMM DD');
        if (bday) {
            a.push({
                icon: <CakeIcon/>,
                label: `Born ${bday}`
            })
        }
    }

    if (obj.join_date) {
        const mjv = getMemberJoinDate(obj.join_date,'');
        if (mjv&&mjv!=='-') {
            a.push({
                icon: <CalendarIcon/>,
                label: `Joined ${mjv}`
            })
        }
    }

    a = a.concat(social);

    return a;
}