import React from "react";

/*
* This is the TextArea atom
 */

export default function M3_A_TextArea({onChange, value, sx, ...rest}) {
    let styles = {},
        classes = "w-full input-focus transition-colors rounded-lg bg-white px-2 py-1";

    if (sx) {
        styles = sx;
    }

    return (
        <textarea
            className={classes}
            style={styles}
            value={value}
            onChange={onChange}
            {...rest}
        />
    );
}