import React from "react";
import M3_A_Card from "../atoms/card";
import M3_A_Avatar from "../atoms/avatar";
import M3_A_Text from "../atoms/text";
import M3_A_Icon from "../atoms/icon";
import {m3_icon_map} from "../icons/icon-map";
import M3_A_IconButton from "../atoms/icon-button";
import {PencilIcon} from "@heroicons/react/24/outline";
import {buildImageUrl} from "../../../common/utilities/images";


/*
    * This is a member card example
 */

const _key_fields = [
    {
        accessor: "hub",
        label: "Hub"
    },
    /*
    {
      accessor: "receptionsjahr",
      label: "Rec."
    },

     */
    {
        accessor: "location",
        emoji: "",
        icon: "location",
    },
    {
        accessor: "interests",
        icon: "interests"
    }
];

/*
TODO
- emoji for flag
- icon support
 */

const _demo_member = {
    "hub": "Chicago",
    "member_type": "Ambassador",
    "headline": "Product Designer @ Apple",
    "name": "Austin Knight",

    "profile_picture": "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200",

    "location": "Chicago, IL",
    "interests": "UX Design, Product Design, Design Systems, Design Leadership, Design Ops",

    "receptionsjahr": "2015"
};

export default function M3_E_MemberCard({
    theme,
                                            member,
                                            member_types,
                                            onViewProfile = () => {},
                                            onEditProfile = () => {},
                                            key_fields = [
                                                {
                                                    accessor: "location",
                                                    emoji: "",
                                                    icon: "location",
                                                },
                                            ]
                                        }) {

    const sections = [
        {
            key: "header",
            children: <div className="flex gap-3">
                <div className="flex-none">
                    <M3_A_Avatar sx={{
                        backgroundColor: theme?.background_text_80,
                        color: theme?.background_active_text
                    }} size="3xl" letter={member?.about?.first_name?.charAt(0).toUpperCase()} avatar={buildImageUrl(member?.profile_picture, "_medium")}/>
                </div>
                <div className="flex-grow flex justify-center flex-col">
                    <M3_A_Text as="div" size="base" weight="font-semibold" color="text-gray-800">
                        {member?.name}
                    </M3_A_Text>
                    {member?.about?.headline && <M3_A_Text as="div" size="sm" color="text-gray-800">
                        {member?.about?.headline}
                    </M3_A_Text>}
                    {member?.member_type && <M3_A_Text as="div" size="sm" color="text-gray-600">
                        {member_types.find(it => it.id === member.member_type)?.singular}
                    </M3_A_Text>}
                </div>
            </div>
        },
        {
            key: "body",
            children: <div className="flex flex-col gap-2 ">
                {key_fields.map(it => {
                    return <div className="flex gap-3">
                        <div className='flex-none w-6 h-5 items-center justify-center flex text-gray-600'>
                            {it.emoji && <div>
                                {it.emoji}
                            </div>}
                            {it.icon && <M3_A_Icon size="sm" icon={m3_icon_map.outlines[it.icon]}/>}
                            {!it.emoji && !it.icon && <div className="">
                                <M3_A_Text size="xs" weight="font-medium">
                                    {it.label}
                                </M3_A_Text>
                            </div>}
                        </div>
                        <div className="flex-grow text-gray-800 line-clamp-2">
                            <M3_A_Text size="sm" leading="leading-5" as="div">
                                {member[it.accessor] || "--"}
                            </M3_A_Text>
                        </div>
                    </div>
                })}
            </div>
        },
        {
            key: "footer",
            children: <div className="grid grid-cols-2 gap-2">
                <M3_A_IconButton onClick={() => {
                    onEditProfile();
                }} size="base" text="Edit" adjust_icon_size={-2} intent="primary" icon={<PencilIcon/>} fluid/>
                <M3_A_IconButton onClick={() => {
                    onViewProfile();
                }} size="base" text="View" fluid/>
            </div>
        }
    ];
    return <M3_A_Card sections={sections}/>
}