import React, {useCallback, useEffect} from "react";
import {flexRender, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {useVirtualizer} from "@tanstack/react-virtual";
import {useMobileDimensions} from "./list-view/utils";
import {_m3_c_list_view_components} from "./list-view/components";
import {useInfiniteScrollData} from "../../routes/community/contacts/hooks";
import {ContactsEmptyState} from "../../routes/community/contacts/components";

function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

/*
examples for clean function
AH Ahrens -> Ahrens
AH Bayerlein II -> Bayerlein II
AH Behrends IV -> Behrends IV
 */

const symbols = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X"];

export function getLastName(str) {
    const cleaned = str.trim();
    const split = cleaned.split(" ").filter(a => !!a);
    if (symbols.includes(`${split[split.length - 1]}`)) {
        return split[split.length - 2] + " " + split[split.length - 1];
    } else {
        return split[split.length - 1];
    }
}

function getAddedLetters(arr) {
    let added = {};
    arr.forEach(item => {
        if (item?.type === "subheader") {
            added[item.name] = true;
        }
    })
    return added;
}

const addLetterHeaderRow = (arr) => {
    // for each letter of the name field of an item, and if it hasn't been added yet, add a row with the letter as the name and a different type
    if (arr.length < 16) {
        return arr;
    }
    let added = getAddedLetters(arr);

    arr.forEach(item => {
        if (item?.type === "subheader") {
            return;
        }
        const last_name_split = getLastName(item.name);

        const first_letter = last_name_split.charAt(0).toUpperCase();
        if (!added[first_letter]) {

            const current_index = arr.findIndex(i => i.id === item.id);
            // add item at index

            arr.splice(current_index, 0, {
                id: `subheader-letter-${first_letter}`,
                name: first_letter,
                type: "subheader"
            })

            added[first_letter] = true;
        }
    })

    return arr;
}

export default function SimpleVirtualizedList({
                                                  config,
                                                  page_size
                                              }) {

    const dimensions = useMobileDimensions(config?.state?.search_open);

    const [data, is_fetching, fetchNextPage, has_more_ref] = useInfiniteScrollData(config.state.active_view_data.config, config.state.query, page_size, config.context.community_uid, config.fns.getData, config.fns.getPartialsData);

   const [transformed_data, setTransformedData] = React.useMemo(() => {
        return addLetterHeaderRow(data);
    }, [data])


    const columns = React.useMemo(() => [
        {
            size: dimensions.width - convertRemToPixels(0.75),
            accessorKey: `name`,
            cell: info => <_m3_c_list_view_components.Cell info={info}/>,
            meta: {
                type: "mobile-name",
                onViewProfile: config?.fns?.onViewProfile
            }
        }
    ], [dimensions.width]);

    const tableContainerRef = React.useRef(null);

    const fetchMoreOnBottomReached = useCallback((containerRefElement) => {
        if (containerRefElement) {
            const {scrollHeight, scrollTop, scrollLeft, clientHeight} = containerRefElement
            //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
            // also handleStickyColumnShadow
            if (is_fetching || data.length === 0 || !has_more_ref.current) {
                return;
            } else if ((scrollHeight - scrollTop - clientHeight) < 300) {

                fetchNextPage();
            }
        }
    }, [fetchNextPage, is_fetching, data.length]);

    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current)
    }, [fetchMoreOnBottomReached])

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        debugTable: false,
        getRowId: row => row.id,
        state: {}
    })

    const {rows} = table.getRowModel();

    const item_height_px = 41;

    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        estimateSize: () => item_height_px, //estimate row height for accurate scrollbar dragging
        getScrollElement: () => tableContainerRef.current, //measure dynamic row height, except in firefox because it measures table border height incorrectly
        measureElement: typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1 ? element => element?.getBoundingClientRect().height : undefined,
        overscan: 5,
    })

    const show_empty_state = data.length === 0 && !is_fetching;

    return <div className="relative" style={{
        height: dimensions.height, width: dimensions.width,
    }}>
        <div
            className={`list-view-container`}
            ref={tableContainerRef}
            id="list-view-table-container"
            onScroll={e => {
                fetchMoreOnBottomReached(e.target);
            }}
            style={{
                overflow: 'auto', //our scrollable table container
                position: 'relative', //needed for sticky header
                height: dimensions.height, //should be a fixed height
            }}
        >
            <table style={{display: 'grid'}} className={``}>
                <tbody
                    style={{
                        display: 'grid', height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
                        position: 'relative', //needed for absolute positioning of rows
                    }}
                >
                {rowVirtualizer.getVirtualItems().map(virtualRow => {
                    const row = rows[virtualRow.index];
                    return (<tr
                        data-index={virtualRow.index} //needed for dynamic row height measurement
                        ref={node => rowVirtualizer.measureElement(node)} //measure dynamic row height
                        key={row.id}
                        className="relative group px-1.5"
                        style={{
                            height: `${item_height_px}px`, //this should always be a `style` as it changes on scroll
                            display: 'flex', position: 'absolute', transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                            width: '100%',
                        }}
                    >
                        {row.getVisibleCells().map((cell, cell_index) => {
                            return (<td
                                key={cell.id}
                                style={{
                                    display: 'flex',
                                    height: `${item_height_px}px`,
                                    position: '',
                                    left: "",
                                    zIndex: '',
                                    width: cell.column.getSize(),
                                }}
                                className={``}
                            >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>)
                        })}
                    </tr>)
                })}
                </tbody>
            </table>
        </div>
        {show_empty_state && <ContactsEmptyState query={config?.state?.query} />}
    </div>
}