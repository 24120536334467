import dayjs from "dayjs";
import ScheduleView from "./schedule-view";
import React, {useEffect, useState} from "react";
import {useToasts} from "../../../config/toasts";
import {copyTextToClipboard} from "../../../m3/utilities/copy-to-clipboard";
import {externalAuthFetch} from "../../../../api/network";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {DateFormat} from "./components/datetime-selector";
import M3_A_Button from "../../../m3/atoms/button";

export function calendarSortEvents(a, b) {
    return a.start_datetime - b.start_datetime;
}

export function buildEventTimeString(start_datetime, end_datetime, all_day, already_dayjs) {
    const start_date = already_dayjs ? start_datetime : dayjs(start_datetime);
    const end_date = already_dayjs ? end_datetime : dayjs(end_datetime);

    if (all_day) {
        return `All Day`;
    }


    // desired outputs:
    // 1. 10am - 11am
    // 2. 10:30am - 11am
    // 3. 10am - 11:30am
    // 4. 10:30am - 11:30am
    // 5. 4 - 11:30pm

    const start_string = start_date.format('h:mma').replace(':00', '');
    const end_string = end_date.format('h:mma').replace(':00', '');

    const start_is_on_hour = start_date.minute() === 0;
    const end_is_on_hour = end_date.minute() === 0;

    const start_ampm = start_date.format('a');
    const end_ampm = end_date.format('a');

    const duration_is_one_hour = end_date.diff(start_date, 'hour') === 1;

    if (duration_is_one_hour) {
        return `${start_string}`;
    }

    if (!start_is_on_hour && !end_is_on_hour && start_ampm === end_ampm) {
        return `${start_date.format('h:mm')} - ${end_date.format('h:mma')}`;
    }

    if ((start_is_on_hour || !end_is_on_hour) && start_ampm === end_ampm) {
        return `${start_date.format('h')} - ${end_date.format('h:mma')}`;
    }

    if ((!start_is_on_hour || end_is_on_hour) && start_ampm === end_ampm) {
        return `${start_date.format('h:mm')} - ${end_date.format('ha')}`;
    }

    return `${start_string} - ${end_string}`;
}

export function parseEventLocation(location) {
    if(!location){
        return {
            label: null,
            sublabel: null
        };
    }
    const comma_count = (location.match(/,/g) || []).length;
    if (comma_count < 3) {
        return {
            label: location,
            sublabel: null
        }
    } else {
        // can be many
        const parts = location.split(",");
        const label = parts[0];
        const sublabel = parts.slice(1).join(",");
        return {
            label,
            sublabel
        }
    }
}

export function parseConferenceData(conferenceData) {
    if (!conferenceData) {
        return {
            label: "",
            type: "",
            uri: ""
        }
    }

    let parsed;

    if(typeof conferenceData === 'string'){
        try {
            parsed = JSON.parse(conferenceData);
        } catch (e) {
            return {
                label: "",
                type: "",
                uri: ""
            }
        }
    }

    if (typeof parsed !== 'object') {
        return {
            label: "",
            type: "",
            uri: ""
        }
    }

    if (!parsed.entryPoints) {
        return {
            label: "",
            type: "",
            uri: ""
        }
    }

    const entry_point = parsed.entryPoints[0];

    if (!entry_point) {
        return {
            label: "",
            type: "",
            uri: ""
        }
    }

    return {
        label: entry_point.label,
        type: entry_point.entryPointType,
        uri: entry_point.uri
    }
}

export function calendarBuildEventsSections(filtered_items, {active_group_by, active_sort}, spec, community) {
    const today = dayjs();
    let arr = [], month_map = {};

    arr.push({
        id: `month-${today.format('YYYY-MM')}`,
        type: "month",
        label: today.format('MMMM'),
    })

    month_map[today.format('YYYY-MM')] = true;

    // add entry for today
    arr.push({
        id: `${today.format('YYYY-MM-DD')}`,
        date: today.format('D'),
        month: today.format('MMM'),
        weekday: today.format('ddd'),
        weekday_full: today.format('dddd'),
        is_today: true,
        items: []
    });

    // now loop through the events
    filtered_items.forEach(evt => {
        const list_id = `${dayjs(evt.start).format('YYYY-MM-DD')}`;
        const time_string = `${buildEventTimeString(evt.start_datetime, evt.end_datetime)}`;
        const list_index = arr.findIndex(item => item.id === list_id);
        const parsed_location = parseEventLocation(evt?.location);
        const parse_conf_data = parseConferenceData(evt?.conference_data);
        let click_handler, meta = [];

        // if month not in map, add it
        if (!month_map[dayjs(evt.start).format('YYYY-MM')]) {
            arr.push({
                id: `month-${dayjs(evt.start).format('YYYY-MM')}`,
                type: "month",
                label: dayjs(evt.start).format('MMMM'),
            })
            month_map[dayjs(evt.start).format('YYYY-MM')] = true;
        }

        if (list_index !== -1) {
            if (evt?.type !== 'birthday') {
                click_handler = () => {
                    community.openPreviewOverlay({
                        type: 'event',
                        id: evt.id
                    })
                };
            } else {
                click_handler = () => {
                    community.openPreviewOverlay({
                        type: 'member',
                        id: evt.member_id
                    })
                };
            }
            arr[list_index].items.push({
                id: evt.id,
                title: evt.summary,
                start_datetime: evt.start_datetime,
                end_datetime: evt.end_datetime,
                time_string: `${time_string}`,
                all_day: evt.all_day,
                onClick: click_handler,
                subtitle: parsed_location.label
            });
        } else {
            if (evt?.type !== 'birthday') {
                click_handler = () => {
                    community.openPreviewOverlay({
                        type: 'event',
                        id: evt.id
                    })
                };
                meta.push({
                    type: 'group',
                    id: evt.group_id,
                    community_uid: community.uid
                });
            } else {
                click_handler = () => {
                    community.openPreviewOverlay({
                        type: 'member',
                        id: evt.member_id
                    })
                };
            }
            arr.push({
                id: list_id,
                date: dayjs(evt.start).format('D'),
                month: dayjs(evt.start).format('MMM'),
                weekday: dayjs(evt.start).format('ddd'),
                weekday_full: dayjs(evt.start).format('dddd'),
                items: [
                    {
                        id: evt.id,
                        title: evt.summary,
                        start_datetime: evt.start_datetime,
                        end_datetime: evt.end_datetime,
                        time_string: `${time_string}`,
                        all_day: evt.all_day,
                        // todo switch to meta renderer
                        meta: meta,
                        onClick: click_handler,
                        subtitle: parsed_location.label
                    }
                ]
            })
        }
    })

    return arr;
}

export function calendarFilterEvents(items = [], {active_filters, query}, {filters}, birthdays = []) {
    let ap = [...items, ...birthdays].map(evt => {
        return {
            ...evt,
            _month_name: dayjs(evt.start).format('MMM'),
            _day: dayjs(evt.start).format('D'),
            starts_at: "Starts at"
        }
    });

    if (active_filters.length > 0) {
        active_filters.forEach(filter => {
            const filter_data = filters.filter(f => f.id === filter.id)[0];
            if (filter_data) {
                /*
                if (filter.id === "unassigned" && !!filter.value) {
                    ap = ap.filter(p => p.assignee === "");
                } else if (filter.id === "group" && !!filter.value) {
                    const team_role_ids = all_teams.filter(t => filter.value.includes(t.id)).map(t => Object.keys(t.entities.roles)).flat();
                    ap = ap.filter(p => team_role_ids.includes(p.id));
                }

                 */
            } else {
                console.log('filter not found', filter);
            }
        })
    }

    if (query) {
        return ap.filter(r => {
            return r.summary.toLowerCase().includes(query.toLowerCase());
            // return r.name.toLowerCase().includes(query.toLowerCase()) || r?.assignee_data?.name.toLowerCase().includes(query.toLowerCase());
        })
    }

    return ap;
}

export const CalendarPageRenderer = (sections = [], props = {}) => {
    return <>
        {sections && <ScheduleView query={props.query || ""} items={sections}/>}
    </>
};

export function SubscribeToCalendar({member_id, community_uid, user_uid}) {
    const [state, setState] = useState('');
    const [link, setLink] = useState(null);
    const toasts = useToasts();

    useEffect(() => {
        if(link || state){
            return;
        }

        action();
    }, [link]);

    function action() {

        setState('loading');

        const body = {
            community_uid,
            member_id,
            user_uid
        };

        const res = (resp) => {
            if (!resp?.data?.link) {
                setState('error')
                toasts.addToast({text: "Error generating link", intent: "danger"})
                return
            }
            setState('success')
            //
            setLink(resp.data.link);
        };

        externalAuthFetch("/get-subscribe-link", res, res, "POST", body)
    }

    function copyToClipboard() {
        copyTextToClipboard(link, () => {
            toasts.addToast({text: "Link copied to clipboard", intent: "success"})
        })
    }

    if(state === 'error') {
        return <M3_A_Button size="lg" intent="danger" text="Error Generating Link" />
    }

    return <M3_A_Button size="lg" intent="primary" loading={state === 'loading'} text="Copy Subscribe Link" onClick={copyToClipboard}/>
}

export async function getBirthdayMap(community_uid) {
    const ref = doc(db, `communities/${community_uid}/meta/birthday-map`);
    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? doc.data() : null;
        });
}

// age can be 1 - 150
function getAgeSuffix(age) {
    // account for 13, 22...
    const last_digit = age.toString().slice(-1);
    if (last_digit === '1') {
        return 'st';
    } else if (last_digit === '2') {
        return 'nd';
    } else if (last_digit === '3') {
        return 'rd';
    } else {
        return 'th';
    }
}

export function parseBirthdayMapToEvents(map) {
    // structure is { [month_key]: { [day_key]: { [member_id]: { name, year } } } }
    const today = dayjs();
    const this_month = today.format('M');
    const this_date = today.format('D');

    let a = [];

    /*
    trying to get an array in this utils:
    { }
     */

    // let's loop through the months
    Object.keys(map).forEach(month_key => {
        // loop through the days
        Object.keys(map[month_key]).forEach(day_key => {
            // loop through the members
            Object.keys(map[month_key][day_key]).forEach(member_id => {
                const member_data = map[month_key][day_key][member_id];
                // start_datetime is an iso string
                let sdt = dayjs().month(month_key).date(day_key);
                // if is in the past, add a year
                if (dayjs(sdt).isBefore(today)) {
                    sdt = dayjs(sdt).add(1, 'year');
                }

                let b = "";
                if (member_data.year) {
                    const age = sdt.year() - member_data.year;
                    b = `${age}${getAgeSuffix(age)} `;
                }

                a.push({
                    icon: "gift",
                    type: "birthday",
                    start: sdt.format(DateFormat),
                    start_datetime: sdt.toISOString(),
                    id: `${member_id}-birthday`,
                    member_id,
                    end_datetime: sdt.toISOString(),
                    all_day: true,
                    summary: `${member_data.name}'s ${b}Birthday`
                })
            })
        })
    })


    // now sort starting with this date

    a.sort((a, b) => {
        return dayjs(a.start_datetime).isBefore(dayjs(b.start_datetime)) ? -1 : 1;
    })

    return a;
}