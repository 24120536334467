import React from 'react';
import {useCommunity} from "../../../../config/community";
import {SectionHeader} from "../../../../m3/_legacy_components/entity-grid";
import {useToasts} from "../../../../config/toasts";
import {MemberPoliciesList} from "../../member/policies/list";

export function MemberPreferencesPolicies({member}) {
    const community = useCommunity();
    const toasts = useToasts();

    return <div className="grid gap-6">
        <SectionHeader title="Policies"/>
        <MemberPoliciesList data={member} community={community} />
    </div>
}