import React, {useEffect} from 'react';
import styled from "styled-components";
import {useCommunity} from "../../../config/community";
import M3_C_Header from "../../components/header";
import {useNavigate} from "react-router-dom";
import {setDocumentTitle} from "../../utilities/set-document-title";
import {useIsMobile} from "../../hooks/is-mobile";

export function LayoutContentBlock({children, hidden, fill, top_padding = '', ...rest}) {
    return <div className={`${fill ? "" : "px-4"} ${hidden ? "hidden" : ""} ${top_padding}`} {...rest}>
        {children}
    </div>
}

export function LayoutContentWrapper({
                                         children,
                                         sticky,
                                         no_top_padding,
                                         no_bottom_padding,
                                         gap = "gap-6",
                                         directory,
                                         fill,
                                         entity,
                                         max_width = 'max-w-2xl'
                                     }) {
    return <div
        className={`flex ${gap} flex-col ${no_top_padding ? "" : entity ? "pt-2" : directory ? "pt-4" : "pt-4"} ${no_bottom_padding ? "" : "pb-24"} ${fill ? "max-w-[1440px]" : max_width} ${sticky ? "sticky top-14" : ""}`}>
        {children}
    </div>
}

export function LayoutFull({children, full_bleed, topbar}) {
    return <div className={`udk-layoutfull`}>
        {topbar}
        <div className="overflow-y-auto">
            {children}
        </div>
    </div>
}

function getMode() {
    const content_width = document.getElementById("content")?.offsetWidth;
    console.log("CONTENT WIDTH", content_width)

    if (content_width > 1300) {
        // enough for 2 columns
        return "multi";
    } else if (content_width > 1100) {
        return "two"
    } else if (content_width > 900) {
        return "split"
    } else {
        return "single"
    }
}

function getCanShowSidebar(preview_overlay) {
    const content_width = document.getElementById("content")?.offsetWidth;
   // console.log("preview_overlay",preview_overlay,content_width)
    return !!preview_overlay ? content_width > 1300 : content_width > 840;
}

const RightWrapperDiv = styled.div`
    max-width: 480px;
`;


const Wrapper = styled.div`
    border-bottom: 1px solid transparent;

    &:hover {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    ${props => props.is_scrolled_down && `
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.5);
   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    `}
`;

export function PageLayoutWrapper({children, topbar, topbar_offset = "", sticky}) {
    let classes = `flex flex-col `, classes_child = `flex flex-col`;
    if (sticky) {
        classes += " sticky top-0";
    }
    if (topbar) {
        classes_child += ` ${topbar_offset}`;
    }
    return <div className={classes}>
        {topbar}
        <div className={classes_child}>
            {children}
        </div>
    </div>
}

export function PageLayoutSection({
                                      children,
                                      gap = "",
                                      narrow,
                                      padding_x = "px-4 sm:px-6",
                                      padding_y = "py-2 sm:py-4",
                                      divider = true,
                                      overflow = "overflow-x-hidden"
                                  }) {
    let classes = `flex flex-col ${gap} ${overflow}`;
    if (padding_x) {
        if (narrow && padding_x === "px-4 sm:px-6") {
            classes += " px-2 sm:px-4";
        } else {
            classes += " " + padding_x;
        }
    }
    if (padding_y) {
        classes += " " + padding_y;
    }
    if (divider) {
        classes += " border-t border-gray-200";
    }
    return <div className={classes}>
        {children}
    </div>
}

export function PageLayoutBlock({children, padding_x = ""}) {
    return <div className={`${padding_x}`}>
        {children}
    </div>
}

export function CommunityContentPage({
                                         header,
                                         children,
                                         sidebar
                                     }) {
    const navigate = useNavigate();

    return <CommunityPageLayout sidebar={sidebar} layout="sidebar">
        <PageLayoutWrapper topbar={<M3_C_Header {...{
            title: header.title,
            sticky: true,
            size: "base",
            show_background: true,
            onBack: () => {
                navigate(-1);
            },
            actions: header.actions || []
        }} />}>
            {children}
        </PageLayoutWrapper>
    </CommunityPageLayout>
}

export function CommunityIndexPage({
                                       desktop_slim,
                                       sidebar,
                                       header,
                                       children
                                   }) {
    const community = useCommunity();
    const navigate = useNavigate();
    const is_mobile = useIsMobile();

    const theme = community.active_theme;

    useEffect(() => {
        // set background color on body
        document.body.style.backgroundColor = theme?.background;
        return function () {
            document.body.style.backgroundColor = "";
        }
    }, [community.active_theme]);

    if (is_mobile) {
        let main_styles = {};
        main_styles.backgroundColor = theme?.background;
        if (theme?.mode === "dark") {
            // if light mode, add shadow based on values in theme like shadow_color, shadow_opacity, and shadow_blur
            main_styles.border = `1px solid ${theme?.border_color}`;
        } else {
            // if dark mode, add border based on values in theme
            main_styles.boxShadow = theme?.box_shadow;
        }

        return <div className="min-h-screen flex flex-col relative z-0" style={main_styles}>
            {header && <M3_C_Header {...{
                ...header,
                title: header.title,
                padding_top: "pt-2",
                size: "xl",
                sticky: true,
                z_index: "z-[5]",
                onBack: header?.can_go_back ? () => {
                    navigate(-1);
                } : null,
                actions: header.actions || [],
                show_background: false,
                default_icon_variant: "inherit",
                title_sx: {
                    color: theme?.background_text,
                    display: "inline"
                },
                icon_sx: {
                    color: theme?.background_text_60
                },
                icon: header.icon
            }} />}
            <div className="sticky z-10 pb-20 pt-1 rounded-t-xl flex-grow overflow-x-hidden" style={{
                borderColor: theme?.background_divider,
                backgroundColor: theme?.foreground,
                color: theme?.foreground_text,
                zIndex: 25
            }}>
                {children}
            </div>
        </div>
    }

    let header_extras = {};

    if (desktop_slim) {
        header_extras.padding_bottom = "pb-1";
        header_extras.default_height = "h-10";
        header_extras.top_padding = "pt-10";
    }

    const layout = !!sidebar ? "sidebar" : "full";

    return <CommunityPageLayout layout={layout} sidebar={sidebar}>
        <PageLayoutWrapper topbar={!header ? null : <M3_C_Header {...{
            ...header,
            ...header_extras,
            title: header.title,
            sticky: true,
            show_background: true,
            size: "base",
            onBack: header?.can_go_back ? () => {
                navigate(-1);
            } : null,
            actions: header.actions || []
        }} />} topbar_offset={desktop_slim ? "-mt-10" : "-mt-12"}>
            <div className={`${!!header ? (desktop_slim ? "pt-10" : "pt-12") : ""}`}>
                {children}
            </div>
        </PageLayoutWrapper>
    </CommunityPageLayout>
}

export function CommunityEntityPage({
                                        is_loading,
                                        sidebar,
                                        modals,
                                        tabs,
                                        card,
                                        children,
                                        header = {
                                            title: "Member",
                                            actions: []
                                        }
                                    }) {
    const community = useCommunity();
    const navigate = useNavigate();

    useEffect(() => {
        if (header?.title) {
            setDocumentTitle(header.title, `${community.profile.name}`);
        }
    }, [community.profile.name, header?.title]);

    return <CommunityPageLayout topbar={<M3_C_Header {...{
        has_background: false,
        watch_scroll_id: "main",
        scroll_snap_px: 132,
        title: header.title,
        show_background: true,
        sticky: true,
        size: "base",
        onBack: () => {
            navigate(-1);
        },
        actions: header.actions || []
    }} />} sidebar={sidebar} layout="main-and-sidebar">
        {card}
        {tabs}
        {children}
        {modals}
    </CommunityPageLayout>
}

export function CommunityPageLayout({layout = "full", topbar = null, sidebar = null, children}) {
    const community = useCommunity() || {};
    const multiple_children = React.Children.count(children) > 1;
    const is_mobile = useIsMobile();
    const [can_show_sidebar, setCanShowSidebar] = React.useState(getCanShowSidebar(community.preview_overlay));

    React.useEffect(() => {
        function handleResize() {
                setCanShowSidebar(getCanShowSidebar(community.preview_overlay));
        }

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [community.preview_overlay])

    const has_right_sidebar = !!community.preview_overlay;

    const remaining_children = multiple_children ? children.slice(1) : null;

    const bottom_padding = is_mobile ? "pb-16" : "";

    const window_width = window.innerWidth;

    const hide_sidebar_due_to_preview = !!community.preview_overlay && window_width < 1300;

    const show_sidebar = can_show_sidebar && !hide_sidebar_due_to_preview;

    const renderSidebar = () => {
        return <div className="border-l border-gray-200 h-full pl-4">
            {sidebar}
        </div>;
    };

    return <div className={`flex-grow h-full relative ${bottom_padding}`} style={{
        minHeight: '1rem',
    }}>
        {topbar}
        <div className={`mx-auto max-w-[1200px] h-full grid ${show_sidebar}`} style={{
            gridTemplateColumns: show_sidebar ? "1fr 400px" : "1fr"
        }}>
            <div className="h-full max-w-[800px] w-screen sm:w-auto min-w-screen sm:min-w-[400px]">
                {children}
            </div>
            {show_sidebar && renderSidebar()}
        </div>
    </div>;
}

// need to update when the second sidebar shows up
export function LayoutStandard({children}) {
    const community = useCommunity() || {};
    const multiple_children = React.Children.count(children) > 1;
    const [mode, setMode] = React.useState(getMode());

    React.useEffect(() => {
        function handleResize() {
            setMode(getMode());
        }

        setTimeout(() => {
            setMode(getMode());
        }, 50)

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [community.preview_overlay])

    const has_right_sidebar = !!community.preview_overlay;

    const remaining_children = multiple_children ? children.slice(2) : null;

    // mode==="multi"?"1fr":"minmax(300px, 400px)"
    return <div className={`flex-grow`}>
        <div className={`grid h-full`} style={{
            gridTemplateColumns: mode === "split" ? "60% auto" : mode === "multi" ? "850px 1fr" : mode === "two" ? "750px 1fr" : "1fr",
        }}>
            {multiple_children ? children[0] : children}
            {mode !== 'single' && <RightWrapperDiv className="border-l border-gray-200" gridTemplateColumns={"1fr"}>
                {multiple_children ? children[1] : null}
            </RightWrapperDiv>}
        </div>
        {remaining_children}
    </div>
}

export function LayoutComplex({children, full_bleed, toggleSidebar, scrollable, show_sidebar = true, sidebar}) {
    return <div className={`udk-layoutfull flex-grow ${scrollable ? "overflow-y-auto" : ""}`}>
        <div id="scrollable-inner"
             className="overflow-visible h-screen overflow-y-auto udk-scrollable udk-scrollable-inner" style={{}}>
            <div className="w-full" style={{maxWidth: '1260px'}}>
                {children}
            </div>
        </div>
    </div>
}