import React from "react";
import {
    BrowserRouter,
    Routes,
    Route, Outlet, useLocation, Navigate
} from "react-router-dom";
import UnaverseHomePage from "./routes/unaverse/memberships";
import {ManagePage} from "./routes/manage";
import {useAuth} from "./config/auth";
import SignInPage from "./routes/auth/sign-in";
import CreateAccountPage from "./routes/auth/create-account";
import SignOutPage from "./routes/auth/sign-out";
import {useUnaverse} from "./config/unaverse";
import {FrameLoader} from "./m3/_legacy_components/app-frame/loader";
import {UnaversePage} from "./routes/unaverse";
import ResetPasswordPage from "./routes/auth/reset-password";
import ForgotPasswordPage from "./routes/auth/forgot-password";
import {CommunityNotFound} from "./routes/unaverse/community/not-found";
import GeneralLinkPage from "./routes/auth/link";
import {UserPolicyGate} from "./m3/_legacy_components/policy-gates/user";
import ScrollToTop from "./m3/utilities/scroll-to-top";
import {CommunityLinkPage} from "./routes/auth/link/community";
import RedirectHandler from "./m3/utilities/redirect-handler";
import {PlaygroundPage} from "./routes/misc/playground";
import M3Playground from "./m3/playground";
import CommunityExternalPage from "./routes/community/external";
import {MarketingPage} from "./m3/_legacy_components/app-frame/marketing";
import CreateCommunityPage from "./routes/unaverse/create-community";
import {CommunityRouter} from "./community-router";

const hide_frame = [
    "/sign-in",
    "/sign-out",
    "/create-account",
    '/create-community',
    "/forgot-password",
];

function OuterWrapper({children}) {
    const location = useLocation();
    const {user} = useAuth();
    const unaverse = useUnaverse();
    const is_logged_in = !!user;
    const app_frame_layout = hide_frame.includes(location.pathname) ? "empty" : "standard";

    let c = <>
        {children}
        <Outlet/>
    </>;

    if (!is_logged_in) {
        if (app_frame_layout === "empty") {
            return c;
        }

        return <MarketingPage key="main-content">
            {c}
        </MarketingPage>
    }

    let a = <>
        {c}
        {unaverse.loader > 0 && <FrameLoader onReset={() => unaverse.setLoader(0)} progress={unaverse.loader}/>}
        <UserPolicyGate user={unaverse.user}/>
    </>

    if (app_frame_layout === "empty") {
        return a;
    }

    return <MarketingPage>
        {a}
    </MarketingPage>
}

function getFlags() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    return {
        playground: urlParams.has('playground')
    }
}

export default function AppFrame() {
    const {user, __ready} = useAuth();
    const is_logged_in = !!user;

    const flags = getFlags();

    if (flags.playground) {
        return <M3Playground/>
    }

    // take debug from url param ?debug=true
    const debug = window.location.search.includes('debug=true');

    if (!__ready) {
        return null;
    }

    return <BrowserRouter>
        <ScrollToTop/>
        <RedirectHandler/>
        <Routes>
            <Route path="playground" element={<PlaygroundPage/>}/>

            <Route path="manage/*" element={<ManagePage/>}/>

            <Route path="/c/:domain/not-found" element={<CommunityNotFound/>}/>

            <Route path="/c/:domain/join-community-flow" element={<CommunityLinkPage/>}/>

            <Route path="/c/:domain/external" element={<CommunityExternalPage/>}/>

            <Route path="/c/:domain/*" element={<CommunityRouter debug={debug}/>} />

            <Route path="sign-in" element={is_logged_in ? <Navigate to="/" replace/> : <SignInPage/>}/>
            <Route path="forgot-flow" element={is_logged_in ? <Navigate to="/" replace/> : <ForgotPasswordPage/>}/>
            <Route path="reset-password" element={is_logged_in ? <Navigate to="/" replace/> : <ResetPasswordPage/>}/>
            <Route path="sign-out" element={<SignOutPage/>}/>
            <Route path="create-account" element={<CreateAccountPage/>}/>

            <Route path="link" element={<GeneralLinkPage/>}/>

            <Route path="/" element={<OuterWrapper/>}>

                <Route
                    path="communities"
                    element={<Navigate to="/" replace/>}
                />

                <Route path="/" element={is_logged_in ? <UnaverseHomePage/> : <UnaversePage/>}/>

                <Route path="/create-community" element={is_logged_in ? <CreateCommunityPage/> :
                    <Navigate to={"/create-account?ref=create-community"} replace/>}/>

                <Route
                    path="memberships"
                    element={<Navigate to="/" replace/>}
                />

                <Route
                    path="*"
                    element={<Navigate to="/" replace/>}
                />
            </Route>
        </Routes>
    </BrowserRouter>
};