import React, {useState} from 'react';
import {useCommunity} from "../../../config/community";
import {useToasts} from "../../../config/toasts";
import MetaHeader from "../../../m3/_legacy_components/meta-header";
import Button from "../../../m3/_legacy_components/button";
import Field from "../../../m3/_legacy_components/form/field";
import HandleSelector from "../../auth/create-account/handle-selector";
import {slugify} from "../../../m3/utilities/slugify";
import {getNameMeta} from "../../auth/create-account";
import {useNavigate} from "react-router-dom";
import {api_createPosition} from "../../../../api/positions/create";
import {ModalBody, ModalContentBlock, ModalFooter, ModalHeader} from "../../../m3/_legacy_components/preview-overlay";
import {PrettyCheckbox} from "../../../m3/_legacy_components/toggle";
import {logEngagementEvent} from "../../../config/firebase-setup";

// Groups help members stay connected and organized.
// If your group is closed, only members you add can view group contents.
export function AddPosition({onClose}) {
    const toasts = useToasts();
    const community = useCommunity();
    const navigate = useNavigate();

    const [state, setState] = useState("");
    const [name, setName] = useState("");
    const [handle_name, setHandleName] = useState("");
    const [purpose, setPurpose] = useState("");
    const [create_another, setCreateAnother] = useState(false);
    const [handle, setHandle] = useState("");
    const [assignee, setAssignee] = useState("");

    const [flags, setFlags] = useState({
        _customizing: false
    })

    const can_create_position = name.length > 1 && handle;

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    function reset() {
        setName("");
        setPurpose("");
        setAssignee("");
        setHandleName("");
        setHandle("");
        setFlags({...flags, _customizing: false})
        setState("");
    }

    function handleCreate() {
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,

            name,
            purpose,
            handle,
            assignee,
            emoji: "",
            profile_picture: "",
            profile_picture_color: "",
        };
        setState("loading");
        api_createPosition(payload)
            .then((response) => {
                setState("");
                if (!response.ok || response?.ok === 'no') {
                    console.error("ERROR", response);
                    toasts.addToast({text: "something went wrong", intent: "danger"})
                    return;
                } else if (response?.data?.id) {
                    logEngagementEvent("create_position", {
                        content_id: response?.data?.id
                    }, community.uid);
                    toasts.addToast({text: "Position created", intent: "success"})
                    if (create_another) {
                        reset();
                        return;
                    }
                    navigate(`/c/${community.domain}/position/${response.data.id}?ref=created-position`)
                }
            })
    }

    return <div className="" style={{minHeight: '16rem'}}>
        <ModalHeader title={"Create Position"} onClose={handleClose}/>
        <ModalBody>
            <ModalContentBlock>
                <div className="pb-5">
                    <p className="text-sm text-gray-600">Positions are for leaders in your community, assign members to
                        automate access and create an org chart.</p>
                </div>
                <div className="space-y-4">
                    <Field autoFocus onBlur={() => setHandleName(slugify(name))} value={name}
                           onChange={(fid, val) => setName(val)} label="Name" placeholder="Treasurer"/>

                    {state !== "loading" && <div className={`hidden`}>
                        {getNameMeta(handle, flags._customizing, () => setFlags({
                            ...flags,
                            _customizing: true
                        }), "role")}

                        <HandleSelector default_text={handle_name} setHandle={(hh) => {
                            setHandle(hh)
                        }} type='role' scope={"community"} scope_id={community.uid}/>
                    </div>}

                    <Field input_props={{community_uid: community.uid}} optional label="Assignee" value={assignee}
                           onChange={(a, b) => {
                               if (!b) {
                                   setAssignee("");
                                   return;
                               }
                               setAssignee(b);
                           }} type="assignee" id="assignee"/>
                    <Field optional value={purpose} onChange={(fid, val) => setPurpose(val)} label="Purpose"
                           placeholder="What is this position responsible for?"/>
                </div>
            </ModalContentBlock>
        </ModalBody>
        <ModalFooter right={<Button loading={state === "loading"} intent="primary" disabled={!can_create_position}
                                    text="Create" onClick={handleCreate}/>}
                     left={<div>
                         <PrettyCheckbox active={create_another} onClick={() => setCreateAnother(!create_another)}
                                         label="Create another position"/>
                     </div>}/>
    </div>
}