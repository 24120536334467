import React, {useState} from "react";
import {SectionHeader} from "../../../../../m3/_legacy_components/entity-grid";
import Field, {FieldLabel} from "../../../../../m3/_legacy_components/form/field";
import Button from "../../../../../m3/_legacy_components/button";
import SmartRulesActionsManager from "../../../../../m3/_legacy_components/smart-rules";
import {TextAction} from "../../../../auth/sign-in";
import {authFetch} from "../../../../../../api/network";
import {useToasts} from "../../../../../config/toasts";
import {useCommunity} from "../../../../../config/community";

export function parseStringifiedJSON(str, fallback = {}) {
    if (!str) return fallback;
    try {
        return JSON.parse(str);
    } catch (e) {
        return fallback;
    }
}


async function updateGroupSettings(payload) {
    return await new Promise((resolve, reject) => {
        const r = (resp) => {
            resolve(resp);
        }
        authFetch("/groups/update-forum-settings", r, r, "POST", {payload});
    });
}

export function GMMForum({
                             setData,
                             cb = () => {
                             },
                             onSaved,
                             id,
                             group
                         }) {
    const community = useCommunity();
    const [default_post_attachments_folder, setDefaultPostAttachmentsFolder] = useState(group.default_post_attachments_folder || '')
    const [email_attachment_rules, setEmailAttachmentRules] = useState(parseStringifiedJSON(group?.email_attachment_rules, []))
    const [changed, setChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const toasts = useToasts();

    function saveChanges() {
        setLoading(true);

        toasts.addToast({
            text: "Updating",
            intent: "info",
        });

        updateGroupSettings({
            group_id: id,
            community_uid: community.uid,
            member_id: community.member_id,
            default_post_attachments_folder,
            email_attachment_rules: JSON.stringify(email_attachment_rules)
        })
            .then((resp) => {
                setLoading(false);
                setChanged(false);
                toasts.addToast({
                    text: "Updated",
                    intent: "success",
                });
                onSaved();
            })
    }

    const save_btn = <Button loading={loading} intent="success" size="small" onClick={() => {
        saveChanges();
    }} disabled={!changed}>Save</Button>

    return <div className={`grid gap-6`}>
        <div>
            <SectionHeader actions={save_btn} title="New Posts"/>
            <div className="pt-2 grid gap-4">
                <Field placeholder="Select Folder" input_props={{
                    drive_id: group.folder_id
                }} type="drive-folder" id="drive-folder" onChange={(id, v) => {
                    setDefaultPostAttachmentsFolder(v);

                    setChanged(true);
                }} onBlur={() => {
                }} value={default_post_attachments_folder} label="Default Post Attachments Folder"/>

                <div>
                    <FieldLabel corner_hint={<TextAction text="Regex Hints" onClick={() => {
                        const url = "https://medium.com/factory-mind/regex-tutorial-a-simple-cheatsheet-by-examples-649dc1c3f285";
                        window.open(url, '_blank', 'noopener noreferrer');
                    }}/>} label="Email Attachment Rules"/>
                    <div>
                        <SmartRulesActionsManager init_rules={email_attachment_rules} rules_context="email-attachment"
                                                  context={{
                                                      drive_id: group.folder_id
                                                  }} onChange={(nv) => {

                            setEmailAttachmentRules(nv);
                            setChanged(true);
                        }}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
}