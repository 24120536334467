import styled from "styled-components";
import React from "react";
import {BasicSelect} from "../basic-select";
import {FieldInput} from "../field";
import {data_months_arr, data_months_map} from "../../../../../common/data/data/months";

function getLayoutFromTimeFormat(tf) {
    if(tf==='MM/DD/YYYY') {
        return '2fr 1fr 1.5fr'
    } else if(tf==='UU/YYYY') {
        return '3fr 2fr'
    } else if(tf==='MM/YYYY') {
        return '3fr 2fr'
    } else if(tf==='YYYY') {
        return '1fr'
    } else {
        return '1fr 2fr 1.5fr'
    }
}

const BDaySelectorOuter = styled.div`
    display: grid;
    grid-template-columns: ${props=>getLayoutFromTimeFormat(props.time_format)};
    grid-gap: 8px;
`;

function dateIsInvalid(date) {
    // check if is number
    if(!date||isNaN(date)) {
        return false;
    }
    return date>31||date<1;
}

function yearIsInvalid(year) {
    if(!year||isNaN(year)) {
        return false;
    }
    return !(year>1500&&year<2100);
}

function getListboxValue(v,t,offset=1) {
    // can't do !v because then v==0 is taken out
    if(v===null||v==='') {
        return null;
    }
    if(t==='months') {
        // becasue month 0 === null selector

        if(isNaN(v)) {
            return null;
        }
        const item = data_months_map[(v+offset)];
        if(!item) {
            return {
                value: v,
                label: "-"
            }
        }
        return {
            value: v,
            label: item.label
        }
    } else {
        return {
            value: v?v:null,
            label: !v?"":seasons[v].label
        }
    }
}

export const BirthdaySelector = ({value, disabled, month_offset = 1, autoFocus = false, time_format='MM/DD/YYYY', onChange}) => {
    let new_birthday = {...value};
    const split_char = time_format.includes('/') ? '/' : '.';
    const order = time_format.split(split_char);
    return <BDaySelectorOuter time_format={time_format} className={`birthday-selector ${time_format}`}>
        {order.map((item,k)=>{
            switch (item) {
                case 'MM':
                    return <div key={'month'} className='month'>
                        <BasicSelect placeholder="Month" disabled={disabled} onChange={(v,t,e)=>{
                            console.log("CHANGE MONTH",v)
                            if(!v||v.value===null) {
                                new_birthday.month = null;
                                onChange(new_birthday);
                            } else {
                                new_birthday.month = parseInt(v.value-month_offset);
                                onChange(new_birthday);
                            }
                            // ideally focus here on the next item
                        }} value={getListboxValue(new_birthday?new_birthday.month:null,'months',month_offset)} searchable options={data_months_arr} />
                    </div>;
                case 'UU':
                    return <div key={'season'} className="season">
                        <BasicSelect placeholder="Season" disabled={disabled} onChange={(v)=>{
                            if(!v) {
                                new_birthday.season = '';
                                onChange(new_birthday);
                            } else {
                                new_birthday.season = v.value;
                                onChange(new_birthday);
                            }
                        }} value={getListboxValue(new_birthday?new_birthday.season:null, 'season')} searchable options={seasons} />
                    </div>
                case 'DD':
                    return <div key={'date'} className='date'>
                        <FieldInput autoFocus={k===0&&autoFocus} disabled={disabled} type="number" min={0} max={31} onChange={(fid,v)=>{
                            if(v) {
                                new_birthday.date = parseInt(v);
                                onChange(new_birthday);
                            } else {
                                new_birthday.date = null;
                                onChange(new_birthday);
                            }

                        }} onBlur={()=>{
                            if(dateIsInvalid(new_birthday.date)) {
                                alert('Please enter a valid date');
                                new_birthday.date = null;
                                onChange(new_birthday);
                            }
                        }} placeholder={"Day"} value={new_birthday&&new_birthday.date?new_birthday.date:''}/>
                    </div>;
                case 'YYYY':
                    return <div key={'year'} className='year'>
                        <FieldInput disabled={disabled} input_props={{
                            min: 1500,
                            max: 2100
                        }} autoFocus={k===0&&autoFocus} type="number" onChange={(fid,v)=>{
                            console.log("ONCHA",v)
                            new_birthday.year = parseInt(v);
                            onChange(new_birthday);
                        }} onBlur={()=>{
                            if(yearIsInvalid(new_birthday.year)) {
                                alert('Please enter a valid year');
                                new_birthday.year = null;
                                onChange(new_birthday);
                            }
                        }} placeholder={"Year"} value={new_birthday&&new_birthday.year?new_birthday.year:''}/>
                    </div>;
                default:
                    return null;
            }
        })}
    </BDaySelectorOuter>
};