import {authFetch} from "../../../../api/network";
import {
    collection,
    doc,
    documentId,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    startAfter,
    where
} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {getManyDocuments} from "../../../config/community";
import {convertFromRaw} from 'draft-js';
import {utils_dates_format} from "../../../../common/utilities/dates";

export async function getSendStatus(community_uid, post_id, mailgun_message_id, list_id) {
    return await new Promise((resolve, reject) => {
        const res = (data) => {
            resolve(data.data);
        };
        const payload = {
            community_uid: community_uid,
            list_id: list_id,
            message_id: mailgun_message_id
        };
        authFetch("/messages/get-status", res, res, "POST", {payload});
    })
}

export async function getPostDeliveryData(community_uid, post_id, mailgun_message_id) {


    const ref = doc(db, 'community_content', community_uid, 'emails', mailgun_message_id);
    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? {...doc.data(), id: doc.id} : null;
        });
}

async function getPostIds(community_uid, id, ref, page, per_page = 25, type = 'member') {

    if (ref && page > 0) {
        const ref3 = query(collection(db, 'community_content', community_uid, 'inboxes', `${type}-${id}`, 'posts'), where('type', '==', 'post'), orderBy('ts', 'desc'), startAfter(ref), limit(per_page));
        return await getDocs(ref3)
            .then(snap => {
                return snap.docs;
            });
    }
    const ref2 = query(collection(db, 'community_content', community_uid, 'inboxes', `${type}-${id}`, 'posts'), where('type', '==', 'post'), orderBy('ts', 'desc'), limit(per_page));
    return await getDocs(ref2)
        .then(snap => {
            return snap.docs;
        });
}

export async function getGroupPostsForTopic(community_uid, group_id, series_id, last_page_ref, pg, per_page) {
    let col = collection(db, 'community_content', community_uid, 'posts');

    let q;
    if (last_page_ref) {

        q = query(col,
            where('context_id', '==', group_id), where('series', '==', series_id), where('type', '==', 'post'), orderBy('created_at', 'desc'), startAfter(last_page_ref), limit(per_page));
    } else {
        q = query(col,
            where('context_id', '==', group_id),
            where('series', '==', series_id),
            where('type', '==', 'post'),
            orderBy('created_at', 'desc'),
            limit(per_page));
    }
    const snap = await getDocs(q);
    const docs = snap.docs;
    const last_ref = docs.length > 0 ? docs[docs.length - 1] : null;

    return {
        last_ref,
        posts: docs.map(d => {
            const p = d.data();
            return {
                id: d.id,
                formatted_date: utils_dates_format(p.ts),
                data: processFinalData(p)
            }
        })
    }
}

export async function getGroupInboxPosts(community_uid, group_id, series_id, member_id, ref, page, inbox, per_page = 25) {
    const recent_posts_docs = await getPostIds(community_uid, group_id, ref, page, per_page, 'group');

    const recent_posts = recent_posts_docs.map(d => {
        const dt = d.data();
        return {
            ...dt,
            seen: !!dt.seen[member_id],
            id: d.id
        }
    });

    const last_ref = recent_posts.length > 0 ? recent_posts_docs[recent_posts_docs.length - 1] : null;

    const ids = recent_posts.map(rp => rp.id);

    const {posts_data} = await getPostsData(ids, community_uid);

    return {
        posts: recent_posts.map(rp => {
            return {
                ...rp,
                formatted_date: utils_dates_format(rp.ts),
                data: processFinalData(posts_data[rp.id])
            }
        }), last_ref
    }
}

function processFinalData(data) {
    // add content state
    let obj = {...data};
    if (data.raw) {
        obj.content_state = convertFromRaw(parseRawContent(data.raw));
    }
    return obj;
}

export async function getMemberInboxPosts(community_uid, member_id, ref, page, inbox, per_page = 25, type = "member") {
    const recent_posts_docs = await getPostIds(community_uid, member_id, ref, page, per_page, type);

    const recent_posts = recent_posts_docs.map(d => {
        return {
            ...d.data(),
            id: d.id
        }
    });

    const last_ref = recent_posts.length > 0 ? recent_posts_docs[recent_posts_docs.length - 1] : null;

    const ids = recent_posts.map(rp => rp.id);

    const {posts_data} = await getPostsData(ids, community_uid);

    return {
        posts: recent_posts.map(rp => {
            return {
                ...rp,
                formatted_date: utils_dates_format(rp.ts),
                data: processFinalData(posts_data[rp.id])
            }
        }), last_ref
    }
}

export async function getPostsData(ids, community_uid) {
    return await getManyDocuments("community_content", community_uid, "posts", documentId(), ids).then(result => {
        let obj = {};
        result.forEach(d => {
            obj[d.id] = d.data();
        });
        return {posts_data: obj};
    })
}

export async function getPostsMetadata(cid, mid) {
    const ref = doc(db, 'community_content', cid, 'inboxes', mid);
    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? doc.data() : null;
        });
}

export function parseRawContent(raw) {
    if(!raw) {
        return {};
    }

    if(typeof raw === 'string') {
        return JSON.parse(raw);
    }

    return raw;
}

export function transformPostsToMessages(posts, community_uid) {
    return posts.map(post => {
        return {
            id: post?.id || post?.data?.id || "",
            subject: post?.data?.subject || "",
            context: {
                community_uid,
                type: post?.data?.context_type || "",
                id: post?.data?.context_id || "",
            },
            community_uid,
            raw: post,
            raw_content: parseRawContent(post?.data?.raw),
            read: !!post.seen,
            images: post?.data?.images || [],
            polls: post?.data?.polls || [],
            attachments: post?.data?.attachments || [],
            has_image: post?.data?.images.length > 0,
            has_poll: post?.data?.polls?.length > 0,
            has_attachment: post?.data?.attachments.length > 0,
            date_formatted: post?.formatted_date?.short_date || "",
            author_image: post?.data?.author_image || "",
            author_id: post?.data?.author_id || "",
            author_name: post?.data?.author_name || "",
            layout: 'standard',
            last_reply_at: post?.data?.last_reply || null,
            repliers: Object.keys(post?.data?.replies_members || {}),
            reply_count: post?.data?.replies_count || 0,

            reactions: post?.data?.reactions || {},
            reactions_counts: post?.data?.reactions_counts || {},
            reactions_members: post?.data?.reactions_members || {},
            reactions_order: post?.data?.reactions_order || [],
        }
    })
}