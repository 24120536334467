import React, {useEffect, useRef, useState} from "react"
import './styles.css';
import {
    CommunityIndexPage,
    LayoutContentBlock,
    LayoutContentWrapper,
    LayoutFull, PageLayoutBlock, PageLayoutSection
} from "../../../m3/_legacy_components/app-frame/layouts";
import EntityCard from "../../../m3/_legacy_components/entity-card";
import {db} from "../../../config/setup-firestore";
import {collection, where, startAt, query, orderBy, limit, getDocs, startAfter} from "firebase/firestore";
import {useCommunity} from "../../../config/community";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {buildGroupProfileCard} from "../group/build-group-card";
import {useUnaverse} from "../../../config/unaverse";
import {SectionHeader} from "../../../m3/_legacy_components/entity-grid";
import Button from "../../../m3/_legacy_components/button";
import {RectangleGroupIcon} from "@heroicons/react/24/outline";
import {saveItemToLocalStorage} from "../../../m3/utilities/localstorage";
import {setDocumentTitle} from "../../../m3/utilities/set-document-title";
import {TextAction} from "../../auth/sign-in";
import EmptyState from "../../../m3/_legacy_components/empty-state";
import {m3_icon_map} from "../../../m3/icons/icon-map";

async function getAllYourGroups(cid, mid) {
    const col = collection(db, 'community_entities', cid, 'groups');
    let q;
    q = query(col, where(`user_uids.${mid}`, '==', true), limit(500));
    const snap = await getDocs(q);
    const data = snap.docs.map(doc => {
        return {
            ...doc.data(),
            id: doc.id
        }
    });
    return data;
}

async function getGroups(cid, lim = 24, start_after) {
    const col = collection(db, 'community_entities', cid, 'groups');
    let q;
    if (start_after) {
        q = query(col, orderBy("last_activity"), startAfter(start_after), limit(lim));
    } else {
        q = query(col, orderBy("last_activity"), limit(lim));
    }
    const snap = await getDocs(q);
    const data = snap.docs.map(doc => {
        return {
            ...doc.data(),
            id: doc.id
        }
    });
    return {
        data,
        last_ref: snap.empty ? null : snap.docs[snap.docs.length - 1],
        has_more: data.length === lim
    }

}

const renderGroups = (groups, community, unaverse, navigate, flags, loading, dedup = [], preview_len = 3) => {

    if (loading && !groups.length) {
        return Array.from(Array(preview_len).keys()).map(num => {
            return <EntityCard onClick={() => {
            }} key={num}
                               config={{
                                   loading: true
                               }}/>
        })
    }
    return groups.map(it => {
        if (dedup.includes(it.id)) {
            return null;
        }
        let spec = buildGroupProfileCard(it, unaverse, community, flags);
        if (spec.icon.type === 'emoji') {
            spec.icon.top_offset = "-mt-3";
        } else if(spec.icon.type === 'text' || spec.icon.type === 'image') {
            spec.icon.top_offset = "-mt-3";
        }

        spec.header_top_padding = "pt-0";
        spec.buttons = [];
        return <EntityCard onClick={() => navigate(`/c/${community.domain}/space/${it.id}`)} key={it.id}
                           config={spec}/>
    })
}

function DiscoverGroups({groups, dedup, unaverse, flags, community, navigate, loadMore, has_more, loading}) {
    return <div>
        <div className="grid gap-3 pt-1" style={{gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))'}}>
            {renderGroups(groups, community, unaverse, navigate, flags, loading, dedup)}
        </div>
        <div className="flex justify-center p-4 h-10">
            {has_more && !loading && <Button text="Load more" onClick={() => loadMore()}/>}
        </div>
        {!loading&&!has_more&&groups.length===0&&<EmptyState icon={<RectangleGroupIcon />} title="No Spaces found" />}
    </div>
}

function GroupsYouModerate({groups, flags, unaverse, community, navigate, loading}) {
    return <div>
        <div className="grid gap-3 pt-1" style={{gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))'}}>
            {renderGroups(groups, community, unaverse, navigate, flags, loading)}
        </div>
    </div>
}

function YourGroups({groups, unaverse, preview_len, flags, community, navigate, loading}) {
    return <div>
        <div className="grid gap-3 pt-1" style={{gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))'}}>
            {renderGroups(groups, community, unaverse, navigate, flags, loading, [], preview_len)}
        </div>
    </div>
}

const gsort = (a, b) => {
    return b.last_activity - a.last_activity || a.name.localeCompare(b.name);
}

export default function GroupsPage() {
    const unaverse = useUnaverse();
    const community = useCommunity();
    let [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [your_groups, setYourGroups] = useState([]);
    const [explore_groups, setEG] = useState([]);
    const [has_more, setHasMore] = useState([]);
    const start_after = useRef(null);

    setDocumentTitle(`Spaces`, `${community.profile.name}`);

    let per_page = 25;
    useEffect(function () {
        const action = searchParams.has('action')

        if (!!action && searchParams.get('action') === 'create') {
            community.openEditOverlay({
                type: 'add-group',
                size: 'medium'
            })
            navigate(location.pathname);
        }
    }, [searchParams, location])

    useEffect(function () {
        start_after.current = null;
        loadGroups();
        getAllYourGroups(community.id, community.member_id)
            .then(res => {
                setYourGroups(res)
            })
    }, [community.community_id, community.member_id])

    function loadGroups() {
        getGroups(community.id, per_page, start_after.current)
            .then(resp => {

                let nv;

                if (!start_after.current) {
                    nv = [...resp.data];
                } else {
                    nv = [...explore_groups, ...resp.data];
                }
                saveItemToLocalStorage(`${community.id}_group_names_index`, nv.map(a => a.name))
                setEG(nv);
                start_after.current = resp.last_ref;

                setHasMore(resp.has_more);
                setLoading(false);
            })
    }

    function loadMore() {
        setLoading(true);
        setHasMore(false);
        loadGroups();
    }

    let sections = [];

    let groups_you_moderate = [], groups_you_do_not_moderate;

    const flags = {
        minimal: true,
        show_title_icon: true,
        cover_photo_size: "_medium",
        all_names: explore_groups.map(a => a.name),
    };

    if (community?.member?.group_ids.length > 0) {
        groups_you_moderate = your_groups.filter(g => g.moderators.user_uids[community.member_id]);
        groups_you_do_not_moderate = your_groups.filter(g => !g.moderators.user_uids[community.member_id]);
        const split_organizer_groups = community?.member?.group_ids.length > 3;

        if (groups_you_moderate.length > 0 && split_organizer_groups) {
            sections.push({
                title: "Spaces you organize",
                comp: <GroupsYouModerate loading={loading} flags={flags} groups={groups_you_moderate.sort(gsort)}
                                         unaverse={unaverse} navigate={navigate} community={community}/>
            })
        }

        sections.push({
            title: "Your Spaces",
            comp: <YourGroups preview_len={Object.keys(community.member.group_ids).length} loading={loading}
                              flags={flags} groups={split_organizer_groups ? groups_you_do_not_moderate.sort(gsort) : [
                ...groups_you_moderate.sort(gsort),
                ...groups_you_do_not_moderate.sort(gsort)
            ]} unaverse={unaverse}
                              navigate={navigate} community={community}/>
        })
    }

    const dedup = your_groups.map(a => a.id);

    sections.push({
        title: "Discover new Spaces",
        can_create: true,
        comp: <DiscoverGroups loading={loading} flags={flags} dedup={your_groups.map(a => a.id)} {...{
            groups: explore_groups.filter(a => !dedup.includes(a.id)).sort(gsort),
            unaverse,
            community,
            navigate,
            loadMore,
            has_more,
            loading
        }} />
    });

    const content = <>
        {sections.map(sec => {
            return <PageLayoutBlock key={sec.title}>
                <div className="pb-1">
                    <SectionHeader size="text-lg" weight="font-semibold"
                                   actions={!sec.can_create ? null : <TextAction onClick={() => {
                                       community.openEditOverlay({
                                           type: 'add-group',
                                           size: 'medium'
                                       })
                                   }} text="New Space"/>} title={sec.title}/>
                </div>
                {sec.comp}
            </PageLayoutBlock>
        })}
    </>;

    let header_actions = [];

    if (community.member_access.manage_members) {
        header_actions.push({
            type: "icon",
            intent: "primary",
            icon: m3_icon_map.outlines.add,
            onClick: () => {
                community.openEditOverlay({
                    type: 'add-group',
                    size: 'medium'
                })
            }
        });
    }

    return <CommunityIndexPage header={{
        title: "Spaces",
        actions: header_actions
    }}>
        <PageLayoutSection gap="gap-6" divider={false}>
            {content}
        </PageLayoutSection>
    </CommunityIndexPage>;
};