import {authFetch} from "../network";

export async function api_downloadEvent(payload) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        authFetch("/calendar/download-event", res, res, "POST", {payload});
    })
}