import React, {useState} from "react";
import {useCommunity} from "../../../../config/community";
import SimplePortal from "../../../../m3/_legacy_components/simple-portal";
import EntityTabs from "../../../../m3/_legacy_components/entity-tabs";
import Modal from "../../../../m3/_legacy_components/modal";
import {GMMMembers} from "./tabs/members";
import {GMMRules} from "./tabs/rules";
import {GMMOrganizers} from "./tabs/organizers";
import {
    ModalBody,
    ModalContentBlock,
    ModalHeader,
    ModalTabsWrapper
} from "../../../../m3/_legacy_components/preview-overlay";
import {GenericModal} from "../../onboarding/designer";
import M3_A_Portal from "../../../../m3/atoms/portal";

const tabs = [
    {
        label: "Organizers"
    },
    {
        label: "Members"
    },
    {
        label: "Rules"
    }
]

export function GroupMembersModal({onClose, data}) {
    return <GroupMembers onClose={onClose} {...data} />
}

export function GroupMembers({starting_tab = "Members", fns, flags, id, onAddMember, data, onClose}) {
    const community = useCommunity();
    const [tab, setTab] = useState(starting_tab || tabs[0].label);

    function renderTab() {
        const p = {
            data, id, onAddMember, flags, fns
        };

        switch (tab) {
            case "Members":
                return <GMMMembers {...p} />
            case "Rules":
                return <GMMRules {...p} />
            case "Organizers":
                return <GMMOrganizers {...p} />
            default:
                return null;
        }
    }

    return <GenericModal onClose={onClose} active_tab={tab} tabs={tabs} onChangeTab={setTab} title={data?.name}>
            <ModalContentBlock>
                {renderTab()}
            </ModalContentBlock>
        </GenericModal>;
}