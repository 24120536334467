export function buildLeadersDirectory(view_stacks, all_teams) {
    let a = {
        customize_menu: null,
        primary_actions: [],

        features: {
            can_create_view: true,
            can_search: true,
            can_sort: true,
            can_group: true,
            can_edit_views: true,
            can_filter: true,
            can_customize: true
        },

        actions: [],
        collections: [],

        sort: [
            {
                id: "title",
                label: "Name",
                type: "string"
            },
            {
                id: "order",
                label: "Order",
                type: "number"
            }
        ],

        filters: [
            {
                id: "unassigned",
                label: "Only unassigned",
                type: "boolean"
            },
            {
                id: "group",
                label: "Spaces",
                selected_label: {
                    one: "Space",
                    many: "Spaces"
                },
                type: "select",
                options: all_teams.map(team => {
                    return {
                        value: team.id,
                        label: team.name
                    }
                })
            }
        ],

        group_by: [
            {
                id: "group",
                label: "Group"
            }
        ]
    };

    if (view_stacks) {
        view_stacks.forEach(view_stack => {
            a.collections.push({
                ...view_stack,
                views: view_stack.stack
            })
        })
    } else {
        a.collections = [
            {
                id: "leaders",
                title: "Leaders",
                views: [
                    {
                        label: "All",
                        group_by: "group"
                    },
                    {
                        label: "Unassigned",
                        filters: [
                            {
                                id: "unassigned",
                                value: true
                            }
                        ]
                    },
                ]
            }
        ]
    }

    return a;
}