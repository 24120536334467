import React, {useEffect, useRef, useState} from 'react';
//import SignatureCanvas from "react-signature-canvas/src";
import {IconAction} from "../../../../routes/auth/sign-in";
import {TrashIcon} from "@heroicons/react/24/outline";
import SimpleTooltip from "../../tooltip";
import EntityTabs from "../../entity-tabs";
import {ref, uploadBytes, uploadString, getDownloadURL} from "firebase/storage";
import Button from "../../button";
import {useCommunity} from "../../../../config/community";
import {storage} from "../../../../config/firebase-setup";
import dayjs from "dayjs";
import {InlineLoader} from "../../admin-activity";

const tabs = [
    {
        label: "Draw"
    },
    {
        label: "Type"
    },
    {
        label: "Image"
    }
]

function Wrapper({children, onDone, onChangeType, mode, valid, setMode, type}) {
    const [tab, setTab] = useState(type)
    useEffect(function () {
        onChangeType(tab)
    }, [tab])
    return <div className="border relative border-gray-300 rounded-lg" style={{width: '400px', height: '240px'}}>
        {children}
        <div className="absolute right-3 bottom-2">
            <EntityTabs layout="minimal" tabs={tabs} active={tab} onChangeTab={t => setTab(t)}/>
        </div>
        <div className="absolute top-2 right-3">
            {mode === "edit" && <Button onClick={onDone} disabled={!valid} size="small" text="Done" intent="primary"/>}
        </div>
    </div>
}

function ClearAction({
                         onClear = () => {
                         }
                     }) {
    return <div className="absolute left-3 bottom-2">
        <SimpleTooltip text="Clear">
            <IconAction onClick={onClear}>
                <TrashIcon/>
            </IconAction>
        </SimpleTooltip>
    </div>
}

function DrawField({onChange}) {
    const sigCanvas = React.useRef(null);

    function handleChange() {
        if (sigCanvas.current.isEmpty()) {
            onChange(null)
        } else {
            onChange(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'))
        }
    }

    function handleClear() {
        sigCanvas.current.clear();
        onChange(null);
    }

    const is_empty = sigCanvas.current ? sigCanvas.current.isEmpty() : true;

    /*

        <SignatureCanvas onEnd={handleChange} ref={sigCanvas} penColor="black"
                         canvasProps={{width: 400, height: 240, className: 'sigCanvas'}}/>
     */
    return <div className="relative h-full w-full">
        {!is_empty && <ClearAction onClear={handleClear}/>}
    </div>
}

function TypeField({prefill = "", onChange}) {
    const [value, setValue] = useState(prefill)
    const fontSize = 54 - (((value.length - 10) / 10) * 10);

    useEffect(function () {
        if (prefill) {
            handleChange();
        }
    }, [prefill])

    function textToDataUrl() {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const text = value;
        const font = `${fontSize}px Brush Script MT`;
        context.font = font;
        const textWidth = context.measureText(text).width;
        const textHeight = parseInt(font, 10); // base 10
        canvas.width = textWidth + 24;
        canvas.height = textHeight + 24;
        context.font = font;
        context.textBaseline = 'top';
        context.fillText(text, 8, 12);
        return canvas.toDataURL();
    }

    function handleChange() {
        if (value) {
            onChange(textToDataUrl())
        } else {
            onChange(null)
        }

    }

    const style = {
        fontFamily: "Brush Script MT, cursive",
        fontSize: fontSize,
        outline: 'none'
    }
    return <div className="relative h-full w-full">
        <div className="flex h-full pb-8 justify-center items-center text-center">
            <input onBlur={handleChange} onChange={e => setValue(e.target.value)} value={value} maxLength={48}
                   className="text-center w-full"
                   placeholder="Your Signature" style={style}/>
        </div>
        {value && <ClearAction onClear={() => {
            setValue("");
            onChange(null);
        }}/>}
    </div>
}

function ImageField({onChange}) {
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(null);
    const inputRef = React.useRef(null);

    useEffect(function () {
        if (image) {
            readImage()
        } else {
            onChange(null);
        }
        onChange(image);
    }, [image])

    function readImage() {
        const reader = new FileReader();
        reader.onload = function (e) {
            onChange(e.target.result);
        };
        reader.readAsDataURL(image);
    }

    return <div className="relative h-full flex-col w-full flex items-center justify-center">
        {preview && <div className="h-20 w-auto">
            <img id="preview" width="auto" height="auto" src={preview} className="" style={{maxHeight: "5rem"}}/>
        </div>}
        <div className="pt-4">
            <Button style={{}} text={image ? "Replace" : "Upload"} onClick={() => {
                inputRef.current.click();
            }}/>
        </div>

        <input ref={inputRef} onChange={(e) => {
            if (e.target.files[0]) {
                setImage(e.target.files[0]);
                setPreview(URL.createObjectURL(e.target.files[0]));
            }
        }} accept="image/*" className="hidden" type="file" name="file2" id="file" multiple={false}/>

        {image && <ClearAction onClear={() => setImage(null)}/>}
    </div>
}

function capitalize(s) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export function SignatureField({
                                   type = "draw",
                                   onChange = ()=>{},
                                   url = null,
                                   valid = false,
                                   ts = null,
                               }) {
    const community = useCommunity();
    const [mode, setMode] = useState(!url ? "edit" : "view");
    const [raw, setRaw] = useState(null);
    const [_type, setType] = useState(type||"draw");
    const [_url, setUrl] = useState(url);
    const [_ts, setTs] = useState(ts);
    const [_valid, setValid] = useState(valid);
    const [state, setState] = useState("");
    const isFirstRun = useRef(true);

    function updateParent() {
        if (_url && _valid) {
            onChange({
                url: _url,
                type: _type,
                valid: _valid,
                ts: _ts
            })
        } else {
            onChange({
                url: "",
                type: "",
                valid: false,
                ts: null
            })
        }

    }

    useEffect(function () {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        updateParent();
    }, [_url])

    function uploadFile() {
        const uid = randomIntFromInterval(0, 2423493);
        const signature_ref = ref(storage, `${community.uid}/signatures/${uid}-${dayjs().format('DD-MM-YYYY')}.png`);
        setUrl("");
        setState("uploading");
        uploadString(signature_ref, raw, 'data_url').then((snapshot) => {
            console.log('Uploaded a blob or file!');
            getDownloadURL(snapshot.ref).then((downloadURL) => {
                console.log('File available at', downloadURL);
                setUrl(downloadURL);
                setState("success");
            });
        });
    }

    function captureSignature() {
        uploadFile();
        setTs(Date.now());
    }

    function handleChangeValue(r) {
        if (_type === "type") {
            setRaw(r);
            setValid(!!r);
        } else if (_type === "draw") {
            setRaw(r);
            setValid(!!r);
        } else {
            setRaw(r);
            setValid(!!r);
        }
    }

    function handleDone() {
        if (_valid) {
            setMode("view");
            captureSignature();
        }
    }

    function handleChangeType(new_type) {
        setType(new_type)
    }

    const note = <div className="pt-1.5">
        <div className="text-xs text-gray-600">
            I understand this is a legal representation of my signature.
        </div>
    </div>;

    if (mode === "view") {
        return <div>
            <div className="flex space-x-4 items-center">
                <div style={{backgroundImage: `url('${_url}')`}}
                     className="w-40 h-16 bg-contain bg-center bg-no-repeat relative flex items-center justify-center bg-gray-50 border-gray-200 border rounded-md">
                    {state === "uploading" && <InlineLoader mini/>}
                </div>
                <div>
                    <Button onClick={() => {
                        setValid(false);
                        setMode("edit");
                        setState("");
                    }} text="Edit Signature"/>
                </div>
            </div>
            {note}
        </div>
    }

    return <div>
        <Wrapper onDone={handleDone} mode={mode} valid={_valid} setMode={setMode} type={capitalize(_type)}
                 onChangeType={nt => handleChangeType(nt.toLowerCase())}>
            {_type === "draw" && <DrawField onChange={handleChangeValue}/>}
            {_type === "type" && <TypeField onChange={handleChangeValue} prefill={community.member.name}/>}
            {_type === "image" && <ImageField onChange={handleChangeValue}/>}
        </Wrapper>
        {note}
    </div>
}
