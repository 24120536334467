import React from "react"
import './styles.css';
import {
    Bars3BottomLeftIcon, CalendarIcon,
    CheckBadgeIcon, CheckCircleIcon,
    DocumentIcon,
    FilmIcon,
    LinkIcon, ListBulletIcon,
    PhotoIcon, StarIcon
} from "@heroicons/react/24/outline";

const icon_map = {
    'text': <Bars3BottomLeftIcon/>,
    'image': <PhotoIcon/>,
    'video': <FilmIcon/>,
    'link': <LinkIcon/>,
    'list': <ListBulletIcon/>,
    'check-circle': <CheckCircleIcon/>,
    'star': <StarIcon/>,
    'calendar': <CalendarIcon/>,
    'file': <DocumentIcon/>,
    'poll': <CheckBadgeIcon/>,
}

export default function IconSelectMenu({
                                           items = [],
                                           title = "What would you like to post?"
                                       }) {

    return <div className="">
        <div className="px-5 py-3 flex flex-col gap-2">
            <div className="text-center font-sm font-medium text-gray-800">
                {title}
            </div>
            <div className={`grid gap-3 grid-cols-2 ${items.length>3?"sm:grid-cols-4":"sm:grid-cols-3"} px-12`}>
                {items.map(item => {
                    return <div key={item.label} className="flex justify-center">
                        <div onClick={item.onClick}
                             className="flex px-2 py-1.5 rounded-xl cursor-pointer hover:bg-gray-100 w-20 flex-col items-center gap-1.5">
                            <div className="w-5 h-5 text-gray-600">
                                {icon_map[item.type]}
                            </div>
                            <div className="text-xs text-center font-medium text-gray-700">{item.label}</div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    </div>
};