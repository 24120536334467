function getCommonWords(arr,min=3) {
    if(!arr||arr.length===0 || !Array.isArray(arr)) {
        return [];
    }
    let ind = {};
    arr.forEach(string=>{
        string.split(" ").forEach(word=>{
            if(!ind[word]) {
                ind[word] = 1;
            } else {
                ind[word]++;
            }
        });
    })
    return Object.entries(ind).filter(([word,count])=>count>min).map(a=>a[0]);
}

// return nm.replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
function cleanName(nm) {
    // also clean of [, ], /, \, {, }, <, >, (, ), |, ", ', :, ;, ., ?, !, @, #, $, %, ^, &, *, ~, `, =, +, -, _, and ,

    const regex = /[^a-zA-Z0-9 ]/g;

    return nm.replace(regex, "");
}

export const groupsFollowSchemaWithCities = (all_names) => {
    const min = 6;
    // Math.floor(all_names.length/3))
    const common_words = getCommonWords(all_names, min);
    if(common_words.length>0) {
        return common_words[0];
    }
    return false;
}

export const getNameInitials = (nm = "",all_names=[], len=2, default_letter = "S") => {
    const name = cleanName(nm);

    if(!name) {
        return default_letter;
    }

    const split1 = name.split(" ");
    const split = split1.filter(a=>a.length>2);
    const word_count = split.length;
    const common_words = getCommonWords(all_names);

    let final = `${split.join(" ")}`;
    common_words.forEach(cw=>{
        final = final.replace(cw, "").trim();
    });

    if(final.length===0) {
        return name.charAt(0).toUpperCase();
    }

    const final_words = final.split(" ");

    let penultimate;

    if(final_words.length===1) {
        penultimate = final_words[0].charAt(0).toUpperCase();
    } else {
        const two_letter_words = final_words.filter(a=>a.length===2);
        if(two_letter_words[0]) {
            penultimate = two_letter_words[0].toUpperCase()
        } else {
            penultimate = `${final_words[0].charAt(0)}${final_words[1].charAt(0)}`.toUpperCase();
        }
    }

    return penultimate.slice(0, len);
};