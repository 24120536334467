import React, {useEffect, useRef, useState} from "react";
import ReactDOM from "react-dom";
import {M3_E_SelectPanelFieldEditor} from "../../examples/select-panel";
import {ViewCellValue} from "./components";
import {data_autocomplete_map} from "../../../../common/data/autocomplete-map";
import {findDataField} from "../../../../common/data/data-fields";
import {all_field_types} from "../../../../common/data/field-types";
const Wrapper = ({children, anchor = 'bottom', rect}) => {
    let style = {};
    const window_height = window.innerHeight;
    if (anchor === 'bottom') {
        // align to right if it's too close to the right side of the screen

        if ((rect.left + 400) < window.innerWidth) {
            style = {
                top: `${(rect.top + rect.height + 4)}px`,
                left: `${rect.left}px`,
            };
        } else {
            style = {
                top: `${(rect.top + rect.height + 4)}px`,
                right: `${window.innerWidth - rect.right}px`,
            };
        }
    } else if (anchor === 'top') {
        style = {
            bottom: `${(window_height - rect.top + 4)}px`,
            left: `${rect.left}px`,
        };
    }

    return <div
        onClick={e => {
            e.stopPropagation();
            e.preventDefault();
        }}
        className={`absolute cell-portal z-9999 bg-white border border-gray-200 popup-shadow rounded-lg`}
        style={{
            ...style,
            minHeight: "4rem",
            minWidth: "16rem", // rect.width,
            maxWidth: '20rem'
        }}>
        {children}
    </div>
}

export function TableCellPortalWrapper({children, element_ref}) {
    const rect = !element_ref ? null : element_ref.getBoundingClientRect();

    if (!rect) {
        return null;
    }

    return ReactDOM.createPortal(
        <Wrapper rect={rect}>
            {children}
        </Wrapper>,
        document.body
    );
}

function getSelectOptions(column_def, config) {
    const {accessorKey} = column_def;
    const attribute_data = config?.data?.attributes?.find(att => att.col_data.accessorKey === accessorKey);
    if (attribute_data && attribute_data?.col_data?.display?.value_options) {
        return attribute_data?.col_data?.display?.value_options;
    } else if (attribute_data?.col_data?.display?.autocomplete && data_autocomplete_map?.[attribute_data?.col_data?.display?.autocomplete]?.data) {
        return data_autocomplete_map?.[attribute_data?.col_data?.display?.autocomplete]?.data;
    }
    console.warn("NO VALUE OPTIONS", column_def, config)
    return [];
}

// subtract one from month
function subtractOne(month) {
    if (month === 0) {
        return 11;
    } else {
        return month - 1;
    }
}

function convertToDate(input) {
    if (typeof input === "object") {
        if (typeof input.getMonth === 'function') {
            return input;
        } else if (input.year && input.month && input.date) {
            return new Date(input.year, input.month, input.date);
        } else {
            return null;
        }
    } else if (typeof input === "string") {
        // check if it's a date format with two dashes
        const split = input.split("-");
        if (split.length === 3) {
            const year = parseInt(split[0]);
            const month = subtractOne(parseInt(split[1]));
            const date = parseInt(split[2]);

            if (isNaN(year) || isNaN(month) || isNaN(date)) {
                return null;
            } else {
                return new Date(year, month, date);
            }
        }
    } else {
        return null;
    }
}

function changesMade(a, b) {
    return JSON.stringify(a) !== JSON.stringify(b);
}

function padValue(v) {
    // add leading 0 if needed
    return v < 10 ? `0${v}` : v;
}

function addOne(month) {
    if (month === 11) {
        return 0;
    } else {
        return month + 1;
    }
}

function getStartingValue(val, accessor_key, attributes) {
    // try to see if the value passed is valid, if not, show empty value
    const data_field_info = findDataField(accessor_key, attributes);

    if (data_field_info) {
        // now get the object based on type
        const data_field_type_meta = all_field_types[data_field_info?.col_data?._type];
        if (!!data_field_type_meta?.isValid) {
            const valid = data_field_type_meta.isValid(val, data_field_info?.col_data?.validation, data_field_info?.col_data?.display);
            if (!valid) {
                return data_field_type_meta?.getEmptyValue();
            }
        }
    }
    return val;
}

// TODO differentiate by type // text vs select
// use from data_autocomplete_map?.[display.autocomplete]?.data.find(item => item.value === value); if display?.autocomplete && !!value && display?.variant
// !!value && display?.autocomplete === "value_options" for member_type
export function TableEditableCell({
                                      id,
                                      cell_height = 0,
                                      initial_value,
                                      type,
                                      config,
                                      character,
                                      width,
                                      onUpdateRecord,
                                      handleCallback,
                                      resetCharacter,
                                      context
                                  }) {
    const column_def = context.column.columnDef;
    const meta = column_def.meta;
    const [value, setValue] = useState(getStartingValue(context.getValue(), column_def.accessorKey, config?.data?.attributes));
    const [q, setQ] = useState("");
    const [focused, setFocused] = useState(false);
    const is_focused = useRef(false);
    const container_ref = useRef(null);
    const ref = useRef(null);
    const [error, setError] = useState(null);
    const previous_id = useRef(id);

    const autosave = type === "date-object";


    useEffect(() => {
        // check if value is any different
      //  console.log("is_focused", is_focused.current, value, context.getValue(), column_def.accessorKey, config?.data?.attributes)
        if (is_focused.current || (value === getStartingValue(context.getValue(), column_def.accessorKey, config?.data?.attributes) && !character)) {
       //     console.log("FOCUSED or SAME, returning")
        } else if (id && id !== previous_id.current) {
          //  console.log("id changed, setting value to", getStartingValue(context.getValue(), column_def.accessorKey, config?.data?.attributes))
            setValue(getStartingValue(context.getValue(), column_def.accessorKey, config?.data?.attributes));
            previous_id.current = id;
        } else if (character) {
            // first add character to value
          //  console.log("adding character", character, value, all_field_types[type]?.getEmptyValue())
            if (character === "Backspace" || character === "Delete") {
                // set value to empty
                setValue(all_field_types[type]?.getEmptyValue());
                return;
            }

            if (character === "Enter" || character === "Tab") {
                saveChanges(null, character);
                return;
            }

            if (type === "select") {
                // open focus and set first character
                setFocused(true);
                setQ(character);
                return;
            }

            const new_value = `${value}${character}`;
            // console.log("setting value to", new_value)
            setValue(new_value);
            // then set cursor to end
            is_focused.current = true;
            if (ref.current) {
                setTimeout(() => {
                    ref.current.focus();
                }, 50)
            }

            resetCharacter();
        } else if (value !== getStartingValue(context.getValue(), column_def.accessorKey, config?.data?.attributes)) {
            // console.log("value changed, setting value to", is_focused.current, getStartingValue(context.getValue(), column_def.accessorKey, config?.data?.attributes))
            setValue(getStartingValue(context.getValue(), column_def.accessorKey, config?.data?.attributes));
        }
    }, [value, id, context.getValue(), character, type, column_def.accessorKey, config?.data?.attributes]);

    useEffect(() => {
        const focus_changed = focused !== is_focused.current;
        // console.log("setting is_focused", focused)
        is_focused.current = focused;

        // consider autosave
        if (autosave && focus_changed && !focused) {
            // do local compare of value and initial_value
            handleSaveChangesFlow(value, initial_value)
        }
    }, [focused, type, value]);

    function handleSaveChangesFlow(new_value, old_value) {
        if (!changesMade(new_value, old_value)) {
            return true;
        }
        // check if is valid value
        const data_field_info = findDataField(column_def?.accessorKey, config?.data?.attributes);


        if (data_field_info && data_field_info?.col_data?.validation?.required) {
            // now get the object based on type
            const data_field_type_meta = all_field_types[data_field_info?.col_data?._type];

            if (!!data_field_type_meta?.isValid) {
                const valid = data_field_type_meta.isValid(new_value, data_field_info?.col_data?.validation, data_field_info?.col_data?.display);
                if (!valid) {
                    console.log("Invalid value", new_value)
                    return false;
                }
            }
        }

        if (onUpdateRecord) {
            onUpdateRecord(context.row.original.id, column_def.accessorKey, value, context.row.original);
        }

        return true;
    }

    function saveChanges(e, event_name = "Enter") {
        // check if there was a change
        const valid_save = handleSaveChangesFlow(value, initial_value)
        console.log("saveChanges, valid_save:", valid_save, value)
        if (!valid_save) {
            setError({
                type: "validation"
            })
            return;
        }

        // unfocus
        handleCallback(e, event_name);

        if (type === "select") {
            setFocused(false);
        }
    }

    function handleKeyDown(e) {
    //    console.log("HANDLE KEY DOWN", e.keyCode, e.key)
        const enter_key = e.keyCode === 13;
        const escape_key = e.keyCode === 27;
        const tab_key = e.keyCode === 9;
        if (enter_key) {
            e.preventDefault();
            e.stopPropagation();
            saveChanges(e, "Enter");
        } else if (escape_key) {
            e.preventDefault();
            e.stopPropagation();
            // unfocus
            if (ref.current) {
                ref.current.blur();
            }
        } else if (tab_key) {
            e.preventDefault();
            e.stopPropagation();
            saveChanges(e, "Tab");
        }
    }

    function handleChange(e) {
        console.log("handleChange", e.target.value)
        setValue(e.target.value);
    }

    function handleSelectChange(new_value) {
        setValue(new_value);

        if (onUpdateRecord) {
            onUpdateRecord(context.row.original.id, column_def.accessorKey, new_value, context.row.original);
        }

        // unfocus
        handleCallback(null, "Enter");

        setFocused(false);

        // set timeout of 50ms to unfocus in document
        setTimeout(() => {
            if (document.activeElement) {
                document.activeElement.blur();
            }
        }, 50)
    }

    let common_wrapper_style = {
        minHeight: `${cell_height}px`,
    };

    if (type === "date-object" || type === "date") {
        return <>
            <div onDoubleClick={() => {
                setFocused(!focused);
            }} className={`w-full h-full flex items-center px-1.5`} style={common_wrapper_style}
                 ref={container_ref}>
                <ViewCellValue original={context.row.original} {...column_def} value={value}/>
            </div>
            {focused && <TableCellPortalWrapper element_ref={container_ref.current}>
                <M3_E_SelectPanelFieldEditor no_anchor type="date" value={convertToDate(value)} onChange={(a) => {
                    if (a) {
                        const {month, year, day} = a;
                        let save_value;
                        if (type === "date-object") {
                            save_value = {month, year, date: day};
                        } else if (type === "date") {
                            if (!year) {
                                save_value = "";
                            } else {
                                save_value = `${year}-${padValue(addOne(month))}-${padValue(day)}`;
                            }
                        } else {
                            return;
                        }
                        handleSelectChange(save_value);
                    }
                }}/>
            </TableCellPortalWrapper>}
            <input ref={ref} className="hidden" onKeyDown={handleKeyDown}/>
        </>
    }

    // we need to set the height, if character count is greater than 40, then we set height to auto with a max height of 200px
    let _height, _width;

    if (type === "select" || type === "multi-select") {
        let options = getSelectOptions(column_def, config);

        return <>
            <div onDoubleClick={() => {
                setFocused(true);
            }} className={`w-full h-full flex items-center px-1.5`} style={common_wrapper_style} ref={container_ref}>
                <ViewCellValue original={context.row.original} {...column_def} value={value}/>
            </div>
            {focused && <TableCellPortalWrapper element_ref={container_ref.current}>
                <M3_E_SelectPanelFieldEditor type="select" initial_query={q} value={value} onChange={(a) => {
                    console.log("on change", a, value)
                    if (a === value) return;
                    handleSelectChange(a);
                }} options={options} no_anchor/>
            </TableCellPortalWrapper>}

            <input ref={ref} className="hidden" onKeyDown={handleKeyDown}/>
        </>
    }

    if (value.length > 16) {
        _height = "auto";
        _width = width * 1.5;
    } else {
        _height = "34px";
        _width = "auto";
    }

    const text_classes = error ? "bg-red-100 text-red-800" : "text-gray-800";

    return <>
        <textarea
            ref={ref}
            onFocus={(e) => {
                is_focused.current = true;
            }}
            onBlur={() => {
             //   console.log("onBlur")
                is_focused.current = false;
            }}
            onKeyDown={handleKeyDown}
            style={{
                background: "transparent",
                resize: "none",
                minHeight: "34px",
                maxHeight: "4rem",
                height: _height,
                width: _width,
                lineHeight: "1.6",
                outline: "none",
            }}
            onChange={(e) => {
             //   console.log("onChange raw", e)
                is_focused.current = true;
                e.stopPropagation();
                e.preventDefault();
                handleChange(e);

                if (error) {
                    setError(null);
                }
            }} value={value}
            className={`px-1.5 py-1.5 rounded text-sm font-medium w-full flex items-center justify-start ${text_classes}`}/>
    </>
}