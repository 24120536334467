import React from "react";
import {import_initial_state, importButtonRef} from "./index";

export function handleOpenDialog(e) {
    // Note that the ref is set async, so it might be null at some point
    if (importButtonRef.current) {
        importButtonRef.current.open(e);
    }
}

export function handleOnFileLoad(data,file) {
    if(data.data) {
        this.setState({uploading:true})
        // headers are first row
        this.setupHeaders(data.data[0]);

        const data_without_headers = data.data.slice(1);
        this.setupPreview(data_without_headers);
        this.setState({file_name:"file name.extension",file:{},uploading:false})
    }
}

export function handleOnError(err, file, inputElem, reason) {
    alert(`Error: ${err}`);
}

export function handleOnRemoveFile(data) {
    console.log('---------------------------')
    console.log(data)
    console.log('---------------------------')

}

export function handleRemoveFile(e) {
    // Note that the ref is set async, so it might be null at some point
    if (importButtonRef.current) {
        importButtonRef.current.removeFile(e)
    }
    this.setState({...import_initial_state})
}