import React, {Component, useEffect, useState} from 'react';
import {SharedPageTitle} from "../../unaverse/memberships";
import Button from "../../../m3/_legacy_components/button";
import EntityTabs from "../../../m3/_legacy_components/entity-tabs";
import {
    ArrowRightIcon,
    ChevronDownIcon, ChevronUpDownIcon,
    EllipsisHorizontalIcon,
    MagnifyingGlassIcon,
    XMarkIcon
} from "@heroicons/react/20/solid";
import {icon_map} from "./icon-map";
import {ContentMenuSection, ContentPopupMenu} from "../../../m3/_legacy_components/content-popup/components";
import SimpleTooltip from "../../../m3/_legacy_components/tooltip";
import ContentPopup from "../../../m3/_legacy_components/content-popup";
import {useIsMobile} from "../../../m3/hooks/is-mobile";
import {LocalSearchBar} from "../../../m3/_legacy_components/local-search-bar";
import {TextAction} from "../../auth/sign-in";
import Field, {FieldLabel} from "../../../m3/_legacy_components/form/field";
import {RadioGroup} from "../../unaverse/preferences/tabs/notifications";
import styled from "styled-components";
import ReactDOM from "react-dom";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {InlineLoader} from "../../../m3/_legacy_components/admin-activity";
import {m3_icon_map} from "../../../m3/icons/icon-map";
import M3_C_FilterBar from "../../../m3/components/filter-bar";
import M3_A_IconButton from "../../../m3/atoms/icon-button";

/*
Features

Filter: by group, by assigned / unassigned
Sort: by name
Group: by group
 */


function SDHTabs({views, collections, handleSelectCollection, collection_id, active, onChangeTab}) {
    const is_mobile = useIsMobile();

    if (is_mobile) {
        const opts = [
            ...views.map(v => {
                return {
                    label: v.label,
                    onClick: () => onChangeTab(v.id),
                    value: v.id
                }
            })
        ]

        const content = <ContentPopup>
            <ContentPopupMenu items={opts}/>
        </ContentPopup>;

        const label = views.find(v => v.id === active).label;

        return <SimpleTooltip simple trigger="click" interactive hideOnClick placement="bottom-start" text={content}>
            <div className="mt-2 pb-1.5">
                <Button selector size={"small"} text={label}/>
            </div>
        </SimpleTooltip>
    }

    let final_views = [
        ...views
    ];

    const current_collection = collections.find(c => c.id === collection_id);

    const collection_count = current_collection?.count || null;

    if(collection_count) {
        final_views[0].badge = collection_count;
    }

    const switcher = collections.length > 1 ? <span className="block" style={{marginTop: '-2px', marginBottom: '-2px'}}><SDHCollectionSwitcher
        handleSelectCollection={handleSelectCollection} collections={collections}
        active_id={collection_id}/></span> : null;

    return <div className="-mb-px">
        <EntityTabs
            show_switcher={collections.length>1}
            count={collection_count}
            switcher={switcher}
            behavior="menu" max_width={264} onChangeTab={(ntl, nt) => {
            onChangeTab(nt.id);
        }} base="bg-transparent"
            active={active}
            tabs={final_views}/>
    </div>
}

function valueIsEmpty(value, type) {
    if (type === "boolean") {
        return !value;
    }
    return false;
}

function getFilterLabel(data, value) {

    if (data.type === "select" && value) {
        const len = value.length;
        if (len === 1) {
            const option = data.options.find(o => value.includes(o.value));
            return option.label;
        } else if (len > 1) {
            if (data.selected_label) {
                return `${len} ${data.selected_label.many}`;
            }
            return `${len} selected`;
        }
    }

    return data.label;
}

function Filter({data, handleChange, handleApplyChanges, value, type, id}) {
    const active = value !== null && value !== undefined && !valueIsEmpty(value, data.type);

    const [query, setQuery] = React.useState("");
    const [changes_made, setChangesMade] = useState(false);

    function handleClick(id, val) {
        const is_selected = value && value.includes(val);
        if (!value) {
            handleChange(id, [val]);
        } else if (is_selected) {
            let nv = value.filter(v => v !== val);
            if (nv.length === 0) {
                handleChange(id, null);
            } else {
                handleChange(id, nv);
            }
        } else {
            handleChange(id, [...value, val]);
        }
        setChangesMade(true);
    }

    function applyChanges() {
        if (handleApplyChanges) {
            console.log("handleApplyChanges...",value)
            if(valueIsEmpty(value, data.type)) {
                console.log("REMOVE")
                handleChange(id, null, true)
            } else {
                handleApplyChanges();
            }
        }
    }

    let colors = "";

    if (active) {
        colors = `border-blue-500 bg-blue-500 text-white font-semibold`;
    } else {
        colors = `border-gray-300 text-gray-700 font-medium`;
    }

    const show_dropdown = data.type === "select";

    const label = getFilterLabel(data, value);

    const child = <div onClick={() => {
        if (show_dropdown) return;
        if (active) {
            handleChange(id, false);
        } else {
            handleChange(id, true);
        }
    }} className={`${colors} flex items-center rounded-full border text-sm cursor-pointer`}>
        {data.icon && <div className="h-4 opacity-80 w-4 ml-2 mr-0.5">
            {icon_map[data.icon]}
        </div>}
        <div
            className={`${!data.icon ? "pl-2.5" : "pl-1"} ${show_dropdown ? "pr-0.5" : "pr-3"} leading-7 text-sm`}>{label}</div>
        {show_dropdown && <div className="h-4 w-4 mr-2 opacity-80">
            <ChevronDownIcon/>
        </div>}
    </div>;

    const actions = <>
        <TextAction key="delete" onClick={() => {
            // confirm first before deleting
            window.confirm("Are you sure you want to delete this filter?") && handleChange(id, null, true)
        }} text="Delete"/>
        <TextAction key="apply" classNames="close-tooltip" onClick={() => {
            applyChanges();
        }} text="Apply" disabled={!changes_made}/>
    </>;

    if (show_dropdown) {
        const options = data.options.map(o => {
            return {
                label: o.label,
                onClick: () => handleClick(id, o.value),
                value: o.value,
                checked: value && value.includes(o.value)
            }
        }).filter(o => o.label.toLowerCase().includes(query.toLowerCase()));
        const content = <ContentPopup min_width="min-w-[20rem]">
            <ContentMenuSection side_padding={false} divider minimal title={`Edit Filter`} actions={actions}>
                <div className="divide-y divide-gray-200">
                    {(data.options.length > 5) && <div className="px-2.5 py-2">
                        <LocalSearchBar enable_button onSearchSubmit={s => setQuery(s)} query={query}
                                        clearResults={() => setQuery("")}/>

                        <div className="pt-2 flex space-x-3">
                            {(!value || (value.length !== data.options.length)) && <TextAction onClick={() => {
                                handleChange(id, data.options.map(o => o.value));
                            }} text="Select all"/>}
                            {value && value.length > 0 && <TextAction onClick={() => {
                                handleChange(id, null);
                            }} text="Deselect all"/>}
                        </div>
                    </div>}
                    <div className=" overflow-y-auto" style={{maxHeight: '20rem'}}>
                        <ContentPopupMenu can_check items={options}/>
                        {options.length === 0 && <div>
                            <div className="text-gray-500 text-sm text-center px-2.5 py-2">
                                No results
                            </div>
                        </div>}
                    </div>
                </div>
            </ContentMenuSection>
        </ContentPopup>;
        return <SimpleTooltip listenForClose simple trigger="click" interactive hideOnClick={false}
                              placement="bottom-start"
                              text={content}>
            {child}
        </SimpleTooltip>;
    }

    return child;
}

function AddFilter({onAddFilter, filters = []}) {

    const options = [
        ...filters.map(f => {
            return {
                label: f.label,
                onClick: () => onAddFilter(f.id),
                value: f.id
            }
        })
    ];

    const content = <ContentPopup>
        <ContentPopupMenu items={options}/>
    </ContentPopup>;

    return <SimpleTooltip simple trigger="click" interactive hideOnClick placement="bottom-start" text={content}>
        <Button size={"small"} intent="minimal-active" text="More Filters"/>
    </SimpleTooltip>;
}

function reorder(arr, from, to) {
    const result = Array.from(arr);
    const [removed] = result.splice(from, 1);
    result.splice(to, 0, removed);
    return result;
}

const Container = styled.div`

`;

const portal = document.createElement('div');
if (!document.body) {
    throw new Error('body not ready for portal creation!');
}
document.body.appendChild(portal);

class PortalAwareItem extends Component {
    render() {
        const provided = this.props.provided;
        const snapshot = this.props.snapshot;
        const view = this.props.view;

        const usePortal = snapshot.isDragging;

        const inPortal = !!usePortal;

        // todo should be arrangable content popup menu list
        const child = (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
            >
                <div className="flex items-center gap-1.5">
                    <div className="w-4 h-4">
                        <div className={`text-gray-500 rounded-md`} {...provided.dragHandleProps}>
                            {m3_icon_map.custom['grab-handle']}
                        </div>
                    </div>
                    <div className="flex-grow text-gray-800 text-sm">
                        {view.label}
                    </div>
                </div>
            </div>
        );

        if (!usePortal) {
            return child;
        }

        // if dragging - put the item in a portal
        return ReactDOM.createPortal(child, portal);
    }
}

function ArrangeViews({views = [], handleReorderViews}) {

    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const {destination, source} = result;

        const new_views = reorder(
            views,
            source.index,
            destination.index
        );

        handleReorderViews(new_views);
    }

    return <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="blocks-layout">
            {(droppableProvided) => (
                <Container
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                >
                    {views.map((view, index) => (
                        <Draggable draggableId={view.id} index={index} key={view.id}>
                            {(
                                draggableProvided,
                                draggableSnapshot,
                            ) => (
                                <PortalAwareItem
                                    view={view}
                                    provided={draggableProvided}
                                    snapshot={draggableSnapshot}
                                />
                            )}
                        </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                </Container>
            )}
        </Droppable>
    </DragDropContext>
}

function getFiltersToShow(all,active) {
    // if less than 4, show all
    if(all.length<4) {
        return all;
    }

    // otherwise show all that are enabled
    return active;
}

function SDHFilters({all_filters, handleApplyChanges, active_filters, onChangeFilter, onAddFilter}) {

    function handleClearFilters() {
        onChangeFilter("clear", null, true);
    }

    const show_filters = getFiltersToShow(all_filters,active_filters);

    const enabled_filters = active_filters.filter(f => !!f.value);

    const filters_to_add = all_filters.filter(f => !show_filters.map(a=>a.id).includes(f.id));

    return <div className="flex gap-4 py-1">
        <div className="flex-grow flex gap-2">
        {show_filters.map(f => {
            const filter_data = all_filters.find(f2 => f2.id === f.id);
            const filter_value = active_filters.find(f2 => f2.id === f.id)?.value;
            return <Filter handleApplyChanges={handleApplyChanges} handleChange={onChangeFilter} value={filter_value}
                           id={f.id} data={filter_data} key={f.id}/>
        })}
        {filters_to_add.length > 0 && <AddFilter filters={filters_to_add} onAddFilter={onAddFilter}/>}
        </div>
        <div className="flex-none">
            {enabled_filters.length>0&&<Button onClick={handleClearFilters} size={"small"} intent="minimal" text="Clear Filters"/>}
        </div>
    </div>
}

function SDHActions({actions = []}) {
    const opts = [
        ...actions.map(a => {
            return {
                label: a.label,
                onClick: (e) => {
                    a.onClick();
                },
                value: a.value
            }
        })
    ];

    const content = <ContentPopup>
        <ContentPopupMenu items={opts}/>
    </ContentPopup>;

    return <SimpleTooltip usePortal simple trigger="click" interactive hideOnClick={true} placement="bottom-end"
                          text={content}>
        <Button left_icon={{icon: <EllipsisHorizontalIcon/>}} intent="minimal" size={"small"}/>
    </SimpleTooltip>
}

function CustomizeLabelEdit({
                                value = "", updateLabel = () => {
    }
                            }) {
    const [l, setL] = useState(value);

    useEffect(() => {
        setL(value);
    }, [value]);

    function handleBlur() {
        if (!l) {
            alert("Please enter a label");
            return;
        }
        updateLabel(l);
    }

    return <div>
        <Field onBlur={handleBlur} label="View Name" value={l} onChange={(a, b) => {
            setL(b);
        }}/>
    </div>
}

function CustomizeMenu(props) {
    const {
        active_collection,
        block_editor,
        features,
        is_open,
        active_view,
        cm_options,
        active_group_by,
        setActiveGroupBy,
        handleReorderViews,
        handleUpdateViewField,
        setActiveView,
        setChangesMade
    } = props;

    if (!is_open) {
        return null;
    }

    return <ContentMenuSection minimal divider title="Customize">
        <div className="divide-y divide-gray-200 " style={{minWidth: '20rem'}}>
            {features.can_edit_views && <div className="p-3">
                <CustomizeLabelEdit value={active_view.label} updateLabel={(l) => {
                    handleUpdateViewField(active_collection.id, active_view.id, 'label', l);
                    setActiveView({
                        ...active_view,
                        label: l
                    })
                    setChangesMade(true);
                }}/>
            </div>}
            {features.can_edit_views && <div className="p-3">
                <FieldLabel label="Views"/>
                <ArrangeViews handleReorderViews={handleReorderViews} views={active_collection.stack}/>
            </div>}
            {features.block_editor && <div>
                {block_editor}
            </div>}
            {features.can_group && <div className="p-3">
                <FieldLabel label="Group by:"/>
                <div>
                    <RadioGroup selected={active_group_by} onChange={(a, b) => {
                        handleUpdateViewField(active_collection.id, active_view.id, 'group_by', a.value);
                        setActiveGroupBy(a.value);
                        setChangesMade(true);
                    }} inline options={[
                        {label: "None", value: ""},
                        {label: "Group", value: "group"},
                    ]}/>
                </div>
            </div>}
            {cm_options.length > 0 && <div className="py-1 px-1">
                <ContentPopupMenu items={cm_options}/>
            </div>}
        </div>
    </ContentMenuSection>
}

function SDHCustomize({customize_props}) {
    const [open, setOpen] = useState(false);
    const content = <ContentPopup min_width={"min-w-[24rem]"}>
        <CustomizeMenu is_open={open} {...customize_props} />
    </ContentPopup>;

    return <SimpleTooltip onOpen={() => {
        setOpen(true);
    }} onClose={() => {
        setOpen(false);
    }} simple hideOnClick={false} trigger="click" interactive placement="bottom-end" text={content}>
        <Button size={"small"} intent="minimal" text="Customize"/>
    </SimpleTooltip>
}

function SDHSearch({onChangeQuery, active_query}) {
    const [query, setQuery] = React.useState("");
    const [searching, setSearching] = React.useState(false);
    const [focus, setFocus] = React.useState(false);
    const icon_colors = "text-gray-500";

    function handleBlur() {
        if (query === "") {
            setSearching(false);
        }
        setFocus(false);
    }

    function onKeyDown(e) {
        // User pressed the enter key

        if (e.keyCode === 13) {
                onChangeQuery(query||null);
        }
    }

    return <div onClick={() => {
        if (!searching) {
            setSearching(true)
        }
    }}
                className={`h-7 border ${focus ? " border-gray-300" : "border-white"} ${searching ? "w-auto" : "w-7"} px-1 flex space-x-2 items-center justify-center rounded-md hover:bg-gray-100 hover:border-gray-100 cursor-pointer`}>
        <div className={`h-5 w-5 flex-none ${icon_colors}`}>
            <MagnifyingGlassIcon/>
        </div>
        {searching && <div className="flex-shrink text-sm">
            <input onKeyDown={onKeyDown} value={query} onChange={e => {
                setQuery(e.target.value);
            }} onFocus={() => {
                setFocus(true);
            }} onBlur={handleBlur} autoFocus className="w-36 outline-none" style={{background: 'transparent'}}
                   placeholder="Type to search.."/>
        </div>}
        {searching && <div className="pr-0.5 flex gap-2">
            <div onClick={() => {
                setSearching(false);
                setQuery("")
                setFocus(false);
                onChangeQuery(null);
            }}
                 className={`${query ? "" : "opacity-0"} bg-gray-400 w-4 h-4 cursor-pointer text-white p-px rounded-full`}>
                <XMarkIcon/>
            </div>

            <div onClick={() => {
                if(query!==active_query) {
                    onChangeQuery(query);
                }
            }}
                 className={`${query ? "" : "opacity-0"} ${active_query!==query?"bg-blue-600 cursor-pointer":"bg-gray-300 cursor-disabled"}  w-4 h-4  text-white p-px rounded-full`}>
                <ArrowRightIcon/>
            </div>
        </div>}
    </div>
}

function CreateTab({onClick}) {
    const is_mobile = useIsMobile();

    if (is_mobile) return null;
    return <div className="pl-4 create-button">
        <Button onClick={onClick} size={"small"} intent="minimal" text="Add View"/>
    </div>
}

function SortMenu({sort, active_sort, handleSetSort}) {
    const btn = <Button intent={'minimal'} size={"small"} text="Sort"/>;
    const [changes_made, setChangesMade] = useState(false);
    const [_active_sort, _setActiveSort] = useState(active_sort);

    function updateSort(f, v) {
        _setActiveSort({
            ...(active_sort || {
                field: "title",
                direction: "asc"
            }),
            [f]: v
        });
        setChangesMade(true);
    }

    function applyChanges() {
        handleSetSort(_active_sort);
        setChangesMade(false);
    }

    const content = <ContentPopup min_width="min-w-[20rem]">
        <ContentMenuSection minimal divider title="Sort" actions={changes_made && <TextAction onClick={applyChanges} text="Apply"/>}>
            <div className="grid gap-3 grid-cols-2">
                <div className="">
                    <Field onChange={(a, b) => updateSort('field', b)} type="select" label="Field"
                           value={active_sort?.field || "title"}
                           input_props={{
                               classic: true,
                               options: [
                                   ...sort.map(s => {
                                       return {
                                           text: s.label,
                                           value: s.id
                                       }
                                   })
                               ]
                           }}
                    />
                </div>
                <div className="">
                    <Field onChange={(a, b) => updateSort('direction', b)} type="select" label="Direction"
                           value={active_sort?.direction || "asc"}
                           input_props={{
                               classic: true,
                               options: [
                                   {
                                       text: "Ascending",
                                       value: "asc"
                                   },
                                   {
                                       text: "Descending",
                                       value: "desc"
                                   }
                               ]
                           }}
                    />
                </div>
            </div>
        </ContentMenuSection>
    </ContentPopup>

    return <SimpleTooltip simple hideOnClick={false} trigger="click" interactive placement="bottom-end" text={content}>
        {btn}
    </SimpleTooltip>
}

function SDHCollectionSwitcher({collections, handleSelectCollection, active_id}) {
    const options = [
        ...collections.map(c => {
            return {
                label: c.title,
                onClick: () => {
                    handleSelectCollection(c.id);
                },
                value: c.id,
                checked: c.id === active_id,
                right_label: c.count
            }
        })
    ];
    const content = <ContentPopup min_width="min-w-[20rem]">
        <ContentMenuSection side_padding={false} divider minimal title="Switch">
            <ContentPopupMenu can_check items={options}/>
        </ContentMenuSection>
    </ContentPopup>
    return <SimpleTooltip usePortal simple hideOnClick={true} trigger="click" interactive placement="bottom-start"
                          text={content}>
        <Button intent={'minimal'} size={"small"} left_icon={{icon: <ChevronUpDownIcon/>}}/>
    </SimpleTooltip>
}

export function SharedDirectoryHeader({
                                          active_view,
                                          active_collection,
                                          active_sort,
                                          ready,
                                          loading,
                                          save_changes,
                                          filters = [],
                                          sort = [],
                                          features = {},
                                          handleSetSort = () => {
                                          },
                                          handleApplyChanges = () => {
                                          },
                                          handleSelectCollection = () => {
                                          },
                                          handleAddFilter = () => {
                                          },
                                          handleAddView = () => {
                                          },
                                          handleSetView = () => {
                                          },
                                          default_title = "",
                                          handleChangeFilter = () => {
                                          },
                                          active_filters = [],
                                          handleQueryChange = () => {
                                          },
                                          active_query,
                                          customize_props,
                                          actions,
                                          primary_actions,
                                          collections = []
                                      }) {
    const is_mobile = useIsMobile();
    const [collection_id, setCollectionId] = React.useState(!active_collection ? "" : active_collection.id || collections[0].id);
    const [show_filters, setShowFilters] = React.useState(false);
    const collection = collections.filter(c => c.id === collection_id)[0];
    const [av, setActiveView] = React.useState(!active_view ? "" : active_view.id || collection.views[0].id);

    useEffect(function () {
        if (active_view && active_collection) {
            setCollectionId(active_collection.id);
            setActiveView(active_view.id);
        }
    }, [active_collection, active_view])

    function onChangeView(av) {
        handleSetView(av);
    }

    function promptCreateView() {
        const name = prompt("Enter a name for the view", "New View")

        if (name) {
            handleAddView(name, null);
        }
    }

    if (!ready || !active_collection || !active_view || !collection) {
        return <>
            <div className="border-b h-8 border-gray-200 flex">
                <div className="flex-grow"></div>
                <div className="flex items-center space-x-2">
                    {loading && <div>
                        <InlineLoader mini/>
                    </div>}
                </div>
            </div>
        </>;
    }

    /*
       <SDHTabs collections={collections} collection_id={collection_id}
                             handleSelectCollection={handleSelectCollection} onChangeTab={av2 => onChangeView(av2)}
                             active={av} views={collection.views}/>

                                   <M3_C_FilterBar size="base" variant="underline" x_padding="" active_id={av} items={collection.views.map(v=>{
                        return {
                            id: v.id,
                            label: v.label
                        }
                    })} />
                        <M3_A_IconButton dropdown text="Upcoming"/>
                        <M3_A_IconButton variant="minimal" square icon={m3_icon_map.outlines.cog} />
     */

    return <>
        <div className={`${is_mobile?"pt-2":"border-b border-gray-300 h-8"} flex px-4 items-start`}>
            <div className="flex-grow hover-bar flex items-end">
                <div className="flex items-center -mb-px">
                    <M3_C_FilterBar onClick={(av2)=>{
                        onChangeView(av2)
                    }} size={"sm"} variant={is_mobile?"default":"underline"} x_padding="" active_id={av} items={collection.views.map(v=>{
                        return {
                            id: v.id,
                            label: v.label
                        }
                    })} />

                    {features.can_create_view && <CreateTab onClick={promptCreateView}/>}
                </div>
            </div>
            <div className="flex items-start space-x-2">
                {loading && <div>
                    <InlineLoader mini/>
                </div>}
                {save_changes && <div>
                    {save_changes}
                </div>}
                {!is_mobile && features.can_search && !is_mobile && <div>
                    <SDHSearch active_query={active_query} onChangeQuery={handleQueryChange}/>
                </div>}
                {features.can_filter && !is_mobile && <div>
                    <Button intent={show_filters ? `minimal-active` : 'minimal'} onClick={() => {
                        setShowFilters(!show_filters);
                    }} size={"small"} text="Filter"/>
                </div>}
                {features.can_sort && sort.length > 0 && !is_mobile && <div>
                    <SortMenu sort={sort} active_sort={active_sort} handleSetSort={handleSetSort}/>
                </div>}
                {actions.length > 0 && !is_mobile && <div>
                    <SDHActions actions={actions}/>
                </div>}
                {primary_actions.length > 0 && <div className="pl-1">
                    {primary_actions.map((action, i) => {
                        return <div key={i} className="">
                            {action}
                        </div>
                    })}
                </div>}

                {customize_props && features.can_customize && !is_mobile && <div>
                <SDHCustomize features={features} customize_props={customize_props}/>
            </div>}
            </div>
        </div>
        {show_filters && <div className="pt-1 h-10 px-4">
            <SDHFilters onAddFilter={handleAddFilter} onChangeFilter={handleChangeFilter} all_filters={filters}
                        handleApplyChanges={handleApplyChanges}
                        active_filters={active_filters}/>
        </div>}
    </>
}