import {authFetch} from "../../../../api/network";

export async function markAllAsRead(community) {
    const p2 = {
        community_uid: community.uid,
        member_id: community.member_id,
        user_uid: community.member.user_uid
    };
    return new Promise(resolve => {
        const r = () => {
            resolve();
        };
        authFetch('/notifications/mark-all-as-read', r, r, "POST", {payload: p2});
    })
}