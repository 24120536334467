import React from "react";

const _props = {};

function M3_A_FormControlGroup({children}) {

    return <div className="grid grid-cols-2 gap-3">
        {children}
    </div>
}

export default Object.assign(M3_A_FormControlGroup);