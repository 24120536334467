import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {PreviewHeader} from "./index";
import M3_A_ActionList from "../../atoms/action-list";
import {PageLayoutBlock, PageLayoutSection} from "../app-frame/layouts";
import {m3_icon_map} from "../../icons/icon-map";
import GettingStarted from "../getting-started";
import {getStartedMemberManifest} from "../getting-started/member-manifest";

export function HelpPreview({onClose, id, community, unaverse, data}) {
    const navigate = useNavigate();
    const [show_getting_started, setShowGettingStarted] = useState(false);

    // items will be community support, help center, technical support, and feedback
    const community_support_email = community?.profile?.contact_info?.email;
    let list_actions = [
        {
            leading_indicator: {
                type: "icon",
                icon: m3_icon_map.outlines.help
            },
            label: "Help Center",
            onClick: () => {
                const url = "https://theorange.app/help/getting-started/welcome-to-orange/";
                window.open(url, '_blank');
            }
        },
        {
            leading_indicator: {
                type: "icon",
                icon: m3_icon_map.outlines.email
            },
            label: "Technical Support",
            onClick: () => {
                // open email client to hey@getunaty.com with subject of "COMMUNITY_NAME Technical Support"
                const subject = encodeURIComponent(`${community.name} Technical Support`);
                const email = "hey@getunaty.com";
                window.open(`mailto:${email}?subject=${subject}`);
            }
        },
        {
            leading_indicator: {
                type: "icon",
                icon: m3_icon_map.outlines.gift
            },
            label: "Product Updates",
            onClick: () => {
                // open a url in a new tab
                const url = "https://theorange.app/product-updates/";
                window.open(url, '_blank');
            }
        }
    ];

    if (community_support_email) {
        const item = {
            leading_indicator: {
                type: "icon",
                icon: m3_icon_map.outlines.email
            },
            label: "Community Support",
            onClick: () => {
                // open email client to community_support_email with subject of "COMMUNITY_NAME Support"
                const subject = encodeURIComponent(`${community.name} Support`);
                window.open(`mailto:${community_support_email}?subject=${subject}`);
            }
        }
        // prepend the item to the list
        list_actions.unshift(item);
    }

    return <div>
        <PreviewHeader title="Help" onClose={() => onClose()}/>
        <PageLayoutSection gap="gap-6" narrow divider={false}>
            <PageLayoutBlock>
                <M3_A_ActionList size="sm" items={list_actions}/>
            </PageLayoutBlock>
            <PageLayoutBlock>
                <GettingStarted onLoad={(mf, already_completed) => {
                    // if steps are not completed, show the getting started
                    if (!already_completed) {
                        setShowGettingStarted(true);
                    }
                }} can_skip manifest={getStartedMemberManifest}/>
            </PageLayoutBlock>
        </PageLayoutSection>
    </div>
}