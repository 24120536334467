import {getItemFromLocalStorage, saveItemToLocalStorage} from "../../../m3/utilities/localstorage";

export const getStartedMemberManifest = {
    id: "get-started-member",
    title: "Get Started",
    steps: [
        {
            id: "member-types",
            title: "Setup Membership Levels",
            subtitle: "Match your community's structure to your needs.",
            icon: "user",
            filter: (member, community, user) => {
                return community.is_admin;
            },
            action: {
                label: "Profile",
                type: "click",
                onClick: (member,community) => {
                    window.open(`https://getunaty.com/${community.domain}/admin/settings/member-types`, '_blank', 'noopener');
                    saveItemToLocalStorage(`getstarted-membertypes-${community.id}`, true)
                }
            },
            completion: (member,community) => {
                // either has more than one membership level or has cookie
                return community.member_types.length > 1 || getItemFromLocalStorage(`getstarted-membertypes-${community.id}`, false);
            }
        },
        {
            id: "design-profile",
            title: "Design your Member Profile",
            subtitle: "Collect the right information from your members.",
            icon: "user",
            filter: (member, community, user) => {
                return community.is_admin;
            },
            action: {
                label: "Profile",
                type: "click",
                onClick: (member,community) => {
                    console.log("clicked",community)
                    window.open(`https://getunaty.com/${community.domain}/admin/settings/designer/member-profile`, '_blank', 'noopener');
                    saveItemToLocalStorage(`getstarted-designer-${community.id}`, true)
                }
            },
            completion: (member,community) => {
                // only cookie
                return getItemFromLocalStorage(`getstarted-designer-${community.id}`, false);
            }
        },
        {
            id: "design-membership-card",
            title: "Design Memberships Card",
            subtitle: "Brand your card to make members feel at home.",
            icon: "user",
            filter: (member, community, user) => {
                return community.is_admin;
            },
            action: {
                label: "Profile",
                type: "click",
                onClick: (member,community) => {
                    console.log("clicked",community)
                    window.open(`https://getunaty.com/${community.domain}/admin/settings/designer/membership-card`, '_blank', 'noopener');
                    saveItemToLocalStorage(`getstarted-card-${community.id}`, true)
                }
            },
            completion: (member,community) => {
                // only cookie
                return getItemFromLocalStorage(`getstarted-card-${community.id}`, false);
            }
        },
        {
            id: "get-started-member-1",
            title: "Complete Your Profile",
            subtitle: "Add a picture, short bio, and location",
            icon: "user",
            action: {
                label: "Profile",
                type: "link",
                to: "my-profile?edit=true"
            },
            completion: (member) => {
                return member.profile_picture && member.about.bio && member.about.location;
            }
        },
        {
            id: "get-started-member-2",
            title: "Meet with success team",
            subtitle: "Schedule a meeting with the success team.",
            icon: "calendar",
            filter: (member, community, user) => {
                return community.is_admin;
            },
            action: {
                label: "Schedule",
                type: "schedule",
                key: "schedule-member-success-team"
            },
            status: (member, community, user) => {
                const completed = user['success-onboarding-completed'];
                if (completed) return 'completed';
                return user['scheduled-success-meeting'] ? 'scheduled' : 'pending';
            },
            completion: (member, community, user) => {
                return user['success-onboarding-completed'];
            }
        },
        {
            id: "get-started-member-3",
            title: "Search the community",
            subtitle: "Find people and groups that interest you.",
            icon: "search",
            action: {
                label: "Search",
                type: "link",
                to: "search",
                onClick: (member,community) => {
                    // set local storage
                    saveItemToLocalStorage(`getstarted-search-${community.id}`, true)
                }
            },
            completion: (member, community) => {
                const result = getItemFromLocalStorage(`getstarted-search-${community.id}`, false)
                return result;
            }
        },
        {
            id: "get-started-member-4",
            title: "Explore Contacts",
            subtitle: "Discover members by their skills, interests, and location.",
            icon: "directory",
            action: {
                label: "Contacts",
                type: "link",
                to: "contacts",
                onClick: (member,community) => {
                    // set local storage
                    saveItemToLocalStorage(`getstarted-directory-${community.id}`, true)
                }
            },
            completion: (member, community) => {
                return getItemFromLocalStorage(`getstarted-directory-${community.id}`, false)
            }
        }
    ]
};