import React, {useEffect} from "react";
import {ArrowUpTrayIcon} from "@heroicons/react/24/outline";
import "./styles.css";

const isAdvancedUpload = function () {
    const div = document.createElement('div');
    return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
}();


export function SimpleImageDropzone({
                                        drop_only,
                                        children,
                                        multiple=false,
                                        id = "simple-image-dropzone",
                                        onChange = () => {
                                        }
                                    }) {
    const classes = isAdvancedUpload ? "has-advanced-upload" : "";

    useEffect(function () {
        const f = document.getElementById(id);
        if (isAdvancedUpload) {
            const a = (e) => {
                e.preventDefault();
                e.stopPropagation();
            };
            const b = (e) => {
                f.classList.add('is-dragover');
            };
            const c = (e) => {
                f.classList.remove('is-dragover');
            };
            const d = (e) => {
                if (e.dataTransfer.files[0]) {
                    onChange(e.dataTransfer.files[0]);
                }
            };
            f.addEventListener('drag', a);
            f.addEventListener('dragstart', a);
            f.addEventListener('dragend', a);
            f.addEventListener('dragover', a);
            f.addEventListener('dragleave', a);
            f.addEventListener('drop', a);

            f.addEventListener('dragover', b);
            f.addEventListener('dragcenter', b);

            f.addEventListener('dragleave', c);
            f.addEventListener('dragend', c);
            f.addEventListener('drop', c);

            f.addEventListener('drop', d);
        }
    }, []);

    if(drop_only) {
        return <div id={id} className="dropbox relative">
            {children}
            <div className="drop-note rounded-xl w-full h-full flex items-center justify-center bg-blue-100 pointer-events-none absolute top-0 left-0 right-0 bottom-0" style={{}}>
                <div className="w-20 h-20 text-blue-400">
                    <ArrowUpTrayIcon />
                </div>
            </div>
        </div>
    }

    return <form id={id} className={`box border border-gray-200 rounded-lg px-4 py-6 ${classes}`} method=""
                 onSubmit={(e) => {
                     e.preventDefault()
                 }} action="" encType="multipart/form-data">
        <div className="box__input text-gray-700 text-center">
            <div>
                <input onChange={(e) => {
                    if (e.target.files[0]) {
                        onChange(e.target.files[0]);
                    }
                }} accept="image/*" className="box__file hidden" type="file" name="files[]" id="file"
                       data-multiple-caption="{count} files selected" multiple={multiple}/>
            </div>
            <div>
                <label htmlFor="file"><span className="underline cursor-pointer">Choose a file</span><span
                    className="box__dragndrop"> or drag it here</span>.</label>
            </div>
        </div>
        <div className="box__uploading">Uploading…</div>
        <div className="box__success">Done!</div>
        <div className="box__error">Error! <span></span>.</div>
    </form>
}