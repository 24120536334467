import React from 'react';
import {GoogleIcon} from "./google-icon";
import {getIdToken} from 'firebase/auth';
import {AuthProviders, popupSignIn} from "../../../config/auth";
import {getCurrentLang, getCurrentTimezone} from "../../../../common/utilities/lang-region";
import Button from "../../../m3/_legacy_components/button";
import {authFetch} from "../../../../api/network";

const provider = new AuthProviders.GoogleAuthProvider;

// NO UID === not a user
//provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

export function SignInWithGoogle() {

    function createUserObject(user, handle, name, token_details) {
        const name_split = name.split(' ');

        const newUserData = {
            payload: {
                uid: user.uid,
                email: user.email,
                handle: handle,
                first_name: name_split[0],
                last_name: name_split[1] ? name_split[1] : ''
            },
            options: {
                language: getCurrentLang(),
                timezone: getCurrentTimezone()
            },

            token_details
        };

         authFetch("/users/create-account", handleResult.bind(this), handleError.bind(this), "POST", newUserData)
    }

    function handleResult(result) {
        console.log('handleResult', result)
    }

    function handleError() {
        console.log('handleError')
    }

    function openPopup() {
        console.log('open popup')
        popupSignIn(provider)
            .then((result) => {
                const {additionalUserInfo: {isNewUser}} = result;
                /** @type {firebase.auth.OAuthCredential} */
                let credential = result.credential;

                // This gives you a Google Access Token. You can use it to access the Google API.
                let token = credential.accessToken;
                // The signed-in user info.
                let user = result.user;
                // ...

                if (isNewUser) {
                    getIdToken(user, true)
                        .then((token) => {

                            document.cookie = '__session=' + token + ';max-age=3600';
                            return createUserObject(user, user.email.split('@')[0], user.displayName, {
                                credential,
                                token_details: user.stsTokenManager
                            })
                        });
                }
            }).catch((error) => {
                console.log('error', error)
            // Handle Errors here.
            let errorCode = error.code;
            let errorMessage = error.message;
            // The email of the user's account used.
            let email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            let credential = error.credential;
            // ...
        });
    }

    return <div>
        <Button size="large" fill left_icon={{icon:<div className="h-5 w-5"><GoogleIcon/></div>}} text="Continue with Google"
                onClick={() => openPopup()}/>
    </div>
}