import {activity_types, activity_types_ref} from "./types";
import React from "react";
import {Link} from "react-router-dom";
import {buildAddress} from "../profile-field-renderer/value-pipeline";
import {useCommunity} from "../../../config/community";
import {getMemberJoinDate} from "../../utilities/joined-date";
import {getPhoneNumberData} from "../../utilities/phone";
import {utils_dates_format} from "../../../../common/utilities/dates";
import {data_countries_map} from "../../../../common/data/data/countries";
import {data_languages_map} from "../../../../common/data/data/languages";
import {formatBirthday} from "../../../../common/utilities/birthday";

const positive = /\+\+(.*)\+\+/gim;
const disabled = /--(.*)--/gim;
const bold = /\*\*(.*)\*\*/gim;
const italics = /\*(.*)\*/gim
const link = /\[(.*?)\]\((.*?)\)/gim;

export function getValidPhoneStr(val) {
    if (!val) {
        return '';
    }
    const value = getPhoneNumberData(val);

    if (value.valid) {
        return value.international;
    }
    return val || '';
}

// todo clean up this function to an array of replace items and data points
export function hydrateDynamicText(m, data, context) {
    if (context && context.author) {
        m = m.replace(`{author_name}`, context.author.name);
        m = m.replace(`{author_id}`, context.author.id);
    }
    m = m.replace(`{post_id}`, data.post_id);
    m = m.replace(`{post_subject}`, data.post_subject);
    m = m.replace(`{member_name}`, data.member_name);
    m = m.replace(`{id}`, data.id);
    if (data.unread_count) {
        m = m.replace(`{unread_count}`, data.unread_count);
        m = m.replace(`{unread_count_plural}`, data.unread_count > 1 ? 's' : '');
    }
    m = m.replace(`{new_assignee_name}`, data.new_assignee_name);
    m = m.replace(`{old_assignee_name}`, data.old_assignee_name);
    m = m.replace(`{phone}`, getValidPhoneStr(data.phone));
    m = m.replace(`{admin_name}`, data.admin_name);
    m = m.replace(`{name}`, data.name);
    m = m.replace(`{version}`, data.version);
    m = m.replace(`{label}`, data.label);
    m = m.replace(`{old}`, data.old);
    m = m.replace(`{new}`, data.new);
    m = m.replace(`{join_date}`, getMemberJoinDate(data.join_date));
    m = m.replace(`{birthday}`, formatBirthday(data.birthday));
    m = m.replace(`{field}`, data.field);
    m = m.replace(`{address}`, buildAddress(data.address));
    m = m.replace(`{email}`, data.email);
    m = m.replace(`{timezone}`, data.timezone);
    m = m.replace(`{language}`, data_languages_map[data.language]);
    m = m.replace(`{old_handle}`, data.old_handle);
    m = m.replace(`{handle}`, data.handle);
    m = m.replace(`{meta}`, data.meta);
    m = m.replace(`{bio}`, data.bio);
    m = m.replace(`{occupation}`, data.occupation);
    m = m.replace(`{location}`, data.location);
    m = m.replace(`{group_email}`, data.group_email);
    m = m.replace(`{group_name}`, data.group_name);
    m = m.replace(`{policy_version}`, data.policy_version);
    m = m.replace(`{policy_name}`, data.policy_name);
    m = m.replace(`{moved_to_city}`, data.moved_to_city);
    m = m.replace(`{moved_from_city}`, data.moved_from_city);
    m = m.replace(`{moved_from_country}`, data_countries_map[data.moved_from_country]);
    m = m.replace(`{moved_to_country}`, data_countries_map[data.moved_to_country]);
    return m
        .replace(bold, '##bold:$1##')
        .replace(positive, '##positive:$1##')
        .replace(disabled, '##disabled:$1##')
        .replace(italics, '##italic:$1##')
        .replace(link, '##link:$1data:$2##')
        .trim();
}

const unaty_links = [
    'group',
    'role',
    'member',
    'post',
    'team'
];

const unaty_links_ref = {
    'group': {
        link: (ch, id) => {
            return `/c/${ch}/space/${id}`
        }
    },
    'role': {
        link: (ch, id) => {
            return `/c/${ch}/position/${id}`
        }
    },
    'member': {
        link: (ch, id) => {
            return `/c/${ch}/member-id/${id}`
        }
    },
    'post': {
        link: (ch, id) => {
            return `/c/${ch}/post/${id}`
        }
    },
    'team': {
        link: (ch, id) => {
            return `/c/${ch}/space/${id}`
        }
    }
};

export function renderDynamicText(f_arr, styles = {size: '4'}, ch) {

    return <div style={{lineHeight: '1.15rem'}}
                className={`text-gray-600 pt-0.5 ${styles.size === '4' ? "-mt-0.5" : ""}`}>
        {f_arr.map((it, k) => {
            if (it.startsWith('bold:')) {
                return <span className={`text-gray-800 font-medium`} key={k}>
                {it.replace('bold:', '')}
            </span>;
            } else if (it.startsWith('italic:')) {
                return <span className={`text-gray-600 italic`} key={k}>
                {it.replace('italic:', '')}
            </span>;
            } else if (it.startsWith('positive:')) {
                return <span className={`text-success-800 py-0.5 -my-0.5 px-0.5 rounded bg-success-50`} key={k}>
                {it.replace('positive:', '')}
            </span>;
            } else if (it.startsWith('disabled:')) {
                return <span className={`text-gray-400 py-0.5 line-through -my-0.5 px-0.5 rounded bg-gray-100`} key={k}>
                {it.replace('disabled:', '')}
            </span>;
            } else if (it.startsWith('link:')) {
                const sp = it.replace('link:', '').split('data:');
                const sp1_split = sp[1].split('=');
                const t = sp1_split[0];
                if (ch && unaty_links.includes(t)) {
                    return <Link className={`text-link font-medium text-gray-800 hover:underline cursor-pointer`}
                                 key={k} to={unaty_links_ref[t].link(ch, sp1_split[1])}>
                        {sp[0]}
                    </Link>
                }
                return <span className={`text-link font-medium text-gray-800 hover:underline cursor-pointer`} key={k}>
                    {sp[0]}
            </span>;
            } else {
                return <span className={``} key={k}>
                {it}
            </span>
            }
        })}
    </div>
}

export function parseEmailData(data) {
    return {
        ok: true,
        subject: data?.subject||"Untitled",
        reference_id: data?.reference_id||"",
        from: data?.from||"Unknown",
        preview_text: data?.preview_text||"",
        from_name: data?.from.split(' <')[0]||"Unknown",
        to_name: data?.to.split(' <')[0]||"Unknown",
        to: data?.to||"Unknown",
        sent_at: data?.sent_at||Date.now(),
    };
}

export function RichEmailActivityPreview({data}) {
    const community = useCommunity();
    const parsed = parseEmailData(data);
    if(!parsed.ok) {
        return null;
    }
    return <div onClick={()=>{
        community.openViewOverlay({
            type: 'view-email',
            data: {
                reference_id: parsed.reference_id
            },
            closeCb: ()=> {
community.closeViewModal();
            }
        })
    }}
        className="px-3 w-full py-2 hover:bg-gray-100 bg-white cursor-pointer group rounded-lg border border-gray-200 shadow-md">
        <div className="text-sm font-semibold group-hover:underline">{parsed.subject}</div>
        <div className="text-xs text-gray-700 pt-px flex gap-3">
            <div>
                {parsed.from_name}
            </div>
            <div>
                {utils_dates_format(parsed.sent_at).relative}
            </div>
        </div>
        <div className="pt-0.5 text-xs text-gray-700">
            {parsed.preview_text}
        </div>
    </div>
}

function renderCustomActivityItem(template, data, context) {
    switch (template) {
        case 'email':
            return <RichEmailActivityPreview data={data} />
        default:
            return null;
    }
}

export function AdminActivityItem({connector = 'none', date_formatted, show_time = false, type, ts, data, context}) {
    const activity_info = activity_types[type];
    if (!activity_info) {
        console.error('missing info', type)
        return null;
    }
    const styles = activity_types_ref[activity_info.type];

    let c;

    if(activity_info.template) {
        c = <div className="-mt-px mb-1">
            {renderCustomActivityItem(activity_info.template, data, context)}
        </div>;
    } else {
        const final = hydrateDynamicText(activity_info.message, data);
        const f_arr = final.split(/##/g);
        c = renderDynamicText(f_arr, styles)
    }

    return <div className="relative px-1 py-1">
        <div className={`flex space-x-3 z-10`}>
            <div className={`h-8 w-6 flex justify-center z-10 ${styles.size === '4' ? "-mt-0.5" : ""}`}>
                <div
                    className={`w-${parseInt(styles.size) + 1} h-${parseInt(styles.size) + 1} ${styles.size === '4' ? "mt-0.5" : ""} flex justify-center bg-white`}>
                    <div
                        className={`w-${styles.size} h-${styles.size} flex items-center justify-center text-white rounded-full ${styles.color}`}>
                        <div
                            className={`w-${parseInt(styles.size) - 1.5} h-${parseInt(styles.size) - 1.5} svg-child-inherit`}>
                            {activity_info.icon}
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-sm w-full">
                {c}
                {show_time && <div className="text-xs pt-0.5 pb-0.5 text-gray-500">
                    {date_formatted}
                </div>}
            </div>
        </div>
        {connector === 'line' && <div className="absolute top-1.5 bottom-0 left-1.5 justify-center flex w-5 ">
            <div className="w-0.5 bg-gray-400 h-full"/>
        </div>}
    </div>
}