import React, {useEffect, useState} from "react"
import './styles.css';
import ExternalAuthPage from "../../../m3/_legacy_components/external-auth-page";
import Field from "../../../m3/_legacy_components/form/field";
import Button from "../../../m3/_legacy_components/button";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {authApp, emailSignIn, findAccountByUnatyID} from "../../../config/auth";
import {EyeIcon, EyeSlashIcon, IdentificationIcon} from "@heroicons/react/24/outline";
import {useUnaverse} from "../../../config/unaverse";
import {SignInWithGoogle} from "../sign-in-with-google";
import {setDocumentTitle} from "../../../m3/utilities/set-document-title";
import {externalAuthFetch} from "../../../../api/network";
import {VerifyAccountPin} from "../verify-pin";
import {useToasts} from "../../../config/toasts";
import {checkPasswordStrength, PasswordField} from "../link/password";
import {handleResetPassword} from "../link/api";
import EmptyState from "../../../m3/_legacy_components/empty-state";
import MagicLinkBlock from "../magic-link/magic-link-block";
import SignInWithCode from "../sign-in-with-code";
import M3_A_Link from "../../../m3/atoms/link";
import {utils_strings_isEmail} from "../../../../common/utilities/strings";

export function IconAction({
                               onClick,
                               disabled,
                               icon,
                               color = "text-gray-500 hover:text-gray-700",
                               size = "small",
                               key,
                               children
                           }) {
    const wrapper_size = size === "small" ? "w-5 h-5" : size === 'medium' ? "h-6 w-6" : size === 'large' ? "h-7 w-7" : "h-6 w-6";
    const icon_size = size === "small" ? "w-4 h-4" : size === 'medium' ? "h-5 w-5" : size === 'large' ? "h-6 w-6" : "h-5 w-5";
    return <div key={key} onClick={(e) => {
        e.stopPropagation();
        if (!disabled && onClick) {
            onClick();
        }
    }} className={`${wrapper_size} flex p-0.5 ${color} ${disabled ? "opacity-40" : ""} cursor-pointer rounded-md`}>
        <div className={`inline-block ${icon_size}`}>
            {children}{icon}
        </div>
    </div>
}

export function TextAction({
                               disabled,
                               classNames = "",
                               children, color = 'text-blue-500', icon, text, size = "", onClick = () => {
    }
                           }) {
    const ts = size === "xs" ? "text-xs" : size === "sm" ? "text-sm" : "text-sm";
    return <div onClick={() => {
        if (!disabled) {
            if (onClick) {
                onClick();
            }
        }
    }}
                className={`${ts} ${disabled ? "opacity-60" : ""} ${classNames} inline font-medium ${color} hover:text-black transition-colors cursor-pointer tracking-tight`}>
        {children}
        {icon && <div className="inline-block w-3.5 h-3.5">
            {icon}
        </div>}
        {text}
    </div>
}

const error_codes = {
    "invalid-email": "Sorry, this email isn't valid.",
    "verification-error": "Something went wrong..",
    "handle-not-found": "Sorry, we couldn’t find an account with that handle. Can we help you recover your handle?",
    "email-not-found": "Sorry, we couldn’t find an account with that email. Can we help you recover your email?",
    "wrong-password": "That password you entered did not match our records. Please double check and try again."
};

export function SimpleErrorMessage({code = 'wrong-password'}) {
    return <div className="text-sm py-1 text-red-500">
        {error_codes[code]}
    </div>
}

async function checkMembershipStatus(email, community_uid) {
    const payload = {
        email, community_uid
    };

    return new Promise((resolve, reject) => {
        const res = (resp) => {
            resolve(resp);
        };

        externalAuthFetch("/check-for-community-account", res, res, "POST", {payload})
    })
}

function SignInForm({flow = "", navigate_to = '/', community_uid = "", handleStateChange, state = ""}) {
    let [searchParams, setSearchParams] = useSearchParams();
    const [status, updateStatus] = useState("");
    const [error, setError] = useState("");
    const [unatyid, setUnatyId] = useState(searchParams.get('email') || "");
    const [user_uid, setUserUid] = useState("");
    const [password, setPassword] = useState("");
    const [show_pw, setShowPw] = useState(false);
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const unaverse = useUnaverse();

    const unatyid_type = utils_strings_isEmail(unatyid.trim()) ? "email" : "handle";

    function checkForAccount() {
        setLoading(true);
        findAccountByUnatyID(unatyid)
            .then((resp) => {
                setLoading(false);
                if (resp) {
                    setError("");
                    updateStatus("account-found");
                    if (unatyid_type === 'email') {

                        checkMembershipStatus(unatyid, community_uid)
                            .then((resp) => {
                                if (!resp || !resp.data) {
                                    console.log("no response")
                                    return;
                                }
                                setCode(resp.data.code)
                                setUserUid(resp.data.user_uid || "")
                            })
                    }
                } else {
                    if (unatyid_type === 'email') {
                        setError("email-not-found");
                    } else {
                        setError("handle-not-found");
                    }
                }
            })
    }

    function handleSignIn() {
        setLoading(true);
        unaverse.setLoader(0.1);
        setError("");
        emailSignIn(unatyid, password)
            .then(() => {
                unaverse.setLoader(0.64);
                setTimeout(function () {
                    unaverse.setLoader(1);
                }, 800)
                navigate(navigate_to)
            })
            .catch(() => {
                setError("wrong-password");
                setLoading(false);
            })
    }

    function handleFieldEnter() {
        if (status === 'account-found' && unatyid && password) {
            handleSignIn();
        } else if (unatyid) {
            checkForAccount();
        }
    }

    function renderButton() {
        if (status === 'account-found') {
            return <Button disabled={!password || !unatyid} loading={loading} onClick={() => {
                handleSignIn()
            }} size="large"
                           intent="primary" text="Continue" fill/>
        }
        return <Button disabled={!unatyid} loading={loading} onClick={() => checkForAccount()} size="large"
                       intent="primary" text="Continue" fill/>
    }

    const right_icon = {
        icon: show_pw ? <EyeSlashIcon/> : <EyeIcon/>,
        onClick: () => {
            setShowPw(!show_pw)
        }
    };

    if (status === 'magic-link') {
        return <div className="w-full max-w-sm">
            <div className="pb-4">
                <div className="text-2xl text-gray-800 font-bold">Magic Sign In Link</div>
            </div>
            <MagicLinkBlock email={unatyid}/>
            <div className="pt-4">
                <M3_A_Link variant="secondary" onClick={() => updateStatus("")}>Back to Sign In</M3_A_Link>
            </div>
        </div>
    } else if (status === "sign-in-code") {
        return <div className="w-full max-w-sm">
            <div className="pb-4">
                <div className="text-2xl text-gray-800 font-bold">Sign In Code</div>
            </div>
            <SignInWithCode email={unatyid}/>
            <div className="pt-4">
                <M3_A_Link variant="secondary" onClick={() => updateStatus("")}>Back to Sign In</M3_A_Link>
            </div>
        </div>
    }

    return <div className="w-full max-w-sm">
        <div>
            <div className="text-2xl text-gray-800 font-bold">Welcome Back</div>
        </div>
        <div className="space-y-4 mt-6">
            <Field name="email" onEnter={() => handleFieldEnter()} value={unatyid} onChange={(a, b) => setUnatyId(b)}
                   type="text" label="Unaty ID"
                   input_props={{placeholder: "Email or @handle"}}>
                {(error === 'handle-not-found' || error === 'email-not-found') && <SimpleErrorMessage code={error}/>}
            </Field>

            {status === 'account-found' &&
                <Field name="password" placeholder="********" autoFocus onEnter={() => handleFieldEnter()}
                       right_icon={right_icon} value={password} onChange={(a, b) => setPassword(b)} label="Password"
                       corner_hint={<TextAction onClick={() => {
                           navigate('/forgot-flow')
                       }} size="xs">Forgot your password?</TextAction>} type={show_pw ? "text" : "password"}>

                    {(error === 'wrong-password') && <SimpleErrorMessage code={error}/>}
                </Field>}
            {code && code === 'force-reset-password' && <div className="text-sm text-gray-600">
                This looks like your first time signing in. Please <a
                onClick={() => handleStateChange('first-sign-in', {unatyid, user_uid})} className="link cursor-pointer">verify
                your email address</a> to continue.
            </div>}
            <div className="">
                {renderButton()}
            </div>
            {status === 'account-found' && <div className="text-xs text-gray-600 text-center">
                Request a <a onClick={() => {
                updateStatus("sign-in-code")
            }}
                             className="link">Sign In code</a> link sent to your email.
            </div>}
            {status === 'account-found' && <div className="text-xs text-gray-600 text-center">
                Get a <a onClick={() => {
                updateStatus("magic-link")
            }} className="link">Magic Sign In</a> link sent to your email.
            </div>}
        </div>
        {status !== 'account-found' && <div>
            <div className="py-3 text-center text-gray-600 text-sm">
                or
            </div>
            <SignInWithGoogle/>

        </div>}
        {status !== 'account-found' && <div className="pt-4">
            <div className="text-xs space-y-2 text-center text-gray-600">
                <TextAction onClick={() => navigate('/forgot-flow')}>Forgot your Unaty ID or password?</TextAction>
                <div>New to Orange? <TextAction onClick={() => navigate('/create-account')}>Sign up</TextAction></div>
            </div>

        </div>}


    </div>
}

export async function sendFirstSignInCode(user_uid, email) {
    const username = email;

    return new Promise((resolve, reject) => {
        const res = (resp) => {
            resolve(resp)
        };

        externalAuthFetch("/send-verification-code", res, res, "POST", {username});
    })
}

function FirstSignIn({
                         onBack = () => {
                         }, state_data = {}
                     }) {
    const {unatyid, user_uid} = state_data;
    const navigate = useNavigate();
    const [state, setState] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState('');
    const [authCode, setAuthCode] = useState("");
    const [password, setPassword] = useState("");
    const toasts = useToasts();

    useEffect(() => {
        sendPinEmail();
    }, []);

    function sendPinEmail() {
        sendFirstSignInCode(user_uid, unatyid)
            .then((r) => {
                if (!r) {
                    return;
                }
                if (r.data.ok === 'yes') {
                    setState('sent');
                } else {
                    setState('in-use');
                }
            })
    }

    function resetPassword() {
        console.log("RESET PASSWORD", password, authCode, user_uid, unatyid)
        handleResetPassword(authApp, authCode, password)
            .then((resp) => {
                console.log("handleResetPassword", resp)
                if (resp.ok === 'yes') {
                    setState("password-updated");
                    const payload = {
                        profile_picture: "",
                        profile_picture_color: "",
                        name: "",
                        email: unatyid
                    };

                    externalAuthFetch('/confirm-password-reset', () => {
                    }, () => {
                    }, 'POST', {email: unatyid, payload});

                    emailSignIn(unatyid, password)
                        .then(() => {
                            console.log("SIGN IN SUCCESS")
                            navigate(`/c/${window.location.pathname.split('/')[2]}/home`)
                        })
                } else {

                }
            })
    }

    function handlePinSuccess(code) {
        console.log("CODe", code)
        setAuthCode(code);
        setState('set-password');
    }

    if (!utils_strings_isEmail(unatyid)) {
        return <div className="space-y-4">
            <div className="text-2xl text-gray-800 font-bold">Welcome to Orange</div>

            <div className="text-gray-600 text-sm">
                This email doesn't quite look right. Please try again.
            </div>

            <div>
                <M3_A_Link variant="secondary" onClick={() => onBack()}>Back to Sign In</M3_A_Link>
            </div>
        </div>
    }

    if (state === 'password-updated') {
        return <div className="space-y-4">
            <div className="text-2xl text-gray-800 font-bold">Welcome to Orange</div>

            <div className="text-gray-600 text-sm">
                Your password has been updated.
            </div>
        </div>
    }

    if (state === 'set-password') {
        let result = checkPasswordStrength(password);

        return <div className="space-y-4">
            <div className="text-2xl text-gray-800 font-bold">Welcome to Orange</div>

            <div className="text-gray-600 text-sm">
                Your email has been verified. Please set a password to continue.
            </div>

            <div>
                <PasswordField label="Set your Password" placeholder="New Password" autoFocus onChange={(a, v) => {
                    setPassword(v);
                }} score={result.score}
                               value={password}/>
            </div>
            <div>
                <Button intent='primary' fill size='large' disabled={result < 2}
                        onClick={resetPassword}
                        text={'Set Password & Login'}
                        loading={loading === "loading"}/>
            </div>
        </div>
    }

    return <div className="space-y-4">
        <div className="text-2xl text-gray-800 font-bold">Welcome to Orange</div>

        <div className="text-gray-600 text-sm">
            It looks like you're new here. Please verify your email address to continue.
        </div>

        <div className="mt-4">
            <VerifyAccountPin code_sent onSuccess={handlePinSuccess} username={unatyid}/>
        </div>

        <div className="text-gray-600 text-sm">
            Want to use a different email address? <TextAction onClick={() => {
            onBack()
        }}>Sign in with a different email</TextAction>
        </div>
    </div>
}

export default function SignInPage({
                                       domain,
                                       navigate_to = '/',
                                       styles = {}, state_data = {}, handleStateChange = () => {
    }, community_uid, flow = "standard", state = "sign-in"
                                   }) {

    setDocumentTitle(`Sign in`);

    return <ExternalAuthPage bg_image={styles?.background_image || "/images/custom/seven.jpg"}
                             image={styles.image || ""}
                             show_logo={flow !== 'community'}>
        {state === 'first-sign-in' && <FirstSignIn onBack={() => {
            handleStateChange('sign-in', {})
        }} state_data={state_data}/>}
        {state === 'sign-in' &&
            <SignInForm navigate_to={navigate_to} handleStateChange={handleStateChange} community_uid={community_uid}
                        flow={flow} state={state}/>}
        {state === 'apply-to-join' && <div>
            <EmptyState icon={<IdentificationIcon/>}
                        title={`To join this community, follow the link below.`}
                        subtitle={<>
                            You can also return to <Link className="underline" to={`/`}>home</Link>.
                        </>}
                        button={<Button text="Apply to Join" intent="primary" onClick={() => {
                            const external_url = `https://getunaty.com/${domain}/sign-in`;
                            window.open(external_url, '_blank', 'noopener,noreferrer');
                        }}/>}
            />
        </div>}
    </ExternalAuthPage>
};