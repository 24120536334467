import React from 'react';
import M3_A_InlineEntity from "./inline-entity";
import M3_A_Text from "./text";
import M3_C_Breadcrumbs from "../components/breadcrumbs";
import M3_A_IconButton from "./icon-button";
import {m3_icon_map} from "../icons/icon-map";
import M3_A_Avatar from "./avatar";

/*
* This is the ContextBar atom
 */

const _props = {};

function renderComponent(type, data) {
    switch (type) {
        case "avatar":
            return <M3_A_Avatar {...data} />
        case "breadcrumbs":
            return <M3_C_Breadcrumbs gap="" {...data} />;
        default:
            return null;
    }
}

export default function M3_A_ContextBar({type, children, size, icon = 'back', onIconClick = () => {}, onClose, transparent=true, actions = [], variant = "default", data}) {

    if (variant === "nav") {
        return <div className="flex gap-2 w-full h-11 py-1.5 items-center">
            <div className="flex-grow h-8 flex items-center gap-1.5">
                {icon && <div className="flex-none -ml-1 inline-flex items-center h-8 w-8">
                    <M3_A_IconButton onClick={onIconClick} compact square icon_size="base" variant={!transparent?`dark`:`minimal`}
                                     icon={m3_icon_map.solid[icon]}/>
                </div>}
                <div className="flex-grow inline-flex items-center h-8">
                    {children}
                </div>
            </div>
            <div className="h-8 flex items-center">
                {actions.map(action => {
                    return <div key={action.icon_name} className="">
                        <M3_A_IconButton intent={action?.intent} size={size} adjust_icon_size={0} square onClick={action?.onClick} compact variant={!transparent?`dark`:`minimal`} icon={m3_icon_map.outlines[action.icon_name]} />
                    </div>
                })}
            </div>
        </div>
    } else if(variant === "panel") {
        return <div className="flex gap-2 w-full items-center">
            <div className="flex-grow">
                {children}
            </div>
            <div>
                <M3_A_IconButton onClick={()=>{
                    if(onClose) {
                        onClose()
                    }
                }} compact icon_size="h-6 w-6" variant={"minimal"}
                                 icon={m3_icon_map.solid["close"]}/>
            </div>
        </div>
    }

    return (
        <div className="flex gap-2">
            <div className="flex-grow flex items-center gap-4">
                <div>
                    {renderComponent(type, data)}
                </div>
                {children && <div>
                    {children}
                </div>}
            </div>
            <div>

            </div>
        </div>
    );
}