import React, {useEffect, useRef, useState} from "react"
import './styles.css';
import {convertFromRaw} from "draft-js";
import {
    CommunityContentPage,
    LayoutContentBlock,
    LayoutContentWrapper,
    LayoutStandard, PageLayoutBlock, PageLayoutSection
} from "../../../m3/_legacy_components/app-frame/layouts";
import {
    ExclamationCircleIcon,
    QuestionMarkCircleIcon,
    HandThumbDownIcon,
    HandThumbUpIcon
} from "@heroicons/react/24/solid";
import {Avatar} from "../../../m3/_legacy_components/avatar";
import {
    ArrowRightIcon,
    ChevronLeftIcon, LinkIcon
} from "@heroicons/react/24/outline";
import {ContentBlocksShowcase} from "../write/blocks/showcase";
import MessageReactions, {EmojiSelectorPopup} from "../../../m3/_legacy_components/reactions";
import {ContentPopupMenu} from "../../../m3/_legacy_components/content-popup/components";
import {useNavigate, useParams} from "react-router-dom";
import {DEFAULT_USER_PHOTO, useCommunity} from "../../../config/community";
import {getMessageData} from "../../../../api/messages/api";
import {InlineLoader} from "../../../m3/_legacy_components/admin-activity";

import {ViewDraftJsContent} from "../../../features/forum/utilities/view-content";
import {RepliesContainer} from "./replies/replies-container";
import {UniversalStats} from "../../../m3/_legacy_components/entity-profile-card";
import {EllipsisHorizontalIcon} from "@heroicons/react/20/solid";
import SimpleTooltip from "../../../m3/_legacy_components/tooltip";
import FollowButton from "../../../m3/_legacy_components/follow-button";
import {MessageWidgetImages} from "./images/widget";
import {collection, doc, getDoc, getDocs, limit, orderBy, query, startAfter, where} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {getItemFromLocalStorage} from "../../../m3/utilities/localstorage";
import ContentPopup from "../../../m3/_legacy_components/content-popup";
import {messages_deletePost} from "../../../../api/messages/delete";
import {useToasts} from "../../../config/toasts";
import {copyTextToClipboard} from "../../../m3/utilities/copy-to-clipboard";
import {getGroupData} from "../group/api";
import MessageAttachments from "../../../features/forum/forum/shared/attachments";
import {ArrowAction} from "../manage-member/edit-member";
import {EditedMessage} from "./replies/reply";
import {parseRawContent} from "../../../features/forum/forum/api";
import {quickPinItemToSidebar} from "../../../m3/layouts/layout/section-builder/sidebar-wrapper";
import {MessageReactionsActionsWrapper, updateReactionData} from "./reactions/actions-reactions-wrapper";
import {PostStatsDelivered} from "./stats";
import {utils_dates_format} from "../../../../common/utilities/dates";
import {buildImageUrl} from "../../../../common/utilities/images";

function Title({title = "", onClick}) {
    return <div className="">
        <div onClick={()=>{
            if(onClick) {
                onClick();
            }
        }} className={`text-2xl leading-tight font-bold text-gray-900 break-words ${!!onClick?"hover:underline cursor-pointer":""}`}>{title}</div>
    </div>
}

export function DotsOptionsPopup({items = [], top_align}) {

    const content = <ContentPopup>
        <ContentPopupMenu items={items}/>
    </ContentPopup>;

    return <SimpleTooltip simple trigger="click" interactive hideOnClick={true} placement="bottom-end"
                          text={content}>
        <div
            className={`h-5 w-5 cursor-pointer transition-opacity hover:opacity-70 text-gray-500 ${top_align ? "mb-2" : ""}`}>
            <EllipsisHorizontalIcon/>
        </div>
    </SimpleTooltip>
}

export function MessageHeader({
                                  size = 'large',
                                  top_date,
                                  onTitleClick,
                                  author_image,
                                  follow,
                                  goToMember,
                                  top_align = true,
                                  member_id,
                                  author_id = '',
                                  flags = {},
                                  options = [],
                                  date,
                                  context,
                                  groups,
                                  author_name
                              }) {
    function getSubtitle() {
        if (context === "reply") {
            return null
        }
        return <>
            <div>{date}</div>
        </>
    }

    const subtitle = getSubtitle();

    const show_organizer_tag = flags.is_organizer && member_id === author_id;

    const show_author_tag = flags.is_post_author && member_id === author_id;

    const show_tags = show_organizer_tag || show_author_tag;

    const avatar_size = size === 'large' ? 'h-8 w-8' : size === 'medium' ? 'h-6 w-6' : 'h-5 w-5'

    const container_height = size === 'large' ? 'h-10' : size === 'medium' ? 'h-8' : 'h-6';

    return <div className={`grid gap-2 ${container_height}`} style={{gridTemplateColumns: 'auto 1fr auto'}}>
        <div className={`${container_height} flex items-center`}>
            <Avatar url={buildImageUrl(author_image, "_medium", DEFAULT_USER_PHOTO)} size={avatar_size}/>
        </div>
        <div>
            <div className="text-sm items-baseline flex gap-2">
                <div onClick={() => {
                    if (author_id) {
                        goToMember();
                        //  navigate(`/c/${community.domain}/member/${author_id}`)
                    }
                }}
                     className="text-sm pt-px inline-block hover:underline tracking-tight cursor-pointer text-gray-800 font-semibold">
                    {author_name}
                </div>

                {show_tags && show_author_tag && <div className="text-xs">
                    <span className="text-green-700 font-medium">Author</span>
                </div>}

                {top_date && <div className="text-xs">
                    <span className="text-gray-500">{top_date}</span>
                </div>}
            </div>
            {subtitle && <div className="flex space-x-3 text-xs text-gray-500">
                {subtitle}
            </div>}
        </div>
        <div className={`flex items-center gap-3 pr-2 ${container_height}`}>
            {follow && <div>{follow}</div>}
            {options.length > 0 && <DotsOptionsPopup top_align={top_align} items={options}/>}
        </div>
    </div>
}

function Content({preview, onOpenLighbox, content_state}) {
    const fns = {
        onOpenLighbox: onOpenLighbox
    }
    return <div>
        {preview && <ContentBlocksShowcase/>}
        {!preview && content_state &&
            <ViewDraftJsContent fns={fns} style={{}} classes='prose prose-base text-gray-800'
                                content_state={content_state}/>}
    </div>
}

const map = {
    'like': <HandThumbUpIcon/>,
    'dislike': <HandThumbDownIcon/>,
    'haha': <HandThumbDownIcon/>,
    'emphasize': <ExclamationCircleIcon/>,
    'question': <QuestionMarkCircleIcon/>,
};

export function Reactions({mock, community_uid, handleReact, handleAddReaction, reactions = {}, show_empty}) {
    //   like, dislike, haha, emphasize, question + custom
    const community = useCommunity();
    return <MessageReactions member_id={community.member_id} community_uid={community_uid} handleReact={handleReact}
                             handleAddReaction={handleAddReaction} reactions={reactions} can_react/>
}

export function SimpleBackButton({onClick, icon = <ChevronLeftIcon/>}) {
    return <div onClick={() => {
        if (onClick) {
            onClick();
        }
    }} className="flex cursor-pointer transition-opacity hover:opacity-70 items-center space-x-2">
        <div className="h-5 w-5 text-gray-500">
            {icon}
        </div>
    </div>
}

export function MessageActions({
                                   data, handleAddReaction = () => {
    }, onReply = () => {
    }, show_reply_count = true, text_size = 'text-sm', post_link = ''
                               }) {
    const toasts = useToasts();
    let options = [];

    if (!!data.replies_count && data.replies_count > 0 && show_reply_count) {
        if (data.replies_count > 1) {
            options.push({
                label: `${data.replies_count} replies`,
                onClick: onReply
            })
        } else {
            options.push({
                label: `${data.replies_count} reply`,
                onClick: onReply
            })
        }

    } else {
        options.push({
            label: `Reply`,
            onClick: onReply
        })
    }

    options.push({
        label: "React",
        is_react: true
    });

    if (post_link) {
        options.push({
            label: "Share",
            options: [
                {
                    icon: <LinkIcon/>,
                    label: "Copy link",
                    onClick: () => {
                        copyTextToClipboard(`${window.location.origin}${post_link}`)
                        toasts.addToast({
                            text: "Link copied to clipboard",
                            intent: "success"
                        })
                    }
                }
            ]
        });
    }

    return <div className={`flex flex-wrap gap-5 ${text_size} `}>
        {options.map((c, key) => {
            const child = <div onClick={() => {
                if (c.onClick) {
                    c.onClick();
                }
            }} className="font-semibold cursor-pointer hover:text-gray-400 transition-colors text-gray-600">
                {c.label}
            </div>;

            if (c.is_react) {
                return <EmojiSelectorPopup key={c.label} autoFocus={false} onAddReaction={handleAddReaction}>
                    {child}
                </EmojiSelectorPopup>
            }

            if (c.options) {
                const menu = <ContentPopup>
                    <ContentPopupMenu items={c.options}/>
                </ContentPopup>;

                return <div className="flex justify-center" key={key}>
                    <SimpleTooltip usePortal simple trigger="click" interactive hideOnClick={true}
                                   placement="bottom-end"
                                   text={menu}>
                        {child}
                    </SimpleTooltip>
                </div>
            }

            return <div className="flex justify-center" key={key}>
                {child}
            </div>
        })}
    </div>
}

function MessageStats({data}) {
    let stats = [];

    if (data.series_name) {
        stats.push({
            label: data.series_name
        })
    }

    if (!!data.views) {
        stats.push({
            count: data.views,
            label: `Views`
        })
    }

    return <div className="overflow-x-auto no-scrollbars">
        <UniversalStats stats={stats}/>
    </div>
}

export function PostSidebarView({data, id}) {
    if (!data) return null;
    let content_state = convertFromRaw(parseRawContent(data.raw));
    return <div>
        <Title title={data?.subject || ""}/>
        <MessageHeader context={data?.context} date={data?.formatted_date?.short_date} author_id={data?.author_id || ""}
                       author_image={data?.author_image || ""} author_name={data?.author_name || ""}/>
        <Content content_state={content_state}/>
    </div>
}

async function getNextPost(community_uid, group_id, id) {
    const ref = doc(db, 'community_content', community_uid, 'inboxes', `group-${group_id}`, 'posts', id);
    const _doc = await getDoc(ref);
    if (!_doc.exists) {
        return null;
    }
    const next_ref = query(collection(db, 'community_content', community_uid, 'inboxes', `group-${group_id}`, 'posts'), where('type', '==', 'post'), orderBy('ts', 'desc'), startAfter(_doc), limit(1));
    return await getDocs(next_ref)
        .then(snap => {
            if (snap.docs.length > 0) {
                const post_ref = doc(db, 'community_content', community_uid, 'posts', snap.docs[0].id);
                return getDoc(post_ref)
                    .then(doc => {
                        if (!doc.exists) {
                            return null;
                        } else {
                            return {
                                id: doc.id,
                                ...doc.data()
                            }
                        }
                    })
            } else {
                return null;
            }
        });
}

function NextPost({id, data, community_uid, group_id}) {
    const [next_post, setNextPost] = useState(null);
    const community = useCommunity()
    const navigate = useNavigate();
    useEffect(function () {
        if (id) {
            getNextPost(community_uid, group_id, id)
                .then(pr => {
                    if (pr) {
                        setNextPost(pr);
                    }
                })
                .catch(err => {
                    console.log("ERR", err)
                })
        }
    }, [id])

    function handleClick() {
        community.openPreviewOverlay({
            type: 'post',
            id: next_post.id,
            data: next_post
        })
    }

    if (!data) return null;
    if (!next_post) return null;
    if (!next_post.subject) return null;
    return <div className="pt-4 pb-4 px-4 border-t border-gray-200">
        <div>
            <div className="flex group items-center cursor-pointer" onClick={handleClick}>
                <div className="flex-grow">
                    <div className="text-gray-500 text-sm">
                        Read the next post
                    </div>
                    <div className="text-base group-hover:underline font-bold text-gray-800">
                        {next_post?.subject || ""}
                    </div>
                </div>
                <div className="flex-none">
                    <ArrowAction onClick={handleClick}>
                        <ArrowRightIcon/>
                    </ArrowAction>
                </div>
            </div>
        </div>
        <div></div>
    </div>
}

function getEmojiFromId(key) {
    if (!key) {
        return "";
    }
    const data = getItemFromLocalStorage('emoji_data', null);
    if (data && data.emojis) {
        if (data.emojis[key]) {
            //   return data.emojis[key];
            if (data.emojis[key].skins?.[0]) {
                return data.emojis[key].skins?.[0].native;
            }
        }
    }
    return key;
}

function youReacted(reactions, rid, member_id) {
    if (!reactions) {
        return false;
    }

    if (!rid) {
        return false;
    }

    if (!member_id) {
        return false;
    }

    if (!reactions[rid] || !Array.isArray(reactions[rid])) {
        return false;
    }

    return !!reactions?.[rid]?.includes(member_id);
}

export function ReactionWrapper({
                                    reactions,
                                    reactions_order,
                                    reactions_counts,
                                    reactions_members,
                                    handleReact,
                                    handleAddReaction
                                }) {
    const community = useCommunity();

    if (!reactions_order) {
        return null;
    }
    const keys = reactions_order && reactions_order.length > 0 ? reactions_order : Object.keys(reactions);

    const formatted_reactions = keys.map(key => {
        const emoji = getEmojiFromId(key);
        return {
            id: key,
            count: reactions_counts[key],
            emoji,
            members: reactions[key],
            you: youReacted(reactions, key, community.member_id)
        }
    })
    return <div>
        <Reactions community_uid={community.uid} handleReact={handleReact} handleAddReaction={handleAddReaction}
                   reactions={formatted_reactions}/>
    </div>
}

function RelatedPosts({
                          community_uid = '',
                          group_id = '',
                          post_id = '',
                      }) {
    const [posts, setPosts] = useState([
        {
            subject: "Hello",
            author: "John Doe"
        }
    ]);

    useEffect(function () {
        /*
        getRelatedPosts(community_uid,group_id,post_id)
            .then(posts => {
                console.log('posts:',posts)
            })

         */
    }, [post_id])

    if (!post_id) {
        return null;
    }

    if (posts.length === 0) {
        return null;
    }

    return <div>
        <div className="text-lg font-bold">
            Related Posts
        </div>
        <div className="space-y-2 pt-1">
            {posts.map((post, index) => {
                return <div key={index} className="flex items-center">
                    <div className="flex-1">
                        <div className="text-sm font-bold">
                            {post.subject}
                        </div>
                        <div className="text-xs text-gray-500">
                            {post.author}
                        </div>
                    </div>
                </div>
            })}
        </div>
    </div>
}

export function getPinToOptions(community, type, id) {
    let can_pin_to_sidebar_sections = [];

    const item_link = `/c/${community.domain}/${type}/${id}`;

    community.sidebar_sections.forEach((section, ind) => {
        let section_contains_item = false;
        section.items.forEach(item => {
            if (item?.link?.startsWith(item_link)) {
                section_contains_item = true;
            }
        })

        if (!section_contains_item) {
            can_pin_to_sidebar_sections.push({
                label: section.name,
                index: ind
            })
        }
    })

    return can_pin_to_sidebar_sections;
}

export function MessagePageWrapper() {
    const {id} = useParams();

    return <MessagePage id={id} />
}

export default function MessagePage({id,preview,init_data}) {
    const community = useCommunity();
    const toasts = useToasts();
    const navigate = useNavigate();
    const [state, setState] = useState("loading");
    const [group_data, setGroupData] = useState({});
    const [data, setData] = useState(init_data||null);
    const [error, setError] = useState("");

    const is_localhost = window.location.hostname === "localhost";
    const current_data = useRef(init_data||{});

    useEffect(function () {
        current_data.current = data;
    }, [data])

    useEffect(function () {
        setData(null);
        setState("loading");
        if (id) {
            getMessageData(community.id, id)
                .then(pr => {
                    if(is_localhost) {
                        console.log("pr",pr)
                    }
                    if (pr) {
                        if (pr.group_id) {
                            getGroupData(community.id, pr.group_id)
                                .then(group => {
                                    setGroupData(group);
                                })
                        }
                        setData({
                            ...pr,
                            groups: `${pr.group_id || ""}`,
                            context: {
                                id: pr.context_id,
                                type: pr.context_type,
                                community_uid: community.uid,
                            },
                            formatted_date: utils_dates_format(pr.ts), id: id
                        });
                    } else {
                        setError("does_not_exist")
                    }
                    setState("");
                })
        }
    }, [id])

    if(!id){
        return <div>
            error..
        </div>;
    }

    function updatePostReactions({reaction_id, action}) {
        const {
            new_reactions,
            new_reactions_counts,
            new_reactions_members,
            new_reactions_order
        } = updateReactionData(reaction_id, action, current_data.current, community);
        const new_object = {
            reactions: new_reactions,
            reactions_counts: new_reactions_counts,
            reactions_members: new_reactions_members,
            reactions_order: new_reactions_order
        };
        setData({
            ...data,
            ...new_object
        })
    }

    function handleOpenAttachment(attachment) {
        community.setLightbox({
            context: data.context_type || "group",
            context_id: data.context_id || "",

            type: "post",
            id: id,
            data: data,

            starting_index: data.attachments.findIndex(it => it.id === attachment.id),
            items: [
                ...data.attachments
            ]
        });
    }

    function handleOpenLightbox(imgs, key) {
        community.setLightbox({
            context: data.context_type || "group",
            context_id: data.context_id || "",

            type: "post",
            id: id,
            data: data,

            overview: {
                back: {
                    label: "Post Photos"
                },
                title: "Post Photos"
            },

            starting_index: key,
            items: [
                ...imgs
            ]
        });
    }

    function handleDeletePost() {
        const group_id = data.group_id;
        messages_deletePost(community, {
            post_id: id
        })
            .then(() => {
                navigate(`/c/${community.domain}/space/${group_id}`)
                toasts.addToast({
                    text: "Post Deleted",
                    intent: "success"
                })
            })
    }

    function scrollToReply(id) {
        const el = document.getElementById(id);
        if (el) {
            el.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
        }
    }

    let content;

    let message_images = data?.images || [];

    if (state === "loading") {
        content = <LayoutContentWrapper>
            <div className="flex justify-center p-20">
                <InlineLoader/>
            </div>

        </LayoutContentWrapper>
    } else if (error) {
        content = <LayoutContentWrapper>
            <div className="flex justify-center p-20">
                <div className="text-sm text-gray-500">
                    This post does not exist.
                </div>
            </div>
        </LayoutContentWrapper>
    } else if (data && !data.raw) {
        content = <div>
            activity change..
        </div>
    } else if (data) {

        const post_link = `/c/${community.domain}/messages/${id}`;

        let content_state = convertFromRaw(parseRawContent(data.raw));

        let options = [];

        const is_organizer = group_data?.moderators?.user_uids?.[community.member_id] || false;
        const is_author = data.author_id === community.member_id;
        const can_delete = is_author || community.is_admin;

        if (is_author) {
            options.push({
                label: "Edit Post",
                onClick: () => {
                    community.openComposer({
                        to: [],
                        config: {},
                        closeCb: (dt) => {
                            if (dt && dt.subject && dt.raw) {
                                setData({
                                    ...data,
                                    subject: dt.subject,
                                    raw: dt.raw
                                })
                            }
                        },
                        post_id: id,
                        edit: true,
                        content: '',
                        raw: data?.raw || null,
                        subject: data?.subject || "",
                        type: 'post',
                        template: ''
                    })
                }
            })
        }

        if (can_delete) {
            options.push({
                label: "Delete Post",
                onClick: () => {
                    if (window.confirm("Are you sure you want to delete this post?")) {
                        handleDeletePost();
                    }
                }
            })
        }

        const pin_to_options = getPinToOptions(community, "message", id);

        if (pin_to_options.length > 0) {
            options.push({
                type: "subheader",
                label: "Pin to sidebar:"
            })

            pin_to_options.forEach(opt => {
                options.push({
                    label: opt.label,
                    onClick: () => {
                        toasts.addToast({
                            text: "Pinning to sidebar",
                            intent: "info"
                        })
                        quickPinItemToSidebar(community, opt.index, {
                            type: "message",
                            url: `/c/${community.domain}/post/${id}`,
                            color: "",
                            image: "",
                            emoji: "",
                            name: data?.subject
                        })
                            .then(()=>{
                                toasts.addToast({
                                    text: "Pinned to sidebar",
                                    intent: "success"
                                })
                            })
                    }
                })
            })
        }


        const follow_btn = id && <FollowButton icon_only updateFollowing={(fl) => {
        }} id={id} type="post"/>;
        // <RelatedPosts group_id={data.group_id} community_uid={community.uid} post_id={data.id} />

        const has_topic = !!(data?.series_name);

        const show_stats_bar = has_topic || is_author;

        const show_mailing_stats = !!data?.mailgun_sent_to_count;


        //    scrollToReply("post-reply-LF1wj1VEiLQZynQn3X4i");
        /*
           <MobileSinglePageHeader type="group" group_data={group_data || {}} group_id={data?.context_id}
                                        post_id={id}
                                        onTitleClick={() => {
                                            if (data?.context_id) {
                                                navigate(`/c/${community.domain}/group/${data.context_id}`)
                                            }
                                        }}
                                        onBack={() => {
                                            if (data?.context_id) {
                                                navigate(`/c/${community.domain}/group/${data?.context_id}`)
                                            } else {
                                                navigate(`/c/${community.domain}/home`)
                                            }
                                        }} {...{
                    data, following, setFollowing
                }} />
         */
        let onTitleClick = preview ? () => {
            // go to /post/:id
            navigate(`/c/${community.domain}/post/${id}`)

            // also close the preview overlay
            community.closePreviewOverlay();
            } : null;

        content = <LayoutContentWrapper>
            <LayoutContentBlock id={"post-content"}>
                <div className="pt-2">
                    <Title onClick={onTitleClick} title={data?.subject || ""}/>
                </div>
                <div className="pt-4">
                    <MessageHeader follow={follow_btn} options={options} context={data?.context}
                                   author_id={data?.author_id}
                                   goToMember={() => {
                                       community.openPreviewOverlay({
                                           type: 'member',
                                           id: data?.author_id
                                       })
                                   }}
                                   date={data?.formatted_date?.short_date} top_align={false}
                                   author_image={data?.author_image || ""}
                                   author_name={data?.author_name || ""}/>
                </div>
                <div className="pt-4">
                    <Content onOpenLighbox={(k) => {
                        if (typeof k === "number") {
                            handleOpenLightbox(message_images, k || 0);
                        } else {
                            const img_index = message_images.findIndex(it => it.url === k);
                            handleOpenLightbox(message_images, img_index || 0);
                        }
                    }} content_state={content_state}/>
                </div>
                {data.attachments.length > 0 && <div className="flex pt-2 pb-2">
                    <MessageAttachments onOpenAttachment={handleOpenAttachment} attachments={data.attachments}/>
                </div>}
                {data.edited_at && <div className={`pt-2 pb-0.5`}><EditedMessage data={data}/></div>}
                {show_stats_bar && <div className="pt-2">
                    <MessageStats series_name={data.series_name} data={data}/>
                </div>}
                {show_mailing_stats&&<div className="pt-2">
                    <PostStatsDelivered is_author_or_admin={is_author||community.is_admin} list_id={""} community_uid={community.uid} post={data}/>
                </div>}
                <MessageReactionsActionsWrapper onReply={() => {
                    const el = document.getElementById('post-reply-listener');
                    if (el) {
                        el.focus();
                    } else {
                        console.log('no element')
                    }
                }} getUpdatedData={() => current_data.current} post_id={data.id}
                                                updatePostReactions={updatePostReactions} data={data}
                                                post_link={post_link}/>
                <div className="border-t border-gray-200 mt-3" id="post-replies">
                    <RepliesContainer flags={{
                        is_post_author: is_author,
                        is_organizer: is_organizer
                    }} post_id={id} replies_count={data.replies_count ? data.replies_count : 0}/>
                </div>

                {data.context_type === 'group' &&
                    <NextPost group_id={data.context_id} community_uid={community.uid} id={id} data={data}/>}
            </LayoutContentBlock>
        </LayoutContentWrapper>;
    } else {
        content = <LayoutContentWrapper>
            ...?
        </LayoutContentWrapper>
    }

    if(preview){
        return content;
    }

    const sidebar = <>
        {data && <PageLayoutSection divider={false}>
            {data.images.length > 2 && <PageLayoutBlock>
                <MessageWidgetImages images={message_images} onOpenLighbox={(url, color, all, key) => {
                    handleOpenLightbox(message_images, key);
                }}/>
            </PageLayoutBlock>}
        </PageLayoutSection>}
    </>;

    return <CommunityContentPage sidebar={sidebar} header={{
        title: "Post"
    }}>
        {content}
    </CommunityContentPage>
};