
export const data__profile_section_visibility = {
    'all-members': {
        'label': "All Members",
        "description": "Visible to all members of the community"
    },
    'private': {
        'label': "Private",
        "description": "Visible only to the member"
    },
    'admins-only': {
        'label': "Admins Only",
        "description": "Visible only to admins of the community"
    },
    'read-only': {
        'label': "Read Only",
        "description": "Visible only to the member, but read-only"
    },
    'hidden': {
        'label': "Hidden",
        "description": "Hidden from view"
    }
};

export const data__profile_section_visibility_arr = Object.entries(data__profile_section_visibility).map(([k, v]) => {
    return {
        value: k,
        label: v.label,
    }
})