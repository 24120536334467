import React, {useEffect, useState} from "react";
import UserPage from "../user";
import CommunityPage from "../community";
import {useParams} from "react-router-dom";
import EntityProfileCard from "../../../m3/_legacy_components/entity-profile-card";
import {LayoutContentBlock, LayoutContentWrapper, LayoutStandard} from "../../../m3/_legacy_components/app-frame/layouts";
import Lightbox from "../../../m3/_legacy_components/lightbox";
import {buildCardMeta} from "../../../m3/utilities/build-card-meta";
import {LockClosedIcon} from "@heroicons/react/24/solid";
import SimpleTooltip from "../../../m3/_legacy_components/tooltip";
import Button from "../../../m3/_legacy_components/button";
import {collection, doc, getDoc} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {useUnaverse} from "../../../config/unaverse";
import {DEFAULT_USER_PHOTO} from "../../../config/community";
import {buildImageUrl} from "../../../../common/utilities/images";

function transformedProfile(raw) {
    if (raw.type === "community") {
        return {
            id: raw.id,
            type: raw.type,
            handle: raw.handle,
            name: raw.data.name,

            bio: raw.data.about.bio || "",

            profile_picture: raw.data.profile_picture || "",
            profile_picture_color: raw.data.profile_picture_color || "",

            cover_photo: raw.data.cover_photo || "",
            cover_photo_color: raw.data.cover_photo_color || "",
        }
    } else {

        return {
            id: raw.id,
            type: raw.type,
            handle: raw.handle,
            name: raw.data.name,

            bio: raw.data.about.bio || "",

            profile_picture: raw.data.profile_picture || "",
            profile_picture_color: raw.data.profile_picture_color || "",

            cover_photo: raw.data.cover_photo || "",
            cover_photo_color: raw.data.cover_photo_color || "",
        }
    }
}

const mock_profile = {
    id: "1324",
    type: "user",

    name: "James Joyce",
    handle: "jjoyce",

    bio: "I'm a multi-media philosopher who believes in the life changing power.",

    verified: true,

    profile_picture: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2FVTgxRoNW7_medium?alt=media",
    profile_picture_color: "#000000",

    cover_photo: "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/cover_photos%2F5s85pL_T5_medium?alt=media",
    cover_photo_color: "#000000",

    emoji: "",

    leaders: {},

    locations: {},

    about: {
        blocks: [
            {
                type: "multiline-text",
                label: "Mission",
                value: "String that does support @s and #s and basic links.."
            },
            {
                type: "url",
                label: "Website",
                value: "https://www.vitalground.com"
            },
            {
                type: "date",
                label: "Founded",
                value: "08-31-1981"
            },
            {
                type: "date",
                label: "Headquarters",
                value: "Missoula, MT"
            }
        ]
    },

    tags: [
        "Environmental"
    ],

    meta: {
        type: "Non-profit",
        occupation: "Product Manager @ Accenture",
        joined: "03-2004",
        location: "Missoula, MT",
        birthday: '10-10'
    },

    social: {
        website: "https://vitalground.org"
    }
};

const followed_by = [
    {
        name: "Ernest Hemingway"
    },
    {
        name: "James Beard"
    }
];

function EditProfileButton({onClick}) {
    return <Button text="Edit Profile" onClick={() => onClick()}/>
}

function BlockedButton({toggle, blocked}) {
    return <Button text="Blocked" intent="danger" onClick={() => toggle()}/>
}

function RequestFollowButton({toggle, requested}) {
    return <Button text={requested ? "Pending" : "Follow"} intent={requested ? `` : `dark`} onClick={() => toggle()}/>
}

function FollowingButton({toggle, following = true}) {
    return <Button text={following ? `Following` : `Follow`} intent={following ? "" : "dark"} onClick={() => toggle()}/>
}

async function getData(handle) {
    const ref = doc(db, "unaverse_profiles", handle);
    return await getDoc(ref).then(doc => {
        return doc.exists() ? doc.data() : null;
    });
}

function transformResponse(response) {
    if (!response) {
        return {
            does_not_exist: true
        }
    }
    let base = {
        suspended: false,
        banned: false,
        requested_follow: false,
        private: false,
        blocked: false,
        is_you: false,
        follows_you: false,
        blocked_you: false,
        following: false,
        does_not_exist: false,
        ...transformedProfile(response)
    };

    return base;
}

async function getUnaverseProfileData(handle) {
    return await new Promise((resolve, reject) => {
        getData(handle)
            .then((response) => {
                resolve(transformResponse(response))
            })
    });
}

/*

Blocked
@handle is blocked
Are you sure you want to view their profile?

+ Block @handle
@handle will no longer be able to follow you and you will not see any notifications from @handle

You were blocked
@handle blocked you
You are blocked from following @handle and viewing their profile

Not followed by anyone you're following

Your account is permanently suspended
After careful review, we determined your account broke the Unaty Rules. Your account is in read-only mode, which means you can't X. You won't be able to create new accounts. IF you think we got this wrong, you can submit an appeal.

Locked
After careful review....


does not exist
@handle for the name

This account does't exist
Try searching for another

protected

This account is private....


Suspended
Account suspended
Unaty suspends accounts which violate the Unaverse Rules

Not Found
Block

 */

export function FollowsYouTag() {
    return <div className="text-xs font-medium text-gray-600 px-1.5 py-0.5 bg-gray-200 rounded-md">Follows you</div>
}

function transformProfile(pr, handle, people, openLightbox, extras) {
    let title = "", buttons = [], peeps, layout = {}, pre_suffix, meta = [], image, subtitle_suffix, image_color,
        desc = "",
        subtitle = "", icon, message;

    let show_tabs = false, show_content = false;

    icon = {
        onClick: (url) => openLightbox(url),
        type: "image",
        large: true
    };

    layout.large_image = true;

    if (pr.does_not_exist) {
        title = `@${handle}`;

        message = {
            title: "This account doesn't exist",
            subtitle: "Try searching for another.",
            icon: null
        };

    } else if (pr.banned) {
        title = `@${handle}`;

        message = {
            title: "Account permanently suspended",
            subtitle: <span>Unaty suspends accounts which repeatedly violate the <span className="text-blue-500">Unaverse Rules</span></span>,
            icon: null
        };
    } else if (pr.suspended) {
        title = `${pr.name}`;
        subtitle = `@${handle}`;

        message = {
            title: "Account suspended",
            subtitle: <span>Unaty suspends accounts which violate the <span
                className="text-blue-500">Unaverse Rules</span></span>,
            icon: null
        };
    } else if (pr.blocked_you) {
        title = `@${handle}`;

        message = {
            title: `@${handle} blocked you`,
            subtitle: <span>You are blocked from following @{handle} and viewing their profile</span>,
            icon: null
        };
    } else {
        //   show_content = true;
        //     show_tabs = true;

        if (pr.private) {
            pre_suffix = <SimpleTooltip text="Private Account
        ">
                <div style={{width: '1rem'}} className="h-auto text-gray-800">
                    <LockClosedIcon/>
                </div>
            </SimpleTooltip>;
        }

        if (pr.is_you) {
            buttons.push(<EditProfileButton onClick={() => extras.editProfile()}/>);
        } else {
            if (extras.blocked) {
                buttons.push(<BlockedButton blocked={extras.blocked} toggle={() => extras.toggleBlocked()}/>);
            } else if (pr.private && pr.can_follow) {
                if (pr.following || pr.follows_you) {
                    buttons.push(<FollowingButton following={extras.following}
                                                  toggle={() => extras.toggleFollowing()}/>);
                } else {
                    buttons.push(<RequestFollowButton requested={extras.requested_follow}
                                                      toggle={() => extras.toggleRequestedFollow()}/>);
                }
            } else if (pr.can_follow) {
                buttons.push(<FollowingButton following={extras.following} toggle={() => extras.toggleFollowing()}/>);
            }
        }

        if (people) {
            peeps = {...people};
        }

        if (pr.private && !extras.following) {
            //
            icon = {
                type: 'image'
            };
        } else {
            meta = buildCardMeta(pr);
            desc = `${pr.bio}`;
        }

        image = buildImageUrl(pr.cover_photo);

        image_color = pr.cover_photo_color;

        icon = {
            type: "image",
            large: true,
            image: buildImageUrl(pr.profile_picture, '_medium', extras.fallback_profile_picture),
            onClick: () => openLightbox(buildImageUrl(pr.profile_picture, '', extras.fallback_profile_picture)),
            color: pr.profile_picture_color
        };

        title = `${pr.name}`;
        subtitle = `@${handle}`;
    }

    if (pr.follows_you) {
        subtitle_suffix = <FollowsYouTag/>;
    }

    return {
        title,
        subtitle,
        subtitle_suffix,
        icon,
        show_content,
        layout,
        desc,
        meta,
        show_tabs,

        is_you: pr.is_you,

        buttons,

        image,
        pre_suffix,
        // people: peeps,
        image_color,

        following: false,
        follows_you: false,
        can_follow: false,

        message
    };
}

function editProfile(pr, fl) {
    let ob = {...pr};

    if (fl) {
        ob[fl] = !ob[fl];
    }

    return ob;
}

const flags = [
    'normal',
    'private',
    'banned',
    'suspended',
    'blocked',
    'is_you',
    'requested_follow',
    'blocked_you',
    'does_not_exist'
];

export function UnaverseProfile() {
    const {handle} = useParams();
    const unaverse = useUnaverse();
    const [profile, setProfile] = useState();
    const [following, setFollowing] = useState(false);
    const [requested_follow, setRequestedFollow] = useState(false);
    const [blocked, setBlocked] = useState(false);
    const [flag, setFlag] = useState("");
    const [lightbox, setLightbox] = useState([]);

    function openLightbox(url) {
        setLightbox([
            {
                url
            }
        ])
    }

    useEffect(function () {
        if (handle) {
            getUnaverseProfileData(handle)
                .then(pr => {
                    setProfile({
                        ...pr,
                        is_you: pr.id && pr.id === unaverse.uid
                    });
                })
        }
    }, [handle, unaverse]);

    const loading = !profile;

    function handleToggleFollow() {
        setFollowing(!following);
    }

    function handleToggleRequest() {
        setRequestedFollow(!requested_follow);
    }

    function handleToggleBlock() {
        setBlocked(!blocked);
    }

    if (loading) {
        return <LayoutStandard>
            <EntityProfileCard loading={true}/>
        </LayoutStandard>
    }

    const people = {
        profiles: [
            {},
            {},
            {},
            {},
            {},
        ],
        prefix: "Followed by",
        suffix: "you follow"
    };

    const edited_profile = editProfile(profile, flag);

    const extras = {
        requested_follow,
        toggleRequestedFollow: () => handleToggleRequest(),
        following,
        blocked,
        fallback_profile_picture: DEFAULT_USER_PHOTO,
        editProfile: () => unaverse.openEditOverlay({type: 'edit-user', data: unaverse.profile}),
        toggleBlocked: () => handleToggleBlock(),
        toggleFollowing: () => handleToggleFollow()
    };

    const transformed_profile = transformProfile(edited_profile, handle, people, openLightbox, extras);

    /*
       <div className="flex flex-wrap space-x-2">Flags: {flags.map(f => {
                return <div className="cursor-pointer hover:underline" key={f} onClick={() => {
                    setFlag(f)
                    if (f === 'blocked') {
                        setBlocked(true)
                    } else {
                        setBlocked(false);
                    }
                }}>
                    {f}
                </div>
            })}</div>
     */
    return <LayoutStandard >
        <LayoutContentWrapper entity>
            <LayoutContentBlock>
                {profile && profile.type === 'user' && <UserPage loading={loading} profile={transformed_profile}/>}
                {profile && profile.type === 'community' &&
                    <CommunityPage loading={loading} profile={transformed_profile}/>}

                {lightbox.length > 0 && <Lightbox images={lightbox} onClose={() => setLightbox([])}/>}

            </LayoutContentBlock>
        </LayoutContentWrapper>
    </LayoutStandard>
}