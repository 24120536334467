import React from 'react';
import M3_A_Text from "./text";
import {m3_adjust_size} from "../utilities";

/*
* This is the Label atom
 */

const _props = {

};

const label_color_classes_map = {
    "outline": {
        "blue": "bg-blue-100 text-blue-800 border-blue-200",
        "green": "bg-green-100 text-green-800 border-green-200",
        "gray": "bg-gray-100 text-gray-800 border-gray-200",
        "yellow": "bg-yellow-100 text-yellow-800 border-yellow-200",
        "purple": "bg-purple-100 text-purple-800 border-purple-200",
        "red": "bg-red-100 text-red-800 border-red-200",
        "teal": "bg-teal-100 text-teal-800 border-teal-200"
    },
    "solid": {
        "blue": "bg-blue-500 text-white border-blue-500",
        "green": "bg-green-500 text-white border-green-500",
        "gray": "bg-gray-500 text-white border-gray-500",
        "yellow": "bg-yellow-500 text-white border-yellow-500",
        "purple": "bg-purple-500 text-white border-purple-500",
        "red": "bg-red-500 text-white border-red-500",
        "teal": "bg-teal-500 text-white border-teal-500"
    }
};

const size_height_map = {
    "xs": "h-4",
    "sm": "h-5",
    "base": "h-6",
    "lg": "h-7",
    "xl": "h-8"
};

const padding_size_map = {
    "xs": "px-1",
    "sm": "px-1",
    "base": "px-1",
    "lg": "px-1.5",
    "xl": "px-2"
};

export default function M3_A_Label({children,variant='outline',size="sm",sx,is_interactive,color="gray"}) {
    let classes = `${size_height_map[size]} ${padding_size_map[size]} rounded-lg border border-solid inline-flex justify-center items-center`;

    let style = {
        minWidth: "1.2rem"
    };

    classes += ` ${label_color_classes_map[variant][color]}`;

    if(is_interactive) {
        classes += " transition-opacity hover:opacity-80 cursor-pointer";
    }

    if(sx) {
        style = {
            ...sx,
            minWidth: "1.2rem"
        };
    }

    return (
        <div className={classes} style={style}>
            <M3_A_Text sx={{
                lineHeight: "normal"
            }} weight="font-medium" size={size}>
                {children}
            </M3_A_Text>
        </div>
    );
}