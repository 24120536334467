import React, {useState} from 'react';
import SimplePortal from "../../../../m3/_legacy_components/simple-portal";
import Modal from "../../../../m3/_legacy_components/modal";
import MetaHeader from "../../../../m3/_legacy_components/meta-header";
import FormFieldsRenderer from "../../../../m3/_legacy_components/form-fields-renderer";
import Button from "../../../../m3/_legacy_components/button";
import {DateRange} from "./date-range";
import {TextAction} from "../../../auth/sign-in";
import {useCommunity} from "../../../../config/community";
import {useToasts} from "../../../../config/toasts";
import {api_deletePastAssignee} from "../../../../../api/groups/past-assignees";
import {api_addPastAssignee} from "../../../../../api/groups/past-assignees";

const mock_data = {
    member_id: '',
    from_year: null,
    from_month: null,
    to_year: null,
    to_month: null,
};

export function EditPastAssigneeModal({
                                          data = {}, id, onChange = () => {
    }, onClose = () => {
    }, item_id = ""
                                      }) {
    const community = useCommunity();
    const toasts = useToasts();
    const [dt, setDt] = useState({...mock_data, ...data});
    const [state, setState] = useState("");

    const mode = item_id ? "edit" : "create";

    function handleDelete() {
        setState("loading");
        api_deletePastAssignee(community, {item_id,id}).then(() => {
            setState("");
            toasts.addToast({text: "Past Assignee deleted successfully", intent: "success"});
            onClose();
        }).catch(e => {
            setState("");
            toasts.addToast({text: "Past Assignee could not be deleted", intent: "danger"});
        });
    }

    function handleUpdate(f, v) {
        let ed = {...dt};
        if (f === 'range') {
            const final = {...ed, ...v};
            setDt({...final})
        } else if (f === 'org_data') {
            ed[f]['title'] = v;
            setDt({...ed});
        } else {
            ed[f] = v;
            setDt(ed);
        }
    }

    function onSubmit() {
        setState("loading");
        if (mode === 'create') {
            api_addPastAssignee(community, {
                ...dt,
                id
            })
                .then((r) => {
                    toasts.addToast({text: "Past assignee added", intent: "success"});
                    setState("");
                    onClose();
                })
        } else {
            api_addPastAssignee(community, {
                ...dt,
                id,
                item_id
            })
                .then((r) => {
                    toasts.addToast({text: "Past assignee updated", intent: "success"});
                    setState("");
                    onClose();
                })
        }
    }

    const enabled = dt.from_month !== null && dt.from_year !== null && dt.to_month !== null && dt.to_year !== null && dt.member_id;

    const f = [
        {
            label: "Assignee",
            accessor: "member_id",
            type: 'assignee',
            corner_hint: <TextAction text="Assign to me" onClick={() => {
                setDt({
                    ...dt,
                    member_id: community.member_id
                })
            }}/>,
            value: dt.member_id,
            input_props: {
                community_uid: community.id
            },
            col_span: 2
        }
    ];

    return (
        <SimplePortal>
            <Modal center size="small">
                <div className="py-2 px-4 relative">
                    <MetaHeader can_close title="Add Past Assignee" onClose={onClose}/>
                    <div className="pt-2 space-y-4">
                        <FormFieldsRenderer onChange={(a, b) => {
                            handleUpdate('member_id', b);
                        }} data={dt} fields={f}/>
                        <DateRange onChange={(v) => handleUpdate('range', v)} range={{
                            current: false,
                            ...dt
                        }} enable_current_check={false} labels={{start: 'Start date', end: 'End Date'}}
                                   current_check_text={`I'm currently working in this role`}
                                   type='month-year'/>
                    </div>
                    <div className="flex pt-3 pb-1">
                        <div className="flex-grow">
                            {mode==='edit' && <Button onClick={handleDelete} loading={state === "loading"} text="Delete" />}
                        </div>
                        <div>
                            <Button disabled={!enabled} intent={`primary`} loading={state === "loading"}
                                    onClick={() => {
                                        onSubmit();
                                    }} text={`Continue`}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </SimplePortal>
    );
}