import React from 'react';
import {LockClosedIcon} from "@heroicons/react/24/solid";

export const table_of_contents_mock = [
    {
        title: "My Groups",
        key: "my-groups",
        onTitleClick: () => {},
        items: [
            {
                label: "Ambassador Team",
                count: "103",
                key: "ambassador-team",
                icon: null,
                onClick: () => {},
                isActive: () => false,
                can_expand: false,
                items: []
            },
            {
                label: "Everyone",
                count: "2.5k",
                key: "everyone",
                icon: null,
                open: true,
                onClick: () => {},
                isActive: () => true,
                can_expand: true,
                items: [
                    {
                        label: "New Members",
                        key: "new-members",
                        onClick: () => {},
                        isActive: () => false,
                    },
                    {
                        label: "Most Active",
                        key: "most-active",
                        onClick: () => {},
                        isActive: () => false,
                    }
                ]
            },
            {
                label: "Hub: Los Angeles",
                count: "57",
                key: "hub-los-angeles",
                icon: null,
                onClick: () => {},
                isActive: () => false,
                can_expand: false,
            },
            {
                label: "Fall in Love with a Sandboxer",
                count: "94",
                key: "filws",
                icon: null,
                onClick: () => {},
                isActive: () => false,
                can_expand: false,
            },
            {
                label: "Data Quality Initiative to Rebuild Trust",
                count: "42",
                key: "dqirt",
                icon: null,
                onClick: () => {},
                isActive: () => false,
                can_expand: false,
            },
            {
                label: "Hub: Vancouver",
                count: "61",
                key: "hub-vancouver",
                icon: null,
                onClick: () => {},
                isActive: () => false,
                can_expand: false,
            },
            {
                label: "Initiative Superhive",
                count: "75",
                key: "initiative-superhive",
                icon: null,
                onClick: () => {},
                isActive: () => false,
                can_expand: false,
            },
            {
                label: "Hub: Porto",
                count: "42",
                key: "hub-porto",
                icon: null,
                onClick: () => {},
                isActive: () => false,
                can_expand: false,
            },
        ]
    },
    {
        title: "Collections",
        key: "collections",
        can_add: true,
        onAdd: () => {},
        items: [
            {
                label: "Engaged Members",
                count: "238",
                key: "engaged-members",
                icon: <LockClosedIcon />,
                isActive: () => false,
                onClick: () => {},
                can_expand: false,
                items: [
                    {
                        label: "Most Engaged",
                        key: "most-engaged",
                        onClick: () => {},
                        isActive: () => false,
                    },
                    {
                        label: "Going Cold",
                        key: "going-cold",
                        onClick: () => {},
                        isActive: () => false,
                    }
                ]
            },
        ]
    }
];