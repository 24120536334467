import React, {useEffect, useRef, useState} from "react"
import './styles.css';
import SimplePortal from "../simple-portal";
import {ManageMember} from "../../../routes/community/manage-member";
import {CreateMember} from "../../../routes/community/manage-member/create-member";
import {AnimatePresence, motion} from "framer-motion";
import {EditMember} from "../../../routes/community/manage-member/edit-member";
import {EditUser} from "../../../routes/unaverse/manage-user/edit-user";
import {useLocation} from "react-router-dom";
import {CatchUp} from "../../../routes/community/catch-up";
import {AddGroup} from "../../../routes/community/groups/add-group";
import {GroupAddPeople} from "../../../routes/community/group/add-people/modal";
import {AddPosition} from "../../../routes/community/leaders/add-position";
import {ManagePosition} from "../../../routes/community/position/manage";
import {AddEvent, EditEventWrapper} from "../../../routes/community/calendar/add-event";
import {EditCoverPhotoWrapper, EditProfilePictureWrapper} from "../edit-image-modal";
import {EmailPreviewModal} from "../../../routes/community/manage-member/emails";
import {AddEducationModal} from "../../../routes/community/member/education/add";
import {AddCareerModal} from "../../../routes/community/member/career/add";
import {OnboardingDesignerModal} from "../../../routes/community/onboarding/designer";
import {ProfileDesignerModal} from "../../../routes/community/admin/profile-designer";
import {GroupMembersModal} from "../../../routes/community/group/members/modal";

function getSize(size) {
    if (size === 'medium') {
        return {
            max_width: '32rem',
            width: "w-full"
        }
    } else if (size === 'plus') {
        return {
            max_width: '40rem',
            width: "w-full"
        }
    } else if (size.includes('rem')) {
        return {
            max_width: size,
            width: "w-full"
        }
    }
    return {
        max_width: '60rem',
        width: "w-full"
    }
}

function getContents(type, p) {
    switch (type) {
        case 'add-education':
            return <AddEducationModal {...p} />
        case 'add-career':
            return <AddCareerModal {...p} />
        case 'edit-user':
            return <EditUser {...p} />
        case 'add-member':
            return <CreateMember {...p} />
        case 'view-email':
            return <EmailPreviewModal {...p} />
        case 'edit-cover-photo':
            return <EditCoverPhotoWrapper {...p} />
        case 'edit-profile-picture':
            return <EditProfilePictureWrapper {...p} />
        case 'add-group':
            return <AddGroup {...p} />
        case 'add-event':
            return <AddEvent group_id={p?.data?.group_id || ""} {...p} />
        case 'edit-event':
            return <EditEventWrapper {...p} />
        case 'add-position':
            return <AddPosition {...p} />
        case 'add-group-members':
            return <GroupAddPeople {...p} />
        case 'view-space-members':
            return <GroupMembersModal {...p} />
        case 'edit-member':
            return <EditMember {...p} />
        case 'manage-member':
            return <ManageMember {...p} />
        case 'manage-position':
            return <ManagePosition {...p} />
        case 'catch-up':
            return <CatchUp {...p} />
        case 'onboarding-designer':
            return <OnboardingDesignerModal {...p} />
        case "profile-designer":
            return <ProfileDesignerModal {...p} />
        case 'custom':
            return null;
        default:
            return null;
    }
}

export default function EditModal({
                                      open,
                                      onCloseCb,
                                      onClose = () => {
                                      },
                                      custom,
                                      type,
                                      children,
                                      simple,
                                      data,
                                      size = 'large',
                                  }) {
    const {width, max_width} = getSize(size);
    const location = useLocation();
    const [mounted, setMounted] = useState(false);

    useEffect(function () {
        if (!mounted) {
            setTimeout(function () {
                setMounted(true)
            }, 100)
        }
    }, [mounted])

    function handleClose(pl) {
        if (onCloseCb) {
            onCloseCb(pl);
        }
        onClose();
    }

    const p = {
        onClose: handleClose,
        data
    };
    const [portal_open, setPortalOpen] = useState(open);
    const loc = useRef(location.pathname);

    useEffect(function () {
        if (loc.current !== location.pathname) {
            handleClose();
        }

        loc.current = location.pathname;
    }, [location.pathname])

    useEffect(function () {
        if (!open && portal_open) {
            setTimeout(function () {
                setPortalOpen(false);
            }, 120)
        } else if (open && !portal_open) {
            setPortalOpen(true);
        }
    }, [open, portal_open])

    return <SimplePortal open={portal_open} center>
        <AnimatePresence>
            <motion.div
                initial={{opacity: 0, scale: 0.75}}
                animate={{opacity: 1, scale: 1}}
                exit={{opacity: 0, scale: 0}}
            >
                {open && <div style={{maxWidth: max_width}} id="modal-scrollarea"
                              className={`${width} ${simple ? "" : "bg-white popup-shadow sm:rounded-xl"} min-h-screen sm:min-h-0 mx-auto sm:mt-10 sm:mb-20 relative`}>
                    {!custom && mounted && getContents(type, p)}
                    {mounted && children}
                </div>}
            </motion.div>
        </AnimatePresence>
    </SimplePortal>
};