import React from "react";
import DataWrapper from "../data-wrapper";
import dayjs from "dayjs";
import {useCommunity} from "../../../config/community";
import {LockClosedIcon} from "@heroicons/react/20/solid";
import {MemberGroupsList, ShowMoreEntitiesWrapper} from "./index";
import {getPhoneNumberData} from "../../utilities/phone";
import {getMemberJoinDate} from "../../utilities/joined-date";
import {utils_strings_isEmail} from "../../../../common/utilities/strings";
import {data_countries_map} from "../../../../common/data/data/countries";
import ViewInterests from "../view-interests";
import {formatBirthday} from "../../../../common/utilities/birthday";

let relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime)

export function isValidHttpUrl(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export function parseSocialUrl(v, base = 'twitter.com') {
    if (!v) {
        return '';
    }
    let val = v.toLowerCase();
    const l = val.length;
    if (base === 'eth_address') {
        return val;
    }
    if (isValidHttpUrl(val)) {
        return `__CUSTOM__${val}`;
    }
    if (val.indexOf(`https://${base}/`) !== -1) {
        return val;
    } else if (val.indexOf(`http://${base}/`) !== -1) {
        return val;
    } else if (val.indexOf(`${base}/`) !== -1) {
        return `https://${val}`;
    } else {
        return `https://${base}/${val.replace(/@/g, '')}`;
    }
}

export function handleProfileSocialFields(social) {
    let a = [];
    if (social.website) {
        a.push({
            type: 'website', value: `${formatWebsiteForProfile(social.website)}`
        });
    }
    if (social.twitter) {
        a.push({
            type: 'url-twitter', value: `${parseSocialUrl(social.twitter)}`
        });
        console.log("ADDED TW", a)
    }

    if (social.facebook) {
        a.push({
            type: 'url-facebook', value: `${parseSocialUrl(social.facebook, 'facebook.com')}`
        });
    }
    if (social.linkedin) {
        a.push({
            type: 'url-linkedin', value: `${parseSocialUrl(social.linkedin, 'linkedin.com/in')}`
        });
    }

    if (social.instagram) {
        a.push({
            type: 'url-instagram', value: `${parseSocialUrl(social.instagram, 'instagram.com')}`
        });
    }

    if (social.eth_address) {
        a.push({
            type: 'url-eth_address', value: `${parseSocialUrl(social.eth_address, 'eth_address')}`
        });
    }

    if (social.reddit) {
        a.push({
            type: 'url-reddit', value: `${parseSocialUrl(social.reddit, 'reddit.com/user')}`
        });
    }

    if (social.github) {
        a.push({
            type: 'url-github', value: `${parseSocialUrl(social.github, 'github.com')}`
        });
    }

    if (social.skype) {
        a.push({
            type: 'url-skype', value: `${social.skype}`
        });
    }

    if (social.discord) {
        a.push({
            type: 'url-discord', value: `${parseSocialUrl(social.discord, 'discordapp.com/users')}`
        });
    }

    return a;
}

const display_social_pipeline = {
    'facebook': (v, bool) => {
        let a = v.split("facebook.com/")[1];
        if (a) {
            return `${a.replace(/\//g, '')}`;
        }
        return bool ? v : "";
    }, 'eth': (v, bool) => {
        return v;
    }, 'linkedin': (v) => {
        let a = v.split("linkedin.com/in/")[1];
        if (a) {
            return `${a.replace(/\//g, '')}`;
        }
        return v;
    }, 'twitter': (v, bool) => {
        if (v.includes('@') && !v.includes('http')) {
            return `${v.replace(/\//g, '')}`
        }
        let a = v.split("twitter.com/")[1];
        if (a) {
            return `@${a.replace(/\//g, '')}`;
        }
        return bool ? v : "";
    }, 'discord': (v, bool) => {
        let a = v.split("discordapp.com/users/")[1];
        if (a) {
            return `${a.replace(/\//g, '')}`;
        }
        return bool ? v : "";
    }, 'website': (v) => {
        return formatWebsiteForProfile(v);
    }, 'skype': (v) => {
        return v;
    }, 'instagram': (v, bool) => {
        let a = v.split("instagram.com/")[1];
        if (a) {
            return `@${a.replace(/\//g, '')}`;
        }
        return bool ? v : "";
    }, 'github': (v, bool) => {
        let a = v.split("github.com/")[1];
        if (a) {
            return `@${a.replace(/\//g, '')}`;
        }
        return bool ? v : "";
    }, 'reddit': (v, bool) => {
        let a = v.split("reddit.com/user/")[1];
        if (a) {
            return `@${a.replace(/\//g, '')}`;
        }
        return bool ? v : "";
    }
};

export function cleanSocialForProfile(url, social_type, bool) {

    if (!display_social_pipeline[social_type]) {
        return url;
    }
    return display_social_pipeline[social_type](url, bool);
}

export function buildAddress(a) {
    let str = '';
    if (!a) {
        return '';
    }

    if (a.line_one) {
        str += `${a.line_one}\n`;
    }
    if (a.line_two) {
        str += `${a.line_two}\n`;
    }
    if (a.city) {
        str += `${a.city}`;
    }
    if (a.city && a.zip_code) {
        str += `, `;
    }
    if (a.zip_code) {
        str += `${a.zip_code}`;
    }
    if (a.city || a.zip_code) {
        str += `\n`;
    }
    if (a.state) {
        str += `${a.state}, `;
    }
    if (a.country) {
        str += `${data_countries_map[a.country]}`;
    }
    return str;
}

export function cleanWebsiteForProfile(url) {
    let str = url;
    str = str.replace('https://', '');
    str = str.replace('http://', '');
    str = str.replace('www.', '');
    str = str.replace(/\/$/, '');
    return str.split("/")[0].trim();
}

export function formatWebsiteForProfile(str, bool) {
    if (!str) {
        return '';
    }
    if (bool) {
        return str.replace("https://", "").replace("https://", "").replace("www", "");
    }
    if (str.startsWith('http')) {
        return str;
    } else {
        return `https://${str}`;
    }
}

function addressIsEmpty(obj) {
    if (!obj || typeof obj !== 'object') {
        return true;
    }
    return !Object.values(obj).filter(a => !!a).length;
}

// todo unite with data field types
export function isCFEmpty(value, type) {

    if (type === 'birthday' || type === 'date-object') {
        if (!value) {
            return false;
        }
        return !value.year || !value.month || !value.date
    } else if (type === 'join_date') {
        if (!value) {
            return false;
        }
        return getMemberJoinDate(value) === "Unknown";
    } else if (type === 'checkbox') {
        return value === undefined;
    } else if (type === 'select') {
        if (typeof value === 'object') {
            return !Object.keys(value).length;
        }
        return !value;
    } else if (type === 'multi-select-taxonomy') {
        return !Object.keys(value).length;
    } else if (type === 'multi-select') {
        if (Array.isArray(value)) {
            return !value.length;
        } else if (typeof value === 'object') {
            return !Object.keys(value).length;
        } else {
            return !value.length;
        }
    } else if (type === 'address') {
        return addressIsEmpty(value)
    } else if (type === 'mentees') {
        if (Array.isArray(value) && !value) {
            return !value.length;
        }
        return !Object.keys(value).length;
    } else if (type === 'mentor') {
        return !Object.keys(value).length;
    } else if (type === 'phone') {
        return !value;
    }
    return !value;
}

export function getCFValue(id, type, member_cfs) {
    const mv = member_cfs[id];
    if (type === 'member' || type === 'mentor') {
        return mv ? mv : '';
    } else if (type === 'multi-member' || type === 'mentees') {
        return mv ? mv : [];
    } else if (type === 'date') {
        return mv ? mv : '';
    } else if (type === 'checkbox') {
        return !!mv ? mv : undefined;
    } else if (type === 'text') {
        return mv ? mv : '';
    } else if (type === 'group') {
        return mv ? mv : '';
    } else if (type === 'phone') {
        return mv ? mv : '';
    } else if (type === 'email') {
        return mv ? mv : '';
    } else if (type === 'url') {
        return mv ? formatWebsiteForProfile(mv) : '';
    } else if (type === 'number') {
        return mv ? mv : '';
    } else if (type === 'address') {
        return mv ? mv : {
            line_one: '', line_two: '', city: '', country: '', zip_code: '', state: ''
        };
    } else if (type === 'select') {
        return mv ? mv : '';
    } else if (type === 'multi-select') {
        return mv ? mv : [];
    } else if (type === 'select-taxonomy') {
        return mv ? mv : null;
    } else if (type === 'multi-select-taxonomy') {
        return mv ? mv : {};
    } else if (type === 'textarea') {
        return mv ? mv : '';
    }
}

function Link({children, href}) {
    //href={href} target="_blank" rel="nofollow noreferrer"
    return <span className="link">{children}</span>
}

function shouldHide(closed, member, data_id) {
    if (closed && !member.groups[data_id]) {
        return true;
    }
    return false;
}

export function RenderName({can_hide = true, data, simple, data_id, type = "member"}) {
    const community = useCommunity();

    function onClick() {
        community.goToPreview({...data, type, id: data_id}, type, true);
    }

    const closed = !!data?.closed;

    if (can_hide && shouldHide(closed, community.member, data_id)) {
        return null;
    }

    if (!data.name) {
        return null;
    }

    if (simple) {
        return <span onClick={() => onClick()} className={`hover:underline cursor-pointer ${type}`}>
            <span>{data.name}</span>
    </span>;
    }

    return <span onClick={() => onClick()} className={`link-entity inline-flex items-center space-x-1 ${type}`}>
        {closed && <span className="h-3 w-3 inline-block">
            <LockClosedIcon/>
        </span>}
        <span>{data.name}</span>
    </span>;
}

function getCheckboxLabels(props) {
    if (props?.options?.checked_label) {
        return props?.options;
    }
    return {
        checked_label: "Yes", unchecked_label: "No"
    }
}

function getCFSelectOptionsMap(cf) {
    let text_key = 'text';
    let value_key = 'value';
    if (cf?.options?.choices) {
        let a = {};
        cf.options.choices.forEach(ch => {
            if (ch.text) {
                text_key = 'text';
            } else if (ch.label) {
                text_key = 'label';
            }
            if (ch.value) {
                value_key = 'value';
            } else if (ch.id) {
                value_key = 'id';
            }

            a[ch.value] = {
                label: ch[text_key], disabled: ch.hidden, value: ch[value_key]
            }
        })
        return a;
    } else if (cf?.options && Array.isArray(cf?.options)) {
        let a = {};
        cf?.options?.forEach(ch => {
            if (ch.text) {
                text_key = 'text';
            } else if (ch.label) {
                text_key = 'label';
            }
            if (ch.value) {
                value_key = 'value';
            } else if (ch.id) {
                value_key = 'id';
            }
            a[ch[value_key]] = {
                label: ch[text_key], disabled: ch.hidden, value: ch[value_key],
            }
        })
        return a;

    }

    return {};
}

function cleanMSTValue(obj) {
    const is_array = typeof obj === 'object' && obj.length;
    let a = [];

    if (is_array) {
        if (obj && obj[0] && obj[0].label) {
            return obj;
        }
    }

    return a;
}

// sanitize, hydrate, utils
export function valuePipeline(value, props) {
    let is_valid, fv, a, b;

    switch (props.type) {
        case 'mentor':
            if (value) {
                return <div className="mt-0.5">
                    <DataWrapper id={value} type={`community_members.${props.community_uid}.members`}>
                        <RenderName/>
                    </DataWrapper>
                </div>
            }
            return '';
        case 'assignee':
            if (value) {
                return <div className="mt-0.5">
                    <DataWrapper id={value} type={`community_members.${props.community_uid}.members`}>
                        <RenderName/>
                    </DataWrapper>
                </div>
            }
            return '';
        case 'mentees':
            if (value) {
                fv = Object.keys(value);
                return <div className="flex flex-wrap gap-1 mt-0.5">
                    {fv.map((id, i) => {
                        return <DataWrapper key={id} id={id} type={`community_members.${props.community_uid}.members`}>
                            <RenderName/>
                        </DataWrapper>;
                    })}
                </div>;
            }
            return ''
        case 'roles':
            if (value) {
                fv = Object.keys(value);
                if (fv.length) {
                    return <ShowMoreEntitiesWrapper items={fv} renderItem={(id, i) => {
                        return <DataWrapper key={`${id}`} id={id}
                                            type={`community_entities.${props.community_uid}.roles`}>
                            <RenderName type="role"/>
                        </DataWrapper>;
                    }}/>
                }
            }
            return '';
        case 'groups':

            return <MemberGroupsList data={{groups: value}} minimal />;
        case 'join_date':
            if (!value) {
                return props.fallback || '';
            }
            return getMemberJoinDate(value, props.fallback || "");
        case 'number':
            if (!value) {
                return '';
            }
            return String(value)
        case 'occupation':
            if (!value) {
                return '';
            }
            return String(value)
        case 'location':
            if (!value) {
                return '';
            }
            return String(value)
        case 'last_sign_in':
            if (!value) {
                return props.fallback || "";
            }
            return dayjs.unix(value / 1000).fromNow()
        case 'date-object':
        case 'birthday':
            if (!value) {
                return '';
            }
            return formatBirthday(value)
        case 'select':
            a = getCFSelectOptionsMap(props);

            if (value && a[value] && a[value].label) {
                return a[value].label;
            }
            return '';
        case 'interests':
            const is_my_profile = props?.profile_meta?.is_my_profile;

            return <ViewInterests my_profile={is_my_profile} onAddClick={() => {
props?.profile_actions?.onEditInterests();
            }} add_prompt={null} items={Object.keys(value || {})}
                                  cmi={value || {}}/>
        case 'address':
            if (!value) {
                return '';
            } else if (typeof value === 'string') {
                return value;
            } else {
                return <span className="whitespace-pre-line text-sm leading-4">{buildAddress(value)}</span>;
            }
        case 'multi-select':
            if (!value) {
                return '';
            }
            fv = Object.keys(value);
            a = getCFSelectOptionsMap(props);
            b = fv.length;

            if (b === 0) {
                return '';
            }
            return fv.map((key, i) => {
                if (!a[key]) {
                    console.error("error", a, key)
                    return <span>--</span>
                }
                return <span key={key}>{a[key].label}{(i + 1) !== b && ', '}</span>
            });
        case 'textarea':
            return String(value)
        case 'url':
            if (!value) {
                return '';
            }
            return <Link href={value}>{cleanWebsiteForProfile(value)}</Link>
        case 'text':
            return String(value)
        case 'string':
            return String(value)
        case 'checkbox':
            const labels = getCheckboxLabels(props);
            if (!!value) {
                return value ? labels.checked_label : labels.unchecked_label;
            }
            return ''
        case 'date':
            if (!value) {
                return '';
            }
            a = dayjs(value);
            if (!a.isValid()) {
                return '';
            }
            return a.format('MM-DD-YYYY')
        case 'multi-select-taxonomy':
            if (!value) {
                return '';
            }
            a = cleanMSTValue(value);
            b = a.length;
            if (b === 0) {
                return '';
            }
            return a.map((key, i) => {
                return <span key={`${key.label}-${i}`}>{key.label}{(i + 1) !== b && ', '}</span>
            });
        case 'select-taxonomy':
            if (value && value.label) {
                return value.label;
            }
            return '';
        case 'handle':
            return `@${String(value)}`;
        case 'email':
            is_valid = utils_strings_isEmail(value);
            if (is_valid) {
                return <Link>{value}</Link>
            }
            return `${String(value)}`;
        case 'phone':
            const phone_data = getPhoneNumberData(value);
            return <Link>{String(phone_data.display)}</Link>;
        default:
            return String(value);
    }
}