import React, {useContext, useEffect, useRef, useState} from 'react';
import ContentGrid from "../../content-grid";
import {trace} from "firebase/performance";
import {collection, documentId, getDocs, limit, orderBy, query, startAfter, where} from "firebase/firestore";
import {app_perf} from "../../../../config/firebase-setup";
import {db} from "../../../../config/setup-firestore";
import {chunk, getManyDocuments, useCommunity} from "../../../../config/community";
import {buildElasticsearchQuery, directoryMembersQuery} from "../build-elasticsearch-query";
import {isAnonymousUnatyEmail} from "../../../../m3/utilities/email";
import {buildImageUrl} from "../../../../../common/utilities/images";


let itemStatusMap = {};

const isItemLoaded = index => !!itemStatusMap[index];


function arrayToObject(arr) {
    let a = {};
    arr.forEach(it => {
        a[it] = true;
    })
    return a;
}

function transformNumberValue({value, operator = "=="}) {
    switch (operator) {
        case "==":
            return parseInt(value[0]);

        case ">":
            return parseInt(value[0]);
        case "<":
            return parseInt(value[1]);

        case ">=":
            return parseInt(value[0]);
        case "<=":
            return parseInt(value[1]);

        case "is_between":
            return [parseInt(value[0]), parseInt(value[1])];

        default:
            return value;
    }
}

function buildTransformedFilter(filter) {
    if (filter.type === 'number') {
        return {
            field: filter.id,
            join: "and",
            value: transformNumberValue(filter),
            operator: filter.operator || "=="
        }
    }
    return {
        field: filter.id,
        join: "and",
        value: arrayToObject(filter.value),
        operator: "in"
    }
}

function transformView(view, filters, sort) {
    let v = view;

    if (!v.filters) {
        v.filters = [];
    }

    filters.forEach(filter => {
        v.filters.push(buildTransformedFilter(filter))
    })

    if (sort) {
        v.sort = [
            sort
        ];
    } else {
        v.sort = [];
    }

    return v;
}

const firestore_filter_ids = ['member_type', 'membership_status', 'data_integrity', 'address.country', 'account_status'];

function getSearchType(query, filters = []) {
    if (!query && !filters.length) {
        return 'firestore';
    }

    if (query) {
        return 'elastic';
    }

    if (filters.length > 1) {
        return 'elastic';
    }

    let firestore_filters = 0;

    filters.forEach(filter => {
        if (firestore_filter_ids.includes(filter.id)) {
            firestore_filters++;
        }
    })

    if (filters.length === firestore_filters) {
        return 'firestore';
    }

    return 'elastic';
}

function getFirestoreValue(filt) {
    if (Array.isArray(filt.value) && filt.value.length === 1) {
        return filt.value[0];
    }
    return filt.value
}

function getFirestoreOperator(filt) {
    if (Array.isArray(filt.value) && filt.value.length === 1) {
        return "==";
    }
    return typeof filt.value === 'string' ? "==" : 'in';
}

function buildFirestoreQuery(members_col, group_id, start_after, page, pp, filters, sort) {
    let q;

    let order_by = orderBy(sort[0].field, sort[0].dir);
    let sa = startAfter(start_after);
    let lt = limit(pp);

    let wheres = [];

    if (group_id) {
        wheres.push(where("group_ids", "array-contains", group_id))
    } else {
        wheres.push(where("archived", "==", false));
    }

    filters.forEach(filt => {
        wheres.push(where(filt.id, getFirestoreOperator(filt), getFirestoreValue(filt)))
    })

    if (start_after && page > 0) {
        if (wheres.length === 3) {
            q = query(members_col, wheres[2], wheres[1], wheres[0], order_by, sa, lt)
        } else if (wheres.length === 2) {
            q = query(members_col, wheres[1], wheres[0], order_by, sa, lt)
        } else if (wheres.length === 1) {
            q = query(members_col, wheres[0], order_by, sa, lt)
        } else {
            q = query(members_col, order_by, sa, lt)
        }
    } else {
        if (wheres.length === 3) {
            q = query(members_col, wheres[2], wheres[1], wheres[0], order_by, lt)
        } else if (wheres.length === 2) {
            q = query(members_col, wheres[1], wheres[0], order_by, lt)
        } else if (wheres.length === 1) {
            q = query(members_col, wheres[0], order_by, lt)
        } else {
            q = query(members_col, order_by, lt)
        }
    }

    return q;
}

async function loadMembers(cid, view, defs, page = 0, pp = 10, start_after, group_id, qstr, filters = [], sort = [{
    field: "about.last_name",
    dir: "asc"
}]) {

    const search_type = getSearchType(qstr, filters);

    if (search_type === 'elastic') {
        const es_query = buildElasticsearchQuery(transformView(view, filters, sort), qstr, defs, page, {
            size: pp,
            group_id
        });

        const resp = await directoryMembersQuery(es_query, cid)
            .then((response) => {
                if (!response) {
                    console.error('something went wrong..')
                    return;
                }

                const {members, page} = response;

                const ids2 = members.map(mem => {
                    return mem.id
                }).filter(a => !!a);

                return members.map(m => {
                    return {
                        partial: true,
                        id: m.id,
                        data: () => {
                            return m;
                        }
                    }
                });
            })

        return resp;
    } else {
        const members_col = collection(db, 'community_members', cid, 'members');

        let q = buildFirestoreQuery(members_col, group_id, start_after, page, pp, filters, sort);

        const snap = await getDocs(q);
        return snap.docs;
    }
}

export const getDirectoryData = async (cid, view, defs, len, page_size = 25, start_after, group_id, query, filters, sort, attributes) => {
    const page = Math.floor(len / page_size);
    const b = Date.now();
  //  console.log("getDirectoryData", {cid, view, defs, len, page_size, start_after, group_id, query, filters, sort})
    const t_directory = trace(app_perf, "DIRECTORY_LOAD");
    t_directory.start();
    // cid, view, defs, page = 0, pp = 10, start_after, group_id, qstr, filters = [], sort
    return new Promise(resolve =>
        loadMembers(cid, view, defs, page, page_size, start_after, group_id, query, filters, sort)
            .then((docs = []) => {
                const c = Date.now();
               // console.log("METADATA: ", {view, query, filters, sort})
            //    console.log("TIME:", (c - b), 'ms')
                t_directory.stop();
                resolve(docs);
            })
    );
};

const mergeArrays = (a, b) => {
    const AandB = [...a, ...b];
    const distinctValuesInAandBSet = new Set(AandB);
    return [...distinctValuesInAandBSet];
};

function processItems(items, partials, blocks) {

    return items.map(it => {

        let rt = {...it};
        if (blocks) {
            rt.blocks = [...blocks];
        }
        if (partials[it.id]) {
            return {
                ...rt,
                ...partials[it.id]
            };
        }
        return rt;
    });
}

export function DataGridWrapper({
                                    getData,
                                    partials,
                                    onItemClick,
                                    group_id,
                                    children,
                                    onUpdatedItems,
                                    updated_items,
                                    blocks,
                                    community_id,
                                    page_size = 25,
                                    format,
                                    options,
                                    handleNewData,
                                    defs,
                                    view,
                                    query,
                                    filters,
                                    sort
                                }) {
    const [items, setItems] = useState(() => {
        return [];
    });
    const [fetching, setFetching] = useState(true);
    const start_after = useRef(null);
    const has_more = useRef(false);
    const query_id = useRef("");

    useEffect(function () {
        if (updated_items && updated_items.length > 0 && items && items.length > 0) {
            onUpdatedItems();
            updateItems(items, updated_items);
        }
    }, [items, updated_items])

    function updateItems(items, updated_items) {
        let new_items = [...items];
        updated_items.forEach(a => {
            const i = new_items.findIndex(x => x.id === a.id);
            if (i > -1) {
                new_items[i] = {
                    ...a
                };
            }
        })

        setItems(new_items);
    }

    useEffect(function () {
        const q_id = `${community_id}__${group_id}__${JSON.stringify(filters)}__${JSON.stringify(sort)}__${query||""}`;
        if (q_id !== query_id.current) {
            query_id.current = q_id;
            setItems([]);
            setFetching(true);
            has_more.current = false;
            start_after.current = null;

            getData(community_id, view, defs, 0, page_size, start_after.current, group_id, query, filters, sort)
                .then(arr => {
                    has_more.current = arr.length === page_size;
                    if (arr.length > 0) {
                        start_after.current = arr[arr.length - 1];
                    }

                    handleNewData(arr, [], setItems, setFetching);
                })
        }
    }, [community_id, defs, filters, query, sort, group_id, page_size])

    const loadMoreItems = (setIsFetching) => {

        if (fetching) {
            return;
        }

        setFetching(true);
        return new Promise(resolve => {
                getData(community_id, view, defs, items.length, page_size, start_after.current, group_id, query, filters, sort)
                    .then(arr => {
                        if (arr.length > 0) {
                            start_after.current = arr[arr.length - 1];
                        }
                        has_more.current = arr.length === page_size;
                        handleNewData(arr, items, setItems, setFetching);
                        setIsFetching(false);
                        resolve();
                    })
            }
        );
    };

    const processed = processItems(items, partials, blocks);

    /*
    console.log("--------");
    console.log("PROCESSED ITEMS",processed);
    console.log("BLOCKS",blocks);
    console.log("--------");

     */

    return <ContentGrid onItemClick={onItemClick} has_more={has_more.current} fetching={fetching}
                        isItemLoaded={isItemLoaded} loadMoreItems={loadMoreItems} blocks={blocks} defs={defs}
                        showing_sidebar={options.show_sidebar} format={format} items={processed}>
        {children}
    </ContentGrid>
}


function getPageSize(multiplier = 1.4) {
    const {innerWidth, innerHeight} = window;
    const approx_cols = innerWidth / 200;
    const rows_on_screen = (innerHeight - 150) / 350;
    const count = Math.floor((rows_on_screen * approx_cols) * multiplier);
    const min = 10;
    const max = 100;
    return Math.min(Math.max(count, min), max);
}

function parseMemberEmail(email) {
    if (!email) {
        return "";
    } else if (isAnonymousUnatyEmail(email)) {
        return "Anonymous"
    } else {
        return email;
    }
}

const DEFAULT_PP = "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2F1868I1QUR5";

function buildCustomFields(custom_fields, defs) {
    if (!custom_fields) {
        return {};
    } else {
        return {...custom_fields}
    }
}

export function DirectoryGalleryWrapper({
                                            options,
                                            defs, view,
                                            query,
                                            filters,
                                            sort,
                                            blocks,
                                            group_id,
                                            children,
                                            header,
                                            format = 'small',
                                            openImage,
                                            handleProfilePictureChange
                                        }) {
    const community = useCommunity();
    const [partials, setPartials] = useState({});
    const [updated_items, setUpdatedItems] = useState(null);

    function transformDocs(docs) {
        return docs.map(doc => {
            const dt = doc.data();
            return {
                id: doc.id,
                partial: !!doc.partial,
                raw: {
                    ...dt,
                    id: doc.id
                },
                card: {
                    onItemClick: (item_id, item_data) => openPreview(item_id, item_data),
                    onManage: !options.can_manage ? null :() => {
                        community.openEditOverlay({
                            type: 'manage-member',
                            data: dt,
                            handle: dt.handle,
                            id: doc.id,
                            closeCb: () => {
                                updateDocumentsByIds([doc.id])
                                community.closeEditModal()
                            }
                        })
                    },
                    onExpand: () => {
                        openImage(buildImageUrl(dt.profile_picture || DEFAULT_PP, ''))
                    },
                    onEditImage: !options.can_manage ? null : () => {
                        community.openEditOverlay({
                            type: 'edit-profile-picture',
                            size: "33rem",
                            data: {
                                community_uid: community.uid,
                                onChange: (url, color) => {
                                    if (handleProfilePictureChange) {
                                        handleProfilePictureChange(doc.id, url, color, () => {
                                            updateDocumentsByIds([doc.id])
                                        })
                                    }
                                }
                            },
                            onClose: () => {
                                community.closeEditModal()
                            }
                        })
                    },
                    email: parseMemberEmail(dt?.contact?.email),
                    phone: dt?.contact?.phone || "",
                    occupation: dt?.about?.occupation || "",
                    location: dt?.about?.location || "",
                    image: buildImageUrl(dt?.profile_picture || DEFAULT_PP),
                    title: dt?.name,
                    handle: dt?.handle,
                    custom_fields: buildCustomFields(dt?.custom_fields || null, defs),
                    subtitle: dt?.account_email,
                    meta: []
                }
            }
        })
    }

    function handleNewData(docs, items, setItems, setFetching) {
        const ni = transformDocs(docs);

        setItems(mergeArrays(items, ni));
        setFetching(false);
        checkForPartials(docs);
    }

    async function getPartialsData(fids) {
        let p = [];
        const chunks = chunk(fids, 10);
        chunks.forEach(a => {
            p.push(getManyDocuments('community_members', community.id, 'members', documentId(), a));
        })
        return await Promise.all(p).then(arrs => {
            return arrs;
        });
    }

    function checkForPartials(docs) {
        let fetch_ids = [];
        let to_set = [];
        docs.forEach(doc => {
            if (doc.partial && !partials[doc.id] && doc.id) {
                fetch_ids.push(doc.id);
            } else if (!doc.partial && !partials[doc.id] && doc.id) {
                to_set.push(doc);
            }
        });

        if (fetch_ids.length > 0) {
            getPartialsData(fetch_ids)
                .then(snaps => {
                    updatePartials(snaps)
                })
        } else if (to_set.length > 0) {
            setPartialDocs(to_set)
        }
    }

    function setPartialDocs(docs) {
        if (docs.length) {
            return;
        }
        let p = {...partials};
        if (docs.length) {
            // got new data, how to pass back
            const transformed = transformDocs(docs);
            transformed.forEach(it => {
                p[it.id] = it;
            })
        }
        setPartials(p);
    }

    function updatePartials(snaps) {
        if (!snaps || !snaps[0] || !snaps[0].docs || !snaps[0].docs.length) {
            return;
        }
        let p = {...partials};

        snaps.forEach(snap => {
            if (snap.docs.length) {
                // got new data, how to pass back
                const transformed = transformDocs(snap.docs);
                transformed.forEach(it => {
                    p[it.id] = it;
                })
            }
        })

        setPartials(p);
    }

    function updateDocumentsByIds(ids_to_update = []) {
        if (ids_to_update.length > 0) {
            getPartialsData(ids_to_update)
                .then(doc_sets => {
                    updateItems(doc_sets[0])
                })
        }
    }

    function updateItems(doc_set) {

        if (!doc_set || !doc_set.length) {
            return;
        }
        if (doc_set.length>0) {
            // got new data, how to pass back
            const transformed = transformDocs(doc_set);
            setUpdatedItems(transformed);
        }
    }

    const page_size = getPageSize(2.5);

    const openPreview = (id, data) => {
        if (!id) {
            return;
        }
        community.openPreviewOverlay({
            type: 'member',
            handle: data.handle,
            id: data.id,
            data
        })
    };

    return <DataGridWrapper query={query} view={view} defs={defs} sort={sort} filters={filters}
                            onItemClick={openPreview} group_id={group_id} blocks={blocks} onUpdatedItems={() => {
        setUpdatedItems(null);
    }}
                            header={header} page_size={page_size} partials={partials} updated_items={updated_items}
                            community_id={community.id} options={options} format={format} getData={getDirectoryData}
                            handleNewData={handleNewData}>
        {children}
    </DataGridWrapper>
}