import React, {useEffect, useState} from 'react';
import SignInPage from "../../auth/sign-in";
import {useCommunity} from "../../../config/community";
import {useUnaverse} from "../../../config/unaverse";
import {useAuth} from "../../../config/auth";
import {useNavigate} from "react-router-dom";
import {buildImageUrl} from "../../../../common/utilities/images";
function getState(user,ready,unaverse,community) {

    if(!ready) {
        return "loading";
    }

    if(!user) {
        return "sign-in"
    }

    if(!unaverse.memberships.member_ids) {
        return "loading-memberships";
    }

    if(community.loading_status==='not-a-member') {
        return "apply-to-join";
    }

    return "sign-in";
}

export function CommunitySignIn() {
    const {user,__ready} = useAuth();
    const unaverse = useUnaverse();
    const community = useCommunity();
    const navigate = useNavigate();
    const [state,setState] = useState(getState(user,__ready,unaverse,community));
    const [state_data,setStateData] = React.useState({});

    useEffect(function () {
        if(!unaverse.user_uid) {
            return;
        }
        if(!unaverse.memberships.member_ids) {
            return;
        }
        if(unaverse.memberships.member_ids[community.uid]) {
            navigate(`/c/${community.domain}/home`);
        }
    }, [unaverse.memberships, unaverse.user_uid])
    function handleStateChange(new_state,data) {
        setStateData({...data});
        setState(new_state);
    }

    const styles = {
        image: buildImageUrl(community?.profile?.profile_picture),
        background_image: buildImageUrl(community?.profile?.branding?.sign_in_cover_photo, ""),
        color: community?.profile?.branding?.color
    };

    return <SignInPage domain={community.domain} navigate_to={`/c/${community.domain}/home`} handleStateChange={handleStateChange} state_data={state_data} community_uid={community.uid} flow="community" state={state} styles={styles}/>
}