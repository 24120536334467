import React from 'react';
import './extend-prototypes';
import './config/firebase-setup';
import {AuthContextProvider} from "./config/auth";
import {UnaverseContextProvider} from "./config/unaverse";
import {ToastsContextProvider} from "./config/toasts";
import AppFrame from "./app-router";

export default function App() {

    return (
        <ToastsContextProvider>
            <AuthContextProvider>
                <UnaverseContextProvider>
                    <AppFrame/>
                </UnaverseContextProvider>
            </AuthContextProvider>
        </ToastsContextProvider>
    );
}