import React, {useEffect} from "react"
import './styles.css';
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import ContentPopup from "../content-popup";
import {ContentPopupMenu} from "../content-popup/components";
import SimpleTooltip from "../tooltip";
import {LocalSearchBar} from "../local-search-bar";
import {getCurrentTimezone} from "../../../../common/utilities/lang-region";
import {data_timezones_arr, data_timezones_map} from "../../../../common/data/data/timezones";


// todo make this a generic component

export default function InlineSelectTimezone({
                                                 onChange = () => {
                                                 },
                                                 value
                                             }) {
    const [selected, setSelected] = React.useState(value || getCurrentTimezone());
    const [query, setQuery] = React.useState('');

    useEffect(function () {
        onChange(selected);
    }, [selected]);

    const filtered_tzs = data_timezones_arr.filter(it => it?.label?.toLowerCase().includes(query.toLowerCase()));

    const content = <ContentPopup mx="300px">
        <div className="px-3 pt-2.5 pb-2 border-b border-gray-200 mb-2">
            <LocalSearchBar clearResults={()=>{
                setQuery("");
            }} autoFocus onSearchSubmit={q=>setQuery(q)} />
        </div>
        {filtered_tzs.length === 0 && <div className="px-3 py-2.5 text-gray-500 text-sm">No results</div>}
        <ContentPopupMenu can_check items={[
            ...filtered_tzs.map((it, key) => {
                return {
                    label: it.label,
                    checked: selected === it.value,
                    onClick: () => {
                        setSelected(it.value)
                    }
                }
            })
        ]}/>
    </ContentPopup>;

    const show_label = data_timezones_map[selected] || selected;

    return <SimpleTooltip simple trigger="click" usePortal hideOnClick={false} interactive placement="bottom-start" text={content}>
        <span className="font-medium inline-flex items-center hover:opacity-70 cursor-pointer">{show_label} <span
            className="w-3 h-3 inline-block"><ChevronDownIcon/></span></span>
    </SimpleTooltip>;
};