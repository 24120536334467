import React, {useState, useEffect, useContext} from 'react';

import {db} from "../../../../config/setup-firestore";
import styled from "styled-components";
import {useCommunity} from "../../../../config/community";
import {collection, getDocs, limit, orderBy, query, where} from "firebase/firestore";
import {SectionHeader} from "../../../../m3/_legacy_components/entity-grid";
import {DynamicMediaGrid} from "../tabs/media";
import {CameraIcon} from "@heroicons/react/24/outline";
import {PlayIcon} from "@heroicons/react/20/solid";
import EmptyState from "../../../../m3/_legacy_components/empty-state";

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-gap: 0.75rem;

  > div {
    padding: 0.75rem;
    display: grid;
    place-items: center;

    &::before {
      content: "";
      display: block;
      padding-bottom: 100%;
      grid-area: 1 / 1 / 2 / 2;
    }

    img {
      width: 100%;
      // height: 100%;
      // object-fit: contain;
      grid-area: 1 / 1 / 2 / 2;
    }
  }
`;

export function RecentImagesWidget({can_add,media = [], openImageViewer}) {
    return <DynamicMediaGrid>
        {can_add&&<div key="add-media">
            <div className="rounded-md cursor-pointer bg-gray-100" style={{
                aspectRatio: '1/1',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}>
                <div className="flex flex-col justify-center items-center h-full">
                    <div>
                        <CameraIcon className="h-6 w-6 text-gray-700" aria-hidden="true"/>
                    </div>
                    <div className="text-gray-700 pt-2 text-xs font-medium">Add Photos/Videos</div>
                </div>
            </div>
        </div>}
        {media.map(item => {
            const is_video = item.type === 'video/mp4';
            const img_url = is_video ? item.preview_frame_url : item.url;
            return <div key={item.id}
                        onClick={() => {
                            /*
                            openImageViewer(img.url, "#000000", images.map(a => {
                                return {
                                    ...a,
                                    bg_color: "#000000"
                                }
                            }));

                             */
                        }}
                        className={`rounded-md relative cursor-pointer bg-gray-100`}
                        style={{
                            aspectRatio: '1/1',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundImage: `url(${img_url})`
                        }}
            >

                {is_video && <div className="absolute top-0 right-0 left-0 bottom-0 flex flex-col justify-center items-center h-full">
                    <div>
                        <PlayIcon className="h-8 w-8 text-white" aria-hidden="true"/>
                    </div>
                </div>}
            </div>
        })}
    </DynamicMediaGrid>
}

export default function GroupRecentMedia({media, can_add, group_id}) {
    const [images, setImages] = useState(media || null);
    const community = useCommunity();
    const [loading, setLoading] = useState(false);

    useEffect(function () {
        if (loading || images) {
            return;
        }
        if (!group_id || !community.id) {
            return;
        }
        setLoading(true);
        getRecentImages(group_id, community.id);
    }, [community.id, loading, images, group_id]);

    function getRecentImages(id, cid) {
        const ref = collection(db, 'community_content', cid, 'images');
        const q = query(ref, where('group_id', '==', id), orderBy('created_at', 'desc'), limit(12));
        getDocs(q)
            .then(snap => {
                setImages(snap.docs.map((doc) => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        ...data
                    }
                }));
                setLoading(false);
            })
    }

    if (loading || !images) {
        return <div>
            Loading...
        </div>
    }

    if (images.length === 0) {
        return <div>
            <EmptyState icon={<CameraIcon />} title={"No Images or Videos found"} />
        </div>;
    }

    return <RecentImagesWidget can_add={can_add} media={images} openImageViewer={() => {
    }}/>
}