import React from 'react';
import ExternalAuthPage from "../../../m3/_legacy_components/external-auth-page";
import {setDocumentTitle} from "../../../m3/utilities/set-document-title";
import {useNavigate} from "react-router-dom";
import {getCommunityFromDomain} from "../../auth/link/community";
import Button from "../../../m3/_legacy_components/button";

export function CommunityNotFound() {
   const navigate = useNavigate();

    React.useEffect(() => {
        const community_domain = window.location.pathname.split('/')[2];
        getCommunityFromDomain(community_domain).then((comm) => {
            if(comm) {
              navigate(`/c/${comm.handle}/sign-in`);
            } else {
                setDocumentTitle(`Community not found`);
            }
        })
    }, [])

    return <ExternalAuthPage show_logo>
        <div className="w-full max-w-sm space-y-4">
            <div>
                <div className="text-2xl text-gray-800 font-bold">Community not found</div>
            </div>
            <div>
                <Button intent='primary' fill size='large'
                        onClick={()=>{
                            navigate(`/`);
                        }}
                        text={'Back to Home'}
                />
            </div>
        </div>
    </ExternalAuthPage>
}