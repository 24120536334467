import React, {useContext, useState} from "react";
import {InlineInput} from "../inline-input";
import {LIEDirectionArrow, lie_grid_style, LIEBlank, LIEToggle, lie_getRowIndicator} from "./shared";
import {Badge} from "../../../user-email-manager";
import {useCommunity} from "../../../../../config/community";
import {useUnaverse} from "../../../../../config/unaverse";
import {useToasts} from "../../../../../config/toasts";
import {authFetch} from "../../../../../../api/network";
import {RulesInlineSelect} from "../../../rules-inline-select";

function displayValue(value) {
    if (value.valid && value.default_value) {
        return <LIEBlank text={value.value}/>;
    } else if (value.valid) {
        return value.value;
    }
    return <LIEBlank/>;
}

const rich_import_formats = [" <member_type-", " <member-"];

function formatLabel(label, value = false) {
    for (let i = 0; i < rich_import_formats.length; i++) {
        const rif = rich_import_formats[i];
        if (label.indexOf(rif) !== -1) {
            // membership level
            if (!value) {
                return <Badge color="emerald" text={label.split(rif)[0]} size="sm"/>
            } else {
                return label.split(rif)[0]
            }
        }
    }
    return label;
}

function getSectionId(field_id,custom_fields) {
    const sections = Object.entries(custom_fields).map(a=>{
        return [a[0], a[1].fields]
    });
    let match = "";
    sections.forEach(sec=>{
       if(sec[1].includes(field_id)) {
           match = sec[0];
       }
    });
    return match;
}

export function LIEBodyRow({
                               error, info, errors_only = false, onAddSelectValue=()=>{},
                               options, number, label, row_type, count, open, onOpen, handleDataChange = () => {
    }, striped, value, raw_value, block_data, handleSelect, mapping_data, data, selected_option
                           }) {

    const [loading,setLoading] = useState(false);

    const community = useCommunity();
    const unaverse = useUnaverse();
    const toasts = useToasts();

    function addOption(text) {
        const fid = mapping_data.field_details.field_id;
        const payload = {
            section_id: getSectionId(fid,community.custom_fields),
            field_id: fid,
            text,
            member_id: community.member_id,
            community_uid: community.uid
        };

        setLoading(true);
        toasts.addToast({text: 'Adding option', intent: 'info'});

        const final_label = label;

        const res = (resp) => {
            setLoading(false);

            setTimeout(function() {
                toasts.addToast({text: 'Option added', intent: 'success'});
                onAddSelectValue(final_label,resp.data.option_id);
            },250);
        };

        authFetch("/community/customize/add-select-option", res, res, "POST", {payload});
    }

    if (row_type === 'group') {
        const is_custom_select = mapping_data.data_field.startsWith('custom_fields.');
        return <div className={`grid py-px ${striped ? "bg-gray-100" : ""} rounded`}
                    style={{...lie_grid_style, height: '34px'}}>
            <div className="flex-none flex items-center justify-end">
                {lie_getRowIndicator(error, info, number)}
            </div>
            <div className="flex items-center space-x-1.5">
                <div className="text-gray-700 font-medium leading-5 break-all text-sm truncate"
                     style={{maxWidth: '240px'}}>
                    {label ? formatLabel(label) : <LIEBlank/>}
                </div>
                <div>
                    <Badge text={count} color="blue" rounded/>
                </div>
                <div>
                    <LIEToggle onToggle={() => {
                        if (open) {
                            onOpen(false);
                        } else {
                            onOpen(true);
                        }
                    }} open={open}/>
                </div>
            </div>
            <div className="flex items-center justify-end">
                <LIEDirectionArrow/>
            </div>
            <div className="flex items-center">
                <RulesInlineSelect loading={loading} add_option={!is_custom_select||options.map(a=>a.label.toLowerCase()).includes(label.toLowerCase())?"":label} searchable={options.length > 4}
                                   onAddOption={() => {
                                       addOption(label);
                                   }}
                                   usePortal
                                   highlight={selected_option === null || selected_option === '' || selected_option === undefined}
                                   bg={striped ? "gray-100" : "white"} onSelect={(id) => handleSelect(id)} inline
                                   layout="minimal" placeholder="Select Mapping" size="sm" options={options}
                                   selected={selected_option !== null ? selected_option : ""}/>
            </div>
        </div>
    }
    if (errors_only && !error) {
        return null;
    }
    return <div className={`grid py-px ${striped ? "bg-gray-100" : ""} rounded`}
                style={{...lie_grid_style, height: '34px'}}>
        <div className="flex-none flex items-center justify-end">
            {lie_getRowIndicator(error, info, number)}
        </div>
        <div className="flex items-center -ml-2">
            <InlineInput display={formatLabel(raw_value, true)} onChange={handleDataChange}
                         bg_default={striped ? "bg-gray-100" : ""} value={raw_value}/>
        </div>
        <div className="flex items-center justify-end">
            <LIEDirectionArrow/>
        </div>
        <div className="flex items-center pl-2.5 text-gray-700 font-medium text-sm">
            {displayValue(value)}
        </div>
    </div>
}