import React, {useState} from "react";
import {SectionHeader} from "../../../../../m3/_legacy_components/entity-grid";
import Button from "../../../../../m3/_legacy_components/button";
import {useToasts} from "../../../../../config/toasts";
import {useCommunity} from "../../../../../config/community";
import {api_deleteAllSpaceEvents} from "../../../../../../api/events";


export function GMMEvents({
                             setData,
                             cb = () => {
                             },
                             onSaved,
                             id,
                             group
                         }) {
    const community = useCommunity();
   const [state, setState] = useState("");
    const toasts = useToasts();

    function deleteEvents() {
        // ask users to confirm first
        if (!window.confirm("Are you sure you want to delete all events tied to this group?")) {
            return;
        }

        setState("loading");
        api_deleteAllSpaceEvents({
            community_uid: community.uid,
            member_id: community.member_id,
            group_id: id,
        })
            .then((response) => {
                setState("");
                console.log("RESPONSE", response);
                if (!response.ok || response?.ok === 'no') {
                    console.error("ERROR", response);
                    toasts.addToast({text: "something went wrong", intent: "danger"})
                } else if (response?.data?.id) {
                    toasts.addToast({text: "Events deleted", intent: "success"})
                }
            })
    }

    return <div className={`grid gap-6`}>
        <div>
            <SectionHeader title="Manage Events"/>
            <div className="pt-2">
                <Button loading={state==='loading'} onClick={deleteEvents} text={"Delete all Events"} />
            </div>
        </div>
    </div>
}