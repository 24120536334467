import React, {useState} from "react";
import Accordion from "../../../m3/_legacy_components/accordion";
import {ArrowLeftIcon} from "@heroicons/react/20/solid";
import Button from "../../../m3/_legacy_components/button";
import {updateFieldValue} from "../../../m3/_legacy_components/form-fields-renderer";
import {EditMemberAbout} from "../../community/manage-member/edit-sections/intro";
import {user_editProfile} from "./api";
import {useUnaverse} from "../../../config/unaverse";

function buildSections(dt, handleChange) {
    return [
        {
            title: "About",
            component: <EditMemberAbout type={'user'} data={dt} onChange={handleChange}/>
        }
    ];
}

function ArrowAction({children, onClick}) {
    return <div onClick={() => {
        if (onClick) {
            onClick();
        }
    }}
                className="w-6 h-6 rounded-full flex items-center justify-center hover:bg-gray-100 cursor-pointer text-gray-500 hover:text-gray-800">
        <div className="w-5 h-5">
            {children}
        </div>
    </div>
}

function MiniHeader({onClose, title, actions}) {
    return <div className="flex">
        <div className="flex-grow space-x-3 flex items-center">
            <div className="-m-0.5">
                <ArrowAction onClick={() => onClose()}>
                    <ArrowLeftIcon/>
                </ArrowAction>
            </div>
            <div className="font-bold text-gray-800 text-lg">
                {title}
            </div>
        </div>
        <div className="flex space-x-2">
            {actions}
        </div>
    </div>
}

export function EditUser({onClose, data = {}}) {
    const unaverse = useUnaverse();
    const [state,setState] = useState("");
    const [dt, updateDt] = useState({...data});
    const [unsaved_changes, setUnsavedChanges] = useState({});

    function handleChange(accessor, new_value, old_value, field) {
        updateDt({
            ...updateFieldValue(accessor, new_value, dt)
        })
        setUnsavedChanges({...unsaved_changes, [accessor]: true})
    }

    function handleSave() {
        setState("saving")
        user_editProfile(unaverse,{profile:dt,updated_fields:Object.keys(unsaved_changes)})
            .then(()=>{
                setState("");
                onClose();
            })
    }

    const sections = buildSections(dt, handleChange);

    const unsaved_changes_count = Object.keys(unsaved_changes).length;

    const actions = <>
        <Button loading={state==="saving"} onClick={handleSave} disabled={!unsaved_changes_count} text="Save" intent="success"/>
    </>;
    return <div className="relative" style={{minHeight: '20rem'}}>
        <div className="px-4 sticky top-0 bg-white z-10 py-2 border-b border-gray-200">
            <MiniHeader actions={actions} title="Edit Profile" onClose={onClose}/>
        </div>
        <div className="space-y-2 divide-y divide-gray-200 pb-16">
            {sections.map((sec, k) => {
                return <div key={k} className={"pt-2"}>
                    <Accordion open={true} title={sec.title} subtitle={sec.subtitle} padding_x="px-4">
                        <div className="pt-1">
                            {sec.component}
                        </div>
                    </Accordion>
                </div>
            })}
        </div>
    </div>
}