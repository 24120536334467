import {doc, documentId, getDoc} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {chunk, getManyDocuments} from "../../../config/community";

export async function getRoleData(uid, id) {
    if (!uid || !id) {
        console.error('getRoleData', uid, id)
        return null;
    }
    const ref = doc(db, 'community_entities', uid, 'roles', id);
    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? {...doc.data(), id: doc.id} : null;
        });
}

export async function getManyRoles(cid,fids) {
    let p = [];
    const chunks = chunk(fids,10);
    chunks.forEach(a=>{
        p.push(getManyDocuments('community_entities', cid, 'roles', documentId(), a));
    })
    return await Promise.all(p).then(arrs=>{
        return arrs.flat();
    });
}