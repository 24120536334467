import {authFetch} from "../network";

export async function api_muteGroup(community_uid,member_id,id) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        const payload = {
            entity_type: 'group',
            member_id,
            community_uid,
            entity_id: id
        };
        authFetch("/members/mute", res, res, "POST", {payload});
    })
}

export async function api_unmuteGroup(community_uid,member_id,id) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        const payload = {
            entity_type: 'group',
            community_uid,
            member_id,
            entity_id: id
        };
        authFetch("/members/unmute", res, res, "POST", {payload});
    })
}