import React, {useEffect, useState} from "react";
import {
    CommunityEntityPage, PageLayoutSection
} from "../../../m3/_legacy_components/app-frame/layouts";
import EntityProfileCard from "../../../m3/_legacy_components/entity-profile-card";
import {EntityPastAssigneesTab} from "./tabs/past-assignees";
import {useParams} from "react-router-dom";
import {getRoleData} from "./api";
import {useCommunity} from "../../../config/community";

import {useUnaverse} from "../../../config/unaverse";
import {CalendarIcon} from "@heroicons/react/24/outline";
import {setDocumentTitle} from "../../../m3/utilities/set-document-title";
import {utils_dates_format} from "../../../../common/utilities/dates";
import {m3_icon_map} from "../../../m3/icons/icon-map";
import {buildImageUrl} from "../../../../common/utilities/images";

export function buildRoleProfileCard(data, unaverse, community, flags = {}, loading) {
    let title, message, ex = {}, subtitle;

    if (loading) {
        title = "Loading";
    } else if (flags.does_not_exist) {
        title = "This role doesn't exist";
        subtitle = "Try searching for another.";
    } else if (data) {
        title = data.name;
        subtitle = data?.assignee_data?.name ? data.assignee_data.name : "Unassigned";
        ex = {
            desc: data.purpose,
            image: buildImageUrl(data?.cover_photo),
            image_color: data?.cover_photo_color,
            icon: {
                onClick: () => {
                    community.openImage({
                        url: buildImageUrl(data?.assignee_data?.profile_picture, ''),
                        color: data?.profile_picture_color
                    })
                },
                image: buildImageUrl(data?.assignee_data?.profile_picture, '_medium', community.default_member_profile_picture),
                type: "image",
                interactive: true,
                fade_hover: true,
                large: true
            },
            meta: [
                {
                    icon: <CalendarIcon/>,
                    label: `Created ${utils_dates_format(data?.created_at).mid_date}`
                },
            ]
        };

        if (data && data.assignee) {
            ex.onSubtitleClick = () => {
                community.openPreviewOverlay({
                    type: 'member',
                    id: data.assignee
                })
            };
        }
    } else {
        title = "Not found";
    }

    return {
        title,
        subtitle,
        loading: !!loading,
        message,
        // subtitle: getMemberTypeName(data, community),

        icon: {
            image: "",
            type: "image",
            large: true
        },

        layout: {
            large_image: true
        },
        buttons: [],
        ...ex
    }
}

export default function RolePage() {
    const {id} = useParams();
    const community = useCommunity();
    const unaverse = useUnaverse();
    const [compact, setCompact] = useState(false);
    const [dt, setDt] = useState();
    const [error, setError] = useState();
    const flags = {
        can_manage: !!community.is_admin,
        does_not_exist: error && error === "does_not_exist"
    };
    useEffect(function () {
        if (!dt && id) {
            getData();
        }
    }, [id])

    if (dt && dt.name) {
        setDocumentTitle(dt.name, `${community.profile.name}`);
    }

    function getData() {
        getRoleData(community.id, id)
            .then(pr => {
                if (pr) {
                    setDt({...pr, id: id});
                } else {
                    setError("does_not_exist")
                }

            })
    }

    const tabs = [
        /*
        {
            label: "Groups"
        },
        {
            label: "Responsibilities"
        },

         */
        {
            label: "Timeline"
        }
    ];

    const [tab, setTab] = useState(tabs[0].label);

    function renderTab() {
        if (!dt) {
            return <div></div>
        }

        switch (tab) {
            case 'Timeline':
                return <div className="">
                    <EntityPastAssigneesTab id={id} data={dt} getRole={() => {
                        getRoleData(community.id, id)
                            .then((dt1) => {
                                setDt({...dt1, id: id});
                            })
                    }}/>
                </div>
            default:
                return <div>
                    {/* responsibilities: bullet point lists + how the whole team contributes, this is on a per team basis */}
                </div>
        }
    }

    let spec = buildRoleProfileCard(dt, unaverse, community, flags, !dt && !error);

    const epc_header = {
        enabled: false, onManage: () => {
        }, can_manage: false, name: spec.title
    };


    const card = <EntityProfileCard header={epc_header} can_compact onCompact={() => setCompact(true)}
                                    onExpand={() => setCompact(false)} compact={compact} {...spec}/>;
    const sidebar = <></>;
    const modals = <></>;
    let header_actions = [];

    if (flags.can_manage) {
        header_actions.push({
            type: "button",
            label: "Manage",
            onClick: () => {
                community.openEditOverlay({
                    type: 'manage-position',
                    data: dt,
                    handle: dt.handle,
                    closeCb: () => getData()
                })
            }
        });
    }

    return <CommunityEntityPage card={card} sidebar={sidebar} modals={modals} header={{
        title: spec.title,
        actions: header_actions
    }}>
        <PageLayoutSection>
        {renderTab()}
        </PageLayoutSection>
    </CommunityEntityPage>;
};