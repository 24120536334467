import React from 'react';
import FormFieldsRenderer from "../../../../m3/_legacy_components/form-fields-renderer";

export const buildCustomFields = (fields,community,my_profile) => {

    return fields.map((field, k) => {
        return {
            label: field.label,
            type: field.type,
            accessor: field.accessor,

            disabled: !field?.can_edit,
            placeholder: field?.placeholder||"",

            locked: field?.is_locked,

            footer_hint: field.hint||"",

            input_props: {
                community_uid: community.id,
                required: field?.required,
                options: field?.options || {},
                placeholder: field?.placeholder||""
            }
        }
    })
}

export function EditMemberCustomFields({data, fields, fill, community, onChange, section}) {

    function handleOnChange(accessor, new_value, old_value, field) {
        onChange(accessor, new_value, old_value, field)
    }

    const my_profile = data?.id === community?.member_id;

    const render_fields = buildCustomFields(fields,community, my_profile);

    return <div className="space-y-3 pb-4" id="edit-custom-fields">
        <FormFieldsRenderer fill={fill} onChange={handleOnChange} data={data} fields={render_fields}/>
    </div>
}