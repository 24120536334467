import React, {useEffect, useState} from "react";
import {DEFAULT_USER_PHOTO, useCommunity} from "../../../../config/community";
import Button from "../../../../m3/_legacy_components/button";
import EntityList from "../../../../m3/_legacy_components/entity-list";
import {getStartedRecordUsage} from "../../search";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import {AsyncSuggestions} from "../../../../m3/_legacy_components/simple-autocomplete/async";
import {constructSearchResult} from "../../../../m3/_legacy_components/app-frame/search";
import {handleGroupPeopleSearch} from "./handle-group-people-search";
import {PlusIcon} from "@heroicons/react/24/solid";
import {useToasts} from "../../../../config/toasts";
import DataWrapper from "../../../../m3/_legacy_components/data-wrapper";
import {
    ModalBody,
    ModalContentBlock,
    ModalFooter,
    ModalHeader
} from "../../../../m3/_legacy_components/preview-overlay";
import {getGroupPeopleSuggestions} from "../../../../../api/groups/get-people-suggestions";
import {api_addPeopleToGroup} from "../../../../../api/groups/add-people";
import {buildImageUrl} from "../../../../../common/utilities/images";
import {GenericModal} from "../../onboarding/designer";
import M3_A_Button from "../../../../m3/atoms/button";

function buildEntityListItems(ae, handleRemove) {
    let b = [];

    ae.forEach(a => {
        b.push({
            title: a.raw.label,
            subtitle: a.raw.sublabel,
            id: a.id,
            sub_icon: a.raw.type === "role" ? "role" : "",
            icon: {
                type: "small-image",
                image: `${buildImageUrl(a.raw.image, "_medium", DEFAULT_USER_PHOTO)}`
            },
            type: a.type,
            actions: [
                {
                    type: "remove",
                    message: "Remove",
                    onClick: () => handleRemove(a.id)
                }
            ],
            onClick: null
        })
    })

    return b;
}

function buildAddedIds(data, cid, add_entities) {
    let a = [];

    const {members, roles} = data.entities;

    Object.keys(members).forEach(m => {
        a.push(`${cid}-member-${m}`)
    })

    Object.keys(roles).forEach(r => {
        a.push(`${cid}-role-${r}`)
    })

    add_entities.forEach(ae => {
        a.push(`${cid}-${ae.id}`)
    })

    return a;
}

function Suggestion({data, id, onClick}) {
    if(!data.name&&!data.title) {
        return null;
    }
    return <div onClick={() => onClick(id, data)}
                className="rounded-lg hover:bg-gray-200 cursor-pointer flex items-center pl-1 pr-2 space-x-1.5 py-0.5 border-gray-200 border bg-white ">
        <div className="h-4 w-4 text-blue-600">
            <PlusIcon/>
        </div>
        <div className="text-gray-700 whitespace-nowrap text-sm font-medium">
            {data.name||data.title||""}
        </div>
    </div>
}

function getTypeCollection(type) {
    if(type==="role") {
        return "community_entities";
    }
    return "community_members";
}

function Suggestions({
                         handleClick = () => {
                         }, community_id, suggestions = []
                     }) {
    const [clicked, setClicked] = useState({});
    if (suggestions.length === 0) {
        return null;
    }
    return <div className="flex space-x-2 overflow-x-auto no-scrollbars">
        {suggestions.map(({type, id}, key) => {
            if (clicked[`${type}-${id}`]) {
                return null;
            }
            return <DataWrapper id={id} key={`${type}-${id}`} type={`${getTypeCollection(type)}.${community_id}.${type}s`}>
                <Suggestion id={id} onClick={(a, b) => {
                    setClicked({
                        ...clicked,
                        [`${type}-${id}`]: true
                    })
                    handleClick(a, type, b);
                }}/>
            </DataWrapper>
        })}
    </div>
}

export function getMemberSubtitle(it, mts, key="sub_type") {
    if (it.type === "member") {
        const mt = mts.findIndex(a => a.id === it[key]);
        if (mt !== -1) {
            return `${mts[mt].singular}`;
        }
        return "..."
    } else if (it.type === "role") {
        return "..."
    }
    return "";
}

function prepareEntities(add_entities) {
    let a = {
        roles: {},
        members: {},
        member_types: {},
        groups: {},
        filters: {},
    };

    add_entities.forEach(ae => {
        const [type, id] = ae.id.split("-");
        switch (type) {
            case "role":
                a.roles[id] = true;
                break;
            case "member":
                a.members[id] = true;
                break;
            case "filter":
                a.filters[id] = true;
                break;
            case "member_type":
                a.member_types[id] = true;
                break;
        }
    })

    return a;
}

/*
 title: a.raw.label,
            subtitle: a.raw.sublabel,
            id: a.id,
            sub_icon: a.raw.type === "role" ? "role" : "",
            icon: {
                type: "small-image",
                image: `${buildImageUrl(a.raw.image, "_medium", DEFAULT_USER_PHOTO)}`
            },
            type: a.type,
            actions: [
                {
                    type: "remove",
                    message: "Remove",
                    onClick: () => handleRemove(a.id)
                }
            ],
            onClick: null
 */
const transformRaw = (id,type,data,community) => {
    let sr = {};
    if(type==="member") {
        sr = constructSearchResult({id,...data},type);
        sr.sublabel = getMemberSubtitle({...data,type}, community.member_types, "member_type");
    }
    if(type==="role") {
        const prepped = {id,...data};

        sr = constructSearchResult(prepped,type);

    }

    return sr;
}

export function GroupAddPeople({onClose, data}) {
    const community = useCommunity();
    const toasts = useToasts();
    const [state, setState] = useState();
    const [suggestions, setSuggestions] = useState([]);
    const [add_entities, setAddEntities] = useState([]);

    useEffect(function () {
        getStartedRecordUsage(community.id, 'groupaddmembers');
    }, [community.id])

    useEffect(function () {
        if (data.id) {
            getGroupPeopleSuggestions(community, data.id)
                .then(resp => {
                    setSuggestions(resp||[]);
                })
        }
    }, [data.id])

    const can_add = add_entities.length > 0;

    const existing_ids = buildAddedIds(data, community.id, add_entities);

    const custom_transformer = (a) => {
        const already_added = existing_ids.includes(a.id);
        let f = {
            ...a,
            subtitle: getMemberSubtitle(a, community.member_types),
            label: a.title || "",
            name: a.title || "",
            disabled: already_added || a.boolean_one && a.boolean_one === "true",
            note: already_added ? "Already added" : ""
        };
        return f;
    };

    function handleSelect(dt) {
        setAddEntities([
            ...add_entities,
            {
                id: `${dt.type}-${dt.id}`,
                raw: dt
            }
        ])
    }

    function handleRemove(id) {
        const index = add_entities.findIndex(a => a.id === id);

        if (index > -1) {
            let nae = [...add_entities];
            nae.splice(index, 1);
            setAddEntities(nae);
        }
    }

    function handleAddEntities() {
        setState("loading");
        api_addPeopleToGroup({
            community_uid: community.id,
            member_id: community.member_id
        }, data.id, prepareEntities(add_entities))
            .then(resp => {
                setState("");
                if (!resp.ok || resp.ok === 'no') {
                    toasts.addToast({intent: "danger", text: "Something went wrong"})
                } else {
                    toasts.addToast({intent: "success", text: "Members added"});
                    onClose();
                }
            })
    }

    function handleOpenCreateNewMember() {
        community.openEditOverlay({
            type: 'add-member',
            data: {
                flow: 'add-to-group',
                space_id: data.id,
                group_id: data.id,
                group_data: data
            },
            onCloseCb: () => {
                onClose();
            }
        })
    }

    function handleSuggestionClick(id, typ, data) {
        // check if is already added
        const index = add_entities.findIndex(a => a.id === `${typ}-${id}`);
        if(index!==-1) {
            return;
        }
        const transformed_raw = transformRaw(id,typ,data,community);

        handleSelect(transformed_raw)
    }

    return <GenericModal title={`Add People to ${data?.name}`} onClose={onClose}>
        <ModalContentBlock>
            <div className="space-y-4">
                <div className="space-y-3">
                    <AsyncSuggestions portal reset_on_click stopSearchFocus={() => {
                    }} external_focus={false} clearable onClick={handleSelect}
                                      handleSearch={q => handleGroupPeopleSearch(q, community.id, data.id, {}, custom_transformer)}
                                      layout="large"
                                      default_options={[]} show_menu_focus
                                      placeholder="Search"
                                      icon={<div className="absolute top-2.5 left-2.5 h-5 text-gray-500 w-5">
                                          <MagnifyingGlassIcon/>
                                      </div>}
                                      input_classes={`pl-10 w-full focus-within:bg-white h-10 flex justify-start items-center flex-grow rounded-2xl border`}/>

                    <div className="h-6">
                        <Suggestions community_id={community.id} suggestions={suggestions} handleClick={handleSuggestionClick}/>
                    </div>
                </div>

                <div className="-mx-4" style={{minHeight: '6rem'}}>
                    <EntityList minimal interactive items={buildEntityListItems(add_entities, handleRemove)}
                                community_uid={community.id}/>
                </div>
            </div>
        </ModalContentBlock>
        <ModalFooter left={<Button onClick={()=>{

        }} text="Create new Member" left_icon={{icon:<PlusIcon />}} intent="minimal" />} right={<Button loading={state === "loading"} onClick={handleAddEntities} disabled={!can_add} text="Add"
                                    intent="success"/>} />
    </GenericModal>
}