import dayjs from "dayjs";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../app/config/setup-firestore";

const email_templates = [
    {
        value: 'new-member-invite',
        label: 'Member Invite',
        desc: '',
        fallback: {
            internal_name: 'Member Invite',
            subject: "Join {{community.name}} on Orange",
            content: `{"blocks":[{"key":"5him4","text":"{{sender.name}} ({{sender.account_email}}) invited you to join {{community.name}} on Orange.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":0,"length":15,"key":0},{"offset":17,"length":24,"key":1},{"offset":63,"length":18,"key":2}],"data":{}}],"entityMap":{"0":{"type":"{{mention","mutability":"IMMUTABLE","data":{"mention":{"id":"{{sender.name}}","name":"{{sender.name}}","example":""}}},"1":{"type":"{{mention","mutability":"IMMUTABLE","data":{"mention":{"id":"{{sender.account_email}}","name":"{{sender.account_email}}","example":""}}},"2":{"type":"{{mention","mutability":"IMMUTABLE","data":{"mention":{"id":"{{community.name}}","name":"{{community.name}}","example":""}}}}}`
        }
    },
    {
        value: 'data-check',
        label: 'Member Data Check',
        desc: '',
        fallback: {
            internal_name: 'Member Data Check',
            subject: "Check your Member Profile",
            content: `{"blocks":[{"key":"5him4","text":"Hi {{member.first_name}},","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":3,"length":21,"key":0}],"data":{}},{"key":"3bfv2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"6tjg4","text":"We're missing some information in your Member Profile. Please take time to update your profile.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{"0":{"type":"{{mention","mutability":"IMMUTABLE","data":{"mention":{"id":"{{member.first_name}}","name":"{{member.first_name}}","example":""}}}}}`
        }
    },
    {
        value: 'policy-update',
        label: 'Policy Update',
        desc: '',
        fallback: {
            internal_name: 'Policy Update',
            subject: "Policy Update",
            content: `{"blocks":[{"key":"5him4","text":"Hi {{member.first_name}},","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":3,"length":21,"key":0}],"data":{}},{"key":"3bfv2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"6tjg4","text":"We've updated our policies. Please review the changes.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{"0":{"type":"{{mention","mutability":"IMMUTABLE","data":{"mention":{"id":"{{member.first_name}}","name":"{{member.first_name}}","example":""}}}}}`
        }
    },
    {
        value: 'opt-in',
        label: 'Opt-In',
        desc: '',
        fallback: {
            internal_name: 'Opt-In',
            subject: "Opt-In",
            content: `{"blocks":[{"key":"5him4","text":"Hi {{member.first_name}},","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":3,"length":21,"key":0}],"data":{}},{"key":"3bfv2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"6tjg4","text":"Please take a moment to opt-in for community membership.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{"0":{"type":"{{mention","mutability":"IMMUTABLE","data":{"mention":{"id":"{{member.first_name}}","name":"{{member.first_name}}","example":""}}}}}`
        }
    }
]

export const composer_merge_tags = [
    {
        label: "Date / Time",
        scope: "*",
        tags: [
            {
                label: "Date",
                subtitle: "{{date}}",
                fn: (m)=>`${dayjs().format('MMMM Do YYYY')}`
            },
            {
                label: "Date & Time",
                subtitle: "{{date:time}}",
                fn: (m)=>`${dayjs().format('MMMM Do YYYY, h:mm:ss a')}`
            },
        ]
    },
    {
        label: "Member",
        scope: "direct",
        tags: [
            {
                label: "Name",
                subtitle: "{{member.name}}",
                fn: (m)=>m.name
            },
            {
                label: "First Name",
                subtitle: "{{member.first_name}}",
                fn: (m)=>m.about.first_name
            },
            {
                label: "Last Name",
                subtitle: "{{member.last_name}}",
                fn: (m)=>m.about.last_name
            },
            {
                label: "Account Email",
                subtitle: "{{member.account_email}}",
                fn: (m)=>m.account_email
            },
            {
                label: "Phone",
                subtitle: "{{member.phone}}",
                fn: (m)=>m.contact.phone
            },
        ]
    },
    {
        label: "Sender",
        scope: "direct",
        tags: [
            {
                label: "Name",
                subtitle: "{{sender.name}}",
                fn: (m,c,s)=>s.name
            },
            {
                label: "First Name",
                subtitle: "{{sender.first_name}}",
                fn: (m,c,s)=>s.about.first_name
            },
            {
                label: "Last Name",
                subtitle: "{{sender.last_name}}",
                fn: (m,c,s)=>s.about.last_name
            },
            {
                label: "Account Email",
                subtitle: "{{sender.account_email}}",
                fn: (m,c,s)=>s.account_email
            },
            {
                label: "Phone",
                subtitle: "{{sender.phone}}",
                fn: (m,c,s)=>s.contact.phone
            },
        ]
    },
    {
        label: "Community Info",
        scope: "*",
        tags: [
            {
                label: "Name",
                subtitle: "{{community.name}}",
                fn: (m,c)=>c.name
            }
        ]
    }
];


export async function getEmailTemplateData(community_uid, template_id) {
    const data = await loadTemplateData(community_uid, template_id);

    if(data) {
        return data;
    } else {
        // return fallback of item based on id
        const item = email_templates.filter(it=>it.value===template_id)[0];

        if(item) {
            return item.fallback;
        } else {
            return {
                internal_name: '',
                subject: '',
                content: ''
            }
        }
    }
}

async function loadTemplateData(community_uid,template_id) {
    console.log("loadTemplateData",community_uid,template_id)
    if(!template_id) {
        return null;
    }
    if(!community_uid) {
        return null;
    }
    const ref = doc(db, 'community_content', community_uid, 'email_templates', template_id);
    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? {...doc.data(), id: doc.id} : null;
        });
}