import React from 'react';
import FormFieldsRenderer from "../../../../m3/_legacy_components/form-fields-renderer";
import {isAnonymousUnatyEmail} from "../../../../m3/utilities/email";
import {TextAction} from "../../../auth/sign-in";
import FindMeOn from "../../../../m3/_legacy_components/find-me-on";
import {useCommunity} from "../../../../config/community";
import {getPhoneServices} from "../../member/member-profile-utils";

function buildEmailsFromUser(user) {
    let arr = [];
    if (isAnonymousUnatyEmail(user.account_email)) {
        return arr;
    }
    if (user.emails_status && user.emails_status[user.account_email]) {
        arr.push({
            value: user.account_email,
            label: user.account_email,
            status: user.emails_status[user.account_email].status
        });
    } else {
        arr.push({
            value: user.account_email,
            label: user.account_email,
            status: 'unverified'
        })
    }

    if (user.secondary_emails && user.emails_status) {
        user.secondary_emails.forEach(se => {
            const se_status = user.emails_status[user.account_email] ? user.emails_status[user.account_email].status : 'verified';
            arr.push({
                value: se,
                label: se,
                status: se_status
            });
        });
    }

    return arr;
}

function buildFields(member, community, fns, handleOnChange) {
    const ps = getPhoneServices(member);
    return [
        {
            label: "Email",
            type: 'email-select',
            accessor: "contact.email",
            required: true,
            footer_hint: <span>This email will appear on your profile and be visible to other members.</span>,
            input_props: {
                onAddEmail: fns.addEmail,
                options: buildEmailsFromUser(member)
            },
            corner_hint: (member.secondary_emails.length < 5 && !isAnonymousUnatyEmail(member.account_email)) ?
                <TextAction text={"Add Email"} onClick={() => fns.addEmail()}/> : null
        },
        {
            label: "Phone",
            type: 'phone',
            accessor: "contact.phone",
            footer: <FindMeOn can_edit updateMember={(a, b, c) => {
                console.log("UPDATE MEMBER", a, b, c);
                handleOnChange(`phone_services.${b}`, c, ps['phone'], 'phone_services');
            }} field="phone" id={member.id} my_profile={member.id === community.member.id}
                              active={ps[`phone`]}/>,
            required: true
        },
    ];
}


export function EditMemberContact({data, fill, onChange, fns}) {
    const community = useCommunity();

    function handleOnChange(accessor, new_value, old_value, field) {
        onChange(accessor, new_value, old_value, field)
    }

    const fields = buildFields(data, community, fns, handleOnChange);

    return <div className="space-y-3 pb-4" id="edit-contact">
        <FormFieldsRenderer fill={fill} onChange={handleOnChange} data={data} fields={fields}/>
    </div>
}