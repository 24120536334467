/*
Orange M3 Color System

How it works:
1. app layout supports a custom accent color, there are 10 colors by default but any color can be used
2. user can also select if the mode is light, dark, or auto (based on system settings)
3. if in light mode, user can also set a brightness from 1 to 5
4. user can choose set a notification color from one of 10 generated colors based on the accent color in certain color profile (red, green, blue, yellow, orange, purple, pink, brown, gray, black)
5. app layout is as follows: a background color which is derived from the accent color, mode, and brightness (if light mode), a foreground content color which is always white if light mode and black if dark mode
6. the app content frame has rounded edges and an outline if in dark mode that's the divider color based on the foreground content color
7. the app content frame has a shadow if in light mode
8. for links and content directly on the background, we also generate an active background color as well as for hover and pressed states
9. we also need to generate a text color for items on the background which will be close to white for dark mode or light mode with brightness of 4 or 5
10. we also generate a primary button color that works well on the background (with 2 darker variations for light mode and 2 lighter variations for dark mode)


we get lighter or darker variations of the accent color by changing the hue, saturation, and lightness values
brightness of 1 is very light, 5 is very dark
 */

const system_colors = [
    "#3444d9"
];

function hexToRgb(hex) {
    // https://stackoverflow.com/a/5624139/861745
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

    const hex_clean = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex_clean);

    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: 1
    } : null;
}

function rgbaToHex(r, g, b, a) {
    // Ensure that the values are within valid ranges
    r = Math.round(Math.min(255, Math.max(0, r)));
    g = Math.round(Math.min(255, Math.max(0, g)));
    b = Math.round(Math.min(255, Math.max(0, b)));
    a = Math.min(1, Math.max(0, a));

    // Convert RGB to hexadecimal
    const hexR = r.toString(16).padStart(2, '0');
    const hexG = g.toString(16).padStart(2, '0');
    const hexB = b.toString(16).padStart(2, '0');

    // Convert alpha to hexadecimal (multiply by 255 and round to the nearest integer)
    const hexA = Math.round(a * 255).toString(16).padStart(2, '0');

    // Combine the components and return the hexadecimal color
    return `#${hexR}${hexG}${hexB}${hexA}`;
}

function rgbToHex(r, g, b) {
    // https://stackoverflow.com/a/5624139/861745
    const rgb = b | (g << 8) | (r << 16);
    return '#' + (0x1000000 + rgb).toString(16).slice(1);
}

function rgbToHsl(r, g, b) {
    // https://stackoverflow.com/a/9493060/861745
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b), min = Math.min(r, g, b);

    let h, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        const d = max - min;

        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;

            case g:
                h = (b - r) / d + 2;
                break;

            case b:
                h = (r - g) / d + 4;
                break;

            default:
                break;
        }

        h /= 6;
    }

    return [h, s, l];
}

function hslToRgb(h, s, l, a = 1) {
    // https://stackoverflow.com/a/9493060/861745
    let r, g, b;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        const hue2rgb = (p, q, t) => {
            if (t < 0) t += 1;

            if (t > 1) t -= 1;

            if (t < 1 / 6) return p + (q - p) * 6 * t;

            if (t < 1 / 2) return q;

            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;

            return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;

        const p = 2 * l - q;

        r = hue2rgb(p, q, h + 1 / 3);

        g = hue2rgb(p, q, h);

        b = hue2rgb(p, q, h - 1 / 3);
    }

    return [r * 255, g * 255, b * 255, a];
}

// given a color, generate a red color that works well with it
function generateMatchingColor(color, target_color = "#ff0000") {
    return generateContrastColor(color, target_color);
    //return deriveComplementaryColorHex(color, 108);
}

function generateContrastColor(backgroundHex, targetColorHex) {
    // Convert hex to RGB
    const hexToRgb = (hex) => {
        const bigint = parseInt(hex.slice(1), 16);
        return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
    };

    // Convert RGB to HSL
    const rgbToHsl = ([r, g, b]) => {
        r /= 255;
        g /= 255;
        b /= 255;

        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;

        if (max === min) {
            h = s = 0; // achromatic
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }

            h = (h * 60 + 360) % 360; // Convert hue to degrees
        }

        return [h, s * 100, l * 100];
    };

    // Convert HSL to RGB
    const hslToRgb = ([h, s, l]) => {
        h /= 360;
        s /= 100;
        l /= 100;

        let r, g, b;

        if (s === 0) {
            r = g = b = l; // achromatic
        } else {
            const hue2rgb = (p, q, t) => {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1 / 6) return p + (q - p) * 6 * t;
                if (t < 1 / 2) return q;
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                return p + (q - p) * (2 / 3 - t) * 6;
            };

            const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            const p = 2 * l - q;

            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
        }

        return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    };

    // Convert RGB to hex
    const rgbToHex = ([r, g, b]) => `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)}`;

    // Lighten or darken the target color based on the background color
    const backgroundRgb = hexToRgb(backgroundHex);
    const targetColorRgb = hexToRgb(targetColorHex);

    const contrastColorRgb = targetColorRgb.map((channel, index) => {
        const diff = backgroundRgb[index] - channel;
        const adjustedChannel = channel + diff * 0.2; // Adjust the multiplier as needed
        return Math.min(255, Math.max(0, adjustedChannel));
    });

    const contrastColorHex = rgbToHex(contrastColorRgb);
    return contrastColorHex;
}

export const m3_notification_colors = [
    {
        label: "Red",
        value: "#ff0000"
    },
    {
        label: "Orange",
        value: "#ff8000"
    },
    {
        label: "Yellow",
        value: "#ffff00"
    },
    {
        label: "Green",
        value: "#1ea619"
    },
    {
        label: "Blue",
        value: "#0066ef"
    },
    {
        label: "Indigo",
        value: "#4b0082"
    },
    {
        label: "Violet",
        value: "#8f00ff"
    },
    {
        label: "Pink",
        value: "#ff00ff"
    }
]

function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}

function invertColor(hex, bw) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    let r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
}

export function getNotificationColorOptions(accent_color = "#3444d9", mode = "light", brightness = 5) {
    // first we need to get the background color
    const background_color = deriveThemeBackgroundColor(accent_color, mode, brightness);

    let opts = [...m3_notification_colors];

    // remove any colors that are too close to the background color

    // add white if light mode and brightness is 4 or 5 OR DARK MODE
    // add black if is light mode and brightness is 1 or 2 or 3

    if (mode === "light" && brightness > 3) {
        opts.push({
            label: "White",
            value: "#ffffff"
        })
    } else if (mode === "light" && brightness <= 3) {
        opts.push({
            label: "Black",
            value: "#000000"
        })
    } else {
        opts.push({
            label: "White",
            value: "#ffffff"
        })
    }
    /*
    .filter((opt) => {
        // check if hue is too close to background hue, like within 10 degrees
        const opt_rgb = hexToRgb(opt.value);
        const opt_hsl = rgbToHsl(opt_rgb.r, opt_rgb.g, opt_rgb.b);

        const hue_diff = Math.abs(opt_hsl[0] - background_hsl[0]);

        return hue_diff > 0.1;
    })
     */

    const background_rgb = hexToRgb(background_color);
    const background_hsl = rgbToHsl(background_rgb.r, background_rgb.g, background_rgb.b);

    return opts.map((opt) => {
        // now add a display color
        opt.display = generateMatchingColor(background_color, opt.value);
        opt.display_text = invertColor(opt.display, true);
        return opt;
    })
}


function deriveComplementaryColorHex(accentColor, targetHue) {
    // Convert hex to RGB
    const hexToRgb = (hex) => {
        const bigint = parseInt(hex.slice(1), 16);
        return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
    };

    // Convert RGB to HSL
    const rgbToHsl = ([r, g, b]) => {
        r /= 255;
        g /= 255;
        b /= 255;

        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;

        if (max === min) {
            h = s = 0; // achromatic
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }

            h = (h * 60 + 360) % 360; // Convert hue to degrees
        }

        return [h, s * 100, l * 100];
    };

    // Convert HSL to RGB
    const hslToRgb = ([h, s, l]) => {
        h /= 360;
        s /= 100;
        l /= 100;

        let r, g, b;

        if (s === 0) {
            r = g = b = l; // achromatic
        } else {
            const hue2rgb = (p, q, t) => {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1 / 6) return p + (q - p) * 6 * t;
                if (t < 1 / 2) return q;
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                return p + (q - p) * (2 / 3 - t) * 6;
            };

            const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            const p = 2 * l - q;

            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
        }

        return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    };

    // Convert RGB to hex
    const rgbToHex = ([r, g, b]) => `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)}`;

    // Derive complementary color with specified target hue
    const accentRgb = hexToRgb(accentColor);
    const accentHsl = rgbToHsl(accentRgb);

    // Calculate the hue difference
    let hueDiff = targetHue - accentHsl[0];
    if (hueDiff < 0) {
        hueDiff += 360;
    }

    // Calculate the complementary hue
    const complementaryHue = (accentHsl[0] + 180) % 360;

    // Adjust the hue to match the target hue
    const adjustedHue = (complementaryHue + hueDiff) % 360;

    // Keep the adjusted hue, adjust saturation and lightness for the complementary color
    const complementaryRgb = hslToRgb([adjustedHue, 70, 50]);
    const complementaryHex = rgbToHex(complementaryRgb);

    return complementaryHex;
}

function deriveThemeBackgroundColor(accent_color = "#3444d9", mode = "light", brightness = 5) {
    const accent_color_rgb = hexToRgb(accent_color);

    const accent_color_hsl = rgbToHsl(accent_color_rgb.r, accent_color_rgb.g, accent_color_rgb.b);

    let accent_hue = accent_color_hsl[0];
    let accent_saturation = accent_color_hsl[1];

    // this should be dependent on brightness
    let bg_base_luminance;

    if (mode === "light") {
        bg_base_luminance = brightness === 1 ? 0.90 : brightness === 2 ? 0.85 : brightness === 3 ? 0.8 : brightness === 4 ? 0.2 : 0.08;
    } else {
        bg_base_luminance = 0.04;
    }

    const bg_hsl = [accent_hue, accent_saturation, bg_base_luminance];

    return rgbToHex(...hslToRgb(...bg_hsl));
}

function getBoxShadow(brightness) {
    if (brightness === 5) {
        return "0 10px 12px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)";
    } else if (brightness === 4) {
        return "0 6px 10px -5px rgb(0 0 0 / 0.1), 0 2px 4px -1px rgb(0 0 0 / 0.1)";
    } else if (brightness === 3) {
        return "0 3px 8px -1px rgb(0 0 0 / 0.1), 0 1px 3px -1px rgb(0 0 0 / 0.1)";
    } else if (brightness === 2) {
        return "0 1px 6px -1px rgb(0 0 0 / 0.1), 0 1px 1px -1px rgb(0 0 0 / 0.1)";
    } else {
        return "0 1px 4px 0 rgb(0 0 0 / 0.1)";
    }
}


function hexToHsl(hex) {
    const rgb = hexToRgb(hex);
    return rgbToHsl(rgb.r, rgb.g, rgb.b);
}

/*
legacy theme
active_item_bg
:
"#359FDD"
active_item_text
:
"#FFFFFF"
adjust_for_contrast
:
true
divider_bg
:
"#9eac95"
hover_item_bg
:
"#FFFFFF"
is_dark
:
false
item_text
:
"#686D72"
item_text_highlight
:
"#565b5e"
primary_text
:
"#686D72"
sidebar_bg
:
"#AEBEA4"
update_bg
:
"#DA5A62"
 */
export function getM3Theme(accent_color, mode = "light", brightness = 5, notification = "#ff0000") {
    if (accent_color) {
        const theme = m3_generateTheme(accent_color, mode, brightness, notification);

        if (theme) {
            return theme;
        }
    }
    return null;
}

export function m3_generateTheme(accent_color = "#3444d9", mode = "light", brightness = 5, notification_color = '#ff0000') {
    let theme = {
        accent_color: accent_color,
        mode: mode,
        brightness: brightness,

        accent_color_hue: "",
        accent_color_saturation: "",
        accent_color_lightness: "",

        derived: {
            light: {
                background_80: "",
                background_60: "",
                background_40: "",
                background_20: "",

                background_text_80: "",
                background_text_60: "",
                background_text_40: "",
                background_text_20: "",

                background: "",
                background_hover: "",
                background_pressed: "",
                background_active: "",
                background_divider: "",
                background_active_text: "",

                background_text: "",

                dynamic_background: "",

                foreground: "#ffffff",
                foreground_text: "#000000",
                divider: "#efefef",

                box_shadow: "",

                border_color: "",

                primary: "",
                primary_text: "",
                primary_hover: "",
                primary_pressed: "",
                primary_active: "",

                notification: "",
                notification_text: ""
            },

            dark: {
                background_80: "",
                background_60: "",
                background_40: "",
                background_20: "",

                background_text_80: "",
                background_text_60: "",
                background_text_40: "",
                background_text_20: "",


                background: "",
                background_hover: "",
                background_pressed: "",
                background_active: "",
                background_divider: "",
                background_active_text: "",

                dynamic_background: "",

                background_text: "",

                foreground: "#000000",
                foreground_text: "#ffffff",
                divider: "#212121",

                box_shadow: "",

                border_color: "#212121",

                primary: "",
                primary_text: "",
                primary_hover: "",
                primary_pressed: "",
                primary_active: "",

                notification: "",
                notification_text: ""
            }
        }
    };

    const light_mode_bg_base_luminance = brightness === 1 ? 0.90 : brightness === 2 ? 0.85 : brightness === 3 ? 0.8 : brightness === 4 ? 0.2 : 0.08;
    const dark_mode_bg_base_luminance = 0.05;


    // get the hue, saturation, and lightness values from the accent color
    const accent_color_rgb = hexToRgb(accent_color);

    const accent_color_hsl = rgbToHsl(accent_color_rgb.r, accent_color_rgb.g, accent_color_rgb.b);

    let accent_hue = accent_color_hsl[0];
    let accent_saturation = accent_color_hsl[1];
    let accent_lightness = accent_color_hsl[2];

    theme.accent_color_hue = accent_hue;
    theme.accent_color_saturation = accent_saturation;
    theme.accent_color_lightness = accent_lightness;

    // LIGHT MODE THEME
    // get the background color based on the accent color, mode, and brightness
    // if brightness is 1, we want a very light background color
    // if brightness is 5, we want a very dark background color
    const light_mode_dark_bg = brightness > 3;
    // if light mode and brightness > 3, then we will have a dark background and need lighter hover, pressed, and active colors + text

    // this should be dependent on brightness

    theme.derived.light.box_shadow = getBoxShadow(brightness);

    theme.derived.light.background = deriveThemeBackgroundColor(accent_color, "light", brightness);

    const light_bg_hsl = hexToHsl(theme.derived.light.background);

    let light_mode_bg_hover_hsl, light_mode_bg_pressed_hsl, light_mode_bg_active_hsl, light_mode_bg_divider_hsl,
        light_mode_bg_text_hsl, light_mode_bg_active_text_hsl;

    // let's try a whited out approach
    if (light_mode_dark_bg) {
        // now background divider, this should be 10% lighter than the background
        light_mode_bg_divider_hsl = [accent_hue, accent_saturation, light_mode_bg_base_luminance + 0.04];

        // now background hover, this should be 20% lighter than the background
        light_mode_bg_hover_hsl = [accent_hue, accent_saturation, light_mode_bg_base_luminance + 0.07];

        // now background pressed, this should be 30% lighter than the background
        light_mode_bg_pressed_hsl = [accent_hue, accent_saturation, light_mode_bg_base_luminance + 0.14];

        // now background text, this should be nearly completely white
        light_mode_bg_text_hsl = [accent_hue, accent_saturation, 0.85];


        // let's make the active item bg very light and then have the text be very dark
        // how do we start with white and then adjust the hue and saturation to match the accent color?
        // old light_mode_bg_active_hsl = [accent_hue, accent_saturation, light_mode_bg_base_luminance + 0.12];
        light_mode_bg_active_hsl = [accent_hue, accent_saturation, 0.87];

        //light_mode_bg_active_hsl = deriveActiveBackgroundColor(theme.derived.light.background);
        // light_mode_bg_active_text_hsl = [accent_hue, accent_saturation, 0.95];
        light_mode_bg_active_text_hsl = [accent_hue, accent_saturation, 0.05];

        // add dynamic background color
        theme.derived.light.dynamic_background = "radial-gradient(circle, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.05) 100%)";

    } else {
        // now background divider, this should be 10% darker than the background
        light_mode_bg_divider_hsl = [accent_hue, accent_saturation, light_mode_bg_base_luminance - 0.08];

        // now background hover, this should be 20% darker than the background
        light_mode_bg_hover_hsl = [accent_hue, accent_saturation, light_mode_bg_base_luminance - 0.02];

        // now background pressed, this should be 30% darker than the background
        light_mode_bg_pressed_hsl = [accent_hue, accent_saturation, light_mode_bg_base_luminance - 0.04];

        // now background active, this should be 40% darker than the background
        light_mode_bg_active_hsl = [accent_hue, accent_saturation, light_mode_bg_base_luminance - 0.28];

        // now background text, this should be nearly completely black
        light_mode_bg_text_hsl = [accent_hue, accent_saturation, 0.15];

        // now background active text, this should be nearly completely black
        light_mode_bg_active_text_hsl = [accent_hue, accent_saturation, 0.95];

        // add dynamic background color
        theme.derived.light.dynamic_background = "radial-gradient(circle, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.05) 100%)";
    }

   // theme.derived.light.background_divider = rgbToHex(...hslToRgb(...light_mode_bg_divider_hsl));
    theme.derived.light.background_divider = lightenDarkenColor(theme.derived.light.background, 20*(brightness>3?1:-1))
    theme.derived.light.background_hover = rgbaToHex(...hslToRgb(...light_mode_bg_hover_hsl), 0.1);
    theme.derived.light.background_pressed = rgbToHex(...hslToRgb(...light_mode_bg_pressed_hsl));
    theme.derived.light.background_active = rgbToHex(...hslToRgb(...light_mode_bg_active_hsl));
    theme.derived.light.background_text = rgbToHex(...hslToRgb(...light_mode_bg_text_hsl));
    theme.derived.light.background_active_text = rgbToHex(...hslToRgb(...light_mode_bg_active_text_hsl));

    // now to the primary color, we want to use the accent color but depending on the mode adjust it so the contrast is good which we'll do by adjusting the luminance.
    // if we need to increase the luminance we should do so relatively and also if we drastically increase the luminance we should slightly adjust the hue to keep the color looking good

    // if light mode, we need a primary color with a minimum luminance of 0.65
    const light_mode_luminance_max = 0.4;
    const light_mode_luminance_min = 0.1;

    let light_mode_luminance_diff, light_mode_primary_hue, light_mode_primary_hue_relative_offset,
        light_mode_primary_hsl, light_mode_primary_text_hsl;
    // if accent_color lumination is less than 0.65, we need to increase it
    if (accent_lightness > light_mode_luminance_max) {
        // how much do we need to increase it by?
        light_mode_luminance_diff = accent_lightness - light_mode_luminance_max;

        // now we need to increase the luminance of the accent color by the same amount
        // we also need to slightly adjust the hue to keep the color looking good
        light_mode_primary_hue_relative_offset = light_mode_luminance_diff * 0.05;

        if (accent_hue < 0.5) {
            light_mode_primary_hue = accent_hue + light_mode_primary_hue_relative_offset;
        } else {
            light_mode_primary_hue = accent_hue - light_mode_primary_hue_relative_offset;
        }

        light_mode_primary_hsl = [light_mode_primary_hue, accent_saturation, accent_lightness - light_mode_luminance_diff];
        theme.derived.light.primary = rgbToHex(...hslToRgb(...light_mode_primary_hsl));

        light_mode_primary_text_hsl = [light_mode_primary_hue, accent_saturation, 0.95];
    } else if (accent_lightness < light_mode_luminance_min) {
        // we need to make sure it's not too dark...
        light_mode_luminance_diff = light_mode_luminance_min - accent_lightness;

        // let's set it back to lightness of 0.1
        light_mode_primary_hue_relative_offset = light_mode_luminance_diff * 0.05;

        if (accent_hue < 0.5) {
            light_mode_primary_hue = accent_hue - light_mode_primary_hue_relative_offset;
        } else {
            light_mode_primary_hue = accent_hue + light_mode_primary_hue_relative_offset;
        }

        light_mode_primary_hsl = [light_mode_primary_hue, accent_saturation, accent_lightness + light_mode_luminance_diff];
        theme.derived.light.primary = rgbToHex(...hslToRgb(...light_mode_primary_hsl));

        light_mode_primary_text_hsl = [light_mode_primary_hue, accent_saturation, 0.05];
    } else {

        theme.derived.light.primary = accent_color;

        light_mode_primary_hsl = [accent_hue, accent_saturation, accent_lightness];

        light_mode_primary_text_hsl = [accent_hue, accent_saturation, 0.95];
    }

    // now we need to generate hover, pressed, and active colors for the primary color + text
    // hover should be 8% lighter
    // pressed should be 16% lighter
    // active should be 15% darker

    const light_mode_primary_hover_hsl = [light_mode_primary_hsl[0], light_mode_primary_hsl[1], light_mode_primary_hsl[2] + 0.08];
    const light_mode_primary_pressed_hsl = [light_mode_primary_hsl[0], light_mode_primary_hsl[1], light_mode_primary_hsl[2] + 0.16];
    const light_mode_primary_active_hsl = [light_mode_primary_hsl[0], light_mode_primary_hsl[1], light_mode_primary_hsl[2] - 0.15];


    theme.derived.light.primary_hover = rgbToHex(...hslToRgb(...light_mode_primary_hover_hsl));
    theme.derived.light.primary_pressed = rgbToHex(...hslToRgb(...light_mode_primary_pressed_hsl));
    theme.derived.light.primary_active = rgbToHex(...hslToRgb(...light_mode_primary_active_hsl));

    // now we need to generate the primary text color
    theme.derived.light.primary_text = rgbToHex(...hslToRgb(...light_mode_primary_text_hsl));

    // now we need to generate the notification color
    let light_notification_color_hex;

    light_notification_color_hex = generateMatchingColor(theme.derived.light.background, notification_color);
    theme.derived.light.notification = light_notification_color_hex;
    theme.derived.light.notification_text = invertColor(light_notification_color_hex, true);


    const dark_brightness = 5;
    // DARK MODE
    // brightness is not relevant, we get the background color with lightness of 0.10
    theme.derived.dark.background = deriveThemeBackgroundColor(accent_color, "dark", dark_brightness);


    let dark_mode_bg_hover_hsl, dark_mode_bg_pressed_hsl, dark_mode_bg_active_hsl, dark_mode_bg_divider_hsl,
        dark_mode_bg_text_hsl, dark_mode_bg_active_text_hsl;

    // now background divider, this should be 10% lighter than the background
    dark_mode_bg_divider_hsl = [accent_hue, accent_saturation, dark_mode_bg_base_luminance + 0.1];

    // now background hover, this should be 20% lighter than the background
    dark_mode_bg_hover_hsl = [accent_hue, accent_saturation, dark_mode_bg_base_luminance + 0.05];

    // now background pressed, this should be 30% lighter than the background
    dark_mode_bg_pressed_hsl = [accent_hue, accent_saturation, dark_mode_bg_base_luminance + 0.10];

    // now background active, this should be 40% lighter than the background
    dark_mode_bg_active_hsl = [accent_hue, accent_saturation, 0.85];

    // now background text, this should be nearly completely white
    dark_mode_bg_text_hsl = [accent_hue, accent_saturation, 0.85];

    // now background active text, this should be nearly completely white
    dark_mode_bg_active_text_hsl = [accent_hue, accent_saturation, 0.05];

    // add dynamic background color
    theme.derived.dark.dynamic_background = "radial-gradient(circle, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.05) 100%)";

    theme.derived.dark.background_divider = rgbToHex(...hslToRgb(...dark_mode_bg_divider_hsl));

    theme.derived.dark.background_hover = rgbToHex(...hslToRgb(...dark_mode_bg_hover_hsl));

    theme.derived.dark.background_pressed = rgbToHex(...hslToRgb(...dark_mode_bg_pressed_hsl));

    theme.derived.dark.background_active = rgbToHex(...hslToRgb(...dark_mode_bg_active_hsl));

    theme.derived.dark.background_text = rgbToHex(...hslToRgb(...dark_mode_bg_text_hsl));

    theme.derived.dark.background_active_text = rgbToHex(...hslToRgb(...dark_mode_bg_active_text_hsl));

    // now to the primary color, we want to use the accent color but depending on the mode adjust it so the contrast is good which we'll do by adjusting the luminance.

    // if dark mode, we need a primary color with a minimum luminance of 0.35

    const dark_mode_luminance_max = 0.35;

    const dark_mode_luminance_min = 0.1;

    let dark_mode_luminance_diff, dark_mode_primary_hue, dark_mode_primary_hue_relative_offset, dark_mode_primary_hsl,
        dark_mode_primary_text_hsl;

    // if accent_color lumination is less than 0.35, we need to increase it

    if (accent_lightness > dark_mode_luminance_max) {
        // how much do we need to increase it by?
        dark_mode_luminance_diff = accent_lightness - dark_mode_luminance_max;

        // now we need to increase the luminance of the accent color by the same amount
        // we also need to slightly adjust the hue to keep the color looking good
        dark_mode_primary_hue_relative_offset = dark_mode_luminance_diff * 0.05;

        if (accent_hue < 0.5) {
            dark_mode_primary_hue = accent_hue + dark_mode_primary_hue_relative_offset;
        } else {
            dark_mode_primary_hue = accent_hue - dark_mode_primary_hue_relative_offset;
        }

        dark_mode_primary_hsl = [dark_mode_primary_hue, accent_saturation, accent_lightness - dark_mode_luminance_diff];
        theme.derived.dark.primary = rgbToHex(...hslToRgb(...dark_mode_primary_hsl));

        dark_mode_primary_text_hsl = [dark_mode_primary_hue, accent_saturation, 0.95];
    } else if (accent_lightness < dark_mode_luminance_min) {
        // we need to make sure it's not too dark...
        dark_mode_luminance_diff = dark_mode_luminance_min - accent_lightness;

        // let's set it back to lightness of 0.1
        dark_mode_primary_hue_relative_offset = dark_mode_luminance_diff * 0.05;

        if (accent_hue < 0.5) {
            dark_mode_primary_hue = accent_hue - dark_mode_primary_hue_relative_offset;
        } else {
            dark_mode_primary_hue = accent_hue + dark_mode_primary_hue_relative_offset;
        }

        dark_mode_primary_hsl = [dark_mode_primary_hue, accent_saturation, accent_lightness + dark_mode_luminance_diff];
        theme.derived.dark.primary = rgbToHex(...hslToRgb(...dark_mode_primary_hsl));

        dark_mode_primary_text_hsl = [dark_mode_primary_hue, accent_saturation, 0.05];
    } else {

        theme.derived.dark.primary = accent_color;

        dark_mode_primary_hsl = [accent_hue, accent_saturation, accent_lightness];

        dark_mode_primary_text_hsl = [accent_hue, accent_saturation, 0.95];
    }

// now we need to generate hover, pressed, and active colors for the primary color + text
// hover should be 8% lighter
// pressed should be 16% lighter
// active should be 15% darker

    const dark_mode_primary_hover_hsl = [dark_mode_primary_hsl[0], dark_mode_primary_hsl[1], dark_mode_primary_hsl[2] + 0.08];
    const dark_mode_primary_pressed_hsl = [dark_mode_primary_hsl[0], dark_mode_primary_hsl[1], dark_mode_primary_hsl[2] + 0.16];
    const dark_mode_primary_active_hsl = [dark_mode_primary_hsl[0], dark_mode_primary_hsl[1], dark_mode_primary_hsl[2] - 0.15];

    theme.derived.dark.primary_hover = rgbToHex(...hslToRgb(...dark_mode_primary_hover_hsl));
    theme.derived.dark.primary_pressed = rgbToHex(...hslToRgb(...dark_mode_primary_pressed_hsl));
    theme.derived.dark.primary_active = rgbToHex(...hslToRgb(...dark_mode_primary_active_hsl));

    // now we need to generate the primary text color
    theme.derived.dark.primary_text = rgbToHex(...hslToRgb(...dark_mode_primary_text_hsl));

    // now we need to generate the notification color
    let dark_notification_color_hex;

    dark_notification_color_hex = generateMatchingColor(theme.derived.dark.background, notification_color);
    theme.derived.dark.notification = dark_notification_color_hex;
    theme.derived.dark.notification_text = invertColor(dark_notification_color_hex, true);

    // now build the bg_ and bg_text_ 50 - 900s where background and background_text are 500
    buildThemeScales(theme, "light");
    buildThemeScales(theme, "dark");

    return theme;
}

function lightenDarkenColor(col, amt) {
    let usePound = false;

    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }

    let num = parseInt(col, 16);

    let r = (num >> 16) + amt;

    if (r > 255) r = 255; else if (r < 0) r = 0;

    let b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255; else if (b < 0) b = 0;

    let g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255; else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);

}

function buildThemeScales(theme, mode) {
    const bg_base_rgb = hexToRgb(theme.derived[mode].background);
    const bg_base_hsl = rgbToHsl(bg_base_rgb.r, bg_base_rgb.g, bg_base_rgb.b);

    let multiplier = mode === "dark" || theme?.brightness > 3 ? -1 : 1;

   // theme.derived[mode].background_80 = rgbToHex(...hslToRgb(bg_base_hsl[0], bg_base_hsl[1], bg_base_hsl[2] + (0.2*multiplier)));
    theme.derived[mode].background_80 = lightenDarkenColor(theme.derived[mode].background, 20*(mode==="dark"||theme?.brightness>3?-1:1));
    theme.derived[mode].background_60 = lightenDarkenColor(theme.derived[mode].background, 40*multiplier);
    theme.derived[mode].background_40 = lightenDarkenColor(theme.derived[mode].background, 60*multiplier);
    theme.derived[mode].background_20 = lightenDarkenColor(theme.derived[mode].background, 80*multiplier);


    // now text
    const bg_text_base_rgb = hexToRgb(theme.derived[mode].background_text);
    const bg_text_base_hsl = rgbToHsl(bg_text_base_rgb.r, bg_text_base_rgb.g, bg_text_base_rgb.b);

    //theme.derived[mode].background_text_80 = rgbToHex(...hslToRgb(bg_text_base_hsl[0], bg_text_base_hsl[1], bg_text_base_hsl[2] + (0.2*multiplier)));
    theme.derived[mode].background_text_80 = lightenDarkenColor(theme.derived[mode].background_text, 20*multiplier);
    theme.derived[mode].background_text_60 = lightenDarkenColor(theme.derived[mode].background_text, 40*multiplier);
    theme.derived[mode].background_text_40 = lightenDarkenColor(theme.derived[mode].background_text, 60*multiplier);
    theme.derived[mode].background_text_20 = lightenDarkenColor(theme.derived[mode].background_text, 80*multiplier);

    return theme;
}