import {getItemFromLocalStorage, saveItemToLocalStorage} from "../../m3/utilities/localstorage";
import {slugify} from "../../m3/utilities/slugify";
import {incrementCounts, verifySlugIsUnique} from "./api";

function prepareUpdate(a) {
    let keys = Object.keys(a);
    keys.forEach(key=>{
        if(key.startsWith("_")) {
            delete a[key];
        }
    })
    return a;
}

async function handleUpdate(key = "", data = {}) {
    return saveItemToLocalStorage(key, data);
}

const content_base = {
    spec: {
        name: {
            label: "Name",
            type: "string",
            required: true
        },
        tagline: {
            label: "Tagline",
            type: "string",
            required: true
        },
        slug: {
            label: "Slug",
            type: "slug",
            required: true
        },
    },

    create: {
        changeHook: (state, setState, field) => {
            if (field === 'slug' && !state.slug) {
                setState({
                    ...state,
                    _changed_slug: false
                })
            } else if (field === 'slug') {
                setState({
                    ...state,
                    _changed_slug: true
                })
            } else if (state.name && !state._changed_slug) {
                const sl = slugify(state.name);
                if (sl !== state.slug) {
                    setState({
                        ...state,
                        slug: sl
                    })
                }
            }
        }
    }
};

export const manage_data_model = {
    'pages-home': {
        page: {
            title: "Home",
            subtitle: "First page users see when logging in"
        },
        layout: "form",
        form: {
            loadData: async () => {
                const v = getItemFromLocalStorage('routes-home-content', {
                    tagline: "Introducing Unaty",
                    title: "The Toolkit",
                    button_text: "Start for free",
                });
                if (typeof v === 'string') {
                    return JSON.parse(v);
                } else {
                    return v;
                }
            },
            onSubmit: async (d, cb) => {
                handleUpdate('routes-home-content', d)
                    .then(() => {
                        if (cb) {
                            cb();
                        }
                    })
            },
            fields: ['title', 'tagline', 'button_text']
        },
        spec: {
            tagline: {
                label: "Tagline",
                type: "string"
            },
            title: {
                label: "Title",
                type: "string"
            },
            button_text: {
                label: "Button Text",
                type: "string"
            }
        }
    },
    'pages-explore': {
        page: {
            title: "Explore",
            subtitle: "Where people search for associations, groups, and events"
        },
        layout: "form",
        spec: {
            search_placeholder: {
                type: "string"
            },
            background: {
                type: "rich-background"
            },
            suggestions: {
                type: "array-suggested-actions"
            }
        }
    },
    'pages-create_account': {
        page: {
            title: "Create Account",
            subtitle: ""
        },
        layout: "form",
        spec: {
            title: {
                type: "string"
            },
            background: {
                type: "rich-background"
            }
        }
    },
    'pages-register': {
        page: {
            title: "Register Association",
            subtitle: ""
        },
        layout: "form",
        spec: {
            title: {
                type: "string"
            }
        }
    },
    'associations-profiles': {
        name: "Communities: Profiles",
        can_create: true,
        page: {
            title: "",
            subtitle: ""
        },
        spec: {
            name: {
                type: "string",
                required: true
            },
            slug: {
                type: "slug",
                required: true
            }
        }
    },
    'associations-categories': {
        name: "Associations: Categories",
        can_create: true,
        page: {
            title: "",
            subtitle: ""
        },
        spec: {
            ...content_base.spec,
        }
    },
    'associations-types': {
        name: "Associations: Types",
        can_create: true,
        page: {
            title: "",
            subtitle: ""
        },
        spec: {
            ...content_base.spec,
        }
    },
    'associations-tags': {
        name: "Associations: Tags",
        can_create: true,
        page: {
            title: "",
            subtitle: ""
        },
        spec: {
            ...content_base.spec,
        }
    },
    'associations-networks': {
        name: "Associations: Networks",
        can_create: true,
        page: {
            title: "",
            subtitle: ""
        },
        spec: {
            ...content_base.spec,
        }
    },
    'locations-tags': {
        name: "Locations: Tags",
        can_create: true,
        page: {
            title: "",
            subtitle: ""
        },
        spec: {
            ...content_base.spec,
        }
    },
    'locations-continents': {
        name: "Locations: Continent",
        can_create: true,
        page: {
            title: "",
            subtitle: ""
        },
        spec: {
            ...content_base.spec,
        }
    },
    'locations-states': {
        name: "Locations: State",
        can_create: true,
        page: {
            title: "",
            subtitle: ""
        },
        create: {
            description: "Add a state to the global community graph",
            init_data: {
                name: "",
                slug: ""
            },
            fields: [
                "name",
                "slug",
            ],
            canSubmit: (st) => {
                return st.name && st.slug;
            },
            changeHook: content_base.create.changeHook,
            handleCreate: (payload, cb) => {
                cb();
            }
        },
        spec: {
            ...content_base.spec,
        }
    },
    'locations-counties': {
        name: "Locations: County",
        can_create: true,
        page: {
            title: "",
            subtitle: ""
        },
        spec: {
            ...content_base.spec,
        }
    },
    'locations-cities': {
        name: "Locations: City",
        can_create: true,
        page: {
            title: "",
            subtitle: ""
        },
        spec: {
            ...content_base.spec,
        }
    },
    'locations-countries': {
        name: "Locations: Country",
        page: {
            title: "Countries",
            subtitle: "All the things..",
        },
        can_create: true,
        create: {
            options: {
                enable_add_another: true,
            },
            description: "Add a country to the global community graph",
            init_data: {
                name: "",
                slug: ""
            },
            fields: [
                "name",
                "slug",
                "tagline",
                "color",
                "parents",
                "images",
                "tags"
            ],
            canSubmit: (st) => {
                return st.name && st.slug;
            },
            changeHook: content_base.create.changeHook,
            handleCreate: (payload, cb, err) => {
                const key = `locations-countries.${payload.slug}`;

                verifySlugIsUnique(`${payload.slug}`,"country")
                    .then((resp)=>{

                        if(resp) {

                            handleUpdate(key,prepareUpdate(payload))
                                .then(()=>{
                                    incrementCounts('locations-countries')
                                    cb();
                                })
                        } else {
                            err("slug not unique");
                        }
                    })
            }
        },
        table: {
            columns: [
                "name",
                "tagline",
                "slug",
                "tags",
                "color",
                "parents"
            ],
            db_key: 'locations-countries'
        },
        spec: {
            ...content_base.spec,
            parents: {
                label: "Parents",
                type: "array-string",
                required: true,
                selector: "locations-continents"
            },
            tags: {
              label: "Tags",
              type: "tags"
            },
            color: {
                label: "Color",
                type: "color"
            },
            images: {
                label: "Images",
                type: "array-images",
                image_spec: {
                    min_height: 600,
                    min_width: 600,
                    aspect_ration: 'Any'
                },
                required: false
            }
        }
    },
};