import React from "react";
import M3_A_ActionList from "./action-list";

/*
* This is the NavList atom, it depends on the following atoms:
* - ActionList
 */

const _props = {};

export default function M3_A_NavList({
                                         items = [],
                                         size,
                                         collapsible,
                                         styles
                                     }) {

    return (
        <M3_A_ActionList collapsible={collapsible} styles={styles} size={size} items={items}/>
    );
}