import React, {useContext, useState} from 'react';
import {PollView} from "./view";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import styled from "styled-components";
import {api_createPoll} from "./api";
import dayjs from "dayjs";
import Button from "../button";
import {FieldInput, FieldLabel} from "../form/field";
import CustomCheckbox from "../../../routes/community/position/past-assignees/date-range/checkbox";
import EntityTabs from "../entity-tabs";
import {XMarkIcon} from "@heroicons/react/20/solid";
import {IconAction, TextAction} from "../../../routes/auth/sign-in";

import {useCommunity} from "../../../config/community";
import {useDraggableInPortal} from "../directory/customize-layout";
import {ModalBody, ModalFooter, ModalTabsWrapper} from "../preview-overlay";
import IconSelectMenu from "../icon-select-menu";
import {useToasts} from "../../../config/toasts";
import {
    DateFormat,
    DateTimeTimezoneSelector, getNearest15Minute
} from "../../../routes/community/calendar/components/datetime-selector";
import {m3_icon_map} from "../../icons/icon-map";
import {utils_objects_moveArrayItem} from "../../../../common/utilities/objects";

const methods = [
    {
        id: "single-choice",
        label: "Single Choice"
    },
    {
        id: "ranked-choice-all",
        label: "Ranked Choice"
    },
    {
        id: "rating-number",
        label: "Rating: Number"
    },
    {
        id: "rating-reaction",
        label: "Rating: Reaction"
    },
    {
        id: "event-datetime",
        label: "Event: Date & Time"
    }
];

const show_results_options = [
    {
        id: "immediately",
        label: "Immediately"
    },
    {
        id: "poll-close",
        label: "At Poll Close"
    }
]

/*

need to find x, we're writing an interpolation function between 0 and steps to get time

x is a function of y (step)

first 3 hours up
step[0,5] = (y*30) + 30 // 180

next go in increments of an hour up to 24 hours
step[5,21] = (y * 60) + 180 // 1440

step[21,Z] = (y * 60 * 24) + 1440

where Z = number when we reach max time

0 = 30 minutes (0 = min_time)
1 = 30 + (step * 30) = 30
2 = 30 + (2 * 30) = 30
20 =
80 =
steps = max_time

 */

let nsteps = [
    {
        // 30 minutes
        range: [0, 4],
        fn: (s) => (s * 30) + 30
    },
    {
        // 1 hour
        range: [5, 9],
        fn: (s) => ((s - 5) * 60) + 180
    },
    {
        // plus 6 hours
        range: [10, 14],
        fn: (s) => ((s - 10) * 60 * 6) + 720
    },
    {
        // 1 day
        range: [15, -1],
        fn: (s) => ((s - 15) * 60 * 24) + 1440
    }
];

function getValue(index) {
    const step = nsteps.filter(b => index >= b.range[0] && (b.range[1] === -1 || index <= b.range[1]))[0];

    if (step) {
        return step.fn(index);
    }

    return nsteps[nsteps.length - 1].fn(index);
}

function buildSteps(min_time, max_time) {
    let steps = {};
    let count = 0;
    let index = 0;

    while (count < max_time) {
        const v = getValue(index);
        steps[index] = v;
        count = steps[index];
        index++;
    }

    return steps;
}

const Slider = styled.input`
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 4px; /* Specified height */
  background: #e7e7e7; /* Grey background */
  outline: none; /* Remove outline */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;


  ::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    border-radius: 50%;
    width: 12px; /* Set a specific slider handle width */
    height: 12px; /* Slider handle height */
    background: #1164A3; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }

  ::-moz-range-thumb {
    border-radius: 50%;
    width: 12px; /* Set a specific slider handle width */
    height: 12px; /* Slider handle height */
    background: #1164A3; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }
`;

function PollCloseSelector({poll_duration, poll_steps, min_time, max_time, onChange}) {

    const entries = Object.entries(poll_steps);
    const max = entries.length - 1;

    return <div className="w-full relative max-w-sm">
        <div className="z-10 relative">
            <Slider onChange={e => {
                onChange(e.target.value)
            }} type="range" min={0} max={max} value={poll_duration} className="" id="poll-time"/>
        </div>
        <div className="absolute top-3 left-px right-px">
            <div className="relative w-full">
                {entries.map((entry, index) => {
                    if (index === 0 || index === max) {
                        return <div style={{left: `${(index / max) * 100}%`}} key={entry[0]}
                                    className="absolute top-0 w-px h-2.5 bg-gray-200"/>
                    }

                    return <div key={entry[0]} style={{left: `${(index / max) * 100}%`}}
                                className="absolute top-0 w-px h-2 bg-gray-200"/>
                })}
            </div>

        </div>
        <div className="pt-2 text-sm text-gray-600">
            Poll closes {dayjs().add(poll_steps[poll_duration], 'minutes').fromNow()}
        </div>
    </div>
}


// text utils: YYYY-MM-DD || HH:mm || HH:mm
function getEventDateTimeValue(text) {
    if(!text) {
        return {
            date: "", // YYYY-MM-DD
            start_time: "", // YYYY-MM-DD HH:mm:ss
            end_time: "", // YYYY-MM-DD HH:mm:ss
            timezone: "America/Los_Angeles" // UTC
        }
    }
    const parts = text.split(' || ');

    return {
        date: parts[0],
        start_time: parts[1],
        end_time: parts[2],
        timezone: parts[3]
    }
}

function ChoiceEdit({handleChange, voting_method, text, index}) {

    if(voting_method === 'event-datetime') {
        const datetime_value = getEventDateTimeValue(text);
        const init_start_date = datetime_value.date ? dayjs(datetime_value.date).format('YYYY-MM-DD') : dayjs().add(index,'days').format(DateFormat);
        const init_start_time = datetime_value.start_time ? datetime_value.start_time : getNearest15Minute(index*(24*60));
        const init_end_time = datetime_value.end_time ? datetime_value.end_time : getNearest15Minute(60+(index*(24*60)));
        const timezone = datetime_value.timezone ? datetime_value.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone;

       return <div className="flex">
            <DateTimeTimezoneSelector
                init_start_date={init_start_date}
                init_start_time={init_start_time}
                init_end_time={init_end_time}
                timezone={timezone}
                init_duration={60}
                hide_allday_timezone hide_label
                                      onUpdate={(pl)=>{
                                          const str = `${pl.start_date} || ${pl.start_time} || ${pl.end_time} || ${pl.timezone}`;
                                          handleChange(str);
                                      }}/>
        </div>
    }

    return <div>
        <FieldInput placeholder={`Choice ${index + 1}`} custom={{max: 180}} onChange={(a, v) => handleChange(v)}
                    value={text}/>
    </div>
}

function uniqueId() {
    return Math.random().toString(36).substr(2, 9);
}

function getInits(count = 2) {
    let choices_data = {};
    let choices = [];
    for (let i = 0; i < count; i++) {
        const id = uniqueId();
        choices_data[id] = {
            text: ""
        };
        choices.push(id);
    }
    return {
        choices,
        choices_data
    }
}

function pollIsValid(title, choices, choices_data, voting_method) {
    if (!title) {
        return false;
    }

    if(voting_method === 'rating-number' || voting_method === 'rating-reaction') {
        return true;
    }

    if(voting_method === 'event-datetime') {
        // must have at least 2 choices
        if(choices.length < 2) {
            return false;
        }

        return true;
    }

    const invalid_choices = choices.filter(chid => !choices_data[chid].text);

    if (invalid_choices.length > 0) {
        return false;
    }

    return true;
}

function PollChoices({
                         can_add_choice,
                         voting_method,
                         handleDragEnd,
                         addChoice,
                         choices,
                         choices_data,
                         renderDraggable,
                         handleChange,
                         removeChoice
                     }) {

    if (voting_method === 'rating-number') {
        return <div className="pt-2 pb-1">
            <div className="rounded-md bg-gray-200 px-2 py-1 inline-block text-gray-600 text-xs font-semibold">
                0-10 Scale
            </div>
        </div>
    }

    if (voting_method === 'rating-reaction') {
        return <div className="pt-2 pb-1">
            <div className="rounded-md bg-gray-200 px-2 py-1 inline-block text-gray-600 text-xs font-semibold">
                Bad, Ok, Awesome
            </div>
        </div>
    }

    return <div>
        <FieldLabel label="Choices" corner_hint={can_add_choice &&
            <TextAction text="Add Choice" inverse onClick={() => addChoice()}/>}/>
        <div className="space-y-1">
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId={`dnd-author-poll`}>
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {choices.map((chid, index) => {
                                const chdata = choices_data[chid];
                                return <Draggable isDragDisabled={false} key={`${chid}`} draggableId={chid}
                                                  index={index}>
                                    {renderDraggable((provided) => {
                                        return <div ref={provided.innerRef} className="relative mb-2 items-center flex space-x-2"
                                                    key={chid} {...provided.draggableProps}>
                                            <div
                                                className='w-5 flex-none bg-white text-gray-500 hover:text-gray-800 h-5' {...provided.dragHandleProps}
                                                tabIndex={"-1"}>
                                                {m3_icon_map.custom['grab-handle']}
                                            </div>
                                            <div className="flex-grow">
                                                <ChoiceEdit key={chid} index={index} voting_method={voting_method}
                                                            handleChange={v => handleChange(chid, v)} {...chdata}  />
                                            </div>
                                            {choices.length > 2 && <div className="flex-none w-5">
                                                <IconAction
                                                    onClick={() => removeChoice(chid)}>
                                                    <XMarkIcon/>
                                                </IconAction>
                                            </div>}
                                        </div>
                                    })}
                                </Draggable>
                            })}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    </div>
}

export function AuthorPoll({
                               onNewPoll = () => {
                               },
    init_method = ""
                           }) {
    const inits = getInits();
    const toasts = useToasts();

    const [tab, setTab] = useState("Configure");

    const min_time = 30; // 30 minutes
    const max_time = 60 * 24 * 30; // one month

    const community = useCommunity();

    const poll_steps = buildSteps(min_time, max_time);
    const [title, setTitle] = useState("");
    const [show_results, setShowResults] = useState(show_results_options[0].id);
    const [voting_method, setMethod] = useState(init_method);
    const [choices, setChoices] = useState(inits.choices);
    const [can_comment, setCanComment] = useState(false);
    const [poll_duration, setPollDuration] = useState(21);
    const [choices_data, setChoicesData] = useState(inits.choices_data);

    function removeChoice(id) {
        const index = choices.findIndex(a => a.id === id);
        let ncd = {...choices_data};
        delete ncd[id];
        let nc = [...choices];
        nc.splice(index, 1);
        setChoices(nc);
        setChoicesData(ncd);
    }

    function addChoice() {
        const id = uniqueId();
        let ncd = {...choices_data};
        ncd[id] = {
            text: ""
        };
        let nc = [...choices];
        nc.push(id);
        setChoicesData(ncd)
        setChoices(nc);
    }

    function handleChange(chid, v) {
        let ncd = {...choices_data};
        ncd[chid].text = v;
        setChoicesData(ncd);
    }

    function handleDragEnd({source, destination}) {
        if (!destination) return;
        let nc = utils_objects_moveArrayItem([...choices], source.index, destination.index);

        setChoices(nc)
    }

    function createPoll() {
        const payload = {
            voting_method,
            choices,
            vote_visibility: 'counts',
            who_can_vote: {
                all_members: true,
            },
            title,
            closes: dayjs().add(poll_steps[poll_duration], 'minutes').toDate(),
            choices_data,
            can_change_vote: false,
            opens: null,
            show_results: voting_method === 'ranked-choice-all' ? "at-poll-close" : show_results
        };
        api_createPoll(community, payload)
            .then((resp) => {
                if(!resp.data) {
                    toasts.addToast({
                        text: "Error creating poll",
                        intent: "danger"
                    })
                    return;
                }
                const pid = resp.data;
                onNewPoll(pid);
            })
    }

    const can_add_choice = choices.length < 6;

    const renderDraggable = useDraggableInPortal();

    const poll_is_valid = pollIsValid(title, choices, choices_data, voting_method);
console.log(poll_is_valid, title, choices, choices_data, voting_method)
    const preview = {
        valid: poll_is_valid,
        title,
        choices,
        can_comment,
        voting_method: voting_method,
        choices_data,
        closes: dayjs().add(poll_steps[poll_duration], 'minutes').toDate()
    };

    const poll_choices_props = {
        can_add_choice,
        handleDragEnd,
        addChoice,
        choices,
        choices_data,
        renderDraggable,
        handleChange,
        removeChoice,
        voting_method
    };

    if (!voting_method) {
        return <div className="py-8">
            <IconSelectMenu title="What kind of poll?" items={[
                {
                    label: "Single Choice",
                    type: 'check-circle',
                    onClick: () => setMethod('single-choice')
                },
                {
                    label: "Ranked Choice",
                    type: 'list',
                    onClick: () => setMethod('ranked-choice-all')
                },
                {
                    label: "Event Date/Time",
                    type: 'calendar',
                    onClick: () => setMethod('event-datetime')
                },
                {
                    label: "Rating",
                    type: 'star',
                    onClick: () => setMethod('rating-number')
                }
            ]}/>
        </div>
    }

    return <>
        <ModalTabsWrapper>
            <EntityTabs tabs={[
                {
                    label: "Configure"
                },
                {
                    label: "Preview"
                }
            ]} active={tab} onChangeTab={t => {
                setTab(t)
            }}/>
        </ModalTabsWrapper>
        <ModalBody>
            <div className="flex pt-4 flex-col gap-4">
                {tab === "Configure" && <>
                    <div>
                        <FieldLabel label="Title"/>
                        <FieldInput placeholder="Untitled Poll" value={title} onChange={(a, t) => setTitle(t)}/>
                    </div>

                    <div className="grid sm:grid-cols-2">
                        {voting_method === 'single-choice' && <div><FieldLabel label="Show Results">
                            <EntityTabs
                                onChangeTab={t => setShowResults(show_results_options.filter(a => a.label === t)[0].id)}
                                layout="pills-color"
                                tabs={show_results_options.map(a => a.label).map(b => {
                                    return {
                                        label: b,
                                        id: b
                                    }
                                })} active={show_results_options.filter(a => a.id === show_results)[0].label}/>
                        </FieldLabel></div>}
                        <div>
                            <FieldLabel label="When should this poll close?"/>
                            <PollCloseSelector max_time={max_time} min_time={min_time} poll_steps={poll_steps}
                                               poll_duration={poll_duration} onChange={n => setPollDuration(n)}/>
                        </div>
                    </div>
                    <div className="hidden sm:grid-cols-2">
                        <div className="hidden">
                            <FieldLabel label="Commenting"/>
                            <CustomCheckbox toggleCheck={() => {
                                setCanComment(!can_comment)
                            }} top_margin={false} checked={can_comment} content={"Allow users to leave a comment"}/>
                        </div>
                        <div className="hidden">
                            <FieldLabel label="Method"/>
                            <EntityTabs onChangeTab={t => setMethod(methods.filter(a => a.label === t)[0].id)}
                                        layout="pills-color"
                                        tabs={methods.map(a => a.label).map(b => {
                                            return {
                                                label: b,
                                                id: b
                                            }
                                        })} active={methods.filter(a => a.id === voting_method)[0].label}/>
                        </div>
                    </div>
                    <div className={"hidden"}>
                        poll is valid: {JSON.stringify({poll_is_valid})}
                    </div>
                    <PollChoices {...poll_choices_props} />
                </>}
                {tab === "Preview" && <>
                    <PollView preview={preview}/>
                </>}
            </div>
        </ModalBody>
        <ModalFooter left={null} right={<>
            <Button intent="primary" text="Create Poll" onClick={() => createPoll()} disabled={!poll_is_valid}/>
        </>}/>
    </>
}