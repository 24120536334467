import {collection, doc, getDoc, getDocs, limit, query, where} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {authFetch} from "../../../../api/network";
import {logEngagementEvent} from "../../../config/firebase-setup";

export async function followEntity(community,id,type) {
    const p2 = {
        community_uid: community.uid,
        member_id: community.member_id,
        follow_target_type: type,
        follow_target_id: id
    };
    return new Promise(resolve => {
        const r = () => {
       resolve();
        };
        if(type==='post') {
            logEngagementEvent("post_get_updates", {}, community.uid);
            authFetch('/posts/get-updates', r, r, "POST", {payload: p2});
        } else {
            logEngagementEvent("member_follow", {}, community.uid);
            authFetch('/members/follow-entity', r, r, "POST", {payload: p2});
        }
    })
}

export async function unfollowEntity(community,id,type) {
    const p2 = {
        community_uid: community.uid,
        member_id: community.member_id,
        follow_target_type: type,
        follow_target_id: id
    };

    return new Promise(resolve => {
        const r = () => {
            resolve();
        };
        if(type==="post") {
            logEngagementEvent("post_stop_updates", {}, community.uid);
            authFetch('/posts/stop-updates', r, r, "POST", {payload: p2});
        } else {
            logEngagementEvent("member_unfollow", {}, community.uid);
            authFetch('/members/unfollow-entity', r, r, "POST", {payload: p2});
        }
    })
}

export async function isFollowingEntity(community,id,type) {

    return community.member_following&&community.member_following[`${type}s`]&&community.member_following[`${type}s`][id];
    /*
    const ref = doc(db, 'community_members', community.uid, 'member_following', id);
    return await getDoc(ref)
        .then(doc => {
            if(!doc.exists) {
                return false;
            }
            console.log("IS FOLLOWING",id,type,doc.data())

            return doc.data()[type]&&doc.data()[type][id]
        });

     */
}

export async function getMemberGroups(community_id,member_id) {
    const ref = collection(db, 'community_entities', community_id, 'groups');
    const q = query(ref, where(`user_uids.${member_id}`, "==", true), limit(50))
    return await getDocs(q)
        .then(snap => {
            return !snap.empty ? snap.docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            }) : [];
        });
}

export async function getMemberGroupsOrganizing(community_id,member_id) {
    const ref = collection(db, 'community_entities', community_id, 'groups');
    const q = query(ref, where(`moderators.user_uids.${member_id}`, "==", true), limit(50))
    return await getDocs(q)
        .then(snap => {
            return !snap.empty ? snap.docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            }) : [];
        });
}

export async function getEntityFollowData(community,id,type) {
    const ref = doc(db, 'community_members', community.uid, 'member_following', id);
    return await getDoc(ref)
        .then(doc => {
            if(!doc.exists) {
                return false;
            }

            const dt = doc.data();
            return dt;
        });
}

export async function getCurrentMemberFollowing(community_uid,member_id) {
    const ref = doc(db, 'community_members', community_uid, 'member_following', member_id);
    return await getDoc(ref)
        .then(doc => {
            if(!doc.exists) {
                return {
                    member_count: 0,
                    members: {}
                };
            }

            return doc.data();
        });
}