import React, {Component, createContext, useContext, useState} from 'react';
import ReactDOM from "react-dom";
import {AnimatePresence,motion} from "framer-motion";

function SimpleID() {
    return "id" + Math.random().toString(16).slice(2);
}

function getClassName(intent) {
    switch (intent) {
        case ('success') : {
            return "bg-green-600 text-white";
        }
        case ('danger') : {
            return "bg-red-500 text-white";
        }
        case ('primary') : {
            return "bg-blue-500 text-white";
        }
        case ('warning') : {
            return "bg-yellow-400 text-gray-900";
        }
        default : {
            return "bg-gray-700 text-white";
        }
    }
}

class ToastItem extends Component {

    componentDidMount() {
        setTimeout(() => {
            this.props.onDismiss();
        }, 3000);
    }

    render() {
        const {text, intent = '', onDismiss} = this.props;
        return (
            <div className={`mt-4 px-3.5 py-2.5 rounded-md shadow-sm w-80 z-9999 flex ${getClassName(intent)}`}>
                <div className="flex-grow text-sm font-semibold truncate">
                    {text}
                </div>
                <div>

                </div>
            </div>
        );
    }

    shouldComponentUpdate() {
        return false;
    }
}

function Toasts({toasts, actions}) {

    const content = <div className={`toasts fixed fixed-important z-99999`}>
        <div>
            <AnimatePresence initial={false}>
                {toasts.map(toast => {
                    const {id} = toast;
                    return (
                        <motion.div
                            key={id}
                            positiontransition="true"
                            initial={{opacity: 0, y: 50, scale: 0.3}}
                            animate={{opacity: 1, y: 0, scale: 1}}
                            exit={{opacity: 0, scale: 0.5, transition: {duration: 0.2}}}
                        >
                            <ToastItem {...toast} onDismiss={() => actions.removeToast(id)}/>
                        </motion.div>
                    );
                })}
            </AnimatePresence>
        </div>
    </div>

    return ReactDOM.createPortal(
        content,
        document.body
    );
}

const default_value = {
    toasts: {},
    addToast: () => {
    }
};

const ToastsContext = createContext(default_value);

const defaultOptions = {
    color: "#6796e6"
};

const useToasts = () => {
    const toasts = useContext(ToastsContext);

    return toasts;
};


function createToast(options) {
    const id = SimpleID();

    return {
        ...defaultOptions,
        ...options,
        id
    }
}

function ToastsContextProvider({children}) {
    const [toasts, setToasts] = useState({});

    function removeToast(id) {
        let new_toasts = toasts;
        delete new_toasts[id];
        setToasts({...new_toasts});
    }

    function addToast(opt) {
        let nt = createToast(opt);
        setToasts({...toasts, [nt.id]: nt});
    }

    const value = React.useMemo(() => {
        return {
            addToast: addToast.bind(this)
        };
    }, [toasts]);

    return <ToastsContext.Provider
        value={value}>
        {children}
        <Toasts actions={{
            removeToast: removeToast.bind(this)
        }} toasts={Object.values(toasts)}/>
    </ToastsContext.Provider>
}

export {ToastsContextProvider, useToasts}