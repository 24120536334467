import React from 'react';
import M3_A_Text from "./text";

/*
* This is the FormControl atom
 */

export default function M3_A_FormControl({label, group, required, caption, children}) {

    return <div className={`flex flex-col gap-1 ${group?"group":""}`}>
        <M3_A_Text color="text-gray-600" size="xs" weight="font-medium">{label}{required&&"*"}</M3_A_Text>
        {children && <div>{children}</div>}
        {caption && <M3_A_Text size="xs" className="text-gray-600">{caption}</M3_A_Text>}
    </div>
}