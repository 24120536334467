import React from 'react';
import {ContentPopupAvatarHeader, ContentPopupDivider, ContentPopupMenu} from "../content-popup/components";
import SimpleTooltip from "../tooltip";
import {useNavigate} from "react-router-dom";
import {useUnaverse} from "../../../config/unaverse";
import {buildImageUrl} from "../../../../common/utilities/images";

function buildSpaceMenu(community, unaverse, navigate) {
    let items0 = [];
    let items = [];
    let items1 = [];

    if (community) {
        if (community.member_access.manage_members) {
            items0.push({
                type: "",
                right_label: "",
                onClick: () => navigate(`/c/${community.domain}/spaces?action=create`),
                label: `Create a space`,
            });
        }

        // if can manage members
        if (community.member_access.manage_members) {
            items0.push({
                type: "",
                right_label: "",
                onClick: () => navigate(`/c/${community.domain}/contacts?action=create`),
                label: `Invite people to ${community.name}`,
            });
        }
    }

    if (community) {
        items = [
            {
                type: "",
                right_label: "",
                onClick: () => community.toggleSettings(),
                label: `Account Settings`,
            }
        ];

        if (community.is_admin) {
            items.push({
                onClick: () => community.goToAdminApp(`customize`),
                label: `Community Settings`,
            })

            items.push({
                onClick: () => community.openEditOverlay({
                    type: 'onboarding-designer',
                    data: {},
                    simple: true,
                    closeCb: () => {
                        community.closeEditModal()
                    }
                }),
                label: `Onboarding Designer`,
            })

            items.push({
                onClick: () => community.openEditOverlay({
                    type: 'profile-designer',
                    data: {},
                    simple: true,
                    closeCb: () => {
                        community.closeEditModal()
                    }
                }),
                label: `Profile Designer`,
            })
        }

    } else {
        items = [
            {
                type: "",
                right_label: "",
                onClick: () => unaverse.toggleSettings(),
                label: `Preferences`,
            }
        ];
    }

    items1 = [
        {
            type: "",
            right_label: "",
            onClick: () => community.openPreviewOverlay({
                type: "help"
            }),
            label: "Help & Get Started",
        },
        {
            type: "",
            right_label: "",
            onClick: () => navigate(`/`),
            label: "Switch Community",
        }
    ];

    items1.push({
        type: "",
        right_label: "",
        label: "Sign out",
        onClick: () => navigate(`/c/${community.domain}/sign-out`)
    })

    let items2 = [];

    if (unaverse.is_superadmin) {
        items2.push({
            type: "",
            right_label: "",
            label: "Community Tools",
            onClick: () => navigate(`/c/${community.domain}/tools`)
        })

        items2.push({
            type: "",
            right_label: "",
            label: "Orange Tools",
            onClick: () => navigate(`/manage`)
        })
    }

    return [
        items0,
        items,
        items1,
        items2
    ].filter(function (arr) {
        return arr.length > 0;
    });
}

export function SpaceMenuPopup({children, community}) {
    const navigate = useNavigate();
    const unaverse = useUnaverse();

    const final_arr = buildSpaceMenu(community, unaverse, navigate);

    const contents = <div className="w-72 bg-white content-popup-shadow max-w-xs rounded-lg border border-gray-200">
        {community &&
            <ContentPopupAvatarHeader image={buildImageUrl(community.profile.profile_picture)} hideOnClick={true}
                                      type="user" desc={`/c/${community.domain}`} name={community?.profile.name}/>}
        <ContentPopupDivider/>
        {final_arr.map(function (arr, i) {
            const is_last = i === final_arr.length - 1;
            return <React.Fragment key={i}>
                <ContentPopupMenu items={arr}/>
                {!is_last && <ContentPopupDivider/>}
            </React.Fragment>
        })}
    </div>;

    return <SimpleTooltip hideOnClick simple trigger='click' interactive text={contents} placement='bottom-start'>
        {children}
    </SimpleTooltip>;
}