import React, {useEffect, useState} from "react";
import {externalAuthFetch} from "../../../../api/network";
import {useNavigate} from "react-router-dom";
import {setFavicon} from "../../../m3/utilities/set-document-title";
import M3_A_BlankSlate from "../../../m3/atoms/blank-slate";
import {InlineLoader} from "../../../m3/_legacy_components/admin-activity";
import {PolicyGateWrapper} from "../../../features/gates/policy";

async function api_getPolicyData(policy_slug, community_uid, member_id, token) {

    return new Promise(resolve => {

        const res = (resp) => {
            if (!resp || !resp.data) {
                return resolve({
                    ok: "no",
                    data: {}
                });
            }
            return resolve(resp.data);
        };

        // with this, you could see any communities policy given the slug...
        externalAuthFetch("/get-data", res, res, "POST", {
            payload: {
                content_type: "policy",
                content_id: policy_slug,
                community_uid: community_uid,
                token,
                member_id
            }
        })
    })
}

async function api_override_acceptPolicy(slug, version, community_uid, member_id, token) {
    return new Promise(resolve => {

        const res = (resp) => {
            if (!resp || !resp.data) {
                return resolve({
                    ok: "no",
                    data: {}
                });
            }
            return resolve(resp.data);
        };

        // with this, you could see any communities policy given the slug...
        externalAuthFetch("/handle-update", res, res, "POST", {
            payload: {
                action: "accept-policy",
                data: {
                    slug, version
                },
                community_uid: community_uid,
                token,
                member_id
            }
        })
    })
}

export function ExternalPolicyWrapper(props) {
    const {policy_slug, member_id, token, member, community} = props;
    const [data, setData] = useState(null);
    const [status, setStatus] = useState("");
    const navigate = useNavigate();

    useEffect(function () {
        // get policy data
        api_getPolicyData(policy_slug, community.uid, member_id, token)
            .then((resp) => {
                // resp.data.policy_data
                if (resp && resp.ok === "yes" && resp.data.policy_data) {
                    setData(resp.data.policy_data);

                    setFavicon((community.profile_picture ? `${community.profile_picture}_small?alt=media` : ''))
                    document.title = `Review ${resp.data.policy_data.name} | ${community?.name || "Community"}`;
                }
            })
    }, [policy_slug, status, community.uid, member_id, token])

    function handleUpdate() {
        setStatus("updated");
    }

    if (status === "updated") {
        return <M3_A_BlankSlate
            title={"Policy Accepted"}
            description={"You're all set, feel free to close this tab or sign in to access the Community."}
            button={{
                children: `Go to Sign In`,
                intent: "primary",
                onClick: () => {
                    navigate(`/c/${community.handle}/sign-in`);
                }
            }}
        />
    }

    if (!data) {
        return <div>
            <InlineLoader />
        </div>
    }

    // first check if policy is active
    if (data.status !== "published") {
        return <M3_A_BlankSlate
            title={"This Policy isn't published"}
            description={"For some reason, this policy isn't published yet. Please try again later."}
            button={{
                children: `Go to Sign In`,
                intent: "primary",
                onClick: () => {
                    navigate(`/c/${community.handle}/sign-in`);
                }
            }}
        />
    }

    const policy_id = `${policy_slug}__${data.version}`;

    // then check if member has already accepted policy
    if (member.policies && member.policies[policy_id]) {
        return <M3_A_BlankSlate
            title={"You've already accepted this policy"}
            description={"You're all set, feel free to close this tab or sign in to access the Community."}
            button={{
                children: `Go to Sign In`,
                intent: "primary",
                onClick: () => {
                    navigate(`/c/${community.handle}/sign-in`);
                }
            }}
        />
    }

    return <div>
        <M3_A_BlankSlate
            title={`Review ${data.name}`}
            description={"Please review the policy below before accepting."}
        />
        <PolicyGateWrapper handleAcceptPolicy={async (_slug, _version) => {
            // api_override_acceptPolicy
            return await api_override_acceptPolicy(_slug, _version, community.uid, member_id, token);
        }} policy_data={data} selected_policy={policy_slug} community_uid={community.uid} member_id={member_id}
                           community_data={community} updateParent={handleUpdate} policies_to_accept={[
            {
                type: "policy",
                slug: policy_slug,
                version: data.version,
                gate_data: {}
            }
        ]}/>
    </div>
}