import React, {useEffect, useRef, useState} from "react";
import {Avatar} from "../avatar";
import {Toggle} from "../toggle";
import {PlusIcon, XMarkIcon} from "@heroicons/react/20/solid";
import {LocalSearchBar} from "../local-search-bar";
function Item({title, children}) {

    return <div className="grid gap-2 py-1 px-1.5 rounded hover:bg-gray-100 " style={{gridTemplateColumns: '1fr auto'}}>
        <div className="max-w-xs truncate flex items-center space-x-2">
            <Avatar size="w-6 h-6" text={title}/>
            <span className="text-sm text-gray-700 font-medium">
                {title}
                </span>
        </div>
        <div className="pr-0.5">
            {children}
        </div>
    </div>;
}

function CommunityAccessItem({selected, text="Community Access", setSelected}) {

    return <Item title={text}>
        <Toggle checked={selected} size="xs" onToggle={(v) => setSelected(v)} layout="switch"/>
    </Item>;
}

function AllRolesAccessItems({selected, setSelected}) {

    return <Item title={`All Role Assignees`}>
        <Toggle checked={selected} size="xs" onToggle={(v) => setSelected(v)} layout="switch"/>
    </Item>;
}

function ItemIcon({onClick, icon, color = "text-gray-600"}) {
    return <div onClick={onClick}
                className="w-6 h-6 flex items-center justify-center rounded-md cursor-pointer opacity-70 hover:opacity-100">
        <div className={`w-4 h-4 ${color} svg-child-inherit`}>
            {icon}
        </div>
    </div>
}

function Entity({
                    label, id, type, action, onClick = () => {
    }
                }) {

    return <Item title={label}>
        {action === 'add' && <ItemIcon onClick={onClick} icon={<PlusIcon/>} color="text-secondary"/>}
        {action === 'remove' && <ItemIcon onClick={onClick} icon={<XMarkIcon/>}/>}
    </Item>
}

const mock_access = {
    community: false,
    all_roles: false,
    admins: true,
    teams: {},
    members: {},
    roles: {},
    member_types: {}
};

function getSortedEntities(tk, mtk, rk, reference, types=[]) {
    let all = [];
    if(types.includes('roles')) {
        rk.forEach(k => {
            all.push({
                id: k,
                type: 'roles',
                label: reference.roles[k].name
            });
        });
    }
    if(types.includes('member_types')) {
        mtk.forEach(k => {
            all.push({
                id: k,
                type: 'member_types',
                label: reference.member_types[k].plural
            });
        });
    }
    if(types.includes('teams')) {
        tk.forEach(k => {
            all.push({
                id: k,
                type: 'teams',
                label: reference.teams[k].name
            });
        })
    }

    return all.sort((a, b) => (a.label < b.label) ? 1 : -1);
}

function getResults(query, suggestions, types) {
    if (!query) {
        return [];
    }
    return suggestions.filter(a => {
        return a.label.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
}

export function CommunityAccessManager({
                                           show_search = true,
                                           types = ['members','community'],
                                           updateAccess = () => {},
                                           show_added = true,
    show = ['roles','member_types','teams'],
                                           init_access = {...mock_access},
                                           suggestions = [],
                                           reference = {}
                                       }) {
    const [access, setAccess] = useState({...init_access});
    const [query, setQuery] = useState("");
    if(!reference.teams) {
        reference.teams = {};
    }
    if(!reference.member_types) {
        reference.member_types = {};
    }
    if(!reference.roles) {
        reference.roles = {};
    }
    if(!access.teams) {
        access.teams = {};
    }
    if(!access.roles) {
        access.roles = {};
    }
    if(!access.members) {
        access.members = {};
    }
    if(!access.member_types) {
        access.member_types = {};
    }
    const tk = Object.keys(access.teams);
    const atk = Object.entries(reference.teams).filter(a => !access.teams[a[0]]).map(ent => {
        return {
            id: ent[0],
            type: 'teams',
            label: ent[1].name
        }
    }).sort((a,b)=>a.label<b.label?-1:1);
    const mtk = Object.keys(access.member_types);
    const amtk = Object.entries(reference.member_types).filter(a => !access.member_types[a[0]]).map(ent => {
        return {
            id: ent[0],
            type: 'member_types',
            label: ent[1].plural
        }
    }).sort((a,b)=>a.label<b.label?-1:1);
    const rk = Object.keys(access.roles);
    const ark = Object.entries(reference.roles).filter(a => !access.roles[a[0]]).map(ent => {
        return {
            id: ent[0],
            type: 'roles',
            label: ent[1].name
        }
    }).sort((a,b)=>a.label<b.label?-1:1);
    const sorted = getSortedEntities(tk, mtk, rk, reference, types);
    const results = getResults(query, suggestions, types);
    const isFirstRun = useRef(true);

    useEffect(function () {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        updateAccess(access);
    }, [access]);

    function addAccess({type, id}) {
        let na = {...access};
        na[type][id] = true;
        setAccess(na);
    }

    function removeAccess({type, id}) {
        let na = {...access};
        delete na[type][id];
        setAccess(na);
    }

    function handleSelectedClick(selected, e) {
        if (selected) {
            removeAccess(e);
        } else {
            addAccess(e);
        }
    }

    return <div className="max-w-sm rounded-md  overflow-y-auto"
                style={{maxHeight: '320px', height: '320px'}}>
        {show_search&&<div className="px-2 py-2">
            <LocalSearchBar onChange={q => setQuery(q)}/>
        </div>}
        <div className="px-2 pb-2 space-y-px">
            {types.includes('moderators')&&<CommunityAccessItem text="Moderators" selected={access.moderators} setSelected={(a) => {
                let na = {...access};
                na.moderators = a;
                setAccess(na);
            }}/>}
            {types.includes('group_members')&&<CommunityAccessItem text="Group Members" selected={access.group_members} setSelected={(a) => {
                let na = {...access};
                na.group_members = a;
                setAccess(na);
            }}/>}
            {types.includes('community')&&<CommunityAccessItem text="Community (All Members)" selected={access.community} setSelected={(a) => {
                let na = {...access};
                na.community = a;
                setAccess(na);
            }}/>}
            {types.includes('all_roles')&&<AllRolesAccessItems selected={access.all_roles?access.all_roles:false} setSelected={(a) => {
                let na = {...access};
                na.all_roles = a;
                setAccess(na);
            }}/>}
            {!query && show_added && sorted.map(e => <Entity onClick={() => removeAccess(e)} action={'remove'}
                                               key={`${e.type}-${e.id}`} {...e} />)}
            {query && results.map(e => {
                const selected = access[e.type][e.id];
                return <Entity onClick={() => handleSelectedClick(selected, e)} action={selected ? "remove" : "add"}
                               key={`${e.type}-${e.id}`} {...e} />
            })}
            {!query && <div className="space-y-1">
                {atk.length > 0 && show.includes('teams') && <div className="pt-2">
                    <div className="text-xs px-1.5 pb-0.5 font-medium text-gray-600">Teams</div>
                    <div>
                        {atk.map(e => {
                            const selected = access[e.type][e.id];
                            return <Entity onClick={() => handleSelectedClick(selected, e)}
                                           action={selected ? "remove" : "add"}
                                           key={`${e.type}-${e.id}`} {...e} />
                        })}
                    </div>
                </div>}
                {ark.length > 0 && show.includes('roles') && <div className="pt-2">
                    <div className="text-xs px-1.5 pb-0.5 font-medium text-gray-600">Roles</div>
                    <div>
                        {ark.map(e => {
                            const selected = access[e.type][e.id];
                            return <Entity onClick={() => handleSelectedClick(selected, e)}
                                           action={selected ? "remove" : "add"}
                                           key={`${e.type}-${e.id}`} {...e} />
                        })}
                    </div>
                </div>}
                {amtk.length > 0 && show.includes('member_types') && <div className="pt-2">
                    <div className="text-xs px-1.5 pb-0.5 font-medium text-gray-600">Membership Levels</div>
                    <div>
                        {amtk.map(e => {
                            const selected = access[e.type][e.id];
                            return <Entity onClick={() => handleSelectedClick(selected, e)}
                                           action={selected ? "remove" : "add"}
                                           key={`${e.type}-${e.id}`} {...e} />
                        })}
                    </div>
                </div>}
            </div>}
        </div>
    </div>
}