import {gapi, loadAuth2} from "gapi-script";
import config from "../../../config/firebase-config";
import {getItemFromLocalStorage, saveItemToLocalStorage} from "../../../m3/utilities/localstorage";
import {authFetch} from "../../../../api/network";

// https://www.googleapis.com/auth/userinfo.email
export async function initializeGapi(scopes = 'https://www.googleapis.com/auth/calendar') {
    return new Promise((resolve, reject) => {
        function init() {
            gapi
                .client
                .init({
                    apiKey: config.calendarApiKey,
                    clientId: config.calendarClientID,
                    discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
                    scope: scopes
                })
                .then((res) => {
                    console.log("RESULT")
                    resolve(res);
                })
        }

        gapi.load("client", init);
    })
}

export async function gapiGetUserCalendars() {
    return new Promise((resolve, reject) => {

        gapi.client.calendar.calendarList.list()
            .then((res) => {
                console.log("CALENDARS", res);
                resolve(res);
            })
    })
}

export async function gapiAuth(scopes = 'https://www.googleapis.com/auth/calendar') {
    return new Promise((resolve, reject) => {
        const res = (authResult) => {
            console.log("authResult", authResult)
            resolve(authResult);
        };
        try {
            console.log("TRY THE FOLLOWING")
            gapi.auth2.authorize({
                client_id: config.calendarClientID,
                scope: scopes,
                prompt: 'consent',
                approval_prompt: 'force',
                access_type: 'offline',
                immediate: true
            }, res);
        } catch (e) {
            console.log("error", e);
            resolve(null);
        }
    })
}

export function isGapiInitialized() {
    return gapi && gapi.client;
}

export async function saveGapiAuthInfo(community_uid, member_id, authResult) {
    saveItemToLocalStorage(`${community_uid}_${member_id}_gapi-auth`, authResult);
    const payload = {
        token: authResult, community_uid, member_id
    };
    return new Promise((resolve, reject) => {
        const res = (resp) => {
            resolve(resp);
        };
        authFetch("/update-member-gapi-token", res, res, "POST", {payload})
    })
}

export async function addGoogleCalendarToGroup(calendar_id, community_uid, group_id, member_id) {
    const token = getItemFromLocalStorage(`${community_uid}_${member_id}_gapi-auth`, null);
    if (!token) {
        alert('no token');
        return;
    }
    const payload = {
        calendar_id, token, group_id, member_id, community_uid
    }
    return new Promise((resolve, reject) => {
        const res = (resp) => {
            resolve(resp);
        };
        authFetch("/add-calendar-to-group", res, res, "POST", {payload})
    })
}

export async function syncGroupEventsCalendar(group_id, community_uid, member_id) {

    const payload = {
        group_id, community_uid, member_id
    };
    return new Promise((resolve, reject) => {
        const res = (resp) => {
            resolve(resp);
        };
        authFetch("/sync-group-events-calendar", res, res, "POST", {payload})
    })
}

export async function removeGoogleCalendarFromGroup(calendar_id, community_uid, group_id, member_id) {
    const token = getItemFromLocalStorage(`${community_uid}_${member_id}_gapi-auth`, null);
    if (!token) {
        alert('no token');
        return;
    }
    const payload = {
        calendar_id, token, group_id, member_id, community_uid
    }
    return new Promise((resolve, reject) => {
        const res = (resp) => {
            resolve(resp);
        };
        authFetch("/remove-calendar-from-group", res, res, "POST", {payload})
    })
}

export async function testGetCalendarEvents(calendar_id, community_uid, group_id, member_id) {
    const token = getItemFromLocalStorage(`${community_uid}_${member_id}_gapi-auth`, null);
    if (!token) {
        alert('no token');
        return;
    }
    const payload = {
        token, calendar_id, community_uid, group_id, member_id
    };
    return new Promise((resolve, reject) => {
        const res = (resp) => {
            console.log("resp", resp);
            resolve(resp);
        };
        authFetch("/test-calendar-id", res, res, "POST", {payload})
    })
}