import React from "react";
import Select, {components} from "react-select";
import {advanced_select_styles} from "../../createable-async-select/styles";
import {advanced_select_components, advanced_select_components_simple} from "../../createable-async-select/components";


/*

                   styles={{
                       menuPortal: base => ({ ...base, zIndex: 9999 }),
                       singleValue: (base, state) => ({
                           ...base,
                           fontSize: getFontSize(simple,filter),
                           paddingLeft: state.pad_left ? '32px' : '',
                           margin: 0
                       }),
                       placeholder: (base) => ({
                          ...base,
                          position: 'absolute'
                       }),
                       valueContainer: (base) => ({
                           ...base,
                           display: 'flex',
                           padding: '0 0 0 0.875rem'
                       }),
                       container: (base,state) => ({
                           ...base
                       }),
                       indicatorSeparator: base => ({
                          ...base,
                          display: 'none'
                       }),
                       dropdownIndicator: base => ({
                           ...base,
                           padding: getDropddownPadding(simple,filter)
                       }),
                       option: (base,state) => ({
                           ...base,
                           background: state.isSelected ? '#c5e1fb' : '',
                           paddingLeft: state.pad_left ? '38px' : ''
                       }),
                       control: (base, state) => ({
                           ...base,
                           outline: 'none',
                           borderRadius: '0.375rem',
                           boxShadow: getBoxShadow(state.isFocused,simple,filter),
                           border: state.isFocused?"1px solid #659adf":"1px solid #d4d4d8",
                           minHeight: getMinHeight(minHeight,simple,filter)
                       })
                   }}
 */
function getValue(v,o) {
    if(typeof v === "string") {
        const a = o.filter(b=>b.value===v)[0];
        if(a) {
            return a;
        } else {
            return null;
        }
    }
    if(v&&v.value===null) {
        return null;
    }
    return v;
}
// loadOptions
// components={{ControlComponent,Option,SelectContainer,SingleValue}}
export const BasicSelect = ({onChange=()=>{}, usePortal = true, menuPortalTarget=null, autofocus=false, placeholder='', multi=false, searchable=false, disabled=false, loading=false, clearable=false, onBlur=()=>{}, options=[], value={}}) => {
   const show_v = getValue(value,options);
    return <Select onBlur={onBlur} onChange={onChange} value={show_v} menuPlacement={'auto'}
                   isSearchable={searchable} autoFocus={autofocus} menuPortalTarget={menuPortalTarget?menuPortalTarget:usePortal?document.body:null}
                   components={advanced_select_components_simple}
                   styles={advanced_select_styles}
                   isMulti={multi}
                   placeholder={placeholder}
                   isClearable={clearable}
                   isDisabled={disabled}
                   isLoading={loading}
                   options={options}/>;
};