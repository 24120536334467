import React, {useState} from "react"
import './styles.css';
import Form from "../form";
import Field from "../form/field";

function colspan(cs) {
    switch (cs) {
        case 2:
            return 'col-span-2'
        default:
            return 'col-span-1'
    }
}

function makeDataUpdate(d,ac,nv) {

    let sp = ac.split('.');

    if(sp.length>1&&!d[sp[0]]) {
        d[sp[0]] = {};
    }

    if(sp.length>2&&!d[sp[0]][sp[1]]) {
        d[sp[0]][sp[1]] = {};
    }

    if (sp.length === 3) {
        d[sp[0]][sp[1]][sp[2]] = nv;
    } else if (sp.length === 2) {
        d[sp[0]][sp[1]] = nv;
    } else {
        d[sp[0]] = nv;
    }

    return d;
}

export function updateFieldValue(ac,nv,data,field) {
    if(!ac) {
        if(field&&field.getData) {
            return {
                ...data,
                ...nv
            }
        }
        return data;
    }
    let d = {...data};

    if(ac==='profile_picture'&&Array.isArray(nv)) {
        d = makeDataUpdate(d,'profile_picture',nv[0]);
        d = makeDataUpdate(d,'profile_picture_color',nv[1]);
    } else if(Array.isArray(ac)&&Array.isArray(nv)) {
        for(let i=0;i<ac.length;i++) {
            d = makeDataUpdate(d,ac[i],nv[i]);
        }
    } else {
        d = makeDataUpdate(d,ac,nv);
    }

    return d;
}

export function getFieldValue(ac, data, item) {

    if (!ac) {
        if(item&&item.getData) {
            return item.getData(data);
        }
        return null;
    }
    let sp = ac.split('.');

    if (sp.length === 3) {
        return data?.[sp[0]]?.[sp[1]]?.[sp[2]] || null;
    } else if (sp.length === 2) {
        return data?.[sp[0]]?.[sp[1]] || null;
    } else {

        return data?.[sp[0]] || null;
    }
}

export default function FormFieldsRenderer({fields = [], fill, data, onChange}) {

    function handleOnChange(accessor,new_value,old_value,field,ex) {
        onChange(accessor,new_value,old_value,field,ex)
    }

    let grid_template_columns, use_col_span = false;

    if(!fill && window.innerWidth>768) {
        grid_template_columns = 'repeat(2, minmax(0, 1fr))';
        use_col_span = true;
    } else {
        grid_template_columns = "1fr";
    }

    return <Form>
        <div style={{gridTemplateColumns:grid_template_columns}} className={`grid gap-5`}>
            {fields.map((fi, fk) => {
                const value = getFieldValue(fi.accessor, data, fi);
                if(!fi.accessor&&!fi.getData) {
                    console.error('NO ACCESSOR',fi);
                }
                return <div key={fk} id={`field-${fi.accessor||""}`} className={`${!use_col_span?"":colspan(fi.col_span)}`}>
                    <Field {...fi} id={fi.accessor||""} onChange={(a,b,extra=null)=>{
                        handleOnChange(a,b,value,fi,extra)
                    }} key={fk} options={fi.options||{}} required={fi.required} label={fi.label || ""} value={value} type={fi.type || "text"}/>
                </div>
            })}
        </div>
    </Form>
};