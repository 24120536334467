import {convertFromRaw} from "draft-js";
import {membersBuildInviteTo} from "../manage-member";
import {getEmailTemplateData} from "../../../../api/admin/email-templates";

export async function composer_preparePrefillFromTemplate(community_uid,template_id="new-member-invite",type='invite',member_records = [],onSendCb=()=>{}, custom_data={}) {
    return await getEmailTemplateData(community_uid, template_id)
        .then((resp) => {

            return {
                to: membersBuildInviteTo(member_records),
                config: {},
                content: convertFromRaw(JSON.parse(resp.content)),
                subject: resp.subject,
                type: type,
                template: template_id,
                features: {
                    can_add_to: false,
                    can_edit_to: false
                },
                custom_data: custom_data,
                onSend: () => {
                    onSendCb();
                }
            };
        })
}