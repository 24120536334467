import React from "react";
import {useCommunity} from "../../../config/community";
import {getGroupData} from "../../../routes/community/group/api";
import {GroupEvents} from "../../../routes/community/calendar/group-events";

export function UpcomingEventsWidget(props) {
    const community = useCommunity();
    const [group, setGroup] = React.useState(null);
    const [error, setError] = React.useState(null);

    React.useEffect(()=>{
        if (props.data.group_id) {
            getGroupData(community.id, props.data.group_id)
                .then(dt => {
                    if (!dt) {
                        setError("does_not_exist")
                    } else {
                        setGroup(dt)
                    }
                })
        }
    }, [props.data.group_id]);

    if(error) return <div className="text-center p-4 text-gray-500">
        Something went wrong
    </div>;

    if(!group) return <div className="text-center p-4 text-gray-500">
        Loading...
    </div>;

    const calendar_id = group?.['power_ups']?.['google_calendar']?.['config']?.calendar_id;

    if(!calendar_id) return <div className="text-center p-4 text-gray-500">
        No calendar configured
    </div>;

    let c = <div>
        <GroupEvents calendar_id={calendar_id} layout="message" limit={3}
                     group_id={props.data.group_id}/>
    </div>;

    if(!props.read_only) {
        return <div className="border-gray-200 border p-2 bg-gray-50 rounded-md">
            <div className="pointer-events-none cursor-not-allowed">
            {c}
            </div>
            <div className="pt-1 text-gray-600 text-sm">
                Widget Preview
            </div>
        </div>
    }

    return c;
}