import {data__member_profile_sections} from "../../../../../common/data/profile-sections";
import {
    getMembershipLevelOptions,
    getMembershipStatusOptions,
    getNestedValue,
    shallowCompareValues
} from "../../../../../common/utilities/general";
import {data__member_profile_fields} from "../../../../../common/data/data-fields";
import {all_field_types} from "../../../../../common/data/field-types";
import {getCFValue, isCFEmpty} from "../../../../m3/_legacy_components/profile-field-renderer/value-pipeline";
import {getPhoneServices} from "../member-profile-utils";

export const getFieldVisibility = (field_id, community_design_defaults, standard_field_data) => {
    // spec for saving under design defaults is `design_defaults.f_${field_id.replace(/./g, "_")}_visibility`
    const field_visibility_key = `f_${field_id.replace(/\./g, "_")}_visibility`;
    return community_design_defaults?.[field_visibility_key] || "all-members";
};

export const getFieldEditability = (field_id, community_design_defaults, standard_field_data) => {
    if (standard_field_data) {
        if (standard_field_data?.meta?.derived) {
            return "derived";
        }
        if (standard_field_data?.meta?.dynamic) {
            return "dynamic";
        }
        if (standard_field_data?.meta?.internal) {
            return "read-only";
        }
    }
    // spec for saving under design defaults is `design_defaults.f_${field_id.replace(/./g, "_")}_visibility`
    const field_editability_key = `f_${field_id.replace(/\./g, "_")}_editability`;
    return community_design_defaults?.[field_editability_key] || "standard";
};

const getCustomFieldData = (custom_field_id, custom_field_sections) => {
    const section_with_field = custom_field_sections.find(section => section.fields.includes(custom_field_id));
    if (section_with_field) {
        return section_with_field.field_data?.[custom_field_id] || {};
    } else {
        return {};
    }
}

export const getFieldMetadata = (field_id, design_defaults, custom_fields) => {
    if (field_id?.startsWith("custom_fields.")) {
        const custom_field_id = field_id.replace("custom_fields.", "");
        const custom_field_data = getCustomFieldData(custom_field_id, custom_fields);
        return {
            label: custom_field_data?.name,
            type: custom_field_data?.type,
            type_label: all_field_types[custom_field_data.type]?.label||"-",

            is_derived: false,
            is_dynamic: false,
            is_internal: false,

            visibility: getFieldVisibility(custom_field_id, design_defaults),
            editability: getFieldEditability(custom_field_id, design_defaults),

            id: custom_field_id
        }
    }

    let standard_field_data = data__member_profile_fields?.[field_id];

    if (standard_field_data) {
        return {
            label: standard_field_data.label,
            type: standard_field_data.type,
            type_label: all_field_types[standard_field_data.type].label,

            is_derived: standard_field_data?.meta?.derived,
            is_dynamic: standard_field_data?.meta?.dynamic,
            is_internal: standard_field_data?.meta?.internal,

            visibility: getFieldVisibility(field_id, design_defaults, standard_field_data),
            editability: getFieldEditability(field_id, design_defaults, standard_field_data),

            id: field_id
        }
    } else {
        return null;
    }
}

export const getSectionVisibility = (section_id, community_design_defaults) => {
    return community_design_defaults?.[`${section_id}_visibility`] || "all-members";
};

const canViewField = (visibility, field_id, community, my_profile) => {
    const can_manage_members = community.member_access.manage_members;

    switch (visibility) {
        case "all-members":
            return true;
        case "admins-only":
            return community.is_admin;
        case "private":
            return can_manage_members || my_profile;
        case "read-only":
            return can_manage_members;
        default:
            return false;
    }
}

const canEditField = (visibility, field_id, community, my_profile, is_empty) => {
    const can_manage_members = community.member_access.manage_members;

    switch (visibility) {
        case "standard":
            return my_profile || can_manage_members;
        case "read-only":
            return can_manage_members;
        case "locked":
            return (is_empty && my_profile) || can_manage_members;
        default:
            return false;
    }
}

const canEditSection = (visibility, section_id, community, my_profile) => {
    const can_manage_members = community.member_access.manage_members;

    switch (visibility) {
        case "all-members":
            return my_profile || can_manage_members;
        case "admins-only":
            return community.is_admin;
        case "private":
            return can_manage_members || my_profile;
        case "read-only":
            return can_manage_members;
        default:
            return false;
    }
}

const shouldHideSection = (visibility, section_id, community, my_profile) => {
    const can_manage_members = community.member_access.manage_members;

    switch (visibility) {
        case "all-members":
            return false;
        case "admins-only":
            return !community.is_admin;
        case "private":
            return !can_manage_members && !my_profile;
        case "read-only":
            return !can_manage_members && !my_profile;
        default:
            return true;
    }
}

export const getMemberProfileFieldData = (field) => {
    return data__member_profile_fields[field];
};

const addFieldToVisible = (mode, can_view, can_edit, derived, dynamic) => {
    if(!can_view) {
        return false;
    } else if(mode === "view") {
        return true;
    } else {
        return !derived && !dynamic;
    }
}

export function buildCommonMemberProfileSections(mode = "view", member_id, member_data, sections, community, filter_id) {
    let a = [];

    const community_fields_context = {
        membership_levels: getMembershipLevelOptions(community),
        membership_status: getMembershipStatusOptions(community)
    };

    const phone_services = getPhoneServices(member_data);

    const is_my_profile = member_id === community.member_id;

    sections.forEach(section => {
        if(filter_id && section !== filter_id) {
            return;
        }

        const section_system_data = data__member_profile_sections[section];

        if (section_system_data && !section_system_data.is_disabled) {
            a.push({
                id: section,
                ...section_system_data,
                is_custom_section: false,

                visibility: getSectionVisibility(section, community?.profile?.design_defaults),

                visible_fields: []
            });

            const index = a.length - 1;

            a[index].hide_section = shouldHideSection(a[index].visibility, section, community, is_my_profile);
            a[index].can_edit = canEditSection(a[index].visibility, section, community, is_my_profile);

            // also add visibility

            // now add visible_fields with label and value
            a[index].fields.forEach(field => {
                const field_data = getMemberProfileFieldData(field);

                if (field_data) {

                    const field_type_data = all_field_types?.[field_data.type] || {
                        getEmptyValue: () => ""
                    };

                    const field_value = getNestedValue(field_data?.member?.accessor, member_data, field_type_data.getEmptyValue());

                    const field_visibility = getFieldVisibility(field, community?.profile?.design_defaults, field_data);
                    const field_editability = getFieldEditability(field, community?.profile?.design_defaults, field_data);

                    let is_empty, value;

                    if (field_data?.member?.accessor.startsWith("__")) {
                        if (field_data?.member?.accessor === "__full_name") {
                            value = `${member_data?.about?.first_name} ${member_data?.about?.last_name}`;
                        } else {
                            value = field_value;
                        }
                        is_empty = false;
                    } else {
                        value = field_value;
                        is_empty = shallowCompareValues(value, field_type_data.getEmptyValue());
                    }

                    const can_view = canViewField(field_visibility, field, community, is_my_profile, is_empty);
                    const can_edit = canEditField(field_editability, field, community, is_my_profile, is_empty);

                    const is_locked = !can_edit && field_editability === "locked";

                    let options = [], footer = null;
                    if (field === "member-type") {
                        options = community_fields_context.membership_levels;
                    } else if (field === "membership-status") {
                        options = community_fields_context.membership_status;
                    }

                    if (field === "phone") {
                        footer = {
                            type: "phone-services",
                            data: phone_services.phone
                        };
                    }

                    const add_to_visible = addFieldToVisible(mode, can_view, can_edit, field_data?.meta?.derived, field_data?.meta?.dynamic);

                    if(add_to_visible) {
                        a[a.length - 1].visible_fields.push({
                            field_data,

                            accessor: field_data?.member?.accessor,

                            label: field_data.label,
                            type: field_data.type,

                            visibility: field_visibility,
                            editability: field_editability,

                            can_view,
                            can_edit,

                            is_empty,
                            is_locked,

                            footer,

                            value,

                            options
                        });
                    }
                }
            })
        }
    });

    // add custom sections
    Object.entries(community?.custom_fields).map(([key, dt], index) => {
        if(filter_id && dt.id !== filter_id) {
            return;
        }

        a.push({
            id: dt?.id,
            label: dt.name,
            description: "",
            fields: dt.fields,
            is_custom_section: true,

            visibility: dt?.visibility || "all-members",
            visible_fields: [],
        })

        const section_index = a.length - 1;

        a[section_index].hide_section = shouldHideSection(a[section_index].visibility, `custom.${key}`, community, is_my_profile);
        a[section_index].can_edit = canEditSection(a[section_index].visibility, `custom.${key}`, community, is_my_profile);

        dt.fields.forEach(fk => {
            const fi = dt.field_data[fk];
            if (fi) {
                const v = getCFValue(fk, fi.type, member_data?.custom_fields || {});
                const array_index = a.length - 1;
                /*
                  add_prompt: add_empty_field,
                    onAddClick: () => fns.onOpenEdit(`custom_fields.${fk}`),
                    hint: fi.hint,
                    desc: fi.desc,
                 */
                const field_visibility = getFieldVisibility(fk, community?.profile?.design_defaults);
                const field_editability = getFieldEditability(fk, community?.profile?.design_defaults);

                let is_empty = isCFEmpty(v, fi.type);

                const can_view = canViewField(field_visibility, fk, community, is_my_profile, is_empty);
                const can_edit = canEditField(field_editability, fk, community, is_my_profile, is_empty);

                const is_locked = !can_edit && field_editability === "locked";

                // we want to add a field if can view in both view or edit mode
                // or if in edit mode, hide if is derived
                const add_to_visible = addFieldToVisible(mode, can_view, can_edit, fi?.type === "derived", fi?.type === "dynamic");

                if(add_to_visible) {
                    a[array_index].visible_fields.push({
                        field_data: {
                            meta: {
                                editable: !!can_edit,
                            }
                        },

                        label: fi.name,
                        type: fi.type,
                        required: fi.required,

                        accessor: `custom_fields.${fk}`,

                        community_uid: community.uid,

                        visibility: field_visibility,
                        editability: field_editability,

                        value: v,
                        can_view,
                        can_edit,

                        is_empty: is_empty,
                        is_locked: is_locked,

                        options: fi.options || {}
                    })
                }
            }
        })
    });

    return a;
}

export function buildCommonMemberMeta(member_id, member_data, community, following, follows_you) {
    let a = {
        is_my_profile: false,
        is_following: following,
        is_following_you: follows_you,

        is_deactivated: false,
        is_suspended: false,

        is_private: member_data?.is_private || false,

        is_existing: true,

        can_follow: true,

        can_view: true,
        can_edit_profile: false,
        can_edit_record: false,

        error: null
    };

    if (community.member_access.manage_members) {
        a.can_edit_record = true;
    }

    if (member_id === community.member_id) {
        a.is_my_profile = true;
        a.can_edit_profile = true;
    }

    if (member_data?.account_status === "archived") {

    }

    if (member_data?.account_status === "suspended") {

    }

    return a;
}