import React, {useEffect, useState} from "react";
import {useCommunity} from "../../../config/community";
import {collection, getDocs, limit, query, where} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import WidgetEventsSidebar from "../../../m3/_legacy_components/widgets/events-sidebar";
import dayjs from "dayjs";
import {removeGoogleCalendarFromGroup, syncGroupEventsCalendar} from "./gapi";
import {useToasts} from "../../../config/toasts";
import {buildEventTimeString} from "./utilities";

function sortEvents(a, b) {
    return a.start_datetime - b.start_datetime;
}

async function getCommunityEventsFromDB(cid, gid,lim=10) {
    if (!cid || !gid) return [];
    const ref = collection(db, 'community_content', cid, 'events');
    const today_start = dayjs().startOf('day').toISOString();
    const q = query(ref, where('group_id', '==', gid), where('start_datetime','>',today_start), limit(lim))
    return await getDocs(q)
        .then(snap => {
            return !snap.empty ? snap.docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            }).sort(sortEvents) : [];
        });
}

function buildDateTimeString(start,start_datetime,end,end_datetime,all_day) {
    // input utils = 2023-03-28T13:00:00-07:00

    const start_date = dayjs(start).format('dddd, MMM DD');
    const start_time = dayjs(start_datetime).format('h:mma');
    const end_date = dayjs(end).format('dddd, MMM DD');
    const end_time = dayjs(end_datetime).format('h:mma');

    if(all_day) {
        return `${start_date} - ${end_date}`;
    }

    return `${start_date} at ${start_time}`;
}

export function GroupEvents({group_id = '',last_updated,limit=10,calendar_id=''}) {
    const community = useCommunity();
    const toasts = useToasts();
    const [events, setEvents] = useState([]);

    useEffect(function () {
        getCommunityEvents()
    }, [group_id])

    function handleRefresh() {
        toasts.addToast({
            text: "Syncing events...",
            intent: "info",
        })
        syncGroupEventsCalendar(group_id, community.uid, community.member_id)
            .then((res) => {
                toasts.addToast({
                    text: "Done",
                    intent: "success",
                })
              getCommunityEvents()
            })
    }

    function getCommunityEvents() {
        getCommunityEventsFromDB(community.uid, group_id, limit)
            .then((res) => {
                setEvents(res);
            })
    }

    function onClickUpdate() {
        handleRefresh();
    }

    const tranformedEvents = events.map(evt => {
        return {
            ...evt,
            attendee_count: evt.attendee_emails ? Object.keys(evt.attendee_emails).length : 0,
            datetime_string: buildEventTimeString(evt.start_datetime, evt.end_datetime, evt.all_day),
            _month_name: dayjs(evt.start).format('MMM'),
            _day: dayjs(evt.start).format('D'),
            starts_at: "Starts at"
        }
    });

    //const can_update_now = dayjs().diff(last_updated, 'minutes') > 5;;

    const last_updated_text = last_updated? dayjs(last_updated).format('MMM D, YYYY h:mm A') : 'never';
    const can_update_now = calendar_id && (last_updated? dayjs().diff(last_updated, 'minutes') > 5 : true);
    // <span>Last updated {last_updated? dayjs(last_updated).utils('MMM D, YYYY h:mm A') : 'never'}</span>
    return <div>
        <WidgetEventsSidebar group_id={group_id} items={tranformedEvents}/>
        {calendar_id&&<div className="p-4 text-xs text-gray-600 space-y-1 text-center">
            {last_updated&&<><div>Last updated {last_updated_text}</div></>}
            {can_update_now&&<><div onClick={onClickUpdate} className="underline cursor-pointer">Sync from Google Calendar</div></>}
        </div>}
    </div>
}