import React, {useEffect, useRef, useState} from "react"
import './styles.css';
import {
    CommunityEntityPage, PageLayoutBlock, PageLayoutSection, PageLayoutWrapper
} from "../../../m3/_legacy_components/app-frame/layouts";
import EntityProfileCard from "../../../m3/_legacy_components/entity-profile-card";
import EntityTabs from "../../../m3/_legacy_components/entity-tabs";
import {Forum} from "../../../features/forum/forum";
import GroupAnalyticsTab from "./tabs/analytics";
import EntityEventsTab from "./tabs/events";
import GroupDriveTab from "./tabs/drive";
import EntityMediaTab from "./tabs/media";
import {collection, doc, getDoc} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {useCommunity} from "../../../config/community";
import {Navigate, Route, Routes, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useUnaverse} from "../../../config/unaverse";
import {LockClosedIcon} from "@heroicons/react/24/solid";
import Button from "../../../m3/_legacy_components/button";
import {GroupManageModal} from "./manage/modal";
import {getItemFromLocalStorage, saveItemToLocalStorage} from "../../../m3/utilities/localstorage";
import {setDocumentTitle} from "../../../m3/utilities/set-document-title";
import {GroupMembersModal} from "./members/modal";
import WidgetEventsSidebar from "../../../m3/_legacy_components/widgets/events-sidebar";
import {TextLinksFooter} from "./text-links-footer";
import QuickActions from "../../../m3/_legacy_components/quick-actions";
import {ArrowDownOnSquareIcon, TableCellsIcon, UserPlusIcon} from "@heroicons/react/20/solid";
import {group_changeCoverPhoto} from "../../../../api/groups/change-cover-photo";
import {useToasts} from "../../../config/toasts";
import {authFetch} from "../../../../api/network";
import {group_makeMemberOrganizer, group_removeOrganizer} from "../../../../api/groups/organizers";
import {getGroupNotificationSettings, getNotificationPreferences} from "../preferences/tabs/notifications";
import GettingStarted from "../../../m3/_legacy_components/getting-started";
import {buildGetStartedGroupSetupManifest} from "../../../m3/_legacy_components/getting-started/group-setup-manifest";
import {getGroupAccess} from "./manage/tabs/access";
import {buildGroupProfileCard} from "./build-group-card";
import {
    buildPinboardData, buildWriteWidgets, getGroupAccessForMember, getTopicData, markGroupAsUpToDate
} from "./utilities";
import {EntityPinboard, SuggestAddLocation} from "./misc-components";
import {handleSetSpaceLocation} from "./api";
import {m3_icon_map} from "../../../m3/icons/icon-map";
import {api_generateGhostAccessLink} from "../../manage/pages/tools";
import {copyTextToClipboard} from "../../../m3/utilities/copy-to-clipboard";
import M3_A_ActionMenu from "../../../m3/atoms/action-menu";
import M3_A_IconButton from "../../../m3/atoms/icon-button";

async function getGroupData(cid, id) {
    const col = collection(db, 'community_entities', cid, 'groups');
    return await getDoc(doc(col, id))
        .then(doc => {
            return doc.exists() ? {id, ...doc.data()} : null;
        });
}

const type_map = {
    member: 'members', members: 'members', roles: 'roles', role: 'roles', filter: 'filters'
};

function buildTabs(demo, group, is_organizer_or_admin) {
    let a = [];

    a.push({
        label: "Posts"
    });

    a.push({
        label: "Events"
    });

    /*
    a.push({
        label: "Media"
    });
 */
    a.push({
        label: "Drive"
    });

    if (is_organizer_or_admin) {
        a.push({
            label: "Analytics"
        });
    }

    return a;
}

async function handleSyncMembers(community,id) {
    return new Promise((resolve, reject) => {
        const res = (resp) => {
            if (resp) {
                resolve(true);
            } else {
                reject("Error");
            }
        };
        authFetch("/groups/sync-members", res, res, "POST", {
            payload: {
                id,
                member_id: community.member_id,
                community_uid: community.uid
            }
        })
    })
}

async function makeEveryoneSpace(community,id) {
    if(!id || community?.profile?.everyone_group_id === id) {
        return false;
    }
    console.log("Making everyone space")
    return new Promise((resolve, reject) => {
        const res = (resp) => {
            if(resp) {
                console.log("done", resp);
                resolve(true);
            } else {
                reject("Error");
            }
        };
        authFetch("/superadmin/change-everyone-space", res,res,"POST", {
            payload: {
                new_space_id: id,
                community_uid: community.uid,
                member_id: community.member_id
            }
        })
    })
}

export default function GroupPage({demo = false}) {
    const toasts = useToasts();
    const {id} = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const community = useCommunity();
    const unaverse = useUnaverse();
    const [data, setData] = useState({});
    const [topics, setTopics] = useState([]);
    const [error, setError] = useState();
    const [modal, setModal] = useState();
    const [modal_data, setModalData] = useState();
    const initial_load = Object.keys(data).length === 0;
    const isFirstRun = useRef(true);
    const access_values = getGroupAccess(data);

    const is_member = community?.member_id && data?.user_uids?.[community.member_id];

    const is_organizer = data?.moderators?.user_uids?.[community?.member_id || ""];

    const is_organizer_or_admin = community.is_admin || is_organizer;

    const can_post_to_feed = getGroupAccessForMember(access_values.post_to_feed, is_organizer, is_member, community.is_admin, community.member, community.member_types);

    let tabs = buildTabs(demo, data, is_organizer_or_admin);

    const [tab, setTab] = useState(tabs[0].label);

    const notification_settings = getGroupNotificationSettings(community.member, getNotificationPreferences(community.member, "America/Los_Angeles"), id)

    const default_size = ""; //_large
    const flags = {
        all_names: getItemFromLocalStorage(`${community.id}_group_names_index`, []),
        can_manage: is_organizer_or_admin,
        show_organizers: true,
        loading: initial_load,
        cover_photo_size: default_size,

        moderator_user_uids: data?.moderators?.user_uids || {},
        is_organizer_or_admin,
        is_organizer: data?.moderators?.user_uids[community.member_id],
        is_admin: community.is_admin,
        is_group_member: !data?.user_uids ? false : data?.user_uids[community.member_id]
    };

    useEffect(function () {

        let qstr = searchParams.toString();

        if (qstr) {
            qstr = `?${qstr}`;
        } else {
            qstr = '';
        }
        // update url
        if (tab === 'Posts') {
            navigate(`/c/${community.domain}/space/${id}/posts${qstr}`, {replace: true})
        } else if (tab === 'Events') {
            navigate(`/c/${community.domain}/space/${id}/events${qstr}`, {replace: true})
        } else if (tab === 'Drive') {
            navigate(`/c/${community.domain}/space/${id}/drive${qstr}`, {replace: true})
        } else if (tab === 'Analytics') {
            navigate(`/c/${community.domain}/space/${id}/analytics${qstr}`, {replace: true})
        } else if (tab === 'Media') {
            navigate(`/c/${community.domain}/space/${id}/media${qstr}`, {replace: true})
        }

    }, [tab, community.domain])

    useEffect(function () {
        const action = searchParams.has('action')
        const add_app = searchParams.has('add-app')

        if (!!action && searchParams.get('action') === 'edit-smart-rules') {
            handleOpenEditSmartRules();
        }

        if (!!action && searchParams.get('action') === 'add-members') {
            handleOpenAddGroupMembers()
        }

        if (!!action && searchParams.get('action') === 'edit-pinboard') {
            setModalData({
                starting_tab: "Pinboard"
            })
            setModal('manage');
        }

        if (!!action && searchParams.get('action') === 'view-drive') {
            setTab('Drive');
        }

        if (!!add_app && searchParams.get('add-app')) {
            setModalData({
                starting_tab: "Apps", starting_section: searchParams.get('add-app')
            })
            setModal('manage');
        }
    }, [searchParams])

    useEffect(function () {
        setTab(searchParams.get('tab') || 'Posts');
        if (isFirstRun.current) {
            isFirstRun.current = false;

        } else {
            setData({});
        }

        getGroup();

        return function () {
            postLeaveHooks();
        }
    }, [id])

    function getGroup() {
        getGroupData(community.id, id)
            .then(dt => {
                if (!dt) {
                    setError("does_not_exist")
                } else {
                    setData(dt)
                    postGroupLoadHooks();
                }
            })
    }

    function postGroupLoadHooks() {
        getTopicData(community.id, id)
            .then(topics => {
                setTopics(topics)
            })
    }

    function postLeaveHooks() {
        community.updateMemberEntity("groups", id, {
            last_read_at: Date.now(), updates: 0
        })
        markGroupAsUpToDate(community.id, id, community.member_id)
            .then(() => {

            })
    }

    const has_email_list = data?.['power_ups']?.['unaty__mailing_lists'];

    const has_calendar = data?.['power_ups']?.['google_calendar'];

    function handleOpenEditSmartRules() {
        handleOpenMembersModal("Rules")
    }

    function handleOpenMembersModal(starting_tab = "Members") {
        community.openEditOverlay({
            type: 'view-space-members', data: {
                data,
                id,
                starting_tab,
                flags,
                fns,
                onAddMember: handleOpenAddGroupMembers
            }, onCloseCb: () => {
                navigate(`?`);
            }, size: 'medium'
        })
    }

    function handleOpenAddGroupMembers() {
        saveItemToLocalStorage(`getstarted-groupaddmembers-${community.id}-${id}`, true)
        community.openEditOverlay({
            type: 'add-group-members', data: {
                ...data, id
            }, onCloseCb: () => {
                getGroup()
            }, size: 'medium'
        })
    }

    setDocumentTitle(`${data?.name}`, `${community.profile.name}`);

    function handleCoverPhotoChange(url, color) {
        group_changeCoverPhoto(community, id, {url, color})
            .then(() => {
                getGroup();
                community.closeEditModal()
            })
    }

    function removeOrganizer(itid, type, ent, cb) {
        const payload = {
            community_uid: community.id, member_id: community.member_id, group_id: id, type: type_map[type], id: itid
        };
        group_removeOrganizer(community, payload, data, setData, itid, type, toasts)
            .then(() => {

            })
    }

    function makeMemberOrganizer(itid, type, ent, cb) {
        const replace_member_mods = true;

        const f_type = type_map[type];

        const payload = {
            community_uid: community.id,
            member_id: community.member_id,
            group_id: id,
            type: f_type,
            id: itid,
            replace_member_mods: !!replace_member_mods
        };

        group_makeMemberOrganizer(community, payload, toasts, itid, type, data, setData, id, f_type, cb)
            .then(() => {

            })
    }

    function removeEntityFromGroup(itid, type, ent, cb) {

        const keep_members = false;
        const removing_entity = false;

        if (removing_entity) {
            return;
        }
        const payload = {
            community_uid: community.id,
            id: id,
            member_id: community.member_id,
            entity_type: type_map[type],
            keep_members,
            entity_id: itid
        };

        const res = (result) => {
            getGroup(cb);
        };

        authFetch("/groups/remove-entity", res, res, "POST", {payload});
    }

    function onUpdateCb(upd) {
        setData({
            ...data, ...upd
        })
    }


    const fns = {
        onUpdateCb,

        makeMemberOrganizer,
        removeOrganizer,
        removeEntityFromGroup,
        handleCoverPhotoChange,
        onOpenEditCoverPhoto: !is_organizer_or_admin ? null : () => {
            community.openEditOverlay({
                type: 'edit-cover-photo', size: "42.5rem", data: {
                    community_uid: community.uid, onChange: fns.handleCoverPhotoChange
                }, onClose: () => {
                    community.closeEditModal()
                }
            })
        },
        onManage: () => {
            setModal('manage');
        },
        onOpenMembers: () => {
            handleOpenMembersModal()
        }
    }

    const card_info = buildGroupProfileCard(data, unaverse, community, flags, fns, {error})

    let actions = [{
        label: "Add Members", icon: <UserPlusIcon/>, onClick: () => handleOpenAddGroupMembers()
    }, {
        label: "Member List", icon: <TableCellsIcon/>, onClick: () => {
            navigate(`/c/${community.domain}/contacts?goBack=1&list=space__${id}`);
        }
    }, {
        label: "Export Members", icon: <ArrowDownOnSquareIcon/>, onClick: () => {
            navigate(`/c/${community.domain}/contacts?goBack=1&list=space__${id}&modal=export`);
        }
    },];

    let group_links = [];

    let more_options = {
        items: []
    };
    let header_actions = [];

    if (is_organizer_or_admin) {
        group_links.push({
            label: "Space Settings", onClick: () => {
                setModal('manage')
            }
        })
    }


    if (is_organizer_or_admin) {
        header_actions.push({
            type: "icon",
            icon: m3_icon_map.outlines.edit,
            onClick: () => {
                setModal('manage');
            }
        });
    }

    const is_everyone_space = community?.profile?.everyone_group_id === id;

    if (unaverse.is_superadmin && !is_everyone_space) {
        more_options.items.push({
            label: "[Superadmin] Make Everyone Space",
            onClick: () => {
                window.confirm("Are you sure you want this the everyone space? The existing space will not be deleted.") && makeEveryoneSpace(community,id)
                    .then((response)=>{
                        console.log("RESPONSE",response)
                    })
            }
        })
    }

    if(community.is_admin) {
        // add sync members
        more_options.items.push({
            label: "Sync Members",
            onClick: () => {
                handleSyncMembers(community,id)
                    .then(resp=>{
                        toasts.addToast({
                            text: "Members synced", intent: "success"
                        });
                        getGroup();
                    });
            }
        })
    }

    if(more_options.items.length>0) {
       // console.log("CARD INFO",card_info)

        card_info.buttons.unshift(<M3_A_ActionMenu renderAnchor={() => {
            return <div><M3_A_IconButton square icon={m3_icon_map.outlines.ellipsis}/></div>
        }} lists={[more_options]}/>);

    }

    const pinboard_data = buildPinboardData(data);
    //    <Pinboard/>
    //  <WidgetEventsSidebar/>
    //   <GroupDataQualitySuggestions group={data} community_uid={community.id} group_id={id}/>

    const who_can_post = access_values.post_to_feed.group_members ? "Members" : "Organizers";
    const can_post = can_post_to_feed;
    const create_message = <div className="flex gap-1.5 items-center text-gray-500">
        <div className="h-3 w-3">
            <LockClosedIcon/>
        </div>
        <div>{who_can_post} only</div>
    </div>;

    const write_widgets = buildWriteWidgets(has_calendar);

    const card = <EntityProfileCard {...card_info} />;

    const content = <>
        <PageLayoutSection padding_y="py-0" divider={false}>
            <PageLayoutBlock>
                <EntityTabs active={tab} onChangeTab={(t) => setTab(t)} tabs={tabs}/>
            </PageLayoutBlock>
        </PageLayoutSection>
        <PageLayoutSection padding_x="">
            <Routes>
                <Route path="drive/*" element={<PageLayoutBlock padding_x="px-4"><GroupDriveTab group={data}
                                                                                                group_id={id}/></PageLayoutBlock>}/>
                <Route path="analytics" element={<PageLayoutBlock padding_x="px-4"><GroupAnalyticsTab group_id={id}
                                                                                                      group={data}/></PageLayoutBlock>}/>
                <Route path="posts" element={<Forum demo={false} name={data?.name || ""} write_widgets={write_widgets}
                                                    show_title={false}
                                                    create_message={create_message} show_create={can_post} id={id}
                                                    items={[]}
                                                    folder_id={data?.folder_id} group={data}
                                                    topics={topics} type="group"
                                                    config={{}}/>}/>
                <Route path="media/*" element={<EntityMediaTab entity_id={id} entity_type="group"/>}/>
                <Route path="events/*" element={<PageLayoutBlock padding_x="px-4"><EntityEventsTab group={data}
                                                                                                   group_id={id}/></PageLayoutBlock>}/>
                <Route
                    path="*"
                    element={<Navigate to="posts" replace/>}
                />
            </Routes>
        </PageLayoutSection>
    </>;

    const sidebar = <PageLayoutWrapper sticky>
        <PageLayoutSection divider={false} gap="gap-4">
            {is_organizer_or_admin && <PageLayoutBlock>
                <QuickActions items={actions} minimal show_title={false}/>
            </PageLayoutBlock>}

            <PageLayoutBlock>
                <EntityPinboard demo={false} onOpenSettings={() => {
                    setModalData({
                        starting_tab: "Pinboard"
                    })
                    setModal('manage')
                }} community_uid={community.id} type="group" id={id}/>
            </PageLayoutBlock>

            {flags.is_organizer && data && <PageLayoutBlock>
                <GettingStarted d1={{...data, id}} manifest={buildGetStartedGroupSetupManifest(data)} can_skip/>
            </PageLayoutBlock>}

            {has_calendar && <PageLayoutBlock>
                <WidgetEventsSidebar group_id={id} demo={false}/>
            </PageLayoutBlock>}


            {(flags.is_organizer || community.is_admin) && <PageLayoutBlock>
                <SuggestAddLocation onSetLocation={(location_label, location_place_id) => {
                    handleSetSpaceLocation(community, id, location_label, location_place_id)
                        .then(() => {
                            toasts.addToast({
                                text: "Location updated", intent: "success"
                            });
                            getGroup();
                        })
                }} all_names={flags.all_names || []} data={data}/>
            </PageLayoutBlock>}
            <PageLayoutBlock>
                <TextLinksFooter links={group_links} group={data}/>
            </PageLayoutBlock>
        </PageLayoutSection>
    </PageLayoutWrapper>;

    const modals = <>
        {modal === 'manage' && <GroupManageModal updateGroupData={() => {
            getGroup();
        }} starting_tab={modal_data?.starting_tab || "General"}
                                                 starting_section={modal_data?.starting_section || ""} id={id} fns={fns}
                                                 flags={flags}
                                                 onDelete={() => {

                                                 }} onSaved={() => getGroup()} group={data} onClose={() => {
            setModal(null)
            setModalData(null);
            navigate(`?`);
        }}/>}
        {modal === 'members' &&
            <GroupMembersModal starting_tab={modal_data?.starting_tab || "Members"} flags={flags} fns={fns}
                               onAddMember={handleOpenAddGroupMembers}
                               data={data} id={id} onClose={() => {
                setModal(null)
                setModalData(null);
                navigate(`?`);
            }}/>}
    </>;

    return <CommunityEntityPage card={card} sidebar={sidebar} modals={modals} header={{
        has_background: true,
        watch_scroll_id: "main",
        show_background: true,
        scroll_snap_px: 132,
        title: card_info.title,
        sticky: true,
        size: "base",
        onBack: () => {
            navigate(-1);
        },
        actions: header_actions
    }}>
        {content}
    </CommunityEntityPage>
};