import React, {useEffect, useRef, useState} from 'react';
import {Avatar} from "../../../../m3/_legacy_components/avatar";
import {
    ArrowLongDownIcon,
    EnvelopeIcon, NewspaperIcon,
} from "@heroicons/react/24/outline";

import {
    PencilIcon,
} from "@heroicons/react/24/solid";

import {DEFAULT_USER_PHOTO, useCommunity} from "../../../../config/community";
import DataWrapper from "../../../../m3/_legacy_components/data-wrapper";
import {RenderName} from "../../../../m3/_legacy_components/profile-field-renderer/value-pipeline";
import {useNavigate} from "react-router-dom";
import {ViewDraftJsContent} from "../../utilities/view-content";
import {convertFromRaw} from "draft-js";

import {
    MapPinIcon,
} from "@heroicons/react/20/solid";
import MessageAttachments from "../shared/attachments";
import dayjs from "dayjs";
import {PollLiveWrapper} from "../../../../m3/_legacy_components/poll/live-wrapper";
import EmptyState from "../../../../m3/_legacy_components/empty-state";
import {parseRawContent} from "../api";
import {
    MessageReactionsActionsWrapper,
    updateReactionData
} from "../../../../routes/community/message/reactions/actions-reactions-wrapper";
import {utils_dates_format} from "../../../../../common/utilities/dates";
import {buildImageUrl, image_utilities} from "../../../../../common/utilities/images";
function Tab({children, onClick, is_active}) {
    return <div onClick={onClick}
                className={` ${is_active ? "text-blue-600 font-semibold" : "text-gray-600 hover:text-gray-800 font-medium"} cursor-pointer text-sm`}>{children}</div>
}

const tab_items = {
    'home': [
        {
            label: 'Latest'
        },
        {
            label: 'Active'
        },
        {
            label: 'Community'
        }
    ],
    'group': [
        {
            label: 'Latest'
        },
        {
            label: 'Popular',
            is_active: true
        },
        {
            label: 'Unanswered'
        }
    ]
};

function Tabs({
                  type = 'home', onChangeTab = () => {
    }, custom = []
              }) {
    const final_tabs = [...tab_items[type]].concat(custom);
    const [active_tab, setActiveTab] = useState(final_tabs[0].label)
    useEffect(function () {
        onChangeTab(active_tab);
    }, [active_tab])
    return <div className="flex space-x-4">
        {final_tabs.map(tab => {
            return <Tab onClick={() => setActiveTab(tab.label)} is_active={tab.label === active_tab}
                        key={tab.label}>{tab.label}</Tab>
        })}
    </div>
}

function NewMessageButton() {
    const community = useCommunity();
    const navigate = useNavigate();
    return <div onClick={() => navigate(`/c/${community.domain}/write`)}
                className="h-7 w-7 flex items-center cursor-pointer justify-center hover:bg-blue-700 rounded-xl bg-blue-500 text-white">
        <div className="h-4 w-4">
            <PencilIcon/>
        </div>
    </div>
}

function Title() {
    //
    return <div className="flex items-center">
        <div className="flex-grow">
            <div className="text-2xl font-bold text-gray-800">Messages</div>
        </div>
        <div>
            <NewMessageButton/>
        </div>
    </div>
}

function Subject({
                     loading, read, handleClick = () => {
    }, subject = '', is_draft
                 }) {
    const is_empty = !subject;
    const classes = read || is_empty ? "font-semibold text-gray-500" : "font-bold text-gray-800";
    if (loading) {
        return <div className="block w-40 h-3.5 mt-1 mb-1 rounded-lg bg-gray-400"/>
    }
    if (is_empty) {
        return <div className={`${classes} cursor-pointer hover:underline text-base`}>
            (no subject)
        </div>
    }
    return <div onClick={handleClick}
                className={`${classes} line-clamp-2 tracking-tight leading-tight cursor-pointer hover:underline text-lg`}>
        {subject}
    </div>
}

function Audience({type = 'direct'}) {
    return <div>
        {type === 'direct' ? "58 members" : "2 groups"}
    </div>
}

function AuthorName({
                        loading, goToMember = () => {
    }, author_image, author_name
                    }) {
    if (loading) {
        return <div>
            <div className="block w-24 h-2.5 mt-1.5 mb-1 rounded-lg bg-gray-300"/>
        </div>
    }

    return <div className="flex space-x-1 items-center">
        {author_image&&<Avatar url={buildImageUrl(author_image, '_medium')} size="h-4 w-4"/>}
        <div onClick={goToMember}
             className="text-gray-700 cursor-pointer hover:text-gray-800 hover:underline font-medium">
            {author_name}
        </div>
    </div>
}

export function ThreadGroupNames({groups, context}) {
    if (context && context.id) {

        return <span className="cursor-pointer hover:text-gray-700 hover:underline">
            <DataWrapper id={context.id} type={`community_entities.${context.community_uid}.${context.type}s`}>
                <RenderName simple type="group"/>
            </DataWrapper>
        </span>
    }

    return <div className="cursor-pointer hover:text-gray-700 hover:underline">
        {groups ? groups : ""}
    </div>
}

function ThreadDate({loading, date_formatted = '--'}) {
    return <div>
        {loading ? <div className="block w-12 h-2.5 mt-1.5 mb-1 rounded-lg bg-gray-300"/> : date_formatted}
    </div>
}

function ReplyCount({reply_count}) {
    if (reply_count === 0) {
        return null;
    }
    return <div>
        {reply_count} {reply_count === 1 ? "Reply" : "Replies"}
    </div>
}

function ActionsContainer({children}) {
    return <div
        className="space-x-3 z-10 bg-gray-100 pl-2 rounded-r-xl items-center pr-4 hidden group-hover:flex absolute top-0 right-0 bottom-0">
        {children}
    </div>
}

function DMActions() {
    return <ActionsContainer>
        <ActionText>View Message</ActionText>
    </ActionsContainer>
}

function DraftActions() {
    return <ActionsContainer>
        <ActionText color={"text-gray-500"}>Delete</ActionText>
        <ActionText>Continue</ActionText>
    </ActionsContainer>
}

function ScheduledActions() {
    return <ActionsContainer>
        <ActionText color={"text-gray-500"}>Cancel</ActionText>
        <ActionText>Edit</ActionText>
    </ActionsContainer>
}

function AvatarWrapper({data, large}) {
    let url = buildImageUrl(data.profile_picture, '_medium', DEFAULT_USER_PHOTO);
    if (large) {
        return <Avatar url={url} style={{border: '2px solid #ffffff', marginLeft: `-6px`}} size="h-6 w-6"/>
    }
    return <Avatar url={url} size="h-4 w-4"/>
}

function ReplierAvatars({arr = [], cid, count}) {
    const excess = count - 4 > 0 ? count - 3 : 0;
    return <div className="flex space-x-0.5">
        {arr.map((it, k) => {
            if (excess > 0 && k > 2) {
                return null;
            }
            return <div key={k}>
                <DataWrapper id={it} type={`community_members.${cid}.members`}>
                    <AvatarWrapper large/>
                </DataWrapper>
            </div>
        })}
        {excess > 0 && <div style={{border: '2px solid #ffffff', zIndex: 3}}
                            className="flex h-6 w-6 bg-gray-700 rounded-md items-center justify-center">
            <div className=" text-white font-semibold" style={{fontSize: '0.7rem'}}>
                {excess}
            </div>
        </div>}
    </div>
}

function LastActivity({
                          reply_count = 0,
                          onClick,
                          last_reply_at,
                          cid,
                          repliers = [],
                          is_you = true,
                          action = 'started-thread'
                      }) {

    const replier_count = repliers.length;

    const last_reply_date = last_reply_at ? dayjs(last_reply_at).fromNow() : "a while ago";

    return <div onClick={() => {
        if (onClick) {
            onClick();
        }
    }} className="flex gap-2 cursor-pointer px-1 items-center text-sm">
        <div className="inline-flex items-center space-x-1.5">

            <ReplierAvatars cid={cid} count={replier_count} arr={repliers.slice(0, 4)}/>

            <div className="cursor-pointer">
                    <span
                        className="text-blue-700 text-xs font-semibold">{reply_count > 99 ? "99+" : reply_count} {reply_count === 1 ? "Reply" : "Replies"}</span>
            </div>
        </div>
        <div className="text-xs pt-px text-gray-500">
            Last reply {last_reply_date}
        </div>
    </div>
}

function ScheduledTime({}) {
    return <div>
        Sending tomorrow at 8am
    </div>
}

function MessageStatus() {
    return <div className="text-gray-700 font-medium">Draft</div>
}

function TitleIcons({has_image, is_muted, has_poll, has_attachment, pinned}) {
    let icons = [];
    /*
    if (has_image) {
        icons.push({
            icon: <PhotoIcon/>
        })
    }
    if (is_muted) {
        icons.push({
            icon: <XMarkIcon/>
        })
    }
    if (has_poll) {
        icons.push({
            icon: <ChartBarSquareIcon/>
        })
    }
    if (has_attachment) {
        icons.push({
            icon: <PaperClipIcon/>
        })
    }

     */
    return <div className="flex space-x-2 ml-2">
        {icons.map((ic, k) => {
            return <div className="w-4 h-4 text-gray-500" key={k}>
                {ic.icon}
            </div>
        })}
    </div>
}

function MessageType({type = 'group'}) {
    return <div>
        {type === 'direct' ? "Direct Message" : "New Thread"}
    </div>
}

function ActionText({children, onClick, color = 'text-blue-600'}) {
    return <div onClick={onClick}
                className={`font-semibold cursor-pointer hover:opacity-70 text-sm ${color} whitespace-pre`}>
        {children}
    </div>
}

function ViewThread({goToItem}) {
    return <ActionsContainer>
        <ActionText onClick={goToItem}>
            View
        </ActionText>
    </ActionsContainer>
}

function UnreadIndicator({count}) {
    if (count === 0) {
        return null;
    }
    return <div className="">
        <div className="inline-flex bg-red-500 rounded-lg px-1.5 py-0.5 text-white font-bold text-xs">
            {count > 99 ? "99+" : count}
        </div>
    </div>
}

function Topic({topic}) {
    return <div>
        {topic}
    </div>
}

function buildThreadMeta(layout, eprops, cid) {
    let arr = [];
    const is_mobile = window.innerWidth < 768;
    if (layout === 'standard') {
        arr.push({
            comp: <AuthorName goToMember={eprops.goToMember} author_image={eprops.author_image}
                              author_name={eprops.author_name}
                              loading={eprops.loading}/>,
            key: 'author'
        });
        if (!is_mobile) {
            /*
            arr.push({
                comp: !eprops.loading && <ThreadGroupNames context={eprops.context} groups={eprops.groups}/>,
                key: 'audience'
            })

             */
        }
        arr.push({
            comp: <ThreadDate date_formatted={eprops.date_formatted} loading={eprops.loading}/>,
            key: 'date'
        });

        if(eprops?.features?.show_space) {
            arr.push({
                comp: !eprops.loading && <ThreadGroupNames context={eprops.context} groups={eprops.groups}/>,
                key: 'audience'
            })
        }
        if (eprops.topic) {
            arr.push({
                comp: <Topic topic={eprops.topic}/>,
                key: 'topic'
            })
        }
    } else if (layout === 'direct') {
        arr.push({
            comp: <AuthorName goToMember={eprops.goToMember} author_name={eprops.author_name}
                              loading={eprops.loading}/>,
            key: 'author'
        });
        arr.push({
            comp: <ThreadDate date_formatted={eprops.date_formatted} loading={eprops.loading}/>,
            key: 'date'
        });
    } else if (layout === 'draft') {
        arr.push({
            comp: <MessageStatus/>,
            key: 'status'
        });
        arr.push({
            comp: <MessageType type={eprops.type} loading={eprops.loading}/>,
            key: 'type'
        });
        arr.push({
            comp: <Audience groups={eprops.groups} type={eprops.type} loading={eprops.loading}/>,
            key: 'audience'
        });
    } else if (layout === 'scheduled') {
        arr.push({
            comp: <MessageType loading={eprops.loading}/>,
            key: 'type'
        });
        arr.push({
            comp: <Audience groups={eprops.groups} type={eprops.type} loading={eprops.loading}/>,
            key: 'audience'
        });
        arr.push({
            comp: <ScheduledTime/>,
            key: 'scheduled'
        })
    } else if (layout === 'latest' || layout === 'thread') {
        if (eprops.reply_count > 0) {
            arr.push({
                comp: <LastActivity cid={cid} repliers={eprops.repliers} reply_count={eprops.reply_count}
                                    onClick={eprops.goToItem}
                                    is_you={false}/>,
                key: 'last-activity'
            })
        } else {
            arr.push({
                comp: <AuthorName goToMember={eprops.goToMember} author_name={eprops.author_name}
                                  loading={eprops.loading}/>,
                key: 'author'
            });
            if (!eprops.in_group && !is_mobile) {
                arr.push({
                    comp: !eprops.loading && <ThreadGroupNames context={eprops.context} groups={eprops.groups}/>,
                    key: 'audience'
                })
            }
            if (eprops.topic) {
                arr.push({
                    comp: <Topic topic={eprops.topic}/>,
                    key: 'topic'
                })
            }
            arr.push({
                comp: <ThreadDate date_formatted={eprops.date_formatted} loading={eprops.loading}/>,
                key: 'date'
            })
        }

    } else if (layout === 'sent') {
        if (!is_mobile) {
            arr.push({
                comp: !eprops.loading && <ThreadGroupNames context={eprops.context} groups={eprops.groups}/>,
                key: 'audience'
            })
        }
        arr.push({
            comp: <ThreadDate date_formatted={eprops.date_formatted} loading={eprops.loading}/>,
            key: 'date'
        });
        if (!is_mobile) {
            arr.push({
                comp: !eprops.loading && <ReplyCount reply_count={eprops.reply_count} loading={eprops.loading}/>,
                key: 'reply-count'
            })
        }
    }
    return arr;
}

function ReplierAvatarsThread({repliers, cid}) {
    if (!repliers || repliers.length === 0) {
        return null;
    }
    const arr = repliers.slice(0, 3);
    return <div className="flex">
        {arr.map((it, key) => <div className="" style={{zIndex: 4 - key}} key={key}>
            <DataWrapper id={it} type={`community_members.${cid}.members`}>
                <AvatarWrapper large/>
            </DataWrapper>
        </div>)}
    </div>
}

function buildRightElements(layout, eprops, cid) {
    let arr = [];
    if (layout === 'standard' && !eprops.loading) {
        arr.push(<ReplierAvatarsThread cid={cid} repliers={eprops.repliers}/>);
        arr.push(<ViewThread goToItem={eprops.goToItem}/>)
    } else if (layout === 'latest' || layout === 'thread') {
        arr.push(<UnreadIndicator count={eprops.unread_count}/>)
        arr.push(<ViewThread goToItem={eprops.goToItem}/>)
    } else if (layout === 'sent') {
        arr.push(<UnreadIndicator count={eprops.unread_count}/>)
    } else if (layout === 'draft') {
        arr.push(<DraftActions/>)
    } else if (layout === 'direct') {
        arr.push(<DMActions/>)
    } else if (layout === 'scheduled') {
        arr.push(<ScheduledActions/>)
    }
    return arr;
}

function buildAvatar(layout, eprops) {
    let a;

    if (layout === 'thread') {
        a = null;
    } else if (layout === 'draft' || layout === 'scheduled') {
        a = <div style={{borderRadius: '34%'}} className="h-10 w-10 flex items-center justify-center bg-gray-200">
            <div className="h-6 text-gray-500 w-6">
                <EnvelopeIcon/>
            </div>
        </div>
    } else {
        a = <Avatar url={buildImageUrl(eprops.author_image || DEFAULT_USER_PHOTO, '_medium')} size={"h-10 w-10"}/>
    }

    return a;
}


const max_width = 180;

// 812 width x 894 height
// height = 135
function getWidth(height, width, n = 135, mw = max_width) {
    if (height === width) {
        return `${n}px`
    }
    const ratio = width / height;
    const num = n * ratio
    if (num > mw) {
        return `${mw}px`
    } else {
        return `${num}px`
    }
}

function SingleImage({image, onOpenImage}) {
    const {height, width, url} = image;
    const style = {height: '96px', width: getWidth(height, width, 96, 96)};
    const final_url = image_utilities.adjustUrlSize(url, "200x200");
    return <div className="top-0 right-0 flex items-center justify-center absolute" style={style}>
        <div className="h-full w-full">
            <img onClick={() => onOpenImage(image)} alt="Image" src={final_url}
                 className="h-full cursor-pointer w-full object-cover rounded"/>
        </div>
    </div>
}

function Images({
                    images = [], onOpenImage = () => {
    }
                }) {
    return <div className="flex gap-2 no-scrollbars overflow-x-auto -mx-4 px-4">
        {images.map((img, key) => {
            const {height, width} = img;
            const h = '135px';
            const w = getWidth(height, width);
            return <div key={key} className="flex-none">
                <img onClick={() => onOpenImage(img)} alt="Image" width={w}
                     style={{maxWidth: '180px', minHeight: "135px", maxHeight: '135px'}} height={h} src={img.url}
                     className="rounded cursor-pointer"/>
            </div>
        })}
    </div>
}

function ThreadPolls({polls}) {
    const first_poll_id = polls[0].id;
    return <div className="">
        <PollLiveWrapper id={first_poll_id}/>
    </div>
}

function ThreadReactionsWrapper(props) {
    const community = useCommunity();
    const [data, setData] = useState({
        reactions: props.reactions,
        reactions_counts: props.reactions_counts,
        reactions_order: props.reactions_order,
        reactions_members: props.reactions_members,
    })
    const current_data = useRef({
        reactions: props.reactions,
        reactions_counts: props.reactions_counts,
        reactions_order: props.reactions_order,
        reactions_members: props.reactions_members,
    });

    useEffect(function () {
        current_data.current = data;
    }, [data])

    function updatePostReactions({reaction_id, action}) {
        const {
            new_reactions,
            new_reactions_counts,
            new_reactions_members,
            new_reactions_order
        } = updateReactionData(reaction_id, action, current_data.current, community);
        const new_object = {
            reactions: new_reactions,
            reactions_counts: new_reactions_counts,
            reactions_members: new_reactions_members,
            reactions_order: new_reactions_order
        };
        setData({
            ...data,
            ...new_object
        })
    }

    return <MessageReactionsActionsWrapper pt="pt-1" show_actions={false} getUpdatedData={() => current_data.current}
                                           post_id={props.post_id} updatePostReactions={updatePostReactions}
                                           data={data}/>
}

export function ThreadItemPreview({
                                      community_uid = "",
                                      attachments = [],
                                      images = [],
                                      raw_content,
                                      subject,
                                      author_image,
                                      author_id,
                                      group_id,
                                      author_name,
                                  }) {

    let item = {
        is_preview: true,
        attachments,
        has_attachment: attachments.length > 0,
        images,
        has_image: images.length > 0,
        community_uid,
        subject,
        author_image,
        author_id,
        author_name,

        context: {
            id: group_id,
            type: "group",
            community_uid
        },
        id: "",
        raw: {},
        date_formatted: utils_dates_format(new Date()).date_formatted,
        raw_content
    };

    return <div>
        <ThreadItem handleOpenImage={(a) => {
        }}
                    goToMember={() => {

                    }}
                    handleOpenAttachment={(a) => {
                    }}
                    goToItem={() => {
                    }} {...item} />
    </div>
}

function ThreadItem({
                        features,
                        loading,
                        is_preview,
                        is_muted = false,
                        type = 'group',
                        unread_count = 0,
                        reply_count = 0,
                        has_image,
                        context,
                        id,
                        topic,
                        goToItem,
                        goToMember,
                        author_name,
                        repliers,
                        date_formatted,
                        author_image,
                        attachments = [],
                        groups,
                        post_id,
                        community_uid,
                        has_attachment,
                        polls = [],
                        images = [],
                        reactions = {},
                        reactions_counts = {},
                        reactions_order = [],
                        reactions_members = {},
                        empty_body = false,
                        has_poll,
                        in_group,
                        last_reply_at,
                        pinned,
                        raw,
                        subject,
                        raw_content,
                        read = false,
                        handleOpenImage = () => {
                        },
                        handleOpenAttachment = () => {
                        },
                        layout = 'standard'
                    }) {
    const classes = loading ? "animate-pulse" : "";
    const [show_more, setShowMore] = useState(false);
    const eprops = {
        loading,
        type,
        in_group,
        topic,
        author_name,
        is_muted,
        groups,
        repliers,
        context,
        goToItem,
        author_image,
        has_image,
        unread_count,
        last_reply_at,
        has_attachment,
        has_poll,
        reply_count,
        goToMember,
        date_formatted,
        features
    };

    const meta = buildThreadMeta(layout, eprops, community_uid);
    //const right = buildRightElements(layout, eprops, community_uid);
    const right = [];

    function handleClick() {
        if (goToItem) {
            goToItem()
        }
    }

    const content_state = raw_content ? convertFromRaw(parseRawContent(raw_content)) : null;


    return <div className={`${classes} relative group py-2.5 sm:py-3 px-4`}>
        <div className="flex-grow">
            <div className="flex space-x-2.5 text-sm text-gray-500 flex-wrap">
                {meta.map((it, ik) => {
                    return <div key={it.id || ik}>
                        {it.comp}
                    </div>
                })}
            </div>
            <div className="flex pt-1 pb-0.5 items-center relative">
                <Subject handleClick={handleClick} subject={subject} is_draft={layout === 'draft'} read={read}
                         loading={loading}/>
                {!loading &&
                    <TitleIcons has_poll={has_poll} is_muted={is_muted} has_image={has_image}
                                has_attachment={has_attachment} pinned={pinned}/>}
                <div className="absolute top-0 right-0 flex space-x-0.5">
                    {pinned && <div className="p-0.5">
                        <div className="h-4 w-4 text-gray-600">
                            <MapPinIcon/>
                        </div>
                    </div>}
                </div>
            </div>
            {!empty_body && (images.length < 2) && <div
                style={{minHeight: images.length === 1 ? "100px" : "", paddingRight: images.length === 1 ? "7rem" : ""}}
                className={`relative`}>
                {raw_content && <div className={`${show_more ? "" : "line-clamp-4"}`}>
                    <ViewDraftJsContent text_only compact style={{}}
                                        classes='prose prose-sm text-gray-700 compact-wrapper-text'
                                        content_state={content_state}/>
                </div>}
                {images.length === 1 && <SingleImage onOpenImage={handleOpenImage} image={images[0]}/>}
            </div>}
            {polls && polls.length > 0 && <div className="pt-1.5">
                <ThreadPolls polls={polls}/>
            </div>}
            {images.length > 1 && <div className="pt-1.5">
                <Images onOpenImage={handleOpenImage} images={images}/>
            </div>}
            {attachments.length > 0 && <div className="pt-1.5">
                <MessageAttachments onOpenAttachment={handleOpenAttachment} attachments={attachments}/>
            </div>}

            {!is_preview && <ThreadReactionsWrapper reactions={reactions} key={id}
                                                    reactions_counts={reactions_counts}
                                                    reactions_order={reactions_order}
                                                    reactions_members={reactions_members}
                                                    data={raw} post_id={id}/>}

            {eprops.reply_count > 0 &&
                <div className="pt-1"><LastActivity last_reply_at={last_reply_at} cid={community_uid}
                                                    repliers={eprops.repliers} onClick={eprops.goToItem}
                                                    reply_count={eprops.reply_count}
                                                    is_you={false}/></div>}

        </div>
        <div className="pr-1.5 ">
            {right.map((r, k) => {
                return <div key={k}>
                    {r}
                </div>
            })}
        </div>
    </div>;
}

function ThreadsWrapper({children}) {
    return <div className="divide-y divide-gray-200">
        {children}
    </div>
}

function LoadingInline() {
    return <div className="px-1.5 mt-1 space-x-2 flex py-1 text-gray-500">
        <div className="w-10 h-5 flex items-center justify-center">
            <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                 fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                        strokeWidth="4"/>
                <path className="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
            </svg>
        </div>
        <div className=" font-medium text-sm">Loading..</div>
    </div>
}

function ShowMore({onClick}) {
    return <div onClick={onClick}
                className="px-1.5 mt-1 space-x-2 flex py-1 cursor-pointer hover:text-gray-700 text-gray-500">
        <div className="w-10 h-5 flex items-center justify-center">
            <div className="w-4 h-4">
                <ArrowLongDownIcon/>
            </div>
        </div>
        <div className=" font-medium text-sm">Show more</div>
    </div>
}

function getInitialShowCount(item_size = 54, max = 15) {
    let h = window.innerHeight;
    if (h) {
        let a = window.innerHeight - 440;
        let b = Math.ceil(a / item_size);
        if (b > max) {
            return max
        } else {
            return b;
        }
    }
    return 6;
}

export function ThreadsView({
                                features = {},
                                goToMember = () => {
                                },
                                handleOpenImage = () => {
                                },
                                handleOpenAttachment = () => {
                                },
                                is_loading, showMore, goToItem = () => {
    }, has_more, onChangeTab, items = [], topics = [], type = 'home'
                            }) {
    const [show_count, setShowCount] = useState(getInitialShowCount());
    const [loading, setLoading] = useState(false);
    let custom = topics.map(t => {
        return {
            label: t.label
        };
    });

    useEffect(function () {
        setLoading(false);
    }, [items])

    function handleLoadMore() {
        setLoading(true);
        showMore();
    }

    return <div>
        <ThreadsWrapper>
            {items.map((it, k) => <div className="" key={it.id}>
                <ThreadItem key={it.id} handleOpenImage={(a) => handleOpenImage(a, it?.raw?.data || {}, it.id)}
                            goToMember={() => {
                                goToMember(it.author_id || it.raw.author_id || "");
                            }} features={features}
                            handleOpenAttachment={(a) => handleOpenAttachment(a, it?.raw?.data || {}, it.id)}
                            goToItem={() => goToItem(it)} {...it} />
            </div>)}
            {items.length === 0 && !is_loading && <EmptyState title="No Posts Found" icon={<NewspaperIcon/>}/>}
            {has_more && !loading && <div>
                <ShowMore onClick={() => handleLoadMore()}/>
            </div>}
            {loading && <div>
                <LoadingInline/>
            </div>}
            {is_loading && items.length === 0 && <div>
                <LoadingInline/>
            </div>}
        </ThreadsWrapper>
    </div>
}