import React, {useState} from "react"
import './styles.css';
import {CogIcon, MapPinIcon, UserIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {Avatar} from "../avatar";
import Button from "../button";
import {ChevronDownIcon} from "@heroicons/react/24/solid";
import {UniversalMeta} from "../entity-profile-card";
import SimpleTooltip from "../tooltip";
import {BlocksPopupContent} from "../../../routes/community/write/widgets/popup";
import {TextAction} from "../../../routes/auth/sign-in";
import {ContentPopupMenu} from "../content-popup/components";
import ContentPopup from "../content-popup";
import {Badge} from "../user-email-manager";

const mock_top = {
    type: "breadcrumbs",
    crumbs: [
        {
            to: "",
            label: "Membership Levels"
        },
        {
            to: "",
            label: "Alte Herren"
        }
    ],
    name: "Membership Level",
    icon: <UserIcon/>
};

function ArrowAction({children, disabled, onClick}) {
    const cl = disabled ? "text-gray-200 cursor-not-allowed" : "text-gray-500 cursor-pointer hover:text-gray-800";
    return <div onClick={() => {
        if (onClick) {
            onClick();
        }
    }} className={`${cl} w-6 h-6 rounded-full flex items-center justify-center hover:bg-gray-100 `}>
        <div className="w-5 h-5">
            {children}
        </div>
    </div>
}

function TopEntityIcon({icon, name}) {
    return <div className="flex space-x-1.5 items-center text-gray-500">
        <div className="w-3.5 h-3.5">{icon}</div>
        <div className="text-xs font-medium">{name}</div>
    </div>
}

function TopText({text}) {
    return <div className="flex space-x-1.5 items-center text-gray-500">
        <div className="text-xs font-medium">{text}</div>
    </div>
}

function TopBreadcrumb({crumbs}) {

    return <div className="flex space-x-1.5 text-xs font-medium items-center text-gray-500">
        {crumbs.map((cr, index) => {
            const is_last = crumbs.length === index + 1;
            return <div className="flex" key={index}>
                <div className="hover:underline cursor-pointer">{cr.label}</div>
                {!is_last && <div className="pl-1.5 text-gray-400">/</div>}
            </div>
        })}
    </div>
}

function Top({onClose, icon, name, crumbs, text, type}) {

    if (!type) {
        return <div className="absolute top-4 right-4">
            <ArrowAction onClick={() => onClose()}>
                <XMarkIcon/>
            </ArrowAction>
        </div>
    }
    return <div className="flex items-center pt-1 pb-1">
        <div className="flex-grow ">
            {type && <div>
                {type === 'entity-icon' && <TopEntityIcon icon={icon} name={name}/>}
                {type === 'text' && <TopText text={text}/>}
                {type === 'breadcrumbs' && <TopBreadcrumb crumbs={crumbs}/>}
            </div>}
        </div>
        <div>
            <ArrowAction onClick={() => onClose()}>
                <XMarkIcon/>
            </ArrowAction>
        </div>
    </div>
}

function Subtitle({children}) {
    return <div className="text-base text-gray-700 font-medium">
        {children}
    </div>
}

function Title({children, size}) {
    return <div className={`${size} pt-px tracking-tight font-bold text-gray-800`}>
        {children}
    </div>
}

function Actions({actions}) {
    if (!actions) {
        return null;
    }
    return <div className="flex space-x-2">
        {actions.map((act, key) => {
            return <div key={key}>
                {act.button}
            </div>
        })}
    </div>
}

function ActionsDropdown({actions = []}) {
    const [open, setOpen] = useState(false);

    const content = <div
        className="w-72 bg-white content-popup-shadow max-w-xs rounded-lg border border-gray-200">
        <ContentPopupMenu items={actions}/>
    </div>

    return <SimpleTooltip trigger='click' onOpen={() => setOpen(true)} onClose={() => setOpen(false)} interactive simple
                          text={content} placement='bottom-end'>
        <Button dropdown text="Actions" intent="dark" right_icon={<ChevronDownIcon/>}/>
    </SimpleTooltip>
}

export default function MetaHeader({
                                       tags = [],
                                       tabs, size = "text-xl",
                                       fixed_translucent, divider = true,
                                       top, onClose = () => {
    }, image, can_close = true, title = "", meta = [], subtitle, actions, actions_dropdown = []
                                   }) {
        {meta.length > 0 && <UniversalMeta clean meta={meta}/>}
    return <div className={""}>
        {(top || can_close) && <Top {...top} can_close={can_close} onClose={onClose}/>}
        <div className="flex pt-1">
            <div className="flex-grow flex space-x-3 items-center">
                {image && <div>
                    <Avatar url={image} size="h-8 w-8"/>
                </div>}
                <div>
                    {title && <div className="flex items-center">
                        <Title size={size}>{title}</Title>
                        {tags.length > 0 && <div className="pl-1 flex space-x-2">
                            {tags.map((tag, index) => {
                                return <div key={index} className="ml-2">
                                    <Badge color={tag.color}>{tag.title}</Badge>
                                </div>
                            })}
                        </div>}
                    </div>}
                    <div className="pt-0.5">

                        {subtitle && <Subtitle>{subtitle}</Subtitle>}
                    </div>
                </div>
            </div>
            {(actions&&actions.length>0)||actions_dropdown.length>0&&<div className="flex space-x-2">
                <Actions actions={actions}/>
                {actions_dropdown.length > 0 && <ActionsDropdown actions={actions_dropdown}/>}
            </div>}
        </div>
        {tabs && <div className="pt-1">
            {tabs}
        </div>}
    </div>
};