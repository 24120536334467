import React, {useState} from 'react';
import {table_of_contents_mock} from './mocks';
import './styles.css';
import {ChevronDownIcon, ChevronRightIcon, PlusIcon} from "@heroicons/react/24/outline";
import {ArrowDownIcon, LockClosedIcon} from "@heroicons/react/24/solid";
import {UniversalIcon} from "../entity-card";

function Title({title, onTitleClick}) {
    const clickable = !!onTitleClick;
    return <div onClick={() => {
        if (onTitleClick) {
            onTitleClick();
        }
    }} className={`text-[0.85rem] font-medium tracking-tight text-gray-500 transition-colors ${clickable ? "cursor-pointer hover:text-gray-700" : ""}`}>
        {title}
    </div>
}

function SmallItem({label, color, count, isActive}) {
    const active = isActive();
    let style = {

    };
    if(active) {
        style.color = color;
    }
    return <div style={{color}} className="py-0.5 mb-px group flex">
        <div style={style} className={`${active ? "font-semibold" : "toc-label-text"} flex-grow text-[0.95rem] cursor-pointer transition-colors`}>
            {label}
        </div>
        {count&&<div className="flex-none text-[0.95rem]">{count}</div>}
    </div>
}

function ToggleIcon({open, onClick}) {
    return <div onClick={onClick} className="h-3.5 w-5">
        <div className="h-3.5 w-3.5">
        {open ? <ChevronDownIcon style={{marginTop: '1px'}}/> : <ChevronRightIcon style={{marginTop: '1px'}}/>}
        </div>
    </div>
}

function ItemLabel({active, color, label}) {
    let style = {
        maxWidth: '204px',
        paddingTop: '2px'
    };

    if(active) {
        style.color = `${color}`;
    }

    return <div style={style} className={`${active ? "font-bold" : ""} toc-label-text group-hover:text-black-900 truncate transition-colors text-[0.95rem]`}>{label}</div>
}

function Item({label, onClick, u_icon, format = 'standard', left_icon, can_expand, count, open, isActive=()=>{}, color, items}) {
    const [expanded, setExpanded] = useState(!!open);
    const active = isActive();

    return <div className="group" style={{minWidth:'10rem'}}>
        <div className={`flex ${format==='standard'?"items-baseline":"items-center "} space-x-2 h-6`}>
            <div className={`w-5 h-5 flex items-center text-gray-600 ${active?"":"group-hover:text-gray-900"} transition-colors transition-opacity cursor-pointer`}>
                {can_expand&&format==='standard'&&<ToggleIcon onClick={() => setExpanded(!expanded)} open={expanded}/>}
                {!can_expand&&format!=='icon_expanded'&&<div className="h-3.5 w-3.5">

                </div>}
                {format==='icon_expanded'&&left_icon&&<div className="h-5 w-5">
                    {left_icon}
                </div>}
                {u_icon&&<div className="flex items-center pt-[3px]">
                    <UniversalIcon {...u_icon} />
                </div>}
            </div>
            <div onClick={()=>{
            if(onClick) {
                onClick();
            }
            if(!expanded&&can_expand) {
                setExpanded(true);
            }
            }} className="flex relative flex-grow items-baseline cursor-pointer">
                <div className="flex-grow items-baseline flex">
                    <ItemLabel label={label} color={color} active={active}/>
                </div>
                <div>
                    <div style={{maxWidth: '36px'}} className="text-sm pl-2 truncate text-gray-500">
                        {count}
                    </div>
                </div>
            </div>

        </div>
        {expanded && can_expand && items && items.length>0 && <div className={` pl-7 pt-1.5`}>
            {items.map((item,ikey) => <SmallItem key={`${item.id||ikey}`} color={color} {...item} />)}
        </div>}
    </div>
}

function ShowMore({onClick,label}) {
    return <div className="group cursor-pointer" onClick={onClick}>
        <div className={`flex items-center h-6 space-x-1.5`}>
            <div className="h-3.5 w-3.5">
                <ArrowDownIcon />
            </div>
            <ItemLabel label={label}/>
        </div>
    </div>
}

function Section({children}) {

    return <div className="mb-8 flex-none">
        {children}
    </div>
}

function AddButton({onAdd}) {

    return <div onClick={() => {
        if (onAdd) {
            onAdd();
        }
    }}
                className="rounded-md h-5 w-5 text-gray-400 hover:bg-gray-200 hover:text-gray-600 transition-colors cursor-pointer">
        <PlusIcon/>
    </div>
}

export function TableOfContents({sticky,no_top_padding,sections=[...table_of_contents_mock], color = "#000000"}) {
    const [expand,setExpand] = useState({});
    return <div className={`w-full user-select-none ${sticky?"sidebar-height overflow-y-auto":""}`}>
        <div className={`${no_top_padding?"":"pt-4"} flex flex-col gap-2 overflow-x-auto`}>
        {sections.map(section => {
            const max_show = expand[section.key] ? 99 : 9;

            const can_show_more = section.items.length>max_show;

            return <Section key={section.key}>
                {(section.can_add||section.title)&&<div className="pb-1 flex">
                    <div className="flex-grow">
                        <Title {...section} />
                    </div>
                    <div>
                        {section.can_add && <AddButton {...section} />}
                    </div>
                </div>}
                <div className={`${section.spacing||"space-y-1"}`}>
                    {section.items.map((item,itin) => {
                        if(itin+1>max_show) {
                            return null;
                        }
                        return <Item key={`${item.id||itin}`} color={color} format={section.format||"standard"} {...item} />
                    })}
                    {can_show_more&&!expand[section.key]&&<ShowMore onClick={()=>setExpand({...expand,[section.key]:true})} label="Show more" />}
                </div>
            </Section>
        })}
        </div>
    </div>
}