import React from "react";

export function WB_CoreNumberedList({}) {
    return <div className="prose prose-base">
<ol className="">
    <li>One</li>
    <li>Two</li>
    <li>Three</li>
</ol>
    </div>
}