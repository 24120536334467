import React from "react"
import './styles.css';
import PinboardBadges from "./blocks/badges";
import PinboardLinks from "./blocks/links";
import PinboardImages from "./blocks/images";
import PinboardText from "./blocks/text";

export function PinboardBlockWrapper({title, children}) {
    return <div className="flex" style={{maxWidth: 'inherit'}}>
        <div className="flex-none max-w-[8rem] pr-4 min-w-[8rem] leading-none">
            <span className="text-gray-500 text-sm">{title}</span>
        </div>
        <div className="flex-grow pt-px -mb-0.5 overflow-hidden">
            {children}
        </div>
    </div>
}

export default function Pinboard({
                                     sections = []
                                 }) {

    function handleSelect(id, data) {
        if (data.type === "external-link") {
            window.open(data.url, "_blank", "noopener noreferrer");
        }
    }

    return <div className="grid gap-3">
        {sections.map(function (section) {
            return <div key={section.id}>
                {section.type === "badges" && <PinboardBadges fill badges={section.data}/>}
                {section.type === "links" &&
                    <PinboardLinks onSelect={handleSelect} title={section.label} links={section.data}/>}
                {section.type === "images" && <PinboardImages title={section?.label} images={section.data}/>}
                {section.type === "text" && <PinboardText title={section?.label} data={section.data}/>}
            </div>
        })}
    </div>
};