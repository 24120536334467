import {getPhoneNumberData} from "../../app/m3/utilities/phone";

export function standard_formatPhoneNumberValue(value='',country="", return_data) {
    const data = getPhoneNumberData(value, country);

    if(return_data) {
        return data;
    }

    return data.display;
}