import React, {useEffect, useState} from 'react';
import {LayoutComplex, LayoutFull} from "../../../m3/_legacy_components/app-frame/layouts";
import Button from "../../../m3/_legacy_components/button";
import {UnaverseSectionHeader} from "./unaverse";
import {useNavigate} from "react-router-dom";
import {buildTableColumns, buildTableConfig, SharedUsersTable, SimpleQueryBar} from "../shared/users-table";

const info = {
    columns: [
        "name",
        "id",
        "handle",
    ],
    spec: {
        "id": {
            label: "ID",
            type: "id",
            required: true
        },
        "name": {
            label: "Name",
            type: "title-subtitle-image",
            meta: {
                title: "name",
                subtitle: "account_email",
                image: "profile_picture",
                image_color: "profile_picture_color",
            },
            required: true
        },
        "handle": {
            label: "Handle",
            type: "handle",
            required: true
        },
        "account_email": {
            label: "Account Email",
            type: "string",
            required: true
        },
    }
};

export function ManageUsersPage({can_create = true}) {
    const navigate = useNavigate();

    const [query, setQuery] = useState("");

    function onEditMember(member) {
        if(member&&member.id) {
            navigate(`/manage/user/${member.id}`)
        }
    }

    const table_config = buildTableConfig(info, query, onEditMember, navigate);

    return <LayoutFull>
        <div className="space-y-4">
            <UnaverseSectionHeader
                title={"Users"}
                subtitle={"All people signed up for Unaty"}
                actions={<div className="flex space-x-2">
                    <SimpleQueryBar onStartSearch={(q) => {
                        setQuery(q);
                    }} onClear={() => {
                        setQuery('');
                    }} onToggleLoading={() => {

                    }} handleChange={() => {
                    }}/>
                    {can_create && <div>
                        <Button intent="primary" onClick={() => {
                        }}>Create</Button>
                    </div>}
                </div>}
            />

            <SharedUsersTable table_config={table_config} query={query} />
        </div>
    </LayoutFull>
}