import {operators} from "./filtering-utilities";
import memoize from 'lodash/memoize';
import {onSnapshot} from "firebase/firestore";
import {authFetch} from "../../../../api/network";

function filterIsValid(fi, field_info) {
    return operators[field_info.type].filter(a => a.value === fi.operator)[0].isValid(fi.value);
}

function buildValue(value, type, operator) {
    return operators[type].filter(a => a.value === operator)[0].getQueryValue(value);
}

function checkField(field, operator) {
    if (field === 'cf_number_primary') {
        return field;
    } else if (field === 'cf_number_secondary') {
        return field;
    } else if (field.startsWith('address.')) {
        return field.split('address.')[1];
    } else if (field.startsWith('about.')) {
        return field.split('about.')[1];
    } else if (field.startsWith('stats.')) {
        return field.split('stats.')[1];
    } else if (field.startsWith('custom_fields.')) {
        if (operator === 'empty' || operator === 'not_empty') {
            return 'custom_fields_exist';
        }
        return 'custom_fields';
    } else if (field.startsWith('social.')) {
        if (operator === 'empty' || operator === 'not_empty') {
            return 'social_exist';
        }
        return 'social';
    }
    return field;
}

function checkValue(v, field, operator) {
    if (field.startsWith('custom_fields.')) {

        if (operator === 'empty' || operator === 'not_empty') {
            return `${field.split('_fields.')[1]}`;
        }

        if (typeof v === 'object') {
            // array
            if (Array.isArray(v)) {
                return v.map(a => {
                    return `${field.split('_fields.')[1]}=${a}`
                });
            } else {

                return v;
            }

        }
        return `${field.split('_fields.')[1]}=${v}`;
    }
    return v;
}

function getFinalJoin(index, join, f) {
    const len = f.length;

// there's a case where there's only
    if (len > 1 && index === 0 && f[1].join === 'or') {
        return 'or';
    }

    return join
}

function notYetWorking(ff, operator) {
    if (ff.startsWith('custom_fields.') && (operator === '>' || operator === '<' || operator === '>=' || operator === '<=')) {
        return true;
    }
    return false;
}

function buildFilters(view, defs, options, community, query_meta) {
    const f = view.filters;

    let obj = {
        all: [],
        any: [],
        none: [],
    };

    if (!f) {
        return obj;
    }

    if(query_meta && query_meta._advanced_active) {
        if(query_meta.location) {
            obj.all.push({
                'location': query_meta.location
            });
        }
        if(query_meta.member_type) {
            obj.all.push({
                'subtype': query_meta.member_type
            });
        }
        if(query_meta.occupation) {
            obj.all.push({
                'occupation': query_meta.occupation
            });
        }
        if(query_meta.industry) {
            obj.all.push({
                'industry': query_meta.industry
            });
        }
    }

    // only show deactivated if enabled or it's explicity listed by a filter
    if (!view.show_deactivated_members && f && f.filter(a => a.field === 'account_status').length === 0) {
        obj.none.push({'account_status': "archived"})
    }

    if (options && options.group_id) {
        obj.all.push({'groups': options.group_id})
    }

    f.forEach((fi, index) => {
        const {field, join, value, operator} = fi;
        const field_info = defs.filter_options[field];
        console.log("FIELD INFO", field_info)
        if (!field_info) {
            console.error('no field info for', field, fi)
            return;
        }
        let filter_field;
        if (field.startsWith('custom_fields.') && field_info.type === 'number') {
            if (community.cf_number_primary && field.includes(community.cf_number_primary)) {
                filter_field = 'cf_number_primary';
            } else if (community.cf_number_secondary && field.includes(community.cf_number_secondary)) {
                filter_field = 'cf_number_secondary';
            }
        } else {
            filter_field = field;
        }
console.log("ADDING FILTER", filter_field, operator, value)
        const {type} = field_info;

        const is_valid = filterIsValid(fi, field_info);

        const operator_info = operators[field_info.type].filter(a => a.value === operator)[0];

        console.log("OPERATOR INFO", operator_info)
        const {query_type} = operator_info;

        const final_join = getFinalJoin(index, join, f);

        if (is_valid) {
            // todo we have a grouping problem (a + (b+c) ) or (a+b) + c
            if (final_join === 'and') {
                if (filter_field.startsWith('custom_fields.') && operator === 'empty') {
                    obj.none.push({
                        ['custom_fields_exist']: filter_field.split('_fields.')[1]
                    });
                } else if (filter_field.startsWith('custom_fields.') && operator === 'not_empty') {
                    obj.all.push({
                        ['custom_fields_exist']: filter_field.split('_fields.')[1]
                    });
                } else if (filter_field.startsWith('social.') && operator === 'empty') {
                    obj.none.push({
                        ['social_exist']: filter_field.split('cial.')[1]
                    });
                } else if (filter_field.startsWith('social.') && operator === 'not_empty') {
                    obj.all.push({
                        ['social_exist']: filter_field.split('cial.')[1]
                    });
                } else if (query_type === 'none') {
                    obj.none.push({
                        [checkField(filter_field, operator)]: checkValue(buildValue(value, type, operator), filter_field, operator)
                    });
                } else {
                    obj.all.push({
                        [checkField(filter_field, operator)]: checkValue(buildValue(value, type, operator), filter_field, operator)
                    });
                }

            } else {
                obj.any.push({
                    [checkField(field, operator)]: checkValue(buildValue(value, type, operator), field, operator)
                });
            }
        }
    })

    return obj;
}

/*
[
    {
        "dir": "asc",
        "type": "text",
        "field": "about.last_name"
    },
    {
        "field": "stats.sessions",
        "dir": "asc"
    }
]
 */

const sort_field_map = {
    "about.first_name": "first_name",
    "last_sign_in": "last_sign_in",
    "profile_updated_at": "profile_updated_at",
    "stats.sessions": "sessions",
    "about.last_name": "last_name",
    "age": "age",

};

function buildSorts(view) {
    const sort = view.sort;

    if (!sort) {
        return {
            last_name: "asc"
        }
    } else if (sort.length === 0) {
        return {
            last_name: "asc"
        }
    } else if (sort.length === 1) {

        const sit = sort[0][0];
        const it = sort_field_map[sit.field];

        return {
            [it]: sit.dir
        }
    }
    return sort.map(s => {
        return {
            [sort_field_map[s.field]]: s.dir
        }
    })
}


/*
Examples
"location:"san diego""
"occupation:engineer"
"industry:software"
"location:"new york" occupation:engineer"
"location:"new york" occupation:engineer industry:software"
 */

function cleanText(v) {
    return v.replace(/"/g, '');
}

const supported_fields = {
    "location": {
        parseValue: (v) => {
            // clear
            return cleanText(v);
        }
    },
    "occupation": {
        parseValue: (v) => {
            return cleanText(v);
        }
    },
    "industry": {
        parseValue: (v) => {
            return cleanText(v);
        }
    },
    "member_type": {
        parseValue: (v) => {
            return cleanText(v);
        }
    }
}
function getQueryMeta(q = ``, community) {
    let results = {
        location: "",
        occupation: "",
        industry: "",
        member_type: "",

        _cleaned_query: q,

        _advanced_active: false
    };

    if (!q) {
        return results;
    }

    // split by space not in quotes or parentheses
    const parts = q.match(/(?:[^\s"]+|"[^"]*")+/g);

    if (!parts) {
        return results;
    }

    parts.forEach(p => {
        let [field, value] = p.split(':');

        if(supported_fields[field.toLowerCase()]) {
            results[field.toLowerCase()] = supported_fields[field.toLowerCase()].parseValue(value);
            results._advanced_active = true;

            // remove from query
            results._cleaned_query = results._cleaned_query.replace(p, '');
        }
    });

    results._cleaned_query = results._cleaned_query.trim();

    return results;
}

function buildSearchFields(q, view, defs, options, community) {
    if (!q) {
        return {
            fields: {
                name: {}
            },
            filters: buildFilters(view, defs, options, community, null),
            query: ""
        }
    }

    const query_meta = getQueryMeta(q,community);

    if(query_meta._advanced_active) {
        let a = {
            name: {},
        };

        Object.keys(query_meta).forEach(k => {
            if(k !== '_advanced_active' && k !== "_cleaned_query" && query_meta[k] !== "") {
                a[k] = {};
            }
        })

        return {
            fields: a,
            filters: buildFilters(view, defs, options, community, query_meta),
            query: query_meta._cleaned_query
        };
    }

    return {
        filters: buildFilters(view, defs, options, community, query_meta),
        fields: {
            name: {},
            location: {},
            occupation: {},
            email: {},
            first_name: {},
            last_name: {}
        },
        query: query_meta._cleaned_query
    }
}

function processResults(arr) {
    return arr.map(a => {
        return {
            ...a,
            id: a.id.split('-')[1]
        }
    })
}

function promiseTimeout(timeout, callback) {
    return new Promise((resolve, reject) => {
        // Set up the timeout
        const timer = setTimeout(() => {
            reject(new Error(`Promise timed out after ${timeout} ms`));
        }, timeout);

        // Set up the real work
        callback(
            (value) => {
                clearTimeout(timer);
                resolve(value);
            },
            (error) => {
                clearTimeout(timer);
                reject(error);
            }
        );
    });
}

export async function waitForResult(ref,timeout=1200) {
    let a;
    try {
        a = await promiseTimeout(timeout,(resolve,reject)=>{
            onSnapshot(ref, (doc) => {
                if (doc.exists && doc.data() && doc.data().response !== null) {
                    // Do things
                    resolve({ok:'yes',...doc.data().response});
                } else if (!doc.exists) {
                    resolve({ok:'no',results: [], page: {}});
                }
            });
        })
    } catch(e) {
        a = {ok:'no'};
    }

    return a;
}

export const direct_queryContent = memoize(async (request) => {
    return new Promise(resolve => {
        const payload = {
            request,
            engine: 'content'
        };
        const r = (resp) => {
            if (!resp || !resp.data || !resp.data.data) {
                resolve({results: []});
                return;
            }
            resolve(resp.data.data);
        };
        authFetch('/searches', r, r, "POST", {payload});
    });
})

export const queryGroupsData = memoize(async (request, community_uid) => {

    // TEMP
    return new Promise(resolve => {
        const payload = {
            request, type: 'groups',
            engine: 'content', community_uid
        };
        const r = (resp) => {
            if (!resp || !resp.data || !resp.data.data) {
                resolve({results: []});
                return;
            }
            resolve(resp.data.data);
        };
        authFetch('/searches', r, r, "POST", {payload});
    });
});

export const queryMembersData = memoize(async (request, community_uid) => {

    // TEMP
    return new Promise(resolve => {
        const payload = {
            request, type: 'members',
            engine: 'standard', community_uid
        };
        const r = (resp) => {
            if (!resp || !resp.data || !resp.data.data) {
                resolve({results: []});
                return;
            }
            resolve(resp.data.data);
        };
        authFetch('/searches', r, r, "POST", {payload});
    });
});

/*
        const col = collection(getFirestore(), "searches");

       const ref = await addDoc(col, {
           type: 'members',
           engine: 'standard',
           community_uid: community_uid,
           request,
           response: null
       });

       const nref = doc(col,ref.id);

       return await waitForResult(nref);

    */

export async function directoryMembersQuery(request, community_uid) {
    return await queryMembersData(request, community_uid)
        .then(resp => {

            if (resp.results) {
                return {
                    members: [...processResults(resp.results)],
                    page: resp.page
                }
            } else {
                return null
            }
        });
}

function getDefaultSize(view) {
    const layout = view.layout;
    const height = document.body.clientHeight;
    if (layout === 'gallery') {
        return (Math.ceil(height / 280) + 1) * 6;
    }
    return Math.ceil(820 / 40 * 1.5);
}

function fixPage(page) {
    if(isNaN(page)) {
        return 0;
    } else if (page === 0) {
        return 1;
    } else if(page) {
        return page + 1;
    } else {
        return 0;
    }
}

function buildResultFields() {
    let a = {
        name: {raw: {}},
        phone: {raw: {}},
        email: {raw: {}},
        location: {raw: {}},
        occupation: {raw: {}},
        city: {raw: {}},
        account_status: {raw: {}},
        membership_status: {raw: {}},
        member_type: {raw: {}},
        profile_picture: {raw: {}},
        handle: {raw: {}},
    };

    return a;
}

// https://www.elastic.co/guide/en/app-search/current/filters.html
export function buildElasticsearchQuery(view, squery = "", defs, page, options = {}, community) {
    let size = options.size || getDefaultSize(view);

    // phone, email, bio, location, occupation, country, city, state,

    const search_data= buildSearchFields(squery, view, defs, options, community);

    let request = {
        current: fixPage(page),
        size: size,
        result_fields: {
            name: {raw: {}},
            phone: {raw: {}},
            email: {raw: {}},
            location: {raw: {}},
            occupation: {raw: {}},
            city: {raw: {}},
            account_status: {raw: {}},
            membership_status: {raw: {}},
            member_type: {raw: {}},
            profile_picture: {raw: {}},
            handle: {raw: {}},
        },
        search_fields: search_data.fields,
        sort: buildSorts(view),
        query: search_data.query,
        filters: search_data.filters,
    };

    return request;
}