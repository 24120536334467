import React, {useState} from 'react';
import {prepareExperiences} from "./utilities";
import styled from "styled-components";
import {DEFAULT_COMMUNITY_LOGO} from "../../../../config/community";
import Button from "../../../../m3/_legacy_components/button";
import {EditCareerModal} from "../career/edit";
import {EditEducationModal} from "../education/edit";


const Wrapper = styled.div`
width: 100%;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 16px;
        margin-bottom: 2px;
`;

const SmallTitle = styled.div`
    font-weight: 500;
    font-size: 15px;
        margin-bottom: 2px;
         line-height: 1.3;
`;

const Subtitle = styled.div`
font-size: 15px;
    line-height: 1.5;
        margin-bottom: 2px;
`;

const TitleMeta = styled.div`
 font-weight: 500;
     line-height: 1.3;
    font-size: 15px;
`;

const Meta = styled.div`
    line-height: 1.5;
        margin-bottom: 2px;
        font-size: 13px;
`;

const GHWrapper = styled.div`
`;

const Description = styled.div`
font-size: 15px;
padding-top: 4px;
    padding-bottom: 14px;
`;

const PositionsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    padding-bottom: ${props => !props.last ? '20px' : '0px'};
    grid-gap: 12px;
`;

const MiniPosWrapper = styled.div`
position: relative;
    > span.pdot {
            width: 8px;
    height: 8px;
    position: absolute;
    background: #d9d9d9;
    border-radius: 50%;
    left: -42px;
    top: 6px;
    }
    > span.pline {
        position: absolute;
    left: -39px;
    background: #d9d9d9;
    width: 2px;
    top: 18px;
    bottom: -12px;
    }
`;

const Experiences = styled.div`

    > div:not(:last-child) .child-bottom-border {
     
           border-bottom: 1px solid #efefef;
    }
`;

function GroupedHeader({title = 'Company Name', duration = '1 yr 4 mos'}) {
    return <GHWrapper>
        <Title className='text-gray-900'>{title}</Title>
        <TitleMeta className='text-gray-800'>{duration}</TitleMeta>
    </GHWrapper>
}

function MiniPosition({title, is_last, description, meta, meta_alt}) {
    return <MiniPosWrapper is_last={is_last}>
        <span className='pdot'/>
        {!is_last && <span className='pline'/>}
        <SmallTitle className='text-gray-900'>{title}</SmallTitle>
        <Meta className='text-gray-700'>{meta}</Meta>
        <Meta className='text-gray-700'>{meta_alt}</Meta>
        <Description className='text-gray-800'>
            {description}
        </Description>
    </MiniPosWrapper>
}

const PosWrapper = styled.div`
padding-bottom: ${props=>props.is_last?'0px':''};
`;

function Position({title, is_last, description, subtitle, meta, meta_alt}) {
    return <PosWrapper is_last={is_last}>
        <Title className='text-gray-900'>{title}</Title>
        <Subtitle className='text-gray-800'>{subtitle}</Subtitle>
        <Meta className='text-gray-700'>{meta}</Meta>
        <Meta className='text-gray-700'>{meta_alt}</Meta>
        <Description className='text-gray-800'>
            {description}
        </Description>
    </PosWrapper>
}

function buildMeta(start, end, dur) {
    if(!start&&!end) {
        return ``;
    } else if(!start) {
        return `${end}`;
    } else if(!end) {
        return `${start}`;
    }
    if(dur) {
        return `${start} — ${end} • ${dur}`;
    }
    return `${start} — ${end}`;
}

function getImage(img) {
    return img === '' ? DEFAULT_COMMUNITY_LOGO : img;
}

const Logo = styled.div`
  height: 44px;
    width: 44px;
    border-radius: 42%;
    background-color: #efefef;
  background-image: ${props => `url(${props.image})`};
      background-position: center;
    background-size: cover;
`;

const ExperienceWrapper = styled.div`
display: grid;
    grid-template-columns: 44px 1fr;
    padding-top: ${props => !props.first ? '20px' : ''};
    
    grid-gap: 16px;
`;

const PositonWrapper = styled.div`
position: relative;
> button {
position: absolute;
    top: 8px;
    right: 0;
}

`;

function Experience({data, num, can_add, startEditing}) {
    const {org_data, duration_text, positions} = data;

    const len = positions.length;

    const multi = len > 1;

    return <ExperienceWrapper last={(num+1)===len} first={num === 0}>
        <div>
            <Logo image={getImage(org_data.image)} color={org_data.color}/>
        </div>
        <PositionsWrapper last={(num+1)===len} className='child-bottom-border'>
            {multi && <GroupedHeader title={org_data.title} duration={duration_text}/>}
            {multi && positions.map((pos, i) => {
                return <PositonWrapper is_last={(i+1)===len} key={i}>
                    <MiniPosition is_last={(i + 1) === len} key={i} title={pos.title} description={pos.description}
                                  meta={buildMeta(pos.start, pos.end, pos.duration_text)} meta_alt={pos.location}/>
                        {can_add&&<Button text='Edit' minimal size='small' onClick={() => startEditing(pos.id)} />}
                </PositonWrapper>
            })}
            {!multi && positions.map((pos, i) => {
                return <PositonWrapper is_last={(i+1)===len} key={i}>
                    <Position key={i} title={pos.title} subtitle={org_data.title} description={pos.description}
                              meta={buildMeta(pos.start, pos.end, pos.duration_text)} meta_alt={pos.location}/>
                    {can_add&&<Button text='Edit' minimal size='small' onClick={() => startEditing(pos.id)} />}
                </PositonWrapper>
            })}
        </PositionsWrapper>
    </ExperienceWrapper>
}

export function ExperienceHistory({data, type, modal, can_add=false, deleteEducation, updateModal, loading, editEducation}) {
    const [d, setD] = useState(null);
    const [id, setID] = useState(null);
    const arr = prepareExperiences(data, type);

    return <Wrapper>
        <Experiences>
            {arr.map((exp, i) => {
                return <Experience type={type} startEditing={(id) => {
                    updateModal('edit');
                    setD(data[id]);
                    setID(id);
                }} num={i} can_add={can_add} key={i} data={exp}/>
            })}
        </Experiences>
        {type==='career' && modal === 'edit' && d !== null && id !== null && <EditCareerModal data={d} id={id} loading={loading === 'edit'} onEdit={(da, id) => {
            editEducation(da, id);
            setD(null);
            setID(null);
        }} onDelete={(id)=>{
            deleteEducation(id);
            setD(null);
            setID(null);
        }} onClose={() => {
            updateModal(null);
            setD(null);
            setID(null)
        }} />}
        {type==='education' && modal === 'edit' && d !== null && id !== null &&
        <EditEducationModal data={d} id={id} loading={loading === 'edit'} onEdit={(da, id) => {
            editEducation(da, id);
            setD(null);
            setID(null);
        }} onDelete={(id)=>{
            deleteEducation(id);
            setD(null);
            setID(null);
        }} onClose={() => {
            updateModal(null);
            setD(null);
            setID(null)
        }}/>}
    </Wrapper>
}