import React, {useState} from "react";
import {LayoutComplex, LayoutFull, LayoutStandard} from "../../../m3/_legacy_components/app-frame/layouts";
import {UnaverseSectionHeader} from "./unaverse";
import {useNavigate} from "react-router-dom";
import Button from "../../../m3/_legacy_components/button";
import {authFetch} from "../../../../api/network";

async function addTaxonomyItems(taxonomy,items) {
    return new Promise((resolve,reject) => {
        const body = {
            taxonomy,
            items
        };
        const res = () => {
            resolve();
        }

        authFetch("/elastic-taxonomies/add-items",res,res,"POST",body);
    });
}

function AddTaxonomyItems() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [taxonomy, setTaxonomy] = useState("occupations");

    return <div className="space-y-4">
        <div className="flex space-x-4">
            <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700">
                    Taxonomy
                </label>
                <select
                    value={taxonomy}
                    onChange={e => setTaxonomy(e.target.value)}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                    <option value="occupations">Occupations</option>
                    <option value="industries">Industries</option>
                    <option value="locations">Locations</option>
                    <option value="organizations">Organizations</option>
                </select>
            </div>
            <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700">
                    Items
                </label>
                <textarea
                    value={items.join("\n")}
                    onChange={e => setItems(e.target.value.split("\n"))}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
            </div>
        </div>
        <div className="flex space-x-4">
            <Button
                loading={loading}
                intent="primary"
                disabled={loading||items.length===0}
                onClick={() => {
                    setLoading(true);
                    addTaxonomyItems(taxonomy,items)
                        .then(() => {
                            setLoading(false);
                            setItems([]);
                        })
                }} text={`Add ${items.length} Items`} />
        </div>
    </div>
}
export function ManageTaxonomiesPage() {

    return <LayoutFull>
        <div className="space-y-4">
            <UnaverseSectionHeader
                title={"Taxonomies"}
            />
<div>
    <AddTaxonomyItems />
</div>
        </div>
    </LayoutFull>
}