import React from 'react';

import {
    PencilIcon,
    SparklesIcon,
    Square2StackIcon,
    Bars3BottomLeftIcon,
    ArrowDownIcon,
    ArrowUpRightIcon, ArrowUturnLeftIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    FunnelIcon,EyeIcon,
    MagnifyingGlassIcon, Cog8ToothIcon,
    PlusIcon,
    XMarkIcon, ArrowUturnRightIcon
} from "@heroicons/react/20/solid";

export const _m3_icon_solid = {

    undo: <ArrowUturnLeftIcon />,
    redo: <ArrowUturnRightIcon />,
    enriched: <SparklesIcon/>,
    read_only: <EyeIcon/>,
    // actions
    compose: "",
    filter: <FunnelIcon/>,
    search: <MagnifyingGlassIcon/>,
    sort: "",
    switch: "",
    external_link: <ArrowUpRightIcon/>,
    add: <PlusIcon/>,
    back: <ChevronLeftIcon/>,
    reply: "",
    edit: <PencilIcon />,
    close: <XMarkIcon/>,
    delete: "",
    save: "",
    email: "",
    archive: "",
    dropdown: <ChevronDownIcon/>,
    cancel: "",
    upload: "",
    download: "",
    share: "",
    copy: <Square2StackIcon />,
    move: "",
    link: "",
    unlink: "",
    lock: "",
    unlock: "",

    menu: <Bars3BottomLeftIcon/>,

};