import dayjs from "dayjs";

export const filter_match = [
    {
        value: 'all',
        text: 'all'
    },
    {
        value: 'any',
        text: 'any'
    }
];

export const date_in_last_options = [
    {
        label: "Hours",
        value: "hours"
    },
    {
        label: "Days",
        value: "days"
    },
    {
        label: "Weeks",
        value: "weeks"
    },
    {
        label: "Months",
        value: "months"
    },
    {
        label: "Years",
        value: "years"
    }
];

function getNum(v) {
    if (typeof v === 'string') {
        return parseFloat(v);
    } else if (typeof v === 'number') {
        return v;
    }
    return v;
}

const getQueryVals = {
    string: (v) => {
        if (typeof v !== 'string') {
            return "";
        } else if (v.length > 200) {
            return v.substring(0, 200)
        }
        return v;
    },
    number: (v, dir) => {
        const t = getNum(v)

        if (isNaN(t)) {
            return null;
        }

        if (dir && dir === '>') {
            return {
                from: t + 1
            }
        } else if (dir && dir === '<') {
            return {
                to: t - 1
            }
        } else if (dir && dir === '<=') {
            return {
                to: t
            }
        } else if (dir && dir === '>=') {
            return {
                from: t
            }
        }

        return t;
    },
    relativedate: (v) => {
        const split = v.split('-');

        const count = parseFloat(split[0]);
        const interval = split[1];

        const today = dayjs();
        const second_data = dayjs().subtract(count, interval);

        return {
            from: parseInt(second_data.format('x')),
            to: parseInt(today.format('x'))
        }
    },
    fulldate: (v) => {
        const m = dayjs(v);
        if (!m.isValid()) {
            return null;
        }
        const startOfDay = m.toDate();
        startOfDay.setUTCHours(0, 0, 0, 0);

        const endOfDay = m.toDate();
        endOfDay.setUTCHours(23, 59, 59, 999);

        return {
            from: startOfDay.getTime(),
            to: endOfDay.getTime()
        }
    },
    timestamp: (v, dir = 'from') => {
        const m = dayjs(v);
        if (!m.isValid()) {
            return "";
        }
        if (dir === 'from') {
            return {
                from: parseInt(m.format('x'))
            }
        } else {
            return {
                to: parseInt(m.format('x'))
            };
        }
        return parseInt(m.format('x'));
    },
    boolean: (v) => {
        return !!v;
    },
    objectkeys: (v) => {
        if (typeof v !== 'object') {
            return [];
        }
        return Object.keys(v);
    }
};

export const operators = {
    text: [
        /*
        {
            value: 'includes',
            text: 'includes',
            desc: 'includes',
            query_type: "all",
            isValid: (a) => {
                return !!a
            },
            getQueryValue: (v) => {
                return getQueryVals.string(v);
            }
        },

         */
        {
            value: 'not_empty',
            text: 'is not empty',
            desc: 'is not',
            query_type: "none",
            isValid: (a) => {
                return true
            },
            getQueryValue: (v) => {
                return ""
            }
        },
        {
            value: '==',
            text: 'is',
            desc: 'equals',
            query_type: "all",
            isValid: (a) => {
                return !!a
            },
            getQueryValue: (v) => {
                return getQueryVals.string(v);
            }
        },
        {
            value: 'empty',
            text: 'is empty',
            desc: 'is',
            query_type: "all",
            isValid: (a) => {
                return true
            },
            getQueryValue: (v) => {
                return "";
            }
        },
        /*
        {
            value: 'starts_with',
            text: 'starts with',
            desc: 'starts with',
            query_type: "all",
            isValid: (a) => {
                return !!a
            },
            getQueryValue: (v) => {
                return getQueryVals.string(v);
            }
        },
        {
            value: 'ends_with',
            text: 'ends with',
            desc: 'ends with',
            query_type: "all",
            isValid: (a) => {
                return !!a
            },
            getQueryValue: (v) => {
                return getQueryVals.string(v);
            }
        }

         */
    ],
    member_type: [
        {
            value: '==',
            text: 'is',
            desc: 'is',
            query_type: "all",
            isValid: (a) => {
                return !!a
            },
            getQueryValue: (v) => {
                return getQueryVals.string(v);
            }
        },
        {
            value: '!=',
            text: 'is not',
            desc: 'is not',
            query_type: "none",
            isValid: (a) => {
                return !!a
            },
            getQueryValue: (v) => {
                return getQueryVals.string(v);
            }
        },
        {
            value: 'in',
            text: 'is one of',
            desc: 'is one of',
            query_type: "all",
            isValid: (a) => {
                return typeof a === 'object' && Object.keys(a).length > 0
            },
            getQueryValue: (v) => {
                return getQueryVals.objectkeys(v);
            }
        },
        {
            value: 'not-in',
            text: 'is not in',
            desc: 'is not in',
            query_type: "none",
            isValid: (a) => {
                return typeof a === 'object' && Object.keys(a).length > 0
            },
            getQueryValue: (v) => {
                return getQueryVals.objectkeys(v);
            }
        },
    ],
    account_status: [
        {
            value: '==',
            text: 'is',
            desc: 'is',
            query_type: "all",
            isValid: (a) => {
                return !!a
            },
            getQueryValue: (v) => {
                return getQueryVals.string(v);
            }
        },
        {
            value: '!=',
            text: 'is not',
            desc: 'is not',
            query_type: "none",
            isValid: (a) => {
                return !!a
            },
            getQueryValue: (v) => {
                return getQueryVals.string(v);
            }
        },
        {
            value: 'in',
            text: 'is one of',
            desc: 'is one of',
            query_type: "all",
            isValid: (a) => {
                return typeof a === 'object' && Object.keys(a).length > 0
            },
            getQueryValue: (v) => {
                return getQueryVals.objectkeys(v);
            }
        },
        {
            value: 'not-in',
            text: 'is not in',
            desc: 'is not in',
            query_type: "none",
            isValid: (a) => {
                return typeof a === 'object' && Object.keys(a).length > 0
            },
            getQueryValue: (v) => {
                return getQueryVals.objectkeys(v);
            }
        },
    ],
    membership_status: [
        {
            value: '==',
            text: 'is',
            desc: 'is',
            query_type: "all",
            isValid: (a) => {
                return !!a
            },
            getQueryValue: (v) => {
                return getQueryVals.string(v);
            }
        },
        {
            value: '!=',
            text: 'is not',
            desc: 'is not',
            query_type: "none",
            isValid: (a) => {
                return !!a
            },
            getQueryValue: (v) => {
                return getQueryVals.string(v);
            }
        },
        {
            value: 'in',
            text: 'is one of',
            desc: 'is one of',
            query_type: "all",
            isValid: (a) => {
                return typeof a === 'object' && Object.keys(a).length > 0
            },
            getQueryValue: (v) => {
                return getQueryVals.objectkeys(v);
            }
        },
        {
            value: 'not-in',
            text: 'is not in',
            desc: 'is not in',
            query_type: "none",
            isValid: (a) => {
                return typeof a === 'object' && Object.keys(a).length > 0
            },
            getQueryValue: (v) => {
                return getQueryVals.objectkeys(v);
            }
        },
    ],
    data_integrity: [
        {
            value: '==',
            text: 'is',
            desc: 'is',
            query_type: "all",
            isValid: (a) => {
                return !!a
            },
            getQueryValue: (v) => {
                return getQueryVals.string(v);
            }
        },
        {
            value: '!=',
            text: 'is not',
            desc: 'is not',
            query_type: "none",
            isValid: (a) => {
                return !!a
            },
            getQueryValue: (v) => {
                return getQueryVals.string(v);
            }
        },
        {
            value: 'in',
            text: 'is one of',
            desc: 'is one of',
            query_type: "all",
            isValid: (a) => {
                return typeof a === 'object' && Object.keys(a).length > 0
            },
            getQueryValue: (v) => {
                return getQueryVals.objectkeys(v);
            }
        },
        {
            value: 'not-in',
            text: 'is not in',
            desc: 'is not in',
            query_type: "none",
            isValid: (a) => {
                return typeof a === 'object' && Object.keys(a).length > 0
            },
            getQueryValue: (v) => {
                return getQueryVals.objectkeys(v);
            }
        },
    ],
    boolean: [
        {
            value: 'true',
            text: 'is true',
            query_type: "all",
            desc: 'is',
            isValid: () => {
                return true
            },
            getQueryValue: (v) => {
                return getQueryVals.boolean(v);
            }
        },
        {
            value: 'false',
            text: 'is false',
            desc: 'is',
            query_type: "all",
            isValid: () => {
                return true
            },
            getQueryValue: (v) => {
                return getQueryVals.boolean(v);
            }
        },
    ],
    date: [
        {
            value: 'in_last',
            text: 'in the last',
            desc: 'in the last',
            query_type: "all",
            isValid: (a) => {
                return a.length > 4
            },
            getQueryValue: (v) => {
                return getQueryVals.relativedate(v);
            }
        },
        {
            value: 'is_on',
            text: 'is on',
            desc: 'is on',
            query_type: "all",
            isValid: (a) => {
                return !!a
            },
            getQueryValue: (v) => {
                return getQueryVals.fulldate(v);
            }
        },
        {
            value: 'is_after',
            text: 'is After',
            desc: 'is after',
            query_type: "all",
            isValid: (a) => {
                return !!a
            },
            getQueryValue: (v) => {
                return getQueryVals.timestamp(v, 'from');
            }
        },
        {
            value: 'is_before',
            text: 'is Before',
            desc: 'is before',
            query_type: "all",
            isValid: (a) => {
                return !!a
            },
            getQueryValue: (v) => {
                return getQueryVals.timestamp(v, 'to');
            }
        },
        /*
        {
            value: 'is_between',
            text: 'is Between',
            desc: 'is Between'
        }

         */
    ],
    number: [
        {
            value: '==',
            text: 'Equals',
            desc: 'equals',
            isValid: (a) => {
                return typeof a === 'number' || (typeof a === 'string' && !isNaN(parseFloat(a)))
            },
            getQueryValue: (v) => {
                return getQueryVals.number(v);
            }
        },
        {
            value: '<=',
            text: 'Less or equal to',
            desc: '<=',
            query_type: "all",
            isValid: (a) => {
                return typeof a === 'number' || typeof a === 'string'
            },
            getQueryValue: (v) => {
                return getQueryVals.number(v, '<');
            }
        },
        {
            value: '>',
            text: 'Greater than',

            query_type: "all",
            desc: '>',
            isValid: (a) => {
                return typeof a === 'number' || typeof a === 'string'
            },
            getQueryValue: (v) => {
                return getQueryVals.number(v, '>');
            }
        },
        {
            value: '<',
            text: 'Less than',
            desc: '<',
            query_type: "all",
            isValid: (a) => {
                return typeof a === 'number' || typeof a === 'string'
            },
            getQueryValue: (v) => {
                return getQueryVals.number(v, '<');
            }
        },
        {
            value: '>=',
            text: 'Greater or equal to',
            desc: '>=',
            query_type: "all",
            isValid: (a) => {
                return typeof a === 'number' || typeof a === 'string'
            },
            getQueryValue: (v) => {
                return getQueryVals.number(v, '>=');
            }
        },

        {
            value: 'is_between',
            text: 'Is Between',
            desc: 'is between',
            query_type: "all",
            isValid: (a) => {
                return Array.isArray(a) && a.length === 2 && typeof getQueryVals.number(a[0]) === 'number' && typeof getQueryVals.number(a[1]) === 'number';
            },
            getQueryValue: (v) => {
                if (Array.isArray(v) && v.length === 2) {
                    const from = getQueryVals.number(v[0]);
                    const to = getQueryVals.number(v[1]);
                    return {
                        from,
                        to
                    }
                }
                return false;
            }
        }
    ],
    select: [
        {
            value: '==',
            text: 'is',
            desc: 'is',
            query_type: "all",
            isValid: (a) => {
                return !!a
            },
            getQueryValue: (v) => {
                return getQueryVals.string(v);
            }
        },
        {
            value: '!=',
            text: 'is not',
            desc: 'is not',
            query_type: "none",
            isValid: (a) => {
                return !!a
            },
            getQueryValue: (v) => {
                return getQueryVals.string(v);
            }
        },
        {
            value: 'empty',
            text: 'is empty',
            query_type: "all",
            desc: 'is',
            isValid: () => {
                return true
            },
            getQueryValue: (v) => {
                return ""
            }
        },
        {
            value: 'not_empty',
            text: 'is not empty',
            query_type: "none",
            desc: 'is not',
            isValid: () => {
                return true
            },
            getQueryValue: (v) => {
                return ""
            }
        },
        {
            value: 'in',
            text: 'is one of',
            desc: 'is one of',
            query_type: "all",
            isValid: (a) => {
                return typeof a === 'object' && Object.keys(a).length > 0
            },
            getQueryValue: (v) => {
                return getQueryVals.objectkeys(v);
            }
        },
        {
            value: 'not-in',
            text: 'is not in',
            desc: 'is not in',
            query_type: "none",
            isValid: (a) => {
                return typeof a === 'object' && Object.keys(a).length > 0
            },
            getQueryValue: (v) => {
                return getQueryVals.objectkeys(v);
            }
        }
    ],
    country: [
        {
            value: '==',
            text: 'is',
            desc: 'is',
            query_type: "all",
            isValid: (a) => {
                return !!a
            },
            getQueryValue: (v) => {
                return getQueryVals.string(v);
            }
        },
        {
            value: '!=',
            text: 'is not',
            query_type: "none",
            desc: 'is not',
            isValid: (a) => {
                return !!a
            },
            getQueryValue: (v) => {
                return getQueryVals.string(v);
            }
        },
        {
            value: 'empty',
            query_type: "all",
            text: 'is empty',
            desc: 'is',
            isValid: () => {
                return true
            },
            getQueryValue: (v) => {
                return "";
            }
        },
        {
            value: 'not_empty',
            text: 'is not empty',
            query_type: "none",
            desc: 'is not',
            isValid: () => {
                return true
            },
            getQueryValue: (v) => {
                return "";
            }
        },
        {
            value: 'in',
            text: 'is one of',
            desc: 'is one of',
            query_type: "all",
            isValid: (a) => {
                return typeof a === 'object' && Object.keys(a).length > 0
            },
            getQueryValue: (v) => {
                return getQueryVals.objectkeys(v);
            }
        },
        {
            value: 'not-in',
            text: 'is not in',
            desc: 'is not in',
            query_type: "none",
            isValid: (a) => {
                return typeof a === 'object' && Object.keys(a).length > 0
            },
            getQueryValue: (v) => {
                return getQueryVals.objectkeys(v);
            }
        }
    ]
};

export const modifiers = [];

export const fields = [
    {
        value: 'address.zip_code',
        text: 'Zip Code',
        type: 'text'
    },
    {
        value: 'member_type',
        text: 'Membership Level',
        type: 'member_type'
    },
    {
        value: 'account_status',
        text: 'Invite Status',
        type: 'account_status'
    },
    {
        value: 'address.city',
        text: 'City',
        type: 'text'
    },
    {
        value: 'archived',
        text: 'Deactivated',
        type: 'boolean'
    },
    /*
    {
        value: 'suspended',
        text: 'Suspended',
        type: 'boolean'
    },

     */
    {
        value: 'address.country',
        text: 'Country',
        type: 'select',
        options: []
    }
];