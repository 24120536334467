import React, {useEffect} from "react"
import './styles.css';
import {
    CommunityContentPage, PageLayoutBlock, PageLayoutSection
} from "../../../m3/_legacy_components/app-frame/layouts";
import {NotificationsFeed} from "../../../m3/_legacy_components/notifications";
import {useCommunity} from "../../../config/community";
import {markAllAsRead} from "./api";
import Button from "../../../m3/_legacy_components/button";
import {authFetch} from "../../../../api/network";
import {setDocumentTitle} from "../../../m3/utilities/set-document-title";
import {m3_icon_map} from "../../../m3/icons/icon-map";
import {useIsMobile} from "../../../m3/hooks/is-mobile";

//   TODO: richer item previews for messages with relevant context and reactions (a la slack), and icons for notifications relating to leaders for example where there's no image

function getSummaryText(member) {
    if (member && member.notification_settings) {
        return `Email Summary: ${member.notification_settings.summary.frequency}`;
    }
    return "";
}

function NotificationsTestingControls() {
    const community = useCommunity();

    function createNotification(type) {
        const res = (result) => {
        };

        const payload = {
            type,
            community_uid: community.uid,
            member_id: community.member_id
        };

        authFetch("/notifications/test-create", res, res, "POST", {payload});
    }

    function testCatchupNotification() {

        const res = (resp) => {
            console.log("resp", resp);
        };
        authFetch("/test-catch-up", res, res, "POST", {})
    }

    function testSendSummary() {
        const res = (result) => {
        };

        const payload = {
            community_uid: community.uid,
            member_id: community.member_id
        };

        authFetch("/notifications/test-listener", res, res, "POST", {payload});
    }

    return <div className="space-y-2 flex flex-col">
        <Button text="Role assign" onClick={() => createNotification('assigned')}/>
        <Button text="Follow" onClick={() => createNotification('follow')}/>
        <Button text="Add to Group" onClick={() => createNotification('add-to-group')}/>
        <Button text="Membership Level" onClick={() => createNotification('member-type')}/>
        <div>

            <Button text="Test Summary" onClick={() => testSendSummary()}/>
            <Button text="Test Catchup" onClick={() => testCatchupNotification()}/>
        </div>
    </div>
}

export default function NotificationsPage() {
    const community = useCommunity();
    const is_mobile = useIsMobile();

    setDocumentTitle(`Notifications`, `${community.profile.name}`);

    useEffect(function () {
        if (community && community.unread > 0) {
            markAllAsRead(community)
                .then(() => {

                })
            community.clearUnread();
        }
    }, [])

    const summary_text = getSummaryText(community.member);

    const content = <PageLayoutSection divider={false}>
        <PageLayoutBlock>
            <NotificationsFeed/>
        </PageLayoutBlock>
    </PageLayoutSection>;

    let header_actions = [];

    if(!is_mobile) {
        header_actions.push({
            type: "icon",
            icon: m3_icon_map.outlines.cog,
            onClick: () => {
                community.toggleSettings('preferences')
            }
        });
    }

    return <CommunityContentPage header={{
        title: "Notifications",
        can_go_back: true,
        actions: header_actions
    }}>
        {content}
    </CommunityContentPage>
};