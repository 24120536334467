import React, {useState} from 'react';
import {POST_ACCEPTED_IMAGE_TYPES} from "../../../config/defaults";
import Button from "../button";
import {useCommunity} from "../../../config/community";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {storage} from "../../../config/firebase-setup";
import dayjs from "dayjs";
import {useToasts} from "../../../config/toasts";

async function loadImage(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            const img = new Image;

            img.onload = function () {

                resolve(img);
            };

            img.onerror = function () {
                reject();
            }

            img.src = reader.result; // is the data URL because called with readAsDataURL
        }
        reader.readAsDataURL(file);
    })
}

function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export async function simpleImageUpload(file, path, metadata = {}) {

    const storageRef = ref(storage, path);

    return new Promise((resolve, reject) => {
        uploadBytes(storageRef, file, metadata)
            .then((snapshot) => {
                getDownloadURL(snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    resolve(downloadURL);
                });
            })
    })
}

export function SelectImageIcon({
                                    onChange,
                                }) {
    const toasts = useToasts();
    const community = useCommunity();
    const [ref2, setRef2] = useState(React.createRef());

    function imageSelected(e) {
        fileSelected(e);
    }

    function fileSelected(e) {

        const ff = Array.from(e.target ? e.target.files : e);
        const file = ff[0];

        if (!file) {
            alert("No file selected.");
            return;
        }

        let filesize = ((file.size / 1024) / 1024).toFixed(6);

        // 2MB max
        const max_file_size = 2 * 1024 * 1024;

        if (filesize > max_file_size) {
            alert("File is too large. Max file size is 2MB.");
            return;
        }

        // now we need to make sure it's a square image

        loadImage(file)
            .then(function (img) {
                const width = img.width;
                const height = img.height;
                if (width !== height) {
                    alert("Image must be square. Please crop it to be square.");
                    return;
                }
                if (width > 200 || height > 200) {
                    alert("Image must be less than 200px by 200px.");
                    return;
                }
                handleChange(file);
            })
    }

    function handleChange(file) {
        toasts.addToast({
            text: "Uploading image...",
            intent: "info"
        })
        const uid = randomIntFromInterval(0, 14294323);
        const ext = file.name.split('.').pop();
        const path = `${community.id}/sidebar_icons/${uid}.${ext}`;
        simpleImageUpload(file, path, {
            contentType: file.type
        })
            .then(url => {
                toasts.addToast({
                    text: "Image uploaded.",
                    intent: "success"
                })
                if(onChange) {
                    onChange(url);
                }
            })
    }

    return <div className="px-3">
        <Button text="Upload an Image" onClick={() => {
            ref2.current.click();
        }}/>
        <div className="pt-2 leading-5 text-xs text-gray-500">
            <div className="">
                Max file size: 2MB
            </div>
            <div className="">
                Image must be square, less than 200px by 200px.
            </div>
        </div>
        <input multiple={false} id='icon-upload' type="file" name='image' onChange={imageSelected.bind(this)}
               ref={ref2} value={undefined}
               accept={POST_ACCEPTED_IMAGE_TYPES} style={{display: 'none'}}/>
    </div>
}