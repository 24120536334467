import React, {useEffect, useState} from 'react';
import {Avatar} from "../../m3/_legacy_components/avatar";
import {collection, doc, getDoc, getDocs, limit, orderBy, query, where} from "firebase/firestore";
import {db} from "../../config/setup-firestore";
import {useCommunity} from "../../config/community";
import {ArrowPathIcon, CheckIcon, ChevronRightIcon, XMarkIcon} from "@heroicons/react/20/solid";
import SimpleTooltip from "../../m3/_legacy_components/tooltip";
import {TrophyIcon} from "@heroicons/react/24/outline";
import {SectionHeader} from "../../m3/_legacy_components/entity-grid";
import {buildImageUrl} from "../../../common/utilities/images";

export async function api_getNameMap(community_uid) {
    const ref = doc(db, `communities/${community_uid}/meta/name-map`);
    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? doc.data() : null;
        });
}

function Options({options = [], is_correct, answer, onSelect}) {

    return <div className="grid gap-0.5 pt-1">
        {options.map((opt, key) => {
            const cl = answer === opt.id ? (!is_correct ? "bg-red-200" : "bg-green-200") : "bg-blue-100 hover:bg-blue-200";
            const color = answer === opt.id ? (!is_correct ? "text-red-800" : `text-green-800`) : `text-blue-800`;
            const ico = answer === opt.id ? (!is_correct ? <XMarkIcon/> : <CheckIcon/>) : <ChevronRightIcon/>;
            if (answer && answer !== opt.id) {
                return null;
            }
            return <div key={key}>
                <CustomBtn icon={ico} bg={cl} color={color} onClick={() => onSelect(opt.id)} text={opt.label}/>
            </div>
        })}
    </div>
}

async function getMembers(community_uid, member, max) {
    const ref = collection(db, 'community_members', community_uid, 'members');
    const q = query(ref, where('profile_picture', '!=', ''), limit(100));
    const snap = await getDocs(q);


    return snap.docs.map(doc => {
        const dt = doc.data();
        return {
            id: doc.id,
            image: dt.profile_picture,
            label: `${dt.name}`,
            first_name: dt.about.first_name,
            last_name: dt.about.last_name
        }
    }).filter(a => a.id !== member.id);
}

function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

function buildGame(members) {
    const size = members.length;

    if (size < 30) {
        return null;
    }

    const shuffled = members.sort(() => 0.5 - Math.random());

// Get sub-array of first n elements after shuffled
    let opts = shuffled.slice(0, 4);
    const selected = randomIntFromInterval(0, 2);

    return {
        answer_id: opts[selected].id,
        selected,
        options: opts
    }
}

function getScore(answers) {
    const len = answers.length;
    const correct = answers.filter(a => !!a);
    if (!len) {
        return "";
    }

    return `(${correct.length}/${len})`
}

/*

let's get 10 random ids every time from all members (not own member)

*/

function CustomBtn({icon = <ChevronRightIcon/>, onClick, text = "", bg = "", color = ""}) {

    return <div onClick={onClick}
                className={`${bg} ${color} cursor-pointer inline-flex items-center space-x-1 pl-1 pr-2 py-0.5 text-xs font-medium rounded-md`}>
        <div className="h-4 w-4">{icon}</div>
        <div>{text}</div>
    </div>
}

export function GuessMembersGame() {
    const community = useCommunity();
    const [nm, setNm] = useState();
    const [answers, setAnswers] = useState([]);
    const [game, setGame] = useState(null);
    const [answer, setAnswer] = useState("");
    useEffect(function () {
        api_getNameMap(community.uid)
            .then(nam => {
                setNm(nam)
            })
    }, [])

    useEffect(function () {
        if (nm) {
            loadGame()
        }
    }, [nm])

    function reset() {
        setGame("");
        setAnswer("");
        loadGame()
    }

    function loadGame() {

        getMembers(community.uid, community.member, Object.keys(nm).length)
            .then(members => {
                setGame(buildGame(members))
            })
    }

    function handleSelect(id) {
        const answer_is_correct = id === game.answer_id
        setAnswer(id);
        setAnswers([...answers, answer_is_correct])
    }

    if(!nm||game===null) {
        return null;
    }

    let title;
    let is_correct = !game ? false : answer === game.answer_id;

    if (answer) {
        if (is_correct) {
            title = `Correct!`;
        } else {
            title = `Incorrect..`;
        }
    } else if (game) {
        title = ``;
    }

    /*
    <div className="pt-2 text-center text-gray-500 text-xs">
                    {getScore(answers)}
                </div>
     */
    return <div>
        <SectionHeader title="Who is this?" />
        <div className="flex space-x-3 relative">
            {game && <div className="flex-grow pt-2">
                {title&&<div className={"flex space-x-1.5"}>
                    <div className="text-sm font-medium text-gray-600 mb-1">{title}</div>
                    {answers.length > 0 && <div className={""}>
                        <SimpleTooltip text={getScore(answers)}>
                            <div
                                className={"h-5 w-5 flex items-center justify-center text-gray-500 bg-gray-50 rounded-full"}>
                                <div className={"h-3.5 w-3.5"}>
                                    <TrophyIcon/>
                                </div>

                            </div>
                        </SimpleTooltip>
                    </div>}
                </div>}

                <Options is_correct={is_correct} answer={answer} onSelect={handleSelect} options={game.options}/>
                {answer && <div className="pt-2">
                    <CustomBtn icon={<ArrowPathIcon/>} bg={"bg-blue-100 hover:bg-blue-200"} color={"text-blue-800"}
                               onClick={() => reset()} text="Play again"/>
                </div>}
            </div>}
            {!game && <div className="flex-grow">

            </div>}

            <div className="w-28 pt-2">
                <Avatar url={!game ? "" : buildImageUrl(game.options[game.selected].image)} size="w-28 h-28"/>
            </div>
        </div>
    </div>
}