import {notification_types} from "./types";import {DEFAULT_USER_PHOTO} from "../../../config/community";
import {utils_dates_format} from "../../../../common/utilities/dates";
import {buildImageUrl} from "../../../../common/utilities/images";

export function convertNotification(type="",data) {
    switch (type) {
        default: {
            return  {
                image: buildImageUrl(data.author.image||DEFAULT_USER_PHOTO),
                top: {},
                read: !!data.read,
                author: data.author||{},
                type: type,
                text: notification_types[type]&&notification_types[type].message||"",
                data: data.data||{},
                author_name: data.author.name||"",
                date: utils_dates_format(data.ts).full_date
            }
        }
    }
}