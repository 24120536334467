export const emaillist_who_can_send = [
    {
        value: 'readonly',
        label: "Organizers",
        desc: "Organizers can send emails to this group"
    },
    {
        value: 'members',
        label: "Group Members",
        desc: "Members can send emails to this group"
    },
    {
        value: 'community',
        label: "Community",
        desc: "All community members can send"
    },
    {
        value: 'everyone',
        label: "Public",
        desc: "Anyone in the world"
    }
];

export const emaillist_reply_to_email = [
    {
        value: 'list',
        label: "All Members",
        desc: "Replies go back to everyone"
    },
    {
        value: 'sender',
        label: "Sender",
        desc: "Replies go to who sent the email"
    },
    {
        value: 'custom',
        label: "Custom Email",
        desc: "Replies should go to a specific email"
    }
];

export function emaillist_getReplyToOptions(access_level) {
    let a = [
        {
            value: 'list',
            label: "All Members",
            desc: "Replies go back to everyone"
        },
        {
            value: 'sender',
            label: "Sender",
            desc: "Replies go to who sent the email"
        },
        {
            value: 'custom',
            label: "Custom Email",
            desc: "Replies should go to a specific email"
        }
    ];

    if(access_level==='readonly') {
        a[0].disabled=true;
        a[0].disabled_note="Unavailable";
    }

    return a;
}