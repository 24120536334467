import React, {useEffect, useState} from "react";
import {AsyncSuggestions} from "../simple-autocomplete/async";
import {taxonomySearch} from "../app-frame/taxonomy-api";

async function handleSearch(query) {
    return await new Promise((resolve, reject) => {
        taxonomySearch(query, "occupations")
            .then((resp)=>{
                if(resp&&resp.results) {
                    resolve(resp.results.map(result=>{
                        return {
                            value: result.title,
                            id: result.id.split('_')[1],
                            label: result.title
                        }
                    }))
                } else {
                    resolve([]);
                }
            })
    });
}

export function OccupationField({value,onBlur,onChange=()=>{}}) {
    const ic = "input-focus border rounded-md leading-10 text-base px-3 w-full";
    const [top,setTop] = useState([]);

    useEffect(function () {
        handleSearch("")
            .then(resp=>setTop(resp))
    }, [])

    return <AsyncSuggestions portal onBlur={onBlur} show_no_results={false} show_menu_focus={!!top.length} default_options={top} onClick={(a,b)=>{
        onChange(a.label);
    }} handleSearch={handleSearch} input_classes={ic} value={value} />
}