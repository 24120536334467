import React from "react"
import './styles.css';

function Card({stat, label, insight}) {
    return <div style={{paddingBottom:'70%'}} className="border flex relative flex-col border-gray-300 rounded-xl">
        <div className="absolute top-0 left-0 right-0"></div>
        <div className="absolute bottom-3 left-3 right-3">
            <div>
                <div className="font-black leading-6 text-gray-800 text-2xl">{stat}</div>
            </div>
            <div className="pt-1">
                <div className="font-medium text-sm leading-5 text-gray-700">{label}</div>
            </div>
            {insight&&<div className="pt-0.5">
                <div className="text-xs text-gray-600">Up 13% this month</div>
            </div>}
        </div>
    </div>
}

export default function StatsCards({items=[]}) {

    return <div className="grid gap-3" style={{gridTemplateColumns:'repeat(auto-fill,10rem)'}}>
        {items.map((item,i)=>{
            return <Card key={i} {...item} />
        })}
    </div>
};