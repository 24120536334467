import {collection, doc, getDoc, query, where} from "firebase/firestore";
import {db} from "../../app/config/setup-firestore";

export async function getFolder(community_uid,folder_id) {
    console.log("getFolder",community_uid,folder_id);

    if(!folder_id) return null;
    if(!community_uid) return null;
    const ref = doc(db, 'community_entities', community_uid, 'folders', folder_id);

    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? {...doc.data(), id: doc.id} : null;
        });
}

export async function getFile(community_uid,file_id) {
    console.log("file_id",community_uid,file_id);
    if(!file_id) return null;
    if(!community_uid) return null;
    const ref = doc(db, 'community_entities', community_uid, 'files', file_id);
    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? {...doc.data(), id: doc.id} : null;
        });
}