import React, {useEffect, useState} from "react"
import './styles.css';
import MembershipCard from "../../../m3/_legacy_components/membership-card";
import {useNavigate} from "react-router-dom";
import {useUnaverse} from "../../../config/unaverse";
import DataWrapper from "../../../m3/_legacy_components/data-wrapper";
import {AnimatePresence, motion} from "framer-motion";
import {InlineLoader} from "../../../m3/_legacy_components/admin-activity";
import {setDocumentTitle} from "../../../m3/utilities/set-document-title";
import {MarketingPageContainer} from "../../../m3/_legacy_components/app-frame/marketing";
import styled from "styled-components";
import M3_A_BlankSlate from "../../../m3/atoms/blank-slate";
import M3_A_Link from "../../../m3/atoms/link";
import M3_A_Text from "../../../m3/atoms/text";
import M3_A_Heading from "../../../m3/atoms/heading";

// todo this should not be used like this
export function SharedPageTitle({title = "", children, actions}) {
    return <div className="flex items-center">
        <div className="flex-grow">
            <div className="text-2xl font-bold text-gray-800">{title}{children}</div>
        </div>
        <div className="flex gap-2">
            {actions}
        </div>
    </div>
}

function buildItems(memberships = {communities: {}}) {
    let items = Object.keys(memberships.communities || {});

    return items.map(id => {
        return {
            id,
            member_id: memberships.member_ids[id] || ""
        }
    })
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Item = styled.div`
    flex: 0 0 calc(33% - 0.5rem);
    @media (max-width: 1000px) {
        flex: 0 0 calc(50% - 0.5rem);
    }
  @media (max-width: 420px) {
    flex: 0 0 calc(100%);
  }
`;

function MyMemberships() {
    const unaverse = useUnaverse();
    const navigate = useNavigate();
    const items = buildItems(unaverse.memberships);
    const [data, setData] = useState(null);

    useEffect(() => {

    }, [unaverse.memberships]);

    const is_loading = unaverse.memberships.communities === null;

    let c;

    if (is_loading) {
        c = <div>
            <InlineLoader padding={"p-8"}/>
        </div>
    } else if (items.length === 0) {
        c = <M3_A_BlankSlate button={{
            intent: "primary",
            children: "Create a new Community",
            onClick: () => {
                navigate('/create-community');
            }
        }} children={<M3_A_Text size="xs"><M3_A_Link onClick={() => {
            navigate('/sign-out?redirect=/sign-in');
        }} arrow variant="primary">Try a different email</M3_A_Link></M3_A_Text>} title="Welcome to Orange!"
                             description={`You'll see membership cards for each community you're a part of here. We didn't find any active memberships found for ${unaverse.profile.account_email}.`}/>;
    } else {
        c = <div>
            <Wrapper className="gap-4">
                <AnimatePresence>
                    {items.map(it => {
                        return <Item key={it.id}>
                            <motion.div whileTap={{scale: 0.98}} whileHover={{
                                scale: 1,
                                //     y: -2
                            }} className="w-full flex-none">
                                <DataWrapper data_key={['community', 'member']}
                                             type={["communities", `community_members.${it.id}.members`]}
                                             id={[it.id, it.member_id]} key={it.id}>
                                    <MembershipCard key={it.id} onClick={() => {
                                        //  navigate('');
                                    }}/>
                                </DataWrapper>
                            </motion.div>
                        </Item>
                    })}
                </AnimatePresence>
            </Wrapper>
            <div className="text-center py-16 flex flex-col gap-2 text-gray-700">
                <M3_A_Text size="xs">Showing Memberships for <span
                    className="font-semibold">{unaverse.profile.account_email}</span>. <M3_A_Link onClick={() => {
                    navigate('/sign-out?redirect=/sign-in');
                }} arrow variant="primary">Sign
                    in with
                    a different email</M3_A_Link></M3_A_Text>
                <M3_A_Text size="xs" className="hidden sm:block">Want to use Orange with a different
                    Community? <M3_A_Link onClick={() => {
                        navigate('/create-community');
                    }} variant="primary">Create a new Community</M3_A_Link></M3_A_Text>
            </div>
        </div>
    }

    return <div className="max-w-4xl pt-4 mx-auto">
        <div className="pb-2">
            <div>
                <M3_A_Heading size="h3">Memberships</M3_A_Heading>
            </div>
            <div></div>
        </div>
        {c}
    </div>
}

export default function UnaverseHomePage({demo = false}) {
    setDocumentTitle("Home");


    return <MarketingPageContainer>
        <MyMemberships/>
    </MarketingPageContainer>
};