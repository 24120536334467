import React from "react";

export function ACWrapper({children}) {
    return <div className="">
        {children}
    </div>
}

export function ACItem({children, disabled, layout, active, onClick=()=>{}}) {
    const padding = layout === "large" ? "px-4 py-2" : "px-2.5 py-1.5";
    const spacing = layout === "large" ? " space-x-3" : " space-x-2";
    const bg = disabled ? "bg-gray-100" : active ? "bg-gray-100" : "hover:bg-gray-100";
    return <div onClick={()=>{
        onClick();
    }} className={`${bg} ${padding} ${disabled?"cursor-not-allowed opacity-80":"cursor-pointer"}  `}>
        <div className={`text-sm text-gray-800 flex ${spacing} font-medium`}>
            {children}
        </div>
    </div>
}

export function ACSuggestionsContainer({children}) {
    return <div className="py-1.5">
        {children}
    </div>
}