import React from "react";
import {PinboardBlockWrapper} from "../index";
function LinkIcon({type='image',image}) {
    let i;
    if(image) {
        i = image;
    } else {
        i = null;
    }
    return <div className="h-6 p-1" style={{aspectRatio: "1/1"}}>
        <div style={{backgroundImage: `url(${i})`}} className="bg-cover bg-center rounded h-full w-full">

        </div>
    </div>
}

function Link({external,onClick,muted,image,emoji,name = "Link Name"}) {
const colors = muted ? "text-gray-500 bg-gray-100 hover:bg-gray-200" : "text-gray-800 hover:bg-blue-50";
/*
{external&&<div className="pl-1 pr-0.5 inline-block w-3.5 text-gray-500">
                <div className="h-3 w-3">
                    <ArrowTopRightOnSquareIcon/>
                </div>
            </div>}
 */
    return <div onClick={(e)=>{
        if(e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if(onClick) {
            onClick();
        }
    }} className={`${colors} text-sm mr-1 text-gray-600 mb-0.5 whitespace-nowrap float-left inline-flex rounded-md items-center leading-6  cursor-pointer transition-colors`}>
        {image&&<LinkIcon {...{image,type:"image"}} />}
        {!image&&<div className="w-1.5 h-2" />}
        <div className="pr-2 flex items-center pl-0.5 max-w-[10rem]">
            <div className="inline-block truncate">
              {name}
            </div>

        </div>
    </div>
}

export default function PinboardLinks({can_add,onSelect=()=>{},onAdd,title = 'Apps',links=[]}) {

    return <PinboardBlockWrapper title={title}>
            {links.map((link,index)=>{
                return <Link onClick={()=>onSelect(link.id||"",link)} {...link} key={index} />
            })}
            {can_add&&<Link muted name="Add" onClick={onAdd} />}
    </PinboardBlockWrapper>
};