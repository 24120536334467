import React from "react";
import {CheckIcon,MinusIcon} from "@heroicons/react/20/solid";
import styled from "styled-components";

/*
* This is the Checkbox atom
 */

const _props = {
    label: {
        type: "string",
        required: false,
        default: "Label"
    },
    checked: {
        type: "boolean",
        required: false,
        default: false
    },
    disabled: {
        type: "boolean",
        required: false,
        default: false
    },
    indeterminate: {
        type: "boolean",
        required: false,
        default: false
    },
    onChange: {
        type: "function",
        required: false,
        default: () => {}
    }
};

const IconItem = styled.div`
> svg {
    height: 14px;
}
`;

export default function M3_A_Checkbox({
                                          label = "Label",
                                          checked = false,
                                          indeterminate,
                                          disabled = false,
                                          onChange
                                      }) {

    function handleChange(e) {
        if(disabled) return;
        if(!onChange) return;
        onChange(e, !checked);
    }
/*
    <label htmlFor="comments" className="ml-3 block text-sm font-medium text-gray-700">
                {label}
            </label>
 */

    return <div className="flex-shrink-0 flex items-start">
        {!checked&&!indeterminate&&<div onClick={(e)=> {
            e.stopPropagation()
            handleChange(e)
        }} className="inline-flex justify-center items-center hover:bg-gray-100 flex-shrink-0 h-4 w-4 cursor-pointer text-gray-500 rounded-md bg-white border border-gray-300">

        </div>}
        {indeterminate&&<IconItem onClick={(e)=> {
            e.stopPropagation()
            handleChange(e)
        }}
                                    className="inline-flex items-center justify-center flex-shrink-0 h-4 w-4 cursor-pointer text-white rounded-md border border-secondary bg-secondary">
                <MinusIcon/>
            </IconItem>}
        {checked&&<IconItem onClick={(e)=> {
            e.stopPropagation()
            handleChange(e)
        }} className="inline-flex items-center justify-center flex-shrink-0 h-4 w-4 cursor-pointer text-white rounded-md border border-secondary bg-secondary">
            <CheckIcon/>
        </IconItem>}
    </div>;
}