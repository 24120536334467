import {data_system_interests_arr} from "../../../../common/data/data/interests";
import {data_sexes_arr} from "../../../../common/data/data/sex";
import {data_social_arr} from "../../../../common/data/data/social";
import {data_data_integrity_arr} from "../../../../common/data/data/data-integrity";
import {data_countries_arr} from "../../../../common/data/data/countries";
import {data_membership_status_arr} from "../../../../common/data/data/membership-status";
import {data_invite_status_arr} from "../../../../common/data/data/invite-status";


function buildOptions(member_types) {
    return Object.entries(member_types).map(([id, ent]) => {
        return {
            label: ent.singular,
            id: id
        }
    });
}

const basic_allowed_block_fields = ['profile-picture', 'title', 'email', 'phone', 'occupation', 'account_status', 'membership_status', 'data_integrity', 'location'];

export function buildInterestOptions() {
    return data_system_interests_arr.map(ent => {
        return {
            value: `default_${ent.id}`,
            label: ent.name,
            emoji: ent.emoji
        }
    })
}

function buildChoicesMap(arr) {
    let a = {};
    for (let i = 0; i < arr.length; i++) {
        a[arr[i].value] = arr[i].text;
    }
    return a;
}

export function buildFullDefinition(base, custom_fields, member_types, community_data) {
    let defs = Object.assign({}, base);

    let cf = Object.entries(custom_fields);
    let sort_addons = {};
    let group_addons = {};
    let filter_addons = {};

    defs.allowed_block_fields = [...basic_allowed_block_fields];

    for (let i = 0; i < cf.length; i++) {
        const [id, entry] = cf[i];

        if (entry.type === 'checkbox') {
            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'boolean',
                placeholder: 'true',
                field: `custom_fields.${id}`
            };
        } else if (entry.type === 'number') {
            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'number',
                placeholder: 'Number',
                field: `custom_fields.${id}`
            };
        } else if (entry.type === 'select') {
            if(entry.options.choices) {
                const choices_map = buildChoicesMap(entry.options.choices);
                defs.allowed_block_fields.push(`custom_fields.${id}`);
                group_addons[`custom_fields.${id}`] = {
                    label: entry.name,
                    type: 'select',
                    options: choices_map,
                    field: `custom_fields.${id}`,
                    sort: `custom_fields.${id}`,
                    sort_type: 'text',
                    dir: 'asc'
                };

                filter_addons[`custom_fields.${id}`] = {
                    label: entry.name,
                    type: 'select',
                    custom: true,
                    placeholder: 'Select',
                    field: `custom_fields.${id}`,
                    options: Object.entries(choices_map).map(([id, item]) => {
                        return {value: id, label: item}
                    }),
                    map: choices_map
                };

                defs.properties[`custom_fields.${id}`] = {
label: entry.name,
                    type: 'select',
                    filterable: true,
                    col_width: 150,
                    field: `custom_fields.${id}`,
                    options: Object.entries(choices_map).map(([id, item]) => {
                        return {id: id, label: item}
                    })
                };
            }

        } else if (entry.type === 'multi-select') {
            if(entry.choices_map) {
                filter_addons[`custom_fields.${id}`] = {
                    label: entry.label,
                    type: 'select',
                    custom: true,
                    placeholder: 'Select',
                    field: `custom_fields.${id}`,
                    options: Object.entries(entry.choices_map).map(([id, item]) => {
                        return {value: id, label: item}
                    }),
                    map: entry.choices_map
                };
            }
        } else if (entry.type === 'select-taxonomy') {
            group_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'text',
                options: {},
                field: `custom_fields.${id}`,
                sort: `custom_fields.${id}`,
                sort_type: 'text',
                dir: 'asc'
            };

            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'text',
                custom: true,
                placeholder: 'Text',
                field: `custom_fields.${id}`,
                options: [],
                map: {}
            };
        } else if (entry.type === 'multi-select-taxonomy') {

            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'text',
                custom: true,
                placeholder: 'Text',
                field: `custom_fields.${id}`,
                options: [],
                map: {}
            };
        } else if (entry.type === 'text') {
            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'text',
                custom: true,
                placeholder: 'Start typing..',
                field: `custom_fields.${id}`
            };
        } else if (entry.type === 'textarea') {
            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'text',
                custom: true,
                placeholder: 'Start typing..',
                field: `custom_fields.${id}`
            };
        } else if (entry.type === 'url') {
            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'text',
                custom: true,
                placeholder: 'Start typing..',
                field: `custom_fields.${id}`
            };
        } else if (entry.type === 'member') {
            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'text',
                custom: true,
                placeholder: 'Start typing..',
                field: `custom_fields.${id}`
            };
        } else if (entry.type === 'mentor') {
            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'text',
                custom: true,
                placeholder: 'Start typing..',
                field: `custom_fields.${id}`
            };
        } else if (entry.type === 'mentees') {
            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'text',
                custom: true,
                placeholder: 'Start typing..',
                field: `custom_fields.${id}`
            };
        }
    }

    defs.sort_options = {
        ...defs.sort_options,
        ...sort_addons
    };

    defs.group_options = {
        ...defs.group_options,
        ...group_addons
    };

    defs.filter_options = {
        ...defs.filter_options,
        ...filter_addons
    };

    // populate membership level
    defs.filter_options['member_type'].options = buildOptions(member_types);
    defs.properties['member_type'].options = buildOptions(member_types);
    // TODO FIX VALUE VS ID
    defs.filter_options['account_status'].options = [...data_invite_status_arr.map(it => {
        return {
            label: it.label,
            value: it.id
        }
    })];
    defs.properties['account_status'].options = [...data_invite_status_arr];

    defs.filter_options['membership_status'].options = [...data_membership_status_arr.map(it => {
        return {
            label: it.label,
            value: it.id
        }
    })];
    defs.properties['membership_status'].options = [...data_membership_status_arr];

    defs.filter_options['data_integrity'].options = [...data_data_integrity_arr.map(it => {
        return {
            label: it.label,
            value: it.id
        }
    })];
    defs.properties['data_integrity'].options = [...data_data_integrity_arr];

    defs.properties['address.country'].options = [...data_countries_arr.map(c => {
        return {
            value: c.value,
            text: c.label
        }
    })];

    data_social_arr.forEach(sf => {
        if (!community_data.preferences.members[`hide_${sf.value}`]) {
            defs.filter_options[`social.${sf.value}`] = {
                type: 'text',
                label: sf.label,
                field: `social.${sf.value}`
            };
        }
    })

    const interest_opts = buildInterestOptions();

    defs.properties['interests'].options = [...interest_opts];
    // todo account for interests
    defs.filter_options['interests'].options = interest_opts;


    return defs;
}

export function at_updateSort(sarr, action, field, defs) {
    let to_return = sarr;
    if (action === 'reverse-dir') {
        const sort_index = sarr.findIndex(x => x.field === field);
        const current_dir = to_return[sort_index].dir;
        to_return[sort_index].dir = current_dir === 'desc' ? 'asc' : 'desc';
    } else if (action === 'add-sort') {
        const type_field = field.indexOf('.') !== -1 ? field.split('.')[1] : field;
        to_return.push({
            field,
            type: defs.sort_options[type_field].type,
            dir: 'asc'
        })
    } else if (action === 'remove-sort') {
        const sort_index = sarr.findIndex(x => x.field === field);
        to_return.splice(sort_index, 1);
    }
    return to_return;
}

export const member_directory_definition = {
    general: {
        title: 'Members',
        single: 'member',
        multi: 'members',

        admin_indicators: [],

        bulk_endpoint: '/members/bulk-change',

        admin_link: `/:handle/admin/member/`,
        admin_id: 'docid',
        member_link: `/:handle/member/`,
        member_id: 'handle',
        type: 'members'
    },

    data_transforms: [
        {
            type: 'age_from_birthday',
            field: 'birthday',
            dt_type: 'number',
            dt_label: 'Age',
            value_type: 'number',
            id: 'dt_age'
        },
        /*
        {
            type: 'member_address',
            field: 'address',
            dt_type: 'address',
            dt_label: 'Address',
            value_type: 'text',
            id: 'dt_address'
        },
         */
        {
            type: 'data_quality_score',
            field: 'updated_at',
            dt_type: 'data-quality-score',
            dt_label: 'Data Quality',
            value_type: 'data-quality-tag',
            id: 'dt_dataqualityscore'
        }
    ],

    //
    allowed_layouts: [
        'list',
        'gallery',
        //  'map'
    ],
    default_layout: 'gallery',
    //

    //
    search_field: 'about.last_name',
    search_field_alt: 'about.first_name',
    //

    //
    layout_config: {
        'map': {
            required_properties: ['name'],
            image: 'profile_picture',
            name: 'name',
            // sort doesn't really apply..
            default_sort: [],
            default_filters: [],
            // cannot group by
            default_group_by: null,
            default_features: {
                can_select: false,
            },
            default_properties: [
                {
                    id: 'name',
                    active: true
                }
            ]
        },
        'list': {
            required_properties: ['name'],
            image: 'profile_picture',
            name: 'name',
            default_sort: [
                {
                    dir: 'asc',
                    field: 'about.last_name'
                }
            ],
            default_filters: [],
            default_group_by: null,
            default_features: {
                can_select: false,
            },
            default_properties: [
                {
                    id: 'name',
                    active: true
                }
            ]
        },
        'gallery': {
            required_properties: ['name', 'profile_picture'],
            image: 'profile_picture',
            name: 'name',
            default_sort: [],
            default_filters: [],
            default_group_by: null,
            default_features: {
                can_select: false,
            },
            default_properties: [
                {
                    id: 'name',
                    active: true
                },
            ]
        },
        'table': {
            required_properties: ['name'],
            name: 'name',
            image: 'profile_picture',
            default_sort: [],
            default_filters: [],
            default_group_by: null,
            default_features: {
                can_select: true,
            },
            default_properties: [
                {
                    id: 'name',
                    active: true
                },
            ]
        }
    },
    //

    //
    sort_options: {
        ['about.last_name']: {
            field: 'about.last_name',
            label: 'Last Name',
            type: 'text',
            default_value: 'desc',
            options: ['asc', 'desc']
        },
        ['last_sign_in']: {
            field: 'last_sign_in',
            label: 'Last Sign In',
            type: 'date',
            default_value: 'desc',
            options: ['asc', 'desc']
        },
        ['profile_updated_at']: {
            field: 'profile_updated_at',
            label: 'Profile last updated',
            type: 'date',
            default_value: 'desc',
            options: ['asc', 'desc']
        },
        ['stats.sessions']: {
            field: 'stats.sessions',
            label: 'Sessions',
            type: 'number',
            default_value: 'desc',
            options: ['asc', 'desc']
        },
        ['about.first_name']: {
            field: 'about.first_name',
            label: 'First Name',
            type: 'text',
            default_value: 'asc',
            options: ['asc', 'desc']
        },
        age: {
            field: 'age',
            label: 'Age',
            type: 'number',
            default_value: 'desc',
            options: ['asc', 'desc']
        }
    },
    //

    //
    group_options: {
        member_type: {
            label: 'Membership Level',
            type: 'member_type',
            field: 'member_type',
            sort: 'plural',
            sort_type: 'text',
            dir: 'asc'
        },
        ['address.country']: {
            label: 'Country',
            type: 'select',
            field: 'address.country',
            sort: 'alphabetical',
            sort_type: 'text',
            dir: 'asc'
        }
    },
    //

    //
    filter_options: {
        interests: {
            label: 'Interests',
            type: 'select',
            field: 'interests',
        },
        member_type: {
            type: 'member_type',
            label: 'Membership Level',
            field: 'member_type',
        },
        account_status: {
            type: 'invite_status',
            label: 'Invite Status',
            field: 'account_status',
        },
        membership_status: {
            type: 'membership_status',
            label: 'Membership Status',
            field: 'membership_status',
        },
        data_integrity: {
            type: 'data_integrity',
            label: 'Profile Quality',
            field: 'data_integrity',
        },
        ['stats.sessions']: {
            field: 'stats.sessions',
            label: 'Sessions',
            type: 'number'
        },
        last_sign_in: {
            field: 'last_sign_in',
            label: 'Last Login',
            type: 'date'
        },
        created_at: {
            field: 'created_at',
            label: 'Created at',
            type: 'date'
        },
        profile_updated_at: {
            field: 'profile_updated_at',
            label: 'Updated profile',
            type: 'date'
        },
        age: {
            field: 'age',
            label: 'Age',
            type: 'number'
        },
        ['about.gender']: {
            field: 'about.gender',
            label: 'Sex',
            type: 'select',
            options: data_sexes_arr
        },
        ['address.zip_code']: {
            type: 'text',
            label: 'ZIP Code',
            field: 'address.zip_code'
        },
        ['address.city']: {
            type: 'text',
            label: 'City',
            field: 'address.city'
        },
        ['address.country']: {
            type: 'select',
            label: 'Country',
            field: 'address.country',
            options: data_countries_arr
        }
    },
    //

    //
    properties: {

        name: {
            type: 'name',
            always_on: true,
            field: 'name',
            editable: false,
            default_on: true,
            col_width: 180,
            label: 'Full Name'
        },
        ['about.headline']: {
            type: 'text',
            always_on: false,
            field: 'about.headline',
            editable: false,
            col_width: 180,
            label: 'Headline'
        },
        ['about.first_name']: {
            type: 'text',
            editable: true,
            sortable: true,
            field: 'about.first_name',
            col_width: 150,
            label: 'First Name'
        },
        ['profile_picture']: {
            type: 'profile_picture',
            editable: false,
            sortable: false,
            field: 'profile_picture',
            col_width: 150,
            label: 'Profile Picture'
        },
        ['signature']: {
            type: 'signature',
            editable: false,
            sortable: false,
            field: 'signature',
            col_width: 150,
            label: 'Signature'
        },
        ['about.occupation']: {
            type: 'occupation',
            editable: false,
            sortable: false,
            field: 'about.occupation',
            col_width: 150,
            label: 'Occupation'
        },
        ['about.location']: {
            type: 'location',
            editable: false,
            sortable: false,
            field: 'about.location',
            col_width: 150,
            label: 'Location'
        },
        handle: {
            type: 'handle',
            editable: false,
            field: 'handle',
            col_width: 150,
            label: 'Handle'
        },
        account_email: {
            type: 'account-email',
            editable: false,
            field: 'account_email',
            col_width: 225,
            label: 'Account Email'
        },
        'join_date': {
            type: 'join_date',
            sortable: false,
            editable: false,
            field: 'join_date',
            col_width: 120,
            label: 'Join Date'
        },
        ['about.last_name']: {
            type: 'text',
            sortable: true,
            editable: true,
            field: 'about.last_name',
            col_width: 120,
            label: 'Last Name'
        },
        ['about.bio']: {
            type: 'bio',
            sortable: false,
            editable: true,
            field: 'about.bio',
            col_width: 120,
            label: 'Bio'
        },
        ['id']: {
            type: 'id',
            sortable: false,
            editable: false,
            field: 'id',
            col_width: 120,
            label: 'ID'
        },
        member_type: {
            type: 'select',
            editable: true,
            filterable: true,
            field: 'member_type',
            default_on: true,
            col_width: 150,
            label: 'Membership Level'
        },
        membership_status: {
            type: 'tags',
            editable: true,
            filterable: true,
            field: 'membership_status',
            default_on: false,
            col_width: 120,
            label: 'Membership Status'
        },
        account_status: {
            type: 'tags',
            editable: true,
            filterable: true,
            field: 'account_status',
            default_on: false,
            col_width: 120,
            label: 'Invite Status'
        },
        data_integrity: {
            type: 'tags',
            editable: true,
            filterable: true,
            field: 'data_integrity',
            default_on: false,
            col_width: 120,
            label: 'Profile Quality'
        },
        birthday: {
            type: 'birthday',
            editable: true,
            field: 'birthday',
            col_width: 100,
            label: 'Birthday'
        },
        /*
        dt_age: {
            type: 'number',
            editable: false,
            field: 'dt_age',
            col_width: 150,
            label: 'Age',
            // required for fields that can be calculated
            value_label: {
                single: 'year',
                multi: 'years'
            }
        },

         */
        /*
        dt_address: {
            type: 'address',
            editable: false,
            field: 'dt_address',
            col_width: 150,
            label: 'Address'
        },

         */
        location: {
            type: 'location',
            field: 'about.location',
            editable: true,
            default_on: true,
            col_width: 150,
            label: 'Location'
        },

        ['contact.email']: {
            type: 'email',
            field: 'contact.email',
            editable: true,
            col_width: 120,
            label: 'Email'
        },
        ['contact.phone']: {
            type: 'phone',
            field: 'contact.phone',
            editable: true,
            col_width: 150,
            label: 'Phone'
        },

        ['interests']: {
            type: 'select',
            field: 'interests',
            editable: false,
            col_width: 150,
            label: 'Interests'
        },
        ['roles']: {
            type: 'roles',
            field: 'roles',
            editable: false,
            col_width: 180,
            label: 'Roles'
        },
        ['groups']: {
            type: 'groups',
            field: 'groups',
            editable: false,
            col_width: 180,
            label: 'Groups'
        },
        ['contact.secondary_phone']: {
            type: 'phone',
            field: 'contact.secondary_phone',
            editable: true,
            col_width: 120,
            label: '2nd Phone'
        },
        ['about.title']: {
            type: 'text',
            field: 'about.title',
            editable: true,
            col_width: 120,
            label: 'Title'
        },
        ['about.middle_name']: {
            type: 'text',
            editable: true,
            field: 'about.middle_name',
            col_width: 120,
            label: 'Middle Name'
        },
        ['about.line_one']: {
            type: 'text',
            editable: true,
            field: 'address.line_one',
            col_width: 120,
            label: 'Line One'
        },
        ['about.line_two']: {
            type: 'text',
            editable: true,
            field: 'address.line_two',
            col_width: 120,
            label: 'Line Two'
        },
        ['address.city']: {
            type: 'text',
            editable: true,
            filterable: true,
            field: 'address.city',
            col_width: 120,
            label: 'City'
        },
        ['address.zip_code']: {
            type: 'text',
            editable: true,
            filterable: true,
            field: 'address.zip_code',
            col_width: 120,
            label: 'ZIP Code'
        },
        ['address.state']: {
            type: 'text',
            field: 'address.state',
            filterable: true,
            col_width: 120,
            editable: true,
            label: 'State'
        },
        ['address.country']: {
            type: 'select',
            editable: true,
            filterable: true,
            field: 'address.country',
            col_width: 120,
            label: 'Country'
        },
        ['stats.sessions']: {
            type: 'number',
            field: 'stats.sessions',
            sortable: true,
            filterable: true,
            editable: false,
            col_width: 100,
            label: 'Sessions',
            value_label: {
                single: 'session',
                multi: 'sessions'
            }
        },
        profile_updated_at: {
            type: 'date',
            field: 'profile_updated_at',
            sortable: true,
            options: {
                display: 'relative'
            },
            filterable: true,
            editable: false,
            col_width: 100,
            label: 'Profile Updated'
        },
        joined_date: {
            type: 'joined-date',
            field: 'joined_date',
            sortable: true,
            filterable: true,
            editable: false,
            col_width: 100,
            label: 'Joined'
        },
        created_at: {
            type: 'date',
            options: {
                display: 'absolute'
            },
            field: 'created_at',
            sortable: true,
            filterable: true,
            editable: false,
            col_width: 100,
            label: 'Created'
        },

        age: {
            type: 'number',
            field: 'age',
            sortable: true,
            filterable: true,
            editable: false,
            col_width: 80,
            label: 'Age',
            value_label: {
                single: 'year',
                multi: 'years'
            }
        },
        ['last_sign_in']: {
            type: 'date',
            options: {
                display: 'relative'
            },
            field: 'last_sign_in',
            sortable: true,
            filterable: true,
            editable: false,
            action: {
                label: 'Send Invite',
                condition: 'equals-null',
                id: 'send-member-invite',
                props: ['id', 'user_uid']
            },
            col_width: 100,
            label: 'Last Login'
        },
        /*
        custom_field__XXXXXXXX:{}
         */
    },
    //
};
