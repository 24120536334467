import React from "react";
import {useCommunity} from "../../../../config/community";
import FormFieldsRenderer from "../../../../m3/_legacy_components/form-fields-renderer";
import FindMeOn from "../../../../m3/_legacy_components/find-me-on";
import {getPhoneServices} from "../../member/member-profile-utils";
import {isAnonymousUnatyEmail} from "../../../../m3/utilities/email";
import {TextAction} from "../../../auth/sign-in";
import FieldPrivacyControl, {getFieldPrivacyGetDefaultValue} from "../../../../m3/examples/field-privacy-control";

function buildEmailsFromUser(user) {
    let arr = [];
    if (isAnonymousUnatyEmail(user.account_email)) {
        return arr;
    }
    if (user.emails_status && user.emails_status[user.account_email]) {
        arr.push({
            value: user.account_email,
            label: user.account_email,
            status: user.emails_status[user.account_email].status
        });
    } else {
        arr.push({
            value: user.account_email,
            label: user.account_email,
            status: 'unverified'
        })
    }

    if (user.secondary_emails && user.emails_status) {
        user.secondary_emails.forEach(se => {
            const se_status = user.emails_status[user.account_email] ? user.emails_status[user.account_email].status : 'verified';
            arr.push({
                value: se,
                label: se,
                status: se_status
            });
        });
    }

    return arr;
}

const getExtraFieldMetadata = (accessor, field, member, community, fns, onChange) => {
    let a = {};

    if (accessor === "contact.phone") {
        const ps = getPhoneServices(member);
        a.footer = <FindMeOn can_edit updateMember={(a, b, c) => {
            console.log("UPDATE MEMBER", a, b, c);
            onChange(`phone_services.${b}`, c, ps['phone'], 'phone_services');
        }} field="phone" id={member.id} my_profile={member.id === community.member.id}
                             active={ps[`phone`]}/>;
    } else if (accessor === "contact.email") {
        a.corner_hint = (member.secondary_emails.length < 5 && !isAnonymousUnatyEmail(member.account_email)) ?
            <TextAction text={"Add Email"} onClick={() => fns.addEmail()}/> : null;
    } else if(accessor === "birthday") {
        a.footer = <FieldPrivacyControl value={getFieldPrivacyGetDefaultValue(member, "birthday_date_visibility")}
                                        onChange={(new_value) => {
                                            onChange("birthday_date_visibility", new_value, member?.["birthday_date_visibility"], 'birthday_date_visibility');
                                        }} type="birthday" id={member.id}
                                        my_profile={member.id === community.member.id}
        />;
    }

    return a;
}

const getInputProps = (accessor, field, member, community, fns) => {
    let b = {
        community_uid: community.id,
        required: field?.required,
        options: field?.options || {},
        placeholder: field?.placeholder || "",
        classic: true,
    };
    if (accessor === "contact.email") {
        b.onAddEmail = fns.addEmail;
        b.options = buildEmailsFromUser(member);
    }

    return b;
}

export const buildStandardFields = (fields, data, community, fns, onChange, custom_metadata = {}) => {
    console.log("fields", fields);
    return fields.map((field, k) => {
        const input_props = getInputProps(field.accessor, field, data, community, fns, onChange);
        const extras = getExtraFieldMetadata(field.accessor, field, data, community, fns, onChange);
        return {
            label: field.label,
            type: field.type,
            accessor: field.accessor,

            locked: field?.is_locked,

            disabled: !field?.can_edit,

            input_props,

            ...custom_metadata,

            ...extras,
        };
    });
}

export function EditMemberStandardSection({data, fields, onChange, section_id = "", fns}) {
    const community = useCommunity();

    function handleOnChange(accessor, new_value, old_value, field) {
        onChange(accessor, new_value, old_value, field)
    }

    return <div className="space-y-3 pb-4" id={`edit-${section_id}`}>
        <FormFieldsRenderer onChange={handleOnChange} data={data}
                            fields={buildStandardFields(fields, data, community, fns, handleOnChange)}/>
    </div>
}