import React, {useEffect, useRef, useState} from "react";
import {ContactsPrimaryToolbar} from "../../../routes/community/contacts/components";
import {GridViewComponent} from "./grid";

/*
* This is the GridView component
 */

const _props = {};

function buildGridStartingCols(config) {
    let a = [];

    config.state.active_view_data.config.attributes.forEach((col, index) => {
        const attribute = config.data.attributes.find(attribute => attribute.value === col);
        if (attribute) {
            a.push(attribute.col_data);
            const index = a.length - 1;
            const overrides = config.state.active_view_data.config?.overrides;

            if (overrides && overrides?.[attribute.value]) {
                a[index].label = overrides[attribute.value]?.label || attribute?.label;
                a[index]._custom_label = true;
            } else {
                a[index].label = attribute?.label;
                a[index]._custom_label = false;
            }
        }
    })

    return a;
}

// todo use rest
export default function M3_C_GridView({
                                          children,
                                          config,
                                          renderEmptyState,
                                          inline_sidebar_width
                                      }) {
    const [cols, setCols] = useState(buildGridStartingCols(config));
    const current_id = useRef(config.state.active_view_id);
    const last_updated_at = useRef(config.state.last_updated_at);

    useEffect(() => {
        if (current_id.current !== config.state.active_view_id && config.state.active_view_id === config.state?.active_view_data?.id || last_updated_at.current !== config.state.last_updated_at) {
            let new_cols = [...buildGridStartingCols(config)];
            setCols(new_cols);
            current_id.current = config.state.active_view_id;
            last_updated_at.current = config.state.last_updated_at;
        }
    }, [config,config.state.active_view_id,config.state.last_updated_at]);

    useEffect(() => {
        if (config?.fns?.onUpdateAttributes) {
            config.fns.onUpdateAttributes(cols.map(a => a._id).filter(a => a !== 'id'));
        }
    }, [cols]);

    function addColumn(col_data) {
        setCols([...cols, col_data])

        // add toast
        config.fns?.addToast({
            intent: "success",
            text: `${col_data.label} added to view`
        })
    }

    function removeColumn(col_id) {
        setCols(cols.filter(col => col.accessorKey !== col_id));
    }

    function handleReorderCols(result) {

        if (!result.destination) {
            return;
        }

        const locked_offset = 0;

        const items = Array.from(cols);
        const [reorderedItem] = items.splice(locked_offset + result.source.index, 1);
        items.splice(locked_offset + result.destination.index, 0, reorderedItem);

        setCols(items);
    }

    return (
        <>
            {config.state.show_toolbar&&<ContactsPrimaryToolbar {...{handleReorderCols, cols, removeColumn, addColumn}} config={config}>
                {children}
            </ContactsPrimaryToolbar>}

            <GridViewComponent renderEmptyState={renderEmptyState} config={config} cols={cols}
                               inline_sidebar_width={inline_sidebar_width}
            />
        </>
    );
}