import React, {useEffect, useState} from "react";
import AsyncCreatableSelect from 'react-select/async-creatable';
import {advanced_select_styles} from "./styles";
import {advanced_select_components} from "./components";
import {collection, limit, endAt, orderBy, query, startAt, getDocs} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";

const umlautMap = {
    '\u00dc': 'ue',
    '\u00c4': 'Ae',
    '\u00d6': 'oe',
    '\u0153': 'oe',
    '\u00fc': 'ue',
    '\u00e4': 'ae',
    '\u00f6': 'oe',
    '\u04e6': 'Oe',
    '\u04e7': 'oe',
    '\u0450': 'e',
    '\u00df': 'ss',
};

export function replaceUmlaute(str) {
    return str.replace(new RegExp('[' + Object.keys(umlautMap).join('|') + ']', "g"),
        (a) => umlautMap[a]
    );
}

export function cleanQuery(str) {
    return replaceUmlaute(str).trim().replace(/ /g, '-').toLowerCase();
}

async function queryTaxonomyDB(q, taxonomy) {
    const ref = collection(db, "taxonomies", taxonomy, "options");

    let qu;

    if(q==="*") {
        qu = query(ref, limit(50));
    } else {
        qu = query(ref, orderBy('search_name'), startAt(q), endAt(q + "\uf8ff"), limit(50));
    }

    const snap = await getDocs(qu);

    let obj = {};

    snap.docs.forEach((doc) => {
        obj[doc.id] = {
            value: doc.id,
            label: doc.data().label
        };
    });
    return obj;
}

function getValue(selected,results) {
    if(!selected) {
        return "";
    }
    const a = Object.values(results).filter(a=>a.label===selected)[0];
    return a ? a.value : "";
}

function getShowValue(selected, results={}) {

    if(selected&&typeof selected === "object") {
        return selected;
    }
    const id = getValue(selected,results)
    return !selected ? "" : {
        label: selected,
        value: id
    }
}

function filterResults(res, existing, q) {
    const me = existing && Array.isArray(existing) ? existing.map(a => a.label) : [];
    const final = Object.values(res).filter(a => me.indexOf(a.label) === -1);
    if(q) {
        return final.filter(a=>cleanQuery(a.label).indexOf(q) !== -1)
    }
    return final;
}

export function CreateableAsyncSelect({
                                          autoFocus = false,
                                          multi = false,
                                          disabled,
                                          existing = [],
                                          taxonomy = 'fields_of_study',
                                          value = null,
                                          placeholder = "Start typing..",
                                          onChange = () => {
                                          }
                                      }) {
    const [query, setQuery] = useState("");
    const [selected, setSelected] = useState(value);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState({});
    const [last_q, setLastQ] = useState("");

    useEffect(function () {

        loadOptions("*", (rr)=>{
        });
    }, [])

    function loadOptions(q, cb) {
        const fq = cleanQuery(q);

        if (!loading && fq.length > 0 && last_q !== fq) {
            setLoading(true);

            queryTaxonomyDB(fq, taxonomy).then(res => {
                const new_results = {...results, ...res};
                setResults(new_results);
                setLastQ(fq);
                setLoading(false);

                cb(filterResults(new_results, existing, fq));
            })
        } else {
            cb(filterResults(results, existing, fq));
        }
    }

    function handleInputChange(q) {
        setQuery(q);
    }

    function handleChange(it) {

        if (!it) {
            setSelected(null);
            onChange('');
            setQuery("");
        } else {
            if (!multi) {
                setSelected(it);
                setQuery("");
            } else {
                setSelected([...it]);
            }

            onChange(it);
        }
    }

    const v_value = getShowValue(selected,results);

    return (
        <AsyncCreatableSelect
            cacheOptions
            backspaceRemovesValue
            captureMenuScroll
            isDisabled={disabled}
            placeholder={placeholder}
            defaultOptions={filterResults(results, existing)}
            onInputChange={(v) => handleInputChange(v)}
            autoFocus={autoFocus}
            menuShouldScrollIntoView
            isMulti={multi}
            classNamePrefix={"dropdown-selected"}
            menuPortalTarget={document.body}
            styles={advanced_select_styles}
            components={advanced_select_components}
            isClearable
            isValidNewOption={()=>false}
            onChange={(v) => handleChange(v)}
            value={v_value}
            loadOptions={(q, cb) => loadOptions(q, cb)}
        />
    );
}