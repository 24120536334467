import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {FieldInput} from "../../../../m3/_legacy_components/form/field";
import {SectionHeader} from "../../../../m3/_legacy_components/entity-grid";
import {TextAction} from "../../../auth/sign-in";
import {saveItemToLocalStorage} from "../../../../m3/utilities/localstorage";
import {useCommunity} from "../../../../config/community";
import {SidebarSectionEditor} from "../../../../m3/layouts/layout/section-builder/sidebar-wrapper";
import {Badge} from "../../../../m3/_legacy_components/user-email-manager";
import {getNotificationColorOptions, m3_generateTheme} from "../../../../m3/utilities/color-system";
import M3_A_Icon from "../../../../m3/atoms/icon";
import {MoonIcon, SunIcon} from "@heroicons/react/24/outline";
import M3_A_FormControl from "../../../../m3/atoms/form-control";
import M3_A_Form from "../../../../m3/atoms/form";
import M3_A_Select from "../../../../m3/atoms/select";
import M3_A_ColorInput from "../../../../m3/atoms/color-input";
import M3_A_Text from "../../../../m3/atoms/text";
import {authFetch} from "../../../../../api/network";

const PreviewDiv = styled.div`
    background-color: ${props => props.active ? props.split[2] : props.split[0]};

    :hover {

        background-color: ${props => props.active ? "" : props.split[4]};
    }
`;

function PreviewItem({active, mini, split, has_update}) {
    return <PreviewDiv split={split} active={active}
                       className={`${mini ? "h-5" : "h-10"}  px-4 flex transition-colors gap-3 items-center`}>
        <div className="flex-none">
            <div className={`${mini ? "h-2.5 w-2.5" : "h-5 w-5"}  rounded-md bg-opacity-80`} style={{
                backgroundColor: active ? split[3] : split[5]
            }}>

            </div>
        </div>
        <div className="flex-grow">
            <div className={`${mini ? "h-1.5 w-16" : "h-2.5 w-28"} rounded-md bg-opacity-80`} style={{
                backgroundColor: active ? split[3] : split[5]
            }}></div>
        </div>
        <div className="flex-none">
            {has_update && <div className={`${mini ? "w-1 h-1" : "w-2.5 h-2.5"} rounded-full`} style={{
                backgroundColor: split[7]
            }}/>}
        </div>
    </PreviewDiv>
}

const TitleItem = styled.div`
    color: ${props => props.split[5]};

    :hover {
        background-color: ${props => props.split[4]};
    }
`;

export function SchemePreview({split, mini}) {
    return <div style={{
        backgroundColor: split[0],
    }} className={` border py-2 rounded-md overflow-hidden border-gray-200 ${mini ? "w-auto" : "w-72"}`}>
        {!mini && <TitleItem split={split} className="px-4 pt-2 pb-2 transition-colors mb-0.5 text-base font-medium">
            Theme Preview
        </TitleItem>}
        {mini && <div className='px-4 pb-2 pt-1'>
            <div style={{
                backgroundColor: split[5]
            }} className="h-1.5 rounded-full w-20 opacity-80"/>
        </div>}
        <div>
            <PreviewItem mini={mini} split={split} active/>
            <PreviewItem mini={mini} split={split} has_update/>
            <PreviewItem mini={mini} split={split}/>
        </div>
    </div>
}

const ColorPicker = ({val, label, onChange}) => {
    const [color, setColor] = useState(val);

    useEffect(function () {
        setColor(val)
    }, [val])

    useEffect(function () {
        onChange(color)
    }, [color])

    return (
        <FieldInput type="color" value={color} onChange={(id, v) => {
            onChange(v);
        }}/>
    );
};

/*
Scheme definitions
example: #F8F8FA,#121016,#359FDD,#FFFFFF,#FFFFFF,#686D72,#64CF5D,#DA5A62,#F8F8FA,#686D72
0: background
1: text
2: selected background
3: selected text
 */

/*
TODO
- pull in current color scheme used
- better live preview on desktop
- add set community default option if is admin
- use community default if no scheme is set
 */


function adjustPortal(add = true) {
    const portal = document.getElementsByClassName("fixed top-0 left-0 right-0 bottom-0 z-50 overflow-y-auto");
    const content_element = document.getElementById("content");
    const left_sidebar = document.getElementById("left-sidebar");
    if (portal[0] && content_element) {
        // add styled to the portal element
        if (add) {

            /*
            add following styles to this portal element
                left: 400px;
    background: none;
             */
            portal[0].style.left = (content_element.getBoundingClientRect().x + 32) + "px";
            portal[0].style.background = "none";

            // add margin-left: 0px; to child of portal element
            portal[0].children[0].style.marginLeft = "0px";

            // add     filter: brightness(0.8); to the content element
            content_element.style.filter = "brightness(0.8)";

            // disable pointer events on left sidebar
            left_sidebar.style.pointerEvents = "none";
        } else {
            // remove styles from portal element

        }
    }

    if (!add) {
        if (portal[0]) {

            portal[0].style.left = "";
            portal[0].style.background = "rgba(0, 0, 0, 0.75)";

            // remove styles from child of portal element
            portal[0].children[0].style.marginLeft = "";
        }

        if (content_element) {
            // remove styles from content element
            content_element.style.filter = "";

        }

        if (left_sidebar) {
            // enable pointer events on left sidebar
            left_sidebar.style.pointerEvents = "";
        }

    }
}


function SetDefaultScheme({scheme, madeDefault}) {
    const community = useCommunity();
    const [loading, setLoading] = useState(false);
    const [defaultScheme, setDefaultScheme] = useState(JSON.stringify(community?.theme_preferences));
    const is_default = defaultScheme === scheme;

    const handleSetDefault = async () => {
        setLoading(true);

        function handleRes() {
            setLoading(false);
            community.updateCommunityProfile();
            setDefaultScheme(scheme);
        }

        try {
            //   await saveItemToLocalStorage("default_scheme", scheme);
            authFetch("/communities/update-sidebar-scheme", handleRes, handleRes, "POST", {
                payload: {
                    scheme,
                    community_uid: community.id
                }
            })
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    return <div>
        {is_default ? <Badge color="success" size="sm">Default Theme</Badge> :
            <TextAction loading={loading} onClick={handleSetDefault} text="Make Default Theme"/>}
    </div>
}

const presets = [
    {
        label: "Orange",
        scheme: "#ffffff,#121016,#F2720C,#ffffff,#FAFAFB,#6a737c,#5FBA7D,#C91D2E,#ffffff,#6a737c"
    },
    {
        label: "Light",
        scheme: "#FFFFFF,#121016,#289CCF,#FFFFFF,#DDDDDD,#1D1C1D,#4AD594,#DB4E33,#FFFFFF,#1D1C1D"
    },
    {
        label: "Blue Sky",
        scheme: "#48cae4,#121016,#0096c7,#edfafd,#00b4d8,#edfafd,#0077b6,#0077b6,#48cae4,#edfafd"
    },
    {
        label: "Chester",
        scheme: "#2C3642,#121016,#29323E,#DBE6EC,#303B48,#94A7BD,#29C88E,#E55A5C,#2C3642,#94A7BD"
    },
    {
        label: "Darcula",
        scheme: "#2B2B2B,#121016,#171717,#FFC66D,#242424,#A9B7C6,#CC7832,#6A8759,#2B2B2B,#A9B7C6"
    },
    {
        label: "Edith",
        scheme: "#eef2f6,#121016,#2a4365,#eef2f6,#97aeb7,#161616,#4CAF50,#1966ff,#eef2f6,#161616"
    }
];

function isValidHexColor(str) {
    return str.match(/^#[a-f0-9]{6}$/i) !== null;
}

export const m3_style_presets = [
    {
        label: "Sage",
        scheme: {
            accent_color: "#5FBA7D",
            brightness: 2,
            mode: "light",
            notification_color: "#ff0000"
        }
    },
    {
        label: "Fig",
        scheme: {
            accent_color: "#532D3B",
            brightness: 4,
            mode: "light",
            notification_color: "#0066ef"
        }
    },
    {
        label: "Orange",
        scheme: {
            accent_color: "#F37A0B",
            brightness: 2,
            mode: "light",
            notification_color: "#ff8000"
        }
    },
    {
        label: "Lemon",
        scheme: {
            accent_color: "#FFD054",
            brightness: 1,
            mode: "light",
            notification_color: "#1ea619"
        }
    },
    {
        label: "Cherry",
        scheme: {
            accent_color: "#8B0A0B",
            brightness: 2,
            mode: "light",
            notification_color: "#ff0000"
        }
    },
    {
        label: "Lavender",
        scheme: {
            accent_color: "#B4A7D5",
            brightness: 3,
            mode: "light",
            notification_color: "#000000"
        }
    },
    {
        label: "Blueberry",
        scheme: {
            accent_color: "#4B639A",
            brightness: 3,
            mode: "light",
            notification_color: "#ff8000"
        }
    },
    {
        label: "Custom",
        scheme: {
            accent_color: "*",
            brightness: 2,
            mode: "light",
            notification_color: "#ff0000"
        }
    }
];

function BrightnessSelector({onChange, value}) {


    return <div className="flex gap-2 items-center text-gray-500">
        <div>
            <M3_A_Icon icon={<SunIcon/>} size="sm"/>
        </div>
        <div style={{paddingTop: "5px"}}>
            <input onChange={onChange} type="range" min={1} max={5} value={value} className="slider"
                   id="myRange"/>
        </div>
        <div>
            <M3_A_Icon icon={<SunIcon/>} size="xs"/>
        </div>
    </div>
}

/*
  <ColorPicker val={m3_scheme?.accent_color || "#e37d7d"} label="Accent Color"
                             onChange={new_color => {
                                 if (isValidHexColor(new_color)) {
                                     const new_scheme = {...m3_scheme};
                                     new_scheme.accent_color = new_color;
                                     setM3Scheme(new_scheme);
                                     setChanged(true);
                                 }
                             }}/>
 */

function buildM3Preview(active_theme) {
    return [
        active_theme.background,
        "#ffffff",
        active_theme.background_active,
        active_theme.background_active_text,
        active_theme.background_hover,
        active_theme.background_text,
        active_theme.background,
        active_theme.notification,
    ]
}

export function CustomColorSelect({options, onSelect, value}) {
    const non_custom_option_selected = options.findIndex(c => c.scheme.accent_color === value);
    return <div className="grid gap-4" style={{gridTemplateColumns: "repeat(auto-fill, minmax(4rem, 1fr))"}}>
        {options.map(c => {
            const active = c.scheme.accent_color === value || (c.scheme.accent_color === "*" && non_custom_option_selected === -1);
            return <div className="inline-flex flex-col gap-1 items-center justify-center" key={c.label}>
                <div onClick={() => {
                    onSelect(c.scheme)
                }}
                     className={`w-12 h-12 cursor-pointer p-0.5 border-2 rounded-full ${active ? "border-blue-500" : "border-gray-300 hover:border-gray-400"}`}>
                    <div className="h-10 w-10 border border-gray-200 rounded-full" style={{
                        backgroundColor: c.scheme.accent_color
                    }}/>
                </div>
                <M3_A_Text size="sm" weight="font-medium" color="text-gray-700">{c.label}</M3_A_Text>
            </div>
        })}
    </div>
}

export function M3ColorSchemeEditor({}) {
    const community = useCommunity();
    const [scheme, setScheme] = useState(community.theme_preferences);
    const [changed, setChanged] = useState(false);

    function handleApplyChanges() {
        saveItemToLocalStorage(`community_scheme_m3-${community.id}`, {
            scheme: scheme
        });
        community.setThemePreferences(scheme);
        setChanged(false);
    }

    const generated_scheme = m3_generateTheme(scheme?.accent_color, scheme?.mode, scheme?.brightness, scheme?.notification_color);

    const active_theme = generated_scheme.derived[scheme?.mode || "light"];

    const notification_options = getNotificationColorOptions(scheme?.accent_color, scheme?.mode, scheme?.brightness);

    const preview = buildM3Preview(active_theme);
    return <div>
        <div className="w-full pt-4 pb-8">
            <div className="max-w-xs mx-auto">
                <M3_A_FormControl label="Theme Preview">
                    <SchemePreview mini split={preview}/>
                </M3_A_FormControl>
            </div>
        </div>
        <SectionHeader size="text-lg" title="Customize Theme"
                       actions={changed && <TextAction text="Save & Apply" onClick={() => handleApplyChanges()}/>}/>
        <div className="pt-2">
            <M3_A_FormControl label="System Themes" caption="Choose one of the below or create your own!">
                <CustomColorSelect value={scheme?.accent_color || "#e37d7d"} onSelect={new_scheme => {
                    setScheme({...new_scheme});
                    setChanged(true);
                }} options={m3_style_presets}/>

            </M3_A_FormControl>
        </div>

        <M3_A_Form className="grid grid-cols-2 gap-3 pt-4">
            <M3_A_FormControl label="Accent Color">
                <M3_A_ColorInput value={scheme?.accent_color || "#e37d7d"} onChange={e => {
                    if (!e?.target?.value) {
                        return;
                    }
                    const new_color = e.target.value;
                    if (isValidHexColor(new_color)) {
                        const new_scheme = {...scheme};
                        new_scheme.accent_color = new_color;
                        setScheme(new_scheme);
                        setChanged(true);
                    }
                }}/>
            </M3_A_FormControl>
            <div className={`${scheme?.mode === "dark" ? "opacity-60 cursor-not-allowed pointer-events-none" : ""}`}>
                <M3_A_FormControl caption="Only applies to themes in light mode." label="Brightness">
                    <BrightnessSelector onChange={e => {
                        const new_scheme = {...scheme};
                        new_scheme.brightness = parseInt(e.target.value);
                        setScheme(new_scheme);
                        setChanged(true);
                    }} value={scheme?.brightness || 5}/>
                </M3_A_FormControl>
            </div>
            <M3_A_FormControl label="Mode">
                <M3_A_Select onChange={e => {
                    const new_scheme = {...scheme};
                    new_scheme.mode = e.target.value;
                    setScheme(new_scheme);
                    setChanged(true);
                }} value={scheme?.mode || "light"} options={[
                    {
                        label: "Light",
                        value: "light"
                    },
                    {
                        label: "Dark",
                        value: "dark"
                    },
                    {
                        label: "Auto",
                        value: "auto"
                    }
                ]}/>
            </M3_A_FormControl>
            <M3_A_FormControl label="Notification Color">
                <M3_A_Select onChange={e => {
                    const new_scheme = {...scheme};
                    new_scheme.notification_color = e.target.value;
                    setScheme(new_scheme);
                    setChanged(true);
                }} value={scheme?.notification_color} options={notification_options}/>
            </M3_A_FormControl>

        </M3_A_Form>

        {community.is_admin && <div className="pt-2">
            <SetDefaultScheme scheme={JSON.stringify(scheme)}/>
        </div>}
    </div>
}

export function MemberPreferencesStyle({community}) {

    useEffect(function () {
        adjustPortal(true)
        return () => {
            adjustPortal(false)
        }
    }, [])

    return <div className="grid gap-8" style={{}}>

        <div className="">
            <M3ColorSchemeEditor/>
        </div>
        {community.is_admin && <div>
            <SidebarSectionEditor/>
        </div>}
    </div>
}