import dayjs from "dayjs";

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)