import React, {useEffect, useRef, useState} from "react";
import Field from "../../../../m3/_legacy_components/form/field";
import {useCommunity} from "../../../../config/community";
import {useToasts} from "../../../../config/toasts";
import {authFetch, externalAuthFetch} from "../../../../../api/network";
import DataWrapper from "../../../../m3/_legacy_components/data-wrapper";
import {PrettyCheckbox, PrettyRadio} from "../../../../m3/_legacy_components/toggle";

function SectionTitle({children, large}) {
    if (large) {
        return <div className="font-semibold pt-0.5 text-lg text-gray-800">
            {children}
        </div>
    }
    return <div className="font-semibold pt-0.5 text-sm text-gray-800">
        {children}
    </div>
}

/*
 <div>
            SCHEDUlE
            <div>
                You'll only receive notifications in the hours you choose. Outside of those times, notifications will be paused. Learn more
            </div>
            <div>
                allow notifications
                <div>
                    every day or weekdays / TIME to TIME
                </div>
            </div>
        </div>
 */

/*
Notification types:
follows
activity: likes, replies, mentions, close thread
community membership application actions
role
group membership
profile data changed
weekly summary
direct messages


unsubscribe / subscribe to mailing list
 */

export function RadioGroup({options = [],inline, onChange, selected}) {

        return <div className={inline?"flex space-x-4":""}>
        {options.map((opt, key) => {
            return <PrettyRadio
                key={key}
                onChange={() => onChange(opt)}
                label={opt.label}
                value={selected === opt.value}
            />;
        })}
    </div>
}

const email_summary_options = [
    {
        label: "Immediately (15 min later)",
        default_schedule: "* * * * *",
        value: "immediately"
    },
    {
        label: "Daily",
        value: "daily",
        default_schedule: "0 9 * * *",
        desc: "choose time"
    },
    {
        label: "Weekly",
        value: "weekly",
        default_schedule: "0 9 * * 1", // monday
        desc: "choose day of week and time"
    },
    {
        label: "Monthly",
        value: "monthly",
        default_schedule: "0 9 1 * *",
        desc: "choose day of month and time"
    },
    {
        label: "Never",
        default_schedule: "* * * * *",
        value: "never"
    }
]

const notify_me_about = [
    {
        label: "All Messages",
        value: "all"
    },
    {
        label: "Organizers or members I follow",
        value: "custom"
    },
    {
        label: "Never",
        value: "none"
    }
]


function GroupSetting({data, handleChange, setting}) {
    const nma = setting ? setting.notify_me_about : "all";
    return <div className="rounded-lg items-center flex border border-gray-200 px-4 py-2">
        <div className="flex-grow">
            <div>{data.name}</div>
        </div>
        <div className="w-40">

                <select value={nma} onChange={(e) => {
                    //
                    handleChange({
                        notify_me_about: e.target.value,
                        mailing_list_subscribed: true
                    })
                }} name="notify-me-about" className="htmlselect">
                    {notify_me_about.map(opt => {
                        return <option key={opt.value} value={opt.value}>{opt.label}</option>
                    })}
                </select>
        </div>
    </div>
}

/*
* * * * *
Mi Ho Da Mo Wd

42 4 4 * * = 4th day of every month at 4:42 am at user timezone
 */

const mock_settings = {
    summary: {
        frequency: "daily",
        schedule: "0 9 * * *",
        // every day at 9:55
    },
    catch_up: true,
    messages: {
        notify_me_about: "all",
        replies_to_threads: true,
        auto_subscribe_to_mailing_lists: true
    },
    group_messages: {}
};

/*
 ["GROUPID"]: {
            notify_me_about: "all",
            mailing_list_subscribed: true
        }
 */

const freq_chooser = ["daily", "weekly", "monthly"];

function removeLeading0(a) {
    if(a==="0") {
        return "0";
    } else if(a==="00") {
        return "0";
    }
    return a.replace(/^0+/, '')
}

function convertToSchedule(m, h, dom, mon, weekday, tz) {
    const f_m = m || "*";
    const f_h = h || "*";
    const f_dom = dom || "*";
    const f_mon = mon || "*";
    const f_weekday = weekday || "*";

    return `${removeLeading0(f_m)} ${removeLeading0(f_h)} ${f_dom} ${f_mon} ${f_weekday}`;
}

function FrequencyChooser({frequency, tz, onChange, schedule}) {

    const [minutes, hours, dayofmonth, months, weekdays] = schedule.split(' ');

    if (frequency === "monthly") {
        return <div>
            <Field value={[dayofmonth, hours, minutes]} onChange={(id, nv) => {
                const [dom, hh, mm] = nv;
                onChange(convertToSchedule(mm, hh, dom, null, null, tz))
            }} label="Monthly on the:" type="month-day-time"/>
        </div>
    }

    if (frequency === "weekly") {
        return <div>
            <Field value={[weekdays, hours, minutes]} onChange={(id, nv) => {
                const [wd, hh, mm] = nv;
                onChange(convertToSchedule(mm, hh, null, null, wd, tz))
            }} label="Weekly on:" type="week-day-time"/>
        </div>
    }

    console.log("hours", hours, minutes)
    return <div>
        <Field value={[hours, minutes]} onChange={(id, nv) => {
            const [hh, mm] = nv;
            onChange(convertToSchedule(mm, hh, null, null, null, tz))
        }} label="Every day at:" type="day-time"/>
    </div>
}

async function saveChanges(ctx, pl) {
    const fpl = {
        payload: {
            ...ctx,
            ...pl
        }
    }
    return new Promise(resolve => {
        const res = (result) => {
            resolve(true);
        };
        authFetch("/notifications/update-settings", res, res, "POST", fpl);
    })
}

function buildGroupNotifications(member, settings) {

    let a = [];
    member.group_ids.forEach(gid => {
        a.push({
            id: gid,
            setting: settings.group_messages[gid] || null
        })
    })
    return a;
}

const default_tz = "America/Los_Angeles";

function buildNotificationsSummary(summary,tz) {
    console.log("buildNotificationsSummary",summary)
    console.log("TZ",tz)
    const tz_is_default = tz === default_tz;

    console.log("tz_is_default",tz_is_default)

    return {
        ...summary,

    }
}

function getNotificationPreferences(member,tz) {

    if (member && member.notification_settings) {
        return {
            ...member.notification_settings,
            summary: buildNotificationsSummary(member.notification_settings.summary,"America/Chicago")
        }
    }

    return {...mock_settings};
}

export function UserPreferencesNotifications() {
    const community = useCommunity();
    const toasts = useToasts();
    const tz = community.member.preferences.timezone||"America/Los_Angeles";
    const [settings, setSettings] = useState(getNotificationPreferences(community.member,tz));
    const isFirstRun = useRef(true);

    useEffect(function () {
        // as long as it's invalid, keep trying to update
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        handleSaveChanges();
    }, [settings])

    function handleSaveChanges() {

        const ctx = {
            community_uid: community.uid,
            member_id: community.member_id
        };

        const pl = {
            notification_settings: settings,
            timezone: tz
        };

        saveChanges(ctx, pl)
            .then(() => {
                community.updateMemberProfile();
                // toasts.addToast({intent:"success",text:"Notifications preferences saved"})
            })
    }

    function handleGroupChange(id,gc) {
        setSettings({
            ...settings,
            group_messages: {
                ...settings.group_messages,
                [id]: gc
            }
        })
    }

    const notif_groups = buildGroupNotifications(community.member, settings);

console.log("member",community.member)
    return <div className="space-y-4 divide-gray-200 divide-y">
        <div className="space-y-3">
            <SectionTitle large>General</SectionTitle>

            <div>
                <SectionTitle>Send me an email notification summary when I'm inactive:</SectionTitle>
                <RadioGroup selected={settings.summary.frequency} onChange={(a) => {
                    setSettings({
                        ...settings,
                        summary: {
                            ...settings.summary,
                            frequency: a.value,
                            schedule: a.default_schedule
                        }
                    })
                }} options={email_summary_options}/>
                <div className="pt-2">
                    {freq_chooser.includes(settings.summary.frequency) && <FrequencyChooser tz={tz} onChange={ns => {
                        setSettings({
                            ...settings,
                            summary: {
                                ...settings.summary,
                                schedule: ns
                            }
                        })
                    }} frequency={settings.summary.frequency} schedule={settings.summary.schedule}/>}
                </div>
            </div>
            <div>
                <SectionTitle>Catch-up</SectionTitle>
                <div className="pt-0.5">
                    <PrettyCheckbox active={settings.catch_up} onClick={()=>{
                        setSettings({
                            ...settings,
                            catch_up: !settings.catch_up
                        })
                    }} label="Notify me weekly with any unread updates"/>
                    <div className="text-xs pt-1 text-gray-600">
                        Notification created every Monday morning at 9am est
                    </div>
                </div>
            </div>
        </div>

        <div className="space-y-3 pt-2">
            <SectionTitle large>Messages</SectionTitle>
            <div>
                <SectionTitle>Notify me about:</SectionTitle>

                <div className="pt-1 space-y-0.5">
                    <RadioGroup selected="all" onChange={() => {
                    }} options={notify_me_about}/>
                </div>
            </div>
            <div>
                <PrettyCheckbox active={settings.messages.replies_to_threads} onClick={()=>{
                    setSettings({
                        ...settings,
                        messages: {
                            ...settings.messages,
                            replies_to_threads: !settings.messages.replies_to_threads
                        }
                    })
                }} label="Notify me about replies for threads I'm following"/>
            </div>
            <div>
                <PrettyCheckbox active={settings.messages.auto_subscribe_to_mailing_lists} onClick={()=>{
                    setSettings({
                        ...settings,
                        messages: {
                            ...settings.messages,
                            auto_subscribe_to_mailing_lists: !settings.messages.auto_subscribe_to_mailing_lists
                        }
                    })
                }} label="Auto-subscribe to Group mailing lists"/>
            </div>
        </div>


        <div className="space-y-3 pt-2">
            <SectionTitle large>Group-specific settings</SectionTitle>
            <div className="space-y-2">
                {notif_groups.map(g => {
                    return <DataWrapper id={g.id} type={`community_entities.${community.uid}.groups`}>
                        <GroupSetting handleChange={(gc)=>handleGroupChange(g.id,gc)} setting={g.setting} key={g.id}/>
                    </DataWrapper>
                })}
            </div>
        </div>

        <div>
            <div onClick={()=>{
                const res = (result) => {};

                const payload = {
                    community_uid: community.uid,
                    member_id: community.member_id
                };

                authFetch("/notifications/test-listener", res, res, "POST", {payload});
            }}>TEST</div>

        </div>
    </div>
}