import React from "react";
import M3_A_Text from "./text";

/*
* This is the Radio atom
 */

const _props = {};

function getStyles(size) {
    switch (size) {
        case 'xs':
            return {
                button: 'h-4 w-7 border-2',
                circle: 'h-3 w-3',
                circle_unchecked: 'translate-x-0',
                circle_checked: 'translate-x-3 '
            }
        default:
            return {
                button: 'h-6 w-11 border-2',
                circle: 'h-5 w-5 ',
                circle_unchecked: 'translate-x-0',
                circle_checked: 'translate-x-5 '
            }
    }
}

export default function M3_A_Toggle({
                                        size = "xs",
                                        label,
                                        name = "",
                                        onChange = () => {
                                        },
                                        disabled,
                                        on,
                                        default_on,
                                        as
                                    }) {

    const styles = getStyles(size);
    return (
        <div className="flex gap-2 items-center">
            <div className="flex-grow">
                <M3_A_Text color="text-gray-800" weight="font-semibold" size="sm">{label}</M3_A_Text>
            </div>
            <div className="flex-shrink-0">
                {!on && <button onClick={(e) => {
                    e.stopPropagation()
                    onChange(true)
                }} type="button"
                                className={`bg-gray-200 relative real-outline-none inline-flex flex-shrink-0 ${styles.button} border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200`}
                                aria-pressed="false">
                        <span
                            className={` inline-block ${styles.circle} ${styles.circle_unchecked} rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}/>
                </button>}
                {on && <button onClick={(e) => {
                    e.stopPropagation()
                    onChange(false)
                }} type="button"
                               className={`bg-blue-500 real-outline-none relative inline-flex flex-shrink-0 ${styles.button} border-blue-500 rounded-full cursor-pointer transition-colors ease-in-out duration-200`}
                               aria-pressed="false">
                        <span
                            className={`outline-none inline-block ${styles.circle} ${styles.circle_checked} rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}/>
                </button>}
            </div>
        </div>
    );
}