import React from 'react';
import {collection, getDocs, limit, query, where} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {useCommunity} from "../../../config/community";
import {updateFlowStatus} from "../flow";
import {FlowHandler} from "../../../features/gates/flow/handler";
import ReactDOM from "react-dom";
import {InlineLoader} from "../../../m3/_legacy_components/admin-activity";
import EmptyState from "../../../m3/_legacy_components/empty-state";
import Button from "../../../m3/_legacy_components/button";
import {useUnaverse} from "../../../config/unaverse";
import {createCommunityOnboardingFlow} from "../../manage/pages/community-profile";

export function FullScreenLoader() {
    return <div className="fixed top-0 left-0 bg-white right-0 z-50 bottom-0 h-screen">
        <div className="flex flex-col items-center justify-center h-full">
            <InlineLoader/>
        </div>
    </div>
}

export async function fetchFlow(cid, type = 'onboarding') {
    const flow_ref = collection(db, "community_content", cid, "flows");
    const q = query(flow_ref, where("type", "==", type), limit(1));
    const snapshot = await getDocs(q);
    if (snapshot.empty) {
        return null;
    } else {
        return {
            id: snapshot.docs[0].id,
            ...snapshot.docs[0].data()
        };
    }
}

export function parseFlow(flow) {
    const {branding, header, features, state, intro, outtro, steps} = flow;
    return {
        id: flow.id,
        name: flow.name,
        description: flow.description,
        type: flow.type,

        branding: JSON.parse(branding),

        header: JSON.parse(header),

        features: JSON.parse(features),

        state: JSON.parse(state),

        intro: JSON.parse(intro),

        outtro: JSON.parse(outtro),

        steps: JSON.parse(steps)
    }
}

function CreateOnboardingButton({callback}) {
    const [loading, setLoading] = React.useState(false);
    const community = useCommunity();

    function createOnboarding() {
        setLoading(true);

        createCommunityOnboardingFlow(community.uid, community.profile, community.custom_fields)
            .then(resp => {
                setLoading(false);
                if (callback) {
                    callback();
                }
            })
    }

    return <Button loading={loading} onClick={createOnboarding} text="Create"/>;
}

function isPreview() {
    // check for ?preview=true
    return window.location.search.includes("preview=true");
}

export function OnboardingPage({onComplete}) {
    const community = useCommunity();
    const [flow, setFlow] = React.useState(null);
    const [error, setError] = React.useState(null);
    const is_preview = isPreview();

    React.useEffect(() => {
        load();
    }, [community.uid])

    function load() {
        fetchFlow(community.uid)
            .then((fl) => {
                if (!fl) {
                    setError("not-found");
                    return;
                }
                setFlow(parseFlow(fl));
            })
        community.updateMemberProfile();
    }

    const context = {
        id: community.uid,
        uid: community.uid,
        domain: community.domain,
        member_id: community.member_id,
        user_uid: community.user_uid,
        community_uid: community.uid,
        important_custom_fields: community.important_custom_fields,
        member: community.member,
        custom_fields: community.custom_fields,

        profile: community?.profile,

        profile_definitions: community?.profile_definitions,

        member_types: community?.member_types,

        member_access: community?.member_access,

        refreshData: () => {
            community.updateMemberProfile();
        }
    }

    function handleStart() {
        if(is_preview) {
            return;
        }
        updateFlowStatus(community, {
            flow_id: flow.id,
            status: "started",
            flow_name: flow.name
        })
            .then((resp) => {
                console.log("done", resp)
            })
    }

    function handleComplete() {
        if(is_preview) {
            // close tab
            window.close();
            return;
        }
        updateFlowStatus(community, {
            flow_id: flow.id,
            status: "complete",
            flow_name: flow.name
        })
            .then((resp) => {
                console.log("done", resp)
                if(onComplete) {
                    onComplete();
                }
            })
        community.updateMemberProfile();
    }

    let content;

    if (error) {
        let btn = community.is_admin ? <CreateOnboardingButton callback={() => load()}/> : null;
        return <div className="flex h-full items-center justify-center">
            <EmptyState
                title="No Onboarding Flow Found"
                button={btn}
            />
        </div>
    }

    if (!flow) {
        content = <FullScreenLoader/>
    } else {
        console.log("FLOW",flow)
        content = <div className="fixed top-0 left-0 bg-white right-0 z-50 bottom-0 h-screen">
            <FlowHandler is_preview={is_preview} manifest={flow} onStart={handleStart} onComplete={handleComplete} context={context}/>
        </div>;
    }

    return ReactDOM.createPortal(
        content,
        document.body
    );
}