import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {ArrowRightIcon, MagnifyingGlassIcon, XMarkIcon} from "@heroicons/react/20/solid";
import {InlineLoader} from "../admin-activity";

const XWrapper = styled.div`
cursor: pointer;
  > svg {
    height: 20px;
    width: 20px;
  }
`;

const SearchWrapper = styled.div`

  > svg {
    height: 20px;
    width: 20px;
  }
`;

export function LocalSearchBar({
                                   query,
                                   enable_button,
    loading,
                                   placeholder,
                                   onChange = () => {},
                                   onSearchSubmit = () => {},
                                   onBlur = () => {},
                                   async = false,
                                   clearResults = () => {},
                                   onFocus = () => {
                                   },
                                   simple,
                                   autoFocus = false
                               }) {
    const [term, setTerm] = useState('');
    const [debouncedTerm, setDebouncedTerm] = useState(term);
    const isFirstRun = useRef(true);

    useEffect(function () {
        if(!query) {
            setDebouncedTerm("")
        }
    }, [query])

    useEffect(() => {
        const timer = setTimeout(() => setTerm(debouncedTerm), 200);
        return () => clearTimeout(timer);
    }, [debouncedTerm])

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        if (term) {
            onSearchSubmit(term);
        } else {
            clearResults();
        }
    }, [term]);


    return <div className="relative">
        <div className=" text-sm">
            <input onFocus={onFocus} onBlur={onBlur} style={{fontSize: '16px'}} autoFocus={autoFocus}
                   className={`pr-8 py-2  placeholder-gray-500 w-full rounded-lg ${simple ? "opacity-80 transition-opacity focus-within:opacity-100" : "pl-3  input-focus border border-gray-400"} `}
                   placeholder={placeholder||`Search..`} value={debouncedTerm}  onChange={e => {
                const v = e.target.value;
                setDebouncedTerm(v);
                onChange(v);
            }}/>
        </div>
        <div className="absolute gap-2 right-0 top-0 bottom-0 flex items-center justify-center pr-3">
            {loading && <XWrapper className="flex items-center">
                <InlineLoader mini />
            </XWrapper>}
            {debouncedTerm && <XWrapper onClick={() => setDebouncedTerm('')} className="text-gray-400 hover:text-gray-600 cursor-pointer">
                <XMarkIcon/>
            </XWrapper>}
            {!debouncedTerm && <SearchWrapper className="text-gray-400 hover:text-gray-600 ">
                <MagnifyingGlassIcon/>
            </SearchWrapper>}
        </div>
    </div>
}