import React, {useState} from "react";
import styled from "styled-components";

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(96px, 1fr));
  grid-gap: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  > div {
    padding: 0.75rem;
    display: grid;
    place-items: center;
    &::before {
      content: "";
      display: block;
      padding-bottom: 100%;
      grid-area: 1 / 1 / 2 / 2;
    }
    img {
      width: 100%;
      // height: 100%;
      // object-fit: contain;
      grid-area: 1 / 1 / 2 / 2;
    }
  }
`;

function getBorderRadius(key) {
    if(key%3===0) {
        return `rounded-r-none`;
    } else if(key%3===1) {
        return `rounded-none`;
    } else {
        return `rounded-l-none`;
    }
}

export default function PinboardImages({images=[],openImageViewer=()=>{}}) {
    const slice = images.slice(0,3);
    return <ImageGrid>
        {slice.map((img,key)=>{
            return <div key={`${img.id}${key}`}
                        onClick={()=>{
                            openImageViewer(img.url, "#000000", images.map(a=>{
                                return {
                                    ...a,
                                    bg_color: "#000000"
                                }
                            }), key);
                        }}
                        className={`rounded-lg cursor-pointer bg-gray-200 ${getBorderRadius(key)}`}
                        style={{
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundImage: `url(${img.url})`
                        }}
             />
        })}
    </ImageGrid>
}