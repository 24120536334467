import React, {useEffect, useState} from "react";
import MetaHeader from "../../../../m3/_legacy_components/meta-header";
import Accordion from "../../../../m3/_legacy_components/accordion";
import EntityTabs from "../../../../m3/_legacy_components/entity-tabs";
import DetailList from "../../../../m3/_legacy_components/detail-list";
import {buildCardMeta} from "../../../../m3/utilities/build-card-meta";
import {useCommunity} from "../../../../config/community";
import {useUnaverse} from "../../../../config/unaverse";
import {useToasts} from "../../../../config/toasts";
import {useNavigate} from "react-router-dom";
import {smoothScrollTo} from "../../leaders";
import InternalNotes from "../../../../m3/_legacy_components/notes";
import {ManageModalFrame} from "../../manage-member";
import AdminActivity from "../../../../m3/_legacy_components/admin-activity";
import {AtSymbolIcon, UserIcon} from "@heroicons/react/20/solid";
import {DLCAssignee, DLCHandle} from "../../member/detail-list-components";
import {ChangeAssigneeModal} from "./change-assignee-modal";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../../../config/setup-firestore";
import RoleIcon from "../../../../m3/icons/_legacy/custom/RoleIcon";
import {PastAssignees} from "../past-assignees";
import {authFetch} from "../../../../../api/network";
import {ChangeHandleModal} from "../../manage-member/change-handle";
import {valuePipeline} from "../../../../m3/_legacy_components/profile-field-renderer/value-pipeline";
import {buildImageUrl} from "../../../../../common/utilities/images";

function buildHeader(data, community, actions = []) {
    let title, image;

    title = data.name || "";

    image = buildImageUrl(data?.assignee_data?.profile_picture, '_medium', community.default_member_profile_picture);

    return {
        title,
        image,

        top: {
            type: "entity-icon",
            name: "Position",
            icon: <RoleIcon/>
        },
        meta: buildCardMeta({
            meta: {}
        }, [], {
            is_admin: community.admins.all_user_uids[data.id]
        }),
        actions_dropdown: actions
    }
}

function buildActions(member, community, fns) {
    let options = [];

    return options;
}


function buildTags(position) {
    let a = [];

    if(!position.assignee) {
a.push({
            title: "Unassigned",
            color: "secondary"
        })
    }

    return a;
}

function buildPositionSections(data, community, flags, hc, changeHandle, fns) {
    let a = [];

    a.push({
        title: "Details",
        actions: [],
        items: [
            {
                interactive: true,
                onClick: () => {},
                icon: <UserIcon/>,
                label: "Assignee",
                type: 'assignee',
                component: <DLCAssignee openModal={()=>fns.changeAssignee()} data={data}/>,
                value: data.assignee,
                meta: {}
            },
            {
                label: "Handle",
                icon: <AtSymbolIcon />,
                type: 'string',
                component: <DLCHandle openModal={fns.openChangeHandleModal} data={data} />,
                value: valuePipeline(data.handle, {type: 'handle'}),
                meta: {}
            },
        ]
    });

    a.push({
        title: "Past Assignees",
        actions: [],
        items: [
            {
                interactive: true,
                onClick: () => {},
                label: "Past Assignees",
                component: <div>
                    <PastAssignees data={data} id={data.id} getRole={() => {
                        fns.handleUpdate()
                    }}/>
                </div>
            }
            ]
    })

    return a;
}

export async function changePositionHandle(community, id, handle) {
    return new Promise((resolve, reject) => {
        const res = (resp) => {
            resolve(resp);
        };

        const payload = {
            new_handle: handle,
            community_uid: community.uid,
            member_id: community.member_id,
            id: id,
            user_uid: community.member.user_uid,
        };

        authFetch("/roles/change-handle", res, res, "POST", {payload})
    });
}

export function ManagePosition({onClose, data = {}}) {
    const all_tabs = [{label: "Activity"}, {label: "Notes"}];
    const [dt, updateDt] = useState({...data});
    const [tab, setTab] = useState(all_tabs[0].label);
    const toasts = useToasts();
    const [loading, setLoading] = useState('');
    const navigate = useNavigate();
    const [modal, setModal] = useState("");
    const [modal_data, setModalData] = useState(null);
    const unaverse = useUnaverse();
    const community = useCommunity();

    function scrollTo(t = "Profile", sid = "edit-details") {
        setTab(t)
        setTimeout(function () {
            smoothScrollTo("modal-scrollarea", sid, -160);
        }, 50)
    }

    const fns = {
        changeAssignee: () => {
            console.log("FN")
            setModal("assignee");
        },
        handleUpdate,
        openChangeHandleModal: () => {
            setModal("change-handle");
        }
    };

    const actions = buildActions(dt, community, fns);

    const mh = buildHeader(dt, community, actions);

    function hc(f, v) {
        updateDt({...dt, [f]: v})
    }

    function changeHandle(h) {
        setLoading("handle");
        changePositionHandle(community, dt.id, h).then((resp) => {
            setLoading('');
            setModal("");
            updateDt({...dt, handle: h})
            toasts.addToast({text: `Position handle changed`, intent: 'success'});
        })
    }

    function getPositionData() {
        const ref = doc(db, "community_entities", community.id, "roles", dt.id);
        getDoc(ref).then((doc) => {
            if (doc.exists()) {
                updateDt({...doc.data(), id: doc.id});
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        })
    }

    function handleUpdate() {
        getPositionData();
    }

    function renderTab() {
        //  <AdminActivity member_id={dt.id} community={community} member={dt}/>
        switch (tab) {
            case "Activity":
                return <div className="p-4">
                    <AdminActivity scope_id={dt.id} scope={"role"} community={community} scope_data={dt}/>
                </div>;
            case "Notes":
                return <div className="p-4">
                    <InternalNotes scope={"role"} scope_id={dt.id}/>
                </div>
            case "Past Assignees":
                return <div className="p-4">
                    TODO
                </div>
            default:
                return null;
        }
    }

    const flags = {};

    const tags = buildTags(dt);

    const tabs = <EntityTabs onChangeTab={t => setTab(t)} active={tab} tabs={all_tabs}/>;

    const header = <MetaHeader tags={tags} {...mh} tabs={tabs} onClose={onClose}/>;

    const sections = buildPositionSections(dt, community, flags, hc, changeHandle, fns);

    const default_email = dt?.account_email || "";

    const sidebar = <>
        {sections.map((sec, index) => {
            return <div key={index} className="px-4 py-1">
                <Accordion open={true} title={sec.title}>
                    <DetailList items={sec.items}/>
                </Accordion>
            </div>
        })}
    </>;

    return <ManageModalFrame header={header} sidebar={sidebar} tabs={tabs}>
        {renderTab()}
        {modal === "assignee" && <ChangeAssigneeModal data={dt} onClose={()=>{
            setModal("")
        }} onChange={()=>handleUpdate()} />}
        {modal === 'change-handle' &&
            <ChangeHandleModal loading={loading === 'handle'} community_uid={community.id} data={{handle: dt.handle}}
                               onConfirm={(h) => {
                                   changeHandle(h)
                               }}
                               onClose={() => setModal("")} onCancel={() => {
                setModal("");
            }} type='role'/>}
    </ManageModalFrame>
}