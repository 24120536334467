import React, {useState} from "react";
import M3_C_PromptDialog from "../../../../m3/components/prompt-dialog";
import M3_A_FormControl from "../../../../m3/atoms/form-control";
import M3_A_Select from "../../../../m3/atoms/select";

export default function ContactsModalBulkEdit({
                                                  selected,
                                                  ctx,
                                                  handleSuccess = () => {
                                                  },
                                                  handleClose = () => {
                                                  }
                                              }) {
    const [field, setField] = React.useState("");
    const [loading, setLoading] = useState(false);

    return <M3_C_PromptDialog title={"Bulk Edit Members"} confirm_loading={loading}
                              onConfirm={() => {
                                  handleSuccess();
                              }} confirm_label="Apply Changes" confirm_disabled={true} onClose={() => {
        handleClose();
    }}>
        <M3_A_FormControl label="Field">
            <M3_A_Select onChange={e => {
                setField(e?.target?.value);
            }} value={field} options={[
                {
                    value: "membership_status",
                    label: "Membership Status"
                },
                {
                    value: "table",
                    label: "Table"
                }
            ]}/>
        </M3_A_FormControl>
        <M3_A_FormControl label="Value">
            Value
        </M3_A_FormControl>
    </M3_C_PromptDialog>
}