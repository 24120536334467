import {authFetch} from "../network";
import {logEngagementEvent} from "../../app/config/firebase-setup";

export function composer_handleSendReply(payload, {community}, res) {

    const final_payload = {
        user_uid: community.member.user_uid,
        reply_id: payload.reply_to_id,
        member_id: community.member_id,
        community_uid: community.uid,
        raw: payload.raw,
        attachments: payload.attachments,
        images: payload.images,
        reply_to_id: payload.reply_to_id,
        post_id: payload.post_id,
        plain_text: payload.plain_text
    }

    logEngagementEvent("create_reply", {}, community.uid);

    authFetch("/posts/reply", res, res, "POST", {payload: final_payload});
}