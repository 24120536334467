import React from "react";
import {SharedLinkPage} from "../../../../routes/auth/link";
import {useNavigate} from "react-router-dom";


export function FHB_JoinCommunity({state = {}, context = {}, handlers = {}}) {
const navigate = useNavigate();
    // todo set account_complete = true

    function handleJoinCommunity() {

        navigate(`/c/${context.domain}/onboarding?ref=flow&refid=joincommunity`);
    }

    return <div className="text-sm text-gray-800 pt-1">
        <SharedLinkPage joinCommunityCallback={handleJoinCommunity} passUserData={user=>{
        console.log('user', user)
        }} join_community cname={context.name} />
    </div>
}