import {authFetch} from "../../../../api/network";

export async function user_editProfile(unaverse, {profile,updated_fields}) {
    const payload = {
        ...profile,
        id: unaverse.user_uid,
        user_uid: unaverse.user_uid,
        _updated_fields: updated_fields
    };
    return new Promise(resolve => {
        const r = () => {
            resolve();
        };
        console.log("PAYLOAD",payload)

        authFetch('/users/update-own-profile', r, r, "POST", {payload});
    })
}

export async function user_changeProfilePicture(unaverse, {url, color}) {
    const p2 = {
        id: unaverse.id,
        user_uid: unaverse.user_uid,
        color: color,
        url: url
    };
    return new Promise(resolve => {
        const r = () => {
            unaverse.updateUserProfile()
                .then(up=>{
                    resolve({
                        ...up,

                    })
                });
        };
        authFetch('/users/set-profile-picture', r, r, "POST", {payload: p2});
    })
}