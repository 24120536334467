import React from 'react';
import M3_C_Header from "../components/header";
import {AtSymbolIcon, ChartBarIcon} from "@heroicons/react/24/outline";
import M3_C_FilterBar from "../components/filter-bar";
import M3_C_Layout from "../components/layout";

/*
* This is the MobileSpace example
 */

export default function M3_E_MobileSpace({}) {
    const config = {
        size: "xl",

        onTitleClick: () => {
        },


        watch_scroll_id: "scroll-main",
        title: "New York Hub",
        subtitle: "Open Group, 202 members",

        background: {
            url: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/cover_photos%2F0JJB6KF6X7?alt=media"
        },

        context_bar: {
            variant: "nav",

            onBack: () => {
                console.log("back");
            },

            actions: [
                {
                    type: "icon",
                    icon_name: "add"
                }
            ]
        }
    };

    const filter_bar_config = {
        onChange: (item_id) => {
        },
        active_id: "all-posts",
        items: [
            {
                id: "all-posts",
                label: "All Posts"
            },
            {
                id: "announcements",
                label: "Announcements"
            },
            {
                id: "discussions",
                label: "Discussions"
            },
            {
                icon: <AtSymbolIcon/>,
                id: "questions",
                label: "Questions"
            },
            {
                icon: <ChartBarIcon/>,
                id: "polls",
                label: "Polls"
            }
        ]
    };

    const main = <>
        <M3_C_Header {...config} />
        <div className="pt-10 px-4 flex flex-col gap-4 min-h-[200vh]">
            <div>
                Buttons
            </div>
            <div>
                Organized by Daniel and 2 others
            </div>
            <div className="-mx-4">
                <M3_C_FilterBar x_padding="px-4" {...filter_bar_config} />
            </div>
            <div>
                <div>
                    POST ITEM
                </div>
            </div>
        </div>
    </>;

    const sidebar = <div>
        Sidebar content!
    </div>;

    return <div className="relative h-screen bg-green-200 overflow-auto flex">
        <div className="w-[18rem] flex-noneh-screen">

        </div>
        <div className="flex-grow p-1 h-screen">
            <div className="bg-white rounded-xl" style={{height: "calc(100vh - 0.5rem)"}}>
                {main}
            </div>
        </div>
    </div>
}