import React from "react";
import styled from "styled-components";

/*
* This is the Spinner atom
 */

const _props = {
    size: {
        type: "string",
        required: false,
        default: "base"
    }
};

const Wrapper = styled.div`
  color: rgba(90, 90, 90, 0.2);
  position: relative;
  display: inline-block;
  border: 3px solid;
  border-radius: 50%;
  border-right-color: #5a5a5a;
  animation: rotate 1s linear infinite;
  
    ${props => props.inverted && `
        color: rgba(255, 255, 255, 0.2);
    border-right-color: #fff;
        `}
`;

const size_classes = {
    "sm": "w-4 h-4",
    "base": "w-6 h-6",
    "lg": "w-8 h-8",
    "xl": "w-12 h-12"
};

export default function M3_A_Spinner({
                                        size = "base",
    inverted
                                    }) {
    let classes = "";

    if (size_classes[size]) {
        classes += size_classes[size];
    }

    return (
        <Wrapper inverted={inverted} className={classes}/>
    );
}