import React from 'react';
import Button from "../../../../m3/_legacy_components/button";
import {Avatar} from "../../../../m3/_legacy_components/avatar";

export function CT_Invite({community}) {

    const pp = community.profile.profile_picture;

    return <div className="pt-3">
        <div>
            <Button size="lg" intent="success" text="Join Now" />
        </div>
        <div className="text-base text-gray-600 pt-3">For reference, here are your account details for {community.profile.name}:</div>
        <div className="border rounded-md mt-1.5 border-gray-200 p-4 flex flex-col items-center justify-center">
            <Avatar url={pp?`${pp}_medium?alt=media`:""} text={community.profile.name} />
            <div className="text-lg font-bold text-gray-800 mt-1">{community.profile.name}</div>
            <div className="text-base text-gray-600 mt-1">
            <div className="">
                URL: <a className="color-link">{window.location.origin}/{community.profile.handle}/home</a>
            </div>
            <div className="mt-1.5">
                Email: {`{{member.account_email}}`}
            </div>
            </div>
        </div>
    </div>
}