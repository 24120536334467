import React from "react";
import M3_A_Icon from "./icon";
import {m3_icon_map} from "../icons/icon-map";
import {m3_adjust_size} from "../utilities";

/*
* This is the NodeSelector atom
 */

const _props = {};


const variant_color_map = {
    "light": "text-gray-900/50",
    "dark": "text-white/50"
};

const variant_hover_map = {
    "light": "hover:bg-gray-900/10 active:bg-gray-900/20",
    "dark": "hover:bg-gray-100/10 active:bg-gray-100/20"
};

const margin_left_size_map = {
    "xs": "ml-px",
    "sm": "ml-px",
    "base": "ml-0.5",
    "lg": "ml-0.5",
    "xl": "ml-1",
};

const x_margin_padding_size_map = {
    "xs": "-mx-0.5 px-0.5",
    "sm": "-mx-0.5 px-0.5",
    "base": "-mx-1 px-1",
    "lg": "-mx-1 px-1",
    "xl": "-mx-1.5 px-1.5",
};

const y_margin_padding_size_map = {
    "xs": "-my-px py-px",
    "sm": "-my-px py-px",
    "base": "-my-px py-px",
    "lg": "-my-0.5 py-0.5",
    "xl": "-my-1 py-1",
};

export default function M3_A_NodeSelector({
                                              square,
                                              variant = "light",
                                              children,
                                              dropdown = true,
                                              onClick,
                                              icon_sx,
                                              sx,
                                              size = "base"
                                          }) {
    const icon_size = m3_adjust_size(size, -2);

    const icon_color = variant_color_map[variant];

    const background_hover = variant_hover_map[variant];

    const margin_left = margin_left_size_map[size];

    const y_padding = square ? y_margin_padding_size_map[m3_adjust_size(size,1)] : y_margin_padding_size_map[size];

    let style = {};

    if (sx) {
        style = sx;
    }

    return <span style={style}
                 className={`${x_margin_padding_size_map[size]} ${y_padding} cursor-pointer items-center ${background_hover} transition-colors inline-flex rounded-lg`}
                 onClick={() => {
                     if (onClick) {
                         onClick();
                     }
                 }}>
        {children}
        {dropdown &&
            <M3_A_Icon sx={icon_sx} color={icon_color} className={margin_left} icon={m3_icon_map.solid.dropdown}
                       size={icon_size}/>}
    </span>
}