import {doc, getDoc} from "firebase/firestore";
import {db} from "../../app/config/setup-firestore";

export async function getMessageData(uid, id) {
    if (!uid || !id) {
        console.error('getMessageData', uid, id)
        return null;
    }
    const ref = doc(db, 'community_content', uid, 'posts', id);
    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? {...doc.data(), id: doc.id} : null;
        });
}