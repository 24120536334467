import React, {useEffect, useState} from "react";
import {useUnaverse} from "../../../../config/unaverse";
import {ModalHeader} from "../../../../m3/_legacy_components/preview-overlay";
import {PrefDivider, PrefSection} from "./your-account";
import Field from "../../../../m3/_legacy_components/form/field";
import {authFetch} from "../../../../../api/network";
import Button from "../../../../m3/_legacy_components/button";
import {useCommunity} from "../../../../config/community";
import {data_countries_map} from "../../../../../common/data/data/countries";

function RegionSection({init="",loading,onSave}) {
    const [v,setV] = useState(init);
    const [changed,setChanged] = useState(false);

    useEffect(()=>{
        if(!loading) {
            setChanged(false);
        }
    }, [loading])
    return <div className="mt-2">
        <Field value={v} id="country" onChange={(a,b)=>{
            setV(b);
            setChanged(true);
        }} type="country" label="Region" />

        {changed&&<div className="mt-4 flex">
            <div className="flex-grow" />
            <div className="">
                <Button loading={loading==="saving"} text="Save" intent="success" onClick={()=>onSave('region',v)} />
            </div>
        </div>}
    </div>
}

function TimezoneSection({init="",loading,onSave}) {
    const [v,setV] = useState(init);

    return <div className="mt-2">
        <Field value={v} id="timezone" onChange={(a,b)=>{
            setV(b);
        }} type="timezone" label="Timezone" />

        {loading&&<div className="mt-4 flex">
            <div className="flex-grow" />
            <div className="">
                <Button loading={loading==="saving"} text="Save" intent="success" onClick={()=>onSave('timezone',v)} />
            </div>
        </div>}
    </div>
}

function buildSections(unaverse,setPage) {
    const {profile} = unaverse;
    return [
        {
            title: "Region",
            subtitle: profile.preferences.region ? data_countries_map[profile.preferences.region] : "",
            onClick: () => setPage("country")
        },
        {
            title: "Timezone",
            subtitle: profile.preferences.timezone ? profile.preferences.timezone : "",
            onClick: () => setPage("timezone")
        },
    ]
}

function buildState(profile) {
    return {
        region: profile.preferences.region||"",
        timezone: profile.preferences.timezone||"",
    }
}

export function MemberPreferencesLanguageRegion() {
    const unaverse = useUnaverse();
    const community = useCommunity();
    const [page,setPage] = useState("");
    const [state,setState] = useState(buildState(community.member));
    const [loading,setLoading] = useState('');
    const sections = buildSections({profile: community.member},setPage);

    function handleSave(f,v) {
        setLoading("saving")

        const payload = {
            id: community.member_id,
            community_uid: community.uid,
            preferences: {
                ...community.member.preferences,
                [f]: v
            },
            _updated_fields: ["preferences"]
        };

        const res = () => {
            setLoading("");
            community.updateMemberProfile()
        }

        authFetch('/members/update-timedate', res, res, "POST", {payload});
    }

    function renderPage() {
        switch (page) {
            case "timezone" :
                return <div>
                    <ModalHeader sizing="h-8 px-0" divider={false} goBack={()=>setPage("")} title="Timezone" />
                  <TimezoneSection loading={loading} onSave={handleSave} init={state.timezone} />

                </div>
            case "country" :
                return <div>
                    <ModalHeader sizing="h-8 px-0" divider={false} goBack={()=>setPage("")} title="Region" />
                    <RegionSection loading={loading} onSave={handleSave} init={state.region} />
                </div>
            default:
                return <div>
                    {sections.map((sec,ind)=>{
                        if(sec.divider) {
                            return <PrefDivider key={ind} {...sec} />
                        }
                        return <PrefSection key={ind} {...sec} />
                    })}
                </div>
        }
    }

    return <div>
        {renderPage()}
    </div>
}