import React, {useEffect, useRef, useState} from "react"
import './styles.css';
import {Avatar} from "../avatar";
import {
    ArchiveBoxIcon, ChartBarSquareIcon,
    CheckCircleIcon,
    BoltIcon,
    PaperAirplaneIcon, PlusIcon,
    SparklesIcon,
    UserIcon, EnvelopeOpenIcon
} from "@heroicons/react/24/solid";
import Button from "../button";
import {useCommunity} from "../../../config/community";
import {collection, limit, onSnapshot, orderBy, query, startAfter, where} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {convertNotification} from "./converter";
import {notification_types} from "./types";
import {hydrateDynamicText, renderDynamicText} from "../admin-activity/item";
import {TextAction} from "../../../routes/auth/sign-in";
import {mock_notifications} from "./mocks";
import {SectionHeader} from "../entity-grid";
import {InlineLoader} from "../admin-activity";
import {useUnaverse} from "../../../config/unaverse";
import {WB_MessagesPost} from "../../../routes/community/write/blocks/messages/post";

import DataWrapper from "../data-wrapper";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";
import EmptyState from "../empty-state";
import {BellIcon} from "@heroicons/react/24/outline";
import {utils_dates_format} from "../../../../common/utilities/dates";
import {buildImageUrl} from "../../../../common/utilities/images";

function Name({children}) {
    return <span className="font-medium hover:underline cursor-pointer text-gray-800">{children}</span>
}

const bold = /\*\*(.*)\*\*/gim;

function processTags(m) {
    return m
        .replace(bold, '##bold:$1##')
        .trim();
}

function TDEntity({children}) {
    return <div className="inline-block text-gray-600 hover:underline cursor-pointer font-semibold">
        {children}
    </div>
}

function TopDescriptor({name, action, target}) {
    if (!action) {
        return null;
    }
    return <div className="text-xs pb-1 text-gray-600 ">
        <TDEntity>{name}</TDEntity> {action} <TDEntity>{target}</TDEntity>
    </div>
}

const icon_map = {
    'member': <UserIcon/>,
    'check': <CheckCircleIcon/>,
    'plus': <PlusIcon/>,
    'chart': <ChartBarSquareIcon/>,
    'archive': <ArchiveBoxIcon/>,
    'message': <EnvelopeOpenIcon/>,
    'airplane': <PaperAirplaneIcon/>,
    'bolt': <BoltIcon/>,
    'sparkle': <SparklesIcon/>,
};

function SystemIcon() {
    return <NotifIcon icon={{slug: 'check', color: 'text-gray-500'}}/>
}

function NotifIcon({emoji, icon, image}) {
    let c;
    if (image) {
        c = <Avatar url={image} size="h-8 w-8"/>;
    } else if (emoji) {
        c = <div className="h-8 w-8 text-2xl flex items-center justify-center">
            {emoji}
        </div>;
    } else if (icon) {
        c = <div className="flex h-8 w-8 items-center justify-center">
            <div className={`h-6 w-6 ${icon.color}`}>
                {icon_map[icon.slug]}
            </div>
        </div>
    }
    return <div className="h-8 w-8">
        {c}
    </div>
}

function MainMemberList({followers, count}) {
    const sl = followers.slice(0, 5);
    return <div className="flex space-x-1">
        {sl.map((it, index) => {
            return <Avatar key={index} size="h-7 w-7"/>
        })}
    </div>
}

function SimpleActionDescription({data, type}) {
    let a;
    if (type === 'closed-thread') {
        a = <span><Name>{data.target.name}</Name> closed the thread <Name>{data.thread_subject}</Name>.</span>;
    } else if (type === 'direct-message') {
        a = <span><Name>{data.sender.name}</Name> sent you a direct message.</span>;
    } else if (type === 'follow-request') {
        a = <span><Name>{data.target.name}</Name> requested to follow you.</span>;
    } else if (type === 'follow-request-accepted') {
        a = <span><Name>{data.target.name}</Name> accepted your follow request.</span>;
    } else if (type === 'closed-poll') {
        a = <span>The poll "<Name>{data.name}</Name>" just closed.</span>;
    } else if (type === 'message-delivered') {
        a =
            <span>{data.count} member{data.count === 1 ? "" : "s"} were notified about <Name>{data.thread_subject}</Name>.</span>;
    } else if (type === 'new-membership-application') {
        a = <span><Name>{data.target.name}</Name> applied to join Sandbox via <Name>{data.group_name}</Name>.</span>;
    }
    return <div className="text-sm text-gray-700">
        {a}
    </div>
}

function NewFollowersDescription({followers, suffix = 'followed you.', count}) {
    let a;
    if (count === 1) {
        a = <Name>{followers[0].name}</Name>;
    } else if (count === 2) {
        a = <span><Name>{followers[0].name}</Name> and <Name>{followers[1].name}</Name></span>;
    } else if (count === 3) {
        a = <span><Name>{followers[0].name}</Name> and 1 other</span>;
    } else {
        a = <span><Name>{followers[0].name}</Name> and {count - 1} others</span>;
    }
    return <div className="text-sm text-gray-700">
        {a} {suffix}
    </div>
}

export function PollReference({fill = false}) {
    return <div style={{height: '10rem'}}
                className={`rounded-lg ${fill ? "" : "max-w-md"} hover:bg-gray-100 bg-white transition-colors cursor-pointer border border-gray-200 p-2`}>
        POLL RESULTS
    </div>
}


export function MembershipApplicationReference({fill = false}) {
    return <div style={{height: '6rem'}}
                className={`rounded-lg ${fill ? "" : "max-w-md"} hover:bg-gray-100 bg-white transition-colors cursor-pointer border border-gray-200 p-2`}>
        MembershipApplicationReference
    </div>
}

function FollowRequest({data}) {
    return <div>
        <Button text="Accept"/>
        <Button text="Decline"/>
    </div>
}

function MsgHeader({data,post,handleClick}) {
    return <div className="flex space-x-2 ">
        <div>
            <Avatar size="h-5 w-5" url={buildImageUrl(data.profile_picture, '_medium')}/>
        </div>
        <div className="text-sm flex space-x-1.5">
            <div className="text-sm font-medium hover:underline cursor-pointer text-gray-800">{data.name}</div>
            <div className="text-gray-500">{dayjs(post.created_at).fromNow()}</div>
        </div>
    </div>
}

export function MessageReference({show_title = false, post, community_uid, author_id, text, title = '', fill = false}) {
   const navigate = useNavigate();
   const community = useCommunity();
    function handleClick() {
        navigate(`/c/${community.domain}/post/${post.id}`);
    }

    return <div onClick={handleClick}
        className={`rounded-lg ${fill ? "" : "max-w-md"} hover:bg-gray-100 bg-white transition-colors cursor-pointer border border-gray-200 p-2`}>
        {show_title && <div className="pb-0.5">
            <div  className={`font-bold text-gray-800 line-clamp-1 cursor-pointer hover:underline text-base`}>
                {title}
            </div>
        </div>}
        <DataWrapper id={author_id} type={`community_members.${community_uid}.members`}>
            <MsgHeader post={post} />
        </DataWrapper>

        <div className="text-sm text-gray-600 leading-5 pt-1">
            {text}
        </div>
    </div>
}

function Main({main, type, data}) {
    let a, b, c;
    if (main === 'member-list') {
        a = <MainMemberList {...data} />
    }
    if (type === 'followed-member-i-follow') {
        c = <span>followed <Name>{data.target.name}</Name>.
        </span>;
        b = <NewFollowersDescription suffix={c} {...data} />
    } else if (type === 'followed-you') {
        b = <NewFollowersDescription suffix={"followed you."} {...data} />
    } else if (type === 'closed-thread') {
        a = <SimpleActionDescription type={type} data={data}/>
        b = <div className="pb-0.5"><MessageReference/></div>
    } else if (type === 'direct-message') {
        a = <SimpleActionDescription type={type} data={data}/>
        b = <div className="pb-0.5"><MessageReference show_title title={data.thread_subject}/></div>
    } else if (type === 'follow-request') {
        a = <SimpleActionDescription type={type} data={data}/>
        b = <div className="pb-0.5"><FollowRequest data={data}/></div>
    } else if (type === 'follow-request-accepted') {
        a = <SimpleActionDescription type={type} data={data}/>
        b = <div className="pb-0.5"></div>
    } else if (type === 'closed-poll') {
        a = <SimpleActionDescription type={type} data={data}/>
        b = <div className="pb-0.5"><PollReference/></div>
    } else if (type === 'message-delivered') {
        a = <SimpleActionDescription type={type} data={data}/>
        b = <div className="pb-0.5"><MessageReference/></div>
    } else if (type === 'new-membership-application') {
        a = <SimpleActionDescription type={type} data={data}/>
        b = <div className="pb-0.5"><MembershipApplicationReference/></div>
    }
    return <div>
        <div>{a}</div>
        <div className="pt-1">{b}</div>
    </div>
}

function getNotificationContent(type, data, ctx) {
    if (type === "new-post" && data.post_id) {
        return <WB_MessagesPost community_uid={ctx.community_uid} post_id={data.post_id}/>
    }
    return null;
}

export function NotificationsItem({
                                      top,
                                      main,
                                      show_time,
                                      icon,
                                      image,
                                      data,
                                      relative,
                                      mock,
                                      reactions,
                                      emoji,
                                      context,
                                      author,
                                      author_name = '',
                                      date = '',
                                      text = '',
                                      type
                                  }) {

    const notification_type = notification_types[type];

    if (!notification_type && !mock) {
        return null;
    }

    const ctx = {
        community_uid: context.community_uid
    }

    const content = getNotificationContent(type, data, ctx);

    const final = hydrateDynamicText(mock ? text : notification_type.message, data, {...context, author});
    const f_arr = final.split(/##/g);
const is_system = !!notification_type.system;
    /*

                {reactions && <div className="pt-0.5">
                    <MessageReactions can_react={false} reactions={reactions}/>
                </div>}
     */
    //      {main && <Main data={data} type={type} main={main}/>}
    return <div className="pt-1.5 pb-1.5 rounded-xl relative group hover:bg-gray-100">
        {top && <TopDescriptor{...top}/>}
        <div className="grid gap-2.5 pr-14" style={{gridTemplateColumns: '2rem 1fr'}}>
            <div>
                {is_system?<SystemIcon />:<NotifIcon image={image} icon={icon} emoji={emoji}/>}
            </div>
            <div>
                {text && <div className="text-base flex items-center text-gray-700">
                    {renderDynamicText(f_arr, {}, context.community_handle)}
                </div>}
            </div>
        </div>
        <div className="absolute top-1.5 right-2 h-6 flex items-center">
            {show_time && <div className="text-sm text-gray-500">
                <span>{relative}</span>
            </div>}
        </div>
        {content && <div className="pl-10 pt-0.5">
            {content}
        </div>}
    </div>
}

function NotificationsSection({updates, show_title = true, title, context}) {

    return <div className="mb-4">
        {show_title &&
            <div className=""><SectionHeader weight="font-semibold" size="text-base" title={title}/></div>}
        {updates.map((event, k) => {
            const show_time = k === 0 ? true : updates[k - 1].relative !== event.relative;
            return <div key={k}>
                <NotificationsItem show_time={show_time} context={context} {...event} />
            </div>
        })}
    </div>
}


function NotificationsList({updates = [], loading, context}) {
    const final_updates = updates.map(upd => {
        return {
            ...convertNotification(upd.type, upd),
            ...utils_dates_format(upd.ts)
        }
    });

    let obj = {
        'today': {updates: [], title: 'Today'},
        'yesterday': {updates: [], title: 'Yesterday'},
        'this-week': {updates: [], title: 'This Week'},
        'last-month': {updates: [], title: 'Last Month'},
        'last-year': {updates: [], title: 'Last Year'},
        'beyond': {updates: [], title: 'Beyond'},
    };

    final_updates.forEach(evt => {
        obj[evt.sort].updates.push(evt);
    });

    const sections = Object.values(obj).filter(a => a.updates.length > 0);

    if (final_updates.length === 0) {
        if (loading) {
            return <div className="flex justify-center items-center py-8">
                <InlineLoader/>
            </div>
        }
        return <EmptyState title="No Notifications found" icon={<BellIcon />} />
    }

    return <div>
        {sections.map(sec => <NotificationsSection show_title={sections.length > 1} sections_count={sections.length}
                                                   title={sec.title}
                                                   key={sec.title} context={context}
                                                   updates={sec.updates}/>)}
    </div>
}

function uniqueArray(items) {
    let arr = [];
    items.forEach(function (item) {
        const i = arr.findIndex(x => x.id === item.id);
        if (i <= -1) {
            arr.push(item);
        }
    });
    return arr;
}
export function NotificationsFeed({mock, override_id}) {
    const [notifications, setN] = useState([]);
    const [loading, setLoading] = useState(true);
    const [has_more, setHasMore] = useState(false);
    const last_ref = useRef();
    const community = useCommunity();

    function handleResult(ns) {
        setN(uniqueArray([...notifications, ...ns]).sort((a, b) => b.ts - a.ts));
        setHasMore(ns.length === 12);
        setLoading(false);
    }

    function handleSnap(snap, flag) {
        if (snap.empty) {
            handleResult([]);
        } else {
            last_ref.current = snap.docs[snap.docs.length - 1];
            handleResult(snap.docs.map(d => {
                let o = {...d.data()};
                o.id = d.id;
                return o;
            }));
        }
    }

    function getNotifications(after, lt = 12) {
        setLoading(true);
        const ref = collection(db, "community_members", community.id, "notifications");

        let mid = override_id || community.member_id;

        let q;
        if (after) {
            q = query(ref, where('context_id', '==', mid), orderBy('ts', 'desc'), startAfter(after), limit(lt));
        } else {
            q = query(ref, where('context_id', '==', mid), orderBy('ts', 'desc'), limit(lt));
        }

        onSnapshot(q, (s) => {
            handleSnap(s, true)
        });
    }

    useEffect(function () {
        if (community.uid) {
            getNotifications(null)
        } else {
            setN([]);
        }
    }, [community.uid, override_id]);


    const unread = notifications.filter(a => !a.read).map(b => b.id);

    const has_unread = unread.length > 0;

    const item_ctx = {
        community_handle: community.domain,
        community_uid: community ? community.uid : ""
    };

    return <div className="flex flex-col gap-1">
        {!mock && <NotificationsList loading={loading} updates={notifications} context={item_ctx}/>}
        {mock && <div>
            {mock_notifications.map((mn, index) => {
                return <NotificationsItem mock context={item_ctx} key={index} {...mn} />
            })}
        </div>}
        {!loading && has_more && <div className="flex justify-center py-2">
            <TextAction text="Load more" onClick={() => getNotifications(last_ref.current)}/>
        </div>}
    </div>
}