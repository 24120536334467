export const data_months_map = {
    0: {label: 'January'},
    1: {label: 'February'},
    2: {label: 'March'},
    3: {label: 'April'},
    4: {label: 'May'},
    5: {label: 'June'},
    6: {label: 'July'},
    7: {label: 'August'},
    8: {label: 'September'},
    9: {label: 'October'},
    10: {label: 'November'},
    11: {label: 'December'},
}

export const data_months_arr = Object.entries(data_months_map).map(([key, value]) => {
    return {
        value: key,
        label: value.label
    }
})