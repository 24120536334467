import {emaillist_getReplyToOptions, emaillist_who_can_send} from "./mailing-list-settings";

export const apps_list = [
    {
        name: "Email Lists",
        author: "Unaty",
        version: "1.0.0",
        last_updated: "March 23, 2023",
        thumbnail: `/images/apps/email-lists.png`,
        short_description: "Get a real email address with all your group members that's always up-to-date.",
        description: `
        Create a real mailing list (group-handle@communitydomain.com) that automatically syncs your group members and fully integrates with group messaging.<br/>
        <br/>
        <b>Features:</b>
        <ul>
            <li>
                <b>Real email address</b> - Get a real email address that's always up-to-date with your group members.
</li>
            <li>
                <b>Full integration</b> - Send and receive emails from your group's email address and have them automatically sync with your group's messaging.
</li>
            <li>
                <b>Customizable</b> - Customize your group's email address, reply-to address, and who can send emails.
</li>
        </ul>
        <br/>
        <b>How to use:</b>
        <ul>
            <li>
                <b>Step 1</b> - Create a group email address by clicking adding the app to your group.
</li>
            <li>
                <b>Step 2</b> - Send and receive emails from your group's email address.
</li>
        </ul>
        `,
        id: "unaty__mailing_lists",
        learn_more: "https://getunaty.com",
        icon: `https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2F1868I1QUR5_small?alt=media`,
        config_manifest: {
            sections: [
                {
                    name: "Group Email",
                    fields: [
                        {
                            label: "Email Address",
                            type: "text",
                            accessor: "email_address",
                            disabled: true
                        }
                    ]
                },
                {
                    name: "Preferences",
                    fields: [
                        {
                            label: "Who can send emails:",
                            type: "select",
                            options: [
                                ...emaillist_who_can_send
                            ],
                            accessor: "access_level",
                        },
                        {
                            label: "Default email reply behavior:",
                            type: "select",
                            options: [
                                ...emaillist_getReplyToOptions()
                            ],
                            accessor: "reply_preference",
                        },
                        {
                            label: "Custom reply-to email:",
                            type: "text",
                            accessor: "custom_reply_to",
                        }
                    ]
                }
            ]
        },
        changelog: []
    },
    {
        name: "Google Calendar",
        author: "Unaty",
        version: "1.0.0",
        last_updated: "March 23, 2023",
        thumbnail: `/images/apps/google-calendar.png`,
        short_description: "Google Calendar is a great way to keep track of your events.",
        description: `
        Connect your group's Google Calendar to Unaty to automatically sync your group's events.<br/>
        <br/>
        <b>Features:</b>
        <ul>
            <li>
                <b>Sync events</b> - Automatically sync your group's events with your Google Calendar.
</li>
            <li>
                <b>Full integration</b> - Create, edit, and delete events from your Google Calendar and have them automatically sync with your group's events.
</li>
            <li>
                <b>Customizable</b> - Customize your group's Google Calendar by selecting which calendar to sync with your group's events.
</li>
        </ul>
        <br/>
        <b>How to use:</b>
        <ul>
            <li>
                <b>Step 1</b> - Connect your Google account by clicking adding the app to your group.
</li>
            <li>
                <b>Step 2</b> - Select which calendar to sync with your group's events.
</li>
        </ul>
        `,
        id: "google_calendar",
        learn_more: "https://getunaty.com",
        icon: `https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2F1868I1QUR5_small?alt=media`,
        config_manifest: {
            sections: [
                {
                    name: "Account",
                    fields: [
                        {
                            accessor: "google_user_email",
                            label: "Google Account",
                            type: "connect-google-account",
                            value_mapping: [
                                "member_id",
                            ]
                        },
                        {
                            accessor: "calendar_id",
                            type: "google-calendar-selector",
                            label: "Selected Calendar",
                            hide: [
                                {
                                    accessor: "google_user_email",
                                    value: ""
                                }
                            ],
                            value_mapping: [
                                "calendar_name",
                                "calendar_description",
                                "calendar_timezone",
                            ]
                        }
                    ]
                }
            ]
        },
        changelog: []
    }
]