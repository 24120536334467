import React, {useState, useEffect} from "react";
import {useCommunity} from "../../config/community";
import dayjs from "dayjs";
import {collection, getDocs, limit, orderBy, query, where} from "firebase/firestore";
import {db} from "../../config/setup-firestore";
import {SectionHeader} from "../../m3/_legacy_components/entity-grid";
import {NewBirthdayWidget} from "../../m3/_legacy_components/birthdays-display";
import {getCanShowAge} from "../../m3/examples/field-privacy-control";
import {utils_dates_format} from "../../../common/utilities/dates";

function buildDays(num) {
    let db_days = [];
    let days_map = {};
    let sort_map = {};

    let date = dayjs();

    for (let i = 0; i < num; i++) {
        let date_str, split;
        if (i !== 0) {
            date = date.add(1, 'days');
        }
        // 101-2021
        // 1231-2020
        date_str = date.format('MDD-YYYY');
        split = date_str.split('-');
        db_days.push(split[0]);
        days_map[split[0]] = split[1];
        sort_map[split[0]] = {
            year: split[1],
            date: split[0]
        };
    }

    return {
        db: db_days,
        sort: sort_map,
        map: days_map
    }
}

function getAge(c, b) {
    return c - b;
}

const days = buildDays(7);

const today = dayjs();

function buildFinalData(arr) {
    let obj = {
        today: [],
        week: [],
        days_index: {}
    };

    const t_check = today.format('DD-MM-YYYY');

    for (let i in arr) {
        const item = arr[i];

        // check if this memebr has their birthday today
        if (item.use_date === t_check) {
            // today
            obj.today.push(item);
        } else {
            // if not, let's see if there is already a day tracked in days_index
            let index = obj.week.length;
            if (!item.use_date) {
                continue;
            }
            // 0 === false
            if (obj.days_index[item.use_date] === null || obj.days_index[item.use_date] === undefined) {
                obj.week.push({
                    date: item.use_date,
                    formatted_date: item.formatted_date,
                    members: []
                });
                index = obj.week.length - 1;
                obj.days_index[item.use_date] = index;
                obj.week[index].members.push(item);
            } else {
                // probably need to get index of this here?

                obj.days_index[item.use_date] = index;
                obj.week[(index - 1)].members.push(item);
            }

        }
    }

    return obj;
}

export function MemberBirthdaysWidget() {
    const community = useCommunity();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const date_format = "dddd, MMMM D";

    useEffect(function () {
        getMembers()
            .then((dt) => {
                setLoading(false);
                setData(dt.sort(function (a, b) {
                    let aSize = a.sort_one_field;
                    let bSize = b.sort_one_field;
                    let aLow = a.sort_two_field;
                    let bLow = b.sort_two_field;

                    if (aSize == bSize) {
                        return (aLow < bLow) ? -1 : (aLow > bLow) ? 1 : 0;
                    } else {
                        return (aSize < bSize) ? -1 : 1;
                    }
                }));
            });
    }, []);

    async function getMembers() {

        const ref = collection(db, "community_members", community.id, "members");

        const q = query(ref, where('archived', '==', false), where('short_birthday', 'in', days.db), limit(50));

        const snap = await getDocs(q);

        return snap.docs.map(doc => {
            let item = doc.data();
            item.id = doc.id;
            const sort_data = days.sort[item.short_birthday];
            const age = getAge(days.map[item.short_birthday], item.birthday.year);
            const full_date = dayjs().year(days.map[item.short_birthday]).month(item.birthday.month).date(item.birthday.date);
            return {
                birthday: item.birthday,
                handle: item.handle,
                sort_one_field: parseFloat(sort_data.year),
                sort_two_field: parseFloat(sort_data.date),
                user_uid: item.user_uid,
                id: item.id,
                formatted_date: full_date.format(date_format),
                use_date: full_date.format('DD-MM-YYYY'),
                name: item.name,
                birth_year: item.birthday.year,
                show_age: getCanShowAge(item),
                age: age,
                profile_picture: item.profile_picture,
                important: age > 49 && (age % 5 === 0)
            };
        })
    }

    if (data.length === 0) {
        return null;
    }

    const final = buildFinalData(data);

    return <div>
        <div className="mb-2">
            <SectionHeader size="text-lg" title="Birthdays"/>
        </div>
        <NewBirthdayWidget openMember={(member_id) => {
            community.openPreviewOverlay({
                type: 'member',
                id: member_id
            })
        }} data={final} handle={community.domain}/>
    </div>
}