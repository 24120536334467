import React from "react"
import './styles.css';
import {EmojiPicker} from "../emoji-picker";
import {ContentPopupMenu} from "../content-popup/components";
import SimpleTooltip from "../tooltip";
import {IconAction} from "../../../routes/auth/sign-in";
import {useIsMobile} from "../../../m3/hooks/is-mobile";
import DataWrapper from "../data-wrapper";
import {RenderName} from "../profile-field-renderer/value-pipeline";
import {useCommunity} from "../../../config/community";

const map = {
    'like': {
        emoji: '🏆'
    }
};


function ReactionFrame({
                           active, onClick = () => {
    }, children
                       }) {
    const classes = active ? "active" : "bg-gray-100 border-gray-100";
    return <div onClick={onClick}
                className={`${classes} h-[1.5rem] group border cursor-pointer items-center inline-flex pl-1.5 pr-1.5 rounded-full reaction`}>
        {children}
    </div>
}

function Name({data}) {
    return data.name || "";
}

function Names({community_uid, you, member_id, member_ids}) {
    let other_id = "";
    if (member_ids.length === 1) {
        if (you) {
            return <span className="">You</span>
        }
        return <DataWrapper id={member_ids[0]} type={`community_members.${community_uid}.members`}>
            <Name/>
        </DataWrapper>
    } else if (member_ids.length === 2) {
        if (you) {
            other_id = member_ids[0] === member_id ? member_ids[1] : member_ids[0];
            return <span>
                <span className="">You</span>
                <span className="text-gray-400 font-medium"> and </span>
                <DataWrapper id={other_id} type={`community_members.${community_uid}.members`}>
                    <Name/>
                </DataWrapper>
            </span>
        }
        return <span>
            <DataWrapper id={member_ids[0]} type={`community_members.${community_uid}.members`}>
                <Name/>
            </DataWrapper>
            <span className="text-gray-400 font-medium"> and </span>
            <DataWrapper id={member_ids[1]} type={`community_members.${community_uid}.members`}>
                <Name/>
            </DataWrapper>
        </span>
    } else {
        if (you) {
            other_id = member_ids.filter(id => id !== member_id)[0];
            return <span>
                <span className="">You</span>,&nbsp;
                  <DataWrapper id={other_id} type={`community_members.${community_uid}.members`}>
                <Name/>
            </DataWrapper>,&nbsp;
                <span className="text-gray-400 font-medium"> and </span>
                <span className="text-gray-400 font-medium">{member_ids.length - 2} others</span>
            </span>
        }
        return <span>
            <DataWrapper id={member_ids[0]} type={`community_members.${community_uid}.members`}>
                <Name/>
            </DataWrapper>
            <span className="text-gray-400 font-medium"> and </span>
            <span className="text-gray-400 font-medium">{member_ids.length - 2} others</span>
        </span>
    }
}

function Reaction({active, member_id, community_uid, count, members = [], id, show_hover, onReact, emoji = ''}) {

    const c = <ReactionFrame onClick={onReact} active={active}>
        <div className="-ml-px" style={{fontSize: '1.05rem'}}>
            {emoji}
        </div>
        <div style={{fontSize: '0.8rem'}}
             className={`${active ? "" : "text-gray-600"} ml-px pr-px pl-[0.15rem] font-medium`}>
            {count}
        </div>
    </ReactionFrame>;

    if (show_hover && members && members.length > 0) {
        const text = <div className="flex items-center flex-col gap-2 pt-2" style={{maxWidth: "16rem"}}>
            <div style={{maxWidth: "16rem"}}>
                <div className="w-16 h-16 flex rounded-md items-center justify-center bg-white">
                    <span className="text-[3rem]">
                        {emoji}
                    </span>
                </div>
            </div>
            <div style={{maxWidth: "16rem"}} className=" leading-tight pb-1 text-center">
                  <span className="text-white">
                <Names member_id={member_id} you={active} member_ids={members} community_uid={community_uid}/>
            </span>&nbsp;
                <span className="text-gray-400 font-medium">reacted with</span>&nbsp;<span
                className="text-gray-300 font-medium">:{id}:</span>
            </div>
        </div>;
        return <SimpleTooltip trigger="mouseenter focus" hideOnClick={true}
                              placement="top"
                              text={text}>
            {c}
        </SimpleTooltip>
    }

    return <div>
        {c}
    </div>
}

export const AddReactionIcon = () => {
    return <svg width="inherit" height="inherit" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.6 3C20.0214 3 19.5524 3.46903 19.5524 4.04762V6.35238H17.2476C16.669 6.35238 16.2 6.82142 16.2 7.4C16.2 7.97858 16.669 8.44762 17.2476 8.44762H19.5524V10.7524C19.5524 11.331 20.0214 11.8 20.6 11.8C21.1786 11.8 21.6476 11.331 21.6476 10.7524V8.44762H23.9524C24.531 8.44762 25 7.97858 25 7.4C25 6.82142 24.531 6.35238 23.9524 6.35238H21.6476V4.04762C21.6476 3.46903 21.1786 3 20.6 3Z"
            fill="currentColor"/>
        <path
            d="M20.6 3C20.0214 3 19.5524 3.46903 19.5524 4.04762V6.35238H17.2476C16.669 6.35238 16.2 6.82142 16.2 7.4C16.2 7.97858 16.669 8.44762 17.2476 8.44762H19.5524V10.7524C19.5524 11.331 20.0214 11.8 20.6 11.8C21.1786 11.8 21.6476 11.331 21.6476 10.7524V8.44762H23.9524C24.531 8.44762 25 7.97858 25 7.4C25 6.82142 24.531 6.35238 23.9524 6.35238H21.6476V4.04762C21.6476 3.46903 21.1786 3 20.6 3Z"
            fill="currentColor"/>
        <path
            d="M22.1816 12.544C22.4276 13.4076 22.5556 14.3083 22.5556 15.2222C22.5556 16.5063 22.3026 17.7777 21.8113 18.964C21.3199 20.1503 20.5997 21.2282 19.6917 22.1362C18.7838 23.0441 17.7059 23.7643 16.5196 24.2557C15.3333 24.7471 14.0618 25 12.7778 25C11.4937 25 10.2223 24.7471 9.03598 24.2557C7.84969 23.7643 6.77179 23.0441 5.86384 22.1362C4.95589 21.2282 4.23567 20.1503 3.74429 18.964C3.25291 17.7777 3 16.5063 3 15.2222C3 12.629 4.03016 10.142 5.86384 8.30829C7.69753 6.4746 10.1845 5.44444 12.7778 5.44444C13.6917 5.44444 14.5924 5.57239 15.456 5.81837C15.1165 6.36991 14.8834 6.994 14.7854 7.66199C14.1358 7.48949 13.4614 7.4 12.7778 7.4C10.7032 7.4 8.71358 8.22412 7.24663 9.69108C5.77968 11.158 4.95556 13.1476 4.95556 15.2222C4.95556 16.2495 5.15788 17.2666 5.55099 18.2157C5.94409 19.1647 6.52027 20.027 7.24663 20.7534C7.97299 21.4797 8.83531 22.0559 9.78434 22.449C10.7334 22.8421 11.7505 23.0444 12.7778 23.0444C13.805 23.0444 14.8222 22.8421 15.7712 22.449C16.7202 22.0559 17.5826 21.4797 18.3089 20.7534C19.0353 20.027 19.6115 19.1647 20.0046 18.2157C20.3977 17.2666 20.6 16.2495 20.6 15.2222C20.6 14.5386 20.5105 13.8642 20.338 13.2146C21.006 13.1166 21.6301 12.8835 22.1816 12.544Z"
            fill="currentColor"/>
        <path
            d="M9.84444 12.2889C9.30443 12.2889 8.86667 12.7267 8.86667 13.2667C8.86667 13.8067 9.30443 14.2444 9.84444 14.2444H9.85422C10.3942 14.2444 10.832 13.8067 10.832 13.2667C10.832 12.7267 10.3942 12.2889 9.85422 12.2889H9.84444Z"
            fill="currentColor"/>
        <path
            d="M15.7111 12.2889C15.1711 12.2889 14.7333 12.7267 14.7333 13.2667C14.7333 13.8067 15.1711 14.2444 15.7111 14.2444H15.7209C16.2609 14.2444 16.6987 13.8067 16.6987 13.2667C16.6987 12.7267 16.2609 12.2889 15.7209 12.2889H15.7111Z"
            fill="currentColor"/>
        <path
            d="M10.0126 17.0096C9.61712 17.0096 9.26057 17.2479 9.10925 17.6133C8.95792 17.9787 9.04163 18.3993 9.32133 18.6789C10.2381 19.5954 11.4814 20.1103 12.7778 20.1103C14.0741 20.1103 15.3174 19.5954 16.2342 18.6789C16.5139 18.3993 16.5976 17.9787 16.4463 17.6133C16.295 17.2479 15.9384 17.0096 15.5429 17.0096H10.0126Z"
            fill="currentColor"/>
    </svg>
}

export function EmojiSelectorPopup({
                                       children, autoFocus = true, onAddReaction = () => {
    }
                                   }) {
    const is_mobile = useIsMobile();
    const content = <div className="content-popup-shadow mdrawer bg-white max-w-md border border-gray-200 rounded-lg">
        <EmojiPicker autoFocus={autoFocus} changeEmoji={(emoji) => {
            onAddReaction(emoji.id || "")
            if(is_mobile) {
                const el = document.getElementById('drawer-close-listener');
                if(el) {
                    el.focus();
                }
            }
        }}/>
    </div>;
    return <SimpleTooltip hideOnClick={false} simple trigger="click" interactive usePortal={true}
                          text={content} placement={'bottom-start'}
                          minimal mobileDrawer
                          popoverClassName='minimal-popover'>
        {children}
    </SimpleTooltip>
}

function AddReaction({
                         margin_top = false,
                         onAddReaction = () => {
                         }
                     }) {

    return <EmojiSelectorPopup onAddReaction={onAddReaction}>
        <div
            className={`${margin_top ? "mt-px" : ""} group h-[1.5rem] hover:bg-gray-200 hover:border-gray-200 border border-gray-100 bg-gray-100 cursor-pointer items-center inline-flex pl-1.5 pr-2 rounded-full`}>
            <div className="h-5 w-5 text-gray-600">
                <AddReactionIcon/>
            </div>
        </div>
    </EmojiSelectorPopup>
}

export default function MessageReactions({
                                             member_id = '',
                                             handleReact = () => {
                                             }, community_uid, can_react = true, handleAddReaction, reactions
                                         }) {
    const is_mobile = useIsMobile();
    if (!reactions) {
        return null;
    }

    const r_items = Object.entries(reactions);

    return <div className={"flex gap-1 flex-wrap"}>
        {r_items.map(([id, it], key) => {
            return <Reaction member_id={member_id} community_uid={community_uid} show_hover={!is_mobile}
                             onReact={() => handleReact(it.id)}
                             can_react={can_react} key={it.id} active={it.you} {...it} />
        })}
        {can_react && <AddReaction margin_top onAddReaction={handleAddReaction}/>}
    </div>
};