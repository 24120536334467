import React, {useState, useEffect, useRef} from 'react';
import {getStartedRecordUsage} from "../../../search";
import {useCommunity} from "../../../../../config/community";
import EmptyState from "../../../../../m3/_legacy_components/empty-state";
import {SectionHeader} from "../../../../../m3/_legacy_components/entity-grid";
import {TextAction} from "../../../../auth/sign-in";
import {ArrowUturnUpIcon} from "@heroicons/react/24/outline";
import EntityList from "../../../../../m3/_legacy_components/entity-list";
import {buildEntityForList} from "./organizers";
import {getManyRoles} from "../../../position/api";
import {InlineLoader} from "../../../../../m3/_legacy_components/admin-activity";
import {collection, getDocs, limit, query, startAfter, where} from "firebase/firestore";
import {db} from "../../../../../config/setup-firestore";
import Button from "../../../../../m3/_legacy_components/button";
import {saveItemToLocalStorage} from "../../../../../m3/utilities/localstorage";
import {utils_objects_moveArrayItem} from "../../../../../../common/utilities/objects";
import {getManyLists} from "../../../../../../api/collections";

export function buildGroupDefaultRolesOrder(group) {
    let a = group.roles_order ? [...group.roles_order] : [];

    const r = Object.keys(group.entities.roles);

    r.forEach(id => {
        if (!a.includes(id)) {
            a.push(id);
        }
    });

    return a;
}


function Section({type, title, action, children, empty}) {
    return <div className="">
        <SectionHeader title={title} actions={action}/>

        <div className="">
            {children}
            {empty && <EmptyState subtitle={`No ${type} added`} fill/>}
        </div>
    </div>
}

function SimpleMembersList({field = "member_type", community_uid, value = ""}) {
    const community = useCommunity();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const has_more = useRef(false);
    const start_after = useRef(null);
    const per_page = 15;

    useEffect(function () {
        if (loading || !!data) {
            return;
        }
        setLoading(true);
        loadMembers();
    }, [field, loading, value, community_uid, data])

    function loadMembers() {
        const members_ref = collection(db, "community_members", community_uid, "members");

        let search;

        if(start_after.current) {
            search = query(members_ref, where(field, "==", value), startAfter(start_after.current), limit(per_page));
        } else {
            search = query(members_ref, where(field, "==", value), limit(per_page));
        }

        getDocs(search)
            .then((querySnapshot) => {
                const len = querySnapshot.docs.length;
                has_more.current = len >= per_page;
                if(len > 0) {
                    start_after.current = querySnapshot.docs[len - 1];
                }
                const new_data = querySnapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id
                    }
                });
                setData([
                    ...(data||[]),
                    ...new_data
                ]);

                setLoading(false);
            })
    }

    function loadMore() {
        setLoading(true);
        loadMembers();
    }

    if ((loading && !data) || !data) {
        return <div>
            <InlineLoader padding="p-4"/>
        </div>
    }

    const item_data = !data ? [] : data.map(d => {
        const le = buildEntityForList("members", d, community);
        return {
            ...le,
            id: d.id
        }
    });

    return <div className="">
        {data.length > 0 && <EntityList interactive minimal items={item_data} type={"members"}
                                        community_uid={community_uid}/>}
        {has_more.current && <div className="p-4">
            <Button text="Show more" loading={loading} onClick={() => loadMore()}/>
        </div>}
    </div>
}

function buildNewRolesOrder(roles_order, role_ids, source_index, destination_index, dir) {
    let current = [...roles_order];

    role_ids.forEach(rid => {
        if (!current.includes(rid)) {
            current.push(rid);
        }
    });

    return utils_objects_moveArrayItem(current, source_index, destination_index);
}

function ExpandedMembers({field = "member_types", community_uid, value = "", entity_props = {}}) {
    return <div className="max-h-[400px] border border-gray-200 rounded-lg py-2 my-0.5 overflow-y-auto">
        <SimpleMembersList value={value} community_uid={community_uid} field={field}/>
    </div>
}

function EntityTypeRenderer({type, ids, ...rest}) {
    const community = useCommunity();

    const [data, setData] = useState({});
    const dataRef = React.useRef(data);

    useEffect(function () {
        dataRef.current = data;
        loadMissingData();
    }, [community.uid, ids, type, data])

    function loadMissingData() {
        const missing_ids = ids.filter(id => !dataRef.current[id]);

        if (missing_ids.length === 0) {
            return;
        }

        let new_data = {...dataRef.current};
        missing_ids.forEach(id => {
            new_data[id] = {
                loading: true
            }
        });

        if (type === "roles") {
            getManyRoles(community.uid, missing_ids)
                .then(roles_data => {
                    roles_data.forEach(role => {
                        new_data[role.id] = {
                            id: role.id,
                            ...role.data(),
                            loading: false
                        }
                    })
                    setData(new_data);
                })
        } else if (type === "member_types") {
            // already loaded in the system community
            // comes as array
            // find match ups between the ids passed, and the membership levels
            const match = community.member_types.filter(mt => ids.includes(mt.id));

            let new_data = {...dataRef.current};

            match.forEach(mt => {
                new_data[mt.id] = {
                    id: mt.id,
                    ...mt,
                    loading: false
                }
            })

            setData(new_data);
        } else if (type === "filters") {
            getManyLists(community.uid, missing_ids)
                .then(lists_data => {
                    lists_data.forEach(list => {
                        new_data[list.id] = {
                            id: list.id,
                            ...list.data(),
                            loading: false
                        }
                    })
                    setData(new_data);
                })
        }
    }

    const items_arr = ids.map(id => {
        const item_data = !!data[id] && !data[id].loading ? data[id] : null;
        const item_is_loading = !!data[id] && data[id].loading || !item_data;

        const le = item_is_loading ? {} : buildEntityForList(type, item_data);

        const can_expand = type === "filters" || type === "member_types" && !item_is_loading;

        const expanded_key = type === "member_types" ? "member_type" : "";

        return {
            ...le,
            data: item_data,
            expandable: can_expand,
            expand_component: can_expand ?
                <ExpandedMembers community_uid={community.uid} field={expanded_key} value={id}/> : null,
            id,
            ready: !item_is_loading && !!item_data,
            loading: item_is_loading
        }
    }).filter(a => !!a.ready);


    return <div className="-mx-4">
        <EntityList interactive minimal items={items_arr} type={type}
                    community_uid={community.uid}/>
    </div>
}

export function GMMRules({
                             can_manage = true,
                             data,
                             id,
                             handleMakeRoleModerator = () => {
                             },
                             updateRolesOrder = () => {
                             },
                             removeEntity = () => {
                             },
                             openAddMembers = () => {
                             },
                         }) {
    const community = useCommunity();
    const [modal, setModal] = useState(null);
    const [modal_data, setModalData] = useState(null);

    useEffect(function () {
        getStartedRecordUsage(community.id, 'groupsmartrules');

        saveItemToLocalStorage(`getstarted-groupsmartrules-${community.id}-${id}`, true)
    }, [community.id])

    function confirmRemove() {
        removeEntity(modal_data.data_type, modal_data.id, () => {

        }, modal_data.keep_members);

        setModal(null);
        setModalData(null);

        global.addToast({
            intent: 'info',
            text: 'Removing rule from group'
        });
    }

    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const {destination, source} = result;

        let new_roles_order = buildNewRolesOrder(roles_order, Object.keys(data.entities['roles']), source.index, destination.index, 'down');

        updateRolesOrder(new_roles_order);
    }

    const entity_props = {
        can_remove: can_manage,
        community: community,
        onRemove: (ent_type, ent_id) => {
            setModalData({
                type: "rule_remove_from_group",
                data_type: ent_type,
                keep_members: true,
                id: ent_id
            });
            setModal('open_delete');
        },
        can_select: false,
        onSelect: () => {
        },
        onToggleSegmentLive: () => {
        },
        selected: [],
        block_removal: {}
    };

    const segments_count = Object.keys(data.entities['filters']).length;
    const roles_count = Object.keys(data.entities['roles']).length;
    const member_types_count = Object.keys(data.entities['member_types']).length;

    const roles_order = buildGroupDefaultRolesOrder(data);

    let actions = can_manage ? [
        {
            key: 'make-moderator',
            tooltip: "Make Moderator",
            icon: <ArrowUturnUpIcon/>,
            show: (type, id, dt, index) => {
                if (type === 'roles' && !data.moderators.roles[id] && Object.keys(data.moderators.roles).length < 3) {
                    return true;
                }
                return false;
            },
            onClick: (type, id, dt, index) => {
                if (type === 'roles' && !data.moderators.roles[id]) {
                    handleMakeRoleModerator(id)
                }
            }
        }
    ] : [];

    return <div className="pt-2 space-y-4">
        <Section action={<TextAction text="Add" inverse onClick={() => openAddMembers('roles')}/>}
                 empty={roles_count === 0} title="Roles" type="roles">

            <EntityTypeRenderer
                type={"roles"}
                can_reorder
                ids={Object.keys(data.entities['roles'])}
                entity_props={entity_props}
            />

        </Section>
        <Section action={<TextAction text="Add" inverse onClick={() => openAddMembers('segments')}/>}
                 empty={segments_count === 0} type="lists" title="Lists">
            <EntityTypeRenderer
                type={"filters"}
                ids={Object.keys(data.entities['filters'])}
                entity_props={entity_props}
            />
        </Section>
        <Section action={<TextAction text="Add" inverse onClick={() => openAddMembers('member_types')}/>}
                 empty={member_types_count === 0} title="Membership Levels" type="membership levels">
            <EntityTypeRenderer
                type={"member_types"}
                ids={Object.keys(data.entities['member_types'])}
                entity_props={entity_props}
            />
        </Section>
    </div>
}