import React, {useEffect, useState} from "react"
import './styles.css';
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";

async function getData(id, path) {

    const path_parts = path.split('.');

    if (id === "undefined") {
        console.error("no id", {id, path})
        return null;
    }
    let ref;
    if (path_parts.length === 3) {
        if (path_parts[1] === "undefined") {
            console.error("no path", {id, path})
            return null;
        }
        ref = doc(db, path_parts[0], path_parts[1], path_parts[2], id);
    } else {
        ref = doc(db, path, id);
    }
    return await getDoc(ref)
        .then(doc => {
            return doc.exists() ? doc.data() : null;
        });
}

export default function DataWrapper({children, onError, data_key = 'data', type, id}) {
    const [data, setData] = useState({});
    const [data1, setData1] = useState({});
    const [data2, setData2] = useState({});

    const len = typeof id === 'string' ? 1 : id.length;

    useEffect(function () {
        if (id && type) {
            if (len > 1) {
                getData(id[0], type[0])
                    .then(dt => setData(dt))
                getData(id[1], type[1])
                    .then(dt1 => setData1(dt1))
                if (id.length > 2) {
                    getData(id[2], type[2])
                        .then(dt2 => setData2(dt2))
                }
            } else {
                getData(id, type)
                    .then(dt => setData(dt))
            }
        }
    }, [id, type]);

    let p = {};

    if (len > 1) {
        p[data_key[0]] = {...data};
        p[`${data_key[0]}_id`] = id[0];
    } else {
        p[data_key] = {...data};
        p[`${data_key}_id`] = id;
    }

    if (len > 1) {
        p[data_key[1]] = {...data1};
        p[`${data_key[1]}_id`] = id[1];
    }

    if (len > 2) {
        p[data_key[2]] = {...data2};
        p[`${data_key[2]}_id`] = id[2];
    }

    return React.Children.map(children, child => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
            return React.cloneElement(child, p);
        }
    });
};