import React, {useEffect} from 'react';
import M3_A_Heading from "../../../m3/atoms/heading";
import M3_A_Form from "../../../m3/atoms/form";
import M3_A_FormControl from "../../../m3/atoms/form-control";
import M3_A_ColorInput from "../../../m3/atoms/color-input";
import M3_A_Input from "../../../m3/atoms/input";
import HandleSelector from "../../auth/create-account/handle-selector";
import M3_A_Button from "../../../m3/atoms/button";
import {getCurrentLang, getCurrentTimezone} from "../../../../common/utilities/lang-region";
import {authFetch} from "../../../../api/network";
import {useNavigate} from "react-router-dom";
import M3_A_Text from "../../../m3/atoms/text";
import {DynamicAppFrame} from "../../../m3/layouts/dynamic-frame";
import {m3_generateTheme} from "../../../m3/utilities/color-system";
import {useUnaverse} from "../../../config/unaverse";
import {useIsMobile} from "../../../m3/hooks/is-mobile";
import M3_A_BlankSlate from "../../../m3/atoms/blank-slate";

/*
* This is the CreateCommunity page
 */

const _props = {
    children: {
        type: "node",
        required: false,
    }
};

function CharacterCounter({count}) {
    return <div className="text-right text-gray-400 text-sm">{count}</div>
}

async function handleCreateCommunity(name, handle, color, profile_picture, profile_picture_color) {
    const full_payload = {
        payload: {
            handle: handle.trim(),
            bio: "",
            emoji: "",
            name: name.trim(),
            profile_picture,
            profile_picture_color,
            color: color
        },
        options: {
            language: getCurrentLang(),
            is_claimed: true,
            timezone: getCurrentTimezone()
        },
        is_orange: true
    };


    return new Promise((resolve, reject) => {
        const r = (res) => {
            resolve(res);
        };

        authFetch('/communities/create', r, r, "POST", full_payload);
    })
}

function CreateForm({step, onNextStep, onCommunityCreated, onUpdateColor}) {
    const [name, setName] = React.useState("");
    const [_domain_name, _setDomainName] = React.useState("");
    const [domain, setDomain] = React.useState("");
    const [color, setColor] = React.useState("#F37A0B");
    const [loading, setLoading] = React.useState(false);

    const [profile_picture, setProfilePicture] = React.useState("");
    const [profile_picture_color, setProfilePictureColor] = React.useState("");

    const disabled = !name || !domain;

    useEffect(() => {
        onUpdateColor(color);
    }, [color]);

    function createCommunity(e) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        if (disabled) {
            return;
        }
        setLoading(true);

        handleCreateCommunity(name, domain, color, profile_picture, profile_picture_color)
            .then((resp) => {
                // need to refresh member

                if(resp&&resp.ok) {
                    onCommunityCreated(domain);
                } else {
                    alert("Error creating community")
                }
            })
    }

    const h = <HandleSelector show_label={false} key={"handle-selector"}
                               placeholder={""}
                               default_text={domain||_domain_name}
                               setHandle={(hh) => {
                                   setDomain(hh);
                               }} type='community'
                               scope='global'/>;

    const max_name_length = 80;

    if (step === 'name') {
        return <M3_A_Form as="div" className="grid gap-3">
            <M3_A_FormControl>
                <M3_A_Input placeholder={`Organization Name`}
                            trailing_indicator={<CharacterCounter count={max_name_length - name.length}/>}
                            onBlur={() => {
                                if (name) {
                                    _setDomainName(name);
                                }
                            }} size="lg" auto_focus value={name} type="text" onChange={e => {
                    setName(e?.target?.value.slice(0, 80))
                }}/>
            </M3_A_FormControl>
            <div>
                <M3_A_Button loading={loading} onClick={onNextStep} intent="primary" size="lg"
                             text="Next" disabled={!name}/>
            </div>

            <div className="hidden">
                {h}
            </div>
        </M3_A_Form>
    }

    return <M3_A_Form as="div" className="grid gap-3">

        <M3_A_FormControl label="Accent Color">
            <M3_A_ColorInput size="lg" value={color || "#F37A0B"} onChange={e => {
                if (!e?.target?.value) {
                    return;
                }
                setColor(e.target.value);
            }}/>
        </M3_A_FormControl>

        <M3_A_FormControl label={`Community URL`}>
            {h}
        </M3_A_FormControl>

        <div>
            <M3_A_Button loading={loading} onClick={createCommunity} intent="primary" size="lg"
                         text="Create Community" disabled={disabled}/>
        </div>
    </M3_A_Form>
}

export default function CreateCommunityPage({}) {
    const unaverse = useUnaverse();
    const [step, setStep] = React.useState('name'); // name, customize
    const [accent_color, setAccentColor] = React.useState("#F37A0B");
    const navigate = useNavigate();
    const is_mobile = useIsMobile();

    function handleCreatedCommunity(domain) {
        unaverse
            .refreshMemberships()
            .then(() => {
                navigate(`/c/${domain}/home?ref=create-community`)
            })
    }

    let title, subtitle;

    if (step === 'name') {
        title = "What's the name of your club or association?";
        subtitle = "This will be the name of your community on Orange.";
    } else {
        title = "Let's make your community feel like home.";
        subtitle = "You can adjust any of these later on.";
    }

    const left_sidebar = <div>

    </div>;

    const generated_theme = m3_generateTheme(accent_color, "light", 3);
    const derived = generated_theme.derived.light;
    let step_text = "";
    if (step === 'name') {
        step_text = "Step 1 of 2";
    } else {
        step_text = "Step 2 of 2";
    }

    if(is_mobile) {
        return <M3_A_BlankSlate
            height="h-screen"
            title={"Desktop Only"}
            description={"Community creation is only available on desktop at this time."}
            button={{
                children: "Back to Home",
                intent: "primary",
                onClick: () => {
                    navigate("/");
                }
            }}
        />
    }

    return <DynamicAppFrame left_sidebar={left_sidebar} theme={derived}>
        <div>
            <div className="max-w-lg flex flex-col gap-4 p-8">
                <M3_A_Text size="sm" color="text-gray-600">{step_text}</M3_A_Text>
                <M3_A_Heading size="h1">{title}</M3_A_Heading>
                <M3_A_Text color="text-gray-700" size="base">{subtitle}</M3_A_Text>
                <CreateForm onCommunityCreated={handleCreatedCommunity} onUpdateColor={nc => {
                    setAccentColor(nc);
                }} onNextStep={() => {
                    setStep('customize');
                }} step={step}/>
            </div>
        </div>
    </DynamicAppFrame>
}