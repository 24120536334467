import React, {useContext, useEffect, useState} from 'react';
import {collection, getDocs} from "firebase/firestore";
import {db} from "../../../config/setup-firestore";
import {useCommunity} from "../../../config/community";
import {LocalSearchBar} from "../local-search-bar";
import {InterestTag} from "../view-interests";
import Modal from "../modal";
import SimplePortal from "../simple-portal";
import MetaHeader from "../meta-header";
import Button from "../button";
import {data_system_interests_arr, data_system_interests_map} from "../../../../common/data/data/interests";

async function getCustomCommunityInterests(community_uid) {
    const col = collection(db, 'communities', community_uid, 'interests');
    const snapshot = await getDocs(col);
    return snapshot.docs.map(d => {
        return {
            id: d.id,
            ...d.data()
        }
    })
}

function buildAllInterests(dt, custom) {
    return [...dt.map(it => {
        return {
            ...it,
            id: `default_${it.id}`
        }
    })].concat(custom.map(it => {
        return {
            id: it.id,
            name: it.name,
            emoji: it.emoji,
            custom: true
        }
    }))
}

function buildShowInterests(all_interests, query) {
    if (!query) {
        return all_interests.sort((a, b) => a.name > b.name ? 1 : -1);
    }
    return all_interests.filter(interest => {
        return interest.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    })
}

export function SelectInterests({
                                    title = "Interest Selector", onSelect = () => {
    }, initial = {}, max = 15
                                }) {
    const [selected, setSelected] = useState({...initial});
    const community = useCommunity();
    const [query, setQuery] = useState("");
    const [custom, setCustom] = useState([]);
    const len = Object.keys(selected).length;

    useEffect(function () {
        getCustomInterests()
    }, [])

    function getCustomInterests() {
        getCustomCommunityInterests(community.uid)
            .then(data => {
                setCustom([...data]);
            })
    }

    function handleSelect(id) {
        let ns = selected;
        if (selected[id]) {
            delete ns[id];
        } else {
            if (len > (max - 1)) {
                return;
            }
            ns[id] = true;
        }
        setSelected({...ns});
        onSelect(ns, id);
    }

    const all_interests = buildAllInterests(data_system_interests_arr, custom);

    const show_interests = buildShowInterests(all_interests, query);

    return <div className="space-y-2">
        <div className="pb-1.5 max-w-md">
            <LocalSearchBar autoFocus onChange={q => setQuery(q)}/>
        </div>
        <div>
            <div className={`flex flex-wrap interest-tag-spacing pr-10`}>
                {show_interests.map((int, i) => <InterestTag onClick={() => handleSelect(`${int.id}`)} onRemove={() => {
                }} can_remove={false} active={selected[`${int.id}`]} key={int.id} {...int} />)}
            </div>
        </div>
    </div>
}

export function SelectInterestsModal(props) {
    const [selected, setSelected] = useState(props?.interests || {});
    const [state, setState] = useState("");

    function handleSaveInterests() {
        setState("loading");
        props.onSave(selected);
    }

    return <SimplePortal>
        <Modal center size="medium">
            <div className="py-2 px-4 relative">
                <MetaHeader can_close title="Select your interests" onClose={()=>{
                    props.onClose();
                }}/>
                <div className="pt-2 space-y-4">
            <SelectInterests onSelect={(s, sid) => {
                setSelected(s);
            }} initial={props?.interests || {}} max={props.max || 15}/>
                </div>
                <div className="flex">
                    <div className="flex-grow"></div>
                    <div>
                        <Button loading={state === "loading"} onClick={handleSaveInterests} text="Save"
                                intent="primary"/>
                    </div>
                </div>
            </div>
        </Modal>
    </SimplePortal>
}