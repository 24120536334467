import React from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";

const Wrapper = styled.div`

`;

const RowFrame = styled.div`
padding: 4px 8px;
`;

const ItemFrame = styled.span`

`;

function Item({name,handle,comma,member_id}) {
    return <ItemFrame>
        {member_id!==''&&<Link className='link' to={`/c/${handle}/member-id/${member_id}`}>
        {name?name:"Anonymous"}
        </Link>}
        {member_id===''&&name}
        {comma?', ':' '}
    </ItemFrame>
}

function Row({items,handle,action=`added`}) {
    const len = items.length;
    const slice = items.slice(0,3);
    const others_count = len > 3 ? (len-3) : 0;
     return <RowFrame className='text-gray-800'>
        {slice.map((item,i)=>{
            return <Item comma={((i+1)<len)} handle={handle} key={i} {...item} />
        })}
         {others_count===0?null:others_count===1?` and 1 other was ${action}.`:` and ${others_count} others were ${action}.`}
         {others_count===0?len===1?` was ${action}.`:`were ${action}.`:``}
    </RowFrame>
}

export default function MemberImportSummary({error=[],handle,added=[],existing=[]}) {
    return <Wrapper>
        {added.length>0&&<Row handle={handle} action='added' items={added} />}
        {existing.length>0&&<Row handle={handle} action='already members' items={existing} />}
        {error.length>0&&<Row handle={handle} action='not able to be added' items={error} />}
    </Wrapper>
}