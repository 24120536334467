import React, {useEffect, useState} from "react";
import {externalAuthFetch} from "../../../../api/network";
import {FieldLabel} from "../../../m3/_legacy_components/form/field";
import {PinEntry} from "../verify-pin";
import {isEmailSignInLink} from "../link/api";
import {authApp} from "../../../config/auth";
import {useNavigate} from "react-router-dom";
import Button from "../../../m3/_legacy_components/button";

async function api_requestEmailCode(email) {
    return await new Promise(resolve => {
        const res = (resp) => {
            resolve(resp)
        };

        externalAuthFetch("/send-sign-in-code", res, res, "POST", {email});
    })
}

function VerifyCode({
                        email, onPhaseChange, onSuccess = () => {
    },
                    }) {
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState('');
    const [phase, setPhase] = useState('');

    useEffect(function () {
        if (onPhaseChange) {
            onPhaseChange(phase);
        }
    }, [phase])

    function handlePinSubmit(c) {
        setCode(c);
        verifyCode(c);
    }

    function verifyCode(c) {
        if (loading === 'verifying-code') {
            return;
        }

        setLoading('verifying-code');

        const res = (resp) => {
            if (resp.data.status === 'invalid') {
                setPhase('invalid-code')
            } else {
                onSuccess(resp.data);
                setPhase('verified');
            }
            setLoading('');
        };

        const payload = {
            code: `${c}`,
            username: email
        };

        externalAuthFetch("/check-sign-in-code", res, res, "POST", payload);
    }

    if (phase === 'verified') {
        return;
    }

    if (phase === 'invalid-code') {
        return null;
    }

    return <div>
        <FieldLabel label="Enter the six-digit code"/>
        <div className={`${loading === "verifying-code" ? "opacity-60 animate-pulse" : ""}`}>
            <PinEntry len={6} onSubmit={handlePinSubmit} setCode={c => setCode(c)}/>
        </div>
    </div>
}

export default function SignInWithCode({
                                           email = ""
                                       }) {
    const [status, setStatus] = React.useState("idle");
    const navigate = useNavigate();

    useEffect(() => {
        if(status === "idle") {
            handleSendSignInCode();
        }
    }, [email, status]);

    function handleResendCode() {
    handleSendSignInCode();
    }

    function handleStatusChange(new_phase) {
        setStatus(new_phase);
    }

    function handleSendSignInCode() {
        if (!email || status === "sending" || status === "sent") {
            console.warn("Please enter your email address OR SENT", email, status)
            return;
        }
        setStatus("sending");
        api_requestEmailCode(email)
            .then(resp => {
                setStatus("sent");
            })
    }

    function handleSignIn(data) {
        if (!data.auth_link) {
            alert("No auth link")
            return;
        }

        isEmailSignInLink(authApp, email, data.auth_link)
            .then((result) => {
                window.localStorage.removeItem('emailForSignIn');
                // You can access the new user via result.user
                // Additional user info profile not available via:
                // result.additionalUserInfo.profile == null
                // You can check if the user is new or existing:
                // result.additionalUserInfo.isNewUser

                navigate('/home');
            })
    }

    if (status === "invalid-code") {
        return <div className="flex flex-col gap-4">
            <div className="text-sm text-gray-600">
                The code you entered is invalid. Would you like to resend the code?
            </div>

            <div>
                <Button intent='primary' fill size='large'
                        onClick={() => {
                            handleResendCode();
                        }}
                        text={'Resend Code'}
                />
            </div>
        </div>
    }

    return (
        <div className="flex flex-col gap-4">
            <div className="text-sm text-gray-600">
                A six-digit code was sent to <span className="font-semibold">{email}</span>
            </div>

            <VerifyCode onPhaseChange={handleStatusChange} onSuccess={(data) => {
                handleSignIn(data);
            }} email={email}/>
        </div>
    );
}